import { useState, useEffect } from "react";

/**
 * For detecting change on referralHeader var.
 * @returns An object of states and their setters.
 */
export const useRenderReferralBanners = () => {
  const [referralHeader, setReferralHeader] = useState({});
  const [displayReferralBanner, setDisplayReferralBanner] = useState(false);
  const [displayLogoBannerAndFooter, setDisplayLogoBannerAndFooter] =
    useState(false);

  const renderReferralBanner = () => {
    // Check if header variable is set
    if (referralHeader !== "undefined") {
      if (referralHeader !== null) {
        if (typeof referralHeader.workplacecode !== "undefined") {
          setDisplayReferralBanner(true);
        }
      }
    }
  };

  const renderLogoBanner = () => {
    let hostname = window.location.hostname;
    let logoBannerEnvURLMap = [
      ["localhost"],
      ["192.168.169.170"],
      ["dev-hc.myhealthforlife.org.au"],
      ["uat-hc.myhealthforlife.org.au"],
      ["stg-hc.myhealthforlife.org.au"],
      ["hc.myhealthforlife.com.au"],
    ];

    for (let i = 0; i < logoBannerEnvURLMap.length; i++) {
      if (logoBannerEnvURLMap[i] === hostname) {
        setDisplayLogoBannerAndFooter(true);
      }
    }
  };

  // eslint-disable-next-line no-undef
  useEffect(() => {
    renderReferralBanner();
    renderLogoBanner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralHeader, renderLogoBanner]);

  return {
    referralHeader,
    setReferralHeader,
    displayReferralBanner,
    setDisplayReferralBanner,
    displayLogoBannerAndFooter,
    setDisplayLogoBannerAndFooter,
  };
};
