export const postcodeArr = [
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "SOUTHPORT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.953000",
    Latitude: "-12.729300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "STAPLETON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.999100",
    Latitude: "-13.192000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "THAMARRURR",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.637200",
    Latitude: "-14.239200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "TIPPERARY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.827000",
    Latitude: "-13.948000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "TIVENDALE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.949300",
    Latitude: "-12.467800"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "TIWI ISLANDS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.822500",
    Latitude: "-11.515300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "TORTILLA FLATS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.171700",
    Latitude: "-13.097800"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "TUMBLING WATERS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.962500",
    Latitude: "-12.755100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "UMBAKUMBA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.805200",
    Latitude: "-13.861300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "VERNON ISLANDS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.086200",
    Latitude: "-12.066400"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WADEYE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.516200",
    Latitude: "-14.247800"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WAGAIT BEACH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.751400",
    Latitude: "-12.453600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WAK WAK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.252100",
    Latitude: "-12.666300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WARRUWI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.379800",
    Latitude: "-11.656600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WEDDELL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.002500",
    Latitude: "-12.617200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WEST ARNHEM",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.692100",
    Latitude: "-12.025300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WICKHAM",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.923800",
    Latitude: "-12.548700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WINNELLIE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BAGS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WISHART",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.934700",
    Latitude: "-12.456900"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WOOLANING",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "WURRUMIYANGA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.624500",
    Latitude: "-11.755600"
  },
  {
    Country: "Australia",
    Pcode: "0828",
    Locality: "BERRIMAH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.930100",
    Latitude: "-12.443600"
  },
  {
    Country: "Australia",
    Pcode: "0828",
    Locality: "KNUCKEY LAGOON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.945700",
    Latitude: "-12.424300"
  },
  {
    Country: "Australia",
    Pcode: "0829",
    Locality: "HOLTZE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.008100",
    Latitude: "-12.455400"
  },
  {
    Country: "Australia",
    Pcode: "0829",
    Locality: "PINELANDS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.956900",
    Latitude: "-12.456500"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "ARCHER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.958000",
    Latitude: "-12.521900"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "DRIVER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.976900",
    Latitude: "-12.483500"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "DURACK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.972800",
    Latitude: "-12.472500"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "FARRAR",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.996700",
    Latitude: "-12.479800"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "GRAY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.981500",
    Latitude: "-12.490400"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "MARLOW LAGOON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.957200",
    Latitude: "-12.496000"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "MOULDEN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.974300",
    Latitude: "-12.506000"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "PALMERSTON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.985200",
    Latitude: "-12.480100"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "SHOAL BAY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.053500",
    Latitude: "-12.365800"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "WOODROFFE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.980700",
    Latitude: "-12.503200"
  },
  {
    Country: "Australia",
    Pcode: "0830",
    Locality: "YARRAWONGA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.990000",
    Latitude: "-12.473000"
  },
  {
    Country: "Australia",
    Pcode: "0831",
    Locality: "PALMERSTON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0832",
    Locality: "BAKEWELL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.992900",
    Latitude: "-12.496600"
  },
  {
    Country: "Australia",
    Pcode: "0832",
    Locality: "BELLAMACK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.983000",
    Latitude: "-12.515400"
  },
  {
    Country: "Australia",
    Pcode: "0832",
    Locality: "GUNN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.994400",
    Latitude: "-12.487500"
  },
  {
    Country: "Australia",
    Pcode: "0832",
    Locality: "JOHNSTON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.006200",
    Latitude: "-12.491700"
  },
  {
    Country: "Australia",
    Pcode: "0832",
    Locality: "MITCHELL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.997400",
    Latitude: "-12.530600"
  },
  {
    Country: "Australia",
    Pcode: "0832",
    Locality: "ROSEBERY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.991200",
    Latitude: "-12.508500"
  },
  {
    Country: "Australia",
    Pcode: "0832",
    Locality: "ROSEBERY HEIGHTS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0832",
    Locality: "ZUCCOLI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.004300",
    Latitude: "-12.511800"
  },
  {
    Country: "Australia",
    Pcode: "0834",
    Locality: "VIRGINIA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.019100",
    Latitude: "-12.541300"
  },
  {
    Country: "Australia",
    Pcode: "0835",
    Locality: "HOWARD SPRINGS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.062100",
    Latitude: "-12.467200"
  },
  {
    Country: "Australia",
    Pcode: "0836",
    Locality: "GIRRAWEEN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.096200",
    Latitude: "-12.525500"
  },
  {
    Country: "Australia",
    Pcode: "0836",
    Locality: "HERBERT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.159500",
    Latitude: "-12.525600"
  },
  {
    Country: "Australia",
    Pcode: "0836",
    Locality: "HUMPTY DOO",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.125800",
    Latitude: "-12.584100"
  },
  {
    Country: "Australia",
    Pcode: "0837",
    Locality: "MANTON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.190000",
    Latitude: "-12.813100"
  },
  {
    Country: "Australia",
    Pcode: "0837",
    Locality: "NOONAMAH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.091500",
    Latitude: "-12.642700"
  },
  {
    Country: "Australia",
    Pcode: "0838",
    Locality: "BERRY SPRINGS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.004700",
    Latitude: "-12.722000"
  },
  {
    Country: "Australia",
    Pcode: "0839",
    Locality: "COOLALINGA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.041800",
    Latitude: "-12.521200"
  },
  {
    Country: "Australia",
    Pcode: "0840",
    Locality: "DUNDEE BEACH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.357900",
    Latitude: "-12.732500"
  },
  {
    Country: "Australia",
    Pcode: "0840",
    Locality: "DUNDEE DOWNS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.511300",
    Latitude: "-12.765500"
  },
  {
    Country: "Australia",
    Pcode: "0840",
    Locality: "DUNDEE FOREST",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.448800",
    Latitude: "-12.721500"
  },
  {
    Country: "Australia",
    Pcode: "0841",
    Locality: "DARWIN RIVER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.964000",
    Latitude: "-12.816000"
  },
  {
    Country: "Australia",
    Pcode: "0845",
    Locality: "BATCHELOR",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.046700",
    Latitude: "-13.087000"
  },
  {
    Country: "Australia",
    Pcode: "0846",
    Locality: "ADELAIDE RIVER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.167400",
    Latitude: "-13.166100"
  },
  {
    Country: "Australia",
    Pcode: "0847",
    Locality: "PINE CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.826100",
    Latitude: "-13.823500"
  },
  {
    Country: "Australia",
    Pcode: "0850",
    Locality: "COSSACK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.176400",
    Latitude: "-14.461700"
  },
  {
    Country: "Australia",
    Pcode: "0850",
    Locality: "EMUNGALAN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.256300",
    Latitude: "-14.404400"
  },
  {
    Country: "Australia",
    Pcode: "0850",
    Locality: "KATHERINE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.270000",
    Latitude: "-14.449600"
  },
  {
    Country: "Australia",
    Pcode: "0850",
    Locality: "KATHERINE EAST",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.284800",
    Latitude: "-14.464000"
  },
  {
    Country: "Australia",
    Pcode: "1215",
    Locality: "AUSTRALIA SQUARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1220",
    Locality: "GROSVENOR PLACE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1225",
    Locality: "ROYAL EXCHANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1230",
    Locality: "QUEEN VICTORIA BUILDING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1235",
    Locality: "SYDNEY SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1240",
    Locality: "HAYMARKET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1300",
    Locality: "DARLINGHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1335",
    Locality: "POTTS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1340",
    Locality: "KINGS CROSS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1350",
    Locality: "WOOLLAHRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1355",
    Locality: "BONDI JUNCTION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1360",
    Locality: "DOUBLE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1405",
    Locality: "CHULLORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PARCEL CENTRE RTS USE ONLY",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1435",
    Locality: "ALEXANDRIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1440",
    Locality: "WATERLOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1445",
    Locality: "ROSEBERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1450",
    Locality: "CAMPERDOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1455",
    Locality: "BOTANY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1460",
    Locality: "MASCOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1465",
    Locality: "KENSINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1466",
    Locality: "UNSW SYDNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1470",
    Locality: "DRUMMOYNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1475",
    Locality: "MARRICKVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2066",
    Locality: "LANE COVE NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.161500",
    Latitude: "-33.805600"
  },
  {
    Country: "Australia",
    Pcode: "2066",
    Locality: "LANE COVE WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.149500",
    Latitude: "-33.814000"
  },
  {
    Country: "Australia",
    Pcode: "2066",
    Locality: "LINLEY POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.149300",
    Latitude: "-33.826800"
  },
  {
    Country: "Australia",
    Pcode: "2066",
    Locality: "LONGUEVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.168100",
    Latitude: "-33.829800"
  },
  {
    Country: "Australia",
    Pcode: "2066",
    Locality: "NORTHWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.178400",
    Latitude: "-33.828700"
  },
  {
    Country: "Australia",
    Pcode: "2066",
    Locality: "RIVERVIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "LANE COVE",
    Category: "Delivery Area",
    Longitude: "151.160200",
    Latitude: "-33.824300"
  },
  {
    Country: "Australia",
    Pcode: "2067",
    Locality: "CHATSWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.181200",
    Latitude: "-33.795700"
  },
  {
    Country: "Australia",
    Pcode: "2067",
    Locality: "CHATSWOOD WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.156000",
    Latitude: "-33.796300"
  },
  {
    Country: "Australia",
    Pcode: "2068",
    Locality: "CASTLECRAG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.220500",
    Latitude: "-33.800300"
  },
  {
    Country: "Australia",
    Pcode: "2068",
    Locality: "MIDDLE COVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.211000",
    Latitude: "-33.791500"
  },
  {
    Country: "Australia",
    Pcode: "2068",
    Locality: "NORTH WILLOUGHBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.200500",
    Latitude: "-33.795400"
  },
  {
    Country: "Australia",
    Pcode: "2068",
    Locality: "WILLOUGHBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.200400",
    Latitude: "-33.806400"
  },
  {
    Country: "Australia",
    Pcode: "2068",
    Locality: "WILLOUGHBY EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.205800",
    Latitude: "-33.798600"
  },
  {
    Country: "Australia",
    Pcode: "2068",
    Locality: "WILLOUGHBY NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2069",
    Locality: "CASTLE COVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.212300",
    Latitude: "-33.785900"
  },
  {
    Country: "Australia",
    Pcode: "2069",
    Locality: "ROSEVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.184900",
    Latitude: "-33.782500"
  },
  {
    Country: "Australia",
    Pcode: "2069",
    Locality: "ROSEVILLE CHASE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.200100",
    Latitude: "-33.774800"
  },
  {
    Country: "Australia",
    Pcode: "2070",
    Locality: "EAST LINDFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.186600",
    Latitude: "-33.767300"
  },
  {
    Country: "Australia",
    Pcode: "2070",
    Locality: "LINDFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.160000",
    Latitude: "-33.780600"
  },
  {
    Country: "Australia",
    Pcode: "2070",
    Locality: "LINDFIELD WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2071",
    Locality: "EAST KILLARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.180200",
    Latitude: "-33.757400"
  },
  {
    Country: "Australia",
    Pcode: "2071",
    Locality: "KILLARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.156700",
    Latitude: "-33.768900"
  },
  {
    Country: "Australia",
    Pcode: "2072",
    Locality: "GORDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.151200",
    Latitude: "-33.757300"
  },
  {
    Country: "Australia",
    Pcode: "2073",
    Locality: "PYMBLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.141100",
    Latitude: "-33.745100"
  },
  {
    Country: "Australia",
    Pcode: "2073",
    Locality: "WEST PYMBLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.130200",
    Latitude: "-33.763600"
  },
  {
    Country: "Australia",
    Pcode: "2074",
    Locality: "NORTH TURRAMURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.154000",
    Latitude: "-33.691400"
  },
  {
    Country: "Australia",
    Pcode: "2074",
    Locality: "SOUTH TURRAMURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.108900",
    Latitude: "-33.752400"
  },
  {
    Country: "Australia",
    Pcode: "2074",
    Locality: "TURRAMURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.127500",
    Latitude: "-33.734300"
  },
  {
    Country: "Australia",
    Pcode: "2074",
    Locality: "WARRAWEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.121700",
    Latitude: "-33.725900"
  },
  {
    Country: "Australia",
    Pcode: "2075",
    Locality: "ST IVES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.169800",
    Latitude: "-33.728800"
  },
  {
    Country: "Australia",
    Pcode: "2075",
    Locality: "ST IVES CHASE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.165100",
    Latitude: "-33.703000"
  },
  {
    Country: "Australia",
    Pcode: "2076",
    Locality: "NORMANHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.096400",
    Latitude: "-33.723000"
  },
  {
    Country: "Australia",
    Pcode: "2076",
    Locality: "NORTH WAHROONGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.130300",
    Latitude: "-33.696500"
  },
  {
    Country: "Australia",
    Pcode: "2076",
    Locality: "WAHROONGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.117100",
    Latitude: "-33.714900"
  },
  {
    Country: "Australia",
    Pcode: "2077",
    Locality: "ASQUITH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.116600",
    Latitude: "-33.688300"
  },
  {
    Country: "Australia",
    Pcode: "2077",
    Locality: "HORNSBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.100500",
    Latitude: "-33.697600"
  },
  {
    Country: "Australia",
    Pcode: "2077",
    Locality: "HORNSBY HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.100000",
    Latitude: "-33.664600"
  },
  {
    Country: "Australia",
    Pcode: "2077",
    Locality: "WAITARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.105400",
    Latitude: "-33.710200"
  },
  {
    Country: "Australia",
    Pcode: "2079",
    Locality: "MOUNT COLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.115500",
    Latitude: "-33.664900"
  },
  {
    Country: "Australia",
    Pcode: "2080",
    Locality: "MOUNT KURING-GAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.141100",
    Latitude: "-33.652700"
  },
  {
    Country: "Australia",
    Pcode: "2081",
    Locality: "BEROWRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.152000",
    Latitude: "-33.628000"
  },
  {
    Country: "Australia",
    Pcode: "2081",
    Locality: "COWAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.165000",
    Latitude: "-33.580500"
  },
  {
    Country: "Australia",
    Pcode: "2082",
    Locality: "BEROWRA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.137200",
    Latitude: "-33.525100"
  },
  {
    Country: "Australia",
    Pcode: "2082",
    Locality: "BEROWRA HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.137400",
    Latitude: "-33.601500"
  },
  {
    Country: "Australia",
    Pcode: "2082",
    Locality: "BEROWRA WATERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.120200",
    Latitude: "-33.603400"
  },
  {
    Country: "Australia",
    Pcode: "2083",
    Locality: "BAR POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.172700",
    Latitude: "-33.493200"
  },
  {
    Country: "Australia",
    Pcode: "2083",
    Locality: "BROOKLYN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.234000",
    Latitude: "-33.581500"
  },
  {
    Country: "Australia",
    Pcode: "2083",
    Locality: "CHEERO POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.193300",
    Latitude: "-33.509700"
  },
  {
    Country: "Australia",
    Pcode: "2083",
    Locality: "COGRA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.224400",
    Latitude: "-33.519400"
  },
  {
    Country: "Australia",
    Pcode: "2083",
    Locality: "DANGAR ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.239400",
    Latitude: "-33.539500"
  },
  {
    Country: "Australia",
    Pcode: "2083",
    Locality: "MILSONS PASSAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.178800",
    Latitude: "-33.513100"
  },
  {
    Country: "Australia",
    Pcode: "2083",
    Locality: "MOONEY MOONEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.211300",
    Latitude: "-33.523000"
  },
  {
    Country: "Australia",
    Pcode: "2084",
    Locality: "COTTAGE POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.203400",
    Latitude: "-33.619200"
  },
  {
    Country: "Australia",
    Pcode: "2084",
    Locality: "DUFFYS FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.191800",
    Latitude: "-33.672500"
  },
  {
    Country: "Australia",
    Pcode: "2084",
    Locality: "TERREY HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.215600",
    Latitude: "-33.692100"
  },
  {
    Country: "Australia",
    Pcode: "2085",
    Locality: "BELROSE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.209600",
    Latitude: "-33.721500"
  },
  {
    Country: "Australia",
    Pcode: "2085",
    Locality: "BELROSE WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2085",
    Locality: "DAVIDSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.189700",
    Latitude: "-33.739500"
  },
  {
    Country: "Australia",
    Pcode: "2086",
    Locality: "FRENCHS FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.233200",
    Latitude: "-33.751300"
  },
  {
    Country: "Australia",
    Pcode: "2086",
    Locality: "FRENCHS FOREST EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2087",
    Locality: "FORESTVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.213200",
    Latitude: "-33.760800"
  },
  {
    Country: "Australia",
    Pcode: "2087",
    Locality: "KILLARNEY HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.215500",
    Latitude: "-33.772400"
  },
  {
    Country: "Australia",
    Pcode: "2088",
    Locality: "MOSMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.248600",
    Latitude: "-33.828700"
  },
  {
    Country: "Australia",
    Pcode: "2088",
    Locality: "SPIT JUNCTION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2089",
    Locality: "KURRABA POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.222800",
    Latitude: "-33.841400"
  },
  {
    Country: "Australia",
    Pcode: "2158",
    Locality: "ROUND CORNER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2159",
    Locality: "ARCADIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.068100",
    Latitude: "-33.613600"
  },
  {
    Country: "Australia",
    Pcode: "2159",
    Locality: "BERRILEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.104900",
    Latitude: "-33.605100"
  },
  {
    Country: "Australia",
    Pcode: "2159",
    Locality: "FIDDLETOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.091300",
    Latitude: "-33.561600"
  },
  {
    Country: "Australia",
    Pcode: "2159",
    Locality: "GALSTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.062500",
    Latitude: "-33.648300"
  },
  {
    Country: "Australia",
    Pcode: "2160",
    Locality: "MERRYLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.984800",
    Latitude: "-33.833900"
  },
  {
    Country: "Australia",
    Pcode: "2160",
    Locality: "MERRYLANDS WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.967000",
    Latitude: "-33.837800"
  },
  {
    Country: "Australia",
    Pcode: "2161",
    Locality: "GUILDFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.994600",
    Latitude: "-33.858600"
  },
  {
    Country: "Australia",
    Pcode: "2161",
    Locality: "GUILDFORD WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.963900",
    Latitude: "-33.847600"
  },
  {
    Country: "Australia",
    Pcode: "2161",
    Locality: "OLD GUILDFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.984700",
    Latitude: "-33.864600"
  },
  {
    Country: "Australia",
    Pcode: "2161",
    Locality: "YENNORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.967900",
    Latitude: "-33.861700"
  },
  {
    Country: "Australia",
    Pcode: "2162",
    Locality: "CHESTER HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.997900",
    Latitude: "-33.880800"
  },
  {
    Country: "Australia",
    Pcode: "2162",
    Locality: "SEFTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.010400",
    Latitude: "-33.887700"
  },
  {
    Country: "Australia",
    Pcode: "2163",
    Locality: "CARRAMAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.963700",
    Latitude: "-33.884100"
  },
  {
    Country: "Australia",
    Pcode: "2163",
    Locality: "LANSDOWNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "VILLAWOOD",
    Category: "Delivery Area",
    Longitude: "150.976500",
    Latitude: "-33.897300"
  },
  {
    Country: "Australia",
    Pcode: "2163",
    Locality: "VILLAWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.980300",
    Latitude: "-33.884300"
  },
  {
    Country: "Australia",
    Pcode: "2164",
    Locality: "SMITHFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.940800",
    Latitude: "-33.848300"
  },
  {
    Country: "Australia",
    Pcode: "2164",
    Locality: "SMITHFIELD WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2164",
    Locality: "WETHERILL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.899100",
    Latitude: "-33.841900"
  },
  {
    Country: "Australia",
    Pcode: "2164",
    Locality: "WETHERILL PARK BC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2164",
    Locality: "WOODPARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.960200",
    Latitude: "-33.841300"
  },
  {
    Country: "Australia",
    Pcode: "2165",
    Locality: "FAIRFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.951000",
    Latitude: "-33.873900"
  },
  {
    Country: "Australia",
    Pcode: "2165",
    Locality: "FAIRFIELD EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.973600",
    Latitude: "-33.875300"
  },
  {
    Country: "Australia",
    Pcode: "2165",
    Locality: "FAIRFIELD HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.940800",
    Latitude: "-33.867100"
  },
  {
    Country: "Australia",
    Pcode: "2165",
    Locality: "FAIRFIELD WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.922900",
    Latitude: "-33.870200"
  },
  {
    Country: "Australia",
    Pcode: "2166",
    Locality: "CABRAMATTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.935400",
    Latitude: "-33.898200"
  },
  {
    Country: "Australia",
    Pcode: "2166",
    Locality: "CABRAMATTA WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.911800",
    Latitude: "-33.893000"
  },
  {
    Country: "Australia",
    Pcode: "2166",
    Locality: "CANLEY HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.924700",
    Latitude: "-33.882000"
  },
  {
    Country: "Australia",
    Pcode: "2166",
    Locality: "CANLEY VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.946600",
    Latitude: "-33.884900"
  },
  {
    Country: "Australia",
    Pcode: "2166",
    Locality: "LANSVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.950900",
    Latitude: "-33.900500"
  },
  {
    Country: "Australia",
    Pcode: "2167",
    Locality: "GLENFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.892800",
    Latitude: "-33.970900"
  },
  {
    Country: "Australia",
    Pcode: "2168",
    Locality: "ASHCROFT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.901000",
    Latitude: "-33.916900"
  },
  {
    Country: "Australia",
    Pcode: "2168",
    Locality: "BUSBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.880900",
    Latitude: "-33.911200"
  },
  {
    Country: "Australia",
    Pcode: "2168",
    Locality: "CARTWRIGHT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.892000",
    Latitude: "-33.924500"
  },
  {
    Country: "Australia",
    Pcode: "2168",
    Locality: "GREEN VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.869800",
    Latitude: "-33.902600"
  },
  {
    Country: "Australia",
    Pcode: "2168",
    Locality: "HECKENBERG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.891400",
    Latitude: "-33.907800"
  },
  {
    Country: "Australia",
    Pcode: "2168",
    Locality: "HINCHINBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.861800",
    Latitude: "-33.917200"
  },
  {
    Country: "Australia",
    Pcode: "2168",
    Locality: "MILLER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.880400",
    Latitude: "-33.920600"
  },
  {
    Country: "Australia",
    Pcode: "2168",
    Locality: "SADLEIR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.890500",
    Latitude: "-33.917000"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "CASULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.896100",
    Latitude: "-33.952100"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "CASULA MALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "CHIPPING NORTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.956700",
    Latitude: "-33.914600"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "HAMMONDVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.961600",
    Latitude: "-33.950400"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "LIVERPOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.916000",
    Latitude: "-33.922700"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "LIVERPOOL SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "LIVERPOOL WESTFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "LURNEA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.897200",
    Latitude: "-33.936100"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "MOOREBANK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.956900",
    Latitude: "-33.943600"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "MOUNT PRITCHARD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.900500",
    Latitude: "-33.904200"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "PRESTONS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.871200",
    Latitude: "-33.941500"
  },
  {
    Country: "Australia",
    Pcode: "2170",
    Locality: "WARWICK FARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.934800",
    Latitude: "-33.912800"
  },
  {
    Country: "Australia",
    Pcode: "2171",
    Locality: "CARNES HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.848600",
    Latitude: "-33.937500"
  },
  {
    Country: "Australia",
    Pcode: "2171",
    Locality: "CECIL HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.843100",
    Latitude: "-33.887400"
  },
  {
    Country: "Australia",
    Pcode: "2171",
    Locality: "ELIZABETH HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.849000",
    Latitude: "-33.900300"
  },
  {
    Country: "Australia",
    Pcode: "2171",
    Locality: "HORNINGSEA PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.842000",
    Latitude: "-33.946200"
  },
  {
    Country: "Australia",
    Pcode: "2171",
    Locality: "HOXTON PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.857900",
    Latitude: "-33.929900"
  },
  {
    Country: "Australia",
    Pcode: "2171",
    Locality: "LEN WATERS ESTATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.854600",
    Latitude: "-33.913600"
  },
  {
    Country: "Australia",
    Pcode: "2171",
    Locality: "MIDDLETON GRANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.843200",
    Latitude: "-33.913800"
  },
  {
    Country: "Australia",
    Pcode: "2171",
    Locality: "WEST HOXTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.833800",
    Latitude: "-33.931400"
  },
  {
    Country: "Australia",
    Pcode: "2172",
    Locality: "PLEASURE POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.987500",
    Latitude: "-33.972700"
  },
  {
    Country: "Australia",
    Pcode: "2172",
    Locality: "SANDY POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.993400",
    Latitude: "-33.976200"
  },
  {
    Country: "Australia",
    Pcode: "2172",
    Locality: "VOYAGER POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.974000",
    Latitude: "-33.958700"
  },
  {
    Country: "Australia",
    Pcode: "2173",
    Locality: "HOLSWORTHY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.905300",
    Latitude: "-34.084100"
  },
  {
    Country: "Australia",
    Pcode: "2173",
    Locality: "WATTLE GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.939900",
    Latitude: "-33.952700"
  },
  {
    Country: "Australia",
    Pcode: "2174",
    Locality: "EDMONDSON PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.859800",
    Latitude: "-33.964000"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "PATONGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.275700",
    Latitude: "-33.546900"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "PEARL BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.306300",
    Latitude: "-33.541600"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "PHEGANS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.307800",
    Latitude: "-33.488300"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "WONDABYNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.235200",
    Latitude: "-33.504300"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "WOY WOY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.280900",
    Latitude: "-33.503300"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "WOY WOY BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.301400",
    Latitude: "-33.470700"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "BOOKER BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.347500",
    Latitude: "-33.513800"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "BOX HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.333700",
    Latitude: "-33.540600"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "DALEYS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.355200",
    Latitude: "-33.504700"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "EMPIRE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.368400",
    Latitude: "-33.502300"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "ETTALONG BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.334200",
    Latitude: "-33.515700"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "HARDYS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.355300",
    Latitude: "-33.526900"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "KILLCARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.361300",
    Latitude: "-33.522100"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "KILLCARE HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.375100",
    Latitude: "-33.522700"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "PRETTY BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WAGSTAFFE",
    Category: "Delivery Area",
    Longitude: "151.347800",
    Latitude: "-33.526100"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "ST HUBERTS ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.343500",
    Latitude: "-33.492800"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "UMINA BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.312400",
    Latitude: "-33.519100"
  },
  {
    Country: "Australia",
    Pcode: "2257",
    Locality: "WAGSTAFFE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.340100",
    Latitude: "-33.522500"
  },
  {
    Country: "Australia",
    Pcode: "2258",
    Locality: "FOUNTAINDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.400700",
    Latitude: "-33.346700"
  },
  {
    Country: "Australia",
    Pcode: "2258",
    Locality: "KANGY ANGY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.399600",
    Latitude: "-33.320100"
  },
  {
    Country: "Australia",
    Pcode: "2258",
    Locality: "OURIMBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.352500",
    Latitude: "-33.315100"
  },
  {
    Country: "Australia",
    Pcode: "2258",
    Locality: "PALM GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.303400",
    Latitude: "-33.319200"
  },
  {
    Country: "Australia",
    Pcode: "2258",
    Locality: "PALMDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.370400",
    Latitude: "-33.328700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "ALISON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WYONG",
    Category: "Delivery Area",
    Longitude: "151.398600",
    Latitude: "-33.265900"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "BUSHELLS RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.474400",
    Latitude: "-33.203200"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "CEDAR BRUSH CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.251900",
    Latitude: "-33.162700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "CHAIN VALLEY BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.572900",
    Latitude: "-33.163700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "CRANGAN BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.598000",
    Latitude: "-33.167400"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "DOORALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.369500",
    Latitude: "-33.176500"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "DURREN DURREN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.396200",
    Latitude: "-33.180700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "FRAZER PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.618800",
    Latitude: "-33.185800"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "FREEMANS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.593400",
    Latitude: "-33.200600"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "GWANDALAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.582000",
    Latitude: "-33.139500"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "HALLORAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.436300",
    Latitude: "-33.230900"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "HAMLYN TERRACE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.472900",
    Latitude: "-33.253200"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "JILLIBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.374100",
    Latitude: "-33.216100"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "KANWAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.483500",
    Latitude: "-33.267000"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "KIAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.444200",
    Latitude: "-33.206500"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "KINGFISHER SHORES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.556700",
    Latitude: "-33.170900"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "LAKE MUNMORAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.572200",
    Latitude: "-33.193700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "LEMON TREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.371900",
    Latitude: "-33.133000"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "LITTLE JILLIBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.363900",
    Latitude: "-33.233800"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "MANNERING PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.540200",
    Latitude: "-33.160300"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "MARDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.403200",
    Latitude: "-33.291600"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "MOONEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.620200",
    Latitude: "-33.169700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "POINT WOLSTONCROFT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.583600",
    Latitude: "-33.111300"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "RAVENSDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.291400",
    Latitude: "-33.121200"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "ROCKY POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.469300",
    Latitude: "-33.293400"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "SUMMERLAND POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.563900",
    Latitude: "-33.140800"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "TACOMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.458200",
    Latitude: "-33.283700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "TACOMA SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.455100",
    Latitude: "-33.293700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "TUGGERAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.425100",
    Latitude: "-33.306700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "TUGGERAWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.469300",
    Latitude: "-33.284700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WADALBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.465500",
    Latitude: "-33.272200"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WALLARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.459200",
    Latitude: "-33.225600"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WARNERVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.433100",
    Latitude: "-33.246200"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WATANOBBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.425100",
    Latitude: "-33.268100"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WOONGARRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.475200",
    Latitude: "-33.240300"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WYBUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.604700",
    Latitude: "-33.193200"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WYEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.463000",
    Latitude: "-33.172500"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WYEE POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.506400",
    Latitude: "-33.146100"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WYONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.431200",
    Latitude: "-33.278700"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WYONG CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.310100",
    Latitude: "-33.251400"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "WYONGAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.485400",
    Latitude: "-33.273100"
  },
  {
    Country: "Australia",
    Pcode: "2259",
    Locality: "YARRAMALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.262400",
    Latitude: "-33.211300"
  },
  {
    Country: "Australia",
    Pcode: "2260",
    Locality: "ERINA HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.413300",
    Latitude: "-33.427200"
  },
  {
    Country: "Australia",
    Pcode: "2315",
    Locality: "NELSON BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.135900",
    Latitude: "-32.736800"
  },
  {
    Country: "Australia",
    Pcode: "2315",
    Locality: "SHOAL BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.173800",
    Latitude: "-32.723800"
  },
  {
    Country: "Australia",
    Pcode: "2316",
    Locality: "ANNA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.074900",
    Latitude: "-32.766100"
  },
  {
    Country: "Australia",
    Pcode: "2316",
    Locality: "BOAT HARBOUR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.111900",
    Latitude: "-32.784500"
  },
  {
    Country: "Australia",
    Pcode: "2316",
    Locality: "BOBS FARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.002400",
    Latitude: "-32.778200"
  },
  {
    Country: "Australia",
    Pcode: "2316",
    Locality: "FISHERMANS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.094900",
    Latitude: "-32.785400"
  },
  {
    Country: "Australia",
    Pcode: "2316",
    Locality: "ONE MILE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.119100",
    Latitude: "-32.764700"
  },
  {
    Country: "Australia",
    Pcode: "2316",
    Locality: "TAYLORS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.065700",
    Latitude: "-32.737400"
  },
  {
    Country: "Australia",
    Pcode: "2317",
    Locality: "SALAMANDER BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.089300",
    Latitude: "-32.729300"
  },
  {
    Country: "Australia",
    Pcode: "2317",
    Locality: "SOLDIERS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.066300",
    Latitude: "-32.709300"
  },
  {
    Country: "Australia",
    Pcode: "2318",
    Locality: "CAMPVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.850600",
    Latitude: "-32.774600"
  },
  {
    Country: "Australia",
    Pcode: "2318",
    Locality: "FERODALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.842300",
    Latitude: "-32.704200"
  },
  {
    Country: "Australia",
    Pcode: "2318",
    Locality: "FULLERTON COVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.828700",
    Latitude: "-32.843000"
  },
  {
    Country: "Australia",
    Pcode: "2318",
    Locality: "MEDOWIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.890300",
    Latitude: "-32.739800"
  },
  {
    Country: "Australia",
    Pcode: "2318",
    Locality: "OYSTER COVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.948900",
    Latitude: "-32.741600"
  },
  {
    Country: "Australia",
    Pcode: "2318",
    Locality: "SALT ASH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.922000",
    Latitude: "-32.794400"
  },
  {
    Country: "Australia",
    Pcode: "2318",
    Locality: "WILLIAMTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.834600",
    Latitude: "-32.810900"
  },
  {
    Country: "Australia",
    Pcode: "2319",
    Locality: "LEMON TREE PASSAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.030800",
    Latitude: "-32.732200"
  },
  {
    Country: "Australia",
    Pcode: "2319",
    Locality: "MALLABULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.012700",
    Latitude: "-32.732300"
  },
  {
    Country: "Australia",
    Pcode: "2319",
    Locality: "TANILBA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.982300",
    Latitude: "-32.743000"
  },
  {
    Country: "Australia",
    Pcode: "2319",
    Locality: "TILLIGERRY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.979600",
    Latitude: "-32.763800"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "ABERGLASSLYN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.532600",
    Latitude: "-32.693500"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "ALLANDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.409600",
    Latitude: "-32.724600"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "ANAMBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.491800",
    Latitude: "-32.678900"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "BOLWARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.575100",
    Latitude: "-32.710700"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "BOLWARRA HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.585600",
    Latitude: "-32.691400"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "FARLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.506900",
    Latitude: "-32.736300"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "GLEN OAK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.714700",
    Latitude: "-32.606600"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "GOSFORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.489800",
    Latitude: "-32.649900"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "HILLSBOROUGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MAITLAND",
    Category: "Delivery Area",
    Longitude: "151.476400",
    Latitude: "-32.633200"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "HORSESHOE BEND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.567800",
    Latitude: "-32.735600"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "KEINBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.411600",
    Latitude: "-32.756200"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "LARGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.605400",
    Latitude: "-32.701000"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "LORN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.571800",
    Latitude: "-32.727400"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "LOUTH PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.550600",
    Latitude: "-32.766200"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "MAITLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.552900",
    Latitude: "-32.734100"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "MAITLAND NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "MAITLAND VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.552800",
    Latitude: "-32.675700"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "MELVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.521100",
    Latitude: "-32.677000"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "MINDARIBBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.569000",
    Latitude: "-32.649200"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "MOUNT DEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.539000",
    Latitude: "-32.741500"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "OAKHAMPTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.556200",
    Latitude: "-32.695300"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "OAKHAMPTON HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.550500",
    Latitude: "-32.709000"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "POKOLBIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.274600",
    Latitude: "-32.753500"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "ROSEBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.514200",
    Latitude: "-32.639000"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "ROTHBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.341900",
    Latitude: "-32.709500"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "RUTHERFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.514500",
    Latitude: "-32.711500"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "SOUTH MAITLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.564200",
    Latitude: "-32.746000"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "TELARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.533900",
    Latitude: "-32.726400"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "WALLALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.641200",
    Latitude: "-32.693700"
  },
  {
    Country: "Australia",
    Pcode: "2320",
    Locality: "WINDELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.479800",
    Latitude: "-32.691700"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "BERRY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.661200",
    Latitude: "-32.742400"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "BUTTERWICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.648300",
    Latitude: "-32.650700"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "CLARENCE TOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.762300",
    Latitude: "-32.592200"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "CLIFTLEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.518600",
    Latitude: "-32.789000"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "DUCKENFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.682900",
    Latitude: "-32.738100"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "DUNS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.660400",
    Latitude: "-32.609700"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "GILLIESTON HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.519800",
    Latitude: "-32.760900"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "GLEN MARTIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.843500",
    Latitude: "-32.531900"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "GLEN WILLIAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.795700",
    Latitude: "-32.534500"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "HARPERS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.410000",
    Latitude: "-32.706400"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "HEDDON GRETA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.506500",
    Latitude: "-32.805300"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "HINTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.669300",
    Latitude: "-32.722700"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "LOCHINVAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.455100",
    Latitude: "-32.713700"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "LUSKINTYRE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.429100",
    Latitude: "-32.665300"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "MORPETH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.627200",
    Latitude: "-32.731300"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "LECONFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.393100",
    Latitude: "-32.645900"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "LOWER BELFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.294900",
    Latitude: "-32.620200"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "NORTH ROTHBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.343700",
    Latitude: "-32.693300"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "STANHOPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.406800",
    Latitude: "-32.584200"
  },
  {
    Country: "Australia",
    Pcode: "2336",
    Locality: "ABERDEEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SCONE",
    Category: "Delivery Area",
    Longitude: "150.904200",
    Latitude: "-32.170400"
  },
  {
    Country: "Australia",
    Pcode: "2336",
    Locality: "DARTBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.808500",
    Latitude: "-32.145100"
  },
  {
    Country: "Australia",
    Pcode: "2336",
    Locality: "DAVIS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.205100",
    Latitude: "-32.180300"
  },
  {
    Country: "Australia",
    Pcode: "2336",
    Locality: "ROSSGOLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.736700",
    Latitude: "-32.150400"
  },
  {
    Country: "Australia",
    Pcode: "2336",
    Locality: "ROUCHEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.984700",
    Latitude: "-32.146300"
  },
  {
    Country: "Australia",
    Pcode: "2336",
    Locality: "ROUCHEL BROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.089500",
    Latitude: "-32.179700"
  },
  {
    Country: "Australia",
    Pcode: "2336",
    Locality: "UPPER DARTBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.725500",
    Latitude: "-31.934300"
  },
  {
    Country: "Australia",
    Pcode: "2336",
    Locality: "UPPER ROUCHEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.178300",
    Latitude: "-32.099000"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "BELLTREES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.112700",
    Latitude: "-31.950900"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "BRAWBOY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.624100",
    Latitude: "-31.952900"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "BUNNAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.578900",
    Latitude: "-32.057700"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "DRY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.816500",
    Latitude: "-31.891600"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "ELLERSTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.330600",
    Latitude: "-31.771800"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "GLENBAWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.020000",
    Latitude: "-32.076500"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "GLENROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.391100",
    Latitude: "-31.655600"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "GUNDY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.981400",
    Latitude: "-31.988700"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "KARS SPRINGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.543100",
    Latitude: "-31.927700"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "MIDDLE BROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.790400",
    Latitude: "-31.968800"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "MOOBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.800000",
    Latitude: "-32.062800"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "MOONAN BROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.355000",
    Latitude: "-31.961600"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "MOONAN FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.223000",
    Latitude: "-31.897000"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "MURULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.864500",
    Latitude: "-31.835800"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "OMADALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.307200",
    Latitude: "-31.882100"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "OWENS GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.702400",
    Latitude: "-32.061800"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "PAGES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.224600",
    Latitude: "-31.720600"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "PARKVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.882200",
    Latitude: "-31.972000"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "SCONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.846400",
    Latitude: "-32.075800"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "SEGENHOE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.922500",
    Latitude: "-32.065900"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "STEWARTS BROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.313700",
    Latitude: "-32.033200"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "TOMALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.510200",
    Latitude: "-31.831400"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "WAVERLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.092700",
    Latitude: "-31.868300"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "WINGEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.923700",
    Latitude: "-31.908100"
  },
  {
    Country: "Australia",
    Pcode: "2337",
    Locality: "WOOLOOMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.220100",
    Latitude: "-32.007900"
  },
  {
    Country: "Australia",
    Pcode: "2338",
    Locality: "ARDGLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.783500",
    Latitude: "-31.729100"
  },
  {
    Country: "Australia",
    Pcode: "2338",
    Locality: "BLANDFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.894400",
    Latitude: "-31.752200"
  },
  {
    Country: "Australia",
    Pcode: "2338",
    Locality: "CRAWNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.079800",
    Latitude: "-31.584900"
  },
  {
    Country: "Australia",
    Pcode: "2338",
    Locality: "GREEN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.033300",
    Latitude: "-31.791400"
  },
  {
    Country: "Australia",
    Pcode: "2338",
    Locality: "MURRURUNDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.830100",
    Latitude: "-31.776700"
  },
  {
    Country: "Australia",
    Pcode: "2338",
    Locality: "PAGES RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.747100",
    Latitude: "-31.801100"
  },
  {
    Country: "Australia",
    Pcode: "2338",
    Locality: "SANDY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MURRURUNDI",
    Category: "Delivery Area",
    Longitude: "150.990700",
    Latitude: "-31.820900"
  },
  {
    Country: "Australia",
    Pcode: "2338",
    Locality: "SCOTTS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.954200",
    Latitude: "-31.715400"
  },
  {
    Country: "Australia",
    Pcode: "2338",
    Locality: "TIMOR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.094000",
    Latitude: "-31.719100"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "BIG JACKS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.610400",
    Latitude: "-31.764600"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "BRAEFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.701700",
    Latitude: "-31.571100"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "CATTLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.248800",
    Latitude: "-31.770100"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "CHILCOTTS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.835300",
    Latitude: "-31.668400"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "LITTLE JACKS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.532400",
    Latitude: "-31.797100"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "MACDONALDS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.441600",
    Latitude: "-31.811400"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "PARRAWEENA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.435600",
    Latitude: "-31.747600"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "WARRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.668400",
    Latitude: "-31.663900"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "WARRAH CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.679100",
    Latitude: "-31.769500"
  },
  {
    Country: "Australia",
    Pcode: "2339",
    Locality: "WILLOW TREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.720300",
    Latitude: "-31.645800"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "APPLEBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.823700",
    Latitude: "-30.965100"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "BARRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TAMWORTH",
    Category: "Delivery Area",
    Longitude: "151.290900",
    Latitude: "-31.601800"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "BECTIVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.727000",
    Latitude: "-31.004100"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "BITHRAMERE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.779000",
    Latitude: "-31.132000"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "BOWLING ALLEY POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.141700",
    Latitude: "-31.373400"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "CALALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.977600",
    Latitude: "-31.168700"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "CARROLL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.445200",
    Latitude: "-31.003700"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "DARUKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.971500",
    Latitude: "-31.041300"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "DUNCANS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.205300",
    Latitude: "-31.389400"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "DUNGOWAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.153000",
    Latitude: "-31.219000"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "MOUNT MITCHELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.846200",
    Latitude: "-29.942300"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "NEW VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.337400",
    Latitude: "-30.054900"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "OBAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.870900",
    Latitude: "-30.099400"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "SOUTH GUYRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.673400",
    Latitude: "-30.239400"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "TENTERDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.415800",
    Latitude: "-30.113400"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "THE BASIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.276200",
    Latitude: "-30.193500"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "THE GULF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.258600",
    Latitude: "-30.020400"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "TUBBAMURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.784900",
    Latitude: "-30.099700"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "WANDSWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.463900",
    Latitude: "-30.021600"
  },
  {
    Country: "Australia",
    Pcode: "2369",
    Locality: "OLD MILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.199300",
    Latitude: "-29.898200"
  },
  {
    Country: "Australia",
    Pcode: "2369",
    Locality: "STANNIFER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.229100",
    Latitude: "-29.875500"
  },
  {
    Country: "Australia",
    Pcode: "2369",
    Locality: "TINGHA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.238300",
    Latitude: "-29.974200"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "BALD NOB",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.992300",
    Latitude: "-29.678600"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "DIEHARD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.087800",
    Latitude: "-29.700900"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "DUNDEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.916000",
    Latitude: "-29.571600"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "FURRACABAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.658700",
    Latitude: "-29.820500"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "GIBRALTAR RANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.257000",
    Latitude: "-29.617400"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "GLEN ELGIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.120300",
    Latitude: "-29.567300"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "GLEN INNES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.704500",
    Latitude: "-29.718900"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "KINGSGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.091500",
    Latitude: "-29.797100"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "KINGSLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.466400",
    Latitude: "-29.702500"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "KOOKABOOKRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.074900",
    Latitude: "-30.020700"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "LAMBS VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GLEN INNES",
    Category: "Delivery Area",
    Longitude: "151.792800",
    Latitude: "-29.815100"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "MATHESON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.565400",
    Latitude: "-29.752400"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "MOGGS SWAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.090900",
    Latitude: "-29.930700"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "MOOGEM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.246700",
    Latitude: "-29.457100"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "MORVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GLEN INNES",
    Category: "Delivery Area",
    Longitude: "152.164000",
    Latitude: "-29.421500"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "NEWTON BOYD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.340800",
    Latitude: "-29.728800"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "PINKETT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.959400",
    Latitude: "-29.892600"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "RANGERS VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.763000",
    Latitude: "-29.550200"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "RED RANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.906900",
    Latitude: "-29.795200"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "REDDESTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.668300",
    Latitude: "-29.583500"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "SHANNON VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.882200",
    Latitude: "-29.695100"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "STONEHENGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.724600",
    Latitude: "-29.829800"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "SWAN VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.451000",
    Latitude: "-29.772300"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "WELLINGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.540700",
    Latitude: "-29.572200"
  },
  {
    Country: "Australia",
    Pcode: "2370",
    Locality: "YARROWFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.751500",
    Latitude: "-29.640200"
  },
  {
    Country: "Australia",
    Pcode: "2371",
    Locality: "CAPOOMPETA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.066000",
    Latitude: "-29.388100"
  },
  {
    Country: "Australia",
    Pcode: "2371",
    Locality: "DEEPWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.946700",
    Latitude: "-29.444200"
  },
  {
    Country: "Australia",
    Pcode: "2371",
    Locality: "EMMAVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.549500",
    Latitude: "-29.366100"
  },
  {
    Country: "Australia",
    Pcode: "2371",
    Locality: "ROCKY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.344600",
    Latitude: "-29.271300"
  },
  {
    Country: "Australia",
    Pcode: "2371",
    Locality: "STANNUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.781000",
    Latitude: "-29.338600"
  },
  {
    Country: "Australia",
    Pcode: "2371",
    Locality: "TORRINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.676300",
    Latitude: "-29.294600"
  },
  {
    Country: "Australia",
    Pcode: "2371",
    Locality: "WELLINGTON VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.727000",
    Latitude: "-29.418400"
  },
  {
    Country: "Australia",
    Pcode: "2371",
    Locality: "YELLOW DAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.394700",
    Latitude: "-29.388200"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "BACK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TENTERFIELD",
    Category: "Delivery Area",
    Longitude: "151.705300",
    Latitude: "-28.934100"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "BOLIVIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.880500",
    Latitude: "-29.272200"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "BOOKOOKOORARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.100700",
    Latitude: "-28.783100"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "BOONOO BOONOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.117900",
    Latitude: "-28.910500"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "BOOROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.261100",
    Latitude: "-28.798700"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "CARROLLS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.090100",
    Latitude: "-28.832300"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "CULLENDORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.151500",
    Latitude: "-28.481700"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "DUMARESQ VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.481300",
    Latitude: "-29.168900"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "FOREST LAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.118400",
    Latitude: "-29.242200"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "LISTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.097100",
    Latitude: "-28.618900"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "MOLE RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.743300",
    Latitude: "-29.092100"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "RIVERTREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.282400",
    Latitude: "-28.655900"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "ROCKY RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TENTERFIELD",
    Category: "Delivery Area",
    Longitude: "152.314800",
    Latitude: "-29.144100"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "SANDY FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.046800",
    Latitude: "-29.249900"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "SANDY HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.257600",
    Latitude: "-28.947900"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "SILENT GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.665300",
    Latitude: "-29.157500"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "TARBAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.878900",
    Latitude: "-28.972400"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "TENTERFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.039400",
    Latitude: "-29.093000"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "TIMBARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.237000",
    Latitude: "-29.062500"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "WILLSONS DOWNFALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.127100",
    Latitude: "-28.702300"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "WOODSIDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.816600",
    Latitude: "-29.061400"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "WARRANULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.126400",
    Latitude: "-32.185300"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "WILLINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.269700",
    Latitude: "-32.179300"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "WOOTTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.325600",
    Latitude: "-32.304700"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "YAGON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.463300",
    Latitude: "-32.449000"
  },
  {
    Country: "Australia",
    Pcode: "2424",
    Locality: "CAFFREYS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.062600",
    Latitude: "-31.791500"
  },
  {
    Country: "Australia",
    Pcode: "2424",
    Locality: "CELLS RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.072500",
    Latitude: "-31.650800"
  },
  {
    Country: "Australia",
    Pcode: "2424",
    Locality: "COOPLACURRIPA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.900800",
    Latitude: "-31.587400"
  },
  {
    Country: "Australia",
    Pcode: "2424",
    Locality: "CUNDLE FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.993600",
    Latitude: "-31.812900"
  },
  {
    Country: "Australia",
    Pcode: "2424",
    Locality: "KNORRIT FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.116800",
    Latitude: "-31.839600"
  },
  {
    Country: "Australia",
    Pcode: "2424",
    Locality: "KNORRIT FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.119700",
    Latitude: "-31.780300"
  },
  {
    Country: "Australia",
    Pcode: "2424",
    Locality: "MOUNT GEORGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.196100",
    Latitude: "-31.869300"
  },
  {
    Country: "Australia",
    Pcode: "2424",
    Locality: "NUMBER ONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.059300",
    Latitude: "-31.727200"
  },
  {
    Country: "Australia",
    Pcode: "2424",
    Locality: "TIRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.089300",
    Latitude: "-31.854900"
  },
  {
    Country: "Australia",
    Pcode: "2425",
    Locality: "ALLWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.945500",
    Latitude: "-32.544400"
  },
  {
    Country: "Australia",
    Pcode: "2425",
    Locality: "BOORAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.971800",
    Latitude: "-32.463400"
  },
  {
    Country: "Australia",
    Pcode: "2425",
    Locality: "GIRVAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.059900",
    Latitude: "-32.471300"
  },
  {
    Country: "Australia",
    Pcode: "2425",
    Locality: "STROUD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.993800",
    Latitude: "-32.356800"
  },
  {
    Country: "Australia",
    Pcode: "2425",
    Locality: "THE BRANCH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.037400",
    Latitude: "-32.553600"
  },
  {
    Country: "Australia",
    Pcode: "2425",
    Locality: "WASHPOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GREAT LAKES",
    Category: "Delivery Area",
    Longitude: "151.920100",
    Latitude: "-32.371700"
  },
  {
    Country: "Australia",
    Pcode: "2426",
    Locality: "COOPERNOOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.614000",
    Latitude: "-31.835200"
  },
  {
    Country: "Australia",
    Pcode: "2426",
    Locality: "LANGLEY VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.571600",
    Latitude: "-31.791900"
  },
  {
    Country: "Australia",
    Pcode: "2426",
    Locality: "MOTO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.564800",
    Latitude: "-31.835200"
  },
  {
    Country: "Australia",
    Pcode: "2427",
    Locality: "CROWDY HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.723200",
    Latitude: "-31.841300"
  },
  {
    Country: "Australia",
    Pcode: "2427",
    Locality: "HARRINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.680500",
    Latitude: "-31.856800"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "BLUEYS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.531300",
    Latitude: "-32.353800"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "BOOMERANG BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.537000",
    Latitude: "-32.340800"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "BOOTI BOOTI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.522400",
    Latitude: "-32.314100"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "CHARLOTTE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.507200",
    Latitude: "-32.352800"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "COOMBA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.439800",
    Latitude: "-32.270200"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "COOMBA PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.456200",
    Latitude: "-32.239200"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "DARAWANK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.503400",
    Latitude: "-32.112900"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "ELIZABETH BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.538400",
    Latitude: "-32.332100"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "FORSTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.532900",
    Latitude: "-32.223600"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "FORSTER SHOPPING VILLAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "GREEN POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "FORSTER",
    Category: "Delivery Area",
    Longitude: "152.519000",
    Latitude: "-32.248600"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "PACIFIC PALMS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "SANDBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.524100",
    Latitude: "-32.379600"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "SHALLOW BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.421200",
    Latitude: "-32.222700"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "SMITHS LAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.495300",
    Latitude: "-32.378400"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "TARBUCK BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.477600",
    Latitude: "-32.366300"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "TIONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.520800",
    Latitude: "-32.299800"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "TUNCURRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.485000",
    Latitude: "-32.159700"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "WALLINGAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.436100",
    Latitude: "-32.290100"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "WALLIS LAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.485400",
    Latitude: "-32.240100"
  },
  {
    Country: "Australia",
    Pcode: "2428",
    Locality: "WHOOTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.480400",
    Latitude: "-32.313500"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "BOBIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.285600",
    Latitude: "-31.698800"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "BOORGANNA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.385700",
    Latitude: "-31.621600"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "BUCCA WAUKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.151600",
    Latitude: "-32.104000"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "BULGA FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.190200",
    Latitude: "-31.611900"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "BUNYAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.178700",
    Latitude: "-32.177100"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "BURRELL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.301300",
    Latitude: "-31.949600"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "CAPARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.233100",
    Latitude: "-31.721700"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "CEDAR PARTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.383800",
    Latitude: "-31.812900"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "COMBOYNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.471400",
    Latitude: "-31.595200"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "DINGO FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.144900",
    Latitude: "-31.693500"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "DOLLYS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.299100",
    Latitude: "-31.864600"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "DYERS CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.314400",
    Latitude: "-32.078600"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "ELANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.287800",
    Latitude: "-31.583300"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "FIREFLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.234700",
    Latitude: "-32.084600"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "INNES VIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.394800",
    Latitude: "-31.592200"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "KARAAK FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.301500",
    Latitude: "-31.916000"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "KHATAMBUHL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.989700",
    Latitude: "-31.751200"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "KILLABAKH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.409200",
    Latitude: "-31.719400"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "KILLAWARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.286700",
    Latitude: "-31.894600"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "KIMBRIKI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.214500",
    Latitude: "-31.921800"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "KIPPAXS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.322400",
    Latitude: "-31.653600"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "PEMBROOKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.742300",
    Latitude: "-31.377000"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "PIPECLAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.583800",
    Latitude: "-31.440300"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "RAWDON ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.783700",
    Latitude: "-31.417200"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "REDBANK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.733600",
    Latitude: "-31.426100"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "ROSEWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WAUCHOPE",
    Category: "Delivery Area",
    Longitude: "152.687500",
    Latitude: "-31.457600"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "SANCROX",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.794600",
    Latitude: "-31.439400"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "TOMS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.382200",
    Latitude: "-31.546400"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "UPPER PAPPINBARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.446200",
    Latitude: "-31.276700"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "WAUCHOPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.732500",
    Latitude: "-31.479200"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "WERRIKIMBE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.222100",
    Latitude: "-31.253700"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "YARRAS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WAUCHOPE",
    Category: "Delivery Area",
    Longitude: "152.359900",
    Latitude: "-31.435700"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "YIPPIN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.713200",
    Latitude: "-31.455400"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "BAKERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MACKSVILLE",
    Category: "Delivery Area",
    Longitude: "152.711100",
    Latitude: "-30.827500"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "BURRAPINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.642700",
    Latitude: "-30.718100"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "CONGARINNI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.871300",
    Latitude: "-30.753200"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "CONGARINNI NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.891200",
    Latitude: "-30.685500"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "DONNELLYVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.915400",
    Latitude: "-30.755500"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "GUMMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.970500",
    Latitude: "-30.718500"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "MACKSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.916600",
    Latitude: "-30.720700"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "NEWEE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.942500",
    Latitude: "-30.631900"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "NORTH MACKSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.942700",
    Latitude: "-30.688500"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "SCOTTS HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.995500",
    Latitude: "-30.679600"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "TALARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.835000",
    Latitude: "-30.701100"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "TAYLORS ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.747400",
    Latitude: "-30.763600"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "THUMB CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.575000",
    Latitude: "-30.663200"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "UPPER TAYLORS ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.673200",
    Latitude: "-30.776700"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "UTUNGUN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.828000",
    Latitude: "-30.747400"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "WARRELL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.902600",
    Latitude: "-30.775200"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "WAY WAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.946100",
    Latitude: "-30.786900"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "WIRRIMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.923600",
    Latitude: "-30.674600"
  },
  {
    Country: "Australia",
    Pcode: "2447",
    Locality: "YARRANBELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.770100",
    Latitude: "-30.723700"
  },
  {
    Country: "Australia",
    Pcode: "2448",
    Locality: "HYLAND PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.998600",
    Latitude: "-30.611900"
  },
  {
    Country: "Australia",
    Pcode: "2448",
    Locality: "NAMBUCCA HEADS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.984600",
    Latitude: "-30.643500"
  },
  {
    Country: "Australia",
    Pcode: "2448",
    Locality: "VALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.947900",
    Latitude: "-30.583300"
  },
  {
    Country: "Australia",
    Pcode: "2448",
    Locality: "VALLA BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.006700",
    Latitude: "-30.593500"
  },
  {
    Country: "Australia",
    Pcode: "2449",
    Locality: "ARGENTS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.729500",
    Latitude: "-30.608800"
  },
  {
    Country: "Australia",
    Pcode: "2449",
    Locality: "BOWRAVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.862300",
    Latitude: "-30.635700"
  },
  {
    Country: "Australia",
    Pcode: "2449",
    Locality: "BUCKRA BENDINNI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.685900",
    Latitude: "-30.645800"
  },
  {
    Country: "Australia",
    Pcode: "2449",
    Locality: "GIRRALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.639800",
    Latitude: "-30.562900"
  },
  {
    Country: "Australia",
    Pcode: "2449",
    Locality: "KENNAICLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.814900",
    Latitude: "-30.524400"
  },
  {
    Country: "Australia",
    Pcode: "2449",
    Locality: "KILLIEKRANKIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.593600",
    Latitude: "-30.569600"
  },
  {
    Country: "Australia",
    Pcode: "2449",
    Locality: "MISSABOTTI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.792700",
    Latitude: "-30.571500"
  },
  {
    Country: "Australia",
    Pcode: "2449",
    Locality: "SOUTH ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BOWRAVILLE",
    Category: "Delivery Area",
    Longitude: "152.700400",
    Latitude: "-30.696500"
  },
  {
    Country: "Australia",
    Pcode: "2449",
    Locality: "TEWINGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.902400",
    Latitude: "-30.644800"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "BOAMBEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.059100",
    Latitude: "-30.332800"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "BONVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.020300",
    Latitude: "-30.377900"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "BROOKLANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.874700",
    Latitude: "-30.297400"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "BUCCA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.090300",
    Latitude: "-30.131700"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "COFFS HARBOUR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.107300",
    Latitude: "-30.308500"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "COFFS HARBOUR DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "COFFS HARBOUR JETTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "COFFS HARBOUR PLAZA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "CORAMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.022000",
    Latitude: "-30.198100"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "GLENREAGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.958500",
    Latitude: "-30.080500"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "KARANGI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.052600",
    Latitude: "-30.241300"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "KORORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.115600",
    Latitude: "-30.250200"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "LOWANNA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.888400",
    Latitude: "-30.176100"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "MOONEE BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.152000",
    Latitude: "-30.195500"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "NANA GLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.011700",
    Latitude: "-30.137800"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "NORTH BOAMBEE VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.069600",
    Latitude: "-30.311400"
  },
  {
    Country: "Australia",
    Pcode: "0850",
    Locality: "KATHERINE SOUTH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.262100",
    Latitude: "-14.475000"
  },
  {
    Country: "Australia",
    Pcode: "0850",
    Locality: "LANSDOWNE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.384200",
    Latitude: "-14.421000"
  },
  {
    Country: "Australia",
    Pcode: "0851",
    Locality: "KATHERINE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "ARNOLD",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.383400",
    Latitude: "-16.140700"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "BAINES",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.690200",
    Latitude: "-15.933200"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "BARUNGA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.866900",
    Latitude: "-14.528700"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "BESWICK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.116800",
    Latitude: "-14.557100"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "BESWICK CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.071200",
    Latitude: "-14.489100"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "BINJARI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.183800",
    Latitude: "-14.551700"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "BIRDUM",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.151200",
    Latitude: "-16.232000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "BRADSHAW",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.483500",
    Latitude: "-15.075300"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "BUCHANAN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.715400",
    Latitude: "-17.705600"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "BULMAN WEEMOL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.313500",
    Latitude: "-13.654300"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "CRESWELL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.912400",
    Latitude: "-17.690500"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "DAGURAGU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.805000",
    Latitude: "-17.419900"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "DALY WATERS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.378800",
    Latitude: "-16.262100"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "DELAMERE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.603200",
    Latitude: "-15.329500"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "EDITH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.923000",
    Latitude: "-14.062200"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "ELSEY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.387700",
    Latitude: "-15.131100"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "FLORINA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.911400",
    Latitude: "-14.518100"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "FLYING FOX",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.121100",
    Latitude: "-14.393000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "GREGORY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.354300",
    Latitude: "-16.285200"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "GULUNG MARDRULK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.577200",
    Latitude: "-13.563200"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "GURINDJI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.140900",
    Latitude: "-18.156000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "HELEN SPRINGS STATION",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "JILKMINGGAN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.294600",
    Latitude: "-14.961700"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "KALKARINDJI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.833300",
    Latitude: "-17.448300"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "KATHERINE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BAGS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "LAJAMANU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.507700",
    Latitude: "-18.456500"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "LARRIMAH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.221200",
    Latitude: "-15.607200"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "LIMMEN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.733100",
    Latitude: "-14.874200"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "MANBULLOO",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.248300",
    Latitude: "-14.809000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "MARANBOY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "MATARANKA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.043800",
    Latitude: "-14.954900"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "MCARTHUR",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.068100",
    Latitude: "-16.172100"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "MINIYERI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.083200",
    Latitude: "-15.227700"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "NGUKURR",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.743200",
    Latitude: "-14.732900"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "NITMILUK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.625600",
    Latitude: "-14.155700"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "NUTWOOD DOWNS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "PELLEW ISLANDS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.816400",
    Latitude: "-15.679000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "PIGEON HOLE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.215100",
    Latitude: "-16.795100"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "ROBINSON RIVER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.980800",
    Latitude: "-16.759400"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "STURT PLATEAU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.653600",
    Latitude: "-15.625000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "TANAMI EAST",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.808400",
    Latitude: "-18.429700"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "TIMBER CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.492600",
    Latitude: "-15.654000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "TOP SPRINGS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.799600",
    Latitude: "-16.548400"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "URALLA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.297100",
    Latitude: "-14.487900"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "VENN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.559000",
    Latitude: "-14.548100"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "VICTORIA RIVER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.519300",
    Latitude: "-16.638700"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "WARUMUNGU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.835700",
    Latitude: "-20.137900"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "WAVE HILL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "WILTON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.894100",
    Latitude: "-14.408800"
  },
  {
    Country: "Australia",
    Pcode: "0852",
    Locality: "YARRALIN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.839900",
    Latitude: "-16.440300"
  },
  {
    Country: "Australia",
    Pcode: "0853",
    Locality: "TINDAL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.380200",
    Latitude: "-14.525800"
  },
  {
    Country: "Australia",
    Pcode: "0854",
    Locality: "BORROLOOLA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.308600",
    Latitude: "-16.071100"
  },
  {
    Country: "Australia",
    Pcode: "0854",
    Locality: "KING ASH BAY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0860",
    Locality: "TENNANT CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.191700",
    Latitude: "-19.659000"
  },
  {
    Country: "Australia",
    Pcode: "0861",
    Locality: "TENNANT CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0862",
    Locality: "CALVERT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.298200",
    Latitude: "-16.802200"
  },
  {
    Country: "Australia",
    Pcode: "0862",
    Locality: "ELLIOTT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.524800",
    Latitude: "-17.552400"
  },
  {
    Country: "Australia",
    Pcode: "0862",
    Locality: "NEWCASTLE WATERS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.407100",
    Latitude: "-17.364500"
  },
  {
    Country: "Australia",
    Pcode: "0862",
    Locality: "NICHOLSON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.218500",
    Latitude: "-18.503800"
  },
  {
    Country: "Australia",
    Pcode: "0862",
    Locality: "PAMAYU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.904000",
    Latitude: "-17.601300"
  },
  {
    Country: "Australia",
    Pcode: "0862",
    Locality: "RENNER SPRINGS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0862",
    Locality: "TABLELANDS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.205900",
    Latitude: "-19.026400"
  },
  {
    Country: "Australia",
    Pcode: "1480",
    Locality: "KINGSGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1481",
    Locality: "HURSTVILLE BC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1485",
    Locality: "KOGARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1490",
    Locality: "MIRANDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1495",
    Locality: "CARINGBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1499",
    Locality: "SUTHERLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1515",
    Locality: "WEST CHATSWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1560",
    Locality: "NORTHBRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1565",
    Locality: "MILSONS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1570",
    Locality: "ARTARMON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1585",
    Locality: "CROWS NEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1590",
    Locality: "ST LEONARDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1595",
    Locality: "LANE COVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1630",
    Locality: "HORNSBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1635",
    Locality: "HORNSBY WESTFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1640",
    Locality: "FRENCHS FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1655",
    Locality: "MANLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1660",
    Locality: "MONA VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1670",
    Locality: "NORTH RYDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1670",
    Locality: "NORTH RYDE BC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1675",
    Locality: "GLADESVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1680",
    Locality: "RYDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1685",
    Locality: "WEST RYDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1700",
    Locality: "ERMINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1710",
    Locality: "EPPING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1715",
    Locality: "PENNANT HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1730",
    Locality: "SEVEN HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1750",
    Locality: "NORTH PARRAMATTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1755",
    Locality: "BAULKHAM HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1765",
    Locality: "CASTLE HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1790",
    Locality: "ST MARYS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1800",
    Locality: "ASHFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1805",
    Locality: "BURWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1811",
    Locality: "SILVERWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1825",
    Locality: "LIDCOMBE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1835",
    Locality: "AUBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1851",
    Locality: "WETHERILL PARK DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1860",
    Locality: "FAIRFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1871",
    Locality: "LIVERPOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1875",
    Locality: "MOOREBANK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1885",
    Locality: "BANKSTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1890",
    Locality: "INGLEBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "1891",
    Locality: "MILPERRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2000",
    Locality: "BARANGAROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.201500",
    Latitude: "-33.861400"
  },
  {
    Country: "Australia",
    Pcode: "2000",
    Locality: "DAWES POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.206900",
    Latitude: "-33.856500"
  },
  {
    Country: "Australia",
    Pcode: "2000",
    Locality: "HAYMARKET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.204500",
    Latitude: "-33.881400"
  },
  {
    Country: "Australia",
    Pcode: "2000",
    Locality: "MILLERS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.204200",
    Latitude: "-33.859800"
  },
  {
    Country: "Australia",
    Pcode: "2000",
    Locality: "PARLIAMENT HOUSE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SYDNEY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2000",
    Locality: "SYDNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.209900",
    Latitude: "-33.869700"
  },
  {
    Country: "Australia",
    Pcode: "2000",
    Locality: "SYDNEY SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2000",
    Locality: "THE ROCKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SYDNEY",
    Category: "Delivery Area",
    Longitude: "151.208000",
    Latitude: "-33.859200"
  },
  {
    Country: "Australia",
    Pcode: "2001",
    Locality: "SYDNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GPO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2002",
    Locality: "WORLD SQUARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2004",
    Locality: "ALEXANDRIA MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2004",
    Locality: "EASTERN SUBURBS MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2006",
    Locality: "THE UNIVERSITY OF SYDNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2089",
    Locality: "NEUTRAL BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.218100",
    Latitude: "-33.835000"
  },
  {
    Country: "Australia",
    Pcode: "2089",
    Locality: "NEUTRAL BAY JUNCTION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2090",
    Locality: "CREMORNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.225100",
    Latitude: "-33.829600"
  },
  {
    Country: "Australia",
    Pcode: "2090",
    Locality: "CREMORNE JUNCTION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2090",
    Locality: "CREMORNE POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.228300",
    Latitude: "-33.841600"
  },
  {
    Country: "Australia",
    Pcode: "2092",
    Locality: "SEAFORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.242800",
    Latitude: "-33.794600"
  },
  {
    Country: "Australia",
    Pcode: "2093",
    Locality: "BALGOWLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.261100",
    Latitude: "-33.794500"
  },
  {
    Country: "Australia",
    Pcode: "2093",
    Locality: "BALGOWLAH HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.265500",
    Latitude: "-33.807900"
  },
  {
    Country: "Australia",
    Pcode: "2093",
    Locality: "CLONTARF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.254000",
    Latitude: "-33.807900"
  },
  {
    Country: "Australia",
    Pcode: "2093",
    Locality: "MANLY VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.265500",
    Latitude: "-33.783100"
  },
  {
    Country: "Australia",
    Pcode: "2093",
    Locality: "NORTH BALGOWLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.252400",
    Latitude: "-33.786900"
  },
  {
    Country: "Australia",
    Pcode: "2094",
    Locality: "FAIRLIGHT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.273900",
    Latitude: "-33.794000"
  },
  {
    Country: "Australia",
    Pcode: "2095",
    Locality: "MANLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.293600",
    Latitude: "-33.803300"
  },
  {
    Country: "Australia",
    Pcode: "2095",
    Locality: "MANLY EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2096",
    Locality: "CURL CURL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.290800",
    Latitude: "-33.770200"
  },
  {
    Country: "Australia",
    Pcode: "2096",
    Locality: "FRESHWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.284200",
    Latitude: "-33.776000"
  },
  {
    Country: "Australia",
    Pcode: "2096",
    Locality: "QUEENSCLIFF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.285200",
    Latitude: "-33.782800"
  },
  {
    Country: "Australia",
    Pcode: "2097",
    Locality: "COLLAROY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.303300",
    Latitude: "-33.739500"
  },
  {
    Country: "Australia",
    Pcode: "2097",
    Locality: "COLLAROY BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2097",
    Locality: "COLLAROY PLATEAU",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.289500",
    Latitude: "-33.729800"
  },
  {
    Country: "Australia",
    Pcode: "2097",
    Locality: "WHEELER HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.281400",
    Latitude: "-33.728100"
  },
  {
    Country: "Australia",
    Pcode: "2099",
    Locality: "CROMER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.268300",
    Latitude: "-33.730000"
  },
  {
    Country: "Australia",
    Pcode: "2099",
    Locality: "DEE WHY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.289000",
    Latitude: "-33.751000"
  },
  {
    Country: "Australia",
    Pcode: "2099",
    Locality: "NARRAWEENA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.272200",
    Latitude: "-33.749800"
  },
  {
    Country: "Australia",
    Pcode: "2099",
    Locality: "NORTH CURL CURL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.290900",
    Latitude: "-33.763200"
  },
  {
    Country: "Australia",
    Pcode: "2100",
    Locality: "ALLAMBIE HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.248600",
    Latitude: "-33.769600"
  },
  {
    Country: "Australia",
    Pcode: "2100",
    Locality: "BEACON HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.259300",
    Latitude: "-33.752200"
  },
  {
    Country: "Australia",
    Pcode: "2100",
    Locality: "BROOKVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.269800",
    Latitude: "-33.762600"
  },
  {
    Country: "Australia",
    Pcode: "2100",
    Locality: "NORTH MANLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.270900",
    Latitude: "-33.776100"
  },
  {
    Country: "Australia",
    Pcode: "2100",
    Locality: "OXFORD FALLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.241200",
    Latitude: "-33.730700"
  },
  {
    Country: "Australia",
    Pcode: "2100",
    Locality: "WARRINGAH MALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2101",
    Locality: "ELANORA HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.272000",
    Latitude: "-33.700000"
  },
  {
    Country: "Australia",
    Pcode: "2101",
    Locality: "INGLESIDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.258100",
    Latitude: "-33.689400"
  },
  {
    Country: "Australia",
    Pcode: "2101",
    Locality: "NARRABEEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.281400",
    Latitude: "-33.713700"
  },
  {
    Country: "Australia",
    Pcode: "2101",
    Locality: "NORTH NARRABEEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.287900",
    Latitude: "-33.702100"
  },
  {
    Country: "Australia",
    Pcode: "2102",
    Locality: "WARRIEWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.294000",
    Latitude: "-33.689400"
  },
  {
    Country: "Australia",
    Pcode: "2102",
    Locality: "WARRIEWOOD DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2102",
    Locality: "WARRIEWOOD SHOPPING SQUARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2103",
    Locality: "MONA VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.303000",
    Latitude: "-33.677000"
  },
  {
    Country: "Australia",
    Pcode: "2104",
    Locality: "BAYVIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.289000",
    Latitude: "-33.659500"
  },
  {
    Country: "Australia",
    Pcode: "2105",
    Locality: "CHURCH POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.278900",
    Latitude: "-33.649200"
  },
  {
    Country: "Australia",
    Pcode: "2105",
    Locality: "ELVINA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.277800",
    Latitude: "-33.640000"
  },
  {
    Country: "Australia",
    Pcode: "2105",
    Locality: "LOVETT BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.281400",
    Latitude: "-33.636300"
  },
  {
    Country: "Australia",
    Pcode: "2105",
    Locality: "MCCARRS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.276800",
    Latitude: "-33.642900"
  },
  {
    Country: "Australia",
    Pcode: "2105",
    Locality: "MORNING BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.280900",
    Latitude: "-33.623900"
  },
  {
    Country: "Australia",
    Pcode: "2105",
    Locality: "SCOTLAND ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.290900",
    Latitude: "-33.640200"
  },
  {
    Country: "Australia",
    Pcode: "2106",
    Locality: "NEWPORT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.314300",
    Latitude: "-33.660100"
  },
  {
    Country: "Australia",
    Pcode: "2106",
    Locality: "NEWPORT BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2107",
    Locality: "AVALON BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.328100",
    Latitude: "-33.628700"
  },
  {
    Country: "Australia",
    Pcode: "2107",
    Locality: "BILGOLA BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.325400",
    Latitude: "-33.644700"
  },
  {
    Country: "Australia",
    Pcode: "2107",
    Locality: "BILGOLA PLATEAU",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.312300",
    Latitude: "-33.644800"
  },
  {
    Country: "Australia",
    Pcode: "2107",
    Locality: "CLAREVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.309100",
    Latitude: "-33.635500"
  },
  {
    Country: "Australia",
    Pcode: "2107",
    Locality: "WHALE BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.330400",
    Latitude: "-33.612400"
  },
  {
    Country: "Australia",
    Pcode: "2108",
    Locality: "COASTERS RETREAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.299000",
    Latitude: "-33.604800"
  },
  {
    Country: "Australia",
    Pcode: "2108",
    Locality: "CURRAWONG BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.297800",
    Latitude: "-33.595200"
  },
  {
    Country: "Australia",
    Pcode: "2108",
    Locality: "GREAT MACKEREL BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.298300",
    Latitude: "-33.591300"
  },
  {
    Country: "Australia",
    Pcode: "2108",
    Locality: "PALM BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.326100",
    Latitude: "-33.605600"
  },
  {
    Country: "Australia",
    Pcode: "2110",
    Locality: "HUNTERS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.138200",
    Latitude: "-33.829700"
  },
  {
    Country: "Australia",
    Pcode: "2110",
    Locality: "WOOLWICH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.173000",
    Latitude: "-33.840200"
  },
  {
    Country: "Australia",
    Pcode: "2111",
    Locality: "BORONIA PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2111",
    Locality: "GLADESVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.126300",
    Latitude: "-33.827600"
  },
  {
    Country: "Australia",
    Pcode: "2111",
    Locality: "HENLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.135400",
    Latitude: "-33.842100"
  },
  {
    Country: "Australia",
    Pcode: "2111",
    Locality: "HUNTLEYS COVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.137500",
    Latitude: "-33.837400"
  },
  {
    Country: "Australia",
    Pcode: "2175",
    Locality: "HORSLEY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.848000",
    Latitude: "-33.842600"
  },
  {
    Country: "Australia",
    Pcode: "2176",
    Locality: "ABBOTSBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.859500",
    Latitude: "-33.873700"
  },
  {
    Country: "Australia",
    Pcode: "2176",
    Locality: "BOSSLEY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.884300",
    Latitude: "-33.862200"
  },
  {
    Country: "Australia",
    Pcode: "2176",
    Locality: "EDENSOR PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.876700",
    Latitude: "-33.878900"
  },
  {
    Country: "Australia",
    Pcode: "2176",
    Locality: "GREENFIELD PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.892000",
    Latitude: "-33.875300"
  },
  {
    Country: "Australia",
    Pcode: "2176",
    Locality: "PRAIRIEWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.903700",
    Latitude: "-33.865200"
  },
  {
    Country: "Australia",
    Pcode: "2176",
    Locality: "ST JOHNS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.902000",
    Latitude: "-33.882500"
  },
  {
    Country: "Australia",
    Pcode: "2176",
    Locality: "WAKELEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.908300",
    Latitude: "-33.873700"
  },
  {
    Country: "Australia",
    Pcode: "2177",
    Locality: "BONNYRIGG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.890300",
    Latitude: "-33.893300"
  },
  {
    Country: "Australia",
    Pcode: "2177",
    Locality: "BONNYRIGG HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.870400",
    Latitude: "-33.891000"
  },
  {
    Country: "Australia",
    Pcode: "2178",
    Locality: "CECIL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.811100",
    Latitude: "-33.882000"
  },
  {
    Country: "Australia",
    Pcode: "2178",
    Locality: "KEMPS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.789300",
    Latitude: "-33.870400"
  },
  {
    Country: "Australia",
    Pcode: "2178",
    Locality: "MOUNT VERNON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.807800",
    Latitude: "-33.860500"
  },
  {
    Country: "Australia",
    Pcode: "2179",
    Locality: "AUSTRAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.808800",
    Latitude: "-33.923800"
  },
  {
    Country: "Australia",
    Pcode: "2179",
    Locality: "LEPPINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.800300",
    Latitude: "-33.971800"
  },
  {
    Country: "Australia",
    Pcode: "2190",
    Locality: "CHULLORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.047500",
    Latitude: "-33.892500"
  },
  {
    Country: "Australia",
    Pcode: "2190",
    Locality: "GREENACRE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.057200",
    Latitude: "-33.903700"
  },
  {
    Country: "Australia",
    Pcode: "2190",
    Locality: "MOUNT LEWIS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.049300",
    Latitude: "-33.915600"
  },
  {
    Country: "Australia",
    Pcode: "2191",
    Locality: "BELFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.085600",
    Latitude: "-33.904500"
  },
  {
    Country: "Australia",
    Pcode: "2192",
    Locality: "BELMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.088100",
    Latitude: "-33.919100"
  },
  {
    Country: "Australia",
    Pcode: "2193",
    Locality: "ASHBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.117900",
    Latitude: "-33.901100"
  },
  {
    Country: "Australia",
    Pcode: "2193",
    Locality: "CANTERBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.117000",
    Latitude: "-33.910600"
  },
  {
    Country: "Australia",
    Pcode: "2193",
    Locality: "HURLSTONE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.128800",
    Latitude: "-33.910000"
  },
  {
    Country: "Australia",
    Pcode: "2194",
    Locality: "CAMPSIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.103300",
    Latitude: "-33.912800"
  },
  {
    Country: "Australia",
    Pcode: "2195",
    Locality: "LAKEMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.075500",
    Latitude: "-33.918700"
  },
  {
    Country: "Australia",
    Pcode: "2195",
    Locality: "LAKEMBA DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2195",
    Locality: "WILEY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.067600",
    Latitude: "-33.923100"
  },
  {
    Country: "Australia",
    Pcode: "2196",
    Locality: "PUNCHBOWL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BANKSTOWN",
    Category: "Delivery Area",
    Longitude: "151.051700",
    Latitude: "-33.929000"
  },
  {
    Country: "Australia",
    Pcode: "2196",
    Locality: "ROSELANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.074100",
    Latitude: "-33.933300"
  },
  {
    Country: "Australia",
    Pcode: "2197",
    Locality: "BASS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.998500",
    Latitude: "-33.900600"
  },
  {
    Country: "Australia",
    Pcode: "2198",
    Locality: "GEORGES HALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.989500",
    Latitude: "-33.907700"
  },
  {
    Country: "Australia",
    Pcode: "2199",
    Locality: "YAGOONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.022000",
    Latitude: "-33.904200"
  },
  {
    Country: "Australia",
    Pcode: "2199",
    Locality: "YAGOONA WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2200",
    Locality: "BANKSTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.031400",
    Latitude: "-33.921000"
  },
  {
    Country: "Australia",
    Pcode: "2200",
    Locality: "BANKSTOWN AERODROME",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.986200",
    Latitude: "-33.923300"
  },
  {
    Country: "Australia",
    Pcode: "2200",
    Locality: "BANKSTOWN NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2200",
    Locality: "BANKSTOWN SQUARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2200",
    Locality: "CONDELL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.011600",
    Latitude: "-33.925700"
  },
  {
    Country: "Australia",
    Pcode: "2200",
    Locality: "MANAHAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2200",
    Locality: "MOUNT LEWIS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2203",
    Locality: "DULWICH HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.138200",
    Latitude: "-33.904600"
  },
  {
    Country: "Australia",
    Pcode: "2204",
    Locality: "MARRICKVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.154600",
    Latitude: "-33.912200"
  },
  {
    Country: "Australia",
    Pcode: "2204",
    Locality: "MARRICKVILLE METRO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2204",
    Locality: "MARRICKVILLE SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2205",
    Locality: "ARNCLIFFE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.147800",
    Latitude: "-33.938600"
  },
  {
    Country: "Australia",
    Pcode: "2205",
    Locality: "TURRELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.143100",
    Latitude: "-33.930200"
  },
  {
    Country: "Australia",
    Pcode: "2205",
    Locality: "WOLLI CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.153800",
    Latitude: "-33.930800"
  },
  {
    Country: "Australia",
    Pcode: "2206",
    Locality: "CLEMTON PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.104800",
    Latitude: "-33.925700"
  },
  {
    Country: "Australia",
    Pcode: "2206",
    Locality: "EARLWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.131200",
    Latitude: "-33.924900"
  },
  {
    Country: "Australia",
    Pcode: "2207",
    Locality: "BARDWELL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.124000",
    Latitude: "-33.935700"
  },
  {
    Country: "Australia",
    Pcode: "2207",
    Locality: "BARDWELL VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.131400",
    Latitude: "-33.937400"
  },
  {
    Country: "Australia",
    Pcode: "2207",
    Locality: "BEXLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.117900",
    Latitude: "-33.952300"
  },
  {
    Country: "Australia",
    Pcode: "2207",
    Locality: "BEXLEY NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.115300",
    Latitude: "-33.942500"
  },
  {
    Country: "Australia",
    Pcode: "2207",
    Locality: "BEXLEY SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2208",
    Locality: "KINGSGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.098900",
    Latitude: "-33.939800"
  },
  {
    Country: "Australia",
    Pcode: "2208",
    Locality: "KINGSGROVE DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2208",
    Locality: "KINGSWAY WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2209",
    Locality: "BEVERLY HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.078300",
    Latitude: "-33.947100"
  },
  {
    Country: "Australia",
    Pcode: "2209",
    Locality: "NARWEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.069200",
    Latitude: "-33.947100"
  },
  {
    Country: "Australia",
    Pcode: "2210",
    Locality: "LUGARNO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.045400",
    Latitude: "-33.981400"
  },
  {
    Country: "Australia",
    Pcode: "2210",
    Locality: "PEAKHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.056500",
    Latitude: "-33.961800"
  },
  {
    Country: "Australia",
    Pcode: "2210",
    Locality: "PEAKHURST DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2210",
    Locality: "PEAKHURST HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.054900",
    Latitude: "-33.975300"
  },
  {
    Country: "Australia",
    Pcode: "2210",
    Locality: "RIVERWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.053200",
    Latitude: "-33.947200"
  },
  {
    Country: "Australia",
    Pcode: "2211",
    Locality: "PADSTOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.030700",
    Latitude: "-33.950000"
  },
  {
    Country: "Australia",
    Pcode: "2260",
    Locality: "FORRESTERS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.464500",
    Latitude: "-33.408800"
  },
  {
    Country: "Australia",
    Pcode: "2260",
    Locality: "NORTH AVOCA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.435400",
    Latitude: "-33.456000"
  },
  {
    Country: "Australia",
    Pcode: "2260",
    Locality: "TERRIGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.428000",
    Latitude: "-33.445700"
  },
  {
    Country: "Australia",
    Pcode: "2260",
    Locality: "WAMBERAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.439300",
    Latitude: "-33.423200"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "BATEAU BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.469100",
    Latitude: "-33.384800"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "BAY VILLAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "BERKELEY VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.425300",
    Latitude: "-33.329300"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "BLUE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.500800",
    Latitude: "-33.354600"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "CHITTAWAY BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.436900",
    Latitude: "-33.326700"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "CHITTAWAY POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.443100",
    Latitude: "-33.322300"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "GLENNING VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.422100",
    Latitude: "-33.354700"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "KILLARNEY VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.462800",
    Latitude: "-33.366200"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "LONG JETTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.488300",
    Latitude: "-33.359200"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "MAGENTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.532900",
    Latitude: "-33.303000"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "SHELLY BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.483300",
    Latitude: "-33.369900"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "THE ENTRANCE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.496300",
    Latitude: "-33.344400"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "THE ENTRANCE NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.508800",
    Latitude: "-33.329400"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "TOOWOON BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.499900",
    Latitude: "-33.362400"
  },
  {
    Country: "Australia",
    Pcode: "2261",
    Locality: "TUMBI UMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.425200",
    Latitude: "-33.378500"
  },
  {
    Country: "Australia",
    Pcode: "2262",
    Locality: "BLUE HAVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.504600",
    Latitude: "-33.211400"
  },
  {
    Country: "Australia",
    Pcode: "2262",
    Locality: "BUDGEWOI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.553700",
    Latitude: "-33.232900"
  },
  {
    Country: "Australia",
    Pcode: "2262",
    Locality: "BUDGEWOI PENINSULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.586600",
    Latitude: "-33.215600"
  },
  {
    Country: "Australia",
    Pcode: "2262",
    Locality: "BUFF POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.536500",
    Latitude: "-33.232000"
  },
  {
    Country: "Australia",
    Pcode: "2262",
    Locality: "COLONGRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.543800",
    Latitude: "-33.209200"
  },
  {
    Country: "Australia",
    Pcode: "2262",
    Locality: "DOYALSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.511700",
    Latitude: "-33.194400"
  },
  {
    Country: "Australia",
    Pcode: "2262",
    Locality: "DOYALSON NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.548300",
    Latitude: "-33.181100"
  },
  {
    Country: "Australia",
    Pcode: "2262",
    Locality: "HALEKULANI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.551800",
    Latitude: "-33.219300"
  },
  {
    Country: "Australia",
    Pcode: "2262",
    Locality: "SAN REMO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.521500",
    Latitude: "-33.214000"
  },
  {
    Country: "Australia",
    Pcode: "2263",
    Locality: "CANTON BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.544900",
    Latitude: "-33.271100"
  },
  {
    Country: "Australia",
    Pcode: "2263",
    Locality: "CHARMHAVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.490800",
    Latitude: "-33.226000"
  },
  {
    Country: "Australia",
    Pcode: "2263",
    Locality: "GOROKAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.505900",
    Latitude: "-33.253600"
  },
  {
    Country: "Australia",
    Pcode: "2263",
    Locality: "LAKE HAVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.500900",
    Latitude: "-33.244500"
  },
  {
    Country: "Australia",
    Pcode: "2263",
    Locality: "NORAH HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.564000",
    Latitude: "-33.283700"
  },
  {
    Country: "Australia",
    Pcode: "2263",
    Locality: "NORAVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.556500",
    Latitude: "-33.271900"
  },
  {
    Country: "Australia",
    Pcode: "2263",
    Locality: "TOUKLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.550800",
    Latitude: "-33.258200"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "BALCOLYN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.552000",
    Latitude: "-33.094600"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "BONNELLS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.521800",
    Latitude: "-33.109700"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "BRIGHTWATERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.545000",
    Latitude: "-33.111700"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "DORA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.490800",
    Latitude: "-33.074800"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "ERARING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.522500",
    Latitude: "-33.073300"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "MANDALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.427800",
    Latitude: "-33.130700"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "MIRRABOOKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.555800",
    Latitude: "-33.110700"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "MORISSET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.492900",
    Latitude: "-33.119600"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "MORISSET PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.534500",
    Latitude: "-33.121600"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "MYUNA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.539800",
    Latitude: "-33.057400"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "SILVERWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MORISSET",
    Category: "Delivery Area",
    Longitude: "151.558600",
    Latitude: "-33.103300"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "SUNSHINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.562600",
    Latitude: "-33.112200"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "WINDERMERE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.531400",
    Latitude: "-33.115300"
  },
  {
    Country: "Australia",
    Pcode: "2264",
    Locality: "YARRAWONGA PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.546700",
    Latitude: "-33.100200"
  },
  {
    Country: "Australia",
    Pcode: "2265",
    Locality: "COORANBONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.469300",
    Latitude: "-33.052500"
  },
  {
    Country: "Australia",
    Pcode: "2265",
    Locality: "MARTINSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.388700",
    Latitude: "-33.050500"
  },
  {
    Country: "Australia",
    Pcode: "2267",
    Locality: "WANGI WANGI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.584800",
    Latitude: "-33.071400"
  },
  {
    Country: "Australia",
    Pcode: "2278",
    Locality: "BARNSLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.586000",
    Latitude: "-32.928600"
  },
  {
    Country: "Australia",
    Pcode: "2278",
    Locality: "KILLINGWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.544900",
    Latitude: "-32.928500"
  },
  {
    Country: "Australia",
    Pcode: "2278",
    Locality: "WAKEFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.557300",
    Latitude: "-32.969900"
  },
  {
    Country: "Australia",
    Pcode: "2280",
    Locality: "BELMONT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.673400",
    Latitude: "-33.031900"
  },
  {
    Country: "Australia",
    Pcode: "2280",
    Locality: "BELMONT NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.672500",
    Latitude: "-33.013500"
  },
  {
    Country: "Australia",
    Pcode: "2280",
    Locality: "BELMONT SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.660100",
    Latitude: "-33.053300"
  },
  {
    Country: "Australia",
    Pcode: "2280",
    Locality: "CROUDACE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.647900",
    Latitude: "-33.004900"
  },
  {
    Country: "Australia",
    Pcode: "2280",
    Locality: "FLORAVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.662500",
    Latitude: "-33.011100"
  },
  {
    Country: "Australia",
    Pcode: "2280",
    Locality: "JEWELLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.687500",
    Latitude: "-33.006800"
  },
  {
    Country: "Australia",
    Pcode: "2280",
    Locality: "MARKS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.647000",
    Latitude: "-33.060500"
  },
  {
    Country: "Australia",
    Pcode: "2280",
    Locality: "VALENTINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.640100",
    Latitude: "-33.016700"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "BLACKSMITHS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.654700",
    Latitude: "-33.076500"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "CAMS WHARF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.617600",
    Latitude: "-33.124500"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "OSWALD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.419600",
    Latitude: "-32.696700"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "PHOENIX PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.632700",
    Latitude: "-32.711900"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "RAWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.607400",
    Latitude: "-32.726100"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "WINDERMERE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.453700",
    Latitude: "-32.675500"
  },
  {
    Country: "Australia",
    Pcode: "2321",
    Locality: "WOODVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.613700",
    Latitude: "-32.663300"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "BERESFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.642900",
    Latitude: "-32.802800"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "BLACK HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.620600",
    Latitude: "-32.819200"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "CHISHOLM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.635200",
    Latitude: "-32.753100"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "HEXHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.673100",
    Latitude: "-32.853000"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "LENAGHAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.632200",
    Latitude: "-32.849000"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "STOCKRINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.579200",
    Latitude: "-32.863400"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "TARRO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.673600",
    Latitude: "-32.811800"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "THORNTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.639100",
    Latitude: "-32.785500"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "TOMAGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.732800",
    Latitude: "-32.827900"
  },
  {
    Country: "Australia",
    Pcode: "2322",
    Locality: "WOODBERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.673500",
    Latitude: "-32.792500"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "ASHTONFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.599700",
    Latitude: "-32.778100"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "BRUNKERVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.494900",
    Latitude: "-32.953200"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "BUCHANAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.531000",
    Latitude: "-32.828000"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "BUTTAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.560800",
    Latitude: "-32.832000"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "EAST MAITLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.583300",
    Latitude: "-32.761800"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "FOUR MILE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MAITLAND",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "FREEMANS WATERHOLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.490200",
    Latitude: "-32.982300"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "GREEN HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "METFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.610000",
    Latitude: "-32.766300"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "METFORD DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "MOUNT VINCENT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.471700",
    Latitude: "-32.933400"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "MULBRING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.468000",
    Latitude: "-32.896600"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "PITNACREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.585800",
    Latitude: "-32.738400"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "RICHMOND VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.499700",
    Latitude: "-32.864000"
  },
  {
    Country: "Australia",
    Pcode: "2323",
    Locality: "TENAMBIT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.613600",
    Latitude: "-32.744000"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "BALICKERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.820100",
    Latitude: "-32.653400"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "BRANDY HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.691300",
    Latitude: "-32.689300"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "BUNDABAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.063700",
    Latitude: "-32.670400"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "CARRINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "RAYMOND TERRACE",
    Category: "Delivery Area",
    Longitude: "152.018600",
    Latitude: "-32.660100"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "EAGLETON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.780400",
    Latitude: "-32.695200"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "EAST SEAHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.758500",
    Latitude: "-32.632200"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "HAWKS NEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.186300",
    Latitude: "-32.641900"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "HEATHERBRAE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.739000",
    Latitude: "-32.787800"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "KARUAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.956600",
    Latitude: "-32.628500"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "KINGS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.776200",
    Latitude: "-32.713300"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "LIMEBURNERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "RAYMOND TERRACE",
    Category: "Delivery Area",
    Longitude: "151.921100",
    Latitude: "-32.572600"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "MILLERS FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.698200",
    Latitude: "-32.758900"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "NELSONS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.712300",
    Latitude: "-32.719000"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "NORTH ARM COVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.047200",
    Latitude: "-32.624600"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "OSTERLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.701900",
    Latitude: "-32.724000"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "PINDIMAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.091100",
    Latitude: "-32.658300"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "RAYMOND TERRACE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.762700",
    Latitude: "-32.755300"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "RAYMOND TERRACE EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "SEAHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.701900",
    Latitude: "-32.671300"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "SWAN BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "RAYMOND TERRACE",
    Category: "Delivery Area",
    Longitude: "151.936100",
    Latitude: "-32.699700"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "TAHLEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.998300",
    Latitude: "-32.660300"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "TEA GARDENS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.129800",
    Latitude: "-32.626900"
  },
  {
    Country: "Australia",
    Pcode: "2324",
    Locality: "TWELVE MILE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.870800",
    Latitude: "-32.639900"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "ABERDARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.369500",
    Latitude: "-32.838600"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "ABERNETHY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.402300",
    Latitude: "-32.885900"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "BELLBIRD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.318000",
    Latitude: "-32.854800"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "BELLBIRD HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.330700",
    Latitude: "-32.848300"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "CEDAR CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "CESSNOCK",
    Category: "Delivery Area",
    Longitude: "151.159800",
    Latitude: "-32.835400"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "CESSNOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.356100",
    Latitude: "-32.833400"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "CESSNOCK WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "CONGEWAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.300900",
    Latitude: "-32.991700"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "CORRABARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.219800",
    Latitude: "-32.947000"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "DAIRY ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.175900",
    Latitude: "-33.061700"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "ELLALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.311300",
    Latitude: "-32.929600"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "ELRINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.429200",
    Latitude: "-32.863900"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "EAST TAMWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.952600",
    Latitude: "-31.097600"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "GAROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.972200",
    Latitude: "-31.429700"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "GIDLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.832800",
    Latitude: "-30.997500"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "GOONOO GOONOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.910600",
    Latitude: "-31.293300"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "GOWRIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TAMWORTH",
    Category: "Delivery Area",
    Longitude: "150.839800",
    Latitude: "-31.360200"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "HALLSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.875800",
    Latitude: "-31.007900"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "HANGING ROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.256700",
    Latitude: "-31.499800"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "HILLVUE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.908000",
    Latitude: "-31.129000"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "KEEPIT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.514900",
    Latitude: "-30.854400"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "KINGSWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TAMWORTH",
    Category: "Delivery Area",
    Longitude: "150.931500",
    Latitude: "-31.161300"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "LOOMBERAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.014000",
    Latitude: "-31.277300"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "MOORE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.954400",
    Latitude: "-30.992000"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "NEMINGHA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.001100",
    Latitude: "-31.120900"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "NORTH TAMWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.933900",
    Latitude: "-31.063800"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "NUNDLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.120900",
    Latitude: "-31.468700"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "OGUNBIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.272300",
    Latitude: "-31.347700"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "OXLEY VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.893100",
    Latitude: "-31.054300"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "PIALLAMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.071200",
    Latitude: "-31.145900"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "SOMERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.620800",
    Latitude: "-30.943700"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "SOUTH TAMWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.917200",
    Latitude: "-31.111700"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "TAMINDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.903600",
    Latitude: "-31.081800"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "TAMWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.931600",
    Latitude: "-31.092900"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "TIMBUMBURI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.907000",
    Latitude: "-31.220700"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "WALLAMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.826800",
    Latitude: "-31.043500"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "WARRAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.858200",
    Latitude: "-31.155100"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "WEABONGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.305800",
    Latitude: "-31.195000"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "WEST TAMWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.908700",
    Latitude: "-31.102200"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "WESTDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.853900",
    Latitude: "-31.089600"
  },
  {
    Country: "Australia",
    Pcode: "2340",
    Locality: "WOOLOMIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.140700",
    Latitude: "-31.311300"
  },
  {
    Country: "Australia",
    Pcode: "2341",
    Locality: "WERRIS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.618000",
    Latitude: "-31.323900"
  },
  {
    Country: "Australia",
    Pcode: "2342",
    Locality: "CURRABUBULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.667300",
    Latitude: "-31.227300"
  },
  {
    Country: "Australia",
    Pcode: "2342",
    Locality: "PIALLAWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.534100",
    Latitude: "-31.121800"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "BLACKVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.247300",
    Latitude: "-31.618000"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "BORAMBIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.638300",
    Latitude: "-31.504100"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "BUNDELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.977000",
    Latitude: "-31.615400"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "CAROONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.418500",
    Latitude: "-31.395800"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "COLLY BLUE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.144200",
    Latitude: "-31.450800"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "COOMOO COOMOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.105800",
    Latitude: "-31.585300"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "PINE RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.401700",
    Latitude: "-31.520400"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "QUIPOLLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.730500",
    Latitude: "-31.415100"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "QUIRINDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.545800",
    Latitude: "-31.499400"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "SPRING RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.229700",
    Latitude: "-31.417100"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "WALHALLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "WALLABADAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.877300",
    Latitude: "-31.544400"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "WARRAH RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.569100",
    Latitude: "-31.610200"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "WINDY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.407800",
    Latitude: "-31.624500"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "YANNERGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.064000",
    Latitude: "-31.462400"
  },
  {
    Country: "Australia",
    Pcode: "2343",
    Locality: "YARRAMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.149500",
    Latitude: "-31.698300"
  },
  {
    Country: "Australia",
    Pcode: "2344",
    Locality: "DURI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.831000",
    Latitude: "-31.236700"
  },
  {
    Country: "Australia",
    Pcode: "2344",
    Locality: "WINTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.708100",
    Latitude: "-31.108000"
  },
  {
    Country: "Australia",
    Pcode: "2345",
    Locality: "ATTUNGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.783800",
    Latitude: "-30.899400"
  },
  {
    Country: "Australia",
    Pcode: "2345",
    Locality: "GARTHOWEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.906800",
    Latitude: "-30.865300"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "BORAH CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.517900",
    Latitude: "-30.603600"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "HALLS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.891100",
    Latitude: "-30.760100"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "KLORI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.764400",
    Latitude: "-30.808800"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "MANILLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.711200",
    Latitude: "-30.734700"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "NAMOI RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.825400",
    Latitude: "-30.592600"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "NEW MEXICO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.617900",
    Latitude: "-30.714600"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "RUSHES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.600400",
    Latitude: "-30.845400"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "UPPER MANILLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.660900",
    Latitude: "-30.592500"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "WARRABAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.980400",
    Latitude: "-30.498900"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "WIMBORNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.651300",
    Latitude: "-30.688100"
  },
  {
    Country: "Australia",
    Pcode: "2346",
    Locality: "WONGO CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.515000",
    Latitude: "-30.726100"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "BANOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.418300",
    Latitude: "-30.532600"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "BARRABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.557400",
    Latitude: "-30.386200"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "COBBADAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.567300",
    Latitude: "-30.201800"
  },
  {
    Country: "Australia",
    Pcode: "2372",
    Locality: "WYLIE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.172900",
    Latitude: "-28.560700"
  },
  {
    Country: "Australia",
    Pcode: "2379",
    Locality: "GOOLHI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.720900",
    Latitude: "-30.990500"
  },
  {
    Country: "Australia",
    Pcode: "2379",
    Locality: "MULLALEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.905000",
    Latitude: "-31.160300"
  },
  {
    Country: "Australia",
    Pcode: "2379",
    Locality: "NAPIER LANE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.539200",
    Latitude: "-31.243700"
  },
  {
    Country: "Australia",
    Pcode: "2379",
    Locality: "NOMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.766800",
    Latitude: "-31.191300"
  },
  {
    Country: "Australia",
    Pcode: "2380",
    Locality: "BLUE VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.239000",
    Latitude: "-30.827500"
  },
  {
    Country: "Australia",
    Pcode: "2380",
    Locality: "EMERALD HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.067500",
    Latitude: "-30.867300"
  },
  {
    Country: "Australia",
    Pcode: "2380",
    Locality: "GHOOLENDAADI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.909500",
    Latitude: "-30.934300"
  },
  {
    Country: "Australia",
    Pcode: "2380",
    Locality: "GUNNEDAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.210000",
    Latitude: "-31.002600"
  },
  {
    Country: "Australia",
    Pcode: "2380",
    Locality: "KELVIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.392300",
    Latitude: "-30.829000"
  },
  {
    Country: "Australia",
    Pcode: "2380",
    Locality: "MARYS MOUNT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.006200",
    Latitude: "-30.976700"
  },
  {
    Country: "Australia",
    Pcode: "2380",
    Locality: "MILROY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GUNNEDAH",
    Category: "Delivery Area",
    Longitude: "150.054800",
    Latitude: "-31.134700"
  },
  {
    Country: "Australia",
    Pcode: "2380",
    Locality: "ORANGE GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.419200",
    Latitude: "-30.930800"
  },
  {
    Country: "Australia",
    Pcode: "2380",
    Locality: "RANGARI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.423200",
    Latitude: "-30.671800"
  },
  {
    Country: "Australia",
    Pcode: "2381",
    Locality: "BREEZA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.439100",
    Latitude: "-31.266000"
  },
  {
    Country: "Australia",
    Pcode: "2381",
    Locality: "CURLEWIS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.229900",
    Latitude: "-31.192300"
  },
  {
    Country: "Australia",
    Pcode: "2381",
    Locality: "PREMER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.932100",
    Latitude: "-31.478000"
  },
  {
    Country: "Australia",
    Pcode: "2381",
    Locality: "TAMBAR SPRINGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.875000",
    Latitude: "-31.340000"
  },
  {
    Country: "Australia",
    Pcode: "2382",
    Locality: "BOGGABRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.005600",
    Latitude: "-30.690500"
  },
  {
    Country: "Australia",
    Pcode: "2382",
    Locality: "MAULES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.214500",
    Latitude: "-30.496500"
  },
  {
    Country: "Australia",
    Pcode: "2382",
    Locality: "WEAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.269000",
    Latitude: "-30.707700"
  },
  {
    Country: "Australia",
    Pcode: "2382",
    Locality: "WILLALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.834600",
    Latitude: "-30.837800"
  },
  {
    Country: "Australia",
    Pcode: "2386",
    Locality: "BURREN JUNCTION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.865700",
    Latitude: "-30.044400"
  },
  {
    Country: "Australia",
    Pcode: "2386",
    Locality: "DRILDOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.022700",
    Latitude: "-30.215900"
  },
  {
    Country: "Australia",
    Pcode: "2386",
    Locality: "NOWLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.150100",
    Latitude: "-30.011600"
  },
  {
    Country: "Australia",
    Pcode: "2387",
    Locality: "BULYEROI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.140000",
    Latitude: "-29.799800"
  },
  {
    Country: "Australia",
    Pcode: "2387",
    Locality: "ROWENA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.958500",
    Latitude: "-29.739800"
  },
  {
    Country: "Australia",
    Pcode: "2388",
    Locality: "BOOLCARROLL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.425700",
    Latitude: "-30.040600"
  },
  {
    Country: "Australia",
    Pcode: "2388",
    Locality: "CUTTABRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.159800",
    Latitude: "-30.425500"
  },
  {
    Country: "Australia",
    Pcode: "2388",
    Locality: "MERAH NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.217600",
    Latitude: "-30.188500"
  },
  {
    Country: "Australia",
    Pcode: "2388",
    Locality: "PILLIGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.869800",
    Latitude: "-30.408500"
  },
  {
    Country: "Australia",
    Pcode: "2388",
    Locality: "SPRING PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.263500",
    Latitude: "-29.932800"
  },
  {
    Country: "Australia",
    Pcode: "2388",
    Locality: "THE PILLIGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.468100",
    Latitude: "-30.683300"
  },
  {
    Country: "Australia",
    Pcode: "2388",
    Locality: "WEE WAA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.390700",
    Latitude: "-30.310800"
  },
  {
    Country: "Australia",
    Pcode: "2388",
    Locality: "YARRIE LAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.515300",
    Latitude: "-30.395400"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "BAAN BAA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.935000",
    Latitude: "-30.570100"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "BACK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "NARRABRI",
    Category: "Delivery Area",
    Longitude: "150.271200",
    Latitude: "-30.123300"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "BERRIGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.123200",
    Latitude: "-29.953400"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "BOHENA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.637000",
    Latitude: "-30.425200"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "BULLAWA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.022300",
    Latitude: "-30.251800"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "COURADDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.990000",
    Latitude: "-30.046600"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "EDGEROI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.761600",
    Latitude: "-30.076300"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "EULAH CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.034600",
    Latitude: "-30.366300"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "HARPARARY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.052500",
    Latitude: "-30.553100"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "JACKS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.740600",
    Latitude: "-30.502100"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "KAPUTAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.145900",
    Latitude: "-30.294200"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "NARRABRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.787900",
    Latitude: "-30.226400"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "NARRABRI WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "ROCKY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "NARRABRI",
    Category: "Delivery Area",
    Longitude: "150.197800",
    Latitude: "-29.964800"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "TARRIARO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.033600",
    Latitude: "-30.446300"
  },
  {
    Country: "Australia",
    Pcode: "2390",
    Locality: "TURRAWAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.894700",
    Latitude: "-30.449900"
  },
  {
    Country: "Australia",
    Pcode: "2395",
    Locality: "BINNAWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.402100",
    Latitude: "-31.562700"
  },
  {
    Country: "Australia",
    Pcode: "2395",
    Locality: "ROPERS ROAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.548400",
    Latitude: "-31.538900"
  },
  {
    Country: "Australia",
    Pcode: "2395",
    Locality: "WEETALIBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.582700",
    Latitude: "-31.637500"
  },
  {
    Country: "Australia",
    Pcode: "2396",
    Locality: "BARADINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.101300",
    Latitude: "-30.921700"
  },
  {
    Country: "Australia",
    Pcode: "2396",
    Locality: "BARWON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2396",
    Locality: "GOORIANAWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.986900",
    Latitude: "-31.148300"
  },
  {
    Country: "Australia",
    Pcode: "2396",
    Locality: "KENEBRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.957700",
    Latitude: "-30.725100"
  },
  {
    Country: "Australia",
    Pcode: "2397",
    Locality: "BELLATA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.792500",
    Latitude: "-29.920600"
  },
  {
    Country: "Australia",
    Pcode: "2397",
    Locality: "JEWS LAGOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.485600",
    Latitude: "-29.885700"
  },
  {
    Country: "Australia",
    Pcode: "2397",
    Locality: "MILLIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.468900",
    Latitude: "-29.745800"
  },
  {
    Country: "Australia",
    Pcode: "2398",
    Locality: "GURLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.793900",
    Latitude: "-29.709500"
  },
  {
    Country: "Australia",
    Pcode: "2399",
    Locality: "BINIGUY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.136900",
    Latitude: "-29.581800"
  },
  {
    Country: "Australia",
    Pcode: "2399",
    Locality: "PALLAMALLAWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.191000",
    Latitude: "-29.365000"
  },
  {
    Country: "Australia",
    Pcode: "2400",
    Locality: "ASHLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.787600",
    Latitude: "-29.246300"
  },
  {
    Country: "Australia",
    Pcode: "2400",
    Locality: "BULLARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.131900",
    Latitude: "-29.412500"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "KRAMBACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.259600",
    Latitude: "-32.045100"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "KUNDIBAKH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.258900",
    Latitude: "-31.987600"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "MARLEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.317100",
    Latitude: "-31.767300"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "MOORAL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.359200",
    Latitude: "-31.695900"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "STRATHCEDAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.360900",
    Latitude: "-31.770000"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "THE BIGHT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.383700",
    Latitude: "-31.885100"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "TIPPERARY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.160100",
    Latitude: "-32.050900"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "WARRIWILLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "WHERROL FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.216700",
    Latitude: "-31.790400"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "WINGHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.370800",
    Latitude: "-31.862400"
  },
  {
    Country: "Australia",
    Pcode: "2429",
    Locality: "YARRATT FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.435500",
    Latitude: "-31.784700"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "BLACK HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.535500",
    Latitude: "-32.071300"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "BOHNOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.566600",
    Latitude: "-31.946100"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "BOOTAWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.355300",
    Latitude: "-31.922400"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "BRIMBIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.468500",
    Latitude: "-31.848100"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "CABBAGE TREE ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TAREE",
    Category: "Delivery Area",
    Longitude: "152.591200",
    Latitude: "-31.946000"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "CHATHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "CROKI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.593400",
    Latitude: "-31.873600"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "CUNDLETOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.524700",
    Latitude: "-31.885900"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "DIAMOND BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.541500",
    Latitude: "-32.033000"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "DUMARESQ ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.500200",
    Latitude: "-31.908400"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "FAILFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.444000",
    Latitude: "-32.093100"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "GHINNI GHINNI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.550200",
    Latitude: "-31.883500"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "GLENTHORNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.480100",
    Latitude: "-31.929700"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "HALLIDAYS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.502000",
    Latitude: "-32.071600"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "HILLVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.333600",
    Latitude: "-31.983800"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "JONES ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.598000",
    Latitude: "-31.858100"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "KIWARRAK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.420800",
    Latitude: "-31.993600"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "KOORAINGHAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.500500",
    Latitude: "-31.988400"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "KUNDLE KUNDLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.535400",
    Latitude: "-31.854200"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "LANSDOWNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TAREE",
    Category: "Delivery Area",
    Longitude: "152.509500",
    Latitude: "-31.758600"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "LANSDOWNE FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.590700",
    Latitude: "-31.747700"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "MANNING POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.670300",
    Latitude: "-31.890200"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "MELINGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.491000",
    Latitude: "-31.812800"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "MITCHELLS ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.621600",
    Latitude: "-31.899700"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "MONDROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.412100",
    Latitude: "-31.910800"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "OLD BAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.561100",
    Latitude: "-31.962300"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "OXLEY ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.566600",
    Latitude: "-31.911600"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "PAMPOOLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.511900",
    Latitude: "-31.937000"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "POSSUM BRUSH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.383300",
    Latitude: "-32.039900"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "PURFLEET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.467800",
    Latitude: "-31.943000"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "RAINBOW FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.495500",
    Latitude: "-32.032000"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "RED HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.534600",
    Latitude: "-32.057300"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "SALTWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.539400",
    Latitude: "-32.007400"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "TALLWOODS VILLAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.510200",
    Latitude: "-32.055400"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "TAREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.444800",
    Latitude: "-31.891500"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "TAREE DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "TAREE SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.454900",
    Latitude: "-31.935400"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "TINONEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.413000",
    Latitude: "-31.947100"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "UPPER LANSDOWNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.465000",
    Latitude: "-31.728800"
  },
  {
    Country: "Australia",
    Pcode: "2430",
    Locality: "WALLABI POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.563200",
    Latitude: "-31.990400"
  },
  {
    Country: "Australia",
    Pcode: "2431",
    Locality: "ARAKOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.063100",
    Latitude: "-30.921000"
  },
  {
    Country: "Australia",
    Pcode: "2431",
    Locality: "JERSEYVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.037600",
    Latitude: "-30.923400"
  },
  {
    Country: "Australia",
    Pcode: "2431",
    Locality: "SOUTH WEST ROCKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.038900",
    Latitude: "-30.895100"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "BATAR CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.676300",
    Latitude: "-31.673600"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "BLACK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.628100",
    Latitude: "-31.581600"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "BOBS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.752400",
    Latitude: "-31.597000"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "HERONS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.711300",
    Latitude: "-31.565300"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "KENDALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.696000",
    Latitude: "-31.633000"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "KEREWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.544400",
    Latitude: "-31.629500"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "KEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.735900",
    Latitude: "-31.654600"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "LOGANS CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.702700",
    Latitude: "-31.610300"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "LORNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.609600",
    Latitude: "-31.655300"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "ROSSGLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.729900",
    Latitude: "-31.667300"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "SWANS CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.589900",
    Latitude: "-31.586000"
  },
  {
    Country: "Australia",
    Pcode: "2439",
    Locality: "UPSALLS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.651700",
    Latitude: "-31.629400"
  },
  {
    Country: "Australia",
    Pcode: "2452",
    Locality: "SAWTELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.097200",
    Latitude: "-30.366500"
  },
  {
    Country: "Australia",
    Pcode: "2452",
    Locality: "TOORMINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.088100",
    Latitude: "-30.354200"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "BIELSDOWN HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.667100",
    Latitude: "-30.371800"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "BILLYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.591200",
    Latitude: "-30.166200"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "BOSTOBRICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.602100",
    Latitude: "-30.276800"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "CASCADE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.786500",
    Latitude: "-30.231100"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "CLOUDS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.652000",
    Latitude: "-30.102000"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "DEER VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.540000",
    Latitude: "-30.349300"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "DORRIGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.696500",
    Latitude: "-30.327600"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "DORRIGO MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.735700",
    Latitude: "-30.370600"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "DUNDURRABIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.520000",
    Latitude: "-30.086300"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "EBOR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.315700",
    Latitude: "-30.403600"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "FERNBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.612200",
    Latitude: "-30.342900"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "HERNANI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.435500",
    Latitude: "-30.307800"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "MARENGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.412800",
    Latitude: "-30.144400"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "MEGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.775600",
    Latitude: "-30.299300"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "MOONPAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.639200",
    Latitude: "-30.222000"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "NEVER NEVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.819600",
    Latitude: "-30.357200"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "NORTH DORRIGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.680700",
    Latitude: "-30.267800"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "TALLOWWOOD RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.724400",
    Latitude: "-30.261100"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "TYRINGHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.502500",
    Latitude: "-30.247900"
  },
  {
    Country: "Australia",
    Pcode: "2453",
    Locality: "WILD CATTLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.745900",
    Latitude: "-30.131800"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "BELLINGEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.907800",
    Latitude: "-30.454000"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "BRIERFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.917800",
    Latitude: "-30.515500"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "BRINERVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.501000",
    Latitude: "-30.472300"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "BUNDAGEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.061500",
    Latitude: "-30.421700"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "DARKWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.641100",
    Latitude: "-30.428900"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "FERNMOUNT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.943800",
    Latitude: "-30.476600"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "GLENIFFER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.883200",
    Latitude: "-30.386100"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "KALANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.735400",
    Latitude: "-30.495000"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "MYLESTOM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.040400",
    Latitude: "-30.460200"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "RALEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.990400",
    Latitude: "-30.459100"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "REPTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.031100",
    Latitude: "-30.439100"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "SPICKETTS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.867100",
    Latitude: "-30.534700"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "THORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.780600",
    Latitude: "-30.436800"
  },
  {
    Country: "Australia",
    Pcode: "2454",
    Locality: "VALERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.960100",
    Latitude: "-30.411500"
  },
  {
    Country: "Australia",
    Pcode: "2455",
    Locality: "URUNGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.996600",
    Latitude: "-30.518400"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "ARRAWARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.191200",
    Latitude: "-30.051100"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "ARRAWARRA HEADLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.194600",
    Latitude: "-30.067100"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "CORINDI BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.181200",
    Latitude: "-30.020700"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "DIRTY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.149000",
    Latitude: "-29.995100"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "EMERALD BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.163400",
    Latitude: "-30.162500"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "MULLAWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.195100",
    Latitude: "-30.084500"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "RED ROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.212600",
    Latitude: "-30.000800"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "SAFETY BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.194600",
    Latitude: "-30.091800"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "SANDY BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.181000",
    Latitude: "-30.145000"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "UPPER CORINDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.119600",
    Latitude: "-30.050700"
  },
  {
    Country: "Australia",
    Pcode: "2456",
    Locality: "WOOLGOOLGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.164200",
    Latitude: "-30.084800"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "ALUMY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.958900",
    Latitude: "-29.642400"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BANYABBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.940700",
    Latitude: "-29.393300"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BARCOONGERE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.201700",
    Latitude: "-29.900200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BARRETTS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.759400",
    Latitude: "-29.358200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BARYULGIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.613800",
    Latitude: "-29.222200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BLAXLANDS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.799600",
    Latitude: "-29.893000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BOM BOM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.964500",
    Latitude: "-29.775600"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BOOKRAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.242900",
    Latitude: "-29.690800"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BRAUNSTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.969900",
    Latitude: "-29.813900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BRUSHGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.089500",
    Latitude: "-29.560000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "BUCCARUMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.608400",
    Latitude: "-29.816000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CALAMIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.120100",
    Latitude: "-29.846300"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CANGAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.453800",
    Latitude: "-29.498000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CARNHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.576300",
    Latitude: "-29.348300"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "SAPPHIRE BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.133000",
    Latitude: "-30.230100"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "SHERWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "COFFS HARBOUR",
    Category: "Delivery Area",
    Longitude: "153.045100",
    Latitude: "-30.034900"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "ULONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.897500",
    Latitude: "-30.239300"
  },
  {
    Country: "Australia",
    Pcode: "2450",
    Locality: "UPPER ORARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.992900",
    Latitude: "-30.282500"
  },
  {
    Country: "Australia",
    Pcode: "2452",
    Locality: "BOAMBEE EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.075900",
    Latitude: "-30.345600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "EWINGAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.513900",
    Latitude: "-29.091000"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "GIBBERAGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.101200",
    Latitude: "-29.286600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "GOODWOOD ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.320700",
    Latitude: "-29.381200"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "GORGE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.731900",
    Latitude: "-28.725300"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "HAYSTACK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.544200",
    Latitude: "-28.655900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "HOGARTH RANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.808000",
    Latitude: "-28.913900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "JACKSONS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.559000",
    Latitude: "-28.833900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "JOES BOX",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.593900",
    Latitude: "-28.679500"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "KEYBARBIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.617700",
    Latitude: "-29.114300"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "KIPPENDUFF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.775700",
    Latitude: "-29.164200"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "LOUISA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.630200",
    Latitude: "-29.017700"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "LOWER BOTTLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.605900",
    Latitude: "-28.819000"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "LOWER DUCK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.527500",
    Latitude: "-28.733500"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "LOWER PEACOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.588500",
    Latitude: "-28.778900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "MALLANGANEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.718800",
    Latitude: "-28.905700"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "MOOKIMA WYBRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.529500",
    Latitude: "-29.006800"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "MORORO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.194400",
    Latitude: "-29.343300"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "MOUNT MARSH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.885300",
    Latitude: "-29.270400"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "MUMMULGUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.778600",
    Latitude: "-28.872900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "MYRTLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.021200",
    Latitude: "-29.152600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "OLD BONALBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.592500",
    Latitude: "-28.646100"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "PADDYS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BONALBO",
    Category: "Delivery Area",
    Longitude: "152.420400",
    Latitude: "-28.696400"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "PAGANS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.570500",
    Latitude: "-28.948000"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "PEACOCK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.705700",
    Latitude: "-28.669300"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "PIKAPENE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.657400",
    Latitude: "-29.047900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "RAPPVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.945500",
    Latitude: "-29.066900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "SANDILANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.659700",
    Latitude: "-28.899300"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "SIMPKINS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.772400",
    Latitude: "-28.838400"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "SIX MILE SWAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.863100",
    Latitude: "-29.187800"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "TABULAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.501300",
    Latitude: "-28.878400"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "THERESA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.790900",
    Latitude: "-28.777900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "TUNGLEBUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.693000",
    Latitude: "-28.811800"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "UPPER DUCK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.695800",
    Latitude: "-28.524500"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "WARREGAH ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.231300",
    Latitude: "-29.377000"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "WHIPORIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.000800",
    Latitude: "-29.272600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "WOOMBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.281800",
    Latitude: "-29.306900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "WYAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.867000",
    Latitude: "-29.086600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "YABBRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.506100",
    Latitude: "-28.603800"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "BABYL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.786300",
    Latitude: "-28.723800"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "BACKMEDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.023900",
    Latitude: "-28.761200"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "CASINO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.032700",
    Latitude: "-28.853300"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "COOMBELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.949500",
    Latitude: "-29.022400"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "DOBIES BIGHT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.954500",
    Latitude: "-28.805700"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "DOUBTFUL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.873800",
    Latitude: "-28.715000"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "DYRAABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.838600",
    Latitude: "-28.783800"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "ELLANGOWAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.075500",
    Latitude: "-29.032800"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "FAIRY HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.985400",
    Latitude: "-28.759500"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "IRVINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.101600",
    Latitude: "-28.865500"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "LEEVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.996200",
    Latitude: "-28.956000"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "LOWER DYRAABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.889100",
    Latitude: "-28.807000"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "MONGOGARIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.899900",
    Latitude: "-28.954000"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "NAUGHTONS GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.103100",
    Latitude: "-28.799000"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "NORTH CASINO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.063800",
    Latitude: "-28.801400"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "PIORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.888100",
    Latitude: "-28.861900"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "SEXTONVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.796100",
    Latitude: "-28.677400"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "SHANNON BROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.966000",
    Latitude: "-28.906300"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "SPRING GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.131900",
    Latitude: "-28.824100"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "STRATHEDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.945100",
    Latitude: "-28.752700"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "TOMKI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.134800",
    Latitude: "-28.861900"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "UPPER MONGOGARIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.809900",
    Latitude: "-28.965200"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "WOODVIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.937300",
    Latitude: "-28.844700"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "WOOLNERS ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.837400",
    Latitude: "-28.683300"
  },
  {
    Country: "Australia",
    Pcode: "2470",
    Locality: "YORKLEA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.079200",
    Latitude: "-28.937200"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "BORA RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.185600",
    Latitude: "-29.061400"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "CODRINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.250500",
    Latitude: "-28.954100"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "CORAKI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.274600",
    Latitude: "-28.981500"
  },
  {
    Country: "Australia",
    Pcode: "2481",
    Locality: "BYRON BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.617000",
    Latitude: "-28.646800"
  },
  {
    Country: "Australia",
    Pcode: "2481",
    Locality: "EWINGSDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.545800",
    Latitude: "-28.638900"
  },
  {
    Country: "Australia",
    Pcode: "2481",
    Locality: "HAYTERS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.583100",
    Latitude: "-28.674400"
  },
  {
    Country: "Australia",
    Pcode: "2481",
    Locality: "MYOCUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.505500",
    Latitude: "-28.597300"
  },
  {
    Country: "Australia",
    Pcode: "2481",
    Locality: "SKINNERS SHOOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.591100",
    Latitude: "-28.661000"
  },
  {
    Country: "Australia",
    Pcode: "2481",
    Locality: "SUFFOLK PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.607700",
    Latitude: "-28.688200"
  },
  {
    Country: "Australia",
    Pcode: "2481",
    Locality: "TALOFA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.556200",
    Latitude: "-28.673300"
  },
  {
    Country: "Australia",
    Pcode: "2481",
    Locality: "TYAGARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.551700",
    Latitude: "-28.600100"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "GOONENGERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.432000",
    Latitude: "-28.609900"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "HUONBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.360800",
    Latitude: "-28.537800"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "KOONYUM RANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.410800",
    Latitude: "-28.542600"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "MAIN ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.439300",
    Latitude: "-28.522400"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "MONTECOLLUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.460900",
    Latitude: "-28.592000"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "MULLUMBIMBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.498300",
    Latitude: "-28.549300"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "MULLUMBIMBY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.445300",
    Latitude: "-28.552000"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "PALMWOODS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.404700",
    Latitude: "-28.513300"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "UPPER COOPERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.401200",
    Latitude: "-28.602100"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "UPPER MAIN ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.397900",
    Latitude: "-28.490700"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "UPPER WILSONS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.382800",
    Latitude: "-28.517100"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "WANGANUI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.381000",
    Latitude: "-28.564100"
  },
  {
    Country: "Australia",
    Pcode: "2482",
    Locality: "WILSONS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.442600",
    Latitude: "-28.564100"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "BILLINUDGEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.513600",
    Latitude: "-28.506200"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "BRUNSWICK HEADS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.544600",
    Latitude: "-28.558600"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "BURRINGBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.464000",
    Latitude: "-28.419500"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "CRABBES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.486400",
    Latitude: "-28.460800"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "MIDDLE POCKET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.471300",
    Latitude: "-28.488000"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "MOOBALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.501000",
    Latitude: "-28.434200"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "NEW BRIGHTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.551300",
    Latitude: "-28.516800"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "OCEAN SHORES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.531400",
    Latitude: "-28.495500"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "SLEEPY HOLLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.516000",
    Latitude: "-28.418400"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "SOUTH GOLDEN BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.546700",
    Latitude: "-28.497900"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "THE POCKET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.470100",
    Latitude: "-28.503200"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "UPPER BURRINGBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.422300",
    Latitude: "-28.449800"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "WOOYUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.534300",
    Latitude: "-28.452300"
  },
  {
    Country: "Australia",
    Pcode: "2483",
    Locality: "YELGUN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.494700",
    Latitude: "-28.479900"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "BACK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MURWILLUMBAH",
    Category: "Delivery Area",
    Longitude: "153.168300",
    Latitude: "-28.410400"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "BRAY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.375400",
    Latitude: "-28.342200"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "BRAYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.193800",
    Latitude: "-28.412500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "BYANGUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.363000",
    Latitude: "-28.357900"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "BYRRILL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.241700",
    Latitude: "-28.440000"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "CEDAR CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MURWILLUMBAH",
    Category: "Delivery Area",
    Longitude: "153.265700",
    Latitude: "-28.421100"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "CHILLINGHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.282200",
    Latitude: "-28.313700"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "CHOWAN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.378400",
    Latitude: "-28.457900"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "CLOTHIERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.479400",
    Latitude: "-28.334100"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "COMMISSIONERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.336900",
    Latitude: "-28.503900"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "CONDONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.441500",
    Latitude: "-28.311500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "CRYSTAL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.316200",
    Latitude: "-28.316000"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "CUDGERA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.517800",
    Latitude: "-28.387300"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "DOON DOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.308800",
    Latitude: "-28.459500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "DULGUIGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.422600",
    Latitude: "-28.279600"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "DUM DUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.349800",
    Latitude: "-28.389100"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "DUNBIBLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.386000",
    Latitude: "-28.371400"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "DUNGAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.354800",
    Latitude: "-28.270800"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "EUNGELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.304200",
    Latitude: "-28.348400"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "EVIRON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.482900",
    Latitude: "-28.306500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "FARRANTS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.487100",
    Latitude: "-28.318800"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "FERNVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.420800",
    Latitude: "-28.365300"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "HOPKINS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.233100",
    Latitude: "-28.284400"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "KIELVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.450100",
    Latitude: "-28.338500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "KUNGHUR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.231900",
    Latitude: "-28.462500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "KUNGHUR CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.231800",
    Latitude: "-28.502700"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "KYNNUMBOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.375400",
    Latitude: "-28.306400"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "LIMPINWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.191900",
    Latitude: "-28.290100"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "MEBBIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.162300",
    Latitude: "-28.464100"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "MIDGINBIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.267200",
    Latitude: "-28.499200"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "YADBORO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.176500",
    Latitude: "-35.368400"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "YATTE YATTAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.420900",
    Latitude: "-35.263400"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BAMARANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.520800",
    Latitude: "-34.904900"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BARRINGELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.457600",
    Latitude: "-34.912500"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BASIN VIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.557500",
    Latitude: "-35.092700"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BEECROFT PENINSULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.808400",
    Latitude: "-35.051800"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BERRARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.548900",
    Latitude: "-35.206900"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BEWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.534500",
    Latitude: "-35.082000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BOLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.643500",
    Latitude: "-34.837100"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BOOLIJAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.332000",
    Latitude: "-35.058700"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BREAM BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.661700",
    Latitude: "-35.108500"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BROWNS MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.529300",
    Latitude: "-34.791000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BRUNDEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.656700",
    Latitude: "-34.893500"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BUANGLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.395600",
    Latitude: "-34.870700"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "BURRIER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.438000",
    Latitude: "-34.877800"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "CALLALA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.717200",
    Latitude: "-34.993900"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "CALLALA BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.699800",
    Latitude: "-35.003300"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "CAMBEWARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.555000",
    Latitude: "-34.814900"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "CAMBEWARRA VILLAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.559000",
    Latitude: "-34.821400"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "COMBERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.648200",
    Latitude: "-34.971200"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "COMERONG ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.739400",
    Latitude: "-34.883000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "CUDMIRRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.556500",
    Latitude: "-35.199500"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "CULBURRA BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.748200",
    Latitude: "-34.921900"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "CURRARONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.821100",
    Latitude: "-35.014900"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "EROWAL BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.665100",
    Latitude: "-35.103900"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "ETTREMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.295900",
    Latitude: "-34.867300"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "FALLS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.595600",
    Latitude: "-34.992300"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "GREENWELL POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.730100",
    Latitude: "-34.911300"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "HMAS ALBATROSS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "HMAS CRESWELL",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "HUSKISSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.650100",
    Latitude: "-35.036000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "HYAMS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.686900",
    Latitude: "-35.107200"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "ILLAROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.477100",
    Latitude: "-34.863000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "JERRAWANGALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.439100",
    Latitude: "-35.069500"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "JERVIS BAY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "KINGHORNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.771200",
    Latitude: "-34.978100"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "LONGREACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.522700",
    Latitude: "-34.871400"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "MAYFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "NOWRA",
    Category: "Delivery Area",
    Longitude: "150.670100",
    Latitude: "-34.918700"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "MEROO MEADOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.603800",
    Latitude: "-34.814000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "MONDAYONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.504100",
    Latitude: "-35.174500"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "MOOLLATTOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.376100",
    Latitude: "-34.789000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "MUNDAMIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.562200",
    Latitude: "-34.886100"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "MYOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.674100",
    Latitude: "-35.025100"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "NOWRA HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.567000",
    Latitude: "-34.931500"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "NOWRA NAVAL PO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "NUMBAA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.693900",
    Latitude: "-34.876500"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "OLD EROWAL BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.649300",
    Latitude: "-35.088000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "ORIENT POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.744200",
    Latitude: "-34.911100"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "PARMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.544500",
    Latitude: "-34.967600"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "PYREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.699200",
    Latitude: "-34.916800"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "SANCTUARY POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.627100",
    Latitude: "-35.102800"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "ST GEORGES BASIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.593300",
    Latitude: "-35.110100"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "SUSSEX INLET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.570000",
    Latitude: "-35.147000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "SWANHAVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.570600",
    Latitude: "-35.187000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "TALLOWAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.224100",
    Latitude: "-34.830800"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "TAPITALLEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.522800",
    Latitude: "-34.830400"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "TERARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.628200",
    Latitude: "-34.869500"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "TOMERONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.566500",
    Latitude: "-35.049600"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "TULLARWALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.485500",
    Latitude: "-35.126000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "TWELVE MILE PEG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.391000",
    Latitude: "-35.148800"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "VINCENTIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.671900",
    Latitude: "-35.075200"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "WANDANDIAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.503100",
    Latitude: "-35.081700"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "WATERSLEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.519000",
    Latitude: "-34.855400"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "WOLLUMBOOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.732400",
    Latitude: "-34.956800"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "WOOLLAMIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.639700",
    Latitude: "-35.013800"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "WORRIGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.639000",
    Latitude: "-34.913700"
  },
  {
    Country: "Australia",
    Pcode: "2571",
    Locality: "PICTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.600600",
    Latitude: "-34.173800"
  },
  {
    Country: "Australia",
    Pcode: "2571",
    Locality: "RAZORBACK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.644100",
    Latitude: "-34.158100"
  },
  {
    Country: "Australia",
    Pcode: "2571",
    Locality: "WILTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.681500",
    Latitude: "-34.255900"
  },
  {
    Country: "Australia",
    Pcode: "2572",
    Locality: "LAKESLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.496300",
    Latitude: "-34.185500"
  },
  {
    Country: "Australia",
    Pcode: "2572",
    Locality: "THIRLMERE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.562400",
    Latitude: "-34.205200"
  },
  {
    Country: "Australia",
    Pcode: "2573",
    Locality: "TAHMOOR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.589900",
    Latitude: "-34.230600"
  },
  {
    Country: "Australia",
    Pcode: "2574",
    Locality: "AVON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.677700",
    Latitude: "-34.439900"
  },
  {
    Country: "Australia",
    Pcode: "2574",
    Locality: "BARGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.586900",
    Latitude: "-34.280100"
  },
  {
    Country: "Australia",
    Pcode: "2574",
    Locality: "PHEASANTS NEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.634300",
    Latitude: "-34.278800"
  },
  {
    Country: "Australia",
    Pcode: "2574",
    Locality: "YANDERRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.571800",
    Latitude: "-34.316900"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "ALPINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.532400",
    Latitude: "-34.410600"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "AYLMERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.498800",
    Latitude: "-34.425100"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "BALACLAVA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.482400",
    Latitude: "-34.439900"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "BRAEMAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.483100",
    Latitude: "-34.428000"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "BULLIO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.158600",
    Latitude: "-34.330900"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "COLO VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.456100",
    Latitude: "-34.394700"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "GOODMANS FORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.062000",
    Latitude: "-34.304200"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "HIGH RANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.301300",
    Latitude: "-34.324800"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "HILL TOP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.479700",
    Latitude: "-34.343900"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "JOADJA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.234900",
    Latitude: "-34.436800"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "MANDEMAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.300600",
    Latitude: "-34.437000"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "MITTAGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.480000",
    Latitude: "-34.466700"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "MOUNT LINDSEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.567000",
    Latitude: "-34.455100"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "RENWICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.473200",
    Latitude: "-34.449900"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "WATTLE RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.422700",
    Latitude: "-34.286600"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "WELBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.428600",
    Latitude: "-34.439400"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "WILLOW VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MITTAGONG",
    Category: "Delivery Area",
    Longitude: "150.471500",
    Latitude: "-34.431600"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "WOODLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MITTAGONG",
    Category: "Delivery Area",
    Longitude: "150.397600",
    Latitude: "-34.397200"
  },
  {
    Country: "Australia",
    Pcode: "2575",
    Locality: "YERRINBOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.563900",
    Latitude: "-34.345000"
  },
  {
    Country: "Australia",
    Pcode: "2576",
    Locality: "BOWRAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.420100",
    Latitude: "-34.482900"
  },
  {
    Country: "Australia",
    Pcode: "2576",
    Locality: "BURRADOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.413400",
    Latitude: "-34.511500"
  },
  {
    Country: "Australia",
    Pcode: "2576",
    Locality: "EAST BOWRAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2576",
    Locality: "EAST KANGALOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.603900",
    Latitude: "-34.525300"
  },
  {
    Country: "Australia",
    Pcode: "2576",
    Locality: "GLENQUARRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.492300",
    Latitude: "-34.531200"
  },
  {
    Country: "Australia",
    Pcode: "2576",
    Locality: "KANGALOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.531000",
    Latitude: "-34.544600"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "AVOCA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.470300",
    Latitude: "-34.596400"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "BARREN GROUNDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.731900",
    Latitude: "-34.697800"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "BARRENGARRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.519800",
    Latitude: "-34.686600"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "BEAUMONT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.574900",
    Latitude: "-34.779700"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "BELANGLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.222400",
    Latitude: "-34.512600"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "BERRIMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.331100",
    Latitude: "-34.467800"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "BUDGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.469700",
    Latitude: "-34.801300"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "BURRAWANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.501600",
    Latitude: "-34.578300"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "CANYONLEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.141300",
    Latitude: "-34.485300"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "CARRINGTON FALLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.652000",
    Latitude: "-34.642000"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "FITZROY FALLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.456200",
    Latitude: "-34.650200"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "KANGAROO VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.453200",
    Latitude: "-34.730700"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "KNIGHTS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.691600",
    Latitude: "-34.618600"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "MACQUARIE PASS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.659100",
    Latitude: "-34.561000"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "MANCHESTER SQUARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.399700",
    Latitude: "-34.601100"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "MEDWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.277900",
    Latitude: "-34.492300"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "MERYLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.357400",
    Latitude: "-34.682700"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "MOSS VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.396600",
    Latitude: "-34.554900"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "MOUNT MURRAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.637500",
    Latitude: "-34.556000"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "NEW BERRIMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.334500",
    Latitude: "-34.508000"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "PADDYS RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MOSS VALE",
    Category: "Delivery Area",
    Longitude: "150.127200",
    Latitude: "-34.620100"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "RED ROCKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.521500",
    Latitude: "-34.769700"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "ROBERTSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.608900",
    Latitude: "-34.601300"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "SUTTON FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.292000",
    Latitude: "-34.570000"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "UPPER KANGAROO RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.597700",
    Latitude: "-34.684800"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "UPPER KANGAROO VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.644900",
    Latitude: "-34.669200"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "WERAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.368700",
    Latitude: "-34.606300"
  },
  {
    Country: "Australia",
    Pcode: "2577",
    Locality: "WILDES MEADOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.522700",
    Latitude: "-34.630500"
  },
  {
    Country: "Australia",
    Pcode: "2578",
    Locality: "BUNDANOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.290000",
    Latitude: "-34.671500"
  },
  {
    Country: "Australia",
    Pcode: "2579",
    Locality: "BIG HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.995800",
    Latitude: "-34.544000"
  },
  {
    Country: "Australia",
    Pcode: "2579",
    Locality: "BRAYTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.010800",
    Latitude: "-34.630600"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "DUFFY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.033400",
    Latitude: "-35.334700"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "FISHER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.057700",
    Latitude: "-35.360300"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "HOLDER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.045600",
    Latitude: "-35.333900"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "RIVETT",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.036700",
    Latitude: "-35.347600"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "STIRLING",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.049400",
    Latitude: "-35.349300"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "STROMLO",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "URIARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.808300",
    Latitude: "-35.267600"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "URIARRA VILLAGE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.925000",
    Latitude: "-35.296100"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "WARAMANGA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.061900",
    Latitude: "-35.352100"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "WESTON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.059300",
    Latitude: "-35.330400"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "WESTON CREEK",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "WRIGHT",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.033200",
    Latitude: "-35.320600"
  },
  {
    Country: "Australia",
    Pcode: "2612",
    Locality: "BRADDON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.136700",
    Latitude: "-35.271400"
  },
  {
    Country: "Australia",
    Pcode: "2612",
    Locality: "CAMPBELL",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.154900",
    Latitude: "-35.288900"
  },
  {
    Country: "Australia",
    Pcode: "2612",
    Locality: "REID",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.141200",
    Latitude: "-35.283200"
  },
  {
    Country: "Australia",
    Pcode: "2612",
    Locality: "TURNER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.123000",
    Latitude: "-35.268000"
  },
  {
    Country: "Australia",
    Pcode: "2614",
    Locality: "ARANDA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.080900",
    Latitude: "-35.257700"
  },
  {
    Country: "Australia",
    Pcode: "2614",
    Locality: "COOK",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.066300",
    Latitude: "-35.260400"
  },
  {
    Country: "Australia",
    Pcode: "2614",
    Locality: "HAWKER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.035700",
    Latitude: "-35.247300"
  },
  {
    Country: "Australia",
    Pcode: "2614",
    Locality: "JAMISON CENTRE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2614",
    Locality: "MACQUARIE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.065500",
    Latitude: "-35.250400"
  },
  {
    Country: "Australia",
    Pcode: "2614",
    Locality: "PAGE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.050000",
    Latitude: "-35.237500"
  },
  {
    Country: "Australia",
    Pcode: "2614",
    Locality: "SCULLIN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.039400",
    Latitude: "-35.234700"
  },
  {
    Country: "Australia",
    Pcode: "2614",
    Locality: "WEETANGERA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.049900",
    Latitude: "-35.250900"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "CHARNWOOD",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.033700",
    Latitude: "-35.200400"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "DUNLOP",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.019500",
    Latitude: "-35.194800"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "FLOREY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.048100",
    Latitude: "-35.226700"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "FLYNN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.043700",
    Latitude: "-35.208600"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "FRASER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.045200",
    Latitude: "-35.191900"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "HIGGINS",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.027100",
    Latitude: "-35.233200"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "HOLT",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.014000",
    Latitude: "-35.224500"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "KIPPAX",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "LATHAM",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.030500",
    Latitude: "-35.216600"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "MACGREGOR",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.012100",
    Latitude: "-35.208800"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "MACNAMARA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "MELBA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.054400",
    Latitude: "-35.209200"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "SPENCE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.065400",
    Latitude: "-35.200300"
  },
  {
    Country: "Australia",
    Pcode: "2615",
    Locality: "STRATHNAIRN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2616",
    Locality: "BELCONNEN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2617",
    Locality: "BELCONNEN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.068300",
    Latitude: "-35.235200"
  },
  {
    Country: "Australia",
    Pcode: "2617",
    Locality: "BELCONNEN DC",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2617",
    Locality: "BRUCE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.093300",
    Latitude: "-35.245500"
  },
  {
    Country: "Australia",
    Pcode: "2617",
    Locality: "EVATT",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.071200",
    Latitude: "-35.211200"
  },
  {
    Country: "Australia",
    Pcode: "2617",
    Locality: "GIRALANG",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.097800",
    Latitude: "-35.210400"
  },
  {
    Country: "Australia",
    Pcode: "2617",
    Locality: "KALEEN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.108400",
    Latitude: "-35.226200"
  },
  {
    Country: "Australia",
    Pcode: "2617",
    Locality: "LAWSON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.087700",
    Latitude: "-35.225400"
  },
  {
    Country: "Australia",
    Pcode: "2617",
    Locality: "MCKELLAR",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.075700",
    Latitude: "-35.217500"
  },
  {
    Country: "Australia",
    Pcode: "2617",
    Locality: "UNIVERSITY OF CANBERRA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2618",
    Locality: "HALL",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.067700",
    Latitude: "-35.170300"
  },
  {
    Country: "Australia",
    Pcode: "2618",
    Locality: "SPRINGRANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.090600",
    Latitude: "-35.081800"
  },
  {
    Country: "Australia",
    Pcode: "2618",
    Locality: "WALLAROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.007300",
    Latitude: "-35.158300"
  },
  {
    Country: "Australia",
    Pcode: "2619",
    Locality: "JERRABOMBERRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.199000",
    Latitude: "-35.386100"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "BEARD",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.211100",
    Latitude: "-35.342100"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "BURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "QUEANBEYAN",
    Category: "Delivery Area",
    Longitude: "149.229900",
    Latitude: "-35.544800"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "CARWOOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.313300",
    Latitude: "-35.380600"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "CLEAR RANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.114200",
    Latitude: "-35.700900"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "CRESTWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.216200",
    Latitude: "-35.349100"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "ENVIRONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.181000",
    Latitude: "-35.409100"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "GOOGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.225400",
    Latitude: "-35.439500"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "GREENLEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.251700",
    Latitude: "-35.371100"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "GUNDAROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.265100",
    Latitude: "-35.041500"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "SOUTH ALBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.914300",
    Latitude: "-36.098600"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "SPLITTERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.847800",
    Latitude: "-36.055200"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "TABLE TOP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.023700",
    Latitude: "-35.937200"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "TALMALMO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.569300",
    Latitude: "-35.960900"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "THURGOONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.988500",
    Latitude: "-36.055600"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "WEST ALBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.886900",
    Latitude: "-36.073300"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "WIRLINGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.057200",
    Latitude: "-36.048100"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "WYMAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.255200",
    Latitude: "-35.983300"
  },
  {
    Country: "Australia",
    Pcode: "2641",
    Locality: "HAMILTON VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.896800",
    Latitude: "-36.023700"
  },
  {
    Country: "Australia",
    Pcode: "2641",
    Locality: "LAVINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.935800",
    Latitude: "-36.037000"
  },
  {
    Country: "Australia",
    Pcode: "2641",
    Locality: "SPRINGDALE HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.957600",
    Latitude: "-36.025400"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "BIDGEEMIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.451200",
    Latitude: "-35.400000"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "BROCKLESBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.660700",
    Latitude: "-35.812200"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "BURRUMBUTTOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.793000",
    Latitude: "-35.828400"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "GEEHI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.219700",
    Latitude: "-36.348700"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "GEROGERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.996600",
    Latitude: "-35.841200"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "GLENELLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.907600",
    Latitude: "-35.886300"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "GREG GREG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.055000",
    Latitude: "-36.056200"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "INDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.054300",
    Latitude: "-36.259500"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "JAGUMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.245600",
    Latitude: "-36.036300"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "JAGUNGAL WILDERNESS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.322400",
    Latitude: "-36.146500"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "JINDERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.874800",
    Latitude: "-35.959300"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "JINGELLIC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.695400",
    Latitude: "-35.897200"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "KHANCOBAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.153900",
    Latitude: "-36.259400"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "MURRAY GORGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.115600",
    Latitude: "-36.425500"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "RAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.523100",
    Latitude: "-35.570200"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "TOOMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.039000",
    Latitude: "-35.955800"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "WALBUNDRIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.711100",
    Latitude: "-35.648400"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "WELAREGANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.968500",
    Latitude: "-36.006900"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "WRATHALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.889400",
    Latitude: "-35.364900"
  },
  {
    Country: "Australia",
    Pcode: "2642",
    Locality: "YERONG CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.064200",
    Latitude: "-35.391600"
  },
  {
    Country: "Australia",
    Pcode: "2643",
    Locality: "HOWLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.611300",
    Latitude: "-35.959900"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "BOWNA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.157300",
    Latitude: "-35.980600"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "COPPABELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.750800",
    Latitude: "-35.750400"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "HOLBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.290400",
    Latitude: "-35.691600"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "LANKEYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.657500",
    Latitude: "-35.829100"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "LITTLE BILLABONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.522400",
    Latitude: "-35.621000"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "MOUNTAIN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.147200",
    Latitude: "-35.767400"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "MULLENGANDRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.177000",
    Latitude: "-35.895700"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "WANTAGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.497700",
    Latitude: "-35.798400"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "WOOMARGAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.276500",
    Latitude: "-35.863200"
  },
  {
    Country: "Australia",
    Pcode: "2644",
    Locality: "YARARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.649500",
    Latitude: "-35.718100"
  },
  {
    Country: "Australia",
    Pcode: "2645",
    Locality: "CULLIVEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.472800",
    Latitude: "-35.253000"
  },
  {
    Country: "Australia",
    Pcode: "2645",
    Locality: "URANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.212800",
    Latitude: "-35.322800"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "BALLDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.505100",
    Latitude: "-35.803600"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "COLLENDINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.232300",
    Latitude: "-35.971400"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "COREEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.317700",
    Latitude: "-35.745800"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "COROWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.362800",
    Latitude: "-35.999700"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "DAYSDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.371100",
    Latitude: "-35.610000"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "GOOMBARGANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.567400",
    Latitude: "-35.741600"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "HOPEFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.462800",
    Latitude: "-35.916900"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "LOWESDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.356700",
    Latitude: "-35.845500"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "NYORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.977700",
    Latitude: "-35.535600"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "OAKLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.145700",
    Latitude: "-35.493300"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "REDLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.340200",
    Latitude: "-35.920800"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "RENNIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.126700",
    Latitude: "-35.817600"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "RINGWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.235400",
    Latitude: "-35.873700"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "SANGER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.134900",
    Latitude: "-35.672800"
  },
  {
    Country: "Australia",
    Pcode: "2646",
    Locality: "SAVERNAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.061000",
    Latitude: "-35.744800"
  },
  {
    Country: "Australia",
    Pcode: "2647",
    Locality: "MULWALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.048900",
    Latitude: "-35.919600"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "ANABRANCH NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.823400",
    Latitude: "-33.289200"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "ANABRANCH SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.716300",
    Latitude: "-33.764800"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "CURLWAA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.976100",
    Latitude: "-34.105200"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "ELLERSLIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WENTWORTH",
    Category: "Delivery Area",
    Longitude: "142.024900",
    Latitude: "-33.821500"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "MOORARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.737800",
    Latitude: "-33.012000"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "PAN BAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.238100",
    Latitude: "-33.084100"
  },
  {
    Country: "Australia",
    Pcode: "2700",
    Locality: "MORUNDAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.226600",
    Latitude: "-34.986900"
  },
  {
    Country: "Australia",
    Pcode: "2700",
    Locality: "NARRANDERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.599800",
    Latitude: "-34.675500"
  },
  {
    Country: "Australia",
    Pcode: "2700",
    Locality: "SANDIGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.664800",
    Latitude: "-34.905200"
  },
  {
    Country: "Australia",
    Pcode: "2701",
    Locality: "BERRY JERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.243700",
    Latitude: "-34.710600"
  },
  {
    Country: "Australia",
    Pcode: "2701",
    Locality: "COOLAMON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.205100",
    Latitude: "-34.794000"
  },
  {
    Country: "Australia",
    Pcode: "2701",
    Locality: "METHUL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.105700",
    Latitude: "-34.617000"
  },
  {
    Country: "Australia",
    Pcode: "2701",
    Locality: "RANNOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.267800",
    Latitude: "-34.610600"
  },
  {
    Country: "Australia",
    Pcode: "2702",
    Locality: "GANMAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.009700",
    Latitude: "-34.794700"
  },
  {
    Country: "Australia",
    Pcode: "2703",
    Locality: "YANCO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.408400",
    Latitude: "-34.630700"
  },
  {
    Country: "Australia",
    Pcode: "2705",
    Locality: "BROBENAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.442100",
    Latitude: "-34.384000"
  },
  {
    Country: "Australia",
    Pcode: "2705",
    Locality: "CORBIE HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.502100",
    Latitude: "-34.540100"
  },
  {
    Country: "Australia",
    Pcode: "2705",
    Locality: "GOGELDRIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.277200",
    Latitude: "-34.579700"
  },
  {
    Country: "Australia",
    Pcode: "2705",
    Locality: "LEETON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.397100",
    Latitude: "-34.505900"
  },
  {
    Country: "Australia",
    Pcode: "2705",
    Locality: "MERUNGLE HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.473000",
    Latitude: "-34.618400"
  },
  {
    Country: "Australia",
    Pcode: "2705",
    Locality: "MURRAMI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.246900",
    Latitude: "-34.407800"
  },
  {
    Country: "Australia",
    Pcode: "2705",
    Locality: "STANBRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.266100",
    Latitude: "-34.511900"
  },
  {
    Country: "Australia",
    Pcode: "2705",
    Locality: "WHITTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.127500",
    Latitude: "-34.532600"
  },
  {
    Country: "Australia",
    Pcode: "2706",
    Locality: "DARLINGTON POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.752500",
    Latitude: "-34.578700"
  },
  {
    Country: "Australia",
    Pcode: "2707",
    Locality: "ARGOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.675500",
    Latitude: "-34.884800"
  },
  {
    Country: "Australia",
    Pcode: "2707",
    Locality: "COLEAMBALLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.893600",
    Latitude: "-34.802300"
  },
  {
    Country: "Australia",
    Pcode: "2708",
    Locality: "MURRAY REGION MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "BARRATTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.534200",
    Latitude: "-35.200200"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "BIRGANBIGIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.178300",
    Latitude: "-35.518200"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "BOOROORBAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.736400",
    Latitude: "-34.856000"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "BULLATALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.072300",
    Latitude: "-35.720000"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "CALDWELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.510600",
    Latitude: "-35.595900"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "CALIMO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.566300",
    Latitude: "-35.405000"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "CONARGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.205600",
    Latitude: "-35.248600"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "COREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.498100",
    Latitude: "-35.298400"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "DENILIQUIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.851200",
    Latitude: "-35.547700"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "DENILIQUIN NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "HARTWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.351400",
    Latitude: "-35.339400"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "LINDIFFERON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.186800",
    Latitude: "-35.395800"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "MATHOURA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.860700",
    Latitude: "-35.818400"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "MAYRUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.317300",
    Latitude: "-35.468600"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "MOONBRIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.440200",
    Latitude: "-35.115100"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "MORAGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.710000",
    Latitude: "-35.353200"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "PRETTY PINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.857400",
    Latitude: "-35.374300"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "STEAM PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.288100",
    Latitude: "-34.957700"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "STUD PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.963300",
    Latitude: "-35.378900"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "WAKOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.351200",
    Latitude: "-35.459000"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "WANDOOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.029300",
    Latitude: "-35.419600"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "WANGANELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.850200",
    Latitude: "-35.114200"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "WARRAGOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.131000",
    Latitude: "-35.612000"
  },
  {
    Country: "Australia",
    Pcode: "2710",
    Locality: "WILLURAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.114500",
    Latitude: "-34.984500"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "BOOLIGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.673500",
    Latitude: "-33.732100"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "CARRATHOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.450600",
    Latitude: "-34.413800"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "CLARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.983900",
    Latitude: "-33.467500"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "CORRONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.498900",
    Latitude: "-34.008700"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "GUNBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.278500",
    Latitude: "-33.908500"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "HAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.860900",
    Latitude: "-34.378300"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "HAY SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.798000",
    Latitude: "-34.656700"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "KERI KERI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.988000",
    Latitude: "-34.756000"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "MAUDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.185900",
    Latitude: "-34.493700"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "ONE TREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.895300",
    Latitude: "-34.165800"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "OXLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "HAY",
    Category: "Delivery Area",
    Longitude: "144.032400",
    Latitude: "-34.040600"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "WAUGORAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.788900",
    Latitude: "-34.512200"
  },
  {
    Country: "Australia",
    Pcode: "2711",
    Locality: "YANGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.704300",
    Latitude: "-34.731900"
  },
  {
    Country: "Australia",
    Pcode: "2712",
    Locality: "BERRIGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.828300",
    Latitude: "-35.650300"
  },
  {
    Country: "Australia",
    Pcode: "2712",
    Locality: "BOOMANOOMANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.852100",
    Latitude: "-35.875400"
  },
  {
    Country: "Australia",
    Pcode: "2713",
    Locality: "BLIGHTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.307200",
    Latitude: "-35.595900"
  },
  {
    Country: "Australia",
    Pcode: "2713",
    Locality: "FINLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.588700",
    Latitude: "-35.593200"
  },
  {
    Country: "Australia",
    Pcode: "2713",
    Locality: "LOGIE BRAE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.504200",
    Latitude: "-35.466100"
  },
  {
    Country: "Australia",
    Pcode: "2713",
    Locality: "MYRTLE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.444100",
    Latitude: "-35.549900"
  },
  {
    Country: "Australia",
    Pcode: "2714",
    Locality: "ARATULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.285200",
    Latitude: "-35.779100"
  },
  {
    Country: "Australia",
    Pcode: "2714",
    Locality: "PINE LODGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.433700",
    Latitude: "-35.659100"
  },
  {
    Country: "Australia",
    Pcode: "2714",
    Locality: "TOCUMWAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.499000",
    Latitude: "-35.790900"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "WERRINGTON DOWNS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.732100",
    Latitude: "-33.741600"
  },
  {
    Country: "Australia",
    Pcode: "2748",
    Locality: "ORCHARD HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.733600",
    Latitude: "-33.806700"
  },
  {
    Country: "Australia",
    Pcode: "2749",
    Locality: "CASTLEREAGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.681200",
    Latitude: "-33.685900"
  },
  {
    Country: "Australia",
    Pcode: "2749",
    Locality: "CRANEBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.708200",
    Latitude: "-33.706500"
  },
  {
    Country: "Australia",
    Pcode: "2750",
    Locality: "EMU HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.649200",
    Latitude: "-33.731100"
  },
  {
    Country: "Australia",
    Pcode: "2750",
    Locality: "EMU PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.667800",
    Latitude: "-33.746500"
  },
  {
    Country: "Australia",
    Pcode: "2750",
    Locality: "JAMISONTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.677100",
    Latitude: "-33.769800"
  },
  {
    Country: "Australia",
    Pcode: "2750",
    Locality: "LEONAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.649500",
    Latitude: "-33.764900"
  },
  {
    Country: "Australia",
    Pcode: "2750",
    Locality: "PENRITH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.689500",
    Latitude: "-33.745300"
  },
  {
    Country: "Australia",
    Pcode: "2750",
    Locality: "PENRITH PLAZA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2750",
    Locality: "PENRITH SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2750",
    Locality: "SOUTH PENRITH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.699300",
    Latitude: "-33.773400"
  },
  {
    Country: "Australia",
    Pcode: "2751",
    Locality: "PENRITH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2752",
    Locality: "SILVERDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.594700",
    Latitude: "-33.924800"
  },
  {
    Country: "Australia",
    Pcode: "2752",
    Locality: "WARRAGAMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.599400",
    Latitude: "-33.889400"
  },
  {
    Country: "Australia",
    Pcode: "2753",
    Locality: "AGNES BANKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.698800",
    Latitude: "-33.626300"
  },
  {
    Country: "Australia",
    Pcode: "2753",
    Locality: "BOWEN MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.622700",
    Latitude: "-33.580700"
  },
  {
    Country: "Australia",
    Pcode: "2753",
    Locality: "GROSE VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.679100",
    Latitude: "-33.587500"
  },
  {
    Country: "Australia",
    Pcode: "2753",
    Locality: "GROSE WOLD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.682200",
    Latitude: "-33.600100"
  },
  {
    Country: "Australia",
    Pcode: "2753",
    Locality: "HOBARTVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.743800",
    Latitude: "-33.604900"
  },
  {
    Country: "Australia",
    Pcode: "2753",
    Locality: "LONDONDERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.737900",
    Latitude: "-33.654000"
  },
  {
    Country: "Australia",
    Pcode: "2753",
    Locality: "RICHMOND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.773500",
    Latitude: "-33.605700"
  },
  {
    Country: "Australia",
    Pcode: "2753",
    Locality: "RICHMOND LOWLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.748100",
    Latitude: "-33.581200"
  },
  {
    Country: "Australia",
    Pcode: "2753",
    Locality: "YARRAMUNDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.639300",
    Latitude: "-33.647500"
  },
  {
    Country: "Australia",
    Pcode: "2754",
    Locality: "NORTH RICHMOND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.716900",
    Latitude: "-33.567100"
  },
  {
    Country: "Australia",
    Pcode: "2754",
    Locality: "TENNYSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.731400",
    Latitude: "-33.534400"
  },
  {
    Country: "Australia",
    Pcode: "2754",
    Locality: "THE SLOPES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.704300",
    Latitude: "-33.532700"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "BLIGH PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.798000",
    Latitude: "-33.638400"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "CATTAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.917600",
    Latitude: "-33.544000"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "CENTRAL COLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.767800",
    Latitude: "-33.422300"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "CLARENDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.789100",
    Latitude: "-33.610500"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "COLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WINDSOR",
    Category: "Delivery Area",
    Longitude: "150.805800",
    Latitude: "-33.410500"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "COLO HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.696900",
    Latitude: "-33.290000"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "CORNWALLIS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.808000",
    Latitude: "-33.587200"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "CUMBERLAND REACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.894400",
    Latitude: "-33.463300"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "EBENEZER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.888400",
    Latitude: "-33.522200"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "FREEMANS REACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.805000",
    Latitude: "-33.569500"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "GLOSSODIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.791700",
    Latitude: "-33.531400"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "LOWER PORTLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.894800",
    Latitude: "-33.441900"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "MAROOTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.969400",
    Latitude: "-33.459700"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "MCGRATHS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.842600",
    Latitude: "-33.613000"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "MELLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.737700",
    Latitude: "-33.099100"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "MULGRAVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.831700",
    Latitude: "-33.627300"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "PITT TOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.871700",
    Latitude: "-33.584100"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "PITT TOWN BOTTOMS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.845800",
    Latitude: "-33.586000"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "SACKVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.888900",
    Latitude: "-33.492400"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "SACKVILLE NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.914900",
    Latitude: "-33.486800"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "SCHEYVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.892100",
    Latitude: "-33.602800"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "SOUTH MAROOTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.957800",
    Latitude: "-33.517200"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "SOUTH WINDSOR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.804200",
    Latitude: "-33.630400"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "UPPER COLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.709400",
    Latitude: "-33.414900"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "WILBERFORCE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.855900",
    Latitude: "-33.552700"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "WINDSOR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.813700",
    Latitude: "-33.611600"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "WINDSOR DOWNS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.810700",
    Latitude: "-33.658600"
  },
  {
    Country: "Australia",
    Pcode: "2756",
    Locality: "WOMERAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.797800",
    Latitude: "-33.238000"
  },
  {
    Country: "Australia",
    Pcode: "2757",
    Locality: "KURMOND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.702900",
    Latitude: "-33.549300"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "BERAMBING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.448800",
    Latitude: "-33.531600"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "BILPIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.555100",
    Latitude: "-33.441300"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "BLAXLANDS RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.808500",
    Latitude: "-33.470600"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "EAST KURRAJONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.803200",
    Latitude: "-33.504900"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "KURRAJONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.700900",
    Latitude: "-33.489100"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "KURRAJONG HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.605900",
    Latitude: "-33.519200"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "KURRAJONG HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.655900",
    Latitude: "-33.517600"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "MOUNT TOMAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.422700",
    Latitude: "-33.543700"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "MOUNTAIN LAGOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.620300",
    Latitude: "-33.436500"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "EVANS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.478000",
    Latitude: "-33.458700"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "FITZGERALDS VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.394600",
    Latitude: "-33.523900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "FOREST GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.660900",
    Latitude: "-33.389000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "FOSTERS VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.540200",
    Latitude: "-33.614000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "FREEMANTLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.364800",
    Latitude: "-33.277900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "GEMALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.845000",
    Latitude: "-33.502600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "GEORGES PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.488700",
    Latitude: "-33.530200"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "GILMANDYKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.547900",
    Latitude: "-33.786600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "GLANMIRE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.705800",
    Latitude: "-33.412400"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "GORMANS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.595200",
    Latitude: "-33.479600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "GOWAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.358500",
    Latitude: "-33.145600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "HOBBYS YARDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.324100",
    Latitude: "-33.694000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ISABELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.675300",
    Latitude: "-33.944400"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "JEREMY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.530700",
    Latitude: "-33.992000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "JUDDS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.531000",
    Latitude: "-33.848600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "KELSO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.622600",
    Latitude: "-33.413200"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "KILLONGBUTTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.441400",
    Latitude: "-33.182000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "KIRKCONNELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.852100",
    Latitude: "-33.421100"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "LAFFING WATERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.620800",
    Latitude: "-33.367400"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "LIMEKILNS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.743900",
    Latitude: "-33.246700"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "LLANARTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.550600",
    Latitude: "-33.397800"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "LOCKSLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.798600",
    Latitude: "-33.514100"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "MEADOW FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.908700",
    Latitude: "-33.449400"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "MILKERS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.400700",
    Latitude: "-33.280800"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "MILLAH MURRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.641500",
    Latitude: "-33.172000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "MITCHELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.557700",
    Latitude: "-33.425900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "MOORILDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.324900",
    Latitude: "-33.627600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "MOUNT DAVID",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.610800",
    Latitude: "-33.841900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "MOUNT PANORAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.552100",
    Latitude: "-33.450100"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "MOUNT RANKIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.477700",
    Latitude: "-33.252800"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "NAPOLEON REEF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.747100",
    Latitude: "-33.391700"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "NEWBRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.373000",
    Latitude: "-33.583900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "O'CONNELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.748700",
    Latitude: "-33.556600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ORTON PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.573000",
    Latitude: "-33.467800"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "PALING YARDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BATHURST",
    Category: "Delivery Area",
    Longitude: "149.744500",
    Latitude: "-33.180000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "PALMERS OAKY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.855500",
    Latitude: "-33.189600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "PEEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.659500",
    Latitude: "-33.300300"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "PERTHVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.546300",
    Latitude: "-33.495400"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "RAGLAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.651100",
    Latitude: "-33.419200"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ROBIN HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.531900",
    Latitude: "-33.423900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ROCK FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.381200",
    Latitude: "-33.355300"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ROCKLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.534600",
    Latitude: "-33.700100"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ROCKLEY MOUNT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.560000",
    Latitude: "-33.553100"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "SOFALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.695300",
    Latitude: "-33.059000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "SOUTH BATHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.574400",
    Latitude: "-33.443300"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "STEWARTS MOUNT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.518700",
    Latitude: "-33.400200"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "SUNNY CORNER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.881200",
    Latitude: "-33.373900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "TANNAS MOUNT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.641800",
    Latitude: "-33.613200"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "THE LAGOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.618900",
    Latitude: "-33.545500"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "THE ROCKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BATHURST",
    Category: "Delivery Area",
    Longitude: "149.428300",
    Latitude: "-33.432000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "TRIANGLE FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.449900",
    Latitude: "-33.790000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "TRUNKEY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.337500",
    Latitude: "-33.806500"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "TURONDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.595200",
    Latitude: "-33.122800"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "TWENTY FORESTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.745600",
    Latitude: "-33.678700"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "UPPER TURON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.780900",
    Latitude: "-33.113500"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WALANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.768000",
    Latitude: "-33.451900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WAMBOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.758900",
    Latitude: "-33.505000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WATTLE FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.689600",
    Latitude: "-33.148200"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WATTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.421300",
    Latitude: "-33.312400"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WEST BATHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.563200",
    Latitude: "-33.407900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WHITE ROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.616900",
    Latitude: "-33.461500"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WIAGDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.670300",
    Latitude: "-33.216200"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WIMBLEDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.434800",
    Latitude: "-33.539200"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WINBURNDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.797100",
    Latitude: "-33.304300"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WINDRADYNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.545900",
    Latitude: "-33.405800"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "WISEMANS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.712000",
    Latitude: "-33.640300"
  },
  {
    Country: "Australia",
    Pcode: "2834",
    Locality: "ANGLEDOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.925900",
    Latitude: "-29.139500"
  },
  {
    Country: "Australia",
    Pcode: "2834",
    Locality: "LIGHTNING RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.927100",
    Latitude: "-29.331300"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "BULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.439800",
    Latitude: "-32.032900"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "CANBELEGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.249900",
    Latitude: "-31.522000"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "COBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.810500",
    Latitude: "-31.484100"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "CUBBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.209200",
    Latitude: "-31.423400"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "IRYMPLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.467800",
    Latitude: "-32.535400"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "KERRIGUNDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.212400",
    Latitude: "-30.987000"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "KULWIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.838600",
    Latitude: "-32.336800"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "NOONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.731000",
    Latitude: "-31.559000"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "SANDY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "COBAR",
    Category: "Delivery Area",
    Longitude: "145.356100",
    Latitude: "-31.932900"
  },
  {
    Country: "Australia",
    Pcode: "2835",
    Locality: "TINDAREY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.964800",
    Latitude: "-31.065400"
  },
  {
    Country: "Australia",
    Pcode: "2836",
    Locality: "WHITE CLIFFS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.117700",
    Latitude: "-30.325200"
  },
  {
    Country: "Australia",
    Pcode: "2836",
    Locality: "WILCANNIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.619600",
    Latitude: "-31.199900"
  },
  {
    Country: "Australia",
    Pcode: "2838",
    Locality: "GOODOOGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.404000",
    Latitude: "-29.310700"
  },
  {
    Country: "Australia",
    Pcode: "2839",
    Locality: "BREWARRINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.880800",
    Latitude: "-29.992400"
  },
  {
    Country: "Australia",
    Pcode: "2839",
    Locality: "COLLERINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.681500",
    Latitude: "-29.532600"
  },
  {
    Country: "Australia",
    Pcode: "2839",
    Locality: "GONGOLGON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.884100",
    Latitude: "-30.388600"
  },
  {
    Country: "Australia",
    Pcode: "2839",
    Locality: "NARRAN LAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.316500",
    Latitude: "-29.818600"
  },
  {
    Country: "Australia",
    Pcode: "2839",
    Locality: "TALAWANTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.054700",
    Latitude: "-29.598200"
  },
  {
    Country: "Australia",
    Pcode: "2839",
    Locality: "WEILMORINGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.798000",
    Latitude: "-29.217700"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "BARRINGUN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.712500",
    Latitude: "-29.009000"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "BOURKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.018700",
    Latitude: "-30.192200"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "ENNGONIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.912500",
    Latitude: "-29.274700"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "FORDS BRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.257000",
    Latitude: "-29.678400"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "GUMBALIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.196900",
    Latitude: "-30.140400"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "GUNDERBOOKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.819500",
    Latitude: "-30.612500"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "HUNGERFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.617300",
    Latitude: "-29.199600"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "LOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.950900",
    Latitude: "-30.587800"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "NORTH BOURKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.159800",
    Latitude: "-29.758100"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "TILPA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.407500",
    Latitude: "-31.082800"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "WANAARING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.139600",
    Latitude: "-29.711400"
  },
  {
    Country: "Australia",
    Pcode: "2840",
    Locality: "YANTABULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.041200",
    Latitude: "-29.301400"
  },
  {
    Country: "Australia",
    Pcode: "2842",
    Locality: "MENDOORAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.077600",
    Latitude: "-31.828400"
  },
  {
    Country: "Australia",
    Pcode: "2842",
    Locality: "MOLLYAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.201100",
    Latitude: "-31.575800"
  },
  {
    Country: "Australia",
    Pcode: "2842",
    Locality: "YARRAGRIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.242100",
    Latitude: "-31.695300"
  },
  {
    Country: "Australia",
    Pcode: "2843",
    Locality: "COOLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.765300",
    Latitude: "-31.784500"
  },
  {
    Country: "Australia",
    Pcode: "2844",
    Locality: "BIRRIWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.479800",
    Latitude: "-32.117700"
  },
  {
    Country: "Australia",
    Pcode: "2844",
    Locality: "DUNEDOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.246900",
    Latitude: "-32.041700"
  },
  {
    Country: "Australia",
    Pcode: "2844",
    Locality: "LEADVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.564800",
    Latitude: "-32.037400"
  },
  {
    Country: "Australia",
    Pcode: "2845",
    Locality: "WALLERAWANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.053800",
    Latitude: "-33.425100"
  },
  {
    Country: "Australia",
    Pcode: "2846",
    Locality: "CAPERTEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.024700",
    Latitude: "-33.119400"
  },
  {
    Country: "Australia",
    Pcode: "2846",
    Locality: "GLEN DAVIS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.221400",
    Latitude: "-33.132400"
  },
  {
    Country: "Australia",
    Pcode: "2846",
    Locality: "ROUND SWAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.966800",
    Latitude: "-33.066700"
  },
  {
    Country: "Australia",
    Pcode: "2847",
    Locality: "PORTLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.975300",
    Latitude: "-33.350000"
  },
  {
    Country: "Australia",
    Pcode: "2848",
    Locality: "BROGANS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.978800",
    Latitude: "-32.993000"
  },
  {
    Country: "Australia",
    Pcode: "2848",
    Locality: "CHARBON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.970500",
    Latitude: "-32.883800"
  },
  {
    Country: "Australia",
    Pcode: "2848",
    Locality: "CLANDULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.961600",
    Latitude: "-32.930200"
  },
  {
    Country: "Australia",
    Pcode: "2848",
    Locality: "KANDOS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.962200",
    Latitude: "-32.861600"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "BOGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.119900",
    Latitude: "-32.982600"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "BREAKFAST CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.974400",
    Latitude: "-32.659400"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "BUDDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.060700",
    Latitude: "-32.516500"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "BYLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.134700",
    Latitude: "-32.401000"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "CAMBOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.959500",
    Latitude: "-32.703700"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "CARWELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.904500",
    Latitude: "-32.839800"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "COGGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.076700",
    Latitude: "-32.376600"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "COXS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.120100",
    Latitude: "-32.719500"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "COXS CROWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.063300",
    Latitude: "-32.716100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "CLARAVALE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.656000",
    Latitude: "-14.368000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "COBOURG",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.160400",
    Latitude: "-11.334100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "COLLETT CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.926600",
    Latitude: "-12.903600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "COOMALIE CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.175900",
    Latitude: "-13.001300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "COX PENINSULA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.717400",
    Latitude: "-12.567600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "DALY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.247600",
    Latitude: "-12.742300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "DALY RIVER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.519500",
    Latitude: "-13.428900"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "DARWIN RIVER DAM",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.044500",
    Latitude: "-12.895900"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "DELISSAVILLE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "DOUGLAS-DALY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.525600",
    Latitude: "-13.859200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "EAST ARM",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.901400",
    Latitude: "-12.477400"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "EAST ARNHEM",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.778800",
    Latitude: "-12.634700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "ELRUNDIE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.940900",
    Latitude: "-12.489100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "EVA VALLEY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.003400",
    Latitude: "-13.131500"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "FINNISS VALLEY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.951700",
    Latitude: "-13.064400"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "FLEMING",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.296700",
    Latitude: "-13.852000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "FLY CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.068800",
    Latitude: "-12.797500"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "FREDS PASS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.053700",
    Latitude: "-12.541000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "GALIWINKU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.586500",
    Latitude: "-12.006100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "GLYDE POINT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.078600",
    Latitude: "-12.181800"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "GOULBURN ISLAND",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "GUNBALANYA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.004700",
    Latitude: "-12.354900"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "GUNN POINT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.017300",
    Latitude: "-12.181700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "HIDDEN VALLEY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.908100",
    Latitude: "-12.449100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "HOTHAM",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.392800",
    Latitude: "-12.384200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "HUGHES",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.100800",
    Latitude: "-12.686700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "KAKADU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.351600",
    Latitude: "-12.173200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "KOOLPINYAH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.216400",
    Latitude: "-12.370900"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "LAKE BENNETT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.198900",
    Latitude: "-12.926700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "LAMBELLS LAGOON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.227300",
    Latitude: "-12.578600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "LITCHFIELD PARK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.876700",
    Latitude: "-13.277700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "LIVINGSTONE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.055100",
    Latitude: "-12.745700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "LLOYD CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.160700",
    Latitude: "-12.677700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MANDORAH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.760600",
    Latitude: "-12.442300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MANINGRIDA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.230900",
    Latitude: "-12.052200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MAPURU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MARANUNGA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.710000",
    Latitude: "-13.709600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MARGARET RIVER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.365900",
    Latitude: "-13.234100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MARRAKAI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.446600",
    Latitude: "-12.721700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MCMINNS LAGOON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.067100",
    Latitude: "-12.539100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MICKETT CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.952300",
    Latitude: "-12.365100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MIDDLE POINT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.326700",
    Latitude: "-12.604300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MILIKAPITI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.659300",
    Latitude: "-11.417000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MILINGIMBI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.914500",
    Latitude: "-12.100100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MILYAKBURRA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.201400",
    Latitude: "-13.778300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MINJILANG",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.572400",
    Latitude: "-11.144900"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MOUNT BUNDEY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.750300",
    Latitude: "-13.034700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "MURRUMUJUK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.042700",
    Latitude: "-12.271600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "NAUIYU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.721300",
    Latitude: "-13.725500"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "NEMARLUK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.141100",
    Latitude: "-14.044100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "NGANMARRIYANGA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.876700",
    Latitude: "-14.329500"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "NUMBULWAR",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.742100",
    Latitude: "-14.272200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "NUMBURINDI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.875500",
    Latitude: "-13.834600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "OENPELLI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "PEPPIMENARTI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.079200",
    Latitude: "-14.147800"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "PIRLANGIMPI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.424100",
    Latitude: "-11.393800"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "POINT STEPHENS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "POINT STUART",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.872500",
    Latitude: "-12.523600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "PULARUMPI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "RAKULA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.444700",
    Latitude: "-13.021900"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "RAMINGINING",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.936900",
    Latitude: "-12.330900"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "ROBIN FALLS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.128700",
    Latitude: "-13.368000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "RUM JUNGLE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.029500",
    Latitude: "-12.994600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "SANDPALMS ROADHOUSE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "MOUNT ZEIL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.443800",
    Latitude: "-23.282000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "MURPUTJA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.181800",
    Latitude: "-26.163300"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "MUTITJULU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.067300",
    Latitude: "-25.350600"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "NAMATJIRA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.730500",
    Latitude: "-23.992600"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "NAPPERBY STATION",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "NGAANYATJARRA-GILES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "WA LOCALITY- VIA ALICE SPRINGS",
    Category: "Delivery Area",
    Longitude: "127.919300",
    Latitude: "-25.094300"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "NYAPARI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.206100",
    Latitude: "-26.182800"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "NYIRRIPI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.565700",
    Latitude: "-22.646900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "PAPUNYA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.912300",
    Latitude: "-23.202700"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "PATJARR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "PETERMANN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.722100",
    Latitude: "-25.066900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "PIPALYATJARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.143500",
    Latitude: "-26.174500"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "PUKATJA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.143300",
    Latitude: "-26.288200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "SANDOVER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.529900",
    Latitude: "-21.982400"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "SANTA TERESA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.453600",
    Latitude: "-23.997200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "SIMPSON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.700300",
    Latitude: "-24.942400"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "TANAMI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.512700",
    Latitude: "-19.840800"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "TARA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.998800",
    Latitude: "-21.485900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "TELEGRAPH STATION",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "TI TREE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.413900",
    Latitude: "-22.133800"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "TIEYON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.867100",
    Latitude: "-26.358500"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "TITJIKALA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.075000",
    Latitude: "-24.678900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "TJIRRKARLI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "TJUKURLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "UMUWA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.025500",
    Latitude: "-26.478900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "WALLACE ROCKHOLE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.092200",
    Latitude: "-24.122800"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "WANARN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "WANARN COMMUNITY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "WATARRU",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.903800",
    Latitude: "-27.025700"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "WATINUMA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "WILLOWRA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.620700",
    Latitude: "-21.264200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "WILORA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.726600",
    Latitude: "-21.744300"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "WINGELLINA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "WUTUNUGURRA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.250400",
    Latitude: "-20.462300"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "YUELAMU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.203500",
    Latitude: "-22.265300"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "YUENDUMU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.790800",
    Latitude: "-22.258700"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "YULARA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.970000",
    Latitude: "-25.206900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "YUNYARINYI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.451200",
    Latitude: "-26.321000"
  },
  {
    Country: "Australia",
    Pcode: "0873",
    Locality: "AMOONGUNA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: "133.930200",
    Latitude: "-23.768300"
  },
  {
    Country: "Australia",
    Pcode: "0873",
    Locality: "ARUMBERA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: "133.808800",
    Latitude: "-23.780000"
  },
  {
    Country: "Australia",
    Pcode: "0873",
    Locality: "CONNELLAN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: "133.906100",
    Latitude: "-23.795100"
  },
  {
    Country: "Australia",
    Pcode: "0873",
    Locality: "HEAVITREE GAP CPA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0873",
    Locality: "ILPARPA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: "133.827700",
    Latitude: "-23.744900"
  },
  {
    Country: "Australia",
    Pcode: "0873",
    Locality: "KILGARIFF",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: "133.881700",
    Latitude: "-23.772600"
  },
  {
    Country: "Australia",
    Pcode: "0873",
    Locality: "ROSS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: "133.908200",
    Latitude: "-23.746200"
  },
  {
    Country: "Australia",
    Pcode: "0874",
    Locality: "IRLPME",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.865200",
    Latitude: "-23.647600"
  },
  {
    Country: "Australia",
    Pcode: "0874",
    Locality: "MOUNT JOHNS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.905600",
    Latitude: "-23.723000"
  },
  {
    Country: "Australia",
    Pcode: "0874",
    Locality: "UNDOOLYA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.918700",
    Latitude: "-23.687100"
  },
  {
    Country: "Australia",
    Pcode: "0875",
    Locality: "FLYNN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.833000",
    Latitude: "-23.711900"
  },
  {
    Country: "Australia",
    Pcode: "0875",
    Locality: "LARAPINTA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.832500",
    Latitude: "-23.681700"
  },
  {
    Country: "Australia",
    Pcode: "0880",
    Locality: "GAPUWIYAK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.796200",
    Latitude: "-12.503400"
  },
  {
    Country: "Australia",
    Pcode: "0880",
    Locality: "GUNYANGARA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.704100",
    Latitude: "-12.223500"
  },
  {
    Country: "Australia",
    Pcode: "0880",
    Locality: "NHULUNBUY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.763200",
    Latitude: "-12.203800"
  },
  {
    Country: "Australia",
    Pcode: "0880",
    Locality: "YIRRKALA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.877300",
    Latitude: "-12.246100"
  },
  {
    Country: "Australia",
    Pcode: "0881",
    Locality: "NHULUNBUY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0885",
    Locality: "ALYANGULA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.420700",
    Latitude: "-13.852200"
  },
  {
    Country: "Australia",
    Pcode: "0886",
    Locality: "JABIRU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.837200",
    Latitude: "-12.669900"
  },
  {
    Country: "Australia",
    Pcode: "2036",
    Locality: "HILLSDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.227500",
    Latitude: "-33.952200"
  },
  {
    Country: "Australia",
    Pcode: "2036",
    Locality: "LA PEROUSE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.243500",
    Latitude: "-33.991300"
  },
  {
    Country: "Australia",
    Pcode: "2036",
    Locality: "LITTLE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.245200",
    Latitude: "-33.983400"
  },
  {
    Country: "Australia",
    Pcode: "2036",
    Locality: "MALABAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.254000",
    Latitude: "-33.958100"
  },
  {
    Country: "Australia",
    Pcode: "2036",
    Locality: "MATRAVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.229800",
    Latitude: "-33.964000"
  },
  {
    Country: "Australia",
    Pcode: "2036",
    Locality: "PHILLIP BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.232900",
    Latitude: "-33.979400"
  },
  {
    Country: "Australia",
    Pcode: "2036",
    Locality: "PORT BOTANY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.217100",
    Latitude: "-33.975000"
  },
  {
    Country: "Australia",
    Pcode: "2037",
    Locality: "FOREST LODGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.178400",
    Latitude: "-33.880600"
  },
  {
    Country: "Australia",
    Pcode: "2037",
    Locality: "GLEBE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.187200",
    Latitude: "-33.877800"
  },
  {
    Country: "Australia",
    Pcode: "2038",
    Locality: "ANNANDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.169700",
    Latitude: "-33.880600"
  },
  {
    Country: "Australia",
    Pcode: "2039",
    Locality: "ROZELLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.173300",
    Latitude: "-33.864500"
  },
  {
    Country: "Australia",
    Pcode: "2040",
    Locality: "LEICHHARDT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.155700",
    Latitude: "-33.881900"
  },
  {
    Country: "Australia",
    Pcode: "2040",
    Locality: "LILYFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.160300",
    Latitude: "-33.871000"
  },
  {
    Country: "Australia",
    Pcode: "2041",
    Locality: "BALMAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.180900",
    Latitude: "-33.858300"
  },
  {
    Country: "Australia",
    Pcode: "2041",
    Locality: "BALMAIN EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.192100",
    Latitude: "-33.857100"
  },
  {
    Country: "Australia",
    Pcode: "2041",
    Locality: "BIRCHGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.181800",
    Latitude: "-33.850700"
  },
  {
    Country: "Australia",
    Pcode: "2042",
    Locality: "ENMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.170000",
    Latitude: "-33.900100"
  },
  {
    Country: "Australia",
    Pcode: "2042",
    Locality: "NEWTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.180200",
    Latitude: "-33.897600"
  },
  {
    Country: "Australia",
    Pcode: "2043",
    Locality: "ERSKINEVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.186700",
    Latitude: "-33.902200"
  },
  {
    Country: "Australia",
    Pcode: "2044",
    Locality: "ST PETERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.178100",
    Latitude: "-33.915900"
  },
  {
    Country: "Australia",
    Pcode: "2044",
    Locality: "ST PETERS DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2044",
    Locality: "SYDENHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.168400",
    Latitude: "-33.916900"
  },
  {
    Country: "Australia",
    Pcode: "2044",
    Locality: "TEMPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.164600",
    Latitude: "-33.924700"
  },
  {
    Country: "Australia",
    Pcode: "2045",
    Locality: "HABERFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.138900",
    Latitude: "-33.879700"
  },
  {
    Country: "Australia",
    Pcode: "2046",
    Locality: "ABBOTSFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.129800",
    Latitude: "-33.850300"
  },
  {
    Country: "Australia",
    Pcode: "2046",
    Locality: "CANADA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.115000",
    Latitude: "-33.864500"
  },
  {
    Country: "Australia",
    Pcode: "2046",
    Locality: "CHISWICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.137900",
    Latitude: "-33.849700"
  },
  {
    Country: "Australia",
    Pcode: "2046",
    Locality: "FIVE DOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.128000",
    Latitude: "-33.866300"
  },
  {
    Country: "Australia",
    Pcode: "2046",
    Locality: "RODD POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.144000",
    Latitude: "-33.865800"
  },
  {
    Country: "Australia",
    Pcode: "2046",
    Locality: "RUSSELL LEA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.140700",
    Latitude: "-33.858300"
  },
  {
    Country: "Australia",
    Pcode: "2046",
    Locality: "WAREEMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.131900",
    Latitude: "-33.857500"
  },
  {
    Country: "Australia",
    Pcode: "2047",
    Locality: "DRUMMOYNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.152800",
    Latitude: "-33.852000"
  },
  {
    Country: "Australia",
    Pcode: "2048",
    Locality: "STANMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.165200",
    Latitude: "-33.893600"
  },
  {
    Country: "Australia",
    Pcode: "2048",
    Locality: "WESTGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2049",
    Locality: "LEWISHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.146600",
    Latitude: "-33.895200"
  },
  {
    Country: "Australia",
    Pcode: "2049",
    Locality: "PETERSHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.155500",
    Latitude: "-33.894800"
  },
  {
    Country: "Australia",
    Pcode: "2049",
    Locality: "PETERSHAM NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2050",
    Locality: "CAMPERDOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.182200",
    Latitude: "-33.888600"
  },
  {
    Country: "Australia",
    Pcode: "2050",
    Locality: "MISSENDEN ROAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2055",
    Locality: "NORTH SYDNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "COMPETITION MAIL",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2057",
    Locality: "CHATSWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2058",
    Locality: "NORTHERN SUBURBS MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2059",
    Locality: "NORTH SYDNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2060",
    Locality: "HMAS PLATYPUS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2060",
    Locality: "HMAS WATERHEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2060",
    Locality: "LAVENDER BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.207100",
    Latitude: "-33.843800"
  },
  {
    Country: "Australia",
    Pcode: "2060",
    Locality: "MCMAHONS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.203000",
    Latitude: "-33.844800"
  },
  {
    Country: "Australia",
    Pcode: "2060",
    Locality: "NORTH SYDNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.209500",
    Latitude: "-33.835400"
  },
  {
    Country: "Australia",
    Pcode: "2060",
    Locality: "NORTH SYDNEY SHOPPINGWORLD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2060",
    Locality: "WAVERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.198800",
    Latitude: "-33.838100"
  },
  {
    Country: "Australia",
    Pcode: "2061",
    Locality: "KIRRIBILLI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.216100",
    Latitude: "-33.847400"
  },
  {
    Country: "Australia",
    Pcode: "2061",
    Locality: "MILSONS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.211500",
    Latitude: "-33.846900"
  },
  {
    Country: "Australia",
    Pcode: "2062",
    Locality: "CAMMERAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.214200",
    Latitude: "-33.822700"
  },
  {
    Country: "Australia",
    Pcode: "2063",
    Locality: "NORTHBRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.217400",
    Latitude: "-33.811100"
  },
  {
    Country: "Australia",
    Pcode: "2064",
    Locality: "ARTARMON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.184700",
    Latitude: "-33.811900"
  },
  {
    Country: "Australia",
    Pcode: "2065",
    Locality: "CROWS NEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.203600",
    Latitude: "-33.825200"
  },
  {
    Country: "Australia",
    Pcode: "2065",
    Locality: "CROWS NEST DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2065",
    Locality: "GREENWICH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.185500",
    Latitude: "-33.829700"
  },
  {
    Country: "Australia",
    Pcode: "2065",
    Locality: "NAREMBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.202000",
    Latitude: "-33.817300"
  },
  {
    Country: "Australia",
    Pcode: "2065",
    Locality: "ROYAL NORTH SHORE HOSPITAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2065",
    Locality: "ST LEONARDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.193000",
    Latitude: "-33.822300"
  },
  {
    Country: "Australia",
    Pcode: "2065",
    Locality: "WOLLSTONECRAFT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.195700",
    Latitude: "-33.833100"
  },
  {
    Country: "Australia",
    Pcode: "2066",
    Locality: "LANE COVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.164900",
    Latitude: "-33.818000"
  },
  {
    Country: "Australia",
    Pcode: "2066",
    Locality: "LANE COVE DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2140",
    Locality: "HOMEBUSH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.080700",
    Latitude: "-33.860400"
  },
  {
    Country: "Australia",
    Pcode: "2140",
    Locality: "HOMEBUSH SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2140",
    Locality: "HOMEBUSH WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.068000",
    Latitude: "-33.862800"
  },
  {
    Country: "Australia",
    Pcode: "2141",
    Locality: "BERALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.033500",
    Latitude: "-33.874500"
  },
  {
    Country: "Australia",
    Pcode: "2141",
    Locality: "LIDCOMBE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.049100",
    Latitude: "-33.855100"
  },
  {
    Country: "Australia",
    Pcode: "2141",
    Locality: "LIDCOMBE NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2141",
    Locality: "ROOKWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.056300",
    Latitude: "-33.874700"
  },
  {
    Country: "Australia",
    Pcode: "2142",
    Locality: "BLAXCELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2142",
    Locality: "CAMELLIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.034500",
    Latitude: "-33.819300"
  },
  {
    Country: "Australia",
    Pcode: "2142",
    Locality: "CLYDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.021800",
    Latitude: "-33.833200"
  },
  {
    Country: "Australia",
    Pcode: "2142",
    Locality: "GRANVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.007200",
    Latitude: "-33.836600"
  },
  {
    Country: "Australia",
    Pcode: "2142",
    Locality: "HOLROYD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.997100",
    Latitude: "-33.830000"
  },
  {
    Country: "Australia",
    Pcode: "2142",
    Locality: "ROSEHILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.030400",
    Latitude: "-33.826400"
  },
  {
    Country: "Australia",
    Pcode: "2142",
    Locality: "SOUTH GRANVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.010700",
    Latitude: "-33.857700"
  },
  {
    Country: "Australia",
    Pcode: "2143",
    Locality: "BIRRONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.021000",
    Latitude: "-33.891800"
  },
  {
    Country: "Australia",
    Pcode: "2143",
    Locality: "POTTS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.032700",
    Latitude: "-33.892600"
  },
  {
    Country: "Australia",
    Pcode: "2143",
    Locality: "REGENTS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.026300",
    Latitude: "-33.882300"
  },
  {
    Country: "Australia",
    Pcode: "2143",
    Locality: "REGENTS PARK DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2144",
    Locality: "AUBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.028200",
    Latitude: "-33.851000"
  },
  {
    Country: "Australia",
    Pcode: "2145",
    Locality: "CONSTITUTION HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.975800",
    Latitude: "-33.793100"
  },
  {
    Country: "Australia",
    Pcode: "2145",
    Locality: "GIRRAWEEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.944100",
    Latitude: "-33.800900"
  },
  {
    Country: "Australia",
    Pcode: "2145",
    Locality: "GREYSTANES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.947400",
    Latitude: "-33.823700"
  },
  {
    Country: "Australia",
    Pcode: "2145",
    Locality: "MAYS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.989500",
    Latitude: "-33.821100"
  },
  {
    Country: "Australia",
    Pcode: "2145",
    Locality: "PEMULWUY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.923700",
    Latitude: "-33.819700"
  },
  {
    Country: "Australia",
    Pcode: "2145",
    Locality: "PENDLE HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.955300",
    Latitude: "-33.804100"
  },
  {
    Country: "Australia",
    Pcode: "2145",
    Locality: "SOUTH WENTWORTHVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.971600",
    Latitude: "-33.821700"
  },
  {
    Country: "Australia",
    Pcode: "2145",
    Locality: "WENTWORTHVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.969600",
    Latitude: "-33.806800"
  },
  {
    Country: "Australia",
    Pcode: "2145",
    Locality: "WESTMEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.986900",
    Latitude: "-33.809600"
  },
  {
    Country: "Australia",
    Pcode: "2146",
    Locality: "OLD TOONGABBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.971000",
    Latitude: "-33.784100"
  },
  {
    Country: "Australia",
    Pcode: "2146",
    Locality: "TOONGABBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.958200",
    Latitude: "-33.791900"
  },
  {
    Country: "Australia",
    Pcode: "2146",
    Locality: "TOONGABBIE EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2147",
    Locality: "KINGS LANGLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.936900",
    Latitude: "-33.750300"
  },
  {
    Country: "Australia",
    Pcode: "2147",
    Locality: "LALOR PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.931100",
    Latitude: "-33.760600"
  },
  {
    Country: "Australia",
    Pcode: "2147",
    Locality: "SEVEN HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.936100",
    Latitude: "-33.777400"
  },
  {
    Country: "Australia",
    Pcode: "2147",
    Locality: "SEVEN HILLS WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2148",
    Locality: "ARNDELL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.882000",
    Latitude: "-33.790300"
  },
  {
    Country: "Australia",
    Pcode: "2148",
    Locality: "BLACKTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.903100",
    Latitude: "-33.777700"
  },
  {
    Country: "Australia",
    Pcode: "2148",
    Locality: "BLACKTOWN WESTPOINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2148",
    Locality: "HUNTINGWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.883200",
    Latitude: "-33.797600"
  },
  {
    Country: "Australia",
    Pcode: "2148",
    Locality: "KINGS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.908500",
    Latitude: "-33.743600"
  },
  {
    Country: "Australia",
    Pcode: "2148",
    Locality: "MARAYONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.891800",
    Latitude: "-33.749200"
  },
  {
    Country: "Australia",
    Pcode: "2148",
    Locality: "PROSPECT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.895200",
    Latitude: "-33.812400"
  },
  {
    Country: "Australia",
    Pcode: "2150",
    Locality: "HARRIS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.011900",
    Latitude: "-33.823000"
  },
  {
    Country: "Australia",
    Pcode: "2150",
    Locality: "PARRAMATTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.008000",
    Latitude: "-33.814900"
  },
  {
    Country: "Australia",
    Pcode: "2150",
    Locality: "PARRAMATTA WESTFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2151",
    Locality: "NORTH PARRAMATTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.011600",
    Latitude: "-33.795500"
  },
  {
    Country: "Australia",
    Pcode: "2151",
    Locality: "NORTH ROCKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.018100",
    Latitude: "-33.775700"
  },
  {
    Country: "Australia",
    Pcode: "2152",
    Locality: "NORTHMEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.993400",
    Latitude: "-33.790000"
  },
  {
    Country: "Australia",
    Pcode: "2153",
    Locality: "BAULKHAM HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.982800",
    Latitude: "-33.754400"
  },
  {
    Country: "Australia",
    Pcode: "2153",
    Locality: "BELLA VISTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.954600",
    Latitude: "-33.743700"
  },
  {
    Country: "Australia",
    Pcode: "2153",
    Locality: "WINSTON HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.981700",
    Latitude: "-33.777200"
  },
  {
    Country: "Australia",
    Pcode: "2154",
    Locality: "CASTLE HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.999400",
    Latitude: "-33.730200"
  },
  {
    Country: "Australia",
    Pcode: "2155",
    Locality: "BEAUMONT HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.940800",
    Latitude: "-33.697800"
  },
  {
    Country: "Australia",
    Pcode: "2155",
    Locality: "KELLYVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.956200",
    Latitude: "-33.703800"
  },
  {
    Country: "Australia",
    Pcode: "2155",
    Locality: "KELLYVILLE RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.920500",
    Latitude: "-33.702200"
  },
  {
    Country: "Australia",
    Pcode: "2155",
    Locality: "ROUSE HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.914900",
    Latitude: "-33.677600"
  },
  {
    Country: "Australia",
    Pcode: "2156",
    Locality: "ANNANGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.941800",
    Latitude: "-33.655400"
  },
  {
    Country: "Australia",
    Pcode: "2156",
    Locality: "GLENHAVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.997900",
    Latitude: "-33.702700"
  },
  {
    Country: "Australia",
    Pcode: "2156",
    Locality: "KENTHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.973400",
    Latitude: "-33.642600"
  },
  {
    Country: "Australia",
    Pcode: "2157",
    Locality: "CANOELANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.090500",
    Latitude: "-33.500300"
  },
  {
    Country: "Australia",
    Pcode: "2157",
    Locality: "FOREST GLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.028200",
    Latitude: "-33.547400"
  },
  {
    Country: "Australia",
    Pcode: "2157",
    Locality: "GLENORIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.986000",
    Latitude: "-33.566600"
  },
  {
    Country: "Australia",
    Pcode: "2158",
    Locality: "DURAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.048900",
    Latitude: "-33.684900"
  },
  {
    Country: "Australia",
    Pcode: "2158",
    Locality: "DURAL DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2158",
    Locality: "MIDDLE DURAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.017700",
    Latitude: "-33.638400"
  },
  {
    Country: "Australia",
    Pcode: "2232",
    Locality: "SUTHERLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.054400",
    Latitude: "-34.035000"
  },
  {
    Country: "Australia",
    Pcode: "2232",
    Locality: "WORONORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.041600",
    Latitude: "-34.028800"
  },
  {
    Country: "Australia",
    Pcode: "2233",
    Locality: "ENGADINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.008900",
    Latitude: "-34.057700"
  },
  {
    Country: "Australia",
    Pcode: "2233",
    Locality: "HEATHCOTE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.978700",
    Latitude: "-34.110500"
  },
  {
    Country: "Australia",
    Pcode: "2233",
    Locality: "WATERFALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.989500",
    Latitude: "-34.140400"
  },
  {
    Country: "Australia",
    Pcode: "2233",
    Locality: "WORONORA HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.030200",
    Latitude: "-34.034000"
  },
  {
    Country: "Australia",
    Pcode: "2233",
    Locality: "YARRAWARRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.034500",
    Latitude: "-34.054300"
  },
  {
    Country: "Australia",
    Pcode: "2234",
    Locality: "ALFORDS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.026400",
    Latitude: "-33.987800"
  },
  {
    Country: "Australia",
    Pcode: "2234",
    Locality: "BANGOR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.032300",
    Latitude: "-34.016700"
  },
  {
    Country: "Australia",
    Pcode: "2234",
    Locality: "BARDEN RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.005200",
    Latitude: "-34.035100"
  },
  {
    Country: "Australia",
    Pcode: "2234",
    Locality: "ILLAWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.040600",
    Latitude: "-33.998400"
  },
  {
    Country: "Australia",
    Pcode: "2234",
    Locality: "LUCAS HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.977800",
    Latitude: "-34.037000"
  },
  {
    Country: "Australia",
    Pcode: "2234",
    Locality: "MENAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.002200",
    Latitude: "-34.003100"
  },
  {
    Country: "Australia",
    Pcode: "2234",
    Locality: "MENAI CENTRAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "BUCKETTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.135700",
    Latitude: "-33.103000"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "CALGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.225200",
    Latitude: "-33.427700"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "CENTRAL MANGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.251700",
    Latitude: "-33.283400"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "EAST GOSFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.354400",
    Latitude: "-33.435100"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "ERINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.382300",
    Latitude: "-33.437500"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "ERINA FAIR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "GLENWORTH VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.179000",
    Latitude: "-33.408900"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "GOSFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.340600",
    Latitude: "-33.424400"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "GREENGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.158500",
    Latitude: "-33.380100"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "HOLGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.405000",
    Latitude: "-33.406100"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "KARIONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.273100",
    Latitude: "-33.460000"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "KULNURA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.198600",
    Latitude: "-33.201900"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "LISAROW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.384400",
    Latitude: "-33.384900"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "LOWER MANGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.134000",
    Latitude: "-33.401900"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "MANGROVE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.121800",
    Latitude: "-33.351500"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "MANGROVE MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.183800",
    Latitude: "-33.323400"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "MATCHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.429200",
    Latitude: "-33.404100"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "MOONEY MOONEY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.218900",
    Latitude: "-33.467000"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "MOUNT ELLIOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.385200",
    Latitude: "-33.402300"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "MOUNT WHITE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.194100",
    Latitude: "-33.451900"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "NARARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.335200",
    Latitude: "-33.395500"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "NIAGARA PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.350400",
    Latitude: "-33.376200"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "NORTH GOSFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.354100",
    Latitude: "-33.418800"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "PEATS RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.231100",
    Latitude: "-33.344700"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "POINT CLARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.321600",
    Latitude: "-33.440000"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "POINT FREDERICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.340900",
    Latitude: "-33.445000"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "SOMERSBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.284000",
    Latitude: "-33.373200"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "SPRINGFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GOSFORD",
    Category: "Delivery Area",
    Longitude: "151.376300",
    Latitude: "-33.427500"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "TASCOTT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.320900",
    Latitude: "-33.451800"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "TEN MILE HOLLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.085600",
    Latitude: "-33.323100"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "UPPER MANGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.122300",
    Latitude: "-33.287200"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "WENDOREE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.160200",
    Latitude: "-33.455800"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "WEST GOSFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.322600",
    Latitude: "-33.421100"
  },
  {
    Country: "Australia",
    Pcode: "2250",
    Locality: "WYOMING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.364900",
    Latitude: "-33.405300"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "AVOCA BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.427200",
    Latitude: "-33.470500"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "BENSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.393000",
    Latitude: "-33.496900"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "BOUDDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.401100",
    Latitude: "-33.516800"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "COPACABANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.436300",
    Latitude: "-33.486300"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "DAVISTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.359800",
    Latitude: "-33.484700"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "GREEN POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GOSFORD",
    Category: "Delivery Area",
    Longitude: "151.366900",
    Latitude: "-33.453900"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "KINCUMBER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.392900",
    Latitude: "-33.471000"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "KINCUMBER SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.377600",
    Latitude: "-33.483500"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "MACMASTERS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.411300",
    Latitude: "-33.497200"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "PICKETTS VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.407000",
    Latitude: "-33.457600"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "SARATOGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.351400",
    Latitude: "-33.475500"
  },
  {
    Country: "Australia",
    Pcode: "2251",
    Locality: "YATTALUNGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.366200",
    Latitude: "-33.468100"
  },
  {
    Country: "Australia",
    Pcode: "2252",
    Locality: "CENTRAL COAST MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "BLACKWALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.334900",
    Latitude: "-33.504900"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "HORSFIELD BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.302100",
    Latitude: "-33.492400"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "KOOLEWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.321000",
    Latitude: "-33.468400"
  },
  {
    Country: "Australia",
    Pcode: "2256",
    Locality: "LITTLE WOBBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.249900",
    Latitude: "-33.544000"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "KAHIBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.711800",
    Latitude: "-32.962200"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "MOUNT HUTTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.669600",
    Latitude: "-32.977300"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "REDHEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.708100",
    Latitude: "-33.011200"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "TINGIRA HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.665300",
    Latitude: "-32.997800"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "WHITEBRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.716100",
    Latitude: "-32.973800"
  },
  {
    Country: "Australia",
    Pcode: "2291",
    Locality: "MEREWETHER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.742300",
    Latitude: "-32.948500"
  },
  {
    Country: "Australia",
    Pcode: "2291",
    Locality: "MEREWETHER HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.733900",
    Latitude: "-32.946700"
  },
  {
    Country: "Australia",
    Pcode: "2291",
    Locality: "THE JUNCTION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.759800",
    Latitude: "-32.938100"
  },
  {
    Country: "Australia",
    Pcode: "2292",
    Locality: "BROADMEADOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.730000",
    Latitude: "-32.922600"
  },
  {
    Country: "Australia",
    Pcode: "2292",
    Locality: "HAMILTON NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.737700",
    Latitude: "-32.913400"
  },
  {
    Country: "Australia",
    Pcode: "2293",
    Locality: "MARYVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.755100",
    Latitude: "-32.911400"
  },
  {
    Country: "Australia",
    Pcode: "2293",
    Locality: "WICKHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.756000",
    Latitude: "-32.919700"
  },
  {
    Country: "Australia",
    Pcode: "2294",
    Locality: "CARRINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.767900",
    Latitude: "-32.912200"
  },
  {
    Country: "Australia",
    Pcode: "2295",
    Locality: "FERN BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.812300",
    Latitude: "-32.864100"
  },
  {
    Country: "Australia",
    Pcode: "2295",
    Locality: "STOCKTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.793800",
    Latitude: "-32.888900"
  },
  {
    Country: "Australia",
    Pcode: "2296",
    Locality: "ISLINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.749100",
    Latitude: "-32.914500"
  },
  {
    Country: "Australia",
    Pcode: "2297",
    Locality: "TIGHES HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.754000",
    Latitude: "-32.905200"
  },
  {
    Country: "Australia",
    Pcode: "2298",
    Locality: "GEORGETOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.732000",
    Latitude: "-32.909400"
  },
  {
    Country: "Australia",
    Pcode: "2298",
    Locality: "WARATAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.724800",
    Latitude: "-32.905400"
  },
  {
    Country: "Australia",
    Pcode: "2298",
    Locality: "WARATAH WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.712700",
    Latitude: "-32.898800"
  },
  {
    Country: "Australia",
    Pcode: "2299",
    Locality: "JESMOND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.694200",
    Latitude: "-32.903800"
  },
  {
    Country: "Australia",
    Pcode: "2299",
    Locality: "LAMBTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.705800",
    Latitude: "-32.913400"
  },
  {
    Country: "Australia",
    Pcode: "2299",
    Locality: "NORTH LAMBTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.705000",
    Latitude: "-32.903600"
  },
  {
    Country: "Australia",
    Pcode: "2300",
    Locality: "BAR BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.767600",
    Latitude: "-32.939300"
  },
  {
    Country: "Australia",
    Pcode: "2300",
    Locality: "COOKS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.767300",
    Latitude: "-32.933600"
  },
  {
    Country: "Australia",
    Pcode: "2300",
    Locality: "NEWCASTLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.781700",
    Latitude: "-32.927400"
  },
  {
    Country: "Australia",
    Pcode: "2300",
    Locality: "NEWCASTLE EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.790000",
    Latitude: "-32.926700"
  },
  {
    Country: "Australia",
    Pcode: "2300",
    Locality: "THE HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.776300",
    Latitude: "-32.933200"
  },
  {
    Country: "Australia",
    Pcode: "2302",
    Locality: "NEWCASTLE WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.760200",
    Latitude: "-32.928700"
  },
  {
    Country: "Australia",
    Pcode: "2303",
    Locality: "HAMILTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.745300",
    Latitude: "-32.920900"
  },
  {
    Country: "Australia",
    Pcode: "2303",
    Locality: "HAMILTON DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2303",
    Locality: "HAMILTON EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.754600",
    Latitude: "-32.927100"
  },
  {
    Country: "Australia",
    Pcode: "2303",
    Locality: "HAMILTON SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.746600",
    Latitude: "-32.933000"
  },
  {
    Country: "Australia",
    Pcode: "2304",
    Locality: "KOORAGANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.748600",
    Latitude: "-32.864000"
  },
  {
    Country: "Australia",
    Pcode: "2304",
    Locality: "MAYFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "NEWCASTLE",
    Category: "Delivery Area",
    Longitude: "151.737400",
    Latitude: "-32.898000"
  },
  {
    Country: "Australia",
    Pcode: "2304",
    Locality: "MAYFIELD EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.750000",
    Latitude: "-32.899400"
  },
  {
    Country: "Australia",
    Pcode: "2304",
    Locality: "MAYFIELD NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.751100",
    Latitude: "-32.890200"
  },
  {
    Country: "Australia",
    Pcode: "2304",
    Locality: "MAYFIELD WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.726400",
    Latitude: "-32.886600"
  },
  {
    Country: "Australia",
    Pcode: "2304",
    Locality: "SANDGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.706400",
    Latitude: "-32.867900"
  },
  {
    Country: "Australia",
    Pcode: "2304",
    Locality: "WARABROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.715900",
    Latitude: "-32.886000"
  },
  {
    Country: "Australia",
    Pcode: "2305",
    Locality: "KOTARA EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2305",
    Locality: "NEW LAMBTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.710700",
    Latitude: "-32.927800"
  },
  {
    Country: "Australia",
    Pcode: "2305",
    Locality: "NEW LAMBTON HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.694400",
    Latitude: "-32.927000"
  },
  {
    Country: "Australia",
    Pcode: "2306",
    Locality: "WINDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.681600",
    Latitude: "-32.991600"
  },
  {
    Country: "Australia",
    Pcode: "2307",
    Locality: "SHORTLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.690500",
    Latitude: "-32.879100"
  },
  {
    Country: "Australia",
    Pcode: "2308",
    Locality: "CALLAGHAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.702200",
    Latitude: "-32.890300"
  },
  {
    Country: "Australia",
    Pcode: "2308",
    Locality: "NEWCASTLE UNIVERSITY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2309",
    Locality: "DANGAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2310",
    Locality: "HUNTER REGION MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "ALLYNBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.541700",
    Latitude: "-32.349900"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "BINGLEBURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.615900",
    Latitude: "-32.364100"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "CARRABOLLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.393500",
    Latitude: "-32.179400"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "EAST GRESFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.562100",
    Latitude: "-32.402500"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "ECCLESTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.487600",
    Latitude: "-32.256000"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "GRESFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.499400",
    Latitude: "-32.415100"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "HALTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.539800",
    Latitude: "-32.284800"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "LEWINSBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.598300",
    Latitude: "-32.436000"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "LOSTOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.443600",
    Latitude: "-32.325800"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "MOUNT RIVERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.472900",
    Latitude: "-32.366800"
  },
  {
    Country: "Australia",
    Pcode: "2311",
    Locality: "UPPER ALLYN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.465900",
    Latitude: "-32.134900"
  },
  {
    Country: "Australia",
    Pcode: "2312",
    Locality: "MINIMBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.374800",
    Latitude: "-32.150600"
  },
  {
    Country: "Australia",
    Pcode: "2312",
    Locality: "NABIAC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.433300",
    Latitude: "-32.124500"
  },
  {
    Country: "Australia",
    Pcode: "2315",
    Locality: "CORLETTE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.114500",
    Latitude: "-32.724800"
  },
  {
    Country: "Australia",
    Pcode: "2315",
    Locality: "FINGAL BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.160300",
    Latitude: "-32.750000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "GLENNIES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.106800",
    Latitude: "-32.448200"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "GLENRIDDING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.158700",
    Latitude: "-32.585100"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "GOORANGOOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.205700",
    Latitude: "-32.284000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "GOULDSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.090100",
    Latitude: "-32.578200"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "GOWRIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SINGLETON",
    Category: "Delivery Area",
    Longitude: "151.145600",
    Latitude: "-32.566300"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "GREENLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SINGLETON",
    Category: "Delivery Area",
    Longitude: "151.175200",
    Latitude: "-32.362000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "HAMBLEDON HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.126100",
    Latitude: "-32.599500"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "HEBDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.057000",
    Latitude: "-32.340400"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "HOWES VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.861000",
    Latitude: "-32.815500"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "HOWICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.932500",
    Latitude: "-32.435200"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "HUNTERVIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.179700",
    Latitude: "-32.539700"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "JERRYS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.841800",
    Latitude: "-32.432600"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "LEMINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.960600",
    Latitude: "-32.482100"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "LONG POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.118800",
    Latitude: "-32.565000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "MAISON DIEU",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.093200",
    Latitude: "-32.539500"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "MCDOUGALLS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.146400",
    Latitude: "-32.545600"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "MIDDLE FALBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.146900",
    Latitude: "-32.445600"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "MILBRODALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.959400",
    Latitude: "-32.719900"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "MIRANNIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.368600",
    Latitude: "-32.383500"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "MITCHELLS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.300700",
    Latitude: "-32.556700"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "MOUNT OLIVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SINGLETON",
    Category: "Delivery Area",
    Longitude: "151.225100",
    Latitude: "-32.405200"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "MOUNT ROYAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.300000",
    Latitude: "-32.199800"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "MOUNT THORLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.119000",
    Latitude: "-32.649800"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "OBANVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.158700",
    Latitude: "-32.515400"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "PUTTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.638400",
    Latitude: "-32.885700"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "RAVENSWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.067400",
    Latitude: "-32.417400"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "REDBOURNBERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.192200",
    Latitude: "-32.564300"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "REEDY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SINGLETON",
    Category: "Delivery Area",
    Longitude: "151.337400",
    Latitude: "-32.452700"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "RIXS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.133000",
    Latitude: "-32.520100"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "ROUGHIT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.249600",
    Latitude: "-32.574500"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "SCOTTS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.246100",
    Latitude: "-32.603400"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "SEDGEFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.233000",
    Latitude: "-32.544700"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "SINGLETON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.175300",
    Latitude: "-32.568800"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "SINGLETON DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "SINGLETON HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.162000",
    Latitude: "-32.541100"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "ST CLAIR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SINGLETON",
    Category: "Delivery Area",
    Longitude: "151.263100",
    Latitude: "-32.363100"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "WARKWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.016800",
    Latitude: "-32.555900"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "WATTLE PONDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.186500",
    Latitude: "-32.521700"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "WESTBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.290900",
    Latitude: "-32.458600"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "WHITTINGHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.215300",
    Latitude: "-32.614300"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "WOLLEMI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.473300",
    Latitude: "-32.955600"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "WYLIES FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.119400",
    Latitude: "-32.580600"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "BAERAMI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.457600",
    Latitude: "-32.402700"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "BAERAMI CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.492200",
    Latitude: "-32.574900"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "BENGALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.816800",
    Latitude: "-32.288800"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "CASTLE ROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.758000",
    Latitude: "-32.236300"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "EDDERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.835200",
    Latitude: "-32.352100"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "GUNGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.481100",
    Latitude: "-32.238300"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "KAYUGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.836700",
    Latitude: "-32.204700"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "LIDDELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.004000",
    Latitude: "-32.436500"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "MANOBALAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.635700",
    Latitude: "-32.173900"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "MCCULLYS GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.998700",
    Latitude: "-32.213300"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "MUSCLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.997800",
    Latitude: "-32.281100"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "MUSWELLBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.899900",
    Latitude: "-32.310500"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "SANDY HOLLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.557800",
    Latitude: "-32.351500"
  },
  {
    Country: "Australia",
    Pcode: "2333",
    Locality: "WYBONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.651700",
    Latitude: "-32.276700"
  },
  {
    Country: "Australia",
    Pcode: "2334",
    Locality: "GRETA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.385300",
    Latitude: "-32.690800"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "BELFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.284400",
    Latitude: "-32.675400"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "BRANXTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.363600",
    Latitude: "-32.641500"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "DALWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BRANXTON",
    Category: "Delivery Area",
    Longitude: "151.413900",
    Latitude: "-32.628500"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "EAST BRANXTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.365300",
    Latitude: "-32.655200"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "ELDERSLIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BRANXTON",
    Category: "Delivery Area",
    Longitude: "151.346800",
    Latitude: "-32.586200"
  },
  {
    Country: "Australia",
    Pcode: "2335",
    Locality: "LAMBS VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BRANXTON",
    Category: "Delivery Area",
    Longitude: "151.464400",
    Latitude: "-32.582200"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "TANNABAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.169700",
    Latitude: "-31.355100"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "ULAMAMBRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.388000",
    Latitude: "-31.306300"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "WATTLE SPRINGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.290300",
    Latitude: "-31.439500"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "ARDING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.521000",
    Latitude: "-30.576500"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "BALALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.285300",
    Latitude: "-30.618400"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "GOSTWYCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.610000",
    Latitude: "-30.705100"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "KINGSTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.103600",
    Latitude: "-30.488500"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "MIHI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.703500",
    Latitude: "-30.719000"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "ROCKY RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.455800",
    Latitude: "-30.597700"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "SALISBURY PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.616800",
    Latitude: "-30.789100"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "TORRYBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "URALLA",
    Category: "Delivery Area",
    Longitude: "151.186800",
    Latitude: "-30.437800"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "URALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.488600",
    Latitude: "-30.666600"
  },
  {
    Country: "Australia",
    Pcode: "2358",
    Locality: "YARROWYCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.357900",
    Latitude: "-30.442700"
  },
  {
    Country: "Australia",
    Pcode: "2359",
    Locality: "ABERDEEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BUNDARRA",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2359",
    Locality: "BAKERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BUNDARRA",
    Category: "Delivery Area",
    Longitude: "150.999300",
    Latitude: "-30.317300"
  },
  {
    Country: "Australia",
    Pcode: "2359",
    Locality: "BUNDARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.004300",
    Latitude: "-30.096500"
  },
  {
    Country: "Australia",
    Pcode: "2359",
    Locality: "CAMERONS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.092300",
    Latitude: "-30.328600"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "AUBURN VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.025600",
    Latitude: "-29.837500"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "BRODIES PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.199200",
    Latitude: "-29.812900"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "BUKKULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.105900",
    Latitude: "-29.522300"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "CHERRY TREE HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.012600",
    Latitude: "-29.557700"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "COPETON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.938900",
    Latitude: "-29.878400"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "ELSMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.270200",
    Latitude: "-29.828800"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "GILGAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.120800",
    Latitude: "-29.882300"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "GRAMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.925100",
    Latitude: "-29.441900"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "GUM FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.927800",
    Latitude: "-29.794300"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "HOWELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.044800",
    Latitude: "-29.937800"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "INVERELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.112700",
    Latitude: "-29.762300"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "KINGS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "INVERELL",
    Category: "Delivery Area",
    Longitude: "151.402600",
    Latitude: "-29.599000"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "LITTLE PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.946200",
    Latitude: "-29.732900"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "LONG PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "INVERELL",
    Category: "Delivery Area",
    Longitude: "151.231100",
    Latitude: "-29.770500"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "MOUNT RUSSELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.930800",
    Latitude: "-29.662300"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "NEWSTEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.379300",
    Latitude: "-29.825500"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "NULLAMANNA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.241300",
    Latitude: "-29.611100"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "OAKWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.053300",
    Latitude: "-29.640800"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "PARADISE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.466900",
    Latitude: "-29.887200"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "ROB ROY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.004800",
    Latitude: "-29.720900"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "SAPPHIRE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.352300",
    Latitude: "-29.670600"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "SPRING MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.492800",
    Latitude: "-29.831500"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "STANBOROUGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.049600",
    Latitude: "-29.986000"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "SWANBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.242100",
    Latitude: "-29.713800"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "WALLANGRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.917400",
    Latitude: "-29.266100"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "WANDERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.146900",
    Latitude: "-29.669800"
  },
  {
    Country: "Australia",
    Pcode: "2360",
    Locality: "WOODSTOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "INVERELL",
    Category: "Delivery Area",
    Longitude: "151.356600",
    Latitude: "-29.739100"
  },
  {
    Country: "Australia",
    Pcode: "2361",
    Locality: "ASHFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.178700",
    Latitude: "-29.291200"
  },
  {
    Country: "Australia",
    Pcode: "2361",
    Locality: "ATHOLWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.047600",
    Latitude: "-29.050400"
  },
  {
    Country: "Australia",
    Pcode: "2361",
    Locality: "BONSHAW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.231100",
    Latitude: "-29.024300"
  },
  {
    Country: "Australia",
    Pcode: "2361",
    Locality: "LIMESTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.053600",
    Latitude: "-29.213600"
  },
  {
    Country: "Australia",
    Pcode: "2361",
    Locality: "PINDAROI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.282100",
    Latitude: "-29.471900"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "BACKWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.908700",
    Latitude: "-30.052400"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "BALD BLAIR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.815700",
    Latitude: "-30.173200"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "BALDERSLEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.478500",
    Latitude: "-30.242200"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "BASSENDEAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.181000",
    Latitude: "-30.060400"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "BEN LOMOND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.608000",
    Latitude: "-30.005900"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "BLACK MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.695000",
    Latitude: "-30.329100"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "BRIARBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.359200",
    Latitude: "-30.178700"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "BROCKLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.861200",
    Latitude: "-30.259500"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "BRUSHY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.518300",
    Latitude: "-30.183800"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "FALCONER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.727900",
    Latitude: "-30.174300"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "GEORGES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.191200",
    Latitude: "-30.151700"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "GLEN NEVIS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.229000",
    Latitude: "-29.924500"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "GLENCOE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.710200",
    Latitude: "-29.935300"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "GREEN HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GUYRA",
    Category: "Delivery Area",
    Longitude: "151.784200",
    Latitude: "-30.267400"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "GUYRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.654100",
    Latitude: "-30.215500"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "LLANGOTHLIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.668600",
    Latitude: "-30.098100"
  },
  {
    Country: "Australia",
    Pcode: "2365",
    Locality: "MAYBOLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.567300",
    Latitude: "-29.889600"
  },
  {
    Country: "Australia",
    Pcode: "2421",
    Locality: "SUMMER HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "DUNGOG",
    Category: "Delivery Area",
    Longitude: "151.494000",
    Latitude: "-32.469200"
  },
  {
    Country: "Australia",
    Pcode: "2421",
    Locality: "TOCAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.568400",
    Latitude: "-32.620500"
  },
  {
    Country: "Australia",
    Pcode: "2421",
    Locality: "TORRYBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "DUNGOG",
    Category: "Delivery Area",
    Longitude: "151.578400",
    Latitude: "-32.473700"
  },
  {
    Country: "Australia",
    Pcode: "2421",
    Locality: "VACY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.573600",
    Latitude: "-32.533400"
  },
  {
    Country: "Australia",
    Pcode: "2421",
    Locality: "WEBBERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.516300",
    Latitude: "-32.584900"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BACK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GLOUCESTER",
    Category: "Delivery Area",
    Longitude: "152.047900",
    Latitude: "-31.962200"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BAKERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GLOUCESTER",
    Category: "Delivery Area",
    Longitude: "152.099600",
    Latitude: "-31.982400"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BARRINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.900900",
    Latitude: "-31.991100"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BARRINGTON TOPS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.529700",
    Latitude: "-31.959000"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BAXTERS RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.904900",
    Latitude: "-31.713100"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BELBORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.172900",
    Latitude: "-31.979300"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BERRICO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.791900",
    Latitude: "-32.089500"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BINDERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.824900",
    Latitude: "-32.024200"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BOWMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.822100",
    Latitude: "-31.922900"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BOWMAN FARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.929700",
    Latitude: "-31.905600"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BRETTI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.907900",
    Latitude: "-31.796500"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BULLIAC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.020300",
    Latitude: "-31.922000"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "BUNDOOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.095600",
    Latitude: "-31.896000"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "CALLAGHANS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.015000",
    Latitude: "-31.852200"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "COBARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.685400",
    Latitude: "-31.916100"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "CONEAC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.814500",
    Latitude: "-31.856200"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "COPELAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.821600",
    Latitude: "-31.983400"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "CRAVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.894900",
    Latitude: "-32.134500"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "CRAVEN PLATEAU",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.773100",
    Latitude: "-31.874500"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "CURRICABARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.668100",
    Latitude: "-31.724000"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "DEWITT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.771500",
    Latitude: "-31.794800"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "FAULKLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.890800",
    Latitude: "-32.068500"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "FORBESDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.962000",
    Latitude: "-32.075200"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "GANGAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.072500",
    Latitude: "-32.020100"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "GIRO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.764900",
    Latitude: "-31.681000"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "GLEN WARD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.649800",
    Latitude: "-31.822900"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "GLOUCESTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.962400",
    Latitude: "-32.012000"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "GLOUCESTER TOPS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.609800",
    Latitude: "-32.061500"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "INVERGORDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.713500",
    Latitude: "-32.053300"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "KIA ORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.976700",
    Latitude: "-31.957100"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "MARES RUN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.800600",
    Latitude: "-31.638000"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "MERNOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.576700",
    Latitude: "-31.688600"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "MOGRANI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.029900",
    Latitude: "-32.026600"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "MOPPY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.620400",
    Latitude: "-31.986600"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "RAWDON VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.725100",
    Latitude: "-31.997800"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "ROOKHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.886200",
    Latitude: "-31.882600"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "STRATFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.951000",
    Latitude: "-32.115900"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "TERREEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.047700",
    Latitude: "-32.213300"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "TIBBUC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.903700",
    Latitude: "-31.834300"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "TITAATEE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.112700",
    Latitude: "-32.044900"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "TUGRABAKH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.010900",
    Latitude: "-31.986500"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "WALLANBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.084700",
    Latitude: "-32.078600"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "WARDS RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.941700",
    Latitude: "-32.218100"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "WAUKIVORY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.046700",
    Latitude: "-32.103200"
  },
  {
    Country: "Australia",
    Pcode: "2422",
    Locality: "WOKO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.838400",
    Latitude: "-31.809900"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "BOMBAH POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.271300",
    Latitude: "-32.500100"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "BOOLAMBAYTE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.275000",
    Latitude: "-32.399500"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "BULAHDELAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.163500",
    Latitude: "-32.388700"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "BUNGWAHL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.425200",
    Latitude: "-32.367000"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "COOLONGOLOOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.321200",
    Latitude: "-32.219300"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "CRAWFORD RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.115300",
    Latitude: "-32.394600"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "MARKWELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.170900",
    Latitude: "-32.302900"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "MAYERS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.315500",
    Latitude: "-32.406100"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "MUNGO BRUSH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.335500",
    Latitude: "-32.498400"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "MYALL LAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.389300",
    Latitude: "-32.429400"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "NERONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.178500",
    Latitude: "-32.526900"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "SEAL ROCKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.506400",
    Latitude: "-32.424200"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "TOPI TOPI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.341000",
    Latitude: "-32.360200"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "UPPER MYALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.155700",
    Latitude: "-32.256400"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "VIOLET HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.320300",
    Latitude: "-32.453100"
  },
  {
    Country: "Australia",
    Pcode: "2423",
    Locality: "WANG WAUK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.291500",
    Latitude: "-32.142400"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "TAMBAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.817300",
    Latitude: "-30.883700"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "TELEGRAPH POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.774800",
    Latitude: "-31.329900"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "UPPER ROLLANDS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.608900",
    Latitude: "-31.223300"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "YARRAHAPINNI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.946900",
    Latitude: "-30.819500"
  },
  {
    Country: "Australia",
    Pcode: "2442",
    Locality: "MID NORTH COAST MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "CAMDEN HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.831000",
    Latitude: "-31.642600"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "CORALVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.703600",
    Latitude: "-31.794000"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "CROWDY BAY NATIONAL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.731000",
    Latitude: "-31.800400"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "DEAUVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.788100",
    Latitude: "-31.672700"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "DIAMOND HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.768900",
    Latitude: "-31.699100"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "DUNBOGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.814400",
    Latitude: "-31.657200"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "HANNAM VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.584300",
    Latitude: "-31.713100"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "JOHNS RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.704200",
    Latitude: "-31.731500"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "LAKEWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.765600",
    Latitude: "-31.635000"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "LAURIETON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.795200",
    Latitude: "-31.643900"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "MIDDLE BROTHER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.709500",
    Latitude: "-31.689700"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "MOORLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.651500",
    Latitude: "-31.780500"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "NORTH BROTHER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.774900",
    Latitude: "-31.656600"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "NORTH HAVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.820500",
    Latitude: "-31.636000"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "STEWARTS RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.634400",
    Latitude: "-31.714200"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "WAITUI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.544800",
    Latitude: "-31.684500"
  },
  {
    Country: "Australia",
    Pcode: "2443",
    Locality: "WEST HAVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.783800",
    Latitude: "-31.635300"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "BLACKMANS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.831400",
    Latitude: "-31.390400"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "FERNBANK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.844700",
    Latitude: "-31.420400"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "FLYNNS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "LIGHTHOUSE BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "LIMEBURNERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PORT MACQUARIE",
    Category: "Delivery Area",
    Longitude: "152.912500",
    Latitude: "-31.335000"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "NORTH SHORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.910500",
    Latitude: "-31.393100"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "PORT MACQUARIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.896000",
    Latitude: "-31.461200"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "PORT MACQUARIE BC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "RIVERSIDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.870000",
    Latitude: "-31.393700"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "SETTLEMENT CITY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "THE HATCH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.815600",
    Latitude: "-31.359400"
  },
  {
    Country: "Australia",
    Pcode: "2444",
    Locality: "THRUMSTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.840200",
    Latitude: "-31.458400"
  },
  {
    Country: "Australia",
    Pcode: "2445",
    Locality: "BONNY HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.817600",
    Latitude: "-31.577900"
  },
  {
    Country: "Australia",
    Pcode: "2445",
    Locality: "GRANTS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.822300",
    Latitude: "-31.617600"
  },
  {
    Country: "Australia",
    Pcode: "2445",
    Locality: "JOLLY NOSE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.782000",
    Latitude: "-31.595000"
  },
  {
    Country: "Australia",
    Pcode: "2445",
    Locality: "LAKE CATHIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.838900",
    Latitude: "-31.546600"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "BAGNOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.531400",
    Latitude: "-31.473100"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "BAGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.677800",
    Latitude: "-31.518700"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "BANDA BANDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.463100",
    Latitude: "-31.201200"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "BEECHWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.657000",
    Latitude: "-31.445200"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "BELLANGRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.582000",
    Latitude: "-31.326400"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "BIRDWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.317000",
    Latitude: "-31.356900"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "BROMBIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.631300",
    Latitude: "-31.457700"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "BYABARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.539400",
    Latitude: "-31.534100"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "CAIRNCROSS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.690200",
    Latitude: "-31.343200"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "CROSSLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.715200",
    Latitude: "-31.440100"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "DEBENHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.247200",
    Latitude: "-31.477200"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "DOYLES RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.127400",
    Latitude: "-31.456900"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "ELLENBOROUGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.424300",
    Latitude: "-31.480600"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "FORBES RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.332300",
    Latitude: "-31.229000"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "FRAZERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.623500",
    Latitude: "-31.419900"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "HARTYS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.624900",
    Latitude: "-31.473800"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "HOLLISDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.543100",
    Latitude: "-31.389200"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "HUNTINGDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.651900",
    Latitude: "-31.463800"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "HYNDMANS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.593000",
    Latitude: "-31.472900"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "KINDEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.429800",
    Latitude: "-31.392600"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "KING CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.757600",
    Latitude: "-31.494600"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "LAKE INNES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.813300",
    Latitude: "-31.503700"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "LONG FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.482100",
    Latitude: "-31.435400"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "LOWER PAPPINBARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.586700",
    Latitude: "-31.419100"
  },
  {
    Country: "Australia",
    Pcode: "0200",
    Locality: "AUSTRALIAN NATIONAL UNIVERSITY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0800",
    Locality: "DARWIN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.843400",
    Latitude: "-12.463300"
  },
  {
    Country: "Australia",
    Pcode: "0801",
    Locality: "DARWIN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "GPO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0803",
    Locality: "WAGAIT BEACH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0804",
    Locality: "PARAP",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "ALAWA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.873800",
    Latitude: "-12.379800"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "BRINKIN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.865900",
    Latitude: "-12.365600"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "CASUARINA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.881300",
    Latitude: "-12.374200"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "COCONUT GROVE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.849100",
    Latitude: "-12.396000"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "JINGILI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.872000",
    Latitude: "-12.389200"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "LEE POINT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.888000",
    Latitude: "-12.336300"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "LYONS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.889000",
    Latitude: "-12.358500"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "MILLNER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.862700",
    Latitude: "-12.391600"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "MOIL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.881600",
    Latitude: "-12.389400"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "MUIRHEAD",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.895500",
    Latitude: "-12.358300"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "NAKARA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.877500",
    Latitude: "-12.370600"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "NIGHTCLIFF",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.840800",
    Latitude: "-12.390200"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "RAPID CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.848200",
    Latitude: "-12.371800"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "TIWI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.874700",
    Latitude: "-12.357400"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "WAGAMAN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.884700",
    Latitude: "-12.381100"
  },
  {
    Country: "Australia",
    Pcode: "0810",
    Locality: "WANGURI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.887500",
    Latitude: "-12.371500"
  },
  {
    Country: "Australia",
    Pcode: "0811",
    Locality: "CASUARINA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0812",
    Locality: "ANULA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.890500",
    Latitude: "-12.390200"
  },
  {
    Country: "Australia",
    Pcode: "0812",
    Locality: "BUFFALO CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.917700",
    Latitude: "-12.348100"
  },
  {
    Country: "Australia",
    Pcode: "0812",
    Locality: "HOLMES",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.923900",
    Latitude: "-12.387600"
  },
  {
    Country: "Australia",
    Pcode: "0812",
    Locality: "KARAMA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.915500",
    Latitude: "-12.400300"
  },
  {
    Country: "Australia",
    Pcode: "0812",
    Locality: "LEANYER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.903600",
    Latitude: "-12.367700"
  },
  {
    Country: "Australia",
    Pcode: "0812",
    Locality: "MALAK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.903900",
    Latitude: "-12.392700"
  },
  {
    Country: "Australia",
    Pcode: "0812",
    Locality: "MARRARA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.895300",
    Latitude: "-12.398900"
  },
  {
    Country: "Australia",
    Pcode: "0812",
    Locality: "WULAGI",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.895100",
    Latitude: "-12.383000"
  },
  {
    Country: "Australia",
    Pcode: "0813",
    Locality: "KARAMA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0814",
    Locality: "NIGHTCLIFF",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0815",
    Locality: "CHARLES DARWIN UNIVERSITY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "BAYVIEW",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.856900",
    Latitude: "-12.439200"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "CHARLES DARWIN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "COONAWARRA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.907200",
    Latitude: "-12.434000"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "DARWIN DC",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "EAST POINT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.825500",
    Latitude: "-12.408300"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "EATON",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.882200",
    Latitude: "-12.410700"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "FANNIE BAY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.836600",
    Latitude: "-12.427500"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "LARRAKEYAH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.825000",
    Latitude: "-12.455300"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "LUDMILLA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.846700",
    Latitude: "-12.411700"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "PARAP",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.844200",
    Latitude: "-12.434000"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "STUART PARK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.848100",
    Latitude: "-12.446300"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "THE GARDENS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.831600",
    Latitude: "-12.446300"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "THE NARROWS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.859500",
    Latitude: "-12.427100"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "WINNELLIE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.883400",
    Latitude: "-12.429500"
  },
  {
    Country: "Australia",
    Pcode: "0820",
    Locality: "WOOLNER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.850500",
    Latitude: "-12.434900"
  },
  {
    Country: "Australia",
    Pcode: "0821",
    Locality: "WINNELLIE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "ACACIA HILLS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.141900",
    Latitude: "-12.770300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "ANGURUGU",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.461300",
    Latitude: "-13.980200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "ANINDILYAKWA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.559300",
    Latitude: "-13.951400"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "ANNIE RIVER",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "BATHURST ISLAND",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "BEES CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.055000",
    Latitude: "-12.572700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "BELYUEN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.683100",
    Latitude: "-12.538100"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "BLACK JUNGLE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.210400",
    Latitude: "-12.521300"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "BLACKMORE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.921200",
    Latitude: "-12.695500"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "BURRUNDIE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.861000",
    Latitude: "-13.389800"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "BYNOE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.635900",
    Latitude: "-12.791200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "BYNOE HARBOUR",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.533500",
    Latitude: "-12.636200"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "CAMP CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.934600",
    Latitude: "-13.185700"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "CHANNEL ISLAND",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.860100",
    Latitude: "-12.552600"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "CHARLES DARWIN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.876000",
    Latitude: "-12.451000"
  },
  {
    Country: "Australia",
    Pcode: "0822",
    Locality: "CHARLOTTE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.793900",
    Latitude: "-12.844200"
  },
  {
    Country: "Australia",
    Pcode: "0862",
    Locality: "TENNANT CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BAGS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0862",
    Locality: "WARREGO",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "ALICE SPRINGS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.880100",
    Latitude: "-23.698200"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "ARALUEN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.852000",
    Latitude: "-23.697000"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "BRAITLING",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.863400",
    Latitude: "-23.674300"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "CICCONE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.869700",
    Latitude: "-23.692200"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "DESERT SPRINGS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.888200",
    Latitude: "-23.712200"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "EAST SIDE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.896100",
    Latitude: "-23.692100"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "GILLEN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.866000",
    Latitude: "-23.710800"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "SADADEEN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.900100",
    Latitude: "-23.702500"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "STUART",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.878700",
    Latitude: "-23.676300"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "THE GAP",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.873400",
    Latitude: "-23.715200"
  },
  {
    Country: "Australia",
    Pcode: "0870",
    Locality: "WHITE GUMS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.756500",
    Latitude: "-23.750300"
  },
  {
    Country: "Australia",
    Pcode: "0871",
    Locality: "ALICE SPRINGS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ALI CURUNG",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.321200",
    Latitude: "-21.024100"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ALICE SPRINGS",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: "PO BAGS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "AMATA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.174100",
    Latitude: "-26.129000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "AMPILATWATJA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.242400",
    Latitude: "-21.664900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ANANGU PITJANTJATJARA YANKUNYTJATJARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "REMOTE BOUNDED LOCALITY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ANATYE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.989000",
    Latitude: "-23.064000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ANMATJERE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.317700",
    Latitude: "-22.106900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "AREYONGA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.271600",
    Latitude: "-24.085500"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ATITJERE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.932500",
    Latitude: "-22.982000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "AYERS RANGE SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.283500",
    Latitude: "-26.190900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "BARROW CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "BURT PLAIN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.473500",
    Latitude: "-23.389200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "CANTEEN CREEK",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.586800",
    Latitude: "-20.637100"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "CHILLA WELL",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.427200",
    Latitude: "-21.458300"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "COSTELLO",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.987800",
    Latitude: "-20.920200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "DAVENPORT",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.919500",
    Latitude: "-20.896100"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "DE ROSE HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.322800",
    Latitude: "-26.534200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ENGAWALA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.457500",
    Latitude: "-22.804300"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ERLDUNDA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ERNABELLA (PUKATJA)",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "DUAL LOCALITY NAMES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "FINKE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.575700",
    Latitude: "-25.572700"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "GHAN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.173800",
    Latitude: "-25.066800"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "GIBSON DESERT NORTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "WA LOCALITY- VIA ALICE SPRINGS",
    Category: "Delivery Area",
    Longitude: "126.909200",
    Latitude: "-22.284200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "GIBSON DESERT SOUTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "WA LOCALITY- VIA ALICE SPRINGS",
    Category: "Delivery Area",
    Longitude: "126.241900",
    Latitude: "-25.096800"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "HAASTS BLUFF",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.864200",
    Latitude: "-23.452200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "HALE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.285200",
    Latitude: "-24.047700"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "HART",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.142400",
    Latitude: "-23.035100"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "HART RANGE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "HERMANNSBURG",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.797900",
    Latitude: "-23.934700"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "HUGH",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.610000",
    Latitude: "-24.112200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "IMANPA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.568200",
    Latitude: "-25.118400"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "INDULKANA (IWANTJA)",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "DUAL LOCALITY NAMES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "ININTI STORE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "IWANTJA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "KALKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.152600",
    Latitude: "-26.114200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "KALTJITI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.029900",
    Latitude: "-26.768800"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "KALTUKATJARA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.096200",
    Latitude: "-24.877400"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "KANPI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.115700",
    Latitude: "-26.164000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "KINGS CREEK STATION",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "KINTORE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.384400",
    Latitude: "-23.273600"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "KIWIRRKURRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "CARE PO",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "KULGERA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "KUNPARRKA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.636200",
    Latitude: "-23.336900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "LAKE MACKAY",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "129.829400",
    Latitude: "-22.568000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "LAMBINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.046000",
    Latitude: "-26.997900"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "LARAMBA",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.761600",
    Latitude: "-22.541000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "MACDONNELL RANGE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "MEREENIE",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.826100",
    Latitude: "-23.894200"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "MIMILI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.698600",
    Latitude: "-27.024400"
  },
  {
    Country: "Australia",
    Pcode: "0872",
    Locality: "MOUNT LIEBIG",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.277600",
    Latitude: "-23.268000"
  },
  {
    Country: "Australia",
    Pcode: "2007",
    Locality: "BROADWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2007",
    Locality: "ULTIMO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.198400",
    Latitude: "-33.879900"
  },
  {
    Country: "Australia",
    Pcode: "2008",
    Locality: "CHIPPENDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.200000",
    Latitude: "-33.886200"
  },
  {
    Country: "Australia",
    Pcode: "2008",
    Locality: "DARLINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "CHIPPENDALE",
    Category: "Delivery Area",
    Longitude: "151.192700",
    Latitude: "-33.890800"
  },
  {
    Country: "Australia",
    Pcode: "2009",
    Locality: "PYRMONT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.192600",
    Latitude: "-33.868600"
  },
  {
    Country: "Australia",
    Pcode: "2010",
    Locality: "DARLINGHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.218900",
    Latitude: "-33.879400"
  },
  {
    Country: "Australia",
    Pcode: "2010",
    Locality: "SURRY HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.210000",
    Latitude: "-33.884900"
  },
  {
    Country: "Australia",
    Pcode: "2011",
    Locality: "ELIZABETH BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.228300",
    Latitude: "-33.871600"
  },
  {
    Country: "Australia",
    Pcode: "2011",
    Locality: "POTTS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.226000",
    Latitude: "-33.865500"
  },
  {
    Country: "Australia",
    Pcode: "2011",
    Locality: "RUSHCUTTERS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.228600",
    Latitude: "-33.874500"
  },
  {
    Country: "Australia",
    Pcode: "2011",
    Locality: "WOOLLOOMOOLOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.218400",
    Latitude: "-33.872200"
  },
  {
    Country: "Australia",
    Pcode: "2012",
    Locality: "STRAWBERRY HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2015",
    Locality: "ALEXANDRIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.192000",
    Latitude: "-33.908200"
  },
  {
    Country: "Australia",
    Pcode: "2015",
    Locality: "BEACONSFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.200400",
    Latitude: "-33.911400"
  },
  {
    Country: "Australia",
    Pcode: "2015",
    Locality: "EVELEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.192700",
    Latitude: "-33.895700"
  },
  {
    Country: "Australia",
    Pcode: "2016",
    Locality: "REDFERN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.207300",
    Latitude: "-33.893100"
  },
  {
    Country: "Australia",
    Pcode: "2017",
    Locality: "WATERLOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.207900",
    Latitude: "-33.900300"
  },
  {
    Country: "Australia",
    Pcode: "2017",
    Locality: "WATERLOO DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2017",
    Locality: "ZETLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.208100",
    Latitude: "-33.907600"
  },
  {
    Country: "Australia",
    Pcode: "2018",
    Locality: "EASTLAKES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.212600",
    Latitude: "-33.931000"
  },
  {
    Country: "Australia",
    Pcode: "2018",
    Locality: "ROSEBERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.204800",
    Latitude: "-33.918600"
  },
  {
    Country: "Australia",
    Pcode: "2019",
    Locality: "BANKSMEADOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.214800",
    Latitude: "-33.955400"
  },
  {
    Country: "Australia",
    Pcode: "2019",
    Locality: "BOTANY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.199600",
    Latitude: "-33.949000"
  },
  {
    Country: "Australia",
    Pcode: "2020",
    Locality: "MASCOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.183700",
    Latitude: "-33.935700"
  },
  {
    Country: "Australia",
    Pcode: "2020",
    Locality: "SYDNEY DOMESTIC AIRPORT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2020",
    Locality: "SYDNEY INTERNATIONAL AIRPORT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2021",
    Locality: "CENTENNIAL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.234100",
    Latitude: "-33.897700"
  },
  {
    Country: "Australia",
    Pcode: "2021",
    Locality: "MOORE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.222200",
    Latitude: "-33.895800"
  },
  {
    Country: "Australia",
    Pcode: "2021",
    Locality: "PADDINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.228000",
    Latitude: "-33.883300"
  },
  {
    Country: "Australia",
    Pcode: "2022",
    Locality: "BONDI JUNCTION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.249600",
    Latitude: "-33.892900"
  },
  {
    Country: "Australia",
    Pcode: "2022",
    Locality: "QUEENS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.247300",
    Latitude: "-33.899300"
  },
  {
    Country: "Australia",
    Pcode: "2023",
    Locality: "BELLEVUE HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.254900",
    Latitude: "-33.879600"
  },
  {
    Country: "Australia",
    Pcode: "2024",
    Locality: "BRONTE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.263700",
    Latitude: "-33.903900"
  },
  {
    Country: "Australia",
    Pcode: "2024",
    Locality: "WAVERLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.255900",
    Latitude: "-33.901700"
  },
  {
    Country: "Australia",
    Pcode: "2025",
    Locality: "WOOLLAHRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.241600",
    Latitude: "-33.885700"
  },
  {
    Country: "Australia",
    Pcode: "2026",
    Locality: "BONDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.263400",
    Latitude: "-33.893000"
  },
  {
    Country: "Australia",
    Pcode: "2026",
    Locality: "BONDI BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.273800",
    Latitude: "-33.890900"
  },
  {
    Country: "Australia",
    Pcode: "2026",
    Locality: "NORTH BONDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.281600",
    Latitude: "-33.886100"
  },
  {
    Country: "Australia",
    Pcode: "2026",
    Locality: "TAMARAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.271700",
    Latitude: "-33.898800"
  },
  {
    Country: "Australia",
    Pcode: "2027",
    Locality: "DARLING POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.235600",
    Latitude: "-33.872900"
  },
  {
    Country: "Australia",
    Pcode: "2027",
    Locality: "EDGECLIFF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.237900",
    Latitude: "-33.879600"
  },
  {
    Country: "Australia",
    Pcode: "2027",
    Locality: "HMAS RUSHCUTTERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2027",
    Locality: "POINT PIPER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.252300",
    Latitude: "-33.866900"
  },
  {
    Country: "Australia",
    Pcode: "2028",
    Locality: "DOUBLE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.243300",
    Latitude: "-33.879000"
  },
  {
    Country: "Australia",
    Pcode: "2029",
    Locality: "ROSE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.266600",
    Latitude: "-33.874100"
  },
  {
    Country: "Australia",
    Pcode: "2030",
    Locality: "DOVER HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.280900",
    Latitude: "-33.873000"
  },
  {
    Country: "Australia",
    Pcode: "2030",
    Locality: "HMAS WATSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2030",
    Locality: "ROSE BAY NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2030",
    Locality: "VAUCLUSE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.276400",
    Latitude: "-33.855700"
  },
  {
    Country: "Australia",
    Pcode: "2030",
    Locality: "WATSONS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.281700",
    Latitude: "-33.840300"
  },
  {
    Country: "Australia",
    Pcode: "2031",
    Locality: "CLOVELLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.263100",
    Latitude: "-33.911600"
  },
  {
    Country: "Australia",
    Pcode: "2031",
    Locality: "CLOVELLY WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2031",
    Locality: "RANDWICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.240100",
    Latitude: "-33.918000"
  },
  {
    Country: "Australia",
    Pcode: "2031",
    Locality: "RANDWICK DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2031",
    Locality: "ST PAULS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2032",
    Locality: "DACEYVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.225200",
    Latitude: "-33.929000"
  },
  {
    Country: "Australia",
    Pcode: "2032",
    Locality: "KINGSFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.233800",
    Latitude: "-33.925900"
  },
  {
    Country: "Australia",
    Pcode: "2033",
    Locality: "KENSINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.223700",
    Latitude: "-33.911500"
  },
  {
    Country: "Australia",
    Pcode: "2034",
    Locality: "COOGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.254900",
    Latitude: "-33.919600"
  },
  {
    Country: "Australia",
    Pcode: "2034",
    Locality: "SOUTH COOGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.255600",
    Latitude: "-33.932200"
  },
  {
    Country: "Australia",
    Pcode: "2035",
    Locality: "MAROUBRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.244100",
    Latitude: "-33.945400"
  },
  {
    Country: "Australia",
    Pcode: "2035",
    Locality: "MAROUBRA SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2035",
    Locality: "PAGEWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.223600",
    Latitude: "-33.936800"
  },
  {
    Country: "Australia",
    Pcode: "2036",
    Locality: "CHIFLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.239700",
    Latitude: "-33.969400"
  },
  {
    Country: "Australia",
    Pcode: "2036",
    Locality: "EASTGARDENS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.226400",
    Latitude: "-33.944300"
  },
  {
    Country: "Australia",
    Pcode: "2111",
    Locality: "HUNTLEYS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.143900",
    Latitude: "-33.839700"
  },
  {
    Country: "Australia",
    Pcode: "2111",
    Locality: "MONASH PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2111",
    Locality: "TENNYSON POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.116800",
    Latitude: "-33.832100"
  },
  {
    Country: "Australia",
    Pcode: "2112",
    Locality: "DENISTONE EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.098100",
    Latitude: "-33.796800"
  },
  {
    Country: "Australia",
    Pcode: "2112",
    Locality: "PUTNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.107900",
    Latitude: "-33.826900"
  },
  {
    Country: "Australia",
    Pcode: "2112",
    Locality: "RYDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.113900",
    Latitude: "-33.810500"
  },
  {
    Country: "Australia",
    Pcode: "2113",
    Locality: "BLENHEIM ROAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2113",
    Locality: "EAST RYDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.136000",
    Latitude: "-33.811000"
  },
  {
    Country: "Australia",
    Pcode: "2113",
    Locality: "MACQUARIE CENTRE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2113",
    Locality: "MACQUARIE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.124200",
    Latitude: "-33.778700"
  },
  {
    Country: "Australia",
    Pcode: "2113",
    Locality: "NORTH RYDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.133700",
    Latitude: "-33.796700"
  },
  {
    Country: "Australia",
    Pcode: "2114",
    Locality: "DENISTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.088500",
    Latitude: "-33.798600"
  },
  {
    Country: "Australia",
    Pcode: "2114",
    Locality: "DENISTONE WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.077000",
    Latitude: "-33.800800"
  },
  {
    Country: "Australia",
    Pcode: "2114",
    Locality: "MEADOWBANK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.087400",
    Latitude: "-33.816200"
  },
  {
    Country: "Australia",
    Pcode: "2114",
    Locality: "MELROSE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.074200",
    Latitude: "-33.814800"
  },
  {
    Country: "Australia",
    Pcode: "2114",
    Locality: "WEST RYDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.086200",
    Latitude: "-33.806300"
  },
  {
    Country: "Australia",
    Pcode: "2115",
    Locality: "ERMINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.060400",
    Latitude: "-33.810400"
  },
  {
    Country: "Australia",
    Pcode: "2116",
    Locality: "RYDALMERE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.039300",
    Latitude: "-33.813800"
  },
  {
    Country: "Australia",
    Pcode: "2117",
    Locality: "DUNDAS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.042100",
    Latitude: "-33.803000"
  },
  {
    Country: "Australia",
    Pcode: "2117",
    Locality: "DUNDAS VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.057700",
    Latitude: "-33.792200"
  },
  {
    Country: "Australia",
    Pcode: "2117",
    Locality: "OATLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.025700",
    Latitude: "-33.795000"
  },
  {
    Country: "Australia",
    Pcode: "2117",
    Locality: "TELOPEA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.041200",
    Latitude: "-33.794000"
  },
  {
    Country: "Australia",
    Pcode: "2118",
    Locality: "CARLINGFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.044600",
    Latitude: "-33.774500"
  },
  {
    Country: "Australia",
    Pcode: "2118",
    Locality: "CARLINGFORD COURT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2118",
    Locality: "CARLINGFORD NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2118",
    Locality: "KINGSDENE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2119",
    Locality: "BEECROFT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.061300",
    Latitude: "-33.753600"
  },
  {
    Country: "Australia",
    Pcode: "2119",
    Locality: "CHELTENHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.077500",
    Latitude: "-33.756800"
  },
  {
    Country: "Australia",
    Pcode: "2120",
    Locality: "PENNANT HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.071100",
    Latitude: "-33.736300"
  },
  {
    Country: "Australia",
    Pcode: "2120",
    Locality: "THORNLEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.081000",
    Latitude: "-33.720400"
  },
  {
    Country: "Australia",
    Pcode: "2120",
    Locality: "WESTLEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.070000",
    Latitude: "-33.713600"
  },
  {
    Country: "Australia",
    Pcode: "2121",
    Locality: "EPPING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.075000",
    Latitude: "-33.773900"
  },
  {
    Country: "Australia",
    Pcode: "2121",
    Locality: "NORTH EPPING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.094300",
    Latitude: "-33.758500"
  },
  {
    Country: "Australia",
    Pcode: "2122",
    Locality: "EASTWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.084600",
    Latitude: "-33.788500"
  },
  {
    Country: "Australia",
    Pcode: "2122",
    Locality: "MARSFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.105000",
    Latitude: "-33.783300"
  },
  {
    Country: "Australia",
    Pcode: "2124",
    Locality: "PARRAMATTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2125",
    Locality: "WEST PENNANT HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.033100",
    Latitude: "-33.747600"
  },
  {
    Country: "Australia",
    Pcode: "2126",
    Locality: "CHERRYBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.046000",
    Latitude: "-33.722800"
  },
  {
    Country: "Australia",
    Pcode: "2127",
    Locality: "NEWINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.054700",
    Latitude: "-33.837600"
  },
  {
    Country: "Australia",
    Pcode: "2127",
    Locality: "SYDNEY OLYMPIC PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.066900",
    Latitude: "-33.840200"
  },
  {
    Country: "Australia",
    Pcode: "2127",
    Locality: "WENTWORTH POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.077900",
    Latitude: "-33.826300"
  },
  {
    Country: "Australia",
    Pcode: "2128",
    Locality: "SILVERWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "LIDCOMBE",
    Category: "Delivery Area",
    Longitude: "151.042800",
    Latitude: "-33.833900"
  },
  {
    Country: "Australia",
    Pcode: "2129",
    Locality: "SYDNEY MARKETS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2130",
    Locality: "SUMMER HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.137400",
    Latitude: "-33.893400"
  },
  {
    Country: "Australia",
    Pcode: "2131",
    Locality: "ASHFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.127400",
    Latitude: "-33.889300"
  },
  {
    Country: "Australia",
    Pcode: "2132",
    Locality: "CROYDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.116900",
    Latitude: "-33.878900"
  },
  {
    Country: "Australia",
    Pcode: "2133",
    Locality: "CROYDON PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.102200",
    Latitude: "-33.897500"
  },
  {
    Country: "Australia",
    Pcode: "2133",
    Locality: "ENFIELD SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2134",
    Locality: "BURWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.104100",
    Latitude: "-33.878300"
  },
  {
    Country: "Australia",
    Pcode: "2134",
    Locality: "BURWOOD NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2135",
    Locality: "STRATHFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.080500",
    Latitude: "-33.879100"
  },
  {
    Country: "Australia",
    Pcode: "2136",
    Locality: "BURWOOD HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.103900",
    Latitude: "-33.889300"
  },
  {
    Country: "Australia",
    Pcode: "2136",
    Locality: "ENFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.096000",
    Latitude: "-33.890000"
  },
  {
    Country: "Australia",
    Pcode: "2136",
    Locality: "STRATHFIELD SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.079000",
    Latitude: "-33.896700"
  },
  {
    Country: "Australia",
    Pcode: "2137",
    Locality: "BREAKFAST POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.111000",
    Latitude: "-33.842600"
  },
  {
    Country: "Australia",
    Pcode: "2137",
    Locality: "CABARITA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.115900",
    Latitude: "-33.845200"
  },
  {
    Country: "Australia",
    Pcode: "2137",
    Locality: "CONCORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.106700",
    Latitude: "-33.856600"
  },
  {
    Country: "Australia",
    Pcode: "2137",
    Locality: "MORTLAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.106500",
    Latitude: "-33.841200"
  },
  {
    Country: "Australia",
    Pcode: "2137",
    Locality: "NORTH STRATHFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.088700",
    Latitude: "-33.860500"
  },
  {
    Country: "Australia",
    Pcode: "2138",
    Locality: "CONCORD WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.091900",
    Latitude: "-33.842700"
  },
  {
    Country: "Australia",
    Pcode: "2138",
    Locality: "CONCORD WEST DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2138",
    Locality: "LIBERTY GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.083800",
    Latitude: "-33.840900"
  },
  {
    Country: "Australia",
    Pcode: "2138",
    Locality: "RHODES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.088600",
    Latitude: "-33.828800"
  },
  {
    Country: "Australia",
    Pcode: "2211",
    Locality: "PADSTOW HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.034800",
    Latitude: "-33.970400"
  },
  {
    Country: "Australia",
    Pcode: "2212",
    Locality: "REVESBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.011700",
    Latitude: "-33.949100"
  },
  {
    Country: "Australia",
    Pcode: "2212",
    Locality: "REVESBY HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.018700",
    Latitude: "-33.969900"
  },
  {
    Country: "Australia",
    Pcode: "2212",
    Locality: "REVESBY NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2213",
    Locality: "EAST HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.987700",
    Latitude: "-33.961100"
  },
  {
    Country: "Australia",
    Pcode: "2213",
    Locality: "PANANIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.996000",
    Latitude: "-33.955500"
  },
  {
    Country: "Australia",
    Pcode: "2213",
    Locality: "PICNIC POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.005600",
    Latitude: "-33.972900"
  },
  {
    Country: "Australia",
    Pcode: "2214",
    Locality: "MILPERRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.982900",
    Latitude: "-33.941000"
  },
  {
    Country: "Australia",
    Pcode: "2216",
    Locality: "BANKSIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.146200",
    Latitude: "-33.946400"
  },
  {
    Country: "Australia",
    Pcode: "2216",
    Locality: "BRIGHTON-LE-SANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.154100",
    Latitude: "-33.958900"
  },
  {
    Country: "Australia",
    Pcode: "2216",
    Locality: "KYEEMAGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.160900",
    Latitude: "-33.948800"
  },
  {
    Country: "Australia",
    Pcode: "2216",
    Locality: "ROCKDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.142600",
    Latitude: "-33.954200"
  },
  {
    Country: "Australia",
    Pcode: "2216",
    Locality: "ROCKDALE DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2217",
    Locality: "BEVERLEY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.130500",
    Latitude: "-33.977200"
  },
  {
    Country: "Australia",
    Pcode: "2217",
    Locality: "KOGARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.135300",
    Latitude: "-33.965700"
  },
  {
    Country: "Australia",
    Pcode: "2217",
    Locality: "KOGARAH BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.124200",
    Latitude: "-33.983700"
  },
  {
    Country: "Australia",
    Pcode: "2217",
    Locality: "MONTEREY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.147900",
    Latitude: "-33.972400"
  },
  {
    Country: "Australia",
    Pcode: "2217",
    Locality: "RAMSGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.140300",
    Latitude: "-33.982100"
  },
  {
    Country: "Australia",
    Pcode: "2217",
    Locality: "RAMSGATE BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.146200",
    Latitude: "-33.982600"
  },
  {
    Country: "Australia",
    Pcode: "2218",
    Locality: "ALLAWAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.113400",
    Latitude: "-33.972800"
  },
  {
    Country: "Australia",
    Pcode: "2218",
    Locality: "CARLTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.121500",
    Latitude: "-33.971800"
  },
  {
    Country: "Australia",
    Pcode: "2219",
    Locality: "DOLLS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.144500",
    Latitude: "-33.993500"
  },
  {
    Country: "Australia",
    Pcode: "2219",
    Locality: "SANDRINGHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.139000",
    Latitude: "-33.998700"
  },
  {
    Country: "Australia",
    Pcode: "2219",
    Locality: "SANS SOUCI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.131100",
    Latitude: "-33.996100"
  },
  {
    Country: "Australia",
    Pcode: "2220",
    Locality: "HURSTVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.101000",
    Latitude: "-33.962700"
  },
  {
    Country: "Australia",
    Pcode: "2220",
    Locality: "HURSTVILLE GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.093100",
    Latitude: "-33.978800"
  },
  {
    Country: "Australia",
    Pcode: "2220",
    Locality: "HURSTVILLE WESTFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2221",
    Locality: "BLAKEHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.110300",
    Latitude: "-33.991100"
  },
  {
    Country: "Australia",
    Pcode: "2221",
    Locality: "CARSS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.118300",
    Latitude: "-33.987200"
  },
  {
    Country: "Australia",
    Pcode: "2221",
    Locality: "CONNELLS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.093600",
    Latitude: "-33.986500"
  },
  {
    Country: "Australia",
    Pcode: "2221",
    Locality: "KYLE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.100500",
    Latitude: "-33.990000"
  },
  {
    Country: "Australia",
    Pcode: "2221",
    Locality: "SOUTH HURSTVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.105700",
    Latitude: "-33.977600"
  },
  {
    Country: "Australia",
    Pcode: "2222",
    Locality: "PENSHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.084900",
    Latitude: "-33.961800"
  },
  {
    Country: "Australia",
    Pcode: "2223",
    Locality: "MORTDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.072400",
    Latitude: "-33.969300"
  },
  {
    Country: "Australia",
    Pcode: "2223",
    Locality: "OATLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.071400",
    Latitude: "-33.983200"
  },
  {
    Country: "Australia",
    Pcode: "2224",
    Locality: "KANGAROO POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.095800",
    Latitude: "-34.001200"
  },
  {
    Country: "Australia",
    Pcode: "2224",
    Locality: "SYLVANIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.098300",
    Latitude: "-34.013400"
  },
  {
    Country: "Australia",
    Pcode: "2224",
    Locality: "SYLVANIA SOUTHGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2224",
    Locality: "SYLVANIA WATERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.112500",
    Latitude: "-34.018800"
  },
  {
    Country: "Australia",
    Pcode: "2225",
    Locality: "OYSTER BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.081500",
    Latitude: "-34.006000"
  },
  {
    Country: "Australia",
    Pcode: "2226",
    Locality: "BONNET BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.053300",
    Latitude: "-34.010000"
  },
  {
    Country: "Australia",
    Pcode: "2226",
    Locality: "COMO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.062200",
    Latitude: "-34.001500"
  },
  {
    Country: "Australia",
    Pcode: "2226",
    Locality: "JANNALI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.068000",
    Latitude: "-34.015700"
  },
  {
    Country: "Australia",
    Pcode: "2227",
    Locality: "GYMEA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.083500",
    Latitude: "-34.034600"
  },
  {
    Country: "Australia",
    Pcode: "2227",
    Locality: "GYMEA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.087600",
    Latitude: "-34.050500"
  },
  {
    Country: "Australia",
    Pcode: "2228",
    Locality: "MIRANDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.103300",
    Latitude: "-34.033100"
  },
  {
    Country: "Australia",
    Pcode: "2228",
    Locality: "YOWIE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.103500",
    Latitude: "-34.050800"
  },
  {
    Country: "Australia",
    Pcode: "2229",
    Locality: "CARINGBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.125400",
    Latitude: "-34.035600"
  },
  {
    Country: "Australia",
    Pcode: "2229",
    Locality: "CARINGBAH SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.119800",
    Latitude: "-34.053800"
  },
  {
    Country: "Australia",
    Pcode: "2229",
    Locality: "DOLANS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.127600",
    Latitude: "-34.062600"
  },
  {
    Country: "Australia",
    Pcode: "2229",
    Locality: "LILLI PILLI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "CARRINGBAH",
    Category: "Delivery Area",
    Longitude: "151.114000",
    Latitude: "-34.068700"
  },
  {
    Country: "Australia",
    Pcode: "2229",
    Locality: "PORT HACKING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.125800",
    Latitude: "-34.068500"
  },
  {
    Country: "Australia",
    Pcode: "2229",
    Locality: "TAREN POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.128900",
    Latitude: "-34.016200"
  },
  {
    Country: "Australia",
    Pcode: "2230",
    Locality: "BUNDEENA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.149600",
    Latitude: "-34.084900"
  },
  {
    Country: "Australia",
    Pcode: "2230",
    Locality: "BURRANEER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.137600",
    Latitude: "-34.064200"
  },
  {
    Country: "Australia",
    Pcode: "2230",
    Locality: "CRONULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.154100",
    Latitude: "-34.058400"
  },
  {
    Country: "Australia",
    Pcode: "2230",
    Locality: "GREENHILLS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.163600",
    Latitude: "-34.035800"
  },
  {
    Country: "Australia",
    Pcode: "2230",
    Locality: "MAIANBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.128800",
    Latitude: "-34.078700"
  },
  {
    Country: "Australia",
    Pcode: "2230",
    Locality: "WOOLOOWARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.140200",
    Latitude: "-34.043200"
  },
  {
    Country: "Australia",
    Pcode: "2231",
    Locality: "KURNELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.184700",
    Latitude: "-34.021300"
  },
  {
    Country: "Australia",
    Pcode: "2232",
    Locality: "GRAYS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.078000",
    Latitude: "-34.056300"
  },
  {
    Country: "Australia",
    Pcode: "2232",
    Locality: "KAREELA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.082100",
    Latitude: "-34.015600"
  },
  {
    Country: "Australia",
    Pcode: "2232",
    Locality: "KIRRAWEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.072500",
    Latitude: "-34.033700"
  },
  {
    Country: "Australia",
    Pcode: "2232",
    Locality: "KIRRAWEE DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2232",
    Locality: "LOFTUS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.043500",
    Latitude: "-34.045500"
  },
  {
    Country: "Australia",
    Pcode: "2232",
    Locality: "ROYAL NATIONAL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "CATHERINE HILL BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.626900",
    Latitude: "-33.149900"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "CAVES BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.645600",
    Latitude: "-33.109400"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "LITTLE PELICAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.642700",
    Latitude: "-33.080700"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "MURRAYS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.620400",
    Latitude: "-33.116300"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "NORDS WHARF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.607800",
    Latitude: "-33.144500"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "PELICAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.644900",
    Latitude: "-33.071200"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "PINNY BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.636600",
    Latitude: "-33.122000"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "SWANSEA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.635800",
    Latitude: "-33.082700"
  },
  {
    Country: "Australia",
    Pcode: "2281",
    Locality: "SWANSEA HEADS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.658500",
    Latitude: "-33.094500"
  },
  {
    Country: "Australia",
    Pcode: "2282",
    Locality: "ELEEBANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.645600",
    Latitude: "-32.993300"
  },
  {
    Country: "Australia",
    Pcode: "2282",
    Locality: "LAKELANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.649800",
    Latitude: "-32.962100"
  },
  {
    Country: "Australia",
    Pcode: "2282",
    Locality: "WARNERS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.651300",
    Latitude: "-32.971000"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "ARCADIA VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.572500",
    Latitude: "-33.058300"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "AWABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.541400",
    Latitude: "-33.018600"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "BALMORAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "RATHMINES",
    Category: "Delivery Area",
    Longitude: "151.576600",
    Latitude: "-33.046200"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "BLACKALLS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.578400",
    Latitude: "-32.997900"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "BOLTON POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.611000",
    Latitude: "-32.998400"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "BUTTABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.576600",
    Latitude: "-33.049800"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "CAREY BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.604600",
    Latitude: "-33.026200"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "COAL POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.609900",
    Latitude: "-33.037400"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "FASSIFERN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.577900",
    Latitude: "-32.982700"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "FENNELL BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.596700",
    Latitude: "-32.984300"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "FISHING POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.594400",
    Latitude: "-33.052600"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "KILABEN BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.591700",
    Latitude: "-33.023900"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "RATHMINES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.578700",
    Latitude: "-33.039400"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "RYHOPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.527900",
    Latitude: "-32.990500"
  },
  {
    Country: "Australia",
    Pcode: "2283",
    Locality: "TORONTO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.578300",
    Latitude: "-33.014200"
  },
  {
    Country: "Australia",
    Pcode: "2284",
    Locality: "ARGENTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.632300",
    Latitude: "-32.935200"
  },
  {
    Country: "Australia",
    Pcode: "2284",
    Locality: "BOOLAROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.627800",
    Latitude: "-32.946500"
  },
  {
    Country: "Australia",
    Pcode: "2284",
    Locality: "BOORAGUL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.610600",
    Latitude: "-32.974700"
  },
  {
    Country: "Australia",
    Pcode: "2284",
    Locality: "MARMONG POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.613200",
    Latitude: "-32.981900"
  },
  {
    Country: "Australia",
    Pcode: "2284",
    Locality: "SPEERS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.627400",
    Latitude: "-32.963100"
  },
  {
    Country: "Australia",
    Pcode: "2284",
    Locality: "TERALBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.599500",
    Latitude: "-32.956800"
  },
  {
    Country: "Australia",
    Pcode: "2284",
    Locality: "WOODRISING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.606000",
    Latitude: "-32.985700"
  },
  {
    Country: "Australia",
    Pcode: "2285",
    Locality: "CAMERON PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.616200",
    Latitude: "-32.900800"
  },
  {
    Country: "Australia",
    Pcode: "2285",
    Locality: "CARDIFF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.654900",
    Latitude: "-32.943000"
  },
  {
    Country: "Australia",
    Pcode: "2285",
    Locality: "CARDIFF HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.674500",
    Latitude: "-32.936600"
  },
  {
    Country: "Australia",
    Pcode: "2285",
    Locality: "CARDIFF SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.666100",
    Latitude: "-32.953700"
  },
  {
    Country: "Australia",
    Pcode: "2285",
    Locality: "EDGEWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.630900",
    Latitude: "-32.919700"
  },
  {
    Country: "Australia",
    Pcode: "2285",
    Locality: "GLENDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.650900",
    Latitude: "-32.928900"
  },
  {
    Country: "Australia",
    Pcode: "2285",
    Locality: "MACQUARIE HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.643800",
    Latitude: "-32.950300"
  },
  {
    Country: "Australia",
    Pcode: "2286",
    Locality: "HOLMESVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.570400",
    Latitude: "-32.914900"
  },
  {
    Country: "Australia",
    Pcode: "2286",
    Locality: "SEAHAMPTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.585000",
    Latitude: "-32.883900"
  },
  {
    Country: "Australia",
    Pcode: "2286",
    Locality: "WEST WALLSEND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.555200",
    Latitude: "-32.894800"
  },
  {
    Country: "Australia",
    Pcode: "2287",
    Locality: "BIRMINGHAM GARDENS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.690300",
    Latitude: "-32.892800"
  },
  {
    Country: "Australia",
    Pcode: "2287",
    Locality: "ELERMORE VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.661900",
    Latitude: "-32.916900"
  },
  {
    Country: "Australia",
    Pcode: "2287",
    Locality: "FLETCHER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.643800",
    Latitude: "-32.870900"
  },
  {
    Country: "Australia",
    Pcode: "2287",
    Locality: "MARYLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.663100",
    Latitude: "-32.880900"
  },
  {
    Country: "Australia",
    Pcode: "2287",
    Locality: "MINMI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.621000",
    Latitude: "-32.875900"
  },
  {
    Country: "Australia",
    Pcode: "2287",
    Locality: "RANKIN PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.680000",
    Latitude: "-32.925800"
  },
  {
    Country: "Australia",
    Pcode: "2287",
    Locality: "WALLSEND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.663300",
    Latitude: "-32.898900"
  },
  {
    Country: "Australia",
    Pcode: "2287",
    Locality: "WALLSEND DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2287",
    Locality: "WALLSEND SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2289",
    Locality: "ADAMSTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.725800",
    Latitude: "-32.939100"
  },
  {
    Country: "Australia",
    Pcode: "2289",
    Locality: "ADAMSTOWN HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.710600",
    Latitude: "-32.948900"
  },
  {
    Country: "Australia",
    Pcode: "2289",
    Locality: "GARDEN SUBURB",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.678500",
    Latitude: "-32.945300"
  },
  {
    Country: "Australia",
    Pcode: "2289",
    Locality: "HIGHFIELDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.715200",
    Latitude: "-32.956000"
  },
  {
    Country: "Australia",
    Pcode: "2289",
    Locality: "KOTARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.695800",
    Latitude: "-32.942700"
  },
  {
    Country: "Australia",
    Pcode: "2289",
    Locality: "KOTARA FAIR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2289",
    Locality: "KOTARA SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.690900",
    Latitude: "-32.951000"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "BENNETTS GREEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.698000",
    Latitude: "-32.999500"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "CHARLESTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.693100",
    Latitude: "-32.968200"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "DUDLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.720100",
    Latitude: "-32.994300"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "GATESHEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.688400",
    Latitude: "-32.981900"
  },
  {
    Country: "Australia",
    Pcode: "2290",
    Locality: "HILLSBOROUGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "CHARLESTOWN",
    Category: "Delivery Area",
    Longitude: "151.673500",
    Latitude: "-32.962000"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "FERNANCES CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.120300",
    Latitude: "-33.061300"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "GRETA MAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.281200",
    Latitude: "-32.888400"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "KEARSLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.400200",
    Latitude: "-32.852900"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "KITCHENER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.366900",
    Latitude: "-32.882100"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "LAGUNA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.069700",
    Latitude: "-33.041200"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "LOVEDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.364000",
    Latitude: "-32.752400"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "MILLFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.251800",
    Latitude: "-32.891200"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "MILSONS ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "MORUBEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "MOUNT VIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.259300",
    Latitude: "-32.852900"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "MURRAYS RUN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.152900",
    Latitude: "-33.081300"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "NARONE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "NULKABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.363200",
    Latitude: "-32.802400"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "OLNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.317500",
    Latitude: "-33.037400"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "PAXTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.286400",
    Latitude: "-32.904200"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "PAYNES CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.045200",
    Latitude: "-32.910600"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "PELTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.320100",
    Latitude: "-32.880500"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "QUORROBOLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.383500",
    Latitude: "-32.935700"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "SWEETMANS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.179500",
    Latitude: "-32.891000"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "WATAGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.228500",
    Latitude: "-33.024600"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "WOLLOMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.154100",
    Latitude: "-32.940400"
  },
  {
    Country: "Australia",
    Pcode: "2325",
    Locality: "YENGO NATIONAL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.913400",
    Latitude: "-33.023900"
  },
  {
    Country: "Australia",
    Pcode: "2326",
    Locality: "ABERMAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.436100",
    Latitude: "-32.817700"
  },
  {
    Country: "Australia",
    Pcode: "2326",
    Locality: "BISHOPS BRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.465300",
    Latitude: "-32.747300"
  },
  {
    Country: "Australia",
    Pcode: "2326",
    Locality: "LOXFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.486000",
    Latitude: "-32.794200"
  },
  {
    Country: "Australia",
    Pcode: "2326",
    Locality: "NEATH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.415700",
    Latitude: "-32.817700"
  },
  {
    Country: "Australia",
    Pcode: "2326",
    Locality: "SAWYERS GULLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.452600",
    Latitude: "-32.776700"
  },
  {
    Country: "Australia",
    Pcode: "2326",
    Locality: "WESTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.456300",
    Latitude: "-32.811900"
  },
  {
    Country: "Australia",
    Pcode: "2327",
    Locality: "KURRI KURRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.480400",
    Latitude: "-32.814600"
  },
  {
    Country: "Australia",
    Pcode: "2327",
    Locality: "PELAW MAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.479000",
    Latitude: "-32.837500"
  },
  {
    Country: "Australia",
    Pcode: "2327",
    Locality: "STANFORD MERTHYR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.492600",
    Latitude: "-32.824400"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "BUREEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.730800",
    Latitude: "-32.472900"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "DALSWINTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.708000",
    Latitude: "-32.424300"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "DENMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.700200",
    Latitude: "-32.377300"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "GIANTS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.542400",
    Latitude: "-32.270500"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "HOLLYDEEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.624500",
    Latitude: "-32.331400"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "KERRABEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.294500",
    Latitude: "-32.469100"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "MANGOOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.713600",
    Latitude: "-32.319600"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "MARTINDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.642700",
    Latitude: "-32.562600"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "WIDDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.366400",
    Latitude: "-32.637000"
  },
  {
    Country: "Australia",
    Pcode: "2328",
    Locality: "YARRAWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.578800",
    Latitude: "-32.450800"
  },
  {
    Country: "Australia",
    Pcode: "2329",
    Locality: "CASSILIS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.008700",
    Latitude: "-31.976400"
  },
  {
    Country: "Australia",
    Pcode: "2329",
    Locality: "MERRIWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.277400",
    Latitude: "-32.110800"
  },
  {
    Country: "Australia",
    Pcode: "2329",
    Locality: "UARBRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.740400",
    Latitude: "-32.050000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "APPLETREE FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.849500",
    Latitude: "-32.516900"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "BIG RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.225800",
    Latitude: "-32.573300"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "BIG YENGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "BOWMANS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.120600",
    Latitude: "-32.258800"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "BRIDGMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.187900",
    Latitude: "-32.454200"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "BROKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.094800",
    Latitude: "-32.767700"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "BULGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.990100",
    Latitude: "-32.636400"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "CAMBERWELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.089000",
    Latitude: "-32.486000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "CARROWBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.303800",
    Latitude: "-32.291300"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "CLYDESDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.210600",
    Latitude: "-32.571000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "COMBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.184800",
    Latitude: "-32.551700"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "DARLINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SINGLETON",
    Category: "Delivery Area",
    Longitude: "151.162200",
    Latitude: "-32.559300"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "DOYLES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.772700",
    Latitude: "-32.607000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "DUNOLLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.169300",
    Latitude: "-32.554600"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "DURAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SINGLETON",
    Category: "Delivery Area",
    Longitude: "150.860800",
    Latitude: "-32.574600"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "DYRRING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.233100",
    Latitude: "-32.501000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "FALBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.135300",
    Latitude: "-32.421000"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "FERN GULLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.200600",
    Latitude: "-32.550700"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "FORDWICH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.053300",
    Latitude: "-32.711600"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "GARLAND VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.736300",
    Latitude: "-32.926500"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "GLENDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.283700",
    Latitude: "-32.583900"
  },
  {
    Country: "Australia",
    Pcode: "2330",
    Locality: "GLENDON BROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.396800",
    Latitude: "-32.515000"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "GULF CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.745800",
    Latitude: "-30.175300"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "GUNDAMULDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.717000",
    Latitude: "-30.253400"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "IRONBARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.805300",
    Latitude: "-30.285300"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "LINDESAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.299800",
    Latitude: "-30.335000"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "LONGARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.548700",
    Latitude: "-30.486000"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "MAYVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.409800",
    Latitude: "-30.402200"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "RED HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BARRABA",
    Category: "Delivery Area",
    Longitude: "150.653700",
    Latitude: "-30.493700"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "THIRLDENE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.899600",
    Latitude: "-30.320600"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "UPPER HORTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.375900",
    Latitude: "-30.175200"
  },
  {
    Country: "Australia",
    Pcode: "2347",
    Locality: "WOODSREEF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.792400",
    Latitude: "-30.437300"
  },
  {
    Country: "Australia",
    Pcode: "2348",
    Locality: "NEW ENGLAND MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "ABERFOYLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.988400",
    Latitude: "-30.236900"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "ABINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.217900",
    Latitude: "-30.326700"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "ARGYLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "ARMIDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.671900",
    Latitude: "-30.500500"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "ARMIDALE DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "ARMIDALE EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "BOOROLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.467300",
    Latitude: "-30.356900"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "CASTLE DOYLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.786200",
    Latitude: "-30.634900"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "DANGARSLEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.685500",
    Latitude: "-30.627900"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "DONALD CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.760600",
    Latitude: "-30.424300"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "DUMARESQ",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.554000",
    Latitude: "-30.419300"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "DUVAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.635300",
    Latitude: "-30.413900"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "ENMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ARMIDALE",
    Category: "Delivery Area",
    Longitude: "151.838900",
    Latitude: "-30.729300"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "HILLGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ARMIDALE",
    Category: "Delivery Area",
    Longitude: "151.969100",
    Latitude: "-30.553200"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "INVERGOWRIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.510400",
    Latitude: "-30.509600"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "JEOGLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.175900",
    Latitude: "-30.648300"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "KELLYS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.634500",
    Latitude: "-30.583300"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "LYNDHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ARMIDALE",
    Category: "Delivery Area",
    Longitude: "152.045600",
    Latitude: "-30.357800"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "METZ",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.833100",
    Latitude: "-30.572800"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "PUDDLEDOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.758500",
    Latitude: "-30.392900"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "SAUMAREZ",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.589100",
    Latitude: "-30.579700"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "SAUMAREZ PONDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.564000",
    Latitude: "-30.499000"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "THALGARRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.889100",
    Latitude: "-30.403200"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "TILBUSTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.703900",
    Latitude: "-30.416400"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "WARDS MISTAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.034800",
    Latitude: "-30.130600"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "WEST ARMIDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "WOLLOMOMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.155900",
    Latitude: "-30.482200"
  },
  {
    Country: "Australia",
    Pcode: "2350",
    Locality: "WONGWIBINDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.199600",
    Latitude: "-30.282600"
  },
  {
    Country: "Australia",
    Pcode: "2352",
    Locality: "KOOTINGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.055800",
    Latitude: "-31.062900"
  },
  {
    Country: "Australia",
    Pcode: "2352",
    Locality: "LIMBRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.213200",
    Latitude: "-31.043200"
  },
  {
    Country: "Australia",
    Pcode: "2352",
    Locality: "MULLA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.177300",
    Latitude: "-31.146600"
  },
  {
    Country: "Australia",
    Pcode: "2352",
    Locality: "TINTINHULL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.004600",
    Latitude: "-31.070500"
  },
  {
    Country: "Australia",
    Pcode: "2353",
    Locality: "MOONBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.053900",
    Latitude: "-30.951400"
  },
  {
    Country: "Australia",
    Pcode: "2354",
    Locality: "KENTUCKY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.384100",
    Latitude: "-30.754400"
  },
  {
    Country: "Australia",
    Pcode: "2354",
    Locality: "KENTUCKY SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.453000",
    Latitude: "-30.804100"
  },
  {
    Country: "Australia",
    Pcode: "2354",
    Locality: "NIANGALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.467800",
    Latitude: "-31.437500"
  },
  {
    Country: "Australia",
    Pcode: "2354",
    Locality: "NOWENDOC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.791100",
    Latitude: "-31.474300"
  },
  {
    Country: "Australia",
    Pcode: "2354",
    Locality: "WALCHA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.646500",
    Latitude: "-31.104500"
  },
  {
    Country: "Australia",
    Pcode: "2354",
    Locality: "WALCHA ROAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.419100",
    Latitude: "-30.931200"
  },
  {
    Country: "Australia",
    Pcode: "2354",
    Locality: "WOLLUN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.449700",
    Latitude: "-30.848800"
  },
  {
    Country: "Australia",
    Pcode: "2354",
    Locality: "WOOLBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.319100",
    Latitude: "-30.951800"
  },
  {
    Country: "Australia",
    Pcode: "2354",
    Locality: "YARROWITCH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.077800",
    Latitude: "-31.201900"
  },
  {
    Country: "Australia",
    Pcode: "2355",
    Locality: "BENDEMEER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.177500",
    Latitude: "-30.807300"
  },
  {
    Country: "Australia",
    Pcode: "2355",
    Locality: "RETREAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.085900",
    Latitude: "-30.613400"
  },
  {
    Country: "Australia",
    Pcode: "2355",
    Locality: "WATSONS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.998400",
    Latitude: "-30.681600"
  },
  {
    Country: "Australia",
    Pcode: "2356",
    Locality: "GWABEGAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.959900",
    Latitude: "-30.578200"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "BOMERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.763200",
    Latitude: "-31.513500"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "BOX RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.552600",
    Latitude: "-31.444300"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "BUGALDIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.139200",
    Latitude: "-31.158600"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "COONABARABRAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.217700",
    Latitude: "-31.262300"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "DANDRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.344900",
    Latitude: "-31.043000"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "GOWANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.080000",
    Latitude: "-31.421500"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "PURLEWAUGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.567300",
    Latitude: "-31.348600"
  },
  {
    Country: "Australia",
    Pcode: "2357",
    Locality: "ROCKY GLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.519700",
    Latitude: "-31.077400"
  },
  {
    Country: "Australia",
    Pcode: "2400",
    Locality: "CROOBLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.277700",
    Latitude: "-29.301300"
  },
  {
    Country: "Australia",
    Pcode: "2400",
    Locality: "MALLOWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.276200",
    Latitude: "-29.662800"
  },
  {
    Country: "Australia",
    Pcode: "2400",
    Locality: "MOREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.525400",
    Latitude: "-29.305200"
  },
  {
    Country: "Australia",
    Pcode: "2400",
    Locality: "MOREE EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2400",
    Locality: "TERRY HIE HIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.138400",
    Latitude: "-29.788900"
  },
  {
    Country: "Australia",
    Pcode: "2400",
    Locality: "TULLOONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.073000",
    Latitude: "-28.895900"
  },
  {
    Country: "Australia",
    Pcode: "2401",
    Locality: "GRAVESEND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.350100",
    Latitude: "-29.611600"
  },
  {
    Country: "Australia",
    Pcode: "2402",
    Locality: "COOLATAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.738100",
    Latitude: "-29.246600"
  },
  {
    Country: "Australia",
    Pcode: "2402",
    Locality: "WARIALDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.534100",
    Latitude: "-29.389200"
  },
  {
    Country: "Australia",
    Pcode: "2402",
    Locality: "WARIALDA RAIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.504800",
    Latitude: "-29.637200"
  },
  {
    Country: "Australia",
    Pcode: "2403",
    Locality: "BALFOURS PEAK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.798800",
    Latitude: "-29.464000"
  },
  {
    Country: "Australia",
    Pcode: "2403",
    Locality: "DELUNGRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.748600",
    Latitude: "-29.634800"
  },
  {
    Country: "Australia",
    Pcode: "2403",
    Locality: "GRAGIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.790400",
    Latitude: "-29.549000"
  },
  {
    Country: "Australia",
    Pcode: "2403",
    Locality: "MYALL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.753900",
    Latitude: "-29.779700"
  },
  {
    Country: "Australia",
    Pcode: "2404",
    Locality: "BANGHEET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.417400",
    Latitude: "-29.836000"
  },
  {
    Country: "Australia",
    Pcode: "2404",
    Locality: "BINGARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.549400",
    Latitude: "-29.834100"
  },
  {
    Country: "Australia",
    Pcode: "2404",
    Locality: "DINOGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.585300",
    Latitude: "-30.030900"
  },
  {
    Country: "Australia",
    Pcode: "2404",
    Locality: "ELCOMBE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.343700",
    Latitude: "-29.790500"
  },
  {
    Country: "Australia",
    Pcode: "2404",
    Locality: "GINEROI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.507700",
    Latitude: "-29.735800"
  },
  {
    Country: "Australia",
    Pcode: "2404",
    Locality: "KEERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.743500",
    Latitude: "-30.048800"
  },
  {
    Country: "Australia",
    Pcode: "2404",
    Locality: "PALLAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.425700",
    Latitude: "-29.981700"
  },
  {
    Country: "Australia",
    Pcode: "2404",
    Locality: "UPPER BINGARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.660900",
    Latitude: "-30.068500"
  },
  {
    Country: "Australia",
    Pcode: "2404",
    Locality: "WHITLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GWYDIR",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2405",
    Locality: "BOOMI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.647300",
    Latitude: "-28.709600"
  },
  {
    Country: "Australia",
    Pcode: "2405",
    Locality: "GARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.625000",
    Latitude: "-29.022500"
  },
  {
    Country: "Australia",
    Pcode: "2406",
    Locality: "MUNGINDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.097100",
    Latitude: "-29.025100"
  },
  {
    Country: "Australia",
    Pcode: "2406",
    Locality: "MUNGINDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.767000",
    Latitude: "-28.821000"
  },
  {
    Country: "Australia",
    Pcode: "2406",
    Locality: "WEEMELAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.299300",
    Latitude: "-29.059600"
  },
  {
    Country: "Australia",
    Pcode: "2408",
    Locality: "BLUE NOBBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.615600",
    Latitude: "-29.060100"
  },
  {
    Country: "Australia",
    Pcode: "2408",
    Locality: "NORTH STAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.427300",
    Latitude: "-28.954700"
  },
  {
    Country: "Australia",
    Pcode: "2408",
    Locality: "YALLAROI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.521400",
    Latitude: "-29.123000"
  },
  {
    Country: "Australia",
    Pcode: "2409",
    Locality: "BOGGABILLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.176100",
    Latitude: "-28.715800"
  },
  {
    Country: "Australia",
    Pcode: "2409",
    Locality: "BOONAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.541300",
    Latitude: "-28.759400"
  },
  {
    Country: "Australia",
    Pcode: "2410",
    Locality: "TWIN RIVERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.703600",
    Latitude: "-28.724200"
  },
  {
    Country: "Australia",
    Pcode: "2410",
    Locality: "YETMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.791800",
    Latitude: "-28.967000"
  },
  {
    Country: "Australia",
    Pcode: "2411",
    Locality: "CROPPA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.299600",
    Latitude: "-29.162000"
  },
  {
    Country: "Australia",
    Pcode: "2415",
    Locality: "MONKERAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.853700",
    Latitude: "-32.249200"
  },
  {
    Country: "Australia",
    Pcode: "2415",
    Locality: "NOOROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.887700",
    Latitude: "-32.373100"
  },
  {
    Country: "Australia",
    Pcode: "2415",
    Locality: "STROUD ROAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.933700",
    Latitude: "-32.315200"
  },
  {
    Country: "Australia",
    Pcode: "2415",
    Locality: "UPPER KARUAH RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.738200",
    Latitude: "-32.168200"
  },
  {
    Country: "Australia",
    Pcode: "2415",
    Locality: "WEISMANTELS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.921200",
    Latitude: "-32.258200"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "ALISON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "DUNGOG",
    Category: "Delivery Area",
    Longitude: "151.773000",
    Latitude: "-32.423700"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "BANDON GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.715800",
    Latitude: "-32.275800"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "BENDOLBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.731000",
    Latitude: "-32.350800"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "BROOKFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.754600",
    Latitude: "-32.513900"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "CAMBRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.867400",
    Latitude: "-32.441100"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "CHICHESTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.631900",
    Latitude: "-32.165100"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "DUNGOG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.749600",
    Latitude: "-32.402400"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "FLAT TOPS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.809800",
    Latitude: "-32.433400"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "FOSTERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.768300",
    Latitude: "-32.362300"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "HANLEYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.688000",
    Latitude: "-32.418500"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "HILLDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.637300",
    Latitude: "-32.496400"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "MAIN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.796100",
    Latitude: "-32.301800"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "MARSHDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.806500",
    Latitude: "-32.403000"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "MARTINS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.644700",
    Latitude: "-32.559600"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "MUNNI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.675100",
    Latitude: "-32.309000"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "SALISBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.537400",
    Latitude: "-32.183400"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "STROUD HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.811500",
    Latitude: "-32.379100"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "SUGARLOAF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.687000",
    Latitude: "-32.380500"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "TABBIL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.738600",
    Latitude: "-32.417700"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "UNDERBANK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.610200",
    Latitude: "-32.285100"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "WALLARINGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.676300",
    Latitude: "-32.454600"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "WALLAROBBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.704700",
    Latitude: "-32.502700"
  },
  {
    Country: "Australia",
    Pcode: "2420",
    Locality: "WIRRAGULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.735500",
    Latitude: "-32.459100"
  },
  {
    Country: "Australia",
    Pcode: "2421",
    Locality: "FISHERS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.541000",
    Latitude: "-32.485800"
  },
  {
    Country: "Australia",
    Pcode: "2421",
    Locality: "PATERSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.608200",
    Latitude: "-32.584200"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "ALDAVILLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.760200",
    Latitude: "-31.071900"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "AUSTRAL EDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.923800",
    Latitude: "-31.022000"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "BELLBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.483700",
    Latitude: "-30.736500"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "BELLIMBOPINNI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.901700",
    Latitude: "-31.004200"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "BELMORE RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.973700",
    Latitude: "-31.083900"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "BURNT BRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.806100",
    Latitude: "-31.107800"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "CARRAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.248600",
    Latitude: "-30.833100"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "CLYBUCCA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.953600",
    Latitude: "-30.927000"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "COLLOMBATTI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.807500",
    Latitude: "-30.955300"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "COMARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.388200",
    Latitude: "-30.687200"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "CORANGULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.679500",
    Latitude: "-31.000300"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "CRESCENT HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.928600",
    Latitude: "-31.221900"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "DEEP CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "KEMPSEY",
    Category: "Delivery Area",
    Longitude: "152.708100",
    Latitude: "-30.967500"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "DONDINGALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.749100",
    Latitude: "-31.147500"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "EAST KEMPSEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.851600",
    Latitude: "-31.080700"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "EUROKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.798300",
    Latitude: "-31.078100"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "FREDERICKTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.860600",
    Latitude: "-31.025300"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "GLADSTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.961700",
    Latitude: "-31.018900"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "GREENHILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "KEMPSEY",
    Category: "Delivery Area",
    Longitude: "152.804000",
    Latitude: "-31.046800"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "HAMPDEN HALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.860300",
    Latitude: "-31.072100"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "HAT HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.032000",
    Latitude: "-31.051200"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "HICKEYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.602300",
    Latitude: "-30.875700"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "KEMPSEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.844400",
    Latitude: "-31.062400"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "KINCHELA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.003800",
    Latitude: "-31.004000"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "LOWER CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.281100",
    Latitude: "-30.667600"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "MILLBANK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.607800",
    Latitude: "-30.796000"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "MOONEBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.694400",
    Latitude: "-31.041700"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "MOPARRABAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.461300",
    Latitude: "-31.048000"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "MUNGAY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.670300",
    Latitude: "-30.910300"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "OLD STATION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.912400",
    Latitude: "-31.054100"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "POLA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.873100",
    Latitude: "-31.053500"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "RAINBOW REACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.007200",
    Latitude: "-30.919700"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "SEVEN OAKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.934000",
    Latitude: "-30.999900"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "SHERWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "KEMPSEY",
    Category: "Delivery Area",
    Longitude: "152.710100",
    Latitude: "-31.067700"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "SKILLION FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.732800",
    Latitude: "-31.009800"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "SMITHTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.947000",
    Latitude: "-31.011200"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "SOUTH KEMPSEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.849100",
    Latitude: "-31.120000"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "SUMMER ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.964400",
    Latitude: "-30.985700"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "TEMAGOG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.614300",
    Latitude: "-31.007900"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "TOOROOKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.527200",
    Latitude: "-30.889100"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "TURNERS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.680300",
    Latitude: "-31.040600"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "VERGES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.891700",
    Latitude: "-31.089300"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "WEST KEMPSEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.831900",
    Latitude: "-31.063400"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "WILLAWARRIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.627900",
    Latitude: "-30.942700"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "WILLI WILLI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.391400",
    Latitude: "-30.971600"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "WITTITRIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.566000",
    Latitude: "-31.097100"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "YARRAVEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.763900",
    Latitude: "-31.041800"
  },
  {
    Country: "Australia",
    Pcode: "2440",
    Locality: "YESSABAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.673600",
    Latitude: "-31.108300"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "ALLGOMERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.810100",
    Latitude: "-30.818700"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "BALLENGARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.701300",
    Latitude: "-31.312500"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "BARRAGANYATTI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.918600",
    Latitude: "-30.873700"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "BRIL BRIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.546800",
    Latitude: "-31.280800"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "COOPERABUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.793700",
    Latitude: "-31.265200"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "EUNGAI CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.875700",
    Latitude: "-30.817700"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "EUNGAI RAIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.891000",
    Latitude: "-30.862000"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "FISHERMANS REACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.001300",
    Latitude: "-30.862400"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "GEARYS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.568000",
    Latitude: "-31.172400"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "GRASSY HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.985000",
    Latitude: "-30.780900"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "GUM SCRUB",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.734400",
    Latitude: "-31.260000"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "HACKS FERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.838600",
    Latitude: "-31.339800"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "KIPPARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.516700",
    Latitude: "-31.233200"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "KUNDABUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.815200",
    Latitude: "-31.202100"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "MARLO MERRICAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.684000",
    Latitude: "-31.184600"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CARRS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.922900",
    Latitude: "-29.655700"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CARRS ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.911600",
    Latitude: "-29.669600"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CARRS PENINSULAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.905800",
    Latitude: "-29.653400"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CHAELUNDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.440300",
    Latitude: "-29.983400"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "EAST CORAKI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.310900",
    Latitude: "-29.012200"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "GREEN FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.366000",
    Latitude: "-28.963300"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "GREENRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.118200",
    Latitude: "-28.909600"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "NORTH WOODBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.346000",
    Latitude: "-29.059500"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "SWAN BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "CASINO",
    Category: "Delivery Area",
    Longitude: "153.294500",
    Latitude: "-29.080000"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "TATHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.176300",
    Latitude: "-28.936800"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "WEST BUNGAWALBIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.123100",
    Latitude: "-29.163600"
  },
  {
    Country: "Australia",
    Pcode: "2471",
    Locality: "WEST CORAKI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.210300",
    Latitude: "-29.004900"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "BROADWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WOODBURN",
    Category: "Delivery Area",
    Longitude: "153.435900",
    Latitude: "-29.043100"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "BUCKENDOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.345200",
    Latitude: "-29.037400"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "ESK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.339400",
    Latitude: "-29.256500"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "KILGIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.378600",
    Latitude: "-29.012200"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "MOONEM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "NEW ITALY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.296300",
    Latitude: "-29.149400"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "RILEYS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.392200",
    Latitude: "-29.021100"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "TABBIMOBLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.245300",
    Latitude: "-29.206600"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "THE GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WOODBURN",
    Category: "Delivery Area",
    Longitude: "153.383800",
    Latitude: "-29.170000"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "TRUSTUMS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2472",
    Locality: "WOODBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "EVANS HEAD",
    Category: "Delivery Area",
    Longitude: "153.353600",
    Latitude: "-29.099700"
  },
  {
    Country: "Australia",
    Pcode: "2473",
    Locality: "BUNDJALUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2473",
    Locality: "DOONBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.388600",
    Latitude: "-29.084000"
  },
  {
    Country: "Australia",
    Pcode: "2473",
    Locality: "EVANS HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.425200",
    Latitude: "-29.110600"
  },
  {
    Country: "Australia",
    Pcode: "2473",
    Locality: "IRON GATES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2473",
    Locality: "SOUTH EVANS HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "AFTERLEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.825400",
    Latitude: "-28.569500"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "BARKERS VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.123000",
    Latitude: "-28.525700"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "BORDER RANGES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.061300",
    Latitude: "-28.392800"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "CAWONGLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.095000",
    Latitude: "-28.604600"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "CEDAR POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.001800",
    Latitude: "-28.697800"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "COLLINS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.037200",
    Latitude: "-28.476700"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "COUGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.982600",
    Latitude: "-28.360100"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "DAIRY FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.709800",
    Latitude: "-28.372500"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "EDEN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.915000",
    Latitude: "-28.586800"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "EDENVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.950700",
    Latitude: "-28.686400"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "ETTRICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.920500",
    Latitude: "-28.648000"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "FAWCETTS PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.014800",
    Latitude: "-28.574700"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "FINDON CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.863800",
    Latitude: "-28.342000"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "GENEVA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.978300",
    Latitude: "-28.612600"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "GHINNI GHI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.780400",
    Latitude: "-28.622900"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "GRADYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.949800",
    Latitude: "-28.417200"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "GREEN PIGEON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.064000",
    Latitude: "-28.494300"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "GREVILLIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.814700",
    Latitude: "-28.444400"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "HOMELEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.063800",
    Latitude: "-28.571300"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "HORSE STATION CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.949000",
    Latitude: "-28.608800"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "HORSESHOE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.036000",
    Latitude: "-28.537800"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "IRON POT CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.861800",
    Latitude: "-28.637900"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "KILGRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.976500",
    Latitude: "-28.546000"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "KYOGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.012600",
    Latitude: "-28.636600"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "LITTLE BACK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.046400",
    Latitude: "-28.614700"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "LOADSTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.984800",
    Latitude: "-28.392500"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "LYNCHS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.995100",
    Latitude: "-28.461300"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "NEW PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.993700",
    Latitude: "-28.600700"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "OLD GREVILLIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.887300",
    Latitude: "-28.448600"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "ROSEBERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.906100",
    Latitude: "-28.493900"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "ROSEBERRY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.823400",
    Latitude: "-28.472700"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "RUKENVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.897300",
    Latitude: "-28.467100"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "SAWPIT CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.813200",
    Latitude: "-28.371600"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "SHERWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "KYOGLE",
    Category: "Delivery Area",
    Longitude: "152.787000",
    Latitude: "-28.430300"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "SMITHS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "KYOGLE",
    Category: "Delivery Area",
    Longitude: "152.855900",
    Latitude: "-28.562200"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "TERRACE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.835500",
    Latitude: "-28.410200"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "THE RISK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.933900",
    Latitude: "-28.472100"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "TOONUMBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.761600",
    Latitude: "-28.538100"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "UNUMGAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.717600",
    Latitude: "-28.434600"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "UPPER EDEN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.861700",
    Latitude: "-28.517200"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "UPPER HORSESHOE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.115000",
    Latitude: "-28.499400"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "MOUNT BURRELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.196000",
    Latitude: "-28.498800"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "MOUNT WARNING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.277000",
    Latitude: "-28.392800"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "MURWILLUMBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.379300",
    Latitude: "-28.329700"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "MURWILLUMBAH DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "MURWILLUMBAH SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "NOBBYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.337500",
    Latitude: "-28.287500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "NORTH ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.339500",
    Latitude: "-28.326800"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "NUMINBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.261500",
    Latitude: "-28.264200"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "NUNDERI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.459500",
    Latitude: "-28.322400"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "PALMVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.488300",
    Latitude: "-28.374200"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "PUMPENBIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.154500",
    Latitude: "-28.377400"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "RESERVE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.489200",
    Latitude: "-28.351500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "ROUND MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.544000",
    Latitude: "-28.355300"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "ROWLANDS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.347000",
    Latitude: "-28.462600"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "SMITHS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MURWILLUMBAH",
    Category: "Delivery Area",
    Longitude: "153.384100",
    Latitude: "-28.429700"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "SOUTH MURWILLUMBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.414500",
    Latitude: "-28.335500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "STOKERS SIDING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.412800",
    Latitude: "-28.405900"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "TERRAGON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.291100",
    Latitude: "-28.434600"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "TOMEWIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.375100",
    Latitude: "-28.251500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "TYALGUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.226500",
    Latitude: "-28.362500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "TYALGUM CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.162700",
    Latitude: "-28.336900"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "TYGALGAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.421900",
    Latitude: "-28.296600"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "UKI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.327000",
    Latitude: "-28.414900"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "UPPER CRYSTAL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.305200",
    Latitude: "-28.272100"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "URLIUP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.401600",
    Latitude: "-28.261500"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "WARDROP VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.451900",
    Latitude: "-28.360600"
  },
  {
    Country: "Australia",
    Pcode: "2484",
    Locality: "ZARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.258000",
    Latitude: "-28.318100"
  },
  {
    Country: "Australia",
    Pcode: "2485",
    Locality: "TWEED HEADS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.535300",
    Latitude: "-28.177900"
  },
  {
    Country: "Australia",
    Pcode: "2485",
    Locality: "TWEED HEADS WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.502700",
    Latitude: "-28.184200"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "BANORA POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.533300",
    Latitude: "-28.224800"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "BILAMBIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.461500",
    Latitude: "-28.224400"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "BILAMBIL HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.484100",
    Latitude: "-28.210100"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "BUNGALORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.474600",
    Latitude: "-28.249300"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "CAROOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.428600",
    Latitude: "-28.224000"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "COBAKI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.457800",
    Latitude: "-28.205200"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "COBAKI LAKES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.479800",
    Latitude: "-28.174800"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "DUROBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.462400",
    Latitude: "-28.243100"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "GLENGARRIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.404000",
    Latitude: "-28.229300"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "PIGGABEEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.436300",
    Latitude: "-28.198900"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "TERRANORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.506700",
    Latitude: "-28.239900"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "TWEED HEADS SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.535400",
    Latitude: "-28.198000"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "TWEED HEADS SOUTH DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2486",
    Locality: "UPPER DUROBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.435000",
    Latitude: "-28.253800"
  },
  {
    Country: "Australia",
    Pcode: "2487",
    Locality: "CASUARINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.571300",
    Latitude: "-28.302600"
  },
  {
    Country: "Australia",
    Pcode: "2487",
    Locality: "CHINDERAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.539800",
    Latitude: "-28.249400"
  },
  {
    Country: "Australia",
    Pcode: "2487",
    Locality: "CUDGEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.547500",
    Latitude: "-28.272700"
  },
  {
    Country: "Australia",
    Pcode: "2487",
    Locality: "DURANBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.528600",
    Latitude: "-28.312000"
  },
  {
    Country: "Australia",
    Pcode: "2487",
    Locality: "FINGAL HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.559800",
    Latitude: "-28.190800"
  },
  {
    Country: "Australia",
    Pcode: "2487",
    Locality: "KINGS FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.553400",
    Latitude: "-28.301700"
  },
  {
    Country: "Australia",
    Pcode: "2487",
    Locality: "KINGSCLIFF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.572600",
    Latitude: "-28.267800"
  },
  {
    Country: "Australia",
    Pcode: "2487",
    Locality: "STOTTS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.505400",
    Latitude: "-28.280700"
  },
  {
    Country: "Australia",
    Pcode: "2488",
    Locality: "BOGANGAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.557900",
    Latitude: "-28.328900"
  },
  {
    Country: "Australia",
    Pcode: "2488",
    Locality: "CABARITA BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.573200",
    Latitude: "-28.346200"
  },
  {
    Country: "Australia",
    Pcode: "2488",
    Locality: "TANGLEWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.534800",
    Latitude: "-28.336800"
  },
  {
    Country: "Australia",
    Pcode: "2489",
    Locality: "HASTINGS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.571400",
    Latitude: "-28.364700"
  },
  {
    Country: "Australia",
    Pcode: "2489",
    Locality: "POTTSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.557800",
    Latitude: "-28.387300"
  },
  {
    Country: "Australia",
    Pcode: "2489",
    Locality: "POTTSVILLE BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2490",
    Locality: "NORTH TUMBULGUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.451500",
    Latitude: "-28.257100"
  },
  {
    Country: "Australia",
    Pcode: "2490",
    Locality: "TUMBULGUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.480400",
    Latitude: "-28.283500"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "CONISTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.881600",
    Latitude: "-34.437400"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "GWYNNEVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.883000",
    Latitude: "-34.414400"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "KEIRAVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.873900",
    Latitude: "-34.409900"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "MANGERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.873500",
    Latitude: "-34.432100"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "MOUNT KEIRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.849400",
    Latitude: "-34.402700"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "MOUNT SAINT THOMAS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.870300",
    Latitude: "-34.440300"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "GUERILLA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.218000",
    Latitude: "-35.830000"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "JEREMADRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.139000",
    Latitude: "-35.817900"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "LILLI PILLI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BATEMANS BAY",
    Category: "Delivery Area",
    Longitude: "150.223100",
    Latitude: "-35.772600"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "LONG BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.227100",
    Latitude: "-35.693300"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "MALONEYS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.254100",
    Latitude: "-35.700500"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "MALUA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.215500",
    Latitude: "-35.789000"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "MOGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.074700",
    Latitude: "-35.804200"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "NELLIGEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.088400",
    Latitude: "-35.673500"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "NORTH BATEMANS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.193800",
    Latitude: "-35.683400"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "PEBBLY BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.331700",
    Latitude: "-35.600300"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "ROSEDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.213700",
    Latitude: "-35.815400"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "RUNNYFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.107700",
    Latitude: "-35.719600"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "SOUTH DURRAS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.282800",
    Latitude: "-35.656400"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "SUNSHINE BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.209300",
    Latitude: "-35.745900"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "SURF BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.205300",
    Latitude: "-35.762500"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "SURFSIDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.199500",
    Latitude: "-35.695000"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "WOODLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BATEMANS BAY",
    Category: "Delivery Area",
    Longitude: "150.183500",
    Latitude: "-35.800000"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "BERGALIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.092900",
    Latitude: "-35.981200"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "BINGIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.145200",
    Latitude: "-36.015800"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "BROULEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.156000",
    Latitude: "-35.855800"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "COILA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.099600",
    Latitude: "-36.010200"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "CONGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.135100",
    Latitude: "-35.956500"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "DEUA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.814700",
    Latitude: "-35.908800"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "DEUA RIVER VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.920000",
    Latitude: "-35.786000"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "KIORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.015100",
    Latitude: "-35.905100"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "MERINGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.136900",
    Latitude: "-35.981800"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "MOGENDOURA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.035800",
    Latitude: "-35.864000"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "MORUYA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.092200",
    Latitude: "-35.901800"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "MORUYA HEADS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.140900",
    Latitude: "-35.929600"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "MOSSY POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.177000",
    Latitude: "-35.832100"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "TOMAKIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.189600",
    Latitude: "-35.822600"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "TURLINJAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.073900",
    Latitude: "-36.029100"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "TUROSS HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.120800",
    Latitude: "-36.035500"
  },
  {
    Country: "Australia",
    Pcode: "2537",
    Locality: "WAMBAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.971100",
    Latitude: "-35.969500"
  },
  {
    Country: "Australia",
    Pcode: "2538",
    Locality: "BROOMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.260600",
    Latitude: "-35.498200"
  },
  {
    Country: "Australia",
    Pcode: "2538",
    Locality: "LITTLE FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.373500",
    Latitude: "-35.288800"
  },
  {
    Country: "Australia",
    Pcode: "2538",
    Locality: "MILTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.439900",
    Latitude: "-35.313400"
  },
  {
    Country: "Australia",
    Pcode: "2538",
    Locality: "MOGOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.187800",
    Latitude: "-35.498500"
  },
  {
    Country: "Australia",
    Pcode: "2538",
    Locality: "MORTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.309100",
    Latitude: "-35.413900"
  },
  {
    Country: "Australia",
    Pcode: "2538",
    Locality: "PORTERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.322900",
    Latitude: "-35.242200"
  },
  {
    Country: "Australia",
    Pcode: "2538",
    Locality: "WOODBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MILTON",
    Category: "Delivery Area",
    Longitude: "150.412300",
    Latitude: "-35.392400"
  },
  {
    Country: "Australia",
    Pcode: "2538",
    Locality: "WOODSTOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MILTON",
    Category: "Delivery Area",
    Longitude: "150.396700",
    Latitude: "-35.352300"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "BAWLEY POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.378600",
    Latitude: "-35.512000"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "BENDALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.501900",
    Latitude: "-35.232200"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "BERRINGER LAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.504400",
    Latitude: "-35.251500"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "BURRILL LAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.442600",
    Latitude: "-35.386500"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "COCKWHY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.327600",
    Latitude: "-35.557900"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "CONJOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.413300",
    Latitude: "-35.226900"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "CONJOLA PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.443000",
    Latitude: "-35.263300"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "CROOBYAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.395400",
    Latitude: "-35.321800"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "CUNJURONG POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.506100",
    Latitude: "-35.264500"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "DOLPHIN POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.443600",
    Latitude: "-35.401600"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "FISHERMANS PARADISE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.444900",
    Latitude: "-35.230500"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "KINGS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.442000",
    Latitude: "-35.370400"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "KIOLOA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.375000",
    Latitude: "-35.551400"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "LAKE CONJOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.474300",
    Latitude: "-35.281200"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "LAKE TABOURIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.415000",
    Latitude: "-35.428700"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "MANYANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.517000",
    Latitude: "-35.254400"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "MOLLYMOOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.471000",
    Latitude: "-35.341000"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "MOLLYMOOK BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.467700",
    Latitude: "-35.327600"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "MOUNT KINGIMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.340000",
    Latitude: "-35.339900"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "NARRAWALLEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.462600",
    Latitude: "-35.312400"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "POINTER MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.360000",
    Latitude: "-35.229500"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "PRETTY BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ULLADULLA",
    Category: "Delivery Area",
    Longitude: "150.352800",
    Latitude: "-35.576600"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "TERMEIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.356300",
    Latitude: "-35.475800"
  },
  {
    Country: "Australia",
    Pcode: "2539",
    Locality: "ULLADULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.463800",
    Latitude: "-35.360500"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "BRADBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.816900",
    Latitude: "-34.084000"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "CAMPBELLTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.820400",
    Latitude: "-34.064300"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "CAMPBELLTOWN NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "CATARACT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.817000",
    Latitude: "-34.310800"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "ENGLORIE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.795800",
    Latitude: "-34.080700"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "GILEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.780100",
    Latitude: "-34.134800"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "GLEN ALPINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.781700",
    Latitude: "-34.088100"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "KENTLYN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.869400",
    Latitude: "-34.059100"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "LEUMEAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.837700",
    Latitude: "-34.053800"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "MACARTHUR SQUARE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "ROSEMEADOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.791700",
    Latitude: "-34.105300"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "RUSE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.840000",
    Latitude: "-34.070600"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "ST HELENS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.813600",
    Latitude: "-34.105700"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "WEDDERBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.823400",
    Latitude: "-34.143100"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "WOODBINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.824300",
    Latitude: "-34.045700"
  },
  {
    Country: "Australia",
    Pcode: "2563",
    Locality: "MENANGLE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.757000",
    Latitude: "-34.101600"
  },
  {
    Country: "Australia",
    Pcode: "2564",
    Locality: "GLENQUARIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2564",
    Locality: "LONG POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MACQUARIE FIELDS",
    Category: "Delivery Area",
    Longitude: "150.896000",
    Latitude: "-34.011400"
  },
  {
    Country: "Australia",
    Pcode: "2564",
    Locality: "MACQUARIE FIELDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.889500",
    Latitude: "-33.990900"
  },
  {
    Country: "Australia",
    Pcode: "2565",
    Locality: "BARDIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.861100",
    Latitude: "-33.976400"
  },
  {
    Country: "Australia",
    Pcode: "2565",
    Locality: "DENHAM COURT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.830700",
    Latitude: "-33.982400"
  },
  {
    Country: "Australia",
    Pcode: "2565",
    Locality: "INGLEBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.861600",
    Latitude: "-34.004900"
  },
  {
    Country: "Australia",
    Pcode: "2565",
    Locality: "MACQUARIE LINKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.871800",
    Latitude: "-33.981200"
  },
  {
    Country: "Australia",
    Pcode: "2566",
    Locality: "BOW BOWING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.840000",
    Latitude: "-34.017200"
  },
  {
    Country: "Australia",
    Pcode: "2566",
    Locality: "MINTO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.846400",
    Latitude: "-34.031500"
  },
  {
    Country: "Australia",
    Pcode: "2566",
    Locality: "MINTO DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2566",
    Locality: "MINTO HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.874400",
    Latitude: "-34.034700"
  },
  {
    Country: "Australia",
    Pcode: "2566",
    Locality: "RABY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.816200",
    Latitude: "-34.018800"
  },
  {
    Country: "Australia",
    Pcode: "2566",
    Locality: "ST ANDREWS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.830600",
    Latitude: "-34.021200"
  },
  {
    Country: "Australia",
    Pcode: "2566",
    Locality: "VARROVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.813300",
    Latitude: "-34.004000"
  },
  {
    Country: "Australia",
    Pcode: "2567",
    Locality: "CURRANS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.774200",
    Latitude: "-34.046100"
  },
  {
    Country: "Australia",
    Pcode: "2567",
    Locality: "HARRINGTON PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.736600",
    Latitude: "-34.025600"
  },
  {
    Country: "Australia",
    Pcode: "2567",
    Locality: "MOUNT ANNAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.762600",
    Latitude: "-34.064000"
  },
  {
    Country: "Australia",
    Pcode: "2567",
    Locality: "NARELLAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.733600",
    Latitude: "-34.044500"
  },
  {
    Country: "Australia",
    Pcode: "2567",
    Locality: "NARELLAN DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2567",
    Locality: "NARELLAN VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.743500",
    Latitude: "-34.054600"
  },
  {
    Country: "Australia",
    Pcode: "2567",
    Locality: "SMEATON GRANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.757700",
    Latitude: "-34.037100"
  },
  {
    Country: "Australia",
    Pcode: "2568",
    Locality: "MENANGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.723100",
    Latitude: "-34.133900"
  },
  {
    Country: "Australia",
    Pcode: "2569",
    Locality: "DOUGLAS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.712900",
    Latitude: "-34.195300"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "BELIMBLA PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.538400",
    Latitude: "-34.084500"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "BICKLEY VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.659800",
    Latitude: "-34.079200"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "BROWNLOW HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.652700",
    Latitude: "-34.046900"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "CAMDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.693700",
    Latitude: "-34.058900"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "CAMDEN PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.717300",
    Latitude: "-34.094500"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "CAMDEN SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.700600",
    Latitude: "-34.082200"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "CAWDOR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.664300",
    Latitude: "-34.095900"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "COBBITTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.679900",
    Latitude: "-33.985300"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "ELDERSLIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "CAMDEN",
    Category: "Delivery Area",
    Longitude: "150.716600",
    Latitude: "-34.057500"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "ELLIS LANE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.675300",
    Latitude: "-34.034200"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "GLENMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.602600",
    Latitude: "-34.073900"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "GRASMERE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.671200",
    Latitude: "-34.056300"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "KIRKHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.709500",
    Latitude: "-34.036000"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "MOUNT HUNTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.608900",
    Latitude: "-34.092200"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "NATTAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.445200",
    Latitude: "-34.055800"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "OAKDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.505600",
    Latitude: "-34.078600"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "ORAN PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.742400",
    Latitude: "-33.988100"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "ORANGEVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.600900",
    Latitude: "-34.032400"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "SPRING FARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.725700",
    Latitude: "-34.068300"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "THE OAKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.564900",
    Latitude: "-34.096300"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "THERESA PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.641400",
    Latitude: "-33.989700"
  },
  {
    Country: "Australia",
    Pcode: "2570",
    Locality: "WEROMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.579300",
    Latitude: "-33.987500"
  },
  {
    Country: "Australia",
    Pcode: "2571",
    Locality: "BALMORAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PICTON",
    Category: "Delivery Area",
    Longitude: "150.516400",
    Latitude: "-34.299700"
  },
  {
    Country: "Australia",
    Pcode: "2571",
    Locality: "BUXTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.530900",
    Latitude: "-34.255800"
  },
  {
    Country: "Australia",
    Pcode: "2571",
    Locality: "COURIDJAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.554600",
    Latitude: "-34.235300"
  },
  {
    Country: "Australia",
    Pcode: "2571",
    Locality: "MALDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.636400",
    Latitude: "-34.198300"
  },
  {
    Country: "Australia",
    Pcode: "2571",
    Locality: "MOWBRAY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.549300",
    Latitude: "-34.157800"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "MILVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.913200",
    Latitude: "-34.330500"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "MONTEAGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.353100",
    Latitude: "-34.170600"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "THUDDUNGRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.137700",
    Latitude: "-34.169000"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "TUBBUL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.952800",
    Latitude: "-34.242100"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "WEEDALLION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.893300",
    Latitude: "-34.202700"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "YOUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.366900",
    Latitude: "-34.299000"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "BARTON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.137100",
    Latitude: "-35.307900"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "CANBERRA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "CAPITAL HILL",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.124400",
    Latitude: "-35.308100"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "DEAKIN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.102400",
    Latitude: "-35.318800"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "DEAKIN WEST",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "HARMAN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "HMAS HARMAN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "PARKES",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.136200",
    Latitude: "-35.296000"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "RUSSELL",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.149600",
    Latitude: "-35.298100"
  },
  {
    Country: "Australia",
    Pcode: "2600",
    Locality: "YARRALUMLA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.099800",
    Latitude: "-35.300800"
  },
  {
    Country: "Australia",
    Pcode: "2601",
    Locality: "ACTON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.112700",
    Latitude: "-35.281300"
  },
  {
    Country: "Australia",
    Pcode: "2601",
    Locality: "CANBERRA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: "GPO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2601",
    Locality: "CITY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.128800",
    Latitude: "-35.281300"
  },
  {
    Country: "Australia",
    Pcode: "2602",
    Locality: "AINSLIE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.148200",
    Latitude: "-35.263600"
  },
  {
    Country: "Australia",
    Pcode: "2602",
    Locality: "DICKSON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.140200",
    Latitude: "-35.254000"
  },
  {
    Country: "Australia",
    Pcode: "2602",
    Locality: "DOWNER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.143200",
    Latitude: "-35.242500"
  },
  {
    Country: "Australia",
    Pcode: "2602",
    Locality: "HACKETT",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.162300",
    Latitude: "-35.250500"
  },
  {
    Country: "Australia",
    Pcode: "2602",
    Locality: "LYNEHAM",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.132500",
    Latitude: "-35.243600"
  },
  {
    Country: "Australia",
    Pcode: "2602",
    Locality: "O'CONNOR",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.114700",
    Latitude: "-35.256100"
  },
  {
    Country: "Australia",
    Pcode: "2602",
    Locality: "WATSON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.157100",
    Latitude: "-35.234800"
  },
  {
    Country: "Australia",
    Pcode: "2603",
    Locality: "FORREST",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.124900",
    Latitude: "-35.316900"
  },
  {
    Country: "Australia",
    Pcode: "2603",
    Locality: "GRIFFITH",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.136500",
    Latitude: "-35.326500"
  },
  {
    Country: "Australia",
    Pcode: "2603",
    Locality: "MANUKA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2603",
    Locality: "RED HILL",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.120200",
    Latitude: "-35.332700"
  },
  {
    Country: "Australia",
    Pcode: "2604",
    Locality: "CAUSEWAY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2604",
    Locality: "KINGSTON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.146400",
    Latitude: "-35.314500"
  },
  {
    Country: "Australia",
    Pcode: "2604",
    Locality: "NARRABUNDAH",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.148800",
    Latitude: "-35.335100"
  },
  {
    Country: "Australia",
    Pcode: "2605",
    Locality: "CURTIN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.075300",
    Latitude: "-35.325400"
  },
  {
    Country: "Australia",
    Pcode: "2605",
    Locality: "GARRAN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.106800",
    Latitude: "-35.341000"
  },
  {
    Country: "Australia",
    Pcode: "2605",
    Locality: "HUGHES",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.095400",
    Latitude: "-35.332900"
  },
  {
    Country: "Australia",
    Pcode: "2606",
    Locality: "CHIFLEY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.078000",
    Latitude: "-35.353800"
  },
  {
    Country: "Australia",
    Pcode: "2606",
    Locality: "LYONS",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.074100",
    Latitude: "-35.340000"
  },
  {
    Country: "Australia",
    Pcode: "2606",
    Locality: "O'MALLEY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.112800",
    Latitude: "-35.353800"
  },
  {
    Country: "Australia",
    Pcode: "2606",
    Locality: "PHILLIP",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.091500",
    Latitude: "-35.348100"
  },
  {
    Country: "Australia",
    Pcode: "2606",
    Locality: "WODEN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2607",
    Locality: "FARRER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.102300",
    Latitude: "-35.376200"
  },
  {
    Country: "Australia",
    Pcode: "2607",
    Locality: "ISAACS",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.115300",
    Latitude: "-35.366600"
  },
  {
    Country: "Australia",
    Pcode: "2607",
    Locality: "MAWSON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.100400",
    Latitude: "-35.363000"
  },
  {
    Country: "Australia",
    Pcode: "2607",
    Locality: "PEARCE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.083700",
    Latitude: "-35.363000"
  },
  {
    Country: "Australia",
    Pcode: "2607",
    Locality: "TORRENS",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.087100",
    Latitude: "-35.371200"
  },
  {
    Country: "Australia",
    Pcode: "2608",
    Locality: "CIVIC SQUARE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2609",
    Locality: "CANBERRA AIRPORT",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.194800",
    Latitude: "-35.303400"
  },
  {
    Country: "Australia",
    Pcode: "2609",
    Locality: "FYSHWICK",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.169800",
    Latitude: "-35.322200"
  },
  {
    Country: "Australia",
    Pcode: "2609",
    Locality: "MAJURA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2609",
    Locality: "PIALLIGO",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.192300",
    Latitude: "-35.283200"
  },
  {
    Country: "Australia",
    Pcode: "2609",
    Locality: "SYMONSTON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.157200",
    Latitude: "-35.346900"
  },
  {
    Country: "Australia",
    Pcode: "2610",
    Locality: "CANBERRA BC",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2610",
    Locality: "CANBERRA MC",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "BIMBERI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.696800",
    Latitude: "-35.582700"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "BRINDABELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.653100",
    Latitude: "-35.367300"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "CHAPMAN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.036700",
    Latitude: "-35.354500"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "COOLEMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.733100",
    Latitude: "-35.666700"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "COOMBS",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.041900",
    Latitude: "-35.316000"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "COREE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2611",
    Locality: "DENMAN PROSPECT",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.024600",
    Latitude: "-35.294200"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "DAIRYMANS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.056900",
    Latitude: "-36.209200"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "DANGELONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.258100",
    Latitude: "-36.309500"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "DRY PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.932300",
    Latitude: "-36.078600"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "FRYING PAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.815400",
    Latitude: "-36.145200"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "GLEN FERGUS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.248400",
    Latitude: "-36.212000"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "IRONMUNGY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.911300",
    Latitude: "-36.623700"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "JERANGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.368000",
    Latitude: "-35.886600"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "MAFFRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.964100",
    Latitude: "-36.541900"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "MIDDLE FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.194700",
    Latitude: "-36.231300"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "MIDDLINGBANK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.815100",
    Latitude: "-36.216800"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "MURRUMBUCCA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.065800",
    Latitude: "-36.105100"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "MYALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.110800",
    Latitude: "-36.446500"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "NUMERALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.356100",
    Latitude: "-36.188400"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "PEAK VIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.386800",
    Latitude: "-36.039200"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "PINE VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.061700",
    Latitude: "-36.248800"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "POLO FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.151100",
    Latitude: "-36.231700"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "RHINE FALLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.896200",
    Latitude: "-36.179900"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "ROCK FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.224000",
    Latitude: "-36.395100"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "ROSE VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "COOMA",
    Category: "Delivery Area",
    Longitude: "149.252500",
    Latitude: "-36.116700"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "SHANNONS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.981900",
    Latitude: "-35.966600"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "SPRINGFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "COOMA",
    Category: "Delivery Area",
    Longitude: "149.118500",
    Latitude: "-36.535800"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "THE BROTHERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.084100",
    Latitude: "-36.350700"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "TUROSS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.450900",
    Latitude: "-36.277900"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "WAMBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.994200",
    Latitude: "-36.176700"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "ANDO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.293700",
    Latitude: "-36.725900"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "BOCO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.142700",
    Latitude: "-36.614500"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "CREEWAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.378300",
    Latitude: "-36.753000"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "GLEN ALLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.392400",
    Latitude: "-36.698000"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "GREENLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "NIMMITABEL",
    Category: "Delivery Area",
    Longitude: "149.453200",
    Latitude: "-36.515500"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "HOLTS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.263700",
    Latitude: "-36.616200"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "JINCUMBILLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.209800",
    Latitude: "-36.712200"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "KYBEYAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.424800",
    Latitude: "-36.390000"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "MOUNT COOPER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.200200",
    Latitude: "-36.653600"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "NIMMITABEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.283200",
    Latitude: "-36.504400"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "STEEPLE FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.351200",
    Latitude: "-36.579300"
  },
  {
    Country: "Australia",
    Pcode: "2631",
    Locality: "WINIFRED",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.370600",
    Latitude: "-36.491900"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "BIBBENLUKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.321700",
    Latitude: "-36.806900"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "BOMBALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.243700",
    Latitude: "-36.889900"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "BONDI FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.182900",
    Latitude: "-37.152000"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "BUKALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.213200",
    Latitude: "-36.799500"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "CAMBALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.134400",
    Latitude: "-36.899600"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "CATHCART",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.425900",
    Latitude: "-36.862600"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "COOLUMBOOKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.332800",
    Latitude: "-36.917900"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "CRAIGIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.056000",
    Latitude: "-37.082500"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "GUNNINGRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.152200",
    Latitude: "-36.743900"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "LORDS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.161200",
    Latitude: "-36.967800"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "MERRIANGAAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.009700",
    Latitude: "-36.843600"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "MILA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.188900",
    Latitude: "-37.051400"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "MOUNT DARRAGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.575900",
    Latitude: "-36.846900"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "PADDYS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BOMBALA",
    Category: "Delivery Area",
    Longitude: "149.326800",
    Latitude: "-37.036300"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "PALARANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.097200",
    Latitude: "-36.826800"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "QUIDONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.056300",
    Latitude: "-36.929600"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "ROCKTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.320200",
    Latitude: "-37.146800"
  },
  {
    Country: "Australia",
    Pcode: "2632",
    Locality: "ROSEMEATH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.274200",
    Latitude: "-36.971700"
  },
  {
    Country: "Australia",
    Pcode: "2633",
    Locality: "CORROWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.793500",
    Latitude: "-36.907900"
  },
  {
    Country: "Australia",
    Pcode: "2633",
    Locality: "DELEGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.905400",
    Latitude: "-37.037900"
  },
  {
    Country: "Australia",
    Pcode: "2633",
    Locality: "TOMBONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.931900",
    Latitude: "-36.899900"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "ALBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.914700",
    Latitude: "-36.073400"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "BUNGOWANNAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.763000",
    Latitude: "-35.998500"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "EAST ALBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.939100",
    Latitude: "-36.091300"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "ETTAMOGAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.955000",
    Latitude: "-36.001500"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "GLENROY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ALBURY",
    Category: "Delivery Area",
    Longitude: "146.898700",
    Latitude: "-36.052500"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "LAVINGTON DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "MOORWATHA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.727400",
    Latitude: "-35.922800"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "NORTH ALBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.936900",
    Latitude: "-36.060500"
  },
  {
    Country: "Australia",
    Pcode: "2640",
    Locality: "OURNIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.853700",
    Latitude: "-35.945800"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "BOREE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.570100",
    Latitude: "-35.069200"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "COWABBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.941400",
    Latitude: "-34.577600"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "GOOLGOWI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.757100",
    Latitude: "-33.946300"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "GRONG GRONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.782400",
    Latitude: "-34.691500"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "GUMLY GUMLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.431200",
    Latitude: "-35.137600"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "HUMULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.749400",
    Latitude: "-35.486000"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "LADYSMITH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.564300",
    Latitude: "-35.222800"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "LANDERVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.722000",
    Latitude: "-34.518700"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "MANGOPLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.247100",
    Latitude: "-35.399700"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "MARRAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.340100",
    Latitude: "-34.828400"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "MATONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.912100",
    Latitude: "-34.752500"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "MERRIWAGGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.648200",
    Latitude: "-33.816800"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "MURRULEBALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.411100",
    Latitude: "-34.704700"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "OLD JUNEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.483600",
    Latitude: "-34.815900"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "ROSEWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TUMBARUMBA",
    Category: "Delivery Area",
    Longitude: "147.859600",
    Latitude: "-35.640200"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "TABBITA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.815600",
    Latitude: "-34.096700"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "TARCUTTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.720300",
    Latitude: "-35.288500"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "URANQUINTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.220500",
    Latitude: "-35.207300"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "BURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TUMBARUMBA",
    Category: "Delivery Area",
    Longitude: "148.089200",
    Latitude: "-35.824300"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "COURABYRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.997000",
    Latitude: "-35.702600"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "GLENROY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TUMBARUMBA",
    Category: "Delivery Area",
    Longitude: "147.888500",
    Latitude: "-35.747100"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "MANNUS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.960200",
    Latitude: "-35.843100"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "MARAGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.205000",
    Latitude: "-35.914900"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "MUNDEROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.823700",
    Latitude: "-35.845800"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "PADDYS RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TUMBARUMBA",
    Category: "Delivery Area",
    Longitude: "148.166200",
    Latitude: "-35.768500"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "TARADALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.939900",
    Latitude: "-35.619100"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "TUMBARUMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.072200",
    Latitude: "-35.726200"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "WESTDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TUMBARUMBA",
    Category: "Delivery Area",
    Longitude: "147.882700",
    Latitude: "-35.574300"
  },
  {
    Country: "Australia",
    Pcode: "2653",
    Locality: "WILLIGOBUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.043400",
    Latitude: "-35.657900"
  },
  {
    Country: "Australia",
    Pcode: "2655",
    Locality: "FRENCH PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.927400",
    Latitude: "-35.260000"
  },
  {
    Country: "Australia",
    Pcode: "2655",
    Locality: "THE ROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.141800",
    Latitude: "-35.266900"
  },
  {
    Country: "Australia",
    Pcode: "2655",
    Locality: "TOOTOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.990100",
    Latitude: "-35.240400"
  },
  {
    Country: "Australia",
    Pcode: "2656",
    Locality: "BROOKDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.012000",
    Latitude: "-35.107700"
  },
  {
    Country: "Australia",
    Pcode: "2656",
    Locality: "BROOKONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.575000",
    Latitude: "-35.300800"
  },
  {
    Country: "Australia",
    Pcode: "2656",
    Locality: "FARGUNYAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.755000",
    Latitude: "-35.118700"
  },
  {
    Country: "Australia",
    Pcode: "2656",
    Locality: "LOCKHART",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.744600",
    Latitude: "-35.173800"
  },
  {
    Country: "Australia",
    Pcode: "2656",
    Locality: "MILBRULONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.874000",
    Latitude: "-35.246500"
  },
  {
    Country: "Australia",
    Pcode: "2656",
    Locality: "OSBORNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.743500",
    Latitude: "-35.359100"
  },
  {
    Country: "Australia",
    Pcode: "2656",
    Locality: "URANGELINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.591700",
    Latitude: "-35.439200"
  },
  {
    Country: "Australia",
    Pcode: "2656",
    Locality: "URANGELINE EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.695000",
    Latitude: "-35.491500"
  },
  {
    Country: "Australia",
    Pcode: "2658",
    Locality: "HENTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.069600",
    Latitude: "-35.523600"
  },
  {
    Country: "Australia",
    Pcode: "2658",
    Locality: "MUNYABLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.918200",
    Latitude: "-35.453300"
  },
  {
    Country: "Australia",
    Pcode: "2658",
    Locality: "PLEASANT HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.784500",
    Latitude: "-35.470200"
  },
  {
    Country: "Australia",
    Pcode: "2658",
    Locality: "RYAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.890700",
    Latitude: "-35.536600"
  },
  {
    Country: "Australia",
    Pcode: "2659",
    Locality: "ALMA PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.802300",
    Latitude: "-35.599500"
  },
  {
    Country: "Australia",
    Pcode: "2659",
    Locality: "WALLA WALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.870100",
    Latitude: "-35.747400"
  },
  {
    Country: "Australia",
    Pcode: "2660",
    Locality: "CULCAIRN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.001300",
    Latitude: "-35.689800"
  },
  {
    Country: "Australia",
    Pcode: "2660",
    Locality: "MORVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "CULCAIRN",
    Category: "Delivery Area",
    Longitude: "147.156400",
    Latitude: "-35.635600"
  },
  {
    Country: "Australia",
    Pcode: "2661",
    Locality: "KAPOOKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.286300",
    Latitude: "-35.159200"
  },
  {
    Country: "Australia",
    Pcode: "2663",
    Locality: "ERIN VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.543300",
    Latitude: "-34.689600"
  },
  {
    Country: "Australia",
    Pcode: "2663",
    Locality: "EURONGILLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.806600",
    Latitude: "-34.945000"
  },
  {
    Country: "Australia",
    Pcode: "2663",
    Locality: "JUNEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.579600",
    Latitude: "-34.863700"
  },
  {
    Country: "Australia",
    Pcode: "2663",
    Locality: "MARINNA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.628800",
    Latitude: "-34.807300"
  },
  {
    Country: "Australia",
    Pcode: "2663",
    Locality: "WANTIOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.663700",
    Latitude: "-34.883100"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "ARDLETHAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.842400",
    Latitude: "-34.338400"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "ARIAH PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.201800",
    Latitude: "-34.310300"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "BARELLAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.607400",
    Latitude: "-34.201100"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "BECKOM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.006000",
    Latitude: "-34.356400"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "BECTRIC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.267700",
    Latitude: "-34.503800"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "BINYA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.313800",
    Latitude: "-34.190400"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "KAMARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.753400",
    Latitude: "-34.354900"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "MIRROOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.062900",
    Latitude: "-34.228300"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "MOOMBOOLDOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.690400",
    Latitude: "-34.346800"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "QUANDARY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.322300",
    Latitude: "-34.362100"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "TARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.159600",
    Latitude: "-34.492400"
  },
  {
    Country: "Australia",
    Pcode: "2665",
    Locality: "WALLEROOBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.027800",
    Latitude: "-34.497600"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "MOUNT ADRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.900900",
    Latitude: "-35.217100"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "MOUNT HOREB",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.041600",
    Latitude: "-35.215700"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "MUNDARLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.841400",
    Latitude: "-35.138700"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "SANDY GULLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.037300",
    Latitude: "-35.294900"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "SHARPS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.040000",
    Latitude: "-35.395500"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "TUMBLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.971000",
    Latitude: "-35.143100"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "WESTWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.985000",
    Latitude: "-35.400500"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "WONDALGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.100100",
    Latitude: "-35.397400"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "YAVEN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.940600",
    Latitude: "-35.472100"
  },
  {
    Country: "Australia",
    Pcode: "2730",
    Locality: "BATLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.137000",
    Latitude: "-35.532100"
  },
  {
    Country: "Australia",
    Pcode: "2730",
    Locality: "GREEN HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ADELONG",
    Category: "Delivery Area",
    Longitude: "148.031500",
    Latitude: "-35.532300"
  },
  {
    Country: "Australia",
    Pcode: "2730",
    Locality: "KUNAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.098600",
    Latitude: "-35.549300"
  },
  {
    Country: "Australia",
    Pcode: "2730",
    Locality: "LOWER BAGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.954900",
    Latitude: "-35.559200"
  },
  {
    Country: "Australia",
    Pcode: "2731",
    Locality: "BUNNALOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.552200",
    Latitude: "-35.802500"
  },
  {
    Country: "Australia",
    Pcode: "2731",
    Locality: "MOAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.750600",
    Latitude: "-36.004700"
  },
  {
    Country: "Australia",
    Pcode: "2731",
    Locality: "TANTONAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.631200",
    Latitude: "-35.702400"
  },
  {
    Country: "Australia",
    Pcode: "2731",
    Locality: "THYRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.747300",
    Latitude: "-35.841100"
  },
  {
    Country: "Australia",
    Pcode: "2731",
    Locality: "WOMBOOTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.552000",
    Latitude: "-35.907700"
  },
  {
    Country: "Australia",
    Pcode: "2732",
    Locality: "BARHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.209900",
    Latitude: "-35.605400"
  },
  {
    Country: "Australia",
    Pcode: "2732",
    Locality: "BURRABOI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.357900",
    Latitude: "-35.344200"
  },
  {
    Country: "Australia",
    Pcode: "2732",
    Locality: "COBRAMUNGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.890000",
    Latitude: "-35.436400"
  },
  {
    Country: "Australia",
    Pcode: "2732",
    Locality: "GONN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.000100",
    Latitude: "-35.491600"
  },
  {
    Country: "Australia",
    Pcode: "2732",
    Locality: "NOORONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.017400",
    Latitude: "-35.352800"
  },
  {
    Country: "Australia",
    Pcode: "2732",
    Locality: "THULE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.414800",
    Latitude: "-35.727300"
  },
  {
    Country: "Australia",
    Pcode: "2732",
    Locality: "TULLAKOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.154500",
    Latitude: "-35.383500"
  },
  {
    Country: "Australia",
    Pcode: "2733",
    Locality: "DHURAGOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.135500",
    Latitude: "-35.204600"
  },
  {
    Country: "Australia",
    Pcode: "2733",
    Locality: "MOULAMEIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.105800",
    Latitude: "-35.001300"
  },
  {
    Country: "Australia",
    Pcode: "2733",
    Locality: "NIEMUR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.286300",
    Latitude: "-35.233800"
  },
  {
    Country: "Australia",
    Pcode: "2734",
    Locality: "CUNNINYEUK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.905100",
    Latitude: "-35.247200"
  },
  {
    Country: "Australia",
    Pcode: "2734",
    Locality: "DILPURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.667100",
    Latitude: "-35.172800"
  },
  {
    Country: "Australia",
    Pcode: "2734",
    Locality: "MALLAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.741300",
    Latitude: "-35.104900"
  },
  {
    Country: "Australia",
    Pcode: "2734",
    Locality: "MELLOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.771500",
    Latitude: "-35.321000"
  },
  {
    Country: "Australia",
    Pcode: "2734",
    Locality: "MOOLPA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.761900",
    Latitude: "-34.920600"
  },
  {
    Country: "Australia",
    Pcode: "2734",
    Locality: "MURRAY DOWNS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.648900",
    Latitude: "-35.312300"
  },
  {
    Country: "Australia",
    Pcode: "2734",
    Locality: "STONY CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.535400",
    Latitude: "-35.088100"
  },
  {
    Country: "Australia",
    Pcode: "2734",
    Locality: "TOORANIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.559800",
    Latitude: "-35.018300"
  },
  {
    Country: "Australia",
    Pcode: "2734",
    Locality: "WETUPPA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.876800",
    Latitude: "-35.327900"
  },
  {
    Country: "Australia",
    Pcode: "2735",
    Locality: "KORALEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.450300",
    Latitude: "-35.127600"
  },
  {
    Country: "Australia",
    Pcode: "2735",
    Locality: "SPEEWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.536900",
    Latitude: "-35.195600"
  },
  {
    Country: "Australia",
    Pcode: "2736",
    Locality: "GOODNIGHT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.385600",
    Latitude: "-34.929100"
  },
  {
    Country: "Australia",
    Pcode: "2736",
    Locality: "TOOLEYBUC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.399600",
    Latitude: "-35.030100"
  },
  {
    Country: "Australia",
    Pcode: "2737",
    Locality: "EUSTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.836300",
    Latitude: "-34.288200"
  },
  {
    Country: "Australia",
    Pcode: "2738",
    Locality: "GOL GOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.221000",
    Latitude: "-34.158700"
  },
  {
    Country: "Australia",
    Pcode: "2738",
    Locality: "MALLEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.534000",
    Latitude: "-34.267000"
  },
  {
    Country: "Australia",
    Pcode: "2738",
    Locality: "MONAK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.308800",
    Latitude: "-34.298600"
  },
  {
    Country: "Australia",
    Pcode: "2738",
    Locality: "PARINGI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.388200",
    Latitude: "-34.287100"
  },
  {
    Country: "Australia",
    Pcode: "2738",
    Locality: "TRENTHAM CLIFFS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.264400",
    Latitude: "-34.244800"
  },
  {
    Country: "Australia",
    Pcode: "2739",
    Locality: "BOEILL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.098500",
    Latitude: "-34.156700"
  },
  {
    Country: "Australia",
    Pcode: "2739",
    Locality: "BURONGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.194900",
    Latitude: "-34.156800"
  },
  {
    Country: "Australia",
    Pcode: "2739",
    Locality: "MOURQUONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.169100",
    Latitude: "-34.140800"
  },
  {
    Country: "Australia",
    Pcode: "2745",
    Locality: "GLENMORE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.681800",
    Latitude: "-33.792500"
  },
  {
    Country: "Australia",
    Pcode: "2745",
    Locality: "GREENDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MULGOA",
    Category: "Delivery Area",
    Longitude: "150.661700",
    Latitude: "-33.923500"
  },
  {
    Country: "Australia",
    Pcode: "2745",
    Locality: "LUDDENHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.718200",
    Latitude: "-33.861600"
  },
  {
    Country: "Australia",
    Pcode: "2745",
    Locality: "MULGOA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.642200",
    Latitude: "-33.820100"
  },
  {
    Country: "Australia",
    Pcode: "2745",
    Locality: "REGENTVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.662100",
    Latitude: "-33.771300"
  },
  {
    Country: "Australia",
    Pcode: "2745",
    Locality: "WALLACIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.645900",
    Latitude: "-33.892700"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "CADDENS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.737000",
    Latitude: "-33.772100"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "CAMBRIDGE GARDENS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.720700",
    Latitude: "-33.738000"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "CAMBRIDGE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.726800",
    Latitude: "-33.749600"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "CLAREMONT MEADOWS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.753000",
    Latitude: "-33.775000"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "JORDAN SPRINGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.728700",
    Latitude: "-33.722600"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "KINGSWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.722700",
    Latitude: "-33.761500"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "LLANDILO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.751200",
    Latitude: "-33.717600"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "SHANES PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.791400",
    Latitude: "-33.707500"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "WERRINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.750500",
    Latitude: "-33.758700"
  },
  {
    Country: "Australia",
    Pcode: "2747",
    Locality: "WERRINGTON COUNTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.748800",
    Latitude: "-33.747000"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "SINGLETONS MILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.070800",
    Latitude: "-33.475400"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "SPENCER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.128900",
    Latitude: "-33.444000"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "ST ALBANS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.013000",
    Latitude: "-33.242400"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "UPPER MACDONALD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.929200",
    Latitude: "-33.254600"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "WEBBS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.916100",
    Latitude: "-33.352800"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "WISEMANS FERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.984100",
    Latitude: "-33.387900"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "WRIGHTS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.051400",
    Latitude: "-33.292300"
  },
  {
    Country: "Australia",
    Pcode: "2776",
    Locality: "FAULCONBRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.550600",
    Latitude: "-33.685100"
  },
  {
    Country: "Australia",
    Pcode: "2777",
    Locality: "HAWKESBURY HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.643600",
    Latitude: "-33.670100"
  },
  {
    Country: "Australia",
    Pcode: "2777",
    Locality: "SPRINGWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.574700",
    Latitude: "-33.702200"
  },
  {
    Country: "Australia",
    Pcode: "2777",
    Locality: "SUN VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.597600",
    Latitude: "-33.707800"
  },
  {
    Country: "Australia",
    Pcode: "2777",
    Locality: "VALLEY HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.583700",
    Latitude: "-33.709400"
  },
  {
    Country: "Australia",
    Pcode: "2777",
    Locality: "WINMALEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.614900",
    Latitude: "-33.678000"
  },
  {
    Country: "Australia",
    Pcode: "2777",
    Locality: "YELLOW ROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BLUE MOUNTAINS",
    Category: "Delivery Area",
    Longitude: "150.634000",
    Latitude: "-33.693200"
  },
  {
    Country: "Australia",
    Pcode: "2778",
    Locality: "LINDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.514800",
    Latitude: "-33.711000"
  },
  {
    Country: "Australia",
    Pcode: "2778",
    Locality: "WOODFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.487900",
    Latitude: "-33.705500"
  },
  {
    Country: "Australia",
    Pcode: "2779",
    Locality: "HAZELBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.457500",
    Latitude: "-33.730100"
  },
  {
    Country: "Australia",
    Pcode: "2780",
    Locality: "KATOOMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.295400",
    Latitude: "-33.720500"
  },
  {
    Country: "Australia",
    Pcode: "2780",
    Locality: "KATOOMBA DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2780",
    Locality: "LEURA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.338800",
    Latitude: "-33.703900"
  },
  {
    Country: "Australia",
    Pcode: "2780",
    Locality: "MEDLOW BATH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.323200",
    Latitude: "-33.667800"
  },
  {
    Country: "Australia",
    Pcode: "2782",
    Locality: "WENTWORTH FALLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.379600",
    Latitude: "-33.758300"
  },
  {
    Country: "Australia",
    Pcode: "2783",
    Locality: "LAWSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.431700",
    Latitude: "-33.718100"
  },
  {
    Country: "Australia",
    Pcode: "2784",
    Locality: "BULLABURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.409200",
    Latitude: "-33.727700"
  },
  {
    Country: "Australia",
    Pcode: "2785",
    Locality: "BLACKHEATH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.297900",
    Latitude: "-33.657400"
  },
  {
    Country: "Australia",
    Pcode: "2785",
    Locality: "MEGALONG VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.256600",
    Latitude: "-33.749400"
  },
  {
    Country: "Australia",
    Pcode: "2786",
    Locality: "BELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.316600",
    Latitude: "-33.500300"
  },
  {
    Country: "Australia",
    Pcode: "2786",
    Locality: "DARGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.251100",
    Latitude: "-33.488200"
  },
  {
    Country: "Australia",
    Pcode: "2786",
    Locality: "MOUNT IRVINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.463600",
    Latitude: "-33.481400"
  },
  {
    Country: "Australia",
    Pcode: "2786",
    Locality: "MOUNT VICTORIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.240400",
    Latitude: "-33.577000"
  },
  {
    Country: "Australia",
    Pcode: "2786",
    Locality: "MOUNT WILSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.381700",
    Latitude: "-33.503900"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "BLACK SPRINGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.722200",
    Latitude: "-33.856000"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "CHATHAM VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.876200",
    Latitude: "-33.843100"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "DUCKMALOI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.956600",
    Latitude: "-33.692000"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "EDITH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.943700",
    Latitude: "-33.774900"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "ESSINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.704300",
    Latitude: "-33.743600"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "GINGKIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.971200",
    Latitude: "-33.890200"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "GURNANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.845300",
    Latitude: "-34.039900"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "HAZELGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.895600",
    Latitude: "-33.653500"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "JAUNTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.914800",
    Latitude: "-33.985700"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "KANANGRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.100000",
    Latitude: "-33.982400"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "MAYFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "OBERON",
    Category: "Delivery Area",
    Longitude: "149.775200",
    Latitude: "-33.664200"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "MOUNT OLIVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "OBERON",
    Category: "Delivery Area",
    Longitude: "149.930900",
    Latitude: "-33.598600"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "MOUNT WERONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.956800",
    Latitude: "-34.074100"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "MOZART",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.829600",
    Latitude: "-33.839200"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "NORWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.794400",
    Latitude: "-33.768300"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "OBERON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.854900",
    Latitude: "-33.694900"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "PORTERS RETREAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.772800",
    Latitude: "-33.996900"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "SHOOTERS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.827200",
    Latitude: "-33.925900"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "TARANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.883500",
    Latitude: "-33.553100"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "THE MEADOWS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.939700",
    Latitude: "-33.650500"
  },
  {
    Country: "Australia",
    Pcode: "2787",
    Locality: "YERRANDERIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "BEN BULLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.018900",
    Latitude: "-33.233100"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "BLACKMANS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.058300",
    Latitude: "-33.346300"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "BOWENFELS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.107700",
    Latitude: "-33.480600"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "CLARENCE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.210300",
    Latitude: "-33.470600"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "COBAR PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.157100",
    Latitude: "-33.466200"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "CORNEY TOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.177800",
    Latitude: "-33.476400"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "CULLEN BULLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.004800",
    Latitude: "-33.274200"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "DOCTORS GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.177500",
    Latitude: "-33.489900"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "GANBENANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.116300",
    Latitude: "-33.711800"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "GOOD FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.110800",
    Latitude: "-33.586700"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "HAMPTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.026700",
    Latitude: "-33.679100"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "HARTLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.153500",
    Latitude: "-33.549900"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "HARTLEY VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.226100",
    Latitude: "-33.531600"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "HASSANS WALLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.152800",
    Latitude: "-33.508300"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "MURRAWOMBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.209400",
    Latitude: "-31.176600"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "NYNGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.152800",
    Latitude: "-31.530500"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "PANGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.807100",
    Latitude: "-32.000000"
  },
  {
    Country: "Australia",
    Pcode: "2826",
    Locality: "BOGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "NYNGAN",
    Category: "Delivery Area",
    Longitude: "147.479400",
    Latitude: "-32.028800"
  },
  {
    Country: "Australia",
    Pcode: "2826",
    Locality: "NEVERTIRE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.655500",
    Latitude: "-31.855900"
  },
  {
    Country: "Australia",
    Pcode: "2827",
    Locality: "BEARBONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.892900",
    Latitude: "-31.663900"
  },
  {
    Country: "Australia",
    Pcode: "2827",
    Locality: "BIDDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.794700",
    Latitude: "-31.553000"
  },
  {
    Country: "Australia",
    Pcode: "2827",
    Locality: "BREELONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.812000",
    Latitude: "-31.806400"
  },
  {
    Country: "Australia",
    Pcode: "2827",
    Locality: "COLLIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.275900",
    Latitude: "-31.693200"
  },
  {
    Country: "Australia",
    Pcode: "2827",
    Locality: "CURBAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.530200",
    Latitude: "-31.528300"
  },
  {
    Country: "Australia",
    Pcode: "2827",
    Locality: "GILGANDRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.612800",
    Latitude: "-31.711200"
  },
  {
    Country: "Australia",
    Pcode: "2827",
    Locality: "MERRIGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.260600",
    Latitude: "-31.492900"
  },
  {
    Country: "Australia",
    Pcode: "2828",
    Locality: "ARMATREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.509400",
    Latitude: "-31.425000"
  },
  {
    Country: "Australia",
    Pcode: "2828",
    Locality: "BLACK HOLLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.864900",
    Latitude: "-31.089800"
  },
  {
    Country: "Australia",
    Pcode: "2828",
    Locality: "BOURBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.190700",
    Latitude: "-31.248300"
  },
  {
    Country: "Australia",
    Pcode: "2828",
    Locality: "GULARGAMBONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.506500",
    Latitude: "-31.281800"
  },
  {
    Country: "Australia",
    Pcode: "2828",
    Locality: "MOUNT TENANDRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.763900",
    Latitude: "-31.208000"
  },
  {
    Country: "Australia",
    Pcode: "2828",
    Locality: "QUANDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.781100",
    Latitude: "-31.094100"
  },
  {
    Country: "Australia",
    Pcode: "2828",
    Locality: "WARRUMBUNGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.919600",
    Latitude: "-31.260500"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "BILLEROY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.453200",
    Latitude: "-30.613000"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "COMBARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.294000",
    Latitude: "-31.153800"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "CONIMBIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.020400",
    Latitude: "-30.670100"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "COONAMBLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.271500",
    Latitude: "-30.868700"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "GILGOOMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.575200",
    Latitude: "-30.722800"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "GUNGALMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.976000",
    Latitude: "-30.502700"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "MAGOMETON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.642500",
    Latitude: "-31.024600"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "NEBEA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.551200",
    Latitude: "-30.898900"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "PINE GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.683600",
    Latitude: "-30.877200"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "TERIDGERIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.798800",
    Latitude: "-30.939400"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "TOOLOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.166000",
    Latitude: "-31.001400"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "URAWILKIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.710900",
    Latitude: "-30.697600"
  },
  {
    Country: "Australia",
    Pcode: "2829",
    Locality: "WINGADEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.270400",
    Latitude: "-30.534400"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "BALLIMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.884000",
    Latitude: "-32.166700"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "BROCKLEHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.605600",
    Latitude: "-32.137600"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "DUBBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.617300",
    Latitude: "-32.281400"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "DUBBO DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "DUBBO EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "DUBBO GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "DUBBO WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "GOONOO FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.870700",
    Latitude: "-32.024200"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "KICKABIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.397800",
    Latitude: "-31.912100"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "MINORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.445300",
    Latitude: "-32.280800"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "MOGRIGUY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.653000",
    Latitude: "-32.068600"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "RAWSONVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.475400",
    Latitude: "-32.153700"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "TERRAMUNGAMINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.505500",
    Latitude: "-32.063100"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "TOONGI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.550400",
    Latitude: "-32.483200"
  },
  {
    Country: "Australia",
    Pcode: "2830",
    Locality: "WAMBANGALANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.487700",
    Latitude: "-32.556800"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "BYROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.429900",
    Latitude: "-30.503800"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "CARINDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.618600",
    Latitude: "-30.382400"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "COOLABAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.700600",
    Latitude: "-30.999800"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "ELONG ELONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.084800",
    Latitude: "-32.152500"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "GIRILAMBONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.895100",
    Latitude: "-31.229600"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "HERMIDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.645200",
    Latitude: "-31.632500"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "MACQUARIE MARSHES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.620100",
    Latitude: "-30.687500"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "MERRYGOEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.285300",
    Latitude: "-31.814200"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "NEILREX",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.382300",
    Latitude: "-31.739900"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "NYMAGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.125000",
    Latitude: "-32.125800"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "QUAMBONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.894900",
    Latitude: "-30.902400"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "THE MARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.231100",
    Latitude: "-30.779400"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "DABEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.051600",
    Latitude: "-32.846400"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "DUNGEREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.854800",
    Latitude: "-32.765800"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "DUNVILLE LOOP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.131100",
    Latitude: "-32.906700"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "GINGHI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.054200",
    Latitude: "-32.591800"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "GLEN ALICE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.151600",
    Latitude: "-33.057400"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "GROWEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.088600",
    Latitude: "-32.645800"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "KELGOOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.268100",
    Latitude: "-32.860000"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "LEE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.147000",
    Latitude: "-32.537000"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "MOUNT MARSDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.052500",
    Latitude: "-32.939500"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "CORINELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.560300",
    Latitude: "-33.536600"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "FAIRHOLME",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.379400",
    Latitude: "-33.310200"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "FORBES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.000300",
    Latitude: "-33.389900"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "GAREMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.929100",
    Latitude: "-33.608800"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "JEMALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.737300",
    Latitude: "-33.442100"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "MULYANDRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.165800",
    Latitude: "-33.571500"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "OOMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.062400",
    Latitude: "-33.640500"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "PAYTENS BRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.326800",
    Latitude: "-33.560100"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "WARROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.536700",
    Latitude: "-33.317500"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "WIRRINYA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.726700",
    Latitude: "-33.606000"
  },
  {
    Country: "Australia",
    Pcode: "2873",
    Locality: "ALBERT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.473900",
    Latitude: "-32.380700"
  },
  {
    Country: "Australia",
    Pcode: "2873",
    Locality: "FIVE WAYS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.946300",
    Latitude: "-32.165500"
  },
  {
    Country: "Australia",
    Pcode: "2873",
    Locality: "MIAMLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.918800",
    Latitude: "-32.399800"
  },
  {
    Country: "Australia",
    Pcode: "2873",
    Locality: "TOTTENHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.280000",
    Latitude: "-32.275400"
  },
  {
    Country: "Australia",
    Pcode: "2874",
    Locality: "TULLAMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.583900",
    Latitude: "-32.563700"
  },
  {
    Country: "Australia",
    Pcode: "2875",
    Locality: "BRUIE PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.880300",
    Latitude: "-32.826300"
  },
  {
    Country: "Australia",
    Pcode: "2875",
    Locality: "FIFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.444800",
    Latitude: "-32.860100"
  },
  {
    Country: "Australia",
    Pcode: "2875",
    Locality: "OOTHA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.465200",
    Latitude: "-33.118000"
  },
  {
    Country: "Australia",
    Pcode: "2875",
    Locality: "TRUNDLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.728000",
    Latitude: "-32.840200"
  },
  {
    Country: "Australia",
    Pcode: "2875",
    Locality: "YARRABANDAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.572700",
    Latitude: "-33.137300"
  },
  {
    Country: "Australia",
    Pcode: "2876",
    Locality: "BOGAN GATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.767900",
    Latitude: "-33.090000"
  },
  {
    Country: "Australia",
    Pcode: "2876",
    Locality: "GUNNING GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.743000",
    Latitude: "-33.264500"
  },
  {
    Country: "Australia",
    Pcode: "2876",
    Locality: "GUNNINGBLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.920100",
    Latitude: "-33.129900"
  },
  {
    Country: "Australia",
    Pcode: "2876",
    Locality: "NELUNGALOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.980100",
    Latitude: "-33.180500"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "BOBADAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.726000",
    Latitude: "-32.288200"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "BOONA MOUNT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.259900",
    Latitude: "-32.629300"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "CONDOBOLIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.967300",
    Latitude: "-32.747600"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "DERRIWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.357600",
    Latitude: "-33.096200"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "EREMERANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.382300",
    Latitude: "-32.609400"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "EUABALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.413500",
    Latitude: "-33.023000"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "EUABALONG WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.394700",
    Latitude: "-33.056900"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "GILGUNNIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.056200",
    Latitude: "-32.446200"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "KIACATOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.765800",
    Latitude: "-33.050300"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "MOUNT HOPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.918900",
    Latitude: "-32.745200"
  },
  {
    Country: "Australia",
    Pcode: "2877",
    Locality: "MULGUTHRIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.365900",
    Latitude: "-33.176200"
  },
  {
    Country: "Australia",
    Pcode: "2878",
    Locality: "IVANHOE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.247300",
    Latitude: "-32.702200"
  },
  {
    Country: "Australia",
    Pcode: "2878",
    Locality: "MOSSGIEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.603600",
    Latitude: "-33.280900"
  },
  {
    Country: "Australia",
    Pcode: "2879",
    Locality: "MENINDEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.666800",
    Latitude: "-32.368500"
  },
  {
    Country: "Australia",
    Pcode: "2879",
    Locality: "SUNSET STRIP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.344400",
    Latitude: "-32.279500"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "BROKEN HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.560300",
    Latitude: "-32.352800"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "BROKEN HILL NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "BROKEN HILL WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "BROUGHAMS GATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.305700",
    Latitude: "-30.938700"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "FOWLERS GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.655600",
    Latitude: "-31.358100"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "LITTLE TOPAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.434700",
    Latitude: "-31.796600"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "MILPARINKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.651500",
    Latitude: "-29.929100"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "MUTAWINTJI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.365800",
    Latitude: "-31.076700"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "PACKSADDLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.140700",
    Latitude: "-30.463400"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "SILVERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.163500",
    Latitude: "-31.805400"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "SOUTH BROKEN HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2880",
    Locality: "TIBOOBURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.044800",
    Latitude: "-29.493600"
  },
  {
    Country: "Australia",
    Pcode: "2890",
    Locality: "AUSTRALIAN DEFENCE FORCES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2891",
    Locality: "INTERNATIONAL MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2898",
    Locality: "LORD HOWE ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "159.071100",
    Latitude: "-31.527500"
  },
  {
    Country: "Australia",
    Pcode: "2899",
    Locality: "NORFOLK ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2900",
    Locality: "GREENWAY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.064600",
    Latitude: "-35.415000"
  },
  {
    Country: "Australia",
    Pcode: "2901",
    Locality: "TUGGERANONG DC",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2902",
    Locality: "KAMBAH",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.062800",
    Latitude: "-35.386300"
  },
  {
    Country: "Australia",
    Pcode: "2902",
    Locality: "KAMBAH VILLAGE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2903",
    Locality: "ERINDALE CENTRE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2903",
    Locality: "OXLEY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.078900",
    Latitude: "-35.409100"
  },
  {
    Country: "Australia",
    Pcode: "2903",
    Locality: "WANNIASSA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.088700",
    Latitude: "-35.397100"
  },
  {
    Country: "Australia",
    Pcode: "2904",
    Locality: "FADDEN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.116600",
    Latitude: "-35.403200"
  },
  {
    Country: "Australia",
    Pcode: "2904",
    Locality: "GOWRIE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.109900",
    Latitude: "-35.411800"
  },
  {
    Country: "Australia",
    Pcode: "2904",
    Locality: "MACARTHUR",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.132600",
    Latitude: "-35.404900"
  },
  {
    Country: "Australia",
    Pcode: "3079",
    Locality: "IVANHOE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3081",
    Locality: "BELLFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "HEIDELBERG WEST",
    Category: "Delivery Area",
    Longitude: "145.038700",
    Latitude: "-37.752900"
  },
  {
    Country: "Australia",
    Pcode: "3081",
    Locality: "HEIDELBERG HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.053400",
    Latitude: "-37.744400"
  },
  {
    Country: "Australia",
    Pcode: "3081",
    Locality: "HEIDELBERG RGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3081",
    Locality: "HEIDELBERG WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.045000",
    Latitude: "-37.739400"
  },
  {
    Country: "Australia",
    Pcode: "3082",
    Locality: "MILL PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.062700",
    Latitude: "-37.664100"
  },
  {
    Country: "Australia",
    Pcode: "3083",
    Locality: "BUNDOORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.056000",
    Latitude: "-37.702800"
  },
  {
    Country: "Australia",
    Pcode: "3083",
    Locality: "KINGSBURY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.034500",
    Latitude: "-37.716900"
  },
  {
    Country: "Australia",
    Pcode: "3083",
    Locality: "LA TROBE UNIVERSITY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3084",
    Locality: "BANYULE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3084",
    Locality: "EAGLEMONT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.066000",
    Latitude: "-37.763100"
  },
  {
    Country: "Australia",
    Pcode: "3084",
    Locality: "HEIDELBERG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.069700",
    Latitude: "-37.751300"
  },
  {
    Country: "Australia",
    Pcode: "3084",
    Locality: "ROSANNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.069800",
    Latitude: "-37.739800"
  },
  {
    Country: "Australia",
    Pcode: "3084",
    Locality: "VIEWBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.094300",
    Latitude: "-37.740400"
  },
  {
    Country: "Australia",
    Pcode: "3085",
    Locality: "MACLEOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.068600",
    Latitude: "-37.720700"
  },
  {
    Country: "Australia",
    Pcode: "3085",
    Locality: "MACLEOD WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3085",
    Locality: "YALLAMBIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.093300",
    Latitude: "-37.726800"
  },
  {
    Country: "Australia",
    Pcode: "3086",
    Locality: "LA TROBE UNIVERSITY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3087",
    Locality: "WATSONIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.085000",
    Latitude: "-37.710000"
  },
  {
    Country: "Australia",
    Pcode: "3087",
    Locality: "WATSONIA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.084900",
    Latitude: "-37.696200"
  },
  {
    Country: "Australia",
    Pcode: "3088",
    Locality: "BRIAR HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.123500",
    Latitude: "-37.706000"
  },
  {
    Country: "Australia",
    Pcode: "3088",
    Locality: "GREENSBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.110000",
    Latitude: "-37.699700"
  },
  {
    Country: "Australia",
    Pcode: "3088",
    Locality: "ST HELENA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.123100",
    Latitude: "-37.689800"
  },
  {
    Country: "Australia",
    Pcode: "3089",
    Locality: "DIAMOND CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.152200",
    Latitude: "-37.667300"
  },
  {
    Country: "Australia",
    Pcode: "3090",
    Locality: "PLENTY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.112600",
    Latitude: "-37.665400"
  },
  {
    Country: "Australia",
    Pcode: "3091",
    Locality: "YARRAMBAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.136400",
    Latitude: "-37.637900"
  },
  {
    Country: "Australia",
    Pcode: "3093",
    Locality: "LOWER PLENTY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.117900",
    Latitude: "-37.737800"
  },
  {
    Country: "Australia",
    Pcode: "3094",
    Locality: "MONTMORENCY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.123800",
    Latitude: "-37.720200"
  },
  {
    Country: "Australia",
    Pcode: "3095",
    Locality: "ELTHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.163200",
    Latitude: "-37.712900"
  },
  {
    Country: "Australia",
    Pcode: "3095",
    Locality: "ELTHAM NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.141500",
    Latitude: "-37.697700"
  },
  {
    Country: "Australia",
    Pcode: "3095",
    Locality: "RESEARCH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.188200",
    Latitude: "-37.705600"
  },
  {
    Country: "Australia",
    Pcode: "3096",
    Locality: "WATTLE GLEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.188700",
    Latitude: "-37.672200"
  },
  {
    Country: "Australia",
    Pcode: "3097",
    Locality: "BEND OF ISLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.277800",
    Latitude: "-37.700500"
  },
  {
    Country: "Australia",
    Pcode: "3097",
    Locality: "KANGAROO GROUND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.231100",
    Latitude: "-37.691100"
  },
  {
    Country: "Australia",
    Pcode: "3097",
    Locality: "WATSONS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.261100",
    Latitude: "-37.663500"
  },
  {
    Country: "Australia",
    Pcode: "3099",
    Locality: "ARTHURS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.204800",
    Latitude: "-37.567000"
  },
  {
    Country: "Australia",
    Pcode: "3099",
    Locality: "COTTLES BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.219800",
    Latitude: "-37.606900"
  },
  {
    Country: "Australia",
    Pcode: "3099",
    Locality: "HURSTBRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.193000",
    Latitude: "-37.636900"
  },
  {
    Country: "Australia",
    Pcode: "3099",
    Locality: "NUTFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.185100",
    Latitude: "-37.600700"
  },
  {
    Country: "Australia",
    Pcode: "3099",
    Locality: "STRATHEWEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.274100",
    Latitude: "-37.539000"
  },
  {
    Country: "Australia",
    Pcode: "3101",
    Locality: "COTHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3101",
    Locality: "KEW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.041100",
    Latitude: "-37.801400"
  },
  {
    Country: "Australia",
    Pcode: "3102",
    Locality: "KEW EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.052800",
    Latitude: "-37.790500"
  },
  {
    Country: "Australia",
    Pcode: "3103",
    Locality: "BALWYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.087300",
    Latitude: "-37.809700"
  },
  {
    Country: "Australia",
    Pcode: "3103",
    Locality: "BALWYN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3103",
    Locality: "DEEPDENE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.066100",
    Latitude: "-37.811400"
  },
  {
    Country: "Australia",
    Pcode: "3103",
    Locality: "STRADBROKE PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "CARE PO ONLY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3104",
    Locality: "BALWYN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.084600",
    Latitude: "-37.790800"
  },
  {
    Country: "Australia",
    Pcode: "3104",
    Locality: "GREYTHORN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3105",
    Locality: "BULLEEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.085700",
    Latitude: "-37.766300"
  },
  {
    Country: "Australia",
    Pcode: "3106",
    Locality: "TEMPLESTOWE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.144300",
    Latitude: "-37.756200"
  },
  {
    Country: "Australia",
    Pcode: "3107",
    Locality: "TEMPLESTOWE LOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.114300",
    Latitude: "-37.764200"
  },
  {
    Country: "Australia",
    Pcode: "3108",
    Locality: "DONCASTER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.122300",
    Latitude: "-37.783000"
  },
  {
    Country: "Australia",
    Pcode: "3109",
    Locality: "DONCASTER EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.161500",
    Latitude: "-37.775800"
  },
  {
    Country: "Australia",
    Pcode: "3109",
    Locality: "DONCASTER HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3111",
    Locality: "DONVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.185000",
    Latitude: "-37.786700"
  },
  {
    Country: "Australia",
    Pcode: "3113",
    Locality: "NORTH WARRANDYTE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.212700",
    Latitude: "-37.726200"
  },
  {
    Country: "Australia",
    Pcode: "3113",
    Locality: "WARRANDYTE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.211100",
    Latitude: "-37.751000"
  },
  {
    Country: "Australia",
    Pcode: "3114",
    Locality: "PARK ORCHARDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.211200",
    Latitude: "-37.775900"
  },
  {
    Country: "Australia",
    Pcode: "3115",
    Locality: "WONGA PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.265800",
    Latitude: "-37.737500"
  },
  {
    Country: "Australia",
    Pcode: "3116",
    Locality: "CHIRNSIDE PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.314800",
    Latitude: "-37.739600"
  },
  {
    Country: "Australia",
    Pcode: "3121",
    Locality: "BURNLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.016400",
    Latitude: "-37.829700"
  },
  {
    Country: "Australia",
    Pcode: "3121",
    Locality: "BURNLEY NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3121",
    Locality: "CREMORNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.992500",
    Latitude: "-37.829400"
  },
  {
    Country: "Australia",
    Pcode: "3121",
    Locality: "RICHMOND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.002500",
    Latitude: "-37.822000"
  },
  {
    Country: "Australia",
    Pcode: "3121",
    Locality: "RICHMOND EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3121",
    Locality: "RICHMOND NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3121",
    Locality: "RICHMOND SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3122",
    Locality: "AUBURN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3199",
    Locality: "FRANKSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.142200",
    Latitude: "-38.150800"
  },
  {
    Country: "Australia",
    Pcode: "3199",
    Locality: "FRANKSTON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3199",
    Locality: "FRANKSTON HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3199",
    Locality: "FRANKSTON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.135300",
    Latitude: "-38.176700"
  },
  {
    Country: "Australia",
    Pcode: "3199",
    Locality: "KARINGAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3199",
    Locality: "KARINGAL CENTRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3200",
    Locality: "FRANKSTON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.167700",
    Latitude: "-38.126600"
  },
  {
    Country: "Australia",
    Pcode: "3200",
    Locality: "PINES FOREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3201",
    Locality: "CARRUM DOWNS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.175900",
    Latitude: "-38.096500"
  },
  {
    Country: "Australia",
    Pcode: "3202",
    Locality: "HEATHERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.093700",
    Latitude: "-37.955900"
  },
  {
    Country: "Australia",
    Pcode: "3204",
    Locality: "BENTLEIGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.037700",
    Latitude: "-37.922700"
  },
  {
    Country: "Australia",
    Pcode: "3204",
    Locality: "MCKINNON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.039000",
    Latitude: "-37.910600"
  },
  {
    Country: "Australia",
    Pcode: "3204",
    Locality: "ORMOND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.044600",
    Latitude: "-37.903200"
  },
  {
    Country: "Australia",
    Pcode: "3204",
    Locality: "PATTERSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3205",
    Locality: "SOUTH MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.960600",
    Latitude: "-37.834000"
  },
  {
    Country: "Australia",
    Pcode: "3206",
    Locality: "ALBERT PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.951500",
    Latitude: "-37.845300"
  },
  {
    Country: "Australia",
    Pcode: "3206",
    Locality: "MIDDLE PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.960700",
    Latitude: "-37.850600"
  },
  {
    Country: "Australia",
    Pcode: "3207",
    Locality: "GARDEN CITY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3207",
    Locality: "PORT MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.923300",
    Latitude: "-37.834300"
  },
  {
    Country: "Australia",
    Pcode: "3211",
    Locality: "LITTLE RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.469600",
    Latitude: "-37.935100"
  },
  {
    Country: "Australia",
    Pcode: "3212",
    Locality: "AVALON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.447000",
    Latitude: "-38.048800"
  },
  {
    Country: "Australia",
    Pcode: "3212",
    Locality: "LARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.412000",
    Latitude: "-38.010600"
  },
  {
    Country: "Australia",
    Pcode: "3212",
    Locality: "POINT WILSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.526100",
    Latitude: "-38.033000"
  },
  {
    Country: "Australia",
    Pcode: "3213",
    Locality: "ANAKIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.289400",
    Latitude: "-37.940600"
  },
  {
    Country: "Australia",
    Pcode: "3213",
    Locality: "BATESFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.277900",
    Latitude: "-38.091000"
  },
  {
    Country: "Australia",
    Pcode: "3213",
    Locality: "LOVELY BANKS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.318700",
    Latitude: "-38.046800"
  },
  {
    Country: "Australia",
    Pcode: "3213",
    Locality: "MOORABOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.277100",
    Latitude: "-38.038000"
  },
  {
    Country: "Australia",
    Pcode: "3214",
    Locality: "CORIO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.372900",
    Latitude: "-38.067500"
  },
  {
    Country: "Australia",
    Pcode: "3214",
    Locality: "NORLANE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.355800",
    Latitude: "-38.093700"
  },
  {
    Country: "Australia",
    Pcode: "3214",
    Locality: "NORTH SHORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.373800",
    Latitude: "-38.096200"
  },
  {
    Country: "Australia",
    Pcode: "3215",
    Locality: "BELL PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.338200",
    Latitude: "-38.104500"
  },
  {
    Country: "Australia",
    Pcode: "3215",
    Locality: "BELL POST HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.319700",
    Latitude: "-38.096300"
  },
  {
    Country: "Australia",
    Pcode: "3215",
    Locality: "DRUMCONDRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.354000",
    Latitude: "-38.130100"
  },
  {
    Country: "Australia",
    Pcode: "3215",
    Locality: "GEELONG NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3215",
    Locality: "HAMLYN HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.323000",
    Latitude: "-38.118700"
  },
  {
    Country: "Australia",
    Pcode: "3215",
    Locality: "NORTH GEELONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.349900",
    Latitude: "-38.116400"
  },
  {
    Country: "Australia",
    Pcode: "3215",
    Locality: "RIPPLESIDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.356100",
    Latitude: "-38.123900"
  },
  {
    Country: "Australia",
    Pcode: "3216",
    Locality: "BELMONT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.343800",
    Latitude: "-38.180000"
  },
  {
    Country: "Australia",
    Pcode: "3216",
    Locality: "GROVEDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.331900",
    Latitude: "-38.202700"
  },
  {
    Country: "Australia",
    Pcode: "3216",
    Locality: "GROVEDALE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3216",
    Locality: "HIGHTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.309800",
    Latitude: "-38.175200"
  },
  {
    Country: "Australia",
    Pcode: "3216",
    Locality: "MARSHALL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.358200",
    Latitude: "-38.195700"
  },
  {
    Country: "Australia",
    Pcode: "3216",
    Locality: "WANDANA HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.299000",
    Latitude: "-38.177000"
  },
  {
    Country: "Australia",
    Pcode: "3216",
    Locality: "WAURN PONDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.279800",
    Latitude: "-38.204400"
  },
  {
    Country: "Australia",
    Pcode: "3217",
    Locality: "ARMSTRONG CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.363200",
    Latitude: "-38.234800"
  },
  {
    Country: "Australia",
    Pcode: "3217",
    Locality: "CHARLEMONT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.376000",
    Latitude: "-38.204600"
  },
  {
    Country: "Australia",
    Pcode: "3217",
    Locality: "FRESHWATER CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.255100",
    Latitude: "-38.270100"
  },
  {
    Country: "Australia",
    Pcode: "3217",
    Locality: "MOUNT DUNEED",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.300600",
    Latitude: "-38.250100"
  },
  {
    Country: "Australia",
    Pcode: "3218",
    Locality: "FYANSFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.290600",
    Latitude: "-38.133000"
  },
  {
    Country: "Australia",
    Pcode: "3218",
    Locality: "GEELONG WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.345300",
    Latitude: "-38.139000"
  },
  {
    Country: "Australia",
    Pcode: "3218",
    Locality: "HERNE HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.323800",
    Latitude: "-38.134400"
  },
  {
    Country: "Australia",
    Pcode: "3218",
    Locality: "MANIFOLD HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.333600",
    Latitude: "-38.137600"
  },
  {
    Country: "Australia",
    Pcode: "3218",
    Locality: "MURGHEBOLUC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.139700",
    Latitude: "-38.100200"
  },
  {
    Country: "Australia",
    Pcode: "3218",
    Locality: "STONEHAVEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.225100",
    Latitude: "-38.122400"
  },
  {
    Country: "Australia",
    Pcode: "3219",
    Locality: "BREAKWATER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.373300",
    Latitude: "-38.181000"
  },
  {
    Country: "Australia",
    Pcode: "3219",
    Locality: "EAST GEELONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.381200",
    Latitude: "-38.154700"
  },
  {
    Country: "Australia",
    Pcode: "3219",
    Locality: "NEWCOMB",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.395500",
    Latitude: "-38.162300"
  },
  {
    Country: "Australia",
    Pcode: "3219",
    Locality: "ST ALBANS PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.390400",
    Latitude: "-38.195400"
  },
  {
    Country: "Australia",
    Pcode: "3219",
    Locality: "THOMSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "EAST GEELONG",
    Category: "Delivery Area",
    Longitude: "144.380700",
    Latitude: "-38.171700"
  },
  {
    Country: "Australia",
    Pcode: "3219",
    Locality: "WHITTINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.392400",
    Latitude: "-38.176700"
  },
  {
    Country: "Australia",
    Pcode: "3220",
    Locality: "BAREENA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3220",
    Locality: "GEELONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.363500",
    Latitude: "-38.148100"
  },
  {
    Country: "Australia",
    Pcode: "3220",
    Locality: "NEWTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "GEELONG",
    Category: "Delivery Area",
    Longitude: "144.335900",
    Latitude: "-38.153600"
  },
  {
    Country: "Australia",
    Pcode: "3220",
    Locality: "SOUTH GEELONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.365100",
    Latitude: "-38.167900"
  },
  {
    Country: "Australia",
    Pcode: "3221",
    Locality: "BARRABOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.225800",
    Latitude: "-38.166000"
  },
  {
    Country: "Australia",
    Pcode: "3221",
    Locality: "CERES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.273000",
    Latitude: "-38.162500"
  },
  {
    Country: "Australia",
    Pcode: "3221",
    Locality: "GEELONG MC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3221",
    Locality: "GNARWARRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.144600",
    Latitude: "-38.155200"
  },
  {
    Country: "Australia",
    Pcode: "3222",
    Locality: "CLIFTON SPRINGS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.574500",
    Latitude: "-38.151900"
  },
  {
    Country: "Australia",
    Pcode: "3281",
    Locality: "WOODFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.482600",
    Latitude: "-38.315300"
  },
  {
    Country: "Australia",
    Pcode: "3282",
    Locality: "ILLOWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.399000",
    Latitude: "-38.336400"
  },
  {
    Country: "Australia",
    Pcode: "3282",
    Locality: "KOROIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.371800",
    Latitude: "-38.272300"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "CROSSLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.313400",
    Latitude: "-38.316900"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "KILLARNEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.319100",
    Latitude: "-38.343700"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "KIRKSTALL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.279100",
    Latitude: "-38.267700"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "SOUTHERN CROSS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.407900",
    Latitude: "-38.289000"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "TARRONE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.194900",
    Latitude: "-38.227000"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "TOWER HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.356000",
    Latitude: "-38.328300"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "WARRONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.311300",
    Latitude: "-38.197100"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "WILLATOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.226800",
    Latitude: "-38.151400"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "YANGERY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.440600",
    Latitude: "-38.319100"
  },
  {
    Country: "Australia",
    Pcode: "3283",
    Locality: "YARPTURK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.432800",
    Latitude: "-38.283400"
  },
  {
    Country: "Australia",
    Pcode: "3284",
    Locality: "ORFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.109100",
    Latitude: "-38.203100"
  },
  {
    Country: "Australia",
    Pcode: "3284",
    Locality: "PORT FAIRY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.169700",
    Latitude: "-38.322800"
  },
  {
    Country: "Australia",
    Pcode: "3285",
    Locality: "CODRINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.969200",
    Latitude: "-38.257300"
  },
  {
    Country: "Australia",
    Pcode: "3285",
    Locality: "NARRAWONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.703300",
    Latitude: "-38.235200"
  },
  {
    Country: "Australia",
    Pcode: "3285",
    Locality: "ROSEBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.269600",
    Latitude: "-38.345300"
  },
  {
    Country: "Australia",
    Pcode: "3285",
    Locality: "ST HELENS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.054600",
    Latitude: "-38.244400"
  },
  {
    Country: "Australia",
    Pcode: "3285",
    Locality: "TOOLONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.243200",
    Latitude: "-38.314000"
  },
  {
    Country: "Australia",
    Pcode: "3285",
    Locality: "TYRENDARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.785100",
    Latitude: "-38.207400"
  },
  {
    Country: "Australia",
    Pcode: "3285",
    Locality: "TYRENDARRA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.895200",
    Latitude: "-38.251100"
  },
  {
    Country: "Australia",
    Pcode: "3285",
    Locality: "YAMBUK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.079600",
    Latitude: "-38.322700"
  },
  {
    Country: "Australia",
    Pcode: "3286",
    Locality: "CONDAH SWAMP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.833700",
    Latitude: "-37.971900"
  },
  {
    Country: "Australia",
    Pcode: "3286",
    Locality: "KNEBSWORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.873800",
    Latitude: "-37.988200"
  },
  {
    Country: "Australia",
    Pcode: "3286",
    Locality: "MACARTHUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.043500",
    Latitude: "-38.043000"
  },
  {
    Country: "Australia",
    Pcode: "3286",
    Locality: "WARRABKOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.061900",
    Latitude: "-37.984000"
  },
  {
    Country: "Australia",
    Pcode: "3287",
    Locality: "HAWKESDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.279300",
    Latitude: "-38.084600"
  },
  {
    Country: "Australia",
    Pcode: "3287",
    Locality: "MINHAMITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.346400",
    Latitude: "-37.987900"
  },
  {
    Country: "Australia",
    Pcode: "3289",
    Locality: "GAZETTE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.158200",
    Latitude: "-37.917800"
  },
  {
    Country: "Australia",
    Pcode: "3289",
    Locality: "GERRIGERRUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.200300",
    Latitude: "-37.987400"
  },
  {
    Country: "Australia",
    Pcode: "3289",
    Locality: "PENSHURST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.337100",
    Latitude: "-37.876000"
  },
  {
    Country: "Australia",
    Pcode: "3289",
    Locality: "PURDEET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.393100",
    Latitude: "-37.928800"
  },
  {
    Country: "Australia",
    Pcode: "3289",
    Locality: "TABOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.193700",
    Latitude: "-37.835800"
  },
  {
    Country: "Australia",
    Pcode: "3292",
    Locality: "NELSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.141800",
    Latitude: "-38.077800"
  },
  {
    Country: "Australia",
    Pcode: "3293",
    Locality: "GLENTHOMPSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.525400",
    Latitude: "-37.705000"
  },
  {
    Country: "Australia",
    Pcode: "3293",
    Locality: "NAREEB",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.541800",
    Latitude: "-37.834700"
  },
  {
    Country: "Australia",
    Pcode: "3293",
    Locality: "NARRAPUMELAP SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.641600",
    Latitude: "-37.755400"
  },
  {
    Country: "Australia",
    Pcode: "3294",
    Locality: "DUNKELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.364100",
    Latitude: "-37.672200"
  },
  {
    Country: "Australia",
    Pcode: "3294",
    Locality: "KARABEAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.194000",
    Latitude: "-37.600700"
  },
  {
    Country: "Australia",
    Pcode: "3294",
    Locality: "MIRRANATWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.401200",
    Latitude: "-37.399800"
  },
  {
    Country: "Australia",
    Pcode: "3294",
    Locality: "MOUTAJUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.216900",
    Latitude: "-37.684300"
  },
  {
    Country: "Australia",
    Pcode: "3294",
    Locality: "VICTORIA POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.224400",
    Latitude: "-37.513200"
  },
  {
    Country: "Australia",
    Pcode: "3294",
    Locality: "VICTORIA VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.335300",
    Latitude: "-37.527000"
  },
  {
    Country: "Australia",
    Pcode: "3294",
    Locality: "WOODHOUSE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.422500",
    Latitude: "-37.809000"
  },
  {
    Country: "Australia",
    Pcode: "3300",
    Locality: "BYADUK NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.981700",
    Latitude: "-37.863300"
  },
  {
    Country: "Australia",
    Pcode: "3300",
    Locality: "HAMILTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.014700",
    Latitude: "-37.741700"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "BOCHARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.901500",
    Latitude: "-37.688600"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "BROADWATER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.063500",
    Latitude: "-38.119000"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "BUCKLEY SWAMP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.079200",
    Latitude: "-37.856500"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "BYADUK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.982700",
    Latitude: "-37.945600"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "CROXTON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.227000",
    Latitude: "-37.775800"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "HENSLEY PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.044200",
    Latitude: "-37.623600"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "MORGIANA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.831900",
    Latitude: "-37.730200"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "MOUNT NAPIER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.059300",
    Latitude: "-37.896600"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "STRATHKELLAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.117200",
    Latitude: "-37.701700"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "TAHARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.733500",
    Latitude: "-37.741200"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "TARRINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.121800",
    Latitude: "-37.768100"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "WANNON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.838700",
    Latitude: "-37.646400"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "WARRAYURE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.166800",
    Latitude: "-37.713300"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "YATCHAW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.141000",
    Latitude: "-37.840700"
  },
  {
    Country: "Australia",
    Pcode: "3301",
    Locality: "YULECART",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.908200",
    Latitude: "-37.754200"
  },
  {
    Country: "Australia",
    Pcode: "3302",
    Locality: "BRANXHOLME",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.809500",
    Latitude: "-37.843100"
  },
  {
    Country: "Australia",
    Pcode: "3302",
    Locality: "GRASSDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.657700",
    Latitude: "-37.820100"
  },
  {
    Country: "Australia",
    Pcode: "3303",
    Locality: "BREAKAWAY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.814500",
    Latitude: "-38.026900"
  },
  {
    Country: "Australia",
    Pcode: "3303",
    Locality: "CONDAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.699800",
    Latitude: "-37.940200"
  },
  {
    Country: "Australia",
    Pcode: "3303",
    Locality: "HOTSPUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.553500",
    Latitude: "-37.933400"
  },
  {
    Country: "Australia",
    Pcode: "3303",
    Locality: "LAKE CONDAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.819700",
    Latitude: "-38.085500"
  },
  {
    Country: "Australia",
    Pcode: "3303",
    Locality: "WALLACEDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.812700",
    Latitude: "-37.926400"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "BESSIEBELLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.931200",
    Latitude: "-38.150100"
  },
  {
    Country: "Australia",
    Pcode: "3360",
    Locality: "MANNIBADAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.511100",
    Latitude: "-37.774200"
  },
  {
    Country: "Australia",
    Pcode: "3360",
    Locality: "PITTONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.478200",
    Latitude: "-37.677500"
  },
  {
    Country: "Australia",
    Pcode: "3360",
    Locality: "WILLOWVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.465500",
    Latitude: "-37.843200"
  },
  {
    Country: "Australia",
    Pcode: "3361",
    Locality: "BRADVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.364500",
    Latitude: "-37.794500"
  },
  {
    Country: "Australia",
    Pcode: "3361",
    Locality: "CARRANBALLAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.178500",
    Latitude: "-37.685900"
  },
  {
    Country: "Australia",
    Pcode: "3361",
    Locality: "SKIPTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.331700",
    Latitude: "-37.688800"
  },
  {
    Country: "Australia",
    Pcode: "3363",
    Locality: "CRESWICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.891400",
    Latitude: "-37.421100"
  },
  {
    Country: "Australia",
    Pcode: "3363",
    Locality: "CRESWICK NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.877600",
    Latitude: "-37.365500"
  },
  {
    Country: "Australia",
    Pcode: "3363",
    Locality: "DEAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.978100",
    Latitude: "-37.456400"
  },
  {
    Country: "Australia",
    Pcode: "3363",
    Locality: "GLENDARUEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.731400",
    Latitude: "-37.346600"
  },
  {
    Country: "Australia",
    Pcode: "3363",
    Locality: "LANGDONS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.063100",
    Latitude: "-37.415800"
  },
  {
    Country: "Australia",
    Pcode: "3363",
    Locality: "MOUNT BECKWORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.699700",
    Latitude: "-37.305800"
  },
  {
    Country: "Australia",
    Pcode: "3363",
    Locality: "TOURELLO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.787700",
    Latitude: "-37.354300"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "ALLENDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.912500",
    Latitude: "-37.363100"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "ASCOT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.811600",
    Latitude: "-37.397800"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "BALD HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.835300",
    Latitude: "-37.446100"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "BARKSTEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.099900",
    Latitude: "-37.478800"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "BLAMPIED",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.021900",
    Latitude: "-37.351400"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "BROOMFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.905000",
    Latitude: "-37.384700"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "CABBAGE TREE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "CRESWICK",
    Category: "Delivery Area",
    Longitude: "143.907900",
    Latitude: "-37.460500"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "CAMPBELLTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.959900",
    Latitude: "-37.206400"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "COGHILLS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.752100",
    Latitude: "-37.385600"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "GLENDONALD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.844700",
    Latitude: "-37.360600"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "JOYCES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.989300",
    Latitude: "-37.091900"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "KINGSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.958600",
    Latitude: "-37.379800"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "KOOROOCHEANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.046300",
    Latitude: "-37.303000"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "LAWRENCE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.897300",
    Latitude: "-37.328500"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "MOUNT PROSPECT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.049100",
    Latitude: "-37.399300"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "NEWLYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.979900",
    Latitude: "-37.414400"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "NEWLYN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.999600",
    Latitude: "-37.394000"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "ROCKLYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.066900",
    Latitude: "-37.439000"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "SMEATON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.964500",
    Latitude: "-37.302600"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "SMOKEYTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.922400",
    Latitude: "-37.398800"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "SPRINGMOUNT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.933900",
    Latitude: "-37.413500"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "STRATHLEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.974300",
    Latitude: "-37.152500"
  },
  {
    Country: "Australia",
    Pcode: "3364",
    Locality: "WERONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.023100",
    Latitude: "-37.239800"
  },
  {
    Country: "Australia",
    Pcode: "3370",
    Locality: "CLUNES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.780200",
    Latitude: "-37.287700"
  },
  {
    Country: "Australia",
    Pcode: "3370",
    Locality: "GLENGOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.868000",
    Latitude: "-37.210200"
  },
  {
    Country: "Australia",
    Pcode: "3370",
    Locality: "MOUNT CAMERON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.814000",
    Latitude: "-37.203200"
  },
  {
    Country: "Australia",
    Pcode: "3370",
    Locality: "ULLINA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.891200",
    Latitude: "-37.280600"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "AMHERST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.655800",
    Latitude: "-37.138900"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "BURNBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.594800",
    Latitude: "-37.240200"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "CARALULUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.644000",
    Latitude: "-37.200900"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "DUNACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.768200",
    Latitude: "-37.217100"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "EVANSFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.652000",
    Latitude: "-37.262100"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "LILLICUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.601500",
    Latitude: "-37.170100"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "MOUNT GLASGOW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.762800",
    Latitude: "-37.202400"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "RED LION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.768700",
    Latitude: "-37.165700"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "STONY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "TALBOT",
    Category: "Delivery Area",
    Longitude: "143.685200",
    Latitude: "-37.225700"
  },
  {
    Country: "Australia",
    Pcode: "3371",
    Locality: "TALBOT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.696700",
    Latitude: "-37.180200"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "BEAUFORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.337300",
    Latitude: "-37.440400"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "CHUTE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.414800",
    Latitude: "-37.309100"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "CROSS ROADS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.214600",
    Latitude: "-37.501000"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "LAKE GOLDSMITH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.388400",
    Latitude: "-37.534300"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "LAKE WONGAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.148300",
    Latitude: "-37.604800"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "MAIN LEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.376800",
    Latitude: "-37.387000"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "MENA PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.479800",
    Latitude: "-37.536800"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "NERRING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.446900",
    Latitude: "-37.497400"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "RAGLAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.306900",
    Latitude: "-37.341500"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "STOCKYARD HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.288000",
    Latitude: "-37.545400"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "STONELEIGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.230700",
    Latitude: "-37.583600"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "TRAWALLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.494400",
    Latitude: "-37.446200"
  },
  {
    Country: "Australia",
    Pcode: "3373",
    Locality: "WATERLOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.434900",
    Latitude: "-37.364200"
  },
  {
    Country: "Australia",
    Pcode: "3374",
    Locality: "GREAT WESTERN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.887400",
    Latitude: "-37.161200"
  },
  {
    Country: "Australia",
    Pcode: "3375",
    Locality: "BALLYROGAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.115100",
    Latitude: "-37.428200"
  },
  {
    Country: "Australia",
    Pcode: "3375",
    Locality: "BAYINDEEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.183000",
    Latitude: "-37.303200"
  },
  {
    Country: "Australia",
    Pcode: "3375",
    Locality: "BUANGOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.164400",
    Latitude: "-37.373600"
  },
  {
    Country: "Australia",
    Pcode: "3375",
    Locality: "MIDDLE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.248800",
    Latitude: "-37.399000"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "ARARAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.983300",
    Latitude: "-37.265800"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "ARMSTRONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.882300",
    Latitude: "-37.213400"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "TOOAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.782600",
    Latitude: "-36.810100"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "WOMBELANO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.561300",
    Latitude: "-37.022600"
  },
  {
    Country: "Australia",
    Pcode: "3412",
    Locality: "GOROKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.489800",
    Latitude: "-36.656100"
  },
  {
    Country: "Australia",
    Pcode: "3413",
    Locality: "MINIMAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.218300",
    Latitude: "-36.710700"
  },
  {
    Country: "Australia",
    Pcode: "3413",
    Locality: "NEUARPURR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.059400",
    Latitude: "-36.703400"
  },
  {
    Country: "Australia",
    Pcode: "3413",
    Locality: "OZENKADNOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.362600",
    Latitude: "-36.839900"
  },
  {
    Country: "Australia",
    Pcode: "3413",
    Locality: "PERONNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.361800",
    Latitude: "-36.655400"
  },
  {
    Country: "Australia",
    Pcode: "3414",
    Locality: "ANTWERP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.975200",
    Latitude: "-36.272000"
  },
  {
    Country: "Australia",
    Pcode: "3414",
    Locality: "DIMBOOLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.049500",
    Latitude: "-36.386200"
  },
  {
    Country: "Australia",
    Pcode: "3414",
    Locality: "TARRANYURK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.098200",
    Latitude: "-36.222900"
  },
  {
    Country: "Australia",
    Pcode: "3415",
    Locality: "MIRAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.382200",
    Latitude: "-36.371800"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "BROUGHTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.316600",
    Latitude: "-36.151500"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "GERANG GERUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.864200",
    Latitude: "-36.372900"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "GLENLEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.841900",
    Latitude: "-36.229800"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "KIATA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.781500",
    Latitude: "-36.362000"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "LAWLOIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.453500",
    Latitude: "-36.431700"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "LITTLE DESERT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.743400",
    Latitude: "-36.493700"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "LORQUON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.766700",
    Latitude: "-36.143800"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "NETHERBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.659200",
    Latitude: "-36.085800"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "NHILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.562600",
    Latitude: "-36.306300"
  },
  {
    Country: "Australia",
    Pcode: "3418",
    Locality: "YANAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.433700",
    Latitude: "-36.094700"
  },
  {
    Country: "Australia",
    Pcode: "3419",
    Locality: "KANIVA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.249500",
    Latitude: "-36.407500"
  },
  {
    Country: "Australia",
    Pcode: "3420",
    Locality: "LILLIMUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.131600",
    Latitude: "-36.450400"
  },
  {
    Country: "Australia",
    Pcode: "3420",
    Locality: "SERVICETON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.026300",
    Latitude: "-36.392500"
  },
  {
    Country: "Australia",
    Pcode: "3420",
    Locality: "TELOPEA DOWNS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.121700",
    Latitude: "-36.022100"
  },
  {
    Country: "Australia",
    Pcode: "3423",
    Locality: "JEPARIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.996600",
    Latitude: "-36.116300"
  },
  {
    Country: "Australia",
    Pcode: "3424",
    Locality: "ALBACUTYA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.959300",
    Latitude: "-35.739900"
  },
  {
    Country: "Australia",
    Pcode: "3424",
    Locality: "RAINBOW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.958200",
    Latitude: "-35.908500"
  },
  {
    Country: "Australia",
    Pcode: "3424",
    Locality: "YAAPEET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.090800",
    Latitude: "-35.688200"
  },
  {
    Country: "Australia",
    Pcode: "3427",
    Locality: "DIGGERS REST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.682000",
    Latitude: "-37.613800"
  },
  {
    Country: "Australia",
    Pcode: "3428",
    Locality: "BULLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.803100",
    Latitude: "-37.629700"
  },
  {
    Country: "Australia",
    Pcode: "3429",
    Locality: "SUNBURY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.706400",
    Latitude: "-37.546400"
  },
  {
    Country: "Australia",
    Pcode: "3429",
    Locality: "WILDWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.800700",
    Latitude: "-37.565900"
  },
  {
    Country: "Australia",
    Pcode: "3430",
    Locality: "CLARKEFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.808400",
    Latitude: "-37.482200"
  },
  {
    Country: "Australia",
    Pcode: "3431",
    Locality: "RIDDELLS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.679100",
    Latitude: "-37.447200"
  },
  {
    Country: "Australia",
    Pcode: "3432",
    Locality: "BOLINDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.778800",
    Latitude: "-37.438100"
  },
  {
    Country: "Australia",
    Pcode: "3433",
    Locality: "MONEGEETTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.785100",
    Latitude: "-37.393100"
  },
  {
    Country: "Australia",
    Pcode: "3434",
    Locality: "CHEROKEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.644700",
    Latitude: "-37.397900"
  },
  {
    Country: "Australia",
    Pcode: "3434",
    Locality: "KERRIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.671500",
    Latitude: "-37.380200"
  },
  {
    Country: "Australia",
    Pcode: "3434",
    Locality: "ROMSEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.725600",
    Latitude: "-37.354900"
  },
  {
    Country: "Australia",
    Pcode: "3434",
    Locality: "SPRINGFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "ROMSEY",
    Category: "Delivery Area",
    Longitude: "144.825500",
    Latitude: "-37.329900"
  },
  {
    Country: "Australia",
    Pcode: "3435",
    Locality: "BENLOCH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.675400",
    Latitude: "-37.201900"
  },
  {
    Country: "Australia",
    Pcode: "3435",
    Locality: "GOLDIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.801100",
    Latitude: "-37.255100"
  },
  {
    Country: "Australia",
    Pcode: "3435",
    Locality: "LANCEFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.715100",
    Latitude: "-37.264800"
  },
  {
    Country: "Australia",
    Pcode: "3435",
    Locality: "NULLA VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.763600",
    Latitude: "-37.170400"
  },
  {
    Country: "Australia",
    Pcode: "3437",
    Locality: "BULLENGAROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.504100",
    Latitude: "-37.460600"
  },
  {
    Country: "Australia",
    Pcode: "3437",
    Locality: "GISBORNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.578900",
    Latitude: "-37.505500"
  },
  {
    Country: "Australia",
    Pcode: "3437",
    Locality: "GISBORNE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.620700",
    Latitude: "-37.548800"
  },
  {
    Country: "Australia",
    Pcode: "3438",
    Locality: "NEW GISBORNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.608500",
    Latitude: "-37.450200"
  },
  {
    Country: "Australia",
    Pcode: "3440",
    Locality: "MACEDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.564000",
    Latitude: "-37.423600"
  },
  {
    Country: "Australia",
    Pcode: "3441",
    Locality: "MOUNT MACEDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.590500",
    Latitude: "-37.389800"
  },
  {
    Country: "Australia",
    Pcode: "3442",
    Locality: "ASHBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.458800",
    Latitude: "-37.401700"
  },
  {
    Country: "Australia",
    Pcode: "3442",
    Locality: "CADELLO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.529400",
    Latitude: "-37.303200"
  },
  {
    Country: "Australia",
    Pcode: "3442",
    Locality: "CARLSRUHE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.492000",
    Latitude: "-37.290900"
  },
  {
    Country: "Australia",
    Pcode: "3442",
    Locality: "COBAW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.646100",
    Latitude: "-37.276400"
  },
  {
    Country: "Australia",
    Pcode: "3442",
    Locality: "HESKET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.634300",
    Latitude: "-37.354400"
  },
  {
    Country: "Australia",
    Pcode: "3442",
    Locality: "NEWHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.611600",
    Latitude: "-37.297700"
  },
  {
    Country: "Australia",
    Pcode: "3442",
    Locality: "ROCHFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.666800",
    Latitude: "-37.311600"
  },
  {
    Country: "Australia",
    Pcode: "3442",
    Locality: "WOODEND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.530900",
    Latitude: "-37.368900"
  },
  {
    Country: "Australia",
    Pcode: "3442",
    Locality: "WOODEND NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.526100",
    Latitude: "-37.323800"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "BARFOLD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.500500",
    Latitude: "-37.103500"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "BAYNTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.649600",
    Latitude: "-37.137700"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "BAYNTON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.713100",
    Latitude: "-37.122900"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "EDGECOMBE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.461400",
    Latitude: "-37.184100"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "GLENHOPE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.611700",
    Latitude: "-37.074200"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "GREENHILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.436600",
    Latitude: "-37.174000"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "KYNETON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.443300",
    Latitude: "-37.223300"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "KYNETON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.432800",
    Latitude: "-37.290700"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "LANGLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.497600",
    Latitude: "-37.148300"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "LAURISTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.356300",
    Latitude: "-37.246700"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "LYAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.463000",
    Latitude: "-36.923500"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "MARLBED",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.849900",
    Latitude: "-35.827700"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "NARRAPORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.068100",
    Latitude: "-36.023700"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "WHIRILY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.988300",
    Latitude: "-35.922600"
  },
  {
    Country: "Australia",
    Pcode: "3485",
    Locality: "BANYAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.788200",
    Latitude: "-35.612800"
  },
  {
    Country: "Australia",
    Pcode: "3485",
    Locality: "WATCHUPGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.690600",
    Latitude: "-35.774100"
  },
  {
    Country: "Australia",
    Pcode: "3485",
    Locality: "WILLANGIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.817900",
    Latitude: "-35.720000"
  },
  {
    Country: "Australia",
    Pcode: "3485",
    Locality: "WOOMELANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.625200",
    Latitude: "-35.677300"
  },
  {
    Country: "Australia",
    Pcode: "3487",
    Locality: "LASCELLES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.607500",
    Latitude: "-35.562400"
  },
  {
    Country: "Australia",
    Pcode: "3488",
    Locality: "SPEED",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.424200",
    Latitude: "-35.407600"
  },
  {
    Country: "Australia",
    Pcode: "3488",
    Locality: "TURRIFF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.448700",
    Latitude: "-35.465000"
  },
  {
    Country: "Australia",
    Pcode: "3488",
    Locality: "TURRIFF EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.581700",
    Latitude: "-35.445800"
  },
  {
    Country: "Australia",
    Pcode: "3489",
    Locality: "TEMPY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.437300",
    Latitude: "-35.289900"
  },
  {
    Country: "Australia",
    Pcode: "3490",
    Locality: "BIG DESERT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.480000",
    Latitude: "-35.642000"
  },
  {
    Country: "Australia",
    Pcode: "3490",
    Locality: "BOINKA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.567100",
    Latitude: "-35.148000"
  },
  {
    Country: "Australia",
    Pcode: "3490",
    Locality: "KULWIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.583500",
    Latitude: "-34.989400"
  },
  {
    Country: "Australia",
    Pcode: "3490",
    Locality: "MITTYACK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.620900",
    Latitude: "-35.155400"
  },
  {
    Country: "Australia",
    Pcode: "3490",
    Locality: "MURRAY-SUNSET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.516200",
    Latitude: "-34.566900"
  },
  {
    Country: "Australia",
    Pcode: "3490",
    Locality: "OUYEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.314300",
    Latitude: "-35.053800"
  },
  {
    Country: "Australia",
    Pcode: "3490",
    Locality: "TORRITA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.931400",
    Latitude: "-35.119800"
  },
  {
    Country: "Australia",
    Pcode: "3490",
    Locality: "TUTYE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.484900",
    Latitude: "-35.177800"
  },
  {
    Country: "Australia",
    Pcode: "3491",
    Locality: "PATCHEWOLLOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.245000",
    Latitude: "-35.441900"
  },
  {
    Country: "Australia",
    Pcode: "3494",
    Locality: "CARWARP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.133900",
    Latitude: "-34.531000"
  },
  {
    Country: "Australia",
    Pcode: "3494",
    Locality: "COLIGNAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.321200",
    Latitude: "-34.540500"
  },
  {
    Country: "Australia",
    Pcode: "3494",
    Locality: "IRAAK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.291500",
    Latitude: "-34.423000"
  },
  {
    Country: "Australia",
    Pcode: "3494",
    Locality: "NANGILOC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.306100",
    Latitude: "-34.480700"
  },
  {
    Country: "Australia",
    Pcode: "3496",
    Locality: "CARDROSS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.125700",
    Latitude: "-34.306600"
  },
  {
    Country: "Australia",
    Pcode: "3496",
    Locality: "CULLULLERAINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.638300",
    Latitude: "-34.198100"
  },
  {
    Country: "Australia",
    Pcode: "3496",
    Locality: "LINDSAY POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.014700",
    Latitude: "-34.092000"
  },
  {
    Country: "Australia",
    Pcode: "3496",
    Locality: "MERINGUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.262000",
    Latitude: "-34.406600"
  },
  {
    Country: "Australia",
    Pcode: "3496",
    Locality: "MERRINEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.847000",
    Latitude: "-34.389300"
  },
  {
    Country: "Australia",
    Pcode: "3496",
    Locality: "NEDS CORNER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.378700",
    Latitude: "-34.193500"
  },
  {
    Country: "Australia",
    Pcode: "3496",
    Locality: "RED CLIFFS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.218000",
    Latitude: "-34.339000"
  },
  {
    Country: "Australia",
    Pcode: "3496",
    Locality: "SUNNYCLIFFS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3496",
    Locality: "WERRIMULL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.562200",
    Latitude: "-34.411700"
  },
  {
    Country: "Australia",
    Pcode: "3498",
    Locality: "IRYMPLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.181300",
    Latitude: "-34.245800"
  },
  {
    Country: "Australia",
    Pcode: "3500",
    Locality: "MILDURA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.133600",
    Latitude: "-34.199900"
  },
  {
    Country: "Australia",
    Pcode: "3500",
    Locality: "MILDURA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3501",
    Locality: "HATTAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.279700",
    Latitude: "-34.710900"
  },
  {
    Country: "Australia",
    Pcode: "3501",
    Locality: "KOORLONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.050100",
    Latitude: "-34.299900"
  },
  {
    Country: "Australia",
    Pcode: "3501",
    Locality: "MILDURA CENTRE PLAZA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3501",
    Locality: "MILDURA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3501",
    Locality: "NICHOLS POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.213200",
    Latitude: "-34.210500"
  },
  {
    Country: "Australia",
    Pcode: "3502",
    Locality: "MILDURA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3505",
    Locality: "BIRDWOODTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.067700",
    Latitude: "-34.204100"
  },
  {
    Country: "Australia",
    Pcode: "3505",
    Locality: "CABARITA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.086600",
    Latitude: "-34.204300"
  },
  {
    Country: "Australia",
    Pcode: "3505",
    Locality: "MERBEIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.051200",
    Latitude: "-34.159000"
  },
  {
    Country: "Australia",
    Pcode: "3505",
    Locality: "MERBEIN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.027700",
    Latitude: "-34.214900"
  },
  {
    Country: "Australia",
    Pcode: "3505",
    Locality: "MERBEIN WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.007200",
    Latitude: "-34.167300"
  },
  {
    Country: "Australia",
    Pcode: "3505",
    Locality: "WARGAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.846900",
    Latitude: "-34.183800"
  },
  {
    Country: "Australia",
    Pcode: "3505",
    Locality: "YELTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.989400",
    Latitude: "-34.123700"
  },
  {
    Country: "Australia",
    Pcode: "3506",
    Locality: "COWANGIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.368800",
    Latitude: "-35.183400"
  },
  {
    Country: "Australia",
    Pcode: "3507",
    Locality: "WALPEUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.033200",
    Latitude: "-35.110900"
  },
  {
    Country: "Australia",
    Pcode: "3509",
    Locality: "LINGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.670000",
    Latitude: "-35.186800"
  },
  {
    Country: "Australia",
    Pcode: "3509",
    Locality: "UNDERBOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.820800",
    Latitude: "-35.169400"
  },
  {
    Country: "Australia",
    Pcode: "3512",
    Locality: "CARINA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.090400",
    Latitude: "-35.217600"
  },
  {
    Country: "Australia",
    Pcode: "3512",
    Locality: "MURRAYVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.201200",
    Latitude: "-35.190700"
  },
  {
    Country: "Australia",
    Pcode: "3512",
    Locality: "PANITYA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.031400",
    Latitude: "-35.127300"
  },
  {
    Country: "Australia",
    Pcode: "3515",
    Locality: "MARONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.137200",
    Latitude: "-36.750400"
  },
  {
    Country: "Australia",
    Pcode: "3515",
    Locality: "SHELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.053000",
    Latitude: "-36.844300"
  },
  {
    Country: "Australia",
    Pcode: "3515",
    Locality: "WILSONS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.088900",
    Latitude: "-36.730600"
  },
  {
    Country: "Australia",
    Pcode: "3516",
    Locality: "BRIDGEWATER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.973300",
    Latitude: "-36.648700"
  },
  {
    Country: "Australia",
    Pcode: "3516",
    Locality: "BRIDGEWATER NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.006200",
    Latitude: "-36.549300"
  },
  {
    Country: "Australia",
    Pcode: "3516",
    Locality: "BRIDGEWATER ON LODDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.939000",
    Latitude: "-36.602200"
  },
  {
    Country: "Australia",
    Pcode: "3516",
    Locality: "DERBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.014300",
    Latitude: "-36.654600"
  },
  {
    Country: "Australia",
    Pcode: "3516",
    Locality: "LEICHARDT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.070200",
    Latitude: "-36.683600"
  },
  {
    Country: "Australia",
    Pcode: "3516",
    Locality: "YARRABERB",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.059200",
    Latitude: "-36.592800"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "BEARS LAGOON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.951200",
    Latitude: "-36.338200"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "BRENANAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.707200",
    Latitude: "-36.568500"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "GLENALBYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.716300",
    Latitude: "-36.489600"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "INGLEWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.840000",
    Latitude: "-36.592600"
  },
  {
    Country: "Australia",
    Pcode: "3561",
    Locality: "BONN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.718200",
    Latitude: "-36.413100"
  },
  {
    Country: "Australia",
    Pcode: "3561",
    Locality: "DIGGORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.530800",
    Latitude: "-36.361200"
  },
  {
    Country: "Australia",
    Pcode: "3561",
    Locality: "FAIRY DELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "ROCHESTER",
    Category: "Delivery Area",
    Longitude: "144.723300",
    Latitude: "-36.328300"
  },
  {
    Country: "Australia",
    Pcode: "3561",
    Locality: "NANNEELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.779300",
    Latitude: "-36.331400"
  },
  {
    Country: "Australia",
    Pcode: "3561",
    Locality: "ROCHESTER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.694600",
    Latitude: "-36.362300"
  },
  {
    Country: "Australia",
    Pcode: "3561",
    Locality: "TIMMERING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.881900",
    Latitude: "-36.348800"
  },
  {
    Country: "Australia",
    Pcode: "3562",
    Locality: "TORRUMBARRY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.519300",
    Latitude: "-36.029800"
  },
  {
    Country: "Australia",
    Pcode: "3563",
    Locality: "LOCKINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.497300",
    Latitude: "-36.270700"
  },
  {
    Country: "Australia",
    Pcode: "3564",
    Locality: "BAMAWM EXTENSION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.603500",
    Latitude: "-36.177500"
  },
  {
    Country: "Australia",
    Pcode: "3564",
    Locality: "ECHUCA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.761700",
    Latitude: "-36.172300"
  },
  {
    Country: "Australia",
    Pcode: "3564",
    Locality: "ECHUCA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3564",
    Locality: "ECHUCA VILLAGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.813800",
    Latitude: "-36.141900"
  },
  {
    Country: "Australia",
    Pcode: "3564",
    Locality: "ECHUCA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.669600",
    Latitude: "-36.177400"
  },
  {
    Country: "Australia",
    Pcode: "3564",
    Locality: "KANYAPELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.890900",
    Latitude: "-36.125200"
  },
  {
    Country: "Australia",
    Pcode: "3564",
    Locality: "PATHO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.370500",
    Latitude: "-36.016500"
  },
  {
    Country: "Australia",
    Pcode: "3564",
    Locality: "ROSLYNMEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.525700",
    Latitude: "-36.135000"
  },
  {
    Country: "Australia",
    Pcode: "3564",
    Locality: "WHARPARILLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.634400",
    Latitude: "-36.088800"
  },
  {
    Country: "Australia",
    Pcode: "3565",
    Locality: "KOTTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.526200",
    Latitude: "-36.192300"
  },
  {
    Country: "Australia",
    Pcode: "3566",
    Locality: "GUNBOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.340200",
    Latitude: "-35.943700"
  },
  {
    Country: "Australia",
    Pcode: "3567",
    Locality: "HORFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.212700",
    Latitude: "-35.890200"
  },
  {
    Country: "Australia",
    Pcode: "3567",
    Locality: "LEITCHVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.291900",
    Latitude: "-35.916500"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "BURKES BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.342500",
    Latitude: "-35.836400"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "COHUNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.264300",
    Latitude: "-35.775300"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "CULLEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.153700",
    Latitude: "-35.784200"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "DALTONS BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.285100",
    Latitude: "-35.826900"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "GANNAWARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.146600",
    Latitude: "-35.721000"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "KEELY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.247500",
    Latitude: "-35.854600"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "MACORNA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.070300",
    Latitude: "-35.841700"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "MCMILLANS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.157200",
    Latitude: "-35.848100"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "MEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.108000",
    Latitude: "-35.808700"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "MINCHA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.133100",
    Latitude: "-35.912800"
  },
  {
    Country: "Australia",
    Pcode: "3568",
    Locality: "WEE WEE RUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.291700",
    Latitude: "-35.861700"
  },
  {
    Country: "Australia",
    Pcode: "3570",
    Locality: "AUCHMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.105400",
    Latitude: "-36.459400"
  },
  {
    Country: "Australia",
    Pcode: "3570",
    Locality: "DRUMMARTIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.406500",
    Latitude: "-36.428700"
  },
  {
    Country: "Australia",
    Pcode: "3570",
    Locality: "KAMAROOKA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.390500",
    Latitude: "-36.493900"
  },
  {
    Country: "Australia",
    Pcode: "3570",
    Locality: "NEILBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.265100",
    Latitude: "-36.555900"
  },
  {
    Country: "Australia",
    Pcode: "3570",
    Locality: "RAYWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.182300",
    Latitude: "-36.516500"
  },
  {
    Country: "Australia",
    Pcode: "3571",
    Locality: "DINGEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.212400",
    Latitude: "-36.371000"
  },
  {
    Country: "Australia",
    Pcode: "3571",
    Locality: "KAMAROOKA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.308300",
    Latitude: "-36.399800"
  },
  {
    Country: "Australia",
    Pcode: "3571",
    Locality: "POMPAPIEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.086000",
    Latitude: "-36.368600"
  },
  {
    Country: "Australia",
    Pcode: "3571",
    Locality: "TANDARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.205600",
    Latitude: "-36.436400"
  },
  {
    Country: "Australia",
    Pcode: "3572",
    Locality: "MILLOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.369100",
    Latitude: "-36.361200"
  },
  {
    Country: "Australia",
    Pcode: "3572",
    Locality: "PIAVELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3572",
    Locality: "PRAIRIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.212400",
    Latitude: "-36.294800"
  },
  {
    Country: "Australia",
    Pcode: "3572",
    Locality: "TENNYSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.376500",
    Latitude: "-36.295800"
  },
  {
    Country: "Australia",
    Pcode: "3573",
    Locality: "CALIVIL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.085900",
    Latitude: "-36.247900"
  },
  {
    Country: "Australia",
    Pcode: "3573",
    Locality: "MITIAMO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.235800",
    Latitude: "-36.215200"
  },
  {
    Country: "Australia",
    Pcode: "3573",
    Locality: "PINE GROVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.406600",
    Latitude: "-36.214400"
  },
  {
    Country: "Australia",
    Pcode: "3573",
    Locality: "TERRICK TERRICK EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.390800",
    Latitude: "-36.106100"
  },
  {
    Country: "Australia",
    Pcode: "3575",
    Locality: "GLADFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.953700",
    Latitude: "-36.059000"
  },
  {
    Country: "Australia",
    Pcode: "3575",
    Locality: "JUNGABURRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.283400",
    Latitude: "-36.055800"
  },
  {
    Country: "Australia",
    Pcode: "3575",
    Locality: "LODDON VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.954200",
    Latitude: "-36.000700"
  },
  {
    Country: "Australia",
    Pcode: "3575",
    Locality: "MINCHA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.052400",
    Latitude: "-35.996300"
  },
  {
    Country: "Australia",
    Pcode: "3575",
    Locality: "MOLOGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.132600",
    Latitude: "-36.162900"
  },
  {
    Country: "Australia",
    Pcode: "3575",
    Locality: "PYRAMID HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.176000",
    Latitude: "-36.040200"
  },
  {
    Country: "Australia",
    Pcode: "3575",
    Locality: "SYLVATERRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.227300",
    Latitude: "-36.078700"
  },
  {
    Country: "Australia",
    Pcode: "3575",
    Locality: "TERRICK TERRICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.257300",
    Latitude: "-36.116700"
  },
  {
    Country: "Australia",
    Pcode: "3575",
    Locality: "YARRAWALLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.010800",
    Latitude: "-36.156300"
  },
  {
    Country: "Australia",
    Pcode: "3576",
    Locality: "DURHAM OX",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.911700",
    Latitude: "-36.122800"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "APPIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.873900",
    Latitude: "-35.878400"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "APPIN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.864500",
    Latitude: "-35.930500"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "BAEL BAEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.732800",
    Latitude: "-35.683000"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "BEAUCHAMP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.623600",
    Latitude: "-35.640800"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "BENJEROOP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.818500",
    Latitude: "-35.481600"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "BUDGERUM EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.652300",
    Latitude: "-35.771100"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "CAPELS CROSSING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.912700",
    Latitude: "-35.612900"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "DINGWALL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.848100",
    Latitude: "-35.808600"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "FAIRLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.812500",
    Latitude: "-35.684300"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "GONN CROSSING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.925700",
    Latitude: "-35.509000"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "KERANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.937300",
    Latitude: "-35.724100"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "GOORAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.601700",
    Latitude: "-36.895200"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "KELVIN VIEW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.664300",
    Latitude: "-36.827500"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "KITHBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.731800",
    Latitude: "-36.802200"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "LONGWOOD EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.456900",
    Latitude: "-36.831100"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "MIEPOLL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.474600",
    Latitude: "-36.612200"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "MOGLONEMBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.542300",
    Latitude: "-36.658100"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "MOLKA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.402900",
    Latitude: "-36.678700"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "PRANJIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.383900",
    Latitude: "-36.737000"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "RIGGS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.603000",
    Latitude: "-36.643200"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "RUFFY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.486500",
    Latitude: "-36.945900"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "SHEANS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.653800",
    Latitude: "-36.773000"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "STRATHBOGIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.766500",
    Latitude: "-36.877200"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "TARCOMBE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.317800",
    Latitude: "-36.965700"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "BOHO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.783400",
    Latitude: "-36.673300"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "BOHO SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.827200",
    Latitude: "-36.772500"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "CREEK JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.885400",
    Latitude: "-36.843500"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "EARLSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.681400",
    Latitude: "-36.531200"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "GOWANGARDIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.699500",
    Latitude: "-36.472300"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "KOONDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.750800",
    Latitude: "-36.488800"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "MARRAWEENEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.731300",
    Latitude: "-36.765100"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "TAMLEUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.578900",
    Latitude: "-36.588700"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "TAMLEUGH NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.583500",
    Latitude: "-36.531100"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "UPOTIPOTPON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.777300",
    Latitude: "-36.505900"
  },
  {
    Country: "Australia",
    Pcode: "3669",
    Locality: "VIOLET TOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.713500",
    Latitude: "-36.616800"
  },
  {
    Country: "Australia",
    Pcode: "3670",
    Locality: "BADDAGINNIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.859500",
    Latitude: "-36.603400"
  },
  {
    Country: "Australia",
    Pcode: "3670",
    Locality: "TARNOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.861400",
    Latitude: "-36.536300"
  },
  {
    Country: "Australia",
    Pcode: "3670",
    Locality: "WARRENBAYNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.892400",
    Latitude: "-36.707100"
  },
  {
    Country: "Australia",
    Pcode: "3671",
    Locality: "BENALLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3672",
    Locality: "BENALLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.006800",
    Latitude: "-36.573300"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "BROKEN CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.890300",
    Latitude: "-36.432600"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "GOOMALIBEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.882100",
    Latitude: "-36.485400"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "LIMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.948800",
    Latitude: "-36.749500"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "LIMA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.963400",
    Latitude: "-36.813400"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "LIMA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.020600",
    Latitude: "-36.833600"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "LURG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.119000",
    Latitude: "-36.579900"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "MOLYULLAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.146200",
    Latitude: "-36.668200"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "MOORNGAG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.054500",
    Latitude: "-36.732800"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "SAMARIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.075200",
    Latitude: "-36.806800"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "SWANPOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.999800",
    Latitude: "-36.731900"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "TATONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.163600",
    Latitude: "-36.756500"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "UPPER LURG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.190500",
    Latitude: "-36.584200"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "UPPER RYANS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.210600",
    Latitude: "-36.672700"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "WINTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.093700",
    Latitude: "-36.521900"
  },
  {
    Country: "Australia",
    Pcode: "3673",
    Locality: "WINTON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.092900",
    Latitude: "-36.455500"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "BOWEYA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.115000",
    Latitude: "-36.270600"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "BOWEYA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.106000",
    Latitude: "-36.227100"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "GLENROWAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.210800",
    Latitude: "-36.461300"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "GLENROWAN WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.166700",
    Latitude: "-36.515800"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "GRETA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.294300",
    Latitude: "-36.541200"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "GRETA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.264900",
    Latitude: "-36.653600"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "GRETA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.237500",
    Latitude: "-36.530900"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "HANSONVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.286600",
    Latitude: "-36.620000"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "MOUNT BRUNO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.151900",
    Latitude: "-36.321400"
  },
  {
    Country: "Australia",
    Pcode: "3675",
    Locality: "TAMINICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.164700",
    Latitude: "-36.375600"
  },
  {
    Country: "Australia",
    Pcode: "3676",
    Locality: "WANGARATTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3677",
    Locality: "WANGARATTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.312000",
    Latitude: "-36.365300"
  },
  {
    Country: "Australia",
    Pcode: "3677",
    Locality: "YARRUNGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "BOBINAWARRAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.488800",
    Latitude: "-36.535300"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "BOORHAMAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.321500",
    Latitude: "-36.229100"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "BOORHAMAN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.335000",
    Latitude: "-36.162600"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "BOWSER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.361800",
    Latitude: "-36.310100"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "BYAWATHA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.471000",
    Latitude: "-36.288600"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "CARBOOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.555200",
    Latitude: "-36.669600"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "CHESHUNT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.443200",
    Latitude: "-36.827600"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "CHESHUNT SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.477100",
    Latitude: "-36.934600"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "DOCKER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.366900",
    Latitude: "-36.507400"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "DOCKERS PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.335100",
    Latitude: "-36.287600"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "EAST WANGARATTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.378400",
    Latitude: "-36.362900"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "EDI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.410300",
    Latitude: "-36.650000"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "EDI UPPER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.526100",
    Latitude: "-36.768500"
  },
  {
    Country: "Australia",
    Pcode: "3732",
    Locality: "MYRRHEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.304400",
    Latitude: "-36.752400"
  },
  {
    Country: "Australia",
    Pcode: "3733",
    Locality: "WHITFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.418200",
    Latitude: "-36.752500"
  },
  {
    Country: "Australia",
    Pcode: "3735",
    Locality: "BOWMANS FOREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.625000",
    Latitude: "-36.466700"
  },
  {
    Country: "Australia",
    Pcode: "3735",
    Locality: "WHOROULY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.585600",
    Latitude: "-36.518900"
  },
  {
    Country: "Australia",
    Pcode: "3735",
    Locality: "WHOROULY EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.634900",
    Latitude: "-36.542600"
  },
  {
    Country: "Australia",
    Pcode: "3735",
    Locality: "WHOROULY SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.603800",
    Latitude: "-36.597600"
  },
  {
    Country: "Australia",
    Pcode: "3736",
    Locality: "MYRTLEFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "ABBEYARD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.793500",
    Latitude: "-37.016000"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "BARWIDGEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.780400",
    Latitude: "-36.511900"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "BUFFALO RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.648200",
    Latitude: "-36.678900"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "DANDONGADALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.694000",
    Latitude: "-36.835200"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "GAPSTED",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.696200",
    Latitude: "-36.503400"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "HAVILAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.894700",
    Latitude: "-36.619600"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "MERRIANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.686500",
    Latitude: "-36.564600"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "MERRIANG SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.661900",
    Latitude: "-36.602800"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "MUDGEGONGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.856500",
    Latitude: "-36.489600"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "MYRTLEFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.744400",
    Latitude: "-36.584300"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "NUG NUG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.726100",
    Latitude: "-36.670200"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "ROSEWHITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.884500",
    Latitude: "-36.576400"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "SELWYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.002000",
    Latitude: "-37.064000"
  },
  {
    Country: "Australia",
    Pcode: "3737",
    Locality: "WONNANGATTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.781900",
    Latitude: "-37.131400"
  },
  {
    Country: "Australia",
    Pcode: "3738",
    Locality: "OVENS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.784600",
    Latitude: "-36.604700"
  },
  {
    Country: "Australia",
    Pcode: "3739",
    Locality: "EUROBIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.841200",
    Latitude: "-36.636000"
  },
  {
    Country: "Australia",
    Pcode: "3740",
    Locality: "BUCKLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.887000",
    Latitude: "-36.857100"
  },
  {
    Country: "Australia",
    Pcode: "3740",
    Locality: "MOUNT BUFFALO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.768600",
    Latitude: "-36.727700"
  },
  {
    Country: "Australia",
    Pcode: "3740",
    Locality: "POREPUNKAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.918300",
    Latitude: "-36.679800"
  },
  {
    Country: "Australia",
    Pcode: "3741",
    Locality: "BRIGHT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.044100",
    Latitude: "-36.702600"
  },
  {
    Country: "Australia",
    Pcode: "3741",
    Locality: "FREEBURGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.061600",
    Latitude: "-36.772600"
  },
  {
    Country: "Australia",
    Pcode: "3741",
    Locality: "GERMANTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.070000",
    Latitude: "-36.735300"
  },
  {
    Country: "Australia",
    Pcode: "3741",
    Locality: "HARRIETVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.055800",
    Latitude: "-36.916400"
  },
  {
    Country: "Australia",
    Pcode: "3741",
    Locality: "HOTHAM HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.169600",
    Latitude: "-37.051500"
  },
  {
    Country: "Australia",
    Pcode: "3741",
    Locality: "MOUNT HOTHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3741",
    Locality: "SMOKO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.066000",
    Latitude: "-36.821900"
  },
  {
    Country: "Australia",
    Pcode: "3744",
    Locality: "WANDILIGONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.970200",
    Latitude: "-36.797300"
  },
  {
    Country: "Australia",
    Pcode: "3746",
    Locality: "ELDORADO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.553900",
    Latitude: "-36.314100"
  },
  {
    Country: "Australia",
    Pcode: "3747",
    Locality: "BEECHWORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.659900",
    Latitude: "-36.328000"
  },
  {
    Country: "Australia",
    Pcode: "3747",
    Locality: "MURMUNGEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.688900",
    Latitude: "-36.448700"
  },
  {
    Country: "Australia",
    Pcode: "3747",
    Locality: "STANLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.773400",
    Latitude: "-36.391700"
  },
  {
    Country: "Australia",
    Pcode: "3747",
    Locality: "WOORAGEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.743000",
    Latitude: "-36.290300"
  },
  {
    Country: "Australia",
    Pcode: "3749",
    Locality: "BRUARONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.870600",
    Latitude: "-36.420200"
  },
  {
    Country: "Australia",
    Pcode: "3749",
    Locality: "YACKANDANDAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.873400",
    Latitude: "-36.317000"
  },
  {
    Country: "Australia",
    Pcode: "3750",
    Locality: "WOLLERT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.011000",
    Latitude: "-37.590800"
  },
  {
    Country: "Australia",
    Pcode: "3751",
    Locality: "WOODSTOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.054300",
    Latitude: "-37.546000"
  },
  {
    Country: "Australia",
    Pcode: "3752",
    Locality: "SOUTH MORANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.085900",
    Latitude: "-37.637600"
  },
  {
    Country: "Australia",
    Pcode: "3753",
    Locality: "BEVERIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.974200",
    Latitude: "-37.475600"
  },
  {
    Country: "Australia",
    Pcode: "3754",
    Locality: "DOREEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.140300",
    Latitude: "-37.598100"
  },
  {
    Country: "Australia",
    Pcode: "3754",
    Locality: "MERNDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.085500",
    Latitude: "-37.592200"
  },
  {
    Country: "Australia",
    Pcode: "3755",
    Locality: "YAN YEAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.132100",
    Latitude: "-37.550700"
  },
  {
    Country: "Australia",
    Pcode: "3756",
    Locality: "CHINTIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.833800",
    Latitude: "-37.393300"
  },
  {
    Country: "Australia",
    Pcode: "3756",
    Locality: "DARRAWEIT GUIM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.866300",
    Latitude: "-37.415100"
  },
  {
    Country: "Australia",
    Pcode: "3756",
    Locality: "UPPER PLENTY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.061700",
    Latitude: "-37.415100"
  },
  {
    Country: "Australia",
    Pcode: "3756",
    Locality: "WALLAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.962000",
    Latitude: "-37.407000"
  },
  {
    Country: "Australia",
    Pcode: "3757",
    Locality: "EDEN PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.069800",
    Latitude: "-37.501700"
  },
  {
    Country: "Australia",
    Pcode: "3757",
    Locality: "HUMEVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.173700",
    Latitude: "-37.503700"
  },
  {
    Country: "Australia",
    Pcode: "3757",
    Locality: "KINGLAKE CENTRAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.319800",
    Latitude: "-37.469700"
  },
  {
    Country: "Australia",
    Pcode: "3757",
    Locality: "KINGLAKE WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.250700",
    Latitude: "-37.480000"
  },
  {
    Country: "Australia",
    Pcode: "3757",
    Locality: "PHEASANT CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.290900",
    Latitude: "-37.475300"
  },
  {
    Country: "Australia",
    Pcode: "3757",
    Locality: "WHITTLESEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.143900",
    Latitude: "-37.480300"
  },
  {
    Country: "Australia",
    Pcode: "3758",
    Locality: "HEATHCOTE JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.047800",
    Latitude: "-37.381200"
  },
  {
    Country: "Australia",
    Pcode: "3758",
    Locality: "WANDONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.035600",
    Latitude: "-37.352900"
  },
  {
    Country: "Australia",
    Pcode: "3759",
    Locality: "PANTON HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.247500",
    Latitude: "-37.640600"
  },
  {
    Country: "Australia",
    Pcode: "3760",
    Locality: "SMITHS GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.289400",
    Latitude: "-37.628200"
  },
  {
    Country: "Australia",
    Pcode: "3761",
    Locality: "ST ANDREWS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.287700",
    Latitude: "-37.579800"
  },
  {
    Country: "Australia",
    Pcode: "3762",
    Locality: "BYLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.930700",
    Latitude: "-37.350500"
  },
  {
    Country: "Australia",
    Pcode: "3763",
    Locality: "KINGLAKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.349200",
    Latitude: "-37.514800"
  },
  {
    Country: "Australia",
    Pcode: "3764",
    Locality: "FORBES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.887800",
    Latitude: "-37.314300"
  },
  {
    Country: "Australia",
    Pcode: "3764",
    Locality: "GLENAROUA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.961000",
    Latitude: "-37.112900"
  },
  {
    Country: "Australia",
    Pcode: "3764",
    Locality: "HIGH CAMP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.863400",
    Latitude: "-37.191200"
  },
  {
    Country: "Australia",
    Pcode: "3764",
    Locality: "KILMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.959000",
    Latitude: "-37.301000"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "MORTONS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.678800",
    Latitude: "-31.395500"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "MOUNT SEAVIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.187200",
    Latitude: "-31.363000"
  },
  {
    Country: "Australia",
    Pcode: "2446",
    Locality: "PAPPINBARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.479900",
    Latitude: "-31.359800"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "WASHPOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.380300",
    Latitude: "-29.291600"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "WATERVIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.904800",
    Latitude: "-29.686400"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "WATERVIEW HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.846100",
    Latitude: "-29.689900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "WELLS CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.032800",
    Latitude: "-29.896000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "WHITEMAN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.856200",
    Latitude: "-29.561200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "WINEGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.685000",
    Latitude: "-29.548400"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "WOMBAT CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.756300",
    Latitude: "-29.509400"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "CALLIOPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.074400",
    Latitude: "-29.615800"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "COLDSTREAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.110200",
    Latitude: "-29.600600"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "DIGGERS CAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.273700",
    Latitude: "-29.812900"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "GILLETTS RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.109400",
    Latitude: "-29.635000"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "LAKE HIAWATHA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.261700",
    Latitude: "-29.800100"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "LAVADIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.067900",
    Latitude: "-29.712200"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "MINNIE WATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.270500",
    Latitude: "-29.748500"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "PILLAR VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.156200",
    Latitude: "-29.736000"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "SWAN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.995100",
    Latitude: "-29.666700"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "TUCABIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.126600",
    Latitude: "-29.656800"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "ULMARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.048900",
    Latitude: "-29.638700"
  },
  {
    Country: "Australia",
    Pcode: "2462",
    Locality: "WOOLI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.239800",
    Latitude: "-29.814500"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "ASHBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.184600",
    Latitude: "-29.442700"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "ASHBY HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.179300",
    Latitude: "-29.400500"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "ASHBY ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.204000",
    Latitude: "-29.425800"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "BROOMS HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.291900",
    Latitude: "-29.615900"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "GULMARRAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.224800",
    Latitude: "-29.500400"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "ILARWILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.175100",
    Latitude: "-29.485700"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "JACKY BULBIN FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.202300",
    Latitude: "-29.281100"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "JAMES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.239300",
    Latitude: "-29.452900"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "MACLEAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.208400",
    Latitude: "-29.451000"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "PALMERS CHANNEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.279400",
    Latitude: "-29.475200"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "PALMERS ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.296200",
    Latitude: "-29.406800"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "SANDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.315500",
    Latitude: "-29.686700"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "SHARK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.220700",
    Latitude: "-29.574800"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "TALOUMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.276800",
    Latitude: "-29.552400"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "THE SANDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.291400",
    Latitude: "-29.666500"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "TOWNSEND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.220300",
    Latitude: "-29.462600"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "TULLYMORGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.107000",
    Latitude: "-29.389200"
  },
  {
    Country: "Australia",
    Pcode: "2463",
    Locality: "WOODFORD ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.134800",
    Latitude: "-29.514000"
  },
  {
    Country: "Australia",
    Pcode: "2464",
    Locality: "ANGOURIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.355400",
    Latitude: "-29.460000"
  },
  {
    Country: "Australia",
    Pcode: "2464",
    Locality: "FREEBURN ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.332100",
    Latitude: "-29.403300"
  },
  {
    Country: "Australia",
    Pcode: "2464",
    Locality: "MICALO ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.311800",
    Latitude: "-29.449900"
  },
  {
    Country: "Australia",
    Pcode: "2464",
    Locality: "WOOLOWEYAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.308300",
    Latitude: "-29.486300"
  },
  {
    Country: "Australia",
    Pcode: "2464",
    Locality: "YAMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.340600",
    Latitude: "-29.433700"
  },
  {
    Country: "Australia",
    Pcode: "2464",
    Locality: "YURAYGIR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.330000",
    Latitude: "-29.530900"
  },
  {
    Country: "Australia",
    Pcode: "2465",
    Locality: "HARWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.246500",
    Latitude: "-29.412800"
  },
  {
    Country: "Australia",
    Pcode: "2466",
    Locality: "ILUKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.357100",
    Latitude: "-29.397900"
  },
  {
    Country: "Australia",
    Pcode: "2466",
    Locality: "THE FRESHWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.335300",
    Latitude: "-29.341700"
  },
  {
    Country: "Australia",
    Pcode: "2466",
    Locality: "WOODY HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.368000",
    Latitude: "-29.368500"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "ALICE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.588000",
    Latitude: "-29.054600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "BEAN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.581000",
    Latitude: "-28.586200"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "BINGEEBEEBRA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.776300",
    Latitude: "-28.807600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "BONALBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.618500",
    Latitude: "-28.717600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "BOTTLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.649500",
    Latitude: "-28.788900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "BULLDOG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.491300",
    Latitude: "-29.032400"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "BUNGAWALBIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.176300",
    Latitude: "-29.097500"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "BUSBYS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.775600",
    Latitude: "-29.074300"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "CAMBRIDGE PLATEAU",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.732300",
    Latitude: "-28.806600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "CAMIRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.931600",
    Latitude: "-29.219600"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "CAPEEN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.648400",
    Latitude: "-28.549200"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "CHATSWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.247600",
    Latitude: "-29.389300"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "CLEARFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.910000",
    Latitude: "-29.153300"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "COONGBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.695200",
    Latitude: "-29.108100"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "CULMARAN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.679500",
    Latitude: "-28.868400"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "DEEP CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TABULAM",
    Category: "Delivery Area",
    Longitude: "152.685900",
    Latitude: "-28.967200"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "DRAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.412500",
    Latitude: "-28.906300"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "DRAKE VILLAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.379100",
    Latitude: "-28.928900"
  },
  {
    Country: "Australia",
    Pcode: "2469",
    Locality: "DUCK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.669500",
    Latitude: "-28.617000"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "BOAT HARBOUR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "LISMORE",
    Category: "Delivery Area",
    Longitude: "153.375100",
    Latitude: "-28.777100"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "BOOERIE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.254200",
    Latitude: "-28.776700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "BOORABEE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.055200",
    Latitude: "-28.676600"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "BOOYONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.449200",
    Latitude: "-28.751200"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "BUNGABBEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.133200",
    Latitude: "-28.763400"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "CANIABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.215900",
    Latitude: "-28.833900"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "CHILCOTTS GRASS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.343400",
    Latitude: "-28.833000"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "CLOVASS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.158400",
    Latitude: "-28.866500"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "CLUNES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.409700",
    Latitude: "-28.725500"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "COFFEE CAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.218400",
    Latitude: "-28.653700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "CORNDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.364500",
    Latitude: "-28.705600"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "DORROUGHBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.358100",
    Latitude: "-28.658600"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "DUNGARUBBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.378800",
    Latitude: "-28.991900"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "DUNOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.326900",
    Latitude: "-28.673600"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "EAST LISMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.287200",
    Latitude: "-28.824000"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "ELTHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.408400",
    Latitude: "-28.759400"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "EUREKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.444800",
    Latitude: "-28.686700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "FEDERAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.452600",
    Latitude: "-28.646700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "FERNSIDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.171700",
    Latitude: "-28.793700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "GEORGICA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.156800",
    Latitude: "-28.642800"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "GIRARDS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.278100",
    Latitude: "-28.817600"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "GOOLMANGAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.240200",
    Latitude: "-28.739300"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "GOONELLABAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.333900",
    Latitude: "-28.812700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "HOWARDS GRASS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.300700",
    Latitude: "-28.790000"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "JIGGI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.186100",
    Latitude: "-28.699300"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "KEERRONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.269100",
    Latitude: "-28.710200"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "KOONORIGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.226100",
    Latitude: "-28.707000"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "LAGOON GRASS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.321000",
    Latitude: "-28.794700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "LARNOOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.110600",
    Latitude: "-28.667300"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "LEYCESTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.173800",
    Latitude: "-28.772500"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "LILLIAN ROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.159500",
    Latitude: "-28.542800"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "LINDENDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.374500",
    Latitude: "-28.825300"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "LISMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.285600",
    Latitude: "-28.806700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "LISMORE DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "LISMORE HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.300200",
    Latitude: "-28.804300"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "LOFTVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.257800",
    Latitude: "-28.836100"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "MAROM CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.365100",
    Latitude: "-28.921600"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "MCKEES HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.193700",
    Latitude: "-28.871300"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "MCLEANS RIDGES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.397000",
    Latitude: "-28.792300"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "MODANVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.301700",
    Latitude: "-28.722100"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "MONALTRIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.290500",
    Latitude: "-28.851900"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "MOUNTAIN TOP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.187200",
    Latitude: "-28.618100"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "NIGHTCAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.320200",
    Latitude: "-28.559900"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "NIMBIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.240800",
    Latitude: "-28.580200"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "NORTH LISMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.277000",
    Latitude: "-28.787900"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "NUMULGI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.326200",
    Latitude: "-28.722700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "REPENTANCE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.406600",
    Latitude: "-28.633200"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "RICHMOND HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.343900",
    Latitude: "-28.790700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "ROCK VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.155400",
    Latitude: "-28.726000"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "ROSEBANK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.399200",
    Latitude: "-28.673400"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "RUTHVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.270600",
    Latitude: "-28.927700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "SOUTH GUNDURIMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.255700",
    Latitude: "-28.896500"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "SOUTH LISMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.250500",
    Latitude: "-28.815900"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "STONY CHUTE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.163600",
    Latitude: "-28.582800"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "TERANIA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.295400",
    Latitude: "-28.611500"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "THE CHANNON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.276600",
    Latitude: "-28.677300"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "TREGEAGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.352600",
    Latitude: "-28.864700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "TUCKI TUCKI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.316400",
    Latitude: "-28.922800"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "TUCKURIMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.334000",
    Latitude: "-28.949600"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "TULLERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.294300",
    Latitude: "-28.752600"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "TUNCESTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.222100",
    Latitude: "-28.802100"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "TUNTABLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.260900",
    Latitude: "-28.632900"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "WHIAN WHIAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.352800",
    Latitude: "-28.608100"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "WOODLAWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.318200",
    Latitude: "-28.766200"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "WYRALLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.320500",
    Latitude: "-28.885200"
  },
  {
    Country: "Australia",
    Pcode: "2481",
    Locality: "BROKEN HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.591900",
    Latitude: "-28.730100"
  },
  {
    Country: "Australia",
    Pcode: "2528",
    Locality: "WINDANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.873100",
    Latitude: "-34.528700"
  },
  {
    Country: "Australia",
    Pcode: "2529",
    Locality: "BLACKBUTT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.832900",
    Latitude: "-34.571300"
  },
  {
    Country: "Australia",
    Pcode: "2529",
    Locality: "DUNMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.823000",
    Latitude: "-34.621400"
  },
  {
    Country: "Australia",
    Pcode: "2529",
    Locality: "FLINDERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.846900",
    Latitude: "-34.580300"
  },
  {
    Country: "Australia",
    Pcode: "2529",
    Locality: "OAK FLATS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.820900",
    Latitude: "-34.562800"
  },
  {
    Country: "Australia",
    Pcode: "2529",
    Locality: "OAK FLATS DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2529",
    Locality: "SHELL COVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.873800",
    Latitude: "-34.595300"
  },
  {
    Country: "Australia",
    Pcode: "2529",
    Locality: "SHELLHARBOUR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.862000",
    Latitude: "-34.575900"
  },
  {
    Country: "Australia",
    Pcode: "2529",
    Locality: "SHELLHARBOUR CITY CENTRE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.835900",
    Latitude: "-34.564800"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "AVONDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.745500",
    Latitude: "-34.522100"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "BROWNSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.811900",
    Latitude: "-34.482600"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "CLEVELAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.768600",
    Latitude: "-34.503600"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "DAPTO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.794900",
    Latitude: "-34.506800"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "DOMBARTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.762700",
    Latitude: "-34.454600"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "HAYWARDS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.795800",
    Latitude: "-34.545200"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "HORSLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.776000",
    Latitude: "-34.490600"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "HUNTLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "DAPTO",
    Category: "Delivery Area",
    Longitude: "150.737300",
    Latitude: "-34.495100"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "KANAHOOKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.814700",
    Latitude: "-34.492600"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "KOONAWARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.809200",
    Latitude: "-34.506500"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "MARSHALL MOUNT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.717000",
    Latitude: "-34.539900"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "WONGAWILLI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.750200",
    Latitude: "-34.471900"
  },
  {
    Country: "Australia",
    Pcode: "2530",
    Locality: "YALLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.794100",
    Latitude: "-34.533700"
  },
  {
    Country: "Australia",
    Pcode: "2533",
    Locality: "BOMBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.849000",
    Latitude: "-34.654900"
  },
  {
    Country: "Australia",
    Pcode: "2533",
    Locality: "CURRAMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.764600",
    Latitude: "-34.622800"
  },
  {
    Country: "Australia",
    Pcode: "2533",
    Locality: "JAMBEROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.757700",
    Latitude: "-34.656900"
  },
  {
    Country: "Australia",
    Pcode: "2533",
    Locality: "JERRARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.815100",
    Latitude: "-34.674700"
  },
  {
    Country: "Australia",
    Pcode: "2533",
    Locality: "KIAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.846600",
    Latitude: "-34.674500"
  },
  {
    Country: "Australia",
    Pcode: "2533",
    Locality: "KIAMA DOWNS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.846200",
    Latitude: "-34.639800"
  },
  {
    Country: "Australia",
    Pcode: "2533",
    Locality: "KIAMA HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.840300",
    Latitude: "-34.703000"
  },
  {
    Country: "Australia",
    Pcode: "2533",
    Locality: "MINNAMURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.850000",
    Latitude: "-34.622500"
  },
  {
    Country: "Australia",
    Pcode: "2533",
    Locality: "SADDLEBACK MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.803400",
    Latitude: "-34.698800"
  },
  {
    Country: "Australia",
    Pcode: "2534",
    Locality: "BROUGHTON VILLAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.752800",
    Latitude: "-34.745700"
  },
  {
    Country: "Australia",
    Pcode: "2534",
    Locality: "FOXGROUND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.759400",
    Latitude: "-34.716500"
  },
  {
    Country: "Australia",
    Pcode: "2534",
    Locality: "GERRINGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.823900",
    Latitude: "-34.745100"
  },
  {
    Country: "Australia",
    Pcode: "2534",
    Locality: "GERROA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.796600",
    Latitude: "-34.774000"
  },
  {
    Country: "Australia",
    Pcode: "2534",
    Locality: "ROSE VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GERRINGONG",
    Category: "Delivery Area",
    Longitude: "150.808600",
    Latitude: "-34.718500"
  },
  {
    Country: "Australia",
    Pcode: "2534",
    Locality: "TOOLIJOOA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.781100",
    Latitude: "-34.760600"
  },
  {
    Country: "Australia",
    Pcode: "2534",
    Locality: "WERRI BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.834600",
    Latitude: "-34.732000"
  },
  {
    Country: "Australia",
    Pcode: "2534",
    Locality: "WILLOW VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GERRINGONG",
    Category: "Delivery Area",
    Longitude: "150.790700",
    Latitude: "-34.739600"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "BACK FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.682700",
    Latitude: "-34.843600"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "BELLAWONGARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.612200",
    Latitude: "-34.759100"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "BERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.732500",
    Latitude: "-34.799400"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "BERRY MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.650400",
    Latitude: "-34.765800"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "BROGERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.691300",
    Latitude: "-34.697400"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "BROUGHTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.726800",
    Latitude: "-34.756400"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "BROUGHTON VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.705500",
    Latitude: "-34.739000"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "BUDDEROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.654100",
    Latitude: "-34.680600"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "BUNDEWALLAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.665600",
    Latitude: "-34.750700"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "COOLANGATTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "SHOALHAVEN",
    Category: "Delivery Area",
    Longitude: "150.710500",
    Latitude: "-34.850600"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "FAR MEADOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.700900",
    Latitude: "-34.826600"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "JASPERS BRUSH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.657400",
    Latitude: "-34.801700"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "SHOALHAVEN HEADS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.741200",
    Latitude: "-34.849200"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "WATTAMOLLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.632100",
    Latitude: "-34.724800"
  },
  {
    Country: "Australia",
    Pcode: "2535",
    Locality: "WOODHILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.672600",
    Latitude: "-34.728600"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "BATEHAVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.198500",
    Latitude: "-35.737300"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "BATEMANS BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.166900",
    Latitude: "-35.706300"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "BENANDARAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.215400",
    Latitude: "-35.610000"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "BIMBIMBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.115100",
    Latitude: "-35.851900"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "BUCKENBOWRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.981500",
    Latitude: "-35.652500"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "CATALINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.185400",
    Latitude: "-35.729800"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "CURROWAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.097500",
    Latitude: "-35.566600"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "DENHAMS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.211900",
    Latitude: "-35.751400"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "DEPOT BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.322500",
    Latitude: "-35.626700"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "DURRAS NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.293900",
    Latitude: "-35.626900"
  },
  {
    Country: "Australia",
    Pcode: "2536",
    Locality: "EAST LYNNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.289900",
    Latitude: "-35.588600"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "FROGS HOLLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.809900",
    Latitude: "-36.763600"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "GREENDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BEGA",
    Category: "Delivery Area",
    Longitude: "149.866800",
    Latitude: "-36.585100"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "JELLAT JELLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.883800",
    Latitude: "-36.723900"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "KALARU",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.929300",
    Latitude: "-36.731100"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "KAMERUKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.698700",
    Latitude: "-36.725900"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "KANOONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.771900",
    Latitude: "-36.748100"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "KINGSWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BEGA",
    Category: "Delivery Area",
    Longitude: "149.820300",
    Latitude: "-36.742100"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "MOGAREEKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.979100",
    Latitude: "-36.693000"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "MOGILLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.574900",
    Latitude: "-36.708000"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "MORANS CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.655600",
    Latitude: "-36.668200"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "MUMBULLA MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.888600",
    Latitude: "-36.535200"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "MYRTLE MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.683600",
    Latitude: "-36.854000"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "NELSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BEGA",
    Category: "Delivery Area",
    Longitude: "149.952700",
    Latitude: "-36.666900"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "NEW BUILDINGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.559800",
    Latitude: "-36.960600"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "NUMBUGGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.705200",
    Latitude: "-36.622300"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "PERICOE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.584200",
    Latitude: "-37.075600"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "QUAAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.890300",
    Latitude: "-36.464900"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "REEDY SWAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.904900",
    Latitude: "-36.699500"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "ROCKY HALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.505000",
    Latitude: "-36.931900"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "SOUTH WOLUMLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.765600",
    Latitude: "-36.856200"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "STONY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.803500",
    Latitude: "-36.617300"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "TANJA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.964900",
    Latitude: "-36.625100"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "TANTAWANGALO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.502900",
    Latitude: "-36.741700"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "TARRAGANDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.865600",
    Latitude: "-36.669600"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "TATHRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.966400",
    Latitude: "-36.737300"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "TOOTHDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.752400",
    Latitude: "-36.780300"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "TOWAMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.698300",
    Latitude: "-37.107200"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "VERONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.809400",
    Latitude: "-36.447400"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "WALLAGOOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.921300",
    Latitude: "-36.765400"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "WANDELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.859800",
    Latitude: "-36.308600"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "WAPENGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.968000",
    Latitude: "-36.584700"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "WOG WOG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BEGA",
    Category: "Delivery Area",
    Longitude: "149.444000",
    Latitude: "-37.074300"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "WOLUMLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.814200",
    Latitude: "-36.816100"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "WYNDHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.640500",
    Latitude: "-36.922100"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "YAMBULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.588400",
    Latitude: "-37.238600"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "YANKEES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.548500",
    Latitude: "-36.488100"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "YOWRIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.735600",
    Latitude: "-36.325300"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "BOYDTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.873300",
    Latitude: "-37.104900"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "EDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.893800",
    Latitude: "-37.019300"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "EDROM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.946100",
    Latitude: "-37.133300"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "GREEN CAPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.002900",
    Latitude: "-37.208200"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "KIAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.853400",
    Latitude: "-37.153900"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "NADGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.836700",
    Latitude: "-37.368100"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "NARRABARBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.772000",
    Latitude: "-37.220700"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "NULLICA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.741500",
    Latitude: "-37.055400"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "NUNGATTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.413500",
    Latitude: "-37.192200"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "NUNGATTA SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.452700",
    Latitude: "-37.278900"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "TIMBILLICA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.736700",
    Latitude: "-37.282600"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "WONBOYN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.934800",
    Latitude: "-37.260100"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "WONBOYN LAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2551",
    Locality: "WONBOYN NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.904900",
    Latitude: "-37.198600"
  },
  {
    Country: "Australia",
    Pcode: "2555",
    Locality: "BADGERYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.744100",
    Latitude: "-33.881700"
  },
  {
    Country: "Australia",
    Pcode: "2556",
    Locality: "BRINGELLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.725200",
    Latitude: "-33.946500"
  },
  {
    Country: "Australia",
    Pcode: "2557",
    Locality: "CATHERINE FIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.770500",
    Latitude: "-33.996700"
  },
  {
    Country: "Australia",
    Pcode: "2557",
    Locality: "GLEDSWOOD HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.778400",
    Latitude: "-34.013600"
  },
  {
    Country: "Australia",
    Pcode: "2557",
    Locality: "GREGORY HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.776300",
    Latitude: "-34.031600"
  },
  {
    Country: "Australia",
    Pcode: "2557",
    Locality: "ROSSMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.773400",
    Latitude: "-33.936300"
  },
  {
    Country: "Australia",
    Pcode: "2558",
    Locality: "EAGLE VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.811500",
    Latitude: "-34.037900"
  },
  {
    Country: "Australia",
    Pcode: "2558",
    Locality: "ESCHOL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.800900",
    Latitude: "-34.034100"
  },
  {
    Country: "Australia",
    Pcode: "2558",
    Locality: "KEARNS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.802000",
    Latitude: "-34.019700"
  },
  {
    Country: "Australia",
    Pcode: "2559",
    Locality: "BLAIRMOUNT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.794700",
    Latitude: "-34.051200"
  },
  {
    Country: "Australia",
    Pcode: "2559",
    Locality: "CLAYMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.810300",
    Latitude: "-34.047100"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "AIRDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.832900",
    Latitude: "-34.086700"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "AMBARVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.797300",
    Latitude: "-34.088900"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "APPIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.806600",
    Latitude: "-34.212100"
  },
  {
    Country: "Australia",
    Pcode: "2560",
    Locality: "BLAIR ATHOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.803100",
    Latitude: "-34.062000"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "WOLLOGORANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.520500",
    Latitude: "-34.857500"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "BANGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.959000",
    Latitude: "-34.760200"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "BERREMANGRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.470700",
    Latitude: "-34.798400"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "BOAMBOLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.884400",
    Latitude: "-34.979300"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "BOOKHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.633600",
    Latitude: "-34.843500"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "BOWNING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.813200",
    Latitude: "-34.750900"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "BURRINJUCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.624100",
    Latitude: "-34.957500"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "CAVAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.839800",
    Latitude: "-35.049600"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "GOOD HOPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.822600",
    Latitude: "-34.898200"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "JEIR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.972000",
    Latitude: "-35.054800"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "JERRAWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.073100",
    Latitude: "-34.782900"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "KANGIARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.786500",
    Latitude: "-34.619500"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "LAVERSTOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.853000",
    Latitude: "-34.642400"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "MANTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.017600",
    Latitude: "-34.844900"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "MARCHMONT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.941900",
    Latitude: "-34.901300"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "MULLION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.869300",
    Latitude: "-35.139100"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "MURRUMBATEMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.024400",
    Latitude: "-34.974400"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "NANIMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.127500",
    Latitude: "-35.010200"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "NARRANGULLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.723200",
    Latitude: "-35.003500"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "WEE JASPER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.670600",
    Latitude: "-35.123200"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "WOOLGARLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.730400",
    Latitude: "-34.875700"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "YASS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.907800",
    Latitude: "-34.828500"
  },
  {
    Country: "Australia",
    Pcode: "2582",
    Locality: "YASS RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.122500",
    Latitude: "-34.931900"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "BIGGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.131900",
    Latitude: "-34.052200"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "BINDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.301100",
    Latitude: "-34.307600"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "CROOKED CORNER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.287400",
    Latitude: "-34.204300"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "CROOKWELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.450700",
    Latitude: "-34.450100"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "FULLERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.550400",
    Latitude: "-34.183700"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "GRABBEN GULLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.377900",
    Latitude: "-34.527600"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "LAGGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.568600",
    Latitude: "-34.346100"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "LIMERICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.474300",
    Latitude: "-34.209100"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "LOST RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.318000",
    Latitude: "-34.433400"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "NARRAWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.174400",
    Latitude: "-34.430500"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "PEELWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.428300",
    Latitude: "-34.118000"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "PEJAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.539200",
    Latitude: "-34.531700"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "RUGBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.016800",
    Latitude: "-34.394900"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "TUENA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.319600",
    Latitude: "-34.045100"
  },
  {
    Country: "Australia",
    Pcode: "2583",
    Locality: "WHEEO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.274500",
    Latitude: "-34.514800"
  },
  {
    Country: "Australia",
    Pcode: "2584",
    Locality: "BINALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.667400",
    Latitude: "-34.687500"
  },
  {
    Country: "Australia",
    Pcode: "2585",
    Locality: "GALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.570200",
    Latitude: "-34.599800"
  },
  {
    Country: "Australia",
    Pcode: "2586",
    Locality: "BOOROWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.746500",
    Latitude: "-34.366900"
  },
  {
    Country: "Australia",
    Pcode: "2586",
    Locality: "FROGMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.876200",
    Latitude: "-34.242400"
  },
  {
    Country: "Australia",
    Pcode: "2586",
    Locality: "GODFREYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.714700",
    Latitude: "-34.136600"
  },
  {
    Country: "Australia",
    Pcode: "2586",
    Locality: "MURRINGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.553800",
    Latitude: "-34.286300"
  },
  {
    Country: "Australia",
    Pcode: "2586",
    Locality: "REIDS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.990600",
    Latitude: "-34.125100"
  },
  {
    Country: "Australia",
    Pcode: "2586",
    Locality: "RYE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.919100",
    Latitude: "-34.532800"
  },
  {
    Country: "Australia",
    Pcode: "2586",
    Locality: "TAYLORS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.032300",
    Latitude: "-34.275900"
  },
  {
    Country: "Australia",
    Pcode: "2587",
    Locality: "BEGGAN BEGGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.294000",
    Latitude: "-34.666000"
  },
  {
    Country: "Australia",
    Pcode: "2587",
    Locality: "CUNNINGAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.434900",
    Latitude: "-34.545500"
  },
  {
    Country: "Australia",
    Pcode: "2587",
    Locality: "HARDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.380800",
    Latitude: "-34.539200"
  },
  {
    Country: "Australia",
    Pcode: "2587",
    Locality: "KINGSVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.335300",
    Latitude: "-34.434200"
  },
  {
    Country: "Australia",
    Pcode: "2587",
    Locality: "MCMAHONS REEF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.414800",
    Latitude: "-34.678300"
  },
  {
    Country: "Australia",
    Pcode: "2587",
    Locality: "MURRUMBURRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.318700",
    Latitude: "-34.542300"
  },
  {
    Country: "Australia",
    Pcode: "2587",
    Locality: "NUBBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.236300",
    Latitude: "-34.520300"
  },
  {
    Country: "Australia",
    Pcode: "2587",
    Locality: "WOMBAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.252800",
    Latitude: "-34.420400"
  },
  {
    Country: "Australia",
    Pcode: "2588",
    Locality: "WALLENDBEEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.124600",
    Latitude: "-34.503900"
  },
  {
    Country: "Australia",
    Pcode: "2590",
    Locality: "BETHUNGRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.826300",
    Latitude: "-34.765800"
  },
  {
    Country: "Australia",
    Pcode: "2590",
    Locality: "COOTAMUNDRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.039500",
    Latitude: "-34.634800"
  },
  {
    Country: "Australia",
    Pcode: "2590",
    Locality: "ILLABO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.751100",
    Latitude: "-34.828400"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "BARWANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.473300",
    Latitude: "-34.442600"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "BERTHONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.089800",
    Latitude: "-34.389400"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "BRIBBAREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.890800",
    Latitude: "-34.110500"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "BULLA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.261300",
    Latitude: "-34.107900"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "BURRANGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.251300",
    Latitude: "-34.286800"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "KIKIAMAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.126100",
    Latitude: "-34.095900"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "MAIMURU",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.177100",
    Latitude: "-34.232900"
  },
  {
    Country: "Australia",
    Pcode: "2594",
    Locality: "MEMAGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.124400",
    Latitude: "-34.303300"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "PALERANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.569100",
    Latitude: "-35.422200"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "QUIERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.199600",
    Latitude: "-34.937700"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "REIDSDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.844300",
    Latitude: "-35.568300"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "SASSAFRAS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.198400",
    Latitude: "-35.124600"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "SNOWBALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.602100",
    Latitude: "-35.942700"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "ST GEORGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.224000",
    Latitude: "-35.030700"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "TIANJARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.330500",
    Latitude: "-35.143300"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "TOLWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.108600",
    Latitude: "-34.829900"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "TOMBOYE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.963600",
    Latitude: "-35.254800"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "TOUGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.104300",
    Latitude: "-34.922700"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "WARRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.687300",
    Latitude: "-35.331100"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "WOG WOG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.060200",
    Latitude: "-35.258900"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "WYANBENE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.682600",
    Latitude: "-35.773800"
  },
  {
    Country: "Australia",
    Pcode: "2623",
    Locality: "CAPTAINS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.424000",
    Latitude: "-35.571800"
  },
  {
    Country: "Australia",
    Pcode: "2624",
    Locality: "PERISHER VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2625",
    Locality: "THREDBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2626",
    Locality: "BREDBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.151100",
    Latitude: "-35.958700"
  },
  {
    Country: "Australia",
    Pcode: "2626",
    Locality: "BUMBALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.098900",
    Latitude: "-35.852900"
  },
  {
    Country: "Australia",
    Pcode: "2626",
    Locality: "COLINTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.195700",
    Latitude: "-35.864100"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "CRACKENBACK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.514900",
    Latitude: "-36.439700"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "EAST JINDABYNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.666100",
    Latitude: "-36.410300"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "GROSSES PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.463000",
    Latitude: "-36.562900"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "INGEBIRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.517800",
    Latitude: "-36.649500"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "JINDABYNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.619500",
    Latitude: "-36.411200"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "KALKITE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.645300",
    Latitude: "-36.334300"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "KOSCIUSZKO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.204100",
    Latitude: "-36.496200"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "KOSCIUSZKO NATIONAL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.597500",
    Latitude: "-36.055700"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "MOONBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.547300",
    Latitude: "-36.508400"
  },
  {
    Country: "Australia",
    Pcode: "2627",
    Locality: "PILOT WILDERNESS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.204400",
    Latitude: "-36.710600"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "AVONSIDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.719900",
    Latitude: "-36.410500"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "BELOKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.702600",
    Latitude: "-36.549300"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "BERRIDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.836800",
    Latitude: "-36.378200"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "BRAEMAR BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BERRIDALE",
    Category: "Delivery Area",
    Longitude: "148.670700",
    Latitude: "-36.102100"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "BYADBO WILDERNESS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.620100",
    Latitude: "-36.825100"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "COOTRALANTRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.839100",
    Latitude: "-36.287600"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "DALGETY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.827700",
    Latitude: "-36.521700"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "EUCUMBENE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.626900",
    Latitude: "-36.135200"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "HILL TOP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BERRIDALE",
    Category: "Delivery Area",
    Longitude: "148.694100",
    Latitude: "-36.329100"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "JIMENBUEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.830900",
    Latitude: "-36.749000"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "NIMMO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.624300",
    Latitude: "-36.177000"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "NUMBLA VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.791700",
    Latitude: "-36.611300"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "PAUPONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.673700",
    Latitude: "-36.649400"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "ROCKY PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.686800",
    Latitude: "-36.251000"
  },
  {
    Country: "Australia",
    Pcode: "2628",
    Locality: "SNOWY PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.522900",
    Latitude: "-36.156700"
  },
  {
    Country: "Australia",
    Pcode: "2629",
    Locality: "ADAMINABY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.771900",
    Latitude: "-35.997600"
  },
  {
    Country: "Australia",
    Pcode: "2629",
    Locality: "ANGLERS REACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.677000",
    Latitude: "-36.010600"
  },
  {
    Country: "Australia",
    Pcode: "2629",
    Locality: "BOLARO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.852700",
    Latitude: "-35.919600"
  },
  {
    Country: "Australia",
    Pcode: "2629",
    Locality: "CABRAMURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.447600",
    Latitude: "-35.976500"
  },
  {
    Country: "Australia",
    Pcode: "2629",
    Locality: "LONG PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ADAMINABY",
    Category: "Delivery Area",
    Longitude: "148.579700",
    Latitude: "-35.655700"
  },
  {
    Country: "Australia",
    Pcode: "2629",
    Locality: "OLD ADAMINABY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.689600",
    Latitude: "-36.041300"
  },
  {
    Country: "Australia",
    Pcode: "2629",
    Locality: "PROVIDENCE PORTAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.615200",
    Latitude: "-35.951000"
  },
  {
    Country: "Australia",
    Pcode: "2629",
    Locality: "TANTANGARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.672000",
    Latitude: "-35.722400"
  },
  {
    Country: "Australia",
    Pcode: "2629",
    Locality: "YAOUK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.806500",
    Latitude: "-35.803000"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "ARABLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.986800",
    Latitude: "-36.378100"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "BADJA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.511300",
    Latitude: "-36.041900"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "BILLILINGRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.112100",
    Latitude: "-36.015900"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "BINJURA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.108000",
    Latitude: "-36.174300"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "BOBUNDARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.969700",
    Latitude: "-36.461700"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "BUCKENDERRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.713400",
    Latitude: "-36.156600"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "BUNGARBY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.045800",
    Latitude: "-36.686900"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "BUNYAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.162200",
    Latitude: "-36.148300"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "CARLAMINDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.305700",
    Latitude: "-36.264100"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "CHAKOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.179100",
    Latitude: "-36.073900"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "COOLRINGDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.983700",
    Latitude: "-36.258400"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "COOMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.112600",
    Latitude: "-36.250900"
  },
  {
    Country: "Australia",
    Pcode: "2630",
    Locality: "COUNTEGANY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.491200",
    Latitude: "-36.183900"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "COMBANING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.650600",
    Latitude: "-34.483600"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "DIRNASEER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.729600",
    Latitude: "-34.597100"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "GIDGINBUNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.450100",
    Latitude: "-34.309000"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "GROGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.734100",
    Latitude: "-34.315300"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "JUNEE REEFS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.668800",
    Latitude: "-34.709800"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "MIMOSA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.411600",
    Latitude: "-34.547000"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "MORANGARELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.726000",
    Latitude: "-34.173800"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "NARRABURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.608300",
    Latitude: "-34.363300"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "PUCAWAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.386100",
    Latitude: "-34.387200"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "REEFTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.400200",
    Latitude: "-34.232300"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "SEBASTOPOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.533500",
    Latitude: "-34.572000"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "SPRINGDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.745400",
    Latitude: "-34.474700"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "TEMORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.525000",
    Latitude: "-34.447300"
  },
  {
    Country: "Australia",
    Pcode: "2666",
    Locality: "TRUNGLEY HALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.555500",
    Latitude: "-34.273100"
  },
  {
    Country: "Australia",
    Pcode: "2668",
    Locality: "BARMEDMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.416000",
    Latitude: "-34.122100"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "ERIGOLIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.389100",
    Latitude: "-33.903200"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "GIRRAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.141200",
    Latitude: "-33.665300"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "KIKOIRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.657500",
    Latitude: "-33.666700"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "MELBERGEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.008900",
    Latitude: "-33.743000"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "NARADHAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.322500",
    Latitude: "-33.656100"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "RANKINS SPRINGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.185200",
    Latitude: "-33.807800"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "TALLIMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.902500",
    Latitude: "-34.049200"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "TULLIBIGEAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.727300",
    Latitude: "-33.396000"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "UNGARIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.911700",
    Latitude: "-33.585500"
  },
  {
    Country: "Australia",
    Pcode: "2669",
    Locality: "WEETHALLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.582900",
    Latitude: "-33.862900"
  },
  {
    Country: "Australia",
    Pcode: "2671",
    Locality: "ALLEENA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.127300",
    Latitude: "-34.092200"
  },
  {
    Country: "Australia",
    Pcode: "2671",
    Locality: "BACK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WEST WYALONG",
    Category: "Delivery Area",
    Longitude: "147.456400",
    Latitude: "-33.858600"
  },
  {
    Country: "Australia",
    Pcode: "2671",
    Locality: "BURCHER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.232500",
    Latitude: "-33.488500"
  },
  {
    Country: "Australia",
    Pcode: "2671",
    Locality: "LAKE COWAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.375400",
    Latitude: "-33.656600"
  },
  {
    Country: "Australia",
    Pcode: "2671",
    Locality: "NORTH YALGOGRIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.847500",
    Latitude: "-33.820000"
  },
  {
    Country: "Australia",
    Pcode: "2671",
    Locality: "WEST WYALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.091100",
    Latitude: "-33.872800"
  },
  {
    Country: "Australia",
    Pcode: "2671",
    Locality: "WYALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.303500",
    Latitude: "-33.890600"
  },
  {
    Country: "Australia",
    Pcode: "2672",
    Locality: "CURLEW WATERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.434000",
    Latitude: "-33.260800"
  },
  {
    Country: "Australia",
    Pcode: "2672",
    Locality: "LAKE CARGELLIGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.303400",
    Latitude: "-33.363100"
  },
  {
    Country: "Australia",
    Pcode: "2672",
    Locality: "MURRIN BRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.370600",
    Latitude: "-33.206000"
  },
  {
    Country: "Australia",
    Pcode: "2675",
    Locality: "HILLSTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.477400",
    Latitude: "-33.443400"
  },
  {
    Country: "Australia",
    Pcode: "2675",
    Locality: "LAKE BREWSTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.995800",
    Latitude: "-33.468600"
  },
  {
    Country: "Australia",
    Pcode: "2675",
    Locality: "MONIA GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.974900",
    Latitude: "-33.585300"
  },
  {
    Country: "Australia",
    Pcode: "2675",
    Locality: "ROTO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.390500",
    Latitude: "-32.965100"
  },
  {
    Country: "Australia",
    Pcode: "2675",
    Locality: "WALLANTHERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.925800",
    Latitude: "-33.286800"
  },
  {
    Country: "Australia",
    Pcode: "2678",
    Locality: "CHARLES STURT UNIVERSITY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WAGGA WAGGA",
    Category: "Delivery Area",
    Longitude: "147.354400",
    Latitude: "-35.057800"
  },
  {
    Country: "Australia",
    Pcode: "2678",
    Locality: "RIVERINA MC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "BEELBANGERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.107200",
    Latitude: "-34.233000"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "BENEREMBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.828500",
    Latitude: "-34.491800"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "BILBUL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.158800",
    Latitude: "-34.283900"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "GRIFFITH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.044300",
    Latitude: "-34.275300"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "GRIFFITH DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "GRIFFITH EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "HANWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.022600",
    Latitude: "-34.359200"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "KOOBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.098700",
    Latitude: "-34.425900"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "LAKE WYANGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.023200",
    Latitude: "-34.226300"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "NERICON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.033900",
    Latitude: "-34.174800"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "THARBOGANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.943600",
    Latitude: "-34.268300"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "WARBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.929500",
    Latitude: "-34.173500"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "WARRAWIDGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.739800",
    Latitude: "-34.336700"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "WIDGELLI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.149100",
    Latitude: "-34.360000"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "WILLBRIGGIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.998300",
    Latitude: "-34.462000"
  },
  {
    Country: "Australia",
    Pcode: "2680",
    Locality: "YOOGALI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.098700",
    Latitude: "-34.316300"
  },
  {
    Country: "Australia",
    Pcode: "2681",
    Locality: "MYALL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.147100",
    Latitude: "-34.096300"
  },
  {
    Country: "Australia",
    Pcode: "2681",
    Locality: "YENDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.218300",
    Latitude: "-34.194300"
  },
  {
    Country: "Australia",
    Pcode: "2700",
    Locality: "BUNDURE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.890200",
    Latitude: "-35.134600"
  },
  {
    Country: "Australia",
    Pcode: "2700",
    Locality: "COLINROOBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.561000",
    Latitude: "-34.468100"
  },
  {
    Country: "Australia",
    Pcode: "2700",
    Locality: "COROBIMILLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.405600",
    Latitude: "-34.868600"
  },
  {
    Country: "Australia",
    Pcode: "2700",
    Locality: "CUDGEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.447100",
    Latitude: "-34.682400"
  },
  {
    Country: "Australia",
    Pcode: "2700",
    Locality: "EUROLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.314500",
    Latitude: "-34.704500"
  },
  {
    Country: "Australia",
    Pcode: "2700",
    Locality: "GILLENBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.567700",
    Latitude: "-34.824400"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "HERMITAGE FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.144800",
    Latitude: "-33.478300"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "JENOLAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.064000",
    Latitude: "-33.815200"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "KANIMBLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.208100",
    Latitude: "-33.635200"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "LIDSDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.114600",
    Latitude: "-33.365500"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "LITHGOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.160100",
    Latitude: "-33.480000"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "LITTLE HARTLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.184300",
    Latitude: "-33.585700"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "LITTLETON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.139700",
    Latitude: "-33.495800"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "LOWTHER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.115700",
    Latitude: "-33.621200"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "MARRANGAROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.149800",
    Latitude: "-33.423100"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "MCKELLARS PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.153000",
    Latitude: "-33.470300"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "MORTS ESTATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.169000",
    Latitude: "-33.467500"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "MOUNT LAMBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.995800",
    Latitude: "-33.470700"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "NEWNES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.285900",
    Latitude: "-33.191000"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "NEWNES PLATEAU",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.256900",
    Latitude: "-33.336200"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "OAKY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.183600",
    Latitude: "-33.465200"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "POTTERY ESTATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.156300",
    Latitude: "-33.487200"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "RYDAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.046500",
    Latitude: "-33.532600"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "SHEEDYS GULLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.151300",
    Latitude: "-33.495400"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "SODWALLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.987200",
    Latitude: "-33.572400"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "SOUTH BOWENFELS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.112600",
    Latitude: "-33.519300"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "SOUTH LITTLETON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.138400",
    Latitude: "-33.500800"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "SPRINGVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "LITHGOW",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "STATE MINE GULLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.208200",
    Latitude: "-33.443900"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "VALE OF CLWYDD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.185700",
    Latitude: "-33.487700"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "WOLGAN VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.159800",
    Latitude: "-33.246500"
  },
  {
    Country: "Australia",
    Pcode: "2790",
    Locality: "WOLLANGAMBE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.449100",
    Latitude: "-33.343500"
  },
  {
    Country: "Australia",
    Pcode: "2791",
    Locality: "CARCOAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.153700",
    Latitude: "-33.618900"
  },
  {
    Country: "Australia",
    Pcode: "2791",
    Locality: "ERROWANBANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.032200",
    Latitude: "-33.530400"
  },
  {
    Country: "Australia",
    Pcode: "2792",
    Locality: "BURNT YARDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.027100",
    Latitude: "-33.590200"
  },
  {
    Country: "Australia",
    Pcode: "2792",
    Locality: "MANDURAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.878200",
    Latitude: "-33.667100"
  },
  {
    Country: "Australia",
    Pcode: "2793",
    Locality: "DARBYS FALLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.856200",
    Latitude: "-33.905900"
  },
  {
    Country: "Australia",
    Pcode: "2793",
    Locality: "ROSEBERG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.050800",
    Latitude: "-33.859900"
  },
  {
    Country: "Australia",
    Pcode: "2793",
    Locality: "WOODSTOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "COWRA",
    Category: "Delivery Area",
    Longitude: "148.885400",
    Latitude: "-33.774000"
  },
  {
    Country: "Australia",
    Pcode: "2794",
    Locality: "BUMBALDRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.412500",
    Latitude: "-33.867600"
  },
  {
    Country: "Australia",
    Pcode: "2794",
    Locality: "COWRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.657900",
    Latitude: "-33.850200"
  },
  {
    Country: "Australia",
    Pcode: "2794",
    Locality: "HOVELLS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.863500",
    Latitude: "-34.067500"
  },
  {
    Country: "Australia",
    Pcode: "2794",
    Locality: "MOUNT COLLINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.783600",
    Latitude: "-34.033900"
  },
  {
    Country: "Australia",
    Pcode: "2794",
    Locality: "WATTAMONDARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.588900",
    Latitude: "-33.943800"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ABERCROMBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.561700",
    Latitude: "-33.392000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ABERCROMBIE RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.352600",
    Latitude: "-33.911600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ARKELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.387800",
    Latitude: "-33.735700"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "ARKSTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.662200",
    Latitude: "-34.014100"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "BALD RIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.422700",
    Latitude: "-33.937100"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "BALLYROE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.570900",
    Latitude: "-34.060100"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "BATHAMPTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.413600",
    Latitude: "-33.489600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "BATHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.579000",
    Latitude: "-33.418900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "BATHURST WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "BILLYWILLINGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.437400",
    Latitude: "-33.276000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "BREWONGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.689200",
    Latitude: "-33.472400"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "BRUINBUN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.488200",
    Latitude: "-33.119200"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "BURRAGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.545000",
    Latitude: "-33.905500"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "CALOOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.434900",
    Latitude: "-33.639600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "CHARLES STURT UNIVERSITY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "CHARLTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.643600",
    Latitude: "-33.672500"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "CLEAR CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.709900",
    Latitude: "-33.333600"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "COLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BATHURST",
    Category: "Delivery Area",
    Longitude: "149.264700",
    Latitude: "-33.778400"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "COPPERHANNIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.209700",
    Latitude: "-33.875500"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "COW FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.512300",
    Latitude: "-33.580100"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "CRUDINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.675000",
    Latitude: "-32.974700"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "CURRAGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.173100",
    Latitude: "-33.950400"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "DARK CORNER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.881700",
    Latitude: "-33.304000"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "DOG ROCKS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.616000",
    Latitude: "-33.752900"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "DUNKELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.480800",
    Latitude: "-33.410500"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "DURAMANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.549900",
    Latitude: "-33.253300"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "EGLINTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.560200",
    Latitude: "-33.358700"
  },
  {
    Country: "Australia",
    Pcode: "2810",
    Locality: "PINEY RANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.935800",
    Latitude: "-33.868100"
  },
  {
    Country: "Australia",
    Pcode: "2810",
    Locality: "PINNACLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.948300",
    Latitude: "-33.740600"
  },
  {
    Country: "Australia",
    Pcode: "2810",
    Locality: "PULLABOOKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.764500",
    Latitude: "-33.755000"
  },
  {
    Country: "Australia",
    Pcode: "2810",
    Locality: "WARRADERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.242500",
    Latitude: "-33.717900"
  },
  {
    Country: "Australia",
    Pcode: "2817",
    Locality: "TONDERBURINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.762900",
    Latitude: "-31.330600"
  },
  {
    Country: "Australia",
    Pcode: "2817",
    Locality: "TOORAWEENAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.979300",
    Latitude: "-31.494100"
  },
  {
    Country: "Australia",
    Pcode: "2818",
    Locality: "BENOLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.668600",
    Latitude: "-32.500900"
  },
  {
    Country: "Australia",
    Pcode: "2818",
    Locality: "GEURIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.858100",
    Latitude: "-32.368000"
  },
  {
    Country: "Australia",
    Pcode: "2818",
    Locality: "PONTO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.788100",
    Latitude: "-32.481500"
  },
  {
    Country: "Australia",
    Pcode: "2818",
    Locality: "TERRABELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.731500",
    Latitude: "-32.443900"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "APSLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.969400",
    Latitude: "-32.612100"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "ARTHURVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.738100",
    Latitude: "-32.551100"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "BAKERS SWAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.915600",
    Latitude: "-32.779800"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "BODANGORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.057000",
    Latitude: "-32.432200"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "COMOBELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.969500",
    Latitude: "-32.379400"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "CURRA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.831800",
    Latitude: "-32.673300"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "DRIPSTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.012300",
    Latitude: "-32.635900"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "FARNHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.109600",
    Latitude: "-32.841600"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "GOLLAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.048700",
    Latitude: "-32.256200"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "LAKE BURRENDONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.134300",
    Latitude: "-32.665200"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "MARYVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.885400",
    Latitude: "-32.451400"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "MEDWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WELLINGTON",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "MONTEFIORES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.936400",
    Latitude: "-32.528600"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "MOOKERAWA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.160200",
    Latitude: "-32.785600"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "MOUNT AQUILA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.173100",
    Latitude: "-32.838100"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "MOUNT ARTHUR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.896000",
    Latitude: "-32.551900"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "MUMBIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.063200",
    Latitude: "-32.703700"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "NEUREA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.931900",
    Latitude: "-32.677600"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "SPICERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.125200",
    Latitude: "-32.369000"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "STUART TOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.049600",
    Latitude: "-32.839100"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "SUNTOP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.839300",
    Latitude: "-32.559900"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "WALMER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.752700",
    Latitude: "-32.651800"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "WELLINGTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.959200",
    Latitude: "-32.566100"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "WUULUMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.088700",
    Latitude: "-32.534800"
  },
  {
    Country: "Australia",
    Pcode: "2820",
    Locality: "YARRAGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.100100",
    Latitude: "-32.610000"
  },
  {
    Country: "Australia",
    Pcode: "2821",
    Locality: "BURROWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.334800",
    Latitude: "-32.051800"
  },
  {
    Country: "Australia",
    Pcode: "2821",
    Locality: "NARROMINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.110300",
    Latitude: "-32.260200"
  },
  {
    Country: "Australia",
    Pcode: "2822",
    Locality: "BALLADORAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.558000",
    Latitude: "-31.894800"
  },
  {
    Country: "Australia",
    Pcode: "2822",
    Locality: "EUMUNGERIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.630100",
    Latitude: "-31.984900"
  },
  {
    Country: "Australia",
    Pcode: "2823",
    Locality: "BUNDEMAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.227700",
    Latitude: "-31.805400"
  },
  {
    Country: "Australia",
    Pcode: "2823",
    Locality: "CATHUNDRAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.748100",
    Latitude: "-31.963500"
  },
  {
    Country: "Australia",
    Pcode: "2823",
    Locality: "DANDALOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.664700",
    Latitude: "-32.231600"
  },
  {
    Country: "Australia",
    Pcode: "2823",
    Locality: "GIN GIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.132200",
    Latitude: "-31.911100"
  },
  {
    Country: "Australia",
    Pcode: "2823",
    Locality: "TRANGIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.903700",
    Latitude: "-32.065900"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "BEEMUNNEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.850700",
    Latitude: "-31.672000"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "BULLAGREEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.048600",
    Latitude: "-31.331300"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "EENAWEENA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.580100",
    Latitude: "-31.601400"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "MARTHAGUY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.869800",
    Latitude: "-31.241000"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "MOUNT FOSTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.570800",
    Latitude: "-31.184800"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "MOUNT HARRIS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.655400",
    Latitude: "-31.277800"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "MUMBLEBONE PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.631400",
    Latitude: "-31.475400"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "OXLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WARREN",
    Category: "Delivery Area",
    Longitude: "147.618400",
    Latitude: "-30.995900"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "PIGEONBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.080000",
    Latitude: "-31.531400"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "PINE CLUMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.144400",
    Latitude: "-31.426300"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "RAVENSWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.846500",
    Latitude: "-31.698100"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "RED HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WARREN",
    Category: "Delivery Area",
    Longitude: "147.806400",
    Latitude: "-31.738600"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "SNAKES PLAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.766000",
    Latitude: "-31.773600"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "TENANDRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.852100",
    Latitude: "-31.557200"
  },
  {
    Country: "Australia",
    Pcode: "2824",
    Locality: "WARREN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.855700",
    Latitude: "-31.769400"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "BABINDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.521800",
    Latitude: "-31.945200"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "BUDDABADAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.184900",
    Latitude: "-31.942300"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "CANONBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.439900",
    Latitude: "-31.313600"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "HONEYBUGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.858100",
    Latitude: "-31.837300"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "MIANDETTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.911300",
    Latitude: "-31.588700"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "MULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.304000",
    Latitude: "-31.819000"
  },
  {
    Country: "Australia",
    Pcode: "2825",
    Locality: "MULLENGUDGERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.489100",
    Latitude: "-31.709900"
  },
  {
    Country: "Australia",
    Pcode: "2904",
    Locality: "MONASH",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.088900",
    Latitude: "-35.415100"
  },
  {
    Country: "Australia",
    Pcode: "2905",
    Locality: "BONYTHON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.077700",
    Latitude: "-35.434400"
  },
  {
    Country: "Australia",
    Pcode: "2905",
    Locality: "CALWELL",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.106500",
    Latitude: "-35.442800"
  },
  {
    Country: "Australia",
    Pcode: "2905",
    Locality: "CHISHOLM",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.122800",
    Latitude: "-35.421800"
  },
  {
    Country: "Australia",
    Pcode: "2905",
    Locality: "GILMORE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.136500",
    Latitude: "-35.415800"
  },
  {
    Country: "Australia",
    Pcode: "2905",
    Locality: "ISABELLA PLAINS",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.095000",
    Latitude: "-35.429200"
  },
  {
    Country: "Australia",
    Pcode: "2905",
    Locality: "RICHARDSON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.108500",
    Latitude: "-35.426300"
  },
  {
    Country: "Australia",
    Pcode: "2905",
    Locality: "THEODORE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.119600",
    Latitude: "-35.448700"
  },
  {
    Country: "Australia",
    Pcode: "2906",
    Locality: "BANKS",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.100600",
    Latitude: "-35.471800"
  },
  {
    Country: "Australia",
    Pcode: "2906",
    Locality: "CONDER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.100900",
    Latitude: "-35.457600"
  },
  {
    Country: "Australia",
    Pcode: "2906",
    Locality: "GORDON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.082900",
    Latitude: "-35.456700"
  },
  {
    Country: "Australia",
    Pcode: "2911",
    Locality: "CRACE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.107100",
    Latitude: "-35.203200"
  },
  {
    Country: "Australia",
    Pcode: "2911",
    Locality: "MITCHELL",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.134900",
    Latitude: "-35.217800"
  },
  {
    Country: "Australia",
    Pcode: "2912",
    Locality: "GUNGAHLIN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.137600",
    Latitude: "-35.186000"
  },
  {
    Country: "Australia",
    Pcode: "2913",
    Locality: "CASEY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.094300",
    Latitude: "-35.166500"
  },
  {
    Country: "Australia",
    Pcode: "2913",
    Locality: "FRANKLIN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.143400",
    Latitude: "-35.197800"
  },
  {
    Country: "Australia",
    Pcode: "2913",
    Locality: "NGUNNAWAL",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.113300",
    Latitude: "-35.172700"
  },
  {
    Country: "Australia",
    Pcode: "2913",
    Locality: "NICHOLLS",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.097300",
    Latitude: "-35.188400"
  },
  {
    Country: "Australia",
    Pcode: "2913",
    Locality: "PALMERSTON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.119400",
    Latitude: "-35.193200"
  },
  {
    Country: "Australia",
    Pcode: "2913",
    Locality: "TAYLOR",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.107800",
    Latitude: "-35.146500"
  },
  {
    Country: "Australia",
    Pcode: "2914",
    Locality: "AMAROO",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.127400",
    Latitude: "-35.168800"
  },
  {
    Country: "Australia",
    Pcode: "2914",
    Locality: "BONNER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.142200",
    Latitude: "-35.157200"
  },
  {
    Country: "Australia",
    Pcode: "2914",
    Locality: "FORDE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.144100",
    Latitude: "-35.167000"
  },
  {
    Country: "Australia",
    Pcode: "2914",
    Locality: "HARRISON",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.156400",
    Latitude: "-35.197400"
  },
  {
    Country: "Australia",
    Pcode: "2914",
    Locality: "JACKA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.130700",
    Latitude: "-35.153300"
  },
  {
    Country: "Australia",
    Pcode: "2914",
    Locality: "MONCRIEFF",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.115000",
    Latitude: "-35.160100"
  },
  {
    Country: "Australia",
    Pcode: "2914",
    Locality: "THROSBY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.164200",
    Latitude: "-35.190500"
  },
  {
    Country: "Australia",
    Pcode: "3000",
    Locality: "MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.964600",
    Latitude: "-37.811200"
  },
  {
    Country: "Australia",
    Pcode: "3001",
    Locality: "MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "GPO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3002",
    Locality: "EAST MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.982500",
    Latitude: "-37.815400"
  },
  {
    Country: "Australia",
    Pcode: "3003",
    Locality: "WEST MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.920400",
    Latitude: "-37.810500"
  },
  {
    Country: "Australia",
    Pcode: "3004",
    Locality: "MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "ST KILDA RD BUSINESS DISTRICT",
    Category: "Delivery Area",
    Longitude: "144.978300",
    Latitude: "-37.826800"
  },
  {
    Country: "Australia",
    Pcode: "3004",
    Locality: "ST KILDA ROAD CENTRAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3004",
    Locality: "ST KILDA ROAD MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "CARE PO ONLY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3006",
    Locality: "SOUTH WHARF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.952300",
    Latitude: "-37.825300"
  },
  {
    Country: "Australia",
    Pcode: "3006",
    Locality: "SOUTHBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.963600",
    Latitude: "-37.825300"
  },
  {
    Country: "Australia",
    Pcode: "3008",
    Locality: "DOCKLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.938500",
    Latitude: "-37.818100"
  },
  {
    Country: "Australia",
    Pcode: "3010",
    Locality: "UNIVERSITY OF MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3011",
    Locality: "FOOTSCRAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.894700",
    Latitude: "-37.800800"
  },
  {
    Country: "Australia",
    Pcode: "3011",
    Locality: "SEDDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.891500",
    Latitude: "-37.806400"
  },
  {
    Country: "Australia",
    Pcode: "3011",
    Locality: "SEDDON WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3012",
    Locality: "BROOKLYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.841800",
    Latitude: "-37.814100"
  },
  {
    Country: "Australia",
    Pcode: "3012",
    Locality: "KINGSVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.880100",
    Latitude: "-37.807700"
  },
  {
    Country: "Australia",
    Pcode: "3012",
    Locality: "MAIDSTONE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.874800",
    Latitude: "-37.781600"
  },
  {
    Country: "Australia",
    Pcode: "3012",
    Locality: "TOTTENHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.854000",
    Latitude: "-37.805900"
  },
  {
    Country: "Australia",
    Pcode: "3012",
    Locality: "WEST FOOTSCRAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.871800",
    Latitude: "-37.801500"
  },
  {
    Country: "Australia",
    Pcode: "3013",
    Locality: "YARRAVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.882600",
    Latitude: "-37.818000"
  },
  {
    Country: "Australia",
    Pcode: "3013",
    Locality: "YARRAVILLE WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3015",
    Locality: "NEWPORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.879700",
    Latitude: "-37.845400"
  },
  {
    Country: "Australia",
    Pcode: "3015",
    Locality: "SOUTH KINGSVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.870300",
    Latitude: "-37.829500"
  },
  {
    Country: "Australia",
    Pcode: "3015",
    Locality: "SPOTSWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.886700",
    Latitude: "-37.830100"
  },
  {
    Country: "Australia",
    Pcode: "3016",
    Locality: "WILLIAMSTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.888400",
    Latitude: "-37.861100"
  },
  {
    Country: "Australia",
    Pcode: "3016",
    Locality: "WILLIAMSTOWN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.867700",
    Latitude: "-37.852800"
  },
  {
    Country: "Australia",
    Pcode: "3018",
    Locality: "ALTONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.815300",
    Latitude: "-37.863200"
  },
  {
    Country: "Australia",
    Pcode: "3018",
    Locality: "SEAHOLME",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.844400",
    Latitude: "-37.863100"
  },
  {
    Country: "Australia",
    Pcode: "3019",
    Locality: "BRAYBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.853800",
    Latitude: "-37.786200"
  },
  {
    Country: "Australia",
    Pcode: "3019",
    Locality: "ROBINSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3020",
    Locality: "ALBION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.816700",
    Latitude: "-37.775600"
  },
  {
    Country: "Australia",
    Pcode: "3020",
    Locality: "GLENGALA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3020",
    Locality: "SUNSHINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.831200",
    Latitude: "-37.787200"
  },
  {
    Country: "Australia",
    Pcode: "3020",
    Locality: "SUNSHINE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.836900",
    Latitude: "-37.762600"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "ROLLANDS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.652600",
    Latitude: "-31.287700"
  },
  {
    Country: "Australia",
    Pcode: "2441",
    Locality: "STUARTS POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.984700",
    Latitude: "-30.825500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CHAMBIGNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.738100",
    Latitude: "-29.770100"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CLARENZA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.000100",
    Latitude: "-29.699500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CLIFDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.947400",
    Latitude: "-29.512700"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "COALDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.805300",
    Latitude: "-29.399500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "COLLUM COLLUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.480400",
    Latitude: "-29.262000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "COOMBADJHA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.478400",
    Latitude: "-29.368200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "COPMANHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.797000",
    Latitude: "-29.576200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "COUTTS CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.898200",
    Latitude: "-29.856800"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "COWPER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.076100",
    Latitude: "-29.579500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "CROWTHER ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.920100",
    Latitude: "-29.626300"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "DALMORTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.473800",
    Latitude: "-29.783200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "DEEP CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BARYULGIL",
    Category: "Delivery Area",
    Longitude: "152.657300",
    Latitude: "-29.156600"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "DILKOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.976200",
    Latitude: "-29.495700"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "DUMBUDGERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.527900",
    Latitude: "-29.299100"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "EATONSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.819500",
    Latitude: "-29.639500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "EIGHTEEN MILE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.592900",
    Latitude: "-29.280500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "ELLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.899900",
    Latitude: "-29.780200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "FINE FLOWER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.676500",
    Latitude: "-29.381700"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "FORTIS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.869300",
    Latitude: "-29.476600"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "GLENUGIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.038200",
    Latitude: "-29.801500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "GRAFTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.937700",
    Latitude: "-29.680900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "GREAT MARLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.981100",
    Latitude: "-29.649700"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "GURRANANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.985300",
    Latitude: "-29.452800"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "HALFWAY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.100700",
    Latitude: "-29.940100"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "HEIFER STATION",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.590600",
    Latitude: "-29.456000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "JACKADGERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.570500",
    Latitude: "-29.613900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "JUNCTION HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.929200",
    Latitude: "-29.638100"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "KANGAROO CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.852800",
    Latitude: "-29.967500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "KOOLKHAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.931800",
    Latitude: "-29.619100"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "KREMNOS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.949000",
    Latitude: "-29.976900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "KUNGALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.028600",
    Latitude: "-29.952700"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "KYARRAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.979600",
    Latitude: "-29.588200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "LANITZA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.968200",
    Latitude: "-29.895200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "LAWRENCE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.094800",
    Latitude: "-29.442400"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "LEVENSTRATH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.930900",
    Latitude: "-29.828700"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "LILYDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.640500",
    Latitude: "-29.544200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "LIONSVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.503600",
    Latitude: "-29.183600"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "LOWER SOUTHGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.059100",
    Latitude: "-29.537800"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "MALABUGILMAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.628000",
    Latitude: "-29.179200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "MOLEVILLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.900800",
    Latitude: "-29.573000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "MOUNTAIN VIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.934100",
    Latitude: "-29.588000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "MYLNEFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.836700",
    Latitude: "-29.617200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "NEWBOLD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.695900",
    Latitude: "-29.519000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "NYMBOIDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.663700",
    Latitude: "-29.954800"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "PULGANBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.683500",
    Latitude: "-29.458200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "PUNCHBOWL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GRAFTON",
    Category: "Delivery Area",
    Longitude: "152.776400",
    Latitude: "-29.461000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "RAMORNIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.743200",
    Latitude: "-29.657000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "RUSHFORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.859800",
    Latitude: "-29.748700"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "SANDY CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.093900",
    Latitude: "-29.767900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "SEELANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.892200",
    Latitude: "-29.639900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "SHANNONDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.836100",
    Latitude: "-29.797400"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "SMITHS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GRAFTON",
    Category: "Delivery Area",
    Longitude: "152.726500",
    Latitude: "-29.504600"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "SOUTH ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GRAFTON",
    Category: "Delivery Area",
    Longitude: "153.169100",
    Latitude: "-29.524000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "SOUTH GRAFTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.934700",
    Latitude: "-29.722000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "SOUTHAMPTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.908200",
    Latitude: "-29.714500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "SOUTHGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.005200",
    Latitude: "-29.599500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "STOCKYARD CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.800200",
    Latitude: "-29.515000"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "THE PINNACLES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.920200",
    Latitude: "-29.545300"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "THE WHITEMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.876000",
    Latitude: "-29.600200"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "TOWALLUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.831100",
    Latitude: "-30.077100"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "TRENAYR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.959300",
    Latitude: "-29.613900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "TYNDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.159400",
    Latitude: "-29.565900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "UPPER COPMANHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.752400",
    Latitude: "-29.566900"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "UPPER FINE FLOWER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.740000",
    Latitude: "-29.276500"
  },
  {
    Country: "Australia",
    Pcode: "2460",
    Locality: "WARRAGAI CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.968600",
    Latitude: "-29.566800"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "WADEVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.135100",
    Latitude: "-28.560500"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "WARRAZAMBIL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.032700",
    Latitude: "-28.447900"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "WEST WIANGAREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.958500",
    Latitude: "-28.563200"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "WIANGAREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.976200",
    Latitude: "-28.509900"
  },
  {
    Country: "Australia",
    Pcode: "2474",
    Locality: "WYNEDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.922400",
    Latitude: "-28.530000"
  },
  {
    Country: "Australia",
    Pcode: "2475",
    Locality: "TOOLOOM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.452900",
    Latitude: "-28.595700"
  },
  {
    Country: "Australia",
    Pcode: "2475",
    Locality: "UPPER TOOLOOM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.374700",
    Latitude: "-28.586200"
  },
  {
    Country: "Australia",
    Pcode: "2475",
    Locality: "URBENVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.493500",
    Latitude: "-28.464300"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "ACACIA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.321500",
    Latitude: "-28.376400"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "BOOMI CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.596400",
    Latitude: "-28.485900"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "BRUMBY PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.656700",
    Latitude: "-28.463000"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "KOREELAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.426200",
    Latitude: "-28.375700"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "LEGUME",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.292400",
    Latitude: "-28.440100"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "LINDESAY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.636000",
    Latitude: "-28.347500"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "LOWER ACACIA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.241100",
    Latitude: "-28.444900"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "MULI MULI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.583400",
    Latitude: "-28.418100"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "THE GLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.659200",
    Latitude: "-28.405600"
  },
  {
    Country: "Australia",
    Pcode: "2476",
    Locality: "WOODENBONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.594600",
    Latitude: "-28.375800"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "ALSTONVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.443800",
    Latitude: "-28.794600"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "ALSTONVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.452800",
    Latitude: "-28.839700"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "BAGOTVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.414700",
    Latitude: "-28.976800"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "CABBAGE TREE ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.456600",
    Latitude: "-28.979700"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "DALWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BALLINA",
    Category: "Delivery Area",
    Longitude: "153.413900",
    Latitude: "-28.893800"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "EAST WARDELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.484800",
    Latitude: "-28.965300"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "GOAT ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.457200",
    Latitude: "-28.989700"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "LYNWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.442900",
    Latitude: "-28.870700"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "MEERSCHAUM VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.418700",
    Latitude: "-28.938300"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "PEARCES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.456700",
    Latitude: "-28.770900"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "ROUS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.408400",
    Latitude: "-28.863500"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "ROUS MILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.382200",
    Latitude: "-28.879700"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "TUCKOMBIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.467500",
    Latitude: "-28.812200"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "URALBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.472500",
    Latitude: "-28.873700"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "WARDELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.457700",
    Latitude: "-28.949700"
  },
  {
    Country: "Australia",
    Pcode: "2477",
    Locality: "WOLLONGBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.408700",
    Latitude: "-28.819400"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "BALLINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.557900",
    Latitude: "-28.844000"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "BALLINA DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "COOLGARDIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.462500",
    Latitude: "-28.916000"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "CUMBALUM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.532000",
    Latitude: "-28.821300"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "EAST BALLINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.589300",
    Latitude: "-28.860300"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "EMPIRE VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.517900",
    Latitude: "-28.922200"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "KEITH HALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.544100",
    Latitude: "-28.890500"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "KINVARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.547800",
    Latitude: "-28.793400"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "LENNOX HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.582500",
    Latitude: "-28.789100"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "PATCHS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.511100",
    Latitude: "-28.946900"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "PIMLICO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.499200",
    Latitude: "-28.894300"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "PIMLICO ISLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.493800",
    Latitude: "-28.917900"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "SKENNARS HEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.588600",
    Latitude: "-28.833000"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "SOUTH BALLINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.564600",
    Latitude: "-28.882100"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "TEVEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.491400",
    Latitude: "-28.819100"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "TINTENBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.510800",
    Latitude: "-28.790600"
  },
  {
    Country: "Australia",
    Pcode: "2478",
    Locality: "WEST BALLINA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.517800",
    Latitude: "-28.858500"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "BANGALOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.523900",
    Latitude: "-28.681300"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "BINNA BURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.490700",
    Latitude: "-28.712900"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "BROOKLET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.508400",
    Latitude: "-28.746400"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "COOPERS SHOOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.573200",
    Latitude: "-28.691500"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "COORABELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.487900",
    Latitude: "-28.627500"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "FERNLEIGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.488700",
    Latitude: "-28.769100"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "KNOCKROW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.542600",
    Latitude: "-28.760500"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "MCLEODS SHOOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.545000",
    Latitude: "-28.658900"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "NASHUA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.463600",
    Latitude: "-28.731000"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "NEWRYBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.549900",
    Latitude: "-28.727700"
  },
  {
    Country: "Australia",
    Pcode: "2479",
    Locality: "POSSUM CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.492800",
    Latitude: "-28.670200"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "BENTLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.095100",
    Latitude: "-28.724700"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "BEXHILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.354600",
    Latitude: "-28.755800"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "BLAKEBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.232300",
    Latitude: "-28.769400"
  },
  {
    Country: "Australia",
    Pcode: "2480",
    Locality: "BLUE KNOB",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.192700",
    Latitude: "-28.548500"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "NORTH WOLLONGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.894400",
    Latitude: "-34.407000"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "SPRING HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WOLLONGONG",
    Category: "Delivery Area",
    Longitude: "150.869500",
    Latitude: "-34.454100"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "WEST WOLLONGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.868400",
    Latitude: "-34.425700"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "WOLLONGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.890600",
    Latitude: "-34.428200"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "WOLLONGONG DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2500",
    Locality: "WOLLONGONG WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2502",
    Locality: "CRINGILA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.866300",
    Latitude: "-34.470900"
  },
  {
    Country: "Australia",
    Pcode: "2502",
    Locality: "LAKE HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.866400",
    Latitude: "-34.483400"
  },
  {
    Country: "Australia",
    Pcode: "2502",
    Locality: "PRIMBEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.884500",
    Latitude: "-34.507400"
  },
  {
    Country: "Australia",
    Pcode: "2502",
    Locality: "WARRAWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.882700",
    Latitude: "-34.487700"
  },
  {
    Country: "Australia",
    Pcode: "2505",
    Locality: "PORT KEMBLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.897800",
    Latitude: "-34.471500"
  },
  {
    Country: "Australia",
    Pcode: "2506",
    Locality: "BERKELEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.842900",
    Latitude: "-34.481500"
  },
  {
    Country: "Australia",
    Pcode: "2508",
    Locality: "COALCLIFF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.970400",
    Latitude: "-34.245000"
  },
  {
    Country: "Australia",
    Pcode: "2508",
    Locality: "DARKES FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.924200",
    Latitude: "-34.216200"
  },
  {
    Country: "Australia",
    Pcode: "2508",
    Locality: "HELENSBURGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.983000",
    Latitude: "-34.184300"
  },
  {
    Country: "Australia",
    Pcode: "2508",
    Locality: "LILYVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.034900",
    Latitude: "-34.175000"
  },
  {
    Country: "Australia",
    Pcode: "2508",
    Locality: "MADDENS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.939400",
    Latitude: "-34.262500"
  },
  {
    Country: "Australia",
    Pcode: "2508",
    Locality: "OTFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.003500",
    Latitude: "-34.207300"
  },
  {
    Country: "Australia",
    Pcode: "2508",
    Locality: "STANWELL PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.979000",
    Latitude: "-34.229100"
  },
  {
    Country: "Australia",
    Pcode: "2508",
    Locality: "STANWELL TOPS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.976300",
    Latitude: "-34.219600"
  },
  {
    Country: "Australia",
    Pcode: "2508",
    Locality: "WORONORA DAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.939100",
    Latitude: "-34.164300"
  },
  {
    Country: "Australia",
    Pcode: "2515",
    Locality: "AUSTINMER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.927800",
    Latitude: "-34.301900"
  },
  {
    Country: "Australia",
    Pcode: "2515",
    Locality: "CLIFTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.968700",
    Latitude: "-34.258200"
  },
  {
    Country: "Australia",
    Pcode: "2515",
    Locality: "COLEDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.941600",
    Latitude: "-34.288900"
  },
  {
    Country: "Australia",
    Pcode: "2515",
    Locality: "SCARBOROUGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.959000",
    Latitude: "-34.267600"
  },
  {
    Country: "Australia",
    Pcode: "2515",
    Locality: "THIRROUL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.913400",
    Latitude: "-34.314100"
  },
  {
    Country: "Australia",
    Pcode: "2515",
    Locality: "WOMBARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.950100",
    Latitude: "-34.276500"
  },
  {
    Country: "Australia",
    Pcode: "2516",
    Locality: "BULLI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.900700",
    Latitude: "-34.326800"
  },
  {
    Country: "Australia",
    Pcode: "2517",
    Locality: "RUSSELL VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.891100",
    Latitude: "-34.354400"
  },
  {
    Country: "Australia",
    Pcode: "2517",
    Locality: "WOONONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.903500",
    Latitude: "-34.349600"
  },
  {
    Country: "Australia",
    Pcode: "2517",
    Locality: "WOONONA EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2518",
    Locality: "BELLAMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.919400",
    Latitude: "-34.369300"
  },
  {
    Country: "Australia",
    Pcode: "2518",
    Locality: "CORRIMAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.888600",
    Latitude: "-34.369900"
  },
  {
    Country: "Australia",
    Pcode: "2518",
    Locality: "CORRIMAL EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2518",
    Locality: "EAST CORRIMAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.912200",
    Latitude: "-34.377400"
  },
  {
    Country: "Australia",
    Pcode: "2518",
    Locality: "TARRAWANNA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.876300",
    Latitude: "-34.376500"
  },
  {
    Country: "Australia",
    Pcode: "2518",
    Locality: "TOWRADGI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.905500",
    Latitude: "-34.384100"
  },
  {
    Country: "Australia",
    Pcode: "2519",
    Locality: "BALGOWNIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.871700",
    Latitude: "-34.386600"
  },
  {
    Country: "Australia",
    Pcode: "2519",
    Locality: "FAIRY MEADOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.889800",
    Latitude: "-34.396700"
  },
  {
    Country: "Australia",
    Pcode: "2519",
    Locality: "FERNHILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.890800",
    Latitude: "-34.384500"
  },
  {
    Country: "Australia",
    Pcode: "2519",
    Locality: "MOUNT OUSLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.878700",
    Latitude: "-34.400600"
  },
  {
    Country: "Australia",
    Pcode: "2519",
    Locality: "MOUNT PLEASANT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.868700",
    Latitude: "-34.395500"
  },
  {
    Country: "Australia",
    Pcode: "2520",
    Locality: "WOLLONGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2525",
    Locality: "FIGTREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.850100",
    Latitude: "-34.431100"
  },
  {
    Country: "Australia",
    Pcode: "2526",
    Locality: "CORDEAUX",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.776800",
    Latitude: "-34.386900"
  },
  {
    Country: "Australia",
    Pcode: "2526",
    Locality: "CORDEAUX HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.828400",
    Latitude: "-34.445000"
  },
  {
    Country: "Australia",
    Pcode: "2526",
    Locality: "FARMBOROUGH HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.814600",
    Latitude: "-34.456000"
  },
  {
    Country: "Australia",
    Pcode: "2526",
    Locality: "KEMBLA GRANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.800000",
    Latitude: "-34.462700"
  },
  {
    Country: "Australia",
    Pcode: "2526",
    Locality: "KEMBLA HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.810500",
    Latitude: "-34.426200"
  },
  {
    Country: "Australia",
    Pcode: "2526",
    Locality: "MOUNT KEMBLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.825100",
    Latitude: "-34.428900"
  },
  {
    Country: "Australia",
    Pcode: "2526",
    Locality: "UNANDERRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.846800",
    Latitude: "-34.458200"
  },
  {
    Country: "Australia",
    Pcode: "2527",
    Locality: "ALBION PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.776000",
    Latitude: "-34.582300"
  },
  {
    Country: "Australia",
    Pcode: "2527",
    Locality: "ALBION PARK RAIL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.790600",
    Latitude: "-34.561600"
  },
  {
    Country: "Australia",
    Pcode: "2527",
    Locality: "CALDERWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.725200",
    Latitude: "-34.560600"
  },
  {
    Country: "Australia",
    Pcode: "2527",
    Locality: "CROOM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.819600",
    Latitude: "-34.595100"
  },
  {
    Country: "Australia",
    Pcode: "2527",
    Locality: "NORTH MACQUARIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2527",
    Locality: "TONGARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.693900",
    Latitude: "-34.583600"
  },
  {
    Country: "Australia",
    Pcode: "2527",
    Locality: "TULLIMBAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.736000",
    Latitude: "-34.582300"
  },
  {
    Country: "Australia",
    Pcode: "2527",
    Locality: "YELLOW ROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ALBION PARK",
    Category: "Delivery Area",
    Longitude: "150.743000",
    Latitude: "-34.601700"
  },
  {
    Country: "Australia",
    Pcode: "2528",
    Locality: "BARRACK HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.854500",
    Latitude: "-34.563000"
  },
  {
    Country: "Australia",
    Pcode: "2528",
    Locality: "BARRACK POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.869500",
    Latitude: "-34.563300"
  },
  {
    Country: "Australia",
    Pcode: "2528",
    Locality: "LAKE ILLAWARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.860100",
    Latitude: "-34.539900"
  },
  {
    Country: "Australia",
    Pcode: "2528",
    Locality: "MOUNT WARRIGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.842200",
    Latitude: "-34.550000"
  },
  {
    Country: "Australia",
    Pcode: "2528",
    Locality: "WARILLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.859400",
    Latitude: "-34.551600"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "WORROWING HEIGHTS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.645400",
    Latitude: "-35.068400"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "WRECK BAY",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "WRIGHTS BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.667000",
    Latitude: "-35.115300"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "YALWAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.363400",
    Latitude: "-34.942800"
  },
  {
    Country: "Australia",
    Pcode: "2540",
    Locality: "YERRIYONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.485200",
    Latitude: "-34.992700"
  },
  {
    Country: "Australia",
    Pcode: "2541",
    Locality: "BANGALEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.552200",
    Latitude: "-34.841900"
  },
  {
    Country: "Australia",
    Pcode: "2541",
    Locality: "BOMADERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.604400",
    Latitude: "-34.847800"
  },
  {
    Country: "Australia",
    Pcode: "2541",
    Locality: "NORTH NOWRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.573200",
    Latitude: "-34.855600"
  },
  {
    Country: "Australia",
    Pcode: "2541",
    Locality: "NOWRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.606000",
    Latitude: "-34.880600"
  },
  {
    Country: "Australia",
    Pcode: "2541",
    Locality: "NOWRA DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2541",
    Locality: "NOWRA EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2541",
    Locality: "SOUTH NOWRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.596400",
    Latitude: "-34.910300"
  },
  {
    Country: "Australia",
    Pcode: "2541",
    Locality: "WEST NOWRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.583200",
    Latitude: "-34.882300"
  },
  {
    Country: "Australia",
    Pcode: "2545",
    Locality: "BELOWRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.673600",
    Latitude: "-36.107200"
  },
  {
    Country: "Australia",
    Pcode: "2545",
    Locality: "BODALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.009600",
    Latitude: "-36.096900"
  },
  {
    Country: "Australia",
    Pcode: "2545",
    Locality: "CADGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.899700",
    Latitude: "-36.158100"
  },
  {
    Country: "Australia",
    Pcode: "2545",
    Locality: "EUROBODALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.954000",
    Latitude: "-36.165800"
  },
  {
    Country: "Australia",
    Pcode: "2545",
    Locality: "NERRIGUNDAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.848600",
    Latitude: "-36.128000"
  },
  {
    Country: "Australia",
    Pcode: "2545",
    Locality: "POTATO POINT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.121800",
    Latitude: "-36.086200"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "AKOLELE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.078900",
    Latitude: "-36.349500"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "BARRAGGA BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.052500",
    Latitude: "-36.503600"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "BERMAGUI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.043200",
    Latitude: "-36.426900"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "CENTRAL TILBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.075400",
    Latitude: "-36.304500"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "CORUNNA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.106400",
    Latitude: "-36.280700"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "CUTTAGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.038100",
    Latitude: "-36.487200"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "DALMENY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.110800",
    Latitude: "-36.164200"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "DIGNAMS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.970000",
    Latitude: "-36.302500"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "KIANGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.083500",
    Latitude: "-36.190000"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "MURRAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.986400",
    Latitude: "-36.517600"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "MYSTERY BAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.126300",
    Latitude: "-36.309800"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "NAROOMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.059500",
    Latitude: "-36.242300"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "NORTH NAROOMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.104700",
    Latitude: "-36.209200"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "TILBA TILBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.047000",
    Latitude: "-36.334900"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "TINPOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.899300",
    Latitude: "-36.244700"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "WADBILLIGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.623200",
    Latitude: "-36.249500"
  },
  {
    Country: "Australia",
    Pcode: "2546",
    Locality: "WALLAGA LAKE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.064800",
    Latitude: "-36.369900"
  },
  {
    Country: "Australia",
    Pcode: "2548",
    Locality: "BERRAMBOOL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.910200",
    Latitude: "-36.880800"
  },
  {
    Country: "Australia",
    Pcode: "2548",
    Locality: "BOURNDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.889400",
    Latitude: "-36.824600"
  },
  {
    Country: "Australia",
    Pcode: "2548",
    Locality: "MERIMBULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.904200",
    Latitude: "-36.897800"
  },
  {
    Country: "Australia",
    Pcode: "2548",
    Locality: "MIRADOR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.922000",
    Latitude: "-36.876700"
  },
  {
    Country: "Australia",
    Pcode: "2548",
    Locality: "TURA BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.925300",
    Latitude: "-36.857700"
  },
  {
    Country: "Australia",
    Pcode: "2548",
    Locality: "YELLOW PINCH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.835200",
    Latitude: "-36.858200"
  },
  {
    Country: "Australia",
    Pcode: "2549",
    Locality: "BALD HILLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.843000",
    Latitude: "-36.910300"
  },
  {
    Country: "Australia",
    Pcode: "2549",
    Locality: "BROADWATER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MERIMBULA",
    Category: "Delivery Area",
    Longitude: "149.895000",
    Latitude: "-36.972000"
  },
  {
    Country: "Australia",
    Pcode: "2549",
    Locality: "GREIGS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.850200",
    Latitude: "-36.967400"
  },
  {
    Country: "Australia",
    Pcode: "2549",
    Locality: "LOCHIEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.770900",
    Latitude: "-36.946600"
  },
  {
    Country: "Australia",
    Pcode: "2549",
    Locality: "MILLINGANDI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.851400",
    Latitude: "-36.885200"
  },
  {
    Country: "Australia",
    Pcode: "2549",
    Locality: "NETHERCOTE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.793500",
    Latitude: "-37.025700"
  },
  {
    Country: "Australia",
    Pcode: "2549",
    Locality: "PAMBULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.875800",
    Latitude: "-36.931700"
  },
  {
    Country: "Australia",
    Pcode: "2549",
    Locality: "PAMBULA BEACH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.899400",
    Latitude: "-36.940700"
  },
  {
    Country: "Australia",
    Pcode: "2549",
    Locality: "SOUTH PAMBULA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.847700",
    Latitude: "-36.947000"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "ANGLEDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.869600",
    Latitude: "-36.633500"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "BEGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.842400",
    Latitude: "-36.684900"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "BEMBOKA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.531200",
    Latitude: "-36.615700"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "BLACK RANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.843400",
    Latitude: "-36.734000"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "BROGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.715600",
    Latitude: "-36.476200"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "BUCKAJO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.754800",
    Latitude: "-36.691800"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "BURRAGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.641000",
    Latitude: "-37.002300"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "CANDELO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.660700",
    Latitude: "-36.783600"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "CHINNOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.938900",
    Latitude: "-36.695400"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "COBARGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.869800",
    Latitude: "-36.394700"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "COOLAGOLITE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.971300",
    Latitude: "-36.439500"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "COOLANGUBRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.455500",
    Latitude: "-37.002400"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "COOPERS GULLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.797300",
    Latitude: "-36.650900"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "DEVILS HOLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.649400",
    Latitude: "-36.873000"
  },
  {
    Country: "Australia",
    Pcode: "2550",
    Locality: "DOCTOR GEORGE MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.897100",
    Latitude: "-36.666500"
  },
  {
    Country: "Australia",
    Pcode: "2579",
    Locality: "EXETER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.281500",
    Latitude: "-34.615900"
  },
  {
    Country: "Australia",
    Pcode: "2579",
    Locality: "MARULAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.989100",
    Latitude: "-34.741400"
  },
  {
    Country: "Australia",
    Pcode: "2579",
    Locality: "PENROSE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WINGELLO",
    Category: "Delivery Area",
    Longitude: "150.210700",
    Latitude: "-34.658500"
  },
  {
    Country: "Australia",
    Pcode: "2579",
    Locality: "TALLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.119700",
    Latitude: "-34.752500"
  },
  {
    Country: "Australia",
    Pcode: "2579",
    Locality: "WINGELLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.166000",
    Latitude: "-34.703200"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "BANNABY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.000300",
    Latitude: "-34.408300"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "BANNISTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.488300",
    Latitude: "-34.612300"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "BAW BAW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.645500",
    Latitude: "-34.717200"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "BOXERS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.821300",
    Latitude: "-34.771000"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "BRISBANE GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.713800",
    Latitude: "-34.796100"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "BUNGONIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.958100",
    Latitude: "-34.878500"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "CARRICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.909400",
    Latitude: "-34.697300"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "CHATSBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.805000",
    Latitude: "-34.529500"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "CURRAWANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.539700",
    Latitude: "-34.976100"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "CURRAWEELA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.796900",
    Latitude: "-34.248800"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "GOLSPIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.675800",
    Latitude: "-34.259500"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "GOULBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.726800",
    Latitude: "-34.744600"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "GOULBURN DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "GOULBURN NORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "GREENWICH PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.916200",
    Latitude: "-34.597700"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "GUNDARY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.779700",
    Latitude: "-34.840000"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "JERRONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.860000",
    Latitude: "-34.155300"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "KINGSDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.683300",
    Latitude: "-34.671400"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "LAKE BATHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.685200",
    Latitude: "-35.016900"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "LOWER BORO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.767500",
    Latitude: "-35.136500"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "MAYFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GOULBURN",
    Category: "Delivery Area",
    Longitude: "149.788300",
    Latitude: "-35.230900"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "MIDDLE ARM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.727700",
    Latitude: "-34.597500"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "MOUNT FAIRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.605900",
    Latitude: "-35.182100"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "MUMMEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.562700",
    Latitude: "-34.647100"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "MYRTLEVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.817700",
    Latitude: "-34.472700"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "PALING YARDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GOULBURN",
    Category: "Delivery Area",
    Longitude: "149.740100",
    Latitude: "-34.115300"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "PARKESBOURNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.547300",
    Latitude: "-34.760200"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "POMEROY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.542800",
    Latitude: "-34.692300"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "QUIALIGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.772500",
    Latitude: "-34.935000"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "RICHLANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.791100",
    Latitude: "-34.341800"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "ROSLYN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.638200",
    Latitude: "-34.495600"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "RUN-O-WATERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.671100",
    Latitude: "-34.769100"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "STONEQUARRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.755800",
    Latitude: "-34.373600"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "TARAGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.579700",
    Latitude: "-35.107100"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "TARALGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.780200",
    Latitude: "-34.408000"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "TARLO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.821500",
    Latitude: "-34.623400"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "TIRRANNAVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.674600",
    Latitude: "-34.869700"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "TOWRANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.830500",
    Latitude: "-34.694100"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "WAYO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.616300",
    Latitude: "-34.630400"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "WIARBOROUGH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.951900",
    Latitude: "-34.233600"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "WINDELLAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.907600",
    Latitude: "-35.022900"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "WOMBEYAN CAVES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.942100",
    Latitude: "-34.301700"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "WOODHOUSELEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.638100",
    Latitude: "-34.573100"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "YALBRAITH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.740900",
    Latitude: "-34.289900"
  },
  {
    Country: "Australia",
    Pcode: "2580",
    Locality: "YARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.608400",
    Latitude: "-34.796100"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "BELLMOUNT FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.266900",
    Latitude: "-34.897500"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "BEVENDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.084500",
    Latitude: "-34.502500"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "BIALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.263500",
    Latitude: "-34.594000"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "BLAKNEY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.036500",
    Latitude: "-34.638700"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "BREADALBANE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.445200",
    Latitude: "-34.800300"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "BROADWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.076100",
    Latitude: "-34.714800"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "COLLECTOR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.421200",
    Latitude: "-34.940500"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "CULLERIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.355100",
    Latitude: "-34.784900"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "DALTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.183800",
    Latitude: "-34.717100"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "GUNNING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.264700",
    Latitude: "-34.788100"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "GURRUNDAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.380900",
    Latitude: "-34.652600"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "LADE VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.154700",
    Latitude: "-34.839000"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "LAKE GEORGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.387200",
    Latitude: "-35.098900"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "LERIDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.333400",
    Latitude: "-34.890500"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "MERRILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.245800",
    Latitude: "-34.692600"
  },
  {
    Country: "Australia",
    Pcode: "2581",
    Locality: "OOLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.182800",
    Latitude: "-34.793200"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "HUME",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.168000",
    Latitude: "-35.380600"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "KARABAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.235000",
    Latitude: "-35.375100"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "KOWEN",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "MICHELAGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.216800",
    Latitude: "-35.753600"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "OAKS ESTATE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.227100",
    Latitude: "-35.340000"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "PADDYS RIVER",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "QUEANBEYAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.229500",
    Latitude: "-35.352100"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "QUEANBEYAN DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "QUEANBEYAN EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.245000",
    Latitude: "-35.346300"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "QUEANBEYAN WEST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.208200",
    Latitude: "-35.356500"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "ROYALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.171900",
    Latitude: "-35.490000"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "SUTTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.206000",
    Latitude: "-35.149600"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "THARWA",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.064300",
    Latitude: "-35.508900"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "THE ANGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.106900",
    Latitude: "-35.611100"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "THE RIDGEWAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.259800",
    Latitude: "-35.338700"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "TINDERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.303700",
    Latitude: "-35.687100"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "TRALEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.162000",
    Latitude: "-35.423000"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "URILA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.292100",
    Latitude: "-35.550500"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "WAMBOIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.324200",
    Latitude: "-35.254600"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "WILLIAMSDALE",
    State: "Australian Capital Territory",
    StateAbbrev: "ACT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.133800",
    Latitude: "-35.574600"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "WILLIAMSDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.148700",
    Latitude: "-35.587800"
  },
  {
    Country: "Australia",
    Pcode: "2620",
    Locality: "YARROW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.307300",
    Latitude: "-35.462100"
  },
  {
    Country: "Australia",
    Pcode: "2621",
    Locality: "ANEMBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.446700",
    Latitude: "-35.771100"
  },
  {
    Country: "Australia",
    Pcode: "2621",
    Locality: "BUNGENDORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.480500",
    Latitude: "-35.296000"
  },
  {
    Country: "Australia",
    Pcode: "2621",
    Locality: "BYWONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.326000",
    Latitude: "-35.173800"
  },
  {
    Country: "Australia",
    Pcode: "2621",
    Locality: "FORBES CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.501100",
    Latitude: "-35.424800"
  },
  {
    Country: "Australia",
    Pcode: "2621",
    Locality: "HOSKINSTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.434300",
    Latitude: "-35.408700"
  },
  {
    Country: "Australia",
    Pcode: "2621",
    Locality: "PRIMROSE VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.390000",
    Latitude: "-35.493900"
  },
  {
    Country: "Australia",
    Pcode: "2621",
    Locality: "ROSSI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.492100",
    Latitude: "-35.489500"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "ARALUEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.819300",
    Latitude: "-35.644200"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "BACK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BRAIDWOOD",
    Category: "Delivery Area",
    Longitude: "149.944100",
    Latitude: "-35.341000"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "BALLALABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.638900",
    Latitude: "-35.616300"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "BENDOURA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.678400",
    Latitude: "-35.542800"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "BERLANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.686100",
    Latitude: "-35.675400"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "BOMBAY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.646300",
    Latitude: "-35.438300"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "BORO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.701300",
    Latitude: "-35.208400"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "BRAIDWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.807600",
    Latitude: "-35.427400"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "BUDAWANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.051200",
    Latitude: "-35.389300"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "BULEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.141600",
    Latitude: "-35.023200"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "CHARLEYS FOREST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.002700",
    Latitude: "-35.360200"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "COOLUMBURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.107300",
    Latitude: "-35.046600"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "CORANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.128800",
    Latitude: "-35.216700"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "DURRAN DURRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.872900",
    Latitude: "-35.336800"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "ENDRICK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.247200",
    Latitude: "-35.238700"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "FARRINGDON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.598700",
    Latitude: "-35.519600"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "HAROLDS CROSS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.563800",
    Latitude: "-35.587600"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "HEREFORD HALL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.549600",
    Latitude: "-35.772500"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "JEMBAICUMBENE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.763000",
    Latitude: "-35.524600"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "JERRABATTGULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.581000",
    Latitude: "-35.685100"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "JINDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.587400",
    Latitude: "-35.875400"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "JINGERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.440200",
    Latitude: "-35.680400"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "KINDERVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.545000",
    Latitude: "-35.646000"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "KRAWARREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.609600",
    Latitude: "-35.751300"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "LARBERT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.772200",
    Latitude: "-35.315800"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "MAJORS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.739100",
    Latitude: "-35.592800"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "MANAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.684300",
    Latitude: "-35.268200"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "MARLOWE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.866800",
    Latitude: "-35.269000"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "MERRICUMBENE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.853300",
    Latitude: "-35.730900"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "MONGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.915100",
    Latitude: "-35.557400"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "MONGARLOWE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.935100",
    Latitude: "-35.411600"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "MULLOON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.608900",
    Latitude: "-35.309100"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "MURRENGENBURG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.975000",
    Latitude: "-35.538200"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "NERINGLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.783000",
    Latitude: "-35.732400"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "NERRIGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.044700",
    Latitude: "-35.083900"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "NORTHANGERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.911400",
    Latitude: "-35.468000"
  },
  {
    Country: "Australia",
    Pcode: "2622",
    Locality: "OALLEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.962300",
    Latitude: "-35.155800"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "PARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.121900",
    Latitude: "-33.667400"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "PINE CAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.243300",
    Latitude: "-33.669000"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "POMONA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.891200",
    Latitude: "-34.018900"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "POONCARIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.448600",
    Latitude: "-33.423200"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "RUFUS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.311400",
    Latitude: "-34.010900"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "SCOTIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.300700",
    Latitude: "-33.209600"
  },
  {
    Country: "Australia",
    Pcode: "2648",
    Locality: "WENTWORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.031400",
    Latitude: "-33.868700"
  },
  {
    Country: "Australia",
    Pcode: "2649",
    Locality: "LAUREL HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.090200",
    Latitude: "-35.604800"
  },
  {
    Country: "Australia",
    Pcode: "2649",
    Locality: "NURENMERENMONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.284600",
    Latitude: "-35.785300"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "ALFREDTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.550200",
    Latitude: "-35.154500"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "ASHMONT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.332000",
    Latitude: "-35.124500"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BELFRAYDEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.055100",
    Latitude: "-35.116700"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BIG SPRINGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.408000",
    Latitude: "-35.352400"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BOMEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.432300",
    Latitude: "-35.061100"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BOOK BOOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.553300",
    Latitude: "-35.340000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BOOROOMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.367600",
    Latitude: "-35.072200"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BORAMBOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.680700",
    Latitude: "-35.154000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BOURKELANDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.348000",
    Latitude: "-35.156200"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BRUCEDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.426100",
    Latitude: "-35.018300"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BULGARY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.950800",
    Latitude: "-35.025500"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "BURRANDANA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.355100",
    Latitude: "-35.410500"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "CARABOST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.719200",
    Latitude: "-35.610900"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "CARTWRIGHTS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.391100",
    Latitude: "-35.074900"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "COLLINGULLIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.091300",
    Latitude: "-35.093500"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "COOKARDINIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.250800",
    Latitude: "-35.569000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "CURRAWARNA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.058900",
    Latitude: "-34.964400"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "DHULURA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.297700",
    Latitude: "-35.015400"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "DOWNSIDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.349700",
    Latitude: "-34.988800"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "EAST WAGGA WAGGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.400000",
    Latitude: "-35.127800"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "ESTELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.350900",
    Latitude: "-35.075800"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "EUBERTA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.203200",
    Latitude: "-35.050400"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "EUNANOREENYA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.475800",
    Latitude: "-35.081100"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "GALORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.828800",
    Latitude: "-34.966400"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "GELSTON PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.350200",
    Latitude: "-35.247500"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "GLENFIELD PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.330500",
    Latitude: "-35.136900"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "GOBBAGOMBALIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.308600",
    Latitude: "-35.072600"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "GREGADOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.437800",
    Latitude: "-35.235800"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "HAREFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.536600",
    Latitude: "-34.969000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "HILLGROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WAGGA WAGGA",
    Category: "Delivery Area",
    Longitude: "147.357300",
    Latitude: "-35.045100"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "KOORINGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.374100",
    Latitude: "-35.143900"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "KYEAMBA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.568600",
    Latitude: "-35.457500"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "LAKE ALBERT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.391800",
    Latitude: "-35.167400"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "LLOYD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.323300",
    Latitude: "-35.147600"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "MAXWELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.301200",
    Latitude: "-35.285000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "MOORONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.315700",
    Latitude: "-35.113300"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "MOUNT AUSTIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.356400",
    Latitude: "-35.137000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "NORTH WAGGA WAGGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.391900",
    Latitude: "-35.097000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "OBERNE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.850800",
    Latitude: "-35.439000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "OURA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.597600",
    Latitude: "-35.086300"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "PULLETOP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.365100",
    Latitude: "-35.493300"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "ROWAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.314500",
    Latitude: "-35.212000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "SAN ISIDORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.289000",
    Latitude: "-35.125400"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "SPRINGVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "WAGGA WAGGA",
    Category: "Delivery Area",
    Longitude: "147.332800",
    Latitude: "-35.172600"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "TATTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.359100",
    Latitude: "-35.159300"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "THE GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.212200",
    Latitude: "-34.952300"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "TOLLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.351500",
    Latitude: "-35.145300"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "TURVEY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.360200",
    Latitude: "-35.127400"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "WAGGA WAGGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.360500",
    Latitude: "-35.105300"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "WAGGA WAGGA BC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "WALLACETOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.473900",
    Latitude: "-34.974800"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "WANTABADGERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.708800",
    Latitude: "-34.994400"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "YARRAGUNDRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.224900",
    Latitude: "-35.122200"
  },
  {
    Country: "Australia",
    Pcode: "2650",
    Locality: "YATHELLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.417600",
    Latitude: "-34.919200"
  },
  {
    Country: "Australia",
    Pcode: "2651",
    Locality: "FOREST HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.464200",
    Latitude: "-35.151800"
  },
  {
    Country: "Australia",
    Pcode: "2651",
    Locality: "WAGGA WAGGA RAAF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "RAAF BASE",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2652",
    Locality: "BOORGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.016900",
    Latitude: "-34.020900"
  },
  {
    Country: "Australia",
    Pcode: "2714",
    Locality: "TUPPAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.332000",
    Latitude: "-35.687400"
  },
  {
    Country: "Australia",
    Pcode: "2715",
    Locality: "ARUMPO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.022700",
    Latitude: "-33.494100"
  },
  {
    Country: "Australia",
    Pcode: "2715",
    Locality: "BALRANALD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.389200",
    Latitude: "-34.343200"
  },
  {
    Country: "Australia",
    Pcode: "2715",
    Locality: "HATFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.716800",
    Latitude: "-33.722400"
  },
  {
    Country: "Australia",
    Pcode: "2715",
    Locality: "KYALITE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.565600",
    Latitude: "-34.903700"
  },
  {
    Country: "Australia",
    Pcode: "2715",
    Locality: "MUNGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.083100",
    Latitude: "-33.752500"
  },
  {
    Country: "Australia",
    Pcode: "2716",
    Locality: "FOUR CORNERS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.427000",
    Latitude: "-34.844700"
  },
  {
    Country: "Australia",
    Pcode: "2716",
    Locality: "GALA VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.756100",
    Latitude: "-34.969900"
  },
  {
    Country: "Australia",
    Pcode: "2716",
    Locality: "JERILDERIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.774900",
    Latitude: "-35.302600"
  },
  {
    Country: "Australia",
    Pcode: "2716",
    Locality: "MABINS WELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.519300",
    Latitude: "-34.923000"
  },
  {
    Country: "Australia",
    Pcode: "2716",
    Locality: "MAIRJIMMY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.760500",
    Latitude: "-35.484900"
  },
  {
    Country: "Australia",
    Pcode: "2717",
    Locality: "COOMEALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.106500",
    Latitude: "-34.091300"
  },
  {
    Country: "Australia",
    Pcode: "2717",
    Locality: "DARETON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.036100",
    Latitude: "-34.097900"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "ARGALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.431900",
    Latitude: "-35.296900"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "BLOWERING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.255000",
    Latitude: "-35.519700"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "BOGONG PEAKS WILDERNESS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.382600",
    Latitude: "-35.521400"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "BOMBOWLEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.243100",
    Latitude: "-35.261700"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "BOMBOWLEE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.325900",
    Latitude: "-35.274000"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "BUDDONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.237500",
    Latitude: "-35.657000"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "COURAGAGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.496800",
    Latitude: "-35.113500"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "GADARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.128000",
    Latitude: "-35.308800"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "GILMORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.181800",
    Latitude: "-35.404000"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "GOCUP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.188000",
    Latitude: "-35.243000"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "GOOBARRAGANDRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.483200",
    Latitude: "-35.463800"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "JONES BRIDGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.256500",
    Latitude: "-35.375900"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "KILLIMICAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.247000",
    Latitude: "-35.211300"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "LACMALAC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.308900",
    Latitude: "-35.310800"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "LITTLE RIVER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.323500",
    Latitude: "-35.340600"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "MINJARY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.142300",
    Latitude: "-35.223900"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "MUNDONGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.284800",
    Latitude: "-35.290900"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "PINBEYAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.395700",
    Latitude: "-35.700400"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "RED HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TUMUT",
    Category: "Delivery Area",
    Longitude: "148.374200",
    Latitude: "-35.157700"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "TALBINGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.320400",
    Latitude: "-35.599200"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "TUMORRAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.484800",
    Latitude: "-35.197600"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "TUMUT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.235600",
    Latitude: "-35.322400"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "TUMUT PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.269000",
    Latitude: "-35.338300"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "WEREBOLDERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.222600",
    Latitude: "-35.391100"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "WERMATONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.306700",
    Latitude: "-35.377100"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "WINDOWIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.136700",
    Latitude: "-35.374600"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "WYANGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.339100",
    Latitude: "-35.223000"
  },
  {
    Country: "Australia",
    Pcode: "2720",
    Locality: "YARRANGOBILLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.485800",
    Latitude: "-35.681300"
  },
  {
    Country: "Australia",
    Pcode: "2721",
    Locality: "BLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.630400",
    Latitude: "-34.023800"
  },
  {
    Country: "Australia",
    Pcode: "2721",
    Locality: "QUANDIALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.791400",
    Latitude: "-34.019300"
  },
  {
    Country: "Australia",
    Pcode: "2722",
    Locality: "BRUNGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.225300",
    Latitude: "-35.149200"
  },
  {
    Country: "Australia",
    Pcode: "2722",
    Locality: "BRUNGLE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.290800",
    Latitude: "-35.157500"
  },
  {
    Country: "Australia",
    Pcode: "2722",
    Locality: "BURRA CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.994600",
    Latitude: "-34.895200"
  },
  {
    Country: "Australia",
    Pcode: "2722",
    Locality: "DARBALARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.258700",
    Latitude: "-35.063000"
  },
  {
    Country: "Australia",
    Pcode: "2722",
    Locality: "GUNDAGAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.116500",
    Latitude: "-35.044700"
  },
  {
    Country: "Australia",
    Pcode: "2722",
    Locality: "MUTTAMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.138900",
    Latitude: "-34.799100"
  },
  {
    Country: "Australia",
    Pcode: "2722",
    Locality: "NANGUS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.924600",
    Latitude: "-35.011000"
  },
  {
    Country: "Australia",
    Pcode: "2722",
    Locality: "RENO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "GUNDAGAI",
    Category: "Delivery Area",
    Longitude: "148.037400",
    Latitude: "-35.010900"
  },
  {
    Country: "Australia",
    Pcode: "2722",
    Locality: "SOUTH GUNDAGAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.130700",
    Latitude: "-35.104800"
  },
  {
    Country: "Australia",
    Pcode: "2725",
    Locality: "STOCKINBINGAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.871300",
    Latitude: "-34.450500"
  },
  {
    Country: "Australia",
    Pcode: "2726",
    Locality: "COONEYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.283700",
    Latitude: "-34.755800"
  },
  {
    Country: "Australia",
    Pcode: "2726",
    Locality: "JUGIONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.333600",
    Latitude: "-34.823700"
  },
  {
    Country: "Australia",
    Pcode: "2727",
    Locality: "ADJUNGBILLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.460100",
    Latitude: "-35.031300"
  },
  {
    Country: "Australia",
    Pcode: "2727",
    Locality: "COOLAC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.208300",
    Latitude: "-34.897200"
  },
  {
    Country: "Australia",
    Pcode: "2727",
    Locality: "GOBARRALONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.362700",
    Latitude: "-34.929000"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "ADELONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.069200",
    Latitude: "-35.304800"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "BANGADANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.983000",
    Latitude: "-35.273300"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "BLACK CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ADELONG",
    Category: "Delivery Area",
    Longitude: "148.035300",
    Latitude: "-35.322900"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "CALIFAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.099300",
    Latitude: "-35.243700"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "COOLEYS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.092100",
    Latitude: "-35.342000"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "DARLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.978100",
    Latitude: "-35.340500"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "ELLERSLIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.910000",
    Latitude: "-35.307600"
  },
  {
    Country: "Australia",
    Pcode: "2729",
    Locality: "GRAHAMSTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.034800",
    Latitude: "-35.257900"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "THE DEVILS WILDERNESS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.491100",
    Latitude: "-33.564400"
  },
  {
    Country: "Australia",
    Pcode: "2758",
    Locality: "WHEENY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.673500",
    Latitude: "-33.467000"
  },
  {
    Country: "Australia",
    Pcode: "2759",
    Locality: "ERSKINE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.800700",
    Latitude: "-33.809100"
  },
  {
    Country: "Australia",
    Pcode: "2759",
    Locality: "ST CLAIR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.786300",
    Latitude: "-33.797900"
  },
  {
    Country: "Australia",
    Pcode: "2760",
    Locality: "COLYTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.795900",
    Latitude: "-33.782200"
  },
  {
    Country: "Australia",
    Pcode: "2760",
    Locality: "NORTH ST MARYS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.786200",
    Latitude: "-33.754800"
  },
  {
    Country: "Australia",
    Pcode: "2760",
    Locality: "OXLEY PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.794800",
    Latitude: "-33.770600"
  },
  {
    Country: "Australia",
    Pcode: "2760",
    Locality: "ROPES CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.780200",
    Latitude: "-33.729300"
  },
  {
    Country: "Australia",
    Pcode: "2760",
    Locality: "ST MARYS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.771100",
    Latitude: "-33.759000"
  },
  {
    Country: "Australia",
    Pcode: "2760",
    Locality: "ST MARYS EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2760",
    Locality: "ST MARYS SOUTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2761",
    Locality: "COLEBEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.855900",
    Latitude: "-33.724600"
  },
  {
    Country: "Australia",
    Pcode: "2761",
    Locality: "DEAN PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.860100",
    Latitude: "-33.735600"
  },
  {
    Country: "Australia",
    Pcode: "2761",
    Locality: "GLENDENNING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.855900",
    Latitude: "-33.748900"
  },
  {
    Country: "Australia",
    Pcode: "2761",
    Locality: "HASSALL GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.838600",
    Latitude: "-33.731700"
  },
  {
    Country: "Australia",
    Pcode: "2761",
    Locality: "OAKHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.834200",
    Latitude: "-33.737200"
  },
  {
    Country: "Australia",
    Pcode: "2761",
    Locality: "PLUMPTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.836400",
    Latitude: "-33.752100"
  },
  {
    Country: "Australia",
    Pcode: "2762",
    Locality: "SCHOFIELDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.870400",
    Latitude: "-33.705300"
  },
  {
    Country: "Australia",
    Pcode: "2763",
    Locality: "ACACIA GARDENS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.914000",
    Latitude: "-33.734100"
  },
  {
    Country: "Australia",
    Pcode: "2763",
    Locality: "QUAKERS HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.892800",
    Latitude: "-33.728700"
  },
  {
    Country: "Australia",
    Pcode: "2765",
    Locality: "BERKSHIRE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.779100",
    Latitude: "-33.668400"
  },
  {
    Country: "Australia",
    Pcode: "2765",
    Locality: "BOX HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.899100",
    Latitude: "-33.642700"
  },
  {
    Country: "Australia",
    Pcode: "2765",
    Locality: "MARAYLYA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.915900",
    Latitude: "-33.596700"
  },
  {
    Country: "Australia",
    Pcode: "2765",
    Locality: "MARSDEN PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.815400",
    Latitude: "-33.699800"
  },
  {
    Country: "Australia",
    Pcode: "2765",
    Locality: "NELSON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "RIVERSTONE",
    Category: "Delivery Area",
    Longitude: "150.923300",
    Latitude: "-33.644000"
  },
  {
    Country: "Australia",
    Pcode: "2765",
    Locality: "OAKVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.871900",
    Latitude: "-33.624600"
  },
  {
    Country: "Australia",
    Pcode: "2765",
    Locality: "RIVERSTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.857300",
    Latitude: "-33.668800"
  },
  {
    Country: "Australia",
    Pcode: "2765",
    Locality: "VINEYARD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.848700",
    Latitude: "-33.641500"
  },
  {
    Country: "Australia",
    Pcode: "2766",
    Locality: "EASTERN CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.851600",
    Latitude: "-33.801600"
  },
  {
    Country: "Australia",
    Pcode: "2766",
    Locality: "ROOTY HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.843100",
    Latitude: "-33.772100"
  },
  {
    Country: "Australia",
    Pcode: "2767",
    Locality: "BUNGARRIBEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.863800",
    Latitude: "-33.779300"
  },
  {
    Country: "Australia",
    Pcode: "2767",
    Locality: "DOONSIDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.870400",
    Latitude: "-33.755800"
  },
  {
    Country: "Australia",
    Pcode: "2767",
    Locality: "WOODCROFT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.880700",
    Latitude: "-33.755900"
  },
  {
    Country: "Australia",
    Pcode: "2768",
    Locality: "GLENWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.931800",
    Latitude: "-33.736800"
  },
  {
    Country: "Australia",
    Pcode: "2768",
    Locality: "PARKLEA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.915800",
    Latitude: "-33.725200"
  },
  {
    Country: "Australia",
    Pcode: "2768",
    Locality: "STANHOPE GARDENS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.927200",
    Latitude: "-33.714800"
  },
  {
    Country: "Australia",
    Pcode: "2769",
    Locality: "THE PONDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.906200",
    Latitude: "-33.706300"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "BIDWILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.821800",
    Latitude: "-33.730000"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "BLACKETT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.814900",
    Latitude: "-33.738300"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "DHARRUK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.814800",
    Latitude: "-33.748000"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "EMERTON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.805800",
    Latitude: "-33.744100"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "HEBERSHAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.823900",
    Latitude: "-33.745900"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "LETHBRIDGE PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.800600",
    Latitude: "-33.738100"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "MINCHINBURY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.828900",
    Latitude: "-33.787200"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "MOUNT DRUITT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.814100",
    Latitude: "-33.768000"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "MOUNT DRUITT VILLAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "SHALVEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.806300",
    Latitude: "-33.727800"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "TREGEAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.792600",
    Latitude: "-33.745100"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "WHALAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.803400",
    Latitude: "-33.755700"
  },
  {
    Country: "Australia",
    Pcode: "2770",
    Locality: "WILLMOT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.794000",
    Latitude: "-33.724800"
  },
  {
    Country: "Australia",
    Pcode: "2773",
    Locality: "GLENBROOK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.627300",
    Latitude: "-33.765900"
  },
  {
    Country: "Australia",
    Pcode: "2773",
    Locality: "LAPSTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.636100",
    Latitude: "-33.774600"
  },
  {
    Country: "Australia",
    Pcode: "2774",
    Locality: "BLAXLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.617100",
    Latitude: "-33.746000"
  },
  {
    Country: "Australia",
    Pcode: "2774",
    Locality: "BLAXLAND EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2774",
    Locality: "MOUNT RIVERVIEW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.637800",
    Latitude: "-33.726800"
  },
  {
    Country: "Australia",
    Pcode: "2774",
    Locality: "WARRIMOO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.612900",
    Latitude: "-33.722900"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "CENTRAL MACDONALD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.971200",
    Latitude: "-33.335800"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "FERNANCES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.012200",
    Latitude: "-33.193800"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "GUNDERMAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.054400",
    Latitude: "-33.398200"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "HIGHER MACDONALD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.900400",
    Latitude: "-33.170300"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "LAUGHTONDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.034400",
    Latitude: "-33.447300"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "LEETS VALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.949100",
    Latitude: "-33.425500"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "LOWER MACDONALD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.969000",
    Latitude: "-33.359600"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "MARLOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.167400",
    Latitude: "-33.467700"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "MOGO CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.076100",
    Latitude: "-33.146300"
  },
  {
    Country: "Australia",
    Pcode: "2775",
    Locality: "PERRYS CROSSING",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.978100",
    Latitude: "-33.173800"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "YARRAS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BATHURST",
    Category: "Delivery Area",
    Longitude: "149.674000",
    Latitude: "-33.365800"
  },
  {
    Country: "Australia",
    Pcode: "2795",
    Locality: "YETHOLME",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.814100",
    Latitude: "-33.436100"
  },
  {
    Country: "Australia",
    Pcode: "2797",
    Locality: "GARLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.034700",
    Latitude: "-33.758800"
  },
  {
    Country: "Australia",
    Pcode: "2797",
    Locality: "LYNDHURST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BLAYNEY",
    Category: "Delivery Area",
    Longitude: "149.006300",
    Latitude: "-33.690900"
  },
  {
    Country: "Australia",
    Pcode: "2798",
    Locality: "FOREST REEFS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.072200",
    Latitude: "-33.465600"
  },
  {
    Country: "Australia",
    Pcode: "2798",
    Locality: "GUYONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.256600",
    Latitude: "-33.397300"
  },
  {
    Country: "Australia",
    Pcode: "2798",
    Locality: "MILLTHORPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.199500",
    Latitude: "-33.455500"
  },
  {
    Country: "Australia",
    Pcode: "2798",
    Locality: "SPRING TERRACE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.093800",
    Latitude: "-33.403700"
  },
  {
    Country: "Australia",
    Pcode: "2798",
    Locality: "TALLWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.131800",
    Latitude: "-33.503400"
  },
  {
    Country: "Australia",
    Pcode: "2799",
    Locality: "BARRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "BLAYNEY",
    Category: "Delivery Area",
    Longitude: "149.273400",
    Latitude: "-33.648800"
  },
  {
    Country: "Australia",
    Pcode: "2799",
    Locality: "BLAYNEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.254500",
    Latitude: "-33.537800"
  },
  {
    Country: "Australia",
    Pcode: "2799",
    Locality: "BROWNS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.155500",
    Latitude: "-33.536300"
  },
  {
    Country: "Australia",
    Pcode: "2799",
    Locality: "FITZGERALDS MOUNT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.377400",
    Latitude: "-33.496700"
  },
  {
    Country: "Australia",
    Pcode: "2799",
    Locality: "KINGS PLAINS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.324000",
    Latitude: "-33.490300"
  },
  {
    Country: "Australia",
    Pcode: "2799",
    Locality: "NEVILLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.203300",
    Latitude: "-33.721800"
  },
  {
    Country: "Australia",
    Pcode: "2799",
    Locality: "VITTORIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.350500",
    Latitude: "-33.417100"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "BELGRAVIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.029800",
    Latitude: "-33.107400"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "BOREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.835500",
    Latitude: "-33.250700"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "BORENORE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.953100",
    Latitude: "-33.230500"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "BYNG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.237600",
    Latitude: "-33.346000"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "CADIA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.009700",
    Latitude: "-33.443000"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "CANOBOLAS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.988400",
    Latitude: "-33.356800"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "CARGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.817300",
    Latitude: "-33.405900"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "CLERGATE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.108300",
    Latitude: "-33.187200"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "CLIFTON GROVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.169100",
    Latitude: "-33.240900"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "EMU SWAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.195700",
    Latitude: "-33.306400"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "FOUR MILE CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.941200",
    Latitude: "-33.426900"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "HUNTLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ORANGE",
    Category: "Delivery Area",
    Longitude: "149.128000",
    Latitude: "-33.369500"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "KANGAROOBIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.047500",
    Latitude: "-33.160900"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "KERRS CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.100500",
    Latitude: "-33.053000"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "LEWIS PONDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.277300",
    Latitude: "-33.268900"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "LIDSTER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.926200",
    Latitude: "-33.321400"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "LOWER LEWIS PONDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.273700",
    Latitude: "-33.199200"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "LUCKNOW",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.160300",
    Latitude: "-33.352100"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "MARCH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.077200",
    Latitude: "-33.210600"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "MULLION CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.205500",
    Latitude: "-33.088900"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "NASHDALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.008900",
    Latitude: "-33.302000"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "OPHIR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.247600",
    Latitude: "-33.131800"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "ORANGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.082300",
    Latitude: "-33.251000"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "ORANGE DC",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "ORANGE EAST",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "ORANGE MOULDER STREET",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "PANUARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.940600",
    Latitude: "-33.519100"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "SHADFORTH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.199300",
    Latitude: "-33.390800"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "SPRING CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.102100",
    Latitude: "-33.344900"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "SPRING HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ORANGE",
    Category: "Delivery Area",
    Longitude: "149.146600",
    Latitude: "-33.402500"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "SPRINGSIDE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.043700",
    Latitude: "-33.381400"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "SUMMER HILL CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.155100",
    Latitude: "-33.206900"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "WALDEGRAVE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.052600",
    Latitude: "-33.438400"
  },
  {
    Country: "Australia",
    Pcode: "2800",
    Locality: "WINDERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.018800",
    Latitude: "-33.209000"
  },
  {
    Country: "Australia",
    Pcode: "2803",
    Locality: "BENDICK MURRELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.461600",
    Latitude: "-34.186600"
  },
  {
    Country: "Australia",
    Pcode: "2803",
    Locality: "CROWTHER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.554600",
    Latitude: "-34.125100"
  },
  {
    Country: "Australia",
    Pcode: "2803",
    Locality: "WIRRIMAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.421400",
    Latitude: "-34.112200"
  },
  {
    Country: "Australia",
    Pcode: "2804",
    Locality: "BILLIMARI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.616400",
    Latitude: "-33.675700"
  },
  {
    Country: "Australia",
    Pcode: "2804",
    Locality: "CANOWINDRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.675700",
    Latitude: "-33.533700"
  },
  {
    Country: "Australia",
    Pcode: "2804",
    Locality: "MOORBEL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.699200",
    Latitude: "-33.563000"
  },
  {
    Country: "Australia",
    Pcode: "2804",
    Locality: "NYRANG CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.533200",
    Latitude: "-33.545200"
  },
  {
    Country: "Australia",
    Pcode: "2805",
    Locality: "GOOLOOGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.441500",
    Latitude: "-33.664400"
  },
  {
    Country: "Australia",
    Pcode: "2806",
    Locality: "EUGOWRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.356700",
    Latitude: "-33.419900"
  },
  {
    Country: "Australia",
    Pcode: "2807",
    Locality: "KOORAWATHA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.560700",
    Latitude: "-34.012600"
  },
  {
    Country: "Australia",
    Pcode: "2831",
    Locality: "WONGARBON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.814600",
    Latitude: "-32.296300"
  },
  {
    Country: "Australia",
    Pcode: "2832",
    Locality: "COME BY CHANCE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.435500",
    Latitude: "-30.345000"
  },
  {
    Country: "Australia",
    Pcode: "2832",
    Locality: "CRYON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.615800",
    Latitude: "-29.983500"
  },
  {
    Country: "Australia",
    Pcode: "2832",
    Locality: "CUMBORAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.625700",
    Latitude: "-29.506000"
  },
  {
    Country: "Australia",
    Pcode: "2832",
    Locality: "GRAWIN OPAL FIELDS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.644400",
    Latitude: "-29.673700"
  },
  {
    Country: "Australia",
    Pcode: "2832",
    Locality: "WALGETT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.015600",
    Latitude: "-29.974200"
  },
  {
    Country: "Australia",
    Pcode: "2833",
    Locality: "COLLARENEBRI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.515600",
    Latitude: "-29.424900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "SPRING FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.602800",
    Latitude: "-32.668800"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "ST FILLANS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.639300",
    Latitude: "-32.459700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "STONY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MUDGEE",
    Category: "Delivery Area",
    Longitude: "149.734400",
    Latitude: "-32.475700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "TAMBAROORA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.409600",
    Latitude: "-32.965000"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "TICHULAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.929300",
    Latitude: "-32.450600"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "TOTNES VALLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.907400",
    Latitude: "-32.498700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "TRIAMBLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.289300",
    Latitude: "-32.875200"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "TURILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.870000",
    Latitude: "-32.159200"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "TWELVE MILE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.269500",
    Latitude: "-32.523200"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "ULAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.822900",
    Latitude: "-32.268000"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "ULLAMALLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.317100",
    Latitude: "-32.992700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "WILBETREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.568000",
    Latitude: "-32.480200"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "WILPINJONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.878800",
    Latitude: "-32.327500"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "WINDEYER",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.566400",
    Latitude: "-32.798100"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "WOLLAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.995900",
    Latitude: "-32.361200"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "WORLDS END",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.376000",
    Latitude: "-32.666500"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "YARRABIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.337100",
    Latitude: "-32.629600"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "YARRAWONGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.653800",
    Latitude: "-32.364800"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "BARNEYS REEF",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.508400",
    Latitude: "-32.174500"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "BERYL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.460100",
    Latitude: "-32.322500"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "BUNGABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.675000",
    Latitude: "-32.169700"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "COPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.650500",
    Latitude: "-32.271400"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "CUMBANDRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.606800",
    Latitude: "-32.364700"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "GOOLMA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.226200",
    Latitude: "-32.304300"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "GULGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.525300",
    Latitude: "-32.370800"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "GUNTAWANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.446800",
    Latitude: "-32.396500"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "MEBUL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.357900",
    Latitude: "-32.331600"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "MEROTHERIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.567300",
    Latitude: "-32.147400"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "STUBBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.563300",
    Latitude: "-32.265800"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "TALLAWANG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.449600",
    Latitude: "-32.225300"
  },
  {
    Country: "Australia",
    Pcode: "2852",
    Locality: "TWO MILE FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.378700",
    Latitude: "-32.408000"
  },
  {
    Country: "Australia",
    Pcode: "2864",
    Locality: "BOWAN PARK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.841800",
    Latitude: "-33.318600"
  },
  {
    Country: "Australia",
    Pcode: "2864",
    Locality: "CUDAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.735700",
    Latitude: "-33.321100"
  },
  {
    Country: "Australia",
    Pcode: "2864",
    Locality: "MURGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.536100",
    Latitude: "-33.368900"
  },
  {
    Country: "Australia",
    Pcode: "2864",
    Locality: "TOOGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.649500",
    Latitude: "-33.358500"
  },
  {
    Country: "Australia",
    Pcode: "2865",
    Locality: "BOCOBRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.537200",
    Latitude: "-33.085100"
  },
  {
    Country: "Australia",
    Pcode: "2865",
    Locality: "GUMBLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.648800",
    Latitude: "-33.087100"
  },
  {
    Country: "Australia",
    Pcode: "2865",
    Locality: "MANILDRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.663900",
    Latitude: "-33.213500"
  },
  {
    Country: "Australia",
    Pcode: "2866",
    Locality: "AMAROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.927400",
    Latitude: "-33.159400"
  },
  {
    Country: "Australia",
    Pcode: "2866",
    Locality: "BOOMEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.960100",
    Latitude: "-32.971500"
  },
  {
    Country: "Australia",
    Pcode: "2866",
    Locality: "CUNDUMBUL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.917600",
    Latitude: "-32.854300"
  },
  {
    Country: "Australia",
    Pcode: "2866",
    Locality: "EUCHAREENA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.121900",
    Latitude: "-32.939400"
  },
  {
    Country: "Australia",
    Pcode: "2866",
    Locality: "GARRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.759900",
    Latitude: "-33.122900"
  },
  {
    Country: "Australia",
    Pcode: "2866",
    Locality: "LARRAS LEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.870400",
    Latitude: "-32.958500"
  },
  {
    Country: "Australia",
    Pcode: "2866",
    Locality: "MOLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.860800",
    Latitude: "-33.123200"
  },
  {
    Country: "Australia",
    Pcode: "2867",
    Locality: "BALDRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.486500",
    Latitude: "-32.881400"
  },
  {
    Country: "Australia",
    Pcode: "2867",
    Locality: "CUMNOCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.726400",
    Latitude: "-32.929500"
  },
  {
    Country: "Australia",
    Pcode: "2867",
    Locality: "EURIMBLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.815000",
    Latitude: "-32.830500"
  },
  {
    Country: "Australia",
    Pcode: "2867",
    Locality: "LOOMBAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2867",
    Locality: "YULLUNDRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.676500",
    Latitude: "-32.859300"
  },
  {
    Country: "Australia",
    Pcode: "2868",
    Locality: "BOURNEWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.762400",
    Latitude: "-32.753800"
  },
  {
    Country: "Australia",
    Pcode: "2868",
    Locality: "NORTH YEOVAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.672900",
    Latitude: "-32.718600"
  },
  {
    Country: "Australia",
    Pcode: "2868",
    Locality: "OBLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.451600",
    Latitude: "-32.695400"
  },
  {
    Country: "Australia",
    Pcode: "2868",
    Locality: "YEOVAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.582500",
    Latitude: "-32.774400"
  },
  {
    Country: "Australia",
    Pcode: "2869",
    Locality: "PEAK HILL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.018600",
    Latitude: "-32.683600"
  },
  {
    Country: "Australia",
    Pcode: "2869",
    Locality: "TOMINGLEY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.193800",
    Latitude: "-32.542800"
  },
  {
    Country: "Australia",
    Pcode: "2869",
    Locality: "TREWILGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.230900",
    Latitude: "-32.786400"
  },
  {
    Country: "Australia",
    Pcode: "2870",
    Locality: "ALECTOWN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.261700",
    Latitude: "-32.913000"
  },
  {
    Country: "Australia",
    Pcode: "2870",
    Locality: "BUMBERRY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.517000",
    Latitude: "-33.157100"
  },
  {
    Country: "Australia",
    Pcode: "2870",
    Locality: "COOKAMIDGERA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.304800",
    Latitude: "-33.239500"
  },
  {
    Country: "Australia",
    Pcode: "2870",
    Locality: "COOKS MYALLS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.984600",
    Latitude: "-33.014000"
  },
  {
    Country: "Australia",
    Pcode: "2870",
    Locality: "DAROOBALGIE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.152100",
    Latitude: "-33.310500"
  },
  {
    Country: "Australia",
    Pcode: "2870",
    Locality: "GOONUMBLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.053400",
    Latitude: "-32.940100"
  },
  {
    Country: "Australia",
    Pcode: "2870",
    Locality: "MANDAGERY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.450000",
    Latitude: "-33.253100"
  },
  {
    Country: "Australia",
    Pcode: "2870",
    Locality: "PARKES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.262300",
    Latitude: "-33.110700"
  },
  {
    Country: "Australia",
    Pcode: "2870",
    Locality: "TICHBORNE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.074900",
    Latitude: "-33.232400"
  },
  {
    Country: "Australia",
    Pcode: "2871",
    Locality: "BEDGERABONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.663900",
    Latitude: "-33.309100"
  },
  {
    Country: "Australia",
    Pcode: "3045",
    Locality: "MELBOURNE AIRPORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.835000",
    Latitude: "-37.675600"
  },
  {
    Country: "Australia",
    Pcode: "3046",
    Locality: "GLENROY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.920900",
    Latitude: "-37.704000"
  },
  {
    Country: "Australia",
    Pcode: "3046",
    Locality: "HADFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.949300",
    Latitude: "-37.710400"
  },
  {
    Country: "Australia",
    Pcode: "3046",
    Locality: "OAK PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.919800",
    Latitude: "-37.718200"
  },
  {
    Country: "Australia",
    Pcode: "3047",
    Locality: "BROADMEADOWS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.928200",
    Latitude: "-37.678800"
  },
  {
    Country: "Australia",
    Pcode: "3047",
    Locality: "DALLAS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.936400",
    Latitude: "-37.671200"
  },
  {
    Country: "Australia",
    Pcode: "3047",
    Locality: "JACANA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.910000",
    Latitude: "-37.687800"
  },
  {
    Country: "Australia",
    Pcode: "3048",
    Locality: "COOLAROO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.934400",
    Latitude: "-37.653200"
  },
  {
    Country: "Australia",
    Pcode: "3048",
    Locality: "MEADOW HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.918700",
    Latitude: "-37.651200"
  },
  {
    Country: "Australia",
    Pcode: "3049",
    Locality: "ATTWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.882400",
    Latitude: "-37.667100"
  },
  {
    Country: "Australia",
    Pcode: "3049",
    Locality: "WESTMEADOWS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.888100",
    Latitude: "-37.676000"
  },
  {
    Country: "Australia",
    Pcode: "3050",
    Locality: "ROYAL MELBOURNE HOSPITAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3051",
    Locality: "HOTHAM HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3051",
    Locality: "NORTH MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.943500",
    Latitude: "-37.796800"
  },
  {
    Country: "Australia",
    Pcode: "3052",
    Locality: "MELBOURNE UNIVERSITY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3052",
    Locality: "PARKVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.950700",
    Latitude: "-37.786500"
  },
  {
    Country: "Australia",
    Pcode: "3053",
    Locality: "CARLTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.967000",
    Latitude: "-37.800000"
  },
  {
    Country: "Australia",
    Pcode: "3053",
    Locality: "CARLTON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3054",
    Locality: "CARLTON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.968100",
    Latitude: "-37.785600"
  },
  {
    Country: "Australia",
    Pcode: "3054",
    Locality: "PRINCES HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.966600",
    Latitude: "-37.781900"
  },
  {
    Country: "Australia",
    Pcode: "3055",
    Locality: "BRUNSWICK SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3055",
    Locality: "BRUNSWICK WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.940200",
    Latitude: "-37.764100"
  },
  {
    Country: "Australia",
    Pcode: "3055",
    Locality: "MOONEE VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3055",
    Locality: "MORELAND WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3056",
    Locality: "BRUNSWICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.962800",
    Latitude: "-37.766800"
  },
  {
    Country: "Australia",
    Pcode: "3056",
    Locality: "BRUNSWICK LOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3056",
    Locality: "BRUNSWICK NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3057",
    Locality: "BRUNSWICK EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.978500",
    Latitude: "-37.767300"
  },
  {
    Country: "Australia",
    Pcode: "3057",
    Locality: "SUMNER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3058",
    Locality: "BATMAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3058",
    Locality: "COBURG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.963900",
    Latitude: "-37.744900"
  },
  {
    Country: "Australia",
    Pcode: "3058",
    Locality: "COBURG NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.967800",
    Latitude: "-37.727000"
  },
  {
    Country: "Australia",
    Pcode: "3058",
    Locality: "MERLYNSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3058",
    Locality: "MORELAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3059",
    Locality: "GREENVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.873000",
    Latitude: "-37.634800"
  },
  {
    Country: "Australia",
    Pcode: "3060",
    Locality: "FAWKNER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.969400",
    Latitude: "-37.705300"
  },
  {
    Country: "Australia",
    Pcode: "3061",
    Locality: "CAMPBELLFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.956200",
    Latitude: "-37.666700"
  },
  {
    Country: "Australia",
    Pcode: "3062",
    Locality: "SOMERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.947500",
    Latitude: "-37.633600"
  },
  {
    Country: "Australia",
    Pcode: "3063",
    Locality: "OAKLANDS JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.838700",
    Latitude: "-37.577900"
  },
  {
    Country: "Australia",
    Pcode: "3063",
    Locality: "YUROKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.860600",
    Latitude: "-37.581600"
  },
  {
    Country: "Australia",
    Pcode: "3064",
    Locality: "CRAIGIEBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.925800",
    Latitude: "-37.581300"
  },
  {
    Country: "Australia",
    Pcode: "3064",
    Locality: "DONNYBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.988400",
    Latitude: "-37.540000"
  },
  {
    Country: "Australia",
    Pcode: "3064",
    Locality: "KALKALLO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.961300",
    Latitude: "-37.525800"
  },
  {
    Country: "Australia",
    Pcode: "3064",
    Locality: "MICKLEHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.898900",
    Latitude: "-37.546600"
  },
  {
    Country: "Australia",
    Pcode: "3064",
    Locality: "ROXBURGH PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.925400",
    Latitude: "-37.625900"
  },
  {
    Country: "Australia",
    Pcode: "3065",
    Locality: "FITZROY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.979100",
    Latitude: "-37.800900"
  },
  {
    Country: "Australia",
    Pcode: "3066",
    Locality: "COLLINGWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.988100",
    Latitude: "-37.801800"
  },
  {
    Country: "Australia",
    Pcode: "3066",
    Locality: "COLLINGWOOD NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3067",
    Locality: "ABBOTSFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.000000",
    Latitude: "-37.803700"
  },
  {
    Country: "Australia",
    Pcode: "3068",
    Locality: "CLIFTON HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.998700",
    Latitude: "-37.789200"
  },
  {
    Country: "Australia",
    Pcode: "3068",
    Locality: "FITZROY NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.985700",
    Latitude: "-37.783400"
  },
  {
    Country: "Australia",
    Pcode: "3070",
    Locality: "NORTHCOTE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.000900",
    Latitude: "-37.772200"
  },
  {
    Country: "Australia",
    Pcode: "3070",
    Locality: "NORTHCOTE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3071",
    Locality: "THORNBURY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.004800",
    Latitude: "-37.758400"
  },
  {
    Country: "Australia",
    Pcode: "3072",
    Locality: "GILBERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3072",
    Locality: "NORTHLAND CENTRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3072",
    Locality: "PRESTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.006900",
    Latitude: "-37.741600"
  },
  {
    Country: "Australia",
    Pcode: "3072",
    Locality: "PRESTON LOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3072",
    Locality: "PRESTON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3072",
    Locality: "PRESTON WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3072",
    Locality: "REGENT WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3073",
    Locality: "RESERVOIR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.002300",
    Latitude: "-37.712300"
  },
  {
    Country: "Australia",
    Pcode: "3074",
    Locality: "THOMASTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.006700",
    Latitude: "-37.683200"
  },
  {
    Country: "Australia",
    Pcode: "3075",
    Locality: "LALOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.006000",
    Latitude: "-37.665900"
  },
  {
    Country: "Australia",
    Pcode: "3076",
    Locality: "EPPING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.016000",
    Latitude: "-37.638300"
  },
  {
    Country: "Australia",
    Pcode: "3078",
    Locality: "ALPHINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.032200",
    Latitude: "-37.780300"
  },
  {
    Country: "Australia",
    Pcode: "3078",
    Locality: "FAIRFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.012200",
    Latitude: "-37.784400"
  },
  {
    Country: "Australia",
    Pcode: "3079",
    Locality: "IVANHOE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.045700",
    Latitude: "-37.766600"
  },
  {
    Country: "Australia",
    Pcode: "3079",
    Locality: "IVANHOE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.059500",
    Latitude: "-37.774500"
  },
  {
    Country: "Australia",
    Pcode: "3171",
    Locality: "SANDOWN VILLAGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3171",
    Locality: "SPRINGVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.163200",
    Latitude: "-37.944100"
  },
  {
    Country: "Australia",
    Pcode: "3172",
    Locality: "DINGLEY VILLAGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.125300",
    Latitude: "-37.975000"
  },
  {
    Country: "Australia",
    Pcode: "3172",
    Locality: "SPRINGVALE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.148600",
    Latitude: "-37.971800"
  },
  {
    Country: "Australia",
    Pcode: "3173",
    Locality: "KEYSBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.166600",
    Latitude: "-38.006300"
  },
  {
    Country: "Australia",
    Pcode: "3174",
    Locality: "NOBLE PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.177700",
    Latitude: "-37.967800"
  },
  {
    Country: "Australia",
    Pcode: "3174",
    Locality: "NOBLE PARK NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.188800",
    Latitude: "-37.949500"
  },
  {
    Country: "Australia",
    Pcode: "3175",
    Locality: "BANGHOLME",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.175900",
    Latitude: "-38.046700"
  },
  {
    Country: "Australia",
    Pcode: "3175",
    Locality: "DANDENONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.212600",
    Latitude: "-37.985500"
  },
  {
    Country: "Australia",
    Pcode: "3175",
    Locality: "DANDENONG EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3175",
    Locality: "DANDENONG NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.214900",
    Latitude: "-37.955000"
  },
  {
    Country: "Australia",
    Pcode: "3175",
    Locality: "DANDENONG SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.215600",
    Latitude: "-38.030500"
  },
  {
    Country: "Australia",
    Pcode: "3175",
    Locality: "DUNEARN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3176",
    Locality: "SCORESBY BC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3177",
    Locality: "DOVETON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.240400",
    Latitude: "-37.988800"
  },
  {
    Country: "Australia",
    Pcode: "3177",
    Locality: "EUMEMMERRING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.248500",
    Latitude: "-37.997700"
  },
  {
    Country: "Australia",
    Pcode: "3178",
    Locality: "ROWVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.236300",
    Latitude: "-37.925800"
  },
  {
    Country: "Australia",
    Pcode: "3179",
    Locality: "SCORESBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.221100",
    Latitude: "-37.905200"
  },
  {
    Country: "Australia",
    Pcode: "3180",
    Locality: "KNOXFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.247900",
    Latitude: "-37.889200"
  },
  {
    Country: "Australia",
    Pcode: "3181",
    Locality: "PRAHRAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.006200",
    Latitude: "-37.852900"
  },
  {
    Country: "Australia",
    Pcode: "3181",
    Locality: "PRAHRAN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3181",
    Locality: "WINDSOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.992800",
    Latitude: "-37.854700"
  },
  {
    Country: "Australia",
    Pcode: "3182",
    Locality: "ST KILDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.981200",
    Latitude: "-37.864200"
  },
  {
    Country: "Australia",
    Pcode: "3182",
    Locality: "ST KILDA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3182",
    Locality: "ST KILDA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.971600",
    Latitude: "-37.858400"
  },
  {
    Country: "Australia",
    Pcode: "3183",
    Locality: "BALACLAVA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.994800",
    Latitude: "-37.870400"
  },
  {
    Country: "Australia",
    Pcode: "3183",
    Locality: "ST KILDA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.000800",
    Latitude: "-37.868100"
  },
  {
    Country: "Australia",
    Pcode: "3184",
    Locality: "BRIGHTON ROAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3184",
    Locality: "ELWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.985500",
    Latitude: "-37.881000"
  },
  {
    Country: "Australia",
    Pcode: "3185",
    Locality: "ELSTERNWICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.006500",
    Latitude: "-37.886900"
  },
  {
    Country: "Australia",
    Pcode: "3185",
    Locality: "GARDENVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.009300",
    Latitude: "-37.898100"
  },
  {
    Country: "Australia",
    Pcode: "3185",
    Locality: "RIPPONLEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.994600",
    Latitude: "-37.877400"
  },
  {
    Country: "Australia",
    Pcode: "3186",
    Locality: "BRIGHTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.994300",
    Latitude: "-37.906100"
  },
  {
    Country: "Australia",
    Pcode: "3186",
    Locality: "BRIGHTON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3186",
    Locality: "DENDY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3186",
    Locality: "WERE STREET PO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3187",
    Locality: "BRIGHTON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.019400",
    Latitude: "-37.916500"
  },
  {
    Country: "Australia",
    Pcode: "3187",
    Locality: "NORTH ROAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3188",
    Locality: "HAMPTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.011000",
    Latitude: "-37.937800"
  },
  {
    Country: "Australia",
    Pcode: "3188",
    Locality: "HAMPTON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.030300",
    Latitude: "-37.938500"
  },
  {
    Country: "Australia",
    Pcode: "3188",
    Locality: "HAMPTON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3189",
    Locality: "MOORABBIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.058100",
    Latitude: "-37.941400"
  },
  {
    Country: "Australia",
    Pcode: "3189",
    Locality: "MOORABBIN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3189",
    Locality: "WISHART",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3190",
    Locality: "HIGHETT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.039800",
    Latitude: "-37.950600"
  },
  {
    Country: "Australia",
    Pcode: "3191",
    Locality: "SANDRINGHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.012100",
    Latitude: "-37.952400"
  },
  {
    Country: "Australia",
    Pcode: "3192",
    Locality: "CHELTENHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.057000",
    Latitude: "-37.961900"
  },
  {
    Country: "Australia",
    Pcode: "3192",
    Locality: "CHELTENHAM EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3192",
    Locality: "SOUTHLAND CENTRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3193",
    Locality: "BEAUMARIS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.038200",
    Latitude: "-37.983600"
  },
  {
    Country: "Australia",
    Pcode: "3193",
    Locality: "BLACK ROCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.024300",
    Latitude: "-37.973500"
  },
  {
    Country: "Australia",
    Pcode: "3193",
    Locality: "BLACK ROCK NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3193",
    Locality: "CROMER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3194",
    Locality: "MENTONE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.061500",
    Latitude: "-37.984200"
  },
  {
    Country: "Australia",
    Pcode: "3194",
    Locality: "MENTONE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3194",
    Locality: "MOORABBIN AIRPORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.101000",
    Latitude: "-37.977900"
  },
  {
    Country: "Australia",
    Pcode: "3195",
    Locality: "ASPENDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.101300",
    Latitude: "-38.021900"
  },
  {
    Country: "Australia",
    Pcode: "3195",
    Locality: "ASPENDALE GARDENS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.123400",
    Latitude: "-38.022800"
  },
  {
    Country: "Australia",
    Pcode: "3195",
    Locality: "BRAESIDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.123800",
    Latitude: "-38.001400"
  },
  {
    Country: "Australia",
    Pcode: "3195",
    Locality: "MORDIALLOC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.095100",
    Latitude: "-37.997900"
  },
  {
    Country: "Australia",
    Pcode: "3195",
    Locality: "PARKDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.080800",
    Latitude: "-37.993700"
  },
  {
    Country: "Australia",
    Pcode: "3195",
    Locality: "WATERWAYS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.129800",
    Latitude: "-38.015300"
  },
  {
    Country: "Australia",
    Pcode: "3196",
    Locality: "BONBEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.127900",
    Latitude: "-38.062900"
  },
  {
    Country: "Australia",
    Pcode: "3196",
    Locality: "CHELSEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.121600",
    Latitude: "-38.049600"
  },
  {
    Country: "Australia",
    Pcode: "3196",
    Locality: "CHELSEA HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.133800",
    Latitude: "-38.039800"
  },
  {
    Country: "Australia",
    Pcode: "3196",
    Locality: "EDITHVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.115500",
    Latitude: "-38.037700"
  },
  {
    Country: "Australia",
    Pcode: "3197",
    Locality: "CARRUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.127700",
    Latitude: "-38.077100"
  },
  {
    Country: "Australia",
    Pcode: "3197",
    Locality: "PATTERSON LAKES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.144100",
    Latitude: "-38.067200"
  },
  {
    Country: "Australia",
    Pcode: "3198",
    Locality: "SEAFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.142100",
    Latitude: "-38.102700"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "FRAMLINGHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.696700",
    Latitude: "-38.266300"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "FRAMLINGHAM EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.712300",
    Latitude: "-38.198500"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "GARVOC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.808900",
    Latitude: "-38.286100"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "GLENORMISTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "GLENORMISTON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.984500",
    Latitude: "-38.137400"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "GLENORMISTON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.970500",
    Latitude: "-38.169500"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "KOLORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.922000",
    Latitude: "-38.133500"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "LAANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.812700",
    Latitude: "-38.366700"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "NOORAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.906400",
    Latitude: "-38.194400"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "NOORAT EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.976400",
    Latitude: "-38.202700"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "PANMURE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.712900",
    Latitude: "-38.325000"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "TAROON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.869200",
    Latitude: "-38.329300"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "THE SISTERS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.791100",
    Latitude: "-38.183200"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "BULLAHARRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.139100",
    Latitude: "-38.349200"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "COBDEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.082800",
    Latitude: "-38.332800"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "COBRICO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.015900",
    Latitude: "-38.294100"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "ELINGAMITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.004700",
    Latitude: "-38.368500"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "ELINGAMITE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.979900",
    Latitude: "-38.350600"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "GLENFYNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.005800",
    Latitude: "-38.413100"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "JANCOURT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.093500",
    Latitude: "-38.366800"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "JANCOURT EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.191900",
    Latitude: "-38.413000"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "NAROGHID",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.064600",
    Latitude: "-38.249200"
  },
  {
    Country: "Australia",
    Pcode: "3266",
    Locality: "SIMPSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.257600",
    Latitude: "-38.510400"
  },
  {
    Country: "Australia",
    Pcode: "3267",
    Locality: "SCOTTS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.075500",
    Latitude: "-38.456600"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "AYRFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.853300",
    Latitude: "-38.436700"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "BRUCKNELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.903900",
    Latitude: "-38.459400"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "COORIEMUNGLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.149200",
    Latitude: "-38.566700"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "COWLEYS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.037500",
    Latitude: "-38.503300"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "CURDIES RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.943700",
    Latitude: "-38.444600"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "CURDIEVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.852500",
    Latitude: "-38.521200"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "HEYTESBURY LOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.890900",
    Latitude: "-38.564100"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "NEWFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.008800",
    Latitude: "-38.560600"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "NIRRANDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.774900",
    Latitude: "-38.508500"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "NIRRANDA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.850700",
    Latitude: "-38.481300"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "NIRRANDA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.797800",
    Latitude: "-38.545100"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "NULLAWARRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.815700",
    Latitude: "-38.482500"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "NULLAWARRE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.795500",
    Latitude: "-38.437900"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "PAARATTE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.969200",
    Latitude: "-38.556100"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "THE COVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.695600",
    Latitude: "-38.489900"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "TIMBOON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.963000",
    Latitude: "-38.484200"
  },
  {
    Country: "Australia",
    Pcode: "3268",
    Locality: "TIMBOON WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.921500",
    Latitude: "-38.541900"
  },
  {
    Country: "Australia",
    Pcode: "3269",
    Locality: "PORT CAMPBELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.016300",
    Latitude: "-38.613000"
  },
  {
    Country: "Australia",
    Pcode: "3269",
    Locality: "PRINCETOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.168700",
    Latitude: "-38.653400"
  },
  {
    Country: "Australia",
    Pcode: "3269",
    Locality: "WAARRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.050100",
    Latitude: "-38.573500"
  },
  {
    Country: "Australia",
    Pcode: "3270",
    Locality: "PETERBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.892100",
    Latitude: "-38.589400"
  },
  {
    Country: "Australia",
    Pcode: "3271",
    Locality: "DARLINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.051600",
    Latitude: "-37.979200"
  },
  {
    Country: "Australia",
    Pcode: "3271",
    Locality: "DUNDONNELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.972800",
    Latitude: "-37.897100"
  },
  {
    Country: "Australia",
    Pcode: "3271",
    Locality: "PURA PURA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.090400",
    Latitude: "-37.819200"
  },
  {
    Country: "Australia",
    Pcode: "3272",
    Locality: "MORTLAKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.792600",
    Latitude: "-38.058400"
  },
  {
    Country: "Australia",
    Pcode: "3272",
    Locality: "WOORNDOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.804000",
    Latitude: "-37.883000"
  },
  {
    Country: "Australia",
    Pcode: "3273",
    Locality: "HEXHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.687900",
    Latitude: "-37.990300"
  },
  {
    Country: "Australia",
    Pcode: "3274",
    Locality: "CARAMUT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.534600",
    Latitude: "-37.953400"
  },
  {
    Country: "Australia",
    Pcode: "3275",
    Locality: "MAILORS FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.463500",
    Latitude: "-38.289500"
  },
  {
    Country: "Australia",
    Pcode: "3276",
    Locality: "MINJAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.477300",
    Latitude: "-38.041500"
  },
  {
    Country: "Australia",
    Pcode: "3276",
    Locality: "WOOLSTHORPE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.456100",
    Latitude: "-38.151800"
  },
  {
    Country: "Australia",
    Pcode: "3277",
    Locality: "ALLANSFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.597800",
    Latitude: "-38.403900"
  },
  {
    Country: "Australia",
    Pcode: "3277",
    Locality: "MEPUNGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.676100",
    Latitude: "-38.455700"
  },
  {
    Country: "Australia",
    Pcode: "3277",
    Locality: "MEPUNGA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.713200",
    Latitude: "-38.443900"
  },
  {
    Country: "Australia",
    Pcode: "3277",
    Locality: "MEPUNGA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.653600",
    Latitude: "-38.439300"
  },
  {
    Country: "Australia",
    Pcode: "3277",
    Locality: "NARINGAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.723700",
    Latitude: "-38.394600"
  },
  {
    Country: "Australia",
    Pcode: "3277",
    Locality: "NARINGAL EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.789500",
    Latitude: "-38.408200"
  },
  {
    Country: "Australia",
    Pcode: "3278",
    Locality: "PURNIM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.615800",
    Latitude: "-38.264400"
  },
  {
    Country: "Australia",
    Pcode: "3278",
    Locality: "PURNIM WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.554900",
    Latitude: "-38.298300"
  },
  {
    Country: "Australia",
    Pcode: "3279",
    Locality: "BALLANGEICH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.613500",
    Latitude: "-38.186300"
  },
  {
    Country: "Australia",
    Pcode: "3279",
    Locality: "WANGOOM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.581100",
    Latitude: "-38.330200"
  },
  {
    Country: "Australia",
    Pcode: "3280",
    Locality: "DENNINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.433500",
    Latitude: "-38.355400"
  },
  {
    Country: "Australia",
    Pcode: "3280",
    Locality: "WARRNAMBOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.496600",
    Latitude: "-38.369800"
  },
  {
    Country: "Australia",
    Pcode: "3281",
    Locality: "BUSHFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.517000",
    Latitude: "-38.325000"
  },
  {
    Country: "Australia",
    Pcode: "3281",
    Locality: "GRASSMERE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.551700",
    Latitude: "-38.238900"
  },
  {
    Country: "Australia",
    Pcode: "3281",
    Locality: "WINSLOW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.460900",
    Latitude: "-38.237600"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "LONG FOREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.505700",
    Latitude: "-37.655800"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "MADDINGLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.427300",
    Latitude: "-37.701000"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "MERRIMU",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.473300",
    Latitude: "-37.656500"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "PARWAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.469000",
    Latitude: "-37.738500"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "ROWSLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.368000",
    Latitude: "-37.717800"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "STAUGHTON VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.253300",
    Latitude: "-37.842700"
  },
  {
    Country: "Australia",
    Pcode: "3341",
    Locality: "DALES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.301700",
    Latitude: "-37.522900"
  },
  {
    Country: "Australia",
    Pcode: "3341",
    Locality: "GREENDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.334600",
    Latitude: "-37.535500"
  },
  {
    Country: "Australia",
    Pcode: "3341",
    Locality: "KOROBEIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.341800",
    Latitude: "-37.575400"
  },
  {
    Country: "Australia",
    Pcode: "3341",
    Locality: "MYRNIONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.304600",
    Latitude: "-37.586000"
  },
  {
    Country: "Australia",
    Pcode: "3341",
    Locality: "PENTLAND HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.379800",
    Latitude: "-37.652600"
  },
  {
    Country: "Australia",
    Pcode: "3342",
    Locality: "BALLAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.214100",
    Latitude: "-37.603800"
  },
  {
    Country: "Australia",
    Pcode: "3342",
    Locality: "BEREMBOKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.218100",
    Latitude: "-37.787800"
  },
  {
    Country: "Australia",
    Pcode: "3342",
    Locality: "BLAKEVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.208200",
    Latitude: "-37.477500"
  },
  {
    Country: "Australia",
    Pcode: "3342",
    Locality: "BUNDING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.171200",
    Latitude: "-37.536500"
  },
  {
    Country: "Australia",
    Pcode: "3342",
    Locality: "COLBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.222300",
    Latitude: "-37.533200"
  },
  {
    Country: "Australia",
    Pcode: "3342",
    Locality: "DURDIDWARRAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.163900",
    Latitude: "-37.822600"
  },
  {
    Country: "Australia",
    Pcode: "3342",
    Locality: "FISKVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.238100",
    Latitude: "-37.682300"
  },
  {
    Country: "Australia",
    Pcode: "3342",
    Locality: "INGLISTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.308600",
    Latitude: "-37.660000"
  },
  {
    Country: "Australia",
    Pcode: "3342",
    Locality: "MOUNT WALLACE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.209300",
    Latitude: "-37.733800"
  },
  {
    Country: "Australia",
    Pcode: "3345",
    Locality: "GORDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.107100",
    Latitude: "-37.565200"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "ALFREDTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.801000",
    Latitude: "-37.554900"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "BAKERY HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.865800",
    Latitude: "-37.563200"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "BALLARAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "BALLARAT CENTRAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.850700",
    Latitude: "-37.560000"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "BALLARAT EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.897000",
    Latitude: "-37.564400"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "BALLARAT NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.864700",
    Latitude: "-37.537200"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "BALLARAT WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "BLACK HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.873200",
    Latitude: "-37.549900"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "BROWN HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.915400",
    Latitude: "-37.547100"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "CANADIAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.882500",
    Latitude: "-37.584000"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "EUREKA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.882400",
    Latitude: "-37.564800"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "GOLDEN POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "BALLARAT",
    Category: "Delivery Area",
    Longitude: "143.863800",
    Latitude: "-37.574600"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "INVERMAY PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.860300",
    Latitude: "-37.529000"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "LAKE WENDOUREE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.834500",
    Latitude: "-37.549900"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "LUCAS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.769700",
    Latitude: "-37.552100"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "MOUNT CLEAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.872700",
    Latitude: "-37.603600"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "MOUNT HELEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.887300",
    Latitude: "-37.627400"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "MOUNT PLEASANT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.855100",
    Latitude: "-37.581600"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "NERRINA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.892100",
    Latitude: "-37.538600"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "NEWINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.827900",
    Latitude: "-37.563300"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "REDAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.838000",
    Latitude: "-37.576800"
  },
  {
    Country: "Australia",
    Pcode: "3350",
    Locality: "SOLDIERS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.858200",
    Latitude: "-37.550700"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "BERRINGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.668900",
    Latitude: "-37.771300"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "BO PEEP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.668800",
    Latitude: "-37.549800"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "CAPE CLEAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.609400",
    Latitude: "-37.778100"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "CARNGHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.592000",
    Latitude: "-37.559800"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "CHEPSTOWE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.485400",
    Latitude: "-37.596300"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "HADDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.701000",
    Latitude: "-37.588400"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "HILLCREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.633700",
    Latitude: "-37.618000"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "ILLABAROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.651900",
    Latitude: "-37.824900"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "LAKE BOLAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.835400",
    Latitude: "-37.690100"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "MININERA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.960000",
    Latitude: "-37.603800"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "MOUNT EMU",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.393000",
    Latitude: "-37.600400"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "NERRIN NERRIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.038700",
    Latitude: "-37.760200"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "NEWTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "SCARSDALE",
    Category: "Delivery Area",
    Longitude: "143.664000",
    Latitude: "-37.699000"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "NINTINGBOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.721400",
    Latitude: "-37.609500"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "PIGGOREET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.636800",
    Latitude: "-37.723200"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "PITFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.595800",
    Latitude: "-37.828600"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "ROKEWOOD JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.684800",
    Latitude: "-37.851200"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "ROSS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.760600",
    Latitude: "-37.647600"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "SCARSDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.662200",
    Latitude: "-37.678300"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "SMYTHES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.767200",
    Latitude: "-37.603200"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "SMYTHESDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.669100",
    Latitude: "-37.644800"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "SNAKE VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.575500",
    Latitude: "-37.613000"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "SPRINGDALLAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.623600",
    Latitude: "-37.748800"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "STAFFORDSHIRE REEF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.683900",
    Latitude: "-37.733600"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "STREATHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.077700",
    Latitude: "-37.642100"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "WALLINDUC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.518400",
    Latitude: "-37.876000"
  },
  {
    Country: "Australia",
    Pcode: "3351",
    Locality: "WESTMERE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.962500",
    Latitude: "-37.679300"
  },
  {
    Country: "Australia",
    Pcode: "3388",
    Locality: "RUPANYUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.685400",
    Latitude: "-36.641800"
  },
  {
    Country: "Australia",
    Pcode: "3390",
    Locality: "KEWELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.420300",
    Latitude: "-36.463800"
  },
  {
    Country: "Australia",
    Pcode: "3390",
    Locality: "MURTOA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.481600",
    Latitude: "-36.599200"
  },
  {
    Country: "Australia",
    Pcode: "3391",
    Locality: "BRIM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.443800",
    Latitude: "-36.071800"
  },
  {
    Country: "Australia",
    Pcode: "3392",
    Locality: "BOOLITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.681600",
    Latitude: "-36.342200"
  },
  {
    Country: "Australia",
    Pcode: "3392",
    Locality: "MINYIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.656000",
    Latitude: "-36.454300"
  },
  {
    Country: "Australia",
    Pcode: "3392",
    Locality: "SHEEP HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.551500",
    Latitude: "-36.354700"
  },
  {
    Country: "Australia",
    Pcode: "3393",
    Locality: "AUBREY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.219100",
    Latitude: "-36.228700"
  },
  {
    Country: "Australia",
    Pcode: "3393",
    Locality: "BANGERANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.581400",
    Latitude: "-36.209000"
  },
  {
    Country: "Australia",
    Pcode: "3393",
    Locality: "CANNUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.305300",
    Latitude: "-36.254400"
  },
  {
    Country: "Australia",
    Pcode: "3393",
    Locality: "CRYMELON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.239500",
    Latitude: "-36.153600"
  },
  {
    Country: "Australia",
    Pcode: "3393",
    Locality: "KELLALAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.406300",
    Latitude: "-36.364800"
  },
  {
    Country: "Australia",
    Pcode: "3393",
    Locality: "LAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.421000",
    Latitude: "-36.126900"
  },
  {
    Country: "Australia",
    Pcode: "3393",
    Locality: "WARRACKNABEAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.436200",
    Latitude: "-36.231300"
  },
  {
    Country: "Australia",
    Pcode: "3393",
    Locality: "WILKUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.668900",
    Latitude: "-36.085400"
  },
  {
    Country: "Australia",
    Pcode: "3393",
    Locality: "WILLENABRINA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.225800",
    Latitude: "-36.052400"
  },
  {
    Country: "Australia",
    Pcode: "3395",
    Locality: "BEULAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.420000",
    Latitude: "-35.914700"
  },
  {
    Country: "Australia",
    Pcode: "3395",
    Locality: "KENMARE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.201900",
    Latitude: "-35.939500"
  },
  {
    Country: "Australia",
    Pcode: "3395",
    Locality: "REEDY DAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.649800",
    Latitude: "-35.948600"
  },
  {
    Country: "Australia",
    Pcode: "3395",
    Locality: "ROSEBERY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.421500",
    Latitude: "-35.833500"
  },
  {
    Country: "Australia",
    Pcode: "3396",
    Locality: "HOPETOUN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.330100",
    Latitude: "-35.698500"
  },
  {
    Country: "Australia",
    Pcode: "3400",
    Locality: "HORSHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.196200",
    Latitude: "-36.717200"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "BLACKHEATH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.286100",
    Latitude: "-36.472800"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "BRIMPAEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.196700",
    Latitude: "-37.063400"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "BUNGALALLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.257900",
    Latitude: "-36.797200"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "CHERRYPOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.176900",
    Latitude: "-37.130400"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "DOOEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.236300",
    Latitude: "-36.640900"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "DRUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.321600",
    Latitude: "-36.765000"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "GYMBOWEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.621000",
    Latitude: "-36.754500"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "HAVEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.192700",
    Latitude: "-36.757500"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "JUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.339500",
    Latitude: "-36.573300"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "KALKEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.222800",
    Latitude: "-36.550200"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "KANAGULK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.838400",
    Latitude: "-37.117800"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "KARNAK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.496900",
    Latitude: "-36.843900"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "LAHARUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.342400",
    Latitude: "-36.910800"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "LONGERENONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.339200",
    Latitude: "-36.678200"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "LOWER NORTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.073600",
    Latitude: "-36.803300"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "MCKENZIE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.160400",
    Latitude: "-36.802600"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "MOCKINYA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.176600",
    Latitude: "-36.959800"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "MURRA WARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.246600",
    Latitude: "-36.438800"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "NURCOUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.659300",
    Latitude: "-36.630700"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "NURRABIEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.048200",
    Latitude: "-36.936500"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "PIMPINIO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.077300",
    Latitude: "-36.610600"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "QUANTONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.046000",
    Latitude: "-36.751600"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "RIVERSIDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.240700",
    Latitude: "-36.723400"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "ROCKLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.025700",
    Latitude: "-37.255700"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "ST HELENS PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.454000",
    Latitude: "-36.785900"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "TELANGATUK EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.028700",
    Latitude: "-37.122400"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "TOOLONDO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.883800",
    Latitude: "-37.015700"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "VECTIS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.078700",
    Latitude: "-36.712500"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "WAIL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.094000",
    Latitude: "-36.534500"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "WALLUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.254900",
    Latitude: "-36.357400"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "WARTOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.401200",
    Latitude: "-37.003500"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "WONWONDAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.182200",
    Latitude: "-36.887200"
  },
  {
    Country: "Australia",
    Pcode: "3401",
    Locality: "ZUMSTEINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.376000",
    Latitude: "-37.113200"
  },
  {
    Country: "Australia",
    Pcode: "3402",
    Locality: "HORSHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3407",
    Locality: "BALMORAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.835200",
    Latitude: "-37.213200"
  },
  {
    Country: "Australia",
    Pcode: "3407",
    Locality: "ENGLEFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.809600",
    Latitude: "-37.325000"
  },
  {
    Country: "Australia",
    Pcode: "3407",
    Locality: "GATUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.948500",
    Latitude: "-37.376000"
  },
  {
    Country: "Australia",
    Pcode: "3407",
    Locality: "PIGEON PONDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.662400",
    Latitude: "-37.279000"
  },
  {
    Country: "Australia",
    Pcode: "3407",
    Locality: "VASEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.886100",
    Latitude: "-37.347200"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "ARAPILES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.849300",
    Latitude: "-36.740200"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "CLEAR LAKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.898000",
    Latitude: "-36.937000"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "DOUGLAS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.723200",
    Latitude: "-37.038300"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "DUCHEMBEGARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.907100",
    Latitude: "-36.615800"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "GRASS FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.826000",
    Latitude: "-36.654600"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "JILPANGER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.739800",
    Latitude: "-36.910700"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "MIGA LAKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.639800",
    Latitude: "-36.851600"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "MITRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.788900",
    Latitude: "-36.712400"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "NATIMUK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.938400",
    Latitude: "-36.725700"
  },
  {
    Country: "Australia",
    Pcode: "3409",
    Locality: "NORADJUHA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.911100",
    Latitude: "-36.845300"
  },
  {
    Country: "Australia",
    Pcode: "3475",
    Locality: "EMU",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.452600",
    Latitude: "-36.730700"
  },
  {
    Country: "Australia",
    Pcode: "3475",
    Locality: "LOGAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.487900",
    Latitude: "-36.629700"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "AVON PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.922900",
    Latitude: "-36.548200"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "BEAZLEYS BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.147300",
    Latitude: "-36.707600"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "CARAPOOEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.337700",
    Latitude: "-36.716200"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "CARAPOOEE WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.238600",
    Latitude: "-36.709600"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "COONOOER BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.309000",
    Latitude: "-36.464600"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "COONOOER WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.213800",
    Latitude: "-36.425600"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "DALYENONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.414600",
    Latitude: "-36.806200"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "GOOROC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.208400",
    Latitude: "-36.471100"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "GOWAR EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.399200",
    Latitude: "-36.540900"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "GRAYS BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.899200",
    Latitude: "-36.618000"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "GRE GRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.091000",
    Latitude: "-36.676600"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "GRE GRE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.071200",
    Latitude: "-36.605300"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "GRE GRE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.018600",
    Latitude: "-36.681000"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "KOOREH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.395600",
    Latitude: "-36.646300"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "MARNOO EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.959300",
    Latitude: "-36.652400"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "MOOLERR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.169000",
    Latitude: "-36.647300"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "MOYREISK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.380400",
    Latitude: "-36.878400"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "PARADISE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.068100",
    Latitude: "-36.828900"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "REDBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.300000",
    Latitude: "-36.905500"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "ROSTRON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.171100",
    Latitude: "-36.764800"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "SLATY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.292500",
    Latitude: "-36.539200"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "ST ARNAUD EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.324700",
    Latitude: "-36.628200"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "ST ARNAUD NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.165900",
    Latitude: "-36.592200"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "STUART MILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.282900",
    Latitude: "-36.793100"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "SUTHERLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.195800",
    Latitude: "-36.533500"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "SWANWATER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.104500",
    Latitude: "-36.539300"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "TOTTINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.079300",
    Latitude: "-36.766700"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "TRAYNORS LAGOON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.985200",
    Latitude: "-36.575700"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "WINJALLOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.193000",
    Latitude: "-36.839600"
  },
  {
    Country: "Australia",
    Pcode: "3477",
    Locality: "YORK PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.917500",
    Latitude: "-36.586300"
  },
  {
    Country: "Australia",
    Pcode: "3478",
    Locality: "DOOBOOBETIC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.197400",
    Latitude: "-36.377300"
  },
  {
    Country: "Australia",
    Pcode: "3478",
    Locality: "MEDLYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3478",
    Locality: "MOONAMBEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.304100",
    Latitude: "-36.984300"
  },
  {
    Country: "Australia",
    Pcode: "3478",
    Locality: "PERCYDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.387000",
    Latitude: "-37.077100"
  },
  {
    Country: "Australia",
    Pcode: "3478",
    Locality: "ST ARNAUD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.258500",
    Latitude: "-36.624300"
  },
  {
    Country: "Australia",
    Pcode: "3478",
    Locality: "TANWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.387300",
    Latitude: "-37.008200"
  },
  {
    Country: "Australia",
    Pcode: "3478",
    Locality: "WARRENMANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.297800",
    Latitude: "-37.070800"
  },
  {
    Country: "Australia",
    Pcode: "3478",
    Locality: "YAWONG HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.386800",
    Latitude: "-36.470000"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "AREEGRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.679900",
    Latitude: "-36.236500"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "CARRON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.750400",
    Latitude: "-36.282200"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "COPE COPE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.055400",
    Latitude: "-36.469800"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "CORACK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.026800",
    Latitude: "-36.190800"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "CORACK EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.050700",
    Latitude: "-36.150700"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "DONALD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.954900",
    Latitude: "-36.302000"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "GIL GIL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.035300",
    Latitude: "-36.322100"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "JEFFCOTT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.141000",
    Latitude: "-36.316300"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "JEFFCOTT NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.070800",
    Latitude: "-36.265200"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "LAEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.828300",
    Latitude: "-36.439500"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "LAEN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.900300",
    Latitude: "-36.398800"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "LAEN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.832100",
    Latitude: "-36.378000"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "LAWLER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.745300",
    Latitude: "-36.366400"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "LITCHFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.837500",
    Latitude: "-36.294600"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "RICH AVON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.910400",
    Latitude: "-36.444700"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "RICH AVON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.893700",
    Latitude: "-36.513300"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "RICH AVON WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.799700",
    Latitude: "-36.504600"
  },
  {
    Country: "Australia",
    Pcode: "3480",
    Locality: "SWANWATER WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.016200",
    Latitude: "-36.520600"
  },
  {
    Country: "Australia",
    Pcode: "3482",
    Locality: "MASSEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.860100",
    Latitude: "-36.221600"
  },
  {
    Country: "Australia",
    Pcode: "3482",
    Locality: "MORTON PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.907300",
    Latitude: "-36.074200"
  },
  {
    Country: "Australia",
    Pcode: "3482",
    Locality: "WARMUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.747000",
    Latitude: "-36.086800"
  },
  {
    Country: "Australia",
    Pcode: "3482",
    Locality: "WATCHEM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.874600",
    Latitude: "-36.149300"
  },
  {
    Country: "Australia",
    Pcode: "3482",
    Locality: "WATCHEM WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.733600",
    Latitude: "-36.187800"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "BALLAPUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.747800",
    Latitude: "-35.979100"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "BIRCHIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.924700",
    Latitude: "-35.997300"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "BIRCHIP WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.821800",
    Latitude: "-36.014400"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "CURYO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.710400",
    Latitude: "-35.838500"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "JIL JIL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.978500",
    Latitude: "-35.822500"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "KARYRIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.868800",
    Latitude: "-35.927000"
  },
  {
    Country: "Australia",
    Pcode: "3483",
    Locality: "KINNABULLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.720100",
    Latitude: "-35.900100"
  },
  {
    Country: "Australia",
    Pcode: "3531",
    Locality: "BERRIWILLOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.961100",
    Latitude: "-35.644300"
  },
  {
    Country: "Australia",
    Pcode: "3531",
    Locality: "BOIGBEAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.957200",
    Latitude: "-35.562400"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "BIMBOURIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.761500",
    Latitude: "-35.335000"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "LAKE TYRRELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.836500",
    Latitude: "-35.339500"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "MYALL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "SEA LAKE",
    Category: "Delivery Area",
    Longitude: "142.758000",
    Latitude: "-35.540500"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "NANDALY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.678100",
    Latitude: "-35.312800"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "NINDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.709000",
    Latitude: "-35.433800"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "NYARRIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.675100",
    Latitude: "-35.389800"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "PIER MILAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.697200",
    Latitude: "-35.235900"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "SEA LAKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.895500",
    Latitude: "-35.486800"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "STRATEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.574200",
    Latitude: "-35.380900"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "TYENNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.575900",
    Latitude: "-35.324400"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "TYRRELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.986800",
    Latitude: "-35.432300"
  },
  {
    Country: "Australia",
    Pcode: "3533",
    Locality: "TYRRELL DOWNS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.923900",
    Latitude: "-35.327400"
  },
  {
    Country: "Australia",
    Pcode: "3537",
    Locality: "BARRAPORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.655100",
    Latitude: "-35.998100"
  },
  {
    Country: "Australia",
    Pcode: "3537",
    Locality: "BARRAPORT WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.527800",
    Latitude: "-36.015900"
  },
  {
    Country: "Australia",
    Pcode: "3537",
    Locality: "BOORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.741900",
    Latitude: "-36.123500"
  },
  {
    Country: "Australia",
    Pcode: "3537",
    Locality: "CANARY ISLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.874000",
    Latitude: "-36.003900"
  },
  {
    Country: "Australia",
    Pcode: "3537",
    Locality: "CATUMNAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.618200",
    Latitude: "-36.078200"
  },
  {
    Country: "Australia",
    Pcode: "3537",
    Locality: "GREDGWIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.613900",
    Latitude: "-35.959700"
  },
  {
    Country: "Australia",
    Pcode: "3537",
    Locality: "LEAGHUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.801100",
    Latitude: "-35.982000"
  },
  {
    Country: "Australia",
    Pcode: "3537",
    Locality: "MINMINDIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.756300",
    Latitude: "-36.016200"
  },
  {
    Country: "Australia",
    Pcode: "3537",
    Locality: "YANDO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.814900",
    Latitude: "-36.071300"
  },
  {
    Country: "Australia",
    Pcode: "3540",
    Locality: "CANNIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.525400",
    Latitude: "-35.754200"
  },
  {
    Country: "Australia",
    Pcode: "3540",
    Locality: "OAKVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.541800",
    Latitude: "-35.929200"
  },
  {
    Country: "Australia",
    Pcode: "3540",
    Locality: "QUAMBATOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.538200",
    Latitude: "-35.851200"
  },
  {
    Country: "Australia",
    Pcode: "3542",
    Locality: "COKUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.254800",
    Latitude: "-35.755700"
  },
  {
    Country: "Australia",
    Pcode: "3542",
    Locality: "LALBERT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.438800",
    Latitude: "-35.647600"
  },
  {
    Country: "Australia",
    Pcode: "3542",
    Locality: "TITTYBONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.360300",
    Latitude: "-35.766300"
  },
  {
    Country: "Australia",
    Pcode: "3544",
    Locality: "CHINANGIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.181300",
    Latitude: "-35.532700"
  },
  {
    Country: "Australia",
    Pcode: "3544",
    Locality: "GOWANFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.191600",
    Latitude: "-35.410700"
  },
  {
    Country: "Australia",
    Pcode: "3544",
    Locality: "MURNUNGIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.238700",
    Latitude: "-35.614700"
  },
  {
    Country: "Australia",
    Pcode: "3544",
    Locality: "SPRINGFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "SEA LAKE",
    Category: "Delivery Area",
    Longitude: "143.115600",
    Latitude: "-35.572600"
  },
  {
    Country: "Australia",
    Pcode: "3544",
    Locality: "ULTIMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.266600",
    Latitude: "-35.480000"
  },
  {
    Country: "Australia",
    Pcode: "3544",
    Locality: "ULTIMA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.374000",
    Latitude: "-35.432900"
  },
  {
    Country: "Australia",
    Pcode: "3544",
    Locality: "WAITCHIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.116800",
    Latitude: "-35.396000"
  },
  {
    Country: "Australia",
    Pcode: "3546",
    Locality: "BOLTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.937500",
    Latitude: "-34.961500"
  },
  {
    Country: "Australia",
    Pcode: "3546",
    Locality: "CHINKAPOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.944700",
    Latitude: "-35.181500"
  },
  {
    Country: "Australia",
    Pcode: "3546",
    Locality: "COCAMBA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.900100",
    Latitude: "-35.109900"
  },
  {
    Country: "Australia",
    Pcode: "3546",
    Locality: "GERAHMIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.795500",
    Latitude: "-35.180500"
  },
  {
    Country: "Australia",
    Pcode: "3546",
    Locality: "MANANGATANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.900500",
    Latitude: "-35.054800"
  },
  {
    Country: "Australia",
    Pcode: "3546",
    Locality: "TUROAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.079100",
    Latitude: "-35.178300"
  },
  {
    Country: "Australia",
    Pcode: "3546",
    Locality: "WINNAMBOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.768800",
    Latitude: "-34.960100"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "ANNUELLO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.801000",
    Latitude: "-34.852600"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "BANNERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.797900",
    Latitude: "-34.737700"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "HAPPY VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "ROBINVALE",
    Category: "Delivery Area",
    Longitude: "142.713000",
    Latitude: "-34.694900"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "LIPAROO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.572900",
    Latitude: "-34.808500"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "ROBINVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.752300",
    Latitude: "-34.614100"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "ROBINVALE IRRIGATION DISTRICT SECTION B",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "ROBINVALE IRRIGATION DISTRICT SECTION C",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "ROBINVALE IRRIGATION DISTRICT SECTION D",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "ROBINVALE IRRIGATION DISTRICT SECTION E",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "TOL TOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.819800",
    Latitude: "-34.649400"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "WANDOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.938200",
    Latitude: "-34.820500"
  },
  {
    Country: "Australia",
    Pcode: "3549",
    Locality: "WEMEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.672600",
    Latitude: "-34.810600"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "BENDIGO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.280900",
    Latitude: "-36.756100"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "BENDIGO SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "EAST BENDIGO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.327200",
    Latitude: "-36.737600"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "FLORA HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.296000",
    Latitude: "-36.779800"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "IRONBARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.263900",
    Latitude: "-36.755100"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "KENNINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.302400",
    Latitude: "-36.768100"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "LONG GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.259300",
    Latitude: "-36.743300"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "NORTH BENDIGO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.280400",
    Latitude: "-36.737600"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "QUARRY HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.279700",
    Latitude: "-36.776500"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "SANDHURST EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "SPRING GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.288600",
    Latitude: "-36.799600"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "STRATHDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.314800",
    Latitude: "-36.776500"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "WEST BENDIGO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.245600",
    Latitude: "-36.751500"
  },
  {
    Country: "Australia",
    Pcode: "3550",
    Locality: "WHITE HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.303600",
    Latitude: "-36.728400"
  },
  {
    Country: "Australia",
    Pcode: "3610",
    Locality: "MOORILIM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.303700",
    Latitude: "-36.633600"
  },
  {
    Country: "Australia",
    Pcode: "3610",
    Locality: "MURCHISON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.186800",
    Latitude: "-36.629100"
  },
  {
    Country: "Australia",
    Pcode: "3610",
    Locality: "MURCHISON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.246100",
    Latitude: "-36.614400"
  },
  {
    Country: "Australia",
    Pcode: "3610",
    Locality: "MURCHISON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.270500",
    Latitude: "-36.570500"
  },
  {
    Country: "Australia",
    Pcode: "3612",
    Locality: "MOORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.947500",
    Latitude: "-36.605100"
  },
  {
    Country: "Australia",
    Pcode: "3612",
    Locality: "RUSHWORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.018100",
    Latitude: "-36.566500"
  },
  {
    Country: "Australia",
    Pcode: "3612",
    Locality: "WANALTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.900000",
    Latitude: "-36.598900"
  },
  {
    Country: "Australia",
    Pcode: "3612",
    Locality: "WARANGA SHORES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.090300",
    Latitude: "-36.552800"
  },
  {
    Country: "Australia",
    Pcode: "3612",
    Locality: "WHROO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.050300",
    Latitude: "-36.688000"
  },
  {
    Country: "Australia",
    Pcode: "3614",
    Locality: "TOOLAMBA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.334100",
    Latitude: "-36.496100"
  },
  {
    Country: "Australia",
    Pcode: "3614",
    Locality: "TOOLAMBA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.268200",
    Latitude: "-36.480500"
  },
  {
    Country: "Australia",
    Pcode: "3616",
    Locality: "COOMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.072800",
    Latitude: "-36.409000"
  },
  {
    Country: "Australia",
    Pcode: "3616",
    Locality: "GILLIESTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.204600",
    Latitude: "-36.335700"
  },
  {
    Country: "Australia",
    Pcode: "3616",
    Locality: "GIRGARRE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.072800",
    Latitude: "-36.453000"
  },
  {
    Country: "Australia",
    Pcode: "3616",
    Locality: "HARSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.118200",
    Latitude: "-36.463000"
  },
  {
    Country: "Australia",
    Pcode: "3616",
    Locality: "MOOROOPNA NORTH WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.250000",
    Latitude: "-36.350400"
  },
  {
    Country: "Australia",
    Pcode: "3616",
    Locality: "TATURA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.222700",
    Latitude: "-36.443400"
  },
  {
    Country: "Australia",
    Pcode: "3616",
    Locality: "TATURA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.295500",
    Latitude: "-36.440200"
  },
  {
    Country: "Australia",
    Pcode: "3616",
    Locality: "WARANGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.068300",
    Latitude: "-36.491700"
  },
  {
    Country: "Australia",
    Pcode: "3617",
    Locality: "BYRNESIDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.159200",
    Latitude: "-36.409200"
  },
  {
    Country: "Australia",
    Pcode: "3618",
    Locality: "MERRIGUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.154100",
    Latitude: "-36.368700"
  },
  {
    Country: "Australia",
    Pcode: "3619",
    Locality: "KYABRAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3620",
    Locality: "KYABRAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.017700",
    Latitude: "-36.326800"
  },
  {
    Country: "Australia",
    Pcode: "3620",
    Locality: "KYABRAM SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.067800",
    Latitude: "-36.386900"
  },
  {
    Country: "Australia",
    Pcode: "3620",
    Locality: "LANCASTER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.145000",
    Latitude: "-36.298900"
  },
  {
    Country: "Australia",
    Pcode: "3620",
    Locality: "ST GERMAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.154200",
    Latitude: "-36.206700"
  },
  {
    Country: "Australia",
    Pcode: "3620",
    Locality: "WYUNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.068700",
    Latitude: "-36.208800"
  },
  {
    Country: "Australia",
    Pcode: "3620",
    Locality: "WYUNA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.090700",
    Latitude: "-36.240000"
  },
  {
    Country: "Australia",
    Pcode: "3621",
    Locality: "KYVALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.006600",
    Latitude: "-36.281400"
  },
  {
    Country: "Australia",
    Pcode: "3621",
    Locality: "TONGALA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.959300",
    Latitude: "-36.242100"
  },
  {
    Country: "Australia",
    Pcode: "3621",
    Locality: "YAMBUNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.972800",
    Latitude: "-36.152900"
  },
  {
    Country: "Australia",
    Pcode: "3622",
    Locality: "KOYUGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.881900",
    Latitude: "-36.235600"
  },
  {
    Country: "Australia",
    Pcode: "3622",
    Locality: "STRATHALLAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.770300",
    Latitude: "-36.262100"
  },
  {
    Country: "Australia",
    Pcode: "3623",
    Locality: "CARAG CARAG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.890800",
    Latitude: "-36.462600"
  },
  {
    Country: "Australia",
    Pcode: "3623",
    Locality: "STANHOPE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.981800",
    Latitude: "-36.462600"
  },
  {
    Country: "Australia",
    Pcode: "3623",
    Locality: "STANHOPE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.041000",
    Latitude: "-36.491700"
  },
  {
    Country: "Australia",
    Pcode: "3624",
    Locality: "GIRGARRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.970600",
    Latitude: "-36.390800"
  },
  {
    Country: "Australia",
    Pcode: "3629",
    Locality: "ARDMONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.298300",
    Latitude: "-36.384200"
  },
  {
    Country: "Australia",
    Pcode: "3629",
    Locality: "COOMBOONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.299100",
    Latitude: "-36.268700"
  },
  {
    Country: "Australia",
    Pcode: "3629",
    Locality: "MOOROOPNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.348200",
    Latitude: "-36.393800"
  },
  {
    Country: "Australia",
    Pcode: "3629",
    Locality: "MOOROOPNA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.317600",
    Latitude: "-36.328400"
  },
  {
    Country: "Australia",
    Pcode: "3629",
    Locality: "UNDERA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.213600",
    Latitude: "-36.238200"
  },
  {
    Country: "Australia",
    Pcode: "3630",
    Locality: "BRANDITT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3630",
    Locality: "CANIAMBO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.599700",
    Latitude: "-36.470700"
  },
  {
    Country: "Australia",
    Pcode: "3630",
    Locality: "COLLIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3630",
    Locality: "DUNKIRK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3630",
    Locality: "SHEPPARTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.403100",
    Latitude: "-36.373300"
  },
  {
    Country: "Australia",
    Pcode: "3630",
    Locality: "SHEPPARTON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "ARCADIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.385600",
    Latitude: "-36.555600"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "ARCADIA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.381600",
    Latitude: "-36.591700"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "COSGROVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.634300",
    Latitude: "-36.347400"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "COSGROVE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.633300",
    Latitude: "-36.413000"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "GRAHAMVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.435000",
    Latitude: "-36.350900"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "KARRAMOMUS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.493700",
    Latitude: "-36.527500"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "KIALLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.406400",
    Latitude: "-36.445000"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "KIALLA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.496500",
    Latitude: "-36.461600"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "KIALLA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.389400",
    Latitude: "-36.478000"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "LEMNOS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.474900",
    Latitude: "-36.344400"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "ORRVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.446800",
    Latitude: "-36.411900"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "PINE LODGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.546700",
    Latitude: "-36.361900"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "SHEPPARTON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.496800",
    Latitude: "-36.399500"
  },
  {
    Country: "Australia",
    Pcode: "3631",
    Locality: "SHEPPARTON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.388600",
    Latitude: "-36.321400"
  },
  {
    Country: "Australia",
    Pcode: "3632",
    Locality: "SHEPPARTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3633",
    Locality: "CONGUPNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.475000",
    Latitude: "-36.287700"
  },
  {
    Country: "Australia",
    Pcode: "3634",
    Locality: "BUNBARTHA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.324100",
    Latitude: "-36.225100"
  },
  {
    Country: "Australia",
    Pcode: "3634",
    Locality: "KATANDRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.602400",
    Latitude: "-36.249100"
  },
  {
    Country: "Australia",
    Pcode: "3634",
    Locality: "KATANDRA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.558100",
    Latitude: "-36.223200"
  },
  {
    Country: "Australia",
    Pcode: "3634",
    Locality: "MARIONVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.527200",
    Latitude: "-36.262700"
  },
  {
    Country: "Australia",
    Pcode: "3634",
    Locality: "MARUNGI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.531800",
    Latitude: "-36.183900"
  },
  {
    Country: "Australia",
    Pcode: "3634",
    Locality: "TALLYGAROOPNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.440700",
    Latitude: "-36.239700"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "EVERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.561800",
    Latitude: "-36.442900"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "EVERTON UPPER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.562600",
    Latitude: "-36.377000"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "KILLAWARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.215800",
    Latitude: "-36.251300"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "KING VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.408200",
    Latitude: "-36.694700"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "LACEBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.293700",
    Latitude: "-36.455000"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "LONDRIGAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.437600",
    Latitude: "-36.344300"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "MARKWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.506600",
    Latitude: "-36.475100"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "MEADOW CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.448900",
    Latitude: "-36.597700"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "MILAWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.448900",
    Latitude: "-36.452100"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "NORTH WANGARATTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.329900",
    Latitude: "-36.320900"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "OXLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.376800",
    Latitude: "-36.443200"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "OXLEY FLATS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.393300",
    Latitude: "-36.397900"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "PEECHELBA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.180000",
    Latitude: "-36.167900"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "PEECHELBA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.259200",
    Latitude: "-36.155300"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "ROSE RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.571800",
    Latitude: "-36.918100"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "TARRAWINGEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.461100",
    Latitude: "-36.386100"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "WABONGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.571900",
    Latitude: "-37.044300"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "WALDARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.284400",
    Latitude: "-36.319600"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "WANGANDARY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.227900",
    Latitude: "-36.328800"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "WANGARATTA FORWARD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "WANGARATTA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.251600",
    Latitude: "-36.395900"
  },
  {
    Country: "Australia",
    Pcode: "3678",
    Locality: "WHITLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.373800",
    Latitude: "-36.812300"
  },
  {
    Country: "Australia",
    Pcode: "3682",
    Locality: "BORALMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.399500",
    Latitude: "-36.239300"
  },
  {
    Country: "Australia",
    Pcode: "3682",
    Locality: "LILLIPUT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "SPRINGHURST",
    Category: "Delivery Area",
    Longitude: "146.456700",
    Latitude: "-36.127800"
  },
  {
    Country: "Australia",
    Pcode: "3682",
    Locality: "NORONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.346000",
    Latitude: "-36.090200"
  },
  {
    Country: "Australia",
    Pcode: "3682",
    Locality: "SPRINGHURST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.452200",
    Latitude: "-36.213700"
  },
  {
    Country: "Australia",
    Pcode: "3683",
    Locality: "CHILTERN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.621400",
    Latitude: "-36.186000"
  },
  {
    Country: "Australia",
    Pcode: "3683",
    Locality: "CHILTERN VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.550400",
    Latitude: "-36.143400"
  },
  {
    Country: "Australia",
    Pcode: "3683",
    Locality: "CORNISHTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.571300",
    Latitude: "-36.105000"
  },
  {
    Country: "Australia",
    Pcode: "3685",
    Locality: "BOORHAMAN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.250300",
    Latitude: "-36.102900"
  },
  {
    Country: "Australia",
    Pcode: "3685",
    Locality: "BRIMIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.260000",
    Latitude: "-36.052000"
  },
  {
    Country: "Australia",
    Pcode: "3685",
    Locality: "BROWNS PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.620800",
    Latitude: "-36.036200"
  },
  {
    Country: "Australia",
    Pcode: "3685",
    Locality: "CARLYLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.485900",
    Latitude: "-35.996400"
  },
  {
    Country: "Australia",
    Pcode: "3685",
    Locality: "GOORAMADDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.566200",
    Latitude: "-35.994900"
  },
  {
    Country: "Australia",
    Pcode: "3685",
    Locality: "GREAT SOUTHERN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3685",
    Locality: "PRENTICE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3685",
    Locality: "RUTHERGLEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.488500",
    Latitude: "-36.063900"
  },
  {
    Country: "Australia",
    Pcode: "3687",
    Locality: "WAHGUNYAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.425500",
    Latitude: "-36.002800"
  },
  {
    Country: "Australia",
    Pcode: "3688",
    Locality: "BARNAWARTHA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.672400",
    Latitude: "-36.069600"
  },
  {
    Country: "Australia",
    Pcode: "3688",
    Locality: "INDIGO VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.738000",
    Latitude: "-36.186900"
  },
  {
    Country: "Australia",
    Pcode: "3689",
    Locality: "WODONGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3690",
    Locality: "WEST WODONGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.821100",
    Latitude: "-36.104000"
  },
  {
    Country: "Australia",
    Pcode: "3690",
    Locality: "WODONGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.882400",
    Latitude: "-36.124600"
  },
  {
    Country: "Australia",
    Pcode: "3690",
    Locality: "WODONGA PLAZA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "ALLANS FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.944200",
    Latitude: "-36.297300"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "BANDIANA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.930300",
    Latitude: "-36.135400"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "BARANDUDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.950800",
    Latitude: "-36.184700"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "BARNAWARTHA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.750500",
    Latitude: "-36.079400"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "BELLBRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.058500",
    Latitude: "-36.098000"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "BERRINGAMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.666600",
    Latitude: "-36.197400"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "BETHANGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.116200",
    Latitude: "-36.151900"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "BONEGILLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.992200",
    Latitude: "-36.137900"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "BUNGIL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.402200",
    Latitude: "-36.078300"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "CASTLE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.823700",
    Latitude: "-36.183300"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "CORAL BANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.107400",
    Latitude: "-36.619700"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "DEDERANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.002300",
    Latitude: "-36.475300"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "EBDEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.040600",
    Latitude: "-36.162500"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "GATEWAY ISLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.896700",
    Latitude: "-36.096700"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "GLEN CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.940200",
    Latitude: "-36.437100"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "GUNDOWRING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.077300",
    Latitude: "-36.375800"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "HUON CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.822300",
    Latitude: "-36.155900"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "KANCOONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.007500",
    Latitude: "-36.595300"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "KERGUNYAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.984800",
    Latitude: "-36.344900"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "KERGUNYAH SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.025700",
    Latitude: "-36.424100"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "KIEWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.013300",
    Latitude: "-36.269500"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "KILLARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "WODONGA",
    Category: "Delivery Area",
    Longitude: "146.948200",
    Latitude: "-36.140600"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "LAKE HUME VILLAGE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "ALBURY",
    Category: "Delivery Area",
    Longitude: "147.033000",
    Latitude: "-36.094100"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "LENEVA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.859300",
    Latitude: "-36.204900"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "LUCYVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.605200",
    Latitude: "-36.303900"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "MONGANS BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.130200",
    Latitude: "-36.589500"
  },
  {
    Country: "Australia",
    Pcode: "3764",
    Locality: "KILMORE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.012100",
    Latitude: "-37.273400"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "MOROKA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.951600",
    Latitude: "-37.493100"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "NAP NAP MARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.844300",
    Latitude: "-37.574400"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "RIVERSLEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.950600",
    Latitude: "-38.006700"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "TOOLOME",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.055300",
    Latitude: "-37.613400"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "VALENCIA CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.986400",
    Latitude: "-37.784800"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "WOOLENOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.037600",
    Latitude: "-37.705600"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "WRATHUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.949100",
    Latitude: "-37.594600"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "WRIXON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.850900",
    Latitude: "-37.683800"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "BUDGEE BUDGEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.090500",
    Latitude: "-37.549100"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "COBBANNAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.280000",
    Latitude: "-37.652300"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "COWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.193300",
    Latitude: "-37.385500"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "CROOKED RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.999600",
    Latitude: "-37.387700"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "DARGO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.302000",
    Latitude: "-37.334300"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "HAWKHURST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.167300",
    Latitude: "-37.448900"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "HOLLANDS LANDING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.459400",
    Latitude: "-38.052500"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "LLOWALONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.041500",
    Latitude: "-37.905400"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "MEERLIEU",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.404700",
    Latitude: "-38.013100"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "MIOWERA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.085900",
    Latitude: "-37.477400"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "MOORNAPA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.183300",
    Latitude: "-37.688700"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "MUNRO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.212700",
    Latitude: "-37.898400"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "PERRY BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.262700",
    Latitude: "-37.983900"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "STOCKDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.197600",
    Latitude: "-37.825500"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "STRATFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.116400",
    Latitude: "-37.945200"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "WATERFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3862",
    Locality: "WONGUNGARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.051500",
    Latitude: "-37.254300"
  },
  {
    Country: "Australia",
    Pcode: "3864",
    Locality: "FERNBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.326700",
    Latitude: "-37.862800"
  },
  {
    Country: "Australia",
    Pcode: "3864",
    Locality: "GLENALADALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.318200",
    Latitude: "-37.749600"
  },
  {
    Country: "Australia",
    Pcode: "3865",
    Locality: "LINDENOW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.467500",
    Latitude: "-37.796600"
  },
  {
    Country: "Australia",
    Pcode: "3869",
    Locality: "JUMBUK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.424600",
    Latitude: "-38.399200"
  },
  {
    Country: "Australia",
    Pcode: "3869",
    Locality: "YINNAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.301100",
    Latitude: "-38.334900"
  },
  {
    Country: "Australia",
    Pcode: "3869",
    Locality: "YINNAR SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.356100",
    Latitude: "-38.370400"
  },
  {
    Country: "Australia",
    Pcode: "3870",
    Locality: "BOOLARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.277300",
    Latitude: "-38.382500"
  },
  {
    Country: "Australia",
    Pcode: "3870",
    Locality: "BOOLARRA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.276800",
    Latitude: "-38.425000"
  },
  {
    Country: "Australia",
    Pcode: "3870",
    Locality: "BUDGEREE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.359900",
    Latitude: "-38.419300"
  },
  {
    Country: "Australia",
    Pcode: "3870",
    Locality: "GRAND RIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.394900",
    Latitude: "-38.489000"
  },
  {
    Country: "Australia",
    Pcode: "3870",
    Locality: "JOHNSTONES HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3871",
    Locality: "ALLAMBEE RESERVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.049100",
    Latitude: "-38.307900"
  },
  {
    Country: "Australia",
    Pcode: "3871",
    Locality: "ALLAMBEE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.101500",
    Latitude: "-38.353400"
  },
  {
    Country: "Australia",
    Pcode: "3871",
    Locality: "BAROMI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.175400",
    Latitude: "-38.403600"
  },
  {
    Country: "Australia",
    Pcode: "3871",
    Locality: "DARLIMURLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.207700",
    Latitude: "-38.373500"
  },
  {
    Country: "Australia",
    Pcode: "3871",
    Locality: "DELBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.228800",
    Latitude: "-38.335600"
  },
  {
    Country: "Australia",
    Pcode: "3871",
    Locality: "DOLLAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.180600",
    Latitude: "-38.559500"
  },
  {
    Country: "Australia",
    Pcode: "3871",
    Locality: "MIRBOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.235700",
    Latitude: "-38.482400"
  },
  {
    Country: "Australia",
    Pcode: "3871",
    Locality: "MIRBOO NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.155500",
    Latitude: "-38.394100"
  },
  {
    Country: "Australia",
    Pcode: "3873",
    Locality: "GORMANDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.701500",
    Latitude: "-38.303300"
  },
  {
    Country: "Australia",
    Pcode: "3874",
    Locality: "MCLOUGHLINS BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.902200",
    Latitude: "-38.605300"
  },
  {
    Country: "Australia",
    Pcode: "3874",
    Locality: "WOODSIDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.899400",
    Latitude: "-38.537700"
  },
  {
    Country: "Australia",
    Pcode: "3874",
    Locality: "WOODSIDE BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.978000",
    Latitude: "-38.543800"
  },
  {
    Country: "Australia",
    Pcode: "3874",
    Locality: "WOODSIDE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.820900",
    Latitude: "-38.424700"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "BAIRNSDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.603700",
    Latitude: "-37.853200"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "BANKSIA PENINSULA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.681500",
    Latitude: "-37.949700"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "BENGWORDEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.439100",
    Latitude: "-37.974400"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "BROADLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.690100",
    Latitude: "-37.859500"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "BULLUMWAAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.582200",
    Latitude: "-37.642100"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "CALULU",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.508300",
    Latitude: "-37.788600"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "CLIFTON CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.664100",
    Latitude: "-37.710200"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "DEPTFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.689800",
    Latitude: "-37.571300"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "EAST BAIRNSDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.666700",
    Latitude: "-37.834400"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "EASTWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.633700",
    Latitude: "-37.813000"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "ELLASWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.572100",
    Latitude: "-37.782000"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "FAIRY DELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "BAIRNSDALE",
    Category: "Delivery Area",
    Longitude: "147.740300",
    Latitude: "-37.695300"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "FLAGGY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.480400",
    Latitude: "-37.752200"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "FORGE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.639400",
    Latitude: "-37.932300"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "GOON NURE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.564700",
    Latitude: "-37.977300"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "GRANITE ROCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.675900",
    Latitude: "-37.756200"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "HILLSIDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "BAIRNSDALE",
    Category: "Delivery Area",
    Longitude: "147.514700",
    Latitude: "-37.846300"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "IGUANA CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.348000",
    Latitude: "-37.694500"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "LINDENOW SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.406200",
    Latitude: "-37.870400"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "LUCKNOW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.670100",
    Latitude: "-37.804400"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "MARTHAVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.509400",
    Latitude: "-37.447800"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "CRANBOURNE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.306500",
    Latitude: "-38.113400"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "CRANBOURNE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.298700",
    Latitude: "-38.077500"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "CRANBOURNE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.241200",
    Latitude: "-38.149200"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "CRANBOURNE WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.250800",
    Latitude: "-38.098100"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "DEVON MEADOWS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.316100",
    Latitude: "-38.174500"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "JUNCTION VILLAGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.291900",
    Latitude: "-38.135500"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "SANDHURST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.210700",
    Latitude: "-38.081600"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "SKYE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.215200",
    Latitude: "-38.104400"
  },
  {
    Country: "Australia",
    Pcode: "3978",
    Locality: "CARDINIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.417800",
    Latitude: "-38.143900"
  },
  {
    Country: "Australia",
    Pcode: "3978",
    Locality: "CLYDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.356700",
    Latitude: "-38.150700"
  },
  {
    Country: "Australia",
    Pcode: "3978",
    Locality: "CLYDE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.360400",
    Latitude: "-38.100000"
  },
  {
    Country: "Australia",
    Pcode: "3979",
    Locality: "ALMURTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.580200",
    Latitude: "-38.455400"
  },
  {
    Country: "Australia",
    Pcode: "3979",
    Locality: "GLEN ALVIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.650100",
    Latitude: "-38.501500"
  },
  {
    Country: "Australia",
    Pcode: "3979",
    Locality: "KERNOT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.613700",
    Latitude: "-38.439800"
  },
  {
    Country: "Australia",
    Pcode: "3980",
    Locality: "BLIND BIGHT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.341000",
    Latitude: "-38.206800"
  },
  {
    Country: "Australia",
    Pcode: "3980",
    Locality: "TOORADIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.385400",
    Latitude: "-38.198700"
  },
  {
    Country: "Australia",
    Pcode: "3980",
    Locality: "WARNEET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.317900",
    Latitude: "-38.224400"
  },
  {
    Country: "Australia",
    Pcode: "3981",
    Locality: "BAYLES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.575800",
    Latitude: "-38.177200"
  },
  {
    Country: "Australia",
    Pcode: "3981",
    Locality: "CATANI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.648400",
    Latitude: "-38.192400"
  },
  {
    Country: "Australia",
    Pcode: "3981",
    Locality: "DALMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.418200",
    Latitude: "-38.186700"
  },
  {
    Country: "Australia",
    Pcode: "3981",
    Locality: "HEATH HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.707200",
    Latitude: "-38.239200"
  },
  {
    Country: "Australia",
    Pcode: "3981",
    Locality: "KOO WEE RUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.498700",
    Latitude: "-38.190700"
  },
  {
    Country: "Australia",
    Pcode: "3981",
    Locality: "KOO WEE RUP NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.543000",
    Latitude: "-38.142200"
  },
  {
    Country: "Australia",
    Pcode: "3981",
    Locality: "YANNATHAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.650000",
    Latitude: "-38.221400"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "ADAMS ESTATE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.531300",
    Latitude: "-38.424600"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "CALDERMEADE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.522200",
    Latitude: "-38.247900"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "CORINELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.476400",
    Latitude: "-38.425100"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "CORONET BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.442700",
    Latitude: "-38.435000"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "GRANTVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.539300",
    Latitude: "-38.412200"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "JAM JERRUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.539200",
    Latitude: "-38.322700"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "LANG LANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.576100",
    Latitude: "-38.305300"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "LANG LANG EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.656000",
    Latitude: "-38.269900"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "MONOMEITH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.532200",
    Latitude: "-38.212600"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "PIONEER BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.549100",
    Latitude: "-38.380900"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "QUEENSFERRY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.501300",
    Latitude: "-38.418800"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "TENBY POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.474800",
    Latitude: "-38.414300"
  },
  {
    Country: "Australia",
    Pcode: "3984",
    Locality: "THE GURDIES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.566200",
    Latitude: "-38.386800"
  },
  {
    Country: "Australia",
    Pcode: "3987",
    Locality: "NYORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.681800",
    Latitude: "-38.307000"
  },
  {
    Country: "Australia",
    Pcode: "3988",
    Locality: "MOUNTAIN VIEW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.864600",
    Latitude: "-38.303000"
  },
  {
    Country: "Australia",
    Pcode: "3988",
    Locality: "POOWONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.763300",
    Latitude: "-38.339800"
  },
  {
    Country: "Australia",
    Pcode: "3988",
    Locality: "POOWONG EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.836400",
    Latitude: "-38.330900"
  },
  {
    Country: "Australia",
    Pcode: "3988",
    Locality: "POOWONG NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.784900",
    Latitude: "-38.284800"
  },
  {
    Country: "Australia",
    Pcode: "3990",
    Locality: "GLEN FORBES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.514000",
    Latitude: "-38.448800"
  },
  {
    Country: "Australia",
    Pcode: "3991",
    Locality: "BASS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.470700",
    Latitude: "-38.478000"
  },
  {
    Country: "Australia",
    Pcode: "3992",
    Locality: "BLACKWOOD FOREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3992",
    Locality: "DALYSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.546700",
    Latitude: "-38.568000"
  },
  {
    Country: "Australia",
    Pcode: "3992",
    Locality: "RYANSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.587400",
    Latitude: "-38.508300"
  },
  {
    Country: "Australia",
    Pcode: "3992",
    Locality: "WEST CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.609500",
    Latitude: "-38.554900"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "ANDERSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.448400",
    Latitude: "-38.523200"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "ARCHIES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.564900",
    Latitude: "-38.548800"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "CAPE PATERSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.617800",
    Latitude: "-38.656800"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "HARMERS HAVEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.577700",
    Latitude: "-38.655500"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "KILCUNDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.505800",
    Latitude: "-38.557200"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "LANCE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.670700",
    Latitude: "-38.546000"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "NORTH WONTHAGGI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.592900",
    Latitude: "-38.577900"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "SOUTH DUDLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.575000",
    Latitude: "-38.593200"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "ST CLAIR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.651100",
    Latitude: "-38.583900"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "WATTLE BANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.706400",
    Latitude: "-38.575100"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "WONTHAGGI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.588100",
    Latitude: "-38.618800"
  },
  {
    Country: "Australia",
    Pcode: "3995",
    Locality: "WOOLAMAI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.514500",
    Latitude: "-38.505400"
  },
  {
    Country: "Australia",
    Pcode: "3996",
    Locality: "INVERLOCH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.711400",
    Latitude: "-38.620400"
  },
  {
    Country: "Australia",
    Pcode: "3996",
    Locality: "POUND CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.852200",
    Latitude: "-38.619100"
  },
  {
    Country: "Australia",
    Pcode: "4000",
    Locality: "BRISBANE ADELAIDE STREET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4000",
    Locality: "BRISBANE CITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.025800",
    Latitude: "-27.470400"
  },
  {
    Country: "Australia",
    Pcode: "4000",
    Locality: "BRISBANE GPO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4000",
    Locality: "PETRIE TERRACE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.013300",
    Latitude: "-27.462600"
  },
  {
    Country: "Australia",
    Pcode: "4000",
    Locality: "SPRING HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.022200",
    Latitude: "-27.458700"
  },
  {
    Country: "Australia",
    Pcode: "4001",
    Locality: "BRISBANE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4002",
    Locality: "CITY EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4073",
    Locality: "SINNAMON PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.950100",
    Latitude: "-27.543900"
  },
  {
    Country: "Australia",
    Pcode: "4074",
    Locality: "JAMBOREE HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.934400",
    Latitude: "-27.556300"
  },
  {
    Country: "Australia",
    Pcode: "4074",
    Locality: "JINDALEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.937800",
    Latitude: "-27.535400"
  },
  {
    Country: "Australia",
    Pcode: "4074",
    Locality: "MIDDLE PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.921800",
    Latitude: "-27.556200"
  },
  {
    Country: "Australia",
    Pcode: "4074",
    Locality: "MOUNT OMMANEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.932300",
    Latitude: "-27.545500"
  },
  {
    Country: "Australia",
    Pcode: "4074",
    Locality: "RIVERHILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.908700",
    Latitude: "-27.561900"
  },
  {
    Country: "Australia",
    Pcode: "4074",
    Locality: "SUMNER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.931500",
    Latitude: "-27.564800"
  },
  {
    Country: "Australia",
    Pcode: "4074",
    Locality: "SUMNER PARK BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4074",
    Locality: "WESTLAKE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.912200",
    Latitude: "-27.549300"
  },
  {
    Country: "Australia",
    Pcode: "4075",
    Locality: "CORINDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.984300",
    Latitude: "-27.543100"
  },
  {
    Country: "Australia",
    Pcode: "4075",
    Locality: "GRACEVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.981600",
    Latitude: "-27.521500"
  },
  {
    Country: "Australia",
    Pcode: "4075",
    Locality: "GRACEVILLE EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4075",
    Locality: "OXLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.976000",
    Latitude: "-27.563200"
  },
  {
    Country: "Australia",
    Pcode: "4075",
    Locality: "SHERWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.981800",
    Latitude: "-27.530400"
  },
  {
    Country: "Australia",
    Pcode: "4076",
    Locality: "DARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.952100",
    Latitude: "-27.568900"
  },
  {
    Country: "Australia",
    Pcode: "4076",
    Locality: "WACOL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.917900",
    Latitude: "-27.587500"
  },
  {
    Country: "Australia",
    Pcode: "4077",
    Locality: "DOOLANDELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.987700",
    Latitude: "-27.613700"
  },
  {
    Country: "Australia",
    Pcode: "4077",
    Locality: "DURACK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.986200",
    Latitude: "-27.586800"
  },
  {
    Country: "Australia",
    Pcode: "4077",
    Locality: "INALA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.972400",
    Latitude: "-27.589800"
  },
  {
    Country: "Australia",
    Pcode: "4077",
    Locality: "INALA HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4077",
    Locality: "RICHLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.952800",
    Latitude: "-27.588800"
  },
  {
    Country: "Australia",
    Pcode: "4078",
    Locality: "ELLEN GROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.946200",
    Latitude: "-27.615600"
  },
  {
    Country: "Australia",
    Pcode: "4078",
    Locality: "FOREST LAKE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.963500",
    Latitude: "-27.621900"
  },
  {
    Country: "Australia",
    Pcode: "4101",
    Locality: "HIGHGATE HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.017900",
    Latitude: "-27.487900"
  },
  {
    Country: "Australia",
    Pcode: "4101",
    Locality: "SOUTH BRISBANE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.020000",
    Latitude: "-27.479200"
  },
  {
    Country: "Australia",
    Pcode: "4101",
    Locality: "SOUTH BRISBANE BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4101",
    Locality: "WEST END",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "BRISBANE",
    Category: "Delivery Area",
    Longitude: "153.005300",
    Latitude: "-27.483000"
  },
  {
    Country: "Australia",
    Pcode: "4102",
    Locality: "DUTTON PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.025600",
    Latitude: "-27.494600"
  },
  {
    Country: "Australia",
    Pcode: "4102",
    Locality: "WOOLLOONGABBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.038300",
    Latitude: "-27.492800"
  },
  {
    Country: "Australia",
    Pcode: "4103",
    Locality: "ANNERLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.033600",
    Latitude: "-27.512600"
  },
  {
    Country: "Australia",
    Pcode: "4103",
    Locality: "FAIRFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.022800",
    Latitude: "-27.506500"
  },
  {
    Country: "Australia",
    Pcode: "4103",
    Locality: "FAIRFIELD GARDENS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4104",
    Locality: "YERONGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.011700",
    Latitude: "-27.513400"
  },
  {
    Country: "Australia",
    Pcode: "4105",
    Locality: "MOOROOKA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.024800",
    Latitude: "-27.534600"
  },
  {
    Country: "Australia",
    Pcode: "4105",
    Locality: "TENNYSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.001600",
    Latitude: "-27.526900"
  },
  {
    Country: "Australia",
    Pcode: "4105",
    Locality: "YEERONGPILLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.009400",
    Latitude: "-27.531000"
  },
  {
    Country: "Australia",
    Pcode: "4106",
    Locality: "BRISBANE MARKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4106",
    Locality: "ROCKLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.003600",
    Latitude: "-27.549300"
  },
  {
    Country: "Australia",
    Pcode: "4106",
    Locality: "ROCKLEA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4107",
    Locality: "SALISBURY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.030000",
    Latitude: "-27.548500"
  },
  {
    Country: "Australia",
    Pcode: "4107",
    Locality: "SALISBURY EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4108",
    Locality: "ARCHERFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.010300",
    Latitude: "-27.567300"
  },
  {
    Country: "Australia",
    Pcode: "4108",
    Locality: "ARCHERFIELD BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4108",
    Locality: "COOPERS PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.036700",
    Latitude: "-27.566700"
  },
  {
    Country: "Australia",
    Pcode: "4109",
    Locality: "MACGREGOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.072500",
    Latitude: "-27.563200"
  },
  {
    Country: "Australia",
    Pcode: "4109",
    Locality: "ROBERTSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.057200",
    Latitude: "-27.565000"
  },
  {
    Country: "Australia",
    Pcode: "4109",
    Locality: "SUNNYBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.060300",
    Latitude: "-27.579800"
  },
  {
    Country: "Australia",
    Pcode: "4109",
    Locality: "SUNNYBANK HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.052500",
    Latitude: "-27.597800"
  },
  {
    Country: "Australia",
    Pcode: "4109",
    Locality: "SUNNYBANK SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4110",
    Locality: "ACACIA RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.021900",
    Latitude: "-27.584200"
  },
  {
    Country: "Australia",
    Pcode: "4110",
    Locality: "HEATHWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.991800",
    Latitude: "-27.639400"
  },
  {
    Country: "Australia",
    Pcode: "4110",
    Locality: "HEATHWOOD DF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4110",
    Locality: "LARAPINTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.009700",
    Latitude: "-27.641600"
  },
  {
    Country: "Australia",
    Pcode: "4110",
    Locality: "PALLARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.009800",
    Latitude: "-27.617400"
  },
  {
    Country: "Australia",
    Pcode: "4110",
    Locality: "WILLAWONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.009800",
    Latitude: "-27.603000"
  },
  {
    Country: "Australia",
    Pcode: "4111",
    Locality: "NATHAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.052300",
    Latitude: "-27.548600"
  },
  {
    Country: "Australia",
    Pcode: "4112",
    Locality: "KURABY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.093000",
    Latitude: "-27.605000"
  },
  {
    Country: "Australia",
    Pcode: "4113",
    Locality: "EIGHT MILE PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.088300",
    Latitude: "-27.580200"
  },
  {
    Country: "Australia",
    Pcode: "4113",
    Locality: "RUNCORN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.079400",
    Latitude: "-27.597900"
  },
  {
    Country: "Australia",
    Pcode: "4114",
    Locality: "KINGSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.110000",
    Latitude: "-27.662400"
  },
  {
    Country: "Australia",
    Pcode: "4114",
    Locality: "LOGAN CENTRAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.109100",
    Latitude: "-27.644700"
  },
  {
    Country: "Australia",
    Pcode: "4114",
    Locality: "LOGAN CITY DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4114",
    Locality: "WOODRIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.104700",
    Latitude: "-27.628500"
  },
  {
    Country: "Australia",
    Pcode: "4115",
    Locality: "ALGESTER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.032800",
    Latitude: "-27.614100"
  },
  {
    Country: "Australia",
    Pcode: "4115",
    Locality: "PARKINSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.030800",
    Latitude: "-27.643500"
  },
  {
    Country: "Australia",
    Pcode: "4116",
    Locality: "CALAMVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.047100",
    Latitude: "-27.623400"
  },
  {
    Country: "Australia",
    Pcode: "4116",
    Locality: "DREWVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.055200",
    Latitude: "-27.647600"
  },
  {
    Country: "Australia",
    Pcode: "4116",
    Locality: "STRETTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.067500",
    Latitude: "-27.622700"
  },
  {
    Country: "Australia",
    Pcode: "4217",
    Locality: "ISLE OF CAPRI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4217",
    Locality: "MAIN BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.421000",
    Latitude: "-27.949800"
  },
  {
    Country: "Australia",
    Pcode: "4217",
    Locality: "SURFERS PARADISE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.422400",
    Latitude: "-27.999000"
  },
  {
    Country: "Australia",
    Pcode: "4218",
    Locality: "BROADBEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.430400",
    Latitude: "-28.027000"
  },
  {
    Country: "Australia",
    Pcode: "4218",
    Locality: "BROADBEACH WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.416800",
    Latitude: "-28.027200"
  },
  {
    Country: "Australia",
    Pcode: "4218",
    Locality: "MERMAID BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.436900",
    Latitude: "-28.047700"
  },
  {
    Country: "Australia",
    Pcode: "4218",
    Locality: "MERMAID WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.422000",
    Latitude: "-28.051400"
  },
  {
    Country: "Australia",
    Pcode: "4218",
    Locality: "NOBBY BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4218",
    Locality: "PACIFIC FAIR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4218",
    Locality: "Q SUPERCENTRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4219",
    Locality: "WEST BURLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4220",
    Locality: "BURLEIGH DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4220",
    Locality: "BURLEIGH HEADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.432000",
    Latitude: "-28.110700"
  },
  {
    Country: "Australia",
    Pcode: "4220",
    Locality: "BURLEIGH TOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4220",
    Locality: "BURLEIGH WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.432700",
    Latitude: "-28.088500"
  },
  {
    Country: "Australia",
    Pcode: "4220",
    Locality: "MIAMI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.435000",
    Latitude: "-28.069000"
  },
  {
    Country: "Australia",
    Pcode: "4221",
    Locality: "ELANORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.451400",
    Latitude: "-28.129900"
  },
  {
    Country: "Australia",
    Pcode: "4221",
    Locality: "PALM BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.458200",
    Latitude: "-28.115500"
  },
  {
    Country: "Australia",
    Pcode: "4222",
    Locality: "GRIFFITH UNIVERSITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4223",
    Locality: "CURRUMBIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.482500",
    Latitude: "-28.137800"
  },
  {
    Country: "Australia",
    Pcode: "4223",
    Locality: "CURRUMBIN VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.397500",
    Latitude: "-28.204400"
  },
  {
    Country: "Australia",
    Pcode: "4223",
    Locality: "CURRUMBIN WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.460800",
    Latitude: "-28.157300"
  },
  {
    Country: "Australia",
    Pcode: "4224",
    Locality: "TUGUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.490400",
    Latitude: "-28.148800"
  },
  {
    Country: "Australia",
    Pcode: "4225",
    Locality: "BILINGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.504500",
    Latitude: "-28.160600"
  },
  {
    Country: "Australia",
    Pcode: "4225",
    Locality: "COOLANGATTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.530500",
    Latitude: "-28.170000"
  },
  {
    Country: "Australia",
    Pcode: "4226",
    Locality: "CLEAR ISLAND WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.397300",
    Latitude: "-28.043700"
  },
  {
    Country: "Australia",
    Pcode: "4226",
    Locality: "MERRIMAC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.373400",
    Latitude: "-28.049800"
  },
  {
    Country: "Australia",
    Pcode: "4226",
    Locality: "ROBINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.391800",
    Latitude: "-28.071200"
  },
  {
    Country: "Australia",
    Pcode: "4226",
    Locality: "ROBINA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4227",
    Locality: "REEDY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.396900",
    Latitude: "-28.112200"
  },
  {
    Country: "Australia",
    Pcode: "4227",
    Locality: "VARSITY LAKES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.409400",
    Latitude: "-28.089700"
  },
  {
    Country: "Australia",
    Pcode: "4228",
    Locality: "TALLEBUDGERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.422600",
    Latitude: "-28.149100"
  },
  {
    Country: "Australia",
    Pcode: "4228",
    Locality: "TALLEBUDGERA VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.363100",
    Latitude: "-28.183000"
  },
  {
    Country: "Australia",
    Pcode: "4230",
    Locality: "ROBINA TOWN CENTRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4270",
    Locality: "TAMBORINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.140500",
    Latitude: "-27.868000"
  },
  {
    Country: "Australia",
    Pcode: "4271",
    Locality: "EAGLE HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4272",
    Locality: "NORTH TAMBORINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4272",
    Locality: "TAMBORINE MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.191300",
    Latitude: "-27.944800"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "BENOBBLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.159400",
    Latitude: "-27.990700"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "BIDDADDABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.108500",
    Latitude: "-27.994400"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "BOYLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.127700",
    Latitude: "-27.935900"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "CANUNGRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.142200",
    Latitude: "-28.038100"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "FERNY GLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.156800",
    Latitude: "-28.075100"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "FLYING FOX",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.170600",
    Latitude: "-28.097400"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "ILLINBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.152500",
    Latitude: "-28.128600"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "LAMINGTON NATIONAL PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "O'REILLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "SARABAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.120100",
    Latitude: "-28.117900"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "WITHEREN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.197300",
    Latitude: "-28.036200"
  },
  {
    Country: "Australia",
    Pcode: "4275",
    Locality: "WONGLEPONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.160300",
    Latitude: "-27.970200"
  },
  {
    Country: "Australia",
    Pcode: "4280",
    Locality: "FLAGSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.942000",
    Latitude: "-27.805500"
  },
  {
    Country: "Australia",
    Pcode: "4280",
    Locality: "GLENLOGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4280",
    Locality: "JIMBOOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.021000",
    Latitude: "-27.838900"
  },
  {
    Country: "Australia",
    Pcode: "4280",
    Locality: "NORTH MACLEAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.998700",
    Latitude: "-27.766400"
  },
  {
    Country: "Australia",
    Pcode: "4280",
    Locality: "RIVERBEND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4280",
    Locality: "SOUTH MACLEAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.994200",
    Latitude: "-27.793600"
  },
  {
    Country: "Australia",
    Pcode: "4280",
    Locality: "STOCKLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.069200",
    Latitude: "-27.771900"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "ALLENVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.915700",
    Latitude: "-27.911800"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "BEAUDESERT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.002000",
    Latitude: "-27.989100"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "BIRNAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.058600",
    Latitude: "-27.948600"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "BROMELTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.873800",
    Latitude: "-27.987800"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "CAINBABLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.097000",
    Latitude: "-28.146400"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "CEDAR GROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.975300",
    Latitude: "-27.859800"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "CEDAR VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.012900",
    Latitude: "-27.879800"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "CHINGHEE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.981500",
    Latitude: "-28.284600"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "CHRISTMAS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.989600",
    Latitude: "-28.179400"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "CRYNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.986200",
    Latitude: "-28.035000"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "DARLINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.066300",
    Latitude: "-28.236100"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "SCRUB CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.429100",
    Latitude: "-27.020100"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "TOOGOOLAWAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.369800",
    Latitude: "-27.093300"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "YIMBUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.361500",
    Latitude: "-27.028500"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "AVOCA VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.286100",
    Latitude: "-26.682700"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "BENARKIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.139100",
    Latitude: "-26.882400"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "BENARKIN NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.151100",
    Latitude: "-26.851200"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "BLACKBUTT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.101100",
    Latitude: "-26.883000"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "BLACKBUTT NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.117200",
    Latitude: "-26.869800"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "BLACKBUTT SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.080700",
    Latitude: "-26.908900"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "CHERRY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.111000",
    Latitude: "-26.930300"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "COLINTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.220900",
    Latitude: "-26.966100"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "GILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.009200",
    Latitude: "-26.901900"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "GOOGA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.057200",
    Latitude: "-26.964700"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "HARLIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.350300",
    Latitude: "-26.966600"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "LINVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.237000",
    Latitude: "-26.809800"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "MOORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.262400",
    Latitude: "-26.881700"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "MOUNT BINGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.943300",
    Latitude: "-27.010600"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "MOUNT STANLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.195700",
    Latitude: "-26.598100"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "NUKKU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.062500",
    Latitude: "-26.848800"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "TAROMEO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.124700",
    Latitude: "-26.829500"
  },
  {
    Country: "Australia",
    Pcode: "4314",
    Locality: "TEELAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.097300",
    Latitude: "-26.772800"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "ASHWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.552800",
    Latitude: "-27.624900"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "CALVERT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.508600",
    Latitude: "-27.666300"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "EBENEZER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.617700",
    Latitude: "-27.678100"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "GRANDCHESTER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.451500",
    Latitude: "-27.672900"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "JEEBROPILLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.653900",
    Latitude: "-27.649100"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "LANEFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.551400",
    Latitude: "-27.659300"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "LOWER MOUNT WALKER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.529400",
    Latitude: "-27.718800"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "MERRYVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.482400",
    Latitude: "-27.797100"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "MOORANG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.450800",
    Latitude: "-27.921100"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "MOUNT FORBES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.610500",
    Latitude: "-27.733000"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "MOUNT MORT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.437700",
    Latitude: "-27.775700"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "MOUNT WALKER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.550200",
    Latitude: "-27.773800"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "MOUNT WALKER WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.499200",
    Latitude: "-27.775800"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "ROSEVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.496700",
    Latitude: "-27.859400"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "ROSEWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.599200",
    Latitude: "-27.629500"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "TALLEGALLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.553700",
    Latitude: "-27.589800"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "THE BLUFF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "IPSWICH",
    Category: "Delivery Area",
    Longitude: "152.531600",
    Latitude: "-27.614900"
  },
  {
    Country: "Australia",
    Pcode: "4340",
    Locality: "WOOLSHED",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.501800",
    Latitude: "-27.608300"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "BLENHEIM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.315900",
    Latitude: "-27.659700"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "HATTON VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.478300",
    Latitude: "-27.562900"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "KENSINGTON GROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.473300",
    Latitude: "-27.532300"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "KENTVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.421500",
    Latitude: "-27.475200"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "LAIDLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.413300",
    Latitude: "-27.639700"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "LAIDLEY CREEK WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.352100",
    Latitude: "-27.671000"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "LAIDLEY HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.365600",
    Latitude: "-27.629600"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "LAIDLEY NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.404200",
    Latitude: "-27.609300"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "LAIDLEY SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.386500",
    Latitude: "-27.673400"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "MOUNT BERRYMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.319500",
    Latitude: "-27.717600"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "MULGOWIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.374500",
    Latitude: "-27.724400"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "PLAINLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.420000",
    Latitude: "-27.579600"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "REGENCY DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.441500",
    Latitude: "-27.531600"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "SUMMERHOLM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.466300",
    Latitude: "-27.608400"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "THORNTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.357100",
    Latitude: "-27.798400"
  },
  {
    Country: "Australia",
    Pcode: "4341",
    Locality: "TOWNSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.362000",
    Latitude: "-27.897200"
  },
  {
    Country: "Australia",
    Pcode: "4342",
    Locality: "CROWLEY VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.378800",
    Latitude: "-27.545200"
  },
  {
    Country: "Australia",
    Pcode: "4342",
    Locality: "FOREST HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.362800",
    Latitude: "-27.582200"
  },
  {
    Country: "Australia",
    Pcode: "4342",
    Locality: "GLEN CAIRN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.331300",
    Latitude: "-27.611100"
  },
  {
    Country: "Australia",
    Pcode: "4342",
    Locality: "GLENORE GROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.406700",
    Latitude: "-27.527900"
  },
  {
    Country: "Australia",
    Pcode: "4342",
    Locality: "LOCKROSE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.464000",
    Latitude: "-27.489600"
  },
  {
    Country: "Australia",
    Pcode: "4342",
    Locality: "LYNFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.451500",
    Latitude: "-27.473800"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "ADARE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.289500",
    Latitude: "-27.510100"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "BLACK DUCK CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.220500",
    Latitude: "-27.912400"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "CAFFEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.212200",
    Latitude: "-27.697200"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "COLLEGE VIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.352100",
    Latitude: "-27.555300"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "EAST HALDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.278700",
    Latitude: "-27.892600"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "FORDSDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.109900",
    Latitude: "-27.728300"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "GATTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.279900",
    Latitude: "-27.565700"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "INGOLDSBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.266700",
    Latitude: "-27.736300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "TOOWOOMBA MC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "TUMMAVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.480000",
    Latitude: "-27.907700"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "UMBIRAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.772900",
    Latitude: "-27.659400"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "UPPER LOCKYER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.071800",
    Latitude: "-27.483700"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "VALE VIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.898100",
    Latitude: "-27.661000"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "WHICHELLO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.000100",
    Latitude: "-27.293500"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "WHITE MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.120700",
    Latitude: "-27.443500"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "WITHCOTT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.015300",
    Latitude: "-27.533800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "WOODLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.675500",
    Latitude: "-27.086200"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "WOOLMER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.921700",
    Latitude: "-27.450200"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "WUTUL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.796000",
    Latitude: "-27.031400"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "WYREEMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.846200",
    Latitude: "-27.656900"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "YALANGUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.821800",
    Latitude: "-27.421300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "YANDILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.387500",
    Latitude: "-27.850700"
  },
  {
    Country: "Australia",
    Pcode: "4353",
    Locality: "BERGEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.899700",
    Latitude: "-27.258400"
  },
  {
    Country: "Australia",
    Pcode: "4353",
    Locality: "EAST COOYAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.873900",
    Latitude: "-27.031600"
  },
  {
    Country: "Australia",
    Pcode: "4353",
    Locality: "HADEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.888200",
    Latitude: "-27.210500"
  },
  {
    Country: "Australia",
    Pcode: "4354",
    Locality: "DOUGLAS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "151.911500",
    Latitude: "-27.320300"
  },
  {
    Country: "Australia",
    Pcode: "4354",
    Locality: "GOOMBUNGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.845700",
    Latitude: "-27.304400"
  },
  {
    Country: "Australia",
    Pcode: "4354",
    Locality: "KILBIRNIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.824000",
    Latitude: "-27.233700"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "ANDURAMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.128400",
    Latitude: "-27.071400"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "CRESSBROOK CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.156700",
    Latitude: "-27.248600"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "CROWS NEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.073900",
    Latitude: "-27.260400"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "EMU CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.997200",
    Latitude: "-27.066300"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "GLENAVEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.971300",
    Latitude: "-27.175300"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "JONES GULLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.004100",
    Latitude: "-27.174500"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "MOUNTAIN CAMP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.039900",
    Latitude: "-27.202600"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "PIERCES CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.036800",
    Latitude: "-27.162900"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "PINELANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.004900",
    Latitude: "-27.238700"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "PLAINBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.958600",
    Latitude: "-27.271400"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "THE BLUFF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.136700",
    Latitude: "-27.203300"
  },
  {
    Country: "Australia",
    Pcode: "4355",
    Locality: "UPPER PINELANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.960200",
    Latitude: "-27.224000"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "BONGEEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.444900",
    Latitude: "-27.557800"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "BROXBURN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.697000",
    Latitude: "-27.724000"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "EVANSLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.517500",
    Latitude: "-27.528600"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "IRONGATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.514700",
    Latitude: "-27.636700"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "KINCORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.535400",
    Latitude: "-27.800000"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "LINTHORPE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.666900",
    Latitude: "-27.620200"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "MOTLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.611900",
    Latitude: "-27.543800"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "MOUNT TYSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.587200",
    Latitude: "-27.569900"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "NORTH BRANCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "151.628600",
    Latitude: "-27.853400"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "NORWIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.380400",
    Latitude: "-27.545600"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "PITTSWORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.634600",
    Latitude: "-27.739100"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "PURRAWUNDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.614500",
    Latitude: "-27.517900"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "ROSSVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.581600",
    Latitude: "-27.627800"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "SCRUBBY MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.587900",
    Latitude: "-27.768000"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "SPRINGSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.576200",
    Latitude: "-27.671500"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "ST HELENS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "151.479300",
    Latitude: "-27.676300"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "STONELEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.628900",
    Latitude: "-27.652200"
  },
  {
    Country: "Australia",
    Pcode: "4356",
    Locality: "YARRANLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.551200",
    Latitude: "-27.722800"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "BRINGALILY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.131700",
    Latitude: "-28.072400"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "BULLI CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.862900",
    Latitude: "-28.005800"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "CANNING CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.164700",
    Latitude: "-28.223600"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "CAPTAINS MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.110000",
    Latitude: "-27.933500"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "CLONTARF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "151.229400",
    Latitude: "-27.986200"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "CONDAMINE FARMS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.939600",
    Latitude: "-27.933800"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "CYPRESS GARDENS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.030500",
    Latitude: "-27.980900"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "DOMVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.279500",
    Latitude: "-27.932300"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "FOREST RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.025400",
    Latitude: "-27.961200"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "GRAYS GATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.314200",
    Latitude: "-27.970000"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "KOOROONGARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.270900",
    Latitude: "-28.092300"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "LAVELLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.283400",
    Latitude: "-28.002600"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "LEMONTREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.303800",
    Latitude: "-27.789500"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "MILLMERRAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.255000",
    Latitude: "-27.876200"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "MILLMERRAN DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.061400",
    Latitude: "-27.988800"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "MILLMERRAN WOODS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.047200",
    Latitude: "-28.021100"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "MILLWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.201600",
    Latitude: "-28.022900"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "MOUNT EMLYN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.291800",
    Latitude: "-28.040900"
  },
  {
    Country: "Australia",
    Pcode: "4382",
    Locality: "SOMME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.801900",
    Latitude: "-28.775800"
  },
  {
    Country: "Australia",
    Pcode: "4382",
    Locality: "WYBERBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.876900",
    Latitude: "-28.860000"
  },
  {
    Country: "Australia",
    Pcode: "4383",
    Locality: "JENNINGS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.964300",
    Latitude: "-28.943600"
  },
  {
    Country: "Australia",
    Pcode: "4383",
    Locality: "WALLANGARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.911600",
    Latitude: "-28.899400"
  },
  {
    Country: "Australia",
    Pcode: "4384",
    Locality: "LIMEVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.168600",
    Latitude: "-28.740600"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "BEEBO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.982000",
    Latitude: "-28.680900"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "BONSHAW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.327900",
    Latitude: "-29.007600"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "CAMP CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "INVERELL",
    Category: "Delivery Area",
    Longitude: "150.929300",
    Latitude: "-28.804200"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "GLENARBON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.934600",
    Latitude: "-28.593300"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "MAIDENHEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.400800",
    Latitude: "-29.114100"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "RIVERTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.458800",
    Latitude: "-29.056300"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "SILVER SPUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.340000",
    Latitude: "-28.834400"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "SMITHLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.083800",
    Latitude: "-28.799900"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "TEXAS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.106200",
    Latitude: "-28.888600"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "TEXAS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.186700",
    Latitude: "-28.853500"
  },
  {
    Country: "Australia",
    Pcode: "4385",
    Locality: "WATSONS CROSSING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.323100",
    Latitude: "-29.118000"
  },
  {
    Country: "Australia",
    Pcode: "4387",
    Locality: "BRUSH CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.136900",
    Latitude: "-28.591000"
  },
  {
    Country: "Australia",
    Pcode: "4387",
    Locality: "BYBERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.910700",
    Latitude: "-28.284400"
  },
  {
    Country: "Australia",
    Pcode: "4387",
    Locality: "COOLMUNDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.211400",
    Latitude: "-28.429900"
  },
  {
    Country: "Australia",
    Pcode: "4387",
    Locality: "GREENUP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.305300",
    Latitude: "-28.650000"
  },
  {
    Country: "Australia",
    Pcode: "4387",
    Locality: "INGLEWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.077200",
    Latitude: "-28.391000"
  },
  {
    Country: "Australia",
    Pcode: "4387",
    Locality: "MOSQUITO CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.257400",
    Latitude: "-28.263200"
  },
  {
    Country: "Australia",
    Pcode: "4387",
    Locality: "TERRICA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.481100",
    Latitude: "-28.502800"
  },
  {
    Country: "Australia",
    Pcode: "4387",
    Locality: "WARROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.438400",
    Latitude: "-28.638700"
  },
  {
    Country: "Australia",
    Pcode: "4387",
    Locality: "WHETSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.857800",
    Latitude: "-28.443600"
  },
  {
    Country: "Australia",
    Pcode: "4388",
    Locality: "KURUMBUL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.474300",
    Latitude: "-28.575300"
  },
  {
    Country: "Australia",
    Pcode: "4388",
    Locality: "YELARBON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.674400",
    Latitude: "-28.534000"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "BILLA BILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.302300",
    Latitude: "-28.151000"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "CALINGUNEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.331100",
    Latitude: "-27.995700"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "CALLANDOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.114500",
    Latitude: "-28.536900"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "GOODAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.118500",
    Latitude: "-28.354700"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "GOONDIWINDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.354500",
    Latitude: "-28.416100"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "KINDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.870000",
    Latitude: "-28.091100"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "LUNDAVRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.001300",
    Latitude: "-28.087400"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "WONDALLI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.613700",
    Latitude: "-28.376500"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "WYAGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.636300",
    Latitude: "-28.144600"
  },
  {
    Country: "Australia",
    Pcode: "4390",
    Locality: "YAGABURNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.519900",
    Latitude: "-28.086700"
  },
  {
    Country: "Australia",
    Pcode: "4400",
    Locality: "KINGSTHORPE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.796500",
    Latitude: "-27.495900"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "ACLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.683500",
    Latitude: "-27.314100"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "AUBIGNY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.665900",
    Latitude: "-27.509500"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "BALGOWAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.696400",
    Latitude: "-27.253600"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "BIDDESTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.735500",
    Latitude: "-27.553300"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "BOODUA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.829700",
    Latitude: "-27.377900"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "DEVON PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.679200",
    Latitude: "-27.368800"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "GREENWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.739800",
    Latitude: "-27.338100"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "HIGHLAND PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.762200",
    Latitude: "-27.263200"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "KELVINHAUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.757000",
    Latitude: "-27.393800"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "MOUNT IRVING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.583500",
    Latitude: "-27.514400"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "MULDU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.678000",
    Latitude: "-27.270400"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "OAKEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.710700",
    Latitude: "-27.449200"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "ROSALIE PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.715500",
    Latitude: "-27.222900"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "SABINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.712400",
    Latitude: "-27.352400"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "SILVERLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.767200",
    Latitude: "-27.330000"
  },
  {
    Country: "Australia",
    Pcode: "4401",
    Locality: "YARGULLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.598900",
    Latitude: "-27.481700"
  },
  {
    Country: "Australia",
    Pcode: "4402",
    Locality: "COOYAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.832500",
    Latitude: "-26.965200"
  },
  {
    Country: "Australia",
    Pcode: "4402",
    Locality: "KOORALGIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.933700",
    Latitude: "-26.930100"
  },
  {
    Country: "Australia",
    Pcode: "4402",
    Locality: "UPPER COOYAR CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.728700",
    Latitude: "-26.964000"
  },
  {
    Country: "Australia",
    Pcode: "4403",
    Locality: "BRYMAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.611300",
    Latitude: "-27.229000"
  },
  {
    Country: "Australia",
    Pcode: "4403",
    Locality: "JONDARYAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.581600",
    Latitude: "-27.390900"
  },
  {
    Country: "Australia",
    Pcode: "4403",
    Locality: "MALU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.584300",
    Latitude: "-27.306000"
  },
  {
    Country: "Australia",
    Pcode: "4403",
    Locality: "MOUNT MORIAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.490500",
    Latitude: "-27.477000"
  },
  {
    Country: "Australia",
    Pcode: "4403",
    Locality: "QUINALOW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.610800",
    Latitude: "-27.138600"
  },
  {
    Country: "Australia",
    Pcode: "4403",
    Locality: "WEST PRAIRIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.400500",
    Latitude: "-27.442400"
  },
  {
    Country: "Australia",
    Pcode: "4404",
    Locality: "BOWENVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.458600",
    Latitude: "-27.279300"
  },
  {
    Country: "Australia",
    Pcode: "4404",
    Locality: "FORMARTIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.429200",
    Latitude: "-27.369500"
  },
  {
    Country: "Australia",
    Pcode: "4404",
    Locality: "IRVINGDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.496000",
    Latitude: "-27.162400"
  },
  {
    Country: "Australia",
    Pcode: "4404",
    Locality: "WAINUI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.453900",
    Latitude: "-27.325900"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "BLAXLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.406100",
    Latitude: "-27.218500"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "BUNYA MOUNTAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.569500",
    Latitude: "-26.875900"
  },
  {
    Country: "Australia",
    Pcode: "4468",
    Locality: "MORVEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.092400",
    Latitude: "-26.537200"
  },
  {
    Country: "Australia",
    Pcode: "4470",
    Locality: "BAKERS BEND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.087000",
    Latitude: "-26.743200"
  },
  {
    Country: "Australia",
    Pcode: "4470",
    Locality: "CHARLEVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.277800",
    Latitude: "-26.379700"
  },
  {
    Country: "Australia",
    Pcode: "4470",
    Locality: "GOWRIE STATION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.404200",
    Latitude: "-26.133300"
  },
  {
    Country: "Australia",
    Pcode: "4470",
    Locality: "LANGLO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.702000",
    Latitude: "-25.991400"
  },
  {
    Country: "Australia",
    Pcode: "4470",
    Locality: "MURWEH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.782900",
    Latitude: "-26.930800"
  },
  {
    Country: "Australia",
    Pcode: "4470",
    Locality: "RIVERSLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.508600",
    Latitude: "-26.887600"
  },
  {
    Country: "Australia",
    Pcode: "4471",
    Locality: "CLAVERTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4472",
    Locality: "BLACKALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.307000",
    Latitude: "-24.657800"
  },
  {
    Country: "Australia",
    Pcode: "4472",
    Locality: "MOUNT ENNISKILLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.044300",
    Latitude: "-24.658000"
  },
  {
    Country: "Australia",
    Pcode: "4474",
    Locality: "ADAVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.719700",
    Latitude: "-25.617400"
  },
  {
    Country: "Australia",
    Pcode: "4475",
    Locality: "CHEEPIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4477",
    Locality: "AUGATHELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.587600",
    Latitude: "-25.806400"
  },
  {
    Country: "Australia",
    Pcode: "4477",
    Locality: "UPPER WARREGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.337000",
    Latitude: "-25.125600"
  },
  {
    Country: "Australia",
    Pcode: "4478",
    Locality: "BAYRICK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.075100",
    Latitude: "-25.419900"
  },
  {
    Country: "Australia",
    Pcode: "4478",
    Locality: "CALDERVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.845600",
    Latitude: "-24.941700"
  },
  {
    Country: "Australia",
    Pcode: "4478",
    Locality: "LANSDOWNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.160200",
    Latitude: "-25.121600"
  },
  {
    Country: "Australia",
    Pcode: "4478",
    Locality: "LUMEAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.673200",
    Latitude: "-25.266500"
  },
  {
    Country: "Australia",
    Pcode: "4478",
    Locality: "MACFARLANE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.012500",
    Latitude: "-24.878300"
  },
  {
    Country: "Australia",
    Pcode: "4478",
    Locality: "MINNIE DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.818500",
    Latitude: "-25.085700"
  },
  {
    Country: "Australia",
    Pcode: "4478",
    Locality: "TAMBO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.330700",
    Latitude: "-24.911300"
  },
  {
    Country: "Australia",
    Pcode: "4478",
    Locality: "WINDEYER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.422600",
    Latitude: "-24.561500"
  },
  {
    Country: "Australia",
    Pcode: "4478",
    Locality: "YANDARLO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.505800",
    Latitude: "-25.141800"
  },
  {
    Country: "Australia",
    Pcode: "4479",
    Locality: "COOLADDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.561300",
    Latitude: "-26.703200"
  },
  {
    Country: "Australia",
    Pcode: "4480",
    Locality: "EROMANGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.792900",
    Latitude: "-26.302700"
  },
  {
    Country: "Australia",
    Pcode: "4480",
    Locality: "QUILPIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.312200",
    Latitude: "-26.873800"
  },
  {
    Country: "Australia",
    Pcode: "4481",
    Locality: "FARRARS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.171900",
    Latitude: "-25.241300"
  },
  {
    Country: "Australia",
    Pcode: "4481",
    Locality: "TANBAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.563300",
    Latitude: "-26.248700"
  },
  {
    Country: "Australia",
    Pcode: "4481",
    Locality: "WINDORAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.433100",
    Latitude: "-25.488300"
  },
  {
    Country: "Australia",
    Pcode: "4482",
    Locality: "BIRDSVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.468200",
    Latitude: "-25.548400"
  },
  {
    Country: "Australia",
    Pcode: "4486",
    Locality: "DIRRANBANDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.068900",
    Latitude: "-28.509800"
  },
  {
    Country: "Australia",
    Pcode: "4486",
    Locality: "HEBEL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.511100",
    Latitude: "-28.881400"
  },
  {
    Country: "Australia",
    Pcode: "4487",
    Locality: "BEGONIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.257500",
    Latitude: "-27.440100"
  },
  {
    Country: "Australia",
    Pcode: "4487",
    Locality: "ST GEORGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.796500",
    Latitude: "-28.063800"
  },
  {
    Country: "Australia",
    Pcode: "4488",
    Locality: "BOLLON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.377700",
    Latitude: "-28.297700"
  },
  {
    Country: "Australia",
    Pcode: "4488",
    Locality: "NEBINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.847800",
    Latitude: "-28.117500"
  },
  {
    Country: "Australia",
    Pcode: "4489",
    Locality: "WYANDRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.088700",
    Latitude: "-27.258700"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "BARRINGUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.780400",
    Latitude: "-28.843500"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "COONGOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.855500",
    Latitude: "-27.717100"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "CUNNAMULLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.777900",
    Latitude: "-28.010900"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "CUTTABURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.387700",
    Latitude: "-28.654700"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "HUMEBURN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.204200",
    Latitude: "-27.350500"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "JOBS GATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.740800",
    Latitude: "-28.765300"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "LINDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.384900",
    Latitude: "-27.857900"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "NOORAMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.195600",
    Latitude: "-28.716500"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "TUEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.721000",
    Latitude: "-28.532300"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "WIDGEEGOARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.286600",
    Latitude: "-28.357400"
  },
  {
    Country: "Australia",
    Pcode: "4490",
    Locality: "YOWAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.591500",
    Latitude: "-27.814900"
  },
  {
    Country: "Australia",
    Pcode: "4491",
    Locality: "EULO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.988800",
    Latitude: "-28.212100"
  },
  {
    Country: "Australia",
    Pcode: "4492",
    Locality: "BULLAWARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.297400",
    Latitude: "-27.930400"
  },
  {
    Country: "Australia",
    Pcode: "4492",
    Locality: "BULLOO DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.876800",
    Latitude: "-28.529500"
  },
  {
    Country: "Australia",
    Pcode: "4492",
    Locality: "DYNEVOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.040900",
    Latitude: "-28.343600"
  },
  {
    Country: "Australia",
    Pcode: "4492",
    Locality: "NOCKATUNGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.474100",
    Latitude: "-27.723300"
  },
  {
    Country: "Australia",
    Pcode: "4492",
    Locality: "NORLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.846400",
    Latitude: "-27.658200"
  },
  {
    Country: "Australia",
    Pcode: "4492",
    Locality: "THARGOMINDAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.821700",
    Latitude: "-27.976100"
  },
  {
    Country: "Australia",
    Pcode: "4493",
    Locality: "HUNGERFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.314000",
    Latitude: "-28.710400"
  },
  {
    Country: "Australia",
    Pcode: "4494",
    Locality: "BUNGUNYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.680000",
    Latitude: "-28.454800"
  },
  {
    Country: "Australia",
    Pcode: "4494",
    Locality: "NORTH BUNGUNYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.531800",
    Latitude: "-28.175300"
  },
  {
    Country: "Australia",
    Pcode: "4494",
    Locality: "TARAWERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.711200",
    Latitude: "-28.112300"
  },
  {
    Country: "Australia",
    Pcode: "4496",
    Locality: "NORTH TALWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.393000",
    Latitude: "-28.372700"
  },
  {
    Country: "Australia",
    Pcode: "4496",
    Locality: "SOUTH TALWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.311100",
    Latitude: "-28.610200"
  },
  {
    Country: "Australia",
    Pcode: "4496",
    Locality: "TALWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4497",
    Locality: "DAYMAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.055400",
    Latitude: "-28.596500"
  },
  {
    Country: "Australia",
    Pcode: "4497",
    Locality: "THALLON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.799100",
    Latitude: "-28.546100"
  },
  {
    Country: "Australia",
    Pcode: "4497",
    Locality: "WEENGALLON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.082100",
    Latitude: "-28.382500"
  },
  {
    Country: "Australia",
    Pcode: "4498",
    Locality: "KIOMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.763600",
    Latitude: "-28.222500"
  },
  {
    Country: "Australia",
    Pcode: "4498",
    Locality: "TOOBEAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.853800",
    Latitude: "-28.432400"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "BRAY PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.969900",
    Latitude: "-27.293800"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "BRENDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.978900",
    Latitude: "-27.323800"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "REESVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.800800",
    Latitude: "-26.759800"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "WITTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.826800",
    Latitude: "-26.709000"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "WOOTHA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.802300",
    Latitude: "-26.783700"
  },
  {
    Country: "Australia",
    Pcode: "4553",
    Locality: "DIAMOND VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.926300",
    Latitude: "-26.754200"
  },
  {
    Country: "Australia",
    Pcode: "4553",
    Locality: "GLENVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.020300",
    Latitude: "-26.769400"
  },
  {
    Country: "Australia",
    Pcode: "4553",
    Locality: "MOOLOOLAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4553",
    Locality: "MOOLOOLAH VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.967900",
    Latitude: "-26.762600"
  },
  {
    Country: "Australia",
    Pcode: "4553",
    Locality: "PALMVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.053700",
    Latitude: "-26.748400"
  },
  {
    Country: "Australia",
    Pcode: "4554",
    Locality: "EUDLO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.939000",
    Latitude: "-26.730600"
  },
  {
    Country: "Australia",
    Pcode: "4554",
    Locality: "ILKLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.996500",
    Latitude: "-26.731500"
  },
  {
    Country: "Australia",
    Pcode: "4555",
    Locality: "CHEVALLUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.995500",
    Latitude: "-26.700000"
  },
  {
    Country: "Australia",
    Pcode: "4555",
    Locality: "HUNCHY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.912200",
    Latitude: "-26.680000"
  },
  {
    Country: "Australia",
    Pcode: "4555",
    Locality: "LANDERS SHOOT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.927700",
    Latitude: "-26.714600"
  },
  {
    Country: "Australia",
    Pcode: "4555",
    Locality: "PALMWOODS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.960800",
    Latitude: "-26.692700"
  },
  {
    Country: "Australia",
    Pcode: "4556",
    Locality: "BUDERIM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.068500",
    Latitude: "-26.687900"
  },
  {
    Country: "Australia",
    Pcode: "4556",
    Locality: "FOREST GLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.015500",
    Latitude: "-26.680400"
  },
  {
    Country: "Australia",
    Pcode: "4556",
    Locality: "KUNDA PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.040100",
    Latitude: "-26.661300"
  },
  {
    Country: "Australia",
    Pcode: "4556",
    Locality: "MONS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.024200",
    Latitude: "-26.691500"
  },
  {
    Country: "Australia",
    Pcode: "4556",
    Locality: "SIPPY DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.078300",
    Latitude: "-26.725800"
  },
  {
    Country: "Australia",
    Pcode: "4556",
    Locality: "TANAWHA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.029700",
    Latitude: "-26.723900"
  },
  {
    Country: "Australia",
    Pcode: "4557",
    Locality: "MOOLOOLABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.116200",
    Latitude: "-26.686000"
  },
  {
    Country: "Australia",
    Pcode: "4557",
    Locality: "MOUNTAIN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.102600",
    Latitude: "-26.700200"
  },
  {
    Country: "Australia",
    Pcode: "4558",
    Locality: "COTTON TREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4558",
    Locality: "KULUIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.056000",
    Latitude: "-26.657500"
  },
  {
    Country: "Australia",
    Pcode: "4558",
    Locality: "MAROOCHYDORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.084800",
    Latitude: "-26.655300"
  },
  {
    Country: "Australia",
    Pcode: "4558",
    Locality: "MAROOCHYDORE BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4558",
    Locality: "MAROOCHYDORE DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4558",
    Locality: "SUNSHINE PLAZA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4559",
    Locality: "DIDDILLIBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.037300",
    Latitude: "-26.648600"
  },
  {
    Country: "Australia",
    Pcode: "4559",
    Locality: "KIELS MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.007400",
    Latitude: "-26.657900"
  },
  {
    Country: "Australia",
    Pcode: "4559",
    Locality: "WEST WOOMBYE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.925200",
    Latitude: "-26.665700"
  },
  {
    Country: "Australia",
    Pcode: "4559",
    Locality: "WOOMBYE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.978000",
    Latitude: "-26.660500"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "BLI BLI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.021800",
    Latitude: "-26.610500"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "BURNSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.941200",
    Latitude: "-26.631000"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "COES CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.946500",
    Latitude: "-26.648800"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "COOLOOLABIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.895000",
    Latitude: "-26.536800"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "DULONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.894000",
    Latitude: "-26.644500"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "FLAXTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.864700",
    Latitude: "-26.655500"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "HIGHWORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.931000",
    Latitude: "-26.616300"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "IMAGE FLAT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.924700",
    Latitude: "-26.599600"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "KIAMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.907500",
    Latitude: "-26.574000"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "KULANGOOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.953600",
    Latitude: "-26.588300"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "KUREELPA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.893400",
    Latitude: "-26.608300"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "MAPLETON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.861200",
    Latitude: "-26.629500"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "MONTVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.894000",
    Latitude: "-26.697100"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "NAMBOUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.957600",
    Latitude: "-26.624900"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "NAMBOUR BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "NAMBOUR DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "NAMBOUR WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "PARKLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.981100",
    Latitude: "-26.591200"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "PERWILLOWEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.924100",
    Latitude: "-26.635700"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "ROSEMOUNT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.003800",
    Latitude: "-26.631600"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "SUNSHINE COAST MC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4560",
    Locality: "TOWEN MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.923100",
    Latitude: "-26.649400"
  },
  {
    Country: "Australia",
    Pcode: "4561",
    Locality: "BRIDGES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.943300",
    Latitude: "-26.528900"
  },
  {
    Country: "Australia",
    Pcode: "4561",
    Locality: "MAROOCHY RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.021800",
    Latitude: "-26.575900"
  },
  {
    Country: "Australia",
    Pcode: "4561",
    Locality: "NINDERRY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.975500",
    Latitude: "-26.539000"
  },
  {
    Country: "Australia",
    Pcode: "4561",
    Locality: "NORTH ARM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.957700",
    Latitude: "-26.511000"
  },
  {
    Country: "Australia",
    Pcode: "4561",
    Locality: "VALDORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.008300",
    Latitude: "-26.552100"
  },
  {
    Country: "Australia",
    Pcode: "4561",
    Locality: "YANDINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.959300",
    Latitude: "-26.566000"
  },
  {
    Country: "Australia",
    Pcode: "4561",
    Locality: "YANDINA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.025800",
    Latitude: "-26.522400"
  },
  {
    Country: "Australia",
    Pcode: "4562",
    Locality: "BELLI PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.819700",
    Latitude: "-26.504800"
  },
  {
    Country: "Australia",
    Pcode: "4562",
    Locality: "DOONAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.006100",
    Latitude: "-26.452300"
  },
  {
    Country: "Australia",
    Pcode: "4562",
    Locality: "EERWAH VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.909500",
    Latitude: "-26.477900"
  },
  {
    Country: "Australia",
    Pcode: "4562",
    Locality: "EUMUNDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.955500",
    Latitude: "-26.466500"
  },
  {
    Country: "Australia",
    Pcode: "4562",
    Locality: "VERRIERDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.015400",
    Latitude: "-26.487500"
  },
  {
    Country: "Australia",
    Pcode: "4562",
    Locality: "WEYBA DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.049100",
    Latitude: "-26.452800"
  },
  {
    Country: "Australia",
    Pcode: "4563",
    Locality: "BLACK MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.850700",
    Latitude: "-26.422100"
  },
  {
    Country: "Australia",
    Pcode: "4563",
    Locality: "CARTERS RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.771300",
    Latitude: "-26.451600"
  },
  {
    Country: "Australia",
    Pcode: "4581",
    Locality: "FRASER ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.148800",
    Latitude: "-25.277500"
  },
  {
    Country: "Australia",
    Pcode: "4581",
    Locality: "INSKIP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.058500",
    Latitude: "-25.842200"
  },
  {
    Country: "Australia",
    Pcode: "4581",
    Locality: "ORCHID BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "FRASER ISLAND",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4581",
    Locality: "RAINBOW BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.074300",
    Latitude: "-25.905500"
  },
  {
    Country: "Australia",
    Pcode: "4600",
    Locality: "BLACK SNAKE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.270100",
    Latitude: "-26.205300"
  },
  {
    Country: "Australia",
    Pcode: "4600",
    Locality: "CINNABAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.174300",
    Latitude: "-26.131300"
  },
  {
    Country: "Australia",
    Pcode: "4600",
    Locality: "KILKIVAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.247700",
    Latitude: "-26.078100"
  },
  {
    Country: "Australia",
    Pcode: "4600",
    Locality: "MUDLO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.229600",
    Latitude: "-25.959100"
  },
  {
    Country: "Australia",
    Pcode: "4600",
    Locality: "OAKVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.321600",
    Latitude: "-26.108500"
  },
  {
    Country: "Australia",
    Pcode: "4601",
    Locality: "BARAMBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.129600",
    Latitude: "-26.313800"
  },
  {
    Country: "Australia",
    Pcode: "4601",
    Locality: "BOONARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.022200",
    Latitude: "-26.075000"
  },
  {
    Country: "Australia",
    Pcode: "4601",
    Locality: "BOOUBYJAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.915100",
    Latitude: "-25.888400"
  },
  {
    Country: "Australia",
    Pcode: "4601",
    Locality: "GOOMERI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.080100",
    Latitude: "-26.183500"
  },
  {
    Country: "Australia",
    Pcode: "4601",
    Locality: "GOOMERIBONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.012100",
    Latitude: "-26.143600"
  },
  {
    Country: "Australia",
    Pcode: "4601",
    Locality: "KINBOMBI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.170100",
    Latitude: "-26.239000"
  },
  {
    Country: "Australia",
    Pcode: "4601",
    Locality: "MANUMBAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.292300",
    Latitude: "-26.370200"
  },
  {
    Country: "Australia",
    Pcode: "4601",
    Locality: "TANSEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.085500",
    Latitude: "-25.971800"
  },
  {
    Country: "Australia",
    Pcode: "4601",
    Locality: "WRATTENS FOREST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.338000",
    Latitude: "-26.305400"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "BARLIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.889000",
    Latitude: "-26.203200"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "BYEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.845200",
    Latitude: "-26.185200"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "CHERBOURG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.928800",
    Latitude: "-26.287600"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "CLOYNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.822800",
    Latitude: "-26.113100"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "COBBS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.910100",
    Latitude: "-26.056500"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "CROWNTHORPE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.942600",
    Latitude: "-26.104100"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "GLENROCK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.796400",
    Latitude: "-26.091000"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "KITOBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.858100",
    Latitude: "-26.059700"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "MANYUNG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.011800",
    Latitude: "-26.204200"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "MERLWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.878800",
    Latitude: "-26.151400"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "MOFFATDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.998900",
    Latitude: "-26.307300"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "MOONDOONER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.016700",
    Latitude: "-26.239800"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "MURGON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.936000",
    Latitude: "-26.243900"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "OAKDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.912700",
    Latitude: "-26.198400"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "REDGATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.040200",
    Latitude: "-26.281100"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "SILVERLEAF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.802500",
    Latitude: "-26.184200"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "SUNNY NOOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.886200",
    Latitude: "-26.113300"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "TABLELANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.938000",
    Latitude: "-26.161200"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "WARNUNG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.832800",
    Latitude: "-26.154100"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "WINDERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.801500",
    Latitude: "-26.008900"
  },
  {
    Country: "Australia",
    Pcode: "4605",
    Locality: "WOOROONDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.752800",
    Latitude: "-26.112800"
  },
  {
    Country: "Australia",
    Pcode: "4606",
    Locality: "CHELMSFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.821100",
    Latitude: "-26.260700"
  },
  {
    Country: "Australia",
    Pcode: "4606",
    Locality: "FAIRDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.767000",
    Latitude: "-26.269800"
  },
  {
    Country: "Australia",
    Pcode: "4606",
    Locality: "FICKS CROSSING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.892000",
    Latitude: "-26.259000"
  },
  {
    Country: "Australia",
    Pcode: "4606",
    Locality: "GREENVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.805700",
    Latitude: "-26.311800"
  },
  {
    Country: "Australia",
    Pcode: "4606",
    Locality: "LEAFDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.771800",
    Latitude: "-26.236700"
  },
  {
    Country: "Australia",
    Pcode: "4606",
    Locality: "MOUNT MCEUEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.720900",
    Latitude: "-26.239600"
  },
  {
    Country: "Australia",
    Pcode: "4606",
    Locality: "MP CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.724900",
    Latitude: "-26.284500"
  },
  {
    Country: "Australia",
    Pcode: "4606",
    Locality: "WHEATLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.851900",
    Latitude: "-26.226000"
  },
  {
    Country: "Australia",
    Pcode: "4606",
    Locality: "WONDAI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.863600",
    Latitude: "-26.287900"
  },
  {
    Country: "Australia",
    Pcode: "4608",
    Locality: "CHARLESTOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.918900",
    Latitude: "-26.368300"
  },
  {
    Country: "Australia",
    Pcode: "4608",
    Locality: "CUSHNIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.745700",
    Latitude: "-26.351400"
  },
  {
    Country: "Australia",
    Pcode: "4608",
    Locality: "TINGOORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.812100",
    Latitude: "-26.357300"
  },
  {
    Country: "Australia",
    Pcode: "4608",
    Locality: "WILKESDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.652100",
    Latitude: "-26.348200"
  },
  {
    Country: "Australia",
    Pcode: "4608",
    Locality: "WOOROOLIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.793500",
    Latitude: "-26.414100"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "ALICE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.617200",
    Latitude: "-26.763100"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "BALLOGIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.509000",
    Latitude: "-26.342800"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "BENAIR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.691200",
    Latitude: "-26.635500"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "BOOIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.942700",
    Latitude: "-26.546100"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "BOYNESIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.494500",
    Latitude: "-26.713700"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "CHAHPINGAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.376100",
    Latitude: "-26.470400"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "COOLABUNIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.889000",
    Latitude: "-26.596900"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "CORNDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.880400",
    Latitude: "-26.445500"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "CRAWFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.803600",
    Latitude: "-26.493800"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "DANGORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.587300",
    Latitude: "-26.470100"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "DURONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.242800",
    Latitude: "-26.392900"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "ELLESMERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.732000",
    Latitude: "-26.758900"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "GOODGER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.831400",
    Latitude: "-26.666200"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "GORDONBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.704100",
    Latitude: "-26.467700"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "HALY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.727700",
    Latitude: "-26.693200"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "HODGLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.925200",
    Latitude: "-26.616200"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "HARRAMI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.690900",
    Latitude: "-24.786200"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "KALPOWAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.365100",
    Latitude: "-24.694300"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "KAPALDO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.142000",
    Latitude: "-25.065500"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "LANGLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.240100",
    Latitude: "-25.114800"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "MONAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.133600",
    Latitude: "-24.588300"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "MONTO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.111400",
    Latitude: "-24.839700"
  },
  {
    Country: "Australia",
    Pcode: "3020",
    Locality: "SUNSHINE WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.811000",
    Latitude: "-37.799600"
  },
  {
    Country: "Australia",
    Pcode: "3021",
    Locality: "ALBANVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.769000",
    Latitude: "-37.745900"
  },
  {
    Country: "Australia",
    Pcode: "3021",
    Locality: "KEALBA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.827200",
    Latitude: "-37.735700"
  },
  {
    Country: "Australia",
    Pcode: "3021",
    Locality: "KINGS PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.771900",
    Latitude: "-37.733700"
  },
  {
    Country: "Australia",
    Pcode: "3122",
    Locality: "GLENFERRIE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3122",
    Locality: "HAWTHORN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.030600",
    Latitude: "-37.825800"
  },
  {
    Country: "Australia",
    Pcode: "3122",
    Locality: "HAWTHORN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3122",
    Locality: "HAWTHORN WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3123",
    Locality: "AUBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3123",
    Locality: "HAWTHORN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.049800",
    Latitude: "-37.830900"
  },
  {
    Country: "Australia",
    Pcode: "3124",
    Locality: "CAMBERWELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.074000",
    Latitude: "-37.835600"
  },
  {
    Country: "Australia",
    Pcode: "3124",
    Locality: "CAMBERWELL NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3124",
    Locality: "CAMBERWELL SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3124",
    Locality: "CAMBERWELL WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3124",
    Locality: "HARTWELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3124",
    Locality: "MIDDLE CAMBERWELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3125",
    Locality: "BENNETTSWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3125",
    Locality: "BURWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.114400",
    Latitude: "-37.848500"
  },
  {
    Country: "Australia",
    Pcode: "3125",
    Locality: "SURREY HILLS SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3126",
    Locality: "CAMBERWELL EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3126",
    Locality: "CANTERBURY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.077400",
    Latitude: "-37.824200"
  },
  {
    Country: "Australia",
    Pcode: "3127",
    Locality: "MONT ALBERT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.108000",
    Latitude: "-37.817700"
  },
  {
    Country: "Australia",
    Pcode: "3127",
    Locality: "SURREY HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.100200",
    Latitude: "-37.825200"
  },
  {
    Country: "Australia",
    Pcode: "3127",
    Locality: "SURREY HILLS NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3128",
    Locality: "BOX HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.126000",
    Latitude: "-37.821500"
  },
  {
    Country: "Australia",
    Pcode: "3128",
    Locality: "BOX HILL CENTRAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3128",
    Locality: "BOX HILL SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.123300",
    Latitude: "-37.836700"
  },
  {
    Country: "Australia",
    Pcode: "3128",
    Locality: "HOUSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3128",
    Locality: "WATTLE PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3129",
    Locality: "BOX HILL NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.129100",
    Latitude: "-37.805300"
  },
  {
    Country: "Australia",
    Pcode: "3129",
    Locality: "KERRIMUIR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3129",
    Locality: "MONT ALBERT NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.110600",
    Latitude: "-37.803400"
  },
  {
    Country: "Australia",
    Pcode: "3130",
    Locality: "BLACKBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.150900",
    Latitude: "-37.821500"
  },
  {
    Country: "Australia",
    Pcode: "3130",
    Locality: "BLACKBURN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.155300",
    Latitude: "-37.804600"
  },
  {
    Country: "Australia",
    Pcode: "3130",
    Locality: "BLACKBURN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.147000",
    Latitude: "-37.839200"
  },
  {
    Country: "Australia",
    Pcode: "3130",
    Locality: "LABURNUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3131",
    Locality: "BRENTFORD SQUARE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3131",
    Locality: "FOREST HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.171100",
    Latitude: "-37.841300"
  },
  {
    Country: "Australia",
    Pcode: "3131",
    Locality: "NUNAWADING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.176500",
    Latitude: "-37.817300"
  },
  {
    Country: "Australia",
    Pcode: "3132",
    Locality: "MITCHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.199700",
    Latitude: "-37.816900"
  },
  {
    Country: "Australia",
    Pcode: "3132",
    Locality: "MITCHAM NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3132",
    Locality: "RANGEVIEW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3133",
    Locality: "VERMONT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.199600",
    Latitude: "-37.838700"
  },
  {
    Country: "Australia",
    Pcode: "3133",
    Locality: "VERMONT SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.187700",
    Latitude: "-37.855300"
  },
  {
    Country: "Australia",
    Pcode: "3134",
    Locality: "HEATHWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3134",
    Locality: "RINGWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.228300",
    Latitude: "-37.809900"
  },
  {
    Country: "Australia",
    Pcode: "3134",
    Locality: "RINGWOOD NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.233500",
    Latitude: "-37.792100"
  },
  {
    Country: "Australia",
    Pcode: "3134",
    Locality: "WARRANDYTE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.246100",
    Latitude: "-37.760200"
  },
  {
    Country: "Australia",
    Pcode: "3134",
    Locality: "WARRANWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.250600",
    Latitude: "-37.773000"
  },
  {
    Country: "Australia",
    Pcode: "3135",
    Locality: "BEDFORD ROAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3135",
    Locality: "HEATHMONT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.246000",
    Latitude: "-37.828800"
  },
  {
    Country: "Australia",
    Pcode: "3135",
    Locality: "RINGWOOD EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.252500",
    Latitude: "-37.812400"
  },
  {
    Country: "Australia",
    Pcode: "3136",
    Locality: "CROYDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.282000",
    Latitude: "-37.791300"
  },
  {
    Country: "Australia",
    Pcode: "3136",
    Locality: "CROYDON HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.266100",
    Latitude: "-37.775200"
  },
  {
    Country: "Australia",
    Pcode: "3136",
    Locality: "CROYDON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.285700",
    Latitude: "-37.772200"
  },
  {
    Country: "Australia",
    Pcode: "3136",
    Locality: "CROYDON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.278800",
    Latitude: "-37.815500"
  },
  {
    Country: "Australia",
    Pcode: "3137",
    Locality: "KILSYTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.318100",
    Latitude: "-37.810700"
  },
  {
    Country: "Australia",
    Pcode: "3137",
    Locality: "KILSYTH SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.309900",
    Latitude: "-37.831400"
  },
  {
    Country: "Australia",
    Pcode: "3138",
    Locality: "MOOROOLBARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.325800",
    Latitude: "-37.778500"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "BEENAK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.636500",
    Latitude: "-37.901000"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "DON VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.592000",
    Latitude: "-37.742300"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "HODDLES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.593600",
    Latitude: "-37.850500"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "LAUNCHING PLACE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.577600",
    Latitude: "-37.784100"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "SEVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.474100",
    Latitude: "-37.789800"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "SEVILLE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.503200",
    Latitude: "-37.773000"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "WANDIN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.455300",
    Latitude: "-37.812900"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "WANDIN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.430500",
    Latitude: "-37.788300"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "WOORI YALLOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.541600",
    Latitude: "-37.797700"
  },
  {
    Country: "Australia",
    Pcode: "3139",
    Locality: "YELLINGBO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.521500",
    Latitude: "-37.848700"
  },
  {
    Country: "Australia",
    Pcode: "3140",
    Locality: "LILYDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.371600",
    Latitude: "-37.761700"
  },
  {
    Country: "Australia",
    Pcode: "3141",
    Locality: "SOUTH YARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.991400",
    Latitude: "-37.837800"
  },
  {
    Country: "Australia",
    Pcode: "3142",
    Locality: "HAWKSBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3142",
    Locality: "TOORAK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.018000",
    Latitude: "-37.841000"
  },
  {
    Country: "Australia",
    Pcode: "3222",
    Locality: "CURLEWIS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.522000",
    Latitude: "-38.178400"
  },
  {
    Country: "Australia",
    Pcode: "3222",
    Locality: "DRYSDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.598400",
    Latitude: "-38.175100"
  },
  {
    Country: "Australia",
    Pcode: "3222",
    Locality: "MANNERIM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.607900",
    Latitude: "-38.222000"
  },
  {
    Country: "Australia",
    Pcode: "3222",
    Locality: "MARCUS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.577700",
    Latitude: "-38.252000"
  },
  {
    Country: "Australia",
    Pcode: "3222",
    Locality: "WALLINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.507000",
    Latitude: "-38.228500"
  },
  {
    Country: "Australia",
    Pcode: "3223",
    Locality: "BELLARINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.621900",
    Latitude: "-38.132100"
  },
  {
    Country: "Australia",
    Pcode: "3223",
    Locality: "INDENTED HEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.699900",
    Latitude: "-38.135300"
  },
  {
    Country: "Australia",
    Pcode: "3223",
    Locality: "PORTARLINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.656500",
    Latitude: "-38.140700"
  },
  {
    Country: "Australia",
    Pcode: "3223",
    Locality: "ST LEONARDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.679700",
    Latitude: "-38.181300"
  },
  {
    Country: "Australia",
    Pcode: "3224",
    Locality: "LEOPOLD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.470100",
    Latitude: "-38.196400"
  },
  {
    Country: "Australia",
    Pcode: "3224",
    Locality: "MOOLAP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.425700",
    Latitude: "-38.173300"
  },
  {
    Country: "Australia",
    Pcode: "3225",
    Locality: "POINT LONSDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.598200",
    Latitude: "-38.265800"
  },
  {
    Country: "Australia",
    Pcode: "3225",
    Locality: "QUEENSCLIFF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.663400",
    Latitude: "-38.266200"
  },
  {
    Country: "Australia",
    Pcode: "3225",
    Locality: "SWAN BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.667600",
    Latitude: "-38.232300"
  },
  {
    Country: "Australia",
    Pcode: "3225",
    Locality: "SWAN ISLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.680500",
    Latitude: "-38.249400"
  },
  {
    Country: "Australia",
    Pcode: "3226",
    Locality: "OCEAN GROVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.530400",
    Latitude: "-38.258400"
  },
  {
    Country: "Australia",
    Pcode: "3227",
    Locality: "BARWON HEADS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.488600",
    Latitude: "-38.274800"
  },
  {
    Country: "Australia",
    Pcode: "3227",
    Locality: "BREAMLEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.387400",
    Latitude: "-38.294200"
  },
  {
    Country: "Australia",
    Pcode: "3227",
    Locality: "CONNEWARRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.435200",
    Latitude: "-38.250700"
  },
  {
    Country: "Australia",
    Pcode: "3228",
    Locality: "BELLBRAE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.242600",
    Latitude: "-38.341600"
  },
  {
    Country: "Australia",
    Pcode: "3228",
    Locality: "BELLS BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.251800",
    Latitude: "-38.377000"
  },
  {
    Country: "Australia",
    Pcode: "3228",
    Locality: "JAN JUC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.300100",
    Latitude: "-38.345000"
  },
  {
    Country: "Australia",
    Pcode: "3228",
    Locality: "TORQUAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.325700",
    Latitude: "-38.312900"
  },
  {
    Country: "Australia",
    Pcode: "3230",
    Locality: "ANGLESEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.153500",
    Latitude: "-38.379300"
  },
  {
    Country: "Australia",
    Pcode: "3231",
    Locality: "AIREYS INLET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.097600",
    Latitude: "-38.437900"
  },
  {
    Country: "Australia",
    Pcode: "3231",
    Locality: "BIG HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "LORNE",
    Category: "Delivery Area",
    Longitude: "144.009500",
    Latitude: "-38.480100"
  },
  {
    Country: "Australia",
    Pcode: "3231",
    Locality: "EASTERN VIEW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.043000",
    Latitude: "-38.449400"
  },
  {
    Country: "Australia",
    Pcode: "3231",
    Locality: "FAIRHAVEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.085700",
    Latitude: "-38.456400"
  },
  {
    Country: "Australia",
    Pcode: "3231",
    Locality: "MOGGS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.067300",
    Latitude: "-38.455300"
  },
  {
    Country: "Australia",
    Pcode: "3232",
    Locality: "LORNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.921200",
    Latitude: "-38.523200"
  },
  {
    Country: "Australia",
    Pcode: "3233",
    Locality: "APOLLO BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.646000",
    Latitude: "-38.731300"
  },
  {
    Country: "Australia",
    Pcode: "3233",
    Locality: "CAPE OTWAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.555800",
    Latitude: "-38.801900"
  },
  {
    Country: "Australia",
    Pcode: "3233",
    Locality: "MARENGO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.655400",
    Latitude: "-38.779400"
  },
  {
    Country: "Australia",
    Pcode: "3233",
    Locality: "PETTICOAT CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.733800",
    Latitude: "-38.714400"
  },
  {
    Country: "Australia",
    Pcode: "3233",
    Locality: "SKENES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.709200",
    Latitude: "-38.712000"
  },
  {
    Country: "Australia",
    Pcode: "3233",
    Locality: "SKENES CREEK NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.715500",
    Latitude: "-38.692400"
  },
  {
    Country: "Australia",
    Pcode: "3234",
    Locality: "GREY RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.833600",
    Latitude: "-38.680500"
  },
  {
    Country: "Australia",
    Pcode: "3234",
    Locality: "KENNETT RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.804700",
    Latitude: "-38.642000"
  },
  {
    Country: "Australia",
    Pcode: "3234",
    Locality: "SEPARATION CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.893800",
    Latitude: "-38.599000"
  },
  {
    Country: "Australia",
    Pcode: "3234",
    Locality: "SUGARLOAF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.796100",
    Latitude: "-38.690000"
  },
  {
    Country: "Australia",
    Pcode: "3234",
    Locality: "WONGARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.798600",
    Latitude: "-38.674700"
  },
  {
    Country: "Australia",
    Pcode: "3234",
    Locality: "WYE RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.856000",
    Latitude: "-38.630300"
  },
  {
    Country: "Australia",
    Pcode: "3235",
    Locality: "BENWERRIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.895600",
    Latitude: "-38.491100"
  },
  {
    Country: "Australia",
    Pcode: "3235",
    Locality: "BOONAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.983200",
    Latitude: "-38.425600"
  },
  {
    Country: "Australia",
    Pcode: "3235",
    Locality: "DEANS MARSH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.898600",
    Latitude: "-38.406800"
  },
  {
    Country: "Australia",
    Pcode: "3235",
    Locality: "PENNYROYAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.872200",
    Latitude: "-38.434900"
  },
  {
    Country: "Australia",
    Pcode: "3236",
    Locality: "FORREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.693600",
    Latitude: "-38.513800"
  },
  {
    Country: "Australia",
    Pcode: "3236",
    Locality: "MOUNT SABINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.750700",
    Latitude: "-38.633800"
  },
  {
    Country: "Australia",
    Pcode: "3237",
    Locality: "AIRE VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.562600",
    Latitude: "-38.693100"
  },
  {
    Country: "Australia",
    Pcode: "3237",
    Locality: "BEECH FOREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.602500",
    Latitude: "-38.634100"
  },
  {
    Country: "Australia",
    Pcode: "3237",
    Locality: "FERGUSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.521400",
    Latitude: "-38.625500"
  },
  {
    Country: "Australia",
    Pcode: "3237",
    Locality: "GELLIBRAND LOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.223900",
    Latitude: "-38.715800"
  },
  {
    Country: "Australia",
    Pcode: "3237",
    Locality: "WATTLE HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.254300",
    Latitude: "-38.752800"
  },
  {
    Country: "Australia",
    Pcode: "3237",
    Locality: "WEEAPROINAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.493300",
    Latitude: "-38.631500"
  },
  {
    Country: "Australia",
    Pcode: "3237",
    Locality: "WYELANGTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.456700",
    Latitude: "-38.649700"
  },
  {
    Country: "Australia",
    Pcode: "3237",
    Locality: "YUULONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.307500",
    Latitude: "-38.722400"
  },
  {
    Country: "Australia",
    Pcode: "3238",
    Locality: "GLENAIRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.467800",
    Latitude: "-38.748100"
  },
  {
    Country: "Australia",
    Pcode: "3238",
    Locality: "HORDERN VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.489700",
    Latitude: "-38.793500"
  },
  {
    Country: "Australia",
    Pcode: "3238",
    Locality: "JOHANNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.398800",
    Latitude: "-38.732500"
  },
  {
    Country: "Australia",
    Pcode: "3238",
    Locality: "LAVERS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.387600",
    Latitude: "-38.667500"
  },
  {
    Country: "Australia",
    Pcode: "3239",
    Locality: "CARLISLE RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.434900",
    Latitude: "-38.568300"
  },
  {
    Country: "Australia",
    Pcode: "3239",
    Locality: "CHAPPLE VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.301800",
    Latitude: "-38.624200"
  },
  {
    Country: "Australia",
    Pcode: "3239",
    Locality: "GELLIBRAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.555300",
    Latitude: "-38.528100"
  },
  {
    Country: "Australia",
    Pcode: "3239",
    Locality: "KENNEDYS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.242700",
    Latitude: "-38.598500"
  },
  {
    Country: "Australia",
    Pcode: "3240",
    Locality: "BUCKLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.091500",
    Latitude: "-38.219900"
  },
  {
    Country: "Australia",
    Pcode: "3240",
    Locality: "GHERANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.106500",
    Latitude: "-38.311000"
  },
  {
    Country: "Australia",
    Pcode: "3240",
    Locality: "MODEWARRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.121700",
    Latitude: "-38.266000"
  },
  {
    Country: "Australia",
    Pcode: "3240",
    Locality: "MORIAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.174200",
    Latitude: "-38.247200"
  },
  {
    Country: "Australia",
    Pcode: "3240",
    Locality: "MOUNT MORIAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.190200",
    Latitude: "-38.210400"
  },
  {
    Country: "Australia",
    Pcode: "3240",
    Locality: "PARAPARAP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.183700",
    Latitude: "-38.302200"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "DARTMOOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.311300",
    Latitude: "-37.846400"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "DRIK DRIK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.283800",
    Latitude: "-38.009700"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "DRUMBORG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.491500",
    Latitude: "-38.104600"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "GREENWALD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.386400",
    Latitude: "-37.995600"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "HEYWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.627600",
    Latitude: "-38.133100"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "HOMERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.801800",
    Latitude: "-38.134300"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "LYONS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.480200",
    Latitude: "-37.999900"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "MILLTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.695200",
    Latitude: "-38.054300"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "MUMBANNAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.095500",
    Latitude: "-37.932900"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "MYAMYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.718100",
    Latitude: "-38.006300"
  },
  {
    Country: "Australia",
    Pcode: "3304",
    Locality: "WINNAP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.394000",
    Latitude: "-37.907000"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "ALLESTREE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.648600",
    Latitude: "-38.273800"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "BOLWARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.611000",
    Latitude: "-38.278800"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "CAPE BRIDGEWATER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.418100",
    Latitude: "-38.319100"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "CASHMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.502300",
    Latitude: "-38.307800"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "DUTTON WAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.618400",
    Latitude: "-38.301200"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "GORAE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.558300",
    Latitude: "-38.237000"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "GORAE WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.464200",
    Latitude: "-38.247200"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "HEATHMERE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.638700",
    Latitude: "-38.211700"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "MOUNT RICHMOND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.335700",
    Latitude: "-38.152900"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "PORTLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.608400",
    Latitude: "-38.362700"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "PORTLAND NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.571000",
    Latitude: "-38.299900"
  },
  {
    Country: "Australia",
    Pcode: "3305",
    Locality: "PORTLAND WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.538300",
    Latitude: "-38.375400"
  },
  {
    Country: "Australia",
    Pcode: "3309",
    Locality: "DIGBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.539800",
    Latitude: "-37.813400"
  },
  {
    Country: "Australia",
    Pcode: "3310",
    Locality: "MERINO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.529400",
    Latitude: "-37.734500"
  },
  {
    Country: "Australia",
    Pcode: "3310",
    Locality: "TAHARA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.625600",
    Latitude: "-37.733800"
  },
  {
    Country: "Australia",
    Pcode: "3311",
    Locality: "CASTERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.291900",
    Latitude: "-37.600200"
  },
  {
    Country: "Australia",
    Pcode: "3311",
    Locality: "CORNDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.250900",
    Latitude: "-37.531000"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "BAHGALLAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.283300",
    Latitude: "-37.702100"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "BRIMBOAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.406100",
    Latitude: "-37.394600"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "CARAPOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.569600",
    Latitude: "-37.514400"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "CHETWYND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.381400",
    Latitude: "-37.301800"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "DERGHOLM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.200500",
    Latitude: "-37.343200"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "DORODONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.053300",
    Latitude: "-37.322600"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "DUNROBIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.379400",
    Latitude: "-37.518400"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "HENTY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.529900",
    Latitude: "-37.641900"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "KILLARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "HENTY",
    Category: "Delivery Area",
    Longitude: "141.400700",
    Latitude: "-37.757000"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "LAKE MUNDI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.089900",
    Latitude: "-37.487400"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "LINDSAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.117300",
    Latitude: "-37.602300"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "NANGEELA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.331000",
    Latitude: "-37.480300"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "POOLAIJELO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.077900",
    Latitude: "-37.183500"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "POWERS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.243700",
    Latitude: "-37.221700"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "SANDFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.446100",
    Latitude: "-37.646600"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "STRATHDOWNIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.117700",
    Latitude: "-37.740300"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "WANDO BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.423200",
    Latitude: "-37.442400"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "WANDO VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.491300",
    Latitude: "-37.487500"
  },
  {
    Country: "Australia",
    Pcode: "3312",
    Locality: "WARROCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.283700",
    Latitude: "-37.430500"
  },
  {
    Country: "Australia",
    Pcode: "3314",
    Locality: "BULART",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.917500",
    Latitude: "-37.584500"
  },
  {
    Country: "Australia",
    Pcode: "3314",
    Locality: "CAVENDISH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.071400",
    Latitude: "-37.487200"
  },
  {
    Country: "Australia",
    Pcode: "3314",
    Locality: "GLENISLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.261800",
    Latitude: "-37.217300"
  },
  {
    Country: "Australia",
    Pcode: "3314",
    Locality: "GRAMPIANS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.278800",
    Latitude: "-37.373800"
  },
  {
    Country: "Australia",
    Pcode: "3314",
    Locality: "MOORALLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.108400",
    Latitude: "-37.380300"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "BRIT BRIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.740600",
    Latitude: "-37.425700"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "CLOVER FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.559800",
    Latitude: "-37.608300"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "COLERAINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.673100",
    Latitude: "-37.589700"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "COOJAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.659400",
    Latitude: "-37.360800"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "CULLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.621500",
    Latitude: "-37.240300"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "GRINGEGALGONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.811900",
    Latitude: "-37.405800"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "GRITJURK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.810300",
    Latitude: "-37.565800"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "HILGAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.632900",
    Latitude: "-37.620900"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "KONONGWOOTONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.665100",
    Latitude: "-37.469900"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "MELVILLE FOREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.867200",
    Latitude: "-37.482600"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "MUNTHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.542100",
    Latitude: "-37.574300"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "NAREEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.534300",
    Latitude: "-37.383700"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "PASCHENDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.610100",
    Latitude: "-37.659900"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "TAHARA BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.656500",
    Latitude: "-37.682100"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "TARRAYOUKYAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.556800",
    Latitude: "-37.301800"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "TARRENLEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.737200",
    Latitude: "-37.676900"
  },
  {
    Country: "Australia",
    Pcode: "3315",
    Locality: "WOOTONG VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.733700",
    Latitude: "-37.517000"
  },
  {
    Country: "Australia",
    Pcode: "3317",
    Locality: "HARROW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.624200",
    Latitude: "-37.143800"
  },
  {
    Country: "Australia",
    Pcode: "3318",
    Locality: "CHARAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.512800",
    Latitude: "-36.949800"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "ADDINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.666600",
    Latitude: "-37.391500"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "BLOWHARD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.787100",
    Latitude: "-37.431800"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "BOLWARRAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.107700",
    Latitude: "-37.509000"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "BONSHAW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.813400",
    Latitude: "-37.603700"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "BREWSTER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.529300",
    Latitude: "-37.496200"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "BULLAROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.004600",
    Latitude: "-37.510900"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "BUNGAREE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.004100",
    Latitude: "-37.561000"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "BUNKERS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.752100",
    Latitude: "-37.574500"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "BURRUMBEET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.627800",
    Latitude: "-37.496400"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "CAMBRIAN HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.819000",
    Latitude: "-37.636500"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "CARDIGAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.745400",
    Latitude: "-37.534300"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "CARDIGAN VILLAGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.709900",
    Latitude: "-37.516900"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "CHAPEL FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.896300",
    Latitude: "-37.478700"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "CLARENDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.971500",
    Latitude: "-37.699600"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "CLARETOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.032000",
    Latitude: "-37.521700"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "CLARKES HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.978500",
    Latitude: "-37.497300"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "CORINDHAP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.757000",
    Latitude: "-37.864700"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "DEREEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.760500",
    Latitude: "-37.825900"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "DUNNSTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.967700",
    Latitude: "-37.592700"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "DURHAM LEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.882600",
    Latitude: "-37.697100"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "ENFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.774300",
    Latitude: "-37.753000"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "ERCILDOUNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.588000",
    Latitude: "-37.419400"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "GARIBALDI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.858500",
    Latitude: "-37.730400"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "GLEN PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.922100",
    Latitude: "-37.504100"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "GLENBRAE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.554500",
    Latitude: "-37.348000"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "GONG GONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.936600",
    Latitude: "-37.547500"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "GRENVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.909900",
    Latitude: "-37.757600"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "INVERMAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.877100",
    Latitude: "-37.504800"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "LAL LAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.027000",
    Latitude: "-37.664300"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "LAMPLOUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.519400",
    Latitude: "-37.148600"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "LANGI KAL KAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.492100",
    Latitude: "-37.376500"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "LEARMONTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.723900",
    Latitude: "-37.420100"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "LEIGH CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.961900",
    Latitude: "-37.560600"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "LEXTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.516300",
    Latitude: "-37.251500"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "MAGPIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.850200",
    Latitude: "-37.632800"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "MILLBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.045500",
    Latitude: "-37.612300"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "MINERS REST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.797800",
    Latitude: "-37.486100"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "MOLLONGGHIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.042000",
    Latitude: "-37.467400"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "MOUNT BOLTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.694600",
    Latitude: "-37.356700"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "MOUNT EGERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.119100",
    Latitude: "-37.655900"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "MOUNT MERCER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.867200",
    Latitude: "-37.837100"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "MOUNT ROWAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.840200",
    Latitude: "-37.500300"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "NAPOLEONS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.805200",
    Latitude: "-37.690200"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "NAVIGATORS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.932000",
    Latitude: "-37.609000"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "POOTILLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.958200",
    Latitude: "-37.528200"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "SCOTCHMANS LEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.848100",
    Latitude: "-37.666700"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "SCOTSBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.935400",
    Latitude: "-37.688600"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "SPRINGBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.066000",
    Latitude: "-37.517700"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "SULKY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.863800",
    Latitude: "-37.458900"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "WALLACE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.055700",
    Latitude: "-37.562200"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "WARRENHEIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.925200",
    Latitude: "-37.577300"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "WATTLE FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.945900",
    Latitude: "-37.481200"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "WAUBRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.631800",
    Latitude: "-37.335200"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "WEATHERBOARD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.671700",
    Latitude: "-37.434300"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "WERNETH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.600900",
    Latitude: "-37.932200"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "WINDERMERE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.715700",
    Latitude: "-37.485300"
  },
  {
    Country: "Australia",
    Pcode: "3352",
    Locality: "YENDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.976800",
    Latitude: "-37.644900"
  },
  {
    Country: "Australia",
    Pcode: "3353",
    Locality: "BALLARAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3354",
    Locality: "BAKERY HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3354",
    Locality: "BALLARAT MC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3355",
    Locality: "LAKE GARDENS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.815800",
    Latitude: "-37.546900"
  },
  {
    Country: "Australia",
    Pcode: "3355",
    Locality: "MITCHELL PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.777000",
    Latitude: "-37.509000"
  },
  {
    Country: "Australia",
    Pcode: "3355",
    Locality: "WENDOUREE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.826600",
    Latitude: "-37.530900"
  },
  {
    Country: "Australia",
    Pcode: "3355",
    Locality: "WENDOUREE VILLAGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3356",
    Locality: "DELACOMBE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.814400",
    Latitude: "-37.581400"
  },
  {
    Country: "Australia",
    Pcode: "3356",
    Locality: "SEBASTOPOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.834200",
    Latitude: "-37.601400"
  },
  {
    Country: "Australia",
    Pcode: "3357",
    Locality: "BUNINYONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.893200",
    Latitude: "-37.648200"
  },
  {
    Country: "Australia",
    Pcode: "3358",
    Locality: "WINTER VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.794500",
    Latitude: "-37.579300"
  },
  {
    Country: "Australia",
    Pcode: "3360",
    Locality: "HAPPY VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.596400",
    Latitude: "-37.730100"
  },
  {
    Country: "Australia",
    Pcode: "3360",
    Locality: "LINTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.552900",
    Latitude: "-37.696800"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "METCALFE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.457400",
    Latitude: "-37.145600"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "MIA MIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.610500",
    Latitude: "-36.984700"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "PASTORIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.581100",
    Latitude: "-37.214100"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "PASTORIA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.630500",
    Latitude: "-37.178300"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "PIPERS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.545000",
    Latitude: "-37.252200"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "REDESDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.472300",
    Latitude: "-37.001900"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "SIDONIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.562500",
    Latitude: "-37.164100"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "SPRING HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.348700",
    Latitude: "-37.297300"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "TYLDEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.410500",
    Latitude: "-37.323200"
  },
  {
    Country: "Australia",
    Pcode: "3444",
    Locality: "TYLDEN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.374100",
    Latitude: "-37.353500"
  },
  {
    Country: "Australia",
    Pcode: "3446",
    Locality: "DRUMMOND NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.323500",
    Latitude: "-37.193800"
  },
  {
    Country: "Australia",
    Pcode: "3446",
    Locality: "MALMSBURY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.382600",
    Latitude: "-37.176200"
  },
  {
    Country: "Australia",
    Pcode: "3447",
    Locality: "TARADALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.344400",
    Latitude: "-37.140900"
  },
  {
    Country: "Australia",
    Pcode: "3448",
    Locality: "ELPHINSTONE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.359200",
    Latitude: "-37.076100"
  },
  {
    Country: "Australia",
    Pcode: "3448",
    Locality: "METCALFE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.441500",
    Latitude: "-37.089000"
  },
  {
    Country: "Australia",
    Pcode: "3448",
    Locality: "SUTTON GRANGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.370600",
    Latitude: "-36.988000"
  },
  {
    Country: "Australia",
    Pcode: "3450",
    Locality: "CASTLEMAINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.216400",
    Latitude: "-37.059200"
  },
  {
    Country: "Australia",
    Pcode: "3450",
    Locality: "MOONLIGHT FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.244400",
    Latitude: "-37.062100"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "BARKERS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.223800",
    Latitude: "-37.021900"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "CAMPBELLS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.211500",
    Latitude: "-37.107300"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "CHEWTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.269600",
    Latitude: "-37.091300"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "CHEWTON BUSHLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.293500",
    Latitude: "-37.078000"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "FARADAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.298400",
    Latitude: "-37.051500"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "FRYERSTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.261200",
    Latitude: "-37.139500"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "GLENLUCE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.243900",
    Latitude: "-37.179500"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "GOLDEN POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.273900",
    Latitude: "-37.064600"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "GUILDFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.166800",
    Latitude: "-37.190400"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "IRISHTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.227300",
    Latitude: "-37.147500"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "MCKENZIE HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.183900",
    Latitude: "-37.069300"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "MUCKLEFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.148000",
    Latitude: "-37.054100"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "TARILTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.191500",
    Latitude: "-37.178300"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "VAUGHAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.215900",
    Latitude: "-37.161000"
  },
  {
    Country: "Australia",
    Pcode: "3451",
    Locality: "YAPEEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.166100",
    Latitude: "-37.116300"
  },
  {
    Country: "Australia",
    Pcode: "3453",
    Locality: "HARCOURT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.255700",
    Latitude: "-36.998300"
  },
  {
    Country: "Australia",
    Pcode: "3453",
    Locality: "HARCOURT NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.272400",
    Latitude: "-36.929700"
  },
  {
    Country: "Australia",
    Pcode: "3453",
    Locality: "RAVENSWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.210400",
    Latitude: "-36.889300"
  },
  {
    Country: "Australia",
    Pcode: "3453",
    Locality: "RAVENSWOOD SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.215700",
    Latitude: "-36.946000"
  },
  {
    Country: "Australia",
    Pcode: "3458",
    Locality: "BARRYS REEF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.312200",
    Latitude: "-37.455400"
  },
  {
    Country: "Australia",
    Pcode: "3458",
    Locality: "BLACKWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.286900",
    Latitude: "-37.488800"
  },
  {
    Country: "Australia",
    Pcode: "3458",
    Locality: "FERN HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.413700",
    Latitude: "-37.371600"
  },
  {
    Country: "Australia",
    Pcode: "3458",
    Locality: "LERDERDERG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.420300",
    Latitude: "-37.517100"
  },
  {
    Country: "Australia",
    Pcode: "3458",
    Locality: "LITTLE HAMPTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.288200",
    Latitude: "-37.362200"
  },
  {
    Country: "Australia",
    Pcode: "3458",
    Locality: "NEWBURY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.283700",
    Latitude: "-37.421300"
  },
  {
    Country: "Australia",
    Pcode: "3458",
    Locality: "NORTH BLACKWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.361800",
    Latitude: "-37.416800"
  },
  {
    Country: "Australia",
    Pcode: "3458",
    Locality: "TRENTHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.335800",
    Latitude: "-37.380500"
  },
  {
    Country: "Australia",
    Pcode: "3458",
    Locality: "TRENTHAM EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.395700",
    Latitude: "-37.407400"
  },
  {
    Country: "Australia",
    Pcode: "3460",
    Locality: "BASALT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.105300",
    Latitude: "-37.308500"
  },
  {
    Country: "Australia",
    Pcode: "3460",
    Locality: "DAYLESFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.158900",
    Latitude: "-37.348600"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "BULLARTO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.220600",
    Latitude: "-37.381500"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "BULLARTO SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.217600",
    Latitude: "-37.422800"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "CLYDESDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.097800",
    Latitude: "-37.174200"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "COOMOORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.194900",
    Latitude: "-37.330000"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "DENVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.294600",
    Latitude: "-37.285800"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "DRUMMOND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.304900",
    Latitude: "-37.242800"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "DRY DIGGINGS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.158100",
    Latitude: "-37.297500"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "EGANSTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.093300",
    Latitude: "-37.362500"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "ELEVATED PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.132000",
    Latitude: "-37.294700"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "FRANKLINFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.091700",
    Latitude: "-37.240400"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "GLENLYON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.245700",
    Latitude: "-37.262900"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "HEPBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.126600",
    Latitude: "-37.309800"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "HEPBURN SPRINGS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.146700",
    Latitude: "-37.316200"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "KORWEINGUBOORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.130000",
    Latitude: "-37.441500"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "LEONARDS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.138700",
    Latitude: "-37.412700"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "LYONVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.267000",
    Latitude: "-37.396900"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "MOUNT FRANKLIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.158000",
    Latitude: "-37.260200"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "MUSK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.197000",
    Latitude: "-37.369000"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "MUSK VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.160200",
    Latitude: "-37.380400"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "PORCUPINE RIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.193300",
    Latitude: "-37.272400"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "SAILORS FALLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.115300",
    Latitude: "-37.392600"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "SAILORS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.126400",
    Latitude: "-37.358900"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "JARKLIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.955200",
    Latitude: "-36.249000"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "KINGOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.749600",
    Latitude: "-36.626700"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "KURTING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.813200",
    Latitude: "-36.541400"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "POWLETT PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.857100",
    Latitude: "-36.470300"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "RHEOLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.691500",
    Latitude: "-36.647900"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "SALISBURY WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.925800",
    Latitude: "-36.527600"
  },
  {
    Country: "Australia",
    Pcode: "3517",
    Locality: "SERPENTINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.997700",
    Latitude: "-36.435700"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "BERRIMAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.475600",
    Latitude: "-36.498000"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "BORUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.776400",
    Latitude: "-36.287400"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "FENTONS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.526700",
    Latitude: "-36.557000"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "FERNIHURST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.866700",
    Latitude: "-36.230300"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "FIERY FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.811900",
    Latitude: "-36.412500"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "KURRACA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.614600",
    Latitude: "-36.517200"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "KURRACA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.565400",
    Latitude: "-36.511200"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "MYSIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.751000",
    Latitude: "-36.228700"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "NINE MILE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.467400",
    Latitude: "-36.451600"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "RICHMOND PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.482100",
    Latitude: "-36.396100"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "SKINNERS FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.582800",
    Latitude: "-36.354700"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "WEDDERBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.592500",
    Latitude: "-36.424800"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "WEDDERBURN JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.699700",
    Latitude: "-36.430500"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "WEHLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.623200",
    Latitude: "-36.607700"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "WOOLSHED FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.658800",
    Latitude: "-36.331200"
  },
  {
    Country: "Australia",
    Pcode: "3518",
    Locality: "WOOSANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.449100",
    Latitude: "-36.327000"
  },
  {
    Country: "Australia",
    Pcode: "3520",
    Locality: "KINYPANIAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.795500",
    Latitude: "-36.355900"
  },
  {
    Country: "Australia",
    Pcode: "3520",
    Locality: "KORONG VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.706300",
    Latitude: "-36.363000"
  },
  {
    Country: "Australia",
    Pcode: "3521",
    Locality: "PYALONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.849800",
    Latitude: "-37.111100"
  },
  {
    Country: "Australia",
    Pcode: "3522",
    Locality: "GLENHOPE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.697500",
    Latitude: "-37.070700"
  },
  {
    Country: "Australia",
    Pcode: "3522",
    Locality: "TOOBORAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.803300",
    Latitude: "-37.047500"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "ARGYLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.715000",
    Latitude: "-36.966900"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "COSTERFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.795800",
    Latitude: "-36.870600"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "DERRINAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.568200",
    Latitude: "-36.895100"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "HEATHCOTE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.723300",
    Latitude: "-36.911400"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "HEATHCOTE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.703500",
    Latitude: "-37.005300"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "KNOWSLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.597700",
    Latitude: "-36.827200"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "LADYS PASS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.689800",
    Latitude: "-36.821500"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "MOORMBOOL WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.856700",
    Latitude: "-36.814500"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "MOUNT CAMEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.743400",
    Latitude: "-36.807000"
  },
  {
    Country: "Australia",
    Pcode: "3523",
    Locality: "REDCASTLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.835500",
    Latitude: "-36.737700"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "BARRAKEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.448700",
    Latitude: "-36.258200"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "BUCKRABANYULE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.533900",
    Latitude: "-36.265000"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "CHARLTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.343500",
    Latitude: "-36.280200"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "CHIRRIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.126000",
    Latitude: "-36.162500"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "GRANITE FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.132500",
    Latitude: "-36.213000"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "LAKE MARMAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.533400",
    Latitude: "-36.117800"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "NAREEWILLOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.437900",
    Latitude: "-36.158700"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "TERRAPPEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.577500",
    Latitude: "-36.154100"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "WOOROONOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.213600",
    Latitude: "-36.266700"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "WYCHITELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.625200",
    Latitude: "-36.267300"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "WYCHITELLA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.637400",
    Latitude: "-36.197100"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "YEUNGROON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.348800",
    Latitude: "-36.402000"
  },
  {
    Country: "Australia",
    Pcode: "3525",
    Locality: "YEUNGROON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.433800",
    Latitude: "-36.360800"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "BUNGULUKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.369400",
    Latitude: "-36.049900"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "DUMOSA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.188500",
    Latitude: "-35.938800"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "GLENLOTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.334700",
    Latitude: "-36.128000"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "GLENLOTH EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.481100",
    Latitude: "-36.061100"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "JERUK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.444500",
    Latitude: "-36.014000"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "NINYEUNOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.432200",
    Latitude: "-35.964400"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "TEDDYWADDY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.332000",
    Latitude: "-36.194300"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "TEDDYWADDY WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.213600",
    Latitude: "-36.189600"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "THALIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.084100",
    Latitude: "-36.108100"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "TOWANINNY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.365100",
    Latitude: "-35.843500"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "TOWANINNY SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.331900",
    Latitude: "-35.940900"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "WYCHEPROOF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.230700",
    Latitude: "-36.067200"
  },
  {
    Country: "Australia",
    Pcode: "3527",
    Locality: "WYCHEPROOF SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.185500",
    Latitude: "-36.138800"
  },
  {
    Country: "Australia",
    Pcode: "3529",
    Locality: "KALPIENUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.252000",
    Latitude: "-35.846000"
  },
  {
    Country: "Australia",
    Pcode: "3529",
    Locality: "NULLAWIL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.138000",
    Latitude: "-35.859200"
  },
  {
    Country: "Australia",
    Pcode: "3530",
    Locality: "CULGOA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.114600",
    Latitude: "-35.706100"
  },
  {
    Country: "Australia",
    Pcode: "3530",
    Locality: "SUTTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.973500",
    Latitude: "-35.732800"
  },
  {
    Country: "Australia",
    Pcode: "3530",
    Locality: "WANGIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.274500",
    Latitude: "-35.690400"
  },
  {
    Country: "Australia",
    Pcode: "3530",
    Locality: "WARNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.111100",
    Latitude: "-35.787500"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "KERANG EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.012900",
    Latitude: "-35.771700"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "KOROOP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.070800",
    Latitude: "-35.738500"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "LAKE MERAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.790900",
    Latitude: "-35.898300"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "MACORNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.993300",
    Latitude: "-35.916500"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "MEERING WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.713100",
    Latitude: "-35.884100"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "MILNES BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.073700",
    Latitude: "-35.785700"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "MURRABIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.937800",
    Latitude: "-35.537000"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "MURRABIT WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.880800",
    Latitude: "-35.509400"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "MYALL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.008000",
    Latitude: "-35.604500"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "MYSTIC PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.732400",
    Latitude: "-35.578800"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "NORMANVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.728600",
    Latitude: "-35.793700"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "PINE VIEW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.653600",
    Latitude: "-35.807900"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "REEDY LAKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.870100",
    Latitude: "-35.663800"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "SANDHILL LAKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.691900",
    Latitude: "-35.740000"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "TEAL POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.049100",
    Latitude: "-35.682100"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "TRAGOWEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.968500",
    Latitude: "-35.843000"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "WANDELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.827500",
    Latitude: "-35.735700"
  },
  {
    Country: "Australia",
    Pcode: "3579",
    Locality: "WESTBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.969600",
    Latitude: "-35.639300"
  },
  {
    Country: "Australia",
    Pcode: "3580",
    Locality: "KOONDROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.094600",
    Latitude: "-35.643600"
  },
  {
    Country: "Australia",
    Pcode: "3581",
    Locality: "LAKE CHARM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.817000",
    Latitude: "-35.588600"
  },
  {
    Country: "Australia",
    Pcode: "3583",
    Locality: "TRESCO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.687200",
    Latitude: "-35.498100"
  },
  {
    Country: "Australia",
    Pcode: "3584",
    Locality: "LAKE BOGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.638800",
    Latitude: "-35.448300"
  },
  {
    Country: "Australia",
    Pcode: "3584",
    Locality: "TRESCO WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.638400",
    Latitude: "-35.507000"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "CASTLE DONNINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.571700",
    Latitude: "-35.421300"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "CHILLINGOLLAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.044800",
    Latitude: "-35.275900"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "FISH POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.724900",
    Latitude: "-35.443000"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "GOSCHEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.452500",
    Latitude: "-35.496600"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "KUNAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.539300",
    Latitude: "-35.514900"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "MEATIAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.362600",
    Latitude: "-35.569600"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "NOWIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.284700",
    Latitude: "-35.325800"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "NYRRABY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.223600",
    Latitude: "-35.221000"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "PIRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.356100",
    Latitude: "-35.248700"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "POLISBET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.155500",
    Latitude: "-35.271900"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "SPEEWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.516300",
    Latitude: "-35.220300"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "SWAN HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.512700",
    Latitude: "-35.362100"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "SWAN HILL WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.398000",
    Latitude: "-35.369900"
  },
  {
    Country: "Australia",
    Pcode: "3585",
    Locality: "WINLATON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.750400",
    Latitude: "-35.482300"
  },
  {
    Country: "Australia",
    Pcode: "3586",
    Locality: "BULGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.351000",
    Latitude: "-35.304500"
  },
  {
    Country: "Australia",
    Pcode: "3586",
    Locality: "MURRAWEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.514000",
    Latitude: "-35.300600"
  },
  {
    Country: "Australia",
    Pcode: "3586",
    Locality: "MURRAYDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.566300",
    Latitude: "-35.281700"
  },
  {
    Country: "Australia",
    Pcode: "3586",
    Locality: "PENTAL ISLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.685900",
    Latitude: "-35.401300"
  },
  {
    Country: "Australia",
    Pcode: "3586",
    Locality: "TYNTYNDER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.541600",
    Latitude: "-35.245100"
  },
  {
    Country: "Australia",
    Pcode: "3586",
    Locality: "TYNTYNDER SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.505400",
    Latitude: "-35.273600"
  },
  {
    Country: "Australia",
    Pcode: "3588",
    Locality: "WOORINEN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.454900",
    Latitude: "-35.288300"
  },
  {
    Country: "Australia",
    Pcode: "3589",
    Locality: "WOORINEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.471800",
    Latitude: "-35.265700"
  },
  {
    Country: "Australia",
    Pcode: "3589",
    Locality: "WOORINEN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.432300",
    Latitude: "-35.236200"
  },
  {
    Country: "Australia",
    Pcode: "3590",
    Locality: "BEVERFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.479400",
    Latitude: "-35.222000"
  },
  {
    Country: "Australia",
    Pcode: "3591",
    Locality: "VINIFERA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.401500",
    Latitude: "-35.216700"
  },
  {
    Country: "Australia",
    Pcode: "3594",
    Locality: "NYAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.362300",
    Latitude: "-35.147900"
  },
  {
    Country: "Australia",
    Pcode: "3595",
    Locality: "NYAH WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.342400",
    Latitude: "-35.182600"
  },
  {
    Country: "Australia",
    Pcode: "3596",
    Locality: "MIRALIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.327600",
    Latitude: "-35.127900"
  },
  {
    Country: "Australia",
    Pcode: "3596",
    Locality: "TOWAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.223500",
    Latitude: "-35.149400"
  },
  {
    Country: "Australia",
    Pcode: "3596",
    Locality: "WOOD WOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.328200",
    Latitude: "-35.099800"
  },
  {
    Country: "Australia",
    Pcode: "3597",
    Locality: "KENLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.305700",
    Latitude: "-34.835600"
  },
  {
    Country: "Australia",
    Pcode: "3597",
    Locality: "KOOLOONONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.131300",
    Latitude: "-34.858300"
  },
  {
    Country: "Australia",
    Pcode: "3597",
    Locality: "LAKE POWELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.933800",
    Latitude: "-34.714300"
  },
  {
    Country: "Australia",
    Pcode: "3597",
    Locality: "NARRUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.218700",
    Latitude: "-34.777000"
  },
  {
    Country: "Australia",
    Pcode: "3597",
    Locality: "NATYA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.178700",
    Latitude: "-34.942400"
  },
  {
    Country: "Australia",
    Pcode: "3597",
    Locality: "PIANGIL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.217200",
    Latitude: "-35.053000"
  },
  {
    Country: "Australia",
    Pcode: "3599",
    Locality: "BOUNDARY BEND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.098000",
    Latitude: "-34.729700"
  },
  {
    Country: "Australia",
    Pcode: "3607",
    Locality: "TABILK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.178700",
    Latitude: "-36.855200"
  },
  {
    Country: "Australia",
    Pcode: "3608",
    Locality: "BAILIESTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.084300",
    Latitude: "-36.753300"
  },
  {
    Country: "Australia",
    Pcode: "3608",
    Locality: "GOULBURN WEIR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.174200",
    Latitude: "-36.717300"
  },
  {
    Country: "Australia",
    Pcode: "3608",
    Locality: "GRAYTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.944200",
    Latitude: "-36.828100"
  },
  {
    Country: "Australia",
    Pcode: "3608",
    Locality: "KIRWANS BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.156000",
    Latitude: "-36.745300"
  },
  {
    Country: "Australia",
    Pcode: "3608",
    Locality: "MITCHELLSTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.035100",
    Latitude: "-36.842400"
  },
  {
    Country: "Australia",
    Pcode: "3608",
    Locality: "NAGAMBIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.213900",
    Latitude: "-36.791500"
  },
  {
    Country: "Australia",
    Pcode: "3608",
    Locality: "WAHRING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.259000",
    Latitude: "-36.697000"
  },
  {
    Country: "Australia",
    Pcode: "3608",
    Locality: "WIRRATE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.980200",
    Latitude: "-36.767800"
  },
  {
    Country: "Australia",
    Pcode: "3610",
    Locality: "DHURRINGILE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.216300",
    Latitude: "-36.535700"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "OSBORNES FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.901400",
    Latitude: "-36.318100"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "RUNNING CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.056000",
    Latitude: "-36.544900"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "STAGHORN FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.929800",
    Latitude: "-36.240000"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "TALGARNO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.159400",
    Latitude: "-36.064800"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "TANGAMBALANGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.040200",
    Latitude: "-36.241800"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "THOLOGOLONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.423000",
    Latitude: "-35.988900"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "UPPER GUNDOWRING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.110500",
    Latitude: "-36.535000"
  },
  {
    Country: "Australia",
    Pcode: "3691",
    Locality: "WODONGA FORWARD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3694",
    Locality: "BANDIANA MILPO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3695",
    Locality: "CHARLEROI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.153500",
    Latitude: "-36.306200"
  },
  {
    Country: "Australia",
    Pcode: "3695",
    Locality: "HUON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.076200",
    Latitude: "-36.229100"
  },
  {
    Country: "Australia",
    Pcode: "3695",
    Locality: "SANDY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.120300",
    Latitude: "-36.365300"
  },
  {
    Country: "Australia",
    Pcode: "3697",
    Locality: "TAWONGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.171200",
    Latitude: "-36.673800"
  },
  {
    Country: "Australia",
    Pcode: "3698",
    Locality: "TAWONGA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.139700",
    Latitude: "-36.769500"
  },
  {
    Country: "Australia",
    Pcode: "3699",
    Locality: "BOGONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.221500",
    Latitude: "-36.797000"
  },
  {
    Country: "Australia",
    Pcode: "3699",
    Locality: "FALLS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.244100",
    Latitude: "-36.878900"
  },
  {
    Country: "Australia",
    Pcode: "3699",
    Locality: "MOUNT BEAUTY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.228700",
    Latitude: "-36.748600"
  },
  {
    Country: "Australia",
    Pcode: "3699",
    Locality: "NELSE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.365600",
    Latitude: "-36.833900"
  },
  {
    Country: "Australia",
    Pcode: "3700",
    Locality: "BULLIOH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.358400",
    Latitude: "-36.175600"
  },
  {
    Country: "Australia",
    Pcode: "3700",
    Locality: "GEORGES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.265500",
    Latitude: "-36.152900"
  },
  {
    Country: "Australia",
    Pcode: "3700",
    Locality: "JARVIS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.198600",
    Latitude: "-36.176000"
  },
  {
    Country: "Australia",
    Pcode: "3700",
    Locality: "TALLANGATTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.182700",
    Latitude: "-36.235300"
  },
  {
    Country: "Australia",
    Pcode: "3700",
    Locality: "TALLANGATTA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.275800",
    Latitude: "-36.213300"
  },
  {
    Country: "Australia",
    Pcode: "3701",
    Locality: "DARTMOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.622900",
    Latitude: "-36.611700"
  },
  {
    Country: "Australia",
    Pcode: "3701",
    Locality: "ESKDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.231500",
    Latitude: "-36.528800"
  },
  {
    Country: "Australia",
    Pcode: "3701",
    Locality: "GRANYA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.310000",
    Latitude: "-36.094300"
  },
  {
    Country: "Australia",
    Pcode: "3701",
    Locality: "MITTA MITTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.387900",
    Latitude: "-36.580900"
  },
  {
    Country: "Australia",
    Pcode: "3701",
    Locality: "OLD TALLANGATTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.251300",
    Latitude: "-36.201200"
  },
  {
    Country: "Australia",
    Pcode: "3701",
    Locality: "SHELLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.558700",
    Latitude: "-36.212200"
  },
  {
    Country: "Australia",
    Pcode: "3701",
    Locality: "TALLANDOON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.231800",
    Latitude: "-36.411700"
  },
  {
    Country: "Australia",
    Pcode: "3701",
    Locality: "TALLANGATTA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.275900",
    Latitude: "-36.333800"
  },
  {
    Country: "Australia",
    Pcode: "3701",
    Locality: "TALLANGATTA VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.464400",
    Latitude: "-36.337000"
  },
  {
    Country: "Australia",
    Pcode: "3704",
    Locality: "KOETONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.501300",
    Latitude: "-36.121600"
  },
  {
    Country: "Australia",
    Pcode: "3705",
    Locality: "CUDGEWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.808000",
    Latitude: "-36.133400"
  },
  {
    Country: "Australia",
    Pcode: "3707",
    Locality: "BIGGARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.991500",
    Latitude: "-36.339700"
  },
  {
    Country: "Australia",
    Pcode: "3707",
    Locality: "BRINGENBRONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.044800",
    Latitude: "-36.148600"
  },
  {
    Country: "Australia",
    Pcode: "3707",
    Locality: "COLAC COLAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.844000",
    Latitude: "-36.222200"
  },
  {
    Country: "Australia",
    Pcode: "3707",
    Locality: "CORRYONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.896400",
    Latitude: "-36.185500"
  },
  {
    Country: "Australia",
    Pcode: "3707",
    Locality: "NARIEL VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.836000",
    Latitude: "-36.500300"
  },
  {
    Country: "Australia",
    Pcode: "3707",
    Locality: "THOWGLA VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.928600",
    Latitude: "-36.279600"
  },
  {
    Country: "Australia",
    Pcode: "3707",
    Locality: "TOM GROGGIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.091900",
    Latitude: "-36.598400"
  },
  {
    Country: "Australia",
    Pcode: "3707",
    Locality: "TOWONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.974800",
    Latitude: "-36.128800"
  },
  {
    Country: "Australia",
    Pcode: "3707",
    Locality: "TOWONG UPPER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.010800",
    Latitude: "-36.209700"
  },
  {
    Country: "Australia",
    Pcode: "3708",
    Locality: "TINTALDRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.921000",
    Latitude: "-36.054000"
  },
  {
    Country: "Australia",
    Pcode: "3709",
    Locality: "BURROWYE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.540800",
    Latitude: "-36.024300"
  },
  {
    Country: "Australia",
    Pcode: "3709",
    Locality: "GUYS FOREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.673600",
    Latitude: "-36.089000"
  },
  {
    Country: "Australia",
    Pcode: "3709",
    Locality: "MOUNT ALFRED",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.631500",
    Latitude: "-35.997700"
  },
  {
    Country: "Australia",
    Pcode: "3709",
    Locality: "PINE MOUNTAIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.831600",
    Latitude: "-36.012600"
  },
  {
    Country: "Australia",
    Pcode: "3709",
    Locality: "WALWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.727700",
    Latitude: "-35.995200"
  },
  {
    Country: "Australia",
    Pcode: "3711",
    Locality: "BUXTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.713800",
    Latitude: "-37.431600"
  },
  {
    Country: "Australia",
    Pcode: "3712",
    Locality: "RUBICON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.845600",
    Latitude: "-37.384900"
  },
  {
    Country: "Australia",
    Pcode: "3712",
    Locality: "THORNTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.815200",
    Latitude: "-37.272300"
  },
  {
    Country: "Australia",
    Pcode: "3713",
    Locality: "EILDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.018000",
    Latitude: "-37.386800"
  },
  {
    Country: "Australia",
    Pcode: "3713",
    Locality: "LAKE EILDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.962300",
    Latitude: "-37.187800"
  },
  {
    Country: "Australia",
    Pcode: "3713",
    Locality: "TAYLOR BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.888500",
    Latitude: "-37.206300"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "ACHERON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.708200",
    Latitude: "-37.270500"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "ALEXANDRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.735900",
    Latitude: "-37.196500"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "CATHKIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.612500",
    Latitude: "-37.162900"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "CRYSTAL CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "DEVILS RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.834700",
    Latitude: "-37.180600"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "FAWCETT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.718100",
    Latitude: "-37.103200"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "KORIELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.652400",
    Latitude: "-37.157300"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "MAINTONGOON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.790100",
    Latitude: "-37.126300"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "TAGGERTY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.726000",
    Latitude: "-37.349100"
  },
  {
    Country: "Australia",
    Pcode: "3714",
    Locality: "WHANREGARWEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.621200",
    Latitude: "-37.222000"
  },
  {
    Country: "Australia",
    Pcode: "3715",
    Locality: "ANCONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.802900",
    Latitude: "-36.973700"
  },
  {
    Country: "Australia",
    Pcode: "3715",
    Locality: "MERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.708300",
    Latitude: "-36.982200"
  },
  {
    Country: "Australia",
    Pcode: "3715",
    Locality: "WOODFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.782800",
    Latitude: "-37.058200"
  },
  {
    Country: "Australia",
    Pcode: "3717",
    Locality: "FLOWERDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.306000",
    Latitude: "-37.325700"
  },
  {
    Country: "Australia",
    Pcode: "3717",
    Locality: "GHIN GHIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.352200",
    Latitude: "-37.150400"
  },
  {
    Country: "Australia",
    Pcode: "3808",
    Locality: "DEWHURST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.473800",
    Latitude: "-37.976200"
  },
  {
    Country: "Australia",
    Pcode: "3809",
    Locality: "OFFICER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.408700",
    Latitude: "-38.050700"
  },
  {
    Country: "Australia",
    Pcode: "3809",
    Locality: "OFFICER SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.424600",
    Latitude: "-38.103900"
  },
  {
    Country: "Australia",
    Pcode: "3810",
    Locality: "PAKENHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.487700",
    Latitude: "-38.070800"
  },
  {
    Country: "Australia",
    Pcode: "3810",
    Locality: "PAKENHAM SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.503200",
    Latitude: "-38.138900"
  },
  {
    Country: "Australia",
    Pcode: "3810",
    Locality: "PAKENHAM UPPER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.509600",
    Latitude: "-38.009000"
  },
  {
    Country: "Australia",
    Pcode: "3810",
    Locality: "RYTHDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.462400",
    Latitude: "-38.138300"
  },
  {
    Country: "Australia",
    Pcode: "3812",
    Locality: "MARYKNOLL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.604800",
    Latitude: "-38.035500"
  },
  {
    Country: "Australia",
    Pcode: "3812",
    Locality: "NAR NAR GOON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.565400",
    Latitude: "-38.111500"
  },
  {
    Country: "Australia",
    Pcode: "3812",
    Locality: "NAR NAR GOON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.565200",
    Latitude: "-38.032800"
  },
  {
    Country: "Australia",
    Pcode: "3813",
    Locality: "TYNONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.627000",
    Latitude: "-38.097300"
  },
  {
    Country: "Australia",
    Pcode: "3813",
    Locality: "TYNONG NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.625500",
    Latitude: "-38.030200"
  },
  {
    Country: "Australia",
    Pcode: "3814",
    Locality: "CORA LYNN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.620400",
    Latitude: "-38.143100"
  },
  {
    Country: "Australia",
    Pcode: "3814",
    Locality: "GARFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.669600",
    Latitude: "-38.100200"
  },
  {
    Country: "Australia",
    Pcode: "3814",
    Locality: "GARFIELD NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.686100",
    Latitude: "-38.042000"
  },
  {
    Country: "Australia",
    Pcode: "3814",
    Locality: "VERVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.661900",
    Latitude: "-38.139000"
  },
  {
    Country: "Australia",
    Pcode: "3815",
    Locality: "BUNYIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.720600",
    Latitude: "-38.102000"
  },
  {
    Country: "Australia",
    Pcode: "3815",
    Locality: "BUNYIP NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.724200",
    Latitude: "-38.062400"
  },
  {
    Country: "Australia",
    Pcode: "3815",
    Locality: "IONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.683200",
    Latitude: "-38.156300"
  },
  {
    Country: "Australia",
    Pcode: "3815",
    Locality: "TONIMBUK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.722500",
    Latitude: "-38.015000"
  },
  {
    Country: "Australia",
    Pcode: "3816",
    Locality: "LABERTOUCHE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.791800",
    Latitude: "-38.027000"
  },
  {
    Country: "Australia",
    Pcode: "3816",
    Locality: "LONGWARRY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.771700",
    Latitude: "-38.126200"
  },
  {
    Country: "Australia",
    Pcode: "3816",
    Locality: "LONGWARRY NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.773000",
    Latitude: "-38.077900"
  },
  {
    Country: "Australia",
    Pcode: "3816",
    Locality: "MODELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.722900",
    Latitude: "-38.176000"
  },
  {
    Country: "Australia",
    Pcode: "3818",
    Locality: "ATHLONE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.786700",
    Latitude: "-38.243400"
  },
  {
    Country: "Australia",
    Pcode: "3818",
    Locality: "DROUIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.840900",
    Latitude: "-38.143000"
  },
  {
    Country: "Australia",
    Pcode: "3818",
    Locality: "DROUIN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.892300",
    Latitude: "-38.121300"
  },
  {
    Country: "Australia",
    Pcode: "3818",
    Locality: "DROUIN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.823500",
    Latitude: "-38.187000"
  },
  {
    Country: "Australia",
    Pcode: "3818",
    Locality: "DROUIN WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.867000",
    Latitude: "-38.079400"
  },
  {
    Country: "Australia",
    Pcode: "3818",
    Locality: "HALLORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.838500",
    Latitude: "-38.255400"
  },
  {
    Country: "Australia",
    Pcode: "3818",
    Locality: "JINDIVICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.888800",
    Latitude: "-38.025700"
  },
  {
    Country: "Australia",
    Pcode: "3818",
    Locality: "RIPPLEBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.762200",
    Latitude: "-38.193400"
  },
  {
    Country: "Australia",
    Pcode: "3820",
    Locality: "BONA VISTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.969200",
    Latitude: "-38.206700"
  },
  {
    Country: "Australia",
    Pcode: "3820",
    Locality: "LILLICO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.964400",
    Latitude: "-38.123500"
  },
  {
    Country: "Australia",
    Pcode: "3820",
    Locality: "WARRAGUL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.927100",
    Latitude: "-38.157000"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "BRANDY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.910800",
    Latitude: "-38.098600"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "BRAVINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.923000",
    Latitude: "-38.082500"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "BULN BULN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.948800",
    Latitude: "-38.092700"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "BULN BULN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.014200",
    Latitude: "-38.075900"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "CROSSOVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.973600",
    Latitude: "-38.057300"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "ELLINBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.931800",
    Latitude: "-38.252800"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "FERNDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.929800",
    Latitude: "-38.308500"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "LARDNER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.874300",
    Latitude: "-38.219000"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "NILMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.973000",
    Latitude: "-38.169200"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "NILMA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.003500",
    Latitude: "-38.130300"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "ROKEBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.933400",
    Latitude: "-38.069400"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "SEAVIEW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.957400",
    Latitude: "-38.284500"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "SHADY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.071700",
    Latitude: "-38.100800"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "TETOORA ROAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.886400",
    Latitude: "-38.280100"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "TORWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.872300",
    Latitude: "-38.258000"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "WARRAGUL SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.922300",
    Latitude: "-38.213700"
  },
  {
    Country: "Australia",
    Pcode: "3821",
    Locality: "WARRAGUL WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.882800",
    Latitude: "-38.170900"
  },
  {
    Country: "Australia",
    Pcode: "3822",
    Locality: "CLOVERLEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.997500",
    Latitude: "-38.236400"
  },
  {
    Country: "Australia",
    Pcode: "3822",
    Locality: "DARNUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.013900",
    Latitude: "-38.181300"
  },
  {
    Country: "Australia",
    Pcode: "3822",
    Locality: "GAINSBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.975800",
    Latitude: "-38.225800"
  },
  {
    Country: "Australia",
    Pcode: "3823",
    Locality: "ALLAMBEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.024400",
    Latitude: "-38.289600"
  },
  {
    Country: "Australia",
    Pcode: "3823",
    Locality: "YARRAGON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.074800",
    Latitude: "-38.175300"
  },
  {
    Country: "Australia",
    Pcode: "3823",
    Locality: "YARRAGON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.058700",
    Latitude: "-38.256600"
  },
  {
    Country: "Australia",
    Pcode: "3824",
    Locality: "CHILDERS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.096200",
    Latitude: "-38.300100"
  },
  {
    Country: "Australia",
    Pcode: "3824",
    Locality: "NARRACAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.236100",
    Latitude: "-38.262400"
  },
  {
    Country: "Australia",
    Pcode: "3824",
    Locality: "THORPDALE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.142400",
    Latitude: "-38.331800"
  },
  {
    Country: "Australia",
    Pcode: "3824",
    Locality: "TRAFALGAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.147600",
    Latitude: "-38.160200"
  },
  {
    Country: "Australia",
    Pcode: "3824",
    Locality: "TRAFALGAR EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.190500",
    Latitude: "-38.176100"
  },
  {
    Country: "Australia",
    Pcode: "3824",
    Locality: "TRAFALGAR SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.162400",
    Latitude: "-38.244300"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "ABERFELDY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.379000",
    Latitude: "-37.702000"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "AMOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.404400",
    Latitude: "-37.940000"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "BOOLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.465300",
    Latitude: "-38.055500"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "CARINGAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.345200",
    Latitude: "-37.953500"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "COALVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.264100",
    Latitude: "-38.242400"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "COOPERS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.423600",
    Latitude: "-37.987400"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "MELWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.504900",
    Latitude: "-37.744600"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "MERRIJIG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "BAIRNSDALE",
    Category: "Delivery Area",
    Longitude: "147.431500",
    Latitude: "-37.658100"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "MOUNT TAYLOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.583100",
    Latitude: "-37.727000"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "NEWLANDS ARM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.693100",
    Latitude: "-37.927500"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "RYANS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.470200",
    Latitude: "-37.551900"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "SARSFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.743900",
    Latitude: "-37.754500"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "TABBERABBERA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.375500",
    Latitude: "-37.558300"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "WALPA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.385700",
    Latitude: "-37.791500"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "WATERHOLES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.694900",
    Latitude: "-37.646800"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "WENTWORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.485300",
    Latitude: "-37.321400"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "WOODGLEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.385400",
    Latitude: "-37.773000"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "WUK WUK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.418900",
    Latitude: "-37.748400"
  },
  {
    Country: "Australia",
    Pcode: "3875",
    Locality: "WY YUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.613200",
    Latitude: "-37.790100"
  },
  {
    Country: "Australia",
    Pcode: "3878",
    Locality: "EAGLE POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.671500",
    Latitude: "-37.895400"
  },
  {
    Country: "Australia",
    Pcode: "3880",
    Locality: "BOOLE POOLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.833600",
    Latitude: "-37.903800"
  },
  {
    Country: "Australia",
    Pcode: "3880",
    Locality: "OCEAN GRANGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.670300",
    Latitude: "-38.008300"
  },
  {
    Country: "Australia",
    Pcode: "3880",
    Locality: "PAYNESVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.711900",
    Latitude: "-37.908500"
  },
  {
    Country: "Australia",
    Pcode: "3880",
    Locality: "RAYMOND ISLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.745000",
    Latitude: "-37.921400"
  },
  {
    Country: "Australia",
    Pcode: "3882",
    Locality: "NICHOLSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.747400",
    Latitude: "-37.809500"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "BRUMBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.039600",
    Latitude: "-37.019300"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "BRUTHEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.853600",
    Latitude: "-37.707600"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "BUCHAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.103200",
    Latitude: "-37.423500"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "BUCHAN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.078500",
    Latitude: "-37.547000"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "BUTCHERS RIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.260600",
    Latitude: "-37.256100"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "GELANTIPY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.225900",
    Latitude: "-37.192000"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "MOSSIFACE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.815900",
    Latitude: "-37.741600"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "MURRINDAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.209700",
    Latitude: "-37.401900"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "SUGGAN BUGGAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.336800",
    Latitude: "-36.916500"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "TAMBO UPPER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.879100",
    Latitude: "-37.762700"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "TIMBARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.937200",
    Latitude: "-37.488300"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "W TREE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.300100",
    Latitude: "-37.330100"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "WISELEIGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.786600",
    Latitude: "-37.710100"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "WULGULMERANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.302000",
    Latitude: "-37.027800"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "WULGULMERANG EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.308100",
    Latitude: "-37.100300"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "WULGULMERANG WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.073900",
    Latitude: "-37.237100"
  },
  {
    Country: "Australia",
    Pcode: "3885",
    Locality: "YALMY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.393300",
    Latitude: "-37.416100"
  },
  {
    Country: "Australia",
    Pcode: "3886",
    Locality: "NEWMERELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.454400",
    Latitude: "-37.751600"
  },
  {
    Country: "Australia",
    Pcode: "3887",
    Locality: "LAKE TYERS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.126300",
    Latitude: "-37.841100"
  },
  {
    Country: "Australia",
    Pcode: "3887",
    Locality: "NOWA NOWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.008500",
    Latitude: "-37.698900"
  },
  {
    Country: "Australia",
    Pcode: "3887",
    Locality: "WAIREWA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.160500",
    Latitude: "-37.695300"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "BENDOC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.890700",
    Latitude: "-37.188400"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "BETE BOLONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.297000",
    Latitude: "-37.687900"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "BETE BOLONG NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.293600",
    Latitude: "-37.595800"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "BONANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.647800",
    Latitude: "-37.149100"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "BRODRIBB RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.601000",
    Latitude: "-37.664500"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "CAPE CONRAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.717100",
    Latitude: "-37.789500"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "CORRINGLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.443500",
    Latitude: "-37.785000"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "DEDDICK VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.507900",
    Latitude: "-36.994900"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "DELEGATE RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.816200",
    Latitude: "-37.107400"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "GOONGERAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.699800",
    Latitude: "-37.347500"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "HAYDENS BOG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "JARRAHMOND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.410000",
    Latitude: "-37.673200"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "MARLO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.731800",
    Latitude: "-37.763600"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "NURRAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.552900",
    Latitude: "-37.511300"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "ORBOST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.508700",
    Latitude: "-37.644700"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "SIMPSONS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.365400",
    Latitude: "-37.768100"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "TOSTAREE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.189100",
    Latitude: "-37.784800"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "TUBBUT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.590900",
    Latitude: "-37.060600"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "WAYGARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.320200",
    Latitude: "-37.763000"
  },
  {
    Country: "Australia",
    Pcode: "3888",
    Locality: "WOMBAT CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.255800",
    Latitude: "-37.766400"
  },
  {
    Country: "Australia",
    Pcode: "3889",
    Locality: "BELLBIRD CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.815600",
    Latitude: "-37.639700"
  },
  {
    Country: "Australia",
    Pcode: "3889",
    Locality: "BEMM RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.928700",
    Latitude: "-37.716300"
  },
  {
    Country: "Australia",
    Pcode: "3889",
    Locality: "CABBAGE TREE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.713000",
    Latitude: "-37.646300"
  },
  {
    Country: "Australia",
    Pcode: "3889",
    Locality: "CLUB TERRACE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.938800",
    Latitude: "-37.519200"
  },
  {
    Country: "Australia",
    Pcode: "3889",
    Locality: "COMBIENBAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.021000",
    Latitude: "-37.386300"
  },
  {
    Country: "Australia",
    Pcode: "3889",
    Locality: "ERRINUNDRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.760400",
    Latitude: "-37.473700"
  },
  {
    Country: "Australia",
    Pcode: "3889",
    Locality: "MANORINA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.798500",
    Latitude: "-37.694000"
  },
  {
    Country: "Australia",
    Pcode: "3890",
    Locality: "BULDAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.124000",
    Latitude: "-37.238400"
  },
  {
    Country: "Australia",
    Pcode: "3890",
    Locality: "CANN RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.161000",
    Latitude: "-37.590100"
  },
  {
    Country: "Australia",
    Pcode: "3890",
    Locality: "CHANDLERS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.321700",
    Latitude: "-37.305400"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "TRIDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.971600",
    Latitude: "-38.321500"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "WILD DOG VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.939400",
    Latitude: "-38.365500"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "WOOREEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.022000",
    Latitude: "-38.408700"
  },
  {
    Country: "Australia",
    Pcode: "3954",
    Locality: "KOONWARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.953300",
    Latitude: "-38.558300"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "DUMBALK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.119500",
    Latitude: "-38.523200"
  },
  {
    Country: "Australia",
    Pcode: "2808",
    Locality: "WYANGALA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.947900",
    Latitude: "-33.984800"
  },
  {
    Country: "Australia",
    Pcode: "2809",
    Locality: "GREENETHORPE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.395000",
    Latitude: "-34.028500"
  },
  {
    Country: "Australia",
    Pcode: "2810",
    Locality: "BIMBI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.937200",
    Latitude: "-33.972800"
  },
  {
    Country: "Australia",
    Pcode: "2810",
    Locality: "CARAGABAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.691800",
    Latitude: "-33.820700"
  },
  {
    Country: "Australia",
    Pcode: "2810",
    Locality: "GLENELG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.092700",
    Latitude: "-33.728300"
  },
  {
    Country: "Australia",
    Pcode: "2810",
    Locality: "GRENFELL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.178200",
    Latitude: "-33.933400"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "MURRUMBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.249500",
    Latitude: "-32.382700"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "NULLO MOUNTAIN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.231800",
    Latitude: "-32.725700"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "OLINDA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.148700",
    Latitude: "-32.817400"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "PINNACLE SWAMP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.914100",
    Latitude: "-32.774900"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "PYANGLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.937500",
    Latitude: "-32.622200"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "REEDY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "RYLSTONE",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "RYLSTONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.997100",
    Latitude: "-32.760200"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "UPPER BYLONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.173800",
    Latitude: "-32.500000"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "UPPER GROWEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.016000",
    Latitude: "-32.642900"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "UPPER NILE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.252800",
    Latitude: "-32.962000"
  },
  {
    Country: "Australia",
    Pcode: "2849",
    Locality: "WIRRABA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.523800",
    Latitude: "-33.116600"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "AARONS PASS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.800500",
    Latitude: "-32.874500"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "APPLE TREE FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.707700",
    Latitude: "-32.670400"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "AVISFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.479400",
    Latitude: "-32.752100"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "BARA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.854000",
    Latitude: "-32.570800"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "BARIGAN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.979900",
    Latitude: "-32.510300"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "BOCOBLE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.733000",
    Latitude: "-32.803400"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "BOMBIRA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.604700",
    Latitude: "-32.573300"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "BOTOBOLAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.805400",
    Latitude: "-32.522400"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "BUCKAROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.660200",
    Latitude: "-32.564900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "BUDGEE BUDGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.668400",
    Latitude: "-32.517100"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "BURRUNDULLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.632500",
    Latitude: "-32.631600"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "CAERLEON",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.548700",
    Latitude: "-32.578900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "CANADIAN LEAD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.586500",
    Latitude: "-32.404600"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "CARCALGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.698800",
    Latitude: "-32.864900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "COLLINGWOOD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.453600",
    Latitude: "-32.600900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "COOKS GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.717300",
    Latitude: "-32.353900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "COOYAL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.777700",
    Latitude: "-32.442100"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "CROSS ROADS",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.706900",
    Latitude: "-32.504600"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "CUDGEGONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.792900",
    Latitude: "-32.754900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "CULLENBONE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.506500",
    Latitude: "-32.491700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "CUMBO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.890500",
    Latitude: "-32.399100"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "ERUDGERE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.497200",
    Latitude: "-32.574100"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "EURUNDEREE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.610300",
    Latitude: "-32.506100"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "FROG ROCK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.678800",
    Latitude: "-32.469200"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "GALAMBINE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.521600",
    Latitude: "-32.456400"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "GRATTAI",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.487000",
    Latitude: "-32.661600"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "GREEN GULLY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.681000",
    Latitude: "-32.790700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "HARGRAVES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.375500",
    Latitude: "-32.817700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "HAVILAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.782300",
    Latitude: "-32.630500"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "HAYES GAP",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.735600",
    Latitude: "-32.561700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "HILL END",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.453300",
    Latitude: "-33.017600"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "HOME RULE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.627600",
    Latitude: "-32.424400"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "ILFORD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.830300",
    Latitude: "-32.955200"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "KAINS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.854800",
    Latitude: "-32.479200"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "LINBURN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.705000",
    Latitude: "-32.422400"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "LUE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.839900",
    Latitude: "-32.651400"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MAITLAND BAR",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.428500",
    Latitude: "-32.737700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MENAH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.539500",
    Latitude: "-32.533900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MEROO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.624400",
    Latitude: "-32.751700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MILROY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MUDGEE",
    Category: "Delivery Area",
    Longitude: "149.625100",
    Latitude: "-32.594300"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MOGO",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "MUDGEE",
    Category: "Delivery Area",
    Longitude: "150.023300",
    Latitude: "-32.261900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MONIVAE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.905200",
    Latitude: "-32.697700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MOOLARBEN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.777800",
    Latitude: "-32.352100"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MOUNT FROME",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.668300",
    Latitude: "-32.622200"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MOUNT KNOWLES",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.709100",
    Latitude: "-32.614900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MUDGEE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.578100",
    Latitude: "-32.617500"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MULLAMUDDY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.670000",
    Latitude: "-32.694500"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "MUNGHORN",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.836400",
    Latitude: "-32.374500"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "PIAMBONG",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.424100",
    Latitude: "-32.501700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "PUTTA BUCCA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.584200",
    Latitude: "-32.575900"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "PYRAMUL",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.603800",
    Latitude: "-32.921400"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "QUEENS PINCH",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.682000",
    Latitude: "-32.742700"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "RIVERLEA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.735700",
    Latitude: "-32.681500"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "RUNNING STREAM",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.845600",
    Latitude: "-33.067400"
  },
  {
    Country: "Australia",
    Pcode: "2850",
    Locality: "SALLYS FLAT",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.568200",
    Latitude: "-33.031500"
  },
  {
    Country: "Australia",
    Pcode: "3021",
    Locality: "ST ALBANS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.805700",
    Latitude: "-37.747900"
  },
  {
    Country: "Australia",
    Pcode: "3022",
    Locality: "ARDEER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.803300",
    Latitude: "-37.775000"
  },
  {
    Country: "Australia",
    Pcode: "3022",
    Locality: "DEER PARK EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3023",
    Locality: "BURNSIDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.752400",
    Latitude: "-37.751100"
  },
  {
    Country: "Australia",
    Pcode: "3023",
    Locality: "BURNSIDE HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.755900",
    Latitude: "-37.731600"
  },
  {
    Country: "Australia",
    Pcode: "3023",
    Locality: "CAIRNLEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.790500",
    Latitude: "-37.759200"
  },
  {
    Country: "Australia",
    Pcode: "3023",
    Locality: "CAROLINE SPRINGS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.737100",
    Latitude: "-37.729500"
  },
  {
    Country: "Australia",
    Pcode: "3023",
    Locality: "DEER PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.775000",
    Latitude: "-37.766400"
  },
  {
    Country: "Australia",
    Pcode: "3023",
    Locality: "DEER PARK NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3023",
    Locality: "RAVENHALL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.736700",
    Latitude: "-37.774900"
  },
  {
    Country: "Australia",
    Pcode: "3024",
    Locality: "FIELDSTONE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.648300",
    Latitude: "-37.751100"
  },
  {
    Country: "Australia",
    Pcode: "3024",
    Locality: "MAMBOURIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.552000",
    Latitude: "-37.899800"
  },
  {
    Country: "Australia",
    Pcode: "3024",
    Locality: "MANOR LAKES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.578900",
    Latitude: "-37.863800"
  },
  {
    Country: "Australia",
    Pcode: "3024",
    Locality: "MOUNT COTTRELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.622100",
    Latitude: "-37.795000"
  },
  {
    Country: "Australia",
    Pcode: "3024",
    Locality: "WYNDHAM VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.606400",
    Latitude: "-37.850400"
  },
  {
    Country: "Australia",
    Pcode: "3025",
    Locality: "ALTONA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3025",
    Locality: "ALTONA GATE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3025",
    Locality: "ALTONA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.831800",
    Latitude: "-37.838300"
  },
  {
    Country: "Australia",
    Pcode: "3026",
    Locality: "LAVERTON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.787300",
    Latitude: "-37.833600"
  },
  {
    Country: "Australia",
    Pcode: "3027",
    Locality: "WILLIAMS LANDING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.744400",
    Latitude: "-37.863000"
  },
  {
    Country: "Australia",
    Pcode: "3028",
    Locality: "ALTONA MEADOWS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.781000",
    Latitude: "-37.880600"
  },
  {
    Country: "Australia",
    Pcode: "3028",
    Locality: "LAVERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.766400",
    Latitude: "-37.856900"
  },
  {
    Country: "Australia",
    Pcode: "3028",
    Locality: "SEABROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.759000",
    Latitude: "-37.881200"
  },
  {
    Country: "Australia",
    Pcode: "3029",
    Locality: "HOPPERS CROSSING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.700000",
    Latitude: "-37.868900"
  },
  {
    Country: "Australia",
    Pcode: "3029",
    Locality: "TARNEIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.659500",
    Latitude: "-37.836300"
  },
  {
    Country: "Australia",
    Pcode: "3029",
    Locality: "TRUGANINA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.714300",
    Latitude: "-37.802700"
  },
  {
    Country: "Australia",
    Pcode: "3030",
    Locality: "COCOROC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.617500",
    Latitude: "-37.963100"
  },
  {
    Country: "Australia",
    Pcode: "3030",
    Locality: "DERRIMUT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.770900",
    Latitude: "-37.798300"
  },
  {
    Country: "Australia",
    Pcode: "3030",
    Locality: "POINT COOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.756800",
    Latitude: "-37.905400"
  },
  {
    Country: "Australia",
    Pcode: "3030",
    Locality: "QUANDONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.508200",
    Latitude: "-37.835700"
  },
  {
    Country: "Australia",
    Pcode: "3030",
    Locality: "WERRIBEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.641900",
    Latitude: "-37.907800"
  },
  {
    Country: "Australia",
    Pcode: "3030",
    Locality: "WERRIBEE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.702300",
    Latitude: "-37.935500"
  },
  {
    Country: "Australia",
    Pcode: "3031",
    Locality: "FLEMINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.923200",
    Latitude: "-37.787200"
  },
  {
    Country: "Australia",
    Pcode: "3031",
    Locality: "KENSINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.924600",
    Latitude: "-37.794800"
  },
  {
    Country: "Australia",
    Pcode: "3032",
    Locality: "ASCOT VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.922700",
    Latitude: "-37.779800"
  },
  {
    Country: "Australia",
    Pcode: "3032",
    Locality: "HIGHPOINT CITY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3032",
    Locality: "MARIBYRNONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.887300",
    Latitude: "-37.774200"
  },
  {
    Country: "Australia",
    Pcode: "3032",
    Locality: "TRAVANCORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.936100",
    Latitude: "-37.782000"
  },
  {
    Country: "Australia",
    Pcode: "3033",
    Locality: "KEILOR EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.856700",
    Latitude: "-37.736700"
  },
  {
    Country: "Australia",
    Pcode: "3034",
    Locality: "AVONDALE HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.864900",
    Latitude: "-37.761800"
  },
  {
    Country: "Australia",
    Pcode: "3036",
    Locality: "KEILOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.824800",
    Latitude: "-37.705400"
  },
  {
    Country: "Australia",
    Pcode: "3036",
    Locality: "KEILOR NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.792300",
    Latitude: "-37.681100"
  },
  {
    Country: "Australia",
    Pcode: "3037",
    Locality: "CALDER PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.762700",
    Latitude: "-37.676200"
  },
  {
    Country: "Australia",
    Pcode: "3037",
    Locality: "DELAHEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.776900",
    Latitude: "-37.718800"
  },
  {
    Country: "Australia",
    Pcode: "3037",
    Locality: "HILLSIDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "SYDENHAM",
    Category: "Delivery Area",
    Longitude: "144.741100",
    Latitude: "-37.691000"
  },
  {
    Country: "Australia",
    Pcode: "3037",
    Locality: "SYDENHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.768700",
    Latitude: "-37.702000"
  },
  {
    Country: "Australia",
    Pcode: "3037",
    Locality: "TAYLORS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.753300",
    Latitude: "-37.714800"
  },
  {
    Country: "Australia",
    Pcode: "3038",
    Locality: "KEILOR DOWNS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.804200",
    Latitude: "-37.720400"
  },
  {
    Country: "Australia",
    Pcode: "3038",
    Locality: "KEILOR LODGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.799000",
    Latitude: "-37.697500"
  },
  {
    Country: "Australia",
    Pcode: "3038",
    Locality: "TAYLORS LAKES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.788200",
    Latitude: "-37.699500"
  },
  {
    Country: "Australia",
    Pcode: "3038",
    Locality: "WATERGARDENS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3039",
    Locality: "MOONEE PONDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.919800",
    Latitude: "-37.765300"
  },
  {
    Country: "Australia",
    Pcode: "3040",
    Locality: "ABERFELDIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.896900",
    Latitude: "-37.759800"
  },
  {
    Country: "Australia",
    Pcode: "3040",
    Locality: "ESSENDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.908900",
    Latitude: "-37.749200"
  },
  {
    Country: "Australia",
    Pcode: "3040",
    Locality: "ESSENDON WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.883000",
    Latitude: "-37.754900"
  },
  {
    Country: "Australia",
    Pcode: "3041",
    Locality: "CROSS KEYS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "CARE PO ONLY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3041",
    Locality: "ESSENDON FIELDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.900300",
    Latitude: "-37.724200"
  },
  {
    Country: "Australia",
    Pcode: "3041",
    Locality: "ESSENDON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.901700",
    Latitude: "-37.739000"
  },
  {
    Country: "Australia",
    Pcode: "3041",
    Locality: "STRATHMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.918100",
    Latitude: "-37.733200"
  },
  {
    Country: "Australia",
    Pcode: "3041",
    Locality: "STRATHMORE HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.899500",
    Latitude: "-37.714200"
  },
  {
    Country: "Australia",
    Pcode: "3042",
    Locality: "AIRPORT WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.880400",
    Latitude: "-37.722700"
  },
  {
    Country: "Australia",
    Pcode: "3042",
    Locality: "KEILOR PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.851100",
    Latitude: "-37.718800"
  },
  {
    Country: "Australia",
    Pcode: "3042",
    Locality: "NIDDRIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.882600",
    Latitude: "-37.740600"
  },
  {
    Country: "Australia",
    Pcode: "3042",
    Locality: "NIDDRIE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "CARE PO ONLY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3043",
    Locality: "GLADSTONE PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.895600",
    Latitude: "-37.692500"
  },
  {
    Country: "Australia",
    Pcode: "3043",
    Locality: "GOWANBRAE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.898200",
    Latitude: "-37.701900"
  },
  {
    Country: "Australia",
    Pcode: "3043",
    Locality: "TULLAMARINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.879600",
    Latitude: "-37.702800"
  },
  {
    Country: "Australia",
    Pcode: "3044",
    Locality: "PASCOE VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.936100",
    Latitude: "-37.724600"
  },
  {
    Country: "Australia",
    Pcode: "3044",
    Locality: "PASCOE VALE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.937600",
    Latitude: "-37.743500"
  },
  {
    Country: "Australia",
    Pcode: "3143",
    Locality: "ARMADALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.019400",
    Latitude: "-37.857600"
  },
  {
    Country: "Australia",
    Pcode: "3143",
    Locality: "ARMADALE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3144",
    Locality: "KOOYONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.035800",
    Latitude: "-37.841000"
  },
  {
    Country: "Australia",
    Pcode: "3144",
    Locality: "MALVERN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.035600",
    Latitude: "-37.857800"
  },
  {
    Country: "Australia",
    Pcode: "3144",
    Locality: "MALVERN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3145",
    Locality: "CAULFIELD EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.042000",
    Latitude: "-37.881100"
  },
  {
    Country: "Australia",
    Pcode: "3145",
    Locality: "CENTRAL PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3145",
    Locality: "DARLING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3145",
    Locality: "DARLING SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3145",
    Locality: "MALVERN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.069300",
    Latitude: "-37.878200"
  },
  {
    Country: "Australia",
    Pcode: "3145",
    Locality: "WATTLETREE ROAD PO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3146",
    Locality: "GLEN IRIS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.067700",
    Latitude: "-37.856400"
  },
  {
    Country: "Australia",
    Pcode: "3146",
    Locality: "TOORONGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "CARE PO ONLY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3147",
    Locality: "ASHBURTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.078100",
    Latitude: "-37.867000"
  },
  {
    Country: "Australia",
    Pcode: "3147",
    Locality: "ASHWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.102500",
    Latitude: "-37.865400"
  },
  {
    Country: "Australia",
    Pcode: "3148",
    Locality: "CHADSTONE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.099500",
    Latitude: "-37.882800"
  },
  {
    Country: "Australia",
    Pcode: "3148",
    Locality: "CHADSTONE CENTRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3148",
    Locality: "HOLMESGLEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3148",
    Locality: "JORDANVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3149",
    Locality: "MOUNT WAVERLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.128400",
    Latitude: "-37.878400"
  },
  {
    Country: "Australia",
    Pcode: "3149",
    Locality: "PINEWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3149",
    Locality: "SYNDAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3150",
    Locality: "BRANDON PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3150",
    Locality: "GLEN WAVERLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.169700",
    Latitude: "-37.882300"
  },
  {
    Country: "Australia",
    Pcode: "3150",
    Locality: "WHEELERS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.182800",
    Latitude: "-37.907300"
  },
  {
    Country: "Australia",
    Pcode: "3151",
    Locality: "BURWOOD EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.147500",
    Latitude: "-37.853700"
  },
  {
    Country: "Australia",
    Pcode: "3151",
    Locality: "BURWOOD HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3152",
    Locality: "KNOX CITY CENTRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3152",
    Locality: "STUDFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3152",
    Locality: "WANTIRNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.225000",
    Latitude: "-37.853400"
  },
  {
    Country: "Australia",
    Pcode: "3152",
    Locality: "WANTIRNA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.221000",
    Latitude: "-37.870600"
  },
  {
    Country: "Australia",
    Pcode: "3153",
    Locality: "BAYSWATER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.266100",
    Latitude: "-37.846300"
  },
  {
    Country: "Australia",
    Pcode: "3153",
    Locality: "BAYSWATER NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.284000",
    Latitude: "-37.825500"
  },
  {
    Country: "Australia",
    Pcode: "3154",
    Locality: "THE BASIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.318600",
    Latitude: "-37.855900"
  },
  {
    Country: "Australia",
    Pcode: "3155",
    Locality: "BORONIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.284100",
    Latitude: "-37.862400"
  },
  {
    Country: "Australia",
    Pcode: "3156",
    Locality: "FERNTREE GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.280300",
    Latitude: "-37.886100"
  },
  {
    Country: "Australia",
    Pcode: "3156",
    Locality: "LYSTERFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.298900",
    Latitude: "-37.934000"
  },
  {
    Country: "Australia",
    Pcode: "3156",
    Locality: "LYSTERFIELD SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.266700",
    Latitude: "-37.953000"
  },
  {
    Country: "Australia",
    Pcode: "3156",
    Locality: "MOUNTAIN GATE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3156",
    Locality: "UPPER FERNTREE GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.310000",
    Latitude: "-37.898600"
  },
  {
    Country: "Australia",
    Pcode: "3158",
    Locality: "UPWEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.325300",
    Latitude: "-37.905100"
  },
  {
    Country: "Australia",
    Pcode: "3159",
    Locality: "MENZIES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.406000",
    Latitude: "-37.920700"
  },
  {
    Country: "Australia",
    Pcode: "3159",
    Locality: "SELBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.378200",
    Latitude: "-37.920400"
  },
  {
    Country: "Australia",
    Pcode: "3160",
    Locality: "BELGRAVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.355700",
    Latitude: "-37.910900"
  },
  {
    Country: "Australia",
    Pcode: "3160",
    Locality: "BELGRAVE HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.343400",
    Latitude: "-37.926000"
  },
  {
    Country: "Australia",
    Pcode: "3160",
    Locality: "BELGRAVE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.355600",
    Latitude: "-37.941700"
  },
  {
    Country: "Australia",
    Pcode: "3160",
    Locality: "TECOMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.341800",
    Latitude: "-37.908400"
  },
  {
    Country: "Australia",
    Pcode: "3161",
    Locality: "CAULFIELD JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3161",
    Locality: "CAULFIELD NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.021800",
    Latitude: "-37.870800"
  },
  {
    Country: "Australia",
    Pcode: "3162",
    Locality: "CAULFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.025900",
    Latitude: "-37.884000"
  },
  {
    Country: "Australia",
    Pcode: "3162",
    Locality: "CAULFIELD SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.024900",
    Latitude: "-37.894600"
  },
  {
    Country: "Australia",
    Pcode: "3162",
    Locality: "HOPETOUN GARDENS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3163",
    Locality: "BOORAN ROAD PO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3163",
    Locality: "CARNEGIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.056000",
    Latitude: "-37.894800"
  },
  {
    Country: "Australia",
    Pcode: "3163",
    Locality: "GLEN HUNTLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.041100",
    Latitude: "-37.892100"
  },
  {
    Country: "Australia",
    Pcode: "3163",
    Locality: "MURRUMBEENA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.069200",
    Latitude: "-37.896000"
  },
  {
    Country: "Australia",
    Pcode: "3164",
    Locality: "DANDENONG SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3165",
    Locality: "BENTLEIGH EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.066900",
    Latitude: "-37.922200"
  },
  {
    Country: "Australia",
    Pcode: "3166",
    Locality: "HUGHESDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.081200",
    Latitude: "-37.898700"
  },
  {
    Country: "Australia",
    Pcode: "3166",
    Locality: "HUNTINGDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.108600",
    Latitude: "-37.907400"
  },
  {
    Country: "Australia",
    Pcode: "3166",
    Locality: "OAKLEIGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.096000",
    Latitude: "-37.901100"
  },
  {
    Country: "Australia",
    Pcode: "3166",
    Locality: "OAKLEIGH EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.117800",
    Latitude: "-37.904900"
  },
  {
    Country: "Australia",
    Pcode: "3167",
    Locality: "OAKLEIGH SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.096300",
    Latitude: "-37.927200"
  },
  {
    Country: "Australia",
    Pcode: "3168",
    Locality: "CLAYTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.131700",
    Latitude: "-37.915200"
  },
  {
    Country: "Australia",
    Pcode: "3168",
    Locality: "NOTTING HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.141400",
    Latitude: "-37.905300"
  },
  {
    Country: "Australia",
    Pcode: "3169",
    Locality: "CLARINDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.106700",
    Latitude: "-37.942800"
  },
  {
    Country: "Australia",
    Pcode: "3169",
    Locality: "CLAYTON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.124500",
    Latitude: "-37.945000"
  },
  {
    Country: "Australia",
    Pcode: "3170",
    Locality: "MULGRAVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.165000",
    Latitude: "-37.921300"
  },
  {
    Country: "Australia",
    Pcode: "3170",
    Locality: "WAVERLEY GARDENS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3241",
    Locality: "BAMBRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.949200",
    Latitude: "-38.361500"
  },
  {
    Country: "Australia",
    Pcode: "3241",
    Locality: "OMBERSLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.815000",
    Latitude: "-38.200600"
  },
  {
    Country: "Australia",
    Pcode: "3241",
    Locality: "WENSLEYDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.051500",
    Latitude: "-38.371000"
  },
  {
    Country: "Australia",
    Pcode: "3241",
    Locality: "WINCHELSEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.966800",
    Latitude: "-38.234000"
  },
  {
    Country: "Australia",
    Pcode: "3241",
    Locality: "WINCHELSEA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.982700",
    Latitude: "-38.322400"
  },
  {
    Country: "Australia",
    Pcode: "3241",
    Locality: "WURDIBOLUC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.051300",
    Latitude: "-38.288800"
  },
  {
    Country: "Australia",
    Pcode: "3242",
    Locality: "BIRREGURRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.823000",
    Latitude: "-38.343500"
  },
  {
    Country: "Australia",
    Pcode: "3243",
    Locality: "BARWON DOWNS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.792800",
    Latitude: "-38.509300"
  },
  {
    Country: "Australia",
    Pcode: "3243",
    Locality: "MURROON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.796900",
    Latitude: "-38.427000"
  },
  {
    Country: "Australia",
    Pcode: "3243",
    Locality: "WARNCOORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.722000",
    Latitude: "-38.314500"
  },
  {
    Country: "Australia",
    Pcode: "3243",
    Locality: "WHOOREL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.833300",
    Latitude: "-38.371300"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "ALVIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.494200",
    Latitude: "-38.236300"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "BALINTORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.569400",
    Latitude: "-38.281900"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "BARONGAROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.617800",
    Latitude: "-38.427600"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "BARONGAROOK WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.545400",
    Latitude: "-38.434400"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "BARRAMUNGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.716200",
    Latitude: "-38.582100"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "CORAGULAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.540000",
    Latitude: "-38.263800"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "CORUNNUN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.484700",
    Latitude: "-38.281100"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "DREEITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.509400",
    Latitude: "-38.147500"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "DREEITE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.480000",
    Latitude: "-38.197300"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "GERANGAMETE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.691700",
    Latitude: "-38.453800"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "IRREWARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.683300",
    Latitude: "-38.239200"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "IRREWILLIPE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.414600",
    Latitude: "-38.443700"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "IRREWILLIPE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.486300",
    Latitude: "-38.427600"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "KAWARREN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.600400",
    Latitude: "-38.501000"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "LARPENT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.508400",
    Latitude: "-38.356900"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "NALANGIL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.455800",
    Latitude: "-38.323600"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "ONDIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.620900",
    Latitude: "-38.239300"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "PIRRON YALLOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.425300",
    Latitude: "-38.357100"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "POMBORNEIT EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.392900",
    Latitude: "-38.297200"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "SWAN MARSH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.354200",
    Latitude: "-38.373000"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "TANYBRYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.718100",
    Latitude: "-38.656700"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "WARRION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.555900",
    Latitude: "-38.229500"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "WOOL WOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.413000",
    Latitude: "-38.217300"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "YEO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.646400",
    Latitude: "-38.370600"
  },
  {
    Country: "Australia",
    Pcode: "3249",
    Locality: "YEODENE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.702700",
    Latitude: "-38.397800"
  },
  {
    Country: "Australia",
    Pcode: "3250",
    Locality: "COLAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.587400",
    Latitude: "-38.309600"
  },
  {
    Country: "Australia",
    Pcode: "3250",
    Locality: "COLAC EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.617000",
    Latitude: "-38.330800"
  },
  {
    Country: "Australia",
    Pcode: "3250",
    Locality: "COLAC WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.544900",
    Latitude: "-38.332500"
  },
  {
    Country: "Australia",
    Pcode: "3250",
    Locality: "ELLIMINYT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.577300",
    Latitude: "-38.370800"
  },
  {
    Country: "Australia",
    Pcode: "3251",
    Locality: "BEEAC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.633000",
    Latitude: "-38.178400"
  },
  {
    Country: "Australia",
    Pcode: "3251",
    Locality: "CUNDARE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.593300",
    Latitude: "-38.148500"
  },
  {
    Country: "Australia",
    Pcode: "3251",
    Locality: "CUNDARE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.560600",
    Latitude: "-38.107300"
  },
  {
    Country: "Australia",
    Pcode: "3251",
    Locality: "EURACK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.730100",
    Latitude: "-38.150800"
  },
  {
    Country: "Australia",
    Pcode: "3251",
    Locality: "WEERING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.702200",
    Latitude: "-38.093900"
  },
  {
    Country: "Australia",
    Pcode: "3254",
    Locality: "COROROOKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.528700",
    Latitude: "-38.296400"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "BOOKAAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.080900",
    Latitude: "-38.110600"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "BOSTOCKS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.117400",
    Latitude: "-38.301700"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "BUNGADOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.321800",
    Latitude: "-38.427900"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "CAMPERDOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.137200",
    Latitude: "-38.229000"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "CARPENDEIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.241300",
    Latitude: "-38.391800"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "CHOCOLYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.202600",
    Latitude: "-38.204400"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "GNOTUK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.075300",
    Latitude: "-38.202800"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "KARIAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.223000",
    Latitude: "-38.140200"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "KOALLAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.233400",
    Latitude: "-38.294300"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "LESLIE MANOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.381100",
    Latitude: "-38.104000"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "POMBORNEIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.292400",
    Latitude: "-38.296000"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "POMBORNEIT NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.328100",
    Latitude: "-38.220600"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "SKIBO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.127800",
    Latitude: "-38.205800"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "SOUTH PURRUMBETE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.205400",
    Latitude: "-38.348700"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "STONYFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.308300",
    Latitude: "-38.331500"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "TANDAROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.184800",
    Latitude: "-38.317000"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "TESBURY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.157300",
    Latitude: "-38.300300"
  },
  {
    Country: "Australia",
    Pcode: "3260",
    Locality: "WEERITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.261800",
    Latitude: "-38.222500"
  },
  {
    Country: "Australia",
    Pcode: "3264",
    Locality: "TERANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.876400",
    Latitude: "-38.246100"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "BOORCAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.028600",
    Latitude: "-38.222100"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "CUDGEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.646900",
    Latitude: "-38.341000"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "DIXIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.927000",
    Latitude: "-38.305900"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "ECKLIN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.902200",
    Latitude: "-38.385900"
  },
  {
    Country: "Australia",
    Pcode: "3265",
    Locality: "ELLERSLIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.655300",
    Latitude: "-38.125000"
  },
  {
    Country: "Australia",
    Pcode: "3318",
    Locality: "CONNEWIRRICOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.473900",
    Latitude: "-37.178200"
  },
  {
    Country: "Australia",
    Pcode: "3318",
    Locality: "EDENHOPE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.322700",
    Latitude: "-37.065100"
  },
  {
    Country: "Australia",
    Pcode: "3318",
    Locality: "KADNOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.403200",
    Latitude: "-37.172200"
  },
  {
    Country: "Australia",
    Pcode: "3318",
    Locality: "LANGKOOP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.079100",
    Latitude: "-37.100500"
  },
  {
    Country: "Australia",
    Pcode: "3318",
    Locality: "PATYAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.273200",
    Latitude: "-36.894700"
  },
  {
    Country: "Australia",
    Pcode: "3318",
    Locality: "ULLSWATER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.413800",
    Latitude: "-36.916900"
  },
  {
    Country: "Australia",
    Pcode: "3319",
    Locality: "APSLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.088100",
    Latitude: "-36.978900"
  },
  {
    Country: "Australia",
    Pcode: "3319",
    Locality: "BENAYEO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.042700",
    Latitude: "-36.843100"
  },
  {
    Country: "Australia",
    Pcode: "3319",
    Locality: "BRINGALBERT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.182300",
    Latitude: "-36.827300"
  },
  {
    Country: "Australia",
    Pcode: "3321",
    Locality: "HESSE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.886600",
    Latitude: "-38.085300"
  },
  {
    Country: "Australia",
    Pcode: "3321",
    Locality: "INVERLEIGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.004000",
    Latitude: "-38.108400"
  },
  {
    Country: "Australia",
    Pcode: "3321",
    Locality: "WINGEEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.805500",
    Latitude: "-38.077400"
  },
  {
    Country: "Australia",
    Pcode: "3322",
    Locality: "CRESSY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.658700",
    Latitude: "-38.039300"
  },
  {
    Country: "Australia",
    Pcode: "3323",
    Locality: "BERRYBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.502000",
    Latitude: "-37.967600"
  },
  {
    Country: "Australia",
    Pcode: "3323",
    Locality: "DUVERNEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.559900",
    Latitude: "-38.026300"
  },
  {
    Country: "Australia",
    Pcode: "3323",
    Locality: "FOXHOW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.479000",
    Latitude: "-38.054400"
  },
  {
    Country: "Australia",
    Pcode: "3324",
    Locality: "LISMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.361500",
    Latitude: "-37.958300"
  },
  {
    Country: "Australia",
    Pcode: "3324",
    Locality: "MINGAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.327500",
    Latitude: "-37.852300"
  },
  {
    Country: "Australia",
    Pcode: "3324",
    Locality: "MOUNT BUTE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.412200",
    Latitude: "-37.871600"
  },
  {
    Country: "Australia",
    Pcode: "3325",
    Locality: "DERRINALLUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.175300",
    Latitude: "-37.959300"
  },
  {
    Country: "Australia",
    Pcode: "3325",
    Locality: "LARRALEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.240900",
    Latitude: "-38.051200"
  },
  {
    Country: "Australia",
    Pcode: "3325",
    Locality: "VITE VITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.155500",
    Latitude: "-37.854800"
  },
  {
    Country: "Australia",
    Pcode: "3325",
    Locality: "VITE VITE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.186300",
    Latitude: "-37.792100"
  },
  {
    Country: "Australia",
    Pcode: "3328",
    Locality: "TEESDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.047900",
    Latitude: "-38.022300"
  },
  {
    Country: "Australia",
    Pcode: "3329",
    Locality: "BARUNAH PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.822800",
    Latitude: "-38.013100"
  },
  {
    Country: "Australia",
    Pcode: "3329",
    Locality: "BARUNAH PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.769000",
    Latitude: "-38.047600"
  },
  {
    Country: "Australia",
    Pcode: "3329",
    Locality: "SHELFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.916300",
    Latitude: "-37.966200"
  },
  {
    Country: "Australia",
    Pcode: "3330",
    Locality: "ROKEWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.742600",
    Latitude: "-37.926300"
  },
  {
    Country: "Australia",
    Pcode: "3331",
    Locality: "BANNOCKBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.159500",
    Latitude: "-38.049000"
  },
  {
    Country: "Australia",
    Pcode: "3331",
    Locality: "GHERINGHAP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.227000",
    Latitude: "-38.070900"
  },
  {
    Country: "Australia",
    Pcode: "3331",
    Locality: "MAUDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.197000",
    Latitude: "-37.944900"
  },
  {
    Country: "Australia",
    Pcode: "3331",
    Locality: "RUSSELLS BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.182200",
    Latitude: "-38.006600"
  },
  {
    Country: "Australia",
    Pcode: "3331",
    Locality: "SHE OAKS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.146500",
    Latitude: "-37.896200"
  },
  {
    Country: "Australia",
    Pcode: "3331",
    Locality: "STEIGLITZ",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.191300",
    Latitude: "-37.866600"
  },
  {
    Country: "Australia",
    Pcode: "3331",
    Locality: "SUTHERLANDS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.220000",
    Latitude: "-38.005500"
  },
  {
    Country: "Australia",
    Pcode: "3332",
    Locality: "LETHBRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.089000",
    Latitude: "-37.950300"
  },
  {
    Country: "Australia",
    Pcode: "3333",
    Locality: "BAMGANIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.009300",
    Latitude: "-37.939500"
  },
  {
    Country: "Australia",
    Pcode: "3333",
    Locality: "MEREDITH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.033400",
    Latitude: "-37.867100"
  },
  {
    Country: "Australia",
    Pcode: "3334",
    Locality: "BUNGAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.126700",
    Latitude: "-37.725900"
  },
  {
    Country: "Australia",
    Pcode: "3334",
    Locality: "CARGERIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.949700",
    Latitude: "-37.803800"
  },
  {
    Country: "Australia",
    Pcode: "3334",
    Locality: "ELAINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.006200",
    Latitude: "-37.761600"
  },
  {
    Country: "Australia",
    Pcode: "3334",
    Locality: "MORRISONS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.118300",
    Latitude: "-37.761600"
  },
  {
    Country: "Australia",
    Pcode: "3334",
    Locality: "MOUNT DORAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.042600",
    Latitude: "-37.722400"
  },
  {
    Country: "Australia",
    Pcode: "3335",
    Locality: "BONNIE BROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.677000",
    Latitude: "-37.700200"
  },
  {
    Country: "Australia",
    Pcode: "3335",
    Locality: "GRANGEFIELDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.639100",
    Latitude: "-37.702300"
  },
  {
    Country: "Australia",
    Pcode: "3335",
    Locality: "PLUMPTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "MELTON",
    Category: "Delivery Area",
    Longitude: "144.695300",
    Latitude: "-37.664700"
  },
  {
    Country: "Australia",
    Pcode: "3335",
    Locality: "ROCKBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.648200",
    Latitude: "-37.729600"
  },
  {
    Country: "Australia",
    Pcode: "3335",
    Locality: "THORNHILL PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.624700",
    Latitude: "-37.729100"
  },
  {
    Country: "Australia",
    Pcode: "3336",
    Locality: "AINTREE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.668400",
    Latitude: "-37.719000"
  },
  {
    Country: "Australia",
    Pcode: "3336",
    Locality: "DEANSIDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.704100",
    Latitude: "-37.733200"
  },
  {
    Country: "Australia",
    Pcode: "3336",
    Locality: "FRASER RISE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.711800",
    Latitude: "-37.704300"
  },
  {
    Country: "Australia",
    Pcode: "3337",
    Locality: "HARKNESS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.546300",
    Latitude: "-37.654400"
  },
  {
    Country: "Australia",
    Pcode: "3337",
    Locality: "KURUNJANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.592600",
    Latitude: "-37.662000"
  },
  {
    Country: "Australia",
    Pcode: "3337",
    Locality: "MELTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.623400",
    Latitude: "-37.675400"
  },
  {
    Country: "Australia",
    Pcode: "3337",
    Locality: "MELTON WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.540900",
    Latitude: "-37.678500"
  },
  {
    Country: "Australia",
    Pcode: "3337",
    Locality: "TOOLERN VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.584000",
    Latitude: "-37.598400"
  },
  {
    Country: "Australia",
    Pcode: "3338",
    Locality: "BROOKFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.541400",
    Latitude: "-37.701100"
  },
  {
    Country: "Australia",
    Pcode: "3338",
    Locality: "COBBLEBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.600300",
    Latitude: "-37.708300"
  },
  {
    Country: "Australia",
    Pcode: "3338",
    Locality: "EXFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.542800",
    Latitude: "-37.727700"
  },
  {
    Country: "Australia",
    Pcode: "3338",
    Locality: "EYNESBURY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.533100",
    Latitude: "-37.791100"
  },
  {
    Country: "Australia",
    Pcode: "3338",
    Locality: "MELTON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.567200",
    Latitude: "-37.704600"
  },
  {
    Country: "Australia",
    Pcode: "3338",
    Locality: "STRATHTULLOH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.596800",
    Latitude: "-37.732500"
  },
  {
    Country: "Australia",
    Pcode: "3338",
    Locality: "WEIR VIEWS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.571500",
    Latitude: "-37.731700"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "BACCHUS MARSH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.440900",
    Latitude: "-37.678300"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "BALLIANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.349800",
    Latitude: "-37.820600"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "BALLIANG EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.431400",
    Latitude: "-37.816800"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "COIMADAI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.491000",
    Latitude: "-37.590300"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "DARLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.432100",
    Latitude: "-37.636400"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "GLENMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.303800",
    Latitude: "-37.715400"
  },
  {
    Country: "Australia",
    Pcode: "3340",
    Locality: "HOPETOUN PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.501100",
    Latitude: "-37.694100"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "BULGANA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.995300",
    Latitude: "-37.136200"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "CATHCART",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.882100",
    Latitude: "-37.312500"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "CROWLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.109400",
    Latitude: "-37.131200"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "DENICULL CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.929000",
    Latitude: "-37.339100"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "DOBIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.033100",
    Latitude: "-37.332100"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "DUNNEWORTHY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.041100",
    Latitude: "-37.202200"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "EVERSLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.176500",
    Latitude: "-37.193500"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "LANGI LOGAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.924400",
    Latitude: "-37.393300"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "MAROONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.864100",
    Latitude: "-37.412900"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "MOUNT COLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.210600",
    Latitude: "-37.249300"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "MOUNT COLE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.122400",
    Latitude: "-37.218700"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "MOYSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.748800",
    Latitude: "-37.276900"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "NORVAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.861600",
    Latitude: "-37.257200"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "RHYMNEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.779100",
    Latitude: "-37.217800"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "ROCKY POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.854300",
    Latitude: "-37.335700"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "ROSSBRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.862800",
    Latitude: "-37.526800"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "SHAYS FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.079700",
    Latitude: "-37.065100"
  },
  {
    Country: "Australia",
    Pcode: "3377",
    Locality: "WARRAK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.122600",
    Latitude: "-37.285600"
  },
  {
    Country: "Australia",
    Pcode: "3378",
    Locality: "TATYOON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.992300",
    Latitude: "-37.500300"
  },
  {
    Country: "Australia",
    Pcode: "3378",
    Locality: "YALLA-Y-POORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.126800",
    Latitude: "-37.527400"
  },
  {
    Country: "Australia",
    Pcode: "3379",
    Locality: "BORNES HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.489800",
    Latitude: "-37.529400"
  },
  {
    Country: "Australia",
    Pcode: "3379",
    Locality: "CHATSWORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.659500",
    Latitude: "-37.840200"
  },
  {
    Country: "Australia",
    Pcode: "3379",
    Locality: "MAFEKING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.575800",
    Latitude: "-37.382800"
  },
  {
    Country: "Australia",
    Pcode: "3379",
    Locality: "STAVELY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.623900",
    Latitude: "-37.608800"
  },
  {
    Country: "Australia",
    Pcode: "3379",
    Locality: "WICKLIFFE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.718400",
    Latitude: "-37.707900"
  },
  {
    Country: "Australia",
    Pcode: "3379",
    Locality: "WILLAURA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.720700",
    Latitude: "-37.524900"
  },
  {
    Country: "Australia",
    Pcode: "3379",
    Locality: "WILLAURA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.747800",
    Latitude: "-37.388200"
  },
  {
    Country: "Australia",
    Pcode: "3380",
    Locality: "STAWELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.769600",
    Latitude: "-37.044500"
  },
  {
    Country: "Australia",
    Pcode: "3380",
    Locality: "STAWELL WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "BELLELLEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.694000",
    Latitude: "-37.133900"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "BELLFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "GRAMPIANS",
    Category: "Delivery Area",
    Longitude: "142.503400",
    Latitude: "-37.224400"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "BLACK RANGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.765500",
    Latitude: "-37.133600"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "FYANS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.573600",
    Latitude: "-37.092700"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "HALLS GAP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.532700",
    Latitude: "-37.121300"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "ILLAWARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.689000",
    Latitude: "-37.067200"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "LAKE FYANS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.626100",
    Latitude: "-37.135300"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "LAKE LONSDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.626600",
    Latitude: "-37.041300"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "MOKEPILLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.642300",
    Latitude: "-37.088600"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "MOUNT DRYDEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.532200",
    Latitude: "-37.046000"
  },
  {
    Country: "Australia",
    Pcode: "3381",
    Locality: "POMONAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.635200",
    Latitude: "-37.249500"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "BARKLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.219300",
    Latitude: "-36.941100"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "CONCONGELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.863500",
    Latitude: "-37.043300"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "FRENCHMANS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.198000",
    Latitude: "-36.997200"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "JOEL JOEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.986800",
    Latitude: "-37.005800"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "JOEL SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.992000",
    Latitude: "-37.064600"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "LANDSBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.142700",
    Latitude: "-37.005600"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "LANDSBOROUGH WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.072300",
    Latitude: "-37.006900"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "NAVARRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.106700",
    Latitude: "-36.897500"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "TULKARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.052000",
    Latitude: "-36.944600"
  },
  {
    Country: "Australia",
    Pcode: "3384",
    Locality: "WATTLE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.091300",
    Latitude: "-36.962800"
  },
  {
    Country: "Australia",
    Pcode: "3385",
    Locality: "DADSWELLS BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.495400",
    Latitude: "-36.898400"
  },
  {
    Country: "Australia",
    Pcode: "3385",
    Locality: "DEEP LEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.726700",
    Latitude: "-36.998500"
  },
  {
    Country: "Australia",
    Pcode: "3385",
    Locality: "GLENORCHY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.645400",
    Latitude: "-36.920700"
  },
  {
    Country: "Australia",
    Pcode: "3385",
    Locality: "LEDCOURT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.566900",
    Latitude: "-36.987600"
  },
  {
    Country: "Australia",
    Pcode: "3385",
    Locality: "LUBECK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.550100",
    Latitude: "-36.747400"
  },
  {
    Country: "Australia",
    Pcode: "3385",
    Locality: "RIACHELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.713100",
    Latitude: "-36.830900"
  },
  {
    Country: "Australia",
    Pcode: "3385",
    Locality: "ROSES GAP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.456100",
    Latitude: "-36.974200"
  },
  {
    Country: "Australia",
    Pcode: "3385",
    Locality: "WAL WAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.595400",
    Latitude: "-36.808600"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "BOLANGUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.998000",
    Latitude: "-36.716600"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "CALLAWADDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.799400",
    Latitude: "-36.822100"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "CAMPBELLS BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.790900",
    Latitude: "-36.926900"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "GERMANIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.758500",
    Latitude: "-36.976500"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "GREENS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.940800",
    Latitude: "-36.926200"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "KANYA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.981400",
    Latitude: "-36.778800"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "MARNOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.898400",
    Latitude: "-36.690600"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "MARNOO WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.771600",
    Latitude: "-36.717300"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "MORRL MORRL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.890200",
    Latitude: "-36.864900"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "WALLALOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.872100",
    Latitude: "-36.754700"
  },
  {
    Country: "Australia",
    Pcode: "3387",
    Locality: "WALLALOO EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.926400",
    Latitude: "-36.806900"
  },
  {
    Country: "Australia",
    Pcode: "3388",
    Locality: "BANYENA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.842300",
    Latitude: "-36.592200"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "SHEPHERDS FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.101900",
    Latitude: "-37.273700"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "SPARGO CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.143500",
    Latitude: "-37.489800"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "STRANGWAYS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.091200",
    Latitude: "-37.144200"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "WHEATSHEAF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.242700",
    Latitude: "-37.337200"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "YANDOIT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.105200",
    Latitude: "-37.199800"
  },
  {
    Country: "Australia",
    Pcode: "3461",
    Locality: "YANDOIT HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.048800",
    Latitude: "-37.212900"
  },
  {
    Country: "Australia",
    Pcode: "3462",
    Locality: "GREEN GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.096100",
    Latitude: "-37.115600"
  },
  {
    Country: "Australia",
    Pcode: "3462",
    Locality: "MUCKLEFORD SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.124700",
    Latitude: "-37.108800"
  },
  {
    Country: "Australia",
    Pcode: "3462",
    Locality: "NEWSTEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.050900",
    Latitude: "-37.107700"
  },
  {
    Country: "Australia",
    Pcode: "3462",
    Locality: "SANDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.038900",
    Latitude: "-37.168200"
  },
  {
    Country: "Australia",
    Pcode: "3462",
    Locality: "WELSHMANS REEF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.046300",
    Latitude: "-37.065500"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "BARINGHUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.970900",
    Latitude: "-36.977000"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "BARINGHUP WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.902900",
    Latitude: "-36.957600"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "BRADFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.059600",
    Latitude: "-36.922500"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "EASTVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.939500",
    Latitude: "-36.880700"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "GOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.098900",
    Latitude: "-37.028400"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "LAANECOORIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.912000",
    Latitude: "-36.824600"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "MALDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.077500",
    Latitude: "-36.994100"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "NEEREMAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.953100",
    Latitude: "-36.920000"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "NUGGETTY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.059600",
    Latitude: "-36.955400"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "TARRENGOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.013700",
    Latitude: "-37.000900"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "WALMER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.170800",
    Latitude: "-36.976000"
  },
  {
    Country: "Australia",
    Pcode: "3463",
    Locality: "WOODSTOCK WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.988900",
    Latitude: "-36.817900"
  },
  {
    Country: "Australia",
    Pcode: "3464",
    Locality: "CARISBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.837600",
    Latitude: "-37.028700"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "ADELAIDE LEAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.669300",
    Latitude: "-37.086000"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "ALMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.676000",
    Latitude: "-37.023300"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "BOWENVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.701500",
    Latitude: "-37.004600"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "BUNG BONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.589800",
    Latitude: "-37.095400"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "COTSWOLD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.889200",
    Latitude: "-37.138000"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "CRAIGIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.773300",
    Latitude: "-37.108100"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "DAISY HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.721700",
    Latitude: "-37.111900"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "FLAGSTAFF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.775000",
    Latitude: "-37.048900"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "GOLDEN POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "MARYBOROUGH",
    Category: "Delivery Area",
    Longitude: "143.760300",
    Latitude: "-37.085700"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "HAVELOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.785700",
    Latitude: "-36.968400"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "HOMEBUSH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.528600",
    Latitude: "-37.035200"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "MAJORCA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.823400",
    Latitude: "-37.128200"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "MARYBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.730200",
    Latitude: "-37.050100"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "MOOLORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.916000",
    Latitude: "-37.068800"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "MOONLIGHT FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "MARYBOROUGH",
    Category: "Delivery Area",
    Longitude: "143.652000",
    Latitude: "-37.045600"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "NATTE YALLOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.465000",
    Latitude: "-36.948900"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "RATHSCAR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.544600",
    Latitude: "-36.974200"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "RATHSCAR WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.492000",
    Latitude: "-37.011300"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "SIMSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.774500",
    Latitude: "-36.994800"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "TIMOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.727900",
    Latitude: "-36.983300"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "TIMOR WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.672800",
    Latitude: "-36.958600"
  },
  {
    Country: "Australia",
    Pcode: "3465",
    Locality: "WAREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.614400",
    Latitude: "-36.992300"
  },
  {
    Country: "Australia",
    Pcode: "3467",
    Locality: "AVOCA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.479900",
    Latitude: "-37.069600"
  },
  {
    Country: "Australia",
    Pcode: "3468",
    Locality: "AMPHITHEATRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.405500",
    Latitude: "-37.200300"
  },
  {
    Country: "Australia",
    Pcode: "3468",
    Locality: "MOUNT LONARCH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.386800",
    Latitude: "-37.265200"
  },
  {
    Country: "Australia",
    Pcode: "3469",
    Locality: "ELMHURST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.251100",
    Latitude: "-37.168500"
  },
  {
    Country: "Australia",
    Pcode: "3469",
    Locality: "GLENLOFTY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.189600",
    Latitude: "-37.113300"
  },
  {
    Country: "Australia",
    Pcode: "3469",
    Locality: "GLENLOGIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.299300",
    Latitude: "-37.244500"
  },
  {
    Country: "Australia",
    Pcode: "3469",
    Locality: "GLENPATRICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.331600",
    Latitude: "-37.153400"
  },
  {
    Country: "Australia",
    Pcode: "3469",
    Locality: "NOWHERE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.293400",
    Latitude: "-37.128900"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "BET BET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.748400",
    Latitude: "-36.923300"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "BETLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.794300",
    Latitude: "-36.918000"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "BROMLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.785700",
    Latitude: "-36.886900"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "DUNLUCE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.595400",
    Latitude: "-36.879000"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "DUNOLLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.730900",
    Latitude: "-36.866400"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "EDDINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.857200",
    Latitude: "-36.913200"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "GOLDSBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.655200",
    Latitude: "-36.816000"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "INKERMAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.678100",
    Latitude: "-36.793500"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "MCINTYRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.678000",
    Latitude: "-36.689100"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "MOLIAGUL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.668300",
    Latitude: "-36.750400"
  },
  {
    Country: "Australia",
    Pcode: "3472",
    Locality: "MOUNT HOOGHLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.662400",
    Latitude: "-36.910700"
  },
  {
    Country: "Australia",
    Pcode: "3475",
    Locality: "ARCHDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.511400",
    Latitude: "-36.836300"
  },
  {
    Country: "Australia",
    Pcode: "3475",
    Locality: "ARCHDALE JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.511000",
    Latitude: "-36.886000"
  },
  {
    Country: "Australia",
    Pcode: "3475",
    Locality: "BEALIBA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.556500",
    Latitude: "-36.768800"
  },
  {
    Country: "Australia",
    Pcode: "3475",
    Locality: "BURKES FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.560900",
    Latitude: "-36.635800"
  },
  {
    Country: "Australia",
    Pcode: "3475",
    Locality: "COCHRANES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.575900",
    Latitude: "-36.696200"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "ARNOLD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.858300",
    Latitude: "-36.672100"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "ARNOLD WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.787500",
    Latitude: "-36.677100"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "ASCOT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "BENDIGO",
    Category: "Delivery Area",
    Longitude: "144.334000",
    Latitude: "-36.707900"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "AXE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.393400",
    Latitude: "-36.850200"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "AXEDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.531400",
    Latitude: "-36.770400"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "BAGSHOT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.392500",
    Latitude: "-36.653800"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "BAGSHOT NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.411200",
    Latitude: "-36.587100"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "BENDIGO FORWARD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "CORNELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.777400",
    Latitude: "-36.686800"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "CREEK VIEW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.650300",
    Latitude: "-36.606200"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "EMU CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.348700",
    Latitude: "-36.850500"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "EPPALOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.468700",
    Latitude: "-36.854100"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "EPSOM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.308200",
    Latitude: "-36.699200"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "HUNTLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.320500",
    Latitude: "-36.659200"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "HUNTLY NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.333000",
    Latitude: "-36.594300"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "JUNORTOUN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.360400",
    Latitude: "-36.771300"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "KIMBOLTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.505000",
    Latitude: "-36.929300"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "LAKE EPPALOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.563600",
    Latitude: "-36.871100"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "LLANELLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.833200",
    Latitude: "-36.728600"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "LOCKWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.169200",
    Latitude: "-36.814600"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "LOCKWOOD SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.146000",
    Latitude: "-36.869600"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "LONGLEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.422800",
    Latitude: "-36.758900"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "MAIDEN GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.208000",
    Latitude: "-36.755900"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "MANDURANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.294400",
    Latitude: "-36.826600"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "MANDURANG SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.276400",
    Latitude: "-36.868400"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "MURPHYS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.754600",
    Latitude: "-36.731400"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "MYOLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.608100",
    Latitude: "-36.625000"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "MYRTLE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.403100",
    Latitude: "-36.935900"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "NEWBRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.922400",
    Latitude: "-36.754700"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "PAINSWICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.723300",
    Latitude: "-36.802100"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "SEDGWICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.319400",
    Latitude: "-36.894900"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "STRATHFIELDSAYE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.363100",
    Latitude: "-36.804100"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "TARNAGULLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.830600",
    Latitude: "-36.775400"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "TOOLLEEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.674600",
    Latitude: "-36.719000"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "WAANYARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.813700",
    Latitude: "-36.821200"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "WELLSFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.372400",
    Latitude: "-36.711600"
  },
  {
    Country: "Australia",
    Pcode: "3551",
    Locality: "WOODSTOCK ON LODDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.999200",
    Latitude: "-36.752200"
  },
  {
    Country: "Australia",
    Pcode: "3552",
    Locality: "BENDIGO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3554",
    Locality: "BENDIGO DC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3555",
    Locality: "BIG HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "GOLDEN SQUARE",
    Category: "Delivery Area",
    Longitude: "144.232200",
    Latitude: "-36.834600"
  },
  {
    Country: "Australia",
    Pcode: "3555",
    Locality: "GOLDEN GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.266600",
    Latitude: "-36.793400"
  },
  {
    Country: "Australia",
    Pcode: "3555",
    Locality: "GOLDEN SQUARE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.254300",
    Latitude: "-36.774900"
  },
  {
    Country: "Australia",
    Pcode: "3555",
    Locality: "KANGAROO FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.240800",
    Latitude: "-36.806200"
  },
  {
    Country: "Australia",
    Pcode: "3555",
    Locality: "LANSELL PLAZA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "CALIFORNIA GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.258700",
    Latitude: "-36.728200"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "CAMPBELLS FOREST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.122400",
    Latitude: "-36.618300"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "EAGLEHAWK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.263100",
    Latitude: "-36.708100"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "EAGLEHAWK NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.254000",
    Latitude: "-36.690400"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "JACKASS FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.284000",
    Latitude: "-36.717800"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "MYERS FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.178300",
    Latitude: "-36.700800"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "SAILORS GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.232500",
    Latitude: "-36.705400"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "SEBASTIAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.204100",
    Latitude: "-36.593300"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "WHIPSTICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.271000",
    Latitude: "-36.647000"
  },
  {
    Country: "Australia",
    Pcode: "3556",
    Locality: "WOODVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.186500",
    Latitude: "-36.661500"
  },
  {
    Country: "Australia",
    Pcode: "3557",
    Locality: "BARNADOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.528500",
    Latitude: "-36.644100"
  },
  {
    Country: "Australia",
    Pcode: "3557",
    Locality: "FOSTERVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.471200",
    Latitude: "-36.695900"
  },
  {
    Country: "Australia",
    Pcode: "3557",
    Locality: "GOORNONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.503900",
    Latitude: "-36.622400"
  },
  {
    Country: "Australia",
    Pcode: "3557",
    Locality: "MUSKERRY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.588400",
    Latitude: "-36.674100"
  },
  {
    Country: "Australia",
    Pcode: "3558",
    Locality: "BURNEWANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.674700",
    Latitude: "-36.471400"
  },
  {
    Country: "Australia",
    Pcode: "3558",
    Locality: "COROP WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3558",
    Locality: "ELMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.561500",
    Latitude: "-36.467400"
  },
  {
    Country: "Australia",
    Pcode: "3558",
    Locality: "HUNTER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.522800",
    Latitude: "-36.435800"
  },
  {
    Country: "Australia",
    Pcode: "3558",
    Locality: "RUNNYMEDE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.671200",
    Latitude: "-36.547000"
  },
  {
    Country: "Australia",
    Pcode: "3559",
    Locality: "AVONMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.541400",
    Latitude: "-36.534900"
  },
  {
    Country: "Australia",
    Pcode: "3559",
    Locality: "BURRAMBOOT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.820300",
    Latitude: "-36.531100"
  },
  {
    Country: "Australia",
    Pcode: "3559",
    Locality: "COLBINABBIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.804100",
    Latitude: "-36.604500"
  },
  {
    Country: "Australia",
    Pcode: "3559",
    Locality: "COROP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.774000",
    Latitude: "-36.458700"
  },
  {
    Country: "Australia",
    Pcode: "3559",
    Locality: "GOBARUP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.874200",
    Latitude: "-36.681900"
  },
  {
    Country: "Australia",
    Pcode: "3561",
    Locality: "BALLENDELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.674600",
    Latitude: "-36.293200"
  },
  {
    Country: "Australia",
    Pcode: "3561",
    Locality: "BAMAWM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.633300",
    Latitude: "-36.270300"
  },
  {
    Country: "Australia",
    Pcode: "3634",
    Locality: "ZEERUST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.394200",
    Latitude: "-36.275200"
  },
  {
    Country: "Australia",
    Pcode: "3635",
    Locality: "KAARIMBA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.277600",
    Latitude: "-36.139000"
  },
  {
    Country: "Australia",
    Pcode: "3635",
    Locality: "MUNDOONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.359000",
    Latitude: "-36.165500"
  },
  {
    Country: "Australia",
    Pcode: "3635",
    Locality: "WUNGHNU",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.422100",
    Latitude: "-36.165200"
  },
  {
    Country: "Australia",
    Pcode: "3636",
    Locality: "DRUMANURE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.486100",
    Latitude: "-36.165000"
  },
  {
    Country: "Australia",
    Pcode: "3636",
    Locality: "INVERGORDON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.578100",
    Latitude: "-36.148900"
  },
  {
    Country: "Australia",
    Pcode: "3636",
    Locality: "NARING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.581600",
    Latitude: "-36.059300"
  },
  {
    Country: "Australia",
    Pcode: "3636",
    Locality: "NUMURKAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.442500",
    Latitude: "-36.081100"
  },
  {
    Country: "Australia",
    Pcode: "3637",
    Locality: "WAAIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.310500",
    Latitude: "-36.040300"
  },
  {
    Country: "Australia",
    Pcode: "3637",
    Locality: "YALCA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.290800",
    Latitude: "-35.926600"
  },
  {
    Country: "Australia",
    Pcode: "3638",
    Locality: "KOTUPNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.111200",
    Latitude: "-36.133700"
  },
  {
    Country: "Australia",
    Pcode: "3638",
    Locality: "NATHALIA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.187800",
    Latitude: "-36.060500"
  },
  {
    Country: "Australia",
    Pcode: "3638",
    Locality: "YIELIMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.181000",
    Latitude: "-35.913200"
  },
  {
    Country: "Australia",
    Pcode: "3639",
    Locality: "BARMAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.978300",
    Latitude: "-35.953000"
  },
  {
    Country: "Australia",
    Pcode: "3639",
    Locality: "LOWER MOIRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.975500",
    Latitude: "-36.088800"
  },
  {
    Country: "Australia",
    Pcode: "3639",
    Locality: "PICOLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.100800",
    Latitude: "-35.963700"
  },
  {
    Country: "Australia",
    Pcode: "3639",
    Locality: "PICOLA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.051100",
    Latitude: "-35.963800"
  },
  {
    Country: "Australia",
    Pcode: "3640",
    Locality: "KATUNGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.472600",
    Latitude: "-35.994100"
  },
  {
    Country: "Australia",
    Pcode: "3641",
    Locality: "BEARII",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.343600",
    Latitude: "-35.883500"
  },
  {
    Country: "Australia",
    Pcode: "3641",
    Locality: "MYWEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.506800",
    Latitude: "-35.841000"
  },
  {
    Country: "Australia",
    Pcode: "3641",
    Locality: "STRATHMERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.448600",
    Latitude: "-35.907700"
  },
  {
    Country: "Australia",
    Pcode: "3641",
    Locality: "ULUPNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.467300",
    Latitude: "-35.831200"
  },
  {
    Country: "Australia",
    Pcode: "3643",
    Locality: "COBRAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3644",
    Locality: "BAROOGA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.711200",
    Latitude: "-35.909400"
  },
  {
    Country: "Australia",
    Pcode: "3644",
    Locality: "COBRAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.638700",
    Latitude: "-35.938600"
  },
  {
    Country: "Australia",
    Pcode: "3644",
    Locality: "COBRAM EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.729800",
    Latitude: "-35.965600"
  },
  {
    Country: "Australia",
    Pcode: "3644",
    Locality: "KOONOOMOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.565500",
    Latitude: "-35.866500"
  },
  {
    Country: "Australia",
    Pcode: "3644",
    Locality: "LALALTY",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.721600",
    Latitude: "-35.808500"
  },
  {
    Country: "Australia",
    Pcode: "3644",
    Locality: "MUCKATAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.650900",
    Latitude: "-36.005100"
  },
  {
    Country: "Australia",
    Pcode: "3644",
    Locality: "YARROWEYAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.580000",
    Latitude: "-35.946400"
  },
  {
    Country: "Australia",
    Pcode: "3646",
    Locality: "DOOKIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.710400",
    Latitude: "-36.332500"
  },
  {
    Country: "Australia",
    Pcode: "3646",
    Locality: "MOUNT MAJOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.699300",
    Latitude: "-36.369300"
  },
  {
    Country: "Australia",
    Pcode: "3646",
    Locality: "NALINGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.724300",
    Latitude: "-36.427200"
  },
  {
    Country: "Australia",
    Pcode: "3646",
    Locality: "WAGGARANDALL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.782100",
    Latitude: "-36.249100"
  },
  {
    Country: "Australia",
    Pcode: "3646",
    Locality: "YABBA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.681500",
    Latitude: "-36.230700"
  },
  {
    Country: "Australia",
    Pcode: "3646",
    Locality: "YABBA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.681500",
    Latitude: "-36.280700"
  },
  {
    Country: "Australia",
    Pcode: "3646",
    Locality: "YOUANMITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.687600",
    Latitude: "-36.164000"
  },
  {
    Country: "Australia",
    Pcode: "3647",
    Locality: "DOOKIE COLLEGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.703200",
    Latitude: "-36.395100"
  },
  {
    Country: "Australia",
    Pcode: "3649",
    Locality: "KATAMATITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.696700",
    Latitude: "-36.065600"
  },
  {
    Country: "Australia",
    Pcode: "3649",
    Locality: "KATAMATITE EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.754600",
    Latitude: "-36.043500"
  },
  {
    Country: "Australia",
    Pcode: "3658",
    Locality: "BROADFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.053500",
    Latitude: "-37.215100"
  },
  {
    Country: "Australia",
    Pcode: "3658",
    Locality: "CLONBINANE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.158900",
    Latitude: "-37.367700"
  },
  {
    Country: "Australia",
    Pcode: "3658",
    Locality: "HAZELDENE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3658",
    Locality: "REEDY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.156000",
    Latitude: "-37.256600"
  },
  {
    Country: "Australia",
    Pcode: "3658",
    Locality: "STRATH CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.230600",
    Latitude: "-37.246700"
  },
  {
    Country: "Australia",
    Pcode: "3658",
    Locality: "SUGARLOAF CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.027400",
    Latitude: "-37.125600"
  },
  {
    Country: "Australia",
    Pcode: "3658",
    Locality: "SUNDAY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.043100",
    Latitude: "-37.246800"
  },
  {
    Country: "Australia",
    Pcode: "3658",
    Locality: "TYAAK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.137800",
    Latitude: "-37.221400"
  },
  {
    Country: "Australia",
    Pcode: "3658",
    Locality: "WATERFORD PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.066300",
    Latitude: "-37.300400"
  },
  {
    Country: "Australia",
    Pcode: "3659",
    Locality: "TALLAROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.122300",
    Latitude: "-37.130000"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "CAVEAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.498000",
    Latitude: "-37.054100"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "DROPMORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.428900",
    Latitude: "-37.040400"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "HIGHLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.418400",
    Latitude: "-37.084200"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "HILLDENE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.058800",
    Latitude: "-37.040600"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "KERRISDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.248200",
    Latitude: "-37.170000"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "NORTHWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.102900",
    Latitude: "-36.945200"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "SEYMOUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.161200",
    Latitude: "-37.021400"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "SEYMOUR SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "TRAWOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.239100",
    Latitude: "-37.130700"
  },
  {
    Country: "Australia",
    Pcode: "3660",
    Locality: "WHITEHEADS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.258500",
    Latitude: "-37.051700"
  },
  {
    Country: "Australia",
    Pcode: "3661",
    Locality: "SEYMOUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3662",
    Locality: "PUCKAPUNYAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.937200",
    Latitude: "-36.931300"
  },
  {
    Country: "Australia",
    Pcode: "3663",
    Locality: "MANGALORE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.170800",
    Latitude: "-36.924600"
  },
  {
    Country: "Australia",
    Pcode: "3664",
    Locality: "AVENEL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.313900",
    Latitude: "-36.930300"
  },
  {
    Country: "Australia",
    Pcode: "3664",
    Locality: "UPTON HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.406700",
    Latitude: "-36.896400"
  },
  {
    Country: "Australia",
    Pcode: "3665",
    Locality: "LOCKSLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.318400",
    Latitude: "-36.842100"
  },
  {
    Country: "Australia",
    Pcode: "3665",
    Locality: "LONGWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.386600",
    Latitude: "-36.825700"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "BALMATTUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.655300",
    Latitude: "-36.709900"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "CREIGHTONS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.518000",
    Latitude: "-36.841000"
  },
  {
    Country: "Australia",
    Pcode: "3666",
    Locality: "EUROA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.560000",
    Latitude: "-36.765300"
  },
  {
    Country: "Australia",
    Pcode: "3717",
    Locality: "GLENBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.430400",
    Latitude: "-37.417800"
  },
  {
    Country: "Australia",
    Pcode: "3717",
    Locality: "HOMEWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.314700",
    Latitude: "-37.201400"
  },
  {
    Country: "Australia",
    Pcode: "3717",
    Locality: "KILLINGWORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.423900",
    Latitude: "-37.181500"
  },
  {
    Country: "Australia",
    Pcode: "3717",
    Locality: "LIMESTONE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.549400",
    Latitude: "-37.271600"
  },
  {
    Country: "Australia",
    Pcode: "3717",
    Locality: "MURRINDINDI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.582000",
    Latitude: "-37.369100"
  },
  {
    Country: "Australia",
    Pcode: "3717",
    Locality: "YEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.406200",
    Latitude: "-37.281900"
  },
  {
    Country: "Australia",
    Pcode: "3718",
    Locality: "MOLESWORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.525600",
    Latitude: "-37.151000"
  },
  {
    Country: "Australia",
    Pcode: "3719",
    Locality: "GOBUR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.615100",
    Latitude: "-37.010200"
  },
  {
    Country: "Australia",
    Pcode: "3719",
    Locality: "KANUMBRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.686400",
    Latitude: "-37.048900"
  },
  {
    Country: "Australia",
    Pcode: "3719",
    Locality: "TERIP TERIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.566400",
    Latitude: "-36.985900"
  },
  {
    Country: "Australia",
    Pcode: "3719",
    Locality: "YARCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.609700",
    Latitude: "-37.089700"
  },
  {
    Country: "Australia",
    Pcode: "3720",
    Locality: "BONNIE DOON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.882300",
    Latitude: "-37.011700"
  },
  {
    Country: "Australia",
    Pcode: "3722",
    Locality: "BARWITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.224400",
    Latitude: "-37.009400"
  },
  {
    Country: "Australia",
    Pcode: "3722",
    Locality: "MANSFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.111700",
    Latitude: "-37.049100"
  },
  {
    Country: "Australia",
    Pcode: "3722",
    Locality: "MIRIMBAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.399100",
    Latitude: "-37.114900"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "ARCHERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.193900",
    Latitude: "-36.869700"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "BARJARG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.007500",
    Latitude: "-36.920500"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "BOOROLITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.204500",
    Latitude: "-37.149200"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "BRIDGE CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.104000",
    Latitude: "-36.900700"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "DELATITE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.162800",
    Latitude: "-37.128600"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "ENOCHS POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.113800",
    Latitude: "-37.421300"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "GAFFNEYS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.274400",
    Latitude: "-37.481500"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "GOUGHS BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.070000",
    Latitude: "-37.145600"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "HOWES CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.010200",
    Latitude: "-37.141600"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "HOWQUA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.195200",
    Latitude: "-37.244000"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "HOWQUA HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.303100",
    Latitude: "-37.183800"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "HOWQUA INLET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.132600",
    Latitude: "-37.232000"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "JAMIESON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.114200",
    Latitude: "-37.271000"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "KEVINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.168800",
    Latitude: "-37.352600"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "KNOCKWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.267400",
    Latitude: "-37.419100"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "MACS COVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.144900",
    Latitude: "-37.205500"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "MAINDAMPLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.958300",
    Latitude: "-37.027700"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "MATLOCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.248100",
    Latitude: "-37.591200"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "MERRIJIG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.280100",
    Latitude: "-37.077700"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "MOUNT BULLER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.433800",
    Latitude: "-37.265800"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "MOUNTAIN BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.103900",
    Latitude: "-37.198200"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "PIRIES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.126200",
    Latitude: "-37.178500"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "SAWMILL SETTLEMENT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.368600",
    Latitude: "-37.120300"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "TOLMIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.271900",
    Latitude: "-36.863400"
  },
  {
    Country: "Australia",
    Pcode: "3723",
    Locality: "WOODS POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.342400",
    Latitude: "-37.560100"
  },
  {
    Country: "Australia",
    Pcode: "3724",
    Locality: "MANSFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3725",
    Locality: "BOXWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.802800",
    Latitude: "-36.325800"
  },
  {
    Country: "Australia",
    Pcode: "3725",
    Locality: "CHESNEY VALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.061700",
    Latitude: "-36.414000"
  },
  {
    Country: "Australia",
    Pcode: "3725",
    Locality: "GOORAMBAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.953000",
    Latitude: "-36.414200"
  },
  {
    Country: "Australia",
    Pcode: "3725",
    Locality: "MAJOR PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.779500",
    Latitude: "-36.366400"
  },
  {
    Country: "Australia",
    Pcode: "3725",
    Locality: "STEWARTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.812900",
    Latitude: "-36.416100"
  },
  {
    Country: "Australia",
    Pcode: "3726",
    Locality: "BUNGEET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.057800",
    Latitude: "-36.277000"
  },
  {
    Country: "Australia",
    Pcode: "3726",
    Locality: "BUNGEET WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.004400",
    Latitude: "-36.347600"
  },
  {
    Country: "Australia",
    Pcode: "3726",
    Locality: "DEVENISH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.895500",
    Latitude: "-36.341700"
  },
  {
    Country: "Australia",
    Pcode: "3726",
    Locality: "THOONA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.070200",
    Latitude: "-36.349000"
  },
  {
    Country: "Australia",
    Pcode: "3727",
    Locality: "ALMONDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.071900",
    Latitude: "-36.227100"
  },
  {
    Country: "Australia",
    Pcode: "3727",
    Locality: "LAKE ROWAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.981200",
    Latitude: "-36.262900"
  },
  {
    Country: "Australia",
    Pcode: "3727",
    Locality: "PELLUEBLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.990200",
    Latitude: "-36.199300"
  },
  {
    Country: "Australia",
    Pcode: "3727",
    Locality: "ST JAMES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.879100",
    Latitude: "-36.257300"
  },
  {
    Country: "Australia",
    Pcode: "3727",
    Locality: "YUNDOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.820500",
    Latitude: "-36.276100"
  },
  {
    Country: "Australia",
    Pcode: "3728",
    Locality: "BOOMAHNOOMOONAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.099100",
    Latitude: "-36.124100"
  },
  {
    Country: "Australia",
    Pcode: "3728",
    Locality: "TUNGAMAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.890900",
    Latitude: "-36.161600"
  },
  {
    Country: "Australia",
    Pcode: "3728",
    Locality: "WILBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.058000",
    Latitude: "-36.149200"
  },
  {
    Country: "Australia",
    Pcode: "3728",
    Locality: "YOUARANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.763400",
    Latitude: "-36.146700"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "BATHUMI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.085100",
    Latitude: "-36.025000"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "BOOSEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.787900",
    Latitude: "-36.048100"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "BUNDALONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.128500",
    Latitude: "-36.050900"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "BUNDALONG SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.170800",
    Latitude: "-36.102500"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "BURRAMINE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.872500",
    Latitude: "-36.011200"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "BURRAMINE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.863600",
    Latitude: "-36.069900"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "ESMOND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.184200",
    Latitude: "-36.062700"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "TELFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.952800",
    Latitude: "-36.102000"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "YARRAWONGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.999100",
    Latitude: "-36.030800"
  },
  {
    Country: "Australia",
    Pcode: "3730",
    Locality: "YARRAWONGA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.026700",
    Latitude: "-36.084400"
  },
  {
    Country: "Australia",
    Pcode: "3732",
    Locality: "MOYHU",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.371900",
    Latitude: "-36.597800"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "ERICA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.379000",
    Latitude: "-37.976700"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "FUMINA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.101500",
    Latitude: "-37.910600"
  },
  {
    Country: "Australia",
    Pcode: "3764",
    Locality: "MORANDING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.946400",
    Latitude: "-37.233900"
  },
  {
    Country: "Australia",
    Pcode: "3764",
    Locality: "TANTARABOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.822500",
    Latitude: "-37.238100"
  },
  {
    Country: "Australia",
    Pcode: "3764",
    Locality: "WILLOWMAVIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.877700",
    Latitude: "-37.249100"
  },
  {
    Country: "Australia",
    Pcode: "3765",
    Locality: "MONTROSE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.340800",
    Latitude: "-37.817200"
  },
  {
    Country: "Australia",
    Pcode: "3766",
    Locality: "KALORAMA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.380500",
    Latitude: "-37.820100"
  },
  {
    Country: "Australia",
    Pcode: "3767",
    Locality: "MOUNT DANDENONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.351100",
    Latitude: "-37.836200"
  },
  {
    Country: "Australia",
    Pcode: "3770",
    Locality: "COLDSTREAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.404000",
    Latitude: "-37.706900"
  },
  {
    Country: "Australia",
    Pcode: "3770",
    Locality: "GRUYERE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.467400",
    Latitude: "-37.732200"
  },
  {
    Country: "Australia",
    Pcode: "3770",
    Locality: "YERING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.368400",
    Latitude: "-37.684000"
  },
  {
    Country: "Australia",
    Pcode: "3775",
    Locality: "CHRISTMAS HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.307000",
    Latitude: "-37.658100"
  },
  {
    Country: "Australia",
    Pcode: "3775",
    Locality: "DIXONS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.425800",
    Latitude: "-37.598400"
  },
  {
    Country: "Australia",
    Pcode: "3775",
    Locality: "STEELS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.382300",
    Latitude: "-37.581800"
  },
  {
    Country: "Australia",
    Pcode: "3775",
    Locality: "TARRAWARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.442700",
    Latitude: "-37.656500"
  },
  {
    Country: "Australia",
    Pcode: "3775",
    Locality: "YARRA GLEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.382300",
    Latitude: "-37.649900"
  },
  {
    Country: "Australia",
    Pcode: "3777",
    Locality: "BADGER CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.543300",
    Latitude: "-37.687900"
  },
  {
    Country: "Australia",
    Pcode: "3777",
    Locality: "CASTELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.423000",
    Latitude: "-37.515500"
  },
  {
    Country: "Australia",
    Pcode: "3777",
    Locality: "CHUM CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.485500",
    Latitude: "-37.611100"
  },
  {
    Country: "Australia",
    Pcode: "3777",
    Locality: "HEALESVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.558900",
    Latitude: "-37.666600"
  },
  {
    Country: "Australia",
    Pcode: "3777",
    Locality: "HEALESVILLE MAIN STREET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3777",
    Locality: "MOUNT TOOLEBEWONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.561400",
    Latitude: "-37.717700"
  },
  {
    Country: "Australia",
    Pcode: "3777",
    Locality: "TOOLANGI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.497600",
    Latitude: "-37.510300"
  },
  {
    Country: "Australia",
    Pcode: "3778",
    Locality: "FERNSHAW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.618100",
    Latitude: "-37.609300"
  },
  {
    Country: "Australia",
    Pcode: "3778",
    Locality: "NARBETHONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.638500",
    Latitude: "-37.542300"
  },
  {
    Country: "Australia",
    Pcode: "3779",
    Locality: "CAMBARVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.031600",
    Latitude: "-37.625500"
  },
  {
    Country: "Australia",
    Pcode: "3779",
    Locality: "MARYSVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.818600",
    Latitude: "-37.523400"
  },
  {
    Country: "Australia",
    Pcode: "3781",
    Locality: "COCKATOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.509000",
    Latitude: "-37.931800"
  },
  {
    Country: "Australia",
    Pcode: "3781",
    Locality: "MOUNT BURNETT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.510900",
    Latitude: "-37.975700"
  },
  {
    Country: "Australia",
    Pcode: "3781",
    Locality: "NANGANA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.537000",
    Latitude: "-37.873900"
  },
  {
    Country: "Australia",
    Pcode: "3782",
    Locality: "AVONSLEIGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.488700",
    Latitude: "-37.913300"
  },
  {
    Country: "Australia",
    Pcode: "3782",
    Locality: "CLEMATIS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.421300",
    Latitude: "-37.931100"
  },
  {
    Country: "Australia",
    Pcode: "3782",
    Locality: "EMERALD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.434400",
    Latitude: "-37.946600"
  },
  {
    Country: "Australia",
    Pcode: "3782",
    Locality: "MACCLESFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.489800",
    Latitude: "-37.881800"
  },
  {
    Country: "Australia",
    Pcode: "3783",
    Locality: "GEMBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.630800",
    Latitude: "-37.940300"
  },
  {
    Country: "Australia",
    Pcode: "3785",
    Locality: "TREMONT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.317200",
    Latitude: "-37.878400"
  },
  {
    Country: "Australia",
    Pcode: "3786",
    Locality: "FERNY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.332300",
    Latitude: "-37.875400"
  },
  {
    Country: "Australia",
    Pcode: "3787",
    Locality: "SASSAFRAS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.345100",
    Latitude: "-37.862600"
  },
  {
    Country: "Australia",
    Pcode: "3787",
    Locality: "SASSAFRAS GULLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3788",
    Locality: "OLINDA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.376700",
    Latitude: "-37.852800"
  },
  {
    Country: "Australia",
    Pcode: "3789",
    Locality: "SHERBROOKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.359400",
    Latitude: "-37.890200"
  },
  {
    Country: "Australia",
    Pcode: "3791",
    Locality: "KALLISTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.382200",
    Latitude: "-37.897800"
  },
  {
    Country: "Australia",
    Pcode: "3792",
    Locality: "THE PATCH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.401200",
    Latitude: "-37.891100"
  },
  {
    Country: "Australia",
    Pcode: "3793",
    Locality: "MONBULK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.426000",
    Latitude: "-37.875000"
  },
  {
    Country: "Australia",
    Pcode: "3795",
    Locality: "SILVAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.441300",
    Latitude: "-37.832000"
  },
  {
    Country: "Australia",
    Pcode: "3796",
    Locality: "MOUNT EVELYN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.385200",
    Latitude: "-37.786400"
  },
  {
    Country: "Australia",
    Pcode: "3797",
    Locality: "GILDEROY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.714300",
    Latitude: "-37.863100"
  },
  {
    Country: "Australia",
    Pcode: "3797",
    Locality: "GLADYSDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.642400",
    Latitude: "-37.839900"
  },
  {
    Country: "Australia",
    Pcode: "3797",
    Locality: "POWELLTOWN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.773100",
    Latitude: "-37.859200"
  },
  {
    Country: "Australia",
    Pcode: "3797",
    Locality: "THREE BRIDGES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.696900",
    Latitude: "-37.843400"
  },
  {
    Country: "Australia",
    Pcode: "3797",
    Locality: "YARRA JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.625900",
    Latitude: "-37.796800"
  },
  {
    Country: "Australia",
    Pcode: "3799",
    Locality: "BIG PATS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.760800",
    Latitude: "-37.763800"
  },
  {
    Country: "Australia",
    Pcode: "3799",
    Locality: "EAST WARBURTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.790000",
    Latitude: "-37.770300"
  },
  {
    Country: "Australia",
    Pcode: "3799",
    Locality: "MCMAHONS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.849000",
    Latitude: "-37.722000"
  },
  {
    Country: "Australia",
    Pcode: "3799",
    Locality: "MILLGROVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.650900",
    Latitude: "-37.744000"
  },
  {
    Country: "Australia",
    Pcode: "3799",
    Locality: "REEFTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.902500",
    Latitude: "-37.670300"
  },
  {
    Country: "Australia",
    Pcode: "3799",
    Locality: "WARBURTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.708600",
    Latitude: "-37.728400"
  },
  {
    Country: "Australia",
    Pcode: "3799",
    Locality: "WESBURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.636600",
    Latitude: "-37.768100"
  },
  {
    Country: "Australia",
    Pcode: "3800",
    Locality: "MONASH UNIVERSITY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3802",
    Locality: "ENDEAVOUR HILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.258400",
    Latitude: "-37.969000"
  },
  {
    Country: "Australia",
    Pcode: "3803",
    Locality: "HALLAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.269900",
    Latitude: "-38.004300"
  },
  {
    Country: "Australia",
    Pcode: "3804",
    Locality: "NARRE WARREN EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.358000",
    Latitude: "-37.962700"
  },
  {
    Country: "Australia",
    Pcode: "3804",
    Locality: "NARRE WARREN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.315300",
    Latitude: "-37.983800"
  },
  {
    Country: "Australia",
    Pcode: "3805",
    Locality: "FOUNTAIN GATE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3805",
    Locality: "NARRE WARREN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.308100",
    Latitude: "-38.020800"
  },
  {
    Country: "Australia",
    Pcode: "3805",
    Locality: "NARRE WARREN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.300800",
    Latitude: "-38.049600"
  },
  {
    Country: "Australia",
    Pcode: "3806",
    Locality: "BERWICK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.349300",
    Latitude: "-38.042500"
  },
  {
    Country: "Australia",
    Pcode: "3806",
    Locality: "HARKAWAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.356100",
    Latitude: "-37.992600"
  },
  {
    Country: "Australia",
    Pcode: "3807",
    Locality: "BEACONSFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.384800",
    Latitude: "-38.043800"
  },
  {
    Country: "Australia",
    Pcode: "3807",
    Locality: "GUYS HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.392800",
    Latitude: "-38.015400"
  },
  {
    Country: "Australia",
    Pcode: "3808",
    Locality: "BEACONSFIELD UPPER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.425400",
    Latitude: "-37.998500"
  },
  {
    Country: "Australia",
    Pcode: "3847",
    Locality: "WILLUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.843300",
    Latitude: "-38.265600"
  },
  {
    Country: "Australia",
    Pcode: "3847",
    Locality: "WILLUNG SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.836500",
    Latitude: "-38.322900"
  },
  {
    Country: "Australia",
    Pcode: "3850",
    Locality: "SALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.081200",
    Latitude: "-38.106100"
  },
  {
    Country: "Australia",
    Pcode: "3850",
    Locality: "WURRUK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.023300",
    Latitude: "-38.112800"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "AIRLY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.099400",
    Latitude: "-38.035600"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "BUNDALAGUAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.009000",
    Latitude: "-38.024300"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "CLYDEBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.186600",
    Latitude: "-38.047200"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "COBAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.152000",
    Latitude: "-38.076800"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "DARRIMAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.898600",
    Latitude: "-38.409800"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "DUTSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.240200",
    Latitude: "-38.180600"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "DUTSON DOWNS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.325000",
    Latitude: "-38.193400"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "FLAMINGO BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.314500",
    Latitude: "-38.269800"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "FULHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.965800",
    Latitude: "-38.082200"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "GIFFARD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.112500",
    Latitude: "-38.390500"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "GIFFARD WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.986800",
    Latitude: "-38.378800"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "GLOMAR BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.277400",
    Latitude: "-38.298400"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "GOLDEN BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.363200",
    Latitude: "-38.230000"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "KILMANY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.911400",
    Latitude: "-38.120400"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "LAKE WELLINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.317800",
    Latitude: "-38.093500"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "LOCH SPORT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.595100",
    Latitude: "-38.043700"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "LONGFORD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.154400",
    Latitude: "-38.237800"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "MONTGOMERY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.063100",
    Latitude: "-38.037700"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "MYRTLEBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.022100",
    Latitude: "-38.062700"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "PARADISE BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.421200",
    Latitude: "-38.191900"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "PEARSONDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.028600",
    Latitude: "-38.136000"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "SEACOMBE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.422900",
    Latitude: "-38.128400"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "SEASPRAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.169100",
    Latitude: "-38.327500"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "SOMERTON PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "STRADBROKE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.038400",
    Latitude: "-38.278200"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "THE HEART",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.185300",
    Latitude: "-38.113600"
  },
  {
    Country: "Australia",
    Pcode: "3851",
    Locality: "THE HONEYSUCKLES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.231400",
    Latitude: "-38.335800"
  },
  {
    Country: "Australia",
    Pcode: "3852",
    Locality: "EAST SALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.142000",
    Latitude: "-38.104000"
  },
  {
    Country: "Australia",
    Pcode: "3852",
    Locality: "SALE EAST RAAF",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3853",
    Locality: "SALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3854",
    Locality: "GLENGARRY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.642500",
    Latitude: "-38.123600"
  },
  {
    Country: "Australia",
    Pcode: "3854",
    Locality: "GLENGARRY NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.559100",
    Latitude: "-38.087800"
  },
  {
    Country: "Australia",
    Pcode: "3854",
    Locality: "GLENGARRY WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.508700",
    Latitude: "-38.102100"
  },
  {
    Country: "Australia",
    Pcode: "3856",
    Locality: "TOONGABBIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.572100",
    Latitude: "-38.035400"
  },
  {
    Country: "Australia",
    Pcode: "3857",
    Locality: "COWWARR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.699800",
    Latitude: "-38.026700"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "ARBUCKLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.832200",
    Latitude: "-37.365100"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "BILLABONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.817000",
    Latitude: "-37.251400"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "BURAGWONDUC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.781800",
    Latitude: "-37.485900"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "CROOKAYAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.627200",
    Latitude: "-37.489400"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "DAWSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.690000",
    Latitude: "-37.974400"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "DENISON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.876100",
    Latitude: "-38.039000"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "GILLUM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.733700",
    Latitude: "-37.752000"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "GLENFALLOCH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.604300",
    Latitude: "-37.693000"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "GLENMAGGIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.710000",
    Latitude: "-37.822200"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "HEYFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.766000",
    Latitude: "-37.971800"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "HOWITT PLAINS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.700000",
    Latitude: "-37.194400"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "LICOLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.569800",
    Latitude: "-37.602700"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "LICOLA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.520600",
    Latitude: "-37.520300"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "REYNARD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.633200",
    Latitude: "-37.374600"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "SARGOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.731400",
    Latitude: "-37.582000"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "SEATON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.684200",
    Latitude: "-37.941900"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "TAMBORITHA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.690900",
    Latitude: "-37.435300"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "WINNINDOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.753000",
    Latitude: "-38.077500"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "WORROWING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.742500",
    Latitude: "-37.671300"
  },
  {
    Country: "Australia",
    Pcode: "3858",
    Locality: "YANGOURA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.577200",
    Latitude: "-37.800300"
  },
  {
    Country: "Australia",
    Pcode: "3859",
    Locality: "MAFFRA WEST UPPER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.825100",
    Latitude: "-37.881300"
  },
  {
    Country: "Australia",
    Pcode: "3859",
    Locality: "NEWRY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.902400",
    Latitude: "-37.897200"
  },
  {
    Country: "Australia",
    Pcode: "3859",
    Locality: "TINAMBA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.883700",
    Latitude: "-37.974100"
  },
  {
    Country: "Australia",
    Pcode: "3859",
    Locality: "TINAMBA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.827300",
    Latitude: "-37.922600"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "BOISDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.965700",
    Latitude: "-37.856400"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "BRIAGOLONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.106500",
    Latitude: "-37.814400"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "BUSHY PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.020100",
    Latitude: "-37.846100"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "COONGULLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.778000",
    Latitude: "-37.873200"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "KOOROOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.940900",
    Latitude: "-37.714700"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "MAFFRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.002300",
    Latitude: "-37.963900"
  },
  {
    Country: "Australia",
    Pcode: "3860",
    Locality: "MONOMAK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.828500",
    Latitude: "-37.784800"
  },
  {
    Country: "Australia",
    Pcode: "3922",
    Locality: "SUNDERLAND BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.272900",
    Latitude: "-38.503100"
  },
  {
    Country: "Australia",
    Pcode: "3922",
    Locality: "SUNSET STRIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.265800",
    Latitude: "-38.494600"
  },
  {
    Country: "Australia",
    Pcode: "3922",
    Locality: "SURF BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.290000",
    Latitude: "-38.507800"
  },
  {
    Country: "Australia",
    Pcode: "3922",
    Locality: "VENTNOR",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.184200",
    Latitude: "-38.492800"
  },
  {
    Country: "Australia",
    Pcode: "3922",
    Locality: "WIMBLEDON HEIGHTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.234500",
    Latitude: "-38.480100"
  },
  {
    Country: "Australia",
    Pcode: "3923",
    Locality: "RHYLL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.284000",
    Latitude: "-38.481600"
  },
  {
    Country: "Australia",
    Pcode: "3925",
    Locality: "CAPE WOOLAMAI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.343400",
    Latitude: "-38.543600"
  },
  {
    Country: "Australia",
    Pcode: "3925",
    Locality: "CHURCHILL ISLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.338700",
    Latitude: "-38.501000"
  },
  {
    Country: "Australia",
    Pcode: "3925",
    Locality: "NEWHAVEN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.332300",
    Latitude: "-38.516800"
  },
  {
    Country: "Australia",
    Pcode: "3925",
    Locality: "SAN REMO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.408100",
    Latitude: "-38.529300"
  },
  {
    Country: "Australia",
    Pcode: "3926",
    Locality: "BALNARRING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.123600",
    Latitude: "-38.354900"
  },
  {
    Country: "Australia",
    Pcode: "3926",
    Locality: "BALNARRING BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.121000",
    Latitude: "-38.391300"
  },
  {
    Country: "Australia",
    Pcode: "3926",
    Locality: "MERRICKS BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.107200",
    Latitude: "-38.392900"
  },
  {
    Country: "Australia",
    Pcode: "3926",
    Locality: "MERRICKS NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.073900",
    Latitude: "-38.341800"
  },
  {
    Country: "Australia",
    Pcode: "3927",
    Locality: "SOMERS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.155300",
    Latitude: "-38.380500"
  },
  {
    Country: "Australia",
    Pcode: "3928",
    Locality: "MAIN RIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.967100",
    Latitude: "-38.402100"
  },
  {
    Country: "Australia",
    Pcode: "3929",
    Locality: "FLINDERS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.985900",
    Latitude: "-38.456400"
  },
  {
    Country: "Australia",
    Pcode: "3930",
    Locality: "KUNYUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3930",
    Locality: "MOUNT ELIZA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.095200",
    Latitude: "-38.193700"
  },
  {
    Country: "Australia",
    Pcode: "3931",
    Locality: "MORNINGTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.060900",
    Latitude: "-38.227700"
  },
  {
    Country: "Australia",
    Pcode: "3933",
    Locality: "MOOROODUC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.114400",
    Latitude: "-38.246800"
  },
  {
    Country: "Australia",
    Pcode: "3934",
    Locality: "MOUNT MARTHA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.032200",
    Latitude: "-38.273700"
  },
  {
    Country: "Australia",
    Pcode: "3936",
    Locality: "ARTHURS SEAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.959700",
    Latitude: "-38.361700"
  },
  {
    Country: "Australia",
    Pcode: "3936",
    Locality: "DROMANA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.012200",
    Latitude: "-38.331700"
  },
  {
    Country: "Australia",
    Pcode: "3936",
    Locality: "SAFETY BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.001000",
    Latitude: "-38.316000"
  },
  {
    Country: "Australia",
    Pcode: "3937",
    Locality: "RED HILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.013100",
    Latitude: "-38.362100"
  },
  {
    Country: "Australia",
    Pcode: "3937",
    Locality: "RED HILL SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.042000",
    Latitude: "-38.383700"
  },
  {
    Country: "Australia",
    Pcode: "3938",
    Locality: "MCCRAE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.933100",
    Latitude: "-38.354300"
  },
  {
    Country: "Australia",
    Pcode: "3939",
    Locality: "BONEO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.901800",
    Latitude: "-38.408400"
  },
  {
    Country: "Australia",
    Pcode: "3939",
    Locality: "CAPE SCHANCK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.902300",
    Latitude: "-38.463200"
  },
  {
    Country: "Australia",
    Pcode: "3939",
    Locality: "FINGAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.856900",
    Latitude: "-38.421300"
  },
  {
    Country: "Australia",
    Pcode: "3939",
    Locality: "ROSEBUD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.915800",
    Latitude: "-38.371100"
  },
  {
    Country: "Australia",
    Pcode: "3939",
    Locality: "ROSEBUD PLAZA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3940",
    Locality: "CAPEL SOUND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.874700",
    Latitude: "-38.372800"
  },
  {
    Country: "Australia",
    Pcode: "3941",
    Locality: "RYE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.811800",
    Latitude: "-38.386900"
  },
  {
    Country: "Australia",
    Pcode: "3941",
    Locality: "ST ANDREWS BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.831600",
    Latitude: "-38.419200"
  },
  {
    Country: "Australia",
    Pcode: "3941",
    Locality: "TOOTGAROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.851400",
    Latitude: "-38.378100"
  },
  {
    Country: "Australia",
    Pcode: "3942",
    Locality: "BLAIRGOWRIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.773500",
    Latitude: "-38.370600"
  },
  {
    Country: "Australia",
    Pcode: "3943",
    Locality: "SORRENTO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.739400",
    Latitude: "-38.343700"
  },
  {
    Country: "Australia",
    Pcode: "3944",
    Locality: "PORTSEA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.709600",
    Latitude: "-38.323700"
  },
  {
    Country: "Australia",
    Pcode: "3945",
    Locality: "JEETHO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.726100",
    Latitude: "-38.400500"
  },
  {
    Country: "Australia",
    Pcode: "3945",
    Locality: "KROWERA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.666000",
    Latitude: "-38.449000"
  },
  {
    Country: "Australia",
    Pcode: "3945",
    Locality: "LOCH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.682800",
    Latitude: "-38.377400"
  },
  {
    Country: "Australia",
    Pcode: "3945",
    Locality: "WOODLEIGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.620000",
    Latitude: "-38.392700"
  },
  {
    Country: "Australia",
    Pcode: "3946",
    Locality: "BENA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.740000",
    Latitude: "-38.431700"
  },
  {
    Country: "Australia",
    Pcode: "3950",
    Locality: "KARDELLA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.872300",
    Latitude: "-38.456900"
  },
  {
    Country: "Australia",
    Pcode: "3950",
    Locality: "KORUMBURRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.823400",
    Latitude: "-38.435000"
  },
  {
    Country: "Australia",
    Pcode: "3950",
    Locality: "KORUMBURRA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.858700",
    Latitude: "-38.479600"
  },
  {
    Country: "Australia",
    Pcode: "3950",
    Locality: "STRZELECKI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.898300",
    Latitude: "-38.345000"
  },
  {
    Country: "Australia",
    Pcode: "3950",
    Locality: "WHITELAW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.777700",
    Latitude: "-38.434900"
  },
  {
    Country: "Australia",
    Pcode: "3951",
    Locality: "ARAWATA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.882800",
    Latitude: "-38.394700"
  },
  {
    Country: "Australia",
    Pcode: "3951",
    Locality: "FAIRBANK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.925800",
    Latitude: "-38.404700"
  },
  {
    Country: "Australia",
    Pcode: "3951",
    Locality: "JUMBUNNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.761400",
    Latitude: "-38.472000"
  },
  {
    Country: "Australia",
    Pcode: "3951",
    Locality: "KARDELLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.875000",
    Latitude: "-38.421300"
  },
  {
    Country: "Australia",
    Pcode: "3951",
    Locality: "KONGWAK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.712000",
    Latitude: "-38.509800"
  },
  {
    Country: "Australia",
    Pcode: "3951",
    Locality: "MOYARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.726600",
    Latitude: "-38.482000"
  },
  {
    Country: "Australia",
    Pcode: "3951",
    Locality: "OUTTRIM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.779800",
    Latitude: "-38.517200"
  },
  {
    Country: "Australia",
    Pcode: "3951",
    Locality: "RANCEBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.849200",
    Latitude: "-38.373300"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "BERRYS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.067900",
    Latitude: "-38.412700"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "BOOROOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.055300",
    Latitude: "-38.452500"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "HALLSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.033300",
    Latitude: "-38.358800"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "KOOROOMAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.028200",
    Latitude: "-38.467900"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "LEONGATHA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.937300",
    Latitude: "-38.472000"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "LEONGATHA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.974100",
    Latitude: "-38.424800"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "LEONGATHA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.854100",
    Latitude: "-38.544600"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "MARDAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.133800",
    Latitude: "-38.470200"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "MOUNT ECCLES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.968400",
    Latitude: "-38.373400"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "MOUNT ECCLES SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.994400",
    Latitude: "-38.413100"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "NERRENA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.019700",
    Latitude: "-38.505300"
  },
  {
    Country: "Australia",
    Pcode: "3953",
    Locality: "RUBY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.919600",
    Latitude: "-38.437500"
  },
  {
    Country: "Australia",
    Pcode: "4032",
    Locality: "CHERMSIDE SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4032",
    Locality: "CHERMSIDE WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.012300",
    Latitude: "-27.384800"
  },
  {
    Country: "Australia",
    Pcode: "4034",
    Locality: "ASPLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.016900",
    Latitude: "-27.365600"
  },
  {
    Country: "Australia",
    Pcode: "4034",
    Locality: "BOONDALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.072900",
    Latitude: "-27.348200"
  },
  {
    Country: "Australia",
    Pcode: "4034",
    Locality: "CARSELDINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.017500",
    Latitude: "-27.345800"
  },
  {
    Country: "Australia",
    Pcode: "4034",
    Locality: "GEEBUNG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.046000",
    Latitude: "-27.372300"
  },
  {
    Country: "Australia",
    Pcode: "4034",
    Locality: "ZILLMERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.039700",
    Latitude: "-27.358500"
  },
  {
    Country: "Australia",
    Pcode: "4035",
    Locality: "ALBANY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.968400",
    Latitude: "-27.353600"
  },
  {
    Country: "Australia",
    Pcode: "4035",
    Locality: "BRIDGEMAN DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.994200",
    Latitude: "-27.353200"
  },
  {
    Country: "Australia",
    Pcode: "4036",
    Locality: "BALD HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.017100",
    Latitude: "-27.307400"
  },
  {
    Country: "Australia",
    Pcode: "4037",
    Locality: "EATONS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.938400",
    Latitude: "-27.340800"
  },
  {
    Country: "Australia",
    Pcode: "4051",
    Locality: "ALDERLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.000700",
    Latitude: "-27.423700"
  },
  {
    Country: "Australia",
    Pcode: "4051",
    Locality: "ENOGGERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.974300",
    Latitude: "-27.426400"
  },
  {
    Country: "Australia",
    Pcode: "4051",
    Locality: "GAYTHORNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.979600",
    Latitude: "-27.419700"
  },
  {
    Country: "Australia",
    Pcode: "4051",
    Locality: "GRANGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.015800",
    Latitude: "-27.421600"
  },
  {
    Country: "Australia",
    Pcode: "4051",
    Locality: "NEWMARKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.007200",
    Latitude: "-27.435100"
  },
  {
    Country: "Australia",
    Pcode: "4051",
    Locality: "WILSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.017800",
    Latitude: "-27.434100"
  },
  {
    Country: "Australia",
    Pcode: "4053",
    Locality: "BROOKSIDE CENTRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4053",
    Locality: "EVERTON HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.972500",
    Latitude: "-27.390100"
  },
  {
    Country: "Australia",
    Pcode: "4053",
    Locality: "EVERTON PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.987200",
    Latitude: "-27.399600"
  },
  {
    Country: "Australia",
    Pcode: "4053",
    Locality: "MCDOWALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.993100",
    Latitude: "-27.381800"
  },
  {
    Country: "Australia",
    Pcode: "4053",
    Locality: "MITCHELTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.970200",
    Latitude: "-27.410400"
  },
  {
    Country: "Australia",
    Pcode: "4053",
    Locality: "STAFFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.010400",
    Latitude: "-27.410500"
  },
  {
    Country: "Australia",
    Pcode: "4053",
    Locality: "STAFFORD DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4053",
    Locality: "STAFFORD HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.008600",
    Latitude: "-27.397200"
  },
  {
    Country: "Australia",
    Pcode: "4054",
    Locality: "ARANA HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.954800",
    Latitude: "-27.392700"
  },
  {
    Country: "Australia",
    Pcode: "4054",
    Locality: "KEPERRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.952600",
    Latitude: "-27.412500"
  },
  {
    Country: "Australia",
    Pcode: "4055",
    Locality: "BUNYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.936700",
    Latitude: "-27.365300"
  },
  {
    Country: "Australia",
    Pcode: "4055",
    Locality: "FERNY GROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.927900",
    Latitude: "-27.403200"
  },
  {
    Country: "Australia",
    Pcode: "4055",
    Locality: "FERNY HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.923700",
    Latitude: "-27.391700"
  },
  {
    Country: "Australia",
    Pcode: "4055",
    Locality: "FERNY HILLS DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4055",
    Locality: "UPPER KEDRON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.919600",
    Latitude: "-27.419100"
  },
  {
    Country: "Australia",
    Pcode: "4059",
    Locality: "KELVIN GROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.012000",
    Latitude: "-27.448400"
  },
  {
    Country: "Australia",
    Pcode: "4059",
    Locality: "KELVIN GROVE DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4059",
    Locality: "RED HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.000900",
    Latitude: "-27.452200"
  },
  {
    Country: "Australia",
    Pcode: "4060",
    Locality: "ASHGROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.982700",
    Latitude: "-27.442900"
  },
  {
    Country: "Australia",
    Pcode: "4060",
    Locality: "ASHGROVE EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4061",
    Locality: "THE GAP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.945100",
    Latitude: "-27.443200"
  },
  {
    Country: "Australia",
    Pcode: "4064",
    Locality: "MILTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.005200",
    Latitude: "-27.469100"
  },
  {
    Country: "Australia",
    Pcode: "4064",
    Locality: "MILTON BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4064",
    Locality: "PADDINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.000100",
    Latitude: "-27.460700"
  },
  {
    Country: "Australia",
    Pcode: "4065",
    Locality: "BARDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.977500",
    Latitude: "-27.459500"
  },
  {
    Country: "Australia",
    Pcode: "4066",
    Locality: "AUCHENFLOWER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.994900",
    Latitude: "-27.474600"
  },
  {
    Country: "Australia",
    Pcode: "4066",
    Locality: "MOUNT COOT-THA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.949600",
    Latitude: "-27.476100"
  },
  {
    Country: "Australia",
    Pcode: "4066",
    Locality: "TOOWONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.984600",
    Latitude: "-27.479100"
  },
  {
    Country: "Australia",
    Pcode: "4066",
    Locality: "TOOWONG BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4066",
    Locality: "TOOWONG DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4067",
    Locality: "ST LUCIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.006600",
    Latitude: "-27.499400"
  },
  {
    Country: "Australia",
    Pcode: "4067",
    Locality: "ST LUCIA SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4068",
    Locality: "CHELMER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.975200",
    Latitude: "-27.512400"
  },
  {
    Country: "Australia",
    Pcode: "4068",
    Locality: "INDOOROOPILLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.982500",
    Latitude: "-27.504500"
  },
  {
    Country: "Australia",
    Pcode: "4068",
    Locality: "INDOOROOPILLY CENTRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4068",
    Locality: "TARINGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.985400",
    Latitude: "-27.495100"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "BROOKFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.901300",
    Latitude: "-27.483800"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "CHAPEL HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.949700",
    Latitude: "-27.499400"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "FIG TREE POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.961800",
    Latitude: "-27.529200"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "KENMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.937600",
    Latitude: "-27.515600"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "KENMORE DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "KENMORE EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "KENMORE HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.927600",
    Latitude: "-27.491200"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "PINJARRA HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.911400",
    Latitude: "-27.538100"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "PULLENVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.892000",
    Latitude: "-27.518400"
  },
  {
    Country: "Australia",
    Pcode: "4069",
    Locality: "UPPER BROOKFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.842400",
    Latitude: "-27.473100"
  },
  {
    Country: "Australia",
    Pcode: "4070",
    Locality: "ANSTEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.866600",
    Latitude: "-27.538000"
  },
  {
    Country: "Australia",
    Pcode: "4070",
    Locality: "BELLBOWRIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.886500",
    Latitude: "-27.558000"
  },
  {
    Country: "Australia",
    Pcode: "4070",
    Locality: "MOGGILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.887900",
    Latitude: "-27.583300"
  },
  {
    Country: "Australia",
    Pcode: "4072",
    Locality: "UNIVERSITY OF QUEENSLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4073",
    Locality: "SEVENTEEN MILE ROCKS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.955800",
    Latitude: "-27.548100"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "YATALA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.216700",
    Latitude: "-27.751400"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "YATALA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4208",
    Locality: "GILBERTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.271100",
    Latitude: "-27.740100"
  },
  {
    Country: "Australia",
    Pcode: "4208",
    Locality: "JACOBS WELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.355300",
    Latitude: "-27.782600"
  },
  {
    Country: "Australia",
    Pcode: "4208",
    Locality: "KINGSHOLME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.232800",
    Latitude: "-27.828200"
  },
  {
    Country: "Australia",
    Pcode: "4208",
    Locality: "NORWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.308200",
    Latitude: "-27.777500"
  },
  {
    Country: "Australia",
    Pcode: "4208",
    Locality: "ORMEAU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.252000",
    Latitude: "-27.774800"
  },
  {
    Country: "Australia",
    Pcode: "4208",
    Locality: "ORMEAU HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.233700",
    Latitude: "-27.800600"
  },
  {
    Country: "Australia",
    Pcode: "4209",
    Locality: "COOMERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.329600",
    Latitude: "-27.848700"
  },
  {
    Country: "Australia",
    Pcode: "4209",
    Locality: "PIMPAMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.319000",
    Latitude: "-27.811100"
  },
  {
    Country: "Australia",
    Pcode: "4209",
    Locality: "UPPER COOMERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.282800",
    Latitude: "-27.880100"
  },
  {
    Country: "Australia",
    Pcode: "4209",
    Locality: "WILLOW VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.261200",
    Latitude: "-27.848800"
  },
  {
    Country: "Australia",
    Pcode: "4210",
    Locality: "GUANABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.236900",
    Latitude: "-27.938200"
  },
  {
    Country: "Australia",
    Pcode: "4210",
    Locality: "MAUDSLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.279300",
    Latitude: "-27.940800"
  },
  {
    Country: "Australia",
    Pcode: "4210",
    Locality: "OXENFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.303300",
    Latitude: "-27.904700"
  },
  {
    Country: "Australia",
    Pcode: "4210",
    Locality: "STUDIO VILLAGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4210",
    Locality: "WONGAWALLAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.231300",
    Latitude: "-27.886600"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "ADVANCETOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.271100",
    Latitude: "-28.065700"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "BEECHMONT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.193200",
    Latitude: "-28.131100"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "BINNA BURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.181600",
    Latitude: "-28.198600"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "CARRARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.369000",
    Latitude: "-28.020400"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "CLAGIRABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.244700",
    Latitude: "-27.984200"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "GAVEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.335300",
    Latitude: "-27.957000"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "GILSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.312800",
    Latitude: "-28.028900"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "HIGHLAND PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.333100",
    Latitude: "-28.014500"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "LOWER BEECHMONT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.237400",
    Latitude: "-28.049400"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "MOUNT NATHAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.273100",
    Latitude: "-27.981000"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "NATURAL BRIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.224700",
    Latitude: "-28.226400"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "NERANG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.312500",
    Latitude: "-27.977500"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "NERANG BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "NERANG DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "NUMINBAH VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.243800",
    Latitude: "-28.137400"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "PACIFIC PINES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.313600",
    Latitude: "-27.940100"
  },
  {
    Country: "Australia",
    Pcode: "4211",
    Locality: "SOUTHERN LAMINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.129800",
    Latitude: "-28.294300"
  },
  {
    Country: "Australia",
    Pcode: "4212",
    Locality: "HELENSVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.334900",
    Latitude: "-27.908700"
  },
  {
    Country: "Australia",
    Pcode: "4212",
    Locality: "HELENSVALE TOWN CENTRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4212",
    Locality: "HOPE ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.359800",
    Latitude: "-27.867500"
  },
  {
    Country: "Australia",
    Pcode: "4212",
    Locality: "SANCTUARY COVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4213",
    Locality: "AUSTINVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.311300",
    Latitude: "-28.161500"
  },
  {
    Country: "Australia",
    Pcode: "4213",
    Locality: "BONOGIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.355400",
    Latitude: "-28.144900"
  },
  {
    Country: "Australia",
    Pcode: "4213",
    Locality: "MUDGEERABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.358600",
    Latitude: "-28.083100"
  },
  {
    Country: "Australia",
    Pcode: "4213",
    Locality: "NERANWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.312200",
    Latitude: "-28.118600"
  },
  {
    Country: "Australia",
    Pcode: "4213",
    Locality: "SPRINGBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.277400",
    Latitude: "-28.188400"
  },
  {
    Country: "Australia",
    Pcode: "4213",
    Locality: "TALLAI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.326600",
    Latitude: "-28.062400"
  },
  {
    Country: "Australia",
    Pcode: "4213",
    Locality: "WORONGARY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.333100",
    Latitude: "-28.041000"
  },
  {
    Country: "Australia",
    Pcode: "4214",
    Locality: "ARUNDEL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.364300",
    Latitude: "-27.940300"
  },
  {
    Country: "Australia",
    Pcode: "4214",
    Locality: "ARUNDEL DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4214",
    Locality: "ASHMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.372400",
    Latitude: "-27.988000"
  },
  {
    Country: "Australia",
    Pcode: "4214",
    Locality: "ASHMORE CITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4214",
    Locality: "MOLENDINAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.362400",
    Latitude: "-27.974000"
  },
  {
    Country: "Australia",
    Pcode: "4214",
    Locality: "PARKWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.365500",
    Latitude: "-27.954700"
  },
  {
    Country: "Australia",
    Pcode: "4215",
    Locality: "AUSTRALIA FAIR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4215",
    Locality: "CHIRN PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4215",
    Locality: "LABRADOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.400800",
    Latitude: "-27.945600"
  },
  {
    Country: "Australia",
    Pcode: "4215",
    Locality: "SOUTHPORT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.401000",
    Latitude: "-27.974800"
  },
  {
    Country: "Australia",
    Pcode: "4215",
    Locality: "SOUTHPORT BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4215",
    Locality: "SOUTHPORT PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4216",
    Locality: "BIGGERA WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.391900",
    Latitude: "-27.930400"
  },
  {
    Country: "Australia",
    Pcode: "4216",
    Locality: "COOMBABAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.369100",
    Latitude: "-27.910200"
  },
  {
    Country: "Australia",
    Pcode: "4216",
    Locality: "HOLLYWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.399800",
    Latitude: "-27.895600"
  },
  {
    Country: "Australia",
    Pcode: "4216",
    Locality: "PARADISE POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.394600",
    Latitude: "-27.878900"
  },
  {
    Country: "Australia",
    Pcode: "4216",
    Locality: "RUNAWAY BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.401000",
    Latitude: "-27.912600"
  },
  {
    Country: "Australia",
    Pcode: "4216",
    Locality: "SOUTH STRADBROKE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.426100",
    Latitude: "-27.837400"
  },
  {
    Country: "Australia",
    Pcode: "4217",
    Locality: "BENOWA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.388000",
    Latitude: "-28.010600"
  },
  {
    Country: "Australia",
    Pcode: "4217",
    Locality: "BUNDALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.405800",
    Latitude: "-28.009100"
  },
  {
    Country: "Australia",
    Pcode: "4217",
    Locality: "BUNDALL BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4217",
    Locality: "BUNDALL DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4217",
    Locality: "CHEVRON ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4217",
    Locality: "GOLD COAST MC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "OBUM OBUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.654400",
    Latitude: "-27.923500"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "TAROME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.443300",
    Latitude: "-28.006100"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "TEVIOTVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.677100",
    Latitude: "-27.948900"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "ALLANDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.756600",
    Latitude: "-28.006200"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "ANTHONY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.688300",
    Latitude: "-27.880700"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "BLANTYRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.677300",
    Latitude: "-27.901600"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "BOONAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.696100",
    Latitude: "-27.991500"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "BUNBURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.689000",
    Latitude: "-28.063400"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "BUNJURGEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.618200",
    Latitude: "-28.039700"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "BURNETT CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.598000",
    Latitude: "-28.263700"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "CANNON CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "SCENIC RIM",
    Category: "Delivery Area",
    Longitude: "152.734300",
    Latitude: "-28.075100"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "CARNEYS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.531700",
    Latitude: "-28.211600"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "COOCHIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.647100",
    Latitude: "-28.118300"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "COULSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.737300",
    Latitude: "-27.965700"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "CROFTBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.591500",
    Latitude: "-28.149500"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "DUGANDAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.665000",
    Latitude: "-28.021300"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "FRENCHES CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.633200",
    Latitude: "-28.022700"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "HOYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.680600",
    Latitude: "-27.974300"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "KENTS POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.660400",
    Latitude: "-27.987700"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "MAROON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.689600",
    Latitude: "-28.191400"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "MILBONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.732200",
    Latitude: "-27.881600"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "MILFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.711600",
    Latitude: "-28.031200"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "MOUNT ALFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.606000",
    Latitude: "-28.074600"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "MOUNT FRENCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.626400",
    Latitude: "-27.998000"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "ROADVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.704100",
    Latitude: "-27.920600"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "TEMPLIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.645900",
    Latitude: "-27.971100"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "WALLACES CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.656600",
    Latitude: "-28.065800"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "WOOLOOMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.771900",
    Latitude: "-27.873900"
  },
  {
    Country: "Australia",
    Pcode: "4310",
    Locality: "WYARALONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.806200",
    Latitude: "-27.927800"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "ATKINSONS DAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.427600",
    Latitude: "-27.413900"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "BRIGHTVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.503700",
    Latitude: "-27.497700"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "BUARABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.291700",
    Latitude: "-27.374600"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "BUARABA SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.246600",
    Latitude: "-27.420300"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "CHURCHABLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.369100",
    Latitude: "-27.425600"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "CLARENDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.525300",
    Latitude: "-27.429600"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "COOLANA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.561200",
    Latitude: "-27.513800"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "COOMINYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.469700",
    Latitude: "-27.375300"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "LOCKYER WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.403700",
    Latitude: "-27.452800"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "LOWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.570200",
    Latitude: "-27.471100"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "MINDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.546700",
    Latitude: "-27.548100"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "MOUNT TARAMPA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.492800",
    Latitude: "-27.465600"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "PATRICK ESTATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.582600",
    Latitude: "-27.419800"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "PRENZLAU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.516700",
    Latitude: "-27.530700"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "RIFLE RANGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.531800",
    Latitude: "-27.457100"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "TARAMPA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.543900",
    Latitude: "-27.495500"
  },
  {
    Country: "Australia",
    Pcode: "4311",
    Locality: "WIVENHOE HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.566900",
    Latitude: "-27.367200"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "BRYDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.589400",
    Latitude: "-27.254000"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "CABOONBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.469000",
    Latitude: "-27.149100"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "COAL CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.436200",
    Latitude: "-27.177800"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "CROSSDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.562600",
    Latitude: "-27.181400"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "ESK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.420900",
    Latitude: "-27.257500"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "ESKDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.208200",
    Latitude: "-27.138100"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "GLEN ESK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.470000",
    Latitude: "-27.261800"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "MOOMBRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.477200",
    Latitude: "-27.307600"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "MOUNT BYRON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.676400",
    Latitude: "-27.153000"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "MOUNT HALLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.388500",
    Latitude: "-27.325600"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "MURRUMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.462200",
    Latitude: "-27.204100"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "REDBANK CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.275800",
    Latitude: "-27.294600"
  },
  {
    Country: "Australia",
    Pcode: "4312",
    Locality: "SOMERSET DAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.539700",
    Latitude: "-27.123300"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "BIARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.333500",
    Latitude: "-27.196400"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "BRAEMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.381300",
    Latitude: "-27.037300"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "COOEEIMBARDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.502500",
    Latitude: "-27.107400"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "CRESSBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.431600",
    Latitude: "-27.072100"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "FULHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.471000",
    Latitude: "-27.033500"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "GREGORS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.430400",
    Latitude: "-26.983400"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "IVORY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.313900",
    Latitude: "-27.057700"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "LOWER CRESSBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.475600",
    Latitude: "-27.078900"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "MOUNT BEPPO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.425500",
    Latitude: "-27.125200"
  },
  {
    Country: "Australia",
    Pcode: "4313",
    Locality: "OTTABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.384000",
    Latitude: "-27.150400"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "MONARTO SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.130100",
    Latitude: "-35.182200"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "MURRAY BRIDGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "PO BAGS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "MYPOLONGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.315900",
    Latitude: "-35.030800"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "PALLAMANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.217400",
    Latitude: "-35.046100"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "PETWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.960200",
    Latitude: "-35.086300"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "ROCKLEIGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.102700",
    Latitude: "-35.000300"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "ROCKY GULLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.198800",
    Latitude: "-35.110300"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "TEPKO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.205500",
    Latitude: "-34.974700"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "WALL FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.305400",
    Latitude: "-34.986300"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "WHITE HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.211200",
    Latitude: "-35.138100"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "WOODLANE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.330300",
    Latitude: "-35.001100"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "ZADOWS LANDING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.293900",
    Latitude: "-34.968700"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "ANGAS PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.969100",
    Latitude: "-35.334300"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "BELVIDERE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.955400",
    Latitude: "-35.287800"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "BLETCHLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.981600",
    Latitude: "-35.251800"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "FINNISS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.838600",
    Latitude: "-35.381500"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "GEMMELLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.894600",
    Latitude: "-35.178600"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "HARTLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.050100",
    Latitude: "-35.206000"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "HIGHLAND VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.926700",
    Latitude: "-35.179300"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "LAKE PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.046700",
    Latitude: "-35.363200"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "LANGHORNE CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.063000",
    Latitude: "-35.300200"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "MOUNT OBSERVATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.752900",
    Latitude: "-35.349300"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "MULGUNDAWA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.239600",
    Latitude: "-35.316400"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "NALPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.321000",
    Latitude: "-35.370100"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "RED CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.979700",
    Latitude: "-35.153900"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "SALEM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.024200",
    Latitude: "-35.150800"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "SANDERGROVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.854900",
    Latitude: "-35.324900"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "STRATHALBYN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.868900",
    Latitude: "-35.250400"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "TOOPERANG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.706900",
    Latitude: "-35.380300"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "WILLYAROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.920800",
    Latitude: "-35.280300"
  },
  {
    Country: "Australia",
    Pcode: "5255",
    Locality: "WOODCHESTER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.968600",
    Latitude: "-35.208800"
  },
  {
    Country: "Australia",
    Pcode: "5256",
    Locality: "CLAYTON BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.917700",
    Latitude: "-35.474200"
  },
  {
    Country: "Australia",
    Pcode: "5256",
    Locality: "MILANG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.951600",
    Latitude: "-35.400500"
  },
  {
    Country: "Australia",
    Pcode: "5256",
    Locality: "NURRAGI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.906900",
    Latitude: "-35.354400"
  },
  {
    Country: "Australia",
    Pcode: "5256",
    Locality: "POINT STURT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.983000",
    Latitude: "-35.492900"
  },
  {
    Country: "Australia",
    Pcode: "5256",
    Locality: "TOLDEROL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.106600",
    Latitude: "-35.370300"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "ASHVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.432800",
    Latitude: "-35.568600"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "JERVOIS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.391500",
    Latitude: "-35.265600"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "KEPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.424400",
    Latitude: "-35.153800"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "LAKE ALBERT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.279900",
    Latitude: "-35.618000"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "LAKE ALEXANDRINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.165600",
    Latitude: "-35.457600"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "MALINONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.508900",
    Latitude: "-35.505500"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "NARRUNG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.145300",
    Latitude: "-35.574300"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "NATURI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.562400",
    Latitude: "-35.206100"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "POINT MCLEAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "POLTALLOCH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.304700",
    Latitude: "-35.533000"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "TAILEM BEND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "PO BAGS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "WELLINGTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.317400",
    Latitude: "-35.323100"
  },
  {
    Country: "Australia",
    Pcode: "5259",
    Locality: "WELLINGTON EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.381800",
    Latitude: "-35.415700"
  },
  {
    Country: "Australia",
    Pcode: "5260",
    Locality: "ELWOMPLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.564400",
    Latitude: "-35.315600"
  },
  {
    Country: "Australia",
    Pcode: "5260",
    Locality: "TAILEM BEND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.536300",
    Latitude: "-35.281100"
  },
  {
    Country: "Australia",
    Pcode: "5261",
    Locality: "COOKE PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.522300",
    Latitude: "-35.402900"
  },
  {
    Country: "Australia",
    Pcode: "5261",
    Locality: "COOMANDOOK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.714700",
    Latitude: "-35.445900"
  },
  {
    Country: "Australia",
    Pcode: "5261",
    Locality: "CULBURRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.933700",
    Latitude: "-35.809300"
  },
  {
    Country: "Australia",
    Pcode: "5261",
    Locality: "KI KI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.809500",
    Latitude: "-35.577100"
  },
  {
    Country: "Australia",
    Pcode: "5261",
    Locality: "YUMALI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.695900",
    Latitude: "-35.536100"
  },
  {
    Country: "Australia",
    Pcode: "5262",
    Locality: "BINNUM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.861500",
    Latitude: "-36.781300"
  },
  {
    Country: "Australia",
    Pcode: "5262",
    Locality: "FRANCES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.880500",
    Latitude: "-36.704300"
  },
  {
    Country: "Australia",
    Pcode: "5262",
    Locality: "HYNAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.895200",
    Latitude: "-36.953000"
  },
  {
    Country: "Australia",
    Pcode: "5262",
    Locality: "KYBYBOLITE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.901000",
    Latitude: "-36.857400"
  },
  {
    Country: "Australia",
    Pcode: "5263",
    Locality: "COONAWARRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.882200",
    Latitude: "-37.289800"
  },
  {
    Country: "Australia",
    Pcode: "5264",
    Locality: "COORONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.382400",
    Latitude: "-35.857000"
  },
  {
    Country: "Australia",
    Pcode: "5264",
    Locality: "MENINGIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.387700",
    Latitude: "-35.726300"
  },
  {
    Country: "Australia",
    Pcode: "5264",
    Locality: "MENINGIE EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.563500",
    Latitude: "-35.633900"
  },
  {
    Country: "Australia",
    Pcode: "5264",
    Locality: "MENINGIE WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.176600",
    Latitude: "-35.664500"
  },
  {
    Country: "Australia",
    Pcode: "5264",
    Locality: "SALT CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.665400",
    Latitude: "-36.090500"
  },
  {
    Country: "Australia",
    Pcode: "5264",
    Locality: "WALTOWA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.426300",
    Latitude: "-35.627700"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "NENE VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.527200",
    Latitude: "-37.971800"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "OB FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.772200",
    Latitude: "-37.887300"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "PELICAN POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.426900",
    Latitude: "-37.923900"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "PORT MACDONNELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.693800",
    Latitude: "-38.038900"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "RACECOURSE BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.748500",
    Latitude: "-38.056700"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "SQUARE MILE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.802600",
    Latitude: "-37.871200"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "SUTTONTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.754400",
    Latitude: "-37.783300"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "WANDILO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.696000",
    Latitude: "-37.757500"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "WORROLONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.833600",
    Latitude: "-37.805600"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "WYE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.896800",
    Latitude: "-38.014600"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "YAHL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.848000",
    Latitude: "-37.882600"
  },
  {
    Country: "Australia",
    Pcode: "5301",
    Locality: "CARCUMA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.064700",
    Latitude: "-35.587500"
  },
  {
    Country: "Australia",
    Pcode: "5301",
    Locality: "GERANIUM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.164900",
    Latitude: "-35.470200"
  },
  {
    Country: "Australia",
    Pcode: "5301",
    Locality: "JABUK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.069200",
    Latitude: "-35.408300"
  },
  {
    Country: "Australia",
    Pcode: "5301",
    Locality: "MOORLANDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.681800",
    Latitude: "-35.287400"
  },
  {
    Country: "Australia",
    Pcode: "5301",
    Locality: "NETHERTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.962000",
    Latitude: "-35.516400"
  },
  {
    Country: "Australia",
    Pcode: "5301",
    Locality: "PARRAKIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.288100",
    Latitude: "-35.445100"
  },
  {
    Country: "Australia",
    Pcode: "5301",
    Locality: "PEAKE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.953600",
    Latitude: "-35.376000"
  },
  {
    Country: "Australia",
    Pcode: "5301",
    Locality: "SHERLOCK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.792400",
    Latitude: "-35.321000"
  },
  {
    Country: "Australia",
    Pcode: "5301",
    Locality: "WILKAWATT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5302",
    Locality: "LAMEROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.451700",
    Latitude: "-35.278400"
  },
  {
    Country: "Australia",
    Pcode: "5302",
    Locality: "NGARKAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.608400",
    Latitude: "-35.747600"
  },
  {
    Country: "Australia",
    Pcode: "5303",
    Locality: "PARILLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.703900",
    Latitude: "-35.322000"
  },
  {
    Country: "Australia",
    Pcode: "5304",
    Locality: "KARTE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.786500",
    Latitude: "-35.046800"
  },
  {
    Country: "Australia",
    Pcode: "5304",
    Locality: "KRINGIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.739500",
    Latitude: "-34.906600"
  },
  {
    Country: "Australia",
    Pcode: "5304",
    Locality: "PEEBINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.889700",
    Latitude: "-34.925400"
  },
  {
    Country: "Australia",
    Pcode: "5304",
    Locality: "PINNAROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.859100",
    Latitude: "-35.321600"
  },
  {
    Country: "Australia",
    Pcode: "5306",
    Locality: "WYNARKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.718500",
    Latitude: "-35.129000"
  },
  {
    Country: "Australia",
    Pcode: "5307",
    Locality: "KAROONDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.918500",
    Latitude: "-35.152500"
  },
  {
    Country: "Australia",
    Pcode: "5307",
    Locality: "MARAMA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.163900",
    Latitude: "-35.183900"
  },
  {
    Country: "Australia",
    Pcode: "5308",
    Locality: "COPEVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.911400",
    Latitude: "-34.803200"
  },
  {
    Country: "Australia",
    Pcode: "5308",
    Locality: "GALGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.937600",
    Latitude: "-34.657800"
  },
  {
    Country: "Australia",
    Pcode: "5308",
    Locality: "MANTUNG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.129300",
    Latitude: "-34.604500"
  },
  {
    Country: "Australia",
    Pcode: "5308",
    Locality: "MERCUNDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.031900",
    Latitude: "-34.641500"
  },
  {
    Country: "Australia",
    Pcode: "5308",
    Locality: "PERPONDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.871000",
    Latitude: "-34.947900"
  },
  {
    Country: "Australia",
    Pcode: "5309",
    Locality: "BORRIKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.027300",
    Latitude: "-34.957900"
  },
  {
    Country: "Australia",
    Pcode: "5309",
    Locality: "HALIDON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.159000",
    Latitude: "-34.875200"
  },
  {
    Country: "Australia",
    Pcode: "5309",
    Locality: "MINDARIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.154300",
    Latitude: "-34.769500"
  },
  {
    Country: "Australia",
    Pcode: "5309",
    Locality: "SANDALWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.259200",
    Latitude: "-34.988500"
  },
  {
    Country: "Australia",
    Pcode: "5310",
    Locality: "CALIPH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.277800",
    Latitude: "-34.629100"
  },
  {
    Country: "Australia",
    Pcode: "5310",
    Locality: "WANBI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.295500",
    Latitude: "-34.782100"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "ALAWOONA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.465400",
    Latitude: "-34.748000"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "BILLIATT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.538400",
    Latitude: "-34.944800"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "BUGLE HUT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.739700",
    Latitude: "-34.406900"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "MAGGEA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.044900",
    Latitude: "-34.495800"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "MALPAS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.619000",
    Latitude: "-34.754200"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "MERIBAH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.876700",
    Latitude: "-34.764600"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "PARUNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.738800",
    Latitude: "-34.730700"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "SCHELL WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.465200",
    Latitude: "-34.829500"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "TALDRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.884500",
    Latitude: "-34.421800"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "TAPLAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.890500",
    Latitude: "-34.590000"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "VEITCH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.465500",
    Latitude: "-34.669500"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "WOODLEIGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.720600",
    Latitude: "-34.562000"
  },
  {
    Country: "Australia",
    Pcode: "5311",
    Locality: "WUNKAR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.272100",
    Latitude: "-34.490500"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "BEATTY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.442100",
    Latitude: "-34.019000"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "BEAUMONTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.677900",
    Latitude: "-34.045000"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "BRENDA PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.685500",
    Latitude: "-34.075000"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "BUNDEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.271000",
    Latitude: "-33.887400"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "EBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.578200",
    Latitude: "-34.051000"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "LINDLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.613500",
    Latitude: "-33.885900"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "MAUDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.441800",
    Latitude: "-33.886600"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "MORGAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.631200",
    Latitude: "-34.093500"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "MORPHETTS FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.686400",
    Latitude: "-34.053800"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "MURBKO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.685600",
    Latitude: "-34.206800"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "NORTH WEST BEND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.675400",
    Latitude: "-34.036600"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "STUART",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.778000",
    Latitude: "-33.955200"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "WESTONS FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.918900",
    Latitude: "-33.883300"
  },
  {
    Country: "Australia",
    Pcode: "5320",
    Locality: "WOMBATS REST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.670700",
    Latitude: "-34.116700"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "MONGOLATA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.095100",
    Latitude: "-33.573400"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "NORTH BOOBOROWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.821800",
    Latitude: "-33.506300"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "OAKVALE STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.828200",
    Latitude: "-32.951600"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "OLD KOOMOOLOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.754100",
    Latitude: "-33.564100"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "PARCOOLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.102700",
    Latitude: "-33.644500"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "PINE VALLEY STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.291800",
    Latitude: "-33.322100"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "QUONDONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.428200",
    Latitude: "-33.057800"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "STURT VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.913500",
    Latitude: "-33.238100"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "WARNES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.528400",
    Latitude: "-33.553600"
  },
  {
    Country: "Australia",
    Pcode: "5418",
    Locality: "COLLINSVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.228000",
    Latitude: "-33.403000"
  },
  {
    Country: "Australia",
    Pcode: "5418",
    Locality: "MOUNT BRYAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.911000",
    Latitude: "-33.547000"
  },
  {
    Country: "Australia",
    Pcode: "5419",
    Locality: "CANOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.747000",
    Latitude: "-33.373800"
  },
  {
    Country: "Australia",
    Pcode: "5419",
    Locality: "HALLETT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.878200",
    Latitude: "-33.399400"
  },
  {
    Country: "Australia",
    Pcode: "5419",
    Locality: "MOUNT BRYAN EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.026400",
    Latitude: "-33.423100"
  },
  {
    Country: "Australia",
    Pcode: "5419",
    Locality: "PINE CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.267900",
    Latitude: "-33.211600"
  },
  {
    Country: "Australia",
    Pcode: "5419",
    Locality: "ULOOLOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.943200",
    Latitude: "-33.309900"
  },
  {
    Country: "Australia",
    Pcode: "5419",
    Locality: "WILLALO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.781800",
    Latitude: "-33.424300"
  },
  {
    Country: "Australia",
    Pcode: "5419",
    Locality: "WONNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.063500",
    Latitude: "-33.255700"
  },
  {
    Country: "Australia",
    Pcode: "5420",
    Locality: "CANOWIE BELT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.781200",
    Latitude: "-33.186300"
  },
  {
    Country: "Australia",
    Pcode: "5420",
    Locality: "WHYTE YARCOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.867400",
    Latitude: "-33.245000"
  },
  {
    Country: "Australia",
    Pcode: "5421",
    Locality: "FRANKLYN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.103900",
    Latitude: "-33.149400"
  },
  {
    Country: "Australia",
    Pcode: "5421",
    Locality: "TEROWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.927500",
    Latitude: "-33.152500"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "CAVENAGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.995600",
    Latitude: "-32.669400"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "DAWSON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.995600",
    Latitude: "-32.817400"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "ERSKINE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.821900",
    Latitude: "-32.681200"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "HARDY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.268000",
    Latitude: "-32.993800"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "MANNANARIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.647000",
    Latitude: "-33.033200"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "MINVALARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.804900",
    Latitude: "-32.855000"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "OODLA WIRRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.057500",
    Latitude: "-32.880500"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "PARATOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.227400",
    Latitude: "-32.669600"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "PARNAROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.104000",
    Latitude: "-32.994300"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "PETERBOROUGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.853400",
    Latitude: "-32.969300"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "SUNNYBRAE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.794300",
    Latitude: "-33.021600"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "UCOLTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.959000",
    Latitude: "-32.994600"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "WAROONEE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.227200",
    Latitude: "-32.503600"
  },
  {
    Country: "Australia",
    Pcode: "5422",
    Locality: "YATINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.680600",
    Latitude: "-32.941700"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "AMYTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.349500",
    Latitude: "-32.607300"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "BLACK ROCK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.668800",
    Latitude: "-32.851600"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "COOMOOROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.501400",
    Latitude: "-32.640200"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "EURELIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.552800",
    Latitude: "-32.522000"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "HAMMOND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.355900",
    Latitude: "-32.496400"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "JOHNBURGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.671300",
    Latitude: "-32.504900"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "MINBURRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.756200",
    Latitude: "-32.535700"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "MINBURRA PLAIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.995600",
    Latitude: "-32.504400"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "MINBURRA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.020200",
    Latitude: "-32.275700"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "MORCHARD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.467700",
    Latitude: "-32.704900"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "NORTH HILLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.849400",
    Latitude: "-32.383600"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "ORROROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.687900",
    Latitude: "-32.696200"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "PEKINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.536200",
    Latitude: "-32.837300"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "TARCOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.539400",
    Latitude: "-32.943500"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "WALLOWAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.591300",
    Latitude: "-32.629200"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "WILLOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.348200",
    Latitude: "-32.695600"
  },
  {
    Country: "Australia",
    Pcode: "5431",
    Locality: "YALPARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.814900",
    Latitude: "-32.504700"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "BARATTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.190200",
    Latitude: "-31.946700"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "BELTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.711900",
    Latitude: "-32.267600"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "BIBLIANDO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.083900",
    Latitude: "-31.861800"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "CARRIETON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.549700",
    Latitude: "-32.407900"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "CRADOCK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.597000",
    Latitude: "-32.125500"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "HOLOWILIENA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.911400",
    Latitude: "-31.910600"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "HOLOWILIENA SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.929500",
    Latitude: "-32.019600"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "MOOCKRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.398400",
    Latitude: "-32.342400"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "THREE CREEKS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.759000",
    Latitude: "-32.003900"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "WILCOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.812000",
    Latitude: "-32.099000"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "WILLIPPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.094000",
    Latitude: "-31.750700"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "WITCHITIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.964200",
    Latitude: "-32.155000"
  },
  {
    Country: "Australia",
    Pcode: "5432",
    Locality: "YANYARRIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.556200",
    Latitude: "-32.340400"
  },
  {
    Country: "Australia",
    Pcode: "5433",
    Locality: "BRUCE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.197800",
    Latitude: "-32.475000"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "RISDON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.970200",
    Latitude: "-33.194800"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "RISDON PARK SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.973800",
    Latitude: "-33.217000"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "SOLOMONTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.031900",
    Latitude: "-33.181700"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "TELOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.102900",
    Latitude: "-33.036100"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "WARNERTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.138100",
    Latitude: "-33.242300"
  },
  {
    Country: "Australia",
    Pcode: "5550",
    Locality: "BEAUFORT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.190900",
    Latitude: "-34.081600"
  },
  {
    Country: "Australia",
    Pcode: "5550",
    Locality: "BOWMANS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.276500",
    Latitude: "-34.154600"
  },
  {
    Country: "Australia",
    Pcode: "5550",
    Locality: "INKERMAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.260100",
    Latitude: "-34.284900"
  },
  {
    Country: "Australia",
    Pcode: "5550",
    Locality: "KALLORA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.305800",
    Latitude: "-34.226400"
  },
  {
    Country: "Australia",
    Pcode: "5550",
    Locality: "NANTAWARRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.219000",
    Latitude: "-34.012000"
  },
  {
    Country: "Australia",
    Pcode: "5550",
    Locality: "PORT WAKEFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.173000",
    Latitude: "-34.183700"
  },
  {
    Country: "Australia",
    Pcode: "5550",
    Locality: "PROOF RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.201800",
    Latitude: "-34.265900"
  },
  {
    Country: "Australia",
    Pcode: "5550",
    Locality: "SOUTH HUMMOCKS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.114000",
    Latitude: "-34.033800"
  },
  {
    Country: "Australia",
    Pcode: "5552",
    Locality: "KAINTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.966700",
    Latitude: "-34.146500"
  },
  {
    Country: "Australia",
    Pcode: "5552",
    Locality: "KULPARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.042800",
    Latitude: "-34.072300"
  },
  {
    Country: "Australia",
    Pcode: "5552",
    Locality: "MELTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.975500",
    Latitude: "-34.072600"
  },
  {
    Country: "Australia",
    Pcode: "5552",
    Locality: "PASKEVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.886100",
    Latitude: "-34.036300"
  },
  {
    Country: "Australia",
    Pcode: "5552",
    Locality: "PORT ARTHUR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.079900",
    Latitude: "-34.118800"
  },
  {
    Country: "Australia",
    Pcode: "5552",
    Locality: "SUNNYVALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.826100",
    Latitude: "-34.147200"
  },
  {
    Country: "Australia",
    Pcode: "5552",
    Locality: "THRINGTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.823200",
    Latitude: "-33.996600"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "BOORS PLAIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.716200",
    Latitude: "-34.052300"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "CUNLIFFE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.765200",
    Latitude: "-34.062400"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "JERICHO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.684300",
    Latitude: "-33.965900"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "JERUSALEM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.713900",
    Latitude: "-33.975900"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "KADINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.740300",
    Latitude: "-33.957700"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "MATTA FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.706800",
    Latitude: "-33.972400"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "NEW TOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.696700",
    Latitude: "-33.955700"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "THOMAS PLAIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.928200",
    Latitude: "-33.947500"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "WALLAROO MINES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.695300",
    Latitude: "-33.967700"
  },
  {
    Country: "Australia",
    Pcode: "5554",
    Locality: "WILLAMULKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.833300",
    Latitude: "-33.922300"
  },
  {
    Country: "Australia",
    Pcode: "5555",
    Locality: "ALFORD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.846000",
    Latitude: "-33.828500"
  },
  {
    Country: "Australia",
    Pcode: "5555",
    Locality: "COLLINSFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.212100",
    Latitude: "-33.600800"
  },
  {
    Country: "Australia",
    Pcode: "5555",
    Locality: "HOPE GAP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.199000",
    Latitude: "-33.658800"
  },
  {
    Country: "Australia",
    Pcode: "5555",
    Locality: "LAKE VIEW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.260700",
    Latitude: "-33.644400"
  },
  {
    Country: "Australia",
    Pcode: "5555",
    Locality: "MUNDOORA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.099800",
    Latitude: "-33.631100"
  },
  {
    Country: "Australia",
    Pcode: "5555",
    Locality: "TICKERA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.738600",
    Latitude: "-33.787000"
  },
  {
    Country: "Australia",
    Pcode: "5556",
    Locality: "NORTH BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.629700",
    Latitude: "-33.897700"
  },
  {
    Country: "Australia",
    Pcode: "5556",
    Locality: "WALLAROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.636700",
    Latitude: "-33.940300"
  },
  {
    Country: "Australia",
    Pcode: "5556",
    Locality: "WALLAROO PLAIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.669300",
    Latitude: "-33.863300"
  },
  {
    Country: "Australia",
    Pcode: "5556",
    Locality: "WARBURTO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.585800",
    Latitude: "-34.001900"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "AGERY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.699200",
    Latitude: "-34.144300"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "CROSS ROADS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.610100",
    Latitude: "-34.059300"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "EAST MOONTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.626600",
    Latitude: "-34.080800"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "HAMLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.601200",
    Latitude: "-34.083400"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "KOOROONA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.582900",
    Latitude: "-34.085000"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "MOONTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.582400",
    Latitude: "-34.069300"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "MOONTA BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.569900",
    Latitude: "-34.049800"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "MOONTA MINES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.604200",
    Latitude: "-34.090800"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "NALYAPPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.542200",
    Latitude: "-34.165400"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "NORTH MOONTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.594200",
    Latitude: "-34.044300"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "NORTH YELTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.631200",
    Latitude: "-34.062500"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "PARAMATTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.629600",
    Latitude: "-34.041100"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "PORT HUGHES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.558400",
    Latitude: "-34.081500"
  },
  {
    Country: "Australia",
    Pcode: "5558",
    Locality: "YELTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.618600",
    Latitude: "-34.064900"
  },
  {
    Country: "Australia",
    Pcode: "5560",
    Locality: "BUTE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.999200",
    Latitude: "-33.839300"
  },
  {
    Country: "Australia",
    Pcode: "5560",
    Locality: "NINNES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.022300",
    Latitude: "-33.965600"
  },
  {
    Country: "Australia",
    Pcode: "5570",
    Locality: "CLINTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.001400",
    Latitude: "-34.212800"
  },
  {
    Country: "Australia",
    Pcode: "5570",
    Locality: "CLINTON CENTRE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.919600",
    Latitude: "-34.227300"
  },
  {
    Country: "Australia",
    Pcode: "5570",
    Locality: "PRICE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.994500",
    Latitude: "-34.307400"
  },
  {
    Country: "Australia",
    Pcode: "5570",
    Locality: "WINULTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.897000",
    Latitude: "-34.271100"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "ARDROSSAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.903300",
    Latitude: "-34.438500"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "BLACK POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.884600",
    Latitude: "-34.617400"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "CUNNINGHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.816000",
    Latitude: "-34.435500"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "DOWLINGVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.930700",
    Latitude: "-34.347600"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "JAMES WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.894000",
    Latitude: "-34.486900"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "PETERSVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.843900",
    Latitude: "-34.345500"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "PINE POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.861700",
    Latitude: "-34.582100"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "ELLISTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.911400",
    Latitude: "-33.615000"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "KAPPAWANTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.321900",
    Latitude: "-33.645300"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "MOUNT JOY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.060200",
    Latitude: "-33.515700"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "MOONFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.023000",
    Latitude: "-24.779100"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "MULGILDIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.138300",
    Latitude: "-24.974100"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "MUNGUNGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.142900",
    Latitude: "-24.752400"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "RAWBELLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.735800",
    Latitude: "-24.981000"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "SELENE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.130500",
    Latitude: "-25.009600"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "SPLINTER CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.249800",
    Latitude: "-24.948200"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "TELLEBANG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.259400",
    Latitude: "-25.041300"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "THREE MOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.136200",
    Latitude: "-24.915100"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "VENTNOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.303600",
    Latitude: "-24.906600"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "YARROL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.382100",
    Latitude: "-24.979200"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "ALDERSHOT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.668200",
    Latitude: "-25.462300"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "ANTIGUA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.591300",
    Latitude: "-25.635500"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "BAUPLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.613500",
    Latitude: "-25.818500"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "BAUPLE FOREST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.678700",
    Latitude: "-25.875600"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "BEAVER ROCK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.801600",
    Latitude: "-25.519900"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "BIDWILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.709500",
    Latitude: "-25.609100"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "BOONOOROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.889100",
    Latitude: "-25.649900"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "BOONOOROO PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.830700",
    Latitude: "-25.597000"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "DUCKINWILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.522200",
    Latitude: "-25.403900"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "DUNDATHU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.702800",
    Latitude: "-25.471000"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "DUNMORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.539000",
    Latitude: "-25.505700"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "FERNEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.621600",
    Latitude: "-25.611400"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "GLENORCHY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.640700",
    Latitude: "-25.620300"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "GOOTCHIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.581200",
    Latitude: "-25.877600"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "GRAHAMS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.594100",
    Latitude: "-25.572700"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "GRANVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.730100",
    Latitude: "-25.537300"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "GUNDIAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.529400",
    Latitude: "-25.843100"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "ISLAND PLANTATION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.725400",
    Latitude: "-25.493800"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "MAAROOM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.864600",
    Latitude: "-25.588600"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "MAGNOLIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.689100",
    Latitude: "-25.662800"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "MARYBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.693800",
    Latitude: "-25.525300"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "MARYBOROUGH DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "MARYBOROUGH WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.662400",
    Latitude: "-25.499500"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "MOUNT URAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.401800",
    Latitude: "-25.802400"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "MUNGAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.595800",
    Latitude: "-25.599900"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "NETHERBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.527900",
    Latitude: "-25.777700"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "OAKHURST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.620800",
    Latitude: "-25.510000"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "OWANYILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.618400",
    Latitude: "-25.674000"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "PALLAS STREET MARYBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "PILERWA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.563900",
    Latitude: "-25.603000"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "PIONEERS REST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.568900",
    Latitude: "-25.667500"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "POONA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.905300",
    Latitude: "-25.716700"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "PRAWLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.773100",
    Latitude: "-25.489600"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "ST HELENS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "FRASER COAST",
    Category: "Delivery Area",
    Longitude: "152.708200",
    Latitude: "-25.489100"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "ST MARY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.482300",
    Latitude: "-25.700300"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "TALEGALLA WEIR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.674200",
    Latitude: "-25.780700"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "TANDORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.823500",
    Latitude: "-25.458800"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "TEDDINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.666100",
    Latitude: "-25.624200"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "THE DIMONDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.855100",
    Latitude: "-25.534700"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "THINOOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.464700",
    Latitude: "-25.592500"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "TIARO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.598700",
    Latitude: "-25.728700"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "TINANA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.659500",
    Latitude: "-25.545000"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "TINANA SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.668100",
    Latitude: "-25.594900"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "TINNANBAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.960900",
    Latitude: "-25.784200"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "TUAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.875600",
    Latitude: "-25.682600"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "TUAN FOREST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.808400",
    Latitude: "-25.723700"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "WALKERS POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.746500",
    Latitude: "-25.508600"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "YENGARIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.587200",
    Latitude: "-25.541700"
  },
  {
    Country: "Australia",
    Pcode: "4650",
    Locality: "YERRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.534400",
    Latitude: "-25.587600"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "BOORAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.890700",
    Latitude: "-25.354500"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "BUNYA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.839200",
    Latitude: "-25.380700"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "CRAIGNISH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.724400",
    Latitude: "-25.279300"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "DUNDOWRAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.774500",
    Latitude: "-25.296500"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "WONBAH FOREST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.613800",
    Latitude: "-24.994800"
  },
  {
    Country: "Australia",
    Pcode: "4673",
    Locality: "MIARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.162400",
    Latitude: "-24.676200"
  },
  {
    Country: "Australia",
    Pcode: "4673",
    Locality: "WATERLOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.997700",
    Latitude: "-24.744100"
  },
  {
    Country: "Australia",
    Pcode: "4673",
    Locality: "YANDARAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.094600",
    Latitude: "-24.717300"
  },
  {
    Country: "Australia",
    Pcode: "4674",
    Locality: "BAFFLE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.904200",
    Latitude: "-24.473200"
  },
  {
    Country: "Australia",
    Pcode: "4674",
    Locality: "BERAJONDO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.788500",
    Latitude: "-24.635500"
  },
  {
    Country: "Australia",
    Pcode: "4674",
    Locality: "DEEPWATER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.951800",
    Latitude: "-24.374700"
  },
  {
    Country: "Australia",
    Pcode: "4674",
    Locality: "EULEILAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.867200",
    Latitude: "-24.498600"
  },
  {
    Country: "Australia",
    Pcode: "4674",
    Locality: "MOUNT MARIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.803600",
    Latitude: "-24.522300"
  },
  {
    Country: "Australia",
    Pcode: "4674",
    Locality: "OYSTER CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.858800",
    Latitude: "-24.418900"
  },
  {
    Country: "Australia",
    Pcode: "4674",
    Locality: "ROSEDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.872500",
    Latitude: "-24.679500"
  },
  {
    Country: "Australia",
    Pcode: "4674",
    Locality: "RULES BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.029100",
    Latitude: "-24.492700"
  },
  {
    Country: "Australia",
    Pcode: "4674",
    Locality: "TAUNTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.792000",
    Latitude: "-24.414100"
  },
  {
    Country: "Australia",
    Pcode: "4676",
    Locality: "GINDORAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.553100",
    Latitude: "-24.600500"
  },
  {
    Country: "Australia",
    Pcode: "4676",
    Locality: "LOWMEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.699400",
    Latitude: "-24.600600"
  },
  {
    Country: "Australia",
    Pcode: "4677",
    Locality: "AGNES WATER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.906800",
    Latitude: "-24.224400"
  },
  {
    Country: "Australia",
    Pcode: "4677",
    Locality: "CAPTAIN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.770400",
    Latitude: "-24.281500"
  },
  {
    Country: "Australia",
    Pcode: "4677",
    Locality: "COLOSSEUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.558500",
    Latitude: "-24.431700"
  },
  {
    Country: "Australia",
    Pcode: "4677",
    Locality: "EURIMBULA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.718200",
    Latitude: "-24.109500"
  },
  {
    Country: "Australia",
    Pcode: "4677",
    Locality: "MIRIAM VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.561500",
    Latitude: "-24.330600"
  },
  {
    Country: "Australia",
    Pcode: "4677",
    Locality: "MOUNT TOM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.685700",
    Latitude: "-24.328300"
  },
  {
    Country: "Australia",
    Pcode: "4677",
    Locality: "ROUND HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.873400",
    Latitude: "-24.298700"
  },
  {
    Country: "Australia",
    Pcode: "4677",
    Locality: "SEVENTEEN SEVENTY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.892200",
    Latitude: "-24.164500"
  },
  {
    Country: "Australia",
    Pcode: "4678",
    Locality: "BOROREN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.497500",
    Latitude: "-24.245300"
  },
  {
    Country: "Australia",
    Pcode: "4678",
    Locality: "FORESHORES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.500800",
    Latitude: "-24.085300"
  },
  {
    Country: "Australia",
    Pcode: "4678",
    Locality: "RODDS BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.621400",
    Latitude: "-24.098400"
  },
  {
    Country: "Australia",
    Pcode: "4678",
    Locality: "TURKEY BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.651900",
    Latitude: "-24.081700"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "BARNEY POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.266500",
    Latitude: "-23.841100"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "BEECHER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.209600",
    Latitude: "-23.906400"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "BENARABY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.312500",
    Latitude: "-24.049100"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "BOYNE ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.323900",
    Latitude: "-23.924200"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "BOYNE VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.246700",
    Latitude: "-24.477200"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "BOYNEDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.283300",
    Latitude: "-24.211800"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "BUILYAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "BURUA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.208800",
    Latitude: "-23.949100"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "BYELLEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.196900",
    Latitude: "-23.872500"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "CALLEMONDAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.216200",
    Latitude: "-23.831400"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "CALLIOPE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.199200",
    Latitude: "-24.037600"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "CLINTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.214700",
    Latitude: "-23.877300"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "CURTIS ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.143800",
    Latitude: "-23.606900"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "DIGLUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.073800",
    Latitude: "-24.243900"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "GLADSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "GLADSTONE BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "GLADSTONE CENTRAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.246100",
    Latitude: "-23.841400"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "GLADSTONE DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "GLADSTONE HARBOUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.297800",
    Latitude: "-23.803900"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "GLEN EDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.265100",
    Latitude: "-23.901800"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "HERON ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "IVERAGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.387300",
    Latitude: "-24.113400"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "KIN KORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.246500",
    Latitude: "-23.878000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "KIRKWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.244600",
    Latitude: "-23.907100"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "MOUNT ALMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.870600",
    Latitude: "-24.106300"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "NEW AUCKLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.233800",
    Latitude: "-23.885300"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "O'CONNELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.269700",
    Latitude: "-23.929400"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "RIVER RANCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.154600",
    Latitude: "-23.975600"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "SOUTH END",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "SOUTH GLADSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.267300",
    Latitude: "-23.863900"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "SOUTH TREES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.301800",
    Latitude: "-23.877000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "SUN VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.259100",
    Latitude: "-23.874800"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "TABLELANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "GLADSTONE",
    Category: "Delivery Area",
    Longitude: "150.894600",
    Latitude: "-24.343900"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "TANNUM SANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.380900",
    Latitude: "-23.991300"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "TARAGOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.230800",
    Latitude: "-24.107800"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "TELINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.252900",
    Latitude: "-23.889300"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "TOOLOOA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.269000",
    Latitude: "-23.884600"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "UBOBO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "WEST GLADSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.246000",
    Latitude: "-23.857700"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "WEST STOWE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.137500",
    Latitude: "-23.911000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "WOODERSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.096700",
    Latitude: "-24.080000"
  },
  {
    Country: "Australia",
    Pcode: "4680",
    Locality: "WURDONG HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.275500",
    Latitude: "-23.964100"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "BONDOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.683500",
    Latitude: "-23.177600"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "BUNGUNDARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.628500",
    Latitude: "-23.044400"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "BYFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.656200",
    Latitude: "-22.888000"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "CAUSEWAY LAKE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.796600",
    Latitude: "-23.197900"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "COBRABALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.622200",
    Latitude: "-23.139400"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "COOEE BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.786100",
    Latitude: "-23.141700"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "FARNBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.769900",
    Latitude: "-23.017000"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "HIDDEN VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "LIVINGSTONE SHIRE",
    Category: "Delivery Area",
    Longitude: "150.720000",
    Latitude: "-23.172200"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "INVERNESS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.725800",
    Latitude: "-23.116800"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "KINKA BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.792400",
    Latitude: "-23.224400"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "LAKE MARY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.619300",
    Latitude: "-23.106800"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "LAMMERMOOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.769600",
    Latitude: "-23.163100"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "MARYVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "ROCKHAMPTON",
    Category: "Delivery Area",
    Longitude: "150.622300",
    Latitude: "-22.983500"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "MEIKLEVILLE HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.746000",
    Latitude: "-23.114800"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "MULAMBIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.806700",
    Latitude: "-23.189000"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "MULARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.621300",
    Latitude: "-23.204700"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "PACIFIC HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.734700",
    Latitude: "-23.098700"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "ROSSLYN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.802700",
    Latitude: "-23.170900"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "STOCKYARD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "ROCKHAMPTON",
    Category: "Delivery Area",
    Longitude: "150.780000",
    Latitude: "-22.851300"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "TANBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.735200",
    Latitude: "-23.221300"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "TARANGANBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.754400",
    Latitude: "-23.147900"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "TAROOMBALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.752100",
    Latitude: "-23.173500"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "WEERRIBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.676000",
    Latitude: "-22.793100"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "WOODBURY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.719800",
    Latitude: "-22.988900"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "YEPPOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.767000",
    Latitude: "-23.125700"
  },
  {
    Country: "Australia",
    Pcode: "4704",
    Locality: "WATTLEBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.474300",
    Latitude: "-23.119700"
  },
  {
    Country: "Australia",
    Pcode: "4704",
    Locality: "YAAMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.356200",
    Latitude: "-23.138300"
  },
  {
    Country: "Australia",
    Pcode: "4705",
    Locality: "CLARKE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.312200",
    Latitude: "-22.725700"
  },
  {
    Country: "Australia",
    Pcode: "4705",
    Locality: "LOTUS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.109700",
    Latitude: "-22.346700"
  },
  {
    Country: "Australia",
    Pcode: "4705",
    Locality: "MACKENZIE RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.918100",
    Latitude: "-23.006100"
  },
  {
    Country: "Australia",
    Pcode: "4705",
    Locality: "MARLBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.813200",
    Latitude: "-22.790600"
  },
  {
    Country: "Australia",
    Pcode: "4705",
    Locality: "MOUNT GARDINER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.512000",
    Latitude: "-22.975200"
  },
  {
    Country: "Australia",
    Pcode: "4706",
    Locality: "OGMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.714900",
    Latitude: "-22.622900"
  },
  {
    Country: "Australia",
    Pcode: "4707",
    Locality: "COLLAROY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.262700",
    Latitude: "-22.107100"
  },
  {
    Country: "Australia",
    Pcode: "4707",
    Locality: "ST LAWRENCE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.511000",
    Latitude: "-22.366900"
  },
  {
    Country: "Australia",
    Pcode: "4707",
    Locality: "THE PERCY GROUP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.063100",
    Latitude: "-21.867300"
  },
  {
    Country: "Australia",
    Pcode: "4709",
    Locality: "TIERI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.352700",
    Latitude: "-23.031700"
  },
  {
    Country: "Australia",
    Pcode: "4710",
    Locality: "EMU PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.812900",
    Latitude: "-23.249200"
  },
  {
    Country: "Australia",
    Pcode: "4710",
    Locality: "ZILZIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.813200",
    Latitude: "-23.294500"
  },
  {
    Country: "Australia",
    Pcode: "4711",
    Locality: "GLENDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.456000",
    Latitude: "-23.244300"
  },
  {
    Country: "Australia",
    Pcode: "4711",
    Locality: "GLENLEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.470500",
    Latitude: "-23.288700"
  },
  {
    Country: "Australia",
    Pcode: "4712",
    Locality: "DUARINGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.673700",
    Latitude: "-23.693800"
  },
  {
    Country: "Australia",
    Pcode: "4713",
    Locality: "WOORABINDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.430900",
    Latitude: "-24.152100"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "BAREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.389300",
    Latitude: "-23.618900"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "BOULDER CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.313500",
    Latitude: "-23.670900"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "FLETCHER CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.426300",
    Latitude: "-23.786900"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "HAMILTON CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.397500",
    Latitude: "-23.672900"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "HORSE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.380300",
    Latitude: "-23.669100"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "JOHNSONS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.411800",
    Latitude: "-23.625000"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "LEYDENS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.408900",
    Latitude: "-23.610600"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "LIMESTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.437600",
    Latitude: "-23.657000"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "MOONGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.399800",
    Latitude: "-23.603100"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "MOUNT MORGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.395900",
    Latitude: "-23.640600"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "NINE MILE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.433600",
    Latitude: "-23.724000"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "OAKEY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.325800",
    Latitude: "-23.732500"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "STRUCK OIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.444700",
    Latitude: "-23.618500"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "THE MINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.372800",
    Latitude: "-23.632500"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "TROTTER CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.372400",
    Latitude: "-23.693600"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "WALMUL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.363900",
    Latitude: "-23.719200"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "WALTERHALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.383700",
    Latitude: "-23.627300"
  },
  {
    Country: "Australia",
    Pcode: "4714",
    Locality: "WURA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.331800",
    Latitude: "-23.786900"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "BILOELA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.517600",
    Latitude: "-24.390100"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "CALLIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.494000",
    Latitude: "-24.252000"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "CASTLE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.332700",
    Latitude: "-24.772000"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "DAKENBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.497600",
    Latitude: "-24.339500"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "DUMGREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.657000",
    Latitude: "-24.192700"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "GREYCLIFFE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.294800",
    Latitude: "-24.232200"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "MOUNT MURCHISON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.583800",
    Latitude: "-24.331000"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "ORANGE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.357600",
    Latitude: "-24.334800"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MOUNT PLEASANT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MACKAY",
    Category: "Delivery Area",
    Longitude: "149.159200",
    Latitude: "-21.119200"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MUNBURA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.139800",
    Latitude: "-21.372400"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "NINDAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.128500",
    Latitude: "-21.066100"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "NORTH MACKAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.182800",
    Latitude: "-21.121300"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "OORALEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.143800",
    Latitude: "-21.180500"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "PAGET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.171100",
    Latitude: "-21.191500"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "RACECOURSE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.139100",
    Latitude: "-21.153700"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "RICHMOND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MACKAY",
    Category: "Delivery Area",
    Longitude: "149.137200",
    Latitude: "-21.089900"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "ROSELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.145900",
    Latitude: "-21.238300"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "RURAL VIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.158300",
    Latitude: "-21.061600"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "SANDIFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.106300",
    Latitude: "-21.246600"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "SLADE POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.212700",
    Latitude: "-21.079800"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "SOUTH MACKAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.177500",
    Latitude: "-21.164900"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "TE KOWAI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.115000",
    Latitude: "-21.176000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "THE LEAP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.027600",
    Latitude: "-21.078700"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "WEST MACKAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.162000",
    Latitude: "-21.150500"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "BALL BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.990400",
    Latitude: "-20.929100"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "BRIGHTLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.891000",
    Latitude: "-21.274000"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "CLAIRVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.454200",
    Latitude: "-22.041400"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "COPPABELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.319500",
    Latitude: "-21.918700"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "EPSOM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.825100",
    Latitude: "-21.494200"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "ETON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.943700",
    Latitude: "-21.307900"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "EUNGELLA HINTERLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.417100",
    Latitude: "-20.924300"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "FARLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.089800",
    Latitude: "-21.095400"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "GARGETT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.753500",
    Latitude: "-21.152900"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "HAMPDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.951300",
    Latitude: "-21.085000"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "HAZLEDEAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.908400",
    Latitude: "-21.355000"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "KINCHANT DAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.894600",
    Latitude: "-21.214000"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "KUTTABUL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.904100",
    Latitude: "-21.023900"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "MACKAY MC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "MOUNT CHARLTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.744800",
    Latitude: "-21.025500"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "MOUNT OSSA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.840700",
    Latitude: "-20.977300"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "MOUNT PELION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.797800",
    Latitude: "-20.941200"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "NORTH ETON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.950600",
    Latitude: "-21.233000"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "OAKENDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.028700",
    Latitude: "-21.332500"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "ORKABIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "OWENS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.705600",
    Latitude: "-21.100300"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "PINNACLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.677500",
    Latitude: "-21.217500"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "PLEYSTOWE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.021600",
    Latitude: "-21.155200"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "SEAFORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.934400",
    Latitude: "-20.924600"
  },
  {
    Country: "Australia",
    Pcode: "4741",
    Locality: "YALBOROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.658200",
    Latitude: "-20.828500"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "BURTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.118300",
    Latitude: "-21.605000"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "EAGLEFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.786300",
    Latitude: "-21.492800"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "ELPHINSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.208400",
    Latitude: "-21.517000"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "HAIL CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.397200",
    Latitude: "-21.434400"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "KEMMIS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.346100",
    Latitude: "-21.690400"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "MOUNT BRITTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.630400",
    Latitude: "-21.397200"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "NEBO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.822400",
    Latitude: "-21.654900"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "OXFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.684600",
    Latitude: "-21.896700"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "STRATHFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.548900",
    Latitude: "-21.746100"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "TURRAWULLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.176400",
    Latitude: "-21.141500"
  },
  {
    Country: "Australia",
    Pcode: "4742",
    Locality: "VALKYRIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.630200",
    Latitude: "-22.192300"
  },
  {
    Country: "Australia",
    Pcode: "4743",
    Locality: "GLENDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.119000",
    Latitude: "-21.353000"
  },
  {
    Country: "Australia",
    Pcode: "4743",
    Locality: "SUTTOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.915600",
    Latitude: "-21.317900"
  },
  {
    Country: "Australia",
    Pcode: "4744",
    Locality: "MORANBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.880800",
    Latitude: "-21.915300"
  },
  {
    Country: "Australia",
    Pcode: "4745",
    Locality: "DYSART",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.318300",
    Latitude: "-22.525600"
  },
  {
    Country: "Australia",
    Pcode: "4746",
    Locality: "MAY DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.833800",
    Latitude: "-22.606800"
  },
  {
    Country: "Australia",
    Pcode: "4746",
    Locality: "MIDDLEMOUNT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.605800",
    Latitude: "-22.854600"
  },
  {
    Country: "Australia",
    Pcode: "4750",
    Locality: "BUCASIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.150400",
    Latitude: "-21.035800"
  },
  {
    Country: "Australia",
    Pcode: "4750",
    Locality: "SHOAL POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.141700",
    Latitude: "-21.014600"
  },
  {
    Country: "Australia",
    Pcode: "4751",
    Locality: "GREENMOUNT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MACKAY",
    Category: "Delivery Area",
    Longitude: "149.037900",
    Latitude: "-21.184800"
  },
  {
    Country: "Australia",
    Pcode: "4751",
    Locality: "PALMYRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.076200",
    Latitude: "-21.204400"
  },
  {
    Country: "Australia",
    Pcode: "4751",
    Locality: "VICTORIA PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.010800",
    Latitude: "-21.220200"
  },
  {
    Country: "Australia",
    Pcode: "4751",
    Locality: "WALKERSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.066000",
    Latitude: "-21.157900"
  },
  {
    Country: "Australia",
    Pcode: "4753",
    Locality: "DEVEREUX CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.890600",
    Latitude: "-21.105300"
  },
  {
    Country: "Australia",
    Pcode: "4753",
    Locality: "MARIAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.948700",
    Latitude: "-21.161700"
  },
  {
    Country: "Australia",
    Pcode: "4754",
    Locality: "BENHOLME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.807100",
    Latitude: "-21.160200"
  },
  {
    Country: "Australia",
    Pcode: "4754",
    Locality: "DOWS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.758500",
    Latitude: "-21.094600"
  },
  {
    Country: "Australia",
    Pcode: "4754",
    Locality: "MIA MIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.828900",
    Latitude: "-21.222600"
  },
  {
    Country: "Australia",
    Pcode: "4812",
    Locality: "GULLIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.776500",
    Latitude: "-19.285500"
  },
  {
    Country: "Australia",
    Pcode: "4812",
    Locality: "HERMIT PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.806000",
    Latitude: "-19.283600"
  },
  {
    Country: "Australia",
    Pcode: "4812",
    Locality: "HYDE PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.797900",
    Latitude: "-19.277300"
  },
  {
    Country: "Australia",
    Pcode: "4812",
    Locality: "HYDE PARK CASTLETOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4812",
    Locality: "MUNDINGBURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.786100",
    Latitude: "-19.298100"
  },
  {
    Country: "Australia",
    Pcode: "4812",
    Locality: "MYSTERTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.793800",
    Latitude: "-19.287200"
  },
  {
    Country: "Australia",
    Pcode: "4812",
    Locality: "PIMLICO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.788300",
    Latitude: "-19.283600"
  },
  {
    Country: "Australia",
    Pcode: "4812",
    Locality: "ROSSLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.801600",
    Latitude: "-19.297500"
  },
  {
    Country: "Australia",
    Pcode: "4813",
    Locality: "TOWNSVILLE MILPO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "AITKENVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.769200",
    Latitude: "-19.300000"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "ANNANDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.783900",
    Latitude: "-19.309600"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "CRANBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.751000",
    Latitude: "-19.306400"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "DOUGLAS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.748400",
    Latitude: "-19.323200"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "GARBUTT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.767200",
    Latitude: "-19.259200"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "GARBUTT EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "HEATLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.754500",
    Latitude: "-19.292500"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "MOUNT LOUISA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.738300",
    Latitude: "-19.276700"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "MURRAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.790900",
    Latitude: "-19.335300"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "THURINGOWA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4814",
    Locality: "VINCENT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.764500",
    Latitude: "-19.284500"
  },
  {
    Country: "Australia",
    Pcode: "4815",
    Locality: "CONDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.716600",
    Latitude: "-19.329800"
  },
  {
    Country: "Australia",
    Pcode: "4815",
    Locality: "GRANITE VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.638000",
    Latitude: "-19.604800"
  },
  {
    Country: "Australia",
    Pcode: "4815",
    Locality: "GUMLOW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.685500",
    Latitude: "-19.355900"
  },
  {
    Country: "Australia",
    Pcode: "4815",
    Locality: "KELSO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.717800",
    Latitude: "-19.387900"
  },
  {
    Country: "Australia",
    Pcode: "4815",
    Locality: "PINNACLES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.667900",
    Latitude: "-19.429800"
  },
  {
    Country: "Australia",
    Pcode: "4815",
    Locality: "RASMUSSEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.722200",
    Latitude: "-19.352300"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "ALLIGATOR CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOWNSVILLE",
    Category: "Delivery Area",
    Longitude: "146.928700",
    Latitude: "-19.415800"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "BALGAL BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.412500",
    Latitude: "-19.037800"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "BARRINGHA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.780500",
    Latitude: "-19.528500"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "BROOKHILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.867000",
    Latitude: "-19.441700"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "BUCHANAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "CALCIUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.780800",
    Latitude: "-19.660500"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "CARRUCHAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.918800",
    Latitude: "-18.245200"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "CLEMANT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.436600",
    Latitude: "-19.121100"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "CRYSTAL CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.294600",
    Latitude: "-19.022000"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "CUNGULLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.111600",
    Latitude: "-19.406000"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "ELLERBECK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.994900",
    Latitude: "-18.239400"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "GREENVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.010700",
    Latitude: "-18.925400"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "HOMESTEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.605400",
    Latitude: "-20.351200"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "JULAGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.882900",
    Latitude: "-19.371200"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "KENNEDY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.965400",
    Latitude: "-18.191800"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "MACROSSAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "MAJORS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.016500",
    Latitude: "-19.643000"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "MALPAS-TRENTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.166100",
    Latitude: "-19.947700"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "MINGELA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.658500",
    Latitude: "-19.880300"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "MOUNT ELLIOT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.960200",
    Latitude: "-19.507000"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "MUTARNEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.321300",
    Latitude: "-18.972100"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "NOME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.941900",
    Latitude: "-19.359000"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "PALM ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.560400",
    Latitude: "-18.695700"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "PALUMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.963200",
    Latitude: "-19.138700"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "PENTLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.303400",
    Latitude: "-20.875900"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "RAVENSWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.909100",
    Latitude: "-20.218100"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "REID RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.794700",
    Latitude: "-19.743600"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "ROLLINGSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.381400",
    Latitude: "-19.042700"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "ROSS RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.787600",
    Latitude: "-19.478200"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "SAVANNAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.851000",
    Latitude: "-19.212300"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "SELLHEIM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "TOOMULLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.463300",
    Latitude: "-19.080100"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "TOONPAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.872000",
    Latitude: "-19.506800"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "TORRENS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.117900",
    Latitude: "-21.262300"
  },
  {
    Country: "Australia",
    Pcode: "4816",
    Locality: "WOODSTOCK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.870900",
    Latitude: "-19.636000"
  },
  {
    Country: "Australia",
    Pcode: "4817",
    Locality: "ALICE RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.619100",
    Latitude: "-19.350100"
  },
  {
    Country: "Australia",
    Pcode: "4817",
    Locality: "BOHLE PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.675300",
    Latitude: "-19.291500"
  },
  {
    Country: "Australia",
    Pcode: "4817",
    Locality: "HERVEY RANGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.467400",
    Latitude: "-19.479600"
  },
  {
    Country: "Australia",
    Pcode: "4817",
    Locality: "KIRWAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.716400",
    Latitude: "-19.303700"
  },
  {
    Country: "Australia",
    Pcode: "4817",
    Locality: "RANGEWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.627400",
    Latitude: "-19.299800"
  },
  {
    Country: "Australia",
    Pcode: "4817",
    Locality: "THURINGOWA CENTRAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.728300",
    Latitude: "-19.312800"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "BEACH HOLM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.647800",
    Latitude: "-19.207200"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "BLACK RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.600600",
    Latitude: "-19.267100"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "FORREST BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.281600",
    Latitude: "-18.721900"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "GAIRLOCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.175800",
    Latitude: "-18.604400"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "GARRAWALT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.869000",
    Latitude: "-18.519600"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "HALIFAX",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.321500",
    Latitude: "-18.581900"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "HAWKINS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.099000",
    Latitude: "-18.572700"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "HELENS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.131400",
    Latitude: "-18.789100"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "INGHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.152800",
    Latitude: "-18.653500"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "LANNERCOST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.962100",
    Latitude: "-18.613100"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "LONG POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "HINCHINBROOK",
    Category: "Delivery Area",
    Longitude: "145.980600",
    Latitude: "-18.518200"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "LUCINDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.336900",
    Latitude: "-18.532100"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "MACKNADE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.245500",
    Latitude: "-18.540200"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "MOUNT FOX",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.851000",
    Latitude: "-18.815800"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "ORIENT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.243300",
    Latitude: "-18.803400"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "PEACOCK SIDING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.010800",
    Latitude: "-18.692200"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "TAYLORS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.329500",
    Latitude: "-18.639000"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "TOOBANNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.142800",
    Latitude: "-18.714600"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "TREBONNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.078000",
    Latitude: "-18.643500"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "UPPER STONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.956300",
    Latitude: "-18.778100"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "VALLEY OF LAGOONS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.418300",
    Latitude: "-18.758400"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "VICTORIA PLANTATION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.201000",
    Latitude: "-18.644300"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "WALLAMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.851500",
    Latitude: "-18.681100"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "WHARPS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.066500",
    Latitude: "-18.728100"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "YURUGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.104800",
    Latitude: "-18.865100"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "BINGIL BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.092000",
    Latitude: "-17.826200"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "CARMOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.046100",
    Latitude: "-17.947800"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "DJIRU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.077500",
    Latitude: "-17.844400"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "DUNK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.155400",
    Latitude: "-17.972000"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "GARNERS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.098500",
    Latitude: "-17.814400"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "MIDGEREE BAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.073400",
    Latitude: "-17.806500"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "MISSION BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.106300",
    Latitude: "-17.861400"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "SOUTH MISSION BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.084900",
    Latitude: "-17.955300"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "TAM O'SHANTER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.062600",
    Latitude: "-17.912100"
  },
  {
    Country: "Australia",
    Pcode: "4852",
    Locality: "WONGALING BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.094300",
    Latitude: "-17.904100"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "BILYANA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.958300",
    Latitude: "-18.115400"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "BIRKALLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.951600",
    Latitude: "-17.912200"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "BULGUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.930400",
    Latitude: "-17.893400"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "CARDSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.715600",
    Latitude: "-17.840900"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "DINGO POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.815700",
    Latitude: "-17.923400"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "DJARAWONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.978200",
    Latitude: "-17.861900"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "EAST FELUGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.995000",
    Latitude: "-17.897300"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "EURAMO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.897500",
    Latitude: "-18.003200"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "FELUGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.956900",
    Latitude: "-17.875300"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "HULL HEADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.065600",
    Latitude: "-17.991700"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "JARRA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.884200",
    Latitude: "-17.965200"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "KOOROOMOOL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.662100",
    Latitude: "-18.020800"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "LOWER TULLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.013900",
    Latitude: "-17.976800"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "MERRYBURN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.975700",
    Latitude: "-17.914900"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "MIDGENOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.961000",
    Latitude: "-17.896000"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "MOUNT MACKAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.999700",
    Latitude: "-17.935600"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "MUNRO PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.771400",
    Latitude: "-17.964300"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "MURRAY UPPER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.791400",
    Latitude: "-18.121000"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "MURRIGAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.897700",
    Latitude: "-18.050300"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "ROCKINGHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.997900",
    Latitude: "-18.046600"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "SILKY OAK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.953400",
    Latitude: "-17.973300"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "TULLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.931000",
    Latitude: "-17.931700"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "TULLY HEADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.054200",
    Latitude: "-18.013600"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "WALTER HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.823000",
    Latitude: "-17.830100"
  },
  {
    Country: "Australia",
    Pcode: "4854",
    Locality: "WARRAMI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.793600",
    Latitude: "-18.045800"
  },
  {
    Country: "Australia",
    Pcode: "4855",
    Locality: "DAVESON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.030700",
    Latitude: "-17.779300"
  },
  {
    Country: "Australia",
    Pcode: "4855",
    Locality: "EL ARISH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.005700",
    Latitude: "-17.805200"
  },
  {
    Country: "Australia",
    Pcode: "4855",
    Locality: "FRIDAY POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.002000",
    Latitude: "-17.847200"
  },
  {
    Country: "Australia",
    Pcode: "4855",
    Locality: "GRANADILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.026500",
    Latitude: "-17.867300"
  },
  {
    Country: "Australia",
    Pcode: "4855",
    Locality: "JAFFA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.994200",
    Latitude: "-17.777400"
  },
  {
    Country: "Australia",
    Pcode: "4855",
    Locality: "MAADI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.992400",
    Latitude: "-17.831600"
  },
  {
    Country: "Australia",
    Pcode: "4855",
    Locality: "MARIA CREEKS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.035200",
    Latitude: "-17.811600"
  },
  {
    Country: "Australia",
    Pcode: "4855",
    Locality: "SHELL POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.983900",
    Latitude: "-17.815600"
  },
  {
    Country: "Australia",
    Pcode: "4856",
    Locality: "GOOLBOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.029500",
    Latitude: "-17.723300"
  },
  {
    Country: "Australia",
    Pcode: "4856",
    Locality: "JAPOONVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.929200",
    Latitude: "-17.726800"
  },
  {
    Country: "Australia",
    Pcode: "4856",
    Locality: "MCCUTCHEON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.058900",
    Latitude: "-17.722700"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "LAKELAND DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "LOWER COWLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.072400",
    Latitude: "-17.695000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "LYNDHURST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.363500",
    Latitude: "-19.258000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "MACALISTER RANGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.652600",
    Latitude: "-16.748400"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "MENA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.891800",
    Latitude: "-17.671300"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "MIRRIWINNI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.924300",
    Latitude: "-17.384400"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "MORESBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.032900",
    Latitude: "-17.639500"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "MOUNT CARBINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.089200",
    Latitude: "-16.551900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "MOUNT MOLLOY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.399000",
    Latitude: "-16.703900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "MOUNT MULLIGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.867100",
    Latitude: "-16.772400"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "MOUNT SURPRISE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.393500",
    Latitude: "-18.227400"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "NORTHHEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.156400",
    Latitude: "-18.726900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "NYCHUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.513400",
    Latitude: "-16.783100"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "PETFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.913700",
    Latitude: "-17.372800"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "PORTLAND ROADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "ROOKWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.221600",
    Latitude: "-17.029900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "SANDY POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.041200",
    Latitude: "-17.659900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "SOUTHEDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.277900",
    Latitude: "-16.753800"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "SPRINGFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.493500",
    Latitude: "-17.930800"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "STOCKTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.021800",
    Latitude: "-17.574500"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "STRATHMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.736900",
    Latitude: "-17.397500"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "TALAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.893400",
    Latitude: "-18.051700"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "THORNBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.022700",
    Latitude: "-16.944000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "UTCHEE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.924900",
    Latitude: "-17.647900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "WANGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.005700",
    Latitude: "-17.574500"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "WARRUBULLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.014300",
    Latitude: "-17.679200"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "WAUGH POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.934600",
    Latitude: "-17.444000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "WOOPEN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.878100",
    Latitude: "-17.466400"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "YARRABAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.900900",
    Latitude: "-16.993700"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "BARRINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.617300",
    Latitude: "-17.236300"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "BARWIDGI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.652600",
    Latitude: "-17.672500"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "CAIRNS MC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "DANBULLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.629400",
    Latitude: "-17.143100"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "DIMBULAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.065900",
    Latitude: "-17.200600"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "FORTY MILE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.731100",
    Latitude: "-18.026800"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "GLEN RUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.417300",
    Latitude: "-18.006400"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "GUNNAWARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.014700",
    Latitude: "-17.887500"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "INNOT HOT SPRINGS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.279600",
    Latitude: "-17.793800"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "KAIRI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.545100",
    Latitude: "-17.219900"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "KIRRAMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.563100",
    Latitude: "-18.181700"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "KOOMBOOLOOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.550000",
    Latitude: "-17.814100"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "KOWROWA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "LAKE TINAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.590800",
    Latitude: "-17.217600"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "MINNAMOOLKA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.013800",
    Latitude: "-18.283300"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "MOUNT GARNET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.057300",
    Latitude: "-17.664300"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "MUNDERRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.891000",
    Latitude: "-17.663200"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "MUTCHILBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.258900",
    Latitude: "-17.171400"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "SILVER VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.262800",
    Latitude: "-17.539800"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "TINAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.530600",
    Latitude: "-17.166400"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "WAIRUNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.505200",
    Latitude: "-18.388400"
  },
  {
    Country: "Australia",
    Pcode: "4872",
    Locality: "WALKAMIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.418500",
    Latitude: "-17.137600"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "BAMBOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.392500",
    Latitude: "-16.352200"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "BONNIE DOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.418600",
    Latitude: "-16.462400"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "CAPE TRIBULATION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.420700",
    Latitude: "-16.004800"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "CASSOWARY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.426300",
    Latitude: "-16.525300"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "COOYA BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.420500",
    Latitude: "-16.444000"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "COW BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.433400",
    Latitude: "-16.220600"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "DAGMAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.222500",
    Latitude: "-16.167100"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "DAINTREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.331900",
    Latitude: "-16.257200"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "DEDIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.169300",
    Latitude: "-16.252000"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "DIWAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.428300",
    Latitude: "-16.184600"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "FINLAYVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.349200",
    Latitude: "-16.458100"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "FOREST CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.360400",
    Latitude: "-16.240600"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "KIMBERLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.447200",
    Latitude: "-16.274100"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "LOW ISLES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.565300",
    Latitude: "-16.386100"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "LOWER DAINTREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.390800",
    Latitude: "-16.285500"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "MIALLO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.372100",
    Latitude: "-16.404300"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "MOSSMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.372100",
    Latitude: "-16.455500"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "MOSSMAN GORGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.353200",
    Latitude: "-16.474300"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "PORMPURAAW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.833700",
    Latitude: "-14.740300"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "RAVENSWORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.708700",
    Latitude: "-17.289900"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "RED RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.301500",
    Latitude: "-17.433800"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "SOUTH WELLESLEY ISLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.199700",
    Latitude: "-16.994100"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "STAATEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.904800",
    Latitude: "-16.590700"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "WELLESLEY ISLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.473900",
    Latitude: "-16.610800"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "WEST WELLESLEY ISLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.150800",
    Latitude: "-16.783500"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "WROTHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.914500",
    Latitude: "-16.640700"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "YAGOONYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.883600",
    Latitude: "-15.804700"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "YARRADEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.373500",
    Latitude: "-14.660400"
  },
  {
    Country: "Australia",
    Pcode: "4895",
    Locality: "AYTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4895",
    Locality: "BLOOMFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.206300",
    Latitude: "-15.954300"
  },
  {
    Country: "Australia",
    Pcode: "4895",
    Locality: "COOKTOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.010900",
    Latitude: "-15.393300"
  },
  {
    Country: "Australia",
    Pcode: "4895",
    Locality: "DEGARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.341300",
    Latitude: "-15.947600"
  },
  {
    Country: "Australia",
    Pcode: "4895",
    Locality: "HELENVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4895",
    Locality: "HOPE VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.247600",
    Latitude: "-15.136800"
  },
  {
    Country: "Australia",
    Pcode: "4895",
    Locality: "ROSSVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.266100",
    Latitude: "-15.782400"
  },
  {
    Country: "Australia",
    Pcode: "4895",
    Locality: "STARCKE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.779700",
    Latitude: "-14.575900"
  },
  {
    Country: "Australia",
    Pcode: "4895",
    Locality: "WUJAL WUJAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.318500",
    Latitude: "-15.948300"
  },
  {
    Country: "Australia",
    Pcode: "5000",
    Locality: "ADELAIDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.600200",
    Latitude: "-34.925600"
  },
  {
    Country: "Australia",
    Pcode: "5000",
    Locality: "ADELAIDE BC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5000",
    Locality: "CITY WEST CAMPUS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5000",
    Locality: "HALIFAX STREET",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5000",
    Locality: "HUTT STREET",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5000",
    Locality: "RUNDLE MALL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5000",
    Locality: "STATION ARCADE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5000",
    Locality: "STURT STREET",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5001",
    Locality: "ADELAIDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "GPO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5006",
    Locality: "NORTH ADELAIDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.594900",
    Latitude: "-34.909000"
  },
  {
    Country: "Australia",
    Pcode: "5006",
    Locality: "NORTH ADELAIDE MELBOURNE ST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5007",
    Locality: "BOWDEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.579600",
    Latitude: "-34.903600"
  },
  {
    Country: "Australia",
    Pcode: "5007",
    Locality: "BROMPTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.575800",
    Latitude: "-34.898200"
  },
  {
    Country: "Australia",
    Pcode: "5007",
    Locality: "HINDMARSH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.570300",
    Latitude: "-34.905600"
  },
  {
    Country: "Australia",
    Pcode: "5007",
    Locality: "WELLAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.558700",
    Latitude: "-34.904000"
  },
  {
    Country: "Australia",
    Pcode: "5007",
    Locality: "WEST HINDMARSH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.563200",
    Latitude: "-34.905700"
  },
  {
    Country: "Australia",
    Pcode: "5008",
    Locality: "CROYDON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.565000",
    Latitude: "-34.896300"
  },
  {
    Country: "Australia",
    Pcode: "5008",
    Locality: "CROYDON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.566500",
    Latitude: "-34.880600"
  },
  {
    Country: "Australia",
    Pcode: "5008",
    Locality: "DEVON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.579700",
    Latitude: "-34.887600"
  },
  {
    Country: "Australia",
    Pcode: "5008",
    Locality: "DUDLEY PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.579200",
    Latitude: "-34.879900"
  },
  {
    Country: "Australia",
    Pcode: "5008",
    Locality: "RENOWN PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.579400",
    Latitude: "-34.891400"
  },
  {
    Country: "Australia",
    Pcode: "5008",
    Locality: "RIDLEYTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.570800",
    Latitude: "-34.893200"
  },
  {
    Country: "Australia",
    Pcode: "5008",
    Locality: "WEST CROYDON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.558900",
    Latitude: "-34.889000"
  },
  {
    Country: "Australia",
    Pcode: "5009",
    Locality: "ALLENBY GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.554200",
    Latitude: "-34.902800"
  },
  {
    Country: "Australia",
    Pcode: "5009",
    Locality: "BEVERLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.546300",
    Latitude: "-34.895200"
  },
  {
    Country: "Australia",
    Pcode: "5009",
    Locality: "KILKENNY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.553000",
    Latitude: "-34.878900"
  },
  {
    Country: "Australia",
    Pcode: "5010",
    Locality: "ANGLE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.560400",
    Latitude: "-34.855900"
  },
  {
    Country: "Australia",
    Pcode: "5010",
    Locality: "FERRYDEN PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.561300",
    Latitude: "-34.868600"
  },
  {
    Country: "Australia",
    Pcode: "5010",
    Locality: "REGENCY PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.573100",
    Latitude: "-34.862300"
  },
  {
    Country: "Australia",
    Pcode: "5011",
    Locality: "ST CLAIR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.535100",
    Latitude: "-34.870500"
  },
  {
    Country: "Australia",
    Pcode: "5011",
    Locality: "WOODVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.533600",
    Latitude: "-34.877000"
  },
  {
    Country: "Australia",
    Pcode: "5011",
    Locality: "WOODVILLE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.545400",
    Latitude: "-34.883000"
  },
  {
    Country: "Australia",
    Pcode: "5011",
    Locality: "WOODVILLE SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.536100",
    Latitude: "-34.888400"
  },
  {
    Country: "Australia",
    Pcode: "5011",
    Locality: "WOODVILLE WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.525000",
    Latitude: "-34.885400"
  },
  {
    Country: "Australia",
    Pcode: "5012",
    Locality: "ATHOL PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.542600",
    Latitude: "-34.856200"
  },
  {
    Country: "Australia",
    Pcode: "5012",
    Locality: "MANSFIELD PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.551700",
    Latitude: "-34.856300"
  },
  {
    Country: "Australia",
    Pcode: "5012",
    Locality: "WOODVILLE GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.552300",
    Latitude: "-34.867600"
  },
  {
    Country: "Australia",
    Pcode: "5012",
    Locality: "WOODVILLE NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.541700",
    Latitude: "-34.867500"
  },
  {
    Country: "Australia",
    Pcode: "5013",
    Locality: "GILLMAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.526900",
    Latitude: "-34.837300"
  },
  {
    Country: "Australia",
    Pcode: "5013",
    Locality: "OTTOWAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.535500",
    Latitude: "-34.846300"
  },
  {
    Country: "Australia",
    Pcode: "5013",
    Locality: "PENNINGTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.531100",
    Latitude: "-34.858500"
  },
  {
    Country: "Australia",
    Pcode: "5013",
    Locality: "ROSEWATER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.521800",
    Latitude: "-34.853300"
  },
  {
    Country: "Australia",
    Pcode: "5013",
    Locality: "ROSEWATER EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5013",
    Locality: "WINGFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.560500",
    Latitude: "-34.841200"
  },
  {
    Country: "Australia",
    Pcode: "5014",
    Locality: "ALBERT PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.522500",
    Latitude: "-34.876200"
  },
  {
    Country: "Australia",
    Pcode: "5014",
    Locality: "ALBERTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.516300",
    Latitude: "-34.860500"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "LYNTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.607600",
    Latitude: "-34.997000"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "MITCHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.621800",
    Latitude: "-34.983000"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "MITCHAM SHOPPING CENTRE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "NETHERBY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.622500",
    Latitude: "-34.970000"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "SPRINGFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.633300",
    Latitude: "-34.978700"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "TORRENS PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.613100",
    Latitude: "-34.986200"
  },
  {
    Country: "Australia",
    Pcode: "5063",
    Locality: "EASTWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.622300",
    Latitude: "-34.943100"
  },
  {
    Country: "Australia",
    Pcode: "5063",
    Locality: "FREWVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.631800",
    Latitude: "-34.948800"
  },
  {
    Country: "Australia",
    Pcode: "5063",
    Locality: "FULLARTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.628100",
    Latitude: "-34.952600"
  },
  {
    Country: "Australia",
    Pcode: "5063",
    Locality: "HIGHGATE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.621800",
    Latitude: "-34.961700"
  },
  {
    Country: "Australia",
    Pcode: "5063",
    Locality: "PARKSIDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.616100",
    Latitude: "-34.946600"
  },
  {
    Country: "Australia",
    Pcode: "5064",
    Locality: "GLEN OSMOND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.649300",
    Latitude: "-34.960100"
  },
  {
    Country: "Australia",
    Pcode: "5064",
    Locality: "GLENUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.638800",
    Latitude: "-34.950900"
  },
  {
    Country: "Australia",
    Pcode: "5064",
    Locality: "MOUNT OSMOND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.659600",
    Latitude: "-34.964000"
  },
  {
    Country: "Australia",
    Pcode: "5064",
    Locality: "MYRTLE BANK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.633800",
    Latitude: "-34.960400"
  },
  {
    Country: "Australia",
    Pcode: "5064",
    Locality: "ST GEORGES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.647800",
    Latitude: "-34.951900"
  },
  {
    Country: "Australia",
    Pcode: "5064",
    Locality: "URRBRAE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.638400",
    Latitude: "-34.968800"
  },
  {
    Country: "Australia",
    Pcode: "5065",
    Locality: "DULWICH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.628700",
    Latitude: "-34.936800"
  },
  {
    Country: "Australia",
    Pcode: "5065",
    Locality: "GLENSIDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.633700",
    Latitude: "-34.942700"
  },
  {
    Country: "Australia",
    Pcode: "5065",
    Locality: "LINDEN PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.647200",
    Latitude: "-34.943500"
  },
  {
    Country: "Australia",
    Pcode: "5065",
    Locality: "TOORAK GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.637500",
    Latitude: "-34.933300"
  },
  {
    Country: "Australia",
    Pcode: "5065",
    Locality: "TUSMORE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.647300",
    Latitude: "-34.935800"
  },
  {
    Country: "Australia",
    Pcode: "5066",
    Locality: "BEAUMONT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.659300",
    Latitude: "-34.949800"
  },
  {
    Country: "Australia",
    Pcode: "5066",
    Locality: "BURNSIDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.667200",
    Latitude: "-34.940000"
  },
  {
    Country: "Australia",
    Pcode: "5066",
    Locality: "ERINDALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.663800",
    Latitude: "-34.928800"
  },
  {
    Country: "Australia",
    Pcode: "5066",
    Locality: "HAZELWOOD PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.656000",
    Latitude: "-34.939000"
  },
  {
    Country: "Australia",
    Pcode: "5066",
    Locality: "STONYFELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.676800",
    Latitude: "-34.934100"
  },
  {
    Country: "Australia",
    Pcode: "5066",
    Locality: "WATERFALL GULLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.674300",
    Latitude: "-34.961700"
  },
  {
    Country: "Australia",
    Pcode: "5066",
    Locality: "WATTLE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.677900",
    Latitude: "-34.926900"
  },
  {
    Country: "Australia",
    Pcode: "5067",
    Locality: "BEULAH PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.645200",
    Latitude: "-34.917600"
  },
  {
    Country: "Australia",
    Pcode: "5067",
    Locality: "KENT TOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.619300",
    Latitude: "-34.921200"
  },
  {
    Country: "Australia",
    Pcode: "5067",
    Locality: "NORWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.632000",
    Latitude: "-34.921300"
  },
  {
    Country: "Australia",
    Pcode: "5067",
    Locality: "NORWOOD SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5067",
    Locality: "ROSE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.628200",
    Latitude: "-34.930700"
  },
  {
    Country: "Australia",
    Pcode: "5068",
    Locality: "HEATHPOOL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.646200",
    Latitude: "-34.931200"
  },
  {
    Country: "Australia",
    Pcode: "5068",
    Locality: "KENSINGTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.645600",
    Latitude: "-34.923700"
  },
  {
    Country: "Australia",
    Pcode: "5068",
    Locality: "KENSINGTON GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.663100",
    Latitude: "-34.919700"
  },
  {
    Country: "Australia",
    Pcode: "5068",
    Locality: "KENSINGTON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.654300",
    Latitude: "-34.920100"
  },
  {
    Country: "Australia",
    Pcode: "5068",
    Locality: "LEABROOK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.655000",
    Latitude: "-34.929800"
  },
  {
    Country: "Australia",
    Pcode: "5068",
    Locality: "MARRYATVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.646000",
    Latitude: "-34.928500"
  },
  {
    Country: "Australia",
    Pcode: "5068",
    Locality: "ST MORRIS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.653600",
    Latitude: "-34.911000"
  },
  {
    Country: "Australia",
    Pcode: "5068",
    Locality: "TRINITY GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.644600",
    Latitude: "-34.911000"
  },
  {
    Country: "Australia",
    Pcode: "5069",
    Locality: "COLLEGE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.619900",
    Latitude: "-34.911000"
  },
  {
    Country: "Australia",
    Pcode: "5069",
    Locality: "EVANDALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.635500",
    Latitude: "-34.905400"
  },
  {
    Country: "Australia",
    Pcode: "5069",
    Locality: "HACKNEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.616700",
    Latitude: "-34.914300"
  },
  {
    Country: "Australia",
    Pcode: "5069",
    Locality: "MAYLANDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.636600",
    Latitude: "-34.911200"
  },
  {
    Country: "Australia",
    Pcode: "5069",
    Locality: "ST PETERS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.623500",
    Latitude: "-34.904800"
  },
  {
    Country: "Australia",
    Pcode: "5069",
    Locality: "STEPNEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.629700",
    Latitude: "-34.911400"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "FELIXSTOW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.648200",
    Latitude: "-34.889000"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "FIRLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.653100",
    Latitude: "-34.904500"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "GLYNDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.651300",
    Latitude: "-34.896300"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "GLYNDE DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "GLYNDE PLAZA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "JOSLIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.627300",
    Latitude: "-34.900000"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "MARDEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.633900",
    Latitude: "-34.892400"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "PAYNEHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.642700",
    Latitude: "-34.898400"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "PAYNEHAM SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.644200",
    Latitude: "-34.904900"
  },
  {
    Country: "Australia",
    Pcode: "5070",
    Locality: "ROYSTON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.631600",
    Latitude: "-34.897600"
  },
  {
    Country: "Australia",
    Pcode: "5071",
    Locality: "KENT TOWN DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5072",
    Locality: "AULDANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.687200",
    Latitude: "-34.917800"
  },
  {
    Country: "Australia",
    Pcode: "5072",
    Locality: "MAGILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.675700",
    Latitude: "-34.908100"
  },
  {
    Country: "Australia",
    Pcode: "5072",
    Locality: "MAGILL NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5072",
    Locality: "MAGILL SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5072",
    Locality: "ROSSLYN PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.675500",
    Latitude: "-34.922200"
  },
  {
    Country: "Australia",
    Pcode: "5072",
    Locality: "SKYE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.692800",
    Latitude: "-34.922200"
  },
  {
    Country: "Australia",
    Pcode: "5072",
    Locality: "TERINGIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.703200",
    Latitude: "-34.913300"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "GAWLER BELT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.725600",
    Latitude: "-34.574200"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "GAWLER EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.763000",
    Latitude: "-34.601800"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "GAWLER RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.657700",
    Latitude: "-34.610400"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "GAWLER SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.745700",
    Latitude: "-34.610200"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "GAWLER WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.733100",
    Latitude: "-34.604200"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "HEWETT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.754400",
    Latitude: "-34.573900"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "KALBEEBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.789000",
    Latitude: "-34.618100"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "KANGAROO FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.690400",
    Latitude: "-34.549800"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "KINGSFORD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.789800",
    Latitude: "-34.548700"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "REID",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.730000",
    Latitude: "-34.597800"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "WARD BELT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.670300",
    Latitude: "-34.580700"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "WILLASTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.743300",
    Latitude: "-34.583400"
  },
  {
    Country: "Australia",
    Pcode: "5120",
    Locality: "BUCKLAND PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.503500",
    Latitude: "-34.684400"
  },
  {
    Country: "Australia",
    Pcode: "5120",
    Locality: "VIRGINIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.568400",
    Latitude: "-34.664800"
  },
  {
    Country: "Australia",
    Pcode: "5121",
    Locality: "EYRE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5121",
    Locality: "MACDONALD PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.639700",
    Latitude: "-34.668500"
  },
  {
    Country: "Australia",
    Pcode: "5121",
    Locality: "PENFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.639800",
    Latitude: "-34.694800"
  },
  {
    Country: "Australia",
    Pcode: "5121",
    Locality: "PENFIELD GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.613700",
    Latitude: "-34.656400"
  },
  {
    Country: "Australia",
    Pcode: "5125",
    Locality: "GOLDEN GROVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.743700",
    Latitude: "-34.770400"
  },
  {
    Country: "Australia",
    Pcode: "5125",
    Locality: "GOLDEN GROVE VILLAGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5125",
    Locality: "GREENWITH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.711900",
    Latitude: "-34.761900"
  },
  {
    Country: "Australia",
    Pcode: "5126",
    Locality: "FAIRVIEW PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.729500",
    Latitude: "-34.799900"
  },
  {
    Country: "Australia",
    Pcode: "5126",
    Locality: "SURREY DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.708800",
    Latitude: "-34.800700"
  },
  {
    Country: "Australia",
    Pcode: "5126",
    Locality: "YATALA VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.740300",
    Latitude: "-34.793200"
  },
  {
    Country: "Australia",
    Pcode: "5127",
    Locality: "WYNN VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.689100",
    Latitude: "-34.800300"
  },
  {
    Country: "Australia",
    Pcode: "5131",
    Locality: "HOUGHTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.757800",
    Latitude: "-34.829300"
  },
  {
    Country: "Australia",
    Pcode: "5131",
    Locality: "LOWER HERMITAGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.774500",
    Latitude: "-34.805000"
  },
  {
    Country: "Australia",
    Pcode: "5131",
    Locality: "UPPER HERMITAGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.760600",
    Latitude: "-34.790100"
  },
  {
    Country: "Australia",
    Pcode: "5132",
    Locality: "PARACOMBE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.765900",
    Latitude: "-34.844900"
  },
  {
    Country: "Australia",
    Pcode: "5133",
    Locality: "INGLEWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.791100",
    Latitude: "-34.802200"
  },
  {
    Country: "Australia",
    Pcode: "5134",
    Locality: "CHERRYVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.781200",
    Latitude: "-34.906500"
  },
  {
    Country: "Australia",
    Pcode: "5134",
    Locality: "MONTACUTE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.757900",
    Latitude: "-34.884300"
  },
  {
    Country: "Australia",
    Pcode: "5136",
    Locality: "NORTON SUMMIT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.736800",
    Latitude: "-34.918400"
  },
  {
    Country: "Australia",
    Pcode: "5137",
    Locality: "ASHTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.732400",
    Latitude: "-34.941200"
  },
  {
    Country: "Australia",
    Pcode: "5137",
    Locality: "MARBLE HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.755200",
    Latitude: "-34.925900"
  },
  {
    Country: "Australia",
    Pcode: "5138",
    Locality: "BASKET RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.773700",
    Latitude: "-34.935600"
  },
  {
    Country: "Australia",
    Pcode: "5139",
    Locality: "FOREST RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.800000",
    Latitude: "-34.926400"
  },
  {
    Country: "Australia",
    Pcode: "5140",
    Locality: "GREENHILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.691100",
    Latitude: "-34.949300"
  },
  {
    Country: "Australia",
    Pcode: "5141",
    Locality: "HORSNELL GULLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.705100",
    Latitude: "-34.934500"
  },
  {
    Country: "Australia",
    Pcode: "5141",
    Locality: "SUMMERTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.726400",
    Latitude: "-34.959600"
  },
  {
    Country: "Australia",
    Pcode: "5142",
    Locality: "URAIDLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.744800",
    Latitude: "-34.961600"
  },
  {
    Country: "Australia",
    Pcode: "5144",
    Locality: "CAREY GULLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.768600",
    Latitude: "-34.965400"
  },
  {
    Country: "Australia",
    Pcode: "5150",
    Locality: "LEAWOOD GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.669200",
    Latitude: "-34.971800"
  },
  {
    Country: "Australia",
    Pcode: "5151",
    Locality: "PICCADILLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.732300",
    Latitude: "-34.979500"
  },
  {
    Country: "Australia",
    Pcode: "5152",
    Locality: "CLELAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.695400",
    Latitude: "-34.972200"
  },
  {
    Country: "Australia",
    Pcode: "5152",
    Locality: "CRAFERS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.716600",
    Latitude: "-34.985800"
  },
  {
    Country: "Australia",
    Pcode: "5152",
    Locality: "CRAFERS WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.681800",
    Latitude: "-34.994400"
  },
  {
    Country: "Australia",
    Pcode: "5152",
    Locality: "STIRLING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.720200",
    Latitude: "-35.002300"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "BIGGS FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.782200",
    Latitude: "-35.073400"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "BRADBURY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.721500",
    Latitude: "-35.071300"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "CHAPEL HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.766800",
    Latitude: "-35.084100"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "ECHUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.803300",
    Latitude: "-35.091700"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "FLAXLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.820400",
    Latitude: "-35.136900"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "GREEN HILLS RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.801000",
    Latitude: "-35.166300"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "HEATHFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.716200",
    Latitude: "-35.025500"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "IRONBANK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.688600",
    Latitude: "-35.044400"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "JUPITER CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.748000",
    Latitude: "-35.113400"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "LONGWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.722700",
    Latitude: "-35.042300"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "MACCLESFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.846300",
    Latitude: "-35.177500"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "MYLOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.759900",
    Latitude: "-35.047700"
  },
  {
    Country: "Australia",
    Pcode: "5153",
    Locality: "SCOTT CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.699900",
    Latitude: "-35.059300"
  },
  {
    Country: "Australia",
    Pcode: "5154",
    Locality: "ALDGATE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.733600",
    Latitude: "-35.016000"
  },
  {
    Country: "Australia",
    Pcode: "5155",
    Locality: "BRIDGEWATER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.766600",
    Latitude: "-35.016600"
  },
  {
    Country: "Australia",
    Pcode: "5155",
    Locality: "MOUNT GEORGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.764500",
    Latitude: "-34.991400"
  },
  {
    Country: "Australia",
    Pcode: "5156",
    Locality: "UPPER STURT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.691000",
    Latitude: "-35.023000"
  },
  {
    Country: "Australia",
    Pcode: "5157",
    Locality: "ASHBOURNE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.764700",
    Latitude: "-35.298500"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "WILLOUGHBY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.092400",
    Latitude: "-35.834400"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "WILLSON RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.937100",
    Latitude: "-35.851600"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "BAY OF SHOALS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.566300",
    Latitude: "-35.610800"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "BIRCHMORE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.477200",
    Latitude: "-35.795500"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "BROWNLOW KI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.604300",
    Latitude: "-35.672800"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "CAPE BORDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.711400",
    Latitude: "-35.753800"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "CASSINI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.294900",
    Latitude: "-35.683600"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "CYGNET RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.543400",
    Latitude: "-35.702500"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "D'ESTREES BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.592800",
    Latitude: "-35.951900"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "DE MOLE RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.813600",
    Latitude: "-35.730600"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "DUMBALK NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.169200",
    Latitude: "-38.512100"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "MEENIYAN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.009000",
    Latitude: "-38.582000"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "MIDDLE TARWIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.928500",
    Latitude: "-38.661600"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "TARWIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.953700",
    Latitude: "-38.599100"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "TARWIN LOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.912000",
    Latitude: "-38.790400"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "VENUS BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.793800",
    Latitude: "-38.678500"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "WALKERVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.991400",
    Latitude: "-38.811100"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "WALKERVILLE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.000200",
    Latitude: "-38.840000"
  },
  {
    Country: "Australia",
    Pcode: "3956",
    Locality: "WALKERVILLE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.996000",
    Latitude: "-38.860400"
  },
  {
    Country: "Australia",
    Pcode: "3957",
    Locality: "STONY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.078900",
    Latitude: "-38.593100"
  },
  {
    Country: "Australia",
    Pcode: "3958",
    Locality: "BUFFALO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.003400",
    Latitude: "-38.676500"
  },
  {
    Country: "Australia",
    Pcode: "3959",
    Locality: "FISH CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.071000",
    Latitude: "-38.726700"
  },
  {
    Country: "Australia",
    Pcode: "3959",
    Locality: "SANDY POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.121400",
    Latitude: "-38.818700"
  },
  {
    Country: "Australia",
    Pcode: "3959",
    Locality: "WARATAH BAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.059500",
    Latitude: "-38.799600"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "BENNISON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.267400",
    Latitude: "-38.668000"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "BOOLARONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.258900",
    Latitude: "-38.565400"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "FOSTER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.207800",
    Latitude: "-38.675800"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "FOSTER NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.174200",
    Latitude: "-38.611800"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "GUNYAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "FOSTER",
    Category: "Delivery Area",
    Longitude: "146.288000",
    Latitude: "-38.543800"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "MOUNT BEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.295400",
    Latitude: "-38.589200"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "SHALLOW INLET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "TIDAL RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.320500",
    Latitude: "-39.031700"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "TURTONS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.227200",
    Latitude: "-38.549100"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "WILSONS PROMONTORY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.351500",
    Latitude: "-38.957200"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "WONGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.252500",
    Latitude: "-38.601100"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "WOORARRA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.221600",
    Latitude: "-38.593700"
  },
  {
    Country: "Australia",
    Pcode: "3960",
    Locality: "YANAKIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.200000",
    Latitude: "-38.806300"
  },
  {
    Country: "Australia",
    Pcode: "3962",
    Locality: "AGNES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.388600",
    Latitude: "-38.683900"
  },
  {
    Country: "Australia",
    Pcode: "3962",
    Locality: "TOORA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.321800",
    Latitude: "-38.664700"
  },
  {
    Country: "Australia",
    Pcode: "3962",
    Locality: "TOORA NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.336600",
    Latitude: "-38.598000"
  },
  {
    Country: "Australia",
    Pcode: "3962",
    Locality: "WONYIP",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.378600",
    Latitude: "-38.548700"
  },
  {
    Country: "Australia",
    Pcode: "3962",
    Locality: "WOORARRA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.381800",
    Latitude: "-38.604900"
  },
  {
    Country: "Australia",
    Pcode: "3964",
    Locality: "PORT FRANKLIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.267400",
    Latitude: "-38.686900"
  },
  {
    Country: "Australia",
    Pcode: "3965",
    Locality: "PORT WELSHPOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.477000",
    Latitude: "-38.689900"
  },
  {
    Country: "Australia",
    Pcode: "3966",
    Locality: "BINGINWARRI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.448100",
    Latitude: "-38.593900"
  },
  {
    Country: "Australia",
    Pcode: "3966",
    Locality: "HAZEL PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.388100",
    Latitude: "-38.634600"
  },
  {
    Country: "Australia",
    Pcode: "3966",
    Locality: "WELSHPOOL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.439000",
    Latitude: "-38.664200"
  },
  {
    Country: "Australia",
    Pcode: "3967",
    Locality: "HEDLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.559200",
    Latitude: "-38.669800"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "ALBERTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.662900",
    Latitude: "-38.616200"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "ALBERTON WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.575500",
    Latitude: "-38.600700"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "BALOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.551300",
    Latitude: "-38.424900"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "CALROSSIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.669400",
    Latitude: "-38.498400"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "DEVON NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.613900",
    Latitude: "-38.536300"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "GELLIONDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.605200",
    Latitude: "-38.624500"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "HIAWATHA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.441300",
    Latitude: "-38.532600"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "HUNTERSTON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.812600",
    Latitude: "-38.608300"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "JACK RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.553900",
    Latitude: "-38.559000"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "LANGSBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.667100",
    Latitude: "-38.650200"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "MACKS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.638600",
    Latitude: "-38.466000"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "MADALYA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.509800",
    Latitude: "-38.507800"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "MANNS BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.801800",
    Latitude: "-38.663400"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "PORT ALBERT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.670700",
    Latitude: "-38.691300"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "ROBERTSONS BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.736900",
    Latitude: "-38.673400"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "SNAKE ISLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.551300",
    Latitude: "-38.747200"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "STACEYS BRIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.515100",
    Latitude: "-38.556900"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "TARRA VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.550500",
    Latitude: "-38.469100"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "TARRAVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.749100",
    Latitude: "-38.625800"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "WON WRON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.736500",
    Latitude: "-38.464200"
  },
  {
    Country: "Australia",
    Pcode: "3971",
    Locality: "YARRAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.714800",
    Latitude: "-38.561400"
  },
  {
    Country: "Australia",
    Pcode: "3975",
    Locality: "LYNBROOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.255600",
    Latitude: "-38.057800"
  },
  {
    Country: "Australia",
    Pcode: "3975",
    Locality: "LYNDHURST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.226800",
    Latitude: "-38.064800"
  },
  {
    Country: "Australia",
    Pcode: "3976",
    Locality: "HAMPTON PARK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.267600",
    Latitude: "-38.042800"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "BOTANIC RIDGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.255300",
    Latitude: "-38.138700"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "CANNONS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.307400",
    Latitude: "-38.207600"
  },
  {
    Country: "Australia",
    Pcode: "3977",
    Locality: "CRANBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.272300",
    Latitude: "-38.112800"
  },
  {
    Country: "Australia",
    Pcode: "4117",
    Locality: "BERRINBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.075500",
    Latitude: "-27.660700"
  },
  {
    Country: "Australia",
    Pcode: "4117",
    Locality: "KARAWATHA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.079600",
    Latitude: "-27.632900"
  },
  {
    Country: "Australia",
    Pcode: "4118",
    Locality: "BROWNS PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.051600",
    Latitude: "-27.663100"
  },
  {
    Country: "Australia",
    Pcode: "4118",
    Locality: "FORESTDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.005100",
    Latitude: "-27.663700"
  },
  {
    Country: "Australia",
    Pcode: "4118",
    Locality: "HERITAGE PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.061800",
    Latitude: "-27.682200"
  },
  {
    Country: "Australia",
    Pcode: "4118",
    Locality: "HILLCREST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.027300",
    Latitude: "-27.669200"
  },
  {
    Country: "Australia",
    Pcode: "4118",
    Locality: "REGENTS PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.041900",
    Latitude: "-27.676700"
  },
  {
    Country: "Australia",
    Pcode: "4119",
    Locality: "UNDERWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.112700",
    Latitude: "-27.607900"
  },
  {
    Country: "Australia",
    Pcode: "4120",
    Locality: "GREENSLOPES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.049200",
    Latitude: "-27.506700"
  },
  {
    Country: "Australia",
    Pcode: "4120",
    Locality: "STONES CORNER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4121",
    Locality: "HOLLAND PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.073200",
    Latitude: "-27.519400"
  },
  {
    Country: "Australia",
    Pcode: "4121",
    Locality: "HOLLAND PARK EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4121",
    Locality: "HOLLAND PARK WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.061200",
    Latitude: "-27.528500"
  },
  {
    Country: "Australia",
    Pcode: "4121",
    Locality: "TARRAGINDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.044100",
    Latitude: "-27.526100"
  },
  {
    Country: "Australia",
    Pcode: "4121",
    Locality: "WELLERS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4122",
    Locality: "MANSFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.106100",
    Latitude: "-27.537400"
  },
  {
    Country: "Australia",
    Pcode: "4122",
    Locality: "MANSFIELD BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4122",
    Locality: "MANSFIELD DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4122",
    Locality: "MOUNT GRAVATT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.072200",
    Latitude: "-27.538500"
  },
  {
    Country: "Australia",
    Pcode: "4122",
    Locality: "MOUNT GRAVATT EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.084900",
    Latitude: "-27.529800"
  },
  {
    Country: "Australia",
    Pcode: "4122",
    Locality: "UPPER MOUNT GRAVATT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.080700",
    Latitude: "-27.555500"
  },
  {
    Country: "Australia",
    Pcode: "4122",
    Locality: "UPPER MOUNT GRAVATT BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4122",
    Locality: "WISHART",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.102600",
    Latitude: "-27.556100"
  },
  {
    Country: "Australia",
    Pcode: "4123",
    Locality: "ROCHEDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.123600",
    Latitude: "-27.567100"
  },
  {
    Country: "Australia",
    Pcode: "4123",
    Locality: "ROCHEDALE SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.129800",
    Latitude: "-27.599000"
  },
  {
    Country: "Australia",
    Pcode: "4124",
    Locality: "BORONIA HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.019100",
    Latitude: "-27.688600"
  },
  {
    Country: "Australia",
    Pcode: "4124",
    Locality: "GREENBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.955800",
    Latitude: "-27.684600"
  },
  {
    Country: "Australia",
    Pcode: "4124",
    Locality: "LYONS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.855100",
    Latitude: "-27.774100"
  },
  {
    Country: "Australia",
    Pcode: "4124",
    Locality: "NEW BEITH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.940500",
    Latitude: "-27.760500"
  },
  {
    Country: "Australia",
    Pcode: "4124",
    Locality: "SILVERBARK RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.924700",
    Latitude: "-27.789300"
  },
  {
    Country: "Australia",
    Pcode: "4125",
    Locality: "MUNRUBEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.029300",
    Latitude: "-27.747300"
  },
  {
    Country: "Australia",
    Pcode: "4125",
    Locality: "PARK RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.067000",
    Latitude: "-27.704400"
  },
  {
    Country: "Australia",
    Pcode: "4125",
    Locality: "PARK RIDGE SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.034700",
    Latitude: "-27.727400"
  },
  {
    Country: "Australia",
    Pcode: "4127",
    Locality: "DAISY HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.154400",
    Latitude: "-27.627400"
  },
  {
    Country: "Australia",
    Pcode: "4127",
    Locality: "PRIESTDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.167800",
    Latitude: "-27.607400"
  },
  {
    Country: "Australia",
    Pcode: "4127",
    Locality: "SLACKS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.128400",
    Latitude: "-27.638300"
  },
  {
    Country: "Australia",
    Pcode: "4127",
    Locality: "SPRINGWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.137300",
    Latitude: "-27.621500"
  },
  {
    Country: "Australia",
    Pcode: "4128",
    Locality: "SHAILER PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.175400",
    Latitude: "-27.650600"
  },
  {
    Country: "Australia",
    Pcode: "4128",
    Locality: "TANAH MERAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.169500",
    Latitude: "-27.671600"
  },
  {
    Country: "Australia",
    Pcode: "4129",
    Locality: "LOGANHOLME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.185900",
    Latitude: "-27.683700"
  },
  {
    Country: "Australia",
    Pcode: "4129",
    Locality: "LOGANHOLME BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4129",
    Locality: "LOGANHOLME DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4130",
    Locality: "CARBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.255000",
    Latitude: "-27.672400"
  },
  {
    Country: "Australia",
    Pcode: "4130",
    Locality: "CORNUBIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.212100",
    Latitude: "-27.660000"
  },
  {
    Country: "Australia",
    Pcode: "4131",
    Locality: "LOGANLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.134800",
    Latitude: "-27.675300"
  },
  {
    Country: "Australia",
    Pcode: "4131",
    Locality: "MEADOWBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.143700",
    Latitude: "-27.665600"
  },
  {
    Country: "Australia",
    Pcode: "4132",
    Locality: "CRESTMEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.085300",
    Latitude: "-27.686200"
  },
  {
    Country: "Australia",
    Pcode: "4132",
    Locality: "MARSDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.097200",
    Latitude: "-27.673500"
  },
  {
    Country: "Australia",
    Pcode: "4133",
    Locality: "CHAMBERS FLAT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.083200",
    Latitude: "-27.746600"
  },
  {
    Country: "Australia",
    Pcode: "4133",
    Locality: "LOGAN RESERVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.106800",
    Latitude: "-27.717200"
  },
  {
    Country: "Australia",
    Pcode: "4133",
    Locality: "WATERFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.131500",
    Latitude: "-27.705400"
  },
  {
    Country: "Australia",
    Pcode: "4133",
    Locality: "WATERFORD WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.129100",
    Latitude: "-27.692800"
  },
  {
    Country: "Australia",
    Pcode: "4151",
    Locality: "COORPAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.061700",
    Latitude: "-27.498700"
  },
  {
    Country: "Australia",
    Pcode: "4151",
    Locality: "COORPAROO DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4152",
    Locality: "CAMP HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.076900",
    Latitude: "-27.497300"
  },
  {
    Country: "Australia",
    Pcode: "4152",
    Locality: "CARINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.100000",
    Latitude: "-27.487200"
  },
  {
    Country: "Australia",
    Pcode: "4152",
    Locality: "CARINA HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.088100",
    Latitude: "-27.506000"
  },
  {
    Country: "Australia",
    Pcode: "4152",
    Locality: "CARINDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.111500",
    Latitude: "-27.509500"
  },
  {
    Country: "Australia",
    Pcode: "4153",
    Locality: "BELMONT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.135000",
    Latitude: "-27.509800"
  },
  {
    Country: "Australia",
    Pcode: "4154",
    Locality: "GUMDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.156900",
    Latitude: "-27.495400"
  },
  {
    Country: "Australia",
    Pcode: "4154",
    Locality: "RANSOME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.182100",
    Latitude: "-27.488300"
  },
  {
    Country: "Australia",
    Pcode: "4154",
    Locality: "WAKERLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.155400",
    Latitude: "-27.483900"
  },
  {
    Country: "Australia",
    Pcode: "4155",
    Locality: "CHANDLER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.168800",
    Latitude: "-27.516700"
  },
  {
    Country: "Australia",
    Pcode: "4156",
    Locality: "BURBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.161300",
    Latitude: "-27.560700"
  },
  {
    Country: "Australia",
    Pcode: "4156",
    Locality: "MACKENZIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "BRISBANE",
    Category: "Delivery Area",
    Longitude: "153.128000",
    Latitude: "-27.541800"
  },
  {
    Country: "Australia",
    Pcode: "4157",
    Locality: "CAPALABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.203400",
    Latitude: "-27.539000"
  },
  {
    Country: "Australia",
    Pcode: "4157",
    Locality: "CAPALABA BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4157",
    Locality: "CAPALABA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "FLINDERS LAKES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.888200",
    Latitude: "-27.827300"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "GLENEAGLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.962000",
    Latitude: "-27.940400"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "HILLVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.022400",
    Latitude: "-28.229700"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "INNISPLAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.905500",
    Latitude: "-28.181100"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "JOSEPHVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.900900",
    Latitude: "-28.033400"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "KAGARU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.916700",
    Latitude: "-27.858500"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "KERRY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.033700",
    Latitude: "-28.137700"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "KNAPP CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.810100",
    Latitude: "-28.127500"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "KOORALBYN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.820800",
    Latitude: "-28.072400"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "LAMINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.024500",
    Latitude: "-28.275100"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "LARAVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.934800",
    Latitude: "-28.087800"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "MONARCH GLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.920500",
    Latitude: "-27.814900"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "MOUNT GIPPS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.008600",
    Latitude: "-28.322200"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "MUNDOOLUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.071100",
    Latitude: "-27.897300"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "NINDOOINBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.072600",
    Latitude: "-28.062400"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "OAKY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.945300",
    Latitude: "-28.202700"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "TABOOBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.970000",
    Latitude: "-28.135900"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "TABRAGALBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.066400",
    Latitude: "-28.000900"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "TAMROOKUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.913700",
    Latitude: "-28.128700"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "TAMROOKUM CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.841500",
    Latitude: "-28.169900"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "UNDULLAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.845700",
    Latitude: "-27.848600"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "VERESDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.992800",
    Latitude: "-27.912100"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "VERESDALE SCRUB",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.016200",
    Latitude: "-27.917900"
  },
  {
    Country: "Australia",
    Pcode: "4285",
    Locality: "WOODHILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.972200",
    Latitude: "-27.886200"
  },
  {
    Country: "Australia",
    Pcode: "4287",
    Locality: "BARNEY VIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.764300",
    Latitude: "-28.238800"
  },
  {
    Country: "Australia",
    Pcode: "4287",
    Locality: "MOUNT BARNEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.707100",
    Latitude: "-28.277600"
  },
  {
    Country: "Australia",
    Pcode: "4287",
    Locality: "MOUNT LINDESAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.733500",
    Latitude: "-28.330500"
  },
  {
    Country: "Australia",
    Pcode: "4287",
    Locality: "PALEN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.807000",
    Latitude: "-28.304800"
  },
  {
    Country: "Australia",
    Pcode: "4287",
    Locality: "RATHDOWNEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.801000",
    Latitude: "-28.191000"
  },
  {
    Country: "Australia",
    Pcode: "4287",
    Locality: "RUNNING CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.908900",
    Latitude: "-28.268500"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "AUGUSTINE HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.882900",
    Latitude: "-27.658000"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "BELLBIRD PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.884800",
    Latitude: "-27.638600"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "BROOKWATER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.897100",
    Latitude: "-27.657100"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "CAMIRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.918400",
    Latitude: "-27.634900"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "CAROLE PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.928700",
    Latitude: "-27.621500"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "GAILES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.914000",
    Latitude: "-27.609000"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "GOODNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.892700",
    Latitude: "-27.608800"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "SPRING MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.885700",
    Latitude: "-27.700100"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "SPRINGFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.909100",
    Latitude: "-27.655400"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "SPRINGFIELD CENTRAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.903200",
    Latitude: "-27.683300"
  },
  {
    Country: "Australia",
    Pcode: "4300",
    Locality: "SPRINGFIELD LAKES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.912800",
    Latitude: "-27.697700"
  },
  {
    Country: "Australia",
    Pcode: "4301",
    Locality: "COLLINGWOOD PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.856300",
    Latitude: "-27.622500"
  },
  {
    Country: "Australia",
    Pcode: "4301",
    Locality: "REDBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.873200",
    Latitude: "-27.602600"
  },
  {
    Country: "Australia",
    Pcode: "4301",
    Locality: "REDBANK PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.852000",
    Latitude: "-27.651800"
  },
  {
    Country: "Australia",
    Pcode: "4303",
    Locality: "DINMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.834100",
    Latitude: "-27.596500"
  },
  {
    Country: "Australia",
    Pcode: "4303",
    Locality: "NEW CHUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.830600",
    Latitude: "-27.617100"
  },
  {
    Country: "Australia",
    Pcode: "4303",
    Locality: "RIVERVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.847400",
    Latitude: "-27.592800"
  },
  {
    Country: "Australia",
    Pcode: "4304",
    Locality: "BLACKSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.801700",
    Latitude: "-27.631000"
  },
  {
    Country: "Australia",
    Pcode: "4304",
    Locality: "BOOVAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.792300",
    Latitude: "-27.613700"
  },
  {
    Country: "Australia",
    Pcode: "4304",
    Locality: "BOOVAL FAIR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4304",
    Locality: "BUNDAMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.809100",
    Latitude: "-27.602300"
  },
  {
    Country: "Australia",
    Pcode: "4304",
    Locality: "EBBW VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.825200",
    Latitude: "-27.607800"
  },
  {
    Country: "Australia",
    Pcode: "4304",
    Locality: "NORTH BOOVAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.788100",
    Latitude: "-27.597400"
  },
  {
    Country: "Australia",
    Pcode: "4304",
    Locality: "SILKSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.788100",
    Latitude: "-27.623100"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "BASIN POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.770300",
    Latitude: "-27.601300"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "BRASSALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.736500",
    Latitude: "-27.591600"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "BREMER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "CHURCHILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.751300",
    Latitude: "-27.639800"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "COALFALLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.743300",
    Latitude: "-27.606500"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "EAST IPSWICH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.776000",
    Latitude: "-27.606500"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "EASTERN HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.776900",
    Latitude: "-27.626000"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "FLINDERS VIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.781100",
    Latitude: "-27.651700"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "IPSWICH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.759900",
    Latitude: "-27.623200"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "LEICHHARDT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.735200",
    Latitude: "-27.623000"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "LIMESTONE RIDGES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.724800",
    Latitude: "-27.833900"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "MOORES POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.782800",
    Latitude: "-27.598900"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "NEWTOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "IPSWICH",
    Category: "Delivery Area",
    Longitude: "152.776900",
    Latitude: "-27.616400"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "NORTH IPSWICH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.760800",
    Latitude: "-27.593800"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "JUNCTION VIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.187500",
    Latitude: "-27.810900"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "LAKE CLARENDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.356800",
    Latitude: "-27.516600"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "LAWES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.328700",
    Latitude: "-27.568600"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "LEFTHAND BRANCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.255500",
    Latitude: "-27.790000"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "LOWER TENTHILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.238800",
    Latitude: "-27.604900"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "MORTON VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.381700",
    Latitude: "-27.487000"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "MOUNT SYLVIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.200200",
    Latitude: "-27.738400"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "PLACID HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.229800",
    Latitude: "-27.561400"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "RINGWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.228900",
    Latitude: "-27.523700"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "ROCKSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.284800",
    Latitude: "-27.699500"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "ROPELEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.263000",
    Latitude: "-27.653300"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "SPRING CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "LOCKYER VALLEY",
    Category: "Delivery Area",
    Longitude: "152.333500",
    Latitude: "-27.441800"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "UPPER TENTHILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.216500",
    Latitude: "-27.658400"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "VINEGAR HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.260300",
    Latitude: "-27.468500"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "WOODBINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.204800",
    Latitude: "-27.777800"
  },
  {
    Country: "Australia",
    Pcode: "4343",
    Locality: "WOODLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.287500",
    Latitude: "-27.611200"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "CARPENDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.152400",
    Latitude: "-27.586500"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "EGYPT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.098500",
    Latitude: "-27.676400"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "FLAGSTONE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.104500",
    Latitude: "-27.628800"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "HELIDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.129600",
    Latitude: "-27.525300"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "HELIDON SPA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.097200",
    Latitude: "-27.543700"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "IREDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.111300",
    Latitude: "-27.579500"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "LILYDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.142600",
    Latitude: "-27.610700"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "LOCKYER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.069000",
    Latitude: "-27.506400"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "ROCKMOUNT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.024100",
    Latitude: "-27.676600"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "SEVENTEEN MILE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.179200",
    Latitude: "-27.458400"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "STOCKYARD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.062400",
    Latitude: "-27.658400"
  },
  {
    Country: "Australia",
    Pcode: "4344",
    Locality: "UPPER FLAGSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.003100",
    Latitude: "-27.631500"
  },
  {
    Country: "Australia",
    Pcode: "4345",
    Locality: "GATTON COLLEGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4346",
    Locality: "MARBURG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.583800",
    Latitude: "-27.563100"
  },
  {
    Country: "Australia",
    Pcode: "4347",
    Locality: "GRANTHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.191900",
    Latitude: "-27.538900"
  },
  {
    Country: "Australia",
    Pcode: "4347",
    Locality: "MA MA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.175200",
    Latitude: "-27.632300"
  },
  {
    Country: "Australia",
    Pcode: "4347",
    Locality: "MOUNT WHITESTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.158300",
    Latitude: "-27.700300"
  },
  {
    Country: "Australia",
    Pcode: "4347",
    Locality: "VERADILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.184400",
    Latitude: "-27.597300"
  },
  {
    Country: "Australia",
    Pcode: "4347",
    Locality: "WINWILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.209300",
    Latitude: "-27.606000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "ATHOL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.769200",
    Latitude: "-27.612500"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "BLUE MOUNTAIN HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.954400",
    Latitude: "-27.499100"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "CENTENARY HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.962300",
    Latitude: "-27.586200"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "CHARLTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.845200",
    Latitude: "-27.516800"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "CLIFFORD GARDENS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "COTSWOLD HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.895100",
    Latitude: "-27.523100"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "CRANLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.923700",
    Latitude: "-27.512300"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "DARLING HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.922600",
    Latitude: "-27.607000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "DRAYTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.901500",
    Latitude: "-27.599600"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "DRAYTON NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "EAST TOOWOOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.967400",
    Latitude: "-27.562200"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "FINNIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.891200",
    Latitude: "-27.626900"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "GLENVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.892900",
    Latitude: "-27.568400"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "GOWRIE MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.822600",
    Latitude: "-27.511300"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "HARLAXTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.955900",
    Latitude: "-27.530800"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "HARRISTOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.926300",
    Latitude: "-27.578000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "KEARNEYS SPRING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.940600",
    Latitude: "-27.603000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "MIDDLE RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.966300",
    Latitude: "-27.608100"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "MOUNT KYNOCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.948600",
    Latitude: "-27.510400"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "MOUNT LOFTY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.981300",
    Latitude: "-27.538200"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "MOUNT RASCAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.914200",
    Latitude: "-27.634600"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "NEWTOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "151.930500",
    Latitude: "-27.554300"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "NORTH TOOWOOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.955100",
    Latitude: "-27.546500"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "NORTHLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "NORTHPOINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "PRINCE HENRY HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.994300",
    Latitude: "-27.552900"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "RANGEVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.987800",
    Latitude: "-27.584900"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "REDWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.989100",
    Latitude: "-27.568100"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "ROCKVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.938000",
    Latitude: "-27.535600"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "SOUTH TOOWOOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.952200",
    Latitude: "-27.576400"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "TOOWOOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "TOOWOOMBA CITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.950600",
    Latitude: "-27.559700"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "TOOWOOMBA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "PUNCHS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.389400",
    Latitude: "-27.980300"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "ROCKY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.346300",
    Latitude: "-28.028500"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "STONEHENGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "151.391900",
    Latitude: "-28.099800"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "THE PINES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.970200",
    Latitude: "-27.971400"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "TURALLIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.184100",
    Latitude: "-27.821400"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "WATTLE RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.979000",
    Latitude: "-28.003200"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "WESTERN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.928000",
    Latitude: "-27.857700"
  },
  {
    Country: "Australia",
    Pcode: "4357",
    Locality: "WOONDUL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.024400",
    Latitude: "-28.058000"
  },
  {
    Country: "Australia",
    Pcode: "4358",
    Locality: "CAMBOOYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.844900",
    Latitude: "-27.728900"
  },
  {
    Country: "Australia",
    Pcode: "4358",
    Locality: "FELTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.757300",
    Latitude: "-27.793700"
  },
  {
    Country: "Australia",
    Pcode: "4358",
    Locality: "FELTON SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.706000",
    Latitude: "-27.875700"
  },
  {
    Country: "Australia",
    Pcode: "4358",
    Locality: "RAMSAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.988400",
    Latitude: "-27.721900"
  },
  {
    Country: "Australia",
    Pcode: "4359",
    Locality: "BUDGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.014800",
    Latitude: "-27.789200"
  },
  {
    Country: "Australia",
    Pcode: "4359",
    Locality: "EAST GREENMOUNT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.956900",
    Latitude: "-27.783700"
  },
  {
    Country: "Australia",
    Pcode: "4359",
    Locality: "GREENMOUNT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.885400",
    Latitude: "-27.771700"
  },
  {
    Country: "Australia",
    Pcode: "4359",
    Locality: "HIRSTGLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.096600",
    Latitude: "-27.838600"
  },
  {
    Country: "Australia",
    Pcode: "4359",
    Locality: "WEST HALDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.113600",
    Latitude: "-27.775500"
  },
  {
    Country: "Australia",
    Pcode: "4360",
    Locality: "NOBBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.892500",
    Latitude: "-27.839000"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "BACK PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.781300",
    Latitude: "-27.887200"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "CLIFTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.918400",
    Latitude: "-27.937700"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "ELLANGOWAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.725600",
    Latitude: "-27.946100"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "ELPHINSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "151.893400",
    Latitude: "-27.982600"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "HEADINGTON HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.033800",
    Latitude: "-27.908900"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "KINGS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.916300",
    Latitude: "-27.899200"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "MANAPOURI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.998300",
    Latitude: "-27.855300"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "MISSEN FLAT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.970200",
    Latitude: "-27.908900"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "MOUNT MOLAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.850600",
    Latitude: "-27.897700"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "NEVILTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.971600",
    Latitude: "-27.881800"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "PILTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.066100",
    Latitude: "-27.877400"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "RYEFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.815200",
    Latitude: "-27.933200"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "SANDY CAMP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.813800",
    Latitude: "-27.976100"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "SPRING CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "152.020600",
    Latitude: "-27.957700"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "UPPER PILTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.129800",
    Latitude: "-27.915200"
  },
  {
    Country: "Australia",
    Pcode: "4361",
    Locality: "VICTORIA HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.802300",
    Latitude: "-28.018400"
  },
  {
    Country: "Australia",
    Pcode: "4362",
    Locality: "ALLORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.986700",
    Latitude: "-28.018900"
  },
  {
    Country: "Australia",
    Pcode: "4362",
    Locality: "BERAT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.058100",
    Latitude: "-28.044600"
  },
  {
    Country: "Australia",
    Pcode: "4362",
    Locality: "DEUCHAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.945800",
    Latitude: "-28.098300"
  },
  {
    Country: "Australia",
    Pcode: "4362",
    Locality: "ELLINTHORP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.918600",
    Latitude: "-28.048000"
  },
  {
    Country: "Australia",
    Pcode: "4362",
    Locality: "GOOMBURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.202000",
    Latitude: "-28.008100"
  },
  {
    Country: "Australia",
    Pcode: "4362",
    Locality: "HENDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.959900",
    Latitude: "-28.074600"
  },
  {
    Country: "Australia",
    Pcode: "4362",
    Locality: "MOUNT MARSHALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.041100",
    Latitude: "-28.079300"
  },
  {
    Country: "Australia",
    Pcode: "4362",
    Locality: "TALGAI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.867500",
    Latitude: "-28.039000"
  },
  {
    Country: "Australia",
    Pcode: "4363",
    Locality: "SOUTHBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.740100",
    Latitude: "-27.705000"
  },
  {
    Country: "Australia",
    Pcode: "4364",
    Locality: "BROOKSTEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.425400",
    Latitude: "-27.713800"
  },
  {
    Country: "Australia",
    Pcode: "4365",
    Locality: "LEYBURN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.597400",
    Latitude: "-28.018900"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "ALLAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.950600",
    Latitude: "-28.197300"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "BONY MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.842400",
    Latitude: "-28.103800"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "CANNINGVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.072900",
    Latitude: "-28.249200"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "CHERRY GULLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.063300",
    Latitude: "-28.421700"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "CLINTONVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.119500",
    Latitude: "-28.090400"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "CUNNINGHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.818800",
    Latitude: "-28.166400"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "DANDEROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.228800",
    Latitude: "-28.254900"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "ELBOW VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.168300",
    Latitude: "-28.409000"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "FREESTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.141700",
    Latitude: "-28.132500"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "GLADFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.179100",
    Latitude: "-28.063900"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "GLENGALLAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.069300",
    Latitude: "-28.109100"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "GREYMARE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.723600",
    Latitude: "-28.256700"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "JUNABEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.156900",
    Latitude: "-28.250000"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "LESLIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.916000",
    Latitude: "-28.172400"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "LESLIE DAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.896200",
    Latitude: "-28.251200"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "LOCH LOMOND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.193400",
    Latitude: "-28.333500"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "MARYVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.275300",
    Latitude: "-28.069000"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "MASSIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.939300",
    Latitude: "-28.132800"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "MONTROSE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "SOUTHERN DOWNS",
    Category: "Delivery Area",
    Longitude: "151.783200",
    Latitude: "-28.160000"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "MORGAN PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.043100",
    Latitude: "-28.257900"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "MOUNT COLLIERY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.347900",
    Latitude: "-28.259200"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "MOUNT STURT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.208100",
    Latitude: "-28.169800"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "MOUNT TABOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.068100",
    Latitude: "-28.214100"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "MURRAYS BRIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.116200",
    Latitude: "-28.309000"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "DALBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.249700",
    Latitude: "-27.141100"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "DUCKLO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.039800",
    Latitude: "-27.278000"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "GRASSDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.134000",
    Latitude: "-27.417100"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "MARMADUA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.686300",
    Latitude: "-27.466300"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "PIRRINUAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.242900",
    Latitude: "-27.047400"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "RANGES BRIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.074800",
    Latitude: "-27.146300"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "ST RUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.271000",
    Latitude: "-27.326300"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "TIPTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.258200",
    Latitude: "-27.459000"
  },
  {
    Country: "Australia",
    Pcode: "4405",
    Locality: "WERANGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.754500",
    Latitude: "-27.258100"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "BOONDANDILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.630400",
    Latitude: "-27.912800"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "HANNAFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.003300",
    Latitude: "-27.469700"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "JIMBOUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "KAIMKILLENBUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.406700",
    Latitude: "-27.039100"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "KOGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.690600",
    Latitude: "-27.081600"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "MACALISTER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.068500",
    Latitude: "-27.035000"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "MOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.564400",
    Latitude: "-27.037900"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "MOONIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.342500",
    Latitude: "-27.708500"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "SOUTHWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.017500",
    Latitude: "-27.828500"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "THE GUMS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.222700",
    Latitude: "-27.315800"
  },
  {
    Country: "Australia",
    Pcode: "4406",
    Locality: "WEIR RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.635800",
    Latitude: "-27.695900"
  },
  {
    Country: "Australia",
    Pcode: "4407",
    Locality: "CATTLE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "150.799900",
    Latitude: "-27.674900"
  },
  {
    Country: "Australia",
    Pcode: "4407",
    Locality: "CECIL PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.183400",
    Latitude: "-27.524000"
  },
  {
    Country: "Australia",
    Pcode: "4407",
    Locality: "DUNMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.965700",
    Latitude: "-27.666700"
  },
  {
    Country: "Australia",
    Pcode: "4407",
    Locality: "NANGWEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.295900",
    Latitude: "-27.534700"
  },
  {
    Country: "Australia",
    Pcode: "4408",
    Locality: "BELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.437900",
    Latitude: "-26.911600"
  },
  {
    Country: "Australia",
    Pcode: "4410",
    Locality: "JANDOWAE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.177900",
    Latitude: "-26.727000"
  },
  {
    Country: "Australia",
    Pcode: "4411",
    Locality: "WARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.944400",
    Latitude: "-26.927800"
  },
  {
    Country: "Australia",
    Pcode: "4412",
    Locality: "BRIGALOW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.816400",
    Latitude: "-26.884300"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "BAKING BOARD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.564500",
    Latitude: "-26.698700"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "BOONARGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.720200",
    Latitude: "-26.810100"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "BURNCLUITH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.722400",
    Latitude: "-26.580400"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "CANAGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.913000",
    Latitude: "-26.646400"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "CHANCES PLAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.745100",
    Latitude: "-26.735200"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "CHINCHILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.627300",
    Latitude: "-26.747100"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "DURAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.955700",
    Latitude: "-26.298000"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "HOPELAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.665400",
    Latitude: "-26.894000"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "MONTROSE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "DALBY",
    Category: "Delivery Area",
    Longitude: "150.620000",
    Latitude: "-27.004600"
  },
  {
    Country: "Australia",
    Pcode: "4413",
    Locality: "WIEAMBILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.405900",
    Latitude: "-26.967500"
  },
  {
    Country: "Australia",
    Pcode: "4415",
    Locality: "COLUMBOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.325700",
    Latitude: "-26.718700"
  },
  {
    Country: "Australia",
    Pcode: "4415",
    Locality: "DALWOGON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.084900",
    Latitude: "-26.574700"
  },
  {
    Country: "Australia",
    Pcode: "4415",
    Locality: "GURULMUNDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.130600",
    Latitude: "-26.383300"
  },
  {
    Country: "Australia",
    Pcode: "4415",
    Locality: "HOOKSWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.327900",
    Latitude: "-26.530100"
  },
  {
    Country: "Australia",
    Pcode: "4415",
    Locality: "KOWGURAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.117400",
    Latitude: "-26.506900"
  },
  {
    Country: "Australia",
    Pcode: "4415",
    Locality: "MILES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.152000",
    Latitude: "-26.721700"
  },
  {
    Country: "Australia",
    Pcode: "4415",
    Locality: "MYALL PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.187000",
    Latitude: "-26.577100"
  },
  {
    Country: "Australia",
    Pcode: "4415",
    Locality: "PELHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.289400",
    Latitude: "-26.298100"
  },
  {
    Country: "Australia",
    Pcode: "4416",
    Locality: "BARRAMORNIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.094500",
    Latitude: "-27.074500"
  },
  {
    Country: "Australia",
    Pcode: "4416",
    Locality: "CONDAMINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.117600",
    Latitude: "-26.977600"
  },
  {
    Country: "Australia",
    Pcode: "4416",
    Locality: "MORABY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.751600",
    Latitude: "-26.890400"
  },
  {
    Country: "Australia",
    Pcode: "4416",
    Locality: "NANGRAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.315000",
    Latitude: "-26.837600"
  },
  {
    Country: "Australia",
    Pcode: "4416",
    Locality: "PINE HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.949100",
    Latitude: "-26.901300"
  },
  {
    Country: "Australia",
    Pcode: "4416",
    Locality: "SUNNYSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "WESTERN DOWNS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4416",
    Locality: "YULABILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.798800",
    Latitude: "-27.026300"
  },
  {
    Country: "Australia",
    Pcode: "4417",
    Locality: "NOORINDOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.220200",
    Latitude: "-27.080100"
  },
  {
    Country: "Australia",
    Pcode: "4417",
    Locality: "OBERINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.893700",
    Latitude: "-27.013100"
  },
  {
    Country: "Australia",
    Pcode: "4417",
    Locality: "PARKNOOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.195300",
    Latitude: "-27.415600"
  },
  {
    Country: "Australia",
    Pcode: "4417",
    Locality: "SURAT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.063800",
    Latitude: "-27.152500"
  },
  {
    Country: "Australia",
    Pcode: "4417",
    Locality: "WARKON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.391700",
    Latitude: "-26.929900"
  },
  {
    Country: "Australia",
    Pcode: "4417",
    Locality: "WELLESLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.908600",
    Latitude: "-27.432700"
  },
  {
    Country: "Australia",
    Pcode: "4417",
    Locality: "WERIBONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.887700",
    Latitude: "-27.215700"
  },
  {
    Country: "Australia",
    Pcode: "4418",
    Locality: "GULUGUBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.093600",
    Latitude: "-26.272600"
  },
  {
    Country: "Australia",
    Pcode: "4419",
    Locality: "COCKATOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.290900",
    Latitude: "-25.648100"
  },
  {
    Country: "Australia",
    Pcode: "4419",
    Locality: "GROSMONT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.764000",
    Latitude: "-25.965300"
  },
  {
    Country: "Australia",
    Pcode: "4419",
    Locality: "WANDOAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.898900",
    Latitude: "-26.138500"
  },
  {
    Country: "Australia",
    Pcode: "4420",
    Locality: "BROADMERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.548000",
    Latitude: "-25.497000"
  },
  {
    Country: "Australia",
    Pcode: "4420",
    Locality: "COORADA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.529000",
    Latitude: "-24.963500"
  },
  {
    Country: "Australia",
    Pcode: "4420",
    Locality: "GHINGHINDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.755300",
    Latitude: "-25.178800"
  },
  {
    Country: "Australia",
    Pcode: "4420",
    Locality: "GLENHAUGHTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.126300",
    Latitude: "-25.199800"
  },
  {
    Country: "Australia",
    Pcode: "4420",
    Locality: "GWAMBEGWINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.541300",
    Latitude: "-25.304700"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "BRENDALE BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "BRENDALE DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "CASHMERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.903500",
    Latitude: "-27.293200"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "CLEAR MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.890900",
    Latitude: "-27.319700"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "JOYNER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.940700",
    Latitude: "-27.274000"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "STRATHPINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.982300",
    Latitude: "-27.302200"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "STRATHPINE CENTRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4500",
    Locality: "WARNER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.949500",
    Latitude: "-27.310300"
  },
  {
    Country: "Australia",
    Pcode: "4501",
    Locality: "LAWNTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.979900",
    Latitude: "-27.282500"
  },
  {
    Country: "Australia",
    Pcode: "4502",
    Locality: "PETRIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.966700",
    Latitude: "-27.262900"
  },
  {
    Country: "Australia",
    Pcode: "4503",
    Locality: "DAKABIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.985200",
    Latitude: "-27.223300"
  },
  {
    Country: "Australia",
    Pcode: "4503",
    Locality: "GRIFFIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.045200",
    Latitude: "-27.273500"
  },
  {
    Country: "Australia",
    Pcode: "4503",
    Locality: "KALLANGUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.996400",
    Latitude: "-27.250600"
  },
  {
    Country: "Australia",
    Pcode: "4503",
    Locality: "KURWONGBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.937500",
    Latitude: "-27.225500"
  },
  {
    Country: "Australia",
    Pcode: "4503",
    Locality: "MURRUMBA DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.009200",
    Latitude: "-27.266400"
  },
  {
    Country: "Australia",
    Pcode: "4503",
    Locality: "WHITESIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.907900",
    Latitude: "-27.245600"
  },
  {
    Country: "Australia",
    Pcode: "4504",
    Locality: "NARANGBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.924400",
    Latitude: "-27.186900"
  },
  {
    Country: "Australia",
    Pcode: "4505",
    Locality: "BURPENGARY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.950900",
    Latitude: "-27.155300"
  },
  {
    Country: "Australia",
    Pcode: "4505",
    Locality: "BURPENGARY DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4505",
    Locality: "BURPENGARY EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.006400",
    Latitude: "-27.140700"
  },
  {
    Country: "Australia",
    Pcode: "4506",
    Locality: "MOORINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.871200",
    Latitude: "-27.146700"
  },
  {
    Country: "Australia",
    Pcode: "4506",
    Locality: "MORAYFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.933400",
    Latitude: "-27.133600"
  },
  {
    Country: "Australia",
    Pcode: "4507",
    Locality: "BANKSIA BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.144000",
    Latitude: "-27.037700"
  },
  {
    Country: "Australia",
    Pcode: "4507",
    Locality: "BELLARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.146200",
    Latitude: "-27.062500"
  },
  {
    Country: "Australia",
    Pcode: "4507",
    Locality: "BONGAREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.163300",
    Latitude: "-27.079700"
  },
  {
    Country: "Australia",
    Pcode: "4507",
    Locality: "BRIBIE ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4507",
    Locality: "BRIBIE ISLAND NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.110500",
    Latitude: "-26.917300"
  },
  {
    Country: "Australia",
    Pcode: "4507",
    Locality: "WELSBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.117200",
    Latitude: "-26.984600"
  },
  {
    Country: "Australia",
    Pcode: "4507",
    Locality: "WHITE PATCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.123500",
    Latitude: "-27.032000"
  },
  {
    Country: "Australia",
    Pcode: "4507",
    Locality: "WOORIM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.179200",
    Latitude: "-27.051200"
  },
  {
    Country: "Australia",
    Pcode: "4508",
    Locality: "DECEPTION BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.007100",
    Latitude: "-27.181900"
  },
  {
    Country: "Australia",
    Pcode: "4509",
    Locality: "MANGO HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.043600",
    Latitude: "-27.240500"
  },
  {
    Country: "Australia",
    Pcode: "4509",
    Locality: "NORTH LAKES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.013900",
    Latitude: "-27.223700"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "BEACHMERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.046400",
    Latitude: "-27.119900"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "BELLMERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.899900",
    Latitude: "-27.079000"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "CABOOLTURE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.974900",
    Latitude: "-27.065500"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "CABOOLTURE BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "CABOOLTURE SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.949200",
    Latitude: "-27.094000"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "DONNYBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.063500",
    Latitude: "-27.000300"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "MELDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.067100",
    Latitude: "-27.027300"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "MOODLU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.911200",
    Latitude: "-27.055400"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "ROCKSBERG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.850200",
    Latitude: "-27.114000"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "TOORBUL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.101500",
    Latitude: "-27.038500"
  },
  {
    Country: "Australia",
    Pcode: "4510",
    Locality: "UPPER CABOOLTURE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.904000",
    Latitude: "-27.116500"
  },
  {
    Country: "Australia",
    Pcode: "4511",
    Locality: "GODWIN BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.099700",
    Latitude: "-27.085800"
  },
  {
    Country: "Australia",
    Pcode: "4511",
    Locality: "NINGI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.063600",
    Latitude: "-27.075700"
  },
  {
    Country: "Australia",
    Pcode: "4511",
    Locality: "SANDSTONE POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.134500",
    Latitude: "-27.077400"
  },
  {
    Country: "Australia",
    Pcode: "4512",
    Locality: "BRACALBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.836600",
    Latitude: "-27.003300"
  },
  {
    Country: "Australia",
    Pcode: "4512",
    Locality: "WAMURAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.863500",
    Latitude: "-27.036200"
  },
  {
    Country: "Australia",
    Pcode: "4512",
    Locality: "WAMURAN BASIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.823700",
    Latitude: "-27.048400"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "BELLTHORPE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.718000",
    Latitude: "-26.856100"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "CEDARTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.811700",
    Latitude: "-26.859700"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "COMMISSIONERS FLAT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.839400",
    Latitude: "-26.871800"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "D'AGUILAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.798000",
    Latitude: "-26.985100"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "DELANEYS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.774300",
    Latitude: "-27.011600"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "MOUNT ARCHER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "SOMERSET",
    Category: "Delivery Area",
    Longitude: "152.659600",
    Latitude: "-26.989000"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "MOUNT DELANEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.716800",
    Latitude: "-27.018000"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "NEURUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.696400",
    Latitude: "-26.977400"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "STANMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.798900",
    Latitude: "-26.907400"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "STONY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.705600",
    Latitude: "-26.915600"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "VILLENEUVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.634400",
    Latitude: "-26.952700"
  },
  {
    Country: "Australia",
    Pcode: "4514",
    Locality: "WOODFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.797900",
    Latitude: "-26.939100"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "GLENFERN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.599700",
    Latitude: "-26.958900"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "HAZELDEAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "SOMERSET",
    Category: "Delivery Area",
    Longitude: "152.538400",
    Latitude: "-27.029300"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "JIMNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.539900",
    Latitude: "-26.701300"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "KILCOY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.562400",
    Latitude: "-26.943100"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "KINGAHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.372500",
    Latitude: "-26.550400"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "MONSILDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "KILCOY",
    Category: "Delivery Area",
    Longitude: "152.368000",
    Latitude: "-26.700700"
  },
  {
    Country: "Australia",
    Pcode: "4563",
    Locality: "COOROY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.902300",
    Latitude: "-26.419400"
  },
  {
    Country: "Australia",
    Pcode: "4563",
    Locality: "COOROY MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.951600",
    Latitude: "-26.431500"
  },
  {
    Country: "Australia",
    Pcode: "4563",
    Locality: "LAKE MACDONALD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.937300",
    Latitude: "-26.390100"
  },
  {
    Country: "Australia",
    Pcode: "4563",
    Locality: "RIDGEWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.834400",
    Latitude: "-26.463300"
  },
  {
    Country: "Australia",
    Pcode: "4563",
    Locality: "TINBEERWAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.976800",
    Latitude: "-26.404600"
  },
  {
    Country: "Australia",
    Pcode: "4564",
    Locality: "MARCOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.079700",
    Latitude: "-26.592400"
  },
  {
    Country: "Australia",
    Pcode: "4564",
    Locality: "MUDJIMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.114700",
    Latitude: "-26.614600"
  },
  {
    Country: "Australia",
    Pcode: "4564",
    Locality: "PACIFIC PARADISE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.074500",
    Latitude: "-26.623200"
  },
  {
    Country: "Australia",
    Pcode: "4564",
    Locality: "TWIN WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.082000",
    Latitude: "-26.630100"
  },
  {
    Country: "Australia",
    Pcode: "4565",
    Locality: "BOREEN POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.985500",
    Latitude: "-26.281700"
  },
  {
    Country: "Australia",
    Pcode: "4565",
    Locality: "COOROIBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.010700",
    Latitude: "-26.349900"
  },
  {
    Country: "Australia",
    Pcode: "4565",
    Locality: "COOTHARABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.935900",
    Latitude: "-26.281600"
  },
  {
    Country: "Australia",
    Pcode: "4565",
    Locality: "NOOSA NORTH SHORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.038500",
    Latitude: "-26.255000"
  },
  {
    Country: "Australia",
    Pcode: "4565",
    Locality: "RINGTAIL CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.945100",
    Latitude: "-26.334200"
  },
  {
    Country: "Australia",
    Pcode: "4565",
    Locality: "TEWANTIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.016500",
    Latitude: "-26.391200"
  },
  {
    Country: "Australia",
    Pcode: "4566",
    Locality: "NOOSAVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.057400",
    Latitude: "-26.424700"
  },
  {
    Country: "Australia",
    Pcode: "4566",
    Locality: "NOOSAVILLE DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4567",
    Locality: "CASTAWAYS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.098100",
    Latitude: "-26.432700"
  },
  {
    Country: "Australia",
    Pcode: "4567",
    Locality: "NOOSA HEADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.091000",
    Latitude: "-26.400100"
  },
  {
    Country: "Australia",
    Pcode: "4567",
    Locality: "SUNRISE BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.102700",
    Latitude: "-26.415500"
  },
  {
    Country: "Australia",
    Pcode: "4567",
    Locality: "SUNSHINE BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.105300",
    Latitude: "-26.403800"
  },
  {
    Country: "Australia",
    Pcode: "4568",
    Locality: "FEDERAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.811000",
    Latitude: "-26.401300"
  },
  {
    Country: "Australia",
    Pcode: "4568",
    Locality: "PINBARREN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.863100",
    Latitude: "-26.318900"
  },
  {
    Country: "Australia",
    Pcode: "4568",
    Locality: "POMONA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.871800",
    Latitude: "-26.362600"
  },
  {
    Country: "Australia",
    Pcode: "4569",
    Locality: "COORAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.808100",
    Latitude: "-26.345500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "AMAMOOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.680500",
    Latitude: "-26.345700"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "AMAMOOR CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.523300",
    Latitude: "-26.376100"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "ANDERLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.679800",
    Latitude: "-26.017200"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "ARALUEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.659700",
    Latitude: "-26.157600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "BANKS POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.671000",
    Latitude: "-26.151900"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "BEENAAM VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.816800",
    Latitude: "-26.191600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "BELLA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.546600",
    Latitude: "-26.478400"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "BELLS BRIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.545000",
    Latitude: "-26.126600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "BOLLIER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.733900",
    Latitude: "-26.457200"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "BROOLOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.707700",
    Latitude: "-26.514400"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "CALGOA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.224200",
    Latitude: "-25.865500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "CALICO CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.648000",
    Latitude: "-26.300900"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "CANINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.742700",
    Latitude: "-26.135100"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "CEDAR POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.802900",
    Latitude: "-26.212900"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "CHATSWORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.609600",
    Latitude: "-26.138600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "COLES CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.741000",
    Latitude: "-26.357800"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "COONDOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.894900",
    Latitude: "-26.190900"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "CORELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.646400",
    Latitude: "-26.106200"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "CURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.598900",
    Latitude: "-26.071500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "DAGUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.667400",
    Latitude: "-26.318600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "DOWNSFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.723400",
    Latitude: "-26.043600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "EAST DEEP CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.718700",
    Latitude: "-26.196000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "FISHERMANS POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.608700",
    Latitude: "-26.167000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GILLDORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.676900",
    Latitude: "-26.299800"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GLANMIRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.695800",
    Latitude: "-26.228200"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GLASTONBURY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.529200",
    Latitude: "-26.200000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GLEN ECHO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.397600",
    Latitude: "-25.911100"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GLENWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.634000",
    Latitude: "-25.928900"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GOOMBOORIAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.781700",
    Latitude: "-26.082300"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GREENS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.747000",
    Latitude: "-26.169300"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GUNALDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.594400",
    Latitude: "-26.003300"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GYMPIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.661200",
    Latitude: "-26.183400"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "GYMPIE DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "IMBIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.640800",
    Latitude: "-26.503900"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "JONES HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.659900",
    Latitude: "-26.237100"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "KANDANGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.691000",
    Latitude: "-26.392000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "KANDANGA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.628800",
    Latitude: "-26.400700"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "KANIGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.587200",
    Latitude: "-25.940500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "KIA ORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.765500",
    Latitude: "-26.022600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "KYBONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.712800",
    Latitude: "-26.291400"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "LAGOON POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.683800",
    Latitude: "-26.276600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "LAKE BORUMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.513700",
    Latitude: "-26.523400"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "LANGSHAW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.587500",
    Latitude: "-26.304000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "LONG FLAT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.659600",
    Latitude: "-26.268500"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "INVERLAW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.760600",
    Latitude: "-26.590400"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "IRONPOT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.445800",
    Latitude: "-26.606600"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "KINGAROY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.834300",
    Latitude: "-26.523100"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "KINGAROY DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "KUMBIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.648900",
    Latitude: "-26.682900"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "MANNUEM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.585500",
    Latitude: "-26.638100"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "MEMERAMBI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.808300",
    Latitude: "-26.458600"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "TAABINGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.820300",
    Latitude: "-26.593500"
  },
  {
    Country: "Australia",
    Pcode: "4610",
    Locality: "WATTLE GROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.669800",
    Latitude: "-26.566000"
  },
  {
    Country: "Australia",
    Pcode: "4611",
    Locality: "MARSHLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.748600",
    Latitude: "-26.150800"
  },
  {
    Country: "Australia",
    Pcode: "4611",
    Locality: "MONDURE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.772300",
    Latitude: "-26.192100"
  },
  {
    Country: "Australia",
    Pcode: "4612",
    Locality: "HIVESVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.688000",
    Latitude: "-26.171500"
  },
  {
    Country: "Australia",
    Pcode: "4612",
    Locality: "KAWL KAWL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.725600",
    Latitude: "-26.185600"
  },
  {
    Country: "Australia",
    Pcode: "4612",
    Locality: "KEYSLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.736400",
    Latitude: "-26.218300"
  },
  {
    Country: "Australia",
    Pcode: "4612",
    Locality: "STONELANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.707100",
    Latitude: "-26.047000"
  },
  {
    Country: "Australia",
    Pcode: "4612",
    Locality: "WIGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.582900",
    Latitude: "-25.965700"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "ABBEYWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.643200",
    Latitude: "-26.108400"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "BOONDOOMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.211700",
    Latitude: "-26.163200"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "BRIGOODA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.383400",
    Latitude: "-26.235400"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "COVERTY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.468900",
    Latitude: "-26.274800"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "KINLEYMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.654400",
    Latitude: "-26.178100"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "MELROSE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.606000",
    Latitude: "-26.278600"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "OKEDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.504900",
    Latitude: "-26.155000"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "PROSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.585500",
    Latitude: "-26.182800"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "SPEEDWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.546600",
    Latitude: "-26.067800"
  },
  {
    Country: "Australia",
    Pcode: "4613",
    Locality: "STALWORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.589200",
    Latitude: "-26.115000"
  },
  {
    Country: "Australia",
    Pcode: "4614",
    Locality: "NEUMGNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.859400",
    Latitude: "-26.847000"
  },
  {
    Country: "Australia",
    Pcode: "4614",
    Locality: "UPPER YARRAMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.889000",
    Latitude: "-26.898700"
  },
  {
    Country: "Australia",
    Pcode: "4614",
    Locality: "YARRAMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.985600",
    Latitude: "-26.829400"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "BARKER CREEK FLAT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.944700",
    Latitude: "-26.648100"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "BROOKLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.801900",
    Latitude: "-26.738900"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "BULLCAMP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.098700",
    Latitude: "-26.601400"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "EAST NANANGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.059200",
    Latitude: "-26.691900"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "ELGIN VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.206700",
    Latitude: "-26.441900"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "GLAN DEVON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.017200",
    Latitude: "-26.615000"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "JOHNSTOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.104000",
    Latitude: "-26.420100"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "KUNIOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.900400",
    Latitude: "-26.668200"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "MAIDENWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.791900",
    Latitude: "-26.838400"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "NANANGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.985000",
    Latitude: "-26.676100"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "PIMPIMBUDGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.762900",
    Latitude: "-26.904500"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "RUNNYMEDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.085300",
    Latitude: "-26.557300"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "SANDY RIDGES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.030700",
    Latitude: "-26.523600"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "SOUTH EAST NANANGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.054800",
    Latitude: "-26.735200"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "SOUTH NANANGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.962700",
    Latitude: "-26.748800"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "TARONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.870100",
    Latitude: "-26.773800"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "WATTLE CAMP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.962200",
    Latitude: "-26.463700"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "WENGENVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.685600",
    Latitude: "-26.830100"
  },
  {
    Country: "Australia",
    Pcode: "4615",
    Locality: "WYALLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.077200",
    Latitude: "-26.465500"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "ARAMARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.356700",
    Latitude: "-25.600700"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "BROOWEENA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.251600",
    Latitude: "-25.579600"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "DOONGUL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.335500",
    Latitude: "-25.480000"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "GIGOOMGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.223500",
    Latitude: "-25.753100"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "GLENBAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.357400",
    Latitude: "-25.756100"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "GUNGALOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.468400",
    Latitude: "-25.510100"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "MALARGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.118400",
    Latitude: "-25.807500"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "NORTH ARAMARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.334600",
    Latitude: "-25.551500"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "TEEBAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.195700",
    Latitude: "-25.664100"
  },
  {
    Country: "Australia",
    Pcode: "4620",
    Locality: "WOOCOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.340400",
    Latitude: "-25.659600"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "BIGGENDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.978900",
    Latitude: "-25.561900"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "BOOMPA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.091900",
    Latitude: "-25.654700"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "COALSTOUN LAKES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.891600",
    Latitude: "-25.616800"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "CORINGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.939300",
    Latitude: "-25.371800"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "DALLARNIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.044000",
    Latitude: "-25.353700"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "DEGILBO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.980400",
    Latitude: "-25.478200"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "DIDCOT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.885800",
    Latitude: "-25.501300"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "GOLDEN FLEECE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.191200",
    Latitude: "-25.417700"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "LAKESIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.109500",
    Latitude: "-25.571200"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "WATERANGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.834200",
    Latitude: "-25.390200"
  },
  {
    Country: "Australia",
    Pcode: "4621",
    Locality: "WOOWOONGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.090100",
    Latitude: "-25.473000"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "DUNDOWRAN BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.762500",
    Latitude: "-25.272800"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "ELI WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.802500",
    Latitude: "-25.272400"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "GREAT SANDY STRAIT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.960900",
    Latitude: "-25.397500"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "HERVEY BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "HERVEY BAY DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "KAWUNGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.846700",
    Latitude: "-25.309200"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "KINGFISHER BAY RESORT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "NIKENBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.816100",
    Latitude: "-25.322000"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "PIALBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.833100",
    Latitude: "-25.280200"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "POINT VERNON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.812800",
    Latitude: "-25.256600"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "RIVER HEADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.914700",
    Latitude: "-25.409500"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "SCARNESS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.854000",
    Latitude: "-25.287800"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "SUNSHINE ACRES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.788900",
    Latitude: "-25.358000"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "SUSAN RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.772700",
    Latitude: "-25.412400"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "TAKURA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.713100",
    Latitude: "-25.304100"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "TOOGOOM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.690900",
    Latitude: "-25.254000"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "TORQUAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.872800",
    Latitude: "-25.292500"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "URANGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.892800",
    Latitude: "-25.302800"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "URRAWEEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.820100",
    Latitude: "-25.298600"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "WALLIEBUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.710300",
    Latitude: "-25.419700"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "WALLIGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.768100",
    Latitude: "-25.337400"
  },
  {
    Country: "Australia",
    Pcode: "4655",
    Locality: "WONDUNNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.860500",
    Latitude: "-25.318800"
  },
  {
    Country: "Australia",
    Pcode: "4659",
    Locality: "BEELBI CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.641200",
    Latitude: "-25.291400"
  },
  {
    Country: "Australia",
    Pcode: "4659",
    Locality: "BURGOWAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.676600",
    Latitude: "-25.365600"
  },
  {
    Country: "Australia",
    Pcode: "4659",
    Locality: "BURRUM HEADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.610400",
    Latitude: "-25.216100"
  },
  {
    Country: "Australia",
    Pcode: "4659",
    Locality: "BURRUM RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.601300",
    Latitude: "-25.269800"
  },
  {
    Country: "Australia",
    Pcode: "4659",
    Locality: "BURRUM TOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.586300",
    Latitude: "-25.328600"
  },
  {
    Country: "Australia",
    Pcode: "4659",
    Locality: "HOWARD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.546600",
    Latitude: "-25.315300"
  },
  {
    Country: "Australia",
    Pcode: "4659",
    Locality: "PACIFIC HAVEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.556500",
    Latitude: "-25.266800"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "ABINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.357400",
    Latitude: "-25.209500"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "APPLE TREE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.220600",
    Latitude: "-25.234600"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "BUXTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.493400",
    Latitude: "-25.186700"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "CHERWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.475400",
    Latitude: "-25.285900"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "CHILDERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.268500",
    Latitude: "-25.235500"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "CORDALBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.207300",
    Latitude: "-25.160100"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "DOOLBI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.299500",
    Latitude: "-25.229700"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "EUREKA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.154300",
    Latitude: "-25.279400"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "FARNSFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.290300",
    Latitude: "-25.112600"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "GOODWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.373200",
    Latitude: "-25.100600"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "GREGORY RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.208100",
    Latitude: "-25.103500"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "HORTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.332500",
    Latitude: "-25.228300"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "ISIS CENTRAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.180300",
    Latitude: "-25.187400"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "ISIS RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.389800",
    Latitude: "-25.275500"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "KULLOGUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.255800",
    Latitude: "-25.341000"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "NORTH GREGORY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "ISIS SHIRE",
    Category: "Delivery Area",
    Longitude: "152.296800",
    Latitude: "-25.047000"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "NORTH ISIS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.269000",
    Latitude: "-25.178900"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "PROMISEDLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.152200",
    Latitude: "-25.089500"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "REDRIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.366000",
    Latitude: "-25.165900"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "SOUTH ISIS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.300400",
    Latitude: "-25.269300"
  },
  {
    Country: "Australia",
    Pcode: "4660",
    Locality: "WOODGATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.492500",
    Latitude: "-25.117200"
  },
  {
    Country: "Australia",
    Pcode: "4662",
    Locality: "TORBANLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.573200",
    Latitude: "-25.358500"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "ABBOTSFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.008800",
    Latitude: "-24.831800"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "ALLOWAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.362900",
    Latitude: "-24.953300"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "ASHFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.397700",
    Latitude: "-24.872500"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "AVENELL HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.365500",
    Latitude: "-24.889700"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "AVOCA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.306600",
    Latitude: "-24.881200"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "AVONDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.147900",
    Latitude: "-24.772800"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BARGARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.461500",
    Latitude: "-24.829300"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BRANYAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.268300",
    Latitude: "-24.916600"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BUCCA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.100900",
    Latitude: "-24.863000"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BUNDABERG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BUNDABERG CENTRAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.350600",
    Latitude: "-24.867600"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BUNDABERG DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BUNDABERG EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.373800",
    Latitude: "-24.859100"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BUNDABERG NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.348700",
    Latitude: "-24.845100"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BUNDABERG SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.361900",
    Latitude: "-24.872300"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BUNDABERG WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.337200",
    Latitude: "-24.871900"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "BURNETT HEADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.401600",
    Latitude: "-24.775200"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "CALAVOS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.431600",
    Latitude: "-24.934400"
  },
  {
    Country: "Australia",
    Pcode: "4694",
    Locality: "ALDOGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.042800",
    Latitude: "-23.807600"
  },
  {
    Country: "Australia",
    Pcode: "4694",
    Locality: "TARGINNIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.099600",
    Latitude: "-23.744800"
  },
  {
    Country: "Australia",
    Pcode: "4694",
    Locality: "YARWUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.138800",
    Latitude: "-23.806300"
  },
  {
    Country: "Australia",
    Pcode: "4695",
    Locality: "AMBROSE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.884500",
    Latitude: "-23.811400"
  },
  {
    Country: "Australia",
    Pcode: "4695",
    Locality: "BRACEWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.923200",
    Latitude: "-23.920500"
  },
  {
    Country: "Australia",
    Pcode: "4695",
    Locality: "DARTS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.948300",
    Latitude: "-23.727100"
  },
  {
    Country: "Australia",
    Pcode: "4695",
    Locality: "EAST END",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.013700",
    Latitude: "-23.902800"
  },
  {
    Country: "Australia",
    Pcode: "4695",
    Locality: "MACHINE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.933600",
    Latitude: "-23.838300"
  },
  {
    Country: "Australia",
    Pcode: "4695",
    Locality: "MOUNT LARCOM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.001500",
    Latitude: "-23.764800"
  },
  {
    Country: "Australia",
    Pcode: "4695",
    Locality: "THE NARROWS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.951200",
    Latitude: "-23.600800"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "FUMINA SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.113800",
    Latitude: "-37.954700"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "HERNES OAK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.307600",
    Latitude: "-38.221000"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "HILL END",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.140400",
    Latitude: "-38.011700"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "JACOB CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.408800",
    Latitude: "-37.998700"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "JERICHO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.222800",
    Latitude: "-37.668100"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "MOE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.247100",
    Latitude: "-38.175000"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "MOE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.247400",
    Latitude: "-38.213500"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "MOONDARRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.354300",
    Latitude: "-38.047700"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "NEWBOROUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.293900",
    Latitude: "-38.178500"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "RAWSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.405600",
    Latitude: "-37.961700"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "TANJIL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.208700",
    Latitude: "-37.940500"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "TANJIL SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.264200",
    Latitude: "-38.101900"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "THALLOO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.309000",
    Latitude: "-38.083900"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "THOMSON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "BAW BAW",
    Category: "Delivery Area",
    Longitude: "146.347500",
    Latitude: "-37.740200"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "TOOMBON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.413700",
    Latitude: "-37.710500"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "WALHALLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.471300",
    Latitude: "-37.938300"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "WALHALLA EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.578000",
    Latitude: "-37.936500"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "WESTBURY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.223000",
    Latitude: "-38.144200"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "WILLOW GROVE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.164300",
    Latitude: "-38.077300"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "YALLOURN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.342000",
    Latitude: "-38.192800"
  },
  {
    Country: "Australia",
    Pcode: "3825",
    Locality: "YALLOURN NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.379400",
    Latitude: "-38.128900"
  },
  {
    Country: "Australia",
    Pcode: "3831",
    Locality: "NEERIM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.960700",
    Latitude: "-37.956600"
  },
  {
    Country: "Australia",
    Pcode: "3831",
    Locality: "NEERIM EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.074100",
    Latitude: "-38.000300"
  },
  {
    Country: "Australia",
    Pcode: "3831",
    Locality: "NEERIM SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.994200",
    Latitude: "-38.009800"
  },
  {
    Country: "Australia",
    Pcode: "3832",
    Locality: "NAYOOK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.930700",
    Latitude: "-37.913300"
  },
  {
    Country: "Australia",
    Pcode: "3832",
    Locality: "NEERIM JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.968500",
    Latitude: "-37.920000"
  },
  {
    Country: "Australia",
    Pcode: "3832",
    Locality: "NEERIM NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.028800",
    Latitude: "-37.922800"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "ADA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.855200",
    Latitude: "-37.840700"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "BAW BAW",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.322700",
    Latitude: "-37.837600"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "BAW BAW VILLAGE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.255700",
    Latitude: "-37.844000"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "GENTLE ANNIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.838600",
    Latitude: "-37.947400"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "ICY CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.098800",
    Latitude: "-37.883400"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "LOCH VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.008500",
    Latitude: "-37.802800"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "NOOJEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.978400",
    Latitude: "-37.860500"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "PIEDMONT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.938200",
    Latitude: "-37.895000"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "TANJIL BREN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.154500",
    Latitude: "-37.846800"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "TOORONGO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.082800",
    Latitude: "-37.782600"
  },
  {
    Country: "Australia",
    Pcode: "3833",
    Locality: "VESPER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.071800",
    Latitude: "-37.875300"
  },
  {
    Country: "Australia",
    Pcode: "3835",
    Locality: "THORPDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.180400",
    Latitude: "-38.304100"
  },
  {
    Country: "Australia",
    Pcode: "3840",
    Locality: "DRIFFIELD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.303600",
    Latitude: "-38.270700"
  },
  {
    Country: "Australia",
    Pcode: "3840",
    Locality: "HAZELWOOD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.373900",
    Latitude: "-38.288900"
  },
  {
    Country: "Australia",
    Pcode: "3840",
    Locality: "HAZELWOOD NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.478800",
    Latitude: "-38.270800"
  },
  {
    Country: "Australia",
    Pcode: "3840",
    Locality: "HAZELWOOD SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.460800",
    Latitude: "-38.318900"
  },
  {
    Country: "Australia",
    Pcode: "3840",
    Locality: "JEERALANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.460300",
    Latitude: "-38.362000"
  },
  {
    Country: "Australia",
    Pcode: "3840",
    Locality: "JEERALANG JUNCTION",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.418400",
    Latitude: "-38.348400"
  },
  {
    Country: "Australia",
    Pcode: "3840",
    Locality: "MARYVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.432100",
    Latitude: "-38.183100"
  },
  {
    Country: "Australia",
    Pcode: "3840",
    Locality: "MORWELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.409100",
    Latitude: "-38.231700"
  },
  {
    Country: "Australia",
    Pcode: "3841",
    Locality: "GIPPSLAND MC",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "3842",
    Locality: "CHURCHILL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.421800",
    Latitude: "-38.315900"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "BLACKWARRY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.617000",
    Latitude: "-38.412800"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "CALLIGNEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.578800",
    Latitude: "-38.327200"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "CALLIGNEE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.617400",
    Latitude: "-38.329600"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "CALLIGNEE SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.607400",
    Latitude: "-38.379500"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "CARRAJUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.680200",
    Latitude: "-38.380000"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "CARRAJUNG LOWER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.762200",
    Latitude: "-38.389800"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "CARRAJUNG SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.801300",
    Latitude: "-38.383400"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "FLYNN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.679200",
    Latitude: "-38.197000"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "FLYNNS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.640500",
    Latitude: "-38.255200"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "KOORNALLA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.528800",
    Latitude: "-38.353100"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "LOY YANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.578000",
    Latitude: "-38.252000"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "MOUNT TASSIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.559300",
    Latitude: "-38.388700"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "TRARALGON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.519700",
    Latitude: "-38.201900"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "TRARALGON EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.589300",
    Latitude: "-38.189000"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "TRARALGON SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.554400",
    Latitude: "-38.286000"
  },
  {
    Country: "Australia",
    Pcode: "3844",
    Locality: "TYERS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.469300",
    Latitude: "-38.138900"
  },
  {
    Country: "Australia",
    Pcode: "3847",
    Locality: "HIAMDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.740700",
    Latitude: "-38.261700"
  },
  {
    Country: "Australia",
    Pcode: "3847",
    Locality: "NAMBROK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.846900",
    Latitude: "-38.086600"
  },
  {
    Country: "Australia",
    Pcode: "3847",
    Locality: "ROSEDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.826700",
    Latitude: "-38.184200"
  },
  {
    Country: "Australia",
    Pcode: "3890",
    Locality: "NOORINBEE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.215100",
    Latitude: "-37.508800"
  },
  {
    Country: "Australia",
    Pcode: "3890",
    Locality: "NOORINBEE NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.284900",
    Latitude: "-37.445300"
  },
  {
    Country: "Australia",
    Pcode: "3890",
    Locality: "TAMBOON",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.187000",
    Latitude: "-37.684200"
  },
  {
    Country: "Australia",
    Pcode: "3890",
    Locality: "TONGHI CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.083700",
    Latitude: "-37.614300"
  },
  {
    Country: "Australia",
    Pcode: "3891",
    Locality: "GENOA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.564100",
    Latitude: "-37.488700"
  },
  {
    Country: "Australia",
    Pcode: "3891",
    Locality: "GIPSY POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.689900",
    Latitude: "-37.487100"
  },
  {
    Country: "Australia",
    Pcode: "3891",
    Locality: "MARAMINGO CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.617200",
    Latitude: "-37.396200"
  },
  {
    Country: "Australia",
    Pcode: "3891",
    Locality: "WALLAGARAUGH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.744800",
    Latitude: "-37.435600"
  },
  {
    Country: "Australia",
    Pcode: "3891",
    Locality: "WANGARABELL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.481700",
    Latitude: "-37.394500"
  },
  {
    Country: "Australia",
    Pcode: "3891",
    Locality: "WINGAN RIVER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.527700",
    Latitude: "-37.629500"
  },
  {
    Country: "Australia",
    Pcode: "3891",
    Locality: "WROXHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.491200",
    Latitude: "-37.337100"
  },
  {
    Country: "Australia",
    Pcode: "3892",
    Locality: "MALLACOOTA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.809300",
    Latitude: "-37.523900"
  },
  {
    Country: "Australia",
    Pcode: "3893",
    Locality: "DOUBLE BRIDGES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.895600",
    Latitude: "-37.596000"
  },
  {
    Country: "Australia",
    Pcode: "3893",
    Locality: "STIRLING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.709000",
    Latitude: "-37.452100"
  },
  {
    Country: "Australia",
    Pcode: "3893",
    Locality: "TAMBO CROSSING",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.833000",
    Latitude: "-37.494400"
  },
  {
    Country: "Australia",
    Pcode: "3895",
    Locality: "DOCTORS FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.735600",
    Latitude: "-37.301700"
  },
  {
    Country: "Australia",
    Pcode: "3895",
    Locality: "ENSAY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.826300",
    Latitude: "-37.399100"
  },
  {
    Country: "Australia",
    Pcode: "3895",
    Locality: "ENSAY NORTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.861500",
    Latitude: "-37.295300"
  },
  {
    Country: "Australia",
    Pcode: "3895",
    Locality: "REEDY FLAT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.928100",
    Latitude: "-37.403100"
  },
  {
    Country: "Australia",
    Pcode: "3896",
    Locality: "BINDI",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.828200",
    Latitude: "-37.110300"
  },
  {
    Country: "Australia",
    Pcode: "3896",
    Locality: "BROOKVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.648900",
    Latitude: "-37.351800"
  },
  {
    Country: "Australia",
    Pcode: "3896",
    Locality: "NUNNIONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.950400",
    Latitude: "-37.235400"
  },
  {
    Country: "Australia",
    Pcode: "3896",
    Locality: "SWIFTS CREEK",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.759200",
    Latitude: "-37.249500"
  },
  {
    Country: "Australia",
    Pcode: "3896",
    Locality: "TONGIO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.698700",
    Latitude: "-37.168700"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "ANGLERS REST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.472200",
    Latitude: "-37.018800"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "BINGO MUNJIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.562900",
    Latitude: "-37.007800"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "BUNDARA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.334900",
    Latitude: "-36.986100"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "CASSILIS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.605100",
    Latitude: "-37.203900"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "COBUNGRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.371800",
    Latitude: "-37.079600"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "DINNER PLAIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.280000",
    Latitude: "-37.033100"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "GLEN VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.519700",
    Latitude: "-36.909100"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "GLEN WILLS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.477500",
    Latitude: "-36.835600"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "HINNOMUNJIE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.657100",
    Latitude: "-37.011100"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "OMEO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.595500",
    Latitude: "-37.075100"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "OMEO VALLEY",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.616700",
    Latitude: "-36.945100"
  },
  {
    Country: "Australia",
    Pcode: "3898",
    Locality: "SHANNONVALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.423100",
    Latitude: "-36.930300"
  },
  {
    Country: "Australia",
    Pcode: "3900",
    Locality: "BENAMBRA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.801800",
    Latitude: "-36.888300"
  },
  {
    Country: "Australia",
    Pcode: "3900",
    Locality: "COBBERAS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.111600",
    Latitude: "-36.863900"
  },
  {
    Country: "Australia",
    Pcode: "3902",
    Locality: "BUMBERRAH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.831800",
    Latitude: "-37.793000"
  },
  {
    Country: "Australia",
    Pcode: "3902",
    Locality: "JOHNSONVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.812600",
    Latitude: "-37.815600"
  },
  {
    Country: "Australia",
    Pcode: "3903",
    Locality: "SWAN REACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.872200",
    Latitude: "-37.808000"
  },
  {
    Country: "Australia",
    Pcode: "3904",
    Locality: "METUNG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.838600",
    Latitude: "-37.868100"
  },
  {
    Country: "Australia",
    Pcode: "3909",
    Locality: "KALIMNA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.962400",
    Latitude: "-37.869200"
  },
  {
    Country: "Australia",
    Pcode: "3909",
    Locality: "KALIMNA WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.935900",
    Latitude: "-37.819100"
  },
  {
    Country: "Australia",
    Pcode: "3909",
    Locality: "LAKE BUNGA",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.037900",
    Latitude: "-37.862700"
  },
  {
    Country: "Australia",
    Pcode: "3909",
    Locality: "LAKE TYERS BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.065100",
    Latitude: "-37.858900"
  },
  {
    Country: "Australia",
    Pcode: "3909",
    Locality: "LAKES ENTRANCE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.001600",
    Latitude: "-37.853400"
  },
  {
    Country: "Australia",
    Pcode: "3909",
    Locality: "NUNGURNER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.898200",
    Latitude: "-37.854200"
  },
  {
    Country: "Australia",
    Pcode: "3909",
    Locality: "NYERIMILANG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.927700",
    Latitude: "-37.890100"
  },
  {
    Country: "Australia",
    Pcode: "3909",
    Locality: "TOORLOO ARM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.068200",
    Latitude: "-37.794400"
  },
  {
    Country: "Australia",
    Pcode: "3910",
    Locality: "LANGWARRIN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.195400",
    Latitude: "-38.152900"
  },
  {
    Country: "Australia",
    Pcode: "3911",
    Locality: "BAXTER",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.155600",
    Latitude: "-38.197800"
  },
  {
    Country: "Australia",
    Pcode: "3911",
    Locality: "LANGWARRIN SOUTH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "BAXTER",
    Category: "Delivery Area",
    Longitude: "145.193000",
    Latitude: "-38.188700"
  },
  {
    Country: "Australia",
    Pcode: "3912",
    Locality: "PEARCEDALE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.249400",
    Latitude: "-38.197000"
  },
  {
    Country: "Australia",
    Pcode: "3912",
    Locality: "SOMERVILLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.203800",
    Latitude: "-38.230000"
  },
  {
    Country: "Australia",
    Pcode: "3913",
    Locality: "TYABB",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.189600",
    Latitude: "-38.266300"
  },
  {
    Country: "Australia",
    Pcode: "3915",
    Locality: "HASTINGS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.171900",
    Latitude: "-38.298000"
  },
  {
    Country: "Australia",
    Pcode: "3915",
    Locality: "TUERONG",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.091500",
    Latitude: "-38.296900"
  },
  {
    Country: "Australia",
    Pcode: "3916",
    Locality: "MERRICKS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.082900",
    Latitude: "-38.393700"
  },
  {
    Country: "Australia",
    Pcode: "3916",
    Locality: "POINT LEO",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.069400",
    Latitude: "-38.415600"
  },
  {
    Country: "Australia",
    Pcode: "3916",
    Locality: "SHOREHAM",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.037100",
    Latitude: "-38.427000"
  },
  {
    Country: "Australia",
    Pcode: "3918",
    Locality: "BITTERN",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.174700",
    Latitude: "-38.338400"
  },
  {
    Country: "Australia",
    Pcode: "3919",
    Locality: "CRIB POINT",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.203900",
    Latitude: "-38.352600"
  },
  {
    Country: "Australia",
    Pcode: "3920",
    Locality: "HMAS CERBERUS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.193700",
    Latitude: "-38.381500"
  },
  {
    Country: "Australia",
    Pcode: "3921",
    Locality: "ELIZABETH ISLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.369000",
    Latitude: "-38.415000"
  },
  {
    Country: "Australia",
    Pcode: "3921",
    Locality: "FRENCH ISLAND",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.388700",
    Latitude: "-38.350900"
  },
  {
    Country: "Australia",
    Pcode: "3922",
    Locality: "COWES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.238800",
    Latitude: "-38.472000"
  },
  {
    Country: "Australia",
    Pcode: "3922",
    Locality: "SILVERLEAVES",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.273100",
    Latitude: "-38.453300"
  },
  {
    Country: "Australia",
    Pcode: "3922",
    Locality: "SMITHS BEACH",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.256000",
    Latitude: "-38.502500"
  },
  {
    Country: "Australia",
    Pcode: "3922",
    Locality: "SUMMERLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.145200",
    Latitude: "-38.507300"
  },
  {
    Country: "Australia",
    Pcode: "4003",
    Locality: "GEORGE STREET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4004",
    Locality: "SPRING HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4005",
    Locality: "NEW FARM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.046800",
    Latitude: "-27.468100"
  },
  {
    Country: "Australia",
    Pcode: "4005",
    Locality: "TENERIFFE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.047200",
    Latitude: "-27.455400"
  },
  {
    Country: "Australia",
    Pcode: "4006",
    Locality: "BOWEN HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.036000",
    Latitude: "-27.445700"
  },
  {
    Country: "Australia",
    Pcode: "4006",
    Locality: "FORTITUDE VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.036200",
    Latitude: "-27.457200"
  },
  {
    Country: "Australia",
    Pcode: "4006",
    Locality: "FORTITUDE VALLEY BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4006",
    Locality: "HERSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.022300",
    Latitude: "-27.449300"
  },
  {
    Country: "Australia",
    Pcode: "4006",
    Locality: "NEWSTEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.044900",
    Latitude: "-27.447200"
  },
  {
    Country: "Australia",
    Pcode: "4007",
    Locality: "ASCOT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.062600",
    Latitude: "-27.429600"
  },
  {
    Country: "Australia",
    Pcode: "4007",
    Locality: "HAMILTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.064100",
    Latitude: "-27.439400"
  },
  {
    Country: "Australia",
    Pcode: "4007",
    Locality: "HAMILTON CENTRAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4008",
    Locality: "BRISBANE AIRPORT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.109600",
    Latitude: "-27.386100"
  },
  {
    Country: "Australia",
    Pcode: "4008",
    Locality: "PINKENBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.142700",
    Latitude: "-27.396600"
  },
  {
    Country: "Australia",
    Pcode: "4009",
    Locality: "EAGLE FARM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.089800",
    Latitude: "-27.431300"
  },
  {
    Country: "Australia",
    Pcode: "4009",
    Locality: "EAGLE FARM BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4010",
    Locality: "ALBION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.043800",
    Latitude: "-27.433300"
  },
  {
    Country: "Australia",
    Pcode: "4010",
    Locality: "ALBION BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4010",
    Locality: "ALBION DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4011",
    Locality: "CLAYFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.054500",
    Latitude: "-27.420100"
  },
  {
    Country: "Australia",
    Pcode: "4011",
    Locality: "HENDRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.069900",
    Latitude: "-27.418300"
  },
  {
    Country: "Australia",
    Pcode: "4012",
    Locality: "NUNDAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.063700",
    Latitude: "-27.400500"
  },
  {
    Country: "Australia",
    Pcode: "4012",
    Locality: "TOOMBUL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4012",
    Locality: "WAVELL HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.047100",
    Latitude: "-27.393400"
  },
  {
    Country: "Australia",
    Pcode: "4012",
    Locality: "WAVELL HEIGHTS NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4013",
    Locality: "NORTHGATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.071600",
    Latitude: "-27.390600"
  },
  {
    Country: "Australia",
    Pcode: "4014",
    Locality: "BANYO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.083100",
    Latitude: "-27.378900"
  },
  {
    Country: "Australia",
    Pcode: "4014",
    Locality: "NUDGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.090400",
    Latitude: "-27.370600"
  },
  {
    Country: "Australia",
    Pcode: "4014",
    Locality: "NUDGEE BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.095900",
    Latitude: "-27.349400"
  },
  {
    Country: "Australia",
    Pcode: "4014",
    Locality: "VIRGINIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.063400",
    Latitude: "-27.375100"
  },
  {
    Country: "Australia",
    Pcode: "4014",
    Locality: "VIRGINIA BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4014",
    Locality: "VIRGINIA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4017",
    Locality: "BRACKEN RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.033800",
    Latitude: "-27.317100"
  },
  {
    Country: "Australia",
    Pcode: "4017",
    Locality: "BRIGHTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.057100",
    Latitude: "-27.296800"
  },
  {
    Country: "Australia",
    Pcode: "4017",
    Locality: "BRIGHTON EVENTIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4017",
    Locality: "BRIGHTON NATHAN STREET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4017",
    Locality: "DEAGON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.058900",
    Latitude: "-27.327600"
  },
  {
    Country: "Australia",
    Pcode: "4017",
    Locality: "SANDGATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.063200",
    Latitude: "-27.318100"
  },
  {
    Country: "Australia",
    Pcode: "4017",
    Locality: "SANDGATE DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4017",
    Locality: "SHORNCLIFFE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.081600",
    Latitude: "-27.327500"
  },
  {
    Country: "Australia",
    Pcode: "4018",
    Locality: "FITZGIBBON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.031800",
    Latitude: "-27.342100"
  },
  {
    Country: "Australia",
    Pcode: "4018",
    Locality: "TAIGUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.044800",
    Latitude: "-27.342000"
  },
  {
    Country: "Australia",
    Pcode: "4019",
    Locality: "CLONTARF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.074900",
    Latitude: "-27.245300"
  },
  {
    Country: "Australia",
    Pcode: "4019",
    Locality: "CLONTARF BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4019",
    Locality: "CLONTARF DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4019",
    Locality: "MARGATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.103400",
    Latitude: "-27.243000"
  },
  {
    Country: "Australia",
    Pcode: "4019",
    Locality: "MARGATE BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4019",
    Locality: "WOODY POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.101600",
    Latitude: "-27.256000"
  },
  {
    Country: "Australia",
    Pcode: "4020",
    Locality: "NEWPORT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.090300",
    Latitude: "-27.209500"
  },
  {
    Country: "Australia",
    Pcode: "4020",
    Locality: "REDCLIFFE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.107000",
    Latitude: "-27.225600"
  },
  {
    Country: "Australia",
    Pcode: "4020",
    Locality: "REDCLIFFE NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4020",
    Locality: "SCARBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.109600",
    Latitude: "-27.205000"
  },
  {
    Country: "Australia",
    Pcode: "4021",
    Locality: "KIPPA-RING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.082400",
    Latitude: "-27.221000"
  },
  {
    Country: "Australia",
    Pcode: "4022",
    Locality: "ROTHWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.053000",
    Latitude: "-27.212000"
  },
  {
    Country: "Australia",
    Pcode: "4025",
    Locality: "BULWER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.369200",
    Latitude: "-27.079000"
  },
  {
    Country: "Australia",
    Pcode: "4025",
    Locality: "COWAN COWAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.365900",
    Latitude: "-27.126100"
  },
  {
    Country: "Australia",
    Pcode: "4025",
    Locality: "KOORINGAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.420900",
    Latitude: "-27.349200"
  },
  {
    Country: "Australia",
    Pcode: "4025",
    Locality: "MORETON ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.414700",
    Latitude: "-27.175000"
  },
  {
    Country: "Australia",
    Pcode: "4025",
    Locality: "TANGALOOMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4029",
    Locality: "ROYAL BRISBANE HOSPITAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4030",
    Locality: "KALINGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.046600",
    Latitude: "-27.409300"
  },
  {
    Country: "Australia",
    Pcode: "4030",
    Locality: "LUTWYCHE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.034500",
    Latitude: "-27.420200"
  },
  {
    Country: "Australia",
    Pcode: "4030",
    Locality: "WINDSOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.030700",
    Latitude: "-27.435200"
  },
  {
    Country: "Australia",
    Pcode: "4030",
    Locality: "WOOLOOWIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.041800",
    Latitude: "-27.419600"
  },
  {
    Country: "Australia",
    Pcode: "4031",
    Locality: "GORDON PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.027400",
    Latitude: "-27.416900"
  },
  {
    Country: "Australia",
    Pcode: "4031",
    Locality: "KEDRON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.030700",
    Latitude: "-27.402900"
  },
  {
    Country: "Australia",
    Pcode: "4032",
    Locality: "CHERMSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.033200",
    Latitude: "-27.385300"
  },
  {
    Country: "Australia",
    Pcode: "4032",
    Locality: "CHERMSIDE CENTRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4157",
    Locality: "SHELDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.204200",
    Latitude: "-27.586000"
  },
  {
    Country: "Australia",
    Pcode: "4158",
    Locality: "THORNESIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.199500",
    Latitude: "-27.480900"
  },
  {
    Country: "Australia",
    Pcode: "4159",
    Locality: "BIRKDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.207800",
    Latitude: "-27.496600"
  },
  {
    Country: "Australia",
    Pcode: "4160",
    Locality: "ORMISTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.255500",
    Latitude: "-27.508100"
  },
  {
    Country: "Australia",
    Pcode: "4160",
    Locality: "WELLINGTON POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.241600",
    Latitude: "-27.483900"
  },
  {
    Country: "Australia",
    Pcode: "4161",
    Locality: "ALEXANDRA HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.229100",
    Latitude: "-27.532100"
  },
  {
    Country: "Australia",
    Pcode: "4163",
    Locality: "CLEVELAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.268900",
    Latitude: "-27.527000"
  },
  {
    Country: "Australia",
    Pcode: "4163",
    Locality: "CLEVELAND DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4164",
    Locality: "THORNLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.262500",
    Latitude: "-27.568400"
  },
  {
    Country: "Australia",
    Pcode: "4165",
    Locality: "MOUNT COTTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.220200",
    Latitude: "-27.619600"
  },
  {
    Country: "Australia",
    Pcode: "4165",
    Locality: "REDLAND BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.290700",
    Latitude: "-27.645400"
  },
  {
    Country: "Australia",
    Pcode: "4165",
    Locality: "VICTORIA POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.301900",
    Latitude: "-27.582200"
  },
  {
    Country: "Australia",
    Pcode: "4165",
    Locality: "VICTORIA POINT WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4169",
    Locality: "EAST BRISBANE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.047900",
    Latitude: "-27.483200"
  },
  {
    Country: "Australia",
    Pcode: "4169",
    Locality: "KANGAROO POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.035200",
    Latitude: "-27.474000"
  },
  {
    Country: "Australia",
    Pcode: "4170",
    Locality: "CANNON HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.092700",
    Latitude: "-27.472300"
  },
  {
    Country: "Australia",
    Pcode: "4170",
    Locality: "MORNINGSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.076600",
    Latitude: "-27.461100"
  },
  {
    Country: "Australia",
    Pcode: "4170",
    Locality: "NORMAN PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.063100",
    Latitude: "-27.478500"
  },
  {
    Country: "Australia",
    Pcode: "4170",
    Locality: "SEVEN HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.077600",
    Latitude: "-27.480200"
  },
  {
    Country: "Australia",
    Pcode: "4171",
    Locality: "BALMORAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.068700",
    Latitude: "-27.456700"
  },
  {
    Country: "Australia",
    Pcode: "4171",
    Locality: "BULIMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.060400",
    Latitude: "-27.449000"
  },
  {
    Country: "Australia",
    Pcode: "4171",
    Locality: "HAWTHORNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.058600",
    Latitude: "-27.464700"
  },
  {
    Country: "Australia",
    Pcode: "4172",
    Locality: "MURARRIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.106000",
    Latitude: "-27.448800"
  },
  {
    Country: "Australia",
    Pcode: "4173",
    Locality: "TINGALPA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.125000",
    Latitude: "-27.473600"
  },
  {
    Country: "Australia",
    Pcode: "4173",
    Locality: "TINGALPA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4174",
    Locality: "HEMMANT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.127500",
    Latitude: "-27.444000"
  },
  {
    Country: "Australia",
    Pcode: "4178",
    Locality: "LYTTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.151300",
    Latitude: "-27.420500"
  },
  {
    Country: "Australia",
    Pcode: "4178",
    Locality: "PORT OF BRISBANE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.173700",
    Latitude: "-27.385700"
  },
  {
    Country: "Australia",
    Pcode: "4178",
    Locality: "WYNNUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.168000",
    Latitude: "-27.437100"
  },
  {
    Country: "Australia",
    Pcode: "4178",
    Locality: "WYNNUM NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4178",
    Locality: "WYNNUM PLAZA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4178",
    Locality: "WYNNUM WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.153000",
    Latitude: "-27.453700"
  },
  {
    Country: "Australia",
    Pcode: "4179",
    Locality: "LOTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.193100",
    Latitude: "-27.467600"
  },
  {
    Country: "Australia",
    Pcode: "4179",
    Locality: "MANLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.184000",
    Latitude: "-27.453300"
  },
  {
    Country: "Australia",
    Pcode: "4179",
    Locality: "MANLY WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.167400",
    Latitude: "-27.465600"
  },
  {
    Country: "Australia",
    Pcode: "4183",
    Locality: "AMITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.450400",
    Latitude: "-27.400300"
  },
  {
    Country: "Australia",
    Pcode: "4183",
    Locality: "AMITY POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4183",
    Locality: "DUNWICH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.404700",
    Latitude: "-27.497800"
  },
  {
    Country: "Australia",
    Pcode: "4183",
    Locality: "NORTH STRADBROKE ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.464300",
    Latitude: "-27.525200"
  },
  {
    Country: "Australia",
    Pcode: "4183",
    Locality: "POINT LOOKOUT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.527400",
    Latitude: "-27.430800"
  },
  {
    Country: "Australia",
    Pcode: "4184",
    Locality: "COOCHIEMUDLO ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.329900",
    Latitude: "-27.569000"
  },
  {
    Country: "Australia",
    Pcode: "4184",
    Locality: "KARRAGARRA ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.372300",
    Latitude: "-27.638500"
  },
  {
    Country: "Australia",
    Pcode: "4184",
    Locality: "LAMB ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.381100",
    Latitude: "-27.624700"
  },
  {
    Country: "Australia",
    Pcode: "4184",
    Locality: "MACLEAY ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.360600",
    Latitude: "-27.604400"
  },
  {
    Country: "Australia",
    Pcode: "4184",
    Locality: "PEEL ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.355800",
    Latitude: "-27.500100"
  },
  {
    Country: "Australia",
    Pcode: "4184",
    Locality: "PERULPA ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4184",
    Locality: "RUSSELL ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.388600",
    Latitude: "-27.672400"
  },
  {
    Country: "Australia",
    Pcode: "4205",
    Locality: "BETHANIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.154900",
    Latitude: "-27.687200"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "ALBERTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.266300",
    Latitude: "-27.707600"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "BAHRS SCRUB",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.173400",
    Latitude: "-27.737800"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "BANNOCKBURN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.192100",
    Latitude: "-27.759900"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "BEENLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.194300",
    Latitude: "-27.712800"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "BELIVAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.174600",
    Latitude: "-27.758600"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "BUCCAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.139000",
    Latitude: "-27.742200"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "CEDAR CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "GOLD COAST/LOGAN",
    Category: "Delivery Area",
    Longitude: "153.196200",
    Latitude: "-27.847400"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "EAGLEBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.217900",
    Latitude: "-27.702900"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "EDENS LANDING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.169100",
    Latitude: "-27.702900"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "HOLMVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.170300",
    Latitude: "-27.712200"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "KAIRABAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.141500",
    Latitude: "-27.836700"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "LOGAN VILLAGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.116900",
    Latitude: "-27.789200"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "LUSCOMBE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.208200",
    Latitude: "-27.787800"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "MOUNT WARREN PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.205200",
    Latitude: "-27.730800"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "STAPYLTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.241400",
    Latitude: "-27.732600"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "STEIGLITZ",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.343200",
    Latitude: "-27.739700"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "WINDAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.193600",
    Latitude: "-27.745800"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "WOLFFDENE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.172700",
    Latitude: "-27.783700"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "WOONGOOLBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.320600",
    Latitude: "-27.735700"
  },
  {
    Country: "Australia",
    Pcode: "4207",
    Locality: "YARRABILBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.118000",
    Latitude: "-27.817700"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "NORTH TIVOLI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.789600",
    Latitude: "-27.580000"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "ONE MILE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "IPSWICH",
    Category: "Delivery Area",
    Longitude: "152.741000",
    Latitude: "-27.634600"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "RACEVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.773100",
    Latitude: "-27.638000"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "SADLIERS CROSSING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.745300",
    Latitude: "-27.614100"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "TIVOLI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.775800",
    Latitude: "-27.586400"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "WEST IPSWICH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.749000",
    Latitude: "-27.620200"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "WOODEND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.753100",
    Latitude: "-27.604700"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "WULKURAKA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.721800",
    Latitude: "-27.614300"
  },
  {
    Country: "Australia",
    Pcode: "4305",
    Locality: "YAMANTO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.742700",
    Latitude: "-27.649700"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "AMBERLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.698000",
    Latitude: "-27.630300"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "BANKS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.719900",
    Latitude: "-27.401300"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "BARELLAN POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.844600",
    Latitude: "-27.571800"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "BLACKSOIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.706400",
    Latitude: "-27.577700"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "BORALLON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.702900",
    Latitude: "-27.499600"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "CHUWAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.776000",
    Latitude: "-27.555800"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "DEEBING HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.762000",
    Latitude: "-27.684900"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "DUNDAS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.672800",
    Latitude: "-27.282100"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "ENGLAND CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.708300",
    Latitude: "-27.360400"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "FAIRNEY VIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.652400",
    Latitude: "-27.484400"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "FERNVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.659600",
    Latitude: "-27.456100"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "GLAMORGAN VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.619200",
    Latitude: "-27.510800"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "GOOLMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.767800",
    Latitude: "-27.731500"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "HAIGSLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.632600",
    Latitude: "-27.560600"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "IRONBARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.674200",
    Latitude: "-27.556300"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "KARALEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.825300",
    Latitude: "-27.558600"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "KARANA DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.823300",
    Latitude: "-27.542700"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "KARRABIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.703700",
    Latitude: "-27.596100"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "KHOLO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.787400",
    Latitude: "-27.505700"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "LAKE MANCHESTER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.757600",
    Latitude: "-27.438600"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "LARK HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.593000",
    Latitude: "-27.524600"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "MOUNT CROSBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.790500",
    Latitude: "-27.532700"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "MOUNT MARROW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.618700",
    Latitude: "-27.597800"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "MUIRLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.735200",
    Latitude: "-27.569300"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "PEAK CROSSING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.748900",
    Latitude: "-27.785800"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "PINE MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.711300",
    Latitude: "-27.535100"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "PURGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.712900",
    Latitude: "-27.698000"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "RIPLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.779300",
    Latitude: "-27.672000"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "SOUTH RIPLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.818300",
    Latitude: "-27.721500"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "SPLIT YARD CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.653500",
    Latitude: "-27.377200"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "SWANBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.818900",
    Latitude: "-27.660800"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "THAGOONA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.631400",
    Latitude: "-27.626800"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "VERNOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.617500",
    Latitude: "-27.470100"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "WALLOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.664000",
    Latitude: "-27.606400"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "WANORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.668300",
    Latitude: "-27.517500"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "WASHPOOL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.766800",
    Latitude: "-27.837300"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "WHITE ROCK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "IPSWICH",
    Category: "Delivery Area",
    Longitude: "152.850600",
    Latitude: "-27.695100"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "WILLOWBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.674600",
    Latitude: "-27.681800"
  },
  {
    Country: "Australia",
    Pcode: "4306",
    Locality: "WIVENHOE POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.621400",
    Latitude: "-27.428700"
  },
  {
    Country: "Australia",
    Pcode: "4307",
    Locality: "COLEYVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.572700",
    Latitude: "-27.803500"
  },
  {
    Country: "Australia",
    Pcode: "4307",
    Locality: "HARRISVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.674000",
    Latitude: "-27.809700"
  },
  {
    Country: "Australia",
    Pcode: "4307",
    Locality: "MUTDAPILLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.658900",
    Latitude: "-27.745000"
  },
  {
    Country: "Australia",
    Pcode: "4307",
    Locality: "RADFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.630000",
    Latitude: "-27.850200"
  },
  {
    Country: "Australia",
    Pcode: "4307",
    Locality: "SILVERDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.589200",
    Latitude: "-27.888100"
  },
  {
    Country: "Australia",
    Pcode: "4307",
    Locality: "WARRILL VIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.606300",
    Latitude: "-27.827600"
  },
  {
    Country: "Australia",
    Pcode: "4307",
    Locality: "WILSONS PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.659000",
    Latitude: "-27.841500"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "ARATULA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.538400",
    Latitude: "-27.984000"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "CHARLWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.577200",
    Latitude: "-28.008800"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "CLUMBER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.454500",
    Latitude: "-28.079100"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "FASSIFERN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.588900",
    Latitude: "-27.956000"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "FASSIFERN VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.607100",
    Latitude: "-27.972000"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "FRAZERVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.530500",
    Latitude: "-27.937300"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "KALBAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.611000",
    Latitude: "-27.938700"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "KENTS LAGOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.623200",
    Latitude: "-27.901500"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "KULGUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.683000",
    Latitude: "-27.930400"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "MILORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.690100",
    Latitude: "-27.847600"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "MOOGERAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.506100",
    Latitude: "-28.104400"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "MORWINCHA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.579800",
    Latitude: "-27.970000"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "MOUNT EDWARDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.524400",
    Latitude: "-28.016300"
  },
  {
    Country: "Australia",
    Pcode: "4309",
    Locality: "MUNBILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.653000",
    Latitude: "-27.883700"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "TOOWOOMBA EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "TOOWOOMBA SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "TOOWOOMBA VILLAGE FAIR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "TOOWOOMBA WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "TOP CAMP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.939600",
    Latitude: "-27.633200"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "TORRINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.887200",
    Latitude: "-27.543000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "WELLCAMP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.822000",
    Latitude: "-27.551100"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "WESTBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.841700",
    Latitude: "-27.604000"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "WILSONTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.917700",
    Latitude: "-27.541700"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "WILSONTON HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.926800",
    Latitude: "-27.532600"
  },
  {
    Country: "Australia",
    Pcode: "4350",
    Locality: "WYALLA PLAZA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "BALLARD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.980600",
    Latitude: "-27.499100"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "BAPAUME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.843800",
    Latitude: "-28.576100"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "BIRNAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "151.924200",
    Latitude: "-27.493200"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "BLANCHVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.028800",
    Latitude: "-27.576800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "BRANCHVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.349400",
    Latitude: "-27.617900"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "CABARLAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.997400",
    Latitude: "-27.438800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "CAWDOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.899500",
    Latitude: "-27.461400"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "CEMENT MILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.591300",
    Latitude: "-28.404000"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "COALBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.862000",
    Latitude: "-27.126300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "CONDAMINE PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.291700",
    Latitude: "-27.670900"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "CUTELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.818500",
    Latitude: "-27.455800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "DERRYMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.059600",
    Latitude: "-27.588200"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "DJUAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.905300",
    Latitude: "-27.185100"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "DOCTOR CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.818600",
    Latitude: "-27.175600"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "EVERGREEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.736400",
    Latitude: "-27.150500"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "FIFTEEN MILE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.053700",
    Latitude: "-27.421800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "GEHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.991700",
    Latitude: "-27.388600"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "GLENCOE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.862200",
    Latitude: "-27.462900"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "GORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.410400",
    Latitude: "-28.276700"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "GOWRIE JUNCTION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.881300",
    Latitude: "-27.494200"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "GOWRIE LITTLE PLAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.844500",
    Latitude: "-27.430800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "GRAPETREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.095400",
    Latitude: "-27.319800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "GROOMSVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.967800",
    Latitude: "-27.346300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "HAMPTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.068900",
    Latitude: "-27.372300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "HIGHFIELDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.950100",
    Latitude: "-27.465700"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "HIGHGROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.778500",
    Latitude: "-27.116500"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "HODGSON VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.947400",
    Latitude: "-27.678800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "KARARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.586100",
    Latitude: "-28.187600"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "KLEINTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.953600",
    Latitude: "-27.415300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "KULPI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.696200",
    Latitude: "-27.180700"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "KURROWAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.173800",
    Latitude: "-27.719600"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "LILYVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.875500",
    Latitude: "-27.430800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "MACLAGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.681200",
    Latitude: "-27.055300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "MALLING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.600700",
    Latitude: "-27.061200"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "MERINGANDAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.915000",
    Latitude: "-27.403500"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "MERINGANDAN WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.886200",
    Latitude: "-27.414700"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "MERRITTS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.028900",
    Latitude: "-27.354700"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "MOUNT LUKE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.038600",
    Latitude: "-27.386400"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "MUNIGANEEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.876000",
    Latitude: "-27.376700"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "MURPHYS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.045300",
    Latitude: "-27.460200"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "NARKO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.720600",
    Latitude: "-27.094900"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "NORTH MACLAGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.682700",
    Latitude: "-27.029800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "NUTGROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.755500",
    Latitude: "-27.052800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "OMAN AMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.335200",
    Latitude: "-28.421900"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "PALMTREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.139600",
    Latitude: "-27.409300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "PAMPAS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.390000",
    Latitude: "-27.782000"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "PECHEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.038000",
    Latitude: "-27.313000"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "PERANGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.679300",
    Latitude: "-27.134400"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "PERSEVERANCE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.108900",
    Latitude: "-27.375600"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "POSTMANS RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.056100",
    Latitude: "-27.528600"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "POZIERES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.885400",
    Latitude: "-28.527300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "PRESTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.966000",
    Latitude: "-27.650000"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "RANGEMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOOWOOMBA",
    Category: "Delivery Area",
    Longitude: "151.668400",
    Latitude: "-26.993500"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "RAVENSBOURNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.156800",
    Latitude: "-27.340300"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "SILVER RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.047200",
    Latitude: "-27.612800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "SPRING BLUFF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.972500",
    Latitude: "-27.464800"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "ST AUBYN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.900200",
    Latitude: "-27.077600"
  },
  {
    Country: "Australia",
    Pcode: "4352",
    Locality: "THORNVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.829900",
    Latitude: "-27.072800"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "NORTH BRANCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "SOUTHERN DOWNS",
    Category: "Delivery Area",
    Longitude: "152.308500",
    Latitude: "-28.021100"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "PRATTEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.758600",
    Latitude: "-28.091300"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "RODGERS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.833600",
    Latitude: "-28.223800"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "ROSEHILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.993800",
    Latitude: "-28.183500"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "ROSENTHAL HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.970200",
    Latitude: "-28.243900"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "SILVERWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.007200",
    Latitude: "-28.350900"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "SLADEVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.085900",
    Latitude: "-28.164700"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "SWAN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.140500",
    Latitude: "-28.197900"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "THANE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.702700",
    Latitude: "-28.159700"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "THANES CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.685100",
    Latitude: "-28.118100"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "THE GLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.908300",
    Latitude: "-28.335800"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "THE HERMITAGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.108000",
    Latitude: "-28.206600"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "TOOLBURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.965600",
    Latitude: "-28.165800"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "TREGONY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.354400",
    Latitude: "-28.070800"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "UPPER FREESTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.220900",
    Latitude: "-28.120900"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "UPPER WHEATVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.873800",
    Latitude: "-28.139900"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "WARWICK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.024600",
    Latitude: "-28.216700"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "WARWICK DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "WHEATVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.868500",
    Latitude: "-28.164500"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "WILDASH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.066000",
    Latitude: "-28.337100"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "WILLOWVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.028100",
    Latitude: "-28.129800"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "WIYARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.213300",
    Latitude: "-28.275500"
  },
  {
    Country: "Australia",
    Pcode: "4370",
    Locality: "WOMINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.040800",
    Latitude: "-28.179800"
  },
  {
    Country: "Australia",
    Pcode: "4371",
    Locality: "EMU VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.360800",
    Latitude: "-28.193400"
  },
  {
    Country: "Australia",
    Pcode: "4371",
    Locality: "SWANFELS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.333800",
    Latitude: "-28.140500"
  },
  {
    Country: "Australia",
    Pcode: "4371",
    Locality: "YANGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.212600",
    Latitude: "-28.200900"
  },
  {
    Country: "Australia",
    Pcode: "4372",
    Locality: "TANNYMOREL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.239900",
    Latitude: "-28.307900"
  },
  {
    Country: "Australia",
    Pcode: "4373",
    Locality: "KILLARNEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.299900",
    Latitude: "-28.348300"
  },
  {
    Country: "Australia",
    Pcode: "4373",
    Locality: "THE FALLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.394400",
    Latitude: "-28.311400"
  },
  {
    Country: "Australia",
    Pcode: "4373",
    Locality: "THE HEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.427100",
    Latitude: "-28.261000"
  },
  {
    Country: "Australia",
    Pcode: "4374",
    Locality: "DALVEEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.936000",
    Latitude: "-28.449900"
  },
  {
    Country: "Australia",
    Pcode: "4375",
    Locality: "COTTONVALE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.972000",
    Latitude: "-28.523600"
  },
  {
    Country: "Australia",
    Pcode: "4375",
    Locality: "COTTONVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.939800",
    Latitude: "-28.518300"
  },
  {
    Country: "Australia",
    Pcode: "4375",
    Locality: "FLEURBAIX",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.914100",
    Latitude: "-28.528300"
  },
  {
    Country: "Australia",
    Pcode: "4376",
    Locality: "THULIMBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.933400",
    Latitude: "-28.554600"
  },
  {
    Country: "Australia",
    Pcode: "4377",
    Locality: "GLEN NIVEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.972800",
    Latitude: "-28.588600"
  },
  {
    Country: "Australia",
    Pcode: "4377",
    Locality: "MARYLAND",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: "TENTERFIELD",
    Category: "Delivery Area",
    Longitude: "152.044600",
    Latitude: "-28.532900"
  },
  {
    Country: "Australia",
    Pcode: "4377",
    Locality: "THE SUMMIT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.928900",
    Latitude: "-28.580300"
  },
  {
    Country: "Australia",
    Pcode: "4378",
    Locality: "APPLETHORPE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.935400",
    Latitude: "-28.616100"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "AMIENS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.817400",
    Latitude: "-28.598500"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "AMOSFIELD",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.057600",
    Latitude: "-28.660900"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "BROADWATER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.870700",
    Latitude: "-28.661200"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "CANNON CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "SOUTHERN DOWNS",
    Category: "Delivery Area",
    Longitude: "151.877000",
    Latitude: "-28.607200"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "DALCOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.990300",
    Latitude: "-28.657400"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "DIAMONDVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.959600",
    Latitude: "-28.663500"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "EUKEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.973100",
    Latitude: "-28.787700"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "GLENLYON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.452000",
    Latitude: "-28.891600"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "GREENLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.787000",
    Latitude: "-28.653200"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "KYOOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.984800",
    Latitude: "-28.694900"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "MINGOOLA",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.546600",
    Latitude: "-29.008700"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "MINGOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.532400",
    Latitude: "-28.938900"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "MOUNT TULLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.945500",
    Latitude: "-28.743500"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "NUNDUBBERMERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.772700",
    Latitude: "-28.731100"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "PIKEDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.639400",
    Latitude: "-28.638800"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "PIKES CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.558100",
    Latitude: "-28.723100"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "RUBY CREEK",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.021800",
    Latitude: "-28.628900"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "SEVERNLEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.916200",
    Latitude: "-28.704300"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "SPRINGDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.575300",
    Latitude: "-28.800600"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "STANTHORPE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.935400",
    Latitude: "-28.660600"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "STORM KING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.979500",
    Latitude: "-28.727500"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "SUGARLOAF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "SOUTHERN DOWNS",
    Category: "Delivery Area",
    Longitude: "152.035700",
    Latitude: "-28.711000"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "THORNDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.859100",
    Latitude: "-28.702700"
  },
  {
    Country: "Australia",
    Pcode: "4380",
    Locality: "UNDERCLIFFE",
    State: "New South Wales",
    StateAbbrev: "NSW",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.179300",
    Latitude: "-28.623800"
  },
  {
    Country: "Australia",
    Pcode: "4381",
    Locality: "FLETCHER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.871500",
    Latitude: "-28.768400"
  },
  {
    Country: "Australia",
    Pcode: "4381",
    Locality: "GLEN APLIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.878100",
    Latitude: "-28.738900"
  },
  {
    Country: "Australia",
    Pcode: "4382",
    Locality: "BALLANDEAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.816000",
    Latitude: "-28.875500"
  },
  {
    Country: "Australia",
    Pcode: "4382",
    Locality: "GIRRAWEEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.969200",
    Latitude: "-28.857900"
  },
  {
    Country: "Australia",
    Pcode: "4382",
    Locality: "LYRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.861800",
    Latitude: "-28.831300"
  },
  {
    Country: "Australia",
    Pcode: "4420",
    Locality: "HORNET BANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4420",
    Locality: "SPRING CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "BANANA",
    Category: "Delivery Area",
    Longitude: "150.036400",
    Latitude: "-25.412800"
  },
  {
    Country: "Australia",
    Pcode: "4420",
    Locality: "TAROOM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.878600",
    Latitude: "-25.644600"
  },
  {
    Country: "Australia",
    Pcode: "4421",
    Locality: "GORANBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.640400",
    Latitude: "-27.286700"
  },
  {
    Country: "Australia",
    Pcode: "4421",
    Locality: "TARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.447600",
    Latitude: "-27.281500"
  },
  {
    Country: "Australia",
    Pcode: "4422",
    Locality: "COOMRITH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.594600",
    Latitude: "-27.597700"
  },
  {
    Country: "Australia",
    Pcode: "4422",
    Locality: "FLINTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.551100",
    Latitude: "-27.874200"
  },
  {
    Country: "Australia",
    Pcode: "4422",
    Locality: "INGLESTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.801600",
    Latitude: "-27.615000"
  },
  {
    Country: "Australia",
    Pcode: "4422",
    Locality: "MEANDARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.864400",
    Latitude: "-27.276600"
  },
  {
    Country: "Australia",
    Pcode: "4422",
    Locality: "WESTMAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.758500",
    Latitude: "-27.887200"
  },
  {
    Country: "Australia",
    Pcode: "4423",
    Locality: "GLENMORGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.604800",
    Latitude: "-27.243800"
  },
  {
    Country: "Australia",
    Pcode: "4423",
    Locality: "TEELBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.346400",
    Latitude: "-27.496900"
  },
  {
    Country: "Australia",
    Pcode: "4424",
    Locality: "DRILLHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.927800",
    Latitude: "-26.630800"
  },
  {
    Country: "Australia",
    Pcode: "4424",
    Locality: "DRILLHAM SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.990300",
    Latitude: "-26.791600"
  },
  {
    Country: "Australia",
    Pcode: "4424",
    Locality: "GLENAUBYN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.925700",
    Latitude: "-26.487800"
  },
  {
    Country: "Australia",
    Pcode: "4425",
    Locality: "BOGANDILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.770200",
    Latitude: "-26.483000"
  },
  {
    Country: "Australia",
    Pcode: "4425",
    Locality: "DULACCA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.766200",
    Latitude: "-26.695300"
  },
  {
    Country: "Australia",
    Pcode: "4426",
    Locality: "JACKSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.650700",
    Latitude: "-26.643100"
  },
  {
    Country: "Australia",
    Pcode: "4426",
    Locality: "JACKSON NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.661500",
    Latitude: "-26.456900"
  },
  {
    Country: "Australia",
    Pcode: "4426",
    Locality: "JACKSON SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.567200",
    Latitude: "-26.727900"
  },
  {
    Country: "Australia",
    Pcode: "4427",
    Locality: "CLIFFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.390100",
    Latitude: "-26.160000"
  },
  {
    Country: "Australia",
    Pcode: "4427",
    Locality: "YULEBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.388100",
    Latitude: "-26.610700"
  },
  {
    Country: "Australia",
    Pcode: "4427",
    Locality: "YULEBA NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.461300",
    Latitude: "-26.465600"
  },
  {
    Country: "Australia",
    Pcode: "4427",
    Locality: "YULEBA SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.381500",
    Latitude: "-26.745600"
  },
  {
    Country: "Australia",
    Pcode: "4428",
    Locality: "PICKANJINNIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.102500",
    Latitude: "-26.463100"
  },
  {
    Country: "Australia",
    Pcode: "4428",
    Locality: "WALLUMBILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.184400",
    Latitude: "-26.593500"
  },
  {
    Country: "Australia",
    Pcode: "4428",
    Locality: "WALLUMBILLA NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.256500",
    Latitude: "-26.447600"
  },
  {
    Country: "Australia",
    Pcode: "4428",
    Locality: "WALLUMBILLA SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.184000",
    Latitude: "-26.765700"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "BAFFLE WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.619300",
    Latitude: "-25.616500"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "BEILBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.869700",
    Latitude: "-25.644200"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "DURHAM DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.063800",
    Latitude: "-26.100700"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "HIGHLAND PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MARANOA",
    Category: "Delivery Area",
    Longitude: "148.767500",
    Latitude: "-25.867000"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "HUTTON CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.428100",
    Latitude: "-25.672600"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "INJUNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.549500",
    Latitude: "-25.859100"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "MOUNT HUTTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.355500",
    Latitude: "-25.895100"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "PONY HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.969000",
    Latitude: "-25.889300"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "SIMMIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.589000",
    Latitude: "-25.716400"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "UPPER DAWSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.627000",
    Latitude: "-25.411000"
  },
  {
    Country: "Australia",
    Pcode: "4454",
    Locality: "WESTGROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.453600",
    Latitude: "-25.461900"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "BALLAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.511800",
    Latitude: "-27.190600"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "BLYTHDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.990400",
    Latitude: "-26.517000"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "BUNGEWORGORAI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.603800",
    Latitude: "-26.453500"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "BUNGIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.768500",
    Latitude: "-26.661100"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "BYMOUNT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.648600",
    Latitude: "-26.091500"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "CORNWALL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.456100",
    Latitude: "-26.090400"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "DARGAL ROAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.724000",
    Latitude: "-26.554700"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "EUMAMURRIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.789500",
    Latitude: "-26.144500"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "EUTHULLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.812000",
    Latitude: "-26.432100"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "GUNNEWIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.586100",
    Latitude: "-25.994900"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "HODGSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.628600",
    Latitude: "-26.559800"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "MOOGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.017200",
    Latitude: "-26.367900"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "MOUNT ABUNDANCE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.492700",
    Latitude: "-26.778700"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "MOUNT BINDANGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.461300",
    Latitude: "-26.500000"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "ORALLO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.515800",
    Latitude: "-26.304600"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "ORANGE HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.791000",
    Latitude: "-26.486600"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "ROMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.802300",
    Latitude: "-26.561800"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "TINGUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.870900",
    Latitude: "-26.804400"
  },
  {
    Country: "Australia",
    Pcode: "4455",
    Locality: "WYCOMBE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.598500",
    Latitude: "-27.507000"
  },
  {
    Country: "Australia",
    Pcode: "4461",
    Locality: "MUCKADILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.371900",
    Latitude: "-26.621600"
  },
  {
    Country: "Australia",
    Pcode: "4462",
    Locality: "AMBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.172300",
    Latitude: "-26.535300"
  },
  {
    Country: "Australia",
    Pcode: "4465",
    Locality: "DUNKELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.014900",
    Latitude: "-27.200700"
  },
  {
    Country: "Australia",
    Pcode: "4465",
    Locality: "FORESTVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.882800",
    Latitude: "-26.052800"
  },
  {
    Country: "Australia",
    Pcode: "4465",
    Locality: "MITCHELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.968000",
    Latitude: "-26.462000"
  },
  {
    Country: "Australia",
    Pcode: "4465",
    Locality: "V GATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.802600",
    Latitude: "-26.894900"
  },
  {
    Country: "Australia",
    Pcode: "4465",
    Locality: "WOMALILLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.821600",
    Latitude: "-26.581200"
  },
  {
    Country: "Australia",
    Pcode: "4467",
    Locality: "MUNGALLALA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.469400",
    Latitude: "-26.479400"
  },
  {
    Country: "Australia",
    Pcode: "4467",
    Locality: "REDFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.375700",
    Latitude: "-25.948000"
  },
  {
    Country: "Australia",
    Pcode: "4467",
    Locality: "TYRCONNEL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.561300",
    Latitude: "-26.272400"
  },
  {
    Country: "Australia",
    Pcode: "4468",
    Locality: "CLARA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.863200",
    Latitude: "-26.114300"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "MOUNT KILCOY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.574800",
    Latitude: "-26.847500"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "ROYSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.660400",
    Latitude: "-26.921600"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "SANDY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.642500",
    Latitude: "-26.841600"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "SHEEP STATION CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.473600",
    Latitude: "-26.824300"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "WINYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.582400",
    Latitude: "-26.937700"
  },
  {
    Country: "Australia",
    Pcode: "4515",
    Locality: "WOOLMAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.504700",
    Latitude: "-26.938600"
  },
  {
    Country: "Australia",
    Pcode: "4516",
    Locality: "ELIMBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.969800",
    Latitude: "-26.999600"
  },
  {
    Country: "Australia",
    Pcode: "4517",
    Locality: "BEERBURRUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.971500",
    Latitude: "-26.952100"
  },
  {
    Country: "Australia",
    Pcode: "4518",
    Locality: "GLASS HOUSE MOUNTAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.849800",
    Latitude: "-26.889700"
  },
  {
    Country: "Australia",
    Pcode: "4519",
    Locality: "BEERWAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.960400",
    Latitude: "-26.857200"
  },
  {
    Country: "Australia",
    Pcode: "4519",
    Locality: "COOCHIN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.056300",
    Latitude: "-26.891100"
  },
  {
    Country: "Australia",
    Pcode: "4519",
    Locality: "CROHAMHURST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.861000",
    Latitude: "-26.806900"
  },
  {
    Country: "Australia",
    Pcode: "4519",
    Locality: "PEACHESTER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.868100",
    Latitude: "-26.840700"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "ARMSTRONG CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.795200",
    Latitude: "-27.228100"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "CAMP MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.879500",
    Latitude: "-27.397700"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "CEDAR CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MORETON BAY",
    Category: "Delivery Area",
    Longitude: "152.819000",
    Latitude: "-27.334000"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "CLOSEBURN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.864700",
    Latitude: "-27.327800"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "DRAPER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.916800",
    Latitude: "-27.357800"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "ENOGGERA RESERVOIR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.865700",
    Latitude: "-27.429800"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "HIGHVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.811900",
    Latitude: "-27.372900"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "JOLLYS LOOKOUT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.826900",
    Latitude: "-27.410100"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "KOBBLE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.796600",
    Latitude: "-27.267800"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "MOUNT GLORIOUS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.764200",
    Latitude: "-27.322800"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "MOUNT NEBO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.781000",
    Latitude: "-27.376100"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "MOUNT SAMSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.847700",
    Latitude: "-27.300500"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "SAMFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "SAMFORD VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.855800",
    Latitude: "-27.370400"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "SAMFORD VILLAGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.884600",
    Latitude: "-27.373500"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "SAMSONVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.858000",
    Latitude: "-27.248000"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "WIGHTS MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.847800",
    Latitude: "-27.391400"
  },
  {
    Country: "Australia",
    Pcode: "4520",
    Locality: "YUGAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.872200",
    Latitude: "-27.344400"
  },
  {
    Country: "Australia",
    Pcode: "4521",
    Locality: "CAMPBELLS POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.804800",
    Latitude: "-27.075500"
  },
  {
    Country: "Australia",
    Pcode: "4521",
    Locality: "DAYBORO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.820900",
    Latitude: "-27.201700"
  },
  {
    Country: "Australia",
    Pcode: "4521",
    Locality: "KING SCRUB",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.823900",
    Latitude: "-27.167900"
  },
  {
    Country: "Australia",
    Pcode: "4521",
    Locality: "LACEYS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.742600",
    Latitude: "-27.201600"
  },
  {
    Country: "Australia",
    Pcode: "4521",
    Locality: "MOUNT MEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.741800",
    Latitude: "-27.067100"
  },
  {
    Country: "Australia",
    Pcode: "4521",
    Locality: "MOUNT PLEASANT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MORETON BAY",
    Category: "Delivery Area",
    Longitude: "152.769100",
    Latitude: "-27.147700"
  },
  {
    Country: "Australia",
    Pcode: "4521",
    Locality: "OCEAN VIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.797600",
    Latitude: "-27.129500"
  },
  {
    Country: "Australia",
    Pcode: "4521",
    Locality: "RUSH CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.862800",
    Latitude: "-27.204900"
  },
  {
    Country: "Australia",
    Pcode: "4550",
    Locality: "LANDSBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.988000",
    Latitude: "-26.810500"
  },
  {
    Country: "Australia",
    Pcode: "4550",
    Locality: "MOUNT MELLUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.930000",
    Latitude: "-26.815000"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "AROONA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.115900",
    Latitude: "-26.782400"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "BARINGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "BATTERY HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.129000",
    Latitude: "-26.779300"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "BELLS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.062100",
    Latitude: "-26.815300"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "CALOUNDRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.128000",
    Latitude: "-26.795200"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "CALOUNDRA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "CALOUNDRA WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.100200",
    Latitude: "-26.806200"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "CURRIMUNDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.123600",
    Latitude: "-26.767300"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "DICKY BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.135500",
    Latitude: "-26.782700"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "GOLDEN BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.121000",
    Latitude: "-26.820800"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "KINGS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.144000",
    Latitude: "-26.802000"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "LITTLE MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.094600",
    Latitude: "-26.785700"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "MERIDAN PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.086000",
    Latitude: "-26.763100"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "MOFFAT BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.135900",
    Latitude: "-26.793300"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "PELICAN WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.101100",
    Latitude: "-26.832500"
  },
  {
    Country: "Australia",
    Pcode: "4551",
    Locality: "SHELLY BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.145900",
    Latitude: "-26.796300"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "BALD KNOB",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.908900",
    Latitude: "-26.777900"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "BALMORAL RIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.891600",
    Latitude: "-26.753200"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "BOOROOBIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.784800",
    Latitude: "-26.820400"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "CAMBROON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.674500",
    Latitude: "-26.652500"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "CONONDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.670900",
    Latitude: "-26.743200"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "CRYSTAL WATERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "CURRAMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.732200",
    Latitude: "-26.676000"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "ELAMAN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.782400",
    Latitude: "-26.719600"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "HARPER CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "MALENY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.852600",
    Latitude: "-26.765000"
  },
  {
    Country: "Australia",
    Pcode: "4552",
    Locality: "NORTH MALENY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.872900",
    Latitude: "-26.726900"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "LOWER WONGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.442800",
    Latitude: "-26.110000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "MARODIAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.287500",
    Latitude: "-25.843600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "MARYS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.583800",
    Latitude: "-26.246200"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "MCINTOSH CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.640900",
    Latitude: "-26.260700"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "MELAWONDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.660900",
    Latitude: "-26.417300"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "MIVA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.469700",
    Latitude: "-25.955600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "MONKLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.685000",
    Latitude: "-26.213200"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "MOOLOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.615100",
    Latitude: "-26.300500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "MOTHAR MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.759700",
    Latitude: "-26.234000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "MUNNA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.451800",
    Latitude: "-25.885700"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "NAHRUNDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.618600",
    Latitude: "-26.207000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "NEERDIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.715000",
    Latitude: "-25.953400"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "NEUSA VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.845200",
    Latitude: "-26.195500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "NORTH DEEP CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.693900",
    Latitude: "-26.089500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "PATERSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.526000",
    Latitude: "-25.888700"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "PIE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.618100",
    Latitude: "-26.232100"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "ROSS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.746400",
    Latitude: "-26.097300"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "SCOTCHY POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.530700",
    Latitude: "-26.003800"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "SCRUBBY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "GYMPIE",
    Category: "Delivery Area",
    Longitude: "152.573900",
    Latitude: "-26.223700"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "SEXTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.466500",
    Latitude: "-26.039800"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "SOUTHSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.649600",
    Latitude: "-26.208600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "TAMAREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.660100",
    Latitude: "-26.135100"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "TANDUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.763400",
    Latitude: "-26.283500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "THE DAWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.684600",
    Latitude: "-26.248900"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "THE PALMS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.583200",
    Latitude: "-26.189000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "THEEBINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.532300",
    Latitude: "-25.940500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "TOOLARA FOREST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.860100",
    Latitude: "-26.010200"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "TRAVESTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.760900",
    Latitude: "-26.323300"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "TUCHEKOI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.755600",
    Latitude: "-26.405600"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "TWO MILE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.632000",
    Latitude: "-26.170200"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "UPPER GLASTONBURY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.490400",
    Latitude: "-26.298000"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "UPPER KANDANGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.480500",
    Latitude: "-26.435100"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "VETERAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.706300",
    Latitude: "-26.141800"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "VICTORY HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.692000",
    Latitude: "-26.171800"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "WALLU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.904200",
    Latitude: "-25.950400"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "WIDGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.404300",
    Latitude: "-26.243200"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "WIDGEE CROSSING NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.615600",
    Latitude: "-26.183100"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "WIDGEE CROSSING SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.612000",
    Latitude: "-26.197900"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "WILSONS POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.801300",
    Latitude: "-26.136700"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "WOLVI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.844200",
    Latitude: "-26.144800"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "WOOLOOGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.354200",
    Latitude: "-25.978500"
  },
  {
    Country: "Australia",
    Pcode: "4570",
    Locality: "WOONDUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.728300",
    Latitude: "-26.252100"
  },
  {
    Country: "Australia",
    Pcode: "4571",
    Locality: "COMO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.976700",
    Latitude: "-26.202200"
  },
  {
    Country: "Australia",
    Pcode: "4571",
    Locality: "KIN KIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.870900",
    Latitude: "-26.257200"
  },
  {
    Country: "Australia",
    Pcode: "4572",
    Locality: "ALEXANDRA HEADLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.101700",
    Latitude: "-26.669600"
  },
  {
    Country: "Australia",
    Pcode: "4573",
    Locality: "COOLUM BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.068700",
    Latitude: "-26.537700"
  },
  {
    Country: "Australia",
    Pcode: "4573",
    Locality: "MARCUS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.092200",
    Latitude: "-26.448500"
  },
  {
    Country: "Australia",
    Pcode: "4573",
    Locality: "MOUNT COOLUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.081200",
    Latitude: "-26.566600"
  },
  {
    Country: "Australia",
    Pcode: "4573",
    Locality: "PEREGIAN BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.075000",
    Latitude: "-26.474500"
  },
  {
    Country: "Australia",
    Pcode: "4573",
    Locality: "PEREGIAN SPRINGS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.069600",
    Latitude: "-26.494900"
  },
  {
    Country: "Australia",
    Pcode: "4573",
    Locality: "POINT ARKWRIGHT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.098200",
    Latitude: "-26.546600"
  },
  {
    Country: "Australia",
    Pcode: "4573",
    Locality: "YAROOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.091700",
    Latitude: "-26.552300"
  },
  {
    Country: "Australia",
    Pcode: "4574",
    Locality: "COOLABINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.776000",
    Latitude: "-26.602300"
  },
  {
    Country: "Australia",
    Pcode: "4574",
    Locality: "GHEERULLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.820600",
    Latitude: "-26.576600"
  },
  {
    Country: "Australia",
    Pcode: "4574",
    Locality: "KENILWORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.616600",
    Latitude: "-26.635200"
  },
  {
    Country: "Australia",
    Pcode: "4574",
    Locality: "KIDAMAN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.769000",
    Latitude: "-26.645600"
  },
  {
    Country: "Australia",
    Pcode: "4574",
    Locality: "MOY POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.739500",
    Latitude: "-26.520000"
  },
  {
    Country: "Australia",
    Pcode: "4574",
    Locality: "OBI OBI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.820500",
    Latitude: "-26.644200"
  },
  {
    Country: "Australia",
    Pcode: "4575",
    Locality: "BIRTINYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.115600",
    Latitude: "-26.745700"
  },
  {
    Country: "Australia",
    Pcode: "4575",
    Locality: "BOKARINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.129500",
    Latitude: "-26.738800"
  },
  {
    Country: "Australia",
    Pcode: "4575",
    Locality: "BUDDINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.132600",
    Latitude: "-26.697900"
  },
  {
    Country: "Australia",
    Pcode: "4575",
    Locality: "MINYAMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.121900",
    Latitude: "-26.694700"
  },
  {
    Country: "Australia",
    Pcode: "4575",
    Locality: "PARREARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.121700",
    Latitude: "-26.712300"
  },
  {
    Country: "Australia",
    Pcode: "4575",
    Locality: "WARANA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.131200",
    Latitude: "-26.719900"
  },
  {
    Country: "Australia",
    Pcode: "4575",
    Locality: "WURTULLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.126600",
    Latitude: "-26.755500"
  },
  {
    Country: "Australia",
    Pcode: "4580",
    Locality: "COOLOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.014000",
    Latitude: "-26.021200"
  },
  {
    Country: "Australia",
    Pcode: "4580",
    Locality: "COOLOOLA COVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "153.004300",
    Latitude: "-25.986900"
  },
  {
    Country: "Australia",
    Pcode: "4580",
    Locality: "TIN CAN BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.946600",
    Latitude: "-25.883000"
  },
  {
    Country: "Australia",
    Pcode: "4581",
    Locality: "EURONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "FRASER ISLAND",
    Category: "Delivery Area",
    Longitude: "153.119500",
    Latitude: "-25.514800"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "ARANBANGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.657900",
    Latitude: "-25.812800"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "BAN BAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.762400",
    Latitude: "-25.698200"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "BAN BAN SPRINGS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.823200",
    Latitude: "-25.671000"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "BARLYNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.644900",
    Latitude: "-25.736700"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "BINJOUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.463900",
    Latitude: "-25.530900"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "BLAIRMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.907500",
    Latitude: "-25.801300"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "MOUNT WEDGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.068900",
    Latitude: "-33.345800"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "PALKAGEE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.433100",
    Latitude: "-33.413900"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "POLDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.483000",
    Latitude: "-33.516300"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "TALIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.859600",
    Latitude: "-33.301000"
  },
  {
    Country: "Australia",
    Pcode: "5671",
    Locality: "BAIRD BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.365900",
    Latitude: "-33.137400"
  },
  {
    Country: "Australia",
    Pcode: "5671",
    Locality: "CALCA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.325500",
    Latitude: "-33.041700"
  },
  {
    Country: "Australia",
    Pcode: "5671",
    Locality: "COLLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.647600",
    Latitude: "-33.063100"
  },
  {
    Country: "Australia",
    Pcode: "5671",
    Locality: "MORTANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.459200",
    Latitude: "-33.015200"
  },
  {
    Country: "Australia",
    Pcode: "5671",
    Locality: "MOUNT COOPER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.766000",
    Latitude: "-33.009400"
  },
  {
    Country: "Australia",
    Pcode: "5671",
    Locality: "PORT KENNY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.674100",
    Latitude: "-33.160200"
  },
  {
    Country: "Australia",
    Pcode: "5671",
    Locality: "TYRINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.453300",
    Latitude: "-33.110800"
  },
  {
    Country: "Australia",
    Pcode: "5671",
    Locality: "WITERA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.581200",
    Latitude: "-33.124200"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "CARAWA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.205000",
    Latitude: "-32.334900"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "CHANDADA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.662400",
    Latitude: "-32.763700"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "CHINBINGINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.229700",
    Latitude: "-32.233100"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "EBA ANCHORAGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.291600",
    Latitude: "-32.688700"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "HASLAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.222300",
    Latitude: "-32.460900"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "INKSTER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.647900",
    Latitude: "-32.921000"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "LAURA BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.851000",
    Latitude: "-32.232700"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "MARYVALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.485800",
    Latitude: "-32.877300"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "MUDAMUCKLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.053700",
    Latitude: "-32.129900"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "NUNJIKOMPITA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.365800",
    Latitude: "-32.266800"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "PERLUBIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.359500",
    Latitude: "-32.607300"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "PETINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.390600",
    Latitude: "-32.479500"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "PIEDNIPPIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.416700",
    Latitude: "-32.724400"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "PINJARRA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.751800",
    Latitude: "-32.061600"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "PUNTABIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.114500",
    Latitude: "-32.234900"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "PUREBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.484400",
    Latitude: "-32.144200"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "SCEALE BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.247900",
    Latitude: "-33.023600"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "SMOKY BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.983300",
    Latitude: "-32.397700"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "STREAKY BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.269900",
    Latitude: "-32.832400"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "WESTALL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.090000",
    Latitude: "-32.902600"
  },
  {
    Country: "Australia",
    Pcode: "5680",
    Locality: "YANERBIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.257200",
    Latitude: "-32.935200"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "BOOKABIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.661700",
    Latitude: "-31.855600"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "CEDUNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.684100",
    Latitude: "-32.079000"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "CEDUNA WATERS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "CHARRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.335300",
    Latitude: "-32.097700"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "CHUNDARIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.652600",
    Latitude: "-31.410300"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "COORABIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.110100",
    Latitude: "-31.832900"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "DENIAL BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.565700",
    Latitude: "-32.109300"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "FOWLERS BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.382200",
    Latitude: "-31.901700"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "KALANBI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.643800",
    Latitude: "-31.905500"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "KOONIBBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.449000",
    Latitude: "-31.892100"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "MALTEE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.956300",
    Latitude: "-32.065800"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "MERGHINY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.840400",
    Latitude: "-32.142100"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "MITCHIDY MOOLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.516300",
    Latitude: "-31.653000"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "NADIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.493800",
    Latitude: "-32.094300"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "NANBONA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "132.673500",
    Latitude: "-31.701800"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "NULLARBOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.540100",
    Latitude: "-31.072200"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "OAK VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "130.810000",
    Latitude: "-29.452000"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "PENONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.018300",
    Latitude: "-31.980600"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "THEVENARD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.653400",
    Latitude: "-32.146100"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "UWORRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.358800",
    Latitude: "-31.963600"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "WANDANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.805800",
    Latitude: "-31.971000"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "WATRABA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.244600",
    Latitude: "-31.952600"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "WHITE WELL CORNER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.290600",
    Latitude: "-31.846500"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "YALATA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "131.978300",
    Latitude: "-31.586900"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "YELLABINNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.380200",
    Latitude: "-31.162400"
  },
  {
    Country: "Australia",
    Pcode: "5690",
    Locality: "YUMBARRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.557700",
    Latitude: "-31.713600"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "BLANCHE HARBOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.753300",
    Latitude: "-32.694300"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "COMMISSARIAT POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.751600",
    Latitude: "-32.546700"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "CULTANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.489500",
    Latitude: "-32.770700"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "DAVENPORT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.789000",
    Latitude: "-32.472400"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "MIRANDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.892700",
    Latitude: "-32.694200"
  },
  {
    Country: "Australia",
    Pcode: "6010",
    Locality: "KARRAKATTA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.799000",
    Latitude: "-31.966400"
  },
  {
    Country: "Australia",
    Pcode: "6010",
    Locality: "MOUNT CLAREMONT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.777400",
    Latitude: "-31.960000"
  },
  {
    Country: "Australia",
    Pcode: "6010",
    Locality: "SWANBOURNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.765000",
    Latitude: "-31.972300"
  },
  {
    Country: "Australia",
    Pcode: "6011",
    Locality: "COTTESLOE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.759400",
    Latitude: "-31.992900"
  },
  {
    Country: "Australia",
    Pcode: "6011",
    Locality: "PEPPERMINT GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.767100",
    Latitude: "-31.998600"
  },
  {
    Country: "Australia",
    Pcode: "6012",
    Locality: "MOSMAN PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.766200",
    Latitude: "-32.014200"
  },
  {
    Country: "Australia",
    Pcode: "6014",
    Locality: "FLOREAT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.790300",
    Latitude: "-31.939100"
  },
  {
    Country: "Australia",
    Pcode: "6014",
    Locality: "JOLIMONT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.809200",
    Latitude: "-31.946100"
  },
  {
    Country: "Australia",
    Pcode: "6014",
    Locality: "WEMBLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.818600",
    Latitude: "-31.932400"
  },
  {
    Country: "Australia",
    Pcode: "6015",
    Locality: "CITY BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.768900",
    Latitude: "-31.939500"
  },
  {
    Country: "Australia",
    Pcode: "6016",
    Locality: "GLENDALOUGH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.819300",
    Latitude: "-31.919700"
  },
  {
    Country: "Australia",
    Pcode: "6016",
    Locality: "MOUNT HAWTHORN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.834900",
    Latitude: "-31.920600"
  },
  {
    Country: "Australia",
    Pcode: "6017",
    Locality: "HERDSMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.804500",
    Latitude: "-31.919300"
  },
  {
    Country: "Australia",
    Pcode: "6017",
    Locality: "OSBORNE PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.815100",
    Latitude: "-31.903400"
  },
  {
    Country: "Australia",
    Pcode: "6017",
    Locality: "OSBORNE PARK DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6018",
    Locality: "CHURCHLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.790300",
    Latitude: "-31.921700"
  },
  {
    Country: "Australia",
    Pcode: "6018",
    Locality: "DOUBLEVIEW",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.781900",
    Latitude: "-31.896700"
  },
  {
    Country: "Australia",
    Pcode: "6018",
    Locality: "GWELUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.794300",
    Latitude: "-31.870400"
  },
  {
    Country: "Australia",
    Pcode: "6018",
    Locality: "GWELUP DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6018",
    Locality: "INNALOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.796000",
    Latitude: "-31.892700"
  },
  {
    Country: "Australia",
    Pcode: "6018",
    Locality: "KARRINYUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.775300",
    Latitude: "-31.872300"
  },
  {
    Country: "Australia",
    Pcode: "6018",
    Locality: "WOODLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.793300",
    Latitude: "-31.908300"
  },
  {
    Country: "Australia",
    Pcode: "6019",
    Locality: "SCARBOROUGH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.765000",
    Latitude: "-31.896500"
  },
  {
    Country: "Australia",
    Pcode: "6019",
    Locality: "WEMBLEY DOWNS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.777500",
    Latitude: "-31.918700"
  },
  {
    Country: "Australia",
    Pcode: "6020",
    Locality: "CARINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.782500",
    Latitude: "-31.852100"
  },
  {
    Country: "Australia",
    Pcode: "6020",
    Locality: "MARMION",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.756500",
    Latitude: "-31.840900"
  },
  {
    Country: "Australia",
    Pcode: "6020",
    Locality: "NORTH BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.760400",
    Latitude: "-31.860800"
  },
  {
    Country: "Australia",
    Pcode: "6020",
    Locality: "SORRENTO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.752800",
    Latitude: "-31.827900"
  },
  {
    Country: "Australia",
    Pcode: "6020",
    Locality: "WATERMANS BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.758400",
    Latitude: "-31.851800"
  },
  {
    Country: "Australia",
    Pcode: "6021",
    Locality: "BALCATTA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.818100",
    Latitude: "-31.873200"
  },
  {
    Country: "Australia",
    Pcode: "6021",
    Locality: "STIRLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.812100",
    Latitude: "-31.886600"
  },
  {
    Country: "Australia",
    Pcode: "6022",
    Locality: "HAMERSLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.810900",
    Latitude: "-31.850700"
  },
  {
    Country: "Australia",
    Pcode: "6023",
    Locality: "DUNCRAIG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.778600",
    Latitude: "-31.831700"
  },
  {
    Country: "Australia",
    Pcode: "6024",
    Locality: "GREENWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.803900",
    Latitude: "-31.827700"
  },
  {
    Country: "Australia",
    Pcode: "6024",
    Locality: "WARWICK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.808400",
    Latitude: "-31.839600"
  },
  {
    Country: "Australia",
    Pcode: "6025",
    Locality: "CRAIGIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.769100",
    Latitude: "-31.786800"
  },
  {
    Country: "Australia",
    Pcode: "6025",
    Locality: "HILLARYS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.743100",
    Latitude: "-31.811200"
  },
  {
    Country: "Australia",
    Pcode: "6025",
    Locality: "KALLAROO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.743800",
    Latitude: "-31.790600"
  },
  {
    Country: "Australia",
    Pcode: "6025",
    Locality: "PADBURY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.769300",
    Latitude: "-31.806600"
  },
  {
    Country: "Australia",
    Pcode: "6026",
    Locality: "KINGSLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.801400",
    Latitude: "-31.810000"
  },
  {
    Country: "Australia",
    Pcode: "6026",
    Locality: "WOODVALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.796400",
    Latitude: "-31.788600"
  },
  {
    Country: "Australia",
    Pcode: "6027",
    Locality: "BELDON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.767100",
    Latitude: "-31.775100"
  },
  {
    Country: "Australia",
    Pcode: "6027",
    Locality: "CONNOLLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.749500",
    Latitude: "-31.747800"
  },
  {
    Country: "Australia",
    Pcode: "6027",
    Locality: "EDGEWATER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.783800",
    Latitude: "-31.763400"
  },
  {
    Country: "Australia",
    Pcode: "6027",
    Locality: "HEATHRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.761000",
    Latitude: "-31.764500"
  },
  {
    Country: "Australia",
    Pcode: "6027",
    Locality: "JOONDALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.771000",
    Latitude: "-31.739800"
  },
  {
    Country: "Australia",
    Pcode: "6027",
    Locality: "JOONDALUP DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6027",
    Locality: "MULLALOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.743300",
    Latitude: "-31.779400"
  },
  {
    Country: "Australia",
    Pcode: "6027",
    Locality: "OCEAN REEF",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.737000",
    Latitude: "-31.756900"
  },
  {
    Country: "Australia",
    Pcode: "6028",
    Locality: "BURNS BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.721200",
    Latitude: "-31.721500"
  },
  {
    Country: "Australia",
    Pcode: "6028",
    Locality: "CURRAMBINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.746500",
    Latitude: "-31.732800"
  },
  {
    Country: "Australia",
    Pcode: "6028",
    Locality: "ILUKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.728100",
    Latitude: "-31.735300"
  },
  {
    Country: "Australia",
    Pcode: "6028",
    Locality: "KINROSS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.739300",
    Latitude: "-31.719400"
  },
  {
    Country: "Australia",
    Pcode: "6029",
    Locality: "TRIGG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.758100",
    Latitude: "-31.877600"
  },
  {
    Country: "Australia",
    Pcode: "6030",
    Locality: "CLARKSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.726200",
    Latitude: "-31.685600"
  },
  {
    Country: "Australia",
    Pcode: "6030",
    Locality: "MERRIWA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.712900",
    Latitude: "-31.665400"
  },
  {
    Country: "Australia",
    Pcode: "6030",
    Locality: "MINDARIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.707200",
    Latitude: "-31.689100"
  },
  {
    Country: "Australia",
    Pcode: "6030",
    Locality: "QUINNS ROCKS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.697900",
    Latitude: "-31.664500"
  },
  {
    Country: "Australia",
    Pcode: "6030",
    Locality: "RIDGEWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.722500",
    Latitude: "-31.661600"
  },
  {
    Country: "Australia",
    Pcode: "6030",
    Locality: "TAMALA PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.725300",
    Latitude: "-31.705100"
  },
  {
    Country: "Australia",
    Pcode: "6031",
    Locality: "BANKSIA GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.806500",
    Latitude: "-31.700000"
  },
  {
    Country: "Australia",
    Pcode: "6031",
    Locality: "CARRAMAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.783900",
    Latitude: "-31.701300"
  },
  {
    Country: "Australia",
    Pcode: "6031",
    Locality: "NEERABUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.752400",
    Latitude: "-31.690900"
  },
  {
    Country: "Australia",
    Pcode: "6032",
    Locality: "NOWERGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.754900",
    Latitude: "-31.635500"
  },
  {
    Country: "Australia",
    Pcode: "6033",
    Locality: "CARABOODA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.719700",
    Latitude: "-31.599000"
  },
  {
    Country: "Australia",
    Pcode: "6125",
    Locality: "HOPELAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.879400",
    Latitude: "-32.377700"
  },
  {
    Country: "Australia",
    Pcode: "6125",
    Locality: "MARDELLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.940800",
    Latitude: "-32.323600"
  },
  {
    Country: "Australia",
    Pcode: "6125",
    Locality: "SERPENTINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.007600",
    Latitude: "-32.386100"
  },
  {
    Country: "Australia",
    Pcode: "6126",
    Locality: "KEYSBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.957800",
    Latitude: "-32.439500"
  },
  {
    Country: "Australia",
    Pcode: "6147",
    Locality: "LANGFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.942900",
    Latitude: "-32.041800"
  },
  {
    Country: "Australia",
    Pcode: "6147",
    Locality: "LYNWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.928200",
    Latitude: "-32.041100"
  },
  {
    Country: "Australia",
    Pcode: "6147",
    Locality: "PARKWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.915200",
    Latitude: "-32.047700"
  },
  {
    Country: "Australia",
    Pcode: "6148",
    Locality: "FERNDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.926400",
    Latitude: "-32.030600"
  },
  {
    Country: "Australia",
    Pcode: "6148",
    Locality: "RIVERTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.902100",
    Latitude: "-32.033500"
  },
  {
    Country: "Australia",
    Pcode: "6148",
    Locality: "ROSSMOYNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.868100",
    Latitude: "-32.038400"
  },
  {
    Country: "Australia",
    Pcode: "6148",
    Locality: "SHELLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.884000",
    Latitude: "-32.030800"
  },
  {
    Country: "Australia",
    Pcode: "6149",
    Locality: "BULL CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.862100",
    Latitude: "-32.055600"
  },
  {
    Country: "Australia",
    Pcode: "6149",
    Locality: "LEEMING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.867900",
    Latitude: "-32.076800"
  },
  {
    Country: "Australia",
    Pcode: "6150",
    Locality: "BATEMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.847600",
    Latitude: "-32.055900"
  },
  {
    Country: "Australia",
    Pcode: "6150",
    Locality: "MURDOCH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.837100",
    Latitude: "-32.069000"
  },
  {
    Country: "Australia",
    Pcode: "6150",
    Locality: "WINTHROP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.829300",
    Latitude: "-32.052700"
  },
  {
    Country: "Australia",
    Pcode: "6151",
    Locality: "KENSINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.883600",
    Latitude: "-31.986300"
  },
  {
    Country: "Australia",
    Pcode: "6151",
    Locality: "SOUTH PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.864700",
    Latitude: "-31.976400"
  },
  {
    Country: "Australia",
    Pcode: "6151",
    Locality: "SOUTH PERTH ANGELO ST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6152",
    Locality: "COMO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.870400",
    Latitude: "-32.002900"
  },
  {
    Country: "Australia",
    Pcode: "6152",
    Locality: "KARAWARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.881600",
    Latitude: "-32.008100"
  },
  {
    Country: "Australia",
    Pcode: "6152",
    Locality: "MANNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.869700",
    Latitude: "-32.012800"
  },
  {
    Country: "Australia",
    Pcode: "6152",
    Locality: "SALTER POINT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.867100",
    Latitude: "-32.025100"
  },
  {
    Country: "Australia",
    Pcode: "6152",
    Locality: "WATERFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.888000",
    Latitude: "-32.016000"
  },
  {
    Country: "Australia",
    Pcode: "6153",
    Locality: "APPLECROSS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.836800",
    Latitude: "-32.015400"
  },
  {
    Country: "Australia",
    Pcode: "6153",
    Locality: "APPLECROSS NORTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6153",
    Locality: "ARDROSS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.836100",
    Latitude: "-32.025400"
  },
  {
    Country: "Australia",
    Pcode: "6153",
    Locality: "BRENTWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.852100",
    Latitude: "-32.043300"
  },
  {
    Country: "Australia",
    Pcode: "6153",
    Locality: "CANNING BRIDGE APPLECROSS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6153",
    Locality: "MOUNT PLEASANT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.849700",
    Latitude: "-32.026700"
  },
  {
    Country: "Australia",
    Pcode: "6154",
    Locality: "ALFRED COVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.818100",
    Latitude: "-32.031600"
  },
  {
    Country: "Australia",
    Pcode: "6154",
    Locality: "BOORAGOON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.833000",
    Latitude: "-32.038900"
  },
  {
    Country: "Australia",
    Pcode: "6154",
    Locality: "MYAREE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.815700",
    Latitude: "-32.041700"
  },
  {
    Country: "Australia",
    Pcode: "6155",
    Locality: "CANNING VALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.917900",
    Latitude: "-32.084300"
  },
  {
    Country: "Australia",
    Pcode: "6155",
    Locality: "CANNING VALE DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6155",
    Locality: "CANNING VALE SOUTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 5001-5670",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6155",
    Locality: "WILLETTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.888800",
    Latitude: "-32.054000"
  },
  {
    Country: "Australia",
    Pcode: "6156",
    Locality: "ATTADALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.803900",
    Latitude: "-32.024000"
  },
  {
    Country: "Australia",
    Pcode: "6156",
    Locality: "MELVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.801600",
    Latitude: "-32.040200"
  },
  {
    Country: "Australia",
    Pcode: "6156",
    Locality: "WILLAGEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.804600",
    Latitude: "-32.051400"
  },
  {
    Country: "Australia",
    Pcode: "6156",
    Locality: "WILLAGEE CENTRAL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6157",
    Locality: "BICTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.784800",
    Latitude: "-32.027700"
  },
  {
    Country: "Australia",
    Pcode: "6157",
    Locality: "PALMYRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.784900",
    Latitude: "-32.045500"
  },
  {
    Country: "Australia",
    Pcode: "6157",
    Locality: "PALMYRA DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6158",
    Locality: "EAST FREMANTLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.767100",
    Latitude: "-32.035700"
  },
  {
    Country: "Australia",
    Pcode: "6159",
    Locality: "NORTH FREMANTLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.751300",
    Latitude: "-32.037600"
  },
  {
    Country: "Australia",
    Pcode: "6160",
    Locality: "FREMANTLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.760900",
    Latitude: "-32.052500"
  },
  {
    Country: "Australia",
    Pcode: "6161",
    Locality: "ROTTNEST ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.520700",
    Latitude: "-32.006900"
  },
  {
    Country: "Australia",
    Pcode: "6162",
    Locality: "BEACONSFIELD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.768800",
    Latitude: "-32.070700"
  },
  {
    Country: "Australia",
    Pcode: "6162",
    Locality: "SOUTH FREMANTLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.751900",
    Latitude: "-32.070800"
  },
  {
    Country: "Australia",
    Pcode: "6162",
    Locality: "WHITE GUM VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.770500",
    Latitude: "-32.060300"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "BIBRA LAKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.817600",
    Latitude: "-32.101500"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "BIBRA LAKE DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "COOLBELLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.806900",
    Latitude: "-32.082100"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "HAMILTON HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.779000",
    Latitude: "-32.084500"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "HILTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.786400",
    Latitude: "-32.067000"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "KARDINYA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.815700",
    Latitude: "-32.066600"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "NORTH COOGEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.758800",
    Latitude: "-32.093400"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "NORTH LAKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.833300",
    Latitude: "-32.080200"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "O'CONNOR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.793600",
    Latitude: "-32.060300"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "SAMSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.797800",
    Latitude: "-32.069300"
  },
  {
    Country: "Australia",
    Pcode: "6163",
    Locality: "SPEARWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.781300",
    Latitude: "-32.106900"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "ATWELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.865900",
    Latitude: "-32.142100"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "AUBIN GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.863000",
    Latitude: "-32.165600"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "BANJUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.879900",
    Latitude: "-32.156300"
  },
  {
    Country: "Australia",
    Pcode: "6251",
    Locality: "NEWLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.866300",
    Latitude: "-33.673700"
  },
  {
    Country: "Australia",
    Pcode: "6252",
    Locality: "MULLALYUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.918800",
    Latitude: "-33.761000"
  },
  {
    Country: "Australia",
    Pcode: "6253",
    Locality: "BALINGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.015000",
    Latitude: "-33.773500"
  },
  {
    Country: "Australia",
    Pcode: "6253",
    Locality: "GRIMWADE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.034300",
    Latitude: "-33.696400"
  },
  {
    Country: "Australia",
    Pcode: "6253",
    Locality: "SOUTHAMPTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.952500",
    Latitude: "-33.877500"
  },
  {
    Country: "Australia",
    Pcode: "6254",
    Locality: "GREENBUSHES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.043600",
    Latitude: "-33.851300"
  },
  {
    Country: "Australia",
    Pcode: "6254",
    Locality: "NORTH GREENBUSHES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.058200",
    Latitude: "-33.820900"
  },
  {
    Country: "Australia",
    Pcode: "6255",
    Locality: "BENJINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.300000",
    Latitude: "-33.810900"
  },
  {
    Country: "Australia",
    Pcode: "6255",
    Locality: "BRIDGETOWN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.128100",
    Latitude: "-33.955000"
  },
  {
    Country: "Australia",
    Pcode: "6255",
    Locality: "CATTERICK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.133100",
    Latitude: "-33.843200"
  },
  {
    Country: "Australia",
    Pcode: "6255",
    Locality: "HESTER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.162700",
    Latitude: "-33.904600"
  },
  {
    Country: "Australia",
    Pcode: "6255",
    Locality: "HESTER BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.091100",
    Latitude: "-33.925600"
  },
  {
    Country: "Australia",
    Pcode: "6255",
    Locality: "KANGAROO GULLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.201400",
    Latitude: "-33.960600"
  },
  {
    Country: "Australia",
    Pcode: "6255",
    Locality: "WINNEJUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.298500",
    Latitude: "-33.964600"
  },
  {
    Country: "Australia",
    Pcode: "6256",
    Locality: "GLENLYNN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.153900",
    Latitude: "-34.006800"
  },
  {
    Country: "Australia",
    Pcode: "6256",
    Locality: "KINGSTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.364700",
    Latitude: "-34.112300"
  },
  {
    Country: "Australia",
    Pcode: "6256",
    Locality: "MARANUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.007800",
    Latitude: "-33.949600"
  },
  {
    Country: "Australia",
    Pcode: "6256",
    Locality: "SUNNYSIDE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.256700",
    Latitude: "-34.019200"
  },
  {
    Country: "Australia",
    Pcode: "6256",
    Locality: "WANDILLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.046000",
    Latitude: "-34.037800"
  },
  {
    Country: "Australia",
    Pcode: "6256",
    Locality: "YORNUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.162700",
    Latitude: "-34.079800"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "BALBARRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.227300",
    Latitude: "-34.205100"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "DEANMILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.088000",
    Latitude: "-34.255400"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "DIAMOND TREE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.104400",
    Latitude: "-34.344500"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "DINGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.249200",
    Latitude: "-34.281200"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "DIXVALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.061300",
    Latitude: "-34.202500"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "DONNELLY RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.951400",
    Latitude: "-34.081400"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "GLENORAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.970200",
    Latitude: "-34.238900"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "JARDEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.078400",
    Latitude: "-34.303100"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "LAKE MUIR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.583900",
    Latitude: "-34.490900"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "LINFARNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.107900",
    Latitude: "-34.165100"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "MANJIMUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.146700",
    Latitude: "-34.247900"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "MIDDLESEX",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.177500",
    Latitude: "-34.304200"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "MORDALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.625300",
    Latitude: "-34.330100"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "PALGARUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.175900",
    Latitude: "-34.175200"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "PERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.418200",
    Latitude: "-34.275500"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "QUINNINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.315800",
    Latitude: "-34.458300"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "RINGBARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.114200",
    Latitude: "-34.208500"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "SMITH BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.200600",
    Latitude: "-34.373300"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "UPPER WARREN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.311800",
    Latitude: "-34.371400"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "WILGARRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.207100",
    Latitude: "-34.151800"
  },
  {
    Country: "Australia",
    Pcode: "6258",
    Locality: "YANMAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.006900",
    Latitude: "-34.181200"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "BEEDELUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.861600",
    Latitude: "-34.368800"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "BIDDELIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.744000",
    Latitude: "-34.235500"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "CALLCUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.955900",
    Latitude: "-34.589200"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "CHANNYBEARUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.978400",
    Latitude: "-34.402400"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "COLLINS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.105100",
    Latitude: "-34.462600"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "EASTBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.092800",
    Latitude: "-34.396100"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "LAKE JASPER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.645100",
    Latitude: "-34.384300"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "PEERABEELUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.779600",
    Latitude: "-34.360700"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "PEMBERTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.054500",
    Latitude: "-34.442000"
  },
  {
    Country: "Australia",
    Pcode: "6260",
    Locality: "YEAGARUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.809200",
    Latitude: "-34.512100"
  },
  {
    Country: "Australia",
    Pcode: "6262",
    Locality: "BOORARA BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.208900",
    Latitude: "-34.678900"
  },
  {
    Country: "Australia",
    Pcode: "6262",
    Locality: "CROWEA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.171900",
    Latitude: "-34.536100"
  },
  {
    Country: "Australia",
    Pcode: "6262",
    Locality: "MEERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.035100",
    Latitude: "-34.635400"
  },
  {
    Country: "Australia",
    Pcode: "6262",
    Locality: "NORTHCLIFFE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.140900",
    Latitude: "-34.634400"
  },
  {
    Country: "Australia",
    Pcode: "6262",
    Locality: "SHANNON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.384300",
    Latitude: "-34.711700"
  },
  {
    Country: "Australia",
    Pcode: "6262",
    Locality: "WINDY HARBOUR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.062500",
    Latitude: "-34.767200"
  },
  {
    Country: "Australia",
    Pcode: "6271",
    Locality: "CAPEL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.568200",
    Latitude: "-33.560600"
  },
  {
    Country: "Australia",
    Pcode: "6271",
    Locality: "CAPEL RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.655300",
    Latitude: "-33.624000"
  },
  {
    Country: "Australia",
    Pcode: "6271",
    Locality: "FORREST BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.489300",
    Latitude: "-33.567900"
  },
  {
    Country: "Australia",
    Pcode: "6271",
    Locality: "PEPPERMINT GROVE BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.509800",
    Latitude: "-33.524100"
  },
  {
    Country: "Australia",
    Pcode: "6271",
    Locality: "STIRLING ESTATE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.528400",
    Latitude: "-33.525000"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "BARRABUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.648700",
    Latitude: "-33.901400"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "CARLOTTA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.794300",
    Latitude: "-34.114500"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "CUNDINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.780400",
    Latitude: "-33.859700"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "DARRADUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.545400",
    Latitude: "-34.127400"
  },
  {
    Country: "Australia",
    Pcode: "6324",
    Locality: "WOOGENELLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.899300",
    Latitude: "-34.550300"
  },
  {
    Country: "Australia",
    Pcode: "6326",
    Locality: "NARRIKUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.698500",
    Latitude: "-34.769200"
  },
  {
    Country: "Australia",
    Pcode: "6327",
    Locality: "REDMOND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.697400",
    Latitude: "-34.889000"
  },
  {
    Country: "Australia",
    Pcode: "6327",
    Locality: "REDMOND WEST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.552200",
    Latitude: "-34.887400"
  },
  {
    Country: "Australia",
    Pcode: "6328",
    Locality: "CHEYNES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.321700",
    Latitude: "-34.847400"
  },
  {
    Country: "Australia",
    Pcode: "6328",
    Locality: "GNOWELLEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.540900",
    Latitude: "-34.380500"
  },
  {
    Country: "Australia",
    Pcode: "6328",
    Locality: "GREEN RANGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.427100",
    Latitude: "-34.689400"
  },
  {
    Country: "Australia",
    Pcode: "6328",
    Locality: "KOJANEERUP SOUTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.422500",
    Latitude: "-34.504700"
  },
  {
    Country: "Australia",
    Pcode: "6328",
    Locality: "MANYPEAKS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.191900",
    Latitude: "-34.833600"
  },
  {
    Country: "Australia",
    Pcode: "6328",
    Locality: "METTLER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.583900",
    Latitude: "-34.621600"
  },
  {
    Country: "Australia",
    Pcode: "6328",
    Locality: "PALMDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.131300",
    Latitude: "-34.719600"
  },
  {
    Country: "Australia",
    Pcode: "6328",
    Locality: "WELLSTEAD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.708500",
    Latitude: "-34.536700"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "ALBANY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.884100",
    Latitude: "-35.028000"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "BAYONET HEAD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.934100",
    Latitude: "-34.973200"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "BIG GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.879800",
    Latitude: "-35.082500"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "BORNHOLM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.572100",
    Latitude: "-35.054000"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "CENTENNIAL PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.880900",
    Latitude: "-35.011500"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "COLLINGWOOD HEIGHTS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.915300",
    Latitude: "-34.994000"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "COLLINGWOOD PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.923300",
    Latitude: "-35.004300"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "CUTHBERT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.780800",
    Latitude: "-35.020300"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "DROME",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.772900",
    Latitude: "-34.941100"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "ELLEKER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.720100",
    Latitude: "-35.018200"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "EMU POINT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.951900",
    Latitude: "-34.985800"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "FRENCHMAN BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.947400",
    Latitude: "-35.092700"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "GLEDHOW",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.828100",
    Latitude: "-35.008200"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "GOODE BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.931600",
    Latitude: "-35.086500"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "GREEN VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.825900",
    Latitude: "-34.877500"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "KALGAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.011100",
    Latitude: "-34.901300"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "KING RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.907300",
    Latitude: "-34.936500"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "KRONKUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.612300",
    Latitude: "-35.042300"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "LANGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.896200",
    Latitude: "-34.989900"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "LITTLE GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.868200",
    Latitude: "-35.067800"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "LOCKYER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.855800",
    Latitude: "-35.006000"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "LOWER KING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.932200",
    Latitude: "-34.948000"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "LOWLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.506100",
    Latitude: "-35.062300"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "MARBELUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.728600",
    Latitude: "-34.970500"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "MCKAIL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.831300",
    Latitude: "-34.982500"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "MIDDLETON BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.911200",
    Latitude: "-35.024200"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "MILLBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.872800",
    Latitude: "-34.912300"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "MILPARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.865100",
    Latitude: "-34.989100"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "MIRA MAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.899300",
    Latitude: "-35.013900"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "MOUNT CLARENCE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.898000",
    Latitude: "-35.024800"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "MOUNT ELPHINSTONE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.854300",
    Latitude: "-35.020600"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "MOUNT MELVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.870600",
    Latitude: "-35.019400"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "NANARUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.161700",
    Latitude: "-34.987100"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "NAPIER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.970800",
    Latitude: "-34.784400"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "NULLAKI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.421200",
    Latitude: "-35.029000"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "ORANA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.861100",
    Latitude: "-34.996300"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "PORT ALBANY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.901800",
    Latitude: "-35.032500"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "ROBINSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.828200",
    Latitude: "-35.031400"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "SANDPATCH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.790300",
    Latitude: "-35.054600"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "SEPPINGS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.913800",
    Latitude: "-35.013000"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "SPENCER PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.900000",
    Latitude: "-35.004900"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "TORBAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.646500",
    Latitude: "-34.988900"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "TORNDIRRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.839200",
    Latitude: "-35.079400"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "VANCOUVER PENINSULA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.932100",
    Latitude: "-35.061600"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "WALMSLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.902400",
    Latitude: "-34.974100"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "WARRENUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.862800",
    Latitude: "-34.972800"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "WEST CAPE HOWE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.613000",
    Latitude: "-35.106600"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "WILLYUNG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.858800",
    Latitude: "-34.943600"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "YAKAMIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.881200",
    Latitude: "-35.002200"
  },
  {
    Country: "Australia",
    Pcode: "6330",
    Locality: "YOUNGS SIDING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.528400",
    Latitude: "-34.988700"
  },
  {
    Country: "Australia",
    Pcode: "6331",
    Locality: "ALBANY DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1-2121",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6332",
    Locality: "ALBANY PO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 5001-6028",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "BOW BRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.976400",
    Latitude: "-34.970400"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "MOUNT COOKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.390600",
    Latitude: "-32.374600"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "MOUNT WELLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.303900",
    Latitude: "-32.613800"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "NORTH BANNISTER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.450800",
    Latitude: "-32.529900"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "RANFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.492000",
    Latitude: "-32.793700"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "UPPER MURRAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.297600",
    Latitude: "-32.989000"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "WURAMING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.262700",
    Latitude: "-32.822500"
  },
  {
    Country: "Australia",
    Pcode: "6391",
    Locality: "QUINDANNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.494000",
    Latitude: "-33.024400"
  },
  {
    Country: "Australia",
    Pcode: "6391",
    Locality: "WILLIAMS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.786500",
    Latitude: "-33.052100"
  },
  {
    Country: "Australia",
    Pcode: "6392",
    Locality: "BOKAL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.882100",
    Latitude: "-33.460300"
  },
  {
    Country: "Australia",
    Pcode: "6392",
    Locality: "DARDADINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.755900",
    Latitude: "-33.233200"
  },
  {
    Country: "Australia",
    Pcode: "6392",
    Locality: "DARKAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.736300",
    Latitude: "-33.342500"
  },
  {
    Country: "Australia",
    Pcode: "6392",
    Locality: "MEEKING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.639600",
    Latitude: "-33.240500"
  },
  {
    Country: "Australia",
    Pcode: "6393",
    Locality: "DURANILLIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.757600",
    Latitude: "-33.516700"
  },
  {
    Country: "Australia",
    Pcode: "6393",
    Locality: "MOODIARRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.699100",
    Latitude: "-33.626300"
  },
  {
    Country: "Australia",
    Pcode: "6394",
    Locality: "BEAUFORT RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.074400",
    Latitude: "-33.566100"
  },
  {
    Country: "Australia",
    Pcode: "6394",
    Locality: "BOILUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.020500",
    Latitude: "-33.758600"
  },
  {
    Country: "Australia",
    Pcode: "6394",
    Locality: "BOSCABEL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.071000",
    Latitude: "-33.690300"
  },
  {
    Country: "Australia",
    Pcode: "6394",
    Locality: "CHANGERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.859300",
    Latitude: "-33.738200"
  },
  {
    Country: "Australia",
    Pcode: "6394",
    Locality: "MOKUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.885400",
    Latitude: "-33.603300"
  },
  {
    Country: "Australia",
    Pcode: "6394",
    Locality: "MURADUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.961100",
    Latitude: "-33.857400"
  },
  {
    Country: "Australia",
    Pcode: "6394",
    Locality: "ORCHID VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.821000",
    Latitude: "-34.011600"
  },
  {
    Country: "Australia",
    Pcode: "6394",
    Locality: "QUALEUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.823100",
    Latitude: "-33.850600"
  },
  {
    Country: "Australia",
    Pcode: "6395",
    Locality: "CHERRY TREE POOL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.253200",
    Latitude: "-33.736200"
  },
  {
    Country: "Australia",
    Pcode: "6395",
    Locality: "JINGALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.043500",
    Latitude: "-33.985300"
  },
  {
    Country: "Australia",
    Pcode: "6395",
    Locality: "KOJONUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.163500",
    Latitude: "-33.829700"
  },
  {
    Country: "Australia",
    Pcode: "6395",
    Locality: "LUMEAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.243100",
    Latitude: "-34.011600"
  },
  {
    Country: "Australia",
    Pcode: "6395",
    Locality: "MOBRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.925800",
    Latitude: "-34.112300"
  },
  {
    Country: "Australia",
    Pcode: "6395",
    Locality: "RYANSBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.126200",
    Latitude: "-34.110200"
  },
  {
    Country: "Australia",
    Pcode: "6396",
    Locality: "FRANKLAND RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.961300",
    Latitude: "-34.360800"
  },
  {
    Country: "Australia",
    Pcode: "6397",
    Locality: "ROCKY GULLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.949100",
    Latitude: "-34.598900"
  },
  {
    Country: "Australia",
    Pcode: "6398",
    Locality: "BROKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.452900",
    Latitude: "-34.918900"
  },
  {
    Country: "Australia",
    Pcode: "6398",
    Locality: "NORTH WALPOLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.644700",
    Latitude: "-34.780200"
  },
  {
    Country: "Australia",
    Pcode: "6398",
    Locality: "WALPOLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.761600",
    Latitude: "-34.984200"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "BUCKLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.622900",
    Latitude: "-31.540200"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "BURLONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.598600",
    Latitude: "-31.669900"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "CUNJARDINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.890800",
    Latitude: "-31.447700"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "IRISHTOWN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.641800",
    Latitude: "-31.594800"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "JENNACUBBINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.712500",
    Latitude: "-31.461100"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "JENNAPULLIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.723100",
    Latitude: "-31.545900"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "MALABAINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.743300",
    Latitude: "-31.601400"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "MEENAAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.905500",
    Latitude: "-31.646700"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "MOKINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.572600",
    Latitude: "-31.734700"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "MULUCKINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.699100",
    Latitude: "-31.681700"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "MUMBERKINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.668700",
    Latitude: "-31.394500"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "MURESK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.705700",
    Latitude: "-31.726400"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "NORTHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.663400",
    Latitude: "-31.652300"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "ROSSMORE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.761300",
    Latitude: "-31.403400"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "SOUTHERN BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.836100",
    Latitude: "-31.548000"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "SPENCERS BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.630000",
    Latitude: "-31.736300"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "THROSSELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.830100",
    Latitude: "-31.711900"
  },
  {
    Country: "Australia",
    Pcode: "6401",
    Locality: "WONGAMINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.622700",
    Latitude: "-31.468600"
  },
  {
    Country: "Australia",
    Pcode: "6403",
    Locality: "GRASS VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.807700",
    Latitude: "-31.637100"
  },
  {
    Country: "Australia",
    Pcode: "6405",
    Locality: "GREENWOODS VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.933100",
    Latitude: "-31.493200"
  },
  {
    Country: "Australia",
    Pcode: "6405",
    Locality: "MECKERING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.986100",
    Latitude: "-31.613100"
  },
  {
    Country: "Australia",
    Pcode: "6405",
    Locality: "QUELAGETTING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.078900",
    Latitude: "-31.459400"
  },
  {
    Country: "Australia",
    Pcode: "6405",
    Locality: "WARDING EAST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.061400",
    Latitude: "-31.733900"
  },
  {
    Country: "Australia",
    Pcode: "6407",
    Locality: "CUNDERDIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.272100",
    Latitude: "-31.666400"
  },
  {
    Country: "Australia",
    Pcode: "6407",
    Locality: "WAEEL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.131000",
    Latitude: "-31.602200"
  },
  {
    Country: "Australia",
    Pcode: "6407",
    Locality: "WATERCARRIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.263100",
    Latitude: "-31.444200"
  },
  {
    Country: "Australia",
    Pcode: "6407",
    Locality: "WYOLA WEST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.316400",
    Latitude: "-31.557200"
  },
  {
    Country: "Australia",
    Pcode: "6407",
    Locality: "YOUNDEGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.269500",
    Latitude: "-31.792600"
  },
  {
    Country: "Australia",
    Pcode: "6409",
    Locality: "NORTH TAMMIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.480600",
    Latitude: "-31.507400"
  },
  {
    Country: "Australia",
    Pcode: "6409",
    Locality: "SOUTH TAMMIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.483300",
    Latitude: "-31.730600"
  },
  {
    Country: "Australia",
    Pcode: "6409",
    Locality: "TAMMIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.491100",
    Latitude: "-31.640700"
  },
  {
    Country: "Australia",
    Pcode: "6410",
    Locality: "DAADENNING CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.801400",
    Latitude: "-31.757000"
  },
  {
    Country: "Australia",
    Pcode: "6410",
    Locality: "KELLERBERRIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.705000",
    Latitude: "-31.623700"
  },
  {
    Country: "Australia",
    Pcode: "6479",
    Locality: "MUKINBUDIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.276200",
    Latitude: "-30.870000"
  },
  {
    Country: "Australia",
    Pcode: "6479",
    Locality: "WATTONING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.214600",
    Latitude: "-30.750200"
  },
  {
    Country: "Australia",
    Pcode: "6479",
    Locality: "WILGOYNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.399100",
    Latitude: "-30.764900"
  },
  {
    Country: "Australia",
    Pcode: "6480",
    Locality: "NUKARNI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.240000",
    Latitude: "-31.301100"
  },
  {
    Country: "Australia",
    Pcode: "6484",
    Locality: "BULLFINCH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.119100",
    Latitude: "-30.880500"
  },
  {
    Country: "Australia",
    Pcode: "6484",
    Locality: "ENNUIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.965700",
    Latitude: "-30.654200"
  },
  {
    Country: "Australia",
    Pcode: "6484",
    Locality: "LAKE DEBORAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.499000",
    Latitude: "-30.424400"
  },
  {
    Country: "Australia",
    Pcode: "6485",
    Locality: "COWCOWING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.434300",
    Latitude: "-31.002900"
  },
  {
    Country: "Australia",
    Pcode: "6485",
    Locality: "KORRELOCKING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.540200",
    Latitude: "-31.283800"
  },
  {
    Country: "Australia",
    Pcode: "6485",
    Locality: "NALKAIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.429200",
    Latitude: "-31.068400"
  },
  {
    Country: "Australia",
    Pcode: "6485",
    Locality: "NEMBUDDING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.562800",
    Latitude: "-31.119500"
  },
  {
    Country: "Australia",
    Pcode: "6485",
    Locality: "WYALKATCHEM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.350800",
    Latitude: "-31.269800"
  },
  {
    Country: "Australia",
    Pcode: "6487",
    Locality: "NORTH YELBENI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.663500",
    Latitude: "-31.057000"
  },
  {
    Country: "Australia",
    Pcode: "6487",
    Locality: "SOUTH YELBENI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.660000",
    Latitude: "-31.277300"
  },
  {
    Country: "Australia",
    Pcode: "6487",
    Locality: "YELBENI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.663700",
    Latitude: "-31.168200"
  },
  {
    Country: "Australia",
    Pcode: "6488",
    Locality: "NORTH TRAYNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.787500",
    Latitude: "-31.038700"
  },
  {
    Country: "Australia",
    Pcode: "6488",
    Locality: "SOUTH TRAYNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.799200",
    Latitude: "-31.226700"
  },
  {
    Country: "Australia",
    Pcode: "6488",
    Locality: "TRAYNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.792100",
    Latitude: "-31.111700"
  },
  {
    Country: "Australia",
    Pcode: "6489",
    Locality: "KUNUNOPPIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.919300",
    Latitude: "-31.113900"
  },
  {
    Country: "Australia",
    Pcode: "6489",
    Locality: "NORTH KUNUNOPPIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.972800",
    Latitude: "-31.039400"
  },
  {
    Country: "Australia",
    Pcode: "6489",
    Locality: "SOUTH KUNUNOPPIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.919700",
    Latitude: "-31.226900"
  },
  {
    Country: "Australia",
    Pcode: "6490",
    Locality: "BURRAN ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.008500",
    Latitude: "-31.259500"
  },
  {
    Country: "Australia",
    Pcode: "6490",
    Locality: "CHANDLER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.431600",
    Latitude: "-31.045000"
  },
  {
    Country: "Australia",
    Pcode: "6490",
    Locality: "ELABBIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.147200",
    Latitude: "-31.255000"
  },
  {
    Country: "Australia",
    Pcode: "6490",
    Locality: "KWELKAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.019400",
    Latitude: "-31.141000"
  },
  {
    Country: "Australia",
    Pcode: "6490",
    Locality: "NUNGARIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.182400",
    Latitude: "-31.104600"
  },
  {
    Country: "Australia",
    Pcode: "6490",
    Locality: "TALGOMINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.265900",
    Latitude: "-31.187200"
  },
  {
    Country: "Australia",
    Pcode: "6501",
    Locality: "MUCHEA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.937700",
    Latitude: "-31.549500"
  },
  {
    Country: "Australia",
    Pcode: "6502",
    Locality: "BINDOON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.174700",
    Latitude: "-31.341800"
  },
  {
    Country: "Australia",
    Pcode: "6502",
    Locality: "BINDOON TRAINING AREA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.295400",
    Latitude: "-31.303000"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "BAMBUN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.889400",
    Latitude: "-31.424800"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "BEERMULLAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.752100",
    Latitude: "-31.205500"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "BOONANARRING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.878500",
    Latitude: "-31.204200"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "BREERA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.960800",
    Latitude: "-31.438800"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "COONABIDGEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.851400",
    Latitude: "-31.368000"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "COWALLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.558700",
    Latitude: "-31.091000"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "CULLALLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.003300",
    Latitude: "-31.221900"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "GINGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.915000",
    Latitude: "-31.343100"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "GINGINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.917900",
    Latitude: "-31.294500"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "GRANVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.862000",
    Latitude: "-31.313000"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "LENNARD BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.934400",
    Latitude: "-31.378600"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "MINDARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.961000",
    Latitude: "-31.072900"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "MOONDAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.982400",
    Latitude: "-31.319400"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "MOORE RIVER NATIONAL PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.685000",
    Latitude: "-31.106000"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "MUCKENBURRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.757300",
    Latitude: "-31.332600"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "NEERGABBY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.657700",
    Latitude: "-31.318500"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "ORANGE SPRINGS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.621600",
    Latitude: "-31.005400"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "RED GULLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.796400",
    Latitude: "-31.056600"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "WANERIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.600100",
    Latitude: "-31.204900"
  },
  {
    Country: "Australia",
    Pcode: "6503",
    Locality: "YEAL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.769000",
    Latitude: "-31.410100"
  },
  {
    Country: "Australia",
    Pcode: "6504",
    Locality: "MOOLIABEENEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.060100",
    Latitude: "-31.292600"
  },
  {
    Country: "Australia",
    Pcode: "6505",
    Locality: "WANNAMAL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.144800",
    Latitude: "-31.159800"
  },
  {
    Country: "Australia",
    Pcode: "6506",
    Locality: "MOGUMBER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.053400",
    Latitude: "-31.010200"
  },
  {
    Country: "Australia",
    Pcode: "6507",
    Locality: "CATABY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.485200",
    Latitude: "-30.727600"
  },
  {
    Country: "Australia",
    Pcode: "6507",
    Locality: "COOLJARLOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.386700",
    Latitude: "-30.703300"
  },
  {
    Country: "Australia",
    Pcode: "6507",
    Locality: "DANDARAGAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.741500",
    Latitude: "-30.615400"
  },
  {
    Country: "Australia",
    Pcode: "6507",
    Locality: "MIMEGARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.496200",
    Latitude: "-30.871900"
  },
  {
    Country: "Australia",
    Pcode: "6507",
    Locality: "REGANS FORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.746100",
    Latitude: "-30.955800"
  },
  {
    Country: "Australia",
    Pcode: "6507",
    Locality: "YATHROO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.766200",
    Latitude: "-30.831700"
  },
  {
    Country: "Australia",
    Pcode: "6509",
    Locality: "GLENTROMIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.276600",
    Latitude: "-30.918700"
  },
  {
    Country: "Australia",
    Pcode: "6509",
    Locality: "NEW NORCIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.212900",
    Latitude: "-30.975000"
  },
  {
    Country: "Australia",
    Pcode: "6509",
    Locality: "WADDINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.275800",
    Latitude: "-30.802100"
  },
  {
    Country: "Australia",
    Pcode: "6509",
    Locality: "YARAWINDAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.236000",
    Latitude: "-31.105300"
  },
  {
    Country: "Australia",
    Pcode: "6510",
    Locality: "BARBERTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.028900",
    Latitude: "-30.729700"
  },
  {
    Country: "Australia",
    Pcode: "6510",
    Locality: "BERKSHIRE VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.146100",
    Latitude: "-30.555400"
  },
  {
    Country: "Australia",
    Pcode: "6606",
    Locality: "EAST BALLIDU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.892800",
    Latitude: "-30.561000"
  },
  {
    Country: "Australia",
    Pcode: "6606",
    Locality: "WEST BALLIDU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.633500",
    Latitude: "-30.588400"
  },
  {
    Country: "Australia",
    Pcode: "6608",
    Locality: "EAST DAMBORING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.808100",
    Latitude: "-30.480100"
  },
  {
    Country: "Australia",
    Pcode: "6608",
    Locality: "MARNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.673000",
    Latitude: "-30.465300"
  },
  {
    Country: "Australia",
    Pcode: "6608",
    Locality: "PITHARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.703100",
    Latitude: "-30.411000"
  },
  {
    Country: "Australia",
    Pcode: "6609",
    Locality: "DALWALLINU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.633300",
    Latitude: "-30.254800"
  },
  {
    Country: "Australia",
    Pcode: "6609",
    Locality: "NUGADONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.756200",
    Latitude: "-30.201500"
  },
  {
    Country: "Australia",
    Pcode: "6609",
    Locality: "XANTIPPE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.009100",
    Latitude: "-30.238600"
  },
  {
    Country: "Australia",
    Pcode: "6612",
    Locality: "JIBBERDING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.916200",
    Latitude: "-29.857600"
  },
  {
    Country: "Australia",
    Pcode: "6612",
    Locality: "MIAMOON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.456500",
    Latitude: "-30.152000"
  },
  {
    Country: "Australia",
    Pcode: "6612",
    Locality: "PAYNES FIND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.608600",
    Latitude: "-29.169000"
  },
  {
    Country: "Australia",
    Pcode: "6612",
    Locality: "WUBIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.545700",
    Latitude: "-30.102200"
  },
  {
    Country: "Australia",
    Pcode: "6613",
    Locality: "BUNTINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.561200",
    Latitude: "-29.959300"
  },
  {
    Country: "Australia",
    Pcode: "6614",
    Locality: "MAYA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.558400",
    Latitude: "-29.851800"
  },
  {
    Country: "Australia",
    Pcode: "6616",
    Locality: "LATHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.485300",
    Latitude: "-29.754100"
  },
  {
    Country: "Australia",
    Pcode: "6620",
    Locality: "BUNJIL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.603300",
    Latitude: "-29.632400"
  },
  {
    Country: "Australia",
    Pcode: "6620",
    Locality: "PERENJORI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.160100",
    Latitude: "-29.445900"
  },
  {
    Country: "Australia",
    Pcode: "6620",
    Locality: "ROTHSAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.769500",
    Latitude: "-29.209000"
  },
  {
    Country: "Australia",
    Pcode: "6623",
    Locality: "BOWGADA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.245600",
    Latitude: "-29.360800"
  },
  {
    Country: "Australia",
    Pcode: "6623",
    Locality: "GUTHA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.061400",
    Latitude: "-28.984500"
  },
  {
    Country: "Australia",
    Pcode: "6623",
    Locality: "KOOLANOOKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.242400",
    Latitude: "-29.208900"
  },
  {
    Country: "Australia",
    Pcode: "6623",
    Locality: "MORAWA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.013400",
    Latitude: "-29.194900"
  },
  {
    Country: "Australia",
    Pcode: "6623",
    Locality: "PINTHARUKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.155200",
    Latitude: "-29.084100"
  },
  {
    Country: "Australia",
    Pcode: "6625",
    Locality: "MERKANOOKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.800700",
    Latitude: "-29.190400"
  },
  {
    Country: "Australia",
    Pcode: "6627",
    Locality: "CANNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.898500",
    Latitude: "-28.880800"
  },
  {
    Country: "Australia",
    Pcode: "6628",
    Locality: "TARDUN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.809400",
    Latitude: "-28.749500"
  },
  {
    Country: "Australia",
    Pcode: "6630",
    Locality: "DEVILS CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.553100",
    Latitude: "-28.664000"
  },
  {
    Country: "Australia",
    Pcode: "6630",
    Locality: "MULLEWA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.553200",
    Latitude: "-28.491300"
  },
  {
    Country: "Australia",
    Pcode: "6630",
    Locality: "MURCHISON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.224800",
    Latitude: "-26.485300"
  },
  {
    Country: "Australia",
    Pcode: "6630",
    Locality: "NERRAMYNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.436600",
    Latitude: "-27.723300"
  },
  {
    Country: "Australia",
    Pcode: "6630",
    Locality: "NUNIERRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.615100",
    Latitude: "-28.155900"
  },
  {
    Country: "Australia",
    Pcode: "6630",
    Locality: "WEST CASUARINAS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.261700",
    Latitude: "-28.866700"
  },
  {
    Country: "Australia",
    Pcode: "6630",
    Locality: "WONGOONDY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.498200",
    Latitude: "-28.850700"
  },
  {
    Country: "Australia",
    Pcode: "6630",
    Locality: "WOOLGORONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.810400",
    Latitude: "-27.698200"
  },
  {
    Country: "Australia",
    Pcode: "6631",
    Locality: "PINDAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.923300",
    Latitude: "-28.526400"
  },
  {
    Country: "Australia",
    Pcode: "6632",
    Locality: "AMBANIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.133900",
    Latitude: "-28.710200"
  },
  {
    Country: "Australia",
    Pcode: "6632",
    Locality: "TENINDEWA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.315200",
    Latitude: "-28.567700"
  },
  {
    Country: "Australia",
    Pcode: "6635",
    Locality: "SOUTH MURCHISON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.571200",
    Latitude: "-27.253000"
  },
  {
    Country: "Australia",
    Pcode: "6635",
    Locality: "YALGOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.729700",
    Latitude: "-28.268200"
  },
  {
    Country: "Australia",
    Pcode: "6638",
    Locality: "COOLADAR HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.174200",
    Latitude: "-28.546000"
  },
  {
    Country: "Australia",
    Pcode: "6638",
    Locality: "DAGGAR HILLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.593200",
    Latitude: "-28.135600"
  },
  {
    Country: "Australia",
    Pcode: "6638",
    Locality: "MOUNT MAGNET",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.884500",
    Latitude: "-28.063400"
  },
  {
    Country: "Australia",
    Pcode: "6638",
    Locality: "PAYNESVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.336100",
    Latitude: "-28.050900"
  },
  {
    Country: "Australia",
    Pcode: "6639",
    Locality: "SANDSTONE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.984800",
    Latitude: "-28.302600"
  },
  {
    Country: "Australia",
    Pcode: "6640",
    Locality: "CUE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.887600",
    Latitude: "-27.448600"
  },
  {
    Country: "Australia",
    Pcode: "6640",
    Locality: "EAST MURCHISON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.144400",
    Latitude: "-26.382600"
  },
  {
    Country: "Australia",
    Pcode: "6640",
    Locality: "LAKE AUSTIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.975400",
    Latitude: "-27.505800"
  },
  {
    Country: "Australia",
    Pcode: "6640",
    Locality: "REEDY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.249500",
    Latitude: "-27.222300"
  },
  {
    Country: "Australia",
    Pcode: "6640",
    Locality: "WELD RANGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.582800",
    Latitude: "-26.994800"
  },
  {
    Country: "Australia",
    Pcode: "6642",
    Locality: "ANGELO RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.522300",
    Latitude: "-23.875200"
  },
  {
    Country: "Australia",
    Pcode: "6642",
    Locality: "CAPRICORN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.700100",
    Latitude: "-24.031400"
  },
  {
    Country: "Australia",
    Pcode: "6642",
    Locality: "KARALUNDI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.673500",
    Latitude: "-26.125200"
  },
  {
    Country: "Australia",
    Pcode: "6642",
    Locality: "KUMARINA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.595500",
    Latitude: "-25.139900"
  },
  {
    Country: "Australia",
    Pcode: "6642",
    Locality: "MEEKATHARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.655900",
    Latitude: "-26.508100"
  },
  {
    Country: "Australia",
    Pcode: "6642",
    Locality: "PEAK HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.367300",
    Latitude: "-25.153500"
  },
  {
    Country: "Australia",
    Pcode: "6646",
    Locality: "LAKE CARNEGIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.437100",
    Latitude: "-26.344200"
  },
  {
    Country: "Australia",
    Pcode: "6646",
    Locality: "LITTLE SANDY DESERT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.416300",
    Latitude: "-25.093700"
  },
  {
    Country: "Australia",
    Pcode: "6646",
    Locality: "WILUNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.422900",
    Latitude: "-26.369400"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "BABBAGE ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.635400",
    Latitude: "-24.884400"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "BERNIER ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.143100",
    Latitude: "-24.823000"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "BROCKMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.651200",
    Latitude: "-24.879600"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "BROWN RANGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.720300",
    Latitude: "-24.881500"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "CARBLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.360400",
    Latitude: "-26.170900"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "CARNARVON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.670300",
    Latitude: "-24.881700"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "CORAL BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.772600",
    Latitude: "-23.143500"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "DORRE ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.098500",
    Latitude: "-25.112900"
  },
  {
    Country: "Australia",
    Pcode: "6929",
    Locality: "MOUNT LAWLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6931",
    Locality: "MAYLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6932",
    Locality: "INGLEWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6933",
    Locality: "BAYSWATER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6934",
    Locality: "BASSENDEAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6935",
    Locality: "GUILDFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6936",
    Locality: "MIDLAND DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6939",
    Locality: "TUART HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6941",
    Locality: "MIRRABOOKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6942",
    Locality: "BASSENDEAN DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6943",
    Locality: "MORLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6944",
    Locality: "MALAGA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6945",
    Locality: "MALAGA DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6946",
    Locality: "WANNEROO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6947",
    Locality: "WANGARA DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6951",
    Locality: "SOUTH PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6952",
    Locality: "COMO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6953",
    Locality: "APPLECROSS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6954",
    Locality: "BOORAGOON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6955",
    Locality: "WILLETTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6956",
    Locality: "MELVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6957",
    Locality: "PALMYRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6959",
    Locality: "FREMANTLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6960",
    Locality: "MYAREE BC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6961",
    Locality: "PALMYRA DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6963",
    Locality: "HAMILTON HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6964",
    Locality: "SUCCESS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6965",
    Locality: "BIBRA LAKE DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6966",
    Locality: "KWINANA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6967",
    Locality: "ROCKINGHAM DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 2001-2350",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6968",
    Locality: "ROCKINGHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1-1478",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6969",
    Locality: "ROCKINGHAM BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 5001-5411",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6970",
    Locality: "CANNING VALE DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6979",
    Locality: "VICTORIA PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6981",
    Locality: "EAST VICTORIA PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6982",
    Locality: "BENTLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1-821",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6983",
    Locality: "BENTLEY DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1001-1316",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6984",
    Locality: "BELMONT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6985",
    Locality: "CLOVERDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6986",
    Locality: "WELSHPOOL DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6987",
    Locality: "CANNINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6988",
    Locality: "THORNLIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6989",
    Locality: "MADDINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6990",
    Locality: "GOSNELLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6991",
    Locality: "KELMSCOTT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1-718",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6992",
    Locality: "ARMADALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6997",
    Locality: "KELMSCOTT DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1001-1240",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7000",
    Locality: "GLEBE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.327100",
    Latitude: "-42.874100"
  },
  {
    Country: "Australia",
    Pcode: "7000",
    Locality: "HOBART",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.325900",
    Latitude: "-42.882300"
  },
  {
    Country: "Australia",
    Pcode: "7000",
    Locality: "MOUNT STUART",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.303100",
    Latitude: "-42.872300"
  },
  {
    Country: "Australia",
    Pcode: "7000",
    Locality: "NORTH HOBART",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.317000",
    Latitude: "-42.871700"
  },
  {
    Country: "Australia",
    Pcode: "7000",
    Locality: "QUEENS DOMAIN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.327600",
    Latitude: "-42.867200"
  },
  {
    Country: "Australia",
    Pcode: "7000",
    Locality: "WEST HOBART",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.302700",
    Latitude: "-42.883600"
  },
  {
    Country: "Australia",
    Pcode: "7001",
    Locality: "HOBART",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "GPO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7002",
    Locality: "NORTH HOBART",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7004",
    Locality: "BATTERY POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.332900",
    Latitude: "-42.890100"
  },
  {
    Country: "Australia",
    Pcode: "7004",
    Locality: "SOUTH HOBART",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.292900",
    Latitude: "-42.898000"
  },
  {
    Country: "Australia",
    Pcode: "7005",
    Locality: "DYNNYRNE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.310800",
    Latitude: "-42.903000"
  },
  {
    Country: "Australia",
    Pcode: "7005",
    Locality: "LOWER SANDY BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7005",
    Locality: "SANDY BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.343800",
    Latitude: "-42.910700"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "LAKE SORELL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.157900",
    Latitude: "-42.038300"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "LIAWENEE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.652600",
    Latitude: "-41.901300"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "LOWER MARSHES",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.205500",
    Latitude: "-42.329400"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "MANGALORE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.273600",
    Latitude: "-42.650000"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "MELTON MOWBRAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.236500",
    Latitude: "-42.449000"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "MIENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.757300",
    Latitude: "-41.981000"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "MILLERS BLUFF",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.099400",
    Latitude: "-41.975000"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "MORASS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.947200",
    Latitude: "-42.022500"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "PELHAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.011400",
    Latitude: "-42.573500"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "PONTVILLE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.278000",
    Latitude: "-42.671300"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "SHANNON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.747600",
    Latitude: "-42.070100"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "STEPPES",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.931200",
    Latitude: "-42.097500"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "TODS CORNER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.807900",
    Latitude: "-41.982600"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "WADDAMANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.757800",
    Latitude: "-42.131400"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "WILBURVILLE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.896900",
    Latitude: "-42.047300"
  },
  {
    Country: "Australia",
    Pcode: "7050",
    Locality: "KINGSTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.288000",
    Latitude: "-42.964300"
  },
  {
    Country: "Australia",
    Pcode: "7050",
    Locality: "KINGSTON BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.321400",
    Latitude: "-42.985400"
  },
  {
    Country: "Australia",
    Pcode: "7051",
    Locality: "KINGSTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7052",
    Locality: "BLACKMANS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.316500",
    Latitude: "-43.006000"
  },
  {
    Country: "Australia",
    Pcode: "7053",
    Locality: "BONNET HILL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.330600",
    Latitude: "-42.968700"
  },
  {
    Country: "Australia",
    Pcode: "7053",
    Locality: "TAROONA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.342800",
    Latitude: "-42.945800"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "BARRETTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.262700",
    Latitude: "-43.045800"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "CONINGHAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.284900",
    Latitude: "-43.085000"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "ELECTRONA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.257600",
    Latitude: "-43.053400"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "FERN TREE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.263000",
    Latitude: "-42.934000"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "HOWDEN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.298900",
    Latitude: "-43.023900"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "LESLIE VALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.224900",
    Latitude: "-42.975900"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "LOWER SNUG",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.251100",
    Latitude: "-43.087400"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "MARGATE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.230800",
    Latitude: "-43.029900"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "NEIKA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.243800",
    Latitude: "-42.948100"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "RIDGEWAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.286900",
    Latitude: "-42.920800"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "SNUG",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.207900",
    Latitude: "-43.076600"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "TINDERBOX",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.318900",
    Latitude: "-43.041300"
  },
  {
    Country: "Australia",
    Pcode: "7054",
    Locality: "WELLINGTON PARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.157000",
    Latitude: "-42.898100"
  },
  {
    Country: "Australia",
    Pcode: "7055",
    Locality: "HUNTINGFIELD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.294500",
    Latitude: "-43.000400"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "CRABTREE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.058900",
    Latitude: "-42.939000"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "CRADOC",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.039400",
    Latitude: "-43.113300"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "GLAZIERS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.019500",
    Latitude: "-43.138400"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "GLEN HUON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.927300",
    Latitude: "-43.042500"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "GLENDEVIE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.991000",
    Latitude: "-43.247000"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "GROVE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.096300",
    Latitude: "-42.982900"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "HASTINGS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.888900",
    Latitude: "-43.402800"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "HUONVILLE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.055300",
    Latitude: "-43.030400"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "IDA BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.914200",
    Latitude: "-43.452500"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "JUDBURY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.925200",
    Latitude: "-42.974700"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "LONNAVALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.784500",
    Latitude: "-42.958000"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "LOWER LONGLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.149900",
    Latitude: "-42.972900"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "LOWER WATTLE GROVE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.018900",
    Latitude: "-43.152700"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "LUCASTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.032700",
    Latitude: "-42.965100"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "LUNE RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.849400",
    Latitude: "-43.430100"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "LYMINGTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.052800",
    Latitude: "-43.198900"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "MOUNTAIN RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.125500",
    Latitude: "-42.943300"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "PETCHEYS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.012100",
    Latitude: "-43.185800"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "RAMINEA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.895800",
    Latitude: "-43.281400"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "RANELAGH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.016700",
    Latitude: "-42.996600"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "RECHERCHE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.872500",
    Latitude: "-43.537500"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "SOUTHPORT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.986700",
    Latitude: "-43.413800"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "SOUTHPORT LAGOON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.951300",
    Latitude: "-43.522200"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "STRATHBLANE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.951900",
    Latitude: "-43.361900"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "WATERLOO",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.972400",
    Latitude: "-43.210500"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "WATTLE GROVE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.016900",
    Latitude: "-43.171200"
  },
  {
    Country: "Australia",
    Pcode: "7109",
    Locality: "WOODSTOCK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.039100",
    Latitude: "-43.078900"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "ABELS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.107900",
    Latitude: "-43.229400"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "CHARLOTTE COVE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.147500",
    Latitude: "-43.268500"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "CYGNET",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.086600",
    Latitude: "-43.134100"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "DEEP BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.134400",
    Latitude: "-43.206200"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "EGGS AND BACON BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.107000",
    Latitude: "-43.245900"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "GARDEN ISLAND CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.178900",
    Latitude: "-43.219000"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "GARDNERS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.153800",
    Latitude: "-43.175900"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "NICHOLLS RIVULET",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.158800",
    Latitude: "-43.133700"
  },
  {
    Country: "Australia",
    Pcode: "7216",
    Locality: "ST HELENS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.200800",
    Latitude: "-41.294500"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "DUNCAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.123600",
    Latitude: "-35.750200"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "EMU BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.553200",
    Latitude: "-35.588400"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "FLINDERS CHASE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.715400",
    Latitude: "-35.918000"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "GOSSE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.912700",
    Latitude: "-35.828300"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "HAINES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.640900",
    Latitude: "-35.818000"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "KARATTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.912300",
    Latitude: "-35.966000"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "KINGSCOTE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.611500",
    Latitude: "-35.657000"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "KOHINOOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.412300",
    Latitude: "-35.768800"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "MACGILLIVRAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.455200",
    Latitude: "-35.943800"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "MENZIES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.468000",
    Latitude: "-35.681500"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "MIDDLE RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.081500",
    Latitude: "-35.691800"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "NEPEAN BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.672400",
    Latitude: "-35.745100"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "NEWLAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.112000",
    Latitude: "-35.869000"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "NORTH CAPE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.598200",
    Latitude: "-35.580000"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "SEAL BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.317600",
    Latitude: "-35.968300"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "SEDDON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.271300",
    Latitude: "-35.859400"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "STOKES BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.175600",
    Latitude: "-35.660500"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "STUN'SAIL BOOM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.027400",
    Latitude: "-36.004900"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "VIVONNE BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.110900",
    Latitude: "-35.982700"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "WESTERN RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.948000",
    Latitude: "-35.715700"
  },
  {
    Country: "Australia",
    Pcode: "5223",
    Locality: "WISANGER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.492100",
    Latitude: "-35.628700"
  },
  {
    Country: "Australia",
    Pcode: "5231",
    Locality: "CHAIN OF PONDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.843800",
    Latitude: "-34.819600"
  },
  {
    Country: "Australia",
    Pcode: "5231",
    Locality: "KERSBROOK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.869000",
    Latitude: "-34.754900"
  },
  {
    Country: "Australia",
    Pcode: "5231",
    Locality: "MILLBROOK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.809400",
    Latitude: "-34.823800"
  },
  {
    Country: "Australia",
    Pcode: "5232",
    Locality: "CUDLEE CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.827500",
    Latitude: "-34.858200"
  },
  {
    Country: "Australia",
    Pcode: "5233",
    Locality: "FORRESTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.926400",
    Latitude: "-34.777300"
  },
  {
    Country: "Australia",
    Pcode: "5233",
    Locality: "GUMERACHA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.885200",
    Latitude: "-34.829900"
  },
  {
    Country: "Australia",
    Pcode: "5233",
    Locality: "KENTON VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.897000",
    Latitude: "-34.858300"
  },
  {
    Country: "Australia",
    Pcode: "5234",
    Locality: "BIRDWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.981300",
    Latitude: "-34.818300"
  },
  {
    Country: "Australia",
    Pcode: "5235",
    Locality: "CROMER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.981600",
    Latitude: "-34.768500"
  },
  {
    Country: "Australia",
    Pcode: "5235",
    Locality: "EDEN VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.129800",
    Latitude: "-34.667400"
  },
  {
    Country: "Australia",
    Pcode: "5235",
    Locality: "FLAXMAN VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.047600",
    Latitude: "-34.628200"
  },
  {
    Country: "Australia",
    Pcode: "5235",
    Locality: "MOUNT PLEASANT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.065600",
    Latitude: "-34.763000"
  },
  {
    Country: "Australia",
    Pcode: "5235",
    Locality: "SPRINGTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.122600",
    Latitude: "-34.722900"
  },
  {
    Country: "Australia",
    Pcode: "5235",
    Locality: "TAUNTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.089600",
    Latitude: "-34.687000"
  },
  {
    Country: "Australia",
    Pcode: "5236",
    Locality: "TUNGKILLO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.064800",
    Latitude: "-34.862000"
  },
  {
    Country: "Australia",
    Pcode: "5237",
    Locality: "APAMURRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.205300",
    Latitude: "-34.861000"
  },
  {
    Country: "Australia",
    Pcode: "5237",
    Locality: "MILENDELLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.209500",
    Latitude: "-34.809900"
  },
  {
    Country: "Australia",
    Pcode: "5237",
    Locality: "PALMER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.157100",
    Latitude: "-34.874100"
  },
  {
    Country: "Australia",
    Pcode: "5237",
    Locality: "SANDERSTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.212700",
    Latitude: "-34.721600"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "ANGAS VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.288600",
    Latitude: "-34.749400"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "BIG BEND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.614500",
    Latitude: "-34.638300"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "BOLTO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.317000",
    Latitude: "-34.913800"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "BOWHILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.668100",
    Latitude: "-34.956500"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "CAURNAMONT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.595300",
    Latitude: "-34.865200"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "CLAYPANS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.713200",
    Latitude: "-34.820700"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "COWIRRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.338400",
    Latitude: "-34.920600"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "FIVE MILES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.395500",
    Latitude: "-34.866100"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "FORSTER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.560500",
    Latitude: "-34.732500"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "FRAHNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.362800",
    Latitude: "-34.888500"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "FRAYVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.244500",
    Latitude: "-34.851700"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "JULANKA HOLDINGS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.544900",
    Latitude: "-34.873000"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "LAKE CARLET",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.532300",
    Latitude: "-34.873500"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "MANNUM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.352700",
    Latitude: "-34.856500"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "NILDOTTIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.665900",
    Latitude: "-34.709300"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "OLD TEAL FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.542500",
    Latitude: "-34.871500"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "PELLARING FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.389100",
    Latitude: "-34.862100"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "REEDY CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.993800",
    Latitude: "-37.014300"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "ROSETOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.854700",
    Latitude: "-36.819300"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "SANDY GROVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.856000",
    Latitude: "-36.872100"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "TARATAP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.953800",
    Latitude: "-36.577500"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "TILLEY SWAMP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.908400",
    Latitude: "-36.358000"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "WANGOLINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.814700",
    Latitude: "-36.961900"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "WEST RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.915700",
    Latitude: "-36.742600"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "WYOMI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.829300",
    Latitude: "-36.858200"
  },
  {
    Country: "Australia",
    Pcode: "5276",
    Locality: "BRAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.995300",
    Latitude: "-37.267700"
  },
  {
    Country: "Australia",
    Pcode: "5276",
    Locality: "NORA CREINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.886300",
    Latitude: "-37.309200"
  },
  {
    Country: "Australia",
    Pcode: "5276",
    Locality: "ROBE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.835600",
    Latitude: "-37.215200"
  },
  {
    Country: "Australia",
    Pcode: "5277",
    Locality: "COMAUM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.924600",
    Latitude: "-37.222800"
  },
  {
    Country: "Australia",
    Pcode: "5277",
    Locality: "GLENROY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.821400",
    Latitude: "-37.221200"
  },
  {
    Country: "Australia",
    Pcode: "5277",
    Locality: "MAAOUPE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.702100",
    Latitude: "-37.261900"
  },
  {
    Country: "Australia",
    Pcode: "5277",
    Locality: "MONBULLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.728300",
    Latitude: "-37.393800"
  },
  {
    Country: "Australia",
    Pcode: "5277",
    Locality: "NANGWARRY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.883200",
    Latitude: "-37.538500"
  },
  {
    Country: "Australia",
    Pcode: "5277",
    Locality: "PENOLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.883400",
    Latitude: "-37.394200"
  },
  {
    Country: "Australia",
    Pcode: "5277",
    Locality: "PLEASANT PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.905200",
    Latitude: "-37.650800"
  },
  {
    Country: "Australia",
    Pcode: "5277",
    Locality: "TARPEENA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.788300",
    Latitude: "-37.647200"
  },
  {
    Country: "Australia",
    Pcode: "5278",
    Locality: "KALANGADOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.703000",
    Latitude: "-37.537500"
  },
  {
    Country: "Australia",
    Pcode: "5278",
    Locality: "KRONGART",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.766200",
    Latitude: "-37.484000"
  },
  {
    Country: "Australia",
    Pcode: "5278",
    Locality: "MOERLONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.705300",
    Latitude: "-37.484300"
  },
  {
    Country: "Australia",
    Pcode: "5278",
    Locality: "WEPAR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.712700",
    Latitude: "-37.628000"
  },
  {
    Country: "Australia",
    Pcode: "5279",
    Locality: "KOORINE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.623900",
    Latitude: "-37.620600"
  },
  {
    Country: "Australia",
    Pcode: "5279",
    Locality: "MOUNT BURR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.464200",
    Latitude: "-37.546500"
  },
  {
    Country: "Australia",
    Pcode: "5279",
    Locality: "MOUNT MCINTYRE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.546000",
    Latitude: "-37.563400"
  },
  {
    Country: "Australia",
    Pcode: "5279",
    Locality: "SHORT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.583500",
    Latitude: "-37.368900"
  },
  {
    Country: "Australia",
    Pcode: "5279",
    Locality: "WATTLE RANGE EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.651500",
    Latitude: "-37.393400"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "BEACHPORT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.995600",
    Latitude: "-37.440800"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "CANUNDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.259400",
    Latitude: "-37.651000"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "CLAY WELLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.116800",
    Latitude: "-37.329100"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "FURNER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.334800",
    Latitude: "-37.413000"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "GERMAN FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.427700",
    Latitude: "-37.779800"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "HATHERLEIGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.291100",
    Latitude: "-37.486800"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "KANGAROO INN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.217000",
    Latitude: "-37.344100"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "MAGAREY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.083400",
    Latitude: "-37.416200"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "MILLICENT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.331000",
    Latitude: "-37.604300"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "RENDELSHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.221500",
    Latitude: "-37.530700"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "ROCKY CAMP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.401100",
    Latitude: "-37.575100"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "SEBASTOPOL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.381000",
    Latitude: "-37.516000"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "SOUTHEND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.161200",
    Latitude: "-37.550000"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "TANTANOOLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.454100",
    Latitude: "-37.697900"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "THORNLEA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.172200",
    Latitude: "-37.422200"
  },
  {
    Country: "Australia",
    Pcode: "5280",
    Locality: "WATTLE RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.517900",
    Latitude: "-37.433100"
  },
  {
    Country: "Australia",
    Pcode: "5290",
    Locality: "MOUNT GAMBIER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.782300",
    Latitude: "-37.826300"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "ALLENDALE EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.698200",
    Latitude: "-38.006600"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "BLACKFELLOWS CAVES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.461900",
    Latitude: "-37.931500"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "BURRUNGULE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.592900",
    Latitude: "-37.788100"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "CAPE DOUGLAS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.598600",
    Latitude: "-38.010300"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "CAROLINE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.908200",
    Latitude: "-37.917100"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "CARPENTER ROCKS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.419500",
    Latitude: "-37.880500"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "CAVETON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.830100",
    Latitude: "-37.955300"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "COMPTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.680700",
    Latitude: "-37.807700"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "DISMAL SWAMP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.736100",
    Latitude: "-37.694300"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "DONOVANS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.949300",
    Latitude: "-37.999600"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "EIGHT MILE CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.800000",
    Latitude: "-38.019500"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "GERMAN CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.506100",
    Latitude: "-37.824500"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "GLENBURNIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.918300",
    Latitude: "-37.825500"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "GLENCOE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.605600",
    Latitude: "-37.678600"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "KONGORONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.542000",
    Latitude: "-37.918400"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "MIL-LEL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.864100",
    Latitude: "-37.778700"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "MINGBOOL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.885000",
    Latitude: "-37.697600"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "MOORAK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.686200",
    Latitude: "-37.868100"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "MOUNT GAMBIER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "PO BAGS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "MOUNT GAMBIER EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "MOUNT GAMBIER WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5291",
    Locality: "MOUNT SCHANK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.701900",
    Latitude: "-37.943100"
  },
  {
    Country: "Australia",
    Pcode: "5373",
    Locality: "ST JOHNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.943500",
    Latitude: "-34.377700"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "AUSTRALIA PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.163900",
    Latitude: "-34.081700"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "BOWER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.290000",
    Latitude: "-34.069000"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "BROWNLOW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.340400",
    Latitude: "-34.226300"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "BUCHANAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.007400",
    Latitude: "-34.211100"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "EUDUNDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.109200",
    Latitude: "-34.177600"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "FRANKTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.206000",
    Latitude: "-34.298300"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "HAMPDEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.004600",
    Latitude: "-34.160200"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "HANSBOROUGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.050300",
    Latitude: "-34.257600"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "JULIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.002100",
    Latitude: "-34.118900"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "MOUNT MARY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.459600",
    Latitude: "-34.101600"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "NEALES FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.183500",
    Latitude: "-34.232300"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "NGAPALA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.996000",
    Latitude: "-34.053800"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "PEEP HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.159000",
    Latitude: "-34.153000"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "POINT PASS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.061300",
    Latitude: "-34.087500"
  },
  {
    Country: "Australia",
    Pcode: "5374",
    Locality: "SUTHERLANDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.224100",
    Latitude: "-34.159800"
  },
  {
    Country: "Australia",
    Pcode: "5381",
    Locality: "BRADY CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.994600",
    Latitude: "-33.969700"
  },
  {
    Country: "Australia",
    Pcode: "5381",
    Locality: "BRIGHT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.123000",
    Latitude: "-33.914000"
  },
  {
    Country: "Australia",
    Pcode: "5381",
    Locality: "EMU DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.001000",
    Latitude: "-33.894000"
  },
  {
    Country: "Australia",
    Pcode: "5381",
    Locality: "GERANIUM PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.214000",
    Latitude: "-33.975400"
  },
  {
    Country: "Australia",
    Pcode: "5381",
    Locality: "HALLELUJAH HILLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.057100",
    Latitude: "-33.875000"
  },
  {
    Country: "Australia",
    Pcode: "5381",
    Locality: "ROBERTSTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.078100",
    Latitude: "-33.980500"
  },
  {
    Country: "Australia",
    Pcode: "5381",
    Locality: "ROCKY PLAIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.156000",
    Latitude: "-34.014400"
  },
  {
    Country: "Australia",
    Pcode: "5381",
    Locality: "WORLDS END",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.105300",
    Latitude: "-33.794600"
  },
  {
    Country: "Australia",
    Pcode: "5400",
    Locality: "MAGDALA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.702000",
    Latitude: "-34.419900"
  },
  {
    Country: "Australia",
    Pcode: "5400",
    Locality: "PINKERTON PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.614400",
    Latitude: "-34.426400"
  },
  {
    Country: "Australia",
    Pcode: "5400",
    Locality: "WASLEYS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.692800",
    Latitude: "-34.488100"
  },
  {
    Country: "Australia",
    Pcode: "5400",
    Locality: "WOOLSHEDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.646800",
    Latitude: "-34.485400"
  },
  {
    Country: "Australia",
    Pcode: "5401",
    Locality: "ALMA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.648100",
    Latitude: "-34.259700"
  },
  {
    Country: "Australia",
    Pcode: "5401",
    Locality: "HAMLEY BRIDGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.661900",
    Latitude: "-34.348700"
  },
  {
    Country: "Australia",
    Pcode: "5401",
    Locality: "SALTER SPRINGS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.609900",
    Latitude: "-34.186500"
  },
  {
    Country: "Australia",
    Pcode: "5410",
    Locality: "LINWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.760900",
    Latitude: "-34.382000"
  },
  {
    Country: "Australia",
    Pcode: "5410",
    Locality: "STOCKPORT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.737900",
    Latitude: "-34.330200"
  },
  {
    Country: "Australia",
    Pcode: "5411",
    Locality: "GILES CORNER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.722600",
    Latitude: "-34.225800"
  },
  {
    Country: "Australia",
    Pcode: "5411",
    Locality: "TARLEE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.776500",
    Latitude: "-34.260100"
  },
  {
    Country: "Australia",
    Pcode: "5412",
    Locality: "NAVAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5412",
    Locality: "RHYNIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.677500",
    Latitude: "-34.135300"
  },
  {
    Country: "Australia",
    Pcode: "5412",
    Locality: "RIVERTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.769200",
    Latitude: "-34.167000"
  },
  {
    Country: "Australia",
    Pcode: "5412",
    Locality: "WOOLSHED FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.642000",
    Latitude: "-34.113900"
  },
  {
    Country: "Australia",
    Pcode: "5413",
    Locality: "APOINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.934900",
    Latitude: "-33.910300"
  },
  {
    Country: "Australia",
    Pcode: "5413",
    Locality: "BLACK SPRINGS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.879000",
    Latitude: "-33.897400"
  },
  {
    Country: "Australia",
    Pcode: "5413",
    Locality: "MARRABEL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.876000",
    Latitude: "-34.135900"
  },
  {
    Country: "Australia",
    Pcode: "5413",
    Locality: "SADDLEWORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.783800",
    Latitude: "-34.083900"
  },
  {
    Country: "Australia",
    Pcode: "5413",
    Locality: "STEELTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.883400",
    Latitude: "-34.058000"
  },
  {
    Country: "Australia",
    Pcode: "5413",
    Locality: "TARNMA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.942600",
    Latitude: "-34.159500"
  },
  {
    Country: "Australia",
    Pcode: "5413",
    Locality: "TOTHILL BELT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.937100",
    Latitude: "-34.003300"
  },
  {
    Country: "Australia",
    Pcode: "5413",
    Locality: "TOTHILL CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.933200",
    Latitude: "-34.081300"
  },
  {
    Country: "Australia",
    Pcode: "5413",
    Locality: "WATERLOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.886800",
    Latitude: "-33.989900"
  },
  {
    Country: "Australia",
    Pcode: "5414",
    Locality: "MANOORA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.802900",
    Latitude: "-33.993100"
  },
  {
    Country: "Australia",
    Pcode: "5415",
    Locality: "MINTARO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.765300",
    Latitude: "-33.932700"
  },
  {
    Country: "Australia",
    Pcode: "5415",
    Locality: "STANLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.768000",
    Latitude: "-33.887400"
  },
  {
    Country: "Australia",
    Pcode: "5416",
    Locality: "FARRELL FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.796100",
    Latitude: "-33.803300"
  },
  {
    Country: "Australia",
    Pcode: "5416",
    Locality: "PORTER LAGOON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.874500",
    Latitude: "-33.823300"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "BALAH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.828800",
    Latitude: "-33.646000"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "BALDINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.099100",
    Latitude: "-33.692900"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "BOOBOROWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.773000",
    Latitude: "-33.559300"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "BUNYUNG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.828000",
    Latitude: "-33.798200"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "BURRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.925700",
    Latitude: "-33.682400"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "BURRA EASTERN DISTRICTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.226100",
    Latitude: "-33.641900"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "CANEGRASS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.103300",
    Latitude: "-33.530200"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "DANGGALI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.719400",
    Latitude: "-33.329500"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "FARAWAY HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.678500",
    Latitude: "-33.172000"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "GLUEPOT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.145100",
    Latitude: "-33.749100"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "GUM CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.774000",
    Latitude: "-33.715400"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "HANSON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.850800",
    Latitude: "-33.755700"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "KOONOONA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.930900",
    Latitude: "-33.828800"
  },
  {
    Country: "Australia",
    Pcode: "5417",
    Locality: "LEIGHTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.763900",
    Latitude: "-33.645800"
  },
  {
    Country: "Australia",
    Pcode: "5490",
    Locality: "CALTOWIE NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.479400",
    Latitude: "-33.113000"
  },
  {
    Country: "Australia",
    Pcode: "5490",
    Locality: "CALTOWIE WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.382900",
    Latitude: "-33.156700"
  },
  {
    Country: "Australia",
    Pcode: "5491",
    Locality: "BELALIE EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.716600",
    Latitude: "-33.269200"
  },
  {
    Country: "Australia",
    Pcode: "5491",
    Locality: "BELALIE NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.707100",
    Latitude: "-33.159800"
  },
  {
    Country: "Australia",
    Pcode: "5491",
    Locality: "BUNDALEER GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.543500",
    Latitude: "-33.345300"
  },
  {
    Country: "Australia",
    Pcode: "5491",
    Locality: "BUNDALEER NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.597700",
    Latitude: "-33.272000"
  },
  {
    Country: "Australia",
    Pcode: "5491",
    Locality: "HORNSDALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.523600",
    Latitude: "-33.041400"
  },
  {
    Country: "Australia",
    Pcode: "5491",
    Locality: "JAMESTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.587600",
    Latitude: "-33.166600"
  },
  {
    Country: "Australia",
    Pcode: "5491",
    Locality: "WEST BUNDALEER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.498100",
    Latitude: "-33.314700"
  },
  {
    Country: "Australia",
    Pcode: "5493",
    Locality: "YONGALA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.748800",
    Latitude: "-33.029100"
  },
  {
    Country: "Australia",
    Pcode: "5495",
    Locality: "BAROOTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.012100",
    Latitude: "-32.893000"
  },
  {
    Country: "Australia",
    Pcode: "5495",
    Locality: "GERMEIN BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.035100",
    Latitude: "-33.154700"
  },
  {
    Country: "Australia",
    Pcode: "5495",
    Locality: "MAMBRAY CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.969600",
    Latitude: "-32.795100"
  },
  {
    Country: "Australia",
    Pcode: "5495",
    Locality: "NECTAR BROOK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.992600",
    Latitude: "-32.722700"
  },
  {
    Country: "Australia",
    Pcode: "5495",
    Locality: "PORT GERMEIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.011100",
    Latitude: "-33.007400"
  },
  {
    Country: "Australia",
    Pcode: "5495",
    Locality: "WEEROONA ISLAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.033800",
    Latitude: "-33.101500"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "AVON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.339000",
    Latitude: "-34.279100"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "CALOMBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.406300",
    Latitude: "-34.392500"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "DUBLIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.348200",
    Latitude: "-34.463200"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "LEWISTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.592800",
    Latitude: "-34.609900"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "LONG PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.347200",
    Latitude: "-34.348000"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "LOWER LIGHT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.399000",
    Latitude: "-34.532300"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "MIDDLE BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.447500",
    Latitude: "-34.601200"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "PARHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.258600",
    Latitude: "-34.429200"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "PORT GAWLER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.468100",
    Latitude: "-34.651300"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "THOMPSON BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.278600",
    Latitude: "-34.484500"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "TWO WELLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.522400",
    Latitude: "-34.605600"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "WEBB BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.261100",
    Latitude: "-34.448100"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "WILD HORSE PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.274600",
    Latitude: "-34.347400"
  },
  {
    Country: "Australia",
    Pcode: "5501",
    Locality: "WINDSOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.314300",
    Latitude: "-34.408200"
  },
  {
    Country: "Australia",
    Pcode: "5502",
    Locality: "FISCHER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.619900",
    Latitude: "-34.496600"
  },
  {
    Country: "Australia",
    Pcode: "5502",
    Locality: "GRACE PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.487400",
    Latitude: "-34.353800"
  },
  {
    Country: "Australia",
    Pcode: "5502",
    Locality: "KORUNYE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.512100",
    Latitude: "-34.527500"
  },
  {
    Country: "Australia",
    Pcode: "5502",
    Locality: "MALLALA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.500400",
    Latitude: "-34.444700"
  },
  {
    Country: "Australia",
    Pcode: "5502",
    Locality: "REDBANKS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.581400",
    Latitude: "-34.477300"
  },
  {
    Country: "Australia",
    Pcode: "5502",
    Locality: "REEVES PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.613300",
    Latitude: "-34.545900"
  },
  {
    Country: "Australia",
    Pcode: "5510",
    Locality: "LOCHIEL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.150700",
    Latitude: "-33.919200"
  },
  {
    Country: "Australia",
    Pcode: "5520",
    Locality: "BARUNGA GAP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.122600",
    Latitude: "-33.813500"
  },
  {
    Country: "Australia",
    Pcode: "5520",
    Locality: "BUMBUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.226200",
    Latitude: "-33.910500"
  },
  {
    Country: "Australia",
    Pcode: "5520",
    Locality: "BURNSFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.257400",
    Latitude: "-33.682100"
  },
  {
    Country: "Australia",
    Pcode: "5520",
    Locality: "SNOWTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.191200",
    Latitude: "-33.765300"
  },
  {
    Country: "Australia",
    Pcode: "5520",
    Locality: "WOKURNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.063200",
    Latitude: "-33.721300"
  },
  {
    Country: "Australia",
    Pcode: "5521",
    Locality: "REDHILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.200100",
    Latitude: "-33.533200"
  },
  {
    Country: "Australia",
    Pcode: "5522",
    Locality: "FISHERMAN BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.943100",
    Latitude: "-33.557800"
  },
  {
    Country: "Australia",
    Pcode: "5522",
    Locality: "PORT BROUGHTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.950300",
    Latitude: "-33.673600"
  },
  {
    Country: "Australia",
    Pcode: "5522",
    Locality: "WARD HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.850100",
    Latitude: "-33.688600"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "BEETALOO VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.214200",
    Latitude: "-33.187800"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "CLEMENTS GAP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.065000",
    Latitude: "-33.510400"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "CRYSTAL BROOK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.187500",
    Latitude: "-33.355900"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "HUDDLESTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.289600",
    Latitude: "-33.338200"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "MERRITON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.141800",
    Latitude: "-33.426900"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "NARRIDY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.316700",
    Latitude: "-33.411400"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "NUROM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.096900",
    Latitude: "-33.322800"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "WANDEARAH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "WANDEARAH EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.047800",
    Latitude: "-33.398600"
  },
  {
    Country: "Australia",
    Pcode: "5523",
    Locality: "WANDEARAH WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.925800",
    Latitude: "-33.408200"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "BUNGAMA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.068000",
    Latitude: "-33.199500"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "COONAMIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.043000",
    Latitude: "-33.192000"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "LOWER BROUGHTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.951500",
    Latitude: "-33.320200"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "NAPPERBY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.126300",
    Latitude: "-33.168200"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "NELSHABY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.118600",
    Latitude: "-33.125200"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "PIRIE EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.002200",
    Latitude: "-33.260800"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "PORT DAVIS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.873300",
    Latitude: "-33.236700"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "PORT PIRIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.010600",
    Latitude: "-33.171200"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "PORT PIRIE SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.027200",
    Latitude: "-33.209600"
  },
  {
    Country: "Australia",
    Pcode: "5540",
    Locality: "PORT PIRIE WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.974900",
    Latitude: "-33.152400"
  },
  {
    Country: "Australia",
    Pcode: "5611",
    Locality: "IRON KNOB",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.150700",
    Latitude: "-32.741800"
  },
  {
    Country: "Australia",
    Pcode: "5611",
    Locality: "KATUNGA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.109300",
    Latitude: "-32.789100"
  },
  {
    Country: "Australia",
    Pcode: "5611",
    Locality: "LAKE GILLES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.743000",
    Latitude: "-33.002600"
  },
  {
    Country: "Australia",
    Pcode: "5611",
    Locality: "MYOLA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.227400",
    Latitude: "-33.001900"
  },
  {
    Country: "Australia",
    Pcode: "5611",
    Locality: "SECRET ROCKS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.894200",
    Latitude: "-33.246700"
  },
  {
    Country: "Australia",
    Pcode: "5630",
    Locality: "EDILLILIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.663900",
    Latitude: "-34.410000"
  },
  {
    Country: "Australia",
    Pcode: "5631",
    Locality: "COCKALEECHIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.858500",
    Latitude: "-34.229100"
  },
  {
    Country: "Australia",
    Pcode: "5631",
    Locality: "CUMMINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.638200",
    Latitude: "-34.274200"
  },
  {
    Country: "Australia",
    Pcode: "5632",
    Locality: "KAPINNIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.526300",
    Latitude: "-34.166900"
  },
  {
    Country: "Australia",
    Pcode: "5632",
    Locality: "KARKOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.692500",
    Latitude: "-34.014600"
  },
  {
    Country: "Australia",
    Pcode: "5632",
    Locality: "MITCHELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.526300",
    Latitude: "-34.018100"
  },
  {
    Country: "Australia",
    Pcode: "5632",
    Locality: "YEELANNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.692600",
    Latitude: "-34.110000"
  },
  {
    Country: "Australia",
    Pcode: "5633",
    Locality: "BOONERDO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.976000",
    Latitude: "-33.562500"
  },
  {
    Country: "Australia",
    Pcode: "5633",
    Locality: "LOCK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.732100",
    Latitude: "-33.587800"
  },
  {
    Country: "Australia",
    Pcode: "5633",
    Locality: "ULYERRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.690700",
    Latitude: "-33.409700"
  },
  {
    Country: "Australia",
    Pcode: "5640",
    Locality: "CAMPOONA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.410700",
    Latitude: "-33.554100"
  },
  {
    Country: "Australia",
    Pcode: "5640",
    Locality: "CLEVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.517200",
    Latitude: "-33.708300"
  },
  {
    Country: "Australia",
    Pcode: "5640",
    Locality: "JAMIESON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.367800",
    Latitude: "-33.419100"
  },
  {
    Country: "Australia",
    Pcode: "5640",
    Locality: "WADDIKEE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.321900",
    Latitude: "-33.348800"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "BARNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.704700",
    Latitude: "-33.174900"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "BUCKLEBOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.977600",
    Latitude: "-32.776100"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "BUNGEROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.208400",
    Latitude: "-32.791400"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "CARALUE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.116300",
    Latitude: "-33.278500"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "CORTLINYE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.339800",
    Latitude: "-33.037200"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "CUNYARIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.340000",
    Latitude: "-32.880800"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "KELLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.514800",
    Latitude: "-33.226500"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "KIMBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.412700",
    Latitude: "-33.132700"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "MOSELEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.503300",
    Latitude: "-33.037500"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "PANITYA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.087900",
    Latitude: "-33.194500"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "PINKAWILLINIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.953200",
    Latitude: "-32.984100"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "SOLOMON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.339700",
    Latitude: "-33.227000"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "WILCHERRY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.466700",
    Latitude: "-32.928500"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "YALANDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.704500",
    Latitude: "-33.273400"
  },
  {
    Country: "Australia",
    Pcode: "5641",
    Locality: "YELTANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.312100",
    Latitude: "-32.782900"
  },
  {
    Country: "Australia",
    Pcode: "5642",
    Locality: "DARKE PEAK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.185800",
    Latitude: "-33.421900"
  },
  {
    Country: "Australia",
    Pcode: "5642",
    Locality: "HAMBIDGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.911300",
    Latitude: "-33.409300"
  },
  {
    Country: "Australia",
    Pcode: "5642",
    Locality: "KIELPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.184500",
    Latitude: "-33.575300"
  },
  {
    Country: "Australia",
    Pcode: "5642",
    Locality: "MURLONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.976300",
    Latitude: "-33.713100"
  },
  {
    Country: "Australia",
    Pcode: "5642",
    Locality: "RUDALL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.204400",
    Latitude: "-33.720600"
  },
  {
    Country: "Australia",
    Pcode: "5650",
    Locality: "COOTRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.942800",
    Latitude: "-33.261700"
  },
  {
    Country: "Australia",
    Pcode: "5650",
    Locality: "KOONGAWA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.862600",
    Latitude: "-33.224500"
  },
  {
    Country: "Australia",
    Pcode: "5650",
    Locality: "WARRAMBOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.568700",
    Latitude: "-33.243800"
  },
  {
    Country: "Australia",
    Pcode: "5651",
    Locality: "KYANCUTTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.642100",
    Latitude: "-33.134600"
  },
  {
    Country: "Australia",
    Pcode: "5652",
    Locality: "PANEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5652",
    Locality: "PYGERY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.394500",
    Latitude: "-32.932800"
  },
  {
    Country: "Australia",
    Pcode: "5652",
    Locality: "WUDINNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.461200",
    Latitude: "-33.089600"
  },
  {
    Country: "Australia",
    Pcode: "5653",
    Locality: "YANINEE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.297000",
    Latitude: "-32.910400"
  },
  {
    Country: "Australia",
    Pcode: "5654",
    Locality: "COCATA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.245000",
    Latitude: "-33.240300"
  },
  {
    Country: "Australia",
    Pcode: "5654",
    Locality: "KARCULTABY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.898900",
    Latitude: "-32.940800"
  },
  {
    Country: "Australia",
    Pcode: "5654",
    Locality: "MINNIPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.141600",
    Latitude: "-32.792100"
  },
  {
    Country: "Australia",
    Pcode: "5654",
    Locality: "MOUNT DAMPER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.128300",
    Latitude: "-33.125000"
  },
  {
    Country: "Australia",
    Pcode: "5655",
    Locality: "BOCKELBERG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.055800",
    Latitude: "-32.580700"
  },
  {
    Country: "Australia",
    Pcode: "5655",
    Locality: "GAWLER RANGES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.398000",
    Latitude: "-32.517300"
  },
  {
    Country: "Australia",
    Pcode: "5655",
    Locality: "KALDOONERA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.874000",
    Latitude: "-32.586400"
  },
  {
    Country: "Australia",
    Pcode: "5655",
    Locality: "LOCKES CLAYPAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.076000",
    Latitude: "-32.493800"
  },
  {
    Country: "Australia",
    Pcode: "5655",
    Locality: "NARLABY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.881400",
    Latitude: "-32.474300"
  },
  {
    Country: "Australia",
    Pcode: "5655",
    Locality: "POOCHERA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.864000",
    Latitude: "-32.710900"
  },
  {
    Country: "Australia",
    Pcode: "5660",
    Locality: "CHILPENUNDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.528500",
    Latitude: "-32.593000"
  },
  {
    Country: "Australia",
    Pcode: "5660",
    Locality: "CUNGENA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.676200",
    Latitude: "-32.609400"
  },
  {
    Country: "Australia",
    Pcode: "5661",
    Locality: "KOOLGERA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.741000",
    Latitude: "-32.345300"
  },
  {
    Country: "Australia",
    Pcode: "5661",
    Locality: "PIMBAACLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.443200",
    Latitude: "-32.348600"
  },
  {
    Country: "Australia",
    Pcode: "5661",
    Locality: "WALLALA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.608100",
    Latitude: "-32.314900"
  },
  {
    Country: "Australia",
    Pcode: "5661",
    Locality: "WIRRULLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.560300",
    Latitude: "-32.413600"
  },
  {
    Country: "Australia",
    Pcode: "5661",
    Locality: "YANTANABIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.702100",
    Latitude: "-32.473000"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "BRAMFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.011900",
    Latitude: "-33.652200"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "COLTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.888800",
    Latitude: "-33.468400"
  },
  {
    Country: "Australia",
    Pcode: "5670",
    Locality: "COOLILLIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.239300",
    Latitude: "-33.437800"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "MOOLAWATANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.811300",
    Latitude: "-29.939000"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "MOUNT SERLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.776400",
    Latitude: "-30.400000"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "MULGA VIEW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.057300",
    Latitude: "-30.925100"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "NEPABUNNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.968900",
    Latitude: "-30.559700"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "NORTH MOOLOOLOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.539700",
    Latitude: "-30.644000"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "PINDA SPRINGS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.861700",
    Latitude: "-30.798000"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "UMBERATANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.103900",
    Latitude: "-30.148900"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "WERTALOONA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.465400",
    Latitude: "-30.942700"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "WOOLTANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.627300",
    Latitude: "-30.334600"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "YANKANINNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.006100",
    Latitude: "-30.348300"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "ALTON DOWNS STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.013700",
    Latitude: "-26.360400"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "CALLANNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.653700",
    Latitude: "-29.540800"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "CLAYTON STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.431300",
    Latitude: "-29.310700"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "CLIFTON HILLS STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.241100",
    Latitude: "-27.158500"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "COWARIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.499500",
    Latitude: "-27.503400"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "DULKANINNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.647600",
    Latitude: "-29.102000"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "ETADUNNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.531200",
    Latitude: "-28.803200"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "FARINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.275500",
    Latitude: "-30.072900"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "FARINA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.215700",
    Latitude: "-30.085300"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "KALAMURINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.627300",
    Latitude: "-27.800400"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "LAKE EYRE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.431600",
    Latitude: "-28.674700"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "MARREE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.009600",
    Latitude: "-29.610900"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "MARREE STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.065000",
    Latitude: "-29.700700"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "MULKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.913400",
    Latitude: "-28.381300"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "MULOORINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.024700",
    Latitude: "-29.285600"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "MUNDOWDNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.399600",
    Latitude: "-29.681000"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "MUNGERANIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.120400",
    Latitude: "-28.019700"
  },
  {
    Country: "Australia",
    Pcode: "5733",
    Locality: "PANDIE PANDIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.781000",
    Latitude: "-26.408200"
  },
  {
    Country: "Australia",
    Pcode: "5734",
    Locality: "CROWN POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.722200",
    Latitude: "-26.099800"
  },
  {
    Country: "Australia",
    Pcode: "5734",
    Locality: "ERINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.754300",
    Latitude: "-26.546200"
  },
  {
    Country: "Australia",
    Pcode: "5734",
    Locality: "MACUMBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.091200",
    Latitude: "-27.232600"
  },
  {
    Country: "Australia",
    Pcode: "5734",
    Locality: "MOUNT SARAH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.354700",
    Latitude: "-27.010700"
  },
  {
    Country: "Australia",
    Pcode: "5734",
    Locality: "OODNADATTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.422500",
    Latitude: "-27.550000"
  },
  {
    Country: "Australia",
    Pcode: "5734",
    Locality: "SIMPSON DESERT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.291800",
    Latitude: "-26.793000"
  },
  {
    Country: "Australia",
    Pcode: "5734",
    Locality: "TODMORDEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.721500",
    Latitude: "-27.279800"
  },
  {
    Country: "Australia",
    Pcode: "5734",
    Locality: "WITJIRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.683000",
    Latitude: "-26.369200"
  },
  {
    Country: "Australia",
    Pcode: "5942",
    Locality: "REGENCY PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5950",
    Locality: "ADELAIDE AIRPORT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.533500",
    Latitude: "-34.947600"
  },
  {
    Country: "Australia",
    Pcode: "5950",
    Locality: "EXPORT PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6000",
    Locality: "CITY DELIVERY CENTRE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6000",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.858300",
    Latitude: "-31.954700"
  },
  {
    Country: "Australia",
    Pcode: "6000",
    Locality: "PERTH GPO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6001",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES & LOCKED BAGS",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6003",
    Locality: "HIGHGATE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.870700",
    Latitude: "-31.940200"
  },
  {
    Country: "Australia",
    Pcode: "6003",
    Locality: "NORTHBRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.855600",
    Latitude: "-31.946000"
  },
  {
    Country: "Australia",
    Pcode: "6004",
    Locality: "EAST PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.876600",
    Latitude: "-31.957500"
  },
  {
    Country: "Australia",
    Pcode: "6005",
    Locality: "KINGS PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.832400",
    Latitude: "-31.962200"
  },
  {
    Country: "Australia",
    Pcode: "6005",
    Locality: "WEST PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.842100",
    Latitude: "-31.958500"
  },
  {
    Country: "Australia",
    Pcode: "6006",
    Locality: "NORTH PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.854100",
    Latitude: "-31.928000"
  },
  {
    Country: "Australia",
    Pcode: "6007",
    Locality: "LEEDERVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.838800",
    Latitude: "-31.931700"
  },
  {
    Country: "Australia",
    Pcode: "6007",
    Locality: "WEST LEEDERVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.831500",
    Latitude: "-31.935200"
  },
  {
    Country: "Australia",
    Pcode: "6008",
    Locality: "DAGLISH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.808900",
    Latitude: "-31.951800"
  },
  {
    Country: "Australia",
    Pcode: "6008",
    Locality: "SHENTON PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.797900",
    Latitude: "-31.956000"
  },
  {
    Country: "Australia",
    Pcode: "6008",
    Locality: "SUBIACO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.827100",
    Latitude: "-31.948600"
  },
  {
    Country: "Australia",
    Pcode: "6008",
    Locality: "SUBIACO EAST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6009",
    Locality: "BROADWAY NEDLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 3001-3479",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6009",
    Locality: "CRAWLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.825900",
    Latitude: "-31.983700"
  },
  {
    Country: "Australia",
    Pcode: "6009",
    Locality: "DALKEITH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.799900",
    Latitude: "-31.996800"
  },
  {
    Country: "Australia",
    Pcode: "6009",
    Locality: "NEDLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.802800",
    Latitude: "-31.979100"
  },
  {
    Country: "Australia",
    Pcode: "6009",
    Locality: "NEDLANDS DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6010",
    Locality: "CLAREMONT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.780800",
    Latitude: "-31.979000"
  },
  {
    Country: "Australia",
    Pcode: "6010",
    Locality: "CLAREMONT NORTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 2001-2190",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6084",
    Locality: "WALYUNGA NATIONAL PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.073400",
    Latitude: "-31.719100"
  },
  {
    Country: "Australia",
    Pcode: "6090",
    Locality: "MALAGA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.897600",
    Latitude: "-31.856600"
  },
  {
    Country: "Australia",
    Pcode: "6100",
    Locality: "BURSWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.894000",
    Latitude: "-31.956300"
  },
  {
    Country: "Australia",
    Pcode: "6100",
    Locality: "LATHLAIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.907000",
    Latitude: "-31.967400"
  },
  {
    Country: "Australia",
    Pcode: "6100",
    Locality: "VICTORIA PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.895600",
    Latitude: "-31.976300"
  },
  {
    Country: "Australia",
    Pcode: "6101",
    Locality: "CARLISLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.918200",
    Latitude: "-31.981000"
  },
  {
    Country: "Australia",
    Pcode: "6101",
    Locality: "CARLISLE NORTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6101",
    Locality: "CARLISLE SOUTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6101",
    Locality: "EAST VICTORIA PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.904500",
    Latitude: "-31.988500"
  },
  {
    Country: "Australia",
    Pcode: "6102",
    Locality: "BENTLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.924700",
    Latitude: "-32.002300"
  },
  {
    Country: "Australia",
    Pcode: "6102",
    Locality: "BENTLEY DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6102",
    Locality: "BENTLEY SOUTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6102",
    Locality: "ST JAMES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.909300",
    Latitude: "-31.999500"
  },
  {
    Country: "Australia",
    Pcode: "6103",
    Locality: "RIVERVALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.917700",
    Latitude: "-31.961400"
  },
  {
    Country: "Australia",
    Pcode: "6104",
    Locality: "ASCOT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.925600",
    Latitude: "-31.934400"
  },
  {
    Country: "Australia",
    Pcode: "6104",
    Locality: "BELMONT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.931100",
    Latitude: "-31.950000"
  },
  {
    Country: "Australia",
    Pcode: "6104",
    Locality: "REDCLIFFE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.945700",
    Latitude: "-31.938600"
  },
  {
    Country: "Australia",
    Pcode: "6105",
    Locality: "CLOVERDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.941400",
    Latitude: "-31.962700"
  },
  {
    Country: "Australia",
    Pcode: "6105",
    Locality: "KEWDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.953600",
    Latitude: "-31.980300"
  },
  {
    Country: "Australia",
    Pcode: "6105",
    Locality: "PERTH AIRPORT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.976600",
    Latitude: "-31.950000"
  },
  {
    Country: "Australia",
    Pcode: "6106",
    Locality: "WELSHPOOL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.946800",
    Latitude: "-31.992700"
  },
  {
    Country: "Australia",
    Pcode: "6107",
    Locality: "BECKENHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.959200",
    Latitude: "-32.027100"
  },
  {
    Country: "Australia",
    Pcode: "6107",
    Locality: "CANNINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.936400",
    Latitude: "-32.016000"
  },
  {
    Country: "Australia",
    Pcode: "6107",
    Locality: "EAST CANNINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.956200",
    Latitude: "-32.010000"
  },
  {
    Country: "Australia",
    Pcode: "6107",
    Locality: "KENWICK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.979300",
    Latitude: "-32.024800"
  },
  {
    Country: "Australia",
    Pcode: "6107",
    Locality: "QUEENS PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.945000",
    Latitude: "-32.004400"
  },
  {
    Country: "Australia",
    Pcode: "6107",
    Locality: "WATTLE GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.013600",
    Latitude: "-32.005800"
  },
  {
    Country: "Australia",
    Pcode: "6107",
    Locality: "WILSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.913100",
    Latitude: "-32.020100"
  },
  {
    Country: "Australia",
    Pcode: "6108",
    Locality: "THORNLIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.957600",
    Latitude: "-32.061300"
  },
  {
    Country: "Australia",
    Pcode: "6109",
    Locality: "MADDINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.995500",
    Latitude: "-32.044500"
  },
  {
    Country: "Australia",
    Pcode: "6109",
    Locality: "ORANGE GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.028500",
    Latitude: "-32.037000"
  },
  {
    Country: "Australia",
    Pcode: "6110",
    Locality: "GOSNELLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.994800",
    Latitude: "-32.086600"
  },
  {
    Country: "Australia",
    Pcode: "6110",
    Locality: "HUNTINGDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.965300",
    Latitude: "-32.085300"
  },
  {
    Country: "Australia",
    Pcode: "6110",
    Locality: "MARTIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.049200",
    Latitude: "-32.074100"
  },
  {
    Country: "Australia",
    Pcode: "6110",
    Locality: "SOUTHERN RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.962600",
    Latitude: "-32.106800"
  },
  {
    Country: "Australia",
    Pcode: "6111",
    Locality: "ASHENDON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.209900",
    Latitude: "-32.209700"
  },
  {
    Country: "Australia",
    Pcode: "6111",
    Locality: "CAMILLO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.003600",
    Latitude: "-32.111500"
  },
  {
    Country: "Australia",
    Pcode: "6111",
    Locality: "CANNING MILLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.097300",
    Latitude: "-32.056200"
  },
  {
    Country: "Australia",
    Pcode: "6111",
    Locality: "CHAMPION LAKES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.988100",
    Latitude: "-32.117200"
  },
  {
    Country: "Australia",
    Pcode: "6111",
    Locality: "KARRAGULLEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.133800",
    Latitude: "-32.103700"
  },
  {
    Country: "Australia",
    Pcode: "6111",
    Locality: "KELMSCOTT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.024300",
    Latitude: "-32.111700"
  },
  {
    Country: "Australia",
    Pcode: "6111",
    Locality: "KELMSCOTT DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6111",
    Locality: "LESLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.246500",
    Latitude: "-32.132500"
  },
  {
    Country: "Australia",
    Pcode: "6111",
    Locality: "ROLEYSTONE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.076600",
    Latitude: "-32.117800"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "ARMADALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.005200",
    Latitude: "-32.150400"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "BEDFORDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.062100",
    Latitude: "-32.172900"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "BROOKDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.001100",
    Latitude: "-32.167100"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "FORRESTDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.927600",
    Latitude: "-32.148600"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "HARRISDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.932800",
    Latitude: "-32.116700"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "HAYNES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.977600",
    Latitude: "-32.156600"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "HILBERT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.979100",
    Latitude: "-32.177700"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "MOUNT NASURA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.026400",
    Latitude: "-32.137300"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "MOUNT RICHON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.021400",
    Latitude: "-32.158300"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "PIARA WATERS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.916000",
    Latitude: "-32.130200"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "SEVILLE GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.989400",
    Latitude: "-32.134400"
  },
  {
    Country: "Australia",
    Pcode: "6112",
    Locality: "WUNGONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.013500",
    Latitude: "-32.184300"
  },
  {
    Country: "Australia",
    Pcode: "6121",
    Locality: "OAKFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.928400",
    Latitude: "-32.208500"
  },
  {
    Country: "Australia",
    Pcode: "6121",
    Locality: "OLDBURY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.918900",
    Latitude: "-32.265900"
  },
  {
    Country: "Australia",
    Pcode: "6122",
    Locality: "BYFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.996800",
    Latitude: "-32.221800"
  },
  {
    Country: "Australia",
    Pcode: "6122",
    Locality: "CARDUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.989200",
    Latitude: "-32.252200"
  },
  {
    Country: "Australia",
    Pcode: "6122",
    Locality: "DARLING DOWNS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.994300",
    Latitude: "-32.193300"
  },
  {
    Country: "Australia",
    Pcode: "6122",
    Locality: "KARRAKUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.064700",
    Latitude: "-32.254000"
  },
  {
    Country: "Australia",
    Pcode: "6123",
    Locality: "MUNDIJONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.982300",
    Latitude: "-32.287100"
  },
  {
    Country: "Australia",
    Pcode: "6123",
    Locality: "WHITBY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.012600",
    Latitude: "-32.284900"
  },
  {
    Country: "Australia",
    Pcode: "6124",
    Locality: "JARRAHDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.147400",
    Latitude: "-32.370200"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "WORSLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.986700",
    Latitude: "-33.297300"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "YOURDAMUNG LAKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.280600",
    Latitude: "-33.149500"
  },
  {
    Country: "Australia",
    Pcode: "6226",
    Locality: "ROELANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.874900",
    Latitude: "-33.312300"
  },
  {
    Country: "Australia",
    Pcode: "6227",
    Locality: "BUREKUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.847200",
    Latitude: "-33.327200"
  },
  {
    Country: "Australia",
    Pcode: "6228",
    Locality: "WATERLOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.762100",
    Latitude: "-33.324900"
  },
  {
    Country: "Australia",
    Pcode: "6229",
    Locality: "PICTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.697600",
    Latitude: "-33.344200"
  },
  {
    Country: "Australia",
    Pcode: "6229",
    Locality: "PICTON EAST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.724600",
    Latitude: "-33.345100"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "BUNBURY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.642200",
    Latitude: "-33.327600"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "CAREY PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.653200",
    Latitude: "-33.354000"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "COLLEGE GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.659900",
    Latitude: "-33.375300"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "DALYELLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.615600",
    Latitude: "-33.409700"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "DAVENPORT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.682100",
    Latitude: "-33.370100"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "EAST BUNBURY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.658300",
    Latitude: "-33.338600"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "GELORUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.637500",
    Latitude: "-33.429800"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "GLEN IRIS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.682800",
    Latitude: "-33.341500"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "PELICAN POINT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.688300",
    Latitude: "-33.323000"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "SOUTH BUNBURY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.635700",
    Latitude: "-33.348700"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "USHER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.627800",
    Latitude: "-33.380000"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "VITTORIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.672800",
    Latitude: "-33.319800"
  },
  {
    Country: "Australia",
    Pcode: "6230",
    Locality: "WITHERS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.629900",
    Latitude: "-33.366800"
  },
  {
    Country: "Australia",
    Pcode: "6231",
    Locality: "BUNBURY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6232",
    Locality: "EATON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.715400",
    Latitude: "-33.318600"
  },
  {
    Country: "Australia",
    Pcode: "6232",
    Locality: "MILLBRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.735500",
    Latitude: "-33.306100"
  },
  {
    Country: "Australia",
    Pcode: "6233",
    Locality: "AUSTRALIND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.726400",
    Latitude: "-33.282800"
  },
  {
    Country: "Australia",
    Pcode: "6233",
    Locality: "BINNINGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.715000",
    Latitude: "-33.141300"
  },
  {
    Country: "Australia",
    Pcode: "6233",
    Locality: "LESCHENAULT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.714300",
    Latitude: "-33.250900"
  },
  {
    Country: "Australia",
    Pcode: "6233",
    Locality: "PARKFIELD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.711400",
    Latitude: "-33.185300"
  },
  {
    Country: "Australia",
    Pcode: "6233",
    Locality: "WELLESLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.764300",
    Latitude: "-33.203800"
  },
  {
    Country: "Australia",
    Pcode: "6236",
    Locality: "CROOKED BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.787500",
    Latitude: "-33.476400"
  },
  {
    Country: "Australia",
    Pcode: "6236",
    Locality: "DARDANUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.778300",
    Latitude: "-33.403100"
  },
  {
    Country: "Australia",
    Pcode: "6236",
    Locality: "DARDANUP WEST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.725100",
    Latitude: "-33.389300"
  },
  {
    Country: "Australia",
    Pcode: "6236",
    Locality: "FERGUSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.844800",
    Latitude: "-33.432900"
  },
  {
    Country: "Australia",
    Pcode: "6236",
    Locality: "HENTY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.830000",
    Latitude: "-33.376400"
  },
  {
    Country: "Australia",
    Pcode: "6236",
    Locality: "PARADISE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.762700",
    Latitude: "-33.376600"
  },
  {
    Country: "Australia",
    Pcode: "6236",
    Locality: "WELLINGTON FOREST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.952900",
    Latitude: "-33.428200"
  },
  {
    Country: "Australia",
    Pcode: "6236",
    Locality: "WELLINGTON MILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.911500",
    Latitude: "-33.463900"
  },
  {
    Country: "Australia",
    Pcode: "6237",
    Locality: "BOYANUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.712700",
    Latitude: "-33.480800"
  },
  {
    Country: "Australia",
    Pcode: "6237",
    Locality: "ELGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.629800",
    Latitude: "-33.541200"
  },
  {
    Country: "Australia",
    Pcode: "6237",
    Locality: "GWINDINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.735400",
    Latitude: "-33.523000"
  },
  {
    Country: "Australia",
    Pcode: "6237",
    Locality: "NORTH BOYANUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.692000",
    Latitude: "-33.427400"
  },
  {
    Country: "Australia",
    Pcode: "6237",
    Locality: "STRATHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.602300",
    Latitude: "-33.469500"
  },
  {
    Country: "Australia",
    Pcode: "6237",
    Locality: "THE PLAINS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.689200",
    Latitude: "-33.561200"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "ARGYLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.769100",
    Latitude: "-33.542300"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "BEELERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.862700",
    Latitude: "-33.549600"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "BROOKHAMPTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.893900",
    Latitude: "-33.632300"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "CHARLEY CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.905500",
    Latitude: "-33.583800"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "DONNYBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.813800",
    Latitude: "-33.564800"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "GLEN MERVYN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.077300",
    Latitude: "-33.530100"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "PAYNEDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.769200",
    Latitude: "-33.614200"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "QUEENWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.900400",
    Latitude: "-33.533100"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "THOMSON BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.970100",
    Latitude: "-33.622400"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "UPPER CAPEL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.801100",
    Latitude: "-33.669900"
  },
  {
    Country: "Australia",
    Pcode: "6239",
    Locality: "YABBERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.024500",
    Latitude: "-33.516600"
  },
  {
    Country: "Australia",
    Pcode: "6240",
    Locality: "LOWDEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.965400",
    Latitude: "-33.531800"
  },
  {
    Country: "Australia",
    Pcode: "6243",
    Locality: "WILGA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.233000",
    Latitude: "-33.676600"
  },
  {
    Country: "Australia",
    Pcode: "6243",
    Locality: "WILGA WEST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.161200",
    Latitude: "-33.718800"
  },
  {
    Country: "Australia",
    Pcode: "6244",
    Locality: "BOYUP BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.420100",
    Latitude: "-33.837300"
  },
  {
    Country: "Australia",
    Pcode: "6244",
    Locality: "CHOWERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.643000",
    Latitude: "-34.099500"
  },
  {
    Country: "Australia",
    Pcode: "6244",
    Locality: "DINNINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.505500",
    Latitude: "-33.758500"
  },
  {
    Country: "Australia",
    Pcode: "6244",
    Locality: "KULIKUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.694400",
    Latitude: "-33.778000"
  },
  {
    Country: "Australia",
    Pcode: "6244",
    Locality: "MAYANUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.502200",
    Latitude: "-33.959700"
  },
  {
    Country: "Australia",
    Pcode: "6244",
    Locality: "SCOTTS BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.635200",
    Latitude: "-33.975000"
  },
  {
    Country: "Australia",
    Pcode: "6244",
    Locality: "TONEBRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.674000",
    Latitude: "-34.189300"
  },
  {
    Country: "Australia",
    Pcode: "6244",
    Locality: "TRIGWELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.567200",
    Latitude: "-33.638700"
  },
  {
    Country: "Australia",
    Pcode: "6251",
    Locality: "BRAZIER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.814700",
    Latitude: "-33.756200"
  },
  {
    Country: "Australia",
    Pcode: "6251",
    Locality: "KIRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.926700",
    Latitude: "-33.709900"
  },
  {
    Country: "Australia",
    Pcode: "6311",
    Locality: "WARDERING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.313400",
    Latitude: "-32.838400"
  },
  {
    Country: "Australia",
    Pcode: "6311",
    Locality: "YORNANING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.160000",
    Latitude: "-32.744500"
  },
  {
    Country: "Australia",
    Pcode: "6312",
    Locality: "BOUNDAIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.359900",
    Latitude: "-32.960800"
  },
  {
    Country: "Australia",
    Pcode: "6312",
    Locality: "DUMBERNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.111200",
    Latitude: "-33.003000"
  },
  {
    Country: "Australia",
    Pcode: "6312",
    Locality: "HILLSIDE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.214300",
    Latitude: "-32.896300"
  },
  {
    Country: "Australia",
    Pcode: "6312",
    Locality: "MINIGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.060000",
    Latitude: "-32.935900"
  },
  {
    Country: "Australia",
    Pcode: "6312",
    Locality: "NARROGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.171400",
    Latitude: "-32.937700"
  },
  {
    Country: "Australia",
    Pcode: "6312",
    Locality: "NARROGIN VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.253000",
    Latitude: "-32.955300"
  },
  {
    Country: "Australia",
    Pcode: "6312",
    Locality: "NOMANS LAKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.498000",
    Latitude: "-33.020400"
  },
  {
    Country: "Australia",
    Pcode: "6312",
    Locality: "TOOLIBIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.638400",
    Latitude: "-32.966000"
  },
  {
    Country: "Australia",
    Pcode: "6312",
    Locality: "YILLIMINNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.432700",
    Latitude: "-32.874600"
  },
  {
    Country: "Australia",
    Pcode: "6313",
    Locality: "HIGHBURY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.235900",
    Latitude: "-33.097700"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "ARTHUR RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.003100",
    Latitude: "-33.351600"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "BALLAYING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.590200",
    Latitude: "-33.323500"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "CANCANNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.579300",
    Latitude: "-33.119200"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "COLLANILLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.524400",
    Latitude: "-33.238600"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "DONGOLOCKING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.749600",
    Latitude: "-33.134100"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "GUNDARING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.494100",
    Latitude: "-33.332300"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "JALORAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.434300",
    Latitude: "-33.174100"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "LIME LAKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.379400",
    Latitude: "-33.410800"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "MINDING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.183200",
    Latitude: "-33.281700"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "PIESSEVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.223600",
    Latitude: "-33.187400"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "WAGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.346900",
    Latitude: "-33.303600"
  },
  {
    Country: "Australia",
    Pcode: "6315",
    Locality: "WEDGECARRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.197800",
    Latitude: "-33.419000"
  },
  {
    Country: "Australia",
    Pcode: "6316",
    Locality: "BOYERINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.343500",
    Latitude: "-33.487900"
  },
  {
    Country: "Australia",
    Pcode: "6316",
    Locality: "CARTMETICUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.516300",
    Latitude: "-33.475700"
  },
  {
    Country: "Australia",
    Pcode: "6316",
    Locality: "GLENCOE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.619300",
    Latitude: "-33.462400"
  },
  {
    Country: "Australia",
    Pcode: "6316",
    Locality: "KENMARE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.191400",
    Latitude: "-33.575000"
  },
  {
    Country: "Australia",
    Pcode: "6316",
    Locality: "WESTWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.334100",
    Latitude: "-33.597700"
  },
  {
    Country: "Australia",
    Pcode: "6316",
    Locality: "WOODANILLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.447600",
    Latitude: "-33.570400"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "BADGEBUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.897300",
    Latitude: "-33.621700"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "BULLOCK HILLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.694700",
    Latitude: "-33.433900"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "CARROLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.409000",
    Latitude: "-33.717700"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "COBLININE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.702900",
    Latitude: "-33.572100"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "COYRECUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.853900",
    Latitude: "-33.701500"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "DATATINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.844700",
    Latitude: "-33.412600"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "EWLYAMARTUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.704900",
    Latitude: "-33.699200"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "KATANNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.578900",
    Latitude: "-33.684300"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "MARRACOONDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.460200",
    Latitude: "-33.664900"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "MOOJEBING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.551700",
    Latitude: "-33.607800"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "MURDONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.565800",
    Latitude: "-33.760600"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "PINWERNYING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.536900",
    Latitude: "-33.663800"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "SOUTH DATATINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.867300",
    Latitude: "-33.504000"
  },
  {
    Country: "Australia",
    Pcode: "6317",
    Locality: "SOUTH GLENCOE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.684800",
    Latitude: "-33.514000"
  },
  {
    Country: "Australia",
    Pcode: "6318",
    Locality: "BROOMEHILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6318",
    Locality: "BROOMEHILL EAST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.772000",
    Latitude: "-33.883000"
  },
  {
    Country: "Australia",
    Pcode: "6318",
    Locality: "BROOMEHILL VILLAGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.638500",
    Latitude: "-33.849500"
  },
  {
    Country: "Australia",
    Pcode: "6318",
    Locality: "BROOMEHILL WEST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.487400",
    Latitude: "-33.880500"
  },
  {
    Country: "Australia",
    Pcode: "6320",
    Locality: "BOBALONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.567800",
    Latitude: "-34.004300"
  },
  {
    Country: "Australia",
    Pcode: "6320",
    Locality: "BORDERDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.422000",
    Latitude: "-34.056600"
  },
  {
    Country: "Australia",
    Pcode: "6320",
    Locality: "DARTNALL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.735900",
    Latitude: "-34.038900"
  },
  {
    Country: "Australia",
    Pcode: "6320",
    Locality: "LAKE TOOLBRUNUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.886100",
    Latitude: "-34.138800"
  },
  {
    Country: "Australia",
    Pcode: "6320",
    Locality: "MOONIES HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.522200",
    Latitude: "-34.110700"
  },
  {
    Country: "Australia",
    Pcode: "6320",
    Locality: "TAMBELLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.633400",
    Latitude: "-34.043200"
  },
  {
    Country: "Australia",
    Pcode: "6320",
    Locality: "WANSBROUGH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.704700",
    Latitude: "-34.154900"
  },
  {
    Country: "Australia",
    Pcode: "6321",
    Locality: "CRANBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.415700",
    Latitude: "-34.227200"
  },
  {
    Country: "Australia",
    Pcode: "6322",
    Locality: "TENTERDEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.363100",
    Latitude: "-34.403300"
  },
  {
    Country: "Australia",
    Pcode: "6323",
    Locality: "KENDENUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.585500",
    Latitude: "-34.481100"
  },
  {
    Country: "Australia",
    Pcode: "6324",
    Locality: "DENBARKER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.432900",
    Latitude: "-34.713900"
  },
  {
    Country: "Australia",
    Pcode: "6324",
    Locality: "FOREST HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.404300",
    Latitude: "-34.605900"
  },
  {
    Country: "Australia",
    Pcode: "6324",
    Locality: "MOUNT BARKER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.655100",
    Latitude: "-34.623100"
  },
  {
    Country: "Australia",
    Pcode: "6324",
    Locality: "PERILLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.221700",
    Latitude: "-34.604300"
  },
  {
    Country: "Australia",
    Pcode: "6324",
    Locality: "PORONGURUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.866100",
    Latitude: "-34.668800"
  },
  {
    Country: "Australia",
    Pcode: "6324",
    Locality: "SOUTH STIRLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.265800",
    Latitude: "-34.638500"
  },
  {
    Country: "Australia",
    Pcode: "6324",
    Locality: "TAKALARUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.103600",
    Latitude: "-34.578400"
  },
  {
    Country: "Australia",
    Pcode: "6410",
    Locality: "MOUNT CAROLINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.636200",
    Latitude: "-31.765800"
  },
  {
    Country: "Australia",
    Pcode: "6410",
    Locality: "NORTH KELLERBERRIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.696800",
    Latitude: "-31.478500"
  },
  {
    Country: "Australia",
    Pcode: "6411",
    Locality: "DOODLAKINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.851100",
    Latitude: "-31.529700"
  },
  {
    Country: "Australia",
    Pcode: "6411",
    Locality: "SOUTH DOODLAKINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.891800",
    Latitude: "-31.674400"
  },
  {
    Country: "Australia",
    Pcode: "6412",
    Locality: "BAANDEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.974500",
    Latitude: "-31.529600"
  },
  {
    Country: "Australia",
    Pcode: "6412",
    Locality: "NORTH BAANDEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.915600",
    Latitude: "-31.400300"
  },
  {
    Country: "Australia",
    Pcode: "6413",
    Locality: "HINES HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.062000",
    Latitude: "-31.508000"
  },
  {
    Country: "Australia",
    Pcode: "6414",
    Locality: "NANGEENAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.199100",
    Latitude: "-31.512800"
  },
  {
    Country: "Australia",
    Pcode: "6415",
    Locality: "GOOMARIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.409900",
    Latitude: "-31.219200"
  },
  {
    Country: "Australia",
    Pcode: "6415",
    Locality: "KORBEL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.128100",
    Latitude: "-31.655200"
  },
  {
    Country: "Australia",
    Pcode: "6415",
    Locality: "MERREDIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.295400",
    Latitude: "-31.472900"
  },
  {
    Country: "Australia",
    Pcode: "6415",
    Locality: "NOKANING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.185400",
    Latitude: "-31.378100"
  },
  {
    Country: "Australia",
    Pcode: "6415",
    Locality: "NORPA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.385000",
    Latitude: "-31.573300"
  },
  {
    Country: "Australia",
    Pcode: "6415",
    Locality: "TANDEGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.545800",
    Latitude: "-31.675100"
  },
  {
    Country: "Australia",
    Pcode: "6418",
    Locality: "BRUCE ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.142300",
    Latitude: "-31.860000"
  },
  {
    Country: "Australia",
    Pcode: "6419",
    Locality: "ARDATH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.113100",
    Latitude: "-32.046900"
  },
  {
    Country: "Australia",
    Pcode: "6420",
    Locality: "CRAMPHORNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.613600",
    Latitude: "-31.839900"
  },
  {
    Country: "Australia",
    Pcode: "6420",
    Locality: "MUNTADGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.579000",
    Latitude: "-31.745100"
  },
  {
    Country: "Australia",
    Pcode: "6421",
    Locality: "BURRACOPPIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.444400",
    Latitude: "-31.407800"
  },
  {
    Country: "Australia",
    Pcode: "6421",
    Locality: "SOUTH BURRACOPPIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.620200",
    Latitude: "-31.566100"
  },
  {
    Country: "Australia",
    Pcode: "6421",
    Locality: "WARRALAKIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.606000",
    Latitude: "-30.976500"
  },
  {
    Country: "Australia",
    Pcode: "6422",
    Locality: "WALGOOLAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.592300",
    Latitude: "-31.333100"
  },
  {
    Country: "Australia",
    Pcode: "6423",
    Locality: "BOODAROCKIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.863500",
    Latitude: "-30.894700"
  },
  {
    Country: "Australia",
    Pcode: "6423",
    Locality: "CARRABIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.683100",
    Latitude: "-31.460300"
  },
  {
    Country: "Australia",
    Pcode: "6423",
    Locality: "WARRACHUPPIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.719300",
    Latitude: "-30.976600"
  },
  {
    Country: "Australia",
    Pcode: "6423",
    Locality: "WESTONIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.722400",
    Latitude: "-31.248000"
  },
  {
    Country: "Australia",
    Pcode: "6424",
    Locality: "BODALLIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.895700",
    Latitude: "-31.350300"
  },
  {
    Country: "Australia",
    Pcode: "6424",
    Locality: "NORTH BODALLIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.915300",
    Latitude: "-31.118000"
  },
  {
    Country: "Australia",
    Pcode: "6424",
    Locality: "SOUTH BODALLIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.890600",
    Latitude: "-31.556100"
  },
  {
    Country: "Australia",
    Pcode: "6425",
    Locality: "DULYALBIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.137800",
    Latitude: "-31.556800"
  },
  {
    Country: "Australia",
    Pcode: "6425",
    Locality: "MOORINE ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.167100",
    Latitude: "-31.345100"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "CORINTHIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.129900",
    Latitude: "-31.122100"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "GHOOLI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.557500",
    Latitude: "-31.234600"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "HOLLETON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.210100",
    Latitude: "-31.972200"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "MARVEL LOCH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.436200",
    Latitude: "-31.542000"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "MOUNT HAMPTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.984800",
    Latitude: "-31.761800"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "MOUNT HOLLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.602100",
    Latitude: "-32.087400"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "MOUNT JACKSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.011400",
    Latitude: "-30.082400"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "MOUNT PALMER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.816500",
    Latitude: "-31.486500"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "PARKER RANGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.790900",
    Latitude: "-31.858300"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "SKELETON ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.477600",
    Latitude: "-31.868700"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "SOUTH YILGARN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.309900",
    Latitude: "-31.792600"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "SOUTHERN CROSS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.338800",
    Latitude: "-31.253200"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "TURKEY HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.389000",
    Latitude: "-30.958100"
  },
  {
    Country: "Australia",
    Pcode: "6426",
    Locality: "YELLOWDINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.791100",
    Latitude: "-31.058100"
  },
  {
    Country: "Australia",
    Pcode: "6427",
    Locality: "KOOLYANOBBING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.578300",
    Latitude: "-30.849400"
  },
  {
    Country: "Australia",
    Pcode: "6428",
    Locality: "BABAKIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.027600",
    Latitude: "-32.150000"
  },
  {
    Country: "Australia",
    Pcode: "6429",
    Locality: "BOORABBIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.177600",
    Latitude: "-30.925600"
  },
  {
    Country: "Australia",
    Pcode: "6429",
    Locality: "BULLABULLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.885200",
    Latitude: "-30.989800"
  },
  {
    Country: "Australia",
    Pcode: "6429",
    Locality: "COOLGARDIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.154000",
    Latitude: "-30.949600"
  },
  {
    Country: "Australia",
    Pcode: "6429",
    Locality: "KARRAMINDIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.378700",
    Latitude: "-30.958200"
  },
  {
    Country: "Australia",
    Pcode: "6429",
    Locality: "LONDONDERRY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.280400",
    Latitude: "-31.230000"
  },
  {
    Country: "Australia",
    Pcode: "6429",
    Locality: "MOUNT BURGES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.907700",
    Latitude: "-30.535100"
  },
  {
    Country: "Australia",
    Pcode: "6429",
    Locality: "VICTORIA ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.658000",
    Latitude: "-31.465100"
  },
  {
    Country: "Australia",
    Pcode: "6429",
    Locality: "WALLAROO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.383500",
    Latitude: "-30.676700"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "BINDULI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.385100",
    Latitude: "-30.800000"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "BROADWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.449700",
    Latitude: "-30.788900"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "HANNANS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.453000",
    Latitude: "-30.718700"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "KALGOORLIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.468700",
    Latitude: "-30.756000"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "KARLKURLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.428600",
    Latitude: "-30.732100"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "LAMINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.461800",
    Latitude: "-30.734100"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "MULLINGAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.483900",
    Latitude: "-30.695100"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "PICCADILLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.464800",
    Latitude: "-30.742300"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "SOMERVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.454300",
    Latitude: "-30.766900"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "SOUTH KALGOORLIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.480300",
    Latitude: "-30.761800"
  },
  {
    Country: "Australia",
    Pcode: "6510",
    Locality: "GILLINGARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.035600",
    Latitude: "-30.902600"
  },
  {
    Country: "Australia",
    Pcode: "6510",
    Locality: "KOOJAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.071000",
    Latitude: "-30.803000"
  },
  {
    Country: "Australia",
    Pcode: "6510",
    Locality: "MOORA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.006700",
    Latitude: "-30.604900"
  },
  {
    Country: "Australia",
    Pcode: "6510",
    Locality: "WALEBING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.212500",
    Latitude: "-30.673100"
  },
  {
    Country: "Australia",
    Pcode: "6511",
    Locality: "CERVANTES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.071600",
    Latitude: "-30.498200"
  },
  {
    Country: "Australia",
    Pcode: "6512",
    Locality: "COOMBERDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.075500",
    Latitude: "-30.462800"
  },
  {
    Country: "Australia",
    Pcode: "6512",
    Locality: "NAMBAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.029600",
    Latitude: "-30.375500"
  },
  {
    Country: "Australia",
    Pcode: "6513",
    Locality: "GUNYIDI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.162600",
    Latitude: "-30.136200"
  },
  {
    Country: "Australia",
    Pcode: "6513",
    Locality: "WATHEROO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.093100",
    Latitude: "-30.277200"
  },
  {
    Country: "Australia",
    Pcode: "6514",
    Locality: "GREEN HEAD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.028400",
    Latitude: "-30.081300"
  },
  {
    Country: "Australia",
    Pcode: "4697",
    Locality: "RAGLAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.768300",
    Latitude: "-23.859300"
  },
  {
    Country: "Australia",
    Pcode: "4699",
    Locality: "BAJOOL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.587400",
    Latitude: "-23.683900"
  },
  {
    Country: "Australia",
    Pcode: "4699",
    Locality: "PORT ALMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.778300",
    Latitude: "-23.581600"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "ALLENSTOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.504200",
    Latitude: "-23.393000"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "DEPOT HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.519700",
    Latitude: "-23.392900"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "FAIRY BOWER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.457900",
    Latitude: "-23.402000"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "GREAT KEPPEL ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "PORT CURTIS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.544400",
    Latitude: "-23.419600"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "ROCKHAMPTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "ROCKHAMPTON CITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.510000",
    Latitude: "-23.379800"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "ROCKHAMPTON HOSPITAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "THE KEPPELS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.969600",
    Latitude: "-23.201500"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "THE RANGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.495600",
    Latitude: "-23.392600"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "WANDAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.497000",
    Latitude: "-23.369200"
  },
  {
    Country: "Australia",
    Pcode: "4700",
    Locality: "WEST ROCKHAMPTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.476200",
    Latitude: "-23.384900"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "BERSERKER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.527900",
    Latitude: "-23.364800"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "CENTRAL QUEENSLAND UNIVERSITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "FRENCHVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.548700",
    Latitude: "-23.344700"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "GREENLAKE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.555600",
    Latitude: "-23.022700"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "IRONPOT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "ROCKHAMPTON",
    Category: "Delivery Area",
    Longitude: "150.602900",
    Latitude: "-23.273300"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "KAWANA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.506400",
    Latitude: "-23.339500"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "KOONGAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.548400",
    Latitude: "-23.368600"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "LAKES CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.572700",
    Latitude: "-23.374700"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "LIMESTONE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.561400",
    Latitude: "-23.287200"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "MOUNT ARCHER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.580700",
    Latitude: "-23.325800"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "NANKIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.691500",
    Latitude: "-23.423000"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "NERIMBERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.615500",
    Latitude: "-23.433300"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "NORMAN GARDENS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.538800",
    Latitude: "-23.326800"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "PARK AVENUE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.511500",
    Latitude: "-23.359600"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "RED HILL ROCKHAMPTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "ROCKHAMPTON DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "ROCKYVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.521800",
    Latitude: "-23.250200"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "SANDRINGHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.539600",
    Latitude: "-23.204200"
  },
  {
    Country: "Australia",
    Pcode: "4701",
    Locality: "THE COMMON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.533900",
    Latitude: "-23.382800"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "ALBERTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.751700",
    Latitude: "-24.261900"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "ALSACE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.290200",
    Latitude: "-23.287900"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "ALTON DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.372700",
    Latitude: "-23.313500"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "ANAKIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "ARGOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BALCOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.644500",
    Latitude: "-23.288000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BANANA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.191800",
    Latitude: "-24.419000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BARALABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.931600",
    Latitude: "-24.287900"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BARNARD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.672700",
    Latitude: "-24.006400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BINGEGANG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.174400",
    Latitude: "-23.152800"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BLACKDOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.127300",
    Latitude: "-23.891200"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BLUFF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.057900",
    Latitude: "-23.494800"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BOOLBURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.868400",
    Latitude: "-23.666800"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BOULDERCOMBE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.485200",
    Latitude: "-23.533300"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "BUSHLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.270200",
    Latitude: "-23.585400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "CANAL CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.426500",
    Latitude: "-22.943200"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "CANOONA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.102100",
    Latitude: "-23.013200"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "CAWARRAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.670800",
    Latitude: "-23.250900"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "CENTRAL QUEENSLAND MC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "COMET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.530200",
    Latitude: "-23.666800"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "CONSUELO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.371200",
    Latitude: "-24.705200"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "COOMOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.516700",
    Latitude: "-23.864600"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "COOROOMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.757200",
    Latitude: "-23.284400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "COORUMBENE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.749900",
    Latitude: "-24.557900"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "PROSPECT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.428000",
    Latitude: "-24.523500"
  },
  {
    Country: "Australia",
    Pcode: "4715",
    Locality: "VALENTINE PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.815200",
    Latitude: "-24.423800"
  },
  {
    Country: "Australia",
    Pcode: "4716",
    Locality: "LAWGI DAWES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.786600",
    Latitude: "-24.602600"
  },
  {
    Country: "Australia",
    Pcode: "4716",
    Locality: "THANGOOL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.589900",
    Latitude: "-24.577700"
  },
  {
    Country: "Australia",
    Pcode: "4717",
    Locality: "BLACKWATER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.785100",
    Latitude: "-23.474500"
  },
  {
    Country: "Australia",
    Pcode: "4718",
    Locality: "BAUHINIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.344500",
    Latitude: "-24.559400"
  },
  {
    Country: "Australia",
    Pcode: "4718",
    Locality: "DROMEDARY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.146800",
    Latitude: "-24.585600"
  },
  {
    Country: "Australia",
    Pcode: "4718",
    Locality: "KIANGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.009900",
    Latitude: "-24.697400"
  },
  {
    Country: "Australia",
    Pcode: "4718",
    Locality: "MOURA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.932400",
    Latitude: "-24.488200"
  },
  {
    Country: "Australia",
    Pcode: "4718",
    Locality: "MUNGABUNDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.232500",
    Latitude: "-24.874900"
  },
  {
    Country: "Australia",
    Pcode: "4718",
    Locality: "OOMBABEER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.512700",
    Latitude: "-24.501700"
  },
  {
    Country: "Australia",
    Pcode: "4718",
    Locality: "RHYDDING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.513800",
    Latitude: "-24.754500"
  },
  {
    Country: "Australia",
    Pcode: "4718",
    Locality: "ROUNDSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.749500",
    Latitude: "-24.756700"
  },
  {
    Country: "Australia",
    Pcode: "4718",
    Locality: "WARNOAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.897100",
    Latitude: "-24.712400"
  },
  {
    Country: "Australia",
    Pcode: "4719",
    Locality: "CAMBOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.362500",
    Latitude: "-25.013200"
  },
  {
    Country: "Australia",
    Pcode: "4719",
    Locality: "CRACOW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.316300",
    Latitude: "-25.336100"
  },
  {
    Country: "Australia",
    Pcode: "4719",
    Locality: "GLENMORAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.834500",
    Latitude: "-24.996700"
  },
  {
    Country: "Australia",
    Pcode: "4719",
    Locality: "ISLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.028600",
    Latitude: "-25.111400"
  },
  {
    Country: "Australia",
    Pcode: "4719",
    Locality: "LONESOME CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.112700",
    Latitude: "-24.898600"
  },
  {
    Country: "Australia",
    Pcode: "4719",
    Locality: "THEODORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.075300",
    Latitude: "-24.944600"
  },
  {
    Country: "Australia",
    Pcode: "4720",
    Locality: "EMERALD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.153000",
    Latitude: "-23.530200"
  },
  {
    Country: "Australia",
    Pcode: "4720",
    Locality: "YAMALA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "ARGYLL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.503900",
    Latitude: "-23.315700"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "CLERMONT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.437600",
    Latitude: "-22.720800"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "ELGIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.636600",
    Latitude: "-22.250700"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "FRANKFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.109100",
    Latitude: "-22.214400"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "GEMINI MOUNTAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.883400",
    Latitude: "-22.437700"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "KILCUMMIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.588600",
    Latitude: "-22.351700"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "LAGLAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.315100",
    Latitude: "-22.383200"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "MISTAKE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.928500",
    Latitude: "-22.750400"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "PASHA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.382800",
    Latitude: "-21.823700"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "THERESA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.606900",
    Latitude: "-23.088600"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "WINCHESTER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.107600",
    Latitude: "-22.275800"
  },
  {
    Country: "Australia",
    Pcode: "4721",
    Locality: "WOLFANG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.818400",
    Latitude: "-22.652000"
  },
  {
    Country: "Australia",
    Pcode: "4722",
    Locality: "BUCKLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.575900",
    Latitude: "-24.566000"
  },
  {
    Country: "Australia",
    Pcode: "4722",
    Locality: "CAIRDBEIGN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.122800",
    Latitude: "-24.281900"
  },
  {
    Country: "Australia",
    Pcode: "4722",
    Locality: "CONA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.903800",
    Latitude: "-24.191900"
  },
  {
    Country: "Australia",
    Pcode: "4722",
    Locality: "NANDOWRIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.599500",
    Latitude: "-24.323000"
  },
  {
    Country: "Australia",
    Pcode: "4722",
    Locality: "ORION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.391000",
    Latitude: "-24.207100"
  },
  {
    Country: "Australia",
    Pcode: "4722",
    Locality: "SPRINGSURE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.069500",
    Latitude: "-24.093500"
  },
  {
    Country: "Australia",
    Pcode: "4722",
    Locality: "WEALWANDANGIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.013300",
    Latitude: "-24.490400"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "BELCONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.218700",
    Latitude: "-23.022200"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "CAPELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.021800",
    Latitude: "-23.084400"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "CARBINE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.798000",
    Latitude: "-23.231300"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "CHIRNSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.074000",
    Latitude: "-23.169700"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "CRINUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.269500",
    Latitude: "-23.206900"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "HIBERNIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.900000",
    Latitude: "-23.091600"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "KHOSH BULDUK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.008400",
    Latitude: "-22.999500"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "LILYVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.449600",
    Latitude: "-23.144800"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "LOWESTOFF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.086100",
    Latitude: "-22.847000"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "MOUNT MACARTHUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.209400",
    Latitude: "-22.898700"
  },
  {
    Country: "Australia",
    Pcode: "4723",
    Locality: "RETRO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.935700",
    Latitude: "-22.847100"
  },
  {
    Country: "Australia",
    Pcode: "4724",
    Locality: "ALPHA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.641200",
    Latitude: "-23.635100"
  },
  {
    Country: "Australia",
    Pcode: "4724",
    Locality: "BEAUFORT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.914700",
    Latitude: "-23.481100"
  },
  {
    Country: "Australia",
    Pcode: "4724",
    Locality: "DRUMMONDSLOPE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.596300",
    Latitude: "-24.070400"
  },
  {
    Country: "Australia",
    Pcode: "4724",
    Locality: "HOBARTVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.370000",
    Latitude: "-23.339000"
  },
  {
    Country: "Australia",
    Pcode: "4724",
    Locality: "PINE HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.124700",
    Latitude: "-23.498900"
  },
  {
    Country: "Australia",
    Pcode: "4724",
    Locality: "PORT WINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.042400",
    Latitude: "-23.886200"
  },
  {
    Country: "Australia",
    Pcode: "4724",
    Locality: "QUETTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4724",
    Locality: "SEDGEFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.824300",
    Latitude: "-24.011200"
  },
  {
    Country: "Australia",
    Pcode: "4724",
    Locality: "SURBITON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.619500",
    Latitude: "-23.220100"
  },
  {
    Country: "Australia",
    Pcode: "4725",
    Locality: "BARCALDINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.323700",
    Latitude: "-23.598500"
  },
  {
    Country: "Australia",
    Pcode: "4725",
    Locality: "BARCALDINE DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.995500",
    Latitude: "-23.823700"
  },
  {
    Country: "Australia",
    Pcode: "4725",
    Locality: "PATRICK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.298800",
    Latitude: "-23.736500"
  },
  {
    Country: "Australia",
    Pcode: "4725",
    Locality: "TARA STATION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.958900",
    Latitude: "-23.496400"
  },
  {
    Country: "Australia",
    Pcode: "4726",
    Locality: "ARAMAC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.229600",
    Latitude: "-22.984900"
  },
  {
    Country: "Australia",
    Pcode: "4726",
    Locality: "GALILEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.808900",
    Latitude: "-22.336700"
  },
  {
    Country: "Australia",
    Pcode: "4726",
    Locality: "PELICAN CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.344200",
    Latitude: "-22.843200"
  },
  {
    Country: "Australia",
    Pcode: "4727",
    Locality: "ILFRACOMBE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.378200",
    Latitude: "-23.941300"
  },
  {
    Country: "Australia",
    Pcode: "4754",
    Locality: "MIRANI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.873100",
    Latitude: "-21.164500"
  },
  {
    Country: "Australia",
    Pcode: "4754",
    Locality: "MOUNT MARTIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.822300",
    Latitude: "-21.103200"
  },
  {
    Country: "Australia",
    Pcode: "4754",
    Locality: "PINEVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.806700",
    Latitude: "-21.345800"
  },
  {
    Country: "Australia",
    Pcode: "4754",
    Locality: "SEPTIMUS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.712000",
    Latitude: "-21.315200"
  },
  {
    Country: "Australia",
    Pcode: "4756",
    Locality: "FINCH HATTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.626900",
    Latitude: "-21.130400"
  },
  {
    Country: "Australia",
    Pcode: "4756",
    Locality: "NETHERDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.532600",
    Latitude: "-21.129700"
  },
  {
    Country: "Australia",
    Pcode: "4757",
    Locality: "BROKEN RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.505400",
    Latitude: "-21.165800"
  },
  {
    Country: "Australia",
    Pcode: "4757",
    Locality: "CREDITON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.561200",
    Latitude: "-21.269600"
  },
  {
    Country: "Australia",
    Pcode: "4757",
    Locality: "DALRYMPLE HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.520900",
    Latitude: "-21.064100"
  },
  {
    Country: "Australia",
    Pcode: "4757",
    Locality: "EUNGELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.485400",
    Latitude: "-21.126700"
  },
  {
    Country: "Australia",
    Pcode: "4757",
    Locality: "EUNGELLA DAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.393300",
    Latitude: "-21.162800"
  },
  {
    Country: "Australia",
    Pcode: "4798",
    Locality: "CALEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.742100",
    Latitude: "-20.895200"
  },
  {
    Country: "Australia",
    Pcode: "4798",
    Locality: "MENTMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.726700",
    Latitude: "-20.757000"
  },
  {
    Country: "Australia",
    Pcode: "4798",
    Locality: "PINDI PINDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.724000",
    Latitude: "-20.856200"
  },
  {
    Country: "Australia",
    Pcode: "4798",
    Locality: "ST HELENS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.847200",
    Latitude: "-20.845800"
  },
  {
    Country: "Australia",
    Pcode: "4799",
    Locality: "BLOOMSBURY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.579900",
    Latitude: "-20.694200"
  },
  {
    Country: "Australia",
    Pcode: "4799",
    Locality: "MIDGE POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.687900",
    Latitude: "-20.657200"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "ANDROMACHE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.425100",
    Latitude: "-20.594400"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "BRANDY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.679400",
    Latitude: "-20.333300"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "BREADALBANE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.609500",
    Latitude: "-20.434700"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "CANNON VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.654400",
    Latitude: "-20.314800"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "CAPE CONWAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.791100",
    Latitude: "-20.384300"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "CAPE GLOUCESTER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.534300",
    Latitude: "-20.169500"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "CONWAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.713000",
    Latitude: "-20.450700"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "CONWAY BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.745400",
    Latitude: "-20.474300"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "CRYSTAL BROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.478200",
    Latitude: "-20.378200"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "DINGO BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.493800",
    Latitude: "-20.089700"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "DITTMER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.411600",
    Latitude: "-20.427300"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "FOXDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.521300",
    Latitude: "-20.346400"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "GLEN ISLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.626800",
    Latitude: "-20.417800"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "GOORGANGA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.483900",
    Latitude: "-20.517800"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "GOORGANGA PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.663100",
    Latitude: "-20.494700"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "GREGORY RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "WHITSUNDAY",
    Category: "Delivery Area",
    Longitude: "148.458900",
    Latitude: "-20.244200"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "GUNYARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.563200",
    Latitude: "-20.487100"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "HAMILTON PLAINS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.578500",
    Latitude: "-20.381100"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "HIDEAWAY BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.482300",
    Latitude: "-20.080300"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "KELSEY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.492900",
    Latitude: "-20.431900"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "LAGUNA QUAYS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.662200",
    Latitude: "-20.627200"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "LAKE PROSERPINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.332000",
    Latitude: "-20.398800"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "LETHEBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.651200",
    Latitude: "-20.544600"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "MOUNT JULIAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.607600",
    Latitude: "-20.374800"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "MOUNT MARLOW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.631300",
    Latitude: "-20.351500"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "MOUNT PLUTO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.424700",
    Latitude: "-20.295400"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "MYRTLEVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.549900",
    Latitude: "-20.330900"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "PALM GROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.702700",
    Latitude: "-20.395900"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "PAULS POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.418500",
    Latitude: "-20.479900"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "PRESTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "WHITSUNDAY",
    Category: "Delivery Area",
    Longitude: "148.653400",
    Latitude: "-20.387500"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "PROSERPINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.567200",
    Latitude: "-20.410300"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "RIORDANVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.644900",
    Latitude: "-20.285000"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "SILVER CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.475100",
    Latitude: "-20.468700"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "STRATHDICKIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.595000",
    Latitude: "-20.329800"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "SUGARLOAF",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "WHITSUNDAY",
    Category: "Delivery Area",
    Longitude: "148.631600",
    Latitude: "-20.311600"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "THOOPARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.564300",
    Latitude: "-20.550400"
  },
  {
    Country: "Australia",
    Pcode: "4800",
    Locality: "WILSON BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.723500",
    Latitude: "-20.475100"
  },
  {
    Country: "Australia",
    Pcode: "4802",
    Locality: "AIRLIE BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.718100",
    Latitude: "-20.272300"
  },
  {
    Country: "Australia",
    Pcode: "4802",
    Locality: "CANNONVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.693600",
    Latitude: "-20.281800"
  },
  {
    Country: "Australia",
    Pcode: "4802",
    Locality: "FLAMETREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.748600",
    Latitude: "-20.269900"
  },
  {
    Country: "Australia",
    Pcode: "4802",
    Locality: "JUBILEE POCKET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.729800",
    Latitude: "-20.288000"
  },
  {
    Country: "Australia",
    Pcode: "4802",
    Locality: "MANDALAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.736100",
    Latitude: "-20.270900"
  },
  {
    Country: "Australia",
    Pcode: "4802",
    Locality: "MOUNT ROOPER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.764100",
    Latitude: "-20.262400"
  },
  {
    Country: "Australia",
    Pcode: "4802",
    Locality: "SHUTE HARBOUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.790300",
    Latitude: "-20.289300"
  },
  {
    Country: "Australia",
    Pcode: "4802",
    Locality: "WHITSUNDAYS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.710900",
    Latitude: "-20.308100"
  },
  {
    Country: "Australia",
    Pcode: "4802",
    Locality: "WOODWARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.654300",
    Latitude: "-20.243900"
  },
  {
    Country: "Australia",
    Pcode: "4803",
    Locality: "HAMILTON ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4804",
    Locality: "COLLINSVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.825300",
    Latitude: "-20.557100"
  },
  {
    Country: "Australia",
    Pcode: "4804",
    Locality: "MOUNT COOLON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.371000",
    Latitude: "-21.318600"
  },
  {
    Country: "Australia",
    Pcode: "4804",
    Locality: "MOUNT WYATT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.289700",
    Latitude: "-20.676200"
  },
  {
    Country: "Australia",
    Pcode: "4804",
    Locality: "NEWLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.909900",
    Latitude: "-20.915100"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "BLUE HILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.499300",
    Latitude: "-19.247600"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "BLUEWATER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.547100",
    Latitude: "-19.167500"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "BLUEWATER PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.490500",
    Latitude: "-19.192900"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "BOHLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.720000",
    Latitude: "-19.247100"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "BURDELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.698100",
    Latitude: "-19.248000"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "BUSHLAND BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.685500",
    Latitude: "-19.199000"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "COSGROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.713000",
    Latitude: "-19.271400"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "DEERAGUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.668400",
    Latitude: "-19.248900"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "JENSEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.639200",
    Latitude: "-19.253600"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "LYNAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.482800",
    Latitude: "-19.300900"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "MOUNT LOW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.682000",
    Latitude: "-19.224000"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "MOUNT ST JOHN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.742100",
    Latitude: "-19.251100"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "SAUNDERS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.605600",
    Latitude: "-19.161300"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "SHAW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.697600",
    Latitude: "-19.270100"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "TOOLAKEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.593600",
    Latitude: "-19.149100"
  },
  {
    Country: "Australia",
    Pcode: "4818",
    Locality: "YABULU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.600800",
    Latitude: "-19.202000"
  },
  {
    Country: "Australia",
    Pcode: "4819",
    Locality: "ARCADIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.859500",
    Latitude: "-19.143300"
  },
  {
    Country: "Australia",
    Pcode: "4819",
    Locality: "FLORENCE BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.872700",
    Latitude: "-19.121500"
  },
  {
    Country: "Australia",
    Pcode: "4819",
    Locality: "HORSESHOE BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.852900",
    Latitude: "-19.123600"
  },
  {
    Country: "Australia",
    Pcode: "4819",
    Locality: "MAGNETIC ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4819",
    Locality: "NELLY BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.846200",
    Latitude: "-19.160600"
  },
  {
    Country: "Australia",
    Pcode: "4819",
    Locality: "PICNIC BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.827600",
    Latitude: "-19.167100"
  },
  {
    Country: "Australia",
    Pcode: "4819",
    Locality: "WEST POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.819600",
    Latitude: "-19.136100"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "ALABAMA HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.249100",
    Latitude: "-20.081800"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "BALFES CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "BASALT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.391200",
    Latitude: "-19.711000"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "BLACK JACK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.130800",
    Latitude: "-20.167300"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "BREDDAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.269200",
    Latitude: "-19.970400"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "BROUGHTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.364500",
    Latitude: "-20.096300"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "CAMPASPE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.989500",
    Latitude: "-20.517200"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "CHARTERS TOWERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES/BAGS ONLY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "CHARTERS TOWERS CITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.259100",
    Latitude: "-20.076400"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "COLUMBIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.279200",
    Latitude: "-20.047600"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "DOTSWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.223300",
    Latitude: "-19.506200"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "GRAND SECRET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.245500",
    Latitude: "-20.073100"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "LLANARTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.441400",
    Latitude: "-21.326800"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "MILLCHESTER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.279400",
    Latitude: "-20.092600"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "MOSMAN PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.259800",
    Latitude: "-20.096700"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "QUEENTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.278200",
    Latitude: "-20.074700"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "RICHMOND HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.268900",
    Latitude: "-20.063500"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "SEVENTY MILE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.585700",
    Latitude: "-20.614500"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "SOUTHERN CROSS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.156600",
    Latitude: "-20.010800"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "TOLL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.254600",
    Latitude: "-20.048500"
  },
  {
    Country: "Australia",
    Pcode: "4820",
    Locality: "TOWERS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.250400",
    Latitude: "-20.090300"
  },
  {
    Country: "Australia",
    Pcode: "4821",
    Locality: "DUTTON RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.872600",
    Latitude: "-20.495400"
  },
  {
    Country: "Australia",
    Pcode: "4821",
    Locality: "HUGHENDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.189800",
    Latitude: "-20.845400"
  },
  {
    Country: "Australia",
    Pcode: "4821",
    Locality: "PORCUPINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.247500",
    Latitude: "-20.120500"
  },
  {
    Country: "Australia",
    Pcode: "4821",
    Locality: "PRAIRIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.663400",
    Latitude: "-21.402400"
  },
  {
    Country: "Australia",
    Pcode: "4821",
    Locality: "STAMFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.699600",
    Latitude: "-21.340700"
  },
  {
    Country: "Australia",
    Pcode: "4821",
    Locality: "TANGORIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.164900",
    Latitude: "-21.458400"
  },
  {
    Country: "Australia",
    Pcode: "4822",
    Locality: "ALBION",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "RICHMOND",
    Category: "Delivery Area",
    Longitude: "142.652000",
    Latitude: "-21.324500"
  },
  {
    Country: "Australia",
    Pcode: "4822",
    Locality: "BELLFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.105900",
    Latitude: "-19.346200"
  },
  {
    Country: "Australia",
    Pcode: "4822",
    Locality: "BURLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "RICHMOND SHIRE",
    Category: "Delivery Area",
    Longitude: "143.055900",
    Latitude: "-20.281200"
  },
  {
    Country: "Australia",
    Pcode: "4822",
    Locality: "CAMBRIDGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.606300",
    Latitude: "-20.368200"
  },
  {
    Country: "Australia",
    Pcode: "4822",
    Locality: "MAXWELTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.638100",
    Latitude: "-20.866700"
  },
  {
    Country: "Australia",
    Pcode: "4822",
    Locality: "RICHMOND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.266400",
    Latitude: "-20.752600"
  },
  {
    Country: "Australia",
    Pcode: "4822",
    Locality: "SAXBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.688100",
    Latitude: "-19.907200"
  },
  {
    Country: "Australia",
    Pcode: "4822",
    Locality: "VICTORIA VALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.750500",
    Latitude: "-19.464400"
  },
  {
    Country: "Australia",
    Pcode: "4822",
    Locality: "WOOLGAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.394400",
    Latitude: "-19.991700"
  },
  {
    Country: "Australia",
    Pcode: "4823",
    Locality: "CARPENTARIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.095600",
    Latitude: "-17.949200"
  },
  {
    Country: "Australia",
    Pcode: "4823",
    Locality: "JULIA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.709600",
    Latitude: "-20.640200"
  },
  {
    Country: "Australia",
    Pcode: "4823",
    Locality: "KYNUNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.820200",
    Latitude: "-21.379500"
  },
  {
    Country: "Australia",
    Pcode: "4823",
    Locality: "MCKINLAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.255000",
    Latitude: "-21.416800"
  },
  {
    Country: "Australia",
    Pcode: "4823",
    Locality: "NELIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4823",
    Locality: "STOKES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.394600",
    Latitude: "-18.650800"
  },
  {
    Country: "Australia",
    Pcode: "4823",
    Locality: "TALDORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.492100",
    Latitude: "-19.830600"
  },
  {
    Country: "Australia",
    Pcode: "4823",
    Locality: "WARBURTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.907100",
    Latitude: "-22.471200"
  },
  {
    Country: "Australia",
    Pcode: "4824",
    Locality: "CLONCURRY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.549700",
    Latitude: "-20.508800"
  },
  {
    Country: "Australia",
    Pcode: "4824",
    Locality: "FOUR WAYS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.505700",
    Latitude: "-19.353800"
  },
  {
    Country: "Australia",
    Pcode: "4856",
    Locality: "NO. 4 BRANCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.970900",
    Latitude: "-17.769600"
  },
  {
    Country: "Australia",
    Pcode: "4856",
    Locality: "NO. 5 BRANCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.010000",
    Latitude: "-17.724500"
  },
  {
    Country: "Australia",
    Pcode: "4856",
    Locality: "SILKWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.022800",
    Latitude: "-17.746500"
  },
  {
    Country: "Australia",
    Pcode: "4856",
    Locality: "WALTER LEVER ESTATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.978100",
    Latitude: "-17.718400"
  },
  {
    Country: "Australia",
    Pcode: "4857",
    Locality: "SILKWOOD EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4858",
    Locality: "COMOON LOOP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.035100",
    Latitude: "-17.563200"
  },
  {
    Country: "Australia",
    Pcode: "4858",
    Locality: "ETTY BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.092500",
    Latitude: "-17.563700"
  },
  {
    Country: "Australia",
    Pcode: "4858",
    Locality: "MARTYVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.038000",
    Latitude: "-17.614500"
  },
  {
    Country: "Australia",
    Pcode: "4858",
    Locality: "MOURILYAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.050400",
    Latitude: "-17.579000"
  },
  {
    Country: "Australia",
    Pcode: "4858",
    Locality: "MOURILYAN HARBOUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.106200",
    Latitude: "-17.595000"
  },
  {
    Country: "Australia",
    Pcode: "4858",
    Locality: "NEW HARBOURLINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.066100",
    Latitude: "-17.622700"
  },
  {
    Country: "Australia",
    Pcode: "4859",
    Locality: "NO. 6 BRANCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.954600",
    Latitude: "-17.593300"
  },
  {
    Country: "Australia",
    Pcode: "4859",
    Locality: "SOUTH JOHNSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.994700",
    Latitude: "-17.598000"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "BAMBOO CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.984700",
    Latitude: "-17.551400"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "BELVEDERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.987300",
    Latitude: "-17.525700"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "COCONUTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.064700",
    Latitude: "-17.501800"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "COOROO LANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.898900",
    Latitude: "-17.522300"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "COORUMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.916600",
    Latitude: "-17.569600"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "COQUETTE POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.072900",
    Latitude: "-17.529000"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "CULLINANE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.023100",
    Latitude: "-17.511400"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "DARADGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.999100",
    Latitude: "-17.479000"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "EAST INNISFAIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.035700",
    Latitude: "-17.532200"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "EAST PALMERSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.841400",
    Latitude: "-17.594300"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "EATON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.047600",
    Latitude: "-17.504500"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "EUBENANGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.984800",
    Latitude: "-17.417100"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "FITZGERALD CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.977500",
    Latitude: "-17.493500"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "FLYING FISH POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.080200",
    Latitude: "-17.495500"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "GARRADUNGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.003700",
    Latitude: "-17.457900"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "GOONDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.006700",
    Latitude: "-17.502600"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "GOONDI BEND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.009400",
    Latitude: "-17.519000"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "GOONDI HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.015200",
    Latitude: "-17.531600"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "HUDSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.998800",
    Latitude: "-17.524500"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "INNISFAIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.030600",
    Latitude: "-17.523000"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "INNISFAIL ESTATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.044100",
    Latitude: "-17.515800"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "JUBILEE HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.035200",
    Latitude: "-17.474500"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "MIGHELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.017800",
    Latitude: "-17.541600"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "MUNDOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.019100",
    Latitude: "-17.558400"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "NERADA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.874800",
    Latitude: "-17.547600"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "NGATJAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.916100",
    Latitude: "-17.496900"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "O'BRIENS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.984600",
    Latitude: "-17.507600"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "PALMERSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.665500",
    Latitude: "-17.623600"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "PIN GIN HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.953000",
    Latitude: "-17.563500"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "SOUTH INNISFAIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.046300",
    Latitude: "-17.545700"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "STOTERS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.975000",
    Latitude: "-17.534100"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "SUNDOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.023200",
    Latitude: "-17.496600"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "UPPER DARADGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.955700",
    Latitude: "-17.523500"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "VASA VIEWS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.964800",
    Latitude: "-17.468800"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "WANJURU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.060500",
    Latitude: "-17.452900"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "WEBB",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.050100",
    Latitude: "-17.530300"
  },
  {
    Country: "Australia",
    Pcode: "4860",
    Locality: "WOOROONOORAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.690700",
    Latitude: "-17.574200"
  },
  {
    Country: "Australia",
    Pcode: "4861",
    Locality: "BABINDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.936300",
    Latitude: "-17.334000"
  },
  {
    Country: "Australia",
    Pcode: "4861",
    Locality: "BARTLE FRERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.880100",
    Latitude: "-17.439800"
  },
  {
    Country: "Australia",
    Pcode: "4861",
    Locality: "EAST RUSSELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.017800",
    Latitude: "-17.225400"
  },
  {
    Country: "Australia",
    Pcode: "4865",
    Locality: "GOLDSBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.748900",
    Latitude: "-17.194000"
  },
  {
    Country: "Australia",
    Pcode: "4865",
    Locality: "GORDONVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.779200",
    Latitude: "-17.091300"
  },
  {
    Country: "Australia",
    Pcode: "4865",
    Locality: "GREEN HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.832000",
    Latitude: "-17.024500"
  },
  {
    Country: "Australia",
    Pcode: "4865",
    Locality: "KAMMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4865",
    Locality: "LITTLE MULGRAVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.706300",
    Latitude: "-17.130300"
  },
  {
    Country: "Australia",
    Pcode: "4865",
    Locality: "PACKERS CAMP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.802900",
    Latitude: "-17.021200"
  },
  {
    Country: "Australia",
    Pcode: "4868",
    Locality: "BAYVIEW HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.724800",
    Latitude: "-16.962900"
  },
  {
    Country: "Australia",
    Pcode: "4868",
    Locality: "MOUNT SHERIDAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.729800",
    Latitude: "-16.988300"
  },
  {
    Country: "Australia",
    Pcode: "4868",
    Locality: "WHITE ROCK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "CAIRNS",
    Category: "Delivery Area",
    Longitude: "145.759500",
    Latitude: "-16.977800"
  },
  {
    Country: "Australia",
    Pcode: "4868",
    Locality: "WOREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.751500",
    Latitude: "-16.957800"
  },
  {
    Country: "Australia",
    Pcode: "4869",
    Locality: "BENTLEY PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.724800",
    Latitude: "-17.009500"
  },
  {
    Country: "Australia",
    Pcode: "4869",
    Locality: "EDMONTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.740900",
    Latitude: "-17.015300"
  },
  {
    Country: "Australia",
    Pcode: "4869",
    Locality: "MOUNT PETER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.737700",
    Latitude: "-17.064400"
  },
  {
    Country: "Australia",
    Pcode: "4869",
    Locality: "WRIGHTS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.775600",
    Latitude: "-17.021800"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "AEROGLEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.764100",
    Latitude: "-16.876100"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "NEWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.412600",
    Latitude: "-16.425600"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "NOAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.346900",
    Latitude: "-16.184700"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "ROCKY POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "CAIRNS",
    Category: "Delivery Area",
    Longitude: "145.415500",
    Latitude: "-16.388600"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "SHANNONVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.329200",
    Latitude: "-16.510900"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "SPURGEON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.062600",
    Latitude: "-16.209500"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "STEWART CREEK VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.308600",
    Latitude: "-16.290800"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "SYNDICATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.280400",
    Latitude: "-16.424300"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "THORNTON BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.435000",
    Latitude: "-16.158800"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "UPPER DAINTREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.291900",
    Latitude: "-16.219100"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "WHYANBEEL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.355500",
    Latitude: "-16.340700"
  },
  {
    Country: "Australia",
    Pcode: "4873",
    Locality: "WONGA BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.428300",
    Latitude: "-16.338500"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "EVANS LANDING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.852500",
    Latitude: "-12.661900"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "JARDINE RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.750300",
    Latitude: "-11.243700"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "MAPOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.143400",
    Latitude: "-11.797600"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "MISSION RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.401000",
    Latitude: "-12.767100"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "NANUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.858600",
    Latitude: "-12.643600"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "NAPRANUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "ROCKY POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.885600",
    Latitude: "-12.623300"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "SHELBURNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.979600",
    Latitude: "-12.073200"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "TRUNDING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.872300",
    Latitude: "-12.632700"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "WEIPA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "WEIPA AIRPORT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.924700",
    Latitude: "-12.682200"
  },
  {
    Country: "Australia",
    Pcode: "4874",
    Locality: "WENLOCK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.555700",
    Latitude: "-12.348400"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "BADU ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.139500",
    Latitude: "-10.123600"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "BOIGU ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.214100",
    Latitude: "-9.259590"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "COCONUT ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "DAUAN ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.536900",
    Latitude: "-9.423290"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "ERUB",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "HORN ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "KUBIN VILLAGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "MABUIAG ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.182700",
    Latitude: "-9.957630"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "MOA ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.262300",
    Latitude: "-10.188400"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "MURRAY ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "PRINCE OF WALES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.203200",
    Latitude: "-10.701000"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "SAIBAI ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.681300",
    Latitude: "-9.396710"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "STEPHENS ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "THURSDAY ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.239800",
    Latitude: "-10.567500"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "WARRABER ISLET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.824100",
    Latitude: "-10.207600"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "YAM ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4875",
    Locality: "YORKE ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4876",
    Locality: "BAMAGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.426700",
    Latitude: "-10.873600"
  },
  {
    Country: "Australia",
    Pcode: "4876",
    Locality: "INJINOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.253000",
    Latitude: "-11.041300"
  },
  {
    Country: "Australia",
    Pcode: "4876",
    Locality: "NEW MAPOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.432200",
    Latitude: "-10.766400"
  },
  {
    Country: "Australia",
    Pcode: "4876",
    Locality: "SEISIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.352600",
    Latitude: "-10.846400"
  },
  {
    Country: "Australia",
    Pcode: "4876",
    Locality: "UMAGICO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.394900",
    Latitude: "-10.915100"
  },
  {
    Country: "Australia",
    Pcode: "4877",
    Locality: "CRAIGLIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.477900",
    Latitude: "-16.529600"
  },
  {
    Country: "Australia",
    Pcode: "4877",
    Locality: "KILLALOE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.429500",
    Latitude: "-16.491600"
  },
  {
    Country: "Australia",
    Pcode: "4877",
    Locality: "MOWBRAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.499100",
    Latitude: "-16.590100"
  },
  {
    Country: "Australia",
    Pcode: "4877",
    Locality: "OAK BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.521900",
    Latitude: "-16.587400"
  },
  {
    Country: "Australia",
    Pcode: "4877",
    Locality: "PORT DOUGLAS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.462800",
    Latitude: "-16.498800"
  },
  {
    Country: "Australia",
    Pcode: "4877",
    Locality: "WANGETTI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.573600",
    Latitude: "-16.657300"
  },
  {
    Country: "Australia",
    Pcode: "4878",
    Locality: "BARRON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.711700",
    Latitude: "-16.864500"
  },
  {
    Country: "Australia",
    Pcode: "4878",
    Locality: "CARAVONICA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.683400",
    Latitude: "-16.860500"
  },
  {
    Country: "Australia",
    Pcode: "4878",
    Locality: "HOLLOWAYS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.731600",
    Latitude: "-16.845700"
  },
  {
    Country: "Australia",
    Pcode: "4878",
    Locality: "MACHANS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.749200",
    Latitude: "-16.853500"
  },
  {
    Country: "Australia",
    Pcode: "4878",
    Locality: "SMITHFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.691200",
    Latitude: "-16.831200"
  },
  {
    Country: "Australia",
    Pcode: "4878",
    Locality: "YORKEYS KNOB",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.716900",
    Latitude: "-16.822000"
  },
  {
    Country: "Australia",
    Pcode: "4879",
    Locality: "CLIFTON BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.670900",
    Latitude: "-16.770400"
  },
  {
    Country: "Australia",
    Pcode: "4879",
    Locality: "ELLIS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.672300",
    Latitude: "-16.726200"
  },
  {
    Country: "Australia",
    Pcode: "4879",
    Locality: "KEWARRA BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.677400",
    Latitude: "-16.786300"
  },
  {
    Country: "Australia",
    Pcode: "4879",
    Locality: "PALM COVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.667600",
    Latitude: "-16.746700"
  },
  {
    Country: "Australia",
    Pcode: "4879",
    Locality: "TRINITY BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.697600",
    Latitude: "-16.788800"
  },
  {
    Country: "Australia",
    Pcode: "4879",
    Locality: "TRINITY PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.705200",
    Latitude: "-16.809400"
  },
  {
    Country: "Australia",
    Pcode: "4880",
    Locality: "ARRIGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.293100",
    Latitude: "-17.048800"
  },
  {
    Country: "Australia",
    Pcode: "4880",
    Locality: "BIBOOHRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.427800",
    Latitude: "-16.862700"
  },
  {
    Country: "Australia",
    Pcode: "4880",
    Locality: "CHEWKO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.371100",
    Latitude: "-17.067200"
  },
  {
    Country: "Australia",
    Pcode: "4880",
    Locality: "GLEN RUSSELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.177700",
    Latitude: "-16.943400"
  },
  {
    Country: "Australia",
    Pcode: "4880",
    Locality: "MAREEBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.503600",
    Latitude: "-17.050000"
  },
  {
    Country: "Australia",
    Pcode: "5014",
    Locality: "CHELTENHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.523100",
    Latitude: "-34.866400"
  },
  {
    Country: "Australia",
    Pcode: "5014",
    Locality: "HENDON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.515600",
    Latitude: "-34.874100"
  },
  {
    Country: "Australia",
    Pcode: "5014",
    Locality: "QUEENSTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.510900",
    Latitude: "-34.860500"
  },
  {
    Country: "Australia",
    Pcode: "5014",
    Locality: "ROYAL PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.507400",
    Latitude: "-34.871100"
  },
  {
    Country: "Australia",
    Pcode: "5015",
    Locality: "BIRKENHEAD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.499900",
    Latitude: "-34.837300"
  },
  {
    Country: "Australia",
    Pcode: "5015",
    Locality: "ETHELTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.490100",
    Latitude: "-34.848600"
  },
  {
    Country: "Australia",
    Pcode: "5015",
    Locality: "GLANVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.490500",
    Latitude: "-34.843600"
  },
  {
    Country: "Australia",
    Pcode: "5015",
    Locality: "NEW PORT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.497600",
    Latitude: "-34.850500"
  },
  {
    Country: "Australia",
    Pcode: "5015",
    Locality: "PORT ADELAIDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.511100",
    Latitude: "-34.837100"
  },
  {
    Country: "Australia",
    Pcode: "5015",
    Locality: "PORT ADELAIDE DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5016",
    Locality: "LARGS BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.495400",
    Latitude: "-34.827300"
  },
  {
    Country: "Australia",
    Pcode: "5016",
    Locality: "LARGS NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.500900",
    Latitude: "-34.817100"
  },
  {
    Country: "Australia",
    Pcode: "5016",
    Locality: "PETERHEAD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.499600",
    Latitude: "-34.831100"
  },
  {
    Country: "Australia",
    Pcode: "5017",
    Locality: "OSBORNE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.502000",
    Latitude: "-34.794400"
  },
  {
    Country: "Australia",
    Pcode: "5017",
    Locality: "TAPEROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.501000",
    Latitude: "-34.806500"
  },
  {
    Country: "Australia",
    Pcode: "5018",
    Locality: "NORTH HAVEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.490800",
    Latitude: "-34.786500"
  },
  {
    Country: "Australia",
    Pcode: "5018",
    Locality: "OUTER HARBOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.494200",
    Latitude: "-34.774800"
  },
  {
    Country: "Australia",
    Pcode: "5019",
    Locality: "EXETER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.490800",
    Latitude: "-34.838300"
  },
  {
    Country: "Australia",
    Pcode: "5019",
    Locality: "SEMAPHORE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.482700",
    Latitude: "-34.838300"
  },
  {
    Country: "Australia",
    Pcode: "5019",
    Locality: "SEMAPHORE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.495400",
    Latitude: "-34.857400"
  },
  {
    Country: "Australia",
    Pcode: "5019",
    Locality: "SEMAPHORE SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.480800",
    Latitude: "-34.846900"
  },
  {
    Country: "Australia",
    Pcode: "5020",
    Locality: "WEST LAKES SHORE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.487000",
    Latitude: "-34.862800"
  },
  {
    Country: "Australia",
    Pcode: "5021",
    Locality: "WEST LAKES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.494600",
    Latitude: "-34.870800"
  },
  {
    Country: "Australia",
    Pcode: "5022",
    Locality: "GRANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.496000",
    Latitude: "-34.894600"
  },
  {
    Country: "Australia",
    Pcode: "5022",
    Locality: "HENLEY BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.497700",
    Latitude: "-34.916300"
  },
  {
    Country: "Australia",
    Pcode: "5022",
    Locality: "HENLEY BEACH SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.501500",
    Latitude: "-34.930200"
  },
  {
    Country: "Australia",
    Pcode: "5022",
    Locality: "TENNYSON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.485200",
    Latitude: "-34.884200"
  },
  {
    Country: "Australia",
    Pcode: "5023",
    Locality: "FINDON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.531800",
    Latitude: "-34.898400"
  },
  {
    Country: "Australia",
    Pcode: "5023",
    Locality: "SEATON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.513600",
    Latitude: "-34.892800"
  },
  {
    Country: "Australia",
    Pcode: "5024",
    Locality: "FULHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.514700",
    Latitude: "-34.927600"
  },
  {
    Country: "Australia",
    Pcode: "5024",
    Locality: "FULHAM GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.513000",
    Latitude: "-34.915000"
  },
  {
    Country: "Australia",
    Pcode: "5024",
    Locality: "WEST BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.507100",
    Latitude: "-34.946500"
  },
  {
    Country: "Australia",
    Pcode: "5025",
    Locality: "FLINDERS PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.543700",
    Latitude: "-34.911400"
  },
  {
    Country: "Australia",
    Pcode: "5025",
    Locality: "KIDMAN PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.527200",
    Latitude: "-34.912900"
  },
  {
    Country: "Australia",
    Pcode: "5031",
    Locality: "MILE END",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.569500",
    Latitude: "-34.925900"
  },
  {
    Country: "Australia",
    Pcode: "5031",
    Locality: "MILE END SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.574100",
    Latitude: "-34.936700"
  },
  {
    Country: "Australia",
    Pcode: "5031",
    Locality: "THEBARTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.572100",
    Latitude: "-34.915200"
  },
  {
    Country: "Australia",
    Pcode: "5031",
    Locality: "TORRENSVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.561400",
    Latitude: "-34.920300"
  },
  {
    Country: "Australia",
    Pcode: "5031",
    Locality: "TORRENSVILLE PLAZA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5032",
    Locality: "BROOKLYN PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.543300",
    Latitude: "-34.930300"
  },
  {
    Country: "Australia",
    Pcode: "5032",
    Locality: "LOCKLEYS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.533100",
    Latitude: "-34.924700"
  },
  {
    Country: "Australia",
    Pcode: "5032",
    Locality: "UNDERDALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.549000",
    Latitude: "-34.919100"
  },
  {
    Country: "Australia",
    Pcode: "5033",
    Locality: "COWANDILLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.556900",
    Latitude: "-34.932400"
  },
  {
    Country: "Australia",
    Pcode: "5033",
    Locality: "HILTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.565800",
    Latitude: "-34.932100"
  },
  {
    Country: "Australia",
    Pcode: "5033",
    Locality: "HILTON PLAZA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5033",
    Locality: "MARLESTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.562400",
    Latitude: "-34.945600"
  },
  {
    Country: "Australia",
    Pcode: "5033",
    Locality: "MARLESTON DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5033",
    Locality: "RICHMOND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.561800",
    Latitude: "-34.938400"
  },
  {
    Country: "Australia",
    Pcode: "5033",
    Locality: "WEST RICHMOND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.550000",
    Latitude: "-34.939400"
  },
  {
    Country: "Australia",
    Pcode: "5034",
    Locality: "CLARENCE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.584000",
    Latitude: "-34.963700"
  },
  {
    Country: "Australia",
    Pcode: "5034",
    Locality: "GOODWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.592200",
    Latitude: "-34.951100"
  },
  {
    Country: "Australia",
    Pcode: "5034",
    Locality: "KINGS PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.593600",
    Latitude: "-34.964100"
  },
  {
    Country: "Australia",
    Pcode: "5034",
    Locality: "MILLSWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.589600",
    Latitude: "-34.957200"
  },
  {
    Country: "Australia",
    Pcode: "5034",
    Locality: "WAYVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.591200",
    Latitude: "-34.945000"
  },
  {
    Country: "Australia",
    Pcode: "5035",
    Locality: "ASHFORD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.574100",
    Latitude: "-34.949500"
  },
  {
    Country: "Australia",
    Pcode: "5035",
    Locality: "BLACK FOREST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.576900",
    Latitude: "-34.960600"
  },
  {
    Country: "Australia",
    Pcode: "5035",
    Locality: "EVERARD PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.574600",
    Latitude: "-34.954200"
  },
  {
    Country: "Australia",
    Pcode: "5035",
    Locality: "FORESTVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.580800",
    Latitude: "-34.951600"
  },
  {
    Country: "Australia",
    Pcode: "5035",
    Locality: "KESWICK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.577100",
    Latitude: "-34.944600"
  },
  {
    Country: "Australia",
    Pcode: "5035",
    Locality: "KESWICK TERMINAL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.579000",
    Latitude: "-34.936100"
  },
  {
    Country: "Australia",
    Pcode: "5037",
    Locality: "GLANDORE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.568000",
    Latitude: "-34.961400"
  },
  {
    Country: "Australia",
    Pcode: "5037",
    Locality: "KURRALTA PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.567000",
    Latitude: "-34.951300"
  },
  {
    Country: "Australia",
    Pcode: "5037",
    Locality: "NETLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.549100",
    Latitude: "-34.948900"
  },
  {
    Country: "Australia",
    Pcode: "5037",
    Locality: "NORTH PLYMPTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.553000",
    Latitude: "-34.954600"
  },
  {
    Country: "Australia",
    Pcode: "5038",
    Locality: "CAMDEN PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.541500",
    Latitude: "-34.967200"
  },
  {
    Country: "Australia",
    Pcode: "5038",
    Locality: "PLYMPTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.554500",
    Latitude: "-34.960800"
  },
  {
    Country: "Australia",
    Pcode: "5072",
    Locality: "WOODFORDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.706600",
    Latitude: "-34.904200"
  },
  {
    Country: "Australia",
    Pcode: "5073",
    Locality: "HECTORVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.665600",
    Latitude: "-34.893000"
  },
  {
    Country: "Australia",
    Pcode: "5073",
    Locality: "ROSTREVOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.691200",
    Latitude: "-34.893800"
  },
  {
    Country: "Australia",
    Pcode: "5073",
    Locality: "TRANMERE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.662100",
    Latitude: "-34.905400"
  },
  {
    Country: "Australia",
    Pcode: "5073",
    Locality: "TRANMERE NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5074",
    Locality: "CAMPBELLTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.660800",
    Latitude: "-34.880600"
  },
  {
    Country: "Australia",
    Pcode: "5074",
    Locality: "NEWTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.682600",
    Latitude: "-34.882000"
  },
  {
    Country: "Australia",
    Pcode: "5075",
    Locality: "DERNANCOURT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.676200",
    Latitude: "-34.862500"
  },
  {
    Country: "Australia",
    Pcode: "5075",
    Locality: "PARADISE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.674700",
    Latitude: "-34.869700"
  },
  {
    Country: "Australia",
    Pcode: "5076",
    Locality: "ATHELSTONE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.707100",
    Latitude: "-34.872000"
  },
  {
    Country: "Australia",
    Pcode: "5076",
    Locality: "CASTAMBUL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.764900",
    Latitude: "-34.870800"
  },
  {
    Country: "Australia",
    Pcode: "5081",
    Locality: "COLLINSWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.611400",
    Latitude: "-34.888500"
  },
  {
    Country: "Australia",
    Pcode: "5081",
    Locality: "GILBERTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.613900",
    Latitude: "-34.904600"
  },
  {
    Country: "Australia",
    Pcode: "5081",
    Locality: "MEDINDIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.603800",
    Latitude: "-34.897600"
  },
  {
    Country: "Australia",
    Pcode: "5081",
    Locality: "MEDINDIE GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.606000",
    Latitude: "-34.892100"
  },
  {
    Country: "Australia",
    Pcode: "5081",
    Locality: "VALE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.628100",
    Latitude: "-34.886500"
  },
  {
    Country: "Australia",
    Pcode: "5081",
    Locality: "WALKERVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.617800",
    Latitude: "-34.892300"
  },
  {
    Country: "Australia",
    Pcode: "5082",
    Locality: "FITZROY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.591100",
    Latitude: "-34.896800"
  },
  {
    Country: "Australia",
    Pcode: "5082",
    Locality: "OVINGHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.585000",
    Latitude: "-34.897700"
  },
  {
    Country: "Australia",
    Pcode: "5082",
    Locality: "PROSPECT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.592000",
    Latitude: "-34.882400"
  },
  {
    Country: "Australia",
    Pcode: "5082",
    Locality: "PROSPECT EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5082",
    Locality: "THORNGATE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.596600",
    Latitude: "-34.897400"
  },
  {
    Country: "Australia",
    Pcode: "5083",
    Locality: "BROADVIEW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.615100",
    Latitude: "-34.875800"
  },
  {
    Country: "Australia",
    Pcode: "5083",
    Locality: "NAILSWORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.606700",
    Latitude: "-34.884900"
  },
  {
    Country: "Australia",
    Pcode: "5083",
    Locality: "SEFTON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.606500",
    Latitude: "-34.876400"
  },
  {
    Country: "Australia",
    Pcode: "5084",
    Locality: "BLAIR ATHOL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.596500",
    Latitude: "-34.859200"
  },
  {
    Country: "Australia",
    Pcode: "5084",
    Locality: "KILBURN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.585500",
    Latitude: "-34.859700"
  },
  {
    Country: "Australia",
    Pcode: "5084",
    Locality: "KILBURN NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5085",
    Locality: "CLEARVIEW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.613700",
    Latitude: "-34.857300"
  },
  {
    Country: "Australia",
    Pcode: "5085",
    Locality: "ENFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.605300",
    Latitude: "-34.860600"
  },
  {
    Country: "Australia",
    Pcode: "5085",
    Locality: "ENFIELD PLAZA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5085",
    Locality: "LIGHTSVIEW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.627400",
    Latitude: "-34.862000"
  },
  {
    Country: "Australia",
    Pcode: "5085",
    Locality: "NORTHFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.621900",
    Latitude: "-34.848600"
  },
  {
    Country: "Australia",
    Pcode: "5085",
    Locality: "NORTHGATE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.631200",
    Latitude: "-34.852500"
  },
  {
    Country: "Australia",
    Pcode: "5086",
    Locality: "GILLES PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.658400",
    Latitude: "-34.851500"
  },
  {
    Country: "Australia",
    Pcode: "5086",
    Locality: "GREENACRES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.627900",
    Latitude: "-34.868500"
  },
  {
    Country: "Australia",
    Pcode: "5086",
    Locality: "HAMPSTEAD GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.627800",
    Latitude: "-34.875800"
  },
  {
    Country: "Australia",
    Pcode: "5086",
    Locality: "HILLCREST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.642000",
    Latitude: "-34.863300"
  },
  {
    Country: "Australia",
    Pcode: "5086",
    Locality: "MANNINGHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.622000",
    Latitude: "-34.877200"
  },
  {
    Country: "Australia",
    Pcode: "5086",
    Locality: "OAKDEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.643000",
    Latitude: "-34.852600"
  },
  {
    Country: "Australia",
    Pcode: "5087",
    Locality: "KLEMZIG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.637600",
    Latitude: "-34.877900"
  },
  {
    Country: "Australia",
    Pcode: "5087",
    Locality: "WINDSOR GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.650000",
    Latitude: "-34.868600"
  },
  {
    Country: "Australia",
    Pcode: "5088",
    Locality: "HOLDEN HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.672800",
    Latitude: "-34.851300"
  },
  {
    Country: "Australia",
    Pcode: "5089",
    Locality: "HIGHBURY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.717600",
    Latitude: "-34.851700"
  },
  {
    Country: "Australia",
    Pcode: "5090",
    Locality: "HOPE VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.698600",
    Latitude: "-34.847200"
  },
  {
    Country: "Australia",
    Pcode: "5091",
    Locality: "BANKSIA PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.729700",
    Latitude: "-34.811300"
  },
  {
    Country: "Australia",
    Pcode: "5091",
    Locality: "TEA TREE GULLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.735300",
    Latitude: "-34.823600"
  },
  {
    Country: "Australia",
    Pcode: "5091",
    Locality: "VISTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.730300",
    Latitude: "-34.836900"
  },
  {
    Country: "Australia",
    Pcode: "5092",
    Locality: "MODBURY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.687800",
    Latitude: "-34.836100"
  },
  {
    Country: "Australia",
    Pcode: "5092",
    Locality: "MODBURY HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.685600",
    Latitude: "-34.811200"
  },
  {
    Country: "Australia",
    Pcode: "5092",
    Locality: "MODBURY NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.681000",
    Latitude: "-34.823200"
  },
  {
    Country: "Australia",
    Pcode: "5092",
    Locality: "MODBURY NORTH DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5093",
    Locality: "PARA VISTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.664700",
    Latitude: "-34.826700"
  },
  {
    Country: "Australia",
    Pcode: "5093",
    Locality: "VALLEY VIEW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.660800",
    Latitude: "-34.840100"
  },
  {
    Country: "Australia",
    Pcode: "5094",
    Locality: "CAVAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.599500",
    Latitude: "-34.825900"
  },
  {
    Country: "Australia",
    Pcode: "5094",
    Locality: "DRY CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.564500",
    Latitude: "-34.817700"
  },
  {
    Country: "Australia",
    Pcode: "5094",
    Locality: "GEPPS CROSS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.606400",
    Latitude: "-34.841400"
  },
  {
    Country: "Australia",
    Pcode: "5095",
    Locality: "MAWSON LAKES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.613900",
    Latitude: "-34.810100"
  },
  {
    Country: "Australia",
    Pcode: "5095",
    Locality: "POORAKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.627300",
    Latitude: "-34.823700"
  },
  {
    Country: "Australia",
    Pcode: "5096",
    Locality: "GULFVIEW HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.670900",
    Latitude: "-34.795400"
  },
  {
    Country: "Australia",
    Pcode: "5096",
    Locality: "PARA HILLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.656500",
    Latitude: "-34.809600"
  },
  {
    Country: "Australia",
    Pcode: "5096",
    Locality: "PARA HILLS WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.644300",
    Latitude: "-34.803400"
  },
  {
    Country: "Australia",
    Pcode: "5097",
    Locality: "REDWOOD PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.706800",
    Latitude: "-34.811400"
  },
  {
    Country: "Australia",
    Pcode: "5097",
    Locality: "RIDGEHAVEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.703600",
    Latitude: "-34.821800"
  },
  {
    Country: "Australia",
    Pcode: "5097",
    Locality: "ST AGNES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.709900",
    Latitude: "-34.829600"
  },
  {
    Country: "Australia",
    Pcode: "5098",
    Locality: "INGLE FARM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.647800",
    Latitude: "-34.826100"
  },
  {
    Country: "Australia",
    Pcode: "5157",
    Locality: "BULL CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.764400",
    Latitude: "-35.232400"
  },
  {
    Country: "Australia",
    Pcode: "5157",
    Locality: "CHERRY GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.656300",
    Latitude: "-35.069100"
  },
  {
    Country: "Australia",
    Pcode: "5157",
    Locality: "CLARENDON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.632400",
    Latitude: "-35.117100"
  },
  {
    Country: "Australia",
    Pcode: "5157",
    Locality: "COROMANDEL EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.648600",
    Latitude: "-35.047900"
  },
  {
    Country: "Australia",
    Pcode: "5157",
    Locality: "DORSET VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.709300",
    Latitude: "-35.106000"
  },
  {
    Country: "Australia",
    Pcode: "5157",
    Locality: "KANGARILLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.671400",
    Latitude: "-35.155100"
  },
  {
    Country: "Australia",
    Pcode: "5157",
    Locality: "MCHARG CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.732200",
    Latitude: "-35.274700"
  },
  {
    Country: "Australia",
    Pcode: "5158",
    Locality: "HALLETT COVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.509800",
    Latitude: "-35.075000"
  },
  {
    Country: "Australia",
    Pcode: "5158",
    Locality: "O'HALLORAN HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.555000",
    Latitude: "-35.057200"
  },
  {
    Country: "Australia",
    Pcode: "5158",
    Locality: "SHEIDOW PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.525500",
    Latitude: "-35.081300"
  },
  {
    Country: "Australia",
    Pcode: "5158",
    Locality: "TROTT PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.540000",
    Latitude: "-35.071400"
  },
  {
    Country: "Australia",
    Pcode: "5159",
    Locality: "ABERFOYLE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.599200",
    Latitude: "-35.072200"
  },
  {
    Country: "Australia",
    Pcode: "5159",
    Locality: "CHANDLERS HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.615100",
    Latitude: "-35.081700"
  },
  {
    Country: "Australia",
    Pcode: "5159",
    Locality: "FLAGSTAFF HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.582000",
    Latitude: "-35.048300"
  },
  {
    Country: "Australia",
    Pcode: "5159",
    Locality: "HAPPY VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.577700",
    Latitude: "-35.071300"
  },
  {
    Country: "Australia",
    Pcode: "5160",
    Locality: "LONSDALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.490700",
    Latitude: "-35.106400"
  },
  {
    Country: "Australia",
    Pcode: "5160",
    Locality: "LONSDALE DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5161",
    Locality: "OLD REYNELLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.537400",
    Latitude: "-35.097400"
  },
  {
    Country: "Australia",
    Pcode: "5161",
    Locality: "REYNELLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.522100",
    Latitude: "-35.096600"
  },
  {
    Country: "Australia",
    Pcode: "5161",
    Locality: "REYNELLA EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.552700",
    Latitude: "-35.088100"
  },
  {
    Country: "Australia",
    Pcode: "5162",
    Locality: "MORPHETT VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.528100",
    Latitude: "-35.120800"
  },
  {
    Country: "Australia",
    Pcode: "5162",
    Locality: "WOODCROFT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.557800",
    Latitude: "-35.108300"
  },
  {
    Country: "Australia",
    Pcode: "5163",
    Locality: "HACKHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.527900",
    Latitude: "-35.155300"
  },
  {
    Country: "Australia",
    Pcode: "5163",
    Locality: "HACKHAM WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.515200",
    Latitude: "-35.142400"
  },
  {
    Country: "Australia",
    Pcode: "5163",
    Locality: "HUNTFIELD HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.513600",
    Latitude: "-35.155400"
  },
  {
    Country: "Australia",
    Pcode: "5163",
    Locality: "ONKAPARINGA HILLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.571900",
    Latitude: "-35.137200"
  },
  {
    Country: "Australia",
    Pcode: "5164",
    Locality: "CHRISTIE DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.495400",
    Latitude: "-35.129300"
  },
  {
    Country: "Australia",
    Pcode: "5165",
    Locality: "CHRISTIES BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.476400",
    Latitude: "-35.134300"
  },
  {
    Country: "Australia",
    Pcode: "5165",
    Locality: "CHRISTIES BEACH NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5166",
    Locality: "O'SULLIVAN BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.476200",
    Latitude: "-35.122000"
  },
  {
    Country: "Australia",
    Pcode: "5167",
    Locality: "PORT NOARLUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.477600",
    Latitude: "-35.149200"
  },
  {
    Country: "Australia",
    Pcode: "5167",
    Locality: "PORT NOARLUNGA SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.476500",
    Latitude: "-35.161700"
  },
  {
    Country: "Australia",
    Pcode: "5168",
    Locality: "NOARLUNGA CENTRE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.496000",
    Latitude: "-35.141100"
  },
  {
    Country: "Australia",
    Pcode: "5168",
    Locality: "NOARLUNGA DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.497600",
    Latitude: "-35.166500"
  },
  {
    Country: "Australia",
    Pcode: "5168",
    Locality: "OLD NOARLUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.502300",
    Latitude: "-35.180800"
  },
  {
    Country: "Australia",
    Pcode: "5169",
    Locality: "MOANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.473300",
    Latitude: "-35.207200"
  },
  {
    Country: "Australia",
    Pcode: "5169",
    Locality: "SEAFORD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.478000",
    Latitude: "-35.185600"
  },
  {
    Country: "Australia",
    Pcode: "5169",
    Locality: "SEAFORD HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.497000",
    Latitude: "-35.198200"
  },
  {
    Country: "Australia",
    Pcode: "5169",
    Locality: "SEAFORD MEADOWS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.485900",
    Latitude: "-35.168600"
  },
  {
    Country: "Australia",
    Pcode: "5169",
    Locality: "SEAFORD RISE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.484800",
    Latitude: "-35.204200"
  },
  {
    Country: "Australia",
    Pcode: "5170",
    Locality: "MASLIN BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.477500",
    Latitude: "-35.233400"
  },
  {
    Country: "Australia",
    Pcode: "5171",
    Locality: "BLEWITT SPRINGS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.596600",
    Latitude: "-35.167800"
  },
  {
    Country: "Australia",
    Pcode: "5171",
    Locality: "MCLAREN FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.609200",
    Latitude: "-35.199400"
  },
  {
    Country: "Australia",
    Pcode: "5171",
    Locality: "MCLAREN VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.552500",
    Latitude: "-35.204600"
  },
  {
    Country: "Australia",
    Pcode: "5171",
    Locality: "TATACHILLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.509600",
    Latitude: "-35.237000"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "DINGABLEDINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.638900",
    Latitude: "-35.264100"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "HOPE FOREST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.626300",
    Latitude: "-35.284600"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "KUITPO COLONY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.693500",
    Latitude: "-35.285200"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "KYEEMA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.671800",
    Latitude: "-35.279400"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "MONTARRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.615200",
    Latitude: "-35.263700"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "PAGES FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.546500",
    Latitude: "-35.335400"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "THE RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.622200",
    Latitude: "-35.239200"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "WHITES VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.516400",
    Latitude: "-35.268500"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "WILLUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.569300",
    Latitude: "-35.268500"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "WILLUNGA HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.589200",
    Latitude: "-35.298900"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "WILLUNGA SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.543400",
    Latitude: "-35.300300"
  },
  {
    Country: "Australia",
    Pcode: "5172",
    Locality: "YUNDI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.632200",
    Latitude: "-35.311200"
  },
  {
    Country: "Australia",
    Pcode: "5173",
    Locality: "ALDINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.483400",
    Latitude: "-35.270000"
  },
  {
    Country: "Australia",
    Pcode: "5173",
    Locality: "ALDINGA BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.457700",
    Latitude: "-35.293700"
  },
  {
    Country: "Australia",
    Pcode: "5173",
    Locality: "PORT WILLUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.460900",
    Latitude: "-35.261400"
  },
  {
    Country: "Australia",
    Pcode: "5174",
    Locality: "SELLICKS BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.458500",
    Latitude: "-35.331400"
  },
  {
    Country: "Australia",
    Pcode: "5174",
    Locality: "SELLICKS HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.495300",
    Latitude: "-35.330800"
  },
  {
    Country: "Australia",
    Pcode: "5201",
    Locality: "BLACKFELLOWS CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.710900",
    Latitude: "-35.253000"
  },
  {
    Country: "Australia",
    Pcode: "5201",
    Locality: "KUITPO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.678300",
    Latitude: "-35.214000"
  },
  {
    Country: "Australia",
    Pcode: "5201",
    Locality: "MEADOWS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.754800",
    Latitude: "-35.179200"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "POMPOOTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.366300",
    Latitude: "-34.998400"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "PONDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.333100",
    Latitude: "-34.949300"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "PORT MANNUM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.303400",
    Latitude: "-34.926400"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "PUNTHARI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.290700",
    Latitude: "-34.816100"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "PURNONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.615200",
    Latitude: "-34.825000"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "PURNONG LANDING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "ROCKY POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.442600",
    Latitude: "-34.853900"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "TEAL FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.557500",
    Latitude: "-34.891900"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "WALKER FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.509900",
    Latitude: "-34.801900"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "WONGULLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.551400",
    Latitude: "-34.704100"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "YOUNGHUSBAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.475200",
    Latitude: "-34.911300"
  },
  {
    Country: "Australia",
    Pcode: "5238",
    Locality: "YOUNGHUSBAND HOLDINGS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.549300",
    Latitude: "-34.873900"
  },
  {
    Country: "Australia",
    Pcode: "5240",
    Locality: "LENSWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.821700",
    Latitude: "-34.905600"
  },
  {
    Country: "Australia",
    Pcode: "5241",
    Locality: "LOBETHAL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.876200",
    Latitude: "-34.904700"
  },
  {
    Country: "Australia",
    Pcode: "5242",
    Locality: "BALHANNAH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.812700",
    Latitude: "-34.993200"
  },
  {
    Country: "Australia",
    Pcode: "5243",
    Locality: "OAKBANK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.854700",
    Latitude: "-34.976400"
  },
  {
    Country: "Australia",
    Pcode: "5244",
    Locality: "CHARLESTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.930100",
    Latitude: "-34.910000"
  },
  {
    Country: "Australia",
    Pcode: "5244",
    Locality: "HARROGATE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.991100",
    Latitude: "-34.956500"
  },
  {
    Country: "Australia",
    Pcode: "5244",
    Locality: "INVERBRACKIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5244",
    Locality: "MOUNT TORRENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.964000",
    Latitude: "-34.880000"
  },
  {
    Country: "Australia",
    Pcode: "5244",
    Locality: "WOODSIDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.903900",
    Latitude: "-34.954000"
  },
  {
    Country: "Australia",
    Pcode: "5245",
    Locality: "HAHNDORF",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.809800",
    Latitude: "-35.032800"
  },
  {
    Country: "Australia",
    Pcode: "5245",
    Locality: "PAECHTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.817400",
    Latitude: "-35.051700"
  },
  {
    Country: "Australia",
    Pcode: "5245",
    Locality: "VERDUN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.792100",
    Latitude: "-35.009300"
  },
  {
    Country: "Australia",
    Pcode: "5250",
    Locality: "BLAKISTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.886700",
    Latitude: "-35.041000"
  },
  {
    Country: "Australia",
    Pcode: "5250",
    Locality: "LITTLEHAMPTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.863300",
    Latitude: "-35.037100"
  },
  {
    Country: "Australia",
    Pcode: "5250",
    Locality: "TOTNESS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.841700",
    Latitude: "-35.046100"
  },
  {
    Country: "Australia",
    Pcode: "5251",
    Locality: "BUGLE RANGES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.882200",
    Latitude: "-35.135700"
  },
  {
    Country: "Australia",
    Pcode: "5251",
    Locality: "MOUNT BARKER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.865100",
    Latitude: "-35.081700"
  },
  {
    Country: "Australia",
    Pcode: "5251",
    Locality: "MOUNT BARKER JUNCTION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.867800",
    Latitude: "-35.026300"
  },
  {
    Country: "Australia",
    Pcode: "5251",
    Locality: "MOUNT BARKER SPRINGS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.920900",
    Latitude: "-35.090200"
  },
  {
    Country: "Australia",
    Pcode: "5251",
    Locality: "MOUNT BARKER SUMMIT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.922100",
    Latitude: "-35.070400"
  },
  {
    Country: "Australia",
    Pcode: "5251",
    Locality: "WISTOW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.924200",
    Latitude: "-35.122100"
  },
  {
    Country: "Australia",
    Pcode: "5252",
    Locality: "BRUKUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.948100",
    Latitude: "-35.009600"
  },
  {
    Country: "Australia",
    Pcode: "5252",
    Locality: "DAWESLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.953700",
    Latitude: "-35.042800"
  },
  {
    Country: "Australia",
    Pcode: "5252",
    Locality: "HAY VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.900200",
    Latitude: "-35.005600"
  },
  {
    Country: "Australia",
    Pcode: "5252",
    Locality: "KANMANTOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.001000",
    Latitude: "-35.064600"
  },
  {
    Country: "Australia",
    Pcode: "5252",
    Locality: "NAIRNE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.912400",
    Latitude: "-35.036100"
  },
  {
    Country: "Australia",
    Pcode: "5252",
    Locality: "ST IVES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.976000",
    Latitude: "-35.112100"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "AVOCA DELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.312200",
    Latitude: "-35.091200"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "BRINKLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.250100",
    Latitude: "-35.230300"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "BURDETT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.396100",
    Latitude: "-35.029900"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "CHAPMAN BORE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.542400",
    Latitude: "-35.114300"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "ETTRICK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.542700",
    Latitude: "-35.028300"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "GIFFORD HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.223700",
    Latitude: "-35.162800"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "GREENBANKS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.349000",
    Latitude: "-35.054900"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "LONG FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.310800",
    Latitude: "-35.131400"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "MOBILONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.275500",
    Latitude: "-35.102000"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "MONTEITH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.338400",
    Latitude: "-35.169200"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "MURRAWONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.329800",
    Latitude: "-35.055700"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "MURRAY BRIDGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.263600",
    Latitude: "-35.129400"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "MURRAY BRIDGE EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.308400",
    Latitude: "-35.117700"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "MURRAY BRIDGE NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.268500",
    Latitude: "-35.076500"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "MURRAY BRIDGE SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.274400",
    Latitude: "-35.165600"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "NORTHERN HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.258400",
    Latitude: "-35.102400"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "RIVERGLADES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.299300",
    Latitude: "-35.099800"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "RIVERGLEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.308000",
    Latitude: "-35.172100"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "SUNNYSIDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.367300",
    Latitude: "-35.052000"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "SWANPORT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.304200",
    Latitude: "-35.154800"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "TOORA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.298000",
    Latitude: "-35.082800"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "WHITE SANDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.319600",
    Latitude: "-35.196500"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "WILLOW BANKS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.345100",
    Latitude: "-35.055500"
  },
  {
    Country: "Australia",
    Pcode: "5253",
    Locality: "WOODS POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.370800",
    Latitude: "-35.220400"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "CALLINGTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.037000",
    Latitude: "-35.112700"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "CALOOTE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.256400",
    Latitude: "-34.979600"
  },
  {
    Country: "Australia",
    Pcode: "5254",
    Locality: "MONARTO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.120700",
    Latitude: "-35.082800"
  },
  {
    Country: "Australia",
    Pcode: "5351",
    Locality: "BAROSSA GOLDFIELDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.812100",
    Latitude: "-34.655100"
  },
  {
    Country: "Australia",
    Pcode: "5351",
    Locality: "COCKATOO VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.837400",
    Latitude: "-34.627900"
  },
  {
    Country: "Australia",
    Pcode: "5351",
    Locality: "LYNDOCH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.884300",
    Latitude: "-34.595500"
  },
  {
    Country: "Australia",
    Pcode: "5351",
    Locality: "MOUNT CRAWFORD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.969600",
    Latitude: "-34.723700"
  },
  {
    Country: "Australia",
    Pcode: "5351",
    Locality: "PEWSEY VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.968000",
    Latitude: "-34.639900"
  },
  {
    Country: "Australia",
    Pcode: "5351",
    Locality: "WILLIAMSTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.889700",
    Latitude: "-34.664200"
  },
  {
    Country: "Australia",
    Pcode: "5352",
    Locality: "BETHANY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.984000",
    Latitude: "-34.547300"
  },
  {
    Country: "Australia",
    Pcode: "5352",
    Locality: "GOMERSAL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.895100",
    Latitude: "-34.534700"
  },
  {
    Country: "Australia",
    Pcode: "5352",
    Locality: "KRONDORF",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.970900",
    Latitude: "-34.564900"
  },
  {
    Country: "Australia",
    Pcode: "5352",
    Locality: "ROWLAND FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.936900",
    Latitude: "-34.567900"
  },
  {
    Country: "Australia",
    Pcode: "5352",
    Locality: "STONE WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.957100",
    Latitude: "-34.501100"
  },
  {
    Country: "Australia",
    Pcode: "5352",
    Locality: "TANUNDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.956300",
    Latitude: "-34.524800"
  },
  {
    Country: "Australia",
    Pcode: "5352",
    Locality: "VINE VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.001600",
    Latitude: "-34.521700"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "ANGASTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.061100",
    Latitude: "-34.534500"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "BON ACCORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.664000",
    Latitude: "-25.572000"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "BRANCH CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.435500",
    Latitude: "-25.389800"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "BYRNESTOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.762500",
    Latitude: "-25.523800"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "CAMPBELL CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.716100",
    Latitude: "-25.670300"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "DEEP CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.470800",
    Latitude: "-25.658300"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "DIRNBIR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.532400",
    Latitude: "-25.602000"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "DUNDARRAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.929100",
    Latitude: "-25.659700"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "GAYNDAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.637000",
    Latitude: "-25.625800"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "GINOONDAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.769200",
    Latitude: "-25.605300"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "GOOROOLBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.799800",
    Latitude: "-25.525400"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "HARRIET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.579200",
    Latitude: "-25.757100"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "HUMPHERY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.473400",
    Latitude: "-25.601400"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "IDERAWAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.612800",
    Latitude: "-25.569200"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "MINGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.756000",
    Latitude: "-25.360300"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "MOUNT DEBATEABLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.553200",
    Latitude: "-25.632000"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "MOUNT LAWLESS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.640400",
    Latitude: "-25.543200"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "MOUNT STEADMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.788000",
    Latitude: "-25.456700"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "PENWHAUPELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.738300",
    Latitude: "-25.748400"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "PILE GULLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.480500",
    Latitude: "-25.758800"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "REIDS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.536600",
    Latitude: "-25.528100"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "STOCKHAVEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.921800",
    Latitude: "-25.723300"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "THE LIMITS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.663000",
    Latitude: "-25.676500"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "TOONDAHRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.413000",
    Latitude: "-25.933100"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "WAHOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.720100",
    Latitude: "-25.895100"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "WETHERON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.716300",
    Latitude: "-25.502200"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "WILSON VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.847000",
    Latitude: "-25.551800"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "WOODMILLAR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.576200",
    Latitude: "-25.676300"
  },
  {
    Country: "Australia",
    Pcode: "4625",
    Locality: "YENDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.627800",
    Latitude: "-25.406400"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "BEERON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.267700",
    Latitude: "-25.885600"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "BOYNEWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.269300",
    Latitude: "-25.633700"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "BROVINIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.136700",
    Latitude: "-25.885000"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "CATTLE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.296400",
    Latitude: "-25.449400"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "COONAMBULA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.061600",
    Latitude: "-25.548200"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "DERRI DERRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.243200",
    Latitude: "-25.699800"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "DYKEHEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.051900",
    Latitude: "-25.647400"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "GLENRAE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.366400",
    Latitude: "-25.646700"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "GURGEENA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.382700",
    Latitude: "-25.457400"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "HAWKWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.854800",
    Latitude: "-25.872000"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "MONOGORILBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.028800",
    Latitude: "-26.008900"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "MUNDOWRAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.339700",
    Latitude: "-25.520600"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "MUNDUBBERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.304300",
    Latitude: "-25.585200"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "O'BIL BIL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.252400",
    Latitude: "-25.525900"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "OLD COORANGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.417900",
    Latitude: "-25.787200"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "PHILPOTT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.395400",
    Latitude: "-25.565700"
  },
  {
    Country: "Australia",
    Pcode: "4626",
    Locality: "RIVERLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.221400",
    Latitude: "-25.588900"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "ABERCORN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.158700",
    Latitude: "-25.138000"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "CERATODUS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.110600",
    Latitude: "-25.274800"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "CHELTENHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.824700",
    Latitude: "-25.615900"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "CYNTHIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.122100",
    Latitude: "-25.203600"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "EIDSVOLD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.054700",
    Latitude: "-25.368300"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "EIDSVOLD EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.247400",
    Latitude: "-25.323200"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "EIDSVOLD WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.671500",
    Latitude: "-25.362500"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "GROSVENOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.165300",
    Latitude: "-25.424300"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "MALMOE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.184400",
    Latitude: "-25.482900"
  },
  {
    Country: "Australia",
    Pcode: "4627",
    Locality: "WURUMA DAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.972600",
    Latitude: "-25.140000"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "BANCROFT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.284700",
    Latitude: "-24.790100"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "BUKALI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.163600",
    Latitude: "-24.818100"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "CANIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.968200",
    Latitude: "-24.652400"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "CANNINDAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.214400",
    Latitude: "-24.879200"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "COOMINGLAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.839400",
    Latitude: "-24.750500"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "COOMINGLAH FOREST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.970000",
    Latitude: "-24.914700"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "DALGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.466100",
    Latitude: "-24.618900"
  },
  {
    Country: "Australia",
    Pcode: "4630",
    Locality: "GLENLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.067800",
    Latitude: "-25.050600"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "COONARR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.471000",
    Latitude: "-24.954600"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "CORAL COVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.481800",
    Latitude: "-24.882000"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "ELECTRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.113800",
    Latitude: "-25.000700"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "ELLIOTT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.326000",
    Latitude: "-25.004400"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "ELLIOTT HEADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.475300",
    Latitude: "-24.910900"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "FAIRYMEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.353300",
    Latitude: "-24.770400"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "GIVELDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.149800",
    Latitude: "-24.990500"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "GOOBURRUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.332000",
    Latitude: "-24.822400"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "INNES PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.466200",
    Latitude: "-24.866400"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "KALKIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.382300",
    Latitude: "-24.846100"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "KENSINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.322500",
    Latitude: "-24.911400"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "KEPNOCK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.375400",
    Latitude: "-24.874900"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "KINKUNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.443900",
    Latitude: "-25.025200"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "MEADOWVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.252300",
    Latitude: "-24.820100"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "MILLBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.319700",
    Latitude: "-24.871400"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "MON REPOS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.440500",
    Latitude: "-24.808700"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "MOORE PARK BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.222900",
    Latitude: "-24.702100"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "MOORLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.207600",
    Latitude: "-24.765300"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "MULLETT CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.067900",
    Latitude: "-24.599800"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "NORVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.343600",
    Latitude: "-24.893000"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "OAKWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.297800",
    Latitude: "-24.851800"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "PINE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.169000",
    Latitude: "-25.035600"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "QUNABA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.430200",
    Latitude: "-24.829900"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "RUBYANNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.390200",
    Latitude: "-24.825400"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "SHARON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.253400",
    Latitude: "-24.870800"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "SOUTH BINGERA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.218900",
    Latitude: "-24.960600"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "SOUTH KOLAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.181300",
    Latitude: "-24.928200"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "SVENSSON HEIGHTS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.333600",
    Latitude: "-24.896200"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "THABEBAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.352000",
    Latitude: "-24.907100"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "WALKERVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.358700",
    Latitude: "-24.882500"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "WATALGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.002200",
    Latitude: "-24.638300"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "WELCOME CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.258600",
    Latitude: "-24.769600"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "WINDERMERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.431900",
    Latitude: "-24.860100"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "WINFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.001800",
    Latitude: "-24.554000"
  },
  {
    Country: "Australia",
    Pcode: "4670",
    Locality: "WOONGARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.417200",
    Latitude: "-24.894200"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "BOOLBOONDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.684600",
    Latitude: "-25.061400"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "BOOYAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.092200",
    Latitude: "-25.227400"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "BULLYARD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.042600",
    Latitude: "-24.941600"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "BUNGADOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.082100",
    Latitude: "-24.987300"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "DALYSFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.896300",
    Latitude: "-25.016300"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "DAMASCUS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.974700",
    Latitude: "-24.928100"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "DELAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.051100",
    Latitude: "-25.014600"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "DOUGHBOY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.820900",
    Latitude: "-25.144100"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "DRINAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.008200",
    Latitude: "-25.036100"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "DUINGAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.053900",
    Latitude: "-25.101700"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "GAETA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.593100",
    Latitude: "-24.857400"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "GIN GIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.952600",
    Latitude: "-24.976900"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "GOOD NIGHT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.910900",
    Latitude: "-25.251300"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "HORSE CAMP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.878200",
    Latitude: "-25.073800"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "KOLONGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.682600",
    Latitude: "-24.776000"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "LAKE MONDURAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.812000",
    Latitude: "-24.805400"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "MAROONDAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.012100",
    Latitude: "-24.965100"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "MCILWRAITH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "152.000800",
    Latitude: "-24.998700"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "MOLANGUL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.531700",
    Latitude: "-24.764400"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "MONDURAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.893500",
    Latitude: "-24.854400"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "MOOLBOOLAMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.808900",
    Latitude: "-24.991500"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "MORGANVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.954100",
    Latitude: "-25.182900"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "MOUNT PERRY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.664700",
    Latitude: "-25.186000"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "MUNGY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.439300",
    Latitude: "-25.239400"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "NEARUM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.811300",
    Latitude: "-25.067300"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "NEW MOONTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.728000",
    Latitude: "-25.037900"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "REDHILL FARMS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.958800",
    Latitude: "-25.027000"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "SKYRING RESERVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.960800",
    Latitude: "-25.042500"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "ST AGNES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.931800",
    Latitude: "-25.135100"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "ST KILDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.921400",
    Latitude: "-25.058000"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "TAKILBERAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.716200",
    Latitude: "-24.897000"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "TIRROAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.924900",
    Latitude: "-25.014000"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "WALLAVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.956100",
    Latitude: "-25.086600"
  },
  {
    Country: "Australia",
    Pcode: "4671",
    Locality: "WONBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "151.609600",
    Latitude: "-25.074200"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "COOWONGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.725600",
    Latitude: "-23.307200"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "DALMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.226500",
    Latitude: "-23.356500"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "DINGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.219400",
    Latitude: "-23.705000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "DIXALEA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.301100",
    Latitude: "-23.966000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "DULULU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.276700",
    Latitude: "-23.847600"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "DUMPY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.038400",
    Latitude: "-23.974100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "ETNA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.397500",
    Latitude: "-23.227400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "GAINSFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.935400",
    Latitude: "-23.859600"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "GARNANT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.262800",
    Latitude: "-23.175200"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "GINDIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.131800",
    Latitude: "-23.767800"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "GLENROY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.818800",
    Latitude: "-23.178300"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "GOGANGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.920200",
    Latitude: "-23.553700"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "GOOMALLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.264400",
    Latitude: "-24.316000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "GOOVIGEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.281100",
    Latitude: "-24.094000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "GOOWARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.489500",
    Latitude: "-23.499100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "GRACEMERE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.457100",
    Latitude: "-23.463100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "JAMBIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.434400",
    Latitude: "-24.183400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "JARDINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.397900",
    Latitude: "-23.055700"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "JELLINBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.045400",
    Latitude: "-23.264000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "JOSKELEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.807600",
    Latitude: "-23.379600"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "KABRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.402100",
    Latitude: "-23.501400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "KALAPA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.206100",
    Latitude: "-23.447900"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "KEPPEL SANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.806300",
    Latitude: "-23.336300"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "KOKOTUNGO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.974500",
    Latitude: "-24.126600"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "KUNWARARA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.092800",
    Latitude: "-22.850000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "LOWESBY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.685900",
    Latitude: "-24.324100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "MACKENZIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "CENTRAL HIGHLANDS",
    Category: "Delivery Area",
    Longitude: "149.385000",
    Latitude: "-23.053500"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "MARMOR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.745500",
    Latitude: "-23.702400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "MIDGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.594600",
    Latitude: "-23.496500"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "MILMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.423700",
    Latitude: "-23.114100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "MIMOSA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.608100",
    Latitude: "-24.225000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "MORINISH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.065600",
    Latitude: "-23.203100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "MORINISH SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.033700",
    Latitude: "-23.363100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "MOUNT CHALMERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.638700",
    Latitude: "-23.312900"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "NINE MILE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.351600",
    Latitude: "-23.412100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "PARKHURST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.501600",
    Latitude: "-23.304100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "PHEASANT CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.095800",
    Latitude: "-23.815100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "PINK LILY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.461600",
    Latitude: "-23.350400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "PLUM TREE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "RIDGELANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.257300",
    Latitude: "-23.277400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "ROLLESTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.634400",
    Latitude: "-24.469200"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "ROSSMOYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.498800",
    Latitude: "-23.051500"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "RUBYVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "SAPPHIRE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.775300",
    Latitude: "-23.540800"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "SHOALWATER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.470500",
    Latitude: "-22.539700"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "SMOKY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "SOUTH YAAMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.338600",
    Latitude: "-23.210400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "STANAGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.142300",
    Latitude: "-22.179300"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "STANWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.318000",
    Latitude: "-23.530100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "STEWARTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.917800",
    Latitude: "-23.774400"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "TARRAMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.270100",
    Latitude: "-24.610900"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "THE CAVES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.465500",
    Latitude: "-23.174700"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "THE GEMFIELDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "THOMPSON POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.828900",
    Latitude: "-23.470300"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "TUNGAMULL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.700100",
    Latitude: "-23.331100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "ULOGIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.537700",
    Latitude: "-23.946600"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "WALLAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.557500",
    Latitude: "-23.631500"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "WESTWOOD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.185300",
    Latitude: "-23.678800"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "WILLOWS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.402800",
    Latitude: "-23.572200"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "WILLOWS GEMFIELDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "WOOLEIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.163500",
    Latitude: "-24.258100"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "WOOROONA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.322300",
    Latitude: "-24.016300"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "WOWAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.144800",
    Latitude: "-23.936900"
  },
  {
    Country: "Australia",
    Pcode: "4702",
    Locality: "WYCARBAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.096700",
    Latitude: "-23.493500"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "ADELAIDE PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.696200",
    Latitude: "-23.095500"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "BANGALEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.783500",
    Latitude: "-23.076900"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "BARLOWS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.741200",
    Latitude: "-23.107800"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "BARMARYEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.692200",
    Latitude: "-23.132500"
  },
  {
    Country: "Australia",
    Pcode: "4703",
    Locality: "BARMOYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "150.540300",
    Latitude: "-23.124300"
  },
  {
    Country: "Australia",
    Pcode: "4728",
    Locality: "DUNROBIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.064100",
    Latitude: "-22.772400"
  },
  {
    Country: "Australia",
    Pcode: "4728",
    Locality: "GARFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.947800",
    Latitude: "-23.288900"
  },
  {
    Country: "Australia",
    Pcode: "4728",
    Locality: "JERICHO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.122300",
    Latitude: "-23.578100"
  },
  {
    Country: "Australia",
    Pcode: "4728",
    Locality: "MEXICO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.169100",
    Latitude: "-23.883100"
  },
  {
    Country: "Australia",
    Pcode: "4730",
    Locality: "CAMOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.386600",
    Latitude: "-22.944300"
  },
  {
    Country: "Australia",
    Pcode: "4730",
    Locality: "CHORREGON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.657200",
    Latitude: "-22.712800"
  },
  {
    Country: "Australia",
    Pcode: "4730",
    Locality: "ERNESTINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.990100",
    Latitude: "-23.709200"
  },
  {
    Country: "Australia",
    Pcode: "4730",
    Locality: "LONGREACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.227900",
    Latitude: "-23.512400"
  },
  {
    Country: "Australia",
    Pcode: "4730",
    Locality: "MANEROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.684300",
    Latitude: "-23.524300"
  },
  {
    Country: "Australia",
    Pcode: "4730",
    Locality: "MORELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.764600",
    Latitude: "-22.962400"
  },
  {
    Country: "Australia",
    Pcode: "4730",
    Locality: "STONEHENGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.484600",
    Latitude: "-24.221400"
  },
  {
    Country: "Australia",
    Pcode: "4730",
    Locality: "TOCAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.660000",
    Latitude: "-23.910200"
  },
  {
    Country: "Australia",
    Pcode: "4730",
    Locality: "VERGEMONT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.178200",
    Latitude: "-23.607400"
  },
  {
    Country: "Australia",
    Pcode: "4731",
    Locality: "ISISFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.116000",
    Latitude: "-24.477900"
  },
  {
    Country: "Australia",
    Pcode: "4731",
    Locality: "YARAKA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4732",
    Locality: "MUTTABURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.530300",
    Latitude: "-22.601200"
  },
  {
    Country: "Australia",
    Pcode: "4732",
    Locality: "TABLEDERRY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.529000",
    Latitude: "-22.379200"
  },
  {
    Country: "Australia",
    Pcode: "4733",
    Locality: "CORFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.466500",
    Latitude: "-22.053900"
  },
  {
    Country: "Australia",
    Pcode: "4735",
    Locality: "DIAMANTINA LAKES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.305600",
    Latitude: "-23.940900"
  },
  {
    Country: "Australia",
    Pcode: "4735",
    Locality: "MIDDLETON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.713400",
    Latitude: "-22.610500"
  },
  {
    Country: "Australia",
    Pcode: "4735",
    Locality: "OPALTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.403600",
    Latitude: "-23.019800"
  },
  {
    Country: "Australia",
    Pcode: "4735",
    Locality: "WINTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.039400",
    Latitude: "-22.380600"
  },
  {
    Country: "Australia",
    Pcode: "4736",
    Locality: "JUNDAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.171300",
    Latitude: "-25.074100"
  },
  {
    Country: "Australia",
    Pcode: "4737",
    Locality: "ARMSTRONG BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.268100",
    Latitude: "-21.444600"
  },
  {
    Country: "Australia",
    Pcode: "4737",
    Locality: "BLUE MOUNTAIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.997500",
    Latitude: "-21.504900"
  },
  {
    Country: "Australia",
    Pcode: "4737",
    Locality: "CAMPWIN BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.307600",
    Latitude: "-21.377800"
  },
  {
    Country: "Australia",
    Pcode: "4737",
    Locality: "FRESHWATER POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.281000",
    Latitude: "-21.413400"
  },
  {
    Country: "Australia",
    Pcode: "4737",
    Locality: "SARINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.164500",
    Latitude: "-21.435700"
  },
  {
    Country: "Australia",
    Pcode: "4737",
    Locality: "SARINA BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.286300",
    Latitude: "-21.387600"
  },
  {
    Country: "Australia",
    Pcode: "4737",
    Locality: "SARINA RANGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.126400",
    Latitude: "-21.560400"
  },
  {
    Country: "Australia",
    Pcode: "4737",
    Locality: "SUNNYSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MACKAY",
    Category: "Delivery Area",
    Longitude: "149.105200",
    Latitude: "-21.349700"
  },
  {
    Country: "Australia",
    Pcode: "4738",
    Locality: "ILBILBIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.370900",
    Latitude: "-21.708200"
  },
  {
    Country: "Australia",
    Pcode: "4738",
    Locality: "KOUMALA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.281400",
    Latitude: "-21.622600"
  },
  {
    Country: "Australia",
    Pcode: "4739",
    Locality: "CARMILA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.367800",
    Latitude: "-21.871500"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "ALEXANDRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.092500",
    Latitude: "-21.161900"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "ALLIGATOR CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MACKAY",
    Category: "Delivery Area",
    Longitude: "149.218100",
    Latitude: "-21.323800"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "ANDERGROVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.183800",
    Latitude: "-21.096000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "BAKERS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.159600",
    Latitude: "-21.214200"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "BALBERRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.144900",
    Latitude: "-21.299200"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "BALNAGOWAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.009000",
    Latitude: "-21.114800"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "BEACONSFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.167600",
    Latitude: "-21.095700"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "BELMUNDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.016400",
    Latitude: "-20.949800"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "BLACKS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.189900",
    Latitude: "-21.061300"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "CAPE HILLSBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.028000",
    Latitude: "-20.911500"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "CHELONA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.142800",
    Latitude: "-21.259700"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "CREMORNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.190300",
    Latitude: "-21.133000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "DOLPHIN HEADS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.183800",
    Latitude: "-21.040000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "DUMBLETON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.073500",
    Latitude: "-21.131100"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "DUNDULA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "DUNNROCK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.184200",
    Latitude: "-21.267500"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "EAST MACKAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.201600",
    Latitude: "-21.147500"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "EIMEO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.174200",
    Latitude: "-21.048000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "ERAKALA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.119800",
    Latitude: "-21.128000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "FOULDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.145600",
    Latitude: "-21.137600"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "GLENELLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.134600",
    Latitude: "-21.114900"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "GRASSTREE BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.284300",
    Latitude: "-21.352100"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "HABANA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.080900",
    Latitude: "-21.023200"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "HALIDAY BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.982300",
    Latitude: "-20.909600"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "HAY POINT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.269300",
    Latitude: "-21.298200"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "HOMEBUSH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MACKAY",
    Category: "Delivery Area",
    Longitude: "149.051800",
    Latitude: "-21.270400"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MACKAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.185400",
    Latitude: "-21.142700"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MACKAY CANELAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MACKAY DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MACKAY HARBOUR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.214300",
    Latitude: "-21.117100"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MACKAY NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MACKAY SOUTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MACKAY WEST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MCEWENS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "149.190900",
    Latitude: "-21.234200"
  },
  {
    Country: "Australia",
    Pcode: "4740",
    Locality: "MOUNT JUKES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.962900",
    Latitude: "-20.986000"
  },
  {
    Country: "Australia",
    Pcode: "4804",
    Locality: "SCOTTVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.823700",
    Latitude: "-20.585300"
  },
  {
    Country: "Australia",
    Pcode: "4804",
    Locality: "SPRINGLANDS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.764000",
    Latitude: "-20.606100"
  },
  {
    Country: "Australia",
    Pcode: "4805",
    Locality: "BOGIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.967700",
    Latitude: "-20.393600"
  },
  {
    Country: "Australia",
    Pcode: "4805",
    Locality: "BOWEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.123400",
    Latitude: "-20.117900"
  },
  {
    Country: "Australia",
    Pcode: "4805",
    Locality: "GUMLU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.588300",
    Latitude: "-19.899000"
  },
  {
    Country: "Australia",
    Pcode: "4805",
    Locality: "GUTHALUNGRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.840000",
    Latitude: "-19.904800"
  },
  {
    Country: "Australia",
    Pcode: "4805",
    Locality: "MERINDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4805",
    Locality: "QUEENS BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "CARSTAIRS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.457700",
    Latitude: "-19.651100"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "FREDERICKSFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.402200",
    Latitude: "-19.732900"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "GROPER CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.558600",
    Latitude: "-19.700400"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "HOME HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.413800",
    Latitude: "-19.661200"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "INKERMAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.498400",
    Latitude: "-19.714200"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "KIRKNIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.317500",
    Latitude: "-19.834900"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "OSBORNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.372800",
    Latitude: "-19.690700"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "RANGEMORE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "BURDEKIN",
    Category: "Delivery Area",
    Longitude: "147.393800",
    Latitude: "-19.991000"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "WANGARATTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.469900",
    Latitude: "-19.824300"
  },
  {
    Country: "Australia",
    Pcode: "4806",
    Locality: "WUNJUNGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.564100",
    Latitude: "-19.767200"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "AIRDMILLAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.481200",
    Latitude: "-19.528300"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "AIRVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.343500",
    Latitude: "-19.628600"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "ALVA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.437900",
    Latitude: "-19.468000"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "AYR",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.404000",
    Latitude: "-19.562600"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "CLARE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.192900",
    Latitude: "-19.831600"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "CLAREDALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "DALBEG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.288100",
    Latitude: "-20.207500"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "EIGHT MILE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.230800",
    Latitude: "-20.282100"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "JARVISFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.508000",
    Latitude: "-19.572200"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "MCDESME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.398100",
    Latitude: "-19.619900"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "MILLAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.254100",
    Latitude: "-20.000400"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "MONA PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.231900",
    Latitude: "-19.704700"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "MOUNT KELLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.313700",
    Latitude: "-19.661400"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "MULGRAVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.059000",
    Latitude: "-19.970300"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "RITA ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.531100",
    Latitude: "-19.639600"
  },
  {
    Country: "Australia",
    Pcode: "4807",
    Locality: "SWANS LAGOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.159400",
    Latitude: "-20.072600"
  },
  {
    Country: "Australia",
    Pcode: "4808",
    Locality: "BRANDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.354800",
    Latitude: "-19.543100"
  },
  {
    Country: "Australia",
    Pcode: "4808",
    Locality: "COLEVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.355600",
    Latitude: "-19.458600"
  },
  {
    Country: "Australia",
    Pcode: "4809",
    Locality: "BARRATTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.224900",
    Latitude: "-19.571000"
  },
  {
    Country: "Australia",
    Pcode: "4809",
    Locality: "CROMARTY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.073200",
    Latitude: "-19.457300"
  },
  {
    Country: "Australia",
    Pcode: "4809",
    Locality: "GIRU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.122300",
    Latitude: "-19.504300"
  },
  {
    Country: "Australia",
    Pcode: "4809",
    Locality: "HORSESHOE LAGOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.134800",
    Latitude: "-19.558600"
  },
  {
    Country: "Australia",
    Pcode: "4809",
    Locality: "JERONA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.221500",
    Latitude: "-19.455600"
  },
  {
    Country: "Australia",
    Pcode: "4809",
    Locality: "MOUNT SURROUND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.052700",
    Latitude: "-19.530200"
  },
  {
    Country: "Australia",
    Pcode: "4809",
    Locality: "SHIRBOURNE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.067000",
    Latitude: "-19.569300"
  },
  {
    Country: "Australia",
    Pcode: "4809",
    Locality: "UPPER HAUGHTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.112000",
    Latitude: "-19.739000"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "BELGIAN GARDENS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.793000",
    Latitude: "-19.247300"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "CAPE CLEVELAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.011500",
    Latitude: "-19.309500"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "CASTLE HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.799900",
    Latitude: "-19.253400"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "NORTH WARD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.810000",
    Latitude: "-19.249800"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "PALLARENDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.770900",
    Latitude: "-19.198600"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "RAILWAY ESTATE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.818000",
    Latitude: "-19.274200"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "ROWES BAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.783100",
    Latitude: "-19.242100"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "SHELLY BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOWNSVILLE",
    Category: "Delivery Area",
    Longitude: "146.756800",
    Latitude: "-19.185000"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "SOUTH TOWNSVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.832400",
    Latitude: "-19.258800"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "TOWN COMMON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.744900",
    Latitude: "-19.215200"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "TOWNSVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "TOWNSVILLE CITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.817800",
    Latitude: "-19.259100"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "TOWNSVILLE DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "TOWNSVILLE MC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4810",
    Locality: "WEST END",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "TOWNSVILLE",
    Category: "Delivery Area",
    Longitude: "146.792300",
    Latitude: "-19.263600"
  },
  {
    Country: "Australia",
    Pcode: "4811",
    Locality: "CLUDEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.834500",
    Latitude: "-19.310700"
  },
  {
    Country: "Australia",
    Pcode: "4811",
    Locality: "IDALIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.812800",
    Latitude: "-19.305100"
  },
  {
    Country: "Australia",
    Pcode: "4811",
    Locality: "JAMES COOK UNIVERSITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4811",
    Locality: "MOUNT STUART",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.778100",
    Latitude: "-19.376600"
  },
  {
    Country: "Australia",
    Pcode: "4811",
    Locality: "OAK VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.819900",
    Latitude: "-19.406500"
  },
  {
    Country: "Australia",
    Pcode: "4811",
    Locality: "OONOONBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.814800",
    Latitude: "-19.288800"
  },
  {
    Country: "Australia",
    Pcode: "4811",
    Locality: "ROSENEATH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.821700",
    Latitude: "-19.355300"
  },
  {
    Country: "Australia",
    Pcode: "4811",
    Locality: "STUART",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.869700",
    Latitude: "-19.332400"
  },
  {
    Country: "Australia",
    Pcode: "4811",
    Locality: "WULGURU",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.817300",
    Latitude: "-19.327500"
  },
  {
    Country: "Australia",
    Pcode: "4812",
    Locality: "CURRAJONG",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.779000",
    Latitude: "-19.275500"
  },
  {
    Country: "Australia",
    Pcode: "4824",
    Locality: "GIDYA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.794000",
    Latitude: "-19.252200"
  },
  {
    Country: "Australia",
    Pcode: "4824",
    Locality: "KURIDALA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.393000",
    Latitude: "-21.102200"
  },
  {
    Country: "Australia",
    Pcode: "4824",
    Locality: "OORINDI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4824",
    Locality: "THREE RIVERS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.293400",
    Latitude: "-20.014300"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "ALPURRURULAM",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.836800",
    Latitude: "-20.983900"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "BARKLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.509100",
    Latitude: "-20.644800"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "BREAKAWAY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.531000",
    Latitude: "-20.727000"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "BUCKINGHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.381500",
    Latitude: "-22.124100"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "CARRANDOTTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.386600",
    Latitude: "-22.225600"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "DAJARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.584000",
    Latitude: "-21.931600"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "DUCHESS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.807000",
    Latitude: "-21.213600"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "FIELDING",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.348700",
    Latitude: "-18.786200"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "FISHER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.534400",
    Latitude: "-20.743900"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "GEORGINA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.513800",
    Latitude: "-22.440200"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "GUNPOWDER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.285100",
    Latitude: "-19.764900"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "HAPPY VALLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MOUNT ISA",
    Category: "Delivery Area",
    Longitude: "139.490600",
    Latitude: "-20.748600"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "HEALY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.500600",
    Latitude: "-20.748300"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "KALKADOON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.487500",
    Latitude: "-20.665900"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "LANSKEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.497600",
    Latitude: "-20.687000"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "LAWN HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.609700",
    Latitude: "-18.604200"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "MENZIES",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.497200",
    Latitude: "-20.717600"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "MICA CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.491900",
    Latitude: "-20.765200"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "MILES END",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.487900",
    Latitude: "-20.720500"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "MORNINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MOUNT ISA",
    Category: "Delivery Area",
    Longitude: "139.496100",
    Latitude: "-20.734200"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "MOUNT ISA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.407700",
    Latitude: "-20.494800"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "MOUNT ISA CITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.492800",
    Latitude: "-20.725200"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "MOUNT ISA DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "MOUNT ISA EAST",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "PARKSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MOUNT ISA",
    Category: "Delivery Area",
    Longitude: "139.489700",
    Latitude: "-20.736500"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "PIONEER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MOUNT ISA",
    Category: "Delivery Area",
    Longitude: "139.508300",
    Latitude: "-20.720300"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "PITURIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.360100",
    Latitude: "-21.498400"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "RANKEN",
    State: "Northern Territory",
    StateAbbrev: "NT",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.317000",
    Latitude: "-20.089500"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "RYAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.499300",
    Latitude: "-20.700800"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "SOLDIERS HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.490300",
    Latitude: "-20.705600"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "SPREADBOROUGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.499400",
    Latitude: "-20.761500"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "SUNSET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.511000",
    Latitude: "-20.713100"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "THE GAP",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "MOUNT ISA",
    Category: "Delivery Area",
    Longitude: "139.499300",
    Latitude: "-20.726300"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "THE MONUMENT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "TOWNVIEW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.506200",
    Latitude: "-20.731700"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "WAVERLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.993600",
    Latitude: "-21.420900"
  },
  {
    Country: "Australia",
    Pcode: "4825",
    Locality: "WINSTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.499300",
    Latitude: "-20.710900"
  },
  {
    Country: "Australia",
    Pcode: "4828",
    Locality: "CAMOOWEAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.493500",
    Latitude: "-19.706200"
  },
  {
    Country: "Australia",
    Pcode: "4829",
    Locality: "AMAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.395200",
    Latitude: "-23.160400"
  },
  {
    Country: "Australia",
    Pcode: "4829",
    Locality: "BEDOURIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.358000",
    Latitude: "-24.431200"
  },
  {
    Country: "Australia",
    Pcode: "4829",
    Locality: "BOULIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.921800",
    Latitude: "-22.877000"
  },
  {
    Country: "Australia",
    Pcode: "4829",
    Locality: "MIN MIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.722100",
    Latitude: "-22.917100"
  },
  {
    Country: "Australia",
    Pcode: "4829",
    Locality: "STURT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.321900",
    Latitude: "-23.382500"
  },
  {
    Country: "Australia",
    Pcode: "4829",
    Locality: "TOKO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.503700",
    Latitude: "-22.771600"
  },
  {
    Country: "Australia",
    Pcode: "4829",
    Locality: "WARENDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.236100",
    Latitude: "-22.420500"
  },
  {
    Country: "Australia",
    Pcode: "4829",
    Locality: "WILLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.909800",
    Latitude: "-23.036800"
  },
  {
    Country: "Australia",
    Pcode: "4830",
    Locality: "BURKETOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.595500",
    Latitude: "-17.882800"
  },
  {
    Country: "Australia",
    Pcode: "4830",
    Locality: "DOOMADGEE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.739700",
    Latitude: "-17.701100"
  },
  {
    Country: "Australia",
    Pcode: "4830",
    Locality: "GREGORY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "BURKE SHIRE",
    Category: "Delivery Area",
    Longitude: "139.331200",
    Latitude: "-18.518700"
  },
  {
    Country: "Australia",
    Pcode: "4830",
    Locality: "NICHOLSON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.768700",
    Latitude: "-17.463500"
  },
  {
    Country: "Australia",
    Pcode: "4849",
    Locality: "CARDWELL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.016300",
    Latitude: "-18.270600"
  },
  {
    Country: "Australia",
    Pcode: "4849",
    Locality: "DAMPER CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.101500",
    Latitude: "-18.370200"
  },
  {
    Country: "Australia",
    Pcode: "4849",
    Locality: "HINCHINBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.218500",
    Latitude: "-18.304600"
  },
  {
    Country: "Australia",
    Pcode: "4849",
    Locality: "LUMHOLTZ",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.900200",
    Latitude: "-18.289600"
  },
  {
    Country: "Australia",
    Pcode: "4849",
    Locality: "RUNGOO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.173800",
    Latitude: "-18.485800"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "ABERGOWRIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.924000",
    Latitude: "-18.438800"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "ALLINGHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "BAMBAROO",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.185000",
    Latitude: "-18.891600"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "BEMERSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.194300",
    Latitude: "-18.556800"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "BLACKROCK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.212400",
    Latitude: "-18.702400"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "BRAEMEADOWS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.263800",
    Latitude: "-18.649100"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "COOLBIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.272000",
    Latitude: "-18.902200"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "CORDELIA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.251100",
    Latitude: "-18.608500"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "DALRYMPLE CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.069300",
    Latitude: "-18.494300"
  },
  {
    Country: "Australia",
    Pcode: "4850",
    Locality: "FORESTHOME",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.200900",
    Latitude: "-18.613800"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "BARRON GORGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.664800",
    Latitude: "-16.856400"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "BRINSMEAD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.715800",
    Latitude: "-16.898600"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "BUNGALOW",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.757700",
    Latitude: "-16.936700"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "CAIRNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "CAIRNS CITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.776800",
    Latitude: "-16.920000"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "CAIRNS DC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "CAIRNS MC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "CAIRNS MCLEOD STREET",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "CAIRNS NORTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.769400",
    Latitude: "-16.904300"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "EARLVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.727000",
    Latitude: "-16.946200"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "EARLVILLE BC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "EDGE HILL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.744400",
    Latitude: "-16.900500"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "FRESHWATER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.714300",
    Latitude: "-16.883000"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "KAMERUNGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.698100",
    Latitude: "-16.879700"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "KANIMBLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.722400",
    Latitude: "-16.920600"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "LAMB RANGE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.689900",
    Latitude: "-17.037500"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "MANOORA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.736600",
    Latitude: "-16.916500"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "MANUNDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.749400",
    Latitude: "-16.916600"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "MARTYNVALE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "MOOROOBOOL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.730900",
    Latitude: "-16.934800"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "NORTH CAIRNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "PARRAMATTA PARK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.763700",
    Latitude: "-16.923900"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "PORTSMITH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.773700",
    Latitude: "-16.949800"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "REDLYNCH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.695700",
    Latitude: "-16.924100"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "STRATFORD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.728400",
    Latitude: "-16.878300"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "WESTCOURT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.753100",
    Latitude: "-16.929800"
  },
  {
    Country: "Australia",
    Pcode: "4870",
    Locality: "WHITFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.736100",
    Latitude: "-16.885900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "ALMADEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.649000",
    Latitude: "-17.410000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "ALOOMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.866300",
    Latitude: "-17.113100"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "AMBER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.244700",
    Latitude: "-17.658600"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "BASILISK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.996100",
    Latitude: "-17.669300"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "BELLENDEN KER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.928400",
    Latitude: "-17.274800"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "BLACKBULL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.731500",
    Latitude: "-17.863200"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "BOLWARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.013000",
    Latitude: "-17.413300"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "BOMBEETA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.962700",
    Latitude: "-17.682500"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "BOOGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.013200",
    Latitude: "-17.607000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "BRAMSTON BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.016500",
    Latitude: "-17.353300"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "BULLERINGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.889300",
    Latitude: "-17.613800"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "CAMP CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.952700",
    Latitude: "-17.616900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "CHILLAGOE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.669100",
    Latitude: "-17.103500"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "CLARAVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.678200",
    Latitude: "-18.548300"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "CONJUBOY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.605500",
    Latitude: "-18.708900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "CORALIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.825400",
    Latitude: "-18.204900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "COWLEY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.054900",
    Latitude: "-17.670900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "COWLEY BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.132600",
    Latitude: "-17.655100"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "COWLEY CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.033900",
    Latitude: "-17.693500"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "CROYDON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.232200",
    Latitude: "-18.116700"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "CRYSTALBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.378000",
    Latitude: "-17.433100"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "CURRAJAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.984000",
    Latitude: "-17.577000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "DEERAL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.964000",
    Latitude: "-17.203800"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "DESAILLY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.890800",
    Latitude: "-16.424400"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "EAST CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.695700",
    Latitude: "-19.099800"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "EAST TRINITY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.823000",
    Latitude: "-16.905900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "EINASLEIGH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.125800",
    Latitude: "-18.694600"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "ESMERALDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.522500",
    Latitude: "-18.823200"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "FISHERY FALLS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.891900",
    Latitude: "-17.180100"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "FITZROY ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.994000",
    Latitude: "-16.933600"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "FORSAYTH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.701700",
    Latitude: "-18.757000"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "FOSSILBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.327900",
    Latitude: "-17.857700"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "GEORGETOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.377400",
    Latitude: "-18.244600"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "GERMANTOWN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.967400",
    Latitude: "-17.640800"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "GILBERT RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.736800",
    Latitude: "-18.239900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "GLEN BOUGHTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.826700",
    Latitude: "-16.943300"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "GREEN ISLAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.974500",
    Latitude: "-16.758700"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "HURRICANE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.631500",
    Latitude: "-16.520900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "JULATTEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.359000",
    Latitude: "-16.577200"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "KARRON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.791800",
    Latitude: "-17.569900"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "KURRIMINE BEACH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.125200",
    Latitude: "-17.752400"
  },
  {
    Country: "Australia",
    Pcode: "4871",
    Locality: "LAKELAND",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.795100",
    Latitude: "-16.030900"
  },
  {
    Country: "Australia",
    Pcode: "4880",
    Locality: "PADDYS GREEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.281700",
    Latitude: "-16.909600"
  },
  {
    Country: "Australia",
    Pcode: "4881",
    Locality: "KOAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.534200",
    Latitude: "-16.901400"
  },
  {
    Country: "Australia",
    Pcode: "4881",
    Locality: "KURANDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.609400",
    Latitude: "-16.831300"
  },
  {
    Country: "Australia",
    Pcode: "4881",
    Locality: "MONA MONA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.556000",
    Latitude: "-16.732100"
  },
  {
    Country: "Australia",
    Pcode: "4881",
    Locality: "SPEEWAH",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.622900",
    Latitude: "-16.889100"
  },
  {
    Country: "Australia",
    Pcode: "4882",
    Locality: "TOLGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.463000",
    Latitude: "-17.184700"
  },
  {
    Country: "Australia",
    Pcode: "4883",
    Locality: "ATHERTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.481900",
    Latitude: "-17.270100"
  },
  {
    Country: "Australia",
    Pcode: "4883",
    Locality: "CARRINGTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.442400",
    Latitude: "-17.294000"
  },
  {
    Country: "Australia",
    Pcode: "4883",
    Locality: "EAST BARRON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.545400",
    Latitude: "-17.284200"
  },
  {
    Country: "Australia",
    Pcode: "4883",
    Locality: "UPPER BARRON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.508100",
    Latitude: "-17.385900"
  },
  {
    Country: "Australia",
    Pcode: "4883",
    Locality: "WONGABEL",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.465100",
    Latitude: "-17.332000"
  },
  {
    Country: "Australia",
    Pcode: "4884",
    Locality: "GADGARRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.721800",
    Latitude: "-17.256100"
  },
  {
    Country: "Australia",
    Pcode: "4884",
    Locality: "LAKE BARRINE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.646600",
    Latitude: "-17.245000"
  },
  {
    Country: "Australia",
    Pcode: "4884",
    Locality: "LAKE EACHAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.646200",
    Latitude: "-17.302200"
  },
  {
    Country: "Australia",
    Pcode: "4884",
    Locality: "YUNGABURRA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.600100",
    Latitude: "-17.274500"
  },
  {
    Country: "Australia",
    Pcode: "4885",
    Locality: "BUTCHERS CREEK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.699700",
    Latitude: "-17.338400"
  },
  {
    Country: "Australia",
    Pcode: "4885",
    Locality: "GLEN ALLYN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.672300",
    Latitude: "-17.376700"
  },
  {
    Country: "Australia",
    Pcode: "4885",
    Locality: "JAGGAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.584200",
    Latitude: "-17.406300"
  },
  {
    Country: "Australia",
    Pcode: "4885",
    Locality: "KUREEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.587400",
    Latitude: "-17.335600"
  },
  {
    Country: "Australia",
    Pcode: "4885",
    Locality: "MALANDA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.591500",
    Latitude: "-17.373300"
  },
  {
    Country: "Australia",
    Pcode: "4885",
    Locality: "NORTH JOHNSTONE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.663400",
    Latitude: "-17.332400"
  },
  {
    Country: "Australia",
    Pcode: "4885",
    Locality: "PEERAMON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.598800",
    Latitude: "-17.313100"
  },
  {
    Country: "Australia",
    Pcode: "4885",
    Locality: "TARZALI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.616200",
    Latitude: "-17.433700"
  },
  {
    Country: "Australia",
    Pcode: "4885",
    Locality: "TOPAZ",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.708800",
    Latitude: "-17.412700"
  },
  {
    Country: "Australia",
    Pcode: "4886",
    Locality: "BEATRICE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.579000",
    Latitude: "-17.560800"
  },
  {
    Country: "Australia",
    Pcode: "4886",
    Locality: "ELLINJAA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.663000",
    Latitude: "-17.507700"
  },
  {
    Country: "Australia",
    Pcode: "4886",
    Locality: "MAALAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.596900",
    Latitude: "-17.606500"
  },
  {
    Country: "Australia",
    Pcode: "4886",
    Locality: "MIDDLEBROOK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.639600",
    Latitude: "-17.562900"
  },
  {
    Country: "Australia",
    Pcode: "4886",
    Locality: "MILLAA MILLAA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.630400",
    Latitude: "-17.494100"
  },
  {
    Country: "Australia",
    Pcode: "4886",
    Locality: "MINBUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.522200",
    Latitude: "-17.476700"
  },
  {
    Country: "Australia",
    Pcode: "4886",
    Locality: "MOREGATTA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.585300",
    Latitude: "-17.490000"
  },
  {
    Country: "Australia",
    Pcode: "4886",
    Locality: "MUNGALLI",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.691900",
    Latitude: "-17.544100"
  },
  {
    Country: "Australia",
    Pcode: "4887",
    Locality: "HERBERTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.387000",
    Latitude: "-17.389900"
  },
  {
    Country: "Australia",
    Pcode: "4887",
    Locality: "IRVINEBANK",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.089700",
    Latitude: "-17.446000"
  },
  {
    Country: "Australia",
    Pcode: "4887",
    Locality: "KALUNGA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.387500",
    Latitude: "-17.437800"
  },
  {
    Country: "Australia",
    Pcode: "4887",
    Locality: "MOOMIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.418000",
    Latitude: "-17.357500"
  },
  {
    Country: "Australia",
    Pcode: "4887",
    Locality: "WATSONVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.335200",
    Latitude: "-17.318900"
  },
  {
    Country: "Australia",
    Pcode: "4887",
    Locality: "WONDECLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.430200",
    Latitude: "-17.429900"
  },
  {
    Country: "Australia",
    Pcode: "4888",
    Locality: "EVELYN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.492100",
    Latitude: "-17.517300"
  },
  {
    Country: "Australia",
    Pcode: "4888",
    Locality: "KABAN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.384100",
    Latitude: "-17.506800"
  },
  {
    Country: "Australia",
    Pcode: "4888",
    Locality: "MILLSTREAM",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.365200",
    Latitude: "-17.624800"
  },
  {
    Country: "Australia",
    Pcode: "4888",
    Locality: "RAVENSHOE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.506800",
    Latitude: "-17.620900"
  },
  {
    Country: "Australia",
    Pcode: "4888",
    Locality: "TUMOULIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.433600",
    Latitude: "-17.562500"
  },
  {
    Country: "Australia",
    Pcode: "4890",
    Locality: "HOWITT",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.280000",
    Latitude: "-17.059400"
  },
  {
    Country: "Australia",
    Pcode: "4890",
    Locality: "NORMANTON",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.921500",
    Latitude: "-17.905700"
  },
  {
    Country: "Australia",
    Pcode: "4891",
    Locality: "KARUMBA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.873000",
    Latitude: "-17.477700"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "ABINGDON DOWNS",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.458100",
    Latitude: "-17.811700"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "ARBOUIN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.013200",
    Latitude: "-17.086100"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "ARCHER RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.595300",
    Latitude: "-13.350700"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "AURUKUN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.156700",
    Latitude: "-12.575800"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "BELLEVUE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.263600",
    Latitude: "-16.633800"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "COEN",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.351000",
    Latitude: "-13.808900"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "DIXIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.242200",
    Latitude: "-15.379800"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "EDWARD RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.344600",
    Latitude: "-14.916000"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "GAMBOOLA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.491000",
    Latitude: "-16.424800"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "GROGANVILLE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.362100",
    Latitude: "-16.389500"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "GUNUNA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "HIGHBURY",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.001500",
    Latitude: "-16.322300"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "HOLROYD RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.457500",
    Latitude: "-14.208000"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "KOWANYAMA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "141.816700",
    Latitude: "-15.279100"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "LAKEFIELD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.223300",
    Latitude: "-14.700800"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "LAURA",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.075200",
    Latitude: "-15.448600"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "LIZARD",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.353700",
    Latitude: "-14.754400"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "LOCKHART",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "LOCKHART RIVER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.304400",
    Latitude: "-13.044100"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "LYNDSIDE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.543900",
    Latitude: "-16.915300"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "MARAMIE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "142.359900",
    Latitude: "-16.116100"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "MOUNT MULGRAVE",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.032000",
    Latitude: "-16.285600"
  },
  {
    Country: "Australia",
    Pcode: "4892",
    Locality: "PALMER",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "143.524300",
    Latitude: "-15.978800"
  },
  {
    Country: "Australia",
    Pcode: "5038",
    Locality: "PLYMPTON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.551300",
    Latitude: "-34.975800"
  },
  {
    Country: "Australia",
    Pcode: "5038",
    Locality: "SOUTH PLYMPTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.560000",
    Latitude: "-34.973500"
  },
  {
    Country: "Australia",
    Pcode: "5039",
    Locality: "CLARENCE GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.577700",
    Latitude: "-34.972400"
  },
  {
    Country: "Australia",
    Pcode: "5039",
    Locality: "EDWARDSTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.569400",
    Latitude: "-34.980200"
  },
  {
    Country: "Australia",
    Pcode: "5039",
    Locality: "MELROSE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.578600",
    Latitude: "-34.985200"
  },
  {
    Country: "Australia",
    Pcode: "5039",
    Locality: "MELROSE PARK DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5040",
    Locality: "NOVAR GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.529600",
    Latitude: "-34.966100"
  },
  {
    Country: "Australia",
    Pcode: "5041",
    Locality: "COLONEL LIGHT GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.592000",
    Latitude: "-34.983700"
  },
  {
    Country: "Australia",
    Pcode: "5041",
    Locality: "CUMBERLAND PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.586700",
    Latitude: "-34.972000"
  },
  {
    Country: "Australia",
    Pcode: "5041",
    Locality: "DAW PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.587300",
    Latitude: "-34.980000"
  },
  {
    Country: "Australia",
    Pcode: "5041",
    Locality: "PANORAMA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.598000",
    Latitude: "-35.001400"
  },
  {
    Country: "Australia",
    Pcode: "5041",
    Locality: "WESTBOURNE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.595300",
    Latitude: "-34.971500"
  },
  {
    Country: "Australia",
    Pcode: "5042",
    Locality: "BEDFORD PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.568100",
    Latitude: "-35.022300"
  },
  {
    Country: "Australia",
    Pcode: "5042",
    Locality: "CLOVELLY PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.570700",
    Latitude: "-34.998800"
  },
  {
    Country: "Australia",
    Pcode: "5042",
    Locality: "FLINDERS UNIVERSITY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "BEDFORD PARK",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5042",
    Locality: "PASADENA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.588100",
    Latitude: "-35.003200"
  },
  {
    Country: "Australia",
    Pcode: "5042",
    Locality: "ST MARYS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.580100",
    Latitude: "-35.003600"
  },
  {
    Country: "Australia",
    Pcode: "5042",
    Locality: "TONSLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.570600",
    Latitude: "-35.010200"
  },
  {
    Country: "Australia",
    Pcode: "5043",
    Locality: "ASCOT PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.561000",
    Latitude: "-34.988000"
  },
  {
    Country: "Australia",
    Pcode: "5043",
    Locality: "MARION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.553500",
    Latitude: "-35.006200"
  },
  {
    Country: "Australia",
    Pcode: "5043",
    Locality: "MITCHELL PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.562200",
    Latitude: "-35.005200"
  },
  {
    Country: "Australia",
    Pcode: "5043",
    Locality: "MORPHETTVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.542900",
    Latitude: "-34.984500"
  },
  {
    Country: "Australia",
    Pcode: "5043",
    Locality: "PARK HOLME",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.552100",
    Latitude: "-34.988900"
  },
  {
    Country: "Australia",
    Pcode: "5044",
    Locality: "GLENGOWRIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.531500",
    Latitude: "-34.987700"
  },
  {
    Country: "Australia",
    Pcode: "5044",
    Locality: "SOMERTON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.519800",
    Latitude: "-34.996400"
  },
  {
    Country: "Australia",
    Pcode: "5045",
    Locality: "GLENELG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.514800",
    Latitude: "-34.979700"
  },
  {
    Country: "Australia",
    Pcode: "5045",
    Locality: "GLENELG EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.528800",
    Latitude: "-34.981400"
  },
  {
    Country: "Australia",
    Pcode: "5045",
    Locality: "GLENELG JETTY ROAD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5045",
    Locality: "GLENELG NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.518400",
    Latitude: "-34.967500"
  },
  {
    Country: "Australia",
    Pcode: "5045",
    Locality: "GLENELG SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.514600",
    Latitude: "-34.988200"
  },
  {
    Country: "Australia",
    Pcode: "5046",
    Locality: "OAKLANDS PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.544500",
    Latitude: "-35.006700"
  },
  {
    Country: "Australia",
    Pcode: "5046",
    Locality: "WARRADALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.535400",
    Latitude: "-35.007400"
  },
  {
    Country: "Australia",
    Pcode: "5046",
    Locality: "WARRADALE NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5047",
    Locality: "DARLINGTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.555100",
    Latitude: "-35.033800"
  },
  {
    Country: "Australia",
    Pcode: "5047",
    Locality: "SEACOMBE GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.545800",
    Latitude: "-35.023300"
  },
  {
    Country: "Australia",
    Pcode: "5047",
    Locality: "SEACOMBE HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.546900",
    Latitude: "-35.034000"
  },
  {
    Country: "Australia",
    Pcode: "5047",
    Locality: "STURT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.554600",
    Latitude: "-35.022900"
  },
  {
    Country: "Australia",
    Pcode: "5048",
    Locality: "BRIGHTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.522700",
    Latitude: "-35.017900"
  },
  {
    Country: "Australia",
    Pcode: "5048",
    Locality: "DOVER GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.536900",
    Latitude: "-35.024000"
  },
  {
    Country: "Australia",
    Pcode: "5048",
    Locality: "HOVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.521600",
    Latitude: "-35.011600"
  },
  {
    Country: "Australia",
    Pcode: "5048",
    Locality: "NORTH BRIGHTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.520700",
    Latitude: "-35.005000"
  },
  {
    Country: "Australia",
    Pcode: "5048",
    Locality: "SOUTH BRIGHTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.524200",
    Latitude: "-35.026400"
  },
  {
    Country: "Australia",
    Pcode: "5049",
    Locality: "KINGSTON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.518000",
    Latitude: "-35.038600"
  },
  {
    Country: "Australia",
    Pcode: "5049",
    Locality: "MARINO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.515300",
    Latitude: "-35.050000"
  },
  {
    Country: "Australia",
    Pcode: "5049",
    Locality: "SEACLIFF",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.520300",
    Latitude: "-35.031400"
  },
  {
    Country: "Australia",
    Pcode: "5049",
    Locality: "SEACLIFF PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.529400",
    Latitude: "-35.042600"
  },
  {
    Country: "Australia",
    Pcode: "5049",
    Locality: "SEAVIEW DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.538300",
    Latitude: "-35.042100"
  },
  {
    Country: "Australia",
    Pcode: "5050",
    Locality: "BELLEVUE HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.583000",
    Latitude: "-35.029100"
  },
  {
    Country: "Australia",
    Pcode: "5050",
    Locality: "EDEN HILLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.595700",
    Latitude: "-35.020700"
  },
  {
    Country: "Australia",
    Pcode: "5051",
    Locality: "BLACKWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.610600",
    Latitude: "-35.021500"
  },
  {
    Country: "Australia",
    Pcode: "5051",
    Locality: "COROMANDEL VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.623200",
    Latitude: "-35.044300"
  },
  {
    Country: "Australia",
    Pcode: "5051",
    Locality: "CRAIGBURN FARM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.603600",
    Latitude: "-35.041400"
  },
  {
    Country: "Australia",
    Pcode: "5051",
    Locality: "HAWTHORNDENE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.637300",
    Latitude: "-35.026100"
  },
  {
    Country: "Australia",
    Pcode: "5052",
    Locality: "BELAIR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.655400",
    Latitude: "-35.007400"
  },
  {
    Country: "Australia",
    Pcode: "5052",
    Locality: "GLENALTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.628600",
    Latitude: "-35.011400"
  },
  {
    Country: "Australia",
    Pcode: "5061",
    Locality: "HYDE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.602900",
    Latitude: "-34.956600"
  },
  {
    Country: "Australia",
    Pcode: "5061",
    Locality: "MALVERN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.612600",
    Latitude: "-34.959100"
  },
  {
    Country: "Australia",
    Pcode: "5061",
    Locality: "UNLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.607800",
    Latitude: "-34.947200"
  },
  {
    Country: "Australia",
    Pcode: "5061",
    Locality: "UNLEY BC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5061",
    Locality: "UNLEY DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5061",
    Locality: "UNLEY PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.601200",
    Latitude: "-34.962600"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "BROWN HILL CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.645500",
    Latitude: "-34.984000"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "CLAPHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.605600",
    Latitude: "-34.990300"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "HAWTHORN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.603300",
    Latitude: "-34.971000"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "KINGSWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.613500",
    Latitude: "-34.971000"
  },
  {
    Country: "Australia",
    Pcode: "5062",
    Locality: "LOWER MITCHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.605900",
    Latitude: "-34.981600"
  },
  {
    Country: "Australia",
    Pcode: "5098",
    Locality: "WALKLEY HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.636500",
    Latitude: "-34.840400"
  },
  {
    Country: "Australia",
    Pcode: "5106",
    Locality: "PARAFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.631400",
    Latitude: "-34.795700"
  },
  {
    Country: "Australia",
    Pcode: "5106",
    Locality: "SALISBURY SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.642900",
    Latitude: "-34.782700"
  },
  {
    Country: "Australia",
    Pcode: "5106",
    Locality: "SALISBURY SOUTH BC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5106",
    Locality: "SALISBURY SOUTH DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5107",
    Locality: "GREEN FIELDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.601900",
    Latitude: "-34.794200"
  },
  {
    Country: "Australia",
    Pcode: "5107",
    Locality: "PARAFIELD GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.612900",
    Latitude: "-34.783600"
  },
  {
    Country: "Australia",
    Pcode: "5108",
    Locality: "PARALOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.611900",
    Latitude: "-34.757100"
  },
  {
    Country: "Australia",
    Pcode: "5108",
    Locality: "SALISBURY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.638900",
    Latitude: "-34.763900"
  },
  {
    Country: "Australia",
    Pcode: "5108",
    Locality: "SALISBURY DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.625700",
    Latitude: "-34.772200"
  },
  {
    Country: "Australia",
    Pcode: "5108",
    Locality: "SALISBURY NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.623200",
    Latitude: "-34.744200"
  },
  {
    Country: "Australia",
    Pcode: "5108",
    Locality: "SALISBURY NORTH WHITES ROAD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5109",
    Locality: "BRAHMA LODGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.653000",
    Latitude: "-34.775900"
  },
  {
    Country: "Australia",
    Pcode: "5109",
    Locality: "SALISBURY EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.685900",
    Latitude: "-34.782800"
  },
  {
    Country: "Australia",
    Pcode: "5109",
    Locality: "SALISBURY EAST NORTHBRI AVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5109",
    Locality: "SALISBURY HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.682100",
    Latitude: "-34.763500"
  },
  {
    Country: "Australia",
    Pcode: "5109",
    Locality: "SALISBURY PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.666700",
    Latitude: "-34.759200"
  },
  {
    Country: "Australia",
    Pcode: "5109",
    Locality: "SALISBURY PLAIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.660600",
    Latitude: "-34.767400"
  },
  {
    Country: "Australia",
    Pcode: "5110",
    Locality: "BOLIVAR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.584200",
    Latitude: "-34.765900"
  },
  {
    Country: "Australia",
    Pcode: "5110",
    Locality: "BURTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.600500",
    Latitude: "-34.737200"
  },
  {
    Country: "Australia",
    Pcode: "5110",
    Locality: "DIREK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.606800",
    Latitude: "-34.722900"
  },
  {
    Country: "Australia",
    Pcode: "5110",
    Locality: "GLOBE DERBY PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.587300",
    Latitude: "-34.785600"
  },
  {
    Country: "Australia",
    Pcode: "5110",
    Locality: "ST KILDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.548000",
    Latitude: "-34.745300"
  },
  {
    Country: "Australia",
    Pcode: "5110",
    Locality: "WATERLOO CORNER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.570600",
    Latitude: "-34.715200"
  },
  {
    Country: "Australia",
    Pcode: "5111",
    Locality: "EDINBURGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.638000",
    Latitude: "-34.721400"
  },
  {
    Country: "Australia",
    Pcode: "5111",
    Locality: "EDINBURGH RAAF",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5112",
    Locality: "ELIZABETH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.668400",
    Latitude: "-34.720000"
  },
  {
    Country: "Australia",
    Pcode: "5112",
    Locality: "ELIZABETH EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.683800",
    Latitude: "-34.725200"
  },
  {
    Country: "Australia",
    Pcode: "5112",
    Locality: "ELIZABETH GROVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.669100",
    Latitude: "-34.735500"
  },
  {
    Country: "Australia",
    Pcode: "5112",
    Locality: "ELIZABETH SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.656300",
    Latitude: "-34.741000"
  },
  {
    Country: "Australia",
    Pcode: "5112",
    Locality: "ELIZABETH VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.669400",
    Latitude: "-34.748900"
  },
  {
    Country: "Australia",
    Pcode: "5112",
    Locality: "HILLBANK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.690000",
    Latitude: "-34.728000"
  },
  {
    Country: "Australia",
    Pcode: "5113",
    Locality: "DAVOREN PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.667700",
    Latitude: "-34.690500"
  },
  {
    Country: "Australia",
    Pcode: "5113",
    Locality: "DAVOREN PARK SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5113",
    Locality: "EDINBURGH NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.657400",
    Latitude: "-34.704400"
  },
  {
    Country: "Australia",
    Pcode: "5113",
    Locality: "ELIZABETH DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.694800",
    Latitude: "-34.698100"
  },
  {
    Country: "Australia",
    Pcode: "5113",
    Locality: "ELIZABETH NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.677700",
    Latitude: "-34.699900"
  },
  {
    Country: "Australia",
    Pcode: "5113",
    Locality: "ELIZABETH PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.686300",
    Latitude: "-34.711800"
  },
  {
    Country: "Australia",
    Pcode: "5113",
    Locality: "ELIZABETH WEST DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "ANDREWS FARM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.659100",
    Latitude: "-34.675100"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "BLAKEVIEW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.708400",
    Latitude: "-34.671600"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "CRAIGMORE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.708600",
    Latitude: "-34.699400"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "GOULD CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.749400",
    Latitude: "-34.745800"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "HUMBUG SCRUB",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.810800",
    Latitude: "-34.717800"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "ONE TREE HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.743400",
    Latitude: "-34.719200"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "SAMPSON FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.791700",
    Latitude: "-34.747000"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "SMITHFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.686700",
    Latitude: "-34.684700"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "SMITHFIELD PLAINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.676700",
    Latitude: "-34.676700"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "SMITHFIELD WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "ULEYBURY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.746900",
    Latitude: "-34.674700"
  },
  {
    Country: "Australia",
    Pcode: "5114",
    Locality: "YATTALUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.806800",
    Latitude: "-34.674900"
  },
  {
    Country: "Australia",
    Pcode: "5115",
    Locality: "KUDLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.705700",
    Latitude: "-34.642700"
  },
  {
    Country: "Australia",
    Pcode: "5115",
    Locality: "MUNNO PARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.689700",
    Latitude: "-34.665700"
  },
  {
    Country: "Australia",
    Pcode: "5115",
    Locality: "MUNNO PARA DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.684400",
    Latitude: "-34.648000"
  },
  {
    Country: "Australia",
    Pcode: "5115",
    Locality: "MUNNO PARA WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.669300",
    Latitude: "-34.657400"
  },
  {
    Country: "Australia",
    Pcode: "5116",
    Locality: "EVANSTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.733600",
    Latitude: "-34.618100"
  },
  {
    Country: "Australia",
    Pcode: "5116",
    Locality: "EVANSTON GARDENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.717500",
    Latitude: "-34.628700"
  },
  {
    Country: "Australia",
    Pcode: "5116",
    Locality: "EVANSTON PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.748200",
    Latitude: "-34.629600"
  },
  {
    Country: "Australia",
    Pcode: "5116",
    Locality: "EVANSTON SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.726100",
    Latitude: "-34.651700"
  },
  {
    Country: "Australia",
    Pcode: "5116",
    Locality: "HILLIER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.692100",
    Latitude: "-34.623400"
  },
  {
    Country: "Australia",
    Pcode: "5117",
    Locality: "ANGLE VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.646300",
    Latitude: "-34.637300"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "BIBARINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.765900",
    Latitude: "-34.640400"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "BUCHFELDE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.707300",
    Latitude: "-34.607800"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "CONCORDIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.790700",
    Latitude: "-34.576400"
  },
  {
    Country: "Australia",
    Pcode: "5118",
    Locality: "GAWLER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.744300",
    Latitude: "-34.598500"
  },
  {
    Country: "Australia",
    Pcode: "5201",
    Locality: "PARIS CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.804800",
    Latitude: "-35.221100"
  },
  {
    Country: "Australia",
    Pcode: "5201",
    Locality: "PROSPECT HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.728200",
    Latitude: "-35.226900"
  },
  {
    Country: "Australia",
    Pcode: "5202",
    Locality: "HINDMARSH TIERS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.558200",
    Latitude: "-35.415400"
  },
  {
    Country: "Australia",
    Pcode: "5202",
    Locality: "MYPONGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.459900",
    Latitude: "-35.402800"
  },
  {
    Country: "Australia",
    Pcode: "5202",
    Locality: "MYPONGA BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.397100",
    Latitude: "-35.382700"
  },
  {
    Country: "Australia",
    Pcode: "5203",
    Locality: "BALD HILLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.397000",
    Latitude: "-35.489300"
  },
  {
    Country: "Australia",
    Pcode: "5203",
    Locality: "PARAWA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.336500",
    Latitude: "-35.559800"
  },
  {
    Country: "Australia",
    Pcode: "5203",
    Locality: "TORRENS VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.396200",
    Latitude: "-35.522400"
  },
  {
    Country: "Australia",
    Pcode: "5203",
    Locality: "TUNKALILLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.338600",
    Latitude: "-35.614800"
  },
  {
    Country: "Australia",
    Pcode: "7216",
    Locality: "STIEGLITZ",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.299200",
    Latitude: "-41.333100"
  },
  {
    Country: "Australia",
    Pcode: "7216",
    Locality: "THE GARDENS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.241600",
    Latitude: "-41.156600"
  },
  {
    Country: "Australia",
    Pcode: "7248",
    Locality: "INVERMAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.130600",
    Latitude: "-41.421800"
  },
  {
    Country: "Australia",
    Pcode: "7248",
    Locality: "MAYFIELD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.135000",
    Latitude: "-41.387000"
  },
  {
    Country: "Australia",
    Pcode: "7248",
    Locality: "MOWBRAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.150300",
    Latitude: "-41.404000"
  },
  {
    Country: "Australia",
    Pcode: "7248",
    Locality: "NEWNHAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.114200",
    Latitude: "-41.389600"
  },
  {
    Country: "Australia",
    Pcode: "7248",
    Locality: "ROCHERLEA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.161500",
    Latitude: "-41.375900"
  },
  {
    Country: "Australia",
    Pcode: "7249",
    Locality: "KINGS MEADOWS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.162500",
    Latitude: "-41.470400"
  },
  {
    Country: "Australia",
    Pcode: "7249",
    Locality: "PUNCHBOWL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.165000",
    Latitude: "-41.457600"
  },
  {
    Country: "Australia",
    Pcode: "7249",
    Locality: "SOUTH LAUNCESTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.147700",
    Latitude: "-41.456500"
  },
  {
    Country: "Australia",
    Pcode: "7249",
    Locality: "YOUNGTOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.172200",
    Latitude: "-41.487800"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "BLACKSTONE HEIGHTS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.083100",
    Latitude: "-41.462800"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "EAST LAUNCESTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.150700",
    Latitude: "-41.439600"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "LAUNCESTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.143800",
    Latitude: "-41.427500"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "NEWSTEAD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.162900",
    Latitude: "-41.440800"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "NORWOOD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.181300",
    Latitude: "-41.459400"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "PROSPECT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.143500",
    Latitude: "-41.485700"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "PROSPECT VALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.113500",
    Latitude: "-41.483700"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "RAVENSWOOD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.184000",
    Latitude: "-41.416500"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "RIVERSIDE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.044300",
    Latitude: "-41.423100"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "ST LEONARDS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.237700",
    Latitude: "-41.464400"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "SUMMERHILL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.119300",
    Latitude: "-41.466300"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "TRAVELLERS REST",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.094100",
    Latitude: "-41.495700"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "TREVALLYN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.100500",
    Latitude: "-41.442500"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "WAVERLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.212200",
    Latitude: "-41.423400"
  },
  {
    Country: "Australia",
    Pcode: "7250",
    Locality: "WEST LAUNCESTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.125800",
    Latitude: "-41.452600"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "BEECHFORD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.938100",
    Latitude: "-41.041700"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "DILSTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.066900",
    Latitude: "-41.324300"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "HILLWOOD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.968900",
    Latitude: "-41.219600"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "LEFROY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.983900",
    Latitude: "-41.085100"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "LULWORTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.082000",
    Latitude: "-41.002500"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "MOUNT DIRECTION",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.029900",
    Latitude: "-41.229200"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "PIPERS RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.062100",
    Latitude: "-41.109500"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "STONY HEAD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.017500",
    Latitude: "-41.018200"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "SWAN BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.013600",
    Latitude: "-41.292900"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "WEYMOUTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.106300",
    Latitude: "-41.028400"
  },
  {
    Country: "Australia",
    Pcode: "7252",
    Locality: "WINDERMERE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "HILLWOOD",
    Category: "Delivery Area",
    Longitude: "147.016500",
    Latitude: "-41.310600"
  },
  {
    Country: "Australia",
    Pcode: "7253",
    Locality: "BELL BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.865500",
    Latitude: "-41.127000"
  },
  {
    Country: "Australia",
    Pcode: "7253",
    Locality: "GEORGE TOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.884500",
    Latitude: "-41.091100"
  },
  {
    Country: "Australia",
    Pcode: "7253",
    Locality: "LONG REACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.948300",
    Latitude: "-41.165900"
  },
  {
    Country: "Australia",
    Pcode: "7253",
    Locality: "LOW HEAD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.842600",
    Latitude: "-41.055100"
  },
  {
    Country: "Australia",
    Pcode: "7254",
    Locality: "BELLINGHAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.177700",
    Latitude: "-41.021500"
  },
  {
    Country: "Australia",
    Pcode: "7254",
    Locality: "GOLCONDA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.289600",
    Latitude: "-41.179000"
  },
  {
    Country: "Australia",
    Pcode: "7254",
    Locality: "LEBRINA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.225000",
    Latitude: "-41.164600"
  },
  {
    Country: "Australia",
    Pcode: "7254",
    Locality: "PIPERS BROOK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.207700",
    Latitude: "-41.074600"
  },
  {
    Country: "Australia",
    Pcode: "7254",
    Locality: "RETREAT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.169800",
    Latitude: "-41.137000"
  },
  {
    Country: "Australia",
    Pcode: "7254",
    Locality: "TUNNEL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.179200",
    Latitude: "-41.192800"
  },
  {
    Country: "Australia",
    Pcode: "7254",
    Locality: "WYENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.272400",
    Latitude: "-41.187200"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "BLUE ROCKS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.948900",
    Latitude: "-40.043000"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "EMITA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.937300",
    Latitude: "-40.005700"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "KILLIECRANKIE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.844000",
    Latitude: "-39.843300"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "LACKRANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.190800",
    Latitude: "-40.099800"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "LADY BARRON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.294500",
    Latitude: "-40.216500"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "LEEKA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.816200",
    Latitude: "-39.898100"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "LOCCOTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.047900",
    Latitude: "-40.206300"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "LUGHRATA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.920300",
    Latitude: "-39.947600"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "MEMANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.100900",
    Latitude: "-39.960100"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "PALANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.972600",
    Latitude: "-39.751200"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "RANGA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.114300",
    Latitude: "-40.161800"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "STRZELECKI",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.107100",
    Latitude: "-40.219200"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "WHITEMARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.021400",
    Latitude: "-40.082500"
  },
  {
    Country: "Australia",
    Pcode: "7255",
    Locality: "WINGAROO",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.986600",
    Latitude: "-39.841900"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "BUNGAREE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.942300",
    Latitude: "-39.786400"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "CURRIE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.852100",
    Latitude: "-39.922800"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "EGG LAGOON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "144.010100",
    Latitude: "-39.659100"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "GRASSY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "144.067400",
    Latitude: "-40.047900"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "LOORANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.918500",
    Latitude: "-39.867900"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "LYMWOOD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.997300",
    Latitude: "-40.037900"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "NARACOOPA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "144.118900",
    Latitude: "-39.922700"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "ABBOTSHAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.181000",
    Latitude: "-41.210700"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "CASTRA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.115900",
    Latitude: "-41.300400"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "GAWLER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.139000",
    Latitude: "-41.243300"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "GUNNS PLAINS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.999700",
    Latitude: "-41.291900"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "LEITH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.265000",
    Latitude: "-41.166400"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "LOONGANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.924800",
    Latitude: "-41.405100"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "NIETTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.067500",
    Latitude: "-41.382200"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "NORTH MOTTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.092300",
    Latitude: "-41.207500"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "PRESTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.065700",
    Latitude: "-41.294000"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "SOUTH NIETTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.042000",
    Latitude: "-41.447600"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "SOUTH PRESTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.025800",
    Latitude: "-41.345900"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "SPALFORD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.186000",
    Latitude: "-41.238800"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "SPRENT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.166900",
    Latitude: "-41.281400"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "TURNERS BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.229100",
    Latitude: "-41.170500"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "ULVERSTONE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.185300",
    Latitude: "-41.178200"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "UPPER CASTRA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.130500",
    Latitude: "-41.332600"
  },
  {
    Country: "Australia",
    Pcode: "7315",
    Locality: "WEST ULVERSTONE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.130000",
    Latitude: "-41.146700"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "CAMENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.964500",
    Latitude: "-41.175800"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "CUPRONA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.969200",
    Latitude: "-41.126200"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "HEYBRIDGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.977200",
    Latitude: "-41.085000"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "HOWTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.003500",
    Latitude: "-41.093500"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "LOYETEA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.909900",
    Latitude: "-41.308200"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "PENGUIN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.061000",
    Latitude: "-41.139200"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "PRESERVATION BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.046600",
    Latitude: "-41.104400"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "RIANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.987300",
    Latitude: "-41.207600"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "SOUTH RIANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.977000",
    Latitude: "-41.254900"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "SULPHUR CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.015100",
    Latitude: "-41.108000"
  },
  {
    Country: "Australia",
    Pcode: "7316",
    Locality: "WEST PINE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.997800",
    Latitude: "-41.147700"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "ACTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "BURNIE",
    Category: "Delivery Area",
    Longitude: "145.893000",
    Latitude: "-41.072500"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "BROOKLYN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.906700",
    Latitude: "-41.072000"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "BURNIE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.904400",
    Latitude: "-41.054100"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "CAMDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.848600",
    Latitude: "-41.042200"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "COOEE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.873200",
    Latitude: "-41.044000"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "DOWNLANDS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.890100",
    Latitude: "-41.082700"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "EMU HEIGHTS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.920800",
    Latitude: "-41.075300"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "HAVENVIEW",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.911400",
    Latitude: "-41.085900"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "HILLCREST",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.894700",
    Latitude: "-41.062800"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "MONTELLO",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.895700",
    Latitude: "-41.057200"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "OCEAN VISTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.859700",
    Latitude: "-41.044400"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "PARK GROVE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.884300",
    Latitude: "-41.054800"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "PARKLANDS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.891200",
    Latitude: "-41.048100"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "ROMAINE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.890700",
    Latitude: "-41.094900"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "ROUND HILL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.946500",
    Latitude: "-41.071400"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "SHOREWELL PARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.875900",
    Latitude: "-41.069300"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "SOUTH BURNIE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.913500",
    Latitude: "-41.065800"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "UPPER BURNIE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.899600",
    Latitude: "-41.070700"
  },
  {
    Country: "Australia",
    Pcode: "7320",
    Locality: "WIVENHOE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.932200",
    Latitude: "-41.073200"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "BLACK RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.326500",
    Latitude: "-40.873900"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "BOAT HARBOUR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.628600",
    Latitude: "-40.946500"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "BOAT HARBOUR BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.616300",
    Latitude: "-40.929300"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "CHASM CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.965500",
    Latitude: "-41.069800"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "CORINNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.079700",
    Latitude: "-41.627500"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "COWRIE POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.364500",
    Latitude: "-40.856300"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "CRAYFISH CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.399400",
    Latitude: "-40.864700"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "DETENTION",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.452400",
    Latitude: "-40.879700"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "EAST CAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.853000",
    Latitude: "-41.059700"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "EAST RIDGLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.858800",
    Latitude: "-41.152300"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "EDGCUMBE BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.414600",
    Latitude: "-40.865800"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "GUILDFORD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.712200",
    Latitude: "-41.468100"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "HAMPSHIRE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.753300",
    Latitude: "-41.292800"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "HELLYER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.427600",
    Latitude: "-40.881400"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "HIGHCLERE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.822500",
    Latitude: "-41.195500"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "LUINA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.376800",
    Latitude: "-41.461500"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "MAWBANNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.398100",
    Latitude: "-40.988100"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "MONTUMANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.484900",
    Latitude: "-40.950900"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "MOOREVILLE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.874400",
    Latitude: "-41.099600"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "NATONE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.931000",
    Latitude: "-41.171200"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "PARRAWE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.566600",
    Latitude: "-41.285100"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "PORT LATTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.387500",
    Latitude: "-40.857300"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "RIDGLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.833400",
    Latitude: "-41.141500"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "BLACK HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.487700",
    Latitude: "-34.689700"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "CAMBRAI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.334100",
    Latitude: "-34.684000"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "KEYNETON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.162400",
    Latitude: "-34.553400"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "KONGOLIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "LANGS LANDING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.590200",
    Latitude: "-34.587000"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "MOCULTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.124600",
    Latitude: "-34.470600"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "MOUNT MCKENZIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.092700",
    Latitude: "-34.569200"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "PENRICE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.070800",
    Latitude: "-34.476100"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "PUNYELROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.596300",
    Latitude: "-34.606100"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "SEDAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.318700",
    Latitude: "-34.537400"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "SUNNYDALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.612200",
    Latitude: "-34.655700"
  },
  {
    Country: "Australia",
    Pcode: "5353",
    Locality: "TOWITTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.241600",
    Latitude: "-34.528500"
  },
  {
    Country: "Australia",
    Pcode: "5354",
    Locality: "BAKARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.819000",
    Latitude: "-34.675300"
  },
  {
    Country: "Australia",
    Pcode: "5354",
    Locality: "BAKARA WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.838000",
    Latitude: "-34.570700"
  },
  {
    Country: "Australia",
    Pcode: "5354",
    Locality: "FISHER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.503400",
    Latitude: "-34.518100"
  },
  {
    Country: "Australia",
    Pcode: "5354",
    Locality: "MARKS LANDING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.587900",
    Latitude: "-34.579800"
  },
  {
    Country: "Australia",
    Pcode: "5354",
    Locality: "NAIDIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.838000",
    Latitude: "-34.512000"
  },
  {
    Country: "Australia",
    Pcode: "5354",
    Locality: "SWAN REACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.640900",
    Latitude: "-34.568400"
  },
  {
    Country: "Australia",
    Pcode: "5355",
    Locality: "DAVEYSTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.877300",
    Latitude: "-34.474100"
  },
  {
    Country: "Australia",
    Pcode: "5355",
    Locality: "EBENEZER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.015000",
    Latitude: "-34.416000"
  },
  {
    Country: "Australia",
    Pcode: "5355",
    Locality: "LIGHT PASS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.030100",
    Latitude: "-34.470300"
  },
  {
    Country: "Australia",
    Pcode: "5355",
    Locality: "MARANANGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.938100",
    Latitude: "-34.489200"
  },
  {
    Country: "Australia",
    Pcode: "5355",
    Locality: "MOPPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.953600",
    Latitude: "-34.414000"
  },
  {
    Country: "Australia",
    Pcode: "5355",
    Locality: "NURIOOTPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.978400",
    Latitude: "-34.466400"
  },
  {
    Country: "Australia",
    Pcode: "5355",
    Locality: "SEPPELTSFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.903200",
    Latitude: "-34.496100"
  },
  {
    Country: "Australia",
    Pcode: "5355",
    Locality: "STOCKWELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.063100",
    Latitude: "-34.438500"
  },
  {
    Country: "Australia",
    Pcode: "5356",
    Locality: "ANNADALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.364100",
    Latitude: "-34.408400"
  },
  {
    Country: "Australia",
    Pcode: "5356",
    Locality: "DUTTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.156300",
    Latitude: "-34.356500"
  },
  {
    Country: "Australia",
    Pcode: "5356",
    Locality: "DUTTON EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.240600",
    Latitude: "-34.352600"
  },
  {
    Country: "Australia",
    Pcode: "5356",
    Locality: "SANDLETON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.390700",
    Latitude: "-34.438800"
  },
  {
    Country: "Australia",
    Pcode: "5356",
    Locality: "ST KITTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.083500",
    Latitude: "-34.355500"
  },
  {
    Country: "Australia",
    Pcode: "5356",
    Locality: "STEINFELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.351100",
    Latitude: "-34.343400"
  },
  {
    Country: "Australia",
    Pcode: "5356",
    Locality: "STONEFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5356",
    Locality: "TRURO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.217700",
    Latitude: "-34.433200"
  },
  {
    Country: "Australia",
    Pcode: "5357",
    Locality: "BLANCHETOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.541600",
    Latitude: "-34.318000"
  },
  {
    Country: "Australia",
    Pcode: "5357",
    Locality: "MCBEAN POUND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.631900",
    Latitude: "-34.286600"
  },
  {
    Country: "Australia",
    Pcode: "5357",
    Locality: "NEW WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.900700",
    Latitude: "-34.375700"
  },
  {
    Country: "Australia",
    Pcode: "5357",
    Locality: "NOTTS WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.783400",
    Latitude: "-34.416400"
  },
  {
    Country: "Australia",
    Pcode: "5357",
    Locality: "PAISLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.659400",
    Latitude: "-34.397700"
  },
  {
    Country: "Australia",
    Pcode: "5360",
    Locality: "GREENOCK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.937600",
    Latitude: "-34.440100"
  },
  {
    Country: "Australia",
    Pcode: "5360",
    Locality: "NAIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.890800",
    Latitude: "-34.445600"
  },
  {
    Country: "Australia",
    Pcode: "5371",
    Locality: "MORN HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.759800",
    Latitude: "-34.418100"
  },
  {
    Country: "Australia",
    Pcode: "5371",
    Locality: "ROSEWORTHY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.751700",
    Latitude: "-34.534200"
  },
  {
    Country: "Australia",
    Pcode: "5371",
    Locality: "SHEA-OAK LOG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.843000",
    Latitude: "-34.502700"
  },
  {
    Country: "Australia",
    Pcode: "5371",
    Locality: "TEMPLERS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.746800",
    Latitude: "-34.473200"
  },
  {
    Country: "Australia",
    Pcode: "5372",
    Locality: "FREELING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.821100",
    Latitude: "-34.452500"
  },
  {
    Country: "Australia",
    Pcode: "5373",
    Locality: "ALLENDALE NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.909800",
    Latitude: "-34.279300"
  },
  {
    Country: "Australia",
    Pcode: "5373",
    Locality: "BAGOT WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.986900",
    Latitude: "-34.297500"
  },
  {
    Country: "Australia",
    Pcode: "5373",
    Locality: "BETHEL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.830700",
    Latitude: "-34.337700"
  },
  {
    Country: "Australia",
    Pcode: "5373",
    Locality: "FORDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.871900",
    Latitude: "-34.393200"
  },
  {
    Country: "Australia",
    Pcode: "5373",
    Locality: "HAMILTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.901700",
    Latitude: "-34.232300"
  },
  {
    Country: "Australia",
    Pcode: "5373",
    Locality: "KAPUNDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.904600",
    Latitude: "-34.325300"
  },
  {
    Country: "Australia",
    Pcode: "5373",
    Locality: "KOONUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.012300",
    Latitude: "-34.366400"
  },
  {
    Country: "Australia",
    Pcode: "5452",
    Locality: "WATERVALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.648800",
    Latitude: "-33.964500"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "ARMAGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.575600",
    Latitude: "-33.826500"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "BARINIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.627600",
    Latitude: "-33.747100"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "BENBOURNIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.547600",
    Latitude: "-33.816300"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "BOCONNOC PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.559000",
    Latitude: "-33.859200"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "CLARE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.611800",
    Latitude: "-33.837200"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "EMU FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.591600",
    Latitude: "-33.859800"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "GILLENTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.611600",
    Latitude: "-33.880300"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "HILL RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.685000",
    Latitude: "-33.805100"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "HOYLETON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.523400",
    Latitude: "-33.999000"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "KYBUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.511600",
    Latitude: "-33.926100"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "PENWORTHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.645000",
    Latitude: "-33.922600"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "POLISH HILL RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.672300",
    Latitude: "-33.881800"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "SEVENHILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.624300",
    Latitude: "-33.894500"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "SPRING FARM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.638900",
    Latitude: "-33.847500"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "SPRING GULLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.589600",
    Latitude: "-33.908100"
  },
  {
    Country: "Australia",
    Pcode: "5453",
    Locality: "STANLEY FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.600300",
    Latitude: "-33.793800"
  },
  {
    Country: "Australia",
    Pcode: "5454",
    Locality: "ANDREWS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.646000",
    Latitude: "-33.616400"
  },
  {
    Country: "Australia",
    Pcode: "5454",
    Locality: "BROUGHTON RIVER VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.574200",
    Latitude: "-33.554100"
  },
  {
    Country: "Australia",
    Pcode: "5454",
    Locality: "EUROMINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.572300",
    Latitude: "-33.624500"
  },
  {
    Country: "Australia",
    Pcode: "5454",
    Locality: "HACKLINS CORNER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.675100",
    Latitude: "-33.430300"
  },
  {
    Country: "Australia",
    Pcode: "5454",
    Locality: "MAYFIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.682800",
    Latitude: "-33.363500"
  },
  {
    Country: "Australia",
    Pcode: "5454",
    Locality: "SPALDING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.630600",
    Latitude: "-33.494700"
  },
  {
    Country: "Australia",
    Pcode: "5454",
    Locality: "WASHPOOL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.588000",
    Latitude: "-33.368200"
  },
  {
    Country: "Australia",
    Pcode: "5455",
    Locality: "HILLTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.648200",
    Latitude: "-33.705900"
  },
  {
    Country: "Australia",
    Pcode: "5460",
    Locality: "BARABBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.578600",
    Latitude: "-34.357900"
  },
  {
    Country: "Australia",
    Pcode: "5460",
    Locality: "OWEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.528800",
    Latitude: "-34.240400"
  },
  {
    Country: "Australia",
    Pcode: "5460",
    Locality: "PINERY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.432200",
    Latitude: "-34.298000"
  },
  {
    Country: "Australia",
    Pcode: "5460",
    Locality: "STOCKYARD CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.604700",
    Latitude: "-34.307500"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "BALAKLAVA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.427700",
    Latitude: "-34.144000"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "BOWILLIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.415800",
    Latitude: "-33.934900"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "DALKEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.422600",
    Latitude: "-34.188700"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "ERITH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.401800",
    Latitude: "-34.220600"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "EVERARD CENTRAL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.318700",
    Latitude: "-33.927500"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "GOYDER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.265400",
    Latitude: "-34.081300"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "HALBURY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.544000",
    Latitude: "-34.107200"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "HOSKIN CORNER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.462800",
    Latitude: "-34.203700"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "MOUNT TEMPLETON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.326900",
    Latitude: "-34.017200"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "SAINTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.340500",
    Latitude: "-34.154700"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "STOW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.413500",
    Latitude: "-34.004300"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "WATCHMAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.423200",
    Latitude: "-34.069000"
  },
  {
    Country: "Australia",
    Pcode: "5461",
    Locality: "WHITWARTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.338200",
    Latitude: "-34.094400"
  },
  {
    Country: "Australia",
    Pcode: "5462",
    Locality: "BLYTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.452800",
    Latitude: "-33.835100"
  },
  {
    Country: "Australia",
    Pcode: "5464",
    Locality: "ANAMA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.566900",
    Latitude: "-33.687900"
  },
  {
    Country: "Australia",
    Pcode: "5464",
    Locality: "BRINKWORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.395700",
    Latitude: "-33.683000"
  },
  {
    Country: "Australia",
    Pcode: "5464",
    Locality: "BUNGAREE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.564500",
    Latitude: "-33.745700"
  },
  {
    Country: "Australia",
    Pcode: "5464",
    Locality: "CONDOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.331100",
    Latitude: "-33.778100"
  },
  {
    Country: "Australia",
    Pcode: "5464",
    Locality: "HART",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.473900",
    Latitude: "-33.758100"
  },
  {
    Country: "Australia",
    Pcode: "5464",
    Locality: "KOOLUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.332200",
    Latitude: "-33.550000"
  },
  {
    Country: "Australia",
    Pcode: "5464",
    Locality: "MAROLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.526600",
    Latitude: "-33.659500"
  },
  {
    Country: "Australia",
    Pcode: "5464",
    Locality: "ROCHESTER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.503800",
    Latitude: "-33.694200"
  },
  {
    Country: "Australia",
    Pcode: "5470",
    Locality: "YACKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.477500",
    Latitude: "-33.564000"
  },
  {
    Country: "Australia",
    Pcode: "5471",
    Locality: "GULNARE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.457800",
    Latitude: "-33.462300"
  },
  {
    Country: "Australia",
    Pcode: "5472",
    Locality: "GEORGETOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.412700",
    Latitude: "-33.374000"
  },
  {
    Country: "Australia",
    Pcode: "5473",
    Locality: "GLADSTONE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.369000",
    Latitude: "-33.282500"
  },
  {
    Country: "Australia",
    Pcode: "5480",
    Locality: "APPILA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.409600",
    Latitude: "-33.040700"
  },
  {
    Country: "Australia",
    Pcode: "5480",
    Locality: "LAURA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.295900",
    Latitude: "-33.165500"
  },
  {
    Country: "Australia",
    Pcode: "5480",
    Locality: "STONE HUT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.284400",
    Latitude: "-33.108200"
  },
  {
    Country: "Australia",
    Pcode: "5481",
    Locality: "BANGOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.161600",
    Latitude: "-32.949300"
  },
  {
    Country: "Australia",
    Pcode: "5481",
    Locality: "MURRAY TOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.251000",
    Latitude: "-32.923200"
  },
  {
    Country: "Australia",
    Pcode: "5481",
    Locality: "WIRRABARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.243100",
    Latitude: "-33.037900"
  },
  {
    Country: "Australia",
    Pcode: "5481",
    Locality: "WONGYARRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.215400",
    Latitude: "-32.972000"
  },
  {
    Country: "Australia",
    Pcode: "5482",
    Locality: "BOOLEROO CENTRE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.340900",
    Latitude: "-32.872700"
  },
  {
    Country: "Australia",
    Pcode: "5482",
    Locality: "WEPOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5483",
    Locality: "MELROSE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.182300",
    Latitude: "-32.827900"
  },
  {
    Country: "Australia",
    Pcode: "5485",
    Locality: "WILMINGTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.158300",
    Latitude: "-32.651100"
  },
  {
    Country: "Australia",
    Pcode: "5490",
    Locality: "CALTOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.492900",
    Latitude: "-33.202900"
  },
  {
    Country: "Australia",
    Pcode: "5603",
    Locality: "ARNO BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.555800",
    Latitude: "-33.894300"
  },
  {
    Country: "Australia",
    Pcode: "5603",
    Locality: "HINCKS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.963200",
    Latitude: "-33.856600"
  },
  {
    Country: "Australia",
    Pcode: "5603",
    Locality: "VERRAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.322400",
    Latitude: "-33.878400"
  },
  {
    Country: "Australia",
    Pcode: "5603",
    Locality: "WHARMINDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.228000",
    Latitude: "-33.939600"
  },
  {
    Country: "Australia",
    Pcode: "5604",
    Locality: "PORT NEILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.298700",
    Latitude: "-34.082700"
  },
  {
    Country: "Australia",
    Pcode: "5605",
    Locality: "BUTLER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.163700",
    Latitude: "-34.081600"
  },
  {
    Country: "Australia",
    Pcode: "5605",
    Locality: "TUMBY BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.071300",
    Latitude: "-34.400900"
  },
  {
    Country: "Australia",
    Pcode: "5606",
    Locality: "PORT LINCOLN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.852400",
    Latitude: "-34.719500"
  },
  {
    Country: "Australia",
    Pcode: "5606",
    Locality: "PORT LINCOLN SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5606",
    Locality: "WEDGE ISLAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.461100",
    Latitude: "-35.154000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "BOSTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.830200",
    Latitude: "-34.684500"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "BROOKER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.854000",
    Latitude: "-34.048200"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "CHARLTON GULLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.799200",
    Latitude: "-34.541100"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "COFFIN BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.320100",
    Latitude: "-34.580200"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "COOMUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.720100",
    Latitude: "-34.661200"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "COULTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.449700",
    Latitude: "-34.402000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "DUCK PONDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.765500",
    Latitude: "-34.728600"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "FARM BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.397600",
    Latitude: "-34.493100"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "FOUNTAIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.636800",
    Latitude: "-34.594800"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "GREEN PATCH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.776900",
    Latitude: "-34.593100"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "HAWSON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.785800",
    Latitude: "-34.682000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "KELLIDIE BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.490000",
    Latitude: "-34.596800"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "KIANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.351700",
    Latitude: "-33.999800"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "KOPPIO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.865100",
    Latitude: "-34.418400"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "LINCOLN NATIONAL PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.884900",
    Latitude: "-34.865400"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "LIPSON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.158800",
    Latitude: "-34.246800"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "LITTLE DOUGLAS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.399400",
    Latitude: "-34.563800"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "LOUTH BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.912900",
    Latitude: "-34.539000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "MOODY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.009400",
    Latitude: "-34.049400"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "MOUNT DRUMMOND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.420600",
    Latitude: "-34.245000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "MOUNT DUTTON BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.424600",
    Latitude: "-34.521900"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "MOUNT HOPE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.348800",
    Latitude: "-34.137500"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "MURDINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.750000",
    Latitude: "-33.729000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "NORTH SHIELDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.855700",
    Latitude: "-34.615200"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "PEARLAH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.704800",
    Latitude: "-34.594800"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "POINT BOSTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.923500",
    Latitude: "-34.626200"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "POONINDIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.877800",
    Latitude: "-34.569200"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "PORT LINCOLN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "PO BAGS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "SHERINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.263700",
    Latitude: "-33.847900"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "SLEAFORD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.668100",
    Latitude: "-34.879400"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "TIATUKIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.836600",
    Latitude: "-34.657000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "TOOLIGIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.650500",
    Latitude: "-33.847400"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "TOOTENILLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.792500",
    Latitude: "-34.646500"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "TULKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.760200",
    Latitude: "-34.779400"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "ULEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.583500",
    Latitude: "-34.720900"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "UNGARRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.026300",
    Latitude: "-34.196600"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "VENUS BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.739000",
    Latitude: "-33.209000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "WANGARY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.520700",
    Latitude: "-34.552300"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "WANILLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.683300",
    Latitude: "-34.527400"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "WARROW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "WHITES FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.845800",
    Latitude: "-34.510400"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "WHITES RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.948600",
    Latitude: "-34.498200"
  },
  {
    Country: "Australia",
    Pcode: "5607",
    Locality: "YALLUNDA FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.879200",
    Latitude: "-34.335100"
  },
  {
    Country: "Australia",
    Pcode: "5608",
    Locality: "MULLAQUANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.526900",
    Latitude: "-33.058000"
  },
  {
    Country: "Australia",
    Pcode: "5608",
    Locality: "WHYALLA NORRIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.541000",
    Latitude: "-33.027600"
  },
  {
    Country: "Australia",
    Pcode: "5608",
    Locality: "WHYALLA NORRIE EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5608",
    Locality: "WHYALLA NORRIE NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5608",
    Locality: "WHYALLA STUART",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.516600",
    Latitude: "-33.036200"
  },
  {
    Country: "Australia",
    Pcode: "5609",
    Locality: "COWLEDS LANDING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.450900",
    Latitude: "-33.131000"
  },
  {
    Country: "Australia",
    Pcode: "5609",
    Locality: "MIDDLEBACK RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.260700",
    Latitude: "-33.152800"
  },
  {
    Country: "Australia",
    Pcode: "5609",
    Locality: "MURNINNIE BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.375700",
    Latitude: "-33.317800"
  },
  {
    Country: "Australia",
    Pcode: "5609",
    Locality: "WHYALLA JENKINS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.510300",
    Latitude: "-33.025200"
  },
  {
    Country: "Australia",
    Pcode: "5611",
    Locality: "COOYERDOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.979500",
    Latitude: "-33.100100"
  },
  {
    Country: "Australia",
    Pcode: "5611",
    Locality: "CORUNNA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.060300",
    Latitude: "-32.692000"
  },
  {
    Country: "Australia",
    Pcode: "5611",
    Locality: "GILLES DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.918900",
    Latitude: "-32.917400"
  },
  {
    Country: "Australia",
    Pcode: "5611",
    Locality: "IRON BARON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.159500",
    Latitude: "-32.989600"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "PURPLE DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.918000",
    Latitude: "-30.816500"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "STUARTS CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.940300",
    Latitude: "-29.560100"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "WOOMERA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.816300",
    Latitude: "-31.165200"
  },
  {
    Country: "Australia",
    Pcode: "5722",
    Locality: "ANDAMOOKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.165000",
    Latitude: "-30.453700"
  },
  {
    Country: "Australia",
    Pcode: "5722",
    Locality: "ANDAMOOKA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.149900",
    Latitude: "-30.566400"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "ALLANDALE STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.591000",
    Latitude: "-27.708400"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "ANNA CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.044900",
    Latitude: "-28.761700"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "ARCKARINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.881200",
    Latitude: "-27.837800"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "COOBER PEDY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.826800",
    Latitude: "-29.007900"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "EVELYN DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.370100",
    Latitude: "-27.997600"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "INGOMAR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.799100",
    Latitude: "-29.539900"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "MABEL CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.093400",
    Latitude: "-29.137700"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "MCDOUALL PEAK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.246100",
    Latitude: "-29.602400"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "MOUNT BARRY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.859900",
    Latitude: "-28.380400"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "MOUNT CLARENCE STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.651500",
    Latitude: "-29.178300"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "MOUNT WILLOUGHBY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.233400",
    Latitude: "-28.533000"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "NILPINNA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.589000",
    Latitude: "-28.372400"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "WILLIAM CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.343100",
    Latitude: "-28.906900"
  },
  {
    Country: "Australia",
    Pcode: "5723",
    Locality: "WINTINNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.831600",
    Latitude: "-27.781700"
  },
  {
    Country: "Australia",
    Pcode: "5724",
    Locality: "MARLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.622500",
    Latitude: "-27.299700"
  },
  {
    Country: "Australia",
    Pcode: "5724",
    Locality: "MINTABIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5724",
    Locality: "WELBOURN HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.919200",
    Latitude: "-27.409600"
  },
  {
    Country: "Australia",
    Pcode: "5725",
    Locality: "OLYMPIC DAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.876300",
    Latitude: "-30.440200"
  },
  {
    Country: "Australia",
    Pcode: "5725",
    Locality: "ROXBY DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.911000",
    Latitude: "-30.544600"
  },
  {
    Country: "Australia",
    Pcode: "5725",
    Locality: "ROXBY DOWNS STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.666500",
    Latitude: "-30.591500"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "ALPANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.541800",
    Latitude: "-31.157000"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "ANGORIGINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.812800",
    Latitude: "-31.120800"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "BELTANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.414600",
    Latitude: "-30.820800"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "BELTANA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.120900",
    Latitude: "-30.677200"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "BLINMAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.708300",
    Latitude: "-31.122600"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "EDIACARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.144600",
    Latitude: "-30.813100"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "GUM CREEK STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.660300",
    Latitude: "-31.208500"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "MOOLOOLOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.563700",
    Latitude: "-31.004600"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "MOORILLAH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.481600",
    Latitude: "-30.861300"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "MOTPENA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.296200",
    Latitude: "-31.186800"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "MOUNT FALKLAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.476900",
    Latitude: "-31.163900"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "NARRINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.806200",
    Latitude: "-30.922000"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "NILPENA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.210300",
    Latitude: "-30.973500"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "ORATUNGA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.583700",
    Latitude: "-31.061700"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "PARACHILNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.390100",
    Latitude: "-31.144000"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "PUTTAPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.470800",
    Latitude: "-30.711300"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "WARRAWEENA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.667900",
    Latitude: "-30.821700"
  },
  {
    Country: "Australia",
    Pcode: "5730",
    Locality: "WIRREALPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.170300",
    Latitude: "-31.189600"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "BOLLARDS LAGOON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.705500",
    Latitude: "-28.792100"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "COOPERS CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "CORDILLO DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.523900",
    Latitude: "-26.436500"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "GIDGEALPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.182900",
    Latitude: "-28.028000"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "INNAMINCKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.433800",
    Latitude: "-27.316200"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "LEIGH CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.395100",
    Latitude: "-30.602200"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "LEIGH CREEK STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.528000",
    Latitude: "-30.359900"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "LINDON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.389900",
    Latitude: "-29.249200"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "LYNDHURST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.363200",
    Latitude: "-30.301700"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "MERTY MERTY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.421900",
    Latitude: "-28.551200"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "MOUNT FREELING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.379200",
    Latitude: "-30.011200"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "MOUNT LYNDHURST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.823400",
    Latitude: "-30.011500"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "MULGARIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.458000",
    Latitude: "-30.176400"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "MURNPEOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.330900",
    Latitude: "-29.402300"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "MYRTLE SPRINGS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.258400",
    Latitude: "-30.344700"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "STRZELECKI DESERT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.593900",
    Latitude: "-28.672500"
  },
  {
    Country: "Australia",
    Pcode: "5731",
    Locality: "WITCHELINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.954500",
    Latitude: "-30.143800"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "ANGEPENA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.867700",
    Latitude: "-30.659900"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "ARKAROOLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.376900",
    Latitude: "-30.189900"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "BURR WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.656400",
    Latitude: "-30.455000"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "COPLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.407000",
    Latitude: "-30.494300"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "GAMMON RANGES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.228500",
    Latitude: "-30.557100"
  },
  {
    Country: "Australia",
    Pcode: "5732",
    Locality: "MANNERS WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.735200",
    Latitude: "-30.630800"
  },
  {
    Country: "Australia",
    Pcode: "6061",
    Locality: "NOLLAMARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.843900",
    Latitude: "-31.880000"
  },
  {
    Country: "Australia",
    Pcode: "6061",
    Locality: "WESTMINSTER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.841900",
    Latitude: "-31.866400"
  },
  {
    Country: "Australia",
    Pcode: "6062",
    Locality: "EMBLETON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.910800",
    Latitude: "-31.902400"
  },
  {
    Country: "Australia",
    Pcode: "6062",
    Locality: "MORLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.911900",
    Latitude: "-31.889800"
  },
  {
    Country: "Australia",
    Pcode: "6062",
    Locality: "NORANDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.898400",
    Latitude: "-31.872500"
  },
  {
    Country: "Australia",
    Pcode: "6063",
    Locality: "BEECHBORO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.936100",
    Latitude: "-31.868300"
  },
  {
    Country: "Australia",
    Pcode: "6063",
    Locality: "BENNETT SPRINGS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.942200",
    Latitude: "-31.856400"
  },
  {
    Country: "Australia",
    Pcode: "6064",
    Locality: "ALEXANDER HEIGHTS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.864700",
    Latitude: "-31.828400"
  },
  {
    Country: "Australia",
    Pcode: "6064",
    Locality: "GIRRAWHEEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.839000",
    Latitude: "-31.841200"
  },
  {
    Country: "Australia",
    Pcode: "6064",
    Locality: "KOONDOOLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.866100",
    Latitude: "-31.841400"
  },
  {
    Country: "Australia",
    Pcode: "6064",
    Locality: "MARANGAROO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.836900",
    Latitude: "-31.827800"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "ASHBY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.796900",
    Latitude: "-31.734100"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "DARCH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.845200",
    Latitude: "-31.812800"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "HOCKING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.819100",
    Latitude: "-31.769800"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "KINGSWAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "LANDSDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.866200",
    Latitude: "-31.807500"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "MADELEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.826800",
    Latitude: "-31.811100"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "PEARSALL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.817900",
    Latitude: "-31.782100"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "SINAGRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.805500",
    Latitude: "-31.741900"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "TAPPING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.793800",
    Latitude: "-31.717900"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "WANGARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.834600",
    Latitude: "-31.792900"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "WANGARA DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6065",
    Locality: "WANNEROO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.808800",
    Latitude: "-31.752900"
  },
  {
    Country: "Australia",
    Pcode: "6066",
    Locality: "BALLAJURA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.892500",
    Latitude: "-31.838200"
  },
  {
    Country: "Australia",
    Pcode: "6067",
    Locality: "CULLACABARDEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.897800",
    Latitude: "-31.819500"
  },
  {
    Country: "Australia",
    Pcode: "6068",
    Locality: "WHITEMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.942000",
    Latitude: "-31.825600"
  },
  {
    Country: "Australia",
    Pcode: "6069",
    Locality: "AVELEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.990900",
    Latitude: "-31.782000"
  },
  {
    Country: "Australia",
    Pcode: "6069",
    Locality: "BELHUS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.011200",
    Latitude: "-31.782500"
  },
  {
    Country: "Australia",
    Pcode: "6069",
    Locality: "BRIGADOON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.079700",
    Latitude: "-31.767700"
  },
  {
    Country: "Australia",
    Pcode: "6069",
    Locality: "ELLENBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.966700",
    Latitude: "-31.768700"
  },
  {
    Country: "Australia",
    Pcode: "6069",
    Locality: "THE VINES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.001000",
    Latitude: "-31.759800"
  },
  {
    Country: "Australia",
    Pcode: "6069",
    Locality: "UPPER SWAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.040600",
    Latitude: "-31.767600"
  },
  {
    Country: "Australia",
    Pcode: "6070",
    Locality: "DARLINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.081100",
    Latitude: "-31.925200"
  },
  {
    Country: "Australia",
    Pcode: "6071",
    Locality: "GLEN FORREST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.106600",
    Latitude: "-31.922600"
  },
  {
    Country: "Australia",
    Pcode: "6071",
    Locality: "HOVEA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.098400",
    Latitude: "-31.870000"
  },
  {
    Country: "Australia",
    Pcode: "6072",
    Locality: "MAHOGANY CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.136100",
    Latitude: "-31.908700"
  },
  {
    Country: "Australia",
    Pcode: "6073",
    Locality: "MUNDARING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.167000",
    Latitude: "-31.919100"
  },
  {
    Country: "Australia",
    Pcode: "6073",
    Locality: "MUNDARING DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6074",
    Locality: "SAWYERS VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.244300",
    Latitude: "-31.951700"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "BICKLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.106200",
    Latitude: "-31.999200"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "CARMEL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.081500",
    Latitude: "-32.022500"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "GOOSEBERRY HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.049300",
    Latitude: "-31.948600"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "HACKETTS GULLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.115500",
    Latitude: "-31.984000"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "KALAMUNDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.051400",
    Latitude: "-31.974200"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "LESMURDIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.045100",
    Latitude: "-32.009300"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "PAULLS VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.122300",
    Latitude: "-31.957000"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "PICKERING BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.198500",
    Latitude: "-32.047500"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "PIESSE BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.077300",
    Latitude: "-31.962000"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "RESERVOIR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.172800",
    Latitude: "-31.994700"
  },
  {
    Country: "Australia",
    Pcode: "6076",
    Locality: "WALLISTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.075600",
    Latitude: "-32.000200"
  },
  {
    Country: "Australia",
    Pcode: "6077",
    Locality: "GNANGARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.862000",
    Latitude: "-31.775400"
  },
  {
    Country: "Australia",
    Pcode: "6077",
    Locality: "JANDABUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.856100",
    Latitude: "-31.739300"
  },
  {
    Country: "Australia",
    Pcode: "6078",
    Locality: "MARIGINIUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.845800",
    Latitude: "-31.710900"
  },
  {
    Country: "Australia",
    Pcode: "6078",
    Locality: "PINJAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.831900",
    Latitude: "-31.620100"
  },
  {
    Country: "Australia",
    Pcode: "6079",
    Locality: "LEXIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.919300",
    Latitude: "-31.778800"
  },
  {
    Country: "Australia",
    Pcode: "6079",
    Locality: "MELALEUCA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.919100",
    Latitude: "-31.711400"
  },
  {
    Country: "Australia",
    Pcode: "6081",
    Locality: "PARKERVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.133100",
    Latitude: "-31.869300"
  },
  {
    Country: "Australia",
    Pcode: "6081",
    Locality: "STONEVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.161200",
    Latitude: "-31.850900"
  },
  {
    Country: "Australia",
    Pcode: "6082",
    Locality: "BAILUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.310800",
    Latitude: "-31.738900"
  },
  {
    Country: "Australia",
    Pcode: "6082",
    Locality: "MOUNT HELENA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.213800",
    Latitude: "-31.856900"
  },
  {
    Country: "Australia",
    Pcode: "6083",
    Locality: "GIDGEGANNUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.178700",
    Latitude: "-31.754400"
  },
  {
    Country: "Australia",
    Pcode: "6083",
    Locality: "MORANGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.318700",
    Latitude: "-31.633100"
  },
  {
    Country: "Australia",
    Pcode: "6084",
    Locality: "AVON VALLEY NATIONAL PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.193500",
    Latitude: "-31.641000"
  },
  {
    Country: "Australia",
    Pcode: "6084",
    Locality: "BULLSBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.036800",
    Latitude: "-31.672700"
  },
  {
    Country: "Australia",
    Pcode: "6084",
    Locality: "CHITTERING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.082200",
    Latitude: "-31.468600"
  },
  {
    Country: "Australia",
    Pcode: "6084",
    Locality: "LOWER CHITTERING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.086400",
    Latitude: "-31.545300"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "WEST PINJARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.798500",
    Latitude: "-32.678900"
  },
  {
    Country: "Australia",
    Pcode: "6209",
    Locality: "BARRAGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.789100",
    Latitude: "-32.534700"
  },
  {
    Country: "Australia",
    Pcode: "6209",
    Locality: "FURNISSDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.771400",
    Latitude: "-32.559100"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "COODANUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.749000",
    Latitude: "-32.562900"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "DUDLEY PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.728900",
    Latitude: "-32.557200"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "ERSKINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.699700",
    Latitude: "-32.569000"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "FALCON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.670000",
    Latitude: "-32.585000"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "GREENFIELDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.763500",
    Latitude: "-32.525200"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "HALLS HEAD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.702000",
    Latitude: "-32.541700"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "MADORA BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.752800",
    Latitude: "-32.470100"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "MANDURAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.729300",
    Latitude: "-32.523800"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "MANDURAH DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "MANDURAH EAST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "MANDURAH NORTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "MEADOW SPRINGS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.753400",
    Latitude: "-32.497500"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "SAN REMO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.744200",
    Latitude: "-32.489700"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "SILVER SANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.735500",
    Latitude: "-32.509200"
  },
  {
    Country: "Australia",
    Pcode: "6210",
    Locality: "WANNANUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.651900",
    Latitude: "-32.602700"
  },
  {
    Country: "Australia",
    Pcode: "6211",
    Locality: "BOUVARD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.639700",
    Latitude: "-32.686100"
  },
  {
    Country: "Australia",
    Pcode: "6211",
    Locality: "CLIFTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.642900",
    Latitude: "-32.754900"
  },
  {
    Country: "Australia",
    Pcode: "6211",
    Locality: "DAWESVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.637400",
    Latitude: "-32.627600"
  },
  {
    Country: "Australia",
    Pcode: "6211",
    Locality: "HERRON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.681400",
    Latitude: "-32.752800"
  },
  {
    Country: "Australia",
    Pcode: "6213",
    Locality: "BANKSIADALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.119000",
    Latitude: "-32.627700"
  },
  {
    Country: "Australia",
    Pcode: "6213",
    Locality: "DWELLINGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.078300",
    Latitude: "-32.734900"
  },
  {
    Country: "Australia",
    Pcode: "6213",
    Locality: "ETMILYN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.137600",
    Latitude: "-32.768600"
  },
  {
    Country: "Australia",
    Pcode: "6213",
    Locality: "HOLYOAKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.116800",
    Latitude: "-32.703200"
  },
  {
    Country: "Australia",
    Pcode: "6213",
    Locality: "INGLEHOPE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.184800",
    Latitude: "-32.724100"
  },
  {
    Country: "Australia",
    Pcode: "6213",
    Locality: "MARRINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.010900",
    Latitude: "-32.676000"
  },
  {
    Country: "Australia",
    Pcode: "6213",
    Locality: "TEESDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.015300",
    Latitude: "-32.735700"
  },
  {
    Country: "Australia",
    Pcode: "6214",
    Locality: "BIRCHMONT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.728700",
    Latitude: "-32.715000"
  },
  {
    Country: "Australia",
    Pcode: "6214",
    Locality: "COOLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.881300",
    Latitude: "-32.753100"
  },
  {
    Country: "Australia",
    Pcode: "6214",
    Locality: "WEST COOLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.769500",
    Latitude: "-32.758100"
  },
  {
    Country: "Australia",
    Pcode: "6215",
    Locality: "HAMEL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.889400",
    Latitude: "-32.868500"
  },
  {
    Country: "Australia",
    Pcode: "6215",
    Locality: "LAKE CLIFTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.717100",
    Latitude: "-32.859000"
  },
  {
    Country: "Australia",
    Pcode: "6215",
    Locality: "NANGA BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.095000",
    Latitude: "-32.849000"
  },
  {
    Country: "Australia",
    Pcode: "6215",
    Locality: "PRESTON BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.674300",
    Latitude: "-32.873900"
  },
  {
    Country: "Australia",
    Pcode: "6215",
    Locality: "WAGERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.923300",
    Latitude: "-32.900300"
  },
  {
    Country: "Australia",
    Pcode: "6215",
    Locality: "WAROONA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.883100",
    Latitude: "-32.833000"
  },
  {
    Country: "Australia",
    Pcode: "6218",
    Locality: "YARLOOP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.866600",
    Latitude: "-32.961500"
  },
  {
    Country: "Australia",
    Pcode: "6220",
    Locality: "COOKERNUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.851500",
    Latitude: "-33.003500"
  },
  {
    Country: "Australia",
    Pcode: "6220",
    Locality: "HARVEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.917500",
    Latitude: "-33.074300"
  },
  {
    Country: "Australia",
    Pcode: "6220",
    Locality: "HOFFMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.075600",
    Latitude: "-33.031600"
  },
  {
    Country: "Australia",
    Pcode: "6220",
    Locality: "MYALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.730500",
    Latitude: "-33.015200"
  },
  {
    Country: "Australia",
    Pcode: "6220",
    Locality: "UDUC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.806400",
    Latitude: "-33.052500"
  },
  {
    Country: "Australia",
    Pcode: "6220",
    Locality: "WARAWARRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.912400",
    Latitude: "-33.044600"
  },
  {
    Country: "Australia",
    Pcode: "6221",
    Locality: "MORNINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.028600",
    Latitude: "-33.166500"
  },
  {
    Country: "Australia",
    Pcode: "6221",
    Locality: "WOKALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.843000",
    Latitude: "-33.128800"
  },
  {
    Country: "Australia",
    Pcode: "6223",
    Locality: "BENGER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.843900",
    Latitude: "-33.177500"
  },
  {
    Country: "Australia",
    Pcode: "6224",
    Locality: "BEELA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.902500",
    Latitude: "-33.241600"
  },
  {
    Country: "Australia",
    Pcode: "6224",
    Locality: "BRUNSWICK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.848100",
    Latitude: "-33.221100"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "ALLANSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.090800",
    Latitude: "-33.327100"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "BOWELLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.502500",
    Latitude: "-33.475200"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "BUCKINGHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.340400",
    Latitude: "-33.357500"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "CARDIFF",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.236700",
    Latitude: "-33.477000"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "COLLIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.144600",
    Latitude: "-33.358300"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "COLLIE BURN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.206800",
    Latitude: "-33.392000"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "HARRIS RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.140900",
    Latitude: "-33.241900"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "LYALLS MILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.108700",
    Latitude: "-33.462600"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "MCALINDEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.317700",
    Latitude: "-33.608300"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "MUJA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.339200",
    Latitude: "-33.432800"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "MUMBALLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.133000",
    Latitude: "-33.524700"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "MUNGALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.072700",
    Latitude: "-33.392300"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "NOGGERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.120300",
    Latitude: "-33.593600"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "PALMER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.244300",
    Latitude: "-33.290200"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "PRESTON SETTLEMENT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.141800",
    Latitude: "-33.417800"
  },
  {
    Country: "Australia",
    Pcode: "6225",
    Locality: "SHOTTS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.255500",
    Latitude: "-33.383700"
  },
  {
    Country: "Australia",
    Pcode: "6288",
    Locality: "HAMELIN BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.048000",
    Latitude: "-34.231800"
  },
  {
    Country: "Australia",
    Pcode: "6288",
    Locality: "KARRIDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.138900",
    Latitude: "-34.178400"
  },
  {
    Country: "Australia",
    Pcode: "6288",
    Locality: "NILLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.260200",
    Latitude: "-34.122100"
  },
  {
    Country: "Australia",
    Pcode: "6288",
    Locality: "SCOTT RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.278100",
    Latitude: "-34.263300"
  },
  {
    Country: "Australia",
    Pcode: "6288",
    Locality: "WARNER GLEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.213900",
    Latitude: "-34.127500"
  },
  {
    Country: "Australia",
    Pcode: "6290",
    Locality: "AUGUSTA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.144700",
    Latitude: "-34.303900"
  },
  {
    Country: "Australia",
    Pcode: "6290",
    Locality: "DEEPDENE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.073900",
    Latitude: "-34.268800"
  },
  {
    Country: "Australia",
    Pcode: "6290",
    Locality: "EAST AUGUSTA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.175400",
    Latitude: "-34.311100"
  },
  {
    Country: "Australia",
    Pcode: "6290",
    Locality: "KUDARDUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.155300",
    Latitude: "-34.249300"
  },
  {
    Country: "Australia",
    Pcode: "6290",
    Locality: "LEEUWIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.132000",
    Latitude: "-34.337100"
  },
  {
    Country: "Australia",
    Pcode: "6290",
    Locality: "MOLLOY ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.213200",
    Latitude: "-34.268700"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "BADGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.991600",
    Latitude: "-31.804600"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "BALLADONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.745700",
    Latitude: "-31.932800"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "BURGES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.766500",
    Latitude: "-31.832200"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "CALJIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.928000",
    Latitude: "-31.748900"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "COLD HARBOUR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.835300",
    Latitude: "-31.876800"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "DALIAK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.716100",
    Latitude: "-31.866700"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "FLINT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.464100",
    Latitude: "-32.234700"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "FLYNN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.461800",
    Latitude: "-32.046100"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "GILGERING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.814900",
    Latitude: "-32.012800"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "GREENHILLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.954800",
    Latitude: "-31.896700"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "GWAMBYGINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.778200",
    Latitude: "-31.971500"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "INKPEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.573400",
    Latitude: "-31.844900"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "KAURING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.044700",
    Latitude: "-31.936600"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "MALEBELLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.881300",
    Latitude: "-31.836700"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "MOUNT HARDEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.851200",
    Latitude: "-31.917000"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "MOUNT OBSERVATION",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.520700",
    Latitude: "-31.949400"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "NARRALOGGAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.083700",
    Latitude: "-31.863500"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "QUELLINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.818600",
    Latitude: "-31.788100"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "ST RONANS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.627900",
    Latitude: "-31.898100"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "TALBOT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.674600",
    Latitude: "-32.011400"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "TALBOT WEST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.671600",
    Latitude: "-32.131800"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "WILBERFORCE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.688600",
    Latitude: "-31.787800"
  },
  {
    Country: "Australia",
    Pcode: "6302",
    Locality: "YORK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.773800",
    Latitude: "-31.873900"
  },
  {
    Country: "Australia",
    Pcode: "6304",
    Locality: "BALLY BALLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.142200",
    Latitude: "-32.204400"
  },
  {
    Country: "Australia",
    Pcode: "6304",
    Locality: "BEVERLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.870300",
    Latitude: "-32.094000"
  },
  {
    Country: "Australia",
    Pcode: "6304",
    Locality: "DALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.723000",
    Latitude: "-32.234900"
  },
  {
    Country: "Australia",
    Pcode: "6304",
    Locality: "EAST BEVERLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.090800",
    Latitude: "-32.036300"
  },
  {
    Country: "Australia",
    Pcode: "6304",
    Locality: "KOKEBY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.948400",
    Latitude: "-32.196200"
  },
  {
    Country: "Australia",
    Pcode: "6304",
    Locality: "MORBINNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.166700",
    Latitude: "-32.107300"
  },
  {
    Country: "Australia",
    Pcode: "6304",
    Locality: "WESTDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.618600",
    Latitude: "-32.298200"
  },
  {
    Country: "Australia",
    Pcode: "6306",
    Locality: "ALDERSYDE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.258500",
    Latitude: "-32.317300"
  },
  {
    Country: "Australia",
    Pcode: "6306",
    Locality: "BROOKTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.021000",
    Latitude: "-32.362200"
  },
  {
    Country: "Australia",
    Pcode: "6306",
    Locality: "BULYEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.512800",
    Latitude: "-32.313700"
  },
  {
    Country: "Australia",
    Pcode: "6306",
    Locality: "JELCOBINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.728100",
    Latitude: "-32.405000"
  },
  {
    Country: "Australia",
    Pcode: "6306",
    Locality: "KWEDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.400000",
    Latitude: "-32.350000"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "CODJATOTINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.823900",
    Latitude: "-32.684600"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "DWARDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.684700",
    Latitude: "-32.765200"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "EAST PINGELLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.331300",
    Latitude: "-32.511500"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "GILLIMANNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.455500",
    Latitude: "-32.677300"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "HASTINGS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.790400",
    Latitude: "-32.549600"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "PINGELLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.084000",
    Latitude: "-32.528500"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "PUMPHREYS BRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.897500",
    Latitude: "-32.688900"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "SPRINGS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.609000",
    Latitude: "-32.529800"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "WANDERING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.686200",
    Latitude: "-32.654700"
  },
  {
    Country: "Australia",
    Pcode: "6308",
    Locality: "WEST PINGELLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.988000",
    Latitude: "-32.556400"
  },
  {
    Country: "Australia",
    Pcode: "6309",
    Locality: "EAST POPANYINNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.248900",
    Latitude: "-32.633400"
  },
  {
    Country: "Australia",
    Pcode: "6309",
    Locality: "POPANYINNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.116500",
    Latitude: "-32.648800"
  },
  {
    Country: "Australia",
    Pcode: "6309",
    Locality: "STRATHERNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.343100",
    Latitude: "-32.646400"
  },
  {
    Country: "Australia",
    Pcode: "6309",
    Locality: "WEST POPANYINNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.973100",
    Latitude: "-32.670400"
  },
  {
    Country: "Australia",
    Pcode: "6311",
    Locality: "COMMODINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.305700",
    Latitude: "-32.766800"
  },
  {
    Country: "Australia",
    Pcode: "6311",
    Locality: "CONTINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.020400",
    Latitude: "-32.828900"
  },
  {
    Country: "Australia",
    Pcode: "6311",
    Locality: "CUBALLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.146600",
    Latitude: "-32.825300"
  },
  {
    Country: "Australia",
    Pcode: "6311",
    Locality: "DRYANDRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.989200",
    Latitude: "-32.773800"
  },
  {
    Country: "Australia",
    Pcode: "6311",
    Locality: "LOL GRAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.022300",
    Latitude: "-32.742900"
  },
  {
    Country: "Australia",
    Pcode: "6311",
    Locality: "TOWNSENDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.314200",
    Latitude: "-32.705100"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "TARIN ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.205500",
    Latitude: "-33.090900"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "DUNN ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.510300",
    Latitude: "-33.340600"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "EAST NEWDEGATE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.261400",
    Latitude: "-33.119000"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "HOLT ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.423700",
    Latitude: "-32.658900"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "LAKE BIDDY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.966400",
    Latitude: "-32.899000"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "LAKE CAMM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.736700",
    Latitude: "-32.930200"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "MAGENTA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.263900",
    Latitude: "-33.385200"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "MOUNT SHERIDAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.219300",
    Latitude: "-32.878400"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "NEWDEGATE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.024300",
    Latitude: "-33.085700"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "SOUTH NEWDEGATE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.036200",
    Latitude: "-33.213600"
  },
  {
    Country: "Australia",
    Pcode: "6355",
    Locality: "VARLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.583500",
    Latitude: "-32.859500"
  },
  {
    Country: "Australia",
    Pcode: "6356",
    Locality: "HATTER HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.121300",
    Latitude: "-32.922800"
  },
  {
    Country: "Australia",
    Pcode: "6356",
    Locality: "LAKE KING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.659000",
    Latitude: "-33.092100"
  },
  {
    Country: "Australia",
    Pcode: "6356",
    Locality: "MOUNT MADDEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.847400",
    Latitude: "-33.234600"
  },
  {
    Country: "Australia",
    Pcode: "6357",
    Locality: "PINGARING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.788600",
    Latitude: "-32.691900"
  },
  {
    Country: "Australia",
    Pcode: "6358",
    Locality: "KARLGARIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.692300",
    Latitude: "-32.410000"
  },
  {
    Country: "Australia",
    Pcode: "6359",
    Locality: "FORRESTANIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.744800",
    Latitude: "-32.484400"
  },
  {
    Country: "Australia",
    Pcode: "6359",
    Locality: "HYDEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.092100",
    Latitude: "-32.401100"
  },
  {
    Country: "Australia",
    Pcode: "6359",
    Locality: "LITTLE ITALY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.099100",
    Latitude: "-32.663400"
  },
  {
    Country: "Australia",
    Pcode: "6361",
    Locality: "HARRISMITH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.863100",
    Latitude: "-32.993500"
  },
  {
    Country: "Australia",
    Pcode: "6361",
    Locality: "TINCURRIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.756700",
    Latitude: "-32.943400"
  },
  {
    Country: "Australia",
    Pcode: "6363",
    Locality: "DUDININ",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.035000",
    Latitude: "-32.941100"
  },
  {
    Country: "Australia",
    Pcode: "6363",
    Locality: "WALYURIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.267200",
    Latitude: "-32.880400"
  },
  {
    Country: "Australia",
    Pcode: "6365",
    Locality: "JILAKIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.438200",
    Latitude: "-32.700600"
  },
  {
    Country: "Australia",
    Pcode: "6365",
    Locality: "JITARNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.993700",
    Latitude: "-32.775600"
  },
  {
    Country: "Australia",
    Pcode: "6365",
    Locality: "KULIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.223200",
    Latitude: "-32.682400"
  },
  {
    Country: "Australia",
    Pcode: "6365",
    Locality: "KULIN WEST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.030500",
    Latitude: "-32.610600"
  },
  {
    Country: "Australia",
    Pcode: "6367",
    Locality: "KONDININ",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.289900",
    Latitude: "-32.429800"
  },
  {
    Country: "Australia",
    Pcode: "6368",
    Locality: "SOUTH KUMMININ",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.494200",
    Latitude: "-32.192000"
  },
  {
    Country: "Australia",
    Pcode: "6369",
    Locality: "MOUNT WALKER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.753700",
    Latitude: "-32.071300"
  },
  {
    Country: "Australia",
    Pcode: "6369",
    Locality: "NAREMBEEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.394300",
    Latitude: "-32.062700"
  },
  {
    Country: "Australia",
    Pcode: "6369",
    Locality: "WADDERIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.460800",
    Latitude: "-32.007600"
  },
  {
    Country: "Australia",
    Pcode: "6369",
    Locality: "WEST HOLLETON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.016100",
    Latitude: "-32.008600"
  },
  {
    Country: "Australia",
    Pcode: "6369",
    Locality: "WOOLOCUTTY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.983200",
    Latitude: "-32.163900"
  },
  {
    Country: "Australia",
    Pcode: "6370",
    Locality: "EAST WICKEPIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.711700",
    Latitude: "-32.774700"
  },
  {
    Country: "Australia",
    Pcode: "6370",
    Locality: "MALYALLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.583700",
    Latitude: "-32.719000"
  },
  {
    Country: "Australia",
    Pcode: "6370",
    Locality: "WICKEPIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.537800",
    Latitude: "-32.810000"
  },
  {
    Country: "Australia",
    Pcode: "6370",
    Locality: "WOGOLIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.824300",
    Latitude: "-32.800100"
  },
  {
    Country: "Australia",
    Pcode: "6372",
    Locality: "KIRK ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.763400",
    Latitude: "-32.641800"
  },
  {
    Country: "Australia",
    Pcode: "6372",
    Locality: "YEALERING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.619200",
    Latitude: "-32.613400"
  },
  {
    Country: "Australia",
    Pcode: "6373",
    Locality: "BULLARING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.757200",
    Latitude: "-32.508800"
  },
  {
    Country: "Australia",
    Pcode: "6375",
    Locality: "ADAMSVALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.730700",
    Latitude: "-32.221700"
  },
  {
    Country: "Australia",
    Pcode: "6375",
    Locality: "BILBARIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.940200",
    Latitude: "-32.207100"
  },
  {
    Country: "Australia",
    Pcode: "6375",
    Locality: "CORRIGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.901600",
    Latitude: "-32.335000"
  },
  {
    Country: "Australia",
    Pcode: "6375",
    Locality: "GORGE ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.986300",
    Latitude: "-32.464700"
  },
  {
    Country: "Australia",
    Pcode: "6375",
    Locality: "KUNJIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.701100",
    Latitude: "-32.358000"
  },
  {
    Country: "Australia",
    Pcode: "6375",
    Locality: "KURRENKUTTEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.090900",
    Latitude: "-32.292100"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "BADJALING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.497000",
    Latitude: "-32.013000"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "BALKULING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.153600",
    Latitude: "-31.953800"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "CUBBINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.345000",
    Latitude: "-31.884500"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "DANGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.344100",
    Latitude: "-32.044100"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "DOODENANNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.183800",
    Latitude: "-31.857700"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "DULBELLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.249200",
    Latitude: "-32.001200"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "MOUNT STIRLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.532300",
    Latitude: "-31.879400"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "QUAIRADING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.393400",
    Latitude: "-31.984300"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "SOUTH QUAIRADING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.355500",
    Latitude: "-32.155700"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "WAMENUSKING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.574000",
    Latitude: "-32.138700"
  },
  {
    Country: "Australia",
    Pcode: "6383",
    Locality: "YOTING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.558500",
    Latitude: "-31.992200"
  },
  {
    Country: "Australia",
    Pcode: "6384",
    Locality: "PANTAPIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.650800",
    Latitude: "-31.949700"
  },
  {
    Country: "Australia",
    Pcode: "6385",
    Locality: "KWOLYIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.730300",
    Latitude: "-31.987000"
  },
  {
    Country: "Australia",
    Pcode: "6386",
    Locality: "SHACKLETON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.872700",
    Latitude: "-31.950700"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "BANNISTER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.466600",
    Latitude: "-32.663200"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "BODDINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.512100",
    Latitude: "-32.824800"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "CROSSMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.606600",
    Latitude: "-32.835000"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "LOWER HOTHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.373700",
    Latitude: "-32.950500"
  },
  {
    Country: "Australia",
    Pcode: "6390",
    Locality: "MARRADONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.518400",
    Latitude: "-32.896200"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "CAPE LE GRAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.208200",
    Latitude: "-33.931100"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "CASCADE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.019900",
    Latitude: "-33.414600"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "CASTLETOWN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.907800",
    Latitude: "-33.833200"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "CHADWICK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.880000",
    Latitude: "-33.836000"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "CONDINGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.478700",
    Latitude: "-33.740200"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "COOMALBIDGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.262600",
    Latitude: "-33.700100"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "DALYUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.639700",
    Latitude: "-33.725000"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "EAST MUNGLINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.929800",
    Latitude: "-33.691500"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "ESPERANCE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.892000",
    Latitude: "-33.861600"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "HOWICK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.836700",
    Latitude: "-33.658900"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "MERIVALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.156900",
    Latitude: "-33.824500"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "MONJINGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.767300",
    Latitude: "-33.791500"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "MUNGLINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.497000",
    Latitude: "-33.486200"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "MYRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.964600",
    Latitude: "-33.770700"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "NERIDUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.216200",
    Latitude: "-33.598300"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "NULSEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.874100",
    Latitude: "-33.850700"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "PINK LAKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.819600",
    Latitude: "-33.847100"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "SINCLAIR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.873700",
    Latitude: "-33.862600"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "WEST BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.857700",
    Latitude: "-33.875400"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "WINDABOUT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.903600",
    Latitude: "-33.821100"
  },
  {
    Country: "Australia",
    Pcode: "6452",
    Locality: "BURAMINYA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.989500",
    Latitude: "-33.058400"
  },
  {
    Country: "Australia",
    Pcode: "6452",
    Locality: "CAPE ARID",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.285100",
    Latitude: "-33.630600"
  },
  {
    Country: "Australia",
    Pcode: "6452",
    Locality: "ISRAELITE BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.812700",
    Latitude: "-33.416800"
  },
  {
    Country: "Australia",
    Pcode: "6460",
    Locality: "GOOMALLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.839000",
    Latitude: "-31.297300"
  },
  {
    Country: "Australia",
    Pcode: "6460",
    Locality: "HULONGINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.825000",
    Latitude: "-31.359700"
  },
  {
    Country: "Australia",
    Pcode: "6460",
    Locality: "KARRANADGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.719700",
    Latitude: "-31.227300"
  },
  {
    Country: "Australia",
    Pcode: "6460",
    Locality: "UCARTY WEST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.900500",
    Latitude: "-31.325200"
  },
  {
    Country: "Australia",
    Pcode: "6460",
    Locality: "WALYORMOURING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.879100",
    Latitude: "-31.178200"
  },
  {
    Country: "Australia",
    Pcode: "6461",
    Locality: "DOWERIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.046100",
    Latitude: "-31.208000"
  },
  {
    Country: "Australia",
    Pcode: "6461",
    Locality: "KOOMBERKINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.025000",
    Latitude: "-31.035600"
  },
  {
    Country: "Australia",
    Pcode: "6462",
    Locality: "HINDMARSH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.189300",
    Latitude: "-31.211000"
  },
  {
    Country: "Australia",
    Pcode: "6462",
    Locality: "MINNIVALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.180600",
    Latitude: "-31.065400"
  },
  {
    Country: "Australia",
    Pcode: "6462",
    Locality: "UCARTY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.036200",
    Latitude: "-31.317400"
  },
  {
    Country: "Australia",
    Pcode: "6463",
    Locality: "BENJABERRING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.309200",
    Latitude: "-31.108100"
  },
  {
    Country: "Australia",
    Pcode: "6465",
    Locality: "MANMANNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.141200",
    Latitude: "-30.891800"
  },
  {
    Country: "Australia",
    Pcode: "6466",
    Locality: "CADOUX",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.112700",
    Latitude: "-30.717800"
  },
  {
    Country: "Australia",
    Pcode: "6467",
    Locality: "BURAKIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.151700",
    Latitude: "-30.545100"
  },
  {
    Country: "Australia",
    Pcode: "6468",
    Locality: "GOODLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.259700",
    Latitude: "-30.013600"
  },
  {
    Country: "Australia",
    Pcode: "6468",
    Locality: "KALANNIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.208800",
    Latitude: "-30.311900"
  },
  {
    Country: "Australia",
    Pcode: "6468",
    Locality: "PETRUDOR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.997300",
    Latitude: "-30.410400"
  },
  {
    Country: "Australia",
    Pcode: "6470",
    Locality: "KULJA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.323900",
    Latitude: "-30.403500"
  },
  {
    Country: "Australia",
    Pcode: "6472",
    Locality: "BEACON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.870900",
    Latitude: "-30.441600"
  },
  {
    Country: "Australia",
    Pcode: "6472",
    Locality: "BIMBIJY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.129700",
    Latitude: "-29.740800"
  },
  {
    Country: "Australia",
    Pcode: "6472",
    Locality: "CLEARY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.684600",
    Latitude: "-30.386000"
  },
  {
    Country: "Australia",
    Pcode: "6472",
    Locality: "KARROUN HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.253900",
    Latitude: "-29.924500"
  },
  {
    Country: "Australia",
    Pcode: "6472",
    Locality: "MOUROUBRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.720600",
    Latitude: "-29.775600"
  },
  {
    Country: "Australia",
    Pcode: "6472",
    Locality: "REMLAP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.660700",
    Latitude: "-30.066500"
  },
  {
    Country: "Australia",
    Pcode: "6472",
    Locality: "TAMPU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.811700",
    Latitude: "-30.171000"
  },
  {
    Country: "Australia",
    Pcode: "6473",
    Locality: "NORTH WIALKI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.242600",
    Latitude: "-30.326900"
  },
  {
    Country: "Australia",
    Pcode: "6473",
    Locality: "WIALKI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.142000",
    Latitude: "-30.401200"
  },
  {
    Country: "Australia",
    Pcode: "6475",
    Locality: "BADGERIN ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.323600",
    Latitude: "-30.771500"
  },
  {
    Country: "Australia",
    Pcode: "6475",
    Locality: "BOORALAMING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.369800",
    Latitude: "-30.907300"
  },
  {
    Country: "Australia",
    Pcode: "6475",
    Locality: "DUKIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.530200",
    Latitude: "-30.888000"
  },
  {
    Country: "Australia",
    Pcode: "6475",
    Locality: "KOORDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.483200",
    Latitude: "-30.818300"
  },
  {
    Country: "Australia",
    Pcode: "6475",
    Locality: "LAKE MARGARETTE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.526600",
    Latitude: "-30.683300"
  },
  {
    Country: "Australia",
    Pcode: "6475",
    Locality: "MOLLERIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.499000",
    Latitude: "-30.407600"
  },
  {
    Country: "Australia",
    Pcode: "6475",
    Locality: "NEWCARLBEON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.359800",
    Latitude: "-30.626700"
  },
  {
    Country: "Australia",
    Pcode: "6476",
    Locality: "GABBIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.678800",
    Latitude: "-30.727100"
  },
  {
    Country: "Australia",
    Pcode: "6477",
    Locality: "BENCUBBIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.854600",
    Latitude: "-30.782900"
  },
  {
    Country: "Australia",
    Pcode: "6477",
    Locality: "WELBUNGIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.045300",
    Latitude: "-30.777100"
  },
  {
    Country: "Australia",
    Pcode: "6479",
    Locality: "BARBALIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.109900",
    Latitude: "-30.895800"
  },
  {
    Country: "Australia",
    Pcode: "6479",
    Locality: "BONNIE ROCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.334800",
    Latitude: "-30.544500"
  },
  {
    Country: "Australia",
    Pcode: "6479",
    Locality: "DANDANNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.172300",
    Latitude: "-30.987200"
  },
  {
    Country: "Australia",
    Pcode: "6479",
    Locality: "ELACHBUTTING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.646300",
    Latitude: "-30.629600"
  },
  {
    Country: "Australia",
    Pcode: "6479",
    Locality: "KARLONING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.164500",
    Latitude: "-30.617700"
  },
  {
    Country: "Australia",
    Pcode: "6479",
    Locality: "LAKE BROWN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.391000",
    Latitude: "-30.947600"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "WHITE PEAK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.656700",
    Latitude: "-28.623400"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "WICHERINA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.980300",
    Latitude: "-28.702100"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "WICHERINA SOUTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.017400",
    Latitude: "-28.792400"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "YETNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.697500",
    Latitude: "-28.611500"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "YUNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.049500",
    Latitude: "-28.243400"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "ALMA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.642300",
    Latitude: "-28.257800"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "BOWES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.570300",
    Latitude: "-28.453100"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "EAST BOWES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.719000",
    Latitude: "-28.368900"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "GREGORY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.264400",
    Latitude: "-28.194800"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "HORROCKS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.429700",
    Latitude: "-28.377000"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "ISSEKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.648900",
    Latitude: "-28.433200"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "NORTHAMPTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.624800",
    Latitude: "-28.351300"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "OGILVIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.690800",
    Latitude: "-28.140600"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "SANDY GULLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.509400",
    Latitude: "-28.355300"
  },
  {
    Country: "Australia",
    Pcode: "6535",
    Locality: "YALLABATHARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.351100",
    Latitude: "-28.067300"
  },
  {
    Country: "Australia",
    Pcode: "6536",
    Locality: "KALBARRI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.202000",
    Latitude: "-27.683200"
  },
  {
    Country: "Australia",
    Pcode: "6536",
    Locality: "KALBARRI NATIONAL PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.468500",
    Latitude: "-27.666000"
  },
  {
    Country: "Australia",
    Pcode: "6536",
    Locality: "ZUYTDORP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.211400",
    Latitude: "-27.344300"
  },
  {
    Country: "Australia",
    Pcode: "6537",
    Locality: "DENHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.530600",
    Latitude: "-25.925500"
  },
  {
    Country: "Australia",
    Pcode: "6537",
    Locality: "DIRK HARTOG ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.079600",
    Latitude: "-25.866100"
  },
  {
    Country: "Australia",
    Pcode: "6537",
    Locality: "FRANCOIS PERON NATIONAL PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.598100",
    Latitude: "-25.896000"
  },
  {
    Country: "Australia",
    Pcode: "6537",
    Locality: "MONKEY MIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.705800",
    Latitude: "-25.798800"
  },
  {
    Country: "Australia",
    Pcode: "6537",
    Locality: "NANGA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.945900",
    Latitude: "-26.596200"
  },
  {
    Country: "Australia",
    Pcode: "6537",
    Locality: "SHARK BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.616700",
    Latitude: "-26.309900"
  },
  {
    Country: "Australia",
    Pcode: "6537",
    Locality: "USELESS LOOP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.409900",
    Latitude: "-26.132700"
  },
  {
    Country: "Australia",
    Pcode: "6556",
    Locality: "BEECHINA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.315700",
    Latitude: "-31.858000"
  },
  {
    Country: "Australia",
    Pcode: "6556",
    Locality: "CHIDLOW",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.270100",
    Latitude: "-31.852500"
  },
  {
    Country: "Australia",
    Pcode: "6556",
    Locality: "GORRIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.313300",
    Latitude: "-31.912000"
  },
  {
    Country: "Australia",
    Pcode: "6556",
    Locality: "MALMALLING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.357600",
    Latitude: "-31.967200"
  },
  {
    Country: "Australia",
    Pcode: "6556",
    Locality: "THE LAKES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.331600",
    Latitude: "-31.886500"
  },
  {
    Country: "Australia",
    Pcode: "6558",
    Locality: "WOOROLOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.314300",
    Latitude: "-31.808500"
  },
  {
    Country: "Australia",
    Pcode: "6560",
    Locality: "WUNDOWIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.380800",
    Latitude: "-31.750000"
  },
  {
    Country: "Australia",
    Pcode: "6562",
    Locality: "BAKERS HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.449800",
    Latitude: "-31.735000"
  },
  {
    Country: "Australia",
    Pcode: "6562",
    Locality: "COPLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.510700",
    Latitude: "-31.791300"
  },
  {
    Country: "Australia",
    Pcode: "6562",
    Locality: "WOOTTATING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.440900",
    Latitude: "-31.864700"
  },
  {
    Country: "Australia",
    Pcode: "6564",
    Locality: "CLACKLINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.522200",
    Latitude: "-31.716300"
  },
  {
    Country: "Australia",
    Pcode: "6566",
    Locality: "BEJOORDING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.534500",
    Latitude: "-31.395100"
  },
  {
    Country: "Australia",
    Pcode: "6566",
    Locality: "COONDLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.429900",
    Latitude: "-31.493700"
  },
  {
    Country: "Australia",
    Pcode: "6566",
    Locality: "CULHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.448900",
    Latitude: "-31.414200"
  },
  {
    Country: "Australia",
    Pcode: "6566",
    Locality: "DUMBARTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.528900",
    Latitude: "-31.559300"
  },
  {
    Country: "Australia",
    Pcode: "6566",
    Locality: "HODDYS WELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.454700",
    Latitude: "-31.639300"
  },
  {
    Country: "Australia",
    Pcode: "6566",
    Locality: "KATRINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.563500",
    Latitude: "-31.627900"
  },
  {
    Country: "Australia",
    Pcode: "6566",
    Locality: "NUNILE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.527600",
    Latitude: "-31.486400"
  },
  {
    Country: "Australia",
    Pcode: "6566",
    Locality: "TOODYAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.440700",
    Latitude: "-31.571300"
  },
  {
    Country: "Australia",
    Pcode: "6566",
    Locality: "WEST TOODYAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.401600",
    Latitude: "-31.537400"
  },
  {
    Country: "Australia",
    Pcode: "6567",
    Locality: "DEWARS POOL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.369300",
    Latitude: "-31.405400"
  },
  {
    Country: "Australia",
    Pcode: "6567",
    Locality: "JULIMAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.266700",
    Latitude: "-31.456200"
  },
  {
    Country: "Australia",
    Pcode: "6567",
    Locality: "MOONDYNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.196600",
    Latitude: "-31.560400"
  },
  {
    Country: "Australia",
    Pcode: "6568",
    Locality: "BOLGART",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.517200",
    Latitude: "-31.233900"
  },
  {
    Country: "Australia",
    Pcode: "6568",
    Locality: "WATTENING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.465000",
    Latitude: "-31.308700"
  },
  {
    Country: "Australia",
    Pcode: "6568",
    Locality: "WYENING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.504400",
    Latitude: "-31.159300"
  },
  {
    Country: "Australia",
    Pcode: "6569",
    Locality: "CALINGIRI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.497700",
    Latitude: "-31.084700"
  },
  {
    Country: "Australia",
    Pcode: "6569",
    Locality: "CARANI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.473300",
    Latitude: "-30.996000"
  },
  {
    Country: "Australia",
    Pcode: "6569",
    Locality: "OLD PLAINS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.351000",
    Latitude: "-31.130200"
  },
  {
    Country: "Australia",
    Pcode: "6571",
    Locality: "YERECOIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.438500",
    Latitude: "-30.926900"
  },
  {
    Country: "Australia",
    Pcode: "6572",
    Locality: "PIAWANING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.401600",
    Latitude: "-30.803700"
  },
  {
    Country: "Australia",
    Pcode: "6574",
    Locality: "BINDI BINDI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.421700",
    Latitude: "-30.627300"
  },
  {
    Country: "Australia",
    Pcode: "6574",
    Locality: "GABALONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.402600",
    Latitude: "-30.714800"
  },
  {
    Country: "Australia",
    Pcode: "6575",
    Locality: "MILING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.369700",
    Latitude: "-30.397800"
  },
  {
    Country: "Australia",
    Pcode: "6603",
    Locality: "KONNONGORRING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.782800",
    Latitude: "-31.051500"
  },
  {
    Country: "Australia",
    Pcode: "6603",
    Locality: "LAKE HINDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.550100",
    Latitude: "-30.763500"
  },
  {
    Country: "Australia",
    Pcode: "6603",
    Locality: "LAKE NINAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.640700",
    Latitude: "-30.933000"
  },
  {
    Country: "Australia",
    Pcode: "6603",
    Locality: "MOCARDY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.898800",
    Latitude: "-30.876000"
  },
  {
    Country: "Australia",
    Pcode: "6603",
    Locality: "WONGAN HILLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.691100",
    Latitude: "-30.830400"
  },
  {
    Country: "Australia",
    Pcode: "6605",
    Locality: "KONDUT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.804700",
    Latitude: "-30.717500"
  },
  {
    Country: "Australia",
    Pcode: "6606",
    Locality: "BALLIDU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.769700",
    Latitude: "-30.603900"
  },
  {
    Country: "Australia",
    Pcode: "6770",
    Locality: "HALLS CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "127.674300",
    Latitude: "-18.225200"
  },
  {
    Country: "Australia",
    Pcode: "6770",
    Locality: "MCBEATH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "127.688500",
    Latitude: "-18.242600"
  },
  {
    Country: "Australia",
    Pcode: "6770",
    Locality: "MUELLER RANGES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "126.874800",
    Latitude: "-18.476400"
  },
  {
    Country: "Australia",
    Pcode: "6770",
    Locality: "ORD RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "128.002600",
    Latitude: "-17.889300"
  },
  {
    Country: "Australia",
    Pcode: "6770",
    Locality: "PURNULULU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "128.533600",
    Latitude: "-17.461900"
  },
  {
    Country: "Australia",
    Pcode: "6770",
    Locality: "STURT CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "127.711400",
    Latitude: "-19.305800"
  },
  {
    Country: "Australia",
    Pcode: "6770",
    Locality: "TANAMI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "128.462500",
    Latitude: "-20.039300"
  },
  {
    Country: "Australia",
    Pcode: "6798",
    Locality: "CHRISTMAS ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6799",
    Locality: "HOME ISLAND COCOS (KEELING) ISLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6799",
    Locality: "WEST ISLAND COCOS (KEELING) ISLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6800",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6831",
    Locality: "PERTH ST GEORGES TCE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES Z5001-Z5584 & 5585-5831",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6837",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES A1-A48",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6838",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES B49-B96",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6839",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES C97-C140",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6840",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES D141-D200",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6841",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES E201-H629",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6842",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES J630-L923",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6843",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES M924-N1144",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6844",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES P1145-R1290",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6845",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES S1291-U1997",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6846",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES W1998-W2109",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6847",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES X2200-X2311",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6848",
    Locality: "PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GPO BOXES 9000-10000",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6849",
    Locality: "PERTH BC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 8000-8809",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6850",
    Locality: "CLOISTERS SQUARE PO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 7001-8207",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6865",
    Locality: "NORTHBRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6872",
    Locality: "WEST PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6892",
    Locality: "EAST PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6901",
    Locality: "WEST LEEDERVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6902",
    Locality: "LEEDERVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1-125",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6903",
    Locality: "LEEDERVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 126 - 642",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6904",
    Locality: "SUBIACO PO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6905",
    Locality: "NORTHLANDS PO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6906",
    Locality: "NORTH PERTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6909",
    Locality: "NEDLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6910",
    Locality: "CLAREMONT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6911",
    Locality: "COTTESLOE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6912",
    Locality: "MOSMAN PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6913",
    Locality: "WEMBLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6914",
    Locality: "BALCATTA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6915",
    Locality: "MOUNT HAWTHORN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6916",
    Locality: "OSBORNE PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "GARDEN PARK PO BOXES 700-762",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6916",
    Locality: "OSBORNE PARK DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1001-1960",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6917",
    Locality: "OSBORNE PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1-509",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6918",
    Locality: "INNALOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6919",
    Locality: "JOONDALUP DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6920",
    Locality: "NORTH BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6921",
    Locality: "KARRINYUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6922",
    Locality: "SCARBOROUGH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6923",
    Locality: "HILLARYS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6924",
    Locality: "GREENWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6925",
    Locality: "WALLISTON DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6926",
    Locality: "KALAMUNDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "PLENTY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.947400",
    Latitude: "-42.749200"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "ROSEGARLAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.952200",
    Latitude: "-42.710300"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "SORELL CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.134800",
    Latitude: "-42.785600"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "STRICKLAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.665100",
    Latitude: "-42.356200"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "STYX",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.739300",
    Latitude: "-42.772200"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "TARRALEAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.430000",
    Latitude: "-42.302000"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "TYENNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.654800",
    Latitude: "-42.723100"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "UXBRIDGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.857500",
    Latitude: "-42.772500"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "VICTORIA VALLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.724200",
    Latitude: "-42.264500"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "WAYATINAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.513900",
    Latitude: "-42.382700"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "WESTERWAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.798000",
    Latitude: "-42.689800"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "ADVENTURE BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.312800",
    Latitude: "-43.347600"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "ALLENS RIVULET",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.200000",
    Latitude: "-43.016400"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "ALONNAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.264300",
    Latitude: "-43.318400"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "APOLLO BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.288400",
    Latitude: "-43.164500"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "BARNES BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.363400",
    Latitude: "-43.120600"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "DENNES POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.350100",
    Latitude: "-43.074100"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "GORDON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.207200",
    Latitude: "-43.261200"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "GREAT BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.367400",
    Latitude: "-43.224100"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "KAOOTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.159500",
    Latitude: "-43.025300"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "KILLORA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.322800",
    Latitude: "-43.095200"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "LONGLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.183000",
    Latitude: "-42.971100"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "LUNAWANNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.237700",
    Latitude: "-43.365200"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "NORTH BRUNY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.347000",
    Latitude: "-43.164800"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "OYSTER COVE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.193000",
    Latitude: "-43.119600"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "PELVERATA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.120400",
    Latitude: "-43.050300"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "SANDFLY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.166900",
    Latitude: "-42.995900"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "SIMPSONS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.292200",
    Latitude: "-43.271700"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "SOUTH BRUNY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.276800",
    Latitude: "-43.390600"
  },
  {
    Country: "Australia",
    Pcode: "7150",
    Locality: "UPPER WOODSTOCK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.085000",
    Latitude: "-43.081700"
  },
  {
    Country: "Australia",
    Pcode: "7151",
    Locality: "CASEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "AUSTRALIAN ANTARCTIC TERRITORY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7151",
    Locality: "DAVIS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "AUSTRALIAN ANTARCTIC TERRITORY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7151",
    Locality: "HEARD ISLAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7151",
    Locality: "MACQUARIE ISLAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7151",
    Locality: "MAWSON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "AUSTRALIAN ANTARCTIC TERRITORY",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7151",
    Locality: "MCDONALD ISLANDS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7155",
    Locality: "KETTERING",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.222400",
    Latitude: "-43.127300"
  },
  {
    Country: "Australia",
    Pcode: "7162",
    Locality: "BIRCHS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.224500",
    Latitude: "-43.185800"
  },
  {
    Country: "Australia",
    Pcode: "7162",
    Locality: "WOODBRIDGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.215000",
    Latitude: "-43.160200"
  },
  {
    Country: "Australia",
    Pcode: "7163",
    Locality: "FLOWERPOT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.236900",
    Latitude: "-43.200900"
  },
  {
    Country: "Australia",
    Pcode: "7163",
    Locality: "MIDDLETON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.229000",
    Latitude: "-43.228700"
  },
  {
    Country: "Australia",
    Pcode: "7170",
    Locality: "ACTON PARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.482600",
    Latitude: "-42.871100"
  },
  {
    Country: "Australia",
    Pcode: "7170",
    Locality: "CAMBRIDGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.431700",
    Latitude: "-42.824400"
  },
  {
    Country: "Australia",
    Pcode: "7170",
    Locality: "MOUNT RUMNEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.441400",
    Latitude: "-42.855900"
  },
  {
    Country: "Australia",
    Pcode: "7170",
    Locality: "ROCHES BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.495500",
    Latitude: "-42.886500"
  },
  {
    Country: "Australia",
    Pcode: "7170",
    Locality: "SEVEN MILE BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.508800",
    Latitude: "-42.847700"
  },
  {
    Country: "Australia",
    Pcode: "7171",
    Locality: "MIDWAY POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.531800",
    Latitude: "-42.798300"
  },
  {
    Country: "Australia",
    Pcode: "7171",
    Locality: "PENNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.497700",
    Latitude: "-42.764800"
  },
  {
    Country: "Australia",
    Pcode: "7172",
    Locality: "NUGENT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.712000",
    Latitude: "-42.713300"
  },
  {
    Country: "Australia",
    Pcode: "7172",
    Locality: "ORIELTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.526900",
    Latitude: "-42.717800"
  },
  {
    Country: "Australia",
    Pcode: "7172",
    Locality: "PAWLEENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.592500",
    Latitude: "-42.719700"
  },
  {
    Country: "Australia",
    Pcode: "7172",
    Locality: "SORELL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.586300",
    Latitude: "-42.777700"
  },
  {
    Country: "Australia",
    Pcode: "7172",
    Locality: "WATTLE HILL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.671100",
    Latitude: "-42.767300"
  },
  {
    Country: "Australia",
    Pcode: "7173",
    Locality: "CARLTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.651300",
    Latitude: "-42.860700"
  },
  {
    Country: "Australia",
    Pcode: "7173",
    Locality: "CARLTON RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.713900",
    Latitude: "-42.858700"
  },
  {
    Country: "Australia",
    Pcode: "7173",
    Locality: "CONNELLYS MARSH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.727300",
    Latitude: "-42.886800"
  },
  {
    Country: "Australia",
    Pcode: "7173",
    Locality: "DODGES FERRY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.630900",
    Latitude: "-42.854300"
  },
  {
    Country: "Australia",
    Pcode: "7173",
    Locality: "FORCETT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.664900",
    Latitude: "-42.812700"
  },
  {
    Country: "Australia",
    Pcode: "7173",
    Locality: "LEWISHAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.613600",
    Latitude: "-42.827900"
  },
  {
    Country: "Australia",
    Pcode: "7173",
    Locality: "PRIMROSE SANDS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.676400",
    Latitude: "-42.887400"
  },
  {
    Country: "Australia",
    Pcode: "7174",
    Locality: "COPPING",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.769400",
    Latitude: "-42.828700"
  },
  {
    Country: "Australia",
    Pcode: "7175",
    Locality: "BREAM CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.877800",
    Latitude: "-42.744300"
  },
  {
    Country: "Australia",
    Pcode: "7175",
    Locality: "MARION BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.865000",
    Latitude: "-42.816100"
  },
  {
    Country: "Australia",
    Pcode: "7176",
    Locality: "KELLEVIE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.791400",
    Latitude: "-42.759000"
  },
  {
    Country: "Australia",
    Pcode: "7177",
    Locality: "BOOMER BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.835300",
    Latitude: "-42.848500"
  },
  {
    Country: "Australia",
    Pcode: "7177",
    Locality: "DUNALLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.778500",
    Latitude: "-42.883500"
  },
  {
    Country: "Australia",
    Pcode: "7178",
    Locality: "MURDUNNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.922900",
    Latitude: "-42.938500"
  },
  {
    Country: "Australia",
    Pcode: "7179",
    Locality: "EAGLEHAWK NECK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.888900",
    Latitude: "-43.010500"
  },
  {
    Country: "Australia",
    Pcode: "7180",
    Locality: "TARANNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.865400",
    Latitude: "-43.068300"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "BEACONSFIELD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.782700",
    Latitude: "-41.213500"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "BEAUTY POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.798900",
    Latitude: "-41.159200"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "CLARENCE POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.790500",
    Latitude: "-41.131100"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "FLOWERY GULLY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.818400",
    Latitude: "-41.247200"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "GREENS BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.739200",
    Latitude: "-41.102100"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "KAYENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.891400",
    Latitude: "-41.190500"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "KELSO",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.774600",
    Latitude: "-41.098000"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "ROWELLA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.897100",
    Latitude: "-41.170300"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "SIDMOUTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.872300",
    Latitude: "-41.230000"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "YORK TOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.726500",
    Latitude: "-41.154400"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "BLACKWALL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.966000",
    Latitude: "-41.301400"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "DEVIOT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.920300",
    Latitude: "-41.241200"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "EXETER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.930600",
    Latitude: "-41.302000"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "FRANKFORD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.733200",
    Latitude: "-41.320400"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "GLENGARRY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.862900",
    Latitude: "-41.344700"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "HOLWELL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.768100",
    Latitude: "-41.273300"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "LANENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.967900",
    Latitude: "-41.316700"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "LOIRA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.897100",
    Latitude: "-41.270900"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "NOTLEY HILLS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.921200",
    Latitude: "-41.357900"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "ROBIGANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.940300",
    Latitude: "-41.264700"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "SWAN POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.965000",
    Latitude: "-41.261100"
  },
  {
    Country: "Australia",
    Pcode: "7275",
    Locality: "WINKLEIGH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.851600",
    Latitude: "-41.275100"
  },
  {
    Country: "Australia",
    Pcode: "7276",
    Locality: "GRAVELLY BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.962800",
    Latitude: "-41.283000"
  },
  {
    Country: "Australia",
    Pcode: "7277",
    Locality: "BRIDGENORTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.954900",
    Latitude: "-41.380600"
  },
  {
    Country: "Australia",
    Pcode: "7277",
    Locality: "GRINDELWALD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.006300",
    Latitude: "-41.352300"
  },
  {
    Country: "Australia",
    Pcode: "7277",
    Locality: "LEGANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.039100",
    Latitude: "-41.373600"
  },
  {
    Country: "Australia",
    Pcode: "7277",
    Locality: "ROSEVEARS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.974700",
    Latitude: "-41.336700"
  },
  {
    Country: "Australia",
    Pcode: "7290",
    Locality: "HADSPEN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.063300",
    Latitude: "-41.510500"
  },
  {
    Country: "Australia",
    Pcode: "7291",
    Locality: "CARRICK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.029000",
    Latitude: "-41.541600"
  },
  {
    Country: "Australia",
    Pcode: "7292",
    Locality: "HAGLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.913900",
    Latitude: "-41.523000"
  },
  {
    Country: "Australia",
    Pcode: "7292",
    Locality: "QUAMBY BEND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.866600",
    Latitude: "-41.479400"
  },
  {
    Country: "Australia",
    Pcode: "7292",
    Locality: "ROSEVALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.918200",
    Latitude: "-41.414900"
  },
  {
    Country: "Australia",
    Pcode: "7292",
    Locality: "SELBOURNE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.879600",
    Latitude: "-41.405300"
  },
  {
    Country: "Australia",
    Pcode: "7292",
    Locality: "WESTWOOD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.997100",
    Latitude: "-41.480700"
  },
  {
    Country: "Australia",
    Pcode: "7300",
    Locality: "DEVON HILLS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.180300",
    Latitude: "-41.550100"
  },
  {
    Country: "Australia",
    Pcode: "7300",
    Locality: "PERTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.199100",
    Latitude: "-41.602200"
  },
  {
    Country: "Australia",
    Pcode: "7300",
    Locality: "POWRANNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.265800",
    Latitude: "-41.685400"
  },
  {
    Country: "Australia",
    Pcode: "7301",
    Locality: "BISHOPSBOURNE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.992800",
    Latitude: "-41.611600"
  },
  {
    Country: "Australia",
    Pcode: "7301",
    Locality: "BLACKWOOD CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.909200",
    Latitude: "-41.750800"
  },
  {
    Country: "Australia",
    Pcode: "7301",
    Locality: "LIFFEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.804000",
    Latitude: "-41.692900"
  },
  {
    Country: "Australia",
    Pcode: "7301",
    Locality: "LONGFORD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.127200",
    Latitude: "-41.578600"
  },
  {
    Country: "Australia",
    Pcode: "7301",
    Locality: "TOIBERRY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.033300",
    Latitude: "-41.622400"
  },
  {
    Country: "Australia",
    Pcode: "7302",
    Locality: "BRACKNELL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.920800",
    Latitude: "-41.660000"
  },
  {
    Country: "Australia",
    Pcode: "7302",
    Locality: "CRESSY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.089100",
    Latitude: "-41.792600"
  },
  {
    Country: "Australia",
    Pcode: "7302",
    Locality: "POATINA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.931500",
    Latitude: "-41.800500"
  },
  {
    Country: "Australia",
    Pcode: "7303",
    Locality: "BIRRALEE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.812200",
    Latitude: "-41.403100"
  },
  {
    Country: "Australia",
    Pcode: "7303",
    Locality: "CLUAN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.836300",
    Latitude: "-41.607200"
  },
  {
    Country: "Australia",
    Pcode: "7303",
    Locality: "EXTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.745700",
    Latitude: "-41.512300"
  },
  {
    Country: "Australia",
    Pcode: "7303",
    Locality: "OAKS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.966200",
    Latitude: "-41.583800"
  },
  {
    Country: "Australia",
    Pcode: "7303",
    Locality: "OSMASTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.767600",
    Latitude: "-41.570400"
  },
  {
    Country: "Australia",
    Pcode: "7303",
    Locality: "WESTBURY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.827200",
    Latitude: "-41.502300"
  },
  {
    Country: "Australia",
    Pcode: "7303",
    Locality: "WHITEMORE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.893600",
    Latitude: "-41.584500"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "BRANDUM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.673700",
    Latitude: "-41.826300"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "BREONA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.703500",
    Latitude: "-41.785600"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "CAVESIDE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.436100",
    Latitude: "-41.617500"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "CENTRAL PLATEAU",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.540100",
    Latitude: "-41.850500"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "CHUDLEIGH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.485700",
    Latitude: "-41.549400"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "DAIRY PLAINS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.519300",
    Latitude: "-41.586100"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "DELORAINE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.655700",
    Latitude: "-41.535500"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "DOCTORS POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.685900",
    Latitude: "-41.790900"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "DUNORLAN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.548700",
    Latitude: "-41.494800"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "ELIZABETH TOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.564000",
    Latitude: "-41.444100"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "GOLDEN VALLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.746400",
    Latitude: "-41.646100"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "JACKEYS MARSH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.674500",
    Latitude: "-41.701400"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "KIMBERLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.473700",
    Latitude: "-41.422600"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "LIENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.207200",
    Latitude: "-41.542600"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "MAYBERRY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.283600",
    Latitude: "-41.551400"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "MEANDER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.585100",
    Latitude: "-41.680200"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "MERSEY FOREST",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.197800",
    Latitude: "-41.692900"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "MOLE CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.395100",
    Latitude: "-41.567100"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "MOLTEMA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.528200",
    Latitude: "-41.444900"
  },
  {
    Country: "Australia",
    Pcode: "7470",
    Locality: "ROSEBERY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.511600",
    Latitude: "-41.794400"
  },
  {
    Country: "Australia",
    Pcode: "8001",
    Locality: "MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8002",
    Locality: "EAST MELBOURNE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8003",
    Locality: "COLLINS STREET EAST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8004",
    Locality: "ST KILDA ROAD",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8005",
    Locality: "WORLD TRADE CENTRE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8006",
    Locality: "ABECKETT STREET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8007",
    Locality: "COLLINS STREET WEST",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8008",
    Locality: "ST KILDA ROAD CENTRAL",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8009",
    Locality: "FLINDERS LANE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8010",
    Locality: "LAW COURTS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8011",
    Locality: "LITTLE LONSDALE STREET",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "8012",
    Locality: "DOCKLANDS",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "9726",
    Locality: "GOLD COAST MC",
    State: "Queensland",
    StateAbbrev: "QLD",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "9999",
    Locality: "NORTH POLE",
    State: "Victoria",
    StateAbbrev: "VIC",
    Comments: "SANTA",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6514",
    Locality: "LEEMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.021300",
    Latitude: "-29.987500"
  },
  {
    Country: "Australia",
    Pcode: "6515",
    Locality: "COOROW",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.017900",
    Latitude: "-29.880100"
  },
  {
    Country: "Australia",
    Pcode: "6515",
    Locality: "EGANU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.757600",
    Latitude: "-30.030500"
  },
  {
    Country: "Australia",
    Pcode: "6515",
    Locality: "MARCHAGEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.164700",
    Latitude: "-30.017800"
  },
  {
    Country: "Australia",
    Pcode: "6515",
    Locality: "WADDY FOREST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.196300",
    Latitude: "-29.852000"
  },
  {
    Country: "Australia",
    Pcode: "6516",
    Locality: "JURIEN BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.111300",
    Latitude: "-30.309000"
  },
  {
    Country: "Australia",
    Pcode: "6517",
    Locality: "CARNAMAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.954200",
    Latitude: "-29.712200"
  },
  {
    Country: "Australia",
    Pcode: "6518",
    Locality: "ENEABBA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.283400",
    Latitude: "-29.817400"
  },
  {
    Country: "Australia",
    Pcode: "6518",
    Locality: "WARRADARGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.317000",
    Latitude: "-30.043600"
  },
  {
    Country: "Australia",
    Pcode: "6519",
    Locality: "ARRINO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.548100",
    Latitude: "-29.457500"
  },
  {
    Country: "Australia",
    Pcode: "6519",
    Locality: "ARROWSMITH EAST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.366200",
    Latitude: "-29.542800"
  },
  {
    Country: "Australia",
    Pcode: "6519",
    Locality: "DUDAWA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.683500",
    Latitude: "-29.409100"
  },
  {
    Country: "Australia",
    Pcode: "6519",
    Locality: "KADATHINNI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.585000",
    Latitude: "-29.635800"
  },
  {
    Country: "Australia",
    Pcode: "6519",
    Locality: "THREE SPRINGS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.764100",
    Latitude: "-29.532600"
  },
  {
    Country: "Australia",
    Pcode: "6519",
    Locality: "WOMARDEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.885800",
    Latitude: "-29.500000"
  },
  {
    Country: "Australia",
    Pcode: "6521",
    Locality: "BADGINGARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.630100",
    Latitude: "-30.444000"
  },
  {
    Country: "Australia",
    Pcode: "6521",
    Locality: "BOOTHENDARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.639700",
    Latitude: "-30.227000"
  },
  {
    Country: "Australia",
    Pcode: "6521",
    Locality: "GREY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.138000",
    Latitude: "-30.662200"
  },
  {
    Country: "Australia",
    Pcode: "6521",
    Locality: "HILL RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.318300",
    Latitude: "-30.257800"
  },
  {
    Country: "Australia",
    Pcode: "6521",
    Locality: "NAMBUNG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.212500",
    Latitude: "-30.531100"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "BUNDANOON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.484500",
    Latitude: "-29.278700"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "HOLMWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.625800",
    Latitude: "-29.062400"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "IKEWA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.524200",
    Latitude: "-29.179400"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "LOCKIER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.356700",
    Latitude: "-29.182700"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "MINGENEW",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.437000",
    Latitude: "-29.198900"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "MOORIARY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.347800",
    Latitude: "-29.276800"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "MOUNT BUDD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.673300",
    Latitude: "-29.173900"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "NANGETTY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.402600",
    Latitude: "-29.007900"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "YANDANOOKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.648500",
    Latitude: "-29.301900"
  },
  {
    Country: "Australia",
    Pcode: "6522",
    Locality: "YARRAGADEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.398100",
    Latitude: "-29.130500"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "ALLANOOKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.100200",
    Latitude: "-29.048100"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "ARROWSMITH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.105600",
    Latitude: "-29.551900"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "BONNIEFIELD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.935200",
    Latitude: "-29.209300"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "BOOKARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.897500",
    Latitude: "-29.094300"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "DONGARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.928100",
    Latitude: "-29.243700"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "IRWIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.072300",
    Latitude: "-29.224200"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "MILO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.180300",
    Latitude: "-29.274900"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "MOUNT ADAMS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.105400",
    Latitude: "-29.389200"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "MOUNT HORNER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.155900",
    Latitude: "-29.145600"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "PORT DENISON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.935500",
    Latitude: "-29.301500"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "SPRINGFIELD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.954700",
    Latitude: "-29.287600"
  },
  {
    Country: "Australia",
    Pcode: "6525",
    Locality: "YARDARINO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.041500",
    Latitude: "-29.217300"
  },
  {
    Country: "Australia",
    Pcode: "6528",
    Locality: "MOUNT HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.900400",
    Latitude: "-28.996400"
  },
  {
    Country: "Australia",
    Pcode: "6528",
    Locality: "SOUTH GREENOUGH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.822600",
    Latitude: "-29.010100"
  },
  {
    Country: "Australia",
    Pcode: "6528",
    Locality: "WALKAWAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.851300",
    Latitude: "-28.949700"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "BEACHLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.601300",
    Latitude: "-28.786200"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "BERESFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.618700",
    Latitude: "-28.756900"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "BLUFF POINT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.623000",
    Latitude: "-28.737400"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "GERALDTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.614300",
    Latitude: "-28.775700"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "HOUTMAN ABROLHOS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.808500",
    Latitude: "-28.682200"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "KARLOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.641300",
    Latitude: "-28.801400"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "MAHOMETS FLATS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.612300",
    Latitude: "-28.792600"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "MERU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.686100",
    Latitude: "-28.802100"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "MORESBY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.669600",
    Latitude: "-28.735500"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "MOUNT TARCOOLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.627200",
    Latitude: "-28.801000"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "RANGEWAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.630400",
    Latitude: "-28.787500"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "EAST CARNARVON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.674700",
    Latitude: "-24.864200"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "GILROYD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.147200",
    Latitude: "-25.893200"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "GREYS PLAIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.693100",
    Latitude: "-24.885600"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "INGGARDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.221500",
    Latitude: "-24.982500"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "KENNEDY RANGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.809900",
    Latitude: "-24.529300"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "KINGSFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.695600",
    Latitude: "-24.864100"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "LYNDON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.213100",
    Latitude: "-23.295000"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "MACLEOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.743200",
    Latitude: "-24.145200"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "MASSEY BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.672700",
    Latitude: "-24.903100"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "MINILYA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.619300",
    Latitude: "-23.850300"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "MORGANTOWN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.658000",
    Latitude: "-24.871900"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "NINGALOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.716000",
    Latitude: "-22.702300"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "NORTH PLANTATIONS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.728500",
    Latitude: "-24.836300"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "SOUTH CARNARVON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.656200",
    Latitude: "-24.895900"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "SOUTH PLANTATIONS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.756200",
    Latitude: "-24.842300"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "TALISKER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.087700",
    Latitude: "-26.412500"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "WOODLEIGH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.771400",
    Latitude: "-26.190500"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "WOORAMEL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.505300",
    Latitude: "-25.688500"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "YALARDY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.256700",
    Latitude: "-26.102800"
  },
  {
    Country: "Australia",
    Pcode: "6701",
    Locality: "YANDOO CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.307600",
    Latitude: "-24.291100"
  },
  {
    Country: "Australia",
    Pcode: "6705",
    Locality: "EAST LYONS RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.203600",
    Latitude: "-24.323900"
  },
  {
    Country: "Australia",
    Pcode: "6705",
    Locality: "GASCOYNE JUNCTION",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.201500",
    Latitude: "-25.045400"
  },
  {
    Country: "Australia",
    Pcode: "6705",
    Locality: "GASCOYNE RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.316300",
    Latitude: "-25.229100"
  },
  {
    Country: "Australia",
    Pcode: "6705",
    Locality: "WEST LYONS RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.095600",
    Latitude: "-24.271500"
  },
  {
    Country: "Australia",
    Pcode: "6707",
    Locality: "CAPE RANGE NATIONAL PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.920200",
    Latitude: "-22.117400"
  },
  {
    Country: "Australia",
    Pcode: "6707",
    Locality: "EXMOUTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.113600",
    Latitude: "-21.947000"
  },
  {
    Country: "Australia",
    Pcode: "6707",
    Locality: "EXMOUTH GULF",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.199100",
    Latitude: "-22.637000"
  },
  {
    Country: "Australia",
    Pcode: "6707",
    Locality: "LEARMONTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.021300",
    Latitude: "-22.200800"
  },
  {
    Country: "Australia",
    Pcode: "6707",
    Locality: "NORTH WEST CAPE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.077700",
    Latitude: "-21.940100"
  },
  {
    Country: "Australia",
    Pcode: "6710",
    Locality: "CANE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.875700",
    Latitude: "-22.134000"
  },
  {
    Country: "Australia",
    Pcode: "6710",
    Locality: "ONSLOW",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.155000",
    Latitude: "-21.675500"
  },
  {
    Country: "Australia",
    Pcode: "6710",
    Locality: "PEEDAMULLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.477200",
    Latitude: "-21.722900"
  },
  {
    Country: "Australia",
    Pcode: "6710",
    Locality: "TALANDJI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.037900",
    Latitude: "-22.048400"
  },
  {
    Country: "Australia",
    Pcode: "6710",
    Locality: "YANNARIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.032000",
    Latitude: "-22.918000"
  },
  {
    Country: "Australia",
    Pcode: "6711",
    Locality: "THEVENARD ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6712",
    Locality: "BARROW ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.494900",
    Latitude: "-20.738000"
  },
  {
    Country: "Australia",
    Pcode: "6713",
    Locality: "DAMPIER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.710500",
    Latitude: "-20.668900"
  },
  {
    Country: "Australia",
    Pcode: "6713",
    Locality: "DAMPIER ARCHIPELAGO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.556700",
    Latitude: "-20.574200"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "ANTONYMYRE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.077600",
    Latitude: "-20.624000"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "BALLA BALLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.706500",
    Latitude: "-20.651700"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "BAYNTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.801800",
    Latitude: "-20.751800"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "BULGARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.858500",
    Latitude: "-20.725700"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "BURRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.793400",
    Latitude: "-20.622000"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "CLEAVERVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.024900",
    Latitude: "-20.653400"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "COOYA POOYA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.031500",
    Latitude: "-20.957100"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "GAP RIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.778600",
    Latitude: "-20.734800"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "GNOOREA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.407300",
    Latitude: "-20.834600"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "KARRATHA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.845500",
    Latitude: "-20.734800"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "KARRATHA INDUSTRIAL ESTATE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.878300",
    Latitude: "-20.761500"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "MAITLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.729900",
    Latitude: "-21.012100"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "MARDIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.785100",
    Latitude: "-21.263300"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "MILLARS WELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.817500",
    Latitude: "-20.733500"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "MOUNT ANKETELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.004400",
    Latitude: "-20.718700"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "MULATAGA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.871900",
    Latitude: "-20.738900"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "NICKOL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.801600",
    Latitude: "-20.738500"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "PEGS CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.834400",
    Latitude: "-20.732100"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "SHERLOCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.705800",
    Latitude: "-21.058600"
  },
  {
    Country: "Australia",
    Pcode: "6714",
    Locality: "STOVE HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.837800",
    Latitude: "-20.768500"
  },
  {
    Country: "Australia",
    Pcode: "6716",
    Locality: "FORTESCUE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.728400",
    Latitude: "-21.625700"
  },
  {
    Country: "Australia",
    Pcode: "6716",
    Locality: "HAMERSLEY RANGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.844900",
    Latitude: "-22.095000"
  },
  {
    Country: "Australia",
    Pcode: "6716",
    Locality: "MILLSTREAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.427700",
    Latitude: "-21.392100"
  },
  {
    Country: "Australia",
    Pcode: "6716",
    Locality: "PANNAWONICA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.336200",
    Latitude: "-21.635400"
  },
  {
    Country: "Australia",
    Pcode: "6718",
    Locality: "ROEBOURNE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.162600",
    Latitude: "-20.754800"
  },
  {
    Country: "Australia",
    Pcode: "6718",
    Locality: "WHIM CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.832400",
    Latitude: "-20.856700"
  },
  {
    Country: "Australia",
    Pcode: "6720",
    Locality: "COSSACK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.225100",
    Latitude: "-20.683000"
  },
  {
    Country: "Australia",
    Pcode: "7006",
    Locality: "SANDY BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7007",
    Locality: "MOUNT NELSON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.321300",
    Latitude: "-42.921100"
  },
  {
    Country: "Australia",
    Pcode: "7007",
    Locality: "TOLMANS HILL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.304500",
    Latitude: "-42.913300"
  },
  {
    Country: "Australia",
    Pcode: "7008",
    Locality: "LENAH VALLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.275600",
    Latitude: "-42.869600"
  },
  {
    Country: "Australia",
    Pcode: "7008",
    Locality: "NEW TOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.305600",
    Latitude: "-42.856100"
  },
  {
    Country: "Australia",
    Pcode: "7009",
    Locality: "DERWENT PARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.294900",
    Latitude: "-42.833500"
  },
  {
    Country: "Australia",
    Pcode: "7009",
    Locality: "LUTANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.313500",
    Latitude: "-42.838700"
  },
  {
    Country: "Australia",
    Pcode: "7009",
    Locality: "MOONAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.299500",
    Latitude: "-42.847400"
  },
  {
    Country: "Australia",
    Pcode: "7009",
    Locality: "WEST MOONAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.281500",
    Latitude: "-42.849500"
  },
  {
    Country: "Australia",
    Pcode: "7010",
    Locality: "DOWSING POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.298500",
    Latitude: "-42.821700"
  },
  {
    Country: "Australia",
    Pcode: "7010",
    Locality: "GLENORCHY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.256100",
    Latitude: "-42.844100"
  },
  {
    Country: "Australia",
    Pcode: "7010",
    Locality: "GOODWOOD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.294000",
    Latitude: "-42.826800"
  },
  {
    Country: "Australia",
    Pcode: "7010",
    Locality: "MONTROSE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.242400",
    Latitude: "-42.830800"
  },
  {
    Country: "Australia",
    Pcode: "7010",
    Locality: "ROSETTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.251800",
    Latitude: "-42.820900"
  },
  {
    Country: "Australia",
    Pcode: "7011",
    Locality: "AUSTINS FERRY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.248100",
    Latitude: "-42.774800"
  },
  {
    Country: "Australia",
    Pcode: "7011",
    Locality: "BERRIEDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.225400",
    Latitude: "-42.811400"
  },
  {
    Country: "Australia",
    Pcode: "7011",
    Locality: "CHIGWELL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.229000",
    Latitude: "-42.807300"
  },
  {
    Country: "Australia",
    Pcode: "7011",
    Locality: "CLAREMONT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.222800",
    Latitude: "-42.785300"
  },
  {
    Country: "Australia",
    Pcode: "7012",
    Locality: "COLLINSVALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.169000",
    Latitude: "-42.840300"
  },
  {
    Country: "Australia",
    Pcode: "7012",
    Locality: "GLENLUSK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.201700",
    Latitude: "-42.816400"
  },
  {
    Country: "Australia",
    Pcode: "7015",
    Locality: "GEILSTON BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.354200",
    Latitude: "-42.829200"
  },
  {
    Country: "Australia",
    Pcode: "7015",
    Locality: "LINDISFARNE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.375100",
    Latitude: "-42.841800"
  },
  {
    Country: "Australia",
    Pcode: "7015",
    Locality: "ROSE BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.358200",
    Latitude: "-42.858500"
  },
  {
    Country: "Australia",
    Pcode: "7016",
    Locality: "RISDON VALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.377600",
    Latitude: "-42.807600"
  },
  {
    Country: "Australia",
    Pcode: "7017",
    Locality: "GRASSTREE HILL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.370300",
    Latitude: "-42.784800"
  },
  {
    Country: "Australia",
    Pcode: "7017",
    Locality: "HONEYWOOD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.283500",
    Latitude: "-42.724700"
  },
  {
    Country: "Australia",
    Pcode: "7017",
    Locality: "OLD BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.291800",
    Latitude: "-42.758300"
  },
  {
    Country: "Australia",
    Pcode: "7017",
    Locality: "OTAGO",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.297100",
    Latitude: "-42.799900"
  },
  {
    Country: "Australia",
    Pcode: "7017",
    Locality: "RISDON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.329400",
    Latitude: "-42.792900"
  },
  {
    Country: "Australia",
    Pcode: "7017",
    Locality: "TEA TREE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.340800",
    Latitude: "-42.679700"
  },
  {
    Country: "Australia",
    Pcode: "7018",
    Locality: "BELLERIVE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.378800",
    Latitude: "-42.872300"
  },
  {
    Country: "Australia",
    Pcode: "7018",
    Locality: "HOWRAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.407900",
    Latitude: "-42.879600"
  },
  {
    Country: "Australia",
    Pcode: "7018",
    Locality: "MONTAGU BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.357500",
    Latitude: "-42.864700"
  },
  {
    Country: "Australia",
    Pcode: "7018",
    Locality: "MORNINGTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.399100",
    Latitude: "-42.860800"
  },
  {
    Country: "Australia",
    Pcode: "7018",
    Locality: "ROSNY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.356700",
    Latitude: "-42.871000"
  },
  {
    Country: "Australia",
    Pcode: "7018",
    Locality: "ROSNY PARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.367200",
    Latitude: "-42.865800"
  },
  {
    Country: "Australia",
    Pcode: "7018",
    Locality: "TRANMERE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.416600",
    Latitude: "-42.923400"
  },
  {
    Country: "Australia",
    Pcode: "7018",
    Locality: "WARRANE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.389600",
    Latitude: "-42.851400"
  },
  {
    Country: "Australia",
    Pcode: "7019",
    Locality: "CLARENDON VALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.448800",
    Latitude: "-42.887400"
  },
  {
    Country: "Australia",
    Pcode: "7019",
    Locality: "OAKDOWNS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.455600",
    Latitude: "-42.901600"
  },
  {
    Country: "Australia",
    Pcode: "7019",
    Locality: "ROKEBY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.437300",
    Latitude: "-42.904000"
  },
  {
    Country: "Australia",
    Pcode: "7020",
    Locality: "CLIFTON BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.543000",
    Latitude: "-42.980900"
  },
  {
    Country: "Australia",
    Pcode: "7020",
    Locality: "SANDFORD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.490800",
    Latitude: "-42.976100"
  },
  {
    Country: "Australia",
    Pcode: "7021",
    Locality: "LAUDERDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.485600",
    Latitude: "-42.908300"
  },
  {
    Country: "Australia",
    Pcode: "7022",
    Locality: "SOUTH ARM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.422000",
    Latitude: "-43.026000"
  },
  {
    Country: "Australia",
    Pcode: "7023",
    Locality: "OPOSSUM BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.405700",
    Latitude: "-42.993500"
  },
  {
    Country: "Australia",
    Pcode: "7024",
    Locality: "CREMORNE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.528900",
    Latitude: "-42.952600"
  },
  {
    Country: "Australia",
    Pcode: "7025",
    Locality: "DULCOT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.402200",
    Latitude: "-42.787700"
  },
  {
    Country: "Australia",
    Pcode: "7025",
    Locality: "RICHMOND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.417200",
    Latitude: "-42.736300"
  },
  {
    Country: "Australia",
    Pcode: "7026",
    Locality: "CAMPANIA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.432300",
    Latitude: "-42.617600"
  },
  {
    Country: "Australia",
    Pcode: "7027",
    Locality: "COLEBROOK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.376100",
    Latitude: "-42.510700"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "APSLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.140300",
    Latitude: "-42.407800"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "ARTHURS LAKE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.927500",
    Latitude: "-41.985600"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "BAGDAD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.227200",
    Latitude: "-42.608100"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "BOTHWELL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.982600",
    Latitude: "-42.318300"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "BRIDGEWATER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.235400",
    Latitude: "-42.730600"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "BRIGHTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.222100",
    Latitude: "-42.698300"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "BROADMARSH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.120900",
    Latitude: "-42.652200"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "CRAMPS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.826000",
    Latitude: "-41.874400"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "DROMEDARY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.147400",
    Latitude: "-42.732300"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "DYSART",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.134700",
    Latitude: "-42.551100"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "ELDERSLIE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.074900",
    Latitude: "-42.599200"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "FLINTSTONE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.858400",
    Latitude: "-41.996700"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "GAGEBROOK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.271200",
    Latitude: "-42.742900"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "GRANTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.193400",
    Latitude: "-42.763500"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "HERDSMANS COVE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.265200",
    Latitude: "-42.750800"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "HERMITAGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.847000",
    Latitude: "-42.263400"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "INTERLAKEN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.142400",
    Latitude: "-42.158600"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "JERICHO",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.288600",
    Latitude: "-42.389800"
  },
  {
    Country: "Australia",
    Pcode: "7030",
    Locality: "KEMPTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.220500",
    Latitude: "-42.531400"
  },
  {
    Country: "Australia",
    Pcode: "7182",
    Locality: "CAPE PILLAR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.930200",
    Latitude: "-43.183300"
  },
  {
    Country: "Australia",
    Pcode: "7182",
    Locality: "FORTESCUE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.939400",
    Latitude: "-43.108900"
  },
  {
    Country: "Australia",
    Pcode: "7182",
    Locality: "PORT ARTHUR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.838400",
    Latitude: "-43.146900"
  },
  {
    Country: "Australia",
    Pcode: "7183",
    Locality: "HIGHCROFT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.779200",
    Latitude: "-43.153300"
  },
  {
    Country: "Australia",
    Pcode: "7184",
    Locality: "CAPE RAOUL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.776800",
    Latitude: "-43.206300"
  },
  {
    Country: "Australia",
    Pcode: "7184",
    Locality: "NUBEENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.762800",
    Latitude: "-43.095600"
  },
  {
    Country: "Australia",
    Pcode: "7184",
    Locality: "STORMLEA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.780000",
    Latitude: "-43.186500"
  },
  {
    Country: "Australia",
    Pcode: "7184",
    Locality: "WHITE BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.721500",
    Latitude: "-43.140800"
  },
  {
    Country: "Australia",
    Pcode: "7185",
    Locality: "PREMAYDENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.752500",
    Latitude: "-43.053400"
  },
  {
    Country: "Australia",
    Pcode: "7186",
    Locality: "SALTWATER RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.707800",
    Latitude: "-43.039300"
  },
  {
    Country: "Australia",
    Pcode: "7186",
    Locality: "SLOPING MAIN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.677200",
    Latitude: "-43.016400"
  },
  {
    Country: "Australia",
    Pcode: "7187",
    Locality: "KOONYA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.816100",
    Latitude: "-43.078300"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "APSLAWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.174100",
    Latitude: "-41.968800"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "BUCKLAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.736600",
    Latitude: "-42.561100"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "CRANBROOK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.073700",
    Latitude: "-41.960400"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "DOLPHIN SANDS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.156100",
    Latitude: "-42.090700"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "LITTLE SWANPORT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.935300",
    Latitude: "-42.312800"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "ORFORD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.869400",
    Latitude: "-42.569700"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "PONTYPOOL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.960100",
    Latitude: "-42.326800"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "RHEBAN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.897700",
    Latitude: "-42.663100"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "ROCKY HILLS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.006500",
    Latitude: "-42.218300"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "RUNNYMEDE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.575100",
    Latitude: "-42.637000"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "SPRING BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.895200",
    Latitude: "-42.594600"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "SWANSEA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.018400",
    Latitude: "-42.102800"
  },
  {
    Country: "Australia",
    Pcode: "7190",
    Locality: "TRIABUNNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.932100",
    Latitude: "-42.468700"
  },
  {
    Country: "Australia",
    Pcode: "7209",
    Locality: "ROSS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.581800",
    Latitude: "-42.095800"
  },
  {
    Country: "Australia",
    Pcode: "7209",
    Locality: "TOOMS LAKE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.833100",
    Latitude: "-42.185500"
  },
  {
    Country: "Australia",
    Pcode: "7210",
    Locality: "CAMPBELL TOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.461800",
    Latitude: "-41.921000"
  },
  {
    Country: "Australia",
    Pcode: "7210",
    Locality: "LAKE LEAKE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.846500",
    Latitude: "-42.023200"
  },
  {
    Country: "Australia",
    Pcode: "7211",
    Locality: "CLEVELAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.382500",
    Latitude: "-41.808400"
  },
  {
    Country: "Australia",
    Pcode: "7211",
    Locality: "CONARA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.501400",
    Latitude: "-41.771200"
  },
  {
    Country: "Australia",
    Pcode: "7211",
    Locality: "EPPING FOREST",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.353300",
    Latitude: "-41.760700"
  },
  {
    Country: "Australia",
    Pcode: "7212",
    Locality: "BEN LOMOND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.652100",
    Latitude: "-41.561000"
  },
  {
    Country: "Australia",
    Pcode: "7212",
    Locality: "BLESSINGTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.428600",
    Latitude: "-41.484800"
  },
  {
    Country: "Australia",
    Pcode: "7212",
    Locality: "BURNS CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.479900",
    Latitude: "-41.448700"
  },
  {
    Country: "Australia",
    Pcode: "7212",
    Locality: "DEDDINGTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.469000",
    Latitude: "-41.602200"
  },
  {
    Country: "Australia",
    Pcode: "7212",
    Locality: "EVANDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.320200",
    Latitude: "-41.568800"
  },
  {
    Country: "Australia",
    Pcode: "7212",
    Locality: "NILE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.397700",
    Latitude: "-41.683400"
  },
  {
    Country: "Australia",
    Pcode: "7212",
    Locality: "UPPER BLESSINGTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.578600",
    Latitude: "-41.445900"
  },
  {
    Country: "Australia",
    Pcode: "7212",
    Locality: "WESTERN JUNCTION",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.212100",
    Latitude: "-41.550900"
  },
  {
    Country: "Australia",
    Pcode: "7213",
    Locality: "AVOCA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.711400",
    Latitude: "-41.793500"
  },
  {
    Country: "Australia",
    Pcode: "7213",
    Locality: "ROSSARDEN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.690300",
    Latitude: "-41.659100"
  },
  {
    Country: "Australia",
    Pcode: "7213",
    Locality: "ROYAL GEORGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.990400",
    Latitude: "-41.804600"
  },
  {
    Country: "Australia",
    Pcode: "7213",
    Locality: "STORYS CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "7214",
    Locality: "FINGAL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.936800",
    Latitude: "-41.657000"
  },
  {
    Country: "Australia",
    Pcode: "7214",
    Locality: "MANGANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.839500",
    Latitude: "-41.582100"
  },
  {
    Country: "Australia",
    Pcode: "7214",
    Locality: "MATHINNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.986800",
    Latitude: "-41.443600"
  },
  {
    Country: "Australia",
    Pcode: "7214",
    Locality: "UPPER ESK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.715200",
    Latitude: "-41.449200"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "BEAUMARIS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.263800",
    Latitude: "-41.416400"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "BICHENO",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.252800",
    Latitude: "-41.894900"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "CHAIN OF LAGOONS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.274000",
    Latitude: "-41.658200"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "COLES BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.287400",
    Latitude: "-42.144900"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "CORNWALL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.138300",
    Latitude: "-41.557300"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "DOUGLAS RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.257000",
    Latitude: "-41.796800"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "DOUGLAS-APSLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.189100",
    Latitude: "-41.759700"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "FALMOUTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.244300",
    Latitude: "-41.519300"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "FOUR MILE CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.283100",
    Latitude: "-41.577400"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "FREYCINET",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.303900",
    Latitude: "-42.227900"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "FRIENDLY BEACHES",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.257600",
    Latitude: "-41.996900"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "GRAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.211100",
    Latitude: "-41.645300"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "SCAMANDER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.249500",
    Latitude: "-41.461500"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "SEYMOUR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.272700",
    Latitude: "-41.739700"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "ST MARYS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.167700",
    Latitude: "-41.592000"
  },
  {
    Country: "Australia",
    Pcode: "7215",
    Locality: "UPPER SCAMANDER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.188100",
    Latitude: "-41.462200"
  },
  {
    Country: "Australia",
    Pcode: "7216",
    Locality: "AKAROA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.335100",
    Latitude: "-41.288200"
  },
  {
    Country: "Australia",
    Pcode: "7216",
    Locality: "BINALONG BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.280300",
    Latitude: "-41.253400"
  },
  {
    Country: "Australia",
    Pcode: "7216",
    Locality: "GOSHEN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.126500",
    Latitude: "-41.253700"
  },
  {
    Country: "Australia",
    Pcode: "7216",
    Locality: "GOULDS COUNTRY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.063900",
    Latitude: "-41.202200"
  },
  {
    Country: "Australia",
    Pcode: "7216",
    Locality: "LOTTAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.014500",
    Latitude: "-41.224600"
  },
  {
    Country: "Australia",
    Pcode: "7216",
    Locality: "PYENGANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.955400",
    Latitude: "-41.297100"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "MONTANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.590900",
    Latitude: "-41.590300"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "NEEDLES",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.548500",
    Latitude: "-41.544600"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "PARKHAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.630500",
    Latitude: "-41.390000"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "QUAMBY BROOK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.723500",
    Latitude: "-41.587900"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "RED HILLS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.592800",
    Latitude: "-41.533300"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "REEDY MARSH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.719400",
    Latitude: "-41.442000"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "REYNOLDS NECK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.695100",
    Latitude: "-41.857300"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "WALLS OF JERUSALEM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.247200",
    Latitude: "-41.928200"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "WEEGENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.475200",
    Latitude: "-41.476200"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "WEETAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.639400",
    Latitude: "-41.460800"
  },
  {
    Country: "Australia",
    Pcode: "7304",
    Locality: "WESTERN CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.493600",
    Latitude: "-41.652000"
  },
  {
    Country: "Australia",
    Pcode: "7305",
    Locality: "MERSEYLEA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.475900",
    Latitude: "-41.362700"
  },
  {
    Country: "Australia",
    Pcode: "7305",
    Locality: "RAILTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.410800",
    Latitude: "-41.332500"
  },
  {
    Country: "Australia",
    Pcode: "7305",
    Locality: "SUNNYSIDE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.447900",
    Latitude: "-41.381700"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "ACACIA HILLS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.334300",
    Latitude: "-41.277800"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "BARRINGTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.277500",
    Latitude: "-41.334700"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "BEULAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.381400",
    Latitude: "-41.436800"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "CETHANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.149000",
    Latitude: "-41.476600"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "CLAUDE ROAD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.257000",
    Latitude: "-41.443800"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "CRADLE MOUNTAIN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.935400",
    Latitude: "-41.724600"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "GOWRIE PARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.225100",
    Latitude: "-41.475000"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "LORINNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.153300",
    Latitude: "-41.529900"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "LOWER BARRINGTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.282300",
    Latitude: "-41.290200"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "LOWER BEULAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.412200",
    Latitude: "-41.466500"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "MIDDLESEX",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.946200",
    Latitude: "-41.534600"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "MOUNT ROLAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.276100",
    Latitude: "-41.487000"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "NOOK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.327500",
    Latitude: "-41.329700"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "NOWHERE ELSE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.256300",
    Latitude: "-41.371100"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "PARADISE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.336800",
    Latitude: "-41.454300"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "PROMISED LAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.201700",
    Latitude: "-41.426900"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "ROLAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.227800",
    Latitude: "-41.413400"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "SHEFFIELD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.337600",
    Latitude: "-41.386700"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "STAVERTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.175300",
    Latitude: "-41.452900"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "STOODLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.416100",
    Latitude: "-41.396200"
  },
  {
    Country: "Australia",
    Pcode: "7306",
    Locality: "WEST KENTISH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.268000",
    Latitude: "-41.399000"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "BAKERS BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.641100",
    Latitude: "-41.166300"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "HARFORD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.647000",
    Latitude: "-41.247600"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "HAWLEY BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.533500",
    Latitude: "-41.134400"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "LATROBE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.431200",
    Latitude: "-41.247400"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "MORIARTY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.488000",
    Latitude: "-41.219000"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "NORTHDOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.488000",
    Latitude: "-41.166800"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "PORT SORELL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.530700",
    Latitude: "-41.171100"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "SASSAFRAS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.548000",
    Latitude: "-41.311300"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "SHEARWATER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.533300",
    Latitude: "-41.157100"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "SQUEAKING POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.557800",
    Latitude: "-41.188600"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "THIRLSTANE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.536400",
    Latitude: "-41.207500"
  },
  {
    Country: "Australia",
    Pcode: "7307",
    Locality: "WESLEY VALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.439400",
    Latitude: "-41.193200"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "ABERDEEN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.325800",
    Latitude: "-41.242300"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "AMBLESIDE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.368600",
    Latitude: "-41.202200"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "DEVONPORT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.343000",
    Latitude: "-41.175400"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "DON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.306800",
    Latitude: "-41.182600"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "EAST DEVONPORT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.389900",
    Latitude: "-41.186900"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "ERRIBA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.114300",
    Latitude: "-41.438600"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "EUGENANA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.309600",
    Latitude: "-41.232000"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "FORTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.241000",
    Latitude: "-41.206900"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "FORTHSIDE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.273700",
    Latitude: "-41.223600"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "KINDRED",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.222800",
    Latitude: "-41.255400"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "LILLICO",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.291400",
    Latitude: "-41.169900"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "LOWER WILMOT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.218600",
    Latitude: "-41.315700"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "MELROSE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.298500",
    Latitude: "-41.256900"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "MIANDETTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.352400",
    Latitude: "-41.196200"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "MOINA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.087600",
    Latitude: "-41.510100"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "PALOONA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.272000",
    Latitude: "-41.255800"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "QUOIBA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.329700",
    Latitude: "-41.209900"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "SOUTH SPREYTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.352100",
    Latitude: "-41.255700"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "SPREYTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.343700",
    Latitude: "-41.226100"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "STONY RISE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.331900",
    Latitude: "-41.194300"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "TARLETON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.369600",
    Latitude: "-41.242200"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "TUGRAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.310000",
    Latitude: "-41.208000"
  },
  {
    Country: "Australia",
    Pcode: "7310",
    Locality: "WILMOT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.155700",
    Latitude: "-41.394800"
  },
  {
    Country: "Australia",
    Pcode: "5203",
    Locality: "WATTLE FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.394700",
    Latitude: "-35.432400"
  },
  {
    Country: "Australia",
    Pcode: "5203",
    Locality: "YANKALILLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.358500",
    Latitude: "-35.470900"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "CAPE JERVIS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.132800",
    Latitude: "-35.594600"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "CARRICKALINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.344900",
    Latitude: "-35.424500"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "DEEP CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.228100",
    Latitude: "-35.630200"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "DELAMERE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.208600",
    Latitude: "-35.575100"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "HAY FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.313500",
    Latitude: "-35.503700"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "NORMANVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.304000",
    Latitude: "-35.470400"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "RAPID BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.183200",
    Latitude: "-35.533900"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "SECOND VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.254900",
    Latitude: "-35.542900"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "SILVERTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.164400",
    Latitude: "-35.612500"
  },
  {
    Country: "Australia",
    Pcode: "5204",
    Locality: "WIRRINA COVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.262100",
    Latitude: "-35.498200"
  },
  {
    Country: "Australia",
    Pcode: "5210",
    Locality: "MOUNT COMPASS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.593500",
    Latitude: "-35.357000"
  },
  {
    Country: "Australia",
    Pcode: "5210",
    Locality: "MOUNT MAGNIFICENT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.694100",
    Latitude: "-35.308400"
  },
  {
    Country: "Australia",
    Pcode: "5210",
    Locality: "NANGKITA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.679700",
    Latitude: "-35.341700"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "BACK VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.507800",
    Latitude: "-35.541500"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "CHITON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "ENCOUNTER BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.596300",
    Latitude: "-35.575300"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "HAYBOROUGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.651100",
    Latitude: "-35.532300"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "HINDMARSH VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.595200",
    Latitude: "-35.479000"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "INMAN VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.480100",
    Latitude: "-35.478700"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "LOWER INMAN VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.557900",
    Latitude: "-35.513000"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "MCCRACKEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.630300",
    Latitude: "-35.535400"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "MOUNT JAGGED",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.626800",
    Latitude: "-35.411500"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "VICTOR HARBOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.611200",
    Latitude: "-35.549100"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "VICTOR HARBOR CENTRAL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "VICTOR HARBOR CENTRAL",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "WAITPINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.513300",
    Latitude: "-35.597200"
  },
  {
    Country: "Australia",
    Pcode: "5211",
    Locality: "WILLOW CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.422600",
    Latitude: "-35.564500"
  },
  {
    Country: "Australia",
    Pcode: "5212",
    Locality: "PORT ELLIOT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.675400",
    Latitude: "-35.506100"
  },
  {
    Country: "Australia",
    Pcode: "5213",
    Locality: "MIDDLETON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.708300",
    Latitude: "-35.485100"
  },
  {
    Country: "Australia",
    Pcode: "5214",
    Locality: "CURRENCY CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.782100",
    Latitude: "-35.446300"
  },
  {
    Country: "Australia",
    Pcode: "5214",
    Locality: "GOOLWA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.768000",
    Latitude: "-35.489100"
  },
  {
    Country: "Australia",
    Pcode: "5214",
    Locality: "GOOLWA BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.760900",
    Latitude: "-35.514300"
  },
  {
    Country: "Australia",
    Pcode: "5214",
    Locality: "GOOLWA NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.813400",
    Latitude: "-35.484100"
  },
  {
    Country: "Australia",
    Pcode: "5214",
    Locality: "GOOLWA SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.820000",
    Latitude: "-35.532200"
  },
  {
    Country: "Australia",
    Pcode: "5214",
    Locality: "HINDMARSH ISLAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.883600",
    Latitude: "-35.521000"
  },
  {
    Country: "Australia",
    Pcode: "5214",
    Locality: "MOSQUITO HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.688400",
    Latitude: "-35.425300"
  },
  {
    Country: "Australia",
    Pcode: "5214",
    Locality: "MUNDOO ISLAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.936500",
    Latitude: "-35.540600"
  },
  {
    Country: "Australia",
    Pcode: "5220",
    Locality: "PARNDANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.256000",
    Latitude: "-35.783400"
  },
  {
    Country: "Australia",
    Pcode: "5221",
    Locality: "AMERICAN RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.768500",
    Latitude: "-35.779700"
  },
  {
    Country: "Australia",
    Pcode: "5221",
    Locality: "BALLAST HEAD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.770400",
    Latitude: "-35.755900"
  },
  {
    Country: "Australia",
    Pcode: "5221",
    Locality: "MUSTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.733200",
    Latitude: "-35.824000"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "AMERICAN BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.880500",
    Latitude: "-35.767700"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "ANTECHAMBER BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.065800",
    Latitude: "-35.787800"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "BAUDIN BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.865900",
    Latitude: "-35.779500"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "BROWN BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.846200",
    Latitude: "-35.797000"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "CUTTLEFISH BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.034200",
    Latitude: "-35.752700"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "DUDLEY EAST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.976100",
    Latitude: "-35.774500"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "DUDLEY WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.866700",
    Latitude: "-35.823000"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "IRONSTONE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.982300",
    Latitude: "-35.739900"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "ISLAND BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.799400",
    Latitude: "-35.797300"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "KANGAROO HEAD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.905900",
    Latitude: "-35.735300"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "PELICAN LAGOON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.791700",
    Latitude: "-35.843200"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "PENNESHAW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.943900",
    Latitude: "-35.728400"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "PORKY FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.026100",
    Latitude: "-35.863500"
  },
  {
    Country: "Australia",
    Pcode: "5222",
    Locality: "SAPPHIRETOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.784400",
    Latitude: "-35.790200"
  },
  {
    Country: "Australia",
    Pcode: "5265",
    Locality: "COONALPYN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.867200",
    Latitude: "-35.662700"
  },
  {
    Country: "Australia",
    Pcode: "5265",
    Locality: "FIELD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.643700",
    Latitude: "-35.799100"
  },
  {
    Country: "Australia",
    Pcode: "5266",
    Locality: "BUNBURY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.965300",
    Latitude: "-36.159700"
  },
  {
    Country: "Australia",
    Pcode: "5266",
    Locality: "COLEBATCH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.741600",
    Latitude: "-35.948800"
  },
  {
    Country: "Australia",
    Pcode: "5266",
    Locality: "DEEPWATER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.820300",
    Latitude: "-36.140600"
  },
  {
    Country: "Australia",
    Pcode: "5266",
    Locality: "TINTINARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.035200",
    Latitude: "-35.874400"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "BRIMBAGO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.494700",
    Latitude: "-36.187700"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "COOMBE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.256100",
    Latitude: "-35.949100"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "KEITH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.342600",
    Latitude: "-36.143400"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "LAFFER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.149800",
    Latitude: "-36.175800"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "MAKIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.517300",
    Latitude: "-35.979100"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "MCCALLUM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.665000",
    Latitude: "-35.979400"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "MOUNT CHARLES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.149900",
    Latitude: "-36.083900"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "PETHERICK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.149600",
    Latitude: "-36.360100"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "SHAUGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.857100",
    Latitude: "-35.921400"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "SHERWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.571900",
    Latitude: "-36.093500"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "WILLALOOKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.342200",
    Latitude: "-36.360000"
  },
  {
    Country: "Australia",
    Pcode: "5267",
    Locality: "WIRREGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.573400",
    Latitude: "-36.201100"
  },
  {
    Country: "Australia",
    Pcode: "5268",
    Locality: "BANGHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.879900",
    Latitude: "-36.577100"
  },
  {
    Country: "Australia",
    Pcode: "5268",
    Locality: "BORDERTOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.784800",
    Latitude: "-36.320800"
  },
  {
    Country: "Australia",
    Pcode: "5268",
    Locality: "BORDERTOWN SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.725000",
    Latitude: "-36.428000"
  },
  {
    Country: "Australia",
    Pcode: "5268",
    Locality: "CANNAWIGARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.631600",
    Latitude: "-36.264600"
  },
  {
    Country: "Australia",
    Pcode: "5268",
    Locality: "LOWAN VALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.672300",
    Latitude: "-36.156600"
  },
  {
    Country: "Australia",
    Pcode: "5268",
    Locality: "POOGINAGORIC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.828400",
    Latitude: "-36.444000"
  },
  {
    Country: "Australia",
    Pcode: "5268",
    Locality: "SENIOR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.850500",
    Latitude: "-36.143100"
  },
  {
    Country: "Australia",
    Pcode: "5268",
    Locality: "WESTERN FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.702000",
    Latitude: "-36.567600"
  },
  {
    Country: "Australia",
    Pcode: "5269",
    Locality: "CUSTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.928700",
    Latitude: "-36.468400"
  },
  {
    Country: "Australia",
    Pcode: "5269",
    Locality: "PINE HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.893000",
    Latitude: "-36.297400"
  },
  {
    Country: "Australia",
    Pcode: "5269",
    Locality: "WOLSELEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.893100",
    Latitude: "-36.378200"
  },
  {
    Country: "Australia",
    Pcode: "5270",
    Locality: "BUCKINGHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.571900",
    Latitude: "-36.337200"
  },
  {
    Country: "Australia",
    Pcode: "5270",
    Locality: "CAREW",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.492700",
    Latitude: "-36.299500"
  },
  {
    Country: "Australia",
    Pcode: "5270",
    Locality: "KONGAL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.470700",
    Latitude: "-36.379200"
  },
  {
    Country: "Australia",
    Pcode: "5270",
    Locality: "MUNDULLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.677500",
    Latitude: "-36.395800"
  },
  {
    Country: "Australia",
    Pcode: "5270",
    Locality: "MUNDULLA WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.559300",
    Latitude: "-36.429200"
  },
  {
    Country: "Australia",
    Pcode: "5270",
    Locality: "SWEDE FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.474900",
    Latitude: "-36.460800"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "BOOL LAGOON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.686000",
    Latitude: "-37.147100"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "CADGEE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.710700",
    Latitude: "-36.791300"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "JOANNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.882400",
    Latitude: "-37.104400"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "KEPPOCH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.531700",
    Latitude: "-36.719800"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "KOPPAMURRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.907600",
    Latitude: "-37.049500"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "LAURIE PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.950500",
    Latitude: "-37.006000"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "LOCHABER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.550100",
    Latitude: "-36.821100"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "MARCOLLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.301800",
    Latitude: "-36.570100"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "MOUNT LIGHT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.784600",
    Latitude: "-36.999300"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "MOYHALL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.671400",
    Latitude: "-37.045600"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "NARACOORTE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.748400",
    Latitude: "-36.945600"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "PADTHAWAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.521800",
    Latitude: "-36.595200"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "SPENCE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.541400",
    Latitude: "-37.091300"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "STEWART RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.563900",
    Latitude: "-36.955200"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "STRUAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.767500",
    Latitude: "-37.104400"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "THE GAP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.712400",
    Latitude: "-36.708900"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "WILD DOG VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.759600",
    Latitude: "-36.867500"
  },
  {
    Country: "Australia",
    Pcode: "5271",
    Locality: "WRATTONBULLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.934500",
    Latitude: "-37.144200"
  },
  {
    Country: "Australia",
    Pcode: "5272",
    Locality: "COLES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.518500",
    Latitude: "-37.248500"
  },
  {
    Country: "Australia",
    Pcode: "5272",
    Locality: "CONMURRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.185700",
    Latitude: "-37.119300"
  },
  {
    Country: "Australia",
    Pcode: "5272",
    Locality: "FOX",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.334100",
    Latitude: "-37.248600"
  },
  {
    Country: "Australia",
    Pcode: "5272",
    Locality: "GREENWAYS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.163500",
    Latitude: "-37.247000"
  },
  {
    Country: "Australia",
    Pcode: "5272",
    Locality: "LUCINDALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.401600",
    Latitude: "-37.026400"
  },
  {
    Country: "Australia",
    Pcode: "5272",
    Locality: "WOOLUMBOOL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.341600",
    Latitude: "-36.795800"
  },
  {
    Country: "Australia",
    Pcode: "5273",
    Locality: "AVENUE RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.186700",
    Latitude: "-36.932800"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "BLACKFORD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.044300",
    Latitude: "-36.777800"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "BOATSWAIN POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.750100",
    Latitude: "-37.071500"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "CAPE JAFFA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.706200",
    Latitude: "-36.962100"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "KEILIRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.149500",
    Latitude: "-36.666000"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "KINGSTON SE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.908200",
    Latitude: "-36.842100"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "MOUNT BENSON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.902700",
    Latitude: "-37.106300"
  },
  {
    Country: "Australia",
    Pcode: "5275",
    Locality: "PINKS BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.818800",
    Latitude: "-36.870600"
  },
  {
    Country: "Australia",
    Pcode: "5321",
    Locality: "CADELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.770500",
    Latitude: "-34.089800"
  },
  {
    Country: "Australia",
    Pcode: "5321",
    Locality: "CADELL LAGOON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.754800",
    Latitude: "-34.032400"
  },
  {
    Country: "Australia",
    Pcode: "5322",
    Locality: "GOLDEN HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.934800",
    Latitude: "-34.195200"
  },
  {
    Country: "Australia",
    Pcode: "5322",
    Locality: "QUALCO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.919200",
    Latitude: "-34.112300"
  },
  {
    Country: "Australia",
    Pcode: "5322",
    Locality: "RAMCO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.924800",
    Latitude: "-34.166000"
  },
  {
    Country: "Australia",
    Pcode: "5322",
    Locality: "RAMCO HEIGHTS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.909700",
    Latitude: "-34.190300"
  },
  {
    Country: "Australia",
    Pcode: "5322",
    Locality: "SUNLANDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.885900",
    Latitude: "-34.140500"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "BOOLGUN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.072400",
    Latitude: "-34.386700"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "DEVLINS POUND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.175600",
    Latitude: "-34.108100"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "GOOD HOPE LANDING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.162500",
    Latitude: "-34.171900"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "HAWKS NEST STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.452600",
    Latitude: "-34.036300"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "HOLDER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.025400",
    Latitude: "-34.171900"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "HOLDER SIDING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.056900",
    Latitude: "-34.331900"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "KANNI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.049600",
    Latitude: "-34.245200"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "LOWBANK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.054600",
    Latitude: "-34.182400"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "MARKARANKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.955800",
    Latitude: "-34.027200"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "OVERLAND CORNER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.344600",
    Latitude: "-34.148000"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "POOGINOOK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.103200",
    Latitude: "-34.120400"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "STOCKYARD PLAIN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.805600",
    Latitude: "-34.259300"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "TAYLORVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.947500",
    Latitude: "-34.142900"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "TAYLORVILLE STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.193500",
    Latitude: "-33.925300"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "WAIKERIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.977100",
    Latitude: "-34.205100"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "WIGLEY FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.204600",
    Latitude: "-34.176700"
  },
  {
    Country: "Australia",
    Pcode: "5330",
    Locality: "WOOLPUNDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.207800",
    Latitude: "-34.249400"
  },
  {
    Country: "Australia",
    Pcode: "5331",
    Locality: "KINGSTON ON MURRAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.320100",
    Latitude: "-34.207500"
  },
  {
    Country: "Australia",
    Pcode: "5332",
    Locality: "MOOROOK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.309100",
    Latitude: "-34.276500"
  },
  {
    Country: "Australia",
    Pcode: "5332",
    Locality: "MOOROOK SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.348200",
    Latitude: "-34.306100"
  },
  {
    Country: "Australia",
    Pcode: "5332",
    Locality: "WAPPILKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.300500",
    Latitude: "-34.393500"
  },
  {
    Country: "Australia",
    Pcode: "5332",
    Locality: "YINKANIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.273800",
    Latitude: "-34.341100"
  },
  {
    Country: "Australia",
    Pcode: "5333",
    Locality: "BOOKPURNONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.619700",
    Latitude: "-34.358500"
  },
  {
    Country: "Australia",
    Pcode: "5333",
    Locality: "LOXTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.585800",
    Latitude: "-34.470900"
  },
  {
    Country: "Australia",
    Pcode: "5333",
    Locality: "LOXTON NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.624200",
    Latitude: "-34.411300"
  },
  {
    Country: "Australia",
    Pcode: "5333",
    Locality: "NEW RESIDENCE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.398000",
    Latitude: "-34.365600"
  },
  {
    Country: "Australia",
    Pcode: "5333",
    Locality: "PATA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.539100",
    Latitude: "-34.572300"
  },
  {
    Country: "Australia",
    Pcode: "5333",
    Locality: "PYAP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.432100",
    Latitude: "-34.429600"
  },
  {
    Country: "Australia",
    Pcode: "5333",
    Locality: "PYAP WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.413100",
    Latitude: "-34.557200"
  },
  {
    Country: "Australia",
    Pcode: "5340",
    Locality: "MUNDIC CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.784700",
    Latitude: "-34.213200"
  },
  {
    Country: "Australia",
    Pcode: "5340",
    Locality: "MURTHO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.852500",
    Latitude: "-34.074200"
  },
  {
    Country: "Australia",
    Pcode: "5340",
    Locality: "PARINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.780100",
    Latitude: "-34.174800"
  },
  {
    Country: "Australia",
    Pcode: "5340",
    Locality: "PIKE RIVER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.780900",
    Latitude: "-34.271700"
  },
  {
    Country: "Australia",
    Pcode: "5340",
    Locality: "WONUARRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.886700",
    Latitude: "-34.189900"
  },
  {
    Country: "Australia",
    Pcode: "5340",
    Locality: "YAMBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.911000",
    Latitude: "-34.285100"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "CALPERUM STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.567000",
    Latitude: "-33.825400"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "CHAFFEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.708100",
    Latitude: "-34.092800"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "CHOWILLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.929100",
    Latitude: "-33.781100"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "COOLTONG",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.668300",
    Latitude: "-34.101000"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "CRESCENT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.746500",
    Latitude: "-34.209200"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "OLD CALPERUM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.684300",
    Latitude: "-34.223100"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "RENMARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.750500",
    Latitude: "-34.162400"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "RENMARK NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.739100",
    Latitude: "-34.122700"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "RENMARK SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.721400",
    Latitude: "-34.207300"
  },
  {
    Country: "Australia",
    Pcode: "5341",
    Locality: "RENMARK WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.699000",
    Latitude: "-34.165200"
  },
  {
    Country: "Australia",
    Pcode: "5342",
    Locality: "MONASH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.578900",
    Latitude: "-34.202200"
  },
  {
    Country: "Australia",
    Pcode: "5343",
    Locality: "BERRI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.600200",
    Latitude: "-34.275200"
  },
  {
    Country: "Australia",
    Pcode: "5343",
    Locality: "GERARD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.502100",
    Latitude: "-34.350600"
  },
  {
    Country: "Australia",
    Pcode: "5343",
    Locality: "GURRA GURRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.639500",
    Latitude: "-34.294400"
  },
  {
    Country: "Australia",
    Pcode: "5343",
    Locality: "KATARAPKO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.539900",
    Latitude: "-34.405700"
  },
  {
    Country: "Australia",
    Pcode: "5343",
    Locality: "LYRUP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.681500",
    Latitude: "-34.270900"
  },
  {
    Country: "Australia",
    Pcode: "5343",
    Locality: "WINKIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.553200",
    Latitude: "-34.320900"
  },
  {
    Country: "Australia",
    Pcode: "5344",
    Locality: "GLOSSOP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.544800",
    Latitude: "-34.283500"
  },
  {
    Country: "Australia",
    Pcode: "5345",
    Locality: "BARMERA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.474400",
    Latitude: "-34.232600"
  },
  {
    Country: "Australia",
    Pcode: "5345",
    Locality: "LOVEDAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.418100",
    Latitude: "-34.298400"
  },
  {
    Country: "Australia",
    Pcode: "5345",
    Locality: "SPECTACLE LAKE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.434300",
    Latitude: "-34.345200"
  },
  {
    Country: "Australia",
    Pcode: "5346",
    Locality: "COBDOGLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.406900",
    Latitude: "-34.239100"
  },
  {
    Country: "Australia",
    Pcode: "5350",
    Locality: "ROSEDALE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.843000",
    Latitude: "-34.560000"
  },
  {
    Country: "Australia",
    Pcode: "5350",
    Locality: "SANDY CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.835400",
    Latitude: "-34.600400"
  },
  {
    Country: "Australia",
    Pcode: "5351",
    Locality: "ALTONA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.911800",
    Latitude: "-34.597800"
  },
  {
    Country: "Australia",
    Pcode: "5433",
    Locality: "QUORN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.057400",
    Latitude: "-32.340000"
  },
  {
    Country: "Australia",
    Pcode: "5433",
    Locality: "SALTIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.945800",
    Latitude: "-32.475600"
  },
  {
    Country: "Australia",
    Pcode: "5433",
    Locality: "STEPHENSTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.223900",
    Latitude: "-32.367200"
  },
  {
    Country: "Australia",
    Pcode: "5433",
    Locality: "WILLOCHRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.200000",
    Latitude: "-32.221500"
  },
  {
    Country: "Australia",
    Pcode: "5433",
    Locality: "YARRAH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.956100",
    Latitude: "-32.123000"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "BARNDIOOTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.182700",
    Latitude: "-31.857900"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "BLACK HILL STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.831500",
    Latitude: "-31.800400"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "FLINDERS RANGES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.508000",
    Latitude: "-31.475000"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "HAWKER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.459700",
    Latitude: "-31.857800"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "KANYAKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.262000",
    Latitude: "-32.086900"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "MOUNT HAVELOCK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.785700",
    Latitude: "-31.682300"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "PRELINNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.736300",
    Latitude: "-31.642400"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "SHAGGY RIDGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.740600",
    Latitude: "-31.798500"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "UPALINNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.009800",
    Latitude: "-31.559100"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "WILLOW SPRINGS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.913700",
    Latitude: "-31.475100"
  },
  {
    Country: "Australia",
    Pcode: "5434",
    Locality: "WORUMBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.712800",
    Latitude: "-31.870400"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "ABMINGA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.315400",
    Latitude: "-32.219000"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "BENDA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.116300",
    Latitude: "-32.655900"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "BIMBOWRIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.243300",
    Latitude: "-32.025700"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "BINDARRAH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.661800",
    Latitude: "-32.108800"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "BOOLCOOMATTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.593400",
    Latitude: "-31.912300"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "BULLOO CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.488500",
    Latitude: "-32.138500"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "COCKBURN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.958500",
    Latitude: "-32.075900"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "CURNAMONA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.604100",
    Latitude: "-31.693900"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "DEVONBOROUGH DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.300100",
    Latitude: "-32.682100"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "ERUDINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.552800",
    Latitude: "-31.405400"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "FLORINA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.937100",
    Latitude: "-32.358800"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "FROME DOWNS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.114200",
    Latitude: "-30.657300"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "GRAMPUS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.529900",
    Latitude: "-32.798000"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "KALABITY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.032300",
    Latitude: "-31.724900"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "KALKAROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.529500",
    Latitude: "-31.792000"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "KOONAMORE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.280600",
    Latitude: "-32.125900"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "LAKE FROME",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.780400",
    Latitude: "-30.759000"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MANNA HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.975000",
    Latitude: "-32.431700"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MANUNDA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.857400",
    Latitude: "-32.885200"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MARTINS WELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.187100",
    Latitude: "-31.463100"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MELTON STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.319100",
    Latitude: "-32.283100"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MINGARY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.741800",
    Latitude: "-32.127900"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MOOLEULOOLOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.356000",
    Latitude: "-31.654600"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MOUNT VICTOR STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.714500",
    Latitude: "-31.900600"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MULYUNGARIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.835000",
    Latitude: "-31.567800"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MUNDI MUNDI",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.949800",
    Latitude: "-31.747300"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "MUTOOROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.751300",
    Latitude: "-32.620200"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "NACKARA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.227500",
    Latitude: "-32.817800"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "NETLEY GAP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.009800",
    Latitude: "-32.775000"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "OLARY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.301900",
    Latitude: "-32.280300"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "OULNINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.501700",
    Latitude: "-32.441200"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "OULNINA PARK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.844400",
    Latitude: "-32.590900"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "OUTALPA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.147400",
    Latitude: "-32.203700"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "PINE CREEK STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.934500",
    Latitude: "-32.159100"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "PLUMBAGO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.879400",
    Latitude: "-31.967300"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "PUALCO RANGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.608700",
    Latitude: "-32.957100"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "QUINYAMBIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.580400",
    Latitude: "-30.354400"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "TEPCO STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.756300",
    Latitude: "-32.186500"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "TIKALINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.633500",
    Latitude: "-32.249500"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "WADNAMINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.287400",
    Latitude: "-32.533900"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "WAUKARINGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.440800",
    Latitude: "-32.287700"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "WEEKEROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.963700",
    Latitude: "-32.220400"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "WIAWERA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.428600",
    Latitude: "-32.279100"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "WINNININNIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.753300",
    Latitude: "-32.465100"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "WOMPINIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.823600",
    Latitude: "-31.973400"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "YARRAMBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "140.622300",
    Latitude: "-31.599800"
  },
  {
    Country: "Australia",
    Pcode: "5440",
    Locality: "YUNTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.529800",
    Latitude: "-32.597400"
  },
  {
    Country: "Australia",
    Pcode: "5451",
    Locality: "AUBURN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.677900",
    Latitude: "-34.034800"
  },
  {
    Country: "Australia",
    Pcode: "5451",
    Locality: "UNDALYA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.683700",
    Latitude: "-34.080900"
  },
  {
    Country: "Australia",
    Pcode: "5452",
    Locality: "LEASINGHAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.649200",
    Latitude: "-33.990900"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "ROGUES POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.895700",
    Latitude: "-34.495600"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "SANDILANDS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.787600",
    Latitude: "-34.532600"
  },
  {
    Country: "Australia",
    Pcode: "5571",
    Locality: "TIDDY WIDDY BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.940200",
    Latitude: "-34.401300"
  },
  {
    Country: "Australia",
    Pcode: "5572",
    Locality: "ARTHURTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.781900",
    Latitude: "-34.231500"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "BALGOWAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.513400",
    Latitude: "-34.316700"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "CHINAMAN WELLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.476300",
    Latitude: "-34.391500"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "MAITLAND",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.670500",
    Latitude: "-34.352800"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "POINT PEARCE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.511600",
    Latitude: "-34.437700"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "PORT VICTORIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.515400",
    Latitude: "-34.518200"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "SOUTH KILKERRAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.587300",
    Latitude: "-34.441600"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "URANIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.625200",
    Latitude: "-34.518900"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "WAURALTEE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.602200",
    Latitude: "-34.589300"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "WEETULTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.623500",
    Latitude: "-34.231500"
  },
  {
    Country: "Australia",
    Pcode: "5573",
    Locality: "YORKE VALLEY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.688900",
    Latitude: "-34.445700"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "BLUFF BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.498300",
    Latitude: "-34.729000"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "BRENTWOOD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.511200",
    Latitude: "-34.866200"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "CORNY POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.050000",
    Latitude: "-34.951700"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "HARDWICKE BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.465300",
    Latitude: "-34.900400"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "KOOLYWURTIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.590800",
    Latitude: "-34.674500"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "MARION BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.020800",
    Latitude: "-35.172400"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "MINLATON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.558100",
    Latitude: "-34.805900"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "PARSONS BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "POINT TURTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.334000",
    Latitude: "-34.945100"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "PORT RICKABY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.506700",
    Latitude: "-34.670300"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "RAMSAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.735500",
    Latitude: "-34.823000"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "STENHOUSE BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "WHITE HUT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.034500",
    Latitude: "-35.040700"
  },
  {
    Country: "Australia",
    Pcode: "5575",
    Locality: "WOOL BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.728300",
    Latitude: "-34.976700"
  },
  {
    Country: "Australia",
    Pcode: "5576",
    Locality: "HONITON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.634200",
    Latitude: "-35.110300"
  },
  {
    Country: "Australia",
    Pcode: "5576",
    Locality: "PORT MOOROWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.525700",
    Latitude: "-35.102300"
  },
  {
    Country: "Australia",
    Pcode: "5576",
    Locality: "YORKETOWN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.592800",
    Latitude: "-34.993200"
  },
  {
    Country: "Australia",
    Pcode: "5577",
    Locality: "COUCH BEACH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.175500",
    Latitude: "-34.910600"
  },
  {
    Country: "Australia",
    Pcode: "5577",
    Locality: "FOUL BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.240800",
    Latitude: "-35.154200"
  },
  {
    Country: "Australia",
    Pcode: "5577",
    Locality: "INNESTON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.896000",
    Latitude: "-35.227000"
  },
  {
    Country: "Australia",
    Pcode: "5577",
    Locality: "POINT SOUTTAR",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.256800",
    Latitude: "-34.931000"
  },
  {
    Country: "Australia",
    Pcode: "5577",
    Locality: "THE PINES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.171300",
    Latitude: "-34.933900"
  },
  {
    Country: "Australia",
    Pcode: "5577",
    Locality: "WAROOKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.315300",
    Latitude: "-35.026200"
  },
  {
    Country: "Australia",
    Pcode: "5580",
    Locality: "CURRAMULKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.754900",
    Latitude: "-34.667400"
  },
  {
    Country: "Australia",
    Pcode: "5580",
    Locality: "PORT JULIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.871700",
    Latitude: "-34.652200"
  },
  {
    Country: "Australia",
    Pcode: "5581",
    Locality: "PORT VINCENT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.824500",
    Latitude: "-34.773800"
  },
  {
    Country: "Australia",
    Pcode: "5581",
    Locality: "SHEAOAK FLAT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.865000",
    Latitude: "-34.694200"
  },
  {
    Country: "Australia",
    Pcode: "5582",
    Locality: "PORT GILES",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5582",
    Locality: "STANSBURY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.758100",
    Latitude: "-34.911700"
  },
  {
    Country: "Australia",
    Pcode: "5583",
    Locality: "COOBOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.725500",
    Latitude: "-35.042000"
  },
  {
    Country: "Australia",
    Pcode: "5583",
    Locality: "EDITHBURGH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.718500",
    Latitude: "-35.100700"
  },
  {
    Country: "Australia",
    Pcode: "5583",
    Locality: "SULTANA POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.738400",
    Latitude: "-35.115800"
  },
  {
    Country: "Australia",
    Pcode: "5600",
    Locality: "WHYALLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.575900",
    Latitude: "-33.038200"
  },
  {
    Country: "Australia",
    Pcode: "5600",
    Locality: "WHYALLA DC",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5600",
    Locality: "WHYALLA PLAYFORD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.561600",
    Latitude: "-33.030300"
  },
  {
    Country: "Australia",
    Pcode: "5601",
    Locality: "BACKY POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.785300",
    Latitude: "-32.911400"
  },
  {
    Country: "Australia",
    Pcode: "5601",
    Locality: "DOUGLAS POINT",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.803800",
    Latitude: "-32.857200"
  },
  {
    Country: "Australia",
    Pcode: "5601",
    Locality: "DOUGLAS POINT SOUTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.794800",
    Latitude: "-32.887300"
  },
  {
    Country: "Australia",
    Pcode: "5601",
    Locality: "FALSE BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.644700",
    Latitude: "-32.944700"
  },
  {
    Country: "Australia",
    Pcode: "5601",
    Locality: "FITZGERALD BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.762600",
    Latitude: "-32.907400"
  },
  {
    Country: "Australia",
    Pcode: "5601",
    Locality: "POINT LOWLY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.782100",
    Latitude: "-32.992400"
  },
  {
    Country: "Australia",
    Pcode: "5601",
    Locality: "POINT LOWLY NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.780500",
    Latitude: "-32.984700"
  },
  {
    Country: "Australia",
    Pcode: "5601",
    Locality: "PORT BONYTHON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.742000",
    Latitude: "-32.966500"
  },
  {
    Country: "Australia",
    Pcode: "5601",
    Locality: "WHYALLA BARSON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.542500",
    Latitude: "-32.977000"
  },
  {
    Country: "Australia",
    Pcode: "5602",
    Locality: "COWELL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.817100",
    Latitude: "-33.746700"
  },
  {
    Country: "Australia",
    Pcode: "5602",
    Locality: "LUCKY BAY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.041700",
    Latitude: "-33.700900"
  },
  {
    Country: "Australia",
    Pcode: "5602",
    Locality: "MANGALO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.583800",
    Latitude: "-33.471800"
  },
  {
    Country: "Australia",
    Pcode: "5602",
    Locality: "MIDGEE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.192100",
    Latitude: "-33.431500"
  },
  {
    Country: "Australia",
    Pcode: "5602",
    Locality: "MILTALIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.758100",
    Latitude: "-33.471600"
  },
  {
    Country: "Australia",
    Pcode: "5602",
    Locality: "MINBRIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.930600",
    Latitude: "-33.471400"
  },
  {
    Country: "Australia",
    Pcode: "5602",
    Locality: "MITCHELLVILLE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.139700",
    Latitude: "-33.585900"
  },
  {
    Country: "Australia",
    Pcode: "5602",
    Locality: "PORT GIBBON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.789700",
    Latitude: "-33.806700"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "MUNDALLIO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.886000",
    Latitude: "-32.442200"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "PORT AUGUSTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.788200",
    Latitude: "-32.495200"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "PORT AUGUSTA NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "PORT AUGUSTA WEST",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.721700",
    Latitude: "-32.483200"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "PORT PATERSON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.822700",
    Latitude: "-32.552800"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "WAMI KATA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.804200",
    Latitude: "-32.449700"
  },
  {
    Country: "Australia",
    Pcode: "5700",
    Locality: "WINNINOWIE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.904100",
    Latitude: "-32.619400"
  },
  {
    Country: "Australia",
    Pcode: "5701",
    Locality: "ARKAROOLA VILLAGE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "139.329300",
    Latitude: "-30.319500"
  },
  {
    Country: "Australia",
    Pcode: "5701",
    Locality: "COOK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5701",
    Locality: "PORT AUGUSTA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: "PO BAGS",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "5701",
    Locality: "TARCOOLA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.566900",
    Latitude: "-30.722900"
  },
  {
    Country: "Australia",
    Pcode: "5701",
    Locality: "WOOLUNDUNGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.961000",
    Latitude: "-32.577800"
  },
  {
    Country: "Australia",
    Pcode: "5710",
    Locality: "STIRLING NORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.856800",
    Latitude: "-32.512600"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "EMEROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.871900",
    Latitude: "-32.342400"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "ISLAND LAGOON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.706200",
    Latitude: "-31.491600"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "KOOTABERRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.517200",
    Latitude: "-32.014700"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "LAKE TORRENS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.648400",
    Latitude: "-30.637100"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "LAKE TORRENS STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.974800",
    Latitude: "-31.648600"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "MOUNT ARDEN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.740800",
    Latitude: "-32.255800"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "OAKDEN HILLS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.009300",
    Latitude: "-31.634100"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "PERNATTY",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.410100",
    Latitude: "-31.395900"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "SOUTH GAP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.529300",
    Latitude: "-31.674000"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "WALLERBERDINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.007700",
    Latitude: "-31.847200"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "WILKATANA STATION",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.786900",
    Latitude: "-32.132600"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "WINTABATINYANA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "138.180800",
    Latitude: "-31.444600"
  },
  {
    Country: "Australia",
    Pcode: "5713",
    Locality: "YADLAMALKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.862400",
    Latitude: "-31.896600"
  },
  {
    Country: "Australia",
    Pcode: "5715",
    Locality: "CARRIEWERLOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.360900",
    Latitude: "-32.329900"
  },
  {
    Country: "Australia",
    Pcode: "5715",
    Locality: "ILLEROO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.458600",
    Latitude: "-32.499800"
  },
  {
    Country: "Australia",
    Pcode: "5715",
    Locality: "LINCOLN GAP",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.544800",
    Latitude: "-32.546100"
  },
  {
    Country: "Australia",
    Pcode: "5715",
    Locality: "PANDURRA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.327900",
    Latitude: "-32.547400"
  },
  {
    Country: "Australia",
    Pcode: "5715",
    Locality: "WARTAKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.024600",
    Latitude: "-32.488200"
  },
  {
    Country: "Australia",
    Pcode: "5715",
    Locality: "YALYMBOO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.755400",
    Latitude: "-31.719200"
  },
  {
    Country: "Australia",
    Pcode: "5715",
    Locality: "YUDNAPINNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.066600",
    Latitude: "-32.115000"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "HILTABA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.170600",
    Latitude: "-32.226300"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "KOKATHA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.331500",
    Latitude: "-31.329200"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "KOLENDO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.150100",
    Latitude: "-32.332300"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "KONDOOLKA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.971000",
    Latitude: "-32.065500"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "LAKE EVERARD",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.999900",
    Latitude: "-31.685600"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "LAKE GAIRDNER",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.854800",
    Latitude: "-31.497600"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "LAKE MACFARLANE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.693300",
    Latitude: "-31.956800"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "MAHANEWO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.401200",
    Latitude: "-31.711200"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "MOONAREE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.659800",
    Latitude: "-31.822600"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "MOUNT IVE",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.038500",
    Latitude: "-32.510900"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "NONNING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.509600",
    Latitude: "-32.059500"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "SIAM",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.660000",
    Latitude: "-32.386300"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "THURLGA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.766300",
    Latitude: "-32.467800"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "UNO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.612600",
    Latitude: "-32.800700"
  },
  {
    Country: "Australia",
    Pcode: "5717",
    Locality: "YARDEA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.584900",
    Latitude: "-32.279700"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "BON BON",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.510900",
    Latitude: "-30.593100"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "BULGUNNIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.709600",
    Latitude: "-30.189700"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "COMMONWEALTH HILL",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "133.900300",
    Latitude: "-30.006800"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "COONDAMBO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.716100",
    Latitude: "-30.940100"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "GLENDAMBO",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.759000",
    Latitude: "-30.967900"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "KINGOONYA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.305700",
    Latitude: "-30.909300"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "LAKE HARRIS",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.216900",
    Latitude: "-31.077400"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "MULGATHING",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.049000",
    Latitude: "-30.416700"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "WILGENA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "134.773800",
    Latitude: "-30.826100"
  },
  {
    Country: "Australia",
    Pcode: "5719",
    Locality: "WIRRAMINNA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.325200",
    Latitude: "-31.232700"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "ARCOONA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.103300",
    Latitude: "-31.129800"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "BILLA KALINA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.458500",
    Latitude: "-29.860500"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "BOSWORTH",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "137.559800",
    Latitude: "-30.817800"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "MILLERS CREEK",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.019500",
    Latitude: "-30.015600"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "MOUNT EBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.533700",
    Latitude: "-29.975300"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "MOUNT VIVIAN",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "135.807700",
    Latitude: "-30.602900"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "PARAKYLIA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.280600",
    Latitude: "-30.537900"
  },
  {
    Country: "Australia",
    Pcode: "5720",
    Locality: "PIMBA",
    State: "South Australia",
    StateAbbrev: "SA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "136.794700",
    Latitude: "-31.256500"
  },
  {
    Country: "Australia",
    Pcode: "6034",
    Locality: "EGLINTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.667500",
    Latitude: "-31.584000"
  },
  {
    Country: "Australia",
    Pcode: "6035",
    Locality: "YANCHEP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.733800",
    Latitude: "-31.536200"
  },
  {
    Country: "Australia",
    Pcode: "6036",
    Locality: "BUTLER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.704500",
    Latitude: "-31.644100"
  },
  {
    Country: "Australia",
    Pcode: "6036",
    Locality: "JINDALEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.683500",
    Latitude: "-31.640900"
  },
  {
    Country: "Australia",
    Pcode: "6037",
    Locality: "TWO ROCKS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.617700",
    Latitude: "-31.489100"
  },
  {
    Country: "Australia",
    Pcode: "6038",
    Locality: "ALKIMOS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.685000",
    Latitude: "-31.615000"
  },
  {
    Country: "Australia",
    Pcode: "6041",
    Locality: "CARABAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.541800",
    Latitude: "-31.357700"
  },
  {
    Country: "Australia",
    Pcode: "6041",
    Locality: "GABBADAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.505800",
    Latitude: "-31.291100"
  },
  {
    Country: "Australia",
    Pcode: "6041",
    Locality: "GUILDERTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.508100",
    Latitude: "-31.337700"
  },
  {
    Country: "Australia",
    Pcode: "6041",
    Locality: "WILBINGA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.612700",
    Latitude: "-31.402100"
  },
  {
    Country: "Australia",
    Pcode: "6041",
    Locality: "WOODRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.595000",
    Latitude: "-31.331400"
  },
  {
    Country: "Australia",
    Pcode: "6042",
    Locality: "SEABIRD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.455900",
    Latitude: "-31.265300"
  },
  {
    Country: "Australia",
    Pcode: "6043",
    Locality: "BRETON BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.485500",
    Latitude: "-31.180100"
  },
  {
    Country: "Australia",
    Pcode: "6043",
    Locality: "LEDGE POINT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.387300",
    Latitude: "-31.100200"
  },
  {
    Country: "Australia",
    Pcode: "6044",
    Locality: "KARAKIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.463500",
    Latitude: "-31.063600"
  },
  {
    Country: "Australia",
    Pcode: "6044",
    Locality: "LANCELIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.358900",
    Latitude: "-31.030800"
  },
  {
    Country: "Australia",
    Pcode: "6044",
    Locality: "NILGEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.409500",
    Latitude: "-30.960400"
  },
  {
    Country: "Australia",
    Pcode: "6044",
    Locality: "WEDGE ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.256000",
    Latitude: "-30.811200"
  },
  {
    Country: "Australia",
    Pcode: "6050",
    Locality: "COOLBINIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.854200",
    Latitude: "-31.914000"
  },
  {
    Country: "Australia",
    Pcode: "6050",
    Locality: "MENORA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.861100",
    Latitude: "-31.918600"
  },
  {
    Country: "Australia",
    Pcode: "6050",
    Locality: "MOUNT LAWLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.875400",
    Latitude: "-31.930700"
  },
  {
    Country: "Australia",
    Pcode: "6051",
    Locality: "MAYLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.900400",
    Latitude: "-31.938500"
  },
  {
    Country: "Australia",
    Pcode: "6052",
    Locality: "BEDFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.891700",
    Latitude: "-31.909500"
  },
  {
    Country: "Australia",
    Pcode: "6052",
    Locality: "INGLEWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.881700",
    Latitude: "-31.917500"
  },
  {
    Country: "Australia",
    Pcode: "6053",
    Locality: "BAYSWATER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.914900",
    Latitude: "-31.914000"
  },
  {
    Country: "Australia",
    Pcode: "6054",
    Locality: "ASHFIELD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.937800",
    Latitude: "-31.917600"
  },
  {
    Country: "Australia",
    Pcode: "6054",
    Locality: "BASSENDEAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.944300",
    Latitude: "-31.908300"
  },
  {
    Country: "Australia",
    Pcode: "6054",
    Locality: "EDEN HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.947500",
    Latitude: "-31.890900"
  },
  {
    Country: "Australia",
    Pcode: "6054",
    Locality: "KIARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.939000",
    Latitude: "-31.881600"
  },
  {
    Country: "Australia",
    Pcode: "6054",
    Locality: "LOCKRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.950400",
    Latitude: "-31.881600"
  },
  {
    Country: "Australia",
    Pcode: "6055",
    Locality: "BRABHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.973700",
    Latitude: "-31.829400"
  },
  {
    Country: "Australia",
    Pcode: "6055",
    Locality: "BUSHMEAD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.029800",
    Latitude: "-31.928200"
  },
  {
    Country: "Australia",
    Pcode: "6055",
    Locality: "CAVERSHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.974000",
    Latitude: "-31.877900"
  },
  {
    Country: "Australia",
    Pcode: "6055",
    Locality: "DAYTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.973500",
    Latitude: "-31.854300"
  },
  {
    Country: "Australia",
    Pcode: "6055",
    Locality: "GUILDFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.972000",
    Latitude: "-31.897000"
  },
  {
    Country: "Australia",
    Pcode: "6055",
    Locality: "HAZELMERE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.006600",
    Latitude: "-31.913000"
  },
  {
    Country: "Australia",
    Pcode: "6055",
    Locality: "HENLEY BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.983900",
    Latitude: "-31.811700"
  },
  {
    Country: "Australia",
    Pcode: "6055",
    Locality: "SOUTH GUILDFORD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.976000",
    Latitude: "-31.909500"
  },
  {
    Country: "Australia",
    Pcode: "6055",
    Locality: "WEST SWAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.994500",
    Latitude: "-31.848000"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "BASKERVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.048200",
    Latitude: "-31.799400"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "BELLEVUE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.027600",
    Latitude: "-31.901300"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "BOYA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.054700",
    Latitude: "-31.916500"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "GREENMOUNT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.056900",
    Latitude: "-31.904900"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "HELENA VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.038900",
    Latitude: "-31.919000"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "HERNE HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.028700",
    Latitude: "-31.833800"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "JANE BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.056600",
    Latitude: "-31.865300"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "KOONGAMIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.042900",
    Latitude: "-31.905800"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "MIDDLE SWAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.022600",
    Latitude: "-31.862400"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "MIDLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.009700",
    Latitude: "-31.890200"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "MIDVALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.028400",
    Latitude: "-31.885100"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "MILLENDON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.037900",
    Latitude: "-31.808000"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "RED HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.076500",
    Latitude: "-31.819800"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "STRATTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.039900",
    Latitude: "-31.868600"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "SWAN VIEW",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.050500",
    Latitude: "-31.882300"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "VIVEASH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.997700",
    Latitude: "-31.876900"
  },
  {
    Country: "Australia",
    Pcode: "6056",
    Locality: "WOODBRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.993000",
    Latitude: "-31.890700"
  },
  {
    Country: "Australia",
    Pcode: "6057",
    Locality: "HIGH WYCOMBE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.999700",
    Latitude: "-31.946500"
  },
  {
    Country: "Australia",
    Pcode: "6057",
    Locality: "MAIDA VALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.021200",
    Latitude: "-31.954300"
  },
  {
    Country: "Australia",
    Pcode: "6058",
    Locality: "FORRESTFIELD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.007000",
    Latitude: "-31.982000"
  },
  {
    Country: "Australia",
    Pcode: "6059",
    Locality: "DIANELLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.871400",
    Latitude: "-31.887000"
  },
  {
    Country: "Australia",
    Pcode: "6060",
    Locality: "JOONDANNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.837100",
    Latitude: "-31.909800"
  },
  {
    Country: "Australia",
    Pcode: "6060",
    Locality: "TUART HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.836100",
    Latitude: "-31.897200"
  },
  {
    Country: "Australia",
    Pcode: "6060",
    Locality: "YOKINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.853300",
    Latitude: "-31.900700"
  },
  {
    Country: "Australia",
    Pcode: "6060",
    Locality: "YOKINE SOUTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 2001-2468",
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6061",
    Locality: "BALGA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.839600",
    Latitude: "-31.853500"
  },
  {
    Country: "Australia",
    Pcode: "6061",
    Locality: "MIRRABOOKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.866900",
    Latitude: "-31.861800"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "BEELIAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.829000",
    Latitude: "-32.143200"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "COCKBURN CENTRAL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.846000",
    Latitude: "-32.119500"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "HAMMOND PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.843300",
    Latitude: "-32.170500"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "JANDAKOT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.874600",
    Latitude: "-32.104900"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "SOUTH LAKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.838300",
    Latitude: "-32.107100"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "SUCCESS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.848200",
    Latitude: "-32.144400"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "TREEBY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.890700",
    Latitude: "-32.119400"
  },
  {
    Country: "Australia",
    Pcode: "6164",
    Locality: "YANGEBUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.824000",
    Latitude: "-32.118200"
  },
  {
    Country: "Australia",
    Pcode: "6165",
    Locality: "HOPE VALLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.813700",
    Latitude: "-32.196200"
  },
  {
    Country: "Australia",
    Pcode: "6165",
    Locality: "NAVAL BASE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.780900",
    Latitude: "-32.195000"
  },
  {
    Country: "Australia",
    Pcode: "6166",
    Locality: "COOGEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.767700",
    Latitude: "-32.122900"
  },
  {
    Country: "Australia",
    Pcode: "6166",
    Locality: "HENDERSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.776900",
    Latitude: "-32.163600"
  },
  {
    Country: "Australia",
    Pcode: "6166",
    Locality: "MUNSTER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.778800",
    Latitude: "-32.137700"
  },
  {
    Country: "Australia",
    Pcode: "6166",
    Locality: "WATTLEUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.817100",
    Latitude: "-32.163900"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "ANKETELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.866300",
    Latitude: "-32.219400"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "BERTRAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.843300",
    Latitude: "-32.243300"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "CALISTA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.801600",
    Latitude: "-32.247100"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "CASUARINA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.868000",
    Latitude: "-32.239400"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "KWINANA BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.777200",
    Latitude: "-32.229600"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "KWINANA TOWN CENTRE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.814200",
    Latitude: "-32.247700"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "MANDOGALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.840500",
    Latitude: "-32.194700"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "MEDINA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.799300",
    Latitude: "-32.233600"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "ORELIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.826500",
    Latitude: "-32.233500"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "PARMELIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.826400",
    Latitude: "-32.246100"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "POSTANS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.812200",
    Latitude: "-32.218800"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "THE SPECTACLES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.838200",
    Latitude: "-32.220400"
  },
  {
    Country: "Australia",
    Pcode: "6167",
    Locality: "WANDI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.877200",
    Latitude: "-32.195600"
  },
  {
    Country: "Australia",
    Pcode: "6168",
    Locality: "COOLOONGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.773100",
    Latitude: "-32.294400"
  },
  {
    Country: "Australia",
    Pcode: "6168",
    Locality: "EAST ROCKINGHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.767000",
    Latitude: "-32.263000"
  },
  {
    Country: "Australia",
    Pcode: "6168",
    Locality: "GARDEN ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.678600",
    Latitude: "-32.212500"
  },
  {
    Country: "Australia",
    Pcode: "6168",
    Locality: "HILLMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.766700",
    Latitude: "-32.282800"
  },
  {
    Country: "Australia",
    Pcode: "6168",
    Locality: "PERON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.699600",
    Latitude: "-32.275200"
  },
  {
    Country: "Australia",
    Pcode: "6168",
    Locality: "ROCKINGHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.735500",
    Latitude: "-32.279800"
  },
  {
    Country: "Australia",
    Pcode: "6168",
    Locality: "ROCKINGHAM BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6168",
    Locality: "ROCKINGHAM DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6169",
    Locality: "SAFETY BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.736900",
    Latitude: "-32.308200"
  },
  {
    Country: "Australia",
    Pcode: "6169",
    Locality: "SHOALWATER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.713000",
    Latitude: "-32.296100"
  },
  {
    Country: "Australia",
    Pcode: "6169",
    Locality: "WAIKIKI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.766700",
    Latitude: "-32.315000"
  },
  {
    Country: "Australia",
    Pcode: "6169",
    Locality: "WARNBRO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.766600",
    Latitude: "-32.338300"
  },
  {
    Country: "Australia",
    Pcode: "6170",
    Locality: "LEDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.799000",
    Latitude: "-32.268500"
  },
  {
    Country: "Australia",
    Pcode: "6170",
    Locality: "WELLARD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.845900",
    Latitude: "-32.269400"
  },
  {
    Country: "Australia",
    Pcode: "6171",
    Locality: "BALDIVIS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.833200",
    Latitude: "-32.331300"
  },
  {
    Country: "Australia",
    Pcode: "6172",
    Locality: "PORT KENNEDY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.746500",
    Latitude: "-32.371100"
  },
  {
    Country: "Australia",
    Pcode: "6173",
    Locality: "SECRET HARBOUR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.759100",
    Latitude: "-32.406200"
  },
  {
    Country: "Australia",
    Pcode: "6174",
    Locality: "GOLDEN BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.760900",
    Latitude: "-32.425300"
  },
  {
    Country: "Australia",
    Pcode: "6175",
    Locality: "SINGLETON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.758700",
    Latitude: "-32.444700"
  },
  {
    Country: "Australia",
    Pcode: "6176",
    Locality: "KARNUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.798600",
    Latitude: "-32.422400"
  },
  {
    Country: "Australia",
    Pcode: "6180",
    Locality: "LAKELANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.773100",
    Latitude: "-32.471400"
  },
  {
    Country: "Australia",
    Pcode: "6180",
    Locality: "PARKLANDS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.770200",
    Latitude: "-32.494500"
  },
  {
    Country: "Australia",
    Pcode: "6181",
    Locality: "STAKE HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.803700",
    Latitude: "-32.500500"
  },
  {
    Country: "Australia",
    Pcode: "6182",
    Locality: "KERALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.826400",
    Latitude: "-32.444300"
  },
  {
    Country: "Australia",
    Pcode: "6207",
    Locality: "MYARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.058500",
    Latitude: "-32.486600"
  },
  {
    Country: "Australia",
    Pcode: "6207",
    Locality: "NAMBEELUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.860300",
    Latitude: "-32.500000"
  },
  {
    Country: "Australia",
    Pcode: "6207",
    Locality: "NORTH DANDALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.962300",
    Latitude: "-32.510200"
  },
  {
    Country: "Australia",
    Pcode: "6207",
    Locality: "SOLUS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.182000",
    Latitude: "-32.533000"
  },
  {
    Country: "Australia",
    Pcode: "6207",
    Locality: "WHITTAKER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.054600",
    Latitude: "-32.547600"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "BLYTHEWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.849700",
    Latitude: "-32.678500"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "FAIRBRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.948000",
    Latitude: "-32.587100"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "MEELON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.938700",
    Latitude: "-32.736900"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "NIRIMBA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.738600",
    Latitude: "-32.658000"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "NORTH YUNDERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.793500",
    Latitude: "-32.574600"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "OAKLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.924400",
    Latitude: "-32.648900"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "PINJARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.872100",
    Latitude: "-32.613700"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "POINT GREY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.684100",
    Latitude: "-32.657700"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "RAVENSWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.859300",
    Latitude: "-32.579500"
  },
  {
    Country: "Australia",
    Pcode: "6208",
    Locality: "SOUTH YUNDERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.737600",
    Latitude: "-32.609300"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "EAST NANNUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.864100",
    Latitude: "-34.029000"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "JALBARRAGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.620400",
    Latitude: "-34.017700"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "JARRAHWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.663900",
    Latitude: "-33.793000"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "NANNUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.854300",
    Latitude: "-33.936000"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "SCOTT RIVER EAST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.502200",
    Latitude: "-34.278600"
  },
  {
    Country: "Australia",
    Pcode: "6275",
    Locality: "YOGANUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.628400",
    Latitude: "-33.735600"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "ABBA RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.486800",
    Latitude: "-33.683500"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "ABBEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.256400",
    Latitude: "-33.663600"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "ACTON PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.374100",
    Latitude: "-33.758100"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "AMBERGATE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.310200",
    Latitude: "-33.709200"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "ANNIEBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.167000",
    Latitude: "-33.665500"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "BOALLIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.256700",
    Latitude: "-33.784900"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "BOVELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.354000",
    Latitude: "-33.679600"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "BROADWATER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.283800",
    Latitude: "-33.662500"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "BUSSELTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.348400",
    Latitude: "-33.656600"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "CARBUNUP RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.173900",
    Latitude: "-33.705000"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "CHAPMAN HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.333200",
    Latitude: "-33.786500"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "GEOGRAPHE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.386700",
    Latitude: "-33.638100"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "HITHERGREEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.493700",
    Latitude: "-33.722500"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "JINDONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.238500",
    Latitude: "-33.729800"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "KALGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.371600",
    Latitude: "-33.721400"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "KALOORUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.205600",
    Latitude: "-33.784300"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "KEALY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.230500",
    Latitude: "-33.664700"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "LUDLOW",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.507500",
    Latitude: "-33.606200"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "MARYBROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.204500",
    Latitude: "-33.671400"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "METRICUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.129800",
    Latitude: "-33.785400"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "NORTH JINDONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.189200",
    Latitude: "-33.730900"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "REINSCOURT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.388200",
    Latitude: "-33.649700"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "RUABON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.483300",
    Latitude: "-33.648400"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "SABINA RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.427400",
    Latitude: "-33.746900"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "SIESTA PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.223100",
    Latitude: "-33.655100"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "TUTUNUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.555900",
    Latitude: "-33.662500"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "VASSE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.263300",
    Latitude: "-33.702100"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "WALSALL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.378000",
    Latitude: "-33.801100"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "WEST BUSSELTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.321900",
    Latitude: "-33.658100"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "WILYABRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.040000",
    Latitude: "-33.771400"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "WONNERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.434500",
    Latitude: "-33.619300"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "YALYALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.408600",
    Latitude: "-33.670500"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "YELVERTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.121500",
    Latitude: "-33.741300"
  },
  {
    Country: "Australia",
    Pcode: "6280",
    Locality: "YOONGARILLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.428300",
    Latitude: "-33.716400"
  },
  {
    Country: "Australia",
    Pcode: "6281",
    Locality: "DUNSBOROUGH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.092900",
    Latitude: "-33.601200"
  },
  {
    Country: "Australia",
    Pcode: "6281",
    Locality: "EAGLE BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.060600",
    Latitude: "-33.563000"
  },
  {
    Country: "Australia",
    Pcode: "6281",
    Locality: "NATURALISTE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.049400",
    Latitude: "-33.591100"
  },
  {
    Country: "Australia",
    Pcode: "6281",
    Locality: "QUEDJINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.081000",
    Latitude: "-33.633000"
  },
  {
    Country: "Australia",
    Pcode: "6281",
    Locality: "QUINDALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.119000",
    Latitude: "-33.660000"
  },
  {
    Country: "Australia",
    Pcode: "6282",
    Locality: "YALLINGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.031500",
    Latitude: "-33.679600"
  },
  {
    Country: "Australia",
    Pcode: "6282",
    Locality: "YALLINGUP SIDING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.102300",
    Latitude: "-33.695800"
  },
  {
    Country: "Australia",
    Pcode: "6284",
    Locality: "BAUDIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.444300",
    Latitude: "-33.872900"
  },
  {
    Country: "Australia",
    Pcode: "6284",
    Locality: "COWARAMUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.082900",
    Latitude: "-33.858900"
  },
  {
    Country: "Australia",
    Pcode: "6284",
    Locality: "GRACETOWN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.006100",
    Latitude: "-33.868700"
  },
  {
    Country: "Australia",
    Pcode: "6284",
    Locality: "TREETON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.227500",
    Latitude: "-33.856600"
  },
  {
    Country: "Australia",
    Pcode: "6285",
    Locality: "BRAMLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.115000",
    Latitude: "-33.914400"
  },
  {
    Country: "Australia",
    Pcode: "6285",
    Locality: "BURNSIDE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.017300",
    Latitude: "-33.932700"
  },
  {
    Country: "Australia",
    Pcode: "6285",
    Locality: "GNARABUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.996000",
    Latitude: "-33.994200"
  },
  {
    Country: "Australia",
    Pcode: "6285",
    Locality: "MARGARET RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.054800",
    Latitude: "-33.966600"
  },
  {
    Country: "Australia",
    Pcode: "6285",
    Locality: "OSMINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.220900",
    Latitude: "-33.910300"
  },
  {
    Country: "Australia",
    Pcode: "6285",
    Locality: "PREVELLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.991100",
    Latitude: "-33.977300"
  },
  {
    Country: "Australia",
    Pcode: "6285",
    Locality: "ROSA BROOK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.205000",
    Latitude: "-33.952200"
  },
  {
    Country: "Australia",
    Pcode: "6285",
    Locality: "ROSA GLEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.215300",
    Latitude: "-33.982600"
  },
  {
    Country: "Australia",
    Pcode: "6285",
    Locality: "SCHROEDER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.361800",
    Latitude: "-34.037200"
  },
  {
    Country: "Australia",
    Pcode: "6286",
    Locality: "BORANUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.048100",
    Latitude: "-34.134900"
  },
  {
    Country: "Australia",
    Pcode: "6286",
    Locality: "FOREST GROVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.156500",
    Latitude: "-34.097600"
  },
  {
    Country: "Australia",
    Pcode: "6286",
    Locality: "REDGATE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.023000",
    Latitude: "-34.019200"
  },
  {
    Country: "Australia",
    Pcode: "6286",
    Locality: "WITCHCLIFFE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.115600",
    Latitude: "-34.013200"
  },
  {
    Country: "Australia",
    Pcode: "6288",
    Locality: "ALEXANDRA BRIDGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.199200",
    Latitude: "-34.158500"
  },
  {
    Country: "Australia",
    Pcode: "6288",
    Locality: "COURTENAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.274000",
    Latitude: "-34.187900"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "DENMARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.351600",
    Latitude: "-34.975900"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "HAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.426500",
    Latitude: "-34.946100"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "HAZELVALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.823900",
    Latitude: "-34.921200"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "KENTDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.061300",
    Latitude: "-34.937100"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "KORDABUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.143400",
    Latitude: "-34.916700"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "MOUNT LINDESAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.370000",
    Latitude: "-34.827400"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "MOUNT ROMANCE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.132200",
    Latitude: "-34.834800"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "NORNALUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.816200",
    Latitude: "-35.012400"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "OCEAN BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.308000",
    Latitude: "-35.008500"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "PARRYVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.091600",
    Latitude: "-35.011000"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "PEACEFUL BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.914300",
    Latitude: "-35.026800"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "SCOTSDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.290600",
    Latitude: "-34.904700"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "SHADFORTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.274100",
    Latitude: "-34.961500"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "TINGLEDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.880200",
    Latitude: "-34.959500"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "TRENT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.907900",
    Latitude: "-34.847400"
  },
  {
    Country: "Australia",
    Pcode: "6333",
    Locality: "WILLIAM BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.196000",
    Latitude: "-34.999800"
  },
  {
    Country: "Australia",
    Pcode: "6335",
    Locality: "GNOWANGERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.004400",
    Latitude: "-33.935200"
  },
  {
    Country: "Australia",
    Pcode: "6335",
    Locality: "JACKITUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.121600",
    Latitude: "-33.884500"
  },
  {
    Country: "Australia",
    Pcode: "6335",
    Locality: "KEBARINGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.165300",
    Latitude: "-34.037900"
  },
  {
    Country: "Australia",
    Pcode: "6335",
    Locality: "PALLINUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.960000",
    Latitude: "-33.905500"
  },
  {
    Country: "Australia",
    Pcode: "6336",
    Locality: "COWALELLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.560700",
    Latitude: "-34.071800"
  },
  {
    Country: "Australia",
    Pcode: "6336",
    Locality: "MILLS LAKE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.592900",
    Latitude: "-33.861200"
  },
  {
    Country: "Australia",
    Pcode: "6336",
    Locality: "MINDARABIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.356300",
    Latitude: "-33.899200"
  },
  {
    Country: "Australia",
    Pcode: "6336",
    Locality: "NEEDILUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.763600",
    Latitude: "-33.995000"
  },
  {
    Country: "Australia",
    Pcode: "6336",
    Locality: "ONGERUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.488900",
    Latitude: "-33.962200"
  },
  {
    Country: "Australia",
    Pcode: "6336",
    Locality: "TOOMPUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.394300",
    Latitude: "-34.032300"
  },
  {
    Country: "Australia",
    Pcode: "6337",
    Locality: "FITZGERALD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.456100",
    Latitude: "-33.748300"
  },
  {
    Country: "Australia",
    Pcode: "6337",
    Locality: "GAIRDNER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.011200",
    Latitude: "-34.219600"
  },
  {
    Country: "Australia",
    Pcode: "6337",
    Locality: "JACUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.165100",
    Latitude: "-33.807600"
  },
  {
    Country: "Australia",
    Pcode: "6337",
    Locality: "JERRAMUNGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.078800",
    Latitude: "-33.992000"
  },
  {
    Country: "Australia",
    Pcode: "6337",
    Locality: "WEST FITZGERALD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.393300",
    Latitude: "-33.747900"
  },
  {
    Country: "Australia",
    Pcode: "6338",
    Locality: "AMELUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.259700",
    Latitude: "-34.234200"
  },
  {
    Country: "Australia",
    Pcode: "6338",
    Locality: "BORDEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.260400",
    Latitude: "-34.072800"
  },
  {
    Country: "Australia",
    Pcode: "6338",
    Locality: "BOXWOOD HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.872900",
    Latitude: "-34.342400"
  },
  {
    Country: "Australia",
    Pcode: "6338",
    Locality: "BREMER BAY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.255700",
    Latitude: "-34.396900"
  },
  {
    Country: "Australia",
    Pcode: "6338",
    Locality: "MAGITUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.170500",
    Latitude: "-34.136300"
  },
  {
    Country: "Australia",
    Pcode: "6338",
    Locality: "MONJEBUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.543000",
    Latitude: "-34.259700"
  },
  {
    Country: "Australia",
    Pcode: "6338",
    Locality: "NALYERLUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.403900",
    Latitude: "-34.177800"
  },
  {
    Country: "Australia",
    Pcode: "6338",
    Locality: "NORTH STIRLINGS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.051100",
    Latitude: "-34.187600"
  },
  {
    Country: "Australia",
    Pcode: "6338",
    Locality: "STIRLING RANGE NATIONAL PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.054400",
    Latitude: "-34.415100"
  },
  {
    Country: "Australia",
    Pcode: "6341",
    Locality: "NYABING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.159800",
    Latitude: "-33.556700"
  },
  {
    Country: "Australia",
    Pcode: "6343",
    Locality: "PINGRUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.755900",
    Latitude: "-33.539400"
  },
  {
    Country: "Australia",
    Pcode: "6346",
    Locality: "FITZGERALD RIVER NATIONAL PARK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.496800",
    Latitude: "-34.046300"
  },
  {
    Country: "Australia",
    Pcode: "6346",
    Locality: "JERDACUTTUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.430400",
    Latitude: "-33.768100"
  },
  {
    Country: "Australia",
    Pcode: "6346",
    Locality: "RAVENSTHORPE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.018400",
    Latitude: "-33.460000"
  },
  {
    Country: "Australia",
    Pcode: "6346",
    Locality: "WEST RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.622600",
    Latitude: "-33.569100"
  },
  {
    Country: "Australia",
    Pcode: "6348",
    Locality: "HOPETOUN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.091300",
    Latitude: "-33.861400"
  },
  {
    Country: "Australia",
    Pcode: "6350",
    Locality: "DUMBLEYUNG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.765100",
    Latitude: "-33.305700"
  },
  {
    Country: "Australia",
    Pcode: "6350",
    Locality: "NAIRIBIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.921800",
    Latitude: "-33.336500"
  },
  {
    Country: "Australia",
    Pcode: "6350",
    Locality: "NIPPERING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.680100",
    Latitude: "-33.273800"
  },
  {
    Country: "Australia",
    Pcode: "6351",
    Locality: "MOULYINNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.949600",
    Latitude: "-33.258600"
  },
  {
    Country: "Australia",
    Pcode: "6351",
    Locality: "NORTH MOULYINNING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.905700",
    Latitude: "-33.116400"
  },
  {
    Country: "Australia",
    Pcode: "6352",
    Locality: "KUKERIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.089500",
    Latitude: "-33.186300"
  },
  {
    Country: "Australia",
    Pcode: "6352",
    Locality: "MERILUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.212100",
    Latitude: "-33.300300"
  },
  {
    Country: "Australia",
    Pcode: "6352",
    Locality: "NORTH KUKERIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.065700",
    Latitude: "-33.094200"
  },
  {
    Country: "Australia",
    Pcode: "6352",
    Locality: "SOUTH KUKERIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.138300",
    Latitude: "-33.215500"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "BEENONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.613700",
    Latitude: "-33.045200"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "BUNICHE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.784500",
    Latitude: "-33.013300"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "KUENDER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.520000",
    Latitude: "-32.856200"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "LAKE GRACE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.434600",
    Latitude: "-33.094500"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "MALLEE HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.681700",
    Latitude: "-33.222500"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "NEENDALING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.326700",
    Latitude: "-33.047500"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "NORTH BURNGUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.775000",
    Latitude: "-32.845800"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "NORTH LAKE GRACE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.464700",
    Latitude: "-33.028500"
  },
  {
    Country: "Australia",
    Pcode: "6353",
    Locality: "SOUTH LAKE GRACE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.462100",
    Latitude: "-33.236500"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "WEST KALGOORLIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.433800",
    Latitude: "-30.780900"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "WEST LAMINGTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.450100",
    Latitude: "-30.746100"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "WILLIAMSTOWN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.485800",
    Latitude: "-30.738400"
  },
  {
    Country: "Australia",
    Pcode: "6430",
    Locality: "YILKARI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.437500",
    Latitude: "-30.816500"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "BOORARA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.637800",
    Latitude: "-30.807400"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "BROWN HILL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.527000",
    Latitude: "-30.759700"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "BULONG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.961200",
    Latitude: "-30.741600"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "EMU FLAT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.336300",
    Latitude: "-31.030600"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "FEYSVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.602900",
    Latitude: "-31.012500"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "KANOWNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.456100",
    Latitude: "-30.397700"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "KOOKYNIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.058900",
    Latitude: "-29.635200"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "KURNALPI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.054400",
    Latitude: "-30.397200"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "LAKEWOOD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.525800",
    Latitude: "-30.829500"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "ORA BANDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.014700",
    Latitude: "-30.365900"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "PLUMRIDGE LAKES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "125.883300",
    Latitude: "-29.670500"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "TRAFALGAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.574400",
    Latitude: "-30.811300"
  },
  {
    Country: "Australia",
    Pcode: "6431",
    Locality: "WARBURTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "126.754800",
    Latitude: "-26.125500"
  },
  {
    Country: "Australia",
    Pcode: "6432",
    Locality: "BOULDER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.488300",
    Latitude: "-30.774600"
  },
  {
    Country: "Australia",
    Pcode: "6432",
    Locality: "FIMISTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.505700",
    Latitude: "-30.776300"
  },
  {
    Country: "Australia",
    Pcode: "6432",
    Locality: "SOUTH BOULDER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.496700",
    Latitude: "-30.805000"
  },
  {
    Country: "Australia",
    Pcode: "6432",
    Locality: "VICTORY HEIGHTS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.471700",
    Latitude: "-30.778000"
  },
  {
    Country: "Australia",
    Pcode: "6433",
    Locality: "HANNANS PO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 8001-8360",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6433",
    Locality: "KALGOORLIE DC",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 1-1671 & 4000-5550",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6433",
    Locality: "KALGOORLIE PO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES 10001-11320",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6434",
    Locality: "CUNDEELEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.111200",
    Latitude: "-30.766100"
  },
  {
    Country: "Australia",
    Pcode: "6434",
    Locality: "FORREST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "127.687900",
    Latitude: "-30.762600"
  },
  {
    Country: "Australia",
    Pcode: "6434",
    Locality: "PARKESTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.530300",
    Latitude: "-30.711100"
  },
  {
    Country: "Australia",
    Pcode: "6434",
    Locality: "RAWLINNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "125.396400",
    Latitude: "-30.778900"
  },
  {
    Country: "Australia",
    Pcode: "6434",
    Locality: "ZANTHUS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.923900",
    Latitude: "-30.765700"
  },
  {
    Country: "Australia",
    Pcode: "6436",
    Locality: "MENZIES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.298300",
    Latitude: "-29.736500"
  },
  {
    Country: "Australia",
    Pcode: "6436",
    Locality: "ULARRING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.640000",
    Latitude: "-29.313500"
  },
  {
    Country: "Australia",
    Pcode: "6437",
    Locality: "LEINSTER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.713600",
    Latitude: "-28.076100"
  },
  {
    Country: "Australia",
    Pcode: "6437",
    Locality: "SIR SAMUEL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.480800",
    Latitude: "-27.516900"
  },
  {
    Country: "Australia",
    Pcode: "6438",
    Locality: "LAKE DARLOT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.406100",
    Latitude: "-27.737800"
  },
  {
    Country: "Australia",
    Pcode: "6438",
    Locality: "LEONORA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.268800",
    Latitude: "-28.841300"
  },
  {
    Country: "Australia",
    Pcode: "6440",
    Locality: "BANDYA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.072300",
    Latitude: "-27.953500"
  },
  {
    Country: "Australia",
    Pcode: "6440",
    Locality: "BEADELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "127.553100",
    Latitude: "-27.892100"
  },
  {
    Country: "Australia",
    Pcode: "6440",
    Locality: "COSMO NEWBERY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.404400",
    Latitude: "-28.049900"
  },
  {
    Country: "Australia",
    Pcode: "6440",
    Locality: "LAKE WELLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.050400",
    Latitude: "-27.369800"
  },
  {
    Country: "Australia",
    Pcode: "6440",
    Locality: "LAVERTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.593300",
    Latitude: "-28.743400"
  },
  {
    Country: "Australia",
    Pcode: "6440",
    Locality: "NEALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "125.050600",
    Latitude: "-27.890100"
  },
  {
    Country: "Australia",
    Pcode: "6442",
    Locality: "KAMBALDA EAST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.712200",
    Latitude: "-31.216300"
  },
  {
    Country: "Australia",
    Pcode: "6442",
    Locality: "KAMBALDA WEST",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.585600",
    Latitude: "-31.171700"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "BALLADONIA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "124.223500",
    Latitude: "-32.208300"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "CAIGUNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "125.262600",
    Latitude: "-32.020500"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "COCKLEBIDDY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "126.149300",
    Latitude: "-31.826900"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "DUNDAS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.800400",
    Latitude: "-32.426800"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "EUCLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "128.529100",
    Latitude: "-31.643300"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "FRASER RANGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.733300",
    Latitude: "-31.979200"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "HIGGINSVILLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.639700",
    Latitude: "-31.767300"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "MADURA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "127.013200",
    Latitude: "-31.835300"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "MUNDRABILLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "127.856400",
    Latitude: "-31.673500"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "NORSEMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.877600",
    Latitude: "-32.316000"
  },
  {
    Country: "Australia",
    Pcode: "6443",
    Locality: "WIDGIEMOOLTHA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.894600",
    Latitude: "-31.523600"
  },
  {
    Country: "Australia",
    Pcode: "6445",
    Locality: "NORTH CASCADE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.815800",
    Latitude: "-32.969400"
  },
  {
    Country: "Australia",
    Pcode: "6445",
    Locality: "SALMON GUMS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.691800",
    Latitude: "-32.855500"
  },
  {
    Country: "Australia",
    Pcode: "6446",
    Locality: "GRASS PATCH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.830000",
    Latitude: "-33.222400"
  },
  {
    Country: "Australia",
    Pcode: "6447",
    Locality: "LORT RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.372800",
    Latitude: "-33.357900"
  },
  {
    Country: "Australia",
    Pcode: "6447",
    Locality: "MOUNT NEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.314800",
    Latitude: "-33.357800"
  },
  {
    Country: "Australia",
    Pcode: "6447",
    Locality: "SCADDAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.740500",
    Latitude: "-33.408700"
  },
  {
    Country: "Australia",
    Pcode: "6447",
    Locality: "WITTENOOM HILLS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.182900",
    Latitude: "-33.479800"
  },
  {
    Country: "Australia",
    Pcode: "6448",
    Locality: "GIBSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.851900",
    Latitude: "-33.633100"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "BANDY CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.972900",
    Latitude: "-33.814600"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "BEAUMONT",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.813200",
    Latitude: "-33.384800"
  },
  {
    Country: "Australia",
    Pcode: "6450",
    Locality: "BOYATUP",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.112900",
    Latitude: "-33.652400"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "SPALDING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.634700",
    Latitude: "-28.734200"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "STRATHALBYN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.646100",
    Latitude: "-28.749100"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "SUNSET BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.623400",
    Latitude: "-28.718000"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "TARCOOLA BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.620500",
    Latitude: "-28.806000"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "UTAKARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.652200",
    Latitude: "-28.780100"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "WAGGRAKINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.665200",
    Latitude: "-28.692600"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "WANDINA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.633600",
    Latitude: "-28.817100"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "WEBBERTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.631700",
    Latitude: "-28.752100"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "WEST END",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.586200",
    Latitude: "-28.778000"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "WONTHELLA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.629200",
    Latitude: "-28.768900"
  },
  {
    Country: "Australia",
    Pcode: "6530",
    Locality: "WOORREE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.659000",
    Latitude: "-28.764600"
  },
  {
    Country: "Australia",
    Pcode: "6531",
    Locality: "GERALDTON PO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: "PO BOXES",
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "AJANA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.704000",
    Latitude: "-27.872200"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "BINNU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.906900",
    Latitude: "-28.013300"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "BOOTENAL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.724300",
    Latitude: "-28.855000"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "BRINGO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.836600",
    Latitude: "-28.740600"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "BULLER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.619900",
    Latitude: "-28.650600"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "BURMA ROAD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.037200",
    Latitude: "-28.960300"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "CAPE BURNEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.650800",
    Latitude: "-28.869600"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "CARRARANG",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.417200",
    Latitude: "-26.269700"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "COBURN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.326200",
    Latitude: "-26.750400"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "COOLCALALAYA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.096000",
    Latitude: "-27.631100"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "DARTMOOR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.189200",
    Latitude: "-27.995000"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "DEEPDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.682100",
    Latitude: "-28.781400"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "DINDILOA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.779600",
    Latitude: "-28.432800"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "DRUMMOND COVE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.615100",
    Latitude: "-28.672800"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "DURAWAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.940400",
    Latitude: "-28.578100"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "EAST CHAPMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.809400",
    Latitude: "-28.687500"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "EAST NABAWA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.935700",
    Latitude: "-28.486800"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "EAST YUNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.229600",
    Latitude: "-28.392500"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "ELLENDALE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.986000",
    Latitude: "-28.871500"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "ERADU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.058200",
    Latitude: "-28.677800"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "ERADU SOUTH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.067400",
    Latitude: "-28.763200"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "EURARDY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.716000",
    Latitude: "-27.505000"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "GEORGINA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.771900",
    Latitude: "-28.877700"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "GLENFIELD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.617800",
    Latitude: "-28.694800"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "GREENOUGH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.717400",
    Latitude: "-28.920100"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "HAMELIN POOL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.387200",
    Latitude: "-26.441600"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "HICKETY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.725500",
    Latitude: "-28.456300"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "HOWATHARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.633100",
    Latitude: "-28.544100"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "KOJARENA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.870500",
    Latitude: "-28.717600"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "MARRAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.972900",
    Latitude: "-28.435100"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "MEADOW",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.609600",
    Latitude: "-26.822500"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "MINNENOOKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.871800",
    Latitude: "-28.846900"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "MOONYOONOOKA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.780100",
    Latitude: "-28.772800"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "MOUNT ERIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.855000",
    Latitude: "-28.575300"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "NABAWA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.775600",
    Latitude: "-28.498700"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "NANSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.762600",
    Latitude: "-28.564500"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "NARALING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.832400",
    Latitude: "-28.391800"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "NARNGULU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.685900",
    Latitude: "-28.824200"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "NARRA TARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.768900",
    Latitude: "-28.626300"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "NERREN NERREN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.403100",
    Latitude: "-27.039300"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "NOLBA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.873200",
    Latitude: "-28.291500"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "NORTH ERADU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.118800",
    Latitude: "-28.542800"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "NORTHERN GULLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.934100",
    Latitude: "-28.675000"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "OAKAJEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.602000",
    Latitude: "-28.596200"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "ROCKWELL",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.915700",
    Latitude: "-28.361900"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "RUDDS GULLY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.665800",
    Latitude: "-28.851800"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "SANDSPRINGS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.958600",
    Latitude: "-28.813500"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "SOUTH YUNA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.015400",
    Latitude: "-28.365300"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "TAMALA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "113.747800",
    Latitude: "-26.759200"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "TIBRADDEN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.925800",
    Latitude: "-28.746900"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "TOOLONGA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.072500",
    Latitude: "-26.972300"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "VALENTINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.045100",
    Latitude: "-28.554400"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "WANDANA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "115.307400",
    Latitude: "-28.183700"
  },
  {
    Country: "Australia",
    Pcode: "6532",
    Locality: "WEST BINNU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "114.413400",
    Latitude: "-27.995200"
  },
  {
    Country: "Australia",
    Pcode: "6720",
    Locality: "POINT SAMSON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.228500",
    Latitude: "-20.583200"
  },
  {
    Country: "Australia",
    Pcode: "6720",
    Locality: "WICKHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.148200",
    Latitude: "-20.674100"
  },
  {
    Country: "Australia",
    Pcode: "6721",
    Locality: "INDEE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.454300",
    Latitude: "-20.924700"
  },
  {
    Country: "Australia",
    Pcode: "6721",
    Locality: "MUNDABULLANGANA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.173700",
    Latitude: "-20.582300"
  },
  {
    Country: "Australia",
    Pcode: "6721",
    Locality: "PARDOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.788000",
    Latitude: "-20.140500"
  },
  {
    Country: "Australia",
    Pcode: "6721",
    Locality: "PORT HEDLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.660700",
    Latitude: "-20.336200"
  },
  {
    Country: "Australia",
    Pcode: "6721",
    Locality: "REDBANK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.610500",
    Latitude: "-20.338400"
  },
  {
    Country: "Australia",
    Pcode: "6721",
    Locality: "STRELLEY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.031800",
    Latitude: "-20.488300"
  },
  {
    Country: "Australia",
    Pcode: "6721",
    Locality: "WALLAREENYA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.806200",
    Latitude: "-20.772400"
  },
  {
    Country: "Australia",
    Pcode: "6721",
    Locality: "WEDGEFIELD",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.599300",
    Latitude: "-20.354800"
  },
  {
    Country: "Australia",
    Pcode: "6722",
    Locality: "BOODARIE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.504900",
    Latitude: "-20.506600"
  },
  {
    Country: "Australia",
    Pcode: "6722",
    Locality: "DE GREY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.193000",
    Latitude: "-20.266400"
  },
  {
    Country: "Australia",
    Pcode: "6722",
    Locality: "FINUCANE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.568300",
    Latitude: "-20.301100"
  },
  {
    Country: "Australia",
    Pcode: "6722",
    Locality: "PIPPINGARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.738900",
    Latitude: "-20.463100"
  },
  {
    Country: "Australia",
    Pcode: "6722",
    Locality: "SOUTH HEDLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.597900",
    Latitude: "-20.404700"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "BILINGURR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.227300",
    Latitude: "-17.914800"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "BROOME",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.234900",
    Latitude: "-17.960600"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "DAMPIER PENINSULA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.872900",
    Latitude: "-16.799000"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "DJUGUN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.235500",
    Latitude: "-17.937300"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "EIGHTY MILE BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.425500",
    Latitude: "-19.501800"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "GINGERAH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.631500",
    Latitude: "-19.050100"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "LAGRANGE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "121.981300",
    Latitude: "-18.691600"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "MINYIRR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.204100",
    Latitude: "-17.986100"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "ROEBUCK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.578200",
    Latitude: "-18.013200"
  },
  {
    Country: "Australia",
    Pcode: "6725",
    Locality: "WATERBANK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.653700",
    Latitude: "-17.491600"
  },
  {
    Country: "Australia",
    Pcode: "6726",
    Locality: "CABLE BEACH",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "122.203500",
    Latitude: "-17.957700"
  },
  {
    Country: "Australia",
    Pcode: "6728",
    Locality: "CAMBALLIN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "124.184300",
    Latitude: "-17.917200"
  },
  {
    Country: "Australia",
    Pcode: "6728",
    Locality: "DERBY",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.712400",
    Latitude: "-17.383400"
  },
  {
    Country: "Australia",
    Pcode: "6728",
    Locality: "GEEGULLY CREEK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.942600",
    Latitude: "-18.331200"
  },
  {
    Country: "Australia",
    Pcode: "6728",
    Locality: "KIMBOLTON",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.761200",
    Latitude: "-16.132900"
  },
  {
    Country: "Australia",
    Pcode: "6728",
    Locality: "KING LEOPOLD RANGES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "125.319200",
    Latitude: "-17.149100"
  },
  {
    Country: "Australia",
    Pcode: "6728",
    Locality: "MEDA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.931900",
    Latitude: "-17.403100"
  },
  {
    Country: "Australia",
    Pcode: "6728",
    Locality: "ST GEORGE RANGES",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "125.024200",
    Latitude: "-18.806200"
  },
  {
    Country: "Australia",
    Pcode: "6728",
    Locality: "WILLARE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.302200",
    Latitude: "-17.542200"
  },
  {
    Country: "Australia",
    Pcode: "6731",
    Locality: "COCKATOO ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Post Office Boxes",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6733",
    Locality: "KOOLAN ISLAND",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: ".000000",
    Latitude: ".000000"
  },
  {
    Country: "Australia",
    Pcode: "6740",
    Locality: "DRYSDALE RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "126.744600",
    Latitude: "-14.745900"
  },
  {
    Country: "Australia",
    Pcode: "6740",
    Locality: "KALUMBURU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "126.639000",
    Latitude: "-14.291600"
  },
  {
    Country: "Australia",
    Pcode: "6740",
    Locality: "MITCHELL PLATEAU",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "125.859300",
    Latitude: "-14.944900"
  },
  {
    Country: "Australia",
    Pcode: "6740",
    Locality: "OOMBULGURRI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "127.720300",
    Latitude: "-15.013300"
  },
  {
    Country: "Australia",
    Pcode: "6740",
    Locality: "PRINCE REGENT RIVER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "124.584700",
    Latitude: "-15.996500"
  },
  {
    Country: "Australia",
    Pcode: "6740",
    Locality: "WYNDHAM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "128.212500",
    Latitude: "-15.638100"
  },
  {
    Country: "Australia",
    Pcode: "6743",
    Locality: "CAMBRIDGE GULF",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "128.564900",
    Latitude: "-15.179500"
  },
  {
    Country: "Australia",
    Pcode: "6743",
    Locality: "DURACK",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "127.575100",
    Latitude: "-16.276700"
  },
  {
    Country: "Australia",
    Pcode: "6743",
    Locality: "GIBB",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "126.633100",
    Latitude: "-16.274400"
  },
  {
    Country: "Australia",
    Pcode: "6743",
    Locality: "KUNUNURRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "128.827800",
    Latitude: "-15.561500"
  },
  {
    Country: "Australia",
    Pcode: "6743",
    Locality: "LAKE ARGYLE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "128.601200",
    Latitude: "-16.240700"
  },
  {
    Country: "Australia",
    Pcode: "6743",
    Locality: "WARMUN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "128.223600",
    Latitude: "-17.026500"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "CHICHESTER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.031100",
    Latitude: "-21.759400"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "INNAWANGA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.780900",
    Latitude: "-23.359200"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "JUNA DOWNS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.789900",
    Latitude: "-22.606300"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "KARIJINI",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.329300",
    Latitude: "-22.761300"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "MOUNT SHEILA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.548100",
    Latitude: "-22.384400"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "MULGA DOWNS",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.865400",
    Latitude: "-22.213100"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "NANUTARRA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "116.284200",
    Latitude: "-22.791200"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "ROCKLEA",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.552500",
    Latitude: "-22.942000"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "TOM PRICE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.797100",
    Latitude: "-22.694800"
  },
  {
    Country: "Australia",
    Pcode: "6751",
    Locality: "WITTENOOM",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "118.274500",
    Latitude: "-22.291100"
  },
  {
    Country: "Australia",
    Pcode: "6753",
    Locality: "NEWMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.979000",
    Latitude: "-23.024100"
  },
  {
    Country: "Australia",
    Pcode: "6754",
    Locality: "PARABURDOO",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "117.691500",
    Latitude: "-23.192600"
  },
  {
    Country: "Australia",
    Pcode: "6758",
    Locality: "NULLAGINE",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "120.339700",
    Latitude: "-21.957100"
  },
  {
    Country: "Australia",
    Pcode: "6760",
    Locality: "MARBLE BAR",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "119.740200",
    Latitude: "-21.164400"
  },
  {
    Country: "Australia",
    Pcode: "6762",
    Locality: "TELFER",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "123.528000",
    Latitude: "-21.504800"
  },
  {
    Country: "Australia",
    Pcode: "6765",
    Locality: "FITZROY CROSSING",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "125.575300",
    Latitude: "-18.190200"
  },
  {
    Country: "Australia",
    Pcode: "6765",
    Locality: "MOUNT HARDMAN",
    State: "Western Australia",
    StateAbbrev: "WA",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "124.919200",
    Latitude: "-18.198300"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "RANDALLS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.129100",
    Latitude: "-43.236600"
  },
  {
    Country: "Australia",
    Pcode: "7112",
    Locality: "VERONA SANDS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.159900",
    Latitude: "-43.275300"
  },
  {
    Country: "Australia",
    Pcode: "7113",
    Locality: "FRANKLIN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.982800",
    Latitude: "-43.086900"
  },
  {
    Country: "Australia",
    Pcode: "7116",
    Locality: "BROOKS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.019500",
    Latitude: "-43.235600"
  },
  {
    Country: "Australia",
    Pcode: "7116",
    Locality: "CAIRNS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.960100",
    Latitude: "-43.186600"
  },
  {
    Country: "Australia",
    Pcode: "7116",
    Locality: "CASTLE FORBES BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.931100",
    Latitude: "-43.108600"
  },
  {
    Country: "Australia",
    Pcode: "7116",
    Locality: "GEEVESTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.859400",
    Latitude: "-43.154000"
  },
  {
    Country: "Australia",
    Pcode: "7116",
    Locality: "POLICE POINT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.038700",
    Latitude: "-43.252200"
  },
  {
    Country: "Australia",
    Pcode: "7116",
    Locality: "PORT HUON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.956300",
    Latitude: "-43.150400"
  },
  {
    Country: "Australia",
    Pcode: "7116",
    Locality: "SURGES BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.981500",
    Latitude: "-43.226600"
  },
  {
    Country: "Australia",
    Pcode: "7116",
    Locality: "SURVEYORS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.062300",
    Latitude: "-43.277000"
  },
  {
    Country: "Australia",
    Pcode: "7117",
    Locality: "DOVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.020100",
    Latitude: "-43.296900"
  },
  {
    Country: "Australia",
    Pcode: "7119",
    Locality: "STONOR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.385700",
    Latitude: "-42.400900"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "ANDOVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.493100",
    Latitude: "-42.336800"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "ANTILL PONDS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.403800",
    Latitude: "-42.215900"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "BADEN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.462600",
    Latitude: "-42.427500"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "LEMONT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.654100",
    Latitude: "-42.295700"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "LEVENDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.572600",
    Latitude: "-42.537100"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "MOUNT SEYMOUR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.466700",
    Latitude: "-42.390100"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "OATLANDS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.309700",
    Latitude: "-42.289100"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "PARATTAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.423600",
    Latitude: "-42.358500"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "PAWTELLA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.517100",
    Latitude: "-42.294600"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "RHYNDASTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.379300",
    Latitude: "-42.474200"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "STONEHENGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.673500",
    Latitude: "-42.408200"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "SWANSTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.761100",
    Latitude: "-42.337000"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "TIBERIAS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.356300",
    Latitude: "-42.434200"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "TUNBRIDGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.372700",
    Latitude: "-42.120300"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "TUNNACK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.462800",
    Latitude: "-42.468300"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "WHITEFOORD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.544200",
    Latitude: "-42.420900"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "WOODBURY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.343000",
    Latitude: "-42.188800"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "WOODSDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.595200",
    Latitude: "-42.477700"
  },
  {
    Country: "Australia",
    Pcode: "7120",
    Locality: "YORK PLAINS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.531800",
    Latitude: "-42.260700"
  },
  {
    Country: "Australia",
    Pcode: "7139",
    Locality: "STRATHGORDON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.050900",
    Latitude: "-42.762000"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "BLACK HILLS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.023900",
    Latitude: "-42.705300"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "BOYER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.105600",
    Latitude: "-42.762400"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "BRADYS LAKE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.486700",
    Latitude: "-42.229700"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "BRONTE PARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.475000",
    Latitude: "-42.092000"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "BUSHY PARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.870600",
    Latitude: "-42.721900"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "BUTLERS GORGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.254200",
    Latitude: "-42.241800"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "DEE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.592300",
    Latitude: "-42.259900"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "DERWENT BRIDGE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.280400",
    Latitude: "-42.128800"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "ELLENDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.687000",
    Latitude: "-42.602600"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "FENTONBURY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.753700",
    Latitude: "-42.652800"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "FITZGERALD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.658000",
    Latitude: "-42.754500"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "FLORENTINE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.500400",
    Latitude: "-42.535700"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "GLENFERN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.990300",
    Latitude: "-42.806300"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "GLENORA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.864900",
    Latitude: "-42.687500"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "GRETNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.945100",
    Latitude: "-42.630700"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "HAMILTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.850300",
    Latitude: "-42.547600"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "HAYES",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.998800",
    Latitude: "-42.737000"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "HOLLOW TREE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.891100",
    Latitude: "-42.485200"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "KARANJA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.835100",
    Latitude: "-42.683000"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "LACHLAN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.030000",
    Latitude: "-42.845400"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "LAKE ST CLAIR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.907000",
    Latitude: "-41.996100"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "LAWITTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.035600",
    Latitude: "-42.762500"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "LITTLE PINE LAGOON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.580700",
    Latitude: "-41.972300"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "LONDON LAKES",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.591500",
    Latitude: "-42.156100"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "MACQUARIE PLAINS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.916400",
    Latitude: "-42.716100"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "MAGRA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.078500",
    Latitude: "-42.731800"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "MALBINA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.119000",
    Latitude: "-42.788400"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "MAYDENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.558900",
    Latitude: "-42.754400"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "MEADOWBANK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.818300",
    Latitude: "-42.630400"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "MOLESWORTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.146600",
    Latitude: "-42.808800"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "MOOGARA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.864800",
    Latitude: "-42.820700"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "MOUNT FIELD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.605300",
    Latitude: "-42.663900"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "MOUNT LLOYD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.902400",
    Latitude: "-42.888000"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "NATIONAL PARK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.720500",
    Latitude: "-42.717500"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "NEW NORFOLK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.058200",
    Latitude: "-42.782100"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "OSTERLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.759900",
    Latitude: "-42.310800"
  },
  {
    Country: "Australia",
    Pcode: "7140",
    Locality: "OUSE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.711900",
    Latitude: "-42.435500"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "NUGARA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.905500",
    Latitude: "-39.954300"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "PEARSHAPE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.902800",
    Latitude: "-40.044300"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "PEGARAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "144.037800",
    Latitude: "-39.924300"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "REEKARA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.976100",
    Latitude: "-39.746900"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "SEA ELEPHANT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "144.072700",
    Latitude: "-39.827100"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "SURPRISE BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.926900",
    Latitude: "-40.159600"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "WICKHAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.971600",
    Latitude: "-39.611200"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "YAMBACOONA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "143.887300",
    Latitude: "-39.702600"
  },
  {
    Country: "Australia",
    Pcode: "7256",
    Locality: "YARRA CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: "KING ISLAND",
    Category: "Delivery Area",
    Longitude: "144.089500",
    Latitude: "-40.000600"
  },
  {
    Country: "Australia",
    Pcode: "7257",
    Locality: "CAPE BARREN ISLAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.210700",
    Latitude: "-40.391800"
  },
  {
    Country: "Australia",
    Pcode: "7258",
    Locality: "BREADALBANE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.177800",
    Latitude: "-41.517200"
  },
  {
    Country: "Australia",
    Pcode: "7258",
    Locality: "RELBIA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.209600",
    Latitude: "-41.502900"
  },
  {
    Country: "Australia",
    Pcode: "7258",
    Locality: "WHITE HILLS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.305200",
    Latitude: "-41.503100"
  },
  {
    Country: "Australia",
    Pcode: "7259",
    Locality: "MYRTLE BANK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.364200",
    Latitude: "-41.278000"
  },
  {
    Country: "Australia",
    Pcode: "7259",
    Locality: "NUNAMARA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.338200",
    Latitude: "-41.408200"
  },
  {
    Country: "Australia",
    Pcode: "7259",
    Locality: "PATERSONIA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.310900",
    Latitude: "-41.318700"
  },
  {
    Country: "Australia",
    Pcode: "7259",
    Locality: "TARGA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.397300",
    Latitude: "-41.317600"
  },
  {
    Country: "Australia",
    Pcode: "7259",
    Locality: "TAYENE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.525700",
    Latitude: "-41.352100"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "BLUMONT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.413600",
    Latitude: "-41.142500"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "CUCKOO",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.591700",
    Latitude: "-41.226700"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "FORESTER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.681600",
    Latitude: "-41.063300"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "JETSONVILLE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.461900",
    Latitude: "-41.107300"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "KAMONA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.668000",
    Latitude: "-41.134800"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "LIETINNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.449100",
    Latitude: "-41.156200"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "LISLE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.329200",
    Latitude: "-41.237100"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "NABOWLA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.370400",
    Latitude: "-41.164200"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "NORTH SCOTTSDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.569100",
    Latitude: "-41.095000"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "SCOTTSDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.522700",
    Latitude: "-41.169400"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "SOUTH SPRINGFIELD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.520800",
    Latitude: "-41.266400"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "SPRINGFIELD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.469700",
    Latitude: "-41.256500"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "TONGANAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.604800",
    Latitude: "-41.174800"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "TULENDEENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.660500",
    Latitude: "-41.186500"
  },
  {
    Country: "Australia",
    Pcode: "7260",
    Locality: "WEST SCOTTSDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.450900",
    Latitude: "-41.194000"
  },
  {
    Country: "Australia",
    Pcode: "7261",
    Locality: "BRANXHOLM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.766700",
    Latitude: "-41.173300"
  },
  {
    Country: "Australia",
    Pcode: "7261",
    Locality: "WARRENTINNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.734100",
    Latitude: "-41.100500"
  },
  {
    Country: "Australia",
    Pcode: "7262",
    Locality: "BRIDPORT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.412000",
    Latitude: "-41.027600"
  },
  {
    Country: "Australia",
    Pcode: "7262",
    Locality: "TOMAHAWK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.805600",
    Latitude: "-40.908300"
  },
  {
    Country: "Australia",
    Pcode: "7262",
    Locality: "WATERHOUSE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.651000",
    Latitude: "-40.933400"
  },
  {
    Country: "Australia",
    Pcode: "7263",
    Locality: "ALBERTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.799600",
    Latitude: "-41.307400"
  },
  {
    Country: "Australia",
    Pcode: "7263",
    Locality: "LEGERWOOD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.701900",
    Latitude: "-41.213000"
  },
  {
    Country: "Australia",
    Pcode: "7263",
    Locality: "RINGAROOMA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.709400",
    Latitude: "-41.254700"
  },
  {
    Country: "Australia",
    Pcode: "7263",
    Locality: "TALAWA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.708500",
    Latitude: "-41.288200"
  },
  {
    Country: "Australia",
    Pcode: "7263",
    Locality: "TRENAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.674900",
    Latitude: "-41.325100"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "ANSONS BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.199800",
    Latitude: "-41.081100"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "BOOBYALLA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.895700",
    Latitude: "-40.914000"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "CAPE PORTLAND",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.018500",
    Latitude: "-40.776100"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "DERBY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.838000",
    Latitude: "-41.162400"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "EDDYSTONE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.317100",
    Latitude: "-40.996700"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "GLADSTONE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.062500",
    Latitude: "-41.034400"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "HERRICK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.900800",
    Latitude: "-41.084400"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "MOORINA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.887600",
    Latitude: "-41.135300"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "MOUNT WILLIAM",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.233100",
    Latitude: "-40.955100"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "MUSSELROE BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.126200",
    Latitude: "-40.821500"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "PIONEER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.956500",
    Latitude: "-41.105600"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "RUSHY LAGOON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "148.011400",
    Latitude: "-40.854500"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "SOUTH MOUNT CAMERON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.953200",
    Latitude: "-40.997800"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "TELITA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.758300",
    Latitude: "-41.128500"
  },
  {
    Country: "Australia",
    Pcode: "7264",
    Locality: "WELDBOROUGH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.939300",
    Latitude: "-41.203000"
  },
  {
    Country: "Australia",
    Pcode: "7265",
    Locality: "BANCA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.816400",
    Latitude: "-41.004200"
  },
  {
    Country: "Australia",
    Pcode: "7265",
    Locality: "WINNALEAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.816800",
    Latitude: "-41.076200"
  },
  {
    Country: "Australia",
    Pcode: "7267",
    Locality: "BANGOR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.153100",
    Latitude: "-41.215700"
  },
  {
    Country: "Australia",
    Pcode: "7267",
    Locality: "KAROOLA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.132000",
    Latitude: "-41.247100"
  },
  {
    Country: "Australia",
    Pcode: "7267",
    Locality: "LALLA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.181500",
    Latitude: "-41.256500"
  },
  {
    Country: "Australia",
    Pcode: "7267",
    Locality: "LOWER TURNERS MARSH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.101600",
    Latitude: "-41.214300"
  },
  {
    Country: "Australia",
    Pcode: "7267",
    Locality: "TURNERS MARSH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.119500",
    Latitude: "-41.300800"
  },
  {
    Country: "Australia",
    Pcode: "7268",
    Locality: "LILYDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.206500",
    Latitude: "-41.239700"
  },
  {
    Country: "Australia",
    Pcode: "7268",
    Locality: "NORTH LILYDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.240300",
    Latitude: "-41.226900"
  },
  {
    Country: "Australia",
    Pcode: "7268",
    Locality: "UNDERWOOD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "147.219900",
    Latitude: "-41.317000"
  },
  {
    Country: "Australia",
    Pcode: "7270",
    Locality: "BADGER HEAD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "146.685700",
    Latitude: "-41.105900"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "ROCKY CAPE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.475600",
    Latitude: "-40.896500"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "SAVAGE RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.236800",
    Latitude: "-41.483800"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "SISTERS BEACH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.579000",
    Latitude: "-40.927200"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "STOWPORT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.926800",
    Latitude: "-41.109100"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "TEWKESBURY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.715400",
    Latitude: "-41.218300"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "TULLAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.628600",
    Latitude: "-41.737500"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "UPPER NATONE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.881600",
    Latitude: "-41.234000"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "UPPER STOWPORT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.899700",
    Latitude: "-41.161500"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "WARATAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.501100",
    Latitude: "-41.457300"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "WEST MOOREVILLE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.830800",
    Latitude: "-41.095800"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "WEST RIDGLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.774300",
    Latitude: "-41.149400"
  },
  {
    Country: "Australia",
    Pcode: "7321",
    Locality: "WILTSHIRE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.268900",
    Latitude: "-40.820800"
  },
  {
    Country: "Australia",
    Pcode: "7322",
    Locality: "SOMERSET",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.797900",
    Latitude: "-41.058500"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "CALDER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.628800",
    Latitude: "-41.079500"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "DOCTORS ROCKS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.778100",
    Latitude: "-41.020500"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "ELLIOTT",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.779500",
    Latitude: "-41.107300"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "FLOWERDALE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.665900",
    Latitude: "-40.970100"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "HENRIETTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.680300",
    Latitude: "-41.177400"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "LAPOINYA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.562700",
    Latitude: "-41.031600"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "MEUNNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.467100",
    Latitude: "-41.086900"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "MILABENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.501900",
    Latitude: "-41.030000"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "MOORLEAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.605600",
    Latitude: "-41.030800"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "MOUNT HICKS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.727900",
    Latitude: "-41.077400"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "MYALLA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.565300",
    Latitude: "-40.987800"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "OLDINA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.677200",
    Latitude: "-41.058000"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "OONAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.608400",
    Latitude: "-41.230700"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "PREOLENNA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.557700",
    Latitude: "-41.088900"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "SISTERS CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.564800",
    Latitude: "-40.964600"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "TABLE CAPE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.704400",
    Latitude: "-40.954900"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "TAKONE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.618500",
    Latitude: "-41.160300"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "WEST TAKONE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.501200",
    Latitude: "-41.160000"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "WYNYARD",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.714100",
    Latitude: "-41.015700"
  },
  {
    Country: "Australia",
    Pcode: "7325",
    Locality: "YOLLA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.698600",
    Latitude: "-41.131000"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "ALCOMIE",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.215500",
    Latitude: "-40.945000"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "ARTHUR RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.678000",
    Latitude: "-41.056000"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "BRITTONS SWAMP",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.955600",
    Latitude: "-40.937100"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "BROADMEADOWS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.081700",
    Latitude: "-40.863700"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "CHRISTMAS HILLS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.977900",
    Latitude: "-40.999100"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "COUTA ROCKS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.713200",
    Latitude: "-41.178000"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "EDITH CREEK",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.064700",
    Latitude: "-40.957500"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "FOREST",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.238000",
    Latitude: "-40.848300"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "IRISHTOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.154300",
    Latitude: "-40.915900"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "LILEAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.230900",
    Latitude: "-41.006400"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "MARRAWAH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.683300",
    Latitude: "-40.926800"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "MELLA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.039600",
    Latitude: "-40.850400"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "MENGHA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.274800",
    Latitude: "-40.937000"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "MONTAGU",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.966400",
    Latitude: "-40.809200"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "NABAGEENA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.128700",
    Latitude: "-40.998100"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "NELSON BAY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.706700",
    Latitude: "-41.153500"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "REDPA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.781900",
    Latitude: "-40.978000"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "ROGER RIVER",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.014000",
    Latitude: "-41.065400"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "SCOPUS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.023000",
    Latitude: "-40.810300"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "SCOTCHTOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.100200",
    Latitude: "-40.902800"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "SMITHTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.136300",
    Latitude: "-40.845300"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "SOUTH FOREST",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.276000",
    Latitude: "-40.894700"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "TEMMA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.739600",
    Latitude: "-41.236700"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "TOGARI",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.847400",
    Latitude: "-41.044200"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "TROWUTTA",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.115100",
    Latitude: "-41.062300"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "WEST MONTAGU",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.858300",
    Latitude: "-40.829300"
  },
  {
    Country: "Australia",
    Pcode: "7330",
    Locality: "WOOLNORTH",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "144.776500",
    Latitude: "-40.755900"
  },
  {
    Country: "Australia",
    Pcode: "7331",
    Locality: "STANLEY",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.274600",
    Latitude: "-40.761000"
  },
  {
    Country: "Australia",
    Pcode: "7466",
    Locality: "GORMANSTON",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.619100",
    Latitude: "-42.074400"
  },
  {
    Country: "Australia",
    Pcode: "7467",
    Locality: "LAKE MARGARET",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.558400",
    Latitude: "-41.999100"
  },
  {
    Country: "Australia",
    Pcode: "7467",
    Locality: "QUEENSTOWN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.540500",
    Latitude: "-42.085500"
  },
  {
    Country: "Australia",
    Pcode: "7468",
    Locality: "MACQUARIE HEADS",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.195200",
    Latitude: "-42.215000"
  },
  {
    Country: "Australia",
    Pcode: "7468",
    Locality: "STRAHAN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.340400",
    Latitude: "-42.148200"
  },
  {
    Country: "Australia",
    Pcode: "7469",
    Locality: "GRANVILLE HARBOUR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.069900",
    Latitude: "-41.807100"
  },
  {
    Country: "Australia",
    Pcode: "7469",
    Locality: "RENISON BELL",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.437100",
    Latitude: "-41.790400"
  },
  {
    Country: "Australia",
    Pcode: "7469",
    Locality: "TRIAL HARBOUR",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.185700",
    Latitude: "-41.916900"
  },
  {
    Country: "Australia",
    Pcode: "7469",
    Locality: "ZEEHAN",
    State: "Tasmania",
    StateAbbrev: "TAS",
    Comments: null,
    Category: "Delivery Area",
    Longitude: "145.362300",
    Latitude: "-41.889200"
  }
]
