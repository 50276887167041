import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import Button from './Button.js';
import { isMobile, getDOMElement, isElementInViewport } from "../helpers.js";
import { validateInput, validateDropdown } from '../utils/input-validation.js';
import getParamsAsStr from './HealthCheck.js'
import { useDebounce } from 'use-debounce';
import Cookies from 'js-cookie'; //Import the JS-Cookie library in your React component.
import axios from 'axios';

function AutocompleteInput(props) {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState('');
    const [alertCustomError, setAlertCustomError] = useState('')
    const [inputFocused, setInputFocused] = useState(false);
    const [hasScrolledToView, setHasScrolledToView] = useState(false);
    const [baseAPIURL, setBaseAPIURL] = useState('')
    const [postCodeList, setPostCodeList] = useState('')
    let [validationCheck, setOptions] = useState('')
    const [text, setText] = useState('Hello');
    const debouncedValue = useDebounce(text, 1000);
    const [result, setResult] = useState(null);
    const [validSelection, setValidSelection] = useState(false);
    const [requestLoading, setRequestLoading] = useState(false);
    const inputRef = useRef()
    const [environment, setEnvironment] = useState((props.environment != '' && typeof props.environment != 'undefined') ? props.environment : 'prod')
    
    let MH4L_token = Cookies.get('MH4L_token'); //retrieve the MH4L_token cookie value

    var suggestions = props.options;
    var options = props.options
    var postCodeSuburbs = [];
    var originalOptions = props.options;
   
    useEffect(
        () => {
            if (input.length < 3) { //to check the value is greater then 2
                setResult(null);
                return;
            }
            if (!isNaN(+input) && input != ''){ // to check if the given input is number/not empty 
                
                const timeout = setTimeout(() => {
                    let queryString = `filter[filters][0][field]=Pcode&filter[filters][0][operator]=contains&filter[filters][0][value]=${""}`;
                    const url = props.baseAPIURL + 'common/getGeocodedPostcode2?' + queryString + input
                    const fetchData = async () => {  // data will be fetched after user input something
                    
                    try {
                        setRequestLoading(true)
                        console.log(environment + ' ' + "working"+ ' ' + MH4L_token);
                        //const response = await fetch(url); // response with Basic auth
                        
                        /* Start - Generate token for the anonymous users */
                        // if(typeof MH4L_token == 'undefined')
                        // {
                            //let token_url = props.baseAPIURL + 'auth/loginExt';
                            let token_url = process.env.REACT_APP_TOKENURL;
                            

                            const tokenheader = {
                                headers: {
                                    "Content-Type": "application/json",
                                    "Accept": "application/json",
                                    "Client-Service": "simbyte-mh4l-api-v1",
                                },
                                };
                            const responseData = await axios.get(token_url, 
                            {
                                // "username": process.env.REACT_APP_UNAME,
                                // "password": process.env.REACT_APP_PASS
                            }, tokenheader);
                            
                            //MH4L_token = responseData.data.token;
                            // console.log('APIURL'+ ' -> '+ props.baseAPIURL);
                            // console.log(token_url);    
                            // console.log('Window Location'+ ' -> '+ window.location.origin);

                            MH4L_token = responseData.data;
                        // }
                        /* End - Generate token for the anonymous users */

                        
                        /* Start - response with BT auth (Passing parameters with Bearer token) */
                        const response = await fetch(url, {  
                            method: 'GET',
                            headers: new Headers({
                                "Authorization": 'Bearer '+ MH4L_token,
                                "Client-Service": 'simbyte-mh4l-api-v1',
                            })    
                        });
                        /* End - response with BT auth (Passing parameters with Bearer token) */

                        const json = await response.json();
                        setRequestLoading(false)
                        suggestions = json;
                        options = json;
                        setOptions(json);
                        originalOptions = json;
                        if (props.slug === "postcode") {
                            setFilteredSuggestions(options.map(a => ({locality : a.Locality, pcode : a.Pcode})));
                            suggestions = options.map(a => a.code);
                            postCodeSuburbs = options.map(a => a.Locality);
                        }





                        // /* Start - logout endpoint call to remove BT generated from above login - $MH4L_cookie */
                        // let logout_url = props.baseAPIURL + 'auth/logout';
                        // const logout_tokenheader = {
                        //     headers: {
                        //         "Content-Type": "application/json",
                        //         "Accept": "application/json",
                        //         "Client-Service": "simbyte-mh4l-api-v1",
                        //         "Authorization": 'Bearer '+ MH4L_token, //BT sent to header
                        //     },
                        //     };
                                
                        //     const responseBTData = await axios.post(logout_url, 
                        //         {
                        //             "username": process.env.REACT_APP_UNAME,
                        //             "password": process.env.REACT_APP_PASS
                        //         }, logout_tokenheader);
                                
                        // MH4L_token = responseBTData.data.token;
                        // Cookies.remove('MH4L_token');
                        // sessionStorage.removeItem('MH4L_token');
                        // localStorage.removeItem("MH4L_token")
                        //return false;

                        
                        /* End - logout endpoint call to remove BT generated from above login - $MH4L_cookie */







                    }   catch (error) {
                            console.warn("error", error);
                        }
                };
                  fetchData();
                }, 500); // you can edit the time for wait after user input
                return () => clearTimeout(timeout);
            } else {
                setValidSelection(false)
            }
        
        },
        [input]
      );    

    useEffect(() => {
        if (props.fieldOnly) {
            returnValueViaCallback()
        }
    }, [])

    function buttonClick() {
        returnValueViaCallback()
    }

    function returnValueViaCallback() { //on submit this function works
        console.log('returnValueViaCallback() called')
        
        if (input != null && props.slug != null) {
            let formattedOptions = []
            let dropdownValidationCheck = {}; 
            if (props.slug === "postcode") {
                for (let i = 0; i < validationCheck.length; i++) {
                    formattedOptions.push(validationCheck[i].Locality + ', ' + validationCheck[i].Pcode) // {suggestion.suburb}, {suggestion.code}
                }
            } else {
                formattedOptions = props.options
            }
            if (props.slug == "postcode" && props.fieldOnly){
                dropdownValidationCheck.success = true
            }
            else{
                dropdownValidationCheck = validateDropdown(input, formattedOptions)
            }
            if (dropdownValidationCheck.success) {
                let validationCheck = validateInput(input, props.validation, props.dataType)
                if (validationCheck.valid) {
                    let returnValue = input;
                    if (props.slug !== "postcode") {
                        returnValue = suggestions[dropdownValidationCheck.validIndex]
                    }
                    props.callback(props.slug, returnValue);
                    setAlertCustomError('')
                } else {
                    setAlertCustomError(validationCheck.message)
                    console.warn(validationCheck.report)
                }
            } else {
                setAlertCustomError("Please select a valid option from dropdown")
                console.warn("Submitted value doesn't match possible dropdown options.")
            }
            
            if (props.validationTriggeredCb != null && typeof props.validationTriggeredCb === 'function') {
                console.log('validationTriggeredCb()', props.slug)
                props.validationTriggeredCb(props.slug);
            }
        }
    }
    
    const onChange = (e) => {
        const userInput = e.target.value;
        setInput(e.target.input)
        let unLinked = [];

        if (props.slug === "postcode"){
            unLinked = (originalOptions.filter(a => {return a.Pcode.includes(userInput)}))
           
        } else {
            if (userInput && typeof userInput === "string" && suggestions && suggestions.length > 0) {
                unLinked = suggestions.filter(
                    suggestion => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
                );
                // do something with unLinked
              } else {
                // handle the case where userInput or suggestions is undefined or empty
                console.log('else is working here');
              }
        }

        setInput(e.target.value);
        setFilteredSuggestions(unLinked);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
        setAlertCustomError('')
    };

    const onClick = (e) => {
        setValidSelection(true)
        setFilteredSuggestions([]);
        // console.log(e.target.innerText)
        setAlertCustomError('')
        setInput(e.target.innerText);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);

        if (props.validationTriggeredCb != null && typeof props.validationTriggeredCb === 'function') {
            console.log('validationTriggeredCb()', props.slug)
            props.validationTriggeredCb(props.slug, e.target.innerText);
        }
    };

    function clearSuggestion() {
        setAlertCustomError('')
        setFilteredSuggestions([]);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);       

        if (props.validationNullTriggeredCb != null && typeof props.validationNullTriggeredCb === 'function') {
            // console.log('validationNullTriggeredCb()', props.slug)
            props.validationNullTriggeredCb(props.slug, '');
        } 
    }

    const onKeyDown = (e) => {
        if (props.validationNullTriggeredCb != null && typeof props.validationNullTriggeredCb === 'function') {
            // console.log('validationNullTriggeredCb()', props.slug)
            console.log("inputRef.current.value")
            console.log(inputRef.current.value)
            props.validationNullTriggeredCb(props.slug, '');
        } 

        const desktopSidebar = getDOMElement("partial-desktop-sidebar", "className")[0];
        const isMobile = isElementInViewport(desktopSidebar);

        if (isMobile && !hasScrolledToView) {
            const chatElement = getDOMElement("chat-box", "id");
            
            chatElement.scroll({
                top: chatElement.scrollHeight + 1000, 
                left: 0, 
                behavior: 'smooth'
            });        
            
            setHasScrolledToView(true);
        }
        
        switch (e.key) {
            case 'Enter': //Enter
                if (props.slug === "postcode") {
                    setInput(filteredSuggestions[activeSuggestionIndex].locality + ', ' + filteredSuggestions[activeSuggestionIndex].pcode);
                } else {
                    setInput(filteredSuggestions[activeSuggestionIndex]);
                }

                clearSuggestion();
                setInputFocused(false);
                e.target.blur();
                break;
            case 'ArrowUp': //Arrow Up
                if (activeSuggestionIndex > 0) {
                    setActiveSuggestionIndex(activeSuggestionIndex - 1);
                }

                break;
            case 'ArrowDown': //Arrow down
                if (activeSuggestionIndex < filteredSuggestions.length - 1) {
                    setActiveSuggestionIndex(activeSuggestionIndex + 1);
                }
                break;
        }
    }

    const onBlur = (e) => {
        setInputFocused(false);
    }

    const onFocus = (e) => {
        if (props.slug === "postcode") {          
        } else {
            setFilteredSuggestions(props.options);
        }
        setFilteredSuggestions(props.options);
        setInput("");
        setActiveSuggestionIndex(0);
        setInputFocused(true);
        setShowSuggestions(true);
    }

    useEffect(() => {
        if (activeSuggestionIndex > 3) {
            let activeElTop = getDOMElement('suggestion-active', 'className')[0].offsetTop;
            getDOMElement('suggestions', 'className')[0].scrollTop = activeElTop - 100;
        }
    });

    const SuggestionsListComponent = () => {
        return filteredSuggestions.length ? (
            <ul className="suggestions form-control to-input">
                {props.slug === "postcode" && filteredSuggestions.map((suggestion, index) => {
                    //console.log(suggestion);
                    let className = "";
                    console.log('suggestion work')
                    console.log(suggestion)
                    if (index === activeSuggestionIndex) {
                        className = "suggestion-active";
                    }

                    return (
                        <li className={className} key={`${index}`} onMouseDown={onClick}>
                            {suggestion.locality}, {suggestion.pcode}
                        </li>
                    );
                })}

                {props.slug != "postcode" && filteredSuggestions.map((suggestion, index) => {
                    let className = "";

                    if (index === activeSuggestionIndex) {
                        className = "suggestion-active";
                    }

                    return (
                        <li className={className} key={`${index}`} onMouseDown={onClick}>
                            {suggestion}
                        </li>
                    );
                })}
            </ul>
        ) : (
            <div className="no-suggestions">
            </div>
        );
    };

    return (
        <div className={`postcode component-autocomplete-input component-form-UI ${inputFocused ? 'active' : ''} ${typeof props.fieldOnly != 'undefined' ? 'nested' : ''}`}>
            <input
                className="form-control to-input transparent-background"
                type={props.type}
                placeholder={props.placeholder}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                onFocus={onFocus}
                value={input}
                ref={inputRef}
            />
            {requestLoading &&
                 <p className="text text-loading"><span className="loading"></span></p>
            }
            {alertCustomError.length > 0 &&
                <p className="text text-error">{alertCustomError}</p>
            }
            {inputFocused && showSuggestions && <SuggestionsListComponent />}
            {(typeof props.fieldOnly == 'undefined') &&
                <Button
                    classList={"primary regular large last-button dynamic-input-button"}
                    innerText={props.buttonText}
                    onclick={buttonClick}
                />
            }
        </div>
    );
}

export default AutocompleteInput;
