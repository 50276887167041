import Button from './Button.js';
import DynamicYesNo from './DynamicYesNo.js';
import React, { useState } from "react";
import { validateInput } from '../utils/input-validation.js';

function BloodPressure(props) {
    const [inputValueSys, setInputValueSys] = useState('')
    const [inputValueDia, setInputValueDia] = useState('')
    const [alertCustomError, setAlertCustomError] = useState('')

    function handleKeyPressSys(event) {
        setInputValueSys(event.target.value)
    }

    function handleKeyPressDia(event) {
        setInputValueDia(event.target.value)
    }

    function handleButtonReponse(slug, answer, boo = false) {
        if (answer === props.buttons.response1) {
            let callbackValue = inputValueSys + ',' + inputValueDia;
            let validationCheckSys = validateInput(inputValueSys, props.validation, props.dataType, 0);
            let validationCheckDia = validateInput(inputValueDia, props.validation, props.dataType, 1);

            errorCheckAndCallResponse(callbackValue, validationCheckSys, validationCheckDia);
        } else if (answer === props.buttons.response2) {
            let callbackValue = '';
            let validationCheckSys = {};
            let validationCheckDia = {};
           
            if( inputValueSys !== null && inputValueSys !== '' && inputValueSys !== undefined){
                validationCheckSys = validateInput(inputValueSys, props.validation, props.dataType, 0);
                if( inputValueDia !== null && inputValueDia !== '' && inputValueDia !== undefined){
                    validationCheckDia = validateInput(inputValueDia, props.validation, props.dataType, 1);
                    callbackValue = `${inputValueSys},${inputValueDia}`;
                }
                else{
                    validationCheckDia.valid = true;
                    callbackValue = `${inputValueSys},NULL`;
                }
            }else if( inputValueDia !== null && inputValueDia !== '' && inputValueDia !== undefined){
                validationCheckSys.valid = true;
                validationCheckDia = validateInput(inputValueDia, props.validation, props.dataType, 1);
                callbackValue = `NULL,${inputValueDia}`;
            }else{
                validationCheckSys.valid = true;
                validationCheckDia.valid = true;
                callbackValue = 'NULL';
            }

            errorCheckAndCallResponse(callbackValue, validationCheckSys, validationCheckDia);
        }
    }

    function errorCheckAndCallResponse(callbackValue, validationCheckSys, validationCheckDia){
        
        if (validationCheckSys.valid && validationCheckDia.valid) {
            props.callback(props.slug, callbackValue)
            setAlertCustomError('')
        } else {
            let errMessages = [];

            if (!validationCheckSys.valid) {
                errMessages.push(validationCheckSys.message);
                console.warn(validationCheckSys.report)
            } 
            
            if (!validationCheckDia.valid){
                errMessages.push(validationCheckDia.message);
                console.warn(validationCheckDia.report)
            }

            setAlertCustomError(errMessages.join('. '));
        }   
    }

    return (
        <div>
            <div className={`component-blood-pressure`}>
                <input type="number"
                    className="form-control transparent-background"
                    placeholder="Systolic"
                    onChange={handleKeyPressSys.bind(this)}
                />
                <span className="seperator">/</span>
                <input type="number"
                    className="form-control transparent-background"
                    placeholder="Diastolic"
                    onChange={handleKeyPressDia.bind(this)}
                />
                
            </div>
            {alertCustomError.length > 0 &&
                <p className="text text-error">{alertCustomError}</p>
            }

            <DynamicYesNo
                innerText=""
                options={[props.buttons.response1, props.buttons.response2]}
                collapseButtons={true}
                callback={handleButtonReponse}
                slug=""
            />
        </div>
    );
}

export default BloodPressure;