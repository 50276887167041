const WelcomeContent = () => (
    <div className="">
      <h2 className="text-white">
        Welcome to <em>My health for life</em>
      </h2>
      <h3 className="text-white">A free, healthy lifestyle program.</h3>
      <p className="text large text-white">
        <em>My health for life</em> is a free, healthy lifestyle program helping
        Queenslanders to live well.
      </p>
      <p className="text large text-white">
        You can take the first steps to a healthier you with our online health
        check. It only takes 5-10 minutes to complete. You’ll find out how your
        health is tracking and can decide if the program is right for you.
      </p>
    </div>
);

export default WelcomeContent;