import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';

function Slider(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const max = props.data.length;

    const updateActiveIndex = (index) => {
        if (index < 0) {
            index = 0;
        } else if (index >= max) {
            index = max - 1;
        }

        setActiveIndex(index);
    }

    const handlers = useSwipeable({
        onSwipedLeft: () => updateActiveIndex(activeIndex + 1),
        onSwipedRight: () => updateActiveIndex(activeIndex -1)
    });

    return (
        <div className="component-slider" {...handlers}>
            <ul className="slider-indicators mx-auto">
                {max > 0 && props.data.map((item, index) => 
                    <li key={index} className={`${index === activeIndex ? 'active' : ''}`} onClick={() => updateActiveIndex(index)}></li>
                )}
            </ul>
            <div className='slider-inner' style={{ transform: `translateX(-${activeIndex * 100}%)`}}>
                {max > 0 && props.data.map((item, index) => 
                    <span className={`${index === activeIndex ? 'active' : ''} slider-item`} key={'slider-item-' + index}>
                        {sliderItemFactory(item)}
                    </span>
                )}
            </div>
        </div>
    );
}

function sliderItemFactory(item) {
    switch (item.type) {
        case 'image':
            return <img src={item.url}></img>;
        case 'text':
            return <span dangerouslySetInnerHTML={{__html: item.text}}></span>
        default:
            return null;
    }

}

export default Slider;