import { Accordion } from 'react-bootstrap';

function Accordian(props) {
    return (
        <Accordion flush className='component-accordian' defaultActiveKey='0'>
            <Accordion.Item eventKey = {props.renderExpanded == true ? '0' : '1'}>
                <Accordion.Header className={`${props.bgClass}`}>{props.heading}</Accordion.Header>
                <Accordion.Body className={`${props.bgClass}`} >
                    {props.innerText && props.innerText}
                    {props.children && props.children}
                    
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default Accordian;