export const addClassToClassList = (stateVar, className) => {
  let newClassListStr = stateVar;

  if (!stateVar.includes(className)) {
    newClassListStr += " " + className;
  }

  return newClassListStr;
};

export function removeClassToClassList(stateVar, className) {
  let newClassListStr = stateVar;

  if (stateVar.includes(className)) {
    newClassListStr = newClassListStr.replace(className, "");
  }

  return newClassListStr;
}
