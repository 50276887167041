import { useState } from "react";

function Footer(props) {
    const [defaultBaseURL, setDefaultBaseURL] = useState('https://hc.myhealthforlife.com.au/');
    const baseURLMap = {
        local: 'http://localhost:3000/',
        dev: 'https://dev-hc.myhealthforlife.org.au/',
        uat: 'https://uat-hc.myhealthforlife.org.au/',
        stg: 'https://stg-hc.myhealthforlife.org.au/',
        prod: 'https://hc.myhealthforlife.com.au/'
    }
    
    return (
        <div className={`component-results-footer ${props.className}`}>
            <div className="qld-logos">
                <img src={`${baseURLMap[props.environment] ? baseURLMap[props.environment] : defaultBaseURL}assets/logos/Health-Wellbeing-Qld.png`}></img>
                <img src={`${baseURLMap[props.environment] ? baseURLMap[props.environment] : defaultBaseURL}assets/logos/Qld-Goverment.png`}></img>
            </div>
            <h4><i>Working in partnership with</i></h4>
            <div className="alliance-text"><b>HEALTHIER QUEENSLAND&nbsp;ALLIANCE</b></div>
            <div className="partner-logo">
                <div className="column-wrapper">
                    <a><img src={`${baseURLMap[props.environment] ? baseURLMap[props.environment] : defaultBaseURL}assets/logos/diabetes_qld.png`}></img></a>
                    <a><img src={`${baseURLMap[props.environment] ? baseURLMap[props.environment] : defaultBaseURL}assets/logos/stroke_foundation.png`}></img></a>
                    <a><img src={`${baseURLMap[props.environment] ? baseURLMap[props.environment] : defaultBaseURL}assets/logos/heart_foundation_small.png`}></img></a>                
                </div>
                <div className="column-wrapper">
                    <a><img src={`${baseURLMap[props.environment] ? baseURLMap[props.environment] : defaultBaseURL}assets/logos/QAIHC.png`}></img></a>
                    <a><img src={`${baseURLMap[props.environment] ? baseURLMap[props.environment] : defaultBaseURL}assets/logos/Ethnic_Communities_CoQ.png`}></img></a>
                    <a><img src={`${baseURLMap[props.environment] ? baseURLMap[props.environment] : defaultBaseURL}assets/logos/PHNs.png`}></img></a>                    
                </div>
            </div>
            <div className="footer-nav">
                <ul>
                    <li><a>About us</a></li>
                    <li><a>Contact</a></li>
                    <li><a>Privacy</a></li>
                    <li><a>Terms & Conditions</a></li>
                    <li><a>Sitemap</a></li>
                    <li>Call us <a>13 74 75</a></li>
                </ul>
            </div>
            <div className="acknowledgement">We acknowledge the Australian Aboriginal and Torres Strait Islander peoples as the first inhabitants of the nation and the traditional custodians of the lands where we live, learn and work. </div>
        </div>
    )
}


export default Footer;