import './App.css';
import HealthCheck from './components/HealthCheck.js';
import React, { useState, useEffect } from "react";
import {getDOMElement} from './helpers'

import CacheBuster from 'react-cache-buster';
//import { version } from '../package.json';
//import Loading from './loading';

function App() {
  // Determine if start button is visible on screen. If not then make container scrollable
  // Will remove scrollable class once start button is clicked.

  const isProduction = process.env.NODE_ENV === 'production';
  const appNewVersion = require('../package.json').version;

  useEffect(() => {
    const el = Array.from(getDOMElement('start-button', 'className')).find(el => el.getBoundingClientRect().height > 0);
    console.log(process.env);
    console.log(isProduction);
    //const elPosition = el.getBoundingClientRect();
    //const windowHeight = window.innerHeight;

    //if (elPosition.bottom > windowHeight) {
      //const introContainer = getDOMElement('container-primary', 'className')[0];
      //introContainer.classList.add('scrollable')
    //}
  });

  return (
    <div className="App typography">
      <CacheBuster
      currentVersion={appNewVersion}
      isEnabled = {isProduction} //If false, the library is disabled.
      //isVerboseMode={false} //If true, the library writes verbose logs to console.
      //loadingComponent={<Loading />} //If not pass, nothing appears at the time of new version check.
      //metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <HealthCheck user="staff" />
      </CacheBuster>
    </div>
  );
}

export default App;
