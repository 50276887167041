import Button from './Button.js'
import YesNo from './YesNo.js'
import AutocompleteInput from './AutocompleteInput.js'
import React, { useState, useRef, createRef, useEffect } from "react"
import { validateInput } from '../utils/input-validation.js';

function MultiQuestion(props) {
    const [inputValues, setInputValues] = useState('')
    const [yesNoValues, setYesNoValues] = useState('')
    const [selectOptions, setSelectOptions] = useState([])
    const [alertCustomError, setAlertCustomError] = useState('')
    const [isAlertCustomError, setIsAlertCustomError] = useState(false)
    const [validatedDropdowns, setValidatedDropdown] = useState([])
    const alertRefs = React.useRef([]);

    alertRefs.current = props.childQuestions.map((question, index) => alertRefs[index] ?? createRef())

    // Check for default values incase user has come through moodle
    useEffect(() => {
        let obj = {};

        questions.map((question, index) => {
            if (question.defaultValue != null && typeof question.defaultValue != 'undefined') {
                obj[question.questionName] = question.defaultValue;
            }
        });

        let inputJSON = JSON.stringify(obj);
        setInputValues(inputJSON);
    }, []);

    function writeInputs(name, value) {
        // Check if str is empty and reset
        let fauxInputValues = inputValues
        if (inputValues == '' || typeof inputValues == 'undefined' || inputValues == null) {
            let obj = {}
            fauxInputValues = JSON.stringify(obj)
        }

        // Parse JSON for use
        let inputValuesObject = JSON.parse(fauxInputValues)
        inputValuesObject[name] = value // leverage question name

        let inputValuesJSON = JSON.stringify(inputValuesObject) // Write as JSON string

        setInputValues(inputValuesJSON) // Save new JSON object
    }

    function handleInputChange(event) {
        writeInputs(event.target.name, event.target.value)

        // Loop through childQuestions and get index for this event.target.name
        for (let i = 0; i < props.childQuestions.length; i++) {
            // Hide the errormessage
            if (typeof alertRefs != 'undefined') {
                if (typeof alertRefs.current[i] != 'undefined') {
                    if (props.childQuestions[i].questionName == event.target.name && alertRefs != null && alertRefs.current[i] != null && alertRefs.current[i].current != null) {
                        alertRefs.current[i].current.innerHTML = ''
                        alertRefs.current[i].current.classList = 'text text-error hide'
                    }
                }
            }
        }
        
    }

    function handleYesNoButtonReponse(slug, answer, boo = false) {
        if (slug != null && answer != null) {
            writeInputs(slug, answer)

             // Loop through childQuestions and get index for this event.target.name
            for (let i = 0; i < props.childQuestions.length; i++) {
                // Hide the errormessage
                // alertRefs.current[i].current.innerHTML = ''
                // alertRefs.current[i].current.classList = 'text text-error hide'
                if (typeof alertRefs != 'undefined') {
                    if (typeof alertRefs.current[i] != 'undefined') {
                        if (props.childQuestions[i].questionName == slug && alertRefs != null && alertRefs.current[i] != null && alertRefs.current[i].current != null) {
                            alertRefs.current[i].current.innerHTML = ''
                            alertRefs.current[i].current.classList = 'text text-error hide'
                        }
                    }
                }
            }
        }
    }

    function handleSelectButtonResponse(slug, answer, boo = false) {
        if (slug != null && answer != null) {
            writeInputs(slug, answer)

            // Loop through childQuestions and get index for this event.target.name
            for (let i = 0; i < props.childQuestions.length; i++) {
                // Hide the errormessage
                if (typeof alertRefs != 'undefined') {
                    if (typeof alertRefs.current[i] != 'undefined') {
                        if (props.childQuestions[i].questionName == slug && alertRefs != null && alertRefs.current[i] != null && alertRefs.current[i].current != null) {
                            alertRefs.current[i].current.innerHTML = ''
                            alertRefs.current[i].current.classList = 'text text-error hide'
                        }
                    }
                }
            }
        }
    }

    function handleDropdownValidationCallback(name, value) {
        if (!validatedDropdowns.includes(name)) {
            validatedDropdowns.push(name)
            let newArr = validatedDropdowns;
            setValidatedDropdown(newArr);
            writeInputs(name, value);
            console.log(name, value)
        }
    }

    function handleDropdownValidationCallbackNull(name, value) {
        let newArr = [];

        for (let i = 0; i < validatedDropdowns.length; i++) {
            if (validatedDropdowns[i] != name) {
                newArr.push(validatedDropdowns[i])
                writeInputs(name, value)
            }
        }

        setValidatedDropdown(newArr);
    }

    function handleSubmitButtonResponse(slug, answer) {
        // Locally scoped var for execution stack convenience
        let isValidationError = false

        // Parse stored answer JSON
        let fauxInputValues = inputValues
        console.log("inputValues == ''")
        console.log(inputValues == '')
        if (inputValues == '' || typeof inputValues == 'undefined' || inputValues == null) {
            let obj = {}
            fauxInputValues = JSON.stringify(obj)
        }
        let inputValuesObject = JSON.parse(fauxInputValues)

        // Loop through child question set
        for (let i = 0; i < props.childQuestions.length; i++) {
            // Clear previous question error messages

             // Find answer for that child question in the answer set - can target with slug - else use ''
            let storedValue = ''
            if (typeof inputValuesObject[props.childQuestions[i].questionName] != 'undefined') { // Check if answer is set 
                storedValue = inputValuesObject[props.childQuestions[i].questionName]
            }

            // Check the validation rules for that child question + Check to see if the validation passes
            let validationCheck = validateInput(storedValue, props.childQuestions[i].validation, props.childQuestions[i].dataType)

            if (validationCheck.valid) {
                // Validation has passed - clear message for this field
                alertRefs.current[i].current.innerHTML = ''
                alertRefs.current[i].current.classList = 'text text-error hide'
            } else if (props.childQuestions[i].questionType !== "Single-Select-Dropdown") {
                // If validation doesn't pass, show an error for that error message
                
                isValidationError = true
                console.log(validationCheck)
                alertRefs.current[i].current.innerHTML = validationCheck.message
                alertRefs.current[i].current.classList = 'text text-error'
                console.warn(validationCheck.report)
            } 
            
            /*
                - For dropdowns there is a special check for "data integrity"
                - This is returned from the child question and stored globally
                - Review this for each dropdown question and see if integrity
                is currently valid
            */
            if (props.childQuestions[i].questionType === "Single-Select-Dropdown") {
                if (validatedDropdowns.includes(props.childQuestions[i].questionName)) {
                    alertRefs.current[i].current.innerHTML = ""
                    alertRefs.current[i].current.classList = ''
                } else {
                    isValidationError = true
                    alertRefs.current[i].current.innerHTML = "Please select a valid option from dropdown"
                    alertRefs.current[i].current.classList = 'text text-error'
                }
            }
        }
        
            // This will require some magic ref targeting
        if (!isValidationError) {
            props.callback(props.slug, inputValues);
        } else {
            setIsAlertCustomError(true)
        }
    }

    function replaceAllTags(str) {
        str = str.replaceAll('<HEADING>','')
        str = str.replaceAll('</HEADING>','')
        str = str.replaceAll('<TEXT>','')
        str = str.replaceAll('</TEXT>','')

        return str
    }

    let questions = []
    if (Array.isArray(props.childQuestions)) {
        questions = props.childQuestions
    }

    return (
        <div className={`component-multiquestion`}>
            {questions.map((question, index) => {
                {if (question.questionType == "Short Answer")
                    return <div className={`partial-input`} key={index}>
                        
                        <p><strong dangerouslySetInnerHTML={{__html: replaceAllTags(question.questionText)}}></strong></p>
                        <input type="text"
                            className="form-control transparent-background"
                            placeholder={question.explanationText}
                            name={question.questionName}
                            onChange={handleInputChange.bind(this)}
                            value={question.defaultValue}
                        />
                        <p className="text text-error hide" ref={alertRefs.current[index]}></p>
                    </div>
                }

                {if (question.questionType == "Yes/No") 
                    return <div className={`partial-input`} key={index}>
                        <p><strong dangerouslySetInnerHTML={{__html: replaceAllTags(question.questionText)}}></strong></p>
                        <YesNo
                            innerText=""
                            options={[props.buttons.response1, props.buttons.response2]}
                            collapseButtons={true}
                            callback={handleYesNoButtonReponse.bind(this)}
                            slug={question.questionName}
                        />
                        <p className="text text-error hide" ref={alertRefs.current[index]}></p>
                    </div>
                }

                {if (question.questionType == "Single-Select-Dropdown") 
                    return <div className={`partial-input`} key={index}>
                        <p><strong dangerouslySetInnerHTML={{__html: replaceAllTags(question.questionText)}}></strong></p>
                        <AutocompleteInput
                            placeholder={question.explanationText}
                            type="text"
                            id="colFormLabel"
                            options={props.options[index]}
                            callback={handleSelectButtonResponse.bind(this)} 
                            slug={question.questionName}
                            fieldOnly={true}
                            defaultVal={question.defaultValue}
                            validationTriggeredCb={handleDropdownValidationCallback}
                            validationNullTriggeredCb={handleDropdownValidationCallbackNull}
                            baseAPIURL={props.baseAPIURL}
                        />
                        <p className="text text-error hide" ref={alertRefs.current[index]}></p>
                    </div>
                }
            })}
                    
            <Button
                classList={"primary regular large last-button dynamic-input-button"}
                innerText={props.buttonText}
                onclick={handleSubmitButtonResponse.bind(this)}
            />
        </div>
    );
}

export default MultiQuestion;