function Button(props) {
    function addDefaultSrc(e) {
        e.target.onerror = null;
        e.target.src = '/assets/icon-clock.svg';
    }

    return (
        <div className={`component-button ${props.classList}${props.innerText != null && props.classList.indexOf("split") == -1 && props.innerText.length >= 28 ? ' full-width' : ''}`} 
            onClick={() => props.onclick(props.innerText)}>
            {
                props.classList.indexOf('split') > -1 ?
                    <div className={`image-wrapper ${typeof(props.splitImage) == 'undefined' ? 'no-img' : ''}`}>
                        <div className={`background`}></div>
                        <div className='image'><img src={props.splitImage} onError={addDefaultSrc}/></div>
                    </div> : ""
            }
            <div className="text-block text mini inner-text">{props.children ? props.children : props.innerText}</div>
        </div>
    );
}

export default Button;