/*
 *	Validate if a valueStr is valid as per the rules in validationStr
 *	
 *	@param {number} nestedValidationConditionalIndex When validating string contained in outer ( ) delimiter, index
 *													 of validation conditionals for input  
 *
 *  Returns: Boolean
 *    true - valueStr is valid
 *    false - valueStr is invalid
 */
export function validateInput(valueStr, validationStr, dataType, nestedValidationConditionalIndex) {
	/*
		EXAMPLE VALIDATION STRINGS
		{0} == NULL|Block|"You must enter a value"
		{0} == NULL|Block|"You must enter a value"
		{0} == NULL|Block|"You must enter a value to continue"
		{0} == NULL|Block|"You must enter your age"
		{0} == NULL|Block|"You must enter a value to continue"
		{0} == NULL|Block|"You must enter a value to continue"
		{0} == NULL|Block|"You must enter a Language to continue"
		{0} == NULL|Block|"You must enter a Value to continue"
	*/

	// Trim input
	let inputValueStr = valueStr
	inputValueStr = inputValueStr.trim()
	let errormsg = "";
	// First, check the type of data submitted is valid
	let validDataType = true // default is true for when this isn't set
	if (typeof dataType == 'string' && dataType.length > 0) {
		validDataType = false // dataType has been provided, assume it's not set
		switch (dataType.toLowerCase()) {
			case 'numeric':
				if (!isNaN(inputValueStr) && inputValueStr != '' && inputValueStr == Math.floor(inputValueStr)) {
					validDataType = true
				}
				else {
					errormsg = "a whole number"
				}
				break;
			case 'text':
				if (isNaN(inputValueStr) && inputValueStr != '') {
					console.log('working');
					console.log(inputValueStr);
					validDataType = true
				}
				else{
					errormsg = "text"
				}
				break;
			case 'email':
				if (/^[^\s][^\s]*([\.-]?[^\s]+)*@[^\s]+([\.-]?[^\s]+)*(\.[^\s]{2,3})+$/.test(valueStr) && !/\s/.test(valueStr)) {
					//console.log(inputValueStr);
					validDataType = true
				}
				else{
					errormsg = "a valid email address"
				}
				break;
			case 'phone':
				// ---- VALID PHONE NUMBER EXAMPLES -----
				// 96857300
				// 9685 7300
				// 02 9685 7300
				// 0296857300
				// +61249248232

				if (/(^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3}))$|^([0-9]{8})$|^([0-9]{4} [0-9]{4})$/.test(inputValueStr)) {
					validDataType = true
				}
				else{
					errormsg = "a valid phone number"
				}
				break;
			case 'boolean':
				if (typeof inputValueStr != 'undefined') {
					validDataType = true
					// console.log(validDataType)
				}
				else{
					errormsg = dataType.toLowerCase();
				}
				// if (inputValueStr == true || inputValueStr == false || inputValueStr == 'true' || inputValueStr == 'false') {
				// 	validDataType = true
				// }
				break;
		}
	}

	// Return if dataType not valid
	if (!validDataType) {
		return {
			valid: false,  
			stop: false,
			message: 'Entered value must be ' + errormsg, 
			report: 'Data type is not valid. dataType:"' + dataType + '", value:"' + valueStr + '"'
		}
	}

	if (validationStr == null || validationStr == '' || typeof validationStr == 'undefined') {
		return {
			valid: true,  
			stop: false,
			message: '', 
			report: 'No validation string to evaluate. Returning true to allow the Health Check to continue.'
		}
	}

	// Check if multi condition
	if (validationStr.charAt(0) == '[') {
		// multicondition
		let validationResults = {}
		let validResults = false
		let multipleExplodedValidationStrs = validationStr.split('","')

		for (let i = 0; i < multipleExplodedValidationStrs.length; i++) {
			let tidyExplodedValidationString = tidyExplodedString(multipleExplodedValidationStrs[i]);

			console.log("multipleExplodedValidationStrs["+i+"]:")
			console.log(tidyExplodedValidationString)

			validationResults = validateSingleValidationStr(tidyExplodedValidationString, inputValueStr, dataType)

			if (!validationResults.valid) {
				return validationResults
			} else {
				validResults = true
			}
		} 
		if (validResults) {
			return validationResults
		}
	} else if (validationStr.charAt(0) == '(' && typeof nestedValidationConditionalIndex == 'number') {
		let newValidationStr = validationStr.replaceAll('(', '').replaceAll(')', '');

		let validationResults = {}
		let validResults = false
		let multipleOuterExplodedValidationStrs = newValidationStr.split('],');
		let multipleExplodedValidationStrs = multipleOuterExplodedValidationStrs[nestedValidationConditionalIndex].split(',');

		for (let j = 0; j < multipleExplodedValidationStrs.length; j++) {
			let tidyExplodedValidationString = tidyExplodedString(multipleExplodedValidationStrs[j]);

			console.log("multipleExplodedValidationStrs["+j+"]:")
			console.log(tidyExplodedValidationString)

			validationResults = validateSingleValidationStr(tidyExplodedValidationString, inputValueStr, dataType)

			if (!validationResults.valid) {
				return validationResults
			} else {
				validResults = true
			}				
		}

		if (validResults) {
			return validationResults
		}
	} else {
		return validateSingleValidationStr(validationStr, inputValueStr, dataType)
	}
}

function tidyExplodedString(string) {
	if (string.charAt(0) == '"') {
		string = string.substring(1,(string.length - 1));
	}

	if (string.charAt((string - 1)) == '"') {
		string = string.substring(0,(string.length - 2));
	}

	string = string.replaceAll('[""','"');
	string = string.replaceAll('[','');
	string = string.replaceAll('""]','"');
	string = string.replaceAll(']','');

	return string;
}

function validateSingleValidationStr(validationStr, inputValueStr, dataType) {
	console.log("validationStr:")
	console.log(validationStr)
	console.log("inputValueStr:")
	console.log(inputValueStr)




	// Remove additional '"' chars in blocks
	validationStr = validationStr.replaceAll('""""','"')
	validationStr = validationStr.replaceAll('"""','"')
	validationStr = validationStr.replaceAll('""','"')

	// Explode condition string
	let explodedValidationStr = validationStr.split('|')

	console.log("explodedValidationStr[0]:A")
	console.log(explodedValidationStr[0])


	if (explodedValidationStr.length != 3) { // exit if invalid
		return {
			valid: false, 
			stop: false, 
			message: '', 
			report: 'Incorrect validation string from question. Expecting 2 | delimiters.'
		}
	}

	console.log("explodedValidationStr[0]:B")
	console.log(explodedValidationStr[0])

	let placeholderValueStr = inputValueStr
	if (
		placeholderValueStr == '' 
		|| placeholderValueStr == null 
		|| typeof placeholderValueStr == 'undefined' 
		) {
		placeholderValueStr = '"NULL"' // replace with NULL in these conditions
	} else if (dataType != 'numeric' && dataType != 'Numeric') {
		placeholderValueStr = '"' + placeholderValueStr + '"'
	}

	console.log("explodedValidationStr[0]:C")
	console.log(explodedValidationStr[0])


	explodedValidationStr[0] = explodedValidationStr[0].replace('NULL','"NULL"') // encapsulate null string
	explodedValidationStr[0] = explodedValidationStr[0].replace('"{0}',placeholderValueStr) // replace placeholder {0}
	explodedValidationStr[0] = explodedValidationStr[0].replace('{0}',placeholderValueStr) // replace placeholder {0}
	explodedValidationStr[0] = explodedValidationStr[0].replaceAll('""','"') // replace double double quotes

	explodedValidationStr[2] = explodedValidationStr[2].replaceAll('"','')

	if (explodedValidationStr[0].split('"').length == 2) { // Once stray '"' - replace it
		explodedValidationStr[0] = explodedValidationStr[0].replaceAll('"','')
	}

	// Check if this is a <, <=, >, >=, or == reference
	console.log("explodedValidationStr[0]:D")
	console.log(explodedValidationStr[0])

	// if (explodedValidationStr[0].includes('<') || explodedValidationStr[0].includes('>')) {
	// 	explodedValidationStr[0] = explodedValidationStr[0].replaceAll('"','')
	// 	let intCheckExploded

	// 	if (explodedValidationStr[0].includes('<')) {
	// 		intCheckExploded = explodedValidationStr[0].split('<')
	// 	}

	// 	if (explodedValidationStr[0].includes('>')) {
	// 		intCheckExploded = explodedValidationStr[0].split('>')
	// 	}

	// 	if (isNaN(intCheckExploded[0].trim()) || isNaN(intCheckExploded[0].trim())) {
	// 		return {
	// 			valid: false,  
	// 			stop: false,
	// 			message: 'Entered value must be numeric.', 
	// 			report: 'Data type is not valid.'
	// 		}
	// 	} else {

	// 	}
	// }



	let evalResults = eval(explodedValidationStr[0]) // evaluate
	// Handle Block conditions
	if (evalResults && explodedValidationStr[1].trim().includes('Block')) {
		return {
			valid: false,  
			stop: false,
			message: explodedValidationStr[2], 
			report: 'Didn\'t pass validation string: "' + validationStr + '"'
		}
	} else if (evalResults && !explodedValidationStr[1].trim().includes('Block')) {
		if (explodedValidationStr[1].trim().includes('STOP')) {
			return {
				valid: false, 
				stop: true,
				message: explodedValidationStr[2], 
				report: 'STOP Health Check'
			}
		}
		return {
			valid: true, 
			stop: false,
			message: '', 
			report: 'Condition dictates valid response on passing the validation. \nValidation string: "' + validationStr + '"'
		}
	} else if (!evalResults && explodedValidationStr[1].trim().includes('Block')) {
		return {
			valid: true,  
			stop: false,
			message: '', 
			report: 'Condition dictates valid response on failing the validation. \nValidation string: "' + validationStr + '"'
		}
	} else if (!evalResults && !explodedValidationStr[1].trim().includes('Block')) {
		if (explodedValidationStr[1].trim().includes('STOP')) {
			// ignore stop condition here
			return {
				valid: true,  
				stop: false,
				message: '', 
				report: 'Condition dictates valid response on failing the validation. \nValidation string: "' + validationStr + '"'
			}
		}
		return {
			valid: false,  
			stop: false,
			message: explodedValidationStr[2], 
			report: 'Didn\'t pass validation string: "' + validationStr + '"'
		}
	}

	return {
		valid: true,  
		stop: false,
		message: '', 
		report: 'No conditions were passed or failed. Returning true to allow the Health Check to continue.'
	}
}

/*
 *	Validate if a value is valid option in the options array
 *  Returns: Object
 * 		success: Boolean
 *    		true - value is a valid option from options arr
 *    		false - value is an invalid option and not found in options arr
 * 		validIndex: Number - when success = true
 * 			number - index of valid dropdown option in supplied options array
 */
export function validateDropdown(value, options) {
	let matchFound = {"success": false};

	if (typeof value == 'string' && Array.isArray(options)) {
		for (let i = 0; i < options.length; i++) {
			// check if string
			if (typeof options[i] != 'string') {
				return matchFound // parameters aren't valid
			}

			if (value.toLowerCase() == options[i].toLowerCase()) {
				// value found
				matchFound.success = true;
				matchFound.validIndex = i;
				return matchFound;
			}
		}

		return matchFound; // catch all
	} else {
		return matchFound; // parameters aren't valid
	}
}