import Footer from "./Footer";
import ResultTabs from "./ResultTabs";
import RiskChart from "./RiskChart";
import Accordian from "./Accordian";
import RiskFactor from "./RiskFactor";
import ChatMessage from "./ChatMessage";
import SocialShare from "./SocialShare";
import {landingPageEvent} from "../utils/facebookPixelEvent"

import React, { useEffect, useState } from "react";
import { isMobile, getDOMElement } from "../helpers.js";
import { gtmHandler } from "../gtm/handler";

function ResultsScreen(props) {
  console.log("Results Page props", { props });

  const [hasSentRiskGTM, setHasSentRiskGTM] = useState(false);

  useEffect(() => {
    let app = getDOMElement("App", "className");

    if (typeof app !== "undefined") {
      app[0].classList.add("scrollable-y");
    }
  }, []);

  const ResultsTabChildren = (tabResults) => {
    if (!tabResults.tab1) return;
    // eslint-disable-next-line no-lone-blocks
    {
      /*
		Excluding banner section in the first comma
		And first heading is exclude as well in the third position
		of the destructure
		*/
    }
    let [, container, , ...accordions] = tabResults.tab1;
    const headingList = tabResults.tab1.filter(
      (item) => item?.blockType?.toLowerCase() === "section_heading"
    );

    const factorsToChange = [];
    const riskLoweredBy = [];

    return (
      <div className="tab-content-padding">
        <p style={{ marginBottom: "48px", fontFamily: "Mikadobold" }}>
          {container?.blockContent.text}
        </p>

        <section>
          <div className="risk-heading">
            {headingList[0]?.blockContent.heading}
          </div>

          {accordions?.map((item, index) => {
            // Condition to get waist information
            // because it is an accordion for a separate section
            if (item?.blockType?.toLowerCase() === "accordion") {
              if (
                item?.title?.includes("Your alcohol consumption") ||
                item?.title?.includes("Your fruit intake")
              ) {
                // Add item to the array
                factorsToChange.push({ ...item });
                // Separate the item of the accordions array
                let { [index]: newItem, ...newAccordions } = accordions;
                // Reasign new accordions list to the previous accordions list
                accordions = newAccordions;
              } else if (
                item?.title?.includes("Your waist measurement") ||
                item?.title?.includes("You smoke") ||
                item?.title?.includes("You are currently exercising") ||
                item?.title?.includes("Your vegetable intake")
              ) {
                // Add item to the array
                riskLoweredBy.push({ ...item });
                // Separate the item of the accordions array
                let { [index]: newItem, ...newAccordions } = accordions;
                // Reasign new accordions list to the previous accordions list
                accordions = newAccordions;
              } else {
                return (
                  <div style={{ marginTop: "16px" }}>
                    <Accordian
                      bgClass={"bg-primary"}
                      heading={item?.title}
                      key={index}
                    >
                      <p className="m-0">{item?.blockContent?.text}</p>
                    </Accordian>
                  </div>
                );
              }
            }
          })}
        </section>

        {/* Factors that you can change */}
        <section style={{ marginTop: "32px" }}>
          <div className="risk-heading">
            {headingList[1]?.blockContent.heading}
          </div>

          {factorsToChange?.reverse().map((item, index) => {
            return (
              <div style={{ marginTop: "16px" }}>
                <Accordian
                  bgClass={"bg-primary"}
                  heading={item?.title}
                  key={index}
                  renderExpanded={true}
                >
                  <RiskFactor
                    data={{ ...item?.blockContent?.[0] }}
                    type={item?.title}
                    key={index}
                  ></RiskFactor>

                  {/* <h2
								style={{fontSize:'16px'}}
								className="chart-sub-heading padding-bottom-32 m-0"
							>
								{props.chartSubHeading}
							</h2> */}

                  <p
                    className="padding-bottom-32 m-0"
                    dangerouslySetInnerHTML={{
                      __html: item?.blockContent?.[0]?.text,
                    }}
                  ></p>

                  <ChatMessage
                    isMicroMessage="true"
                    avatarFillClass="fill-primary-3"
                    bgClass="bg-white"
                    text={item?.blockContent?.[0]?.conversation}
                    classList="padding-bottom-32"
                  />

                  {/* <p className="text mini padding-bottom-32 m-0">{props.question}</p> */}

                  {/* <ul className="m-0 p-0">
								<li key={alcohol?.blockContent?.[0]?.link.url}>
									<a href={alcohol?.blockContent?.[0]?.link.url} className="link"> {alcohol?.blockContent?.[0]?.link.title}</a>
									<svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9.07788 11.8444H1.28877V4.06329H6.33528L7.63802 2.7619H0.6444C0.287643 2.7619 0 3.0498 0 3.40564V12.4881C0 12.8445 0.288192 13.1319 0.6444 13.1319H9.73624C10.093 13.1319 10.3806 12.844 10.3806 12.4881V5.5017L9.07791 6.8031L9.07788 11.8444Z" fill="black" />
										<path d="M12.3694 0.131897H8.70814C8.35138 0.131897 8.06374 0.419792 8.06374 0.775634C8.06374 1.13202 8.35193 1.41937 8.70814 1.41937H10.7924L4.73138 7.48811C4.47105 7.74817 4.47105 8.14576 4.73138 8.40582C4.85458 8.5289 5.01957 8.59739 5.18402 8.59739C5.34846 8.59739 5.51291 8.52889 5.63665 8.40582L11.7111 2.35106V4.43315C11.7111 4.78954 11.9993 5.07689 12.3555 5.07689C12.7122 5.07689 12.9999 4.78899 12.9999 4.43315V0.775689C13.0138 0.419834 12.7256 0.131952 12.3694 0.131952L12.3694 0.131897Z" fill="black" />
									</svg>
								</li>
							</ul> */}
                </Accordian>
              </div>
            );
          })}
        </section>
        {/* /Factors that you can change */}

        {/* lower risk */}
        <section className="lower-risk-section">
          <div
            className="risk-heading"
            style={{ borderBottomColor: "#27B9C0" }}
          >
            {headingList[2]?.blockContent?.heading}
          </div>

          {riskLoweredBy?.map((item, index) => {
            console.log("risk  lowered", riskLoweredBy);

            // Render accordion when item is an Array
            if (Array.isArray(item?.blockContent)) {
              return (
                <div style={{ marginTop: "16px" }}>
                  <Accordian
                    bgClass={"bg-primary-5"}
                    heading={item?.title}
                    renderExpanded={true}
                    key={index}
                  >
                    <RiskFactor
                      data={{ ...item?.blockContent?.[0] }}
                      type={item?.title}
                      key={index}
                    ></RiskFactor>

                    <h2 className="chart-sub-heading padding-bottom-32 m-0">
                      {item?.blockContent?.[0]?.["linear-chart"]?.tagStart}
                    </h2>

                    {item?.blockContent?.map((subItem, index) => {
                      if (index === 0) {
                        return (
                          <>
                            <p
                              className="padding-bottom-32 m-0"
                              dangerouslySetInnerHTML={{
                                __html: subItem?.text,
                              }}
                            ></p>

                            <ChatMessage
                              isMicroMessage="true"
                              avatarFillClass="fill-primary-3"
                              bgClass="bg-white"
                              text={subItem?.conversation}
                              classList="padding-bottom-32"
                            />
                          </>
                        );
                      }

                      // Render rest information if exists
                      return (
                        <>
                          <p className="padding-bottom-32 m-0">
                            {subItem?.text}
                            <>
                              <br />
                              <a href={subItem?.link.url} className="link">
                                {" "}
                                {subItem?.link.title}
                              </a>
                              <svg
                                width="13"
                                height="14"
                                viewBox="0 0 13 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.07788 11.8444H1.28877V4.06329H6.33528L7.63802 2.7619H0.6444C0.287643 2.7619 0 3.0498 0 3.40564V12.4881C0 12.8445 0.288192 13.1319 0.6444 13.1319H9.73624C10.093 13.1319 10.3806 12.844 10.3806 12.4881V5.5017L9.07791 6.8031L9.07788 11.8444Z"
                                  fill="black"
                                />
                                <path
                                  d="M12.3694 0.131897H8.70814C8.35138 0.131897 8.06374 0.419792 8.06374 0.775634C8.06374 1.13202 8.35193 1.41937 8.70814 1.41937H10.7924L4.73138 7.48811C4.47105 7.74817 4.47105 8.14576 4.73138 8.40582C4.85458 8.5289 5.01957 8.59739 5.18402 8.59739C5.34846 8.59739 5.51291 8.52889 5.63665 8.40582L11.7111 2.35106V4.43315C11.7111 4.78954 11.9993 5.07689 12.3555 5.07689C12.7122 5.07689 12.9999 4.78899 12.9999 4.43315V0.775689C13.0138 0.419834 12.7256 0.131952 12.3694 0.131952L12.3694 0.131897Z"
                                  fill="black"
                                />
                              </svg>
                            </>
                          </p>

                          {subItem?.link && !subItem?.text && (
                            <ul className="m-0 p-0">
                              <li key={subItem?.link.url}>
                                <a href={subItem?.link.url} className="link">
                                  {" "}
                                  {subItem?.link.title}
                                </a>
                                <svg
                                  width="13"
                                  height="14"
                                  viewBox="0 0 13 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.07788 11.8444H1.28877V4.06329H6.33528L7.63802 2.7619H0.6444C0.287643 2.7619 0 3.0498 0 3.40564V12.4881C0 12.8445 0.288192 13.1319 0.6444 13.1319H9.73624C10.093 13.1319 10.3806 12.844 10.3806 12.4881V5.5017L9.07791 6.8031L9.07788 11.8444Z"
                                    fill="black"
                                  />
                                  <path
                                    d="M12.3694 0.131897H8.70814C8.35138 0.131897 8.06374 0.419792 8.06374 0.775634C8.06374 1.13202 8.35193 1.41937 8.70814 1.41937H10.7924L4.73138 7.48811C4.47105 7.74817 4.47105 8.14576 4.73138 8.40582C4.85458 8.5289 5.01957 8.59739 5.18402 8.59739C5.34846 8.59739 5.51291 8.52889 5.63665 8.40582L11.7111 2.35106V4.43315C11.7111 4.78954 11.9993 5.07689 12.3555 5.07689C12.7122 5.07689 12.9999 4.78899 12.9999 4.43315V0.775689C13.0138 0.419834 12.7256 0.131952 12.3694 0.131952L12.3694 0.131897Z"
                                    fill="black"
                                  />
                                </svg>
                              </li>

                              {subItem?.text}
                            </ul>
                          )}
                        </>
                      );
                    })}
                  </Accordian>
                </div>
              );
            }

            // Render accordion when item is an Object
            return (
              <div style={{ marginTop: "16px" }}>
                <Accordian
                  bgClass={"bg-primary-5"}
                  heading={item?.title}
                  renderExpanded={true}
                  key={index}
                >
                  <RiskFactor
                    data={{ ...item?.blockContent }}
                    type={item?.title}
                    key={index}
                  ></RiskFactor>

                  <h2 className="chart-sub-heading padding-bottom-32 m-0">
                    {item?.blockContent?.["linear-chart"]?.tagStart}
                  </h2>

                  <p
                    className="padding-bottom-32 m-0"
                    dangerouslySetInnerHTML={{
                      __html: item?.blockContent?.text,
                    }}
                  ></p>

                  <ChatMessage
                    isMicroMessage="true"
                    avatarFillClass="fill-primary-3"
                    bgClass="bg-white"
                    text={item?.blockContent?.conversation}
                    classList="padding-bottom-32"
                  />
                </Accordian>
              </div>
            );
          })}
        </section>
        {/* /lower risk */}

        <SocialShare
          suggestedLink="https://www.google.com"
          suggestedLinkText="VIEW SUGGESTED PROGRAM FOR YOU"
          shareButtonText="SHARE YOUR RESULTS WITH A FRIEND"
        />
      </div>
    );
  };

  const SuggestedTabChildren = (tabResults) => {
    if (!tabResults?.tab2) return;
    const suggestedSection = tabResults?.tab2;

    return (
      <div>
        <div style={{ padding: "30px 50px 0px 50px" }}>
          {
            // Validation for blockContent that is an Array
            suggestedSection.map((suggested) => {
              if (Array.isArray(suggested?.blockContent)) {
                suggested.blockContent?.map((item, index) => {
                  return (
                    <section id={index}>
                      <p>{item?.text}</p>
                    </section>
                  );
                });
              }

              // Validation for blockContent that is an object
              if (
                typeof suggested.blockContent === "object" &&
                !Array.isArray(suggested?.blockContent)
              ) {
                const item = suggested?.blockContent;
                return (
                  <section>
                    <p>{item?.text}</p>
                    <p>
                      <strong>
                        <a
                          href={item?.link?.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{ "text-decoration": "underline" }}
                        >
                          {item?.link?.title}
                        </a>
                      </strong>
                    </p>
                  </section>
                );
              }
            })
          }

          {/* <p>
						<em>My health for life</em> is a <span style={{fontFamily:'Mikadobold'}}> free, holistic lifestyle program</span> to help you feel good and live well.
					</p>

					<p>
						Whether its eating well, moving more, maintaining a healthy weight, better sleep, or managing stress, <em>My health for life</em> is a lifestyle program to help you live well and reduce your risk of developing chronic conditions such as type 2 diabetes, heart disease and stroke.
					</p>

					<p style={{fontFamily:'Mikadobold'}}>
						We've helped thousands of Queenslanders achieve their health goal. You can be next!
					</p> */}
        </div>
      </div>
    );
  };

  // Separate banner and content of Tab1 (results tab)
  const [bannerResults, ...contentResults] = props?.resultSet?.tab1 || [];

  // Separate banner and content of Tab2 (suggested tab)
  const [bannerSuggested, ...contentSuggested] = props?.resultSet?.tab2 || [];

  useEffect(() => {
    if (
      !hasSentRiskGTM &&
      !!props.resultSet &&
      Object.values(props.resultSet).length > 0
    ) {
      const { riskLevel, eligibility, resultCategory } = props.resultSet;
      // Get only the data that we need to send to GTM
      const data = { riskLevel, eligibility, resultCategory };

      gtmHandler.pushDataLayer(gtmHandler.activeEventKeys.riskLevel, data);
      setHasSentRiskGTM(true);
      console.log("Has sent risk to gtm", data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSentRiskGTM, props]);

  return (
    <div className="component-results-screen">
      {Object.keys(props.resultSet).length === 0 && (
        <div>
          <h4>Loading your results...</h4>
          <p>
            <span className="loading"></span>
          </p>
        </div>
      )}

      {Object.keys(props.resultSet).length > 0 && (
        <div>
          <h3>{props?.resultSet?.title}</h3>

          <RiskChart
            bgClass={"bg-primary"}
            riskIndication={props.resultSet?.score || 10}
            text={props.resultSet?.summary}
            bands={props.resultSet?.riskBands}
            lowEndPercentage={50}
            midRangePercentage={20}
            highEndPercentage={30}
            resultSet={props.resultSet}
          />

          <div style={{ paddingBottom: "32px", marginBottom: "32px" }}>
            <ResultTabs
              resultSet={props.resultSet}
              result={props.results}
              formSubmitCallback={props.formSubmitCallback}
              loggedInUserData={props.loggedInUserData}
              userFirstName={props.userFirstName}
              userEmail={props.userEmail}
              userPhone={props.userPhone}
            />
          </div>
        </div>
      )}

      {props.resultsVisibility && props.displayLogoBannerAndFooter && (
        <Footer
          className="mobile-footer"
          environment={props.environment}
        ></Footer>
      )}
    </div>
  );
}

export default ResultsScreen;
