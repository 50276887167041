import React, { useState, useEffect, useRef, createRef } from "react";
import { validateInput } from "../utils/input-validation.js";
import { gtmHandler } from "../gtm/handler.js";

function ResultsOnlyForm(props) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    agreeToTerms: false,
    receiveNewsletter: false,
  });

  const formDataTypeMap = {
    firstName: { type: "text", required: true },
    lastName: { type: "text", required: true },
    email: { type: "email", required: true },
    agreeToTerms: { type: "boolean", required: true },
    receiveNewsletter: { type: "boolean", required: false },
  };

  const [requiredInputs, setRequiredInputs] = useState([""]);
  const [formDisabled, setFormDisabled] = useState(false);
  const [formSubmissionMessage, setFormSubmissionMessage] = useState("");
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);
  let isValid = true;
  const alertRefs = React.useRef([]);
  alertRefs.current = Object.keys(formData).map(
    (k, index) => alertRefs[index] ?? createRef()
  );

  useEffect(() => {
    if (!isInitiallyLoaded && Object.keys(props.loggedInUserData).length > 0) {
      setFormData({
        ...formData,
        ["firstName"]: props.loggedInUserData["firstname"] ?? "",
        ["lastName"]: props.loggedInUserData["lastname"] ?? "",
        ["email"]: props.loggedInUserData["email"] ?? "",
      });
    } else if (!isInitiallyLoaded && typeof props.userFirstName == "string") {
      setFormData({
        ...formData,
        ["firstName"]: props.userFirstName ?? "",
        ["email"]: props.userEmail ?? "",
      });
    }

    setIsInitiallyLoaded(true);
  }, []);

  function handleInputChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });

    let refIndex = Object.keys(formData).indexOf(name);
    alertRefs.current[refIndex].innerHTML = "";
    alertRefs.current[refIndex].classList =
      "text text-error text-error-results hide";
  }

  function validateForm() {
    let valid = true;

    Object.keys(formData).forEach((key) => {
      let validationStr =
        formDataTypeMap[key].type === "boolean" &&
        formDataTypeMap[key].required &&
        !formData[key]
          ? "true"
          : "";

      let validationCheck = validateInput(
        String(formData[key]),
        validationStr,
        formDataTypeMap[key].type
      );
      let refIndex = Object.keys(formData).indexOf(key);

      if (validationCheck.valid) {
        // Validation has passed - clear message for this field
        alertRefs.current[refIndex].innerHTML = "";
        alertRefs.current[refIndex].classList =
          "text text-error text-error-results hide";
      } else {
        // If validation doesn't pass, show an error for that error message
        valid = false;

        if (key === "agreeToTerms") {
          validationCheck.message =
            "You must agree to the terms and conditions";
        }

        alertRefs.current[refIndex].innerHTML = validationCheck.message;
        alertRefs.current[refIndex].classList =
          "text text-error text-error-results";
      }
    });

    isValid = valid;
    return valid;
  }

  function toggleFormState(isDisabled) {
    setFormDisabled(isDisabled);
  }

  function isFormValid() {
    validateForm();

    return isValid;
  }

  function handleSubmit(e) {
    e.preventDefault();

    // Attach GTM Tag Manager and setup Data Layer
    gtmHandler.bindSubmit(e);

    let successMessage = "";
    let riskLevel = props.riskLevelProp.toLowerCase();
    if (riskLevel == "high") {
      successMessage =
        'Thank you! Your results have been sent to your email inbox. You can start your healthy living journey with <span style="font-style: italic;"> My health for life</span> at any time by calling us on 13 74 75.';
    }
    if (riskLevel == "mod") {
      successMessage =
        "Thank you! Your results have been sent to your email inbox.";
    }
    if (riskLevel == "low") {
      successMessage =
        "Thank you! Your results have been sent to your email inbox.";
    }
    const cb = (success, message) => {
      toggleFormState(false);
      setFormSubmissionMessage(successMessage);
    };

    if (isFormValid()) {
      toggleFormState(true);
      props.handleSubmit({ ...formData, signup: false }, cb);
    }
  }

  function onSubmitClick(e) {
    // Attach GTM Tag Manager and setup Data Layer
    gtmHandler.bindClick(e);
  }

  return (
    <div className="component-program-sign-up component-form-UI">
      <form id="form-submit-2" onSubmit={handleSubmit}>
        <label>
          <input
            value={formData["firstName"]}
            className={`simple-input`}
            type="text"
            name="firstName"
            placeholder="First Name"
            onChange={handleInputChange}
            disabled={formDisabled}
          />
        </label>
        <p
          className="text text-error text-error-results hide"
          ref={(el) =>
            (alertRefs.current[Object.keys(formData).indexOf("firstName")] = el)
          }
        ></p>

        <label>
          <input
            value={formData["lastName"]}
            className={`simple-input`}
            type="text"
            name="lastName"
            placeholder="Last Name"
            onChange={handleInputChange}
            disabled={formDisabled}
          />
        </label>
        <p
          className="text text-error text-error-results hide"
          ref={(el) =>
            (alertRefs.current[Object.keys(formData).indexOf("lastName")] = el)
          }
        ></p>

        <label>
          <input
            value={formData["email"]}
            className={`simple-input`}
            type="email"
            name="email"
            placeholder="Email"
            onChange={handleInputChange}
            disabled={formDisabled}
          />
        </label>
        <p
          className="text text-error text-error-results hide"
          ref={(el) =>
            (alertRefs.current[Object.keys(formData).indexOf("email")] = el)
          }
        ></p>

        <div className="consent-section mx-auto">
          <label>
            <input
              name="agreeToTerms"
              type="checkbox"
              checked={formData.agreeToTerms}
              onChange={handleInputChange}
              className={`${
                requiredInputs.includes("agreeToTerms") ? "required" : ""
              }`}
              disabled={formDisabled}
            />
            <span>
              I agree to the{" "}
              <a
                href="https://www.myhealthforlife.com.au/privacy-policy/"
                target="_blank"
              >
                privacy policy
              </a>{" "}
              and{" "}
              <a
                href="https://www.myhealthforlife.com.au/terms-conditions/"
                target="_blank"
              >
                terms & conditions
              </a>
            </span>
          </label>
          <p
            className="text text-error text-error-results hide"
            style={{ marginBottom: 9 }}
            ref={(el) =>
              (alertRefs.current[
                Object.keys(formData).indexOf("agreeToTerms")
              ] = el)
            }
          ></p>

          <label>
            <input
              name="receiveNewsletter"
              type="checkbox"
              checked={formData.receiveNewsletter}
              onChange={handleInputChange}
              disabled={formDisabled}
            />
            <span>I would like to receive the quarterly newsletter</span>
          </label>
          <p
            className="text text-error text-error-results hide"
            ref={(el) =>
              (alertRefs.current[
                Object.keys(formData).indexOf("receiveNewsletter")
              ] = el)
            }
          ></p>
        </div>

        <div className={`loader-wrapper ${formDisabled ? "" : "disabled"}`}>
          <span className="loading small"></span>
        </div>
        <input
          id="risk-submit-2"
          type="submit"
          value="SUBMIT DETAILS"
          className={`component-button share-button primary regular large ${
            formDisabled ? "disabled" : ""
          }`}
          disabled={formDisabled}
          onSubmit={onSubmitClick}
        />
        <p
          className={`form-submission-message ${
            formSubmissionMessage == "" ? "" : "active"
          }`}
          dangerouslySetInnerHTML={{ __html: formSubmissionMessage }}
        ></p>
      </form>
    </div>
  );
}

export default ResultsOnlyForm;
