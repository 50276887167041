import Button from './Button.js';
import React, { useState, useEffect } from "react";
import {landingPageEvent} from "../utils/facebookPixelEvent"

function DynamicYesNo(props) {
    useEffect(() =>{
        landingPageEvent();
    },[]);
    const [selectedButton, setSelectedButton] = useState("");

    function buttonClick(choice) {
        setSelectedButton(choice);
        props.callback(props.slug, choice);
    }

    return (
      <div className={`component-yes-no component-dynamic-yes-no ${props.classList}`}>
            {props.innerText != '' &&
                <h1>{props.innerText}</h1>
            }
            <div className={`button-wrapper d-flex justify-content-between ${props.collapseButtons ? 'column' : ''}`}>
            <Button
                classList={`regular primary ${selectedButton == props.options[0] ? "selected" : ""}`}
                innerText={props.options[0]}
                onclick={buttonClick.bind(this, props.options[0]+'')}
            />
            <Button
                classList={`regular primary ${selectedButton == props.options[1] ? "selected" : ""}`}
                innerText={props.options[1]}
                onclick={buttonClick.bind(this, props.options[1]+'')}
            />
          </div>
      </div>
    );
  }
  
  export default DynamicYesNo;