function ProgressBar(props) {
  return (
    <div className="component-progress-bar">
      <div className="inner-progress" style={{width: props.progress + "%"}}>
        <div className="text-block shadow" style={{width: ((100 / props.progress) * 100) + "%"}}>{props.progress + '%'}</div>
      </div>
      <div className="text-block header">{props.progress + '%'}</div>
    </div>
  );
}

export default ProgressBar;