import React, { useState, useEffect } from "react";

function Image(props) {
    return (
        <div className={`component-image`} >
              <img src={props.src} alt={props.alt ? props.alt : ''} loading="lazy" />
        </div>
    );

}

export default Image;
