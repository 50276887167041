import bottles from '../assets/images/bottles.png'
import meter from '../assets/images/meter.png'
import ProgressBar from './ProgressBar';
import React, {useState, useEffect, useRef} from 'react';

//to calculate widht of full bar 
function RiskFactor(props) {
    const [width, setWidth] = useState(0);
    const elementRef = useRef(null);
  
    useEffect(() => {
      setWidth(elementRef.current.getBoundingClientRect().width);
    }, []);

    return (
        <div className="component-risk-factor">
            <div className="main-bar" ref={elementRef}>
                {(() => {
                    let bandHighestValue = props.bands[(props.bands.length - 1)][Object.keys(props.bands[(props.bands.length - 1)])[0]][1]
                    let meterWidth = ((parseInt(props.riskScore) / bandHighestValue) * 100)
                    return (
                        <>
                            {props.bands.map((band,index) => {
                                let classNameStr = ''
                                let styleObj
                                // Loop through all the bands, which are objects
                                {Object.keys(band).map((subBand, subIndex) => {
                                    let barOffsetInPixels = 30
                                    let barWidth = 'calc(' + barOffsetInPixels + 'px + ' + (((parseInt(band[subBand][1]) - parseInt(band[subBand][0])) / bandHighestValue) * 100) + '%)'
                                    let barLeft = 'calc(' + ((parseInt(band[subBand][0]) / bandHighestValue) * 100) + '% - ' + (barOffsetInPixels / 2) + 'px)'
                                    classNameStr = 'progress-br inner-progress-' + subBand
                                    styleObj = {width: barWidth, left: barLeft}
                                })}
                                return <div className={classNameStr} style={styleObj} key={`band-${index}`}></div>
                            })}


                            <div className="meter-trans absolu" style={{ width: meterWidth + "%" }}>
                                <div className="chart-meter">
                                    <img src={meter} alt=""/>
                                </div>
                            </div>
                        </>
                    )
                })()}
            </div>
            <div className="dotted"></div>
            <div className="text-below-meter">
                <p className="left-text" dangerouslySetInnerHTML={{__html: props.tagStart}}></p>
                <p className="right-text" dangerouslySetInnerHTML={{__html: props.tagEnd}}></p>
            </div>
        </div>
      );
}
export default RiskFactor;