import ProgressBar from './ProgressBar.js';
import Button from './Button.js';
import React, { useState, useEffect } from "react";

function Header(props) {
    function preSlide() {
        props.navigateBackCallback();
    }

    return (
        <div className={`component-header ${props.headerState}`}>
            {props.indexFlow.length > 1 && props.headerState === 'inProgress' && 
                <div className='back-button'>
                    <Button
                        classList={"circle arrow"}
                        onclick={preSlide}
                    />
                </div>
            }
            <div className={`mh4l-logo ${props.headerState}`}>
                
            </div>
            {props.headerState === 'inProgress' && <ProgressBar progress={props.progress} />}
        </div>
    );

}

export default Header;
