import { Fragment, useEffect, useRef } from "react";

function RiskChart(props) {
  const strokeLow = useRef([]);
  const strokeMedLow = useRef([]);
  const strokeMedium = useRef([]);
  const strokeMedHigh = useRef([]);
  const strokeHigh = useRef([]);
  const arrow = useRef([]);

  const strokeAttrs = [
    {
      strokeRef: strokeLow,
      svgClassName: "low",
      strokeClassName: "stroke-low",
    },
    {
      strokeRef: strokeMedLow,
      svgClassName: "medLow",
      strokeClassName: "stroke-medlow",
    },
    {
      strokeRef: strokeMedium,
      svgClassName: "medium",
      strokeClassName: "stroke-medium",
    },
    {
      strokeRef: strokeMedHigh,
      svgClassName: "medHigh",
      strokeClassName: "stroke-medHigh",
    },
    {
      strokeRef: strokeHigh,
      svgClassName: "high",
      strokeClassName: "stroke-high",
    },
  ];

  const isStrokesCurrent = strokeAttrs.every(
    ({ strokeRef }) => strokeRef.current !== undefined
  );

  const isRiskBandsArr = !!props.bands && props.bands.length > 0;

  const setRotation = () => {
    if (props.riskIndication === 0) return;

    // Get highest value - this is used for effectively scalling all positions
    let bandHighestValue =
      props.bands[props.bands.length - 1][
        Object.keys(props.bands[props.bands.length - 1])[0]
      ][1];

    bandHighestValue =
      typeof bandHighestValue != "undefined" &&
      typeof bandHighestValue != "string"
        ? bandHighestValue
        : 40;

    // Set meter pos with degrees
    let degreeNumber =
      (270 / 100) * ((parseInt(props.riskIndication) / bandHighestValue) * 100);

    // rebase offset vars
    // let root = document.documentElement;

    // root.style.setProperty('--low-offset', 1000);
    // root.style.setProperty('--medLow-offset', 1000);
    // root.style.setProperty('--medium-offset', 1000);
    // root.style.setProperty('--medHigh-offset', 1000);
    // root.style.setProperty('--high-offset', 1000);

    // Magic numbers set by SVG positioning
    let lowEnd = 1000;
    let highEnd = 840;
    let lowHighDifference = 160;

    // Loop through provided array of bands and set the SVGs elements
    for (let i = 0; i < props.bands.length; i++) {
      // Loop through top level
      for (let j = 0; j < Object.keys(props.bands[i]).length; j++) {
        // Loop through keys for each element
        let bandKey = Object.keys(props.bands[i])[j]; // get key
        let bandArr = props.bands[i][bandKey]; // get array

        // Calc percentage of highest value offset
        let decimalPercentageOfThisBand = bandArr[1] / bandHighestValue;
        // console.log("decimalPercentageOfThisBand")
        // console.log(decimalPercentageOfThisBand)

        // Calculate band arc in SVG numbers
        let bandArcInSVGNumbers =
          decimalPercentageOfThisBand * lowHighDifference;

        // Set style in root var
        // console.log("bandKey")
        // console.log(bandKey)
        switch (bandKey) {
          case "low":
            strokeLow.current.style.strokeDashoffset =
              1000 - bandArcInSVGNumbers;
            break;
          case "medLow":
            strokeMedLow.current.style.strokeDashoffset =
              1000 - bandArcInSVGNumbers;
            break;
          case "medium":
            strokeMedium.current.style.strokeDashoffset =
              1000 - bandArcInSVGNumbers;
            break;
          case "medHigh":
            strokeMedHigh.current.style.strokeDashoffset =
              1000 - bandArcInSVGNumbers;
            break;
          case "high":
            strokeHigh.current.style.strokeDashoffset =
              1000 - bandArcInSVGNumbers;
            break;
          default:
            break;
        }
        // root.style.setProperty('--' + bandKey + '-offset', (1000 - bandArcInSVGNumbers))
      }
    }

    // root.style.setProperty('--arrow-end-deg', deg + "deg");

    // Delay Arrow animation for background to finish
    setTimeout(() => {
      arrow.current.style.transform = `rotate(${degreeNumber}deg)`;
    }, 1000);
  };

  useEffect(() => {
    if (isRiskBandsArr && isStrokesCurrent) {
      setRotation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRiskBandsArr, isStrokesCurrent]);

  // Set the data layer for

  // get the risk key word e.g. 'high'

  // push to a data layer
  // do some other cool stuff

  // to make life easier
  // modify the results page to pass the actual risk word or number as a prop

  return (
    <div className={`component-risk-chart ${props.bgClass}`}>
      {isRiskBandsArr && (
        <div className="indicator-wrapper d-flex justify-content-center align-items-center">
          <div className="text-block header">Low Risk</div>
          <div className="outer-risk-indicator">
            <div className="loader">
              <div className="sliders">
                {strokeAttrs.map(
                  ({ strokeRef, svgClassName, strokeClassName }) => (
                    <Fragment key={svgClassName}>
                      <svg
                        className={svgClassName}
                        viewBox="0 0 120 120"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          ref={strokeRef}
                          className={`load ${strokeClassName}`}
                          cx="60"
                          cy="60"
                          r="40"
                        />
                      </svg>
                    </Fragment>
                  )
                )}
              </div>
              <div className="indicator-ellipse">
                <div ref={arrow} className="arrow-wrapper circle-beige">
                  <svg
                    width="20%"
                    height="20%"
                    viewBox="0 0 51 51"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1549 7.44975C12.1698 1.61236 8.78733 1.91574 6.17468 4.5945C3.56202 7.27326 2.70853 11.313 8.69361 17.1504C14.6787 22.9878 45.0866 48.408 47.6993 45.7293C50.3119 43.0505 24.14 13.2871 18.1549 7.44975Z"
                      fill="black"
                    />
                    <circle
                      cx="11.0576"
                      cy="9.90117"
                      r="2.64221"
                      transform="rotate(90.2603 11.0576 9.90117)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="text-block header">High Risk</div>
        </div>
      )}
      <div className="text-block general">
        <span dangerouslySetInnerHTML={{ __html: props.text }}></span>
      </div>
    </div>
  );
}

export default RiskChart;
