import Button from './Button.js';
import { useState } from "react";

function YesNo(props) {
    const [activeButton, setActiveButton] = useState("");

    function buttonClick(choice) {
        props.callback(props.slug, choice, true);

        setActiveButton(choice);
    }

    return (
      <div className={`component-yes-no ${props.classList}`}>
          <h1>{props.innerText}</h1>
          <div className='button-wrapper d-flex justify-content-between'>
            <Button
                classList={`regular primary yesno ${activeButton == "Yes" ? 'selected' : ''}`}
                innerText={"Yes"}
                onclick={buttonClick.bind(this, 'Yes')}
            />
            <Button
                classList={`regular primary yesno ${activeButton == "No" ? 'selected' : ''}`}
                innerText={"No"}
                onclick={buttonClick.bind(this, 'No')}
            />
          </div>
      </div>
    );
  }
  
  export default YesNo;