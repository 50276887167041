import Button from './Button.js'
import React, { useState, useRef, useEffect } from "react"
import * as ReactDOMServer from 'react-dom/server'
import Modal from "react-bootstrap/Modal"
import WaistTable from "./WaistTable.js"
import { getDOMElement } from '../helpers.js'
import { validateInput } from '../utils/input-validation.js';

function MultiChoice(props) {
    console.log(props)
    const [showWaistInput, setShowWaistInput] = useState(false)
	const [showWaistSelection, setWaistSelection] = useState(false)
    const [waistInput, setWaistInput] = useState("");
    const [waistSizeSelected, setWaistSizeSelected] = useState("")
    const [waistSizeSetWithChart, setWaistSizeSetWithChart] = useState(false)
    const [tableHTMLStr, setTableHTMLStr] = useState("")
    const [waistChartData, setWaistChartData] = useState([])
    const [alertCustomError, setAlertCustomError] = useState('')
    const [questionResponses, setQuestionResponses] = useState('[]')
    const [singleResponse, setSingleResponse] = useState('')
    const [singleResponseCount, setSingleResponseCount] = useState(0)

    const modalRef = useRef()
    const tableRef = useRef()

    let collapseValue = props.collapseValue ?? 4;
    let question = props.question;
    let qid = question.questionId;
    let responses = question.questionResponses;
    let buttonImages = question.buttonImages;
    let btnImgArray = [];
    

    useEffect(() => {
        console.log("JSON.parse(questionResponses)")
        console.log(JSON.parse(questionResponses))
    },[questionResponses])

     useEffect(() => {
        console.log("singleResponse")
        console.log(singleResponse)

        if (props.continueOnSelect && singleResponseCount > 0) {
            // Create string for multiselect validation
            let validationFaux = null
            let inputValuesObject = ''
            if (questionResponses != '') {
                inputValuesObject = JSON.parse(questionResponses)
            }
            if (question.questionType.includes('Single-Select')) {
                inputValuesObject = singleResponse
            }
            if (inputValuesObject.length == 0) {
                validationFaux = ''
            } else {
                if (inputValuesObject[0] != 'continue') {
                    validationFaux = 'This represents value.'
                } else {
                    validationFaux = ''
                }
            }

            let validationCheck = validateInput(validationFaux, props.validation, props.dataType)

            if (validationCheck.valid) {
                if (singleResponse == "continue") {
                    
                    props.callback(question.questionName, inputValuesObject, true)
                    
                } else {
                    let questionResponsesArr = [];

                    if (question.questionType.includes('Single-Select')) {
                        inputValuesObject = singleResponse
                    }
                    props.callback(question.questionName, inputValuesObject, props.continueOnSelect)

                }
                setAlertCustomError('')
            } else {
                setTimeout(function(){
                    setAlertCustomError(validationCheck.message)
                },50)
                
                console.warn(validationCheck.report)
            }      
        }
        setSingleResponseCount(singleResponseCount + 1)
    },[singleResponse])

    if (buttonImages != null && buttonImages.includes('|')) {
        btnImgArray = buttonImages.split('|');
    }

    function writeQuestionResponses(value, action) {
        // Check if str is empty and reset
        let fauxInputValues = questionResponses
        if (questionResponses == '' || typeof questionResponses == 'undefined' || questionResponses == null) {
            let arr = []
            fauxInputValues = JSON.stringify(arr)
        }

        // Parse JSON for use
        let inputValuesObject = JSON.parse(fauxInputValues)

        if (action == 'push') {

            // Check to see if string "NONE OF THE ABOVE" is in the results
            let hasClearOption = (question.ClearAll != null || question.ClearAll != 'null')

            if (hasClearOption && inputValuesObject.length > 0) {
                for (let i = 0; i < inputValuesObject.length; i++) {
                    if (inputValuesObject[i] == responses[question.ClearAll]) {
                        // remove clear all value from results array
                        inputValuesObject.splice(i, 1)
                    }
                }
            }

            if (inputValuesObject.includes(value)) {
                inputValuesObject.splice(value,1) 
            } else {
                inputValuesObject.push(value)
            }
        } else if (action == 'splice') {
            inputValuesObject.splice(value,1) 
        } else if (action == 'clear') {
            inputValuesObject = [value] 
        }

        let inputValuesJSON = JSON.stringify(inputValuesObject) // Write as JSON string
        console.log("inputValuesJSON")
        console.log(inputValuesJSON)

        setQuestionResponses(inputValuesJSON) // Save new JSON object
    }

    function handleModalClose() {
        modalRef.current.classList.remove('show')

        setTimeout(function(){
            modalRef.current.style.display = "none"
        }, 300)
    }

    function handleModalOpen() {
        modalRef.current.style.display = "block"

        setTimeout(function(){
            modalRef.current.classList.add('show')
        }, 10)
    }

    async function fetchSizeChartData() {
        let url = props. baseAPIURL + `question/getSizeChart/v2?gender=${props.sexAnswer.toLowerCase()}`
        let responseData = ''

        const response = await fetch(url, {
          method: 'GET',
        })
          .then(response => response.json())
          .then((data) => {
            responseData = data
          })

        return responseData
    }

    async function handleSelect(selectedOption) {
        if (question.questionType === "Single-Select-Waist") {
            if (selectedOption === "continue") {
                if (waistSizeSetWithChart) {
                    // Input was selected from chart, don't validate
                    if (waistInput !== "") {
                        props.callback(question.questionName, waistInput, props.continueOnSelect);
                    }
                } else {
                   // Input was user provided - validation required 
                    let validationCheck = validateInput(waistInput, props.validation, props.dataType)

                    if (validationCheck.valid) {
                        props.callback(question.questionName, waistInput, props.continueOnSelect);
                        setAlertCustomError('')
                    } else {
                        setAlertCustomError(validationCheck.message)
                        console.warn(validationCheck.report)
                    }
                }
            } else if (selectedOption === responses["response1"]) {
                setAlertCustomError('')
                setShowWaistInput(true)
                setWaistSelection(false)
                setWaistInput('')
                
                handleModalClose()

                // questionResponses = selectedOption;
            } else if (selectedOption === responses["response2"]) {
                setAlertCustomError('')
                setShowWaistInput(false)
                setWaistSelection(true)
                
                handleModalOpen()

                // questionResponses = selectedOption;

                // questionResponses = selectedOption;
                let response = await fetchSizeChartData()

                setWaistChartData(response)

                // let foobar = displayTable(response)
                // console.log(ReactDOMServer.renderToString(foobar))

                // setTableHTMLStr(ReactDOMServer.renderToString(foobar))
            }
        } else {
            if (selectedOption != 'continue') {
                if (question.questionType.includes('Single-Select')) {
                    setSingleResponse(selectedOption)
                    setAlertCustomError('')
                    console.log("selectedOption")
                    console.log(selectedOption)
                } else {
                    let hasClearOption = (question.ClearAll != null || question.ClearAll != 'null')
                    
                    if (hasClearOption && selectedOption == responses[question.ClearAll]) {
                        console.log('check1')
                        getDOMElement('input[type=checkbox]', 'querySelectorAll').forEach( el => el.checked = false );
                        writeQuestionResponses(selectedOption, 'clear');
                    } else {
                        console.log('check2')
                        if (questionResponses.includes(responses[question.ClearAll])) {
                            console.log('check2.2')
                            writeQuestionResponses(questionResponses.indexOf(responses[question.ClearAll]), 'splice');
                            getDOMElement('input[value="' + question.ClearAll + '"]', 'querySelectorAll').forEach( el => el.checked = false );
                        }
                        writeQuestionResponses(selectedOption,'push');
                    }
                }


                
                // setAlertCustomError('')

                // if (question.questionType.includes('Single-Select')) {
                //     questionResponses = selectedOption;
                // } else {
                    

                //     if (hasClearOption && selectedOption == responses[question.ClearAll] && !questionResponses.includes(responses[question.ClearAll])) {
                        
                //         getDOMElement('input[type=checkbox]', 'querySelectorAll').forEach( el => el.checked = false );
                //         questionResponses = [];
                //         questionResponses.push(selectedOption);
                //     } else if (questionResponses.includes(selectedOption)) {
                //         questionResponses.splice(questionResponses.indexOf(selectedOption), 1);
                //     } else {
                //         if (hasClearOption && selectedOption != responses[question.ClearAll] && questionResponses.includes(responses[question.ClearAll])) {
                //             questionResponses.splice(questionResponses.indexOf(responses[question.ClearAll]), 1);
                //             getDOMElement('input[value="' + question.ClearAll + '"]', 'querySelectorAll').forEach( el => el.checked = false );
                //         }

                //         questionResponses.push(selectedOption);
                //     }
                // }
            }

            if ((!props.continueOnSelect && selectedOption == 'continue') || props.continueOnSelect) {
                // Create string for multiselect validation
                let validationFaux = null
                let inputValuesObject = ''
                if (questionResponses != '') {
                    inputValuesObject = JSON.parse(questionResponses)
                }
                if (question.questionType.includes('Single-Select')) {
                    inputValuesObject = singleResponse
                }
                if (inputValuesObject.length == 0) {
                    validationFaux = ''
                } else {
                    if (inputValuesObject[0] != 'continue') {
                        validationFaux = 'This represents value.'
                    } else {
                        validationFaux = ''
                    }
                }

                let validationCheck = validateInput(validationFaux, props.validation, props.dataType)

                if (validationCheck.valid) {
                    if (selectedOption == "continue") {
                        
                        props.callback(question.questionName, inputValuesObject, true)
                        
                    } else {
                        let questionResponsesArr = [];

                        if (question.questionType.includes('Single-Select')) {
                            inputValuesObject = singleResponse
                        }
                        props.callback(question.questionName, inputValuesObject, props.continueOnSelect)

                    }
                    setAlertCustomError('')
                } else {
                    setTimeout(function(){
                        setAlertCustomError(validationCheck.message)
                    },50)
                    console.warn(validationCheck.report)
                }      
            } else if (props.hasCachedElements != null && 
                typeof props.hasCachedElements == 'boolean' &&
                props.hasCachedElementsCallback != null &&
                typeof props.hasCachedElementsCallback == 'function') {
                props.hasCachedElementsCallback();
            }
        }
    }

    function handleSizeSelect(cmStr) {
        handleModalClose()
        setWaistSizeSetWithChart(true)
        setWaistInput(cmStr);
        console.log(cmStr)
    }

    

    const waistInputOnChange = (e) => {
        setWaistInput(e.target.value);
        setAlertCustomError('')
    }
    
    return (
        <div>
            <div className={`component-multi-choice container ${question.questionType === "Single-Select-Waist" ? 'waist-question' : ''} ${Object.keys(responses).length >= collapseValue ? 'condense' : '' }`}>
                {Object.keys(responses).map((key, index) => {
                    return (
                        <div className="multi-choice-row component-form-UI" key={`${qid}_${key}}`} >
                            <input type={`${question.questionType.includes('Single-Select') ? 'radio' : 'checkbox'}`} className="radio_input" id={`control_${qid}_${key}`} name={qid} value={key}/>
                            <label htmlFor={`control_${qid}_${key}`} className="w-100">
                                <Button
                                    classList={`primary mt-2 split`}
                                    innerText={responses[key]}
                                    splitImage={btnImgArray[index]}
                                    onclick={handleSelect}
                                />
                            </label>

                            {question.questionType === "Single-Select-Waist" && showWaistInput && index === 0 && 
                                <input 
                                    className='single-select-waist form-control transparent-background' 
                                    onChange={waistInputOnChange}    
                                    type="text"
                                    placeholder={question.explanationText ?? "Enter waist size in centimetres"}
                                />

                            }

                            {question.questionType === "Single-Select-Waist" && showWaistSelection && index === 1 && waistSizeSetWithChart &&
                                <h3 className="waist-selection">Selected waist size: {waistInput}cm</h3>
                            }
                        </div>
                    )
                })}

                
            </div>

            {alertCustomError.length > 0 &&
                <p className="text text-error">{alertCustomError}</p>
            }

            {question.questionType != "Single-Select-Waist" && (!question.questionType === "Single-Select" || !props.continueOnSelect) &&
                <div style={{marginTop: '32px',display:'block'}}>
                    <Button
                        classList={"primary regular large last-button dynamic-input-button"}
                        innerText={props.question.buttonText}
                        onclick={handleSelect.bind(this, 'continue')}
                        
                    />
                </div>
            }
            {question.questionType === "Single-Select-Waist" &&
                <Button
                    classList={"primary regular large last-button dynamic-input-button"}
                    innerText="Next"
                    onclick={handleSelect.bind(this, 'continue')}

                />
            }

            <div role="dialog" aria-modal="true" className="fade waist-selection modal" tabIndex="-1" ref={modalRef} id="sizechartmodal">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="waist-selection-body modal-body">
                            <WaistTable 
                                response={waistChartData}
                                sex={props.sexAnswer}
                                callback={handleSizeSelect}
                            />
                        </div>
                        <div className="waist-selection-footer modal-footer">
                            <Button
                                classList={"primary regular large last-button dynamic-input-button"}
                                innerText={"Close"}
                                onclick={handleModalClose}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
  
export default MultiChoice;
  