import { useState, useEffect } from "react";

export const useAdaptBaseAPIURL = () => {
  const [hasSetEnvironment, setHasSetEnvironment] = useState(false);
  const [baseAPIURL, setBaseAPIURL] = useState("");
  const [environment, setEnvironment] = useState("");

  // Detect if this is a local, dev, staging, uat, or prod environment
  let hostname = window.location.hostname;
  let href = window.location.href;
  let env = "";
  let baseAPIURLDEV = "https://mh4l-api-dev.simbyte.com/";
  let baseAPIURLUAT = "https://api-uat.myhealthforlife.com.au/";
  let baseAPIURLSTG = "https://api-stg.myhealthforlife.com.au/";
  let baseAPIURLPROD = "https://api.myhealthforlife.com.au/";

  // Env map
  let envURLMap = [
    // local domains below this point - to use dev base API
    ["localhost", "local"],
    ["myhealthforlife.local", "local"],
    ["mh4lvps001.mh4l.local:3306", "local"],
    ["mh4lvps001.mh4l.local", "local"],
    // dev domains below this point
    ["dev-hc.myhealthforlife.org.au", "dev"],
    ["www.dev-web-3.myhealthforlife.org.au", "dev"],
    ["dev-web-2.myhealthforlife.org.au/risk-assessment", "dev"],
    ["mh4lorg_dev-web-2", "dev"],
    ["dev-web-2.myhealthforlife.org.au/userlogin", "dev"],
    ["dev-portal-2.myhealthforlife.org.au", "dev"],
    ["mh4lorg_dev-portal-2", "dev"],
    ["crm.simbyte.com.au/DevMH4L", "dev"],
    ["DevMH4L_MSCRM", "dev"],
    ["103.54.132.97:1433", "dev"],
    ["103.54.132.97", "dev"],
    ["mh4l-api-dev.simbyte.com", "dev"],
    ["dev-learning.myhealthforlife.org.au", "dev"],
    ["mh4lorg_dev-learning", "dev"],
    // uat domains below this point
    ["dev-web-3.myhealthforlife.org.au/risk-assessment", "dev"],
    ["dev-web-3.myhealthforlife.org.au", "dev"],
    ["mh4lorg_dev-web-3", "dev"],
    ["dev-web-3.myhealthforlife.org.au/userlogin", "dev"],
    ["dev-portal-3.myhealthforlife.org.au", "dev"],
    ["mh4lorg_dev-portal-3", "dev"],
    ["mh4l-api-dev.simbyte.com", "dev"],
    ["dev-hc.myhealthforlife.org.au", "dev"],
    ["103.54.132.97:1433", "dev"],
    ["103.54.132.97", "dev"],
    ["uat-hc.myhealthforlife.org.au", "uat"],
    ["uat-web-2.myhealthforlife.org.au/risk-assessment", "uat"],
    ["mh4lorg_uat-web-2", "uat"],
    ["uat-web-2.myhealthforlife.org.au/userlogin", "uat"],
    ["uat-portal-2.myhealthforlife.org.au", "uat"],
    ["crm.myhealthforlife.com.au/STGMH4L", "uat"],
    ["mh4lcrmdb.daq.local:1433", "uat"],
    ["mh4lcrmdb.daq.local", "uat"],
    ["uat-web-3.myhealthforlife.org.au/userlogin", "uat"],
    ["uat-portal-3.myhealthforlife.org.au", "uat"],
    ["crm.myhealthforlife.com.au/UATMH4L", "uat"],
    ["api-uat.myhealthforlife.com.au", "uat"],
    ["login-uat.myhealthforlife.com.au", "uat"],
    ["uat-learning.myhealthforlife.org.au", "uat"],
    ["api-stg.myhealthforlife.com.au", "uat"],
    ["login-stg.myhealthforlife.com.au", "uat"],
    ["uat-web-3.myhealthforlife.org.au/risk-assessment", "uat"],
    // staging domains below this point
    ["www.stg-web.myhealthforlife.org.au", "stg"],
    ["stg-web.myhealthforlife.org.au", "stg"],
    ["stg-hc.myhealthforlife.org.au", "stg"],
    ["stg-learning.myhealthforlife.org.au", "stg"],
    ["stg-portal.myhealthforlife.org.au", "stg"],
    ["www.stg-web.myhealthforlife.org.au", "stg"],
    ["stg-web.myhealthforlife.org.au", "stg"],
    ["uat-web-3.myhealthforlife.org.au", "stg"],
    ["sgt-hc.myhealthforlife.com.au", "stg"],
    ["stg-hc.myhealthforlife.com.au", "stg"],
    ["uat-portal-2.myhealthforlife.org.au", "stg"],
    ["stg-learning.myhealthforlife.org.au", "stg"],
    ["crm.myhealthforlife.com.au", "stg"],
    // prod domains below this point
    ["www.myhealthforlife.com.au", "prod"],
    ["myhealthforlife_production", "prod"],
    ["portal.myhealthforlife.com.au", "prod"],
    ["moodle_prod", "prod"],
    ["crm.myhealthforlife.com.au/PRODMH4L", "prod"],
    ["UATMH4L_MSCRM", "prod"],
    ["mh4lcrmdb.daq.local:1433", "prod"],
    ["mh4lcrmdb.daq.local", "prod"],
    ["api.myhealthforlife.com.au", "prod"],
    ["login.myhealthforlife.com.au", "prod"],
  ];

  // Run only once on page mount
  useEffect(() => {
    if (!hasSetEnvironment) {
      for (let i = 0; i < envURLMap.length; i++) {
        if (envURLMap[i][0].includes("/")) {
          if (href.includes(envURLMap[i][0]) && env === "") {
            env = envURLMap[i][1];
          }
        } else {
          if (hostname === envURLMap[i][0] && env === "") {
            env = envURLMap[i][1];
          }
        }
      }

      if (env === "") {
        env = "prod"; // assume prod env outside of matching case above
      }
      setEnvironment(env); // set the env

      switch (env) {
        case "local":
        case "dev":
          setBaseAPIURL(baseAPIURLDEV);
          console.log("dev ENV - API URL: " + baseAPIURLDEV);
          break;
        case "uat":
          setBaseAPIURL(baseAPIURLUAT);
          console.log("uat ENV - API URL: " + baseAPIURLUAT);
          break;
        case "stg":
          setBaseAPIURL(baseAPIURLSTG);
          console.log("stg ENV - API URL: " + baseAPIURLSTG);
          break;
        case "prod":
          setBaseAPIURL(baseAPIURLPROD);
          console.log("prd ENV - API URL: " + baseAPIURLPROD);
          break;
        default:
          console.warn("Unknown ENV found. ENV - API URL: " + baseAPIURLPROD);
          break;
      }

      console.log("this hook is running");

      setHasSetEnvironment(true);
    }
  }, []);

  return {
    baseAPIURL,
    setBaseAPIURL,
    environment,
    setEnvironment,
  };
};
