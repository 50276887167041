// import 'bootstrap/dist/js/bootstrap.js';
import Image from "./Image";
import RiskFactor from "./RiskFactor";
import ChatMessage from "./ChatMessage";
import Accordian from "./Accordian";
import ReactDOMServer from "react-dom/server";
import ProgramSignUpForm from "./ProgramSignUpForm";
import ResultsOnlyForm from "./ResultsOnlyForm";
import SocialShare from "./SocialShare";
import { getDOMElement } from "../helpers.js";
import React, { useRef, createRef, useEffect, useState } from "react";

function ResultTabs(props) {
  const tabButtonRefs = React.useRef([]);
  const tabPaneRefs = React.useRef([]);
  const toScroll = React.useRef(null);
  tabButtonRefs.current = props.resultSet.tabs?.map(
    (title, index) => tabButtonRefs[index] ?? createRef()
  );
  tabPaneRefs.current = props.resultSet.tabs?.map(
    (title, index) => tabPaneRefs[index] ?? createRef()
  );
  // tabButtonRefs.meta = tabButtonRefs.current
  // tabPaneRefs.meta = tabPaneRefs.current

  let tabCount = -1;

  let sectionHeadingCount = 0;

  function handleTabClick(tabIndex) {
    console.log("tabIndex");
    console.log(tabIndex);
    console.log(tabButtonRefs.current);
    console.log(tabPaneRefs.current);
    // let offsetTop = tabButtonRefs.current[tabCount].current.offsetTop;
    // console.log(offsetTop)

    for (let i = 0; i < tabButtonRefs.current.length; i++) {
      console.log(tabButtonRefs.current[i]);
      tabButtonRefs.current[i].current.ariaSelected = false;
      tabButtonRefs.current[i].current.className =
        "nav-link hc-results-tab-button";

      if (
        tabButtonRefs.current[i].current.id ==
        "hc-nav-home-tab-" + tabIndex
      ) {
        tabButtonRefs.current[i].current.className =
          "nav-link hc-results-tab-button active";
        tabButtonRefs.current[i].current.ariaSelected = true;
      }
    }

    for (let i = 0; i < tabPaneRefs.current.length; i++) {
      tabPaneRefs.current[i].current.className =
        "tab-pane hc-results-tab-pane fade show";
      tabPaneRefs.current[i].current.ariaSelected = false;

      if (tabPaneRefs.current[i].current.id == "hc-nav-home-" + tabIndex) {
        tabPaneRefs.current[i].current.className =
          "tab-pane hc-results-tab-pane fade show active";
        tabPaneRefs.current[i].current.ariaSelected = true;
      }
    }
  }

  function scrollToTab() {
    //to scroll  the main window to the top
    window.scrollTo(0, 0);

    //to scroll the nested scroll to the specific position
    toScroll.current.scrollIntoView({ behavior: "smooth" });
    console.log(toScroll.current);
    //calling the function to target the specific tab
    handleTabClick(1);
  }

  function scrollMove() {
    let scrollTop = getDOMElement("results-box", "id").scrollTop;
    let navScrollTop = getDOMElement("to-scroll", "id").offsetTop;
    // let navScrollTop = toScroll.current.offsetTop;

    if (scrollTop - 20 > navScrollTop) {
      getDOMElement(".nav-tabs-container", "querySelectorAll")[0].classList.add(
        "stick"
      );
    } else {
      getDOMElement(
        ".nav-tabs-container",
        "querySelectorAll"
      )[0].classList.remove("stick");
    }
  }

  getDOMElement("results-box", "id").addEventListener("scroll", scrollMove);

  return (
    <div className="component-result-tab" id="to-scroll" ref={toScroll}>
      <nav className="nav-tabs-container">
        <div className="nav-tabs-outer">
          <div className="nav nav-tabs nav-tabs-inner">
            {props.resultSet.tabs?.map((title, index) => {
              return (
                <button
                  className={`nav-link hc-results-tab-button ${
                    index == 0 ? "active" : ""
                  }`}
                  dangerouslySetInnerHTML={{ __html: title }}
                  id={`hc-nav-home-tab-${index}`}
                  data-bs-toggle="tab"
                  data-bs-target={`#hc-nav-home-${index}`}
                  type="button"
                  role="tab"
                  aria-controls={`hc-nav-home-${index}`}
                  aria-selected={`nav-link ${index == 0 ? "true" : "false"}`}
                  onClick={handleTabClick.bind(this, index)}
                  ref={tabButtonRefs.current[index]}
                ></button>
              );
            })}
          </div>
        </div>
      </nav>
      <div className="tab-content">
        {Object.keys(props.resultSet)?.map((key, indexResultSet) => {
          if (key.includes("tab") && key != "tabs") {
            tabCount++;
            return (
              <div
                key={`hc-nav-home-${tabCount}`}
                className={`tab-pane hc-results-tab-pane fade show ${
                  tabCount == 0 ? "active" : ""
                }`}
                id={`hc-nav-home-${tabCount}`}
                role="tabpanel"
                aria-labelledby={`hc-nav-home-tab-${tabCount}`}
                ref={tabPaneRefs.current[tabCount]}
              >
                {props.resultSet[key]?.map((block, indexTab) => {
                  if (block.blockType == "banner") {
                    return (
                      <div
                        className={`tab-content-upper-section tab-content-padding ${
                          sectionHeadingCount >= 3 && tabCount == 0
                            ? "lower-risk-section"
                            : ""
                        }`}
                      >
                        {Object.keys(block.blockContent).map(
                          (keyBlockContent, indexContent) => {
                            if (keyBlockContent == "heading") {
                              return (
                                <h2
                                  className="heading"
                                  dangerouslySetInnerHTML={{
                                    __html: block.blockContent[keyBlockContent],
                                  }}
                                ></h2>
                              );
                            }
                            if (keyBlockContent == "image") {
                              return (
                                <Image
                                  src={
                                    block.blockContent[keyBlockContent].scr
                                      ? block.blockContent[keyBlockContent].scr
                                      : ""
                                  }
                                  alt={
                                    block.blockContent[keyBlockContent].alt
                                      ? block.blockContent[keyBlockContent].alt
                                      : ""
                                  }
                                ></Image>
                              );
                            }
                            if (keyBlockContent == "text") {
                              return (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: block.blockContent[keyBlockContent],
                                  }}
                                ></p>
                              );
                            }
                          }
                        )}
                      </div>
                    );
                  }

                  if (block.blockType == "container") {
                    if (!Array.isArray(block.blockContent)) {
                      // console.log('container is object')
                      return (
                        <div
                          className={`tab-content-padding container ${
                            sectionHeadingCount >= 3 && tabCount == 0
                              ? "lower-risk-section"
                              : ""
                          }`}
                        >
                          {Object.keys(block.blockContent).map(
                            (keyBlockContent, indexContent) => {
                              if (keyBlockContent == "heading") {
                                return (
                                  <h2
                                    className="heading"
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        block.blockContent[keyBlockContent],
                                    }}
                                  ></h2>
                                );
                              }
                              if (keyBlockContent == "image") {
                                return (
                                  <Image
                                    src={
                                      block.blockContent[keyBlockContent].scr
                                        ? block.blockContent[keyBlockContent]
                                            .scr
                                        : ""
                                    }
                                    alt={
                                      block.blockContent[keyBlockContent].alt
                                        ? block.blockContent[keyBlockContent]
                                            .alt
                                        : ""
                                    }
                                  ></Image>
                                );
                              }
                              if (keyBlockContent == "text") {
                                return (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        block.blockContent[keyBlockContent],
                                    }}
                                  ></p>
                                );
                              }
                              if (keyBlockContent == "link") {
                                return (
                                  <p className="padding-bottom-32 m-0 container-link">
                                    <a
                                      href={
                                        block.blockContent[keyBlockContent].url
                                      }
                                      alt={
                                        block.blockContent[keyBlockContent].alt
                                      }
                                      target="_blank"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          block.blockContent[keyBlockContent]
                                            .title,
                                      }}
                                    ></a>
                                  </p>
                                );
                              }
                            }
                          )}
                        </div>
                      );
                    } else if (Array.isArray(block.blockContent)) {
                      // console.log('container is array')
                      return (
                        <div
                          className={`tab-content-padding container ${
                            sectionHeadingCount >= 3 && tabCount == 0
                              ? "lower-risk-section"
                              : ""
                          }`}
                        >
                          {block.blockContent.map(
                            (blockContentObj, indexBlockContent) => {
                              let components = [];
                              {
                                Object.keys(blockContentObj).map(
                                  (keyBlockContent, indexContent) => {
                                    if (keyBlockContent == "heading") {
                                      components.push(
                                        <h2
                                          className="heading"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              blockContentObj[keyBlockContent],
                                          }}
                                        ></h2>
                                      );
                                    }
                                    if (keyBlockContent == "image") {
                                      components.push(
                                        <Image
                                          src={
                                            blockContentObj[keyBlockContent].scr
                                              ? blockContentObj[keyBlockContent]
                                                  .scr
                                              : ""
                                          }
                                          alt={
                                            blockContentObj[keyBlockContent].alt
                                              ? blockContentObj[keyBlockContent]
                                                  .alt
                                              : ""
                                          }
                                        ></Image>
                                      );
                                    }
                                    if (keyBlockContent == "link") {
                                      components.push(
                                        <p className="padding-bottom-32 m-0 container-link">
                                          <a
                                            href={
                                              blockContentObj[keyBlockContent]
                                                .url
                                            }
                                            alt={
                                              blockContentObj[keyBlockContent]
                                                .alt
                                            }
                                            target="_blank"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                blockContentObj[keyBlockContent]
                                                  .title,
                                            }}
                                          ></a>
                                        </p>
                                      );
                                    }
                                    if (keyBlockContent == "text") {
                                      components.push(
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              blockContentObj[keyBlockContent],
                                          }}
                                        ></p>
                                      );
                                    }
                                  }
                                );
                              }

                              // Return components array
                              return <>{components}</>;
                            }
                          )}
                        </div>
                      );
                    }
                  }

                  if (block.blockType == "section_heading") {
                    sectionHeadingCount++;
                    return (
                      <div
                        className={`tab-content-padding section-heading ${
                          sectionHeadingCount >= 3 && tabCount == 0
                            ? "lower-risk-section"
                            : ""
                        }`}
                      >
                        {Object.keys(block.blockContent).map(
                          (keyBlockContent, indexContent) => {
                            if (keyBlockContent == "heading") {
                              return (
                                <h2
                                  className="risk-heading"
                                  dangerouslySetInnerHTML={{
                                    __html: block.blockContent[keyBlockContent],
                                  }}
                                ></h2>
                              );
                            }
                            if (keyBlockContent == "image") {
                              return (
                                <Image
                                  src={
                                    block.blockContent[keyBlockContent].scr
                                      ? block.blockContent[keyBlockContent].scr
                                      : ""
                                  }
                                  alt={
                                    block.blockContent[keyBlockContent].alt
                                      ? block.blockContent[keyBlockContent].alt
                                      : ""
                                  }
                                ></Image>
                              );
                            }
                            if (keyBlockContent == "text") {
                              return (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: block.blockContent[keyBlockContent],
                                  }}
                                ></p>
                              );
                            }
                          }
                        )}
                      </div>
                    );
                  }

                  if (block.blockType == "accordion") {
                    if (!Array.isArray(block.blockContent)) {
                      return (
                        <div
                          className={`tab-content-padding accordion ${
                            sectionHeadingCount >= 3 && tabCount == 0
                              ? "lower-risk-section"
                              : ""
                          }`}
                        >
                          <Accordian
                            bgClass={`${
                              sectionHeadingCount >= 3 && tabCount == 0
                                ? "bg-primary-5"
                                : "bg-primary"
                            }`}
                            heading={block?.title}
                            renderExpanded={
                              sectionHeadingCount == 1 || tabCount == 0
                                ? false
                                : true
                            }
                            key={indexTab}
                          >
                            {Object.keys(block.blockContent).map(
                              (keyBlockContent, indexContent) => {
                                if (keyBlockContent == "heading") {
                                  return (
                                    <h2
                                      className="heading"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          block.blockContent[keyBlockContent],
                                      }}
                                    ></h2>
                                  );
                                }
                                if (keyBlockContent == "image") {
                                  return (
                                    <Image
                                      src={
                                        block.blockContent[keyBlockContent].scr
                                          ? block.blockContent[keyBlockContent]
                                              .scr
                                          : ""
                                      }
                                      alt={
                                        block.blockContent[keyBlockContent].alt
                                          ? block.blockContent[keyBlockContent]
                                              .alt
                                          : ""
                                      }
                                    ></Image>
                                  );
                                }
                                if (keyBlockContent == "text") {
                                  return (
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          block.blockContent[keyBlockContent],
                                      }}
                                    ></p>
                                  );
                                }
                                if (keyBlockContent == "link") {
                                  return (
                                    <p className="padding-bottom-32 m-0 container-link">
                                      <a
                                        href={
                                          block.blockContent[keyBlockContent]
                                            .url
                                        }
                                        alt={
                                          block.blockContent[keyBlockContent]
                                            .alt
                                        }
                                        target="_blank"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            block.blockContent[keyBlockContent]
                                              .title,
                                        }}
                                      ></a>
                                    </p>
                                  );
                                }
                                if (keyBlockContent == "linear-chart") {
                                  return (
                                    <RiskFactor
                                      // data={{ ...block.blockContent[keyBlockContent] }}
                                      riskScore={
                                        block.blockContent[keyBlockContent]
                                          .riskScore
                                      }
                                      bands={
                                        block.blockContent[keyBlockContent]
                                          .bands
                                      }
                                      tagStart={
                                        block.blockContent[keyBlockContent]
                                          .tagStart
                                      }
                                      tagEnd={
                                        block.blockContent[keyBlockContent]
                                          .tagEnd
                                      }
                                      type={`Title goes here`}
                                      key={indexContent}
                                    ></RiskFactor>
                                  );
                                }
                                if (keyBlockContent == "conversation") {
                                  return (
                                    <ChatMessage
                                      isMicroMessage="true"
                                      avatarFillClass="fill-primary-3"
                                      bgClass="bg-white"
                                      text={block.blockContent[keyBlockContent]}
                                      classList="padding-bottom-32"
                                    />
                                  );
                                }
                              }
                            )}
                          </Accordian>
                        </div>
                      );
                    } else if (Array.isArray(block.blockContent)) {
                      return (
                        <div
                          className={`tab-content-padding accordion ${
                            sectionHeadingCount >= 3 &&
                            tabCount == 0 &&
                            tabCount == 0
                              ? "lower-risk-section"
                              : ""
                          }`}
                        >
                          <Accordian
                            bgClass={`${
                              sectionHeadingCount >= 3 &&
                              tabCount == 0 &&
                              tabCount == 0
                                ? "bg-primary-5"
                                : "bg-primary"
                            }`}
                            // bgClass={'bg-primary-5'} // used for the blue accordians
                            heading={block?.title}
                            renderExpanded={
                              sectionHeadingCount == 1 || tabCount == 0
                                ? false
                                : true
                            }
                            key={indexTab}
                          >
                            {block.blockContent.map(
                              (blockContentObj, indexBlockContent) => {
                                let components = [];
                                {
                                  Object.keys(blockContentObj).map(
                                    (keyBlockContent, indexContent) => {
                                      if (keyBlockContent == "heading") {
                                        components.push(
                                          <h2
                                            className="heading"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                blockContentObj[
                                                  keyBlockContent
                                                ],
                                            }}
                                          ></h2>
                                        );
                                      }
                                      if (keyBlockContent == "image") {
                                        components.push(
                                          <Image
                                            src={
                                              blockContentObj[keyBlockContent]
                                                .scr
                                                ? blockContentObj[
                                                    keyBlockContent
                                                  ].scr
                                                : ""
                                            }
                                            alt={
                                              blockContentObj[keyBlockContent]
                                                .alt
                                                ? blockContentObj[
                                                    keyBlockContent
                                                  ].alt
                                                : ""
                                            }
                                          ></Image>
                                        );
                                      }
                                      if (keyBlockContent == "text") {
                                        components.push(
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                blockContentObj[
                                                  keyBlockContent
                                                ],
                                            }}
                                          ></p>
                                        );
                                      }
                                      if (keyBlockContent == "link") {
                                        components.push(
                                          <p className="padding-bottom-32 m-0 container-link">
                                            <a
                                              href={
                                                blockContentObj[keyBlockContent]
                                                  .url
                                              }
                                              alt={
                                                blockContentObj[keyBlockContent]
                                                  .alt
                                              }
                                              target="_blank"
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  blockContentObj[
                                                    keyBlockContent
                                                  ].title,
                                              }}
                                            ></a>
                                          </p>
                                        );
                                      }
                                      if (keyBlockContent == "linear-chart") {
                                        components.push(
                                          <RiskFactor
                                            // data={{ ...blockContentObj[keyBlockContent] }}
                                            riskScore={
                                              blockContentObj[keyBlockContent]
                                                .riskScore
                                            }
                                            bands={
                                              blockContentObj[keyBlockContent]
                                                .bands
                                            }
                                            tagStart={
                                              blockContentObj[keyBlockContent]
                                                .tagStart
                                            }
                                            tagEnd={
                                              blockContentObj[keyBlockContent]
                                                .tagEnd
                                            }
                                            type={`Title goes here`}
                                            key={indexContent}
                                          ></RiskFactor>
                                        );
                                      }
                                      if (keyBlockContent == "conversation") {
                                        components.push(
                                          <ChatMessage
                                            isMicroMessage="true"
                                            avatarFillClass="fill-primary-3"
                                            bgClass="bg-white"
                                            text={
                                              blockContentObj[keyBlockContent]
                                            }
                                            classList="padding-bottom-32"
                                          />
                                        );
                                      }
                                    }
                                  );
                                }

                                // Return components array
                                return <>{components}</>;
                              }
                            )}
                          </Accordian>
                        </div>
                      );
                    }
                  }

                  if (block.blockType == "contact-form") {
                    console.log(props.result);
                    if (props.result.eligibility == "Yes") {
                      return (
                        <>
                          <div className="tab-content-padding">
                            <Accordian
                              bgClass={"bg-primary"}
                              heading={"I'd like to join the program"}
                              renderExpanded={true}
                            >
                              {(() => {
                                let riskLevel = "";
                                if (typeof props.result.resultSet.riskLevel) {
                                  riskLevel =
                                    props.result.resultSet.riskLevel.toLowerCase();
                                }

                                if (riskLevel == "high") {
                                  return (
                                    <p>
                                      Great news! Enter your details below and a
                                      team member will call you to discuss the
                                      program. You'll also receive a copy of
                                      your results in your inbox.
                                    </p>
                                  );
                                }
                                if (riskLevel == "mod") {
                                  return (
                                    <p>
                                      Great news!  Enter your details below to
                                      create an online account. You'll also
                                      receive a copy of your results in your
                                      inbox.
                                    </p>
                                  );
                                }
                              })()}

                              <ProgramSignUpForm
                                handleSubmit={props.formSubmitCallback}
                                userFirstName={props.userFirstName}
                                userEmail={props.userEmail}
                                userPhone={props.userPhone}
                                loggedInUserData={props.loggedInUserData}
                                riskLevelProp={props.result.resultSet.riskLevel}
                              />
                            </Accordian>
                          </div>
                          <div className="tab-content-padding">
                            <Accordian
                              bgClass={"bg-primary"}
                              heading={
                                "I’d just like to receive my results only"
                              }
                              renderExpanded={false}
                            >
                              {(() => {
                                let riskLevel = "";
                                if (typeof props.result.resultSet.riskLevel) {
                                  riskLevel =
                                    props.result.resultSet.riskLevel.toLowerCase();
                                }
                                if (riskLevel == "high") {
                                  return (
                                    <p>
                                      Enter your details below and you’ll
                                      receive a copy of your results in your
                                      inbox.
                                    </p>
                                  );
                                }
                                if (riskLevel == "mod") {
                                  return (
                                    <p>
                                      Enter your details below and you’ll
                                      receive a copy of your results in your
                                      inbox.
                                    </p>
                                  );
                                }
                              })()}
                              <ResultsOnlyForm
                                handleSubmit={props.formSubmitCallback}
                                userFirstName={props.userFirstName}
                                userEmail={props.userEmail}
                                userPhone={props.userPhone}
                                loggedInUserData={props.loggedInUserData}
                                riskLevelProp={props.result.resultSet.riskLevel}
                              />
                            </Accordian>
                          </div>
                        </>
                      );
                    } else if (props.result.eligibility == "No") {
                      return (
                        <div className="tab-content-padding" style={{}}>
                          <Accordian
                            bgClass={"bg-primary"}
                            heading={"I’d just like to receive my results only"}
                            renderExpanded={true}
                          >
                            <ResultsOnlyForm
                              handleSubmit={props.formSubmitCallback}
                              userFirstName={props.userFirstName}
                              userEmail={props.userEmail}
                              userPhone={props.userPhone}
                              loggedInUserData={props.loggedInUserData}
                              riskLevelProp={props.result.resultSet.riskLevel}
                            />
                          </Accordian>
                        </div>
                      );
                    }
                  }

                  if (block.blockType == "") {
                    return <div className=""></div>;
                  }

                  if (block.blockType == "") {
                    return <div className=""></div>;
                  }
                })}

                {tabCount == 0 && (
                  // <SocialShare

                  //     reference = "toScroll"
                  //     suggestedLink="#to-scroll"
                  //     suggestedLinkText="VIEW SUGGESTED PROGRAM FOR YOU"
                  // />
                  <div className="suggested-link">
                    <a
                      onClick={() => scrollToTab()}
                      // href={props.suggestedLink}
                    >
                      VIEW SUGGESTED PROGRAM FOR YOU
                    </a>
                  </div>
                )}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
}

export default ResultTabs;
