import Button from './Button.js';
import React, { useState, useEffect } from "react";
import { validateInput } from '../utils/input-validation.js';

function DynamicInput(props) {
    const [inputValue, setInputValue] = useState(props.defaultValue)
    const [displayButton, setDisplayButton] = useState(false)
    const [alertError, setAlertError] = useState(false)
    const [alertEmpty, setAlertEmpty] = useState(false)
    const [alertSuccess, setAlertSuccess] = useState(false)
    const [alertNeutral, setAlertNeutral] = useState(false)
    const [alertCustomError, setAlertCustomError] = useState('')
    const [requestLoading, setRequestLoading] = useState(false)
    const [workplaceInvalidPrompt, setWorkplaceInvalidPrompt] = useState(false)
    const [keystrokeTimeout, setKeystrokeTimeout] = useState(null)
    const [workplaceCodeValid, setWorkplaceCodeValid] = useState(false)
    const [isWorkplace, setIsWorkPlace] = useState(props.slug === "workplace")
    const [environment, setEnvironment] = useState((props.environment != '' && typeof props.environment != 'undefined') ? props.environment : 'prod')
    let authBasicMap = {
        local: 'Basic bWg0bF9kZXZlbG9wZXI6c1ByMW4zREB5MyE=',
        dev: 'Basic bWg0bF9kZXZlbG9wZXI6c1ByMW4zREB5MyE=',
        uat: 'Basic bWg0bF9kZXZlbG9wZXI6VGgxNWk1VUBU',
        stg: 'Basic bWg0bGNybTpCZWR1Z2V5YWotMjY=',
        prod: 'Basic bWg0bF9kZXZlbG9wZXI6VGgzbTRMTERlNGQhIQ=='
    }
    let timeout = 1000
    let timeoutFunc = null
    let init = false

    // valid code example:
    // ABCDES009
    // UC0H2U - dev
    // D7H3DB - prod

    useEffect(() => {
        if (!isWorkplace) {
            setDisplayButton(true)
        }
    },[isWorkplace])

    async function validateWorkplace() {
        if (inputValue.trim() == '') {
            setAlertEmpty(true);
        } else {
            setAlertCustomError('')
            setAlertEmpty(false)
            setAlertError(false);
            setAlertSuccess(false);
            // setRequestLoading(true);
            let response = await isCodeValid(inputValue);
            // setRequestLoading(false);

            if (response.status == "Invalid") {
                setAlertError(true);
                setAlertSuccess(false);
                setWorkplaceCodeValid(false)
            } else if (response.status == "Valid") {
                setAlertError(false);
                setAlertSuccess(true);
                setDisplayButton(true);
                setWorkplaceCodeValid(true)
            }
        }
    }

    async function handleKeyPress(event) {
        setAlertCustomError('')
        setWorkplaceCodeValid(false)
        setAlertEmpty(false)
        setAlertError(false)
        setAlertSuccess(false)
        setInputValue(event.target.value)
        setWorkplaceInvalidPrompt(false)
        if (isWorkplace) {
            setDisplayButton(false)
        }
    }

    async function isCodeValid(codeStr) {
        let formData = new FormData()
        formData.append('workplace',codeStr)
        setRequestLoading(true)

        let params = props.params
        if (params.length > 0) {
            if (params.charAt(0) == "&") {
                params = '?' + params.substring(1)
            }
        }

        const response = await fetch(props.apiBase + 'referral/verifyWorkplaceCode' + params, {
            method: 'POST',
            headers: new Headers({
                "Authorization": authBasicMap[environment],
                "Client-Service": `simbyte-mh4l-api-v1`,
            }),
            // body: JSON.stringify({
            //     workplacecode: codeStr,
            // })
            body: formData
        })
        .then(response => response.json())
        .then((data) => {
            setRequestLoading(false)
            return data
        })
        return response
    }

    function buttonClick() {
        if (isWorkplace) {
            if (workplaceCodeValid) {
                // Workplace code VALID - save and continue
                props.callback(props.slug, inputValue)
            } else {
                // Workplace code INVALID - display message
                setWorkplaceInvalidPrompt(true)
            }
        } else {
            let validationCheck = validateInput(inputValue, props.validation, props.dataType)

            if (validationCheck.stop == true) {
                props.callback(props.slug, inputValue, false)
                props.stopCallback(validationCheck.message)
            } else {
                if (validationCheck.valid) {
                    props.callback(props.slug, inputValue)
                    setAlertCustomError('')
                } else {
                    setAlertCustomError(validationCheck.message)
                    console.warn(validationCheck.report)
                }
            }
        }
    }

    return (
        <div className={`component-dynamic-input component-form-UI ${props.classList}`}>
            <input 
                className="form-control to-input transparent-background"
                type={props.inputType}
                value={inputValue}
                placeholder={props.inputPlaceholder}
                onChange={handleKeyPress.bind(this)}
                disabled={requestLoading}
            />
            {requestLoading &&
                <p className="text text-loading">Validating your workplace code <span className="loading"></span></p>
            }
            {alertCustomError.length > 0 &&
                <p className="text text-error">{alertCustomError}</p>
            }
            {alertError &&
                <p className="text text-error">Workplace code invalid</p>
            }
            {alertEmpty &&
                <p className="text text-error">Enter a valid workplace code</p>
            }
            {alertSuccess &&
                <p className="text text-success">Workplace code valid</p>
            }
            {isWorkplace && 
                <Button
                    classList={`primary regular large last-button dynamic-input-button ${!displayButton ? '' : ' hide'} ${requestLoading ? 'disabled' : ''}`}
                    innerText={'Click to validate'}
                    onclick={validateWorkplace}
                    disabled={requestLoading}
                />
            }
            {/*{alertNeutral == true &&
                <p className="text text-error">Validating your workplace code <span className="loading"></span></p>
            }*/}
            <Button
                classList={`primary regular large last-button dynamic-input-button ${displayButton ? '' : ' hide'} ${requestLoading ? 'disabled' : ''}`}
                innerText={props.buttonText}
                onclick={buttonClick.bind(this)}
            />
            {workplaceInvalidPrompt &&
                <p className="text text-error">You must enter a valid workplace code</p>
            }
        </div>
    );
  }
  
  export default DynamicInput;