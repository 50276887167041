import React, { useEffect, useRef, useState } from "react";
import ReactDOM, { createRoot } from "react-dom/client";

import { postcodeArr } from "../data/postcodeArr.js";
import Animation from "./Animation.js";
import BloodPressure from "./BloodPressure.js";
import Button from "./Button.js";
import CVDScore from "./CVDScore.js";
import ChatAvatar from "./ChatAvatar.js";
import ChatMessage from "./ChatMessage.js";
import DynamicInput from "./DynamicInput.js";
import DynamicYesNo from "./DynamicYesNo.js";
import DynamicYesNoSkip from './DynamicYesNoSkip.js';
import Header from "./Header.js";
import Heading from "./Heading.js";
import Image from "./Image.js";
import MultiChoice from "./MultiChoice";
import MultiQuestion from "./MultiQuestion";
import Slider from "./Slider.js";
import Text from "./Text.js";
import WelcomeContent from "./WelcomeContent.js";
import YesNo from "./YesNo.js";
import svgs from "./svgs.js";
import Footer from "./Footer.js";
import ResultsScreen from "./ResultsScreen.js";

import { addClassToClassList } from "../helpers/functions.js";
import AutocompleteInput from "./AutocompleteInput.js";

// XML Parser
import { XMLBuilder, XMLParser } from "fast-xml-parser";
import { getDOMElement } from "../helpers.js";

// Hooks
import { useAdaptBaseAPIURL } from "../hooks/useAdaptBaseAPIURL.js";
import { useRenderReferralBanners } from "../hooks/useRenderBanners.js";

function HealthCheck(props) {
  const [questionSet, setQuestionSet] = useState({});
  const [questionSetLoaded, setQuestionSetLoaded] = useState(false);
  const [sessionCode, setSessionCode] = useState("");
  const [questionSetID, setQuestionSetID] = useState("");
  const [questionIndexFlow, setQuestionIndexFlow] = useState([]);

  const [width, setWidth] = useState(window.innerWidth);
  const [desktopSidecartContent, setDesktopSidecartContent] = useState(
    getDesktopSidecartContent()
  );
  const [desktopViewClassList, setDesktopViewClassList] = useState(
    "partial-desktop-sidebar intro"
  );
  // const desktopViewClassListRef = useRef(desktopViewClassList);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [stateButtonText, setStartButtonText] = useState("Getting ready . . .");

  const [resultsVisibility, setResultsVisibility] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [headerState, setHeaderState] = useState("initial");

  const [saveUserDetailsID, setSaveUserDetailsID] = useState("");

  const [loggedInUserData, setLoggedInUserData] = useState({});
  const [userFirstName, setUserFirstName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [postCodeList, setPostCodeList] = useState(postcodeArr);

  const [overalResultSet, setOveralResultSet] = useState({});
  const [overalResult, setOveralResult] = useState({});

  let loadingQuestionSet = false;
  let sectionIndex = 0;
  let questionIndex = 0;
  let apiSaveResponsesID = "";
  const transitionInitTimeMilliseconds = 1200;

  const cachedElementsRef = useRef([]);
  const questionResponses = useRef([]);

  // Hooks for managing states
  const { baseAPIURL, environment } = useAdaptBaseAPIURL();
  const { displayLogoBannerAndFooter, setReferralHeader } =
    useRenderReferralBanners();

  const queryParams = new URLSearchParams(window.location.search);

  function getParamsAsStr(firstParams = true, question = null, index = null) {
    const queryParams = new URLSearchParams(window.location.search);

    // Collect params
    let params = "";
    queryParams.forEach(function (value, key) {
      if (params == "") {
        if (firstParams) {
          params = params + "?"; // preface the first param
        } else {
          params = params + "&"; // preface the first param from queryParams
        }
      } else {
        params = params + "&"; // delimit the 2nd to n params
      }
      params = params + key + "=" + value; // append param key and value
    });

    // Append debugging vars
    if (environment !== "prod" && question != null && index != null) {
      if (params === "") {
        if (firstParams) {
          params = params + "?"; // preface the first param
        } else {
          params = params + "&"; // preface the first param from queryParams
        }
      } else {
        params = params + "&"; // delimit the 2nd to n params
      }

      let now = new Date();
      let utc_timestamp =
        now.getUTCFullYear() +
        now.getUTCMonth() +
        now.getUTCDate() +
        now.getUTCHours() +
        now.getUTCMinutes() +
        now.getUTCSeconds() +
        now.getUTCMilliseconds();

      params = params + "question_slug=" + question.questionName;
      params = params + "&question_index=" + index;
      params = params + "&timestamp=" + now.toTimeString().replaceAll(" ", "-");
    }

    return params;
  }

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
    setIsMobile(width <= 1040);
  }

  async function fetchQuestionSet() {
    setStartButtonText("Loading . . .");

    let paramsStr = getParamsAsStr(true);
    let fetchURL =
      baseAPIURL +
      "question/getQuestions/F0CC4335-4F20-4D40-9953-DD8A9AA6783B/v2" +
      paramsStr;

    await fetch(fetchURL)
      .then((response) => response.json())
      .then((data) => {
        console.log("Questions set", data.questions);

        // Store data mapping
        setQuestionSet(data.questions);
        setQuestionSetID(data.questionsetId);

        if (
          typeof data.header != "undefined" &&
          typeof data.header == "object"
        ) {
          if (data.header != null) {
            setReferralHeader(data.header);
          }
        }
      })
      .then(() => {
        setQuestionSetLoaded(true);
      });

    setStartButtonText("All loaded!");

    setTimeout(() => setStartButtonText("Great. Let's get started"), 2000);
  }

  useEffect(() => {
    // Init vars
    setIsMobile(width <= 1040);

    // Init events
    window.addEventListener("resize", handleWindowSizeChange);
    // adaptBaseAPIURL();
  }, []);

  // Detecting change on baseAPIURL var
  useEffect(() => {
    // Core fetching functions following base URL determination
    // fetchPostcodeListFromAPI()

    // Fetch question set
    if (!loadingQuestionSet) {
      loadingQuestionSet = true;

      if (
        typeof questionSet === "object" &&
        Object.keys(questionSet).length === 0
      ) {
        fetchQuestionSet().then(() => {});
      }
    }
  }, [baseAPIURL]);

  function startHealthCheck() {
    if (!questionSetLoaded) {
      return;
    }

    let startScreenContainer = getDOMElement(
      "container-primary",
      "className"
    )[0];
    if (startScreenContainer.classList.contains("scrollable")) {
      startScreenContainer.classList.remove("scrollable");
    }

    setDesktopViewClassList(
      addClassToClassList(desktopViewClassList, "half-width")
    );

    setTimeout(
      () =>
        setDesktopSidecartContent(
          `<h2>${questionSet[0].questionSection ?? "Welcome"}</h2>`
        ),
      transitionInitTimeMilliseconds / 4
    );
    setHeaderState("inProgress");
    nextQuestion();
  }

  function nextQuestion() {
    let isLastQuestionOfQuiz = questionIndex === questionSet.length - 1;

    if (isLastQuestionOfQuiz) {
      showResultsScreen();
    } else {
      questionIndex = getNextValidQuestionIndex();
      console.log("Question index is: ", questionIndex);
      let arr = [questionIndex];

      setQuestionIndexFlow((questionIndexFlow) => [
        ...questionIndexFlow,
        ...arr,
      ]);

      let percentage = Math.floor(
        ((questionIndex + 1) / questionSet.length) * 100
      );
      setProgressPercentage(percentage);

      displayQuestion(questionIndex);
    }
  }

  function previousQuestion() {
    //Reset cached elements from current question (incase there are any for the previous)
    cachedElementsRef.current = [];

    // Get previous index (current is last element in array so -2) and save new state
    let previousIndex = questionIndexFlow[questionIndexFlow.length - 2];
    let newArr = questionIndexFlow.filter(
      (i) => i !== questionIndexFlow[questionIndexFlow.length - 1]
    );
    setQuestionIndexFlow(newArr);

    let percentage = Math.floor(
      ((previousIndex + 1) / questionSet.length) * 100
    );
    setProgressPercentage(percentage);

    displayQuestion(previousIndex);
  }

  function isFinalQuestion() {
    if (questionIndex >= questionSet.length - 1) {
      return true;
    } else if (questionIndex < questionSet.length - 1) {
      return false;
    }
  }

  function showResultsScreen() {
    let resultsVisible = !resultsVisibility;

    setResultsVisibility(resultsVisible);

    if (resultsVisible) {
      setHeaderState("results");
    } else {
      setHeaderState("inProgress");
    }
  }

  function getNextValidQuestionIndex() {
    let initialQuestionIndex = questionIndex;
    let newQuestionIndex;

    if (questionIndexFlow.length > 1) {
      if (
        questionIndexFlow[questionIndexFlow.length - 2] + 1 <
        questionIndexFlow[questionIndexFlow.length - 1]
      ) {
        newQuestionIndex = questionIndexFlow[questionIndexFlow.length - 2] + 1;
      }
    }

    if (typeof newQuestionIndex == "undefined") {
      questionIndex =
        questionIndex === 0 && questionIndexFlow.length > 0
          ? questionIndexFlow[questionIndexFlow.length - 1]
          : questionIndex;
      newQuestionIndex =
        initialQuestionIndex === 0 && questionIndexFlow.length > 0
          ? questionIndex
          : questionIndex + 1; // get next question index
    }

    // Look up next question conditions, if valid
    if (typeof questionSet[newQuestionIndex] == "undefined") {
      return newQuestionIndex;
    }

    // Store condition in tidy variable
    let condition = questionSet[newQuestionIndex].condition;

    // Check count of "|" delimiter with split
    if (
      !!condition &&
      condition.includes("|") &&
      condition.split("|").length === 2
    ) {
      let explodedConditionStr = condition.split("|");

      // Check for externalonly and return
      if (
        explodedConditionStr[0].trim() === "{{EXTERNALONLY}}" ||
        explodedConditionStr[0].trim() === '"{{EXTERNALONLY}"}'
      ) {
        return queryParams.get("userid") == null
          ? newQuestionIndex
          : newQuestionIndex++;
      }

      // Remove any and all " characters
      condition = explodedConditionStr[0].replaceAll('"', "");

      // Encapsulate all response meta keys in quotations
      let numberCap = 32;
      for (let i = 0; i <= numberCap; i++) {
        let searchStr = "response" + i;
        let replacementStr = '"response' + i + '"';
        condition = condition.replaceAll(searchStr, replacementStr);
      }

      condition = condition.replaceAll("NULL", '"NULL"');

      // Replace all {{tags}} with response meta key if presence, else replace all {{tags}} with response value.
      for (let i = 0; i < questionResponses.current.length; i++) {
        let searchStr = "{{" + questionResponses.current[i].slug + "}}"; // compose the slug to search for
        let replacementStr = questionResponses.current[i].answer; // set the answer as the replacment str
        if (questionResponses.current[i].answerMeta != "") {
          // check if the meta isn't an empty string (indicates non-multiselect)
          replacementStr = questionResponses.current[i].answerMeta; // update the replacement str
        }

        // If value is a poential int, print out as is, else encapsulate in quotations
        if (!isNaN(parseInt(replacementStr))) {
          // check if it's a number
          // is a number, leave replacement str as is
        } else {
          // not a number, encapsulate in quotations
          replacementStr = '"' + replacementStr + '"';
        }

        condition = condition.replaceAll(searchStr, replacementStr);
      }

      if (
        questionSet[newQuestionIndex].questionType === "Single-Select-Waist"
      ) {
        // This is a waist input question
        // This type of question should only be asked once
        // It could be one of a series of question on waist size, i.e. this could be the 2nd or 3rd time this question is asked
        // Assumption - there will only be one waist input question for each gender
        // Get previously stored reponse for gender and force the display of only the correct waist input
        // Assumption - input from sex question can be appended to the str "waist_size_" to get the slug for the waist size
        // Original solution was to look for waist inputs recorded - this doesn't allow for if a user navigates back as a waist answer would already be recorded, skipping all waist inputs

        // Initialise quesiton index modifier as 1
        let indexModifier = 1;

        // Create

        // get previously recorded responses for "waist_size_male", "waist_size_female", and "waist_size_other"
        let sexAnswer = getAnswerForSlug("sex");
        sexAnswer = sexAnswer.trim(); // trim it
        sexAnswer = sexAnswer.toLowerCase();

        // Check if response has been stored
        if (
          questionSet[newQuestionIndex].questionName ===
          "waist_size_" + sexAnswer
        ) {
          // Current waist input matches sex provided
          return newQuestionIndex;
        } else {
          // Current waist input doesn't match sex provided - find next valid waist input or next question type
          let nextQuestionInvalid = true;
          while (nextQuestionInvalid) {
            if (
              questionSet[questionIndex + indexModifier].questionType ===
              "Single-Select-Waist"
            ) {
              // Another waist question - check if the slug is valid
              if (
                questionSet[questionIndex + indexModifier].questionName ===
                "waist_size_" + sexAnswer
              ) {
                return questionIndex + indexModifier; // Current waist input matches sex provided - return this index
              } else {
                indexModifier++; // Increment index Modifier
              }
            } else {
              // Different question found - terminate loop
              nextQuestionInvalid = false;
            }
          }

          // Return questionIndex with new modifier
          return questionIndex + indexModifier;
        }
      }

      // Evaluate the condition
      let result = false;
      try {
        result = eval(condition);
      } catch (err) {
        console.error(
          "Question condition error: expression couldn't be evaluated. \nCondition: \"" +
            condition +
            '"'
        );
        return newQuestionIndex;
      }

      if (typeof result == "boolean") {
        // Do nothing
      } else {
        console.error(
          "Question condition error: expression didn't evaluate to a true/false. \nCondition: \"" +
            condition +
            '"'
        );
        return newQuestionIndex;
      }

      // Tidy the action string
      let tidyConditionActionStr = tidyConditionalString(
        explodedConditionStr[1]
      );

      if (result) {
        switch (tidyConditionActionStr) {
          case "HIDE":
            newQuestionIndex = newQuestionIndex + 1;
            break; // get next question index
          case "SHOW":
          default:
            break;
        }
      } else {
        switch (tidyConditionActionStr) {
          case "SHOW":
            newQuestionIndex = newQuestionIndex + 1;
            break; // get next question index
          case "HIDE":
          default:
            break;
        }
      }
    } else {
      // Too many "|" key delimiters added - error out
      console.error(
        'Error in question condition: Question can only contain one "|" delimiter.'
      );
    }

    return newQuestionIndex;
  }

  function tidyConditionalString(string) {
    string = string.replaceAll("{", "");
    string = string.replaceAll("}", ""); //
    string = string.replaceAll('"', ""); // remove backslash with double quotation marks
    string = string.replaceAll('"', ""); // remove double quotation marks
    string = string.replaceAll("\\", ""); // remove backslash
    string = string.trim(); // Remove whitespace from ends

    return string;
  }

  function handleQuestionResponse(slug, answer, progressToNextQuestion) {
    progressToNextQuestion =
      typeof progressToNextQuestion == "undefined" ||
      progressToNextQuestion == null
        ? true
        : progressToNextQuestion;

    // Look up question from question set and store response key in answerMeta
    let answerMeta = "";
    for (let i = 0; i < questionSet.length; i++) {
      if (questionSet[i].questionName == slug) {
        // Match found, get question response meta
        if (typeof questionSet[i].questionResponses != "undefined") {
          // check responses exist
          let responseIndex = null; // store for matching response index

          // Determine answer name from question possible answers (if possible)
          for (const [key, value] of Object.entries(
            questionSet[i].questionResponses
          )) {
            if (typeof answer === "object" && answer.includes(value)) {
              answerMeta += "," + key;
            } else if (value == answer) {
              answerMeta = key; // store response key in answerMeta
            }
          }

          if (answerMeta.startsWith(",")) {
            answerMeta = answerMeta.substring(1);
          }
        } // multiple responses not detected, store nothing
      }
    }

    // Loop through responses array and add question data and question meta
    let match = false;
    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug == slug) {
        match = true; // mark match as true
        questionResponses.current[i].answer = answer; // update question response
        questionResponses.current[i].answerMeta = answerMeta;
        // TO DO - send reponse
      }
    }

    if (slug === "firstname") {
      setUserFirstName(answer);
    } else if (slug === "profile") {
      let answerObj = JSON.parse(answer);
      let name =
        typeof answerObj.firstname !== "undefined" ? answerObj.firstname : "";
      setUserFirstName(name);
      setLoggedInUserData(answerObj);
    }

    if (slug === "contact_email") {
      let emailInfo = JSON.parse(answer);
      setUserEmail(emailInfo.email);
    }

    if (slug === "contact_phone") {
      let phoneInfo = JSON.parse(answer);
      setUserPhone(phoneInfo.phone);
    }

    // No match found in loop, add new answer
    if (!match) {
      questionResponses.current.push({
        slug: slug,
        answer: answer,
        answerMeta: answerMeta,
      });
    }

    // Push answer to Simbyte API
    pushQuestionResponseToAPI();

    console.log("Responses dump");
    console.log(questionResponses.current);

    if (cachedElementsRef.current.length > 0) {
      progressToNextQuestion = false;

      let postRenderContainer = getDOMElement("postRenderContainer", "id"); // document.getElementById('postRenderContainer');
      let chatBoxEl = getDOMElement("chat-box", "id");
      let chatBoxElWritable;

      if (document.getElementById("healthcheckembed") == null) {
        chatBoxElWritable = ReactDOM.createRoot(postRenderContainer);
      } else {
        chatBoxElWritable = createRoot(postRenderContainer);
      }

      chatBoxEl.scrollTop = 0;
      postRenderContainer.scrollTop = 0;
      chatBoxElWritable.render(cachedElementsRef.current); // render array
      // chatBoxElWritable.render(cachedElementsRef.current);

      cachedElementsRef.current = [];
    }

    if (progressToNextQuestion) {
      nextQuestion(); // go to next question
    }
  }

  function handleQuestionStop(message) {
    questionIndex = getNextValidQuestionIndex();
    let arr = [questionIndex];
    setQuestionIndexFlow((questionIndexFlow) => [...questionIndexFlow, ...arr]);
    let fauxQuestionText = message;
    if (
      !fauxQuestionText.includes("<TEXT>") &&
      !fauxQuestionText.includes("<HEADING>")
    ) {
      fauxQuestionText = "<TEXT>" + message + "</TEXT>";
    }

    let fauxQuestion = {
      questionText: fauxQuestionText,
      image: "",
      animation: "",
      buttonImages: "",
    };

    let conversationElements = getConversationElements(fauxQuestion);

    // Remove problematic elements
    for (let i = conversationElements.length - 1; i >= 0; i--) {
      if (conversationElements[i].type === "") {
        conversationElements.splice(i, 1);
      }
    }

    if (document.getElementById("healthcheckembed") == null) {
      let chatBoxEl = document.getElementById("chat-box");
      let chatBoxElWritable = ReactDOM.createRoot(chatBoxEl);
      chatBoxEl.scrollTop = 0;
      chatBoxElWritable.render(conversationElements); // render array
    } else {
      const master = document.getElementById("healthcheckembed");
      const shadow = master.shadowRoot;
      const shadowRoot = shadow.getElementById("chat-box");
      let chatBoxElWritable = createRoot(shadowRoot);
      shadowRoot.scrollTop = 0;
      chatBoxElWritable.render(conversationElements); // render array
    }

    //Desktop - Update sidecart content
    setDesktopSidecartContent(`<h2>Heath Check Stopped</h2>`);
  }

  function displayChachedComponents() {
    if (cachedElementsRef.current.length > 0) {
      let postRenderContainer = getDOMElement("postRenderContainer", "id"); // document.getElementById('postRenderContainer');
      let chatBoxEl = getDOMElement("chat-box", "id");
      let chatBoxElWritable;

      if (document.getElementById("healthcheckembed") == null) {
        chatBoxElWritable = ReactDOM.createRoot(postRenderContainer);
      } else {
        chatBoxElWritable = createRoot(postRenderContainer);
      }

      chatBoxElWritable.render(cachedElementsRef.current); // render array

      setTimeout(() => {
        chatBoxEl.scrollTo({ top: chatBoxEl.scrollHeight, behavior: "smooth" });
      }, 50);

      cachedElementsRef.current = [];
    }
  }

  async function pushQuestionResponseToAPI() {
    // formulate form data
    let formData = new FormData();

    if (isFinalQuestion()) {
      formData.append("final", "1");
    }

    // Add question set ID to the formData
    if (apiSaveResponsesID != "") {
      // iff not '' this indicates the save response ID has been previously set
      formData.append("responseId", apiSaveResponsesID); // append the response ID so the data set can be
    }

    for (let i = 0; i < questionResponses.current.length; i++) {
      let answer = questionResponses.current[i].answer;
      if (questionResponses.current[i].answerMeta != "") {
        answer = questionResponses.current[i].answerMeta;
      }

      formData.append(
        "responses[" + i + "][questionName]",
        questionResponses.current[i].slug
      );
      formData.append("responses[" + i + "][response]", answer);
    }

    // questionSetID stored globally
    let response = await callSaveResponsesAPIEndpoint(
      formData,
      structuredClone(questionSet[questionIndex]),
      questionIndex
    );

    console.log("Response", response);

    if (typeof response.responseId != "undefined") {
      apiSaveResponsesID = response.responseId;
      setSessionCode(apiSaveResponsesID);
      setSaveUserDetailsID(response.responseId);
    }

    if (
      typeof response.alertmessage != "undefined" &&
      response.alertmessage === "Completed"
    ) {
      setOveralResultSet(response.resultSet);
      setOveralResult(response);
    }

    setStartButtonText("All loaded!");

    setTimeout(() => setStartButtonText("Great. Let's get started"), 2000);
  }

  async function fetchPostcodeListFromAPI() {
    let responseData = [];
    // commended out 20220822 to enlarge scope of postcodes
    // const state = "Queensland";
    // let queryString = `filter[filters][0][field]=Pcode&filter[filters][0][operator]=contains&filter[filters][0][value]=${""}&filter[logic]=and&filter[filters][1][field]=State&filter[filters][1][operator]=eq&filter[filters][1][value]=${state}`;

    const state = "";

    let paramsStr = getParamsAsStr(false);
    let queryString = `filter[filters][0][field]=Pcode&filter[filters][0][operator]=contains&filter[filters][0][value]=${""}`;
    let fetchURL =
      baseAPIURL + "common/getGeocodedPostcode2?" + queryString + paramsStr;

    const response = await fetch(fetchURL, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        responseData = data;
        setPostCodeList(data);
      });

    return response;
  }

  // questionSetID stored globally
  async function callSaveResponsesAPIEndpoint(
    formData,
    question = null,
    index = null
  ) {
    let responseData = [];
    let paramsStr = getParamsAsStr(true, question, index);
    console.log("paramsStr", paramsStr);
    let fetchURL =
      baseAPIURL +
      "question/saveResponses/" +
      questionSetID +
      "/v2" +
      paramsStr;
    const response = await fetch(fetchURL, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        responseData = data;
      });

    return responseData;
  }

  async function pushUserDetailsToAPI(formDataObject, callback) {
    let formData = new FormData();
    callback = callback || function () {};

    Object.keys(formDataObject).forEach((fieldName) => {
      console.log(fieldName, formDataObject[fieldName]);
      formData.append(fieldName, formDataObject[fieldName]);
    });

    let responseData = [];
    let paramsStr = getParamsAsStr(true);
    let fetchURL =
      baseAPIURL +
      "question/saveDetails/" +
      overalResult.riskassessment_id +
      "/v2" +
      paramsStr;
    const response = await fetch(fetchURL, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        responseData = data;

        // Determine form success status
        callback(data.success == "1", data.message, data.moodle2url);
        console.log(data.moodle2url);
      })
      .catch((error) => {
        console.error("Error in final submission:", error);

        // Determine form success status
        callback(
          false,
          "There was an error submitting the form. Check your submitted information and try again."
        );
      });
  }

  function displayQuestion(index) {
    let question = structuredClone(questionSet[index]);
    let formattedQuestion = filterDynamicTags(question);
    let conversationElements = getConversationElements(formattedQuestion);

    // Remove problematic elements
    for (let i = conversationElements.length - 1; i >= 0; i--) {
      if (conversationElements[i].type == "") {
        conversationElements.splice(i, 1);
      }
    }

    if (document.getElementById("healthcheckembed") == null) {
      let chatBoxEl = document.getElementById("chat-box");
      let chatBoxElWritable = ReactDOM.createRoot(chatBoxEl);
      chatBoxEl.scrollTop = 0;
      chatBoxElWritable.render(conversationElements); // render array
    } else {
      const master = document.getElementById("healthcheckembed");
      const shadow = master.shadowRoot;
      const shadowRoot = shadow.getElementById("chat-box");
      let chatBoxElWritable = createRoot(shadowRoot);
      shadowRoot.scrollTop = 0;
      chatBoxElWritable.render(conversationElements); // render array
    }

    //Desktop - Update sidecart content
    setDesktopSidecartContent(`<h2>${question.questionSection}</h2>`);
  }

  function filterDynamicTags(question) {
    console.log(question);
    let questionText = question.questionText;
    let dynamicTags = [];

    if (questionText != null && questionText.includes("[[")) {
      // dynamic tag detected - check number of dynamic tags

      let splitQuestionText = questionText.split("[[");

      // The first string will always be irrelevant, regardless of the count of the array which was made in the split
      // Every string after the first can be split again by ']]' - the closing tags
      // The first element in the array from the 2nd split will be the keyword
      // Loop through the existing tags and push if there's no mach
      for (let i = 1; i < splitQuestionText.length; i++) {
        let splitSubStr = splitQuestionText[i].split("]]");

        if (dynamicTags.length == 0) {
          dynamicTags.push(splitSubStr[0]);
        } else {
          let match = false;
          for (let j = 0; j < dynamicTags.length; j++) {
            if (dynamicTags[j] == splitSubStr[0]) {
              match = true;
            }
          }
          if (match == false) {
            dynamicTags.push(splitSubStr[0]);
          }
        }
      }

      console.log("Dynamic Tags", dynamicTags);

      // loop through dynamicTags (if length > 0) and replace all text with matching result from question questionResponses
      for (let i = 0; i < dynamicTags.length; i++) {
        let previousResponse = getPreviousQuestionResponseWithQuestionSlug(
          dynamicTags[i]
        );
        questionText = questionText.replaceAll(
          "[[" + dynamicTags[i] + "]]",
          previousResponse
        );
        console.log("Previous Response:", previousResponse);
      }
    }

    question.questionText = questionText;

    return question;
  }

  function getPreviousQuestionResponseWithQuestionSlug(
    slug,
    deliverMeta = false
  ) {
    let recordedAnswer = "";
    let recordedMeta = "";

    if (queryParams.get("userid") != null) {
      let profile = questionResponses.current.find((a) => a.slug == "profile");
      let obj = JSON.parse(profile.answer);

      Object.keys(obj).forEach((key) => {
        if (key === slug) {
          recordedAnswer = obj[key]; // get previously recorded answer
          recordedMeta = ""; // get previously recorded answermeta
        }
      });
    }

    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug == slug) {
        recordedAnswer = questionResponses.current[i].answer; // get previously recorded answer
        recordedMeta = questionResponses.current[i].answerMeta; // get previously recorded answermeta
      }
    }

    if (deliverMeta) {
      return [recordedAnswer, recordedMeta];
    } else {
      return recordedAnswer;
    }
  }

  function getConversationElements(question) {
    /** Fast XML Implementation */

    // Create FXP option to initilize new instance
    const fxp_options = {
      ignoreAttributes: false,
      attributeNamePrefix: "",
      preserveOrder: true,
      allowBooleanAttributes: true,
      // attributesGroupName : "@_"
    };

    // Create a new instance of XMLParser from Fast XML Parser Library
    const fxpParser = new XMLParser(fxp_options);

    // Also create a new instance of XMLBuilder from Fast XML Parser Library
    // It is needed for content re-build of a tag
    const fxpBuilder = new XMLBuilder(fxp_options);

    // Execute the XMLParser to get a JS object
    const resultParse = fxpParser.parse(question.questionText);

    // This Array will content the new items
    const newItems = [];

    for (let item in resultParse) {
      // Get TagElement Object and Atrributes array from entries
      // of resultParse usign Deconstructing assignment
      const [tagElement, attributes] = Object.entries(resultParse[item]) || {};

      // Usign the Deconstructing assignment
      // Get tagName (key) and tagContent (value)
      const [tagName, tagContent] = tagElement || [];

      // Get tagAttributes array from destructured attributes var
      // , (comma) is used cuz the first value
      // of attributes is ':@' (attributes identifier)
      const [, ...tagAttributes] = attributes || [];

      // Create newItem object to save the new structure required
      const newItem = {
        type: tagName.toLowerCase(),
        // Build content tag, included nested tags
        // Using fxpBuilder class
        content: fxpBuilder.build(tagContent),
        // Attach attributes found
        attributes: tagAttributes,
      };

      // Insert the newItem to newItems Array
      newItems.push(newItem);
    }
    /** Fast XML Parser Ends */

    // Get array of images from question data
    let images = question.image ?? [];
    if (question.image !== null) {
      if (question.image.includes("|")) {
        images = images.split("|");
      } else {
        images = [question.image];
      }
    }

    // Get array of animations from question data
    let animations = question.animation ?? [];
    if (question.animation !== null) {
      if (question.animation.includes("|")) {
        animations = animations.split("|");
      } else {
        animations = [question.animation];
      }
    }

    // Declare extra-loop variables
    let newElements = [];
    let elementKey = 0;
    let imageIndex = 0;
    let animationIndex = 0;
    let responseInSitu = false;
    let newElementDetails = getInputDetailsForQuestion(question);

    // Loop through array of XML parser entites
    for (let i = 0; i < newItems.length; i++) {
      // Define new element fundamentals
      let element = {
        type: "",
        options: {
          key: question.questionId + "." + elementKey++ + "." + i,
        },
        content: newItems[i].content,
      };

      // Determine the element type
      switch (newItems[i].type.toLowerCase()) {
        case "heading":
          element.type = Heading;
          element.options.className = "chat-heading";
          element.options.text = element.content;
          break;

        case "text":
          element.type = Text;
          element.options.className = "chat-heading";
          element.options.text = element.content;
          break;

        case "image":
          element.type = Image;
          element.options.src =
            images[imageIndex++] ??
            baseAPIURL + "public/assets/img/mh4l/questions/image-needed.png";
          break;

        case "animation":
          element.type = Animation;
          element.options.bgClass = "bg-primary-3";
          element.options.src =
            animations[animationIndex++] ??
            baseAPIURL + "public/assets/img/mh4l/questions/image-needed.png";
          break;

        case "response":
          responseInSitu = true;
          newElementDetails = getInputDetailsForQuestion(
            question,
            i < newItems.length - 1
          ); // Get input element details
          element.type = newElementDetails.type;
          element.options = {
            ...element.options,
            ...newElementDetails.options,
          };
          break;

        case "conversation":
          element.type = ChatMessage;

          if (element.content.includes("<IMAGE></IMAGE>")) {
            // check content for <IMAGE></IMAGE> tags
            element.options.bgClass = "bg-primary-3";
            element.options.imageUrl =
              images[imageIndex++] ??
              baseAPIURL + "public/assets/img/mh4l/questions/image-needed.png";
          } else if (element.content.includes("<ANIMATION></ANIMATION>")) {
            element.options.bgClass = "bg-primary-3";
            element.options.animationUrl =
              animations[animationIndex++] ??
              baseAPIURL + "public/assets/img/mh4l/questions/image-needed.png";
          } else {
            element.options.bgClass = "bg-primary";
            element.options.text = element.content;
          }

          break;
        default:
          break;
      }

      // check for attribute and pass to isConversationSnippetValid()
      let conversationSnippetValid = true;
      if (newItems[i].attributes.length > 0) {
        for (let j = 0; j < newItems[i].attributes.length; j++) {
          if (typeof newItems[i].attributes[j].condition != "undefined") {
            conversationSnippetValid = isConversationSnippetValid(
              newItems[i].attributes[j].condition
            );
            console.log("conversationSnippetValid:", conversationSnippetValid);
          }
        }
      }

      if (conversationSnippetValid) {
        if (responseInSitu && element.type == ChatMessage) {
          cachedElementsRef.current.push(
            React.createElement(element.type, element.options, element.content)
          );
        } else {
          // Push new element to array
          newElements.push(
            React.createElement(element.type, element.options, element.content)
          );
        }
      }
    }

    // Check if the response is in situ (already added)
    if (!responseInSitu) {
      // Response isn't in situ - add it
      let element = {
        type: "",
        options: {
          key: question.questionId + "." + elementKey++,
        },
      };

      newElementDetails = getInputDetailsForQuestion(question);

      element.type = newElementDetails.type;
      element.options = {
        ...element.options,
        ...newElementDetails.options,
      };

      // Push new element to array
      newElements.push(React.createElement(element.type, element.options));
    }

    // Make button
    let buttonText = "Next";
    if (question.buttonText != null && question.buttonText != "") {
      buttonText = question.buttonText;
    }

    if (
      (question.options != null &&
        question.options.includes("Custom Next button") &&
        question.questionType !== "Short Answer" &&
        question.questionType !== "Single-Select-Dropdown" &&
        question.questionType !== "Blood Pressure" &&
        question.questionType !== "Multiple Questions" &&
        question.questionType !== "Single-Select" &&
        question.questionType !== "Multi-Select" &&
        question.questionType !== "CVD Score") ||
      question.questionType == "True/False" ||
      (question.questionType === "Short Answer" &&
        question.questionText == null)
    ) {
      newElements.push(
        React.createElement(
          Button,
          {
            key: question.questionId + "." + elementKey++,
            classList:
              "primary regular large last-button " +
              question.questionType.replace(" ", ""),
            innerText: buttonText,
            onclick: nextQuestion,
            style: { marginTop: "32px" },
          },
          ""
        )
      );
    }

    // Add avatar
    newElements.push(
      React.createElement(ChatAvatar, {
        key: question.questionId + "." + elementKey++,
        className: isMobile ? "small" : "",
        fillClass: "fill-primary",
        autoSetPosition: true,
      })
    );

    newElements.push(React.createElement("div", { id: "postRenderContainer" }));
    return newElements;
  }

  function getInputDetailsForQuestion(question, hasCachedComponentsAfterInput) {
    let element = {
      type: "",
      options: {
        slug: question.questionName,
        callback: handleQuestionResponse,
        stopCallback: handleQuestionStop,
        validation: question.validation,
        dataType: question.dataType,
        environment: environment,
        baseAPIURL: baseAPIURL,
      },
    };

    // Declear outside the switch
    let options = [];

    switch (question.questionType) {
      case "Yes/No":
      case "True/False":
        element.type = YesNo;
        element.options = {
          ...element.options,
          ...{
            classList: "include-m-b",
          },
        };
        break;

      // Potential problem here for (question.options == "Responses as Buttons")
      case "Single-Select":
      case "Multi-Select":
      case "Single-Select-Waist":
        if (
          question.questionName != "workplace_selection" &&
          question.questionName != "contact_selection" &&
          question.questionName != "hypercholesterolaemia"
        ) {
          element.type = MultiChoice;
          element.options = {
            ...element.options,
            ...{
              question: question,
              continueOnSelect: !(
                question.options != null &&
                question.options.includes("Custom Next button")
              ),
              sexAnswer: getAnswerForSlug("sex"),
              hasCachedElements: hasCachedComponentsAfterInput,
              hasCachedElementsCallback: displayChachedComponents,
            },
          };
        } else {
          element.type = DynamicYesNo;
          element.options = {
            ...element.options,
            ...{
              innerText: "",
              options: [
                question.questionResponses.response1,
                question.questionResponses.response2,
              ],
              collapseButtons: true,
            },
          };
        }
        break;

      case "Short Answer":
        let defaultValue = "";
        if (question.defaultValue != null) {
          defaultValue = defaultValue;
        }

        if (question.questionName == "workplace") {
          let workplaceCodeGetKeys = [
            "tc",
            "trackingcode",
            "tracking_code",
            "eventcode",
            "event_code",
            "workplace",
            "work_place",
            "workplacecode",
            "workplace_code",
            "work_place_code",
          ];

          // loop through queryparams and key params and find a matching query param and add to default value
          queryParams.forEach(function (value, key) {
            for (let i = 0; i < workplaceCodeGetKeys.length; i++) {
              if (key == workplaceCodeGetKeys[i]) {
                defaultValue = value;
              }
            }
          });
        }

        if (question.questionName != "postcode") {
          element.type = DynamicInput;
          element.options = {
            ...element.options,
            ...{
              defaultValue: defaultValue,
              inputType: "text",
              apiBase: baseAPIURL,
              params: getParamsAsStr(false),
              buttonText:
                question.buttonText != null && question.buttonText !== ""
                  ? question.buttonText
                  : "Next",
              inputPlaceholder:
                question.explanationText != null &&
                question.explanationText !== ""
                  ? question.explanationText
                  : "Enter " + tidyPlaceholderStr(question.questionName),
            },
          };
        } else if (question.questionName == "postcode") {
          let options = postCodeList.map((a) => ({
            code: a.Pcode,
            suburb: a.Locality,
          }));
          element.type = AutocompleteInput;
          element.options = {
            ...element.options,
            ...{
              type: "text",
              id: "colFormLabel",
              options: options,
              buttonText:
                question.buttonText != null && question.buttonText !== ""
                  ? question.buttonText
                  : "Next",
              placeholder:
                question.explanationText != null &&
                question.explanationText !== ""
                  ? question.explanationText
                  : "Enter " + tidyPlaceholderStr(question.questionName),
            },
          };
        }
        break;

      case "Single-Select-Dropdown":
        // Get options for dropdown
        options = [];

        Object.values(question.questionResponses).map((val, index) => {
          options.push(val);
        });

        if (question.questionName === "postcode") {
          let simplePostCodes = postCodeList.map((a) => ({
            code: a.Pcode,
            suburb: a.Locality,
          }));
          options = simplePostCodes;
        }

        element.type = AutocompleteInput;
        element.options = {
          ...element.options,
          ...{
            type: "text",
            id: "colFormLabel",
            options: options,
            buttonText:
              question.buttonText != null && question.buttonText !== ""
                ? question.buttonText
                : "Next",
            placeholder:
              question.explanationText != null &&
              question.explanationText !== ""
                ? question.explanationText
                : "Enter " + tidyPlaceholderStr(question.questionName),
          },
        };
        break;

      case "Multiple Questions":
        // If this has a multiselect, get the options
        options = [];
        for (let k = 0; k < question.childQuestions.length; k++) {
          let childQuestionOptions = [];
          if (
            question.childQuestions[k].questionType ==
              "Single-Select-Dropdown" ||
            question.childQuestions[k].questionName === "postcode"
          ) {
            Object.values(question.childQuestions[k].questionResponses).map(
              (val, index) => {
                childQuestionOptions.push(val);
              }
            );

            if (question.childQuestions[k].questionName === "postcode") {
              let simplePostCodes = postCodeList.map((a) => ({
                code: a.Pcode,
                suburb: a.Locality,
              }));
              childQuestionOptions.push(simplePostCodes);
            }
          }
          options.push(childQuestionOptions);
        }

        element.type = MultiQuestion;
        element.options = {
          ...element.options,
          ...{
            buttons: question.questionResponses,
            options: options,
            childQuestions: question.childQuestions,
            inputType: "text",
            buttonText:
              question.buttonText != null && question.buttonText !== ""
                ? question.buttonText
                : "Next",
          },
        };
        break;

      case "CVD Score":
      case "Blood Pressure":
        if (question.questionType == "CVD Score") {
          element.type = CVDScore;
        } else if (question.questionType == "Blood Pressure") {
          element.type = BloodPressure;
        }

        element.options = {
          ...element.options,
          ...{
            buttons: question.questionResponses,
            inputType: "text",
            inputPlaceholder:
              question.explanationText != null &&
              question.explanationText !== ""
                ? question.explanationText
                : "Enter " + tidyPlaceholderStr(question.questionName),
          },
        };
        break;
      default:
        break;
    }

    return element;
  }

  // Gets the answer for a slug from stored responses
  // Returns string
  function getAnswerForSlug(slug) {
    let answer = "";
    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug == slug) {
        answer = questionResponses.current[i].answer; // get previously recorded answer
      }
    }
    return answer;
  }

  // Gets the answerMeta for a slug from stored responses
  // Returns string
  function getAnswerMetaForSlug(slug) {
    let answerMeta = "";
    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug == slug) {
        answerMeta = questionResponses.current[i].answerMeta; // get previously recorded answer
      }
    }
    return answerMeta;
  }

  // Gets the answer and answerMeta for a slug from stored responses
  // Returns objects {answer, answerMeta}
  function getAnswerAndMetaForSlug(slug) {
    let answer = "";
    let answerMeta = "";
    for (let i = 0; i < questionResponses.current.length; i++) {
      if (questionResponses.current[i].slug == slug) {
        answer = questionResponses.current[i].answer;
        answerMeta = questionResponses.current[i].answerMeta; // get previously recorded answer
      }
    }
    return { answer: answer, answerMeta: answerMeta };
  }

  function getConditionStr(questionTextSubStr) {
    let conditionStr = "";
    let conditionAssignature = "";

    if (questionTextSubStr.includes('condition:"')) {
      conditionAssignature = ":";
    } else if (questionTextSubStr.includes('condition="')) {
      conditionAssignature = "=";
    }

    let subStr = questionTextSubStr.split(
      "condition" + conditionAssignature + '"'
    );
    let splitIndex = subStr[1].indexOf('"');
    conditionStr = subStr[1].substring(0, splitIndex);

    return conditionStr;
  }

  function isConversationSnippetValid(condition) {
    // This function will leverage eval() to complete the conditional statements

    let originalCondition = condition;

    if (condition == "{{EXTERNALONLY}}") {
      if (queryParams.get("userid") == null) {
        return true;
      } else {
        return false;
      }
    } else {
      // Encapsulate all response meta keys in quotations
      let numberCap = 9;
      for (let i = 0; i <= numberCap; i++) {
        let searchStr = "response" + i;
        let replacementStr = '"response' + i + '"';
        condition = condition.replaceAll(searchStr, replacementStr);
      }

      // Replace all {{tags}} with response meta key if presence, else replace all {{tags}} with response value.
      for (let i = 0; i < questionResponses.current.length; i++) {
        let searchStr = "{{" + questionResponses.current[i].slug + "}}"; // compose the slug to search for
        let replacementStr = questionResponses.current[i].answer; // set the answer as the replacment str
        if (questionResponses.current[i].answerMeta != "") {
          // check if the meta isn't an empty string (indicates non-multiselect)
          replacementStr = questionResponses.current[i].answerMeta; // update the replacement str
        }

        // If value is a poential int, print out as is, else encapsulate in quotations
        if (!isNaN(parseInt(replacementStr))) {
          // check if it's a number
          // is a number, leave replacement str as is
        } else {
          // not a number, encapsulate in quotations
          replacementStr = '"' + replacementStr + '"';
        }

        condition = condition.replaceAll(searchStr, replacementStr);
      }

      let result = false;
      try {
        result = eval(condition);
      } catch (err) {
        console.error(
          "Conversation condition error: expression couldn't be evaluated. \nCondition:\"" +
            originalCondition +
            '"'
        );
      }

      if (typeof result == "boolean") {
        return result;
      } else {
        console.error(
          "Conversation condition error: expression didn't evaluate to a true/false. \nCondition:\"" +
            originalCondition +
            '"'
        );
        return false;
      }
    }
  }

  function evaluateConversationLogicalCondition(condition) {
    let outcome = null;

    if (condition[1] == "&&") {
      outcome = condition[0] && condition[2];
    } else if (condition[1] == "||") {
      outcome = condition[0] || condition[2];
    }

    return outcome;
  }

  function evaluateNestedConversationCondition(condition) {
    condition[0] = evaluateConversationComparisonCondition(condition[0]);
    condition[2] = evaluateConversationComparisonCondition(condition[2]);

    return evaluateConversationLogicalCondition(condition);
  }

  function evaluateConversationComparisonCondition(partial) {
    // Replace variables
    let responseAnswerAndMeta = [];
    let tagIndex = 0;

    for (let i = 0; i < partial.length; i++) {
      if (partial[i].split("{{").length == 2) {
        tagIndex = i;
        partial[i] = partial[i].replace("{{", "");
        partial[i] = partial[i].replace("}}", "");
        partial[i] = partial[i].trim();
        responseAnswerAndMeta = getPreviousQuestionResponseWithQuestionSlug(
          partial[i],
          true
        );
      }
    }

    // Use meta or value
    if (
      partial[0].indexOf("response") > -1 ||
      partial[2].indexOf("response") > -1
    ) {
      partial[tagIndex] = responseAnswerAndMeta[1];
    } else {
      if (typeof responseAnswerAndMeta[0] == "string") {
        partial[tagIndex] = responseAnswerAndMeta[0];
      } else if (Array.isArray(responseAnswerAndMeta[0])) {
        partial[tagIndex] = responseAnswerAndMeta[0][0];
      }
    }

    // Replace ints with actual ints
    for (let i = 0; i < partial.length; i++) {
      if (!isNaN(parseInt(partial[i]))) {
        partial[i] = parseInt(partial[i]);
      }
    }

    // Got through conditions
    //'==','!==','!=','<=','>=','<','>'
    let outcome = null;
    if (partial[1] === "==") {
      // equals

      outcome = partial[0] === partial[2];
    } else if (partial[1] === "!==") {
      // does not equal

      outcome = partial[0] !== partial[2];
    } else if (partial[1] === "!=") {
      // does not equal

      outcome = partial[0] !== partial[2];
    } else if (partial[1] === ">=") {
      // greater than or equals to

      outcome = partial[0] >= partial[2];
    } else if (partial[1] === "<=") {
      // less than or equals to

      outcome = partial[0] <= partial[2];
    } else if (partial[1] === "<") {
      // less than

      outcome = partial[0] < partial[2];
    } else if (partial[1] === ">") {
      // greater than

      outcome = partial[0] > partial[2];
    }

    return outcome;
  }

  function tidyPlaceholderStr(string) {
    string = string.replaceAll("_", " ");
    string = string.toLowerCase();

    return string;
  }

  function getDesktopSidecartContent() {
    let desktopContent = ""; // init desktop content var
    const desktopContentElement = document.getElementById(
      "healthcheckdesktopcontent"
    ); // get inner html from body element

    // check if body element was set and content isn't empty
    if (
      typeof desktopContentElement !== "undefined" &&
      desktopContentElement != null &&
      typeof desktopContentElement.innerHTML !== "undefined" &&
      desktopContentElement.innerHTML !== ""
    ) {
      desktopContent = desktopContentElement.innerHTML;
      desktopContent = desktopContent.replace(
        ",function () { [native code] }",
        ""
      );
    } else {
      let welcomeContent = WelcomeContent();
      desktopContent = welcomeContent;
    }

    return desktopContent;
  }

  return (
    <>
      {/*
      <div className={`partial-referral-banner ${displayReferralBanner ? '' : ' hide'}`}>
        <div className="container-flex">

          <div className="container-fields">

            <div className="banner-row">
              <div className="banner-cell">
                <span className="label">Referrer: </span><span className="value">{referralHeader.referrer}</span>
              </div>
              <div className="banner-cell">
                <span className="label">Referral Type:</span><span className="value">{referralHeader.referralType}</span>
              </div>
            </div>

            <div className="banner-row">
              <div className="banner-cell">
                <span className="label">Event Type: </span><span className="value">{referralHeader.eventType}</span>
              </div>
              <div className="banner-cell">
                <span className="label">Event Identifier: </span><span className="value">{referralHeader.eventIdentifier}</span>
              </div>
            </div>

          </div>

          <div className="container-button">
            <a href={referralHeader.logOut} className="component-button primary regular mx-auto">
                <div className="text-block text mini inner-text">Logout</div>
            </a>
          </div>

        </div>
      </div>
      */}

      <div
        className={`partial-desktop-header ${
          displayLogoBannerAndFooter ? "" : " hide"
        }`}
        onClick={previousQuestion}
      >
        <div className="mh4l-logo black"></div>
      </div>

      <div
        className="container-session-code"
        id="containerSessionCode"
        data-session-code={sessionCode}
        style={{ display: "none" }}
      ></div>

      <div className="container-primary">
        <div className={"" + desktopViewClassList}>
          <div className="background-pattern bg-pattern"></div>
          <div className="background-gradient"></div>

          <div className="container-content">
            <div className="content">
              <div className="graphic"></div>

              <div
                className="copy"
                dangerouslySetInnerHTML={{ __html: desktopSidecartContent }}
              ></div>

              <Button
                classList={`start-button primary regular large mx-auto ${
                  questionSetLoaded ? "" : "disabled"
                }`}
                innerText={stateButtonText}
                onclick={startHealthCheck}
              />
            </div>
          </div>
        </div>

        <div className="partial-chat-view">
          <Header
            headerState={headerState}
            progress={progressPercentage}
            navigateBackCallback={previousQuestion}
            indexFlow={questionIndexFlow}
          />
          {/*
					<Button
						classList={`primary regular toggle-results-btn`}
						innerText={"Results"}
						onclick={() => {
							let resultsVisible = !resultsVisibility;

							setResultsVisibility(resultsVisible);

							if(resultsVisible){
								setHeaderState('results')
							}else{
								setHeaderState('inProgress')
							}
						}}
					/>  */}

          <div className="container-chat-box">
            <span className="sprite-left"></span>
            <span className="sprite-left-border"></span>
            <span className="sprite-right"></span>
            <span className="sprite-right-border"></span>

            <div className="chat-box" id="chat-box">
              <div className="partial-mobile-loading-screen">
                <div className="copy">
                  <h3>
                    <em>My health for life</em> is a free, healthy lifestyle
                    program helping Queenslanders to live well.
                  </h3>
                </div>

                <Slider
                  data={[
                    { type: "text", text: svgs.mhfllogo },
                    { type: "text", text: svgs.wavinghand },
                    { type: "text", text: svgs.uprighthand },
                  ]}
                />

                <Button
                  classList={`start-button primary regular large mx-auto ${
                    questionSetLoaded ? "" : "disabled"
                  }`}
                  innerText={stateButtonText}
                  onclick={startHealthCheck}
                />
              </div>
            </div>

            {resultsVisibility && (
              <div className="results-box" id="results-box">
                <ResultsScreen
                  resultSet={overalResultSet}
                  results={overalResult}
                  key={"123"}
                  classList="test"
                  imgType="bottles.png"
                  chartSubHeading="11+ standard drinks a week"
                  chartDescription="Consuming 10 or less standard drinks per week is safest for your long term health. Drinking less than 4 standard drinks on any one occasion is best. The less you choose to drink, the better your sleep, energy and body will feel."
                  question="Want to learn more about eating well or explore some new food ideas? "
                  links={[
                    {
                      link: "https://www.google.com",
                      text: "Living well library",
                    },
                    {
                      link: "https://www.google.com",
                      text: "Jaimie's Ministry of Food",
                    },
                  ]}
                  formSubmitCallback={pushUserDetailsToAPI}
                  userFirstName={userFirstName}
                  userEmail={userEmail}
                  userPhone={userPhone}
                  loggedInUserData={loggedInUserData}
                  resultsVisibility={resultsVisibility}
                  displayLogoBannerAndFooter={displayLogoBannerAndFooter}
                  environment={environment}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {resultsVisibility && displayLogoBannerAndFooter && (
        <Footer className="desktop-footer" environment={environment}></Footer>
      )}
    </>
  );
}

export default HealthCheck;
